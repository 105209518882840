import { Injectable } from '@angular/core';
import { ApiRequestService } from '@common/api-request.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NettingTableState } from '../netting-table/netting-table.reducers';
import { MessagesMapperService } from '@common/messages-mapper.service';
import * as OverviewActions from '../invoice-overview/invoice-overview.actions';
import * as NttTableActions from '../netting-table/netting-table.actions';
import * as AmntTableActions from '../amount-table/amount-table.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { Message } from 'primeng/api';
import { of } from 'rxjs';
import { Details, InvoiceOverview } from '../../../netting-preview/netting-overview/netting-overview.model';
import { InvoiceOverviewState } from './invoice-overview.reducers';

@Injectable()
export class InvoiceOverviewEffects {
  constructor(private apiGateway: ApiRequestService,
              private actions$: Actions,
              private store1$: Store<InvoiceOverviewState>,
              private store2$: Store<NettingTableState>,
              private mapper: MessagesMapperService) {
  }

  private baseUrl = '/api/esm/netting/preview';

  load_preview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OverviewActions.LoadView),
      switchMap(({ selected }) =>
        this.apiGateway.post(`${this.baseUrl}/data/detailed`, createRequest(selected))
          .pipe(
            switchMap(response => {
              const messages: Message[] = this.mapper.toErrorMessages(response);

              // WORK put it in other store :: { toPayRow, overallRow, toReceiveRow } = response.value;

              return messages?.length ?
                [NttTableActions.setMessages({ messages })] :
                [
                  OverviewActions.LoadViewSuccess({ item: createItem(selected, response.value) }),
                  AmntTableActions.loadAmountSuccess({ item: createAmount(selected, response.value) })
                ];
            }),

            catchError(error => of(NttTableActions.setMessages(this.mapper.toErrorMessagesObj(error.errorMessage || 'Server Error')))
            ))
      ))
  );

}

const createItem = ({ id, dueDate, nettingAgreement, commodities }, {
  title,
  payer,
  receiver,
  generationButtonVisible,
  genButtonToolTipText
}): InvoiceOverview => ({ details: createDetails(dueDate, nettingAgreement.split('/'),commodities), id, title, payer, receiver, generationButtonVisible, genButtonToolTipText });

const createDetails = (dueDate, nettingAgreement, commodities): Details => ({
  dueDate,
  'commodities': commodities,
  'currency': nettingAgreement[nettingAgreement.length - 2],
  'agreement': nettingAgreement[nettingAgreement.length-1],
});

const createRequest = ({ myVAT, cpVAT, counterPartyId, nettingAgreement, dueDate, commodities}) => ({
  myVAT,
  cpVAT,
  counterPartyId,
  nettingAgreement,
  dueDate,
  commodities
});

const createAmount = ({ id }, { toPayRow, toReceiveRow, overallRow }) => ({
  id,
  values: [
    {
      cashFlow: 'To Receive',
      ourNettingCalculation: toReceiveRow.ourNettingCalculation,
      cpNettingCalculation: toReceiveRow.cpNettingCalculation,
      ourOpenUpToDate: toReceiveRow.ourOpenUpToDate,
      ourAgreedToDate: toReceiveRow.ourAgreedToDate,
      ourDisputedUpToDate: toReceiveRow.ourDisputedUpToDate
    },
    {
      cashFlow: 'To Pay',
      ourNettingCalculation: toPayRow.ourNettingCalculation,
      cpNettingCalculation: toPayRow.cpNettingCalculation,
      ourOpenUpToDate: toPayRow.ourOpenUpToDate,
      ourAgreedToDate: toPayRow.ourAgreedToDate,
      ourDisputedUpToDate: toPayRow.ourDisputedUpToDate
    },
    {
      cashFlow: 'Overall',
      ourNettingCalculation: overallRow.ourNettingCalculation,
      cpNettingCalculation: overallRow.cpNettingCalculation,
      ourOpenUpToDate: overallRow.ourOpenUpToDate,
      ourAgreedToDate: overallRow.ourAgreedToDate,
      ourDisputedUpToDate: overallRow.ourDisputedUpToDate
    },
  ]
});



