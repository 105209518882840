<div class="panel-box margin-bottom-15">
  <form [formGroup]="form">
    <ng-container *ngFor="let control of idFiltersLabels; index as i">
      <div class="row align-items-center">
        <label class="text-bold col-12 form__label" for="filter-{{i}}">{{control.label}}</label>
        <div class="col-12 col-sm-8 col-md-9 col-xl-8">
          <textarea maxlength="4000" class="form-control p-component padding-6 id-filters__textarea" id="filter-{{i}}" formControlName="{{control.name}}"></textarea>
        </div>
        <div class="col">
          <button type="submit" id="{{control.name}}" class="form-control btn margin-3 padding-6 btn&#45;&#45;id"
                  (click)="onFilter(control.name)" [disabled]="disabled">Search
          </button>
        </div>
      </div>
    </ng-container>
  </form>
  <div class="row align-items-center justify-content-between mt-3">
    <div class="col-12 col-sm-8 col-md-9 col-xl-8 info-panel--container">
      <div class="info-panel truncated-text">
        <span class="fa fa-info-circle padding-right-6"></span>
        Fields accept lists of up to 50 IDs
      </div>
    </div>
    <div class="col">
      <button type="reset" id="btn-reset-uti" class="form-control btn margin-3 padding-6 btn--id" (click)="onReset()">Reset
      </button>
    </div>
  </div>
</div>
