import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { Message } from 'primeng/api';

@Component({
  selector: 'cms-billing-reports-filters',
  templateUrl: './billing-reports-filters.component.html',
  styleUrls: ['./billing-reports-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillingReportsFiltersComponent implements OnInit, OnChanges {
  @Input() dates: { startDate: Date, endDate: Date };
  @Input() messages: Message[];
  form: UntypedFormGroup;
  errors: {[key: string]: ValidationErrors} = {};
  defaultDates = {
    startDate: null,
    endDate: null
  };

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      ...(this.dates || this.defaultDates)
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.messages) {
      this.errors = {};
      if (this.messages) {
        this.messages.forEach((obj: Message) => {
          const { key: controlName } = obj;
          this.errors[controlName] = { 'server': true };
        });
      }
    }

    if (this.form && changes.dates) {
      this.form.patchValue({
        ...(this.dates || this.defaultDates)
      });
      this.form.updateValueAndValidity();
    }
  }
}
