import { createAction, props, union } from '@ngrx/store';
import { Message } from 'primeng/api';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { InvoiceDivEntry } from '../../../netting-preview/netting-table-overview/netting-table-overview.model';


export const loadTable = createAction('[ESM-NTT-PREVIEW] Load preview table');
export const querySuccess = createAction('[ESM-NTT-PREVIEW] Search preview successfully', props<{ data: DatatableData<InvoiceDivEntry[]>}>());
export const loading = createAction('[ESM-NTT-PREVIEW] un/loading', props<{loading:boolean }>());
export const pagingAndSorting = createAction('[ESM-NTT-PREVIEW] Paging preview', props<{ sorting: SortingFilter, paging: PagingFilter}>());
export const setMessages = createAction('[ESM-NTT-PREVIEW] set messages', props<{ messages: Message[] }>());
export const resetMessages = createAction('[ESM-NTT-PREVIEW] Reset Messages');
export const cleanState = createAction('[ESM-NTT-PREVIEW] Clear preview state');
export const selectInvoice = createAction('[ESM-NTT-PREVIEW] select invoice', props<{ selected: InvoiceDivEntry }>());
export const withDiscrepancies = createAction('[ESM-NTT-PREVIEW] with discrepancies', props<{ selected: boolean  }>());
export const setPagingAndSorting = createAction('[ESM-NTT-PREVIEW] set sorting and paging', props<{ sorting: SortingFilter, paging: PagingFilter}>());


const actions = union({
  loadTable,
  pagingAndSorting,
  querySuccess,
  setMessages,
  resetMessages,
  cleanState
});

export type NettingTableActions = typeof actions;

