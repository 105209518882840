import { ValidatorFn } from '@angular/forms';
import { Message } from 'primeng/api';
import { MY_FILTERS_DIALOGS } from '@common/my-filters/my-filters.model';
import { Column } from '@common/shared/datatable.model';
import * as moment from 'moment';
import { HttpHeaders } from '@angular/common/http';

export interface AddNote {
  confirmationIds: string[];
  text: string;
}

export interface Dispute {
  invoiceId: number;
  note: string;
}

export enum HiddenDeals {
  EXCLUDE = 'EXCLUDE',
  INCLUDE = 'INCLUDE',
  HIDE_ONLY = 'HIDE_ONLY'
}

export type NoteState = 'FHA' | 'OWN_CP' | 'OTHER_CP';

export type DEALS_DIALOGS = 'TERMINATE_DEAL' | 'ECM_LITE_CANCEL' | 'ADD_NOTES' | 'VIEW_NOTES' | 'HIDE_CONFIRMATION' | 'UNHIDE_CONFIRMATION' | 'AGREE' | 'DISPUTE';

export type ECM_DEALS_DIALOGS = MY_FILTERS_DIALOGS | DEALS_DIALOGS;

export interface DialogState {
  name: ECM_DEALS_DIALOGS;
  messages?: Message[];
  data?: any;
}

export interface NotesCellEvent {
  confirmationId: string;
  checked: boolean;
}

export interface NotesCellViewEvent {
  confirmationId: string;
  notes: Note[];
}

export interface Note {
  contactOther: string;
  createdAtTimestamp: string;
  docVersion: number;
  email: string;
  jobTitle: string;
  mobile: string;
  orgInfo: string;
  phone: string;
  reporter: string;
  state: NoteState;
  text: string;
}

export interface HideConfirmation {
  confirmationId: number;
  reason: string;
}

export interface UnhideConfirmation {
  confirmationId: number;
}

export interface HideConfirmation {
  confirmationId: number;
  reason: string;
}

export interface UnhideConfirmation {
  confirmationId: number;
}

export const emptyDealsValue = '__EMPTY__';

export const getFilterPropertyMultiselectLabel = (item: string): string => !item || item === emptyDealsValue ? '(empty)' : item;

export function updateDealsMultiSelect (controlName: string,
                                      optionsName: string,
                                      values: string[],
                                      validator?: ValidatorFn) {
  this[optionsName] = values && values.length ?
    values.map(item => ({ label: getFilterPropertyMultiselectLabel(item), value: item || emptyDealsValue })) : [];
  if (this.form) {
    if (values && values.length) {
      this.form.controls[controlName].enable();
    } else {
      this.form.controls[controlName].disable();
    }

    if (validator && values && values.length) {
      this.form.controls[controlName].setValidators(validator);
      this.form.controls[controlName].updateValueAndValidity();
    } else {
      this.form.controls[controlName].clearValidators();
    }

    this.form.updateValueAndValidity();
  }
}

export const getFilterPropertyMultiselectLabelValue = (keyObject: string, valueObject: string): string => {
  if (!valueObject || valueObject === emptyDealsValue) {
    return '(empty)';
  } else {
    return !!keyObject ? keyObject + ' (' + valueObject + ')' : valueObject;
  }
};

export function updateDealsMultiSelectLabelValue(controlName: string, optionsName: string, values: { keyObject: string, valueObject: string }[], validator?: ValidatorFn) {

  this[optionsName] = (values && values.length) ? values.map(({ keyObject, valueObject }) => ({
    label: getFilterPropertyMultiselectLabelValue(keyObject, valueObject),
    value: { keyObject, valueObject } || emptyDealsValue
  })) : [];

  if (this.form) {
    if (values && values.length) {
      this.form.controls[controlName].enable();

    } else {
      this.form.controls[controlName].disable();
    }

    if (validator && values && values.length) {
      this.form.controls[controlName].setValidators(validator);
    } else {
      this.form.controls[controlName].clearValidators();
    }

    this.form.updateValueAndValidity();
  }
}

export interface DateRange {
  end: string | Date;
  start: string | Date;
}

export const getDealsColumns = (columnsOrder: string[], all: Column[]) =>
  columnsOrder && columnsOrder.length ?
    columnsOrder.map(propName => all.find(c => c.name === propName)) : [];

export const withFirstLetterUpperCase = (str: string) => `${str[0].toUpperCase()}${str.slice(1)}`;

export const getFilenameFromHeader = (headers: HttpHeaders): string =>
  headers.get('content-disposition').split(';')[1].trim().split('=')[1];

export const mapDateRangeToRequest = (dateRange: DateRange) => {
  let sDateRange = null;
  if (dateRange && (dateRange.end || dateRange.start)) {
    sDateRange = {};
    Object.entries(dateRange).forEach(([key, value]) => {
      if (key) {
        sDateRange[key] = value ? moment(value).format('YYYY-MM-DD') : null;
      }
    });
  }
  return sDateRange;
};

export const mapRequestValues = <T extends (string | number | { keyObject, valueObject })[]>(selectedValues: T, allValues: T): null | T =>
  (!allValues || !selectedValues) || (allValues.length === selectedValues.length) ? null : selectedValues;

export enum Sender {
  US = 'US',
  THEM = 'THEM'
}
