import * as fromExternal from './external-master-data/external-master-data.reducer';
import * as fromInternal from './internal-master-data/internal-master-data.reducer';
import * as fromExternalActions from './external-master-data/external-master-data.actions';
import * as fromInternalActions from './internal-master-data/internal-master-data.actions';
import * as fromMdPdfSettingsActions from './internal-master-data/pdf-settings/pdf-settings.actions';


export type ESM_MD_DIALOGS = 'DELETE';

export interface EsmMasterDataState {
    external: fromExternal.EsmExternalMasterDataState;
    internal: fromInternal.EsmInternalMasterDataState;
}

export const initialMasterDataState: EsmMasterDataState = {
    external: fromExternal.initialState,
    internal: fromInternal.initialState
};

export function initEsmMasterDataReducer(
    state: EsmMasterDataState = { ...initialMasterDataState },
    action: fromExternalActions.MasterDataAction & fromInternalActions.MasterDataActions & fromMdPdfSettingsActions.masterDataPdfSettingsActions): EsmMasterDataState {
    return {
      ...state,
      external: fromExternal.initEsmMasterDataReducer(state.external, action),
      internal: fromInternal.initEsmMasterDataReducer(state.internal, action),
    };
  }
