<button *ngIf="mode === 'adminStatus'"
        class="btn margin-top-3 margin-bottom-3"
        type="button"
        [ngClass]="{'selected': selected(), 'checked': status(true), 'unchecked': status(false) }"
        (click)="onAdminClick()">UA<span class="fa fa-check"></span><span class="fa fa-close"></span></button>
<ng-container *ngIf="mode === 'mo/operatorStatus'">
  <button class="btn margin-top-3 margin-bottom-3 operator"
          [ngClass]="{'selected': selected('masterOperator'), 'checked': status(true, 'masterOperator'), 'unchecked': status(false, 'masterOperator') }"
          type="button"
          (click)="onMasterOperatorClick()">MO<span class="fa fa-check"></span><span class="fa fa-close"></span></button>
  <button class="btn margin-top-3 margin-bottom-3 operator"
          [ngClass]="{'selected': selected('operator'), 'checked': status(true, 'operator'), 'unchecked': status(false, 'operator')}"
          type="button"
          (click)="onOperatorClick()">OP<span class="fa fa-check"></span><span class="fa fa-close"></span></button>
</ng-container>
