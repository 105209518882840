<p-dialog *ngIf="display"
          [header]="headerText"
          [(visible)]="display"
          [modal]="true"
          (visibleChange)="onVisibleChange($event)"
          [style]="{'min-width': '750px'}">
  <p-messages [value]="messages" [closable]="false"></p-messages>
  <section>
    <div class="mt-3">
      <span class="text-bold" *ngIf="invalidInvoices.length>0">The following invoices cannot be agreed</span>
    </div>
    <div class="inline-block  w-100" style="text-align: left">
      <p-listbox class="border-0"
                 [listStyle]="getStyle('invalidInvoices')"
                 [readonly]="true"
                 [options]="invalidList"
                 *ngIf="invalidInvoices.length>0">
      </p-listbox>
    </div>
    <div>
      <span class="text-bold" *ngIf="invoiceList.length>0">Please confirm your agreement of the invoices</span>
    </div>
    <div class="inline-block w-100" style="text-align: left;">
      <p-listbox class="border-0 d-border"
                 [listStyle]="getStyle('invoiceList')"
                 [readonly]="true"
                 [options]="invoiceList">
      </p-listbox>
    </div>
    <hr style=" border-top: 1px solid #d5d2d2; ">

    <div>
      <div>
        <span class="text-bold">Total Gross Amount: &nbsp;&nbsp; {{validInvoices | sum:'totalGrossAmount'| number:'1.2-2'}}€  ({{validInvoices | sum:'vatAmount' | number:'1.2-2'}}€ VAT)</span>
      </div>
      <span class="mt-3 block">Please note that when confirming, you agree to the terms and conditions of the invoice issued by the payee.</span>
    </div>
  </section>
  <p-footer>
    <button class="btn margin-right-3" type="button" (click)="onCancel()">Cancel</button>
    <button class="btn" type="button" [disabled]="!hasValidInvoices" (click)="onConfirmAgree()">Agree</button>
  </p-footer>
</p-dialog>
