import * as moment from 'moment';
import { Message } from 'primeng/api';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { defaultRrmFilter, ExternalFormData, ExternalRrmFilter, ExternalRrmStatus } from '../external-rrm.model';

interface OptionValue {
  label: string;
  value: string;
}

interface FormValues {
  messageStatus: string;
  messageType: string;
  receiver: string;
  startDate: Date;
  startTime: string;
  endDate: Date;
  endTime: string;
}

@Component({
  selector: 'cms-external-rrm-filter',
  templateUrl: './external-rrm-filter.component.html',
  styleUrls: ['./external-rrm-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExternalRrmFilterComponent implements OnInit, OnChanges {
  @Input() filterData: ExternalRrmFilter;
  @Input() formData: ExternalFormData;
  @Input() status: ExternalRrmStatus;
  @Input() isButtonVisible: boolean;
  @Input() messages: Message[];
  @Output() filter = new EventEmitter<ExternalRrmFilter>();
  @Output() refresh = new EventEmitter<ExternalRrmFilter>();

  form: UntypedFormGroup;
  statusOptions: OptionValue[] = [];
  receiverOptions: OptionValue[] = [];
  messageTypeOptions: OptionValue[] = [];
  errors: { [key: string]: ValidationErrors } = {};

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    const data = this.generateFormValues();
    this.form = this.formBuilder.group({
      ...data,
      startDate: [data.startDate, Validators.required],
      startTime: [data.startTime, Validators.required],
      endDate: [data.endDate, Validators.required],
      endTime: [data.endTime, Validators.required],
    });
    this.checkDisabledState();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes.formData) {
      this.statusOptions = this.generateOptions(this.formData && this.formData.messageStatusValues || []);
      this.receiverOptions = this.generateOptions(this.formData && this.formData.receiverValues || []);
      this.messageTypeOptions = this.generateOptions(this.formData && this.formData.messageTypeValues || []);
      if (this.form) {
        this.checkDisabledState();
      }
    }

    if (changes.messages) {
      this.errors = {};
      this.messages.forEach((obj: Message) => {
        const { key: controlName } = obj;
        this.errors[controlName] = { 'server': true };
      });
    }

    if (this.form && changes.filterData) {
      const data = this.generateFormValues();
      this.form.patchValue(data);
      this.form.updateValueAndValidity();
    }
  }

  onRefreshTime(): void {
    const data = this.generateFilterData();
    this.refresh.emit(data);
  }

  onSubmit(): void {
    const data = this.generateFilterData();
    this.filter.emit(data);
  }

  private generateFilterData(): ExternalRrmFilter {
    const { messageStatus, messageType, receiver, startDate, endDate } = this.form.getRawValue();
    let { endTime, startTime } = this.form.getRawValue();

    startTime = moment(startTime, 'HH:mm:ss');
    endTime = moment(endTime, 'HH:mm:ss');
    const start = startDate && moment(startDate).set({
      'hour': startTime.get('hour'),
      'minute': startTime.get('minute'),
      'second': startTime.get('second')
    }).toDate() || null;

    const end = endDate && moment(endDate).set({
      'hour': endTime.get('hour'),
      'minute': endTime.get('minute'),
      'second': endTime.get('second')
    }).toDate() || null;

    return {
      messageStatus,
      messageType,
      receiver,
      end,
      start
    };
  }

  private checkDisabledState(): void {
    const isMessageStatus = this.formData && this.formData.messageStatusValues;
    const receiverValues = this.formData && this.formData.receiverValues;
    const messageTypeValues = this.formData && this.formData.messageTypeValues;
    isMessageStatus ? this.form.get('messageStatus').enable() : this.form.get('messageStatus').disable();
    receiverValues ? this.form.get('messageType').enable() : this.form.get('messageType').disable();
    messageTypeValues ? this.form.get('receiver').enable() : this.form.get('receiver').disable();
  }

  private generateFormValues(): FormValues {
    const { messageStatus, messageType, receiver, start, end } = this.filterData || defaultRrmFilter;
    return {
      messageStatus,
      messageType,
      receiver,
      startDate: start || null,
      startTime: start && moment(start).format('HH:mm:ss') || null,
      endDate: end || null,
      endTime: end && moment(end).format('HH:mm:ss') || null
    };
  }

  private generateOptions(arr: string[] = []): OptionValue[] {
    return [{ label: 'ALL', value: ''}, ...arr.map((item) => ({ label: item, value: item }))];
  }
}
