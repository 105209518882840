import * as moment from 'moment';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Message } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { OrganisationService } from '@common/organisation.service';
import { DatatableState } from '@common/datatable/datatable.model';
import { Column } from '@common/shared/datatable.model';
import { OrganisationModel } from '@common/organisation.model';
import {
  ExternalRrm,
  ExternalRrmForm,
  ExternalRrmFilter,
  dataTableColumns,
  MessageStatusEnum,
  ExternalFormData, ExternalRrmStatus
} from './external-rrm.model';
import { ExternalRrmState } from '../state/external-rrm/external-rrm.reducers';
import * as fromActions from '../state/external-rrm/external-rrm.actions';
import * as fromSelectors from '../state/external-rrm/external-rrm.selectors';
import { ErrorMessageService } from '@common/errors/error-message.service';

@Component({
  selector: 'cms-external-rrm',
  templateUrl: './external-rrm.component.html',
  styleUrls: ['./external-rrm.component.scss']
})
export class ExternalRrmComponent implements OnInit, OnDestroy {
  data$: Observable<DatatableData<ExternalRrm[]>> = this.store.pipe(select(fromSelectors.getData));
  formData$: Observable<ExternalFormData> = this.store.pipe(select(fromSelectors.getFormData));
  status$: Observable<ExternalRrmStatus> = this.store.pipe(select(fromSelectors.getStatus));
  filterValue$: Observable<ExternalRrmFilter> = this.store.pipe(select(fromSelectors.getFilter));
  messages$: Observable<Message[]> = this.store.pipe(select(fromSelectors.getMessages));
  loading$: Observable<boolean> = this.store.pipe(select(fromSelectors.getLoading));
  subscription$: Subscription;
  paging$: Observable<PagingFilter> = this.store.pipe(select(fromSelectors.getPaging));
  sorting$: Observable<SortingFilter> = this.store.pipe(select(fromSelectors.getSorting));
  dataTableColumns: Column[] = dataTableColumns;
  readonly messageStatus = MessageStatusEnum;

  constructor(private readonly organisationService: OrganisationService,
              private readonly store: Store<ExternalRrmState>,
              public readonly errorMessageService:ErrorMessageService) { }

  ngOnInit(): void {
    this.store.dispatch(fromActions.setOrganisationId(this.organisationService.getCachedOrganisationId()));
    this.onOgranisationChangeSubscription();
    this.store.dispatch(fromActions.getPresetValuesAction());
    this.store.dispatch(fromActions.getStatusAction());
    this.store.dispatch(fromActions.loadDefaultDataAction());
  }

  ngOnDestroy(): void {
    this.store.dispatch(fromActions.setToDefaultAction());
    this.subscription$.unsubscribe();
  }

  onHandleResend(id: number): void {
    this.store.dispatch(fromActions.resendAction({ id }));
  }

  onHandleDownload(id: number): void {
    this.store.dispatch(fromActions.downloadDocumentAction(id));
  }

  getDisplayParams(row: ExternalRrm): any {
    return {
      displayValue: `${row.messageId} ${row.receiptTimestamp ?
        ` received at ${moment(row.receiptTimestamp).format('DD/MM/YYYY HH:mm:ss')}` : ''}`,
      id: row.id,
      urlPart: 'rrm',
      dataStoreId: row.dataStoreId
    };
  }

  onLazyLoad({page, perPage, sortField, sortOrder}: DatatableState): void {
    this.store.dispatch(fromActions.sortAndPageDataAction({
      paging: {
        page,
        entriesPerPage: perPage
      },
      sorting: {
        columnName: sortField,
        sortOrder
      }
    }));
  }

  onHandleRefresh(data: ExternalRrmFilter): void {
    this.store.dispatch(fromActions.setFiltersValueAction(data));
    this.store.dispatch(fromActions.loadDefaultDataAction());
  }

  onHandleFilter(data: ExternalRrmFilter): void {
    this.store.dispatch(fromActions.setFiltersValueAction(data));
    this.store.dispatch(fromActions.filterAction(data));
  }

  private onOgranisationChangeSubscription(): void {
    this.subscription$ = this.organisationService
      .getChangeOrganisationObservable()
      .pipe(withLatestFrom(this.store.pipe(select(fromSelectors.getOrganisationId))))
      .subscribe(([organisation, organisationId]: [OrganisationModel, number]) => {
        if ((organisation && organisation.organisationId !== organisationId) || (!organisation && organisationId)) {
          this.store.dispatch(fromActions.setToDefaultAction());
          this.store.dispatch(fromActions.setOrganisationId(organisation ? organisation.organisationId : null));
          this.store.dispatch(fromActions.getPresetValuesAction());
          this.store.dispatch(fromActions.getStatusAction());
          this.store.dispatch(fromActions.loadDefaultDataAction());
        }
      });
  }
}
