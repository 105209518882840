import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { EsmNettingStatementFinderState } from '../../../../state/netting/finder/esm-netting-finder.reducers';
import * as fromMyFiltersSelectors from '../../../../state/netting/finder/my-filter/my-filter.selectors';
import * as fromMyFiltersActions from '../../../../state/netting/finder/my-filter/my-filter.actions';
import { Observable, Subscription } from 'rxjs';
import { EsmNettingStatementUserFilter, getSaveReplaceMyFilter } from '../../esm-netting-finder.model';
import { MyFilter } from '@common/my-filters/my-filters.model';
import * as fromDialogActions from '../../../../state/netting/finder/dialog/dialog.actions';
import * as fromCommon from '@common/state/reducers';
import * as fromDialogSelectors from '../../../../state/netting/finder/dialog/dialog.selectors';
import { NETTING_STATEMENT_DIALOGS } from '../../../../state/netting/finder/dialog/dialog.reducers';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'cms-esm-netting-finder-myfilter',
  templateUrl: './esm-netting-finder-myfilter.component.html',
  styleUrls: ['./esm-netting-finder-myfilter.component.scss']
})
export class EsmNettingFinderMyfilterComponent implements OnChanges, OnDestroy {


    // user filters
    myFilters$ = this.store.pipe(select(fromMyFiltersSelectors.getAllMyFilters));
    defaultMyFilter$ = this.store.pipe(select(fromMyFiltersSelectors.getDefaultFilter));
    allStatementsMyFilter$ = this.store.pipe(select(fromMyFiltersSelectors.getAllDealsFilter));
    dialog$: Observable<fromCommon.DialogState<NETTING_STATEMENT_DIALOGS>> = this.store.pipe(select(fromDialogSelectors.getDialog));
    subscription$: Subscription = new Subscription();

    @Input()
    myForm: UntypedFormGroup;

  constructor(private store: Store<EsmNettingStatementFinderState>) { }

  onSelectMyFilter(myFilter: MyFilter): void {
    this.store.dispatch(fromMyFiltersActions.selectMyFilterAction(myFilter as EsmNettingStatementUserFilter));
  }

  onSetDefaultMyFilter(myFilter: MyFilter): void {
    this.store.dispatch(fromMyFiltersActions.setDefaultFilterAction(myFilter as EsmNettingStatementUserFilter));
  }


  onSaveMyFilter(myFilter: MyFilter): void {
    this.store.dispatch(fromMyFiltersActions.saveMyFiltersAction(getSaveReplaceMyFilter(this.myForm.getRawValue(), myFilter) as EsmNettingStatementUserFilter));
  }

  onReplaceMyFilter(myFilter: MyFilter): void {
    this.store.dispatch(fromMyFiltersActions.replaceMyFiltersAction(getSaveReplaceMyFilter(this.myForm.getRawValue(), myFilter) as EsmNettingStatementUserFilter));
  }


  openSaveMyFilterDialog(): void {
    this.store.dispatch(fromDialogActions.openDialogAction({
      name: 'SAVE_CURRENT'
    }));
  }


  onDeleteMyFilter(filterName: string): void {
    this.store.dispatch(fromMyFiltersActions.deleteMyFilterAction({filterName}));
  }


  onManageMyFilters(): void {
    this.store.dispatch(fromDialogActions.openDialogAction({
      name: 'MANAGE_FILTERS'
    }));
  }

  onCloseMyFiltersDialog(): void {
    this.store.dispatch(fromDialogActions.closeDialogAction());
  }


  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

}
