import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Message } from 'primeng/api';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { BillingTxReportState } from '../../state/billing-tx-report/billing-tx-report.reducers';
import { BillingTxGenerationRequest } from './billing-tx-report.models';
import * as fromSelectors from '../../state/billing-tx-report/billing-tx-report.selectors';
import * as fromActions from '../../state/billing-tx-report/billing-tx-report.actions';

@Component({
  selector: 'cms-billing-tx-report',
  templateUrl: './billing-tx-report.component.html',
  styleUrls: ['./billing-tx-report.component.scss']
})
export class BillingTxReportComponent implements OnInit, OnDestroy {
  messages$: Observable<Message[]>;
  generationWarningVisible: boolean = false;
  noRecordsWarningVisible: boolean = false;
  submitPayload: BillingTxGenerationRequest;

  constructor(
    private store: Store<BillingTxReportState>,
    private mapper: MessagesMapperService
  ) {
  }

  ngOnInit() {
    this.messages$ = this.store.pipe(select(fromSelectors.getMessages));

    this.store.pipe(select(fromSelectors.getSubmitPayload)).subscribe((payload: BillingTxGenerationRequest) => {
      this.submitPayload = payload;
    });

    this.store.pipe(select(fromSelectors.getWarningVisible)).subscribe((value: boolean) => {
      this.generationWarningVisible = value;
    });

    this.store.pipe(select(fromSelectors.getNoResultsVisible)).subscribe((value: boolean) => {
      this.noRecordsWarningVisible = value
    });
  }

  ngOnDestroy() {
    this.store.dispatch(fromActions.setMessagesAction([]));
  }

  cancelReportGeneration() {
    this.store.dispatch(fromActions.hideCountWarning());
    this.store.dispatch(fromActions.setMessagesAction(this.mapper.toSuccessMessages('Generation of ACER Billing Transactions Report has been cancelled.')));
  }

  confirmReportGeneration() {
    this.store.dispatch(fromActions.submitReportGeneration(this.submitPayload));
  }

  confirmNoRecords() {
    this.store.dispatch(fromActions.hideCountWarning());
  }
}
