<div style="max-width: 585px;" class="contract_delivery_profile" *ngIf="bilateral">
  <div class="form-title">Contract | Delivery Profile <span *ngIf="complex">too complex - not editable</span></div>
  <div class="form-body" [formGroup]="formGroup">
    <div formArrayName="deliveryProfiles" *ngIf="!complex">
      <div class="row gutter-0 !me-[40px] bottom-row-border" *ngFor="let profile of profiles; let i = index" [formGroupName]="i">
        <div class="col-5 label">Days of the Week {{i + 1 }}</div>
        <div class="col-7 ps-2 pe-1">
          <input type="text" formControlName="daysOfTheWeek" class="form-control padding-6 text-medium margin-3">
        </div>
        <div class="col-5 label">Start Time {{i + 1 }} <span class="text-required">*</span></div>
        <div class="col-7 ps-2 pe-1">
          <p-inputMask formControlName="startTime" mask="99:99:99" placeholder="hh:mm:ss" slotChar="hh:mm:ss" [styleClass]="'text-small padding-6 margin-3 text-center ' + appendValidationErrorClassOfArray(i, 'startTime' )" [style]="{ 'width': '75px'}"></p-inputMask>
          <span *ngIf=" appendValidationErrorClassOfArray(i, 'startTime')" class="fa fa-info-circle absolute error-marker"></span>
        </div>
        <div class="col-5 label">End Time {{i + 1 }} <span class="text-required">*</span></div>
        <div class="col-7 ps-2 pe-1">
          <p-inputMask formControlName="endTime" mask="99:99:99" placeholder="hh:mm:ss" slotChar="hh:mm:ss" [styleClass]="'text-small padding-6 margin-3 text-center ' + appendValidationErrorClassOfArray(i, 'endTime' )" [style]="{ 'width': '75px'}"></p-inputMask>
          <span *ngIf=" appendValidationErrorClassOfArray(i, 'endTime')" class="fa fa-info-circle absolute error-marker"></span>
        </div>
      </div>
      <div class="form-body">
        <div class="row gutter-0 !me-[40px]">
          <div class="col-5 label"></div>
          <div class="col-7 ps-2 pe-1">
            <button type="button" class="btn add-remove-line" (click)="add(null)" [disabled]="disableAddButton">Add</button>
            <button type="button" class="btn add-remove-line" (click)="remove()" [disabled]="disableRemoveButton">Remove</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
