import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { RemitTradeEntryTable1Service } from '../remit-trade-entry-table1.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SelectItem } from 'primeng/api';
import { RemitTradeEntryTable1OtherCounterpartyModel } from '../remit-trade-entry-table1-data.model';

@Component({
  selector: 'cms-remit-trade-entry-table1-other-counterparty',
  templateUrl: './remit-trade-entry-table1-other-counterparty.component.html',
  styleUrls: ['./remit-trade-entry-table1-other-counterparty.component.scss']
})
export class RemitTradeEntryTable1OtherCounterpartyComponent implements OnInit, OnDestroy {

  @Input()
  formGroup: UntypedFormGroup;

  private onDestroy$ = new Subject();

  otherMarketParticipants: SelectItem[];
  otherBeneficiaryType: SelectItem[];

  otherCounterpartyData: RemitTradeEntryTable1OtherCounterpartyModel;

  reportBoth: boolean = false;
  execution: boolean = false;

  constructor(private tradeEntryService: RemitTradeEntryTable1Service) {
  }

  ngOnInit() {
    this.formGroup.addControl('reportBothSides', new UntypedFormControl(''));
    this.formGroup.addControl('otherMarketParticipantCodeType', new UntypedFormControl('ACE'));
    this.formGroup.addControl('otherMarketParticipantCode', new UntypedFormControl(''));
    this.formGroup.addControl('traderIdForOtherMarketParticipant', new UntypedFormControl(''));
    this.formGroup.addControl('beneficiaryCodeTypeForOtherMarketParticipant', new UntypedFormControl('ACE'));
    this.formGroup.addControl('beneficiaryCodeForOtherMarketParticipant', new UntypedFormControl(''));

    this.tradeEntryService.onTradeEntryDataChange().pipe(takeUntil(this.onDestroy$)).subscribe(tradeEntryData => {
      if (tradeEntryData && tradeEntryData.otherCounterparty) {
        this.otherCounterpartyData = tradeEntryData.otherCounterparty;
        this.initOtherMarketParticipants();
        this.initOtherBeneficiaryType();
      }
    });

    this.formGroup.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(
      formValues => {
        if (this.execution !== formValues.execution) {
          this.execution = formValues.execution;
          if (this.execution && this.reportBoth) {
            this.formGroup.controls['traderIdForOtherMarketParticipant'].clearValidators();
            this.formGroup.controls['traderIdForOtherMarketParticipant'].updateValueAndValidity();
          } else if (!this.execution && this.reportBoth) {
            this.formGroup.controls['traderIdForOtherMarketParticipant'].setValidators(Validators.required);
            this.formGroup.controls['traderIdForOtherMarketParticipant'].updateValueAndValidity();
          }
        }
      }
    );

    this.tradeEntryService.onSubmitted().pipe(takeUntil(this.onDestroy$)).subscribe(
      response => {
        if (response) {
          this.onReportBoth({ target: { checked: false } });
        }
      }
    );

    this.tradeEntryService.onReset().pipe(takeUntil(this.onDestroy$)).subscribe(
      response => {
        if (response) {
          this.onReportBoth({ target: { checked: false } });
        }
      }
    );
  }

  private initOtherMarketParticipants() {
    if (this.otherCounterpartyData) {
      this.otherMarketParticipants = this.otherCounterpartyData.otherMarketParticipants.map(item => (
        { label: item.value, value: item.key }
      ));
    }
  }

  private initOtherBeneficiaryType() {
    if (this.otherCounterpartyData) {
      this.otherBeneficiaryType = this.otherCounterpartyData.otherBeneficiaryType.map(item => (
        { label: item.key, value: item.key }
      ));
    }
  }

  onReportBoth($event) {
    this.reportBoth = $event.target.checked;

    // TODO PB once both was checked he saves the otherMarketCode (and Type) for 'bothIsChecked-scenario'
    this.formGroup.controls['otherMarketParticipantCode'].setValue('');

    if (this.reportBoth) {
      this.formGroup.controls['otherMarketParticipantCodeType'].setValue('ACE');
    } else {
      this.formGroup.controls['traderIdForOtherMarketParticipant'].setValue('');
      this.formGroup.controls['beneficiaryCodeForOtherMarketParticipant'].setValue('');
    }
  }

  getValidationErrorStyleClass(fieldName: string): string {
    if (this.formGroup.controls[fieldName].hasError('error')) {
      return 'error';
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

}
