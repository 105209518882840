import { createAction, union } from '@ngrx/store';
import { Message } from 'primeng/api';
import { GetStatesResponseValue, MonitoringCategories, MonitoringDataTableItem, MonitoringLogs } from '../../monitoring/monitoring.model';
import { Column } from '@common/shared/datatable.model';

export const setToDefaultAction = createAction('[ECM MONITORING] Set to Default');
export const setMessagesAction = createAction('[ECM MONITORING] Set Messages', (payload: Message[]) => ({ payload }));
export const getStatesAction = createAction('[ECM MONITORING] Get States', (payload?: string) => ({payload}));
export const getStatesSuccessAction = createAction('[ECM MONITORING] Get States Success', (payload: GetStatesResponseValue) => ({payload}));
export const createSnapshotAction = createAction('[ECM MONITORING] Create Snapshot');
export const createSnapshotLoadingOffAction = createAction('[ECM MONITORING] Create Snapshot Loading Off'); // change to createSnapshotSuccess action after BE fixing
export const getLogsAction = createAction('[ECM MONITORING] Get Logs');
export const getLogsSuccessAction = createAction('[ECM MONITORING] Get Logs Success', (payload: MonitoringLogs[]) => ({payload}));
export const doneCheckingAction = createAction('[ECM MONITORING] Done Checking',
  (payload: {category: string, snapshotId: number}) => ({payload}));
export const setColumnsAction = createAction('[ECM MONITORING] Set Columns', (payload: Column[]) => ({payload}));
export const loadDataAction = createAction('[ECM MONITORING] Load Data', (payload: { category: MonitoringCategories }) => ({payload}));
export const loadDataSuccessAction = createAction('[ECM MONITORING] Load Data Success',
  (payload: MonitoringDataTableItem[]) => ({payload}));

const actions = union({
  setToDefaultAction,
  setMessagesAction,
  getStatesAction,
  getStatesSuccessAction,
  createSnapshotAction,
  createSnapshotLoadingOffAction,
  getLogsAction,
  getLogsSuccessAction,
  doneCheckingAction,
  loadDataSuccessAction,
  setColumnsAction,
  loadDataAction
});

export type MonitoringActions = typeof actions;
