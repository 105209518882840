<p-messages [(value)]="vMessages" class="width-full" [closable]="false"  [showTransitionOptions]="'500ms'" [hideTransitionOptions]="'200ms'"></p-messages>

<div class="container-fluid row justify-content-center">
  <div class="col-12">
    <div class="margin-top-60">
      <div class="login-box" *ngIf="getShowLogin()">
        <img class="margin-bottom-30" src="assets/img/equias_logo.png" />
        <form [formGroup]="loginForm" (ngSubmit)="onLogin()" class="login-form">
          <div class="login-spinner-bg" *ngIf="loading">
            <p-progressSpinner [style]="{width: '25px', height: '25px'}" strokeWidth="5"></p-progressSpinner>
          </div>
          <p-messages [(value)]="messages" class="block" [closable]="false"  [showTransitionOptions]="'500ms'" [hideTransitionOptions]="'200ms'"></p-messages>
          <div class="col-12 info-panel margin-bottom-30" id="info-password" *ngIf="showSuccessMessage()">
            <div class="fa fa-info-circle float-left"></div>
            <div class="margin-left-18" [innerHTML]="successMsg">
            </div>
          </div>
          <label class="col-12 margin-bottom-3" for="username">Username:<span class="text-required">*</span></label>
          <div class="col-12 margin-bottom-15">
            <input formControlName="username" type="text" id="username" tabindex="1" class="form-control" [ngClass]="{'error': isAccessDenied() || !usernameValid, 'warn': hasWarning()}" placeholder="username" style="font-size: 13px;">
          </div>
          <label class="col-12 margin-bottom-3" for="password">Password:<span class="text-required">*</span></label>
          <div class="col-12 margin-bottom-15">
            <input formControlName="password" type="password" id="password" tabindex="2" class="form-control" [ngClass]="{'error': isAccessDenied() || !passwordValid, 'warn': hasWarning()}" placeholder="password" style="font-size: 13px;" maxlength="16">
          </div>
          <div *ngIf="activateToken">
            <label class="col-12 margin-bottom-3" for="token">Token:<span class="text-required">*</span></label>
            <div class="col-12 margin-bottom-15">
              <input formControlName="token" type="text" id="token" tabindex="3" class="form-control" [ngClass]="{'error': isAccessDenied() || !tokenValid, 'warn': hasWarning()}" placeholder="token" style="font-size: 13px;">
            </div>
          </div>
          <div class="col-12 margin-bottom-15 text-center">
          </div>
          <div class="col-12 clearfix margin-bottom-15">
            <button type="submit" id="btn-login" tabindex="4" class="btn full-width" value="Login" [disabled]="loading">Login</button>
          </div>
          <div class="col-12 clearfix text-center" *ngIf="!activateToken">
            <a [routerLink]="['/forgot_password']" tabindex="5">forgot password?</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
