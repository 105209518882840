<ng-container *ngIf="!edit || !editable">
  <cms-user-states [state]="state"></cms-user-states>
</ng-container>
<ng-container *ngIf="edit">
  <form class="row col-12 padding-0 margin-0" [formGroup]="form">
    <div class="col-md-12 col-12 margin-0 padding-0 text-start">
      <p-dropdown class="col-12 padding-0 margin-0 editable-status"
                  formControlName="value"
                  appendTo="body"
                  [options]="statesOptions"
                  [baseZIndex]="10000"
                  [autoZIndex]="false"
                  [autofocus]="true"
                  [style]="{height: '20px', width: '100%', border: 'none', borderRadius: '0px', overflow: 'hidden'}"
                  (onChange)="onChange()">
        <ng-template let-item  pTemplate="selectedItem">
          <cms-user-states [state]="item.value"></cms-user-states>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <cms-user-states [state]="item.value"></cms-user-states>
        </ng-template>
      </p-dropdown>
    </div>
  </form>
</ng-container>
