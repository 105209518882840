import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Event, Router } from '@angular/router';
import { merge, Observable, of, Subject, Subscription } from 'rxjs';
import { OrganisationService } from '@common/organisation.service';
import { RemitTransactionsService } from '../../datatable/remit-transactions.service';
import { RemitTransactionSearchMode } from '../../datatable/remit-transactions.model';
import { StringUtils } from '@common/shared/string.utils';
import { RemitDocumentType } from '../../dashboard/filter/remit-dashboard-filter-model';
import { RemitSearchModel } from './remit-transactions-search.model';
import { StateService } from '@common/state.service';

@Component({
  selector: 'cms-remit-transactions-search',
  templateUrl: './remit-transactions-search.component.html',
  styleUrls: ['./remit-transactions-search.component.scss']
})
export class RemitTransactionsSearchComponent implements OnInit, OnDestroy {
  @Input() documentType: RemitDocumentType;
  completeForm: RemitSearchModel;
  subscription$: Subscription;
  utiSearchForm: UntypedFormGroup;
  transactionIdForm: UntypedFormGroup;
  contractIdForm: UntypedFormGroup;
  uniqueIdForm: UntypedFormGroup;
  referenceIdForm: UntypedFormGroup;
  filenameForm: UntypedFormGroup;
  loading: Observable<boolean>;
  reset: Observable<boolean> = of(false);
  private onDestroy$ = new Subject();

  constructor(private remitTransactionsService: RemitTransactionsService,
              private formBuilder: UntypedFormBuilder,
              private router: Router, private route: ActivatedRoute,
              private organisationService: OrganisationService,
              private stateService: StateService) {

    this.utiSearchForm = this.formBuilder.group({ uti: ['', this.idsValidator()] });
    this.transactionIdForm = this.formBuilder.group({ transactionId: ['', this.idsValidator()] });
    this.contractIdForm = this.formBuilder.group({ contractId: ['', this.idsValidator()] });
    this.uniqueIdForm = this.formBuilder.group({ uniqueId: ['', this.idsValidator()] });
    this.referenceIdForm = this.formBuilder.group({ referenceId: ['', this.idsValidator()] });
    this.filenameForm = this.formBuilder.group({ filename: ['', this.filenameValidator()] });
  }

  ngOnInit() {
    this.loading = this.remitTransactionsService.getLoading();

    this.subscription$ = this.organisationService
      .getChangeOrganisationObservable()
      .subscribe(() => this.setReset());

    this.completeForm = this.stateService.getRemitTransactionSearchMask(this.documentType);
    this.populateForm();
    this.subscription$.add(merge(
      this.utiSearchForm.valueChanges,
      this.transactionIdForm.valueChanges,
      this.contractIdForm.valueChanges,
      this.uniqueIdForm.valueChanges,
      this.referenceIdForm.valueChanges,
      this.filenameForm.valueChanges)
      .subscribe((formChanges) => this.setRemitSearchFilterModel(formChanges, this.documentType)));

    if (this.documentType === 'REMIT_TABLE_1') {
      this.handleTransactionIdExcecution();
    }

  }

  setReset() {
    if(this.filenameForm.touched){this.filenameForm.reset();}
    this.utiSearchForm.reset();
    this.transactionIdForm.reset();
    this.contractIdForm.reset();
    this.uniqueIdForm.reset();
    this.referenceIdForm.reset();
  }

  searchByUti(event: Event) {
    const searchMode: RemitTransactionSearchMode = 'SEARCH_UTI';
    if (this.utiSearchForm.valid) {
      const searchQuery = {
        searchMode,
        remitDocumentType: this.documentType,
        ids: StringUtils.tokenizeByWhitespace(this.utiSearchForm.controls['uti'].value)
      };
      this.remitTransactionsService.updateSearchQuery(this.documentType, searchQuery);
    }
  }

  searchByTransactionId(event: Event) {
    const searchMode: RemitTransactionSearchMode = 'SEARCH_TRANSACTION_ID';
    if (this.transactionIdForm.valid) {
      const searchQuery = {
        searchMode,
        remitDocumentType: this.documentType,
        ids: StringUtils.tokenizeByWhitespace(this.transactionIdForm.controls['transactionId'].value)
      };
      this.remitTransactionsService.updateSearchQuery(this.documentType, searchQuery);
    }
  }

  searchByContractId(event: Event) {
    const searchMode: RemitTransactionSearchMode = 'SEARCH_CONTRACT_ID';
    if (this.contractIdForm.valid) {
      const searchQuery = {
        searchMode,
        remitDocumentType: this.documentType,
        ids: StringUtils.tokenizeByWhitespace(this.contractIdForm.controls['contractId'].value)
      };
      this.remitTransactionsService.updateSearchQuery(this.documentType, searchQuery);
    }
  }

  searchByUniqueId(event: Event) {
    const searchMode: RemitTransactionSearchMode = 'SEARCH_UNIQUE_ID';
    if (this.uniqueIdForm.valid) {
      const searchQuery = {
        searchMode,
        remitDocumentType: this.documentType,
        ids: StringUtils.tokenizeByWhitespace(this.uniqueIdForm.controls['uniqueId'].value)
      };
      this.remitTransactionsService.updateSearchQuery(this.documentType, searchQuery);
    }
  }

  searchByReferenceId(event: Event) {
    const searchMode: RemitTransactionSearchMode = 'SEARCH_REFERENCE_ID';
    if (this.referenceIdForm.valid) {
      const searchQuery = {
        searchMode,
        remitDocumentType: this.documentType,
        ids: StringUtils.tokenizeByWhitespace(this.referenceIdForm.controls['referenceId'].value)
      };
      this.remitTransactionsService.updateSearchQuery(this.documentType, searchQuery);
    }
  }

  searchByFilename(event: Event) {
    const searchMode: RemitTransactionSearchMode = 'SEARCH_FILENAME';
    if (this.filenameForm.valid) {
      const searchQuery = {
        searchMode,
        remitDocumentType: this.documentType,
        filename: this.filenameForm.controls['filename'].value
      };
      this.remitTransactionsService.updateSearchQuery(this.documentType, searchQuery);
    }
  }

  getReset(): Observable<boolean> {
    return this.reset;
  }

  handleTransactionIdExcecution() {
    if (this.route.snapshot.queryParams?.transactionId) {
      this.transactionIdForm.controls.transactionId.setValue(this.route.snapshot.queryParams.transactionId);
      this.searchByTransactionId(null);
    }
  }

  // error msg stuff
  private idsValidator(): ValidatorFn {

    const ruleConformValidator: ValidatorFn = (control: AbstractControl): { [key: string]: any } => {
      const ids = StringUtils.tokenizeByWhitespace(control.value);
      let areIdsNotRuleConform: boolean;
      let partialReason: string;
      if (ids[0] === '') {
        areIdsNotRuleConform = true;
        partialReason = 'does not allow only whitespace as value';
      } else {
        const hasValidAmount = ids.length > 50;
        if (hasValidAmount) {
          areIdsNotRuleConform = true;
          partialReason = 'has exceeded the maximum number of 50 IDs';
        }
        if (!ids.every(id => id.length <= 100)) {
          if (hasValidAmount) {
            partialReason += ' and the maximum length of 100 characters per id';
          } else {
            areIdsNotRuleConform = true;
            partialReason = 'has exceeded the maximum length of 100 characters per id';
          }
        }
      }
      return areIdsNotRuleConform ? { idsNotRuleConform: { value: control.value, reason: partialReason } } : null;
    };

    return Validators.compose([Validators.required, ruleConformValidator]);
  }

  private filenameValidator(): ValidatorFn {

    const ruleConformValidator: ValidatorFn = (control: AbstractControl): { [key: string]: any } => {
      const filenameNotRuleConform = control.value && control.value.length > 255;
      return filenameNotRuleConform ? { 'filenameNotRuleConform': { value: control.value } } : null;
    };

    return Validators.compose([
      Validators.required,
      ruleConformValidator
    ]);
  }

  // Field 'UTI / Order ID' has exceeded the maximum number of 50 IDs
  isUtiRuleConformError() {
    const errors = this.utiSearchForm.controls['uti'].errors;
    return errors && errors['idsNotRuleConform'];
  }

  // Field 'Linked Transaction ID' has exceeded the maximum number of 50 IDs.
  isTransactionIdsRuleConformError() {
    const errors = this.transactionIdForm.controls['transactionId'].errors;
    return errors && errors['idsNotRuleConform'];
  }

  // Field 'Unique ID' has exceeded the maximum number of 50 IDs.
  isUniqueIdsRuleConformError() {
    const errors = this.uniqueIdForm.controls['uniqueId'].errors;
    return errors && errors['idsNotRuleConform'];
  }

  // Field 'Unique ID' has exceeded the maximum number of 50 IDs.
  isContractIdsRuleConformError() {
    const errors = this.contractIdForm.controls['contractId'].errors;
    return errors && errors['idsNotRuleConform'];
  }

  // Field 'Reference ID' has exceeded the maximum number of 50 IDs.
  isReferenceIdsRuleConformError() {
    const errors = this.referenceIdForm.controls['referenceId'].errors;
    return errors && errors['idsNotRuleConform'];
  }

  isUtiSearchFieldVisible(): boolean {
    return this.documentType === 'REMIT_TABLE_1';
  }

  isTransactionIdSearchFieldVisible(): boolean {
    return this.documentType === 'REMIT_TABLE_1' || this.documentType === 'GAS_CAPACITY';
  }

  isContractIdSearchFieldVisible(): boolean {
    return this.documentType === 'REMIT_TABLE_2';
  }

  isUniqueIdVisible(): boolean {
    return this.documentType === 'ELECTRICITY_RIGHTS';
  }

  isReferenceIdVisible(): boolean {
    return this.documentType === 'FUNDAMENTALS';
  }

  get transactionIdLabel(): string {
    let label: string = '';
    if (this.isTransactionIdSearchFieldVisible()) {
      switch (this.documentType) {
        case 'REMIT_TABLE_1':
          label = 'Linked Transaction ID';
          break;
        case 'GAS_CAPACITY':
          label = 'Transaction ID';
          break;
      }
    }
    return label;
  }

  populateForm(){
    this.utiSearchForm.controls['uti'].setValue(this.completeForm.utiSearchForm);
    this.transactionIdForm.controls['transactionId'].setValue(this.completeForm.transactionIdForm);
    this.contractIdForm.controls['contractId'].setValue(this.completeForm.contractIdForm);
    this.filenameForm.controls['filename'].setValue(this.completeForm.filenameForm);
    this.uniqueIdForm.controls['uniqueId'].setValue(this.completeForm.uniqueIdForm);
  }

  setRemitSearchFilterModel(searchForm, documentType) {

    switch (Object.entries(searchForm)[0][0]) {
      case 'uti': {
        this.completeForm.utiSearchForm = searchForm['uti'];
        break;
      }
      case 'transactionId': {
        this.completeForm.transactionIdForm = searchForm['transactionId'];
        break;
      }
      case 'contractId': {
        this.completeForm.contractIdForm = searchForm['contractId'];
        break;
      }
      case 'filename': {
        this.completeForm.filenameForm = searchForm['filename'];
        break;
      }
      case 'uniqueId': {
        this.completeForm.uniqueIdForm = searchForm['uniqueId'];
        break;
      }
    }
    this.stateService.setRemitTransactionSearchMask(this.completeForm, documentType);
  }


  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
    this.subscription$.unsubscribe();
  }
}