<cms-headline [pageTitle]="'Broker Restrictions for'"
              [displayOrganisationNameWithoutSeparator]="true"
              [displayOrganisationEicCode]="true"></cms-headline>

<div class="">
  <cms-info ngPreserveWhitespaces>

    __Broker Restrictions (Trading companies only) - MO__

    An organisation can upload an XML broker restriction file in this screen. This file allows an organisation to choose which brokers to match confirmations with in CMS and reduce dashboard noise by restricting unwanted broker confirmations from appearing in their dashboard. Brokers who allege broker confirmations to trading organisations with a broker restriction in place will be rejected from CMS.

    An example Broker Restriction file is available on request from the CMS Service Team.

    __Instructions__

    To upload a file, click on the ‘Browse File’ button and select the broker restrictions file to be uploaded.

    Once the file is selected, the file name will appear in the text box and the “Upload” button will be highlighted. Uploading the file will apply the desired broker restrictions in CMS.

  </cms-info>
  <p-messages [value]="messages$ | async" [closable]="true"></p-messages>
  <cms-upload-panel *ngIf="allowedToUpload$ | async"
                    [title]="'Upload broker restrictions file:'"
                    [maxFileSize]="maxFileSize$ | async"
                    (upload)="onUpload($event)"
                    (error)="onUploadError($event)"></cms-upload-panel>

  <div class="margin-bottom-12 decent-max-width panel-box">
    <ng-container *ngIf="(data$| async)?.values?.length > 0; else disableDownload">
      <label>Current settings: <span class="text-success">restrictions enabled</span> -
        broker matching applies only if one of the criteria is met</label>
      <button class="btn margin-top-3 margin-bottom-3 margin-left-12"
              type="button" (click)="onDownloadXML()">
        Download
      </button>
    </ng-container>
    <ng-template #disableDownload>
      <label>Current settings: <span class="text-warning">no restrictions enabled</span> - all brokered deals submitted for broker matching</label>
    </ng-template>
  </div>
  <div class="decent-max-width">
    <cms-datatable *ngIf="(data$ | async) as data"
                   [tableColumns]="dataTableColumns"
                   [paginator]="false"
                   [data]="(data$ |async).values"
                   [loading]="loading$ | async"
                   [tooltipDisabled]="true"
    ></cms-datatable>
  </div>
</div>

<cms-upload-ecm-broker-dialog [display]="dialog$ | async"
                              [organisation]="organisation"
                              (cancel)="onCancelUpload()"
                              (confirm)="onConfirmUpload()"></cms-upload-ecm-broker-dialog>
