import { createSelector } from '@ngrx/store';
import { getProblemSolvingState, State } from '../reducers';
import { SendingFailuresState } from './sending-failures.reducers';
import { SendingFailuresSelected } from '../../sending-failures/sending-failures.model';
import * as fromReducer from './sending-failures.reducers';

export const getSendingFailuresState = createSelector(getProblemSolvingState, (state: State) => state.sendingFailures);
export const getOrganisationId = createSelector(getSendingFailuresState, (state: SendingFailuresState) => state.organisationId);
export const getInitializing = createSelector(getSendingFailuresState, fromReducer.getInitializing);
export const getMessages = createSelector(getSendingFailuresState, fromReducer.getMessages);
export const getFilters = createSelector(getSendingFailuresState, fromReducer.getFilters);
export const getData = createSelector(getSendingFailuresState, fromReducer.getData);
export const getSelectedItems = createSelector(getSendingFailuresState, fromReducer.getSelectedItems);
export const getLoadingTable = createSelector(getSendingFailuresState, fromReducer.getLoadingTable);
export const getDialog = createSelector(getSendingFailuresState, fromReducer.getDialog);

export const getIsSelected = createSelector(getSelectedItems,
  (value: SendingFailuresSelected): boolean => !(Object.keys(value) && Object.keys(value).length));
