<p-dialog *ngIf="display"
          header="Edit Selected Organisation"
          [resizable]="false"
          [visible]="display"
          [modal]="true"
          (visibleChange)="onVisibleChange($event)"
          styleClass="edit-user-dialog"
          [style]="{width: '650px', minWidth: '500px'}">
  <p-messages [value]="messages" [closable]="true"></p-messages>
  <div class="margin-15">
    <form [formGroup]="form" class="row" autocomplete="off">
      <div class="col-12 margin-bottom-12 flex">
        <label for="eicCode" class="col-4 float-left">EIC Code:<span class="required">*</span></label>
        <div class="col-8">
          <input cmsUpperCase autocomplete="off" tabindex="1" id="eicCode" type="text" formControlName="eicCode" class="ng-trim-ignore form-control col-8 padding-6" [ngClass]="{'error': errors['eicCode']}" maxlength="16" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label for="displayName" class="col-4 float-left">Display Name:<span class="required">*</span></label>
        <div class="col-8">
        <input cmsUpperCase autocomplete="off" tabindex="2" id="displayName" type="text" formControlName="displayName" class="ng-trim-ignore form-control col-8 padding-6" [ngClass]="{'error': errors['displayName']}" maxlength="20" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label for="legalName" class="col-4 float-left">Legal Name:<span class="required">*</span></label>
        <div class="col-8">
          <input autocomplete="off" tabindex="3" id="legalName" type="text" formControlName="legalName" class="form-control col-8 padding-6" [ngClass]="{'error': errors['legalName']}" maxlength="50" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label for="businessContact" class="col-4 float-left">Business Contact:</label>
        <div class="col-8">
          <textarea autocomplete="off" tabindex="4" col="3" id="businessContact" formControlName="businessContact" class="form-control col-8 padding-6" style="height: 4em;"></textarea>
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label for="technicalContact" class="col-4 float-left">Technical Contact:</label>
        <div class="col-8">
          <textarea autocomplete="off" tabindex="5" col="3" id="technicalContact" formControlName="technicalContact" class="form-control col-8 padding-6" style="height: 4em;"></textarea>
        </div>
      </div>
    </form>
  </div>
  <p-footer>
    <button class="btn margin-right-3" type="button" (click)="onCancel()">Cancel</button>
    <button class="btn" type="button" (click)="onConfirm()">Update</button>
  </p-footer>
</p-dialog>
