import * as moment from 'moment';
import { ErrorResponse } from '@common/error-response.model';
import { Column } from '@common/shared/datatable.model';

export interface ErrSendingFailuresProcesses extends ErrorResponse{
  values: string[];
}

export interface ErrSendingFailuresPresetModel extends ErrorResponse{
  processNames: string[];
  from: Date | string;
  to: Date | string;
}

export interface ErrSendingFailuresSearchRequest {
  end: Date | string;
  start: Date | string;
  processName: string;
}

export interface ErrSendingFailuresResponse extends ErrorResponse {
  values: ErrSendingFailuresValues[];
}

export interface ErrSendingFailuresValues {
  processName: string;
  state: string;
  errorDetails: string;
  count: number;
}

export interface ErrSendingFailuresResendResponse extends ErrorResponse {
  value: {
    failureMessages: string[],
    successMessage: string
  };
}

export interface ErrSendingFailuresSelected {
  [rowIndex: number]: ErrSendingFailuresValues;
}

export const defaultStyles = {
  'text-align': 'left',
  'text-overflow': 'ellipsis',
  'overflow': 'hidden',
  'white-space': 'nowrap'
};

export const allColumnsConfig: Column[] = [
  { name: 'processName', type: 'text', title: 'Process', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'state', type: 'text', title: 'Error State', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'count', type: 'text', title: 'Count', style: { ...defaultStyles, 'text-align': 'right' }, sortable: true, visible: true },
  { name: 'errorDetails', type: 'button', title: 'Error Details', style: { ...defaultStyles, 'text-align': 'center' }, sortable: false, visible: true },
  { name: 'select', type: 'check', title: 'Select', style: { ...defaultStyles, 'text-align': 'center' }, sortable: false, visible: true }
];

export function getDatesWithoutTimezone(startDate, endDate): { start: string, end: string } {
  const formattedFromDate = moment(startDate).format('YYYY-MM-DDTHH:mm:ss.SSS');
  const formattedToDate = moment(endDate).format('YYYY-MM-DDTHH:mm:ss.SSS');

  return { start: formattedFromDate, end: formattedToDate };
}
