import { Injectable } from '@angular/core';
import { ApiRequestService } from '@common/api-request.service';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { RoboUpdatePermissionModel } from './permissions.component';

@Injectable({
  providedIn: 'root'
})
export class RoboPermissionsService {

  private apiUrl = '/api/err/reporting/permissions';

  permissionEntries: BehaviorSubject<any[]> = new BehaviorSubject([]);
  private permissionChangePanelVisible = new BehaviorSubject<boolean>(false);

  constructor(private apiRequestService: ApiRequestService) {
  }

  loadPresetValues() {
    this.apiRequestService.get(this.apiUrl + '/loadPresetValues').pipe(
      map((response: any) => response.value)).subscribe(value => {
      this.permissionChangePanelVisible.next(value.permissionChangePanelVisible);
    });
  }

  onPresetValuesChanged(): Observable<any>{
    return  this.permissionChangePanelVisible.asObservable();
  }

  loadPermissionEntries() {
    this.apiRequestService.get(this.apiUrl).subscribe(value => {
      if (value) {
        this.permissionEntries.next(value.entries);
      } else {
        this.permissionEntries.next([]);
      }
    });
   }

  setPermissions(permission: RoboUpdatePermissionModel): Promise<any> {
    if(permission){
      return this.apiRequestService.put(this.apiUrl, permission).toPromise();
    }else {
      return of(null).toPromise();
    }
  }

   onPermissionEntriesChanged(): Observable<any[]>{
    return this.permissionEntries.asObservable();
   }
}
