<div class="panel-box">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="row container padding-0 margin-0">
      <!-- col 1 -->
      <div class="col-xl-3 col-lg-6 col-md-6 col-12 first-col-container">
        <div *ngIf="isT1Selected()">
          <label class="col-12 margin-0 text-medium">OMP</label>
          <p-multiSelect [options]="omps"
                         [style]="{'width': '100%'}"
                         [filter]="false"
                         selectedItemsLabel="All ({0})"
                         formControlName="omps">
          </p-multiSelect>
        </div>
        <div *ngIf="isT1Selected()">
          <label class="col-12 margin-0 text-medium">MP</label>
          <p-multiSelect [options]="mps"
                         [style]="{'width': '100%'}"
                         [filter]="false"
                         selectedItemsLabel="All ({0})"
                         formControlName="mps">
          </p-multiSelect>
        </div>
        <div *ngIf="isDocumentTypeVisible()">
          <label class="col-12 margin-0 text-medium">Document Type</label>
          <p-multiSelect [options]="documentTypes"
                         [style]="{'width': '100%'}"
                         [filter]="false"
                         formControlName="documentTypes"
                         selectedItemsLabel="All ({0})"
                         [maxSelectedLabels]="1">
          </p-multiSelect>
        </div>
        <div>
          <label class="col-12 margin-0 text-medium">State</label>

          <p-multiSelect [options]="states"
                         [style]="{'width': '100%'}"
                         [filter]="false"
                         formControlName="states"
                         selectedItemsLabel="All ({0})"
                         [maxSelectedLabels]="getMaxSelectedStates()">
          </p-multiSelect>
        </div>
      </div>

      <!-- col 2 -->
      <div class="col-xl-3 col-lg-6 col-md-6 col-12" *ngIf="!isDocumentTypeFundamentals()">
        <div *ngIf="isCommodityVisible()">
          <label class="col-12 margin-0 text-medium">Commodity</label>
          <p-dropdown [options]="commodities"
                      [style]="{'width': '100%', 'margin-top': '0px'}"
                      formControlName="commodity"></p-dropdown>
        </div>
        <div *ngIf="isT1Selected()">
          <label class="col-12 margin-0 text-medium">Record Type</label>
          <p-dropdown [options]="recordTypes"
                      [style]="{'width': '100%', 'margin-top': '0px'}"
                      formControlName="recordType"></p-dropdown>
        </div>
        <div>
          <label class="col-12 margin-0 text-medium">{{ actionTypeLabel }}</label>
          <p-multiSelect [options]="actionTypes"
                         [style]="{'width': '100%'}"
                         [filter]="false"
                         selectedItemsLabel="All ({0})"
                         formControlName="actionTypes"
                         [maxSelectedLabels]="getMaxSelectedStates()"></p-multiSelect>
        </div>
      </div>

      <!-- col 3 -->
      <div class="col-xl-2 col-lg-6 col-md-6 col-12" *ngIf="isT1Selected() || isPriceVisible()">

        <div *ngIf="isT1Selected()">
          <label class="col-12 margin-0 text-medium">Quantity</label>
          <input type="text" cmsDigitOnly class="form-control p-component padding-6 text-medium" formControlName="quantity" />
        </div>

        <div *ngIf="isPriceVisible()">
          <label class="col-12 margin-0 text-medium">Price</label>
          <input type="text" cmsDigitOnly class="form-control p-component padding-6 text-medium" formControlName="price" />
        </div>

        <div *ngIf="isT1Selected()">
          <label class="col-12 margin-0 text-medium">Sender Name</label>
          <p-multiSelect [options]="senderNames"
                         [style]="{'width': '100%'}"
                         [filter]="false"
                         selectedItemsLabel="All ({0})"
                         formControlName="senderNames">

          </p-multiSelect>
        </div>

      </div>
      <!-- dates col -->
      <div class="col-xl-4 col-lg-6 col-md-6 col-12 dates-container">
        <div class="row position-relative" *ngIf="isT1Selected()">
          <label class="col-12 margin-0 text-medium">Transaction Time (UTC)</label>
          <span class="inline-block col-5 ps-0">
              <p-calendar
                placeholder="From Date"
                formControlName="transactionTimeStart"
                [maxDate]="todayMaxDate"
                [showIcon]="true"
                [monthNavigator]="true"
                [yearNavigator]="true"
                dateFormat="dd/mm/yy"
                cmsDateRestriction>
              </p-calendar>
            </span>
          <span class="inline-block col-5 ps-0">
              <p-calendar
                placeholder="To Date"
                formControlName="transactionTimeEnd"
                [maxDate]="todayMaxDate"
                [showIcon]="true"
                [monthNavigator]="true"
                [yearNavigator]="true"
                dateFormat="dd/mm/yy"
                cmsDateRestriction>
              </p-calendar>
              <span *ngIf="isTransactionTimeRuleConformError()" class="fa fa-info-circle absolute error-marker">
              </span>
            </span>
          <span class="col-auto">
            <cms-additional-date-range [form]="form"
                                       [startField]="'transactionTimeStart'"
                                       [endField]="'transactionTimeEnd'">
            </cms-additional-date-range>
          </span>

        </div>

        <div class="row position-relative" *ngIf="isT2Selected()">
          <label class="col-12 margin-0 text-medium">Contract Date</label>
          <span class="inline-block col-5 ps-0">
              <p-calendar
                placeholder="From Date"
                formControlName="contractDateStart"
                [maxDate]="todayMaxDate"
                [showIcon]="true"
                [monthNavigator]="true"
                [yearNavigator]="true"
                dateFormat="dd/mm/yy"
                cmsDateRestriction>
              </p-calendar>
            </span>
          <span class="inline-block col-5 ps-0">
              <p-calendar
                placeholder="To Date"
                formControlName="contractDateEnd"
                [maxDate]="todayMaxDate"
                [showIcon]="true"
                [monthNavigator]="true"
                [yearNavigator]="true"
                dateFormat="dd/mm/yy"
                cmsDateRestriction>
              </p-calendar>
              <span *ngIf="isContractDateRuleConformError()" class="fa fa-info-circle absolute error-marker"></span>
            </span>
          <span class="col-auto">
            <cms-additional-date-range [form]="form"
                                       [startField]="'contractDateStart'"
                                       [endField]="'contractDateEnd'">
            </cms-additional-date-range>
          </span>
        </div>

        <div class="row position-relative" *ngIf="isCreationTimestampVisible()">
          <label class="col-12 margin-0 text-medium">Creation Timestamp (UTC)</label>
          <span class="inline-block col-5 ps-0">
              <p-calendar
                placeholder="From Date"
                formControlName="creationDateStart"
                [maxDate]="todayMaxDate"
                [showIcon]="true"
                [monthNavigator]="true"
                [yearNavigator]="true"
                dateFormat="dd/mm/yy"
                cmsDateRestriction>
              </p-calendar>
            </span>
          <span class="inline-block col-5 ps-0">
              <p-calendar
                placeholder="To Date"
                formControlName="creationDateEnd"
                [maxDate]="todayMaxDate"
                [showIcon]="true"
                [monthNavigator]="true"
                [yearNavigator]="true"
                dateFormat="dd/mm/yy"
                cmsDateRestriction>
              </p-calendar>
              <span *ngIf="isCreationDateRuleConformError()" class="fa fa-info-circle absolute error-marker"></span>
            </span>
          <span class="col-auto">
            <cms-additional-date-range
              [form]="form"
              [startField]="'creationDateStart'"
              [endField]="'creationDateEnd'">
            </cms-additional-date-range>
          </span>
        </div>

        <div class="row position-relative" *ngIf="isGasDayStartUtcVisible()">
          <label class="col-12 margin-0 text-medium">Gas Day Start (UTC)</label>
          <span class="inline-block col-5 ps-0">
              <p-calendar
                placeholder="From Date"
                formControlName="gasDayUtcStart"
                [showIcon]="true"
                [monthNavigator]="true"
                [yearNavigator]="true"
                dateFormat="dd/mm/yy"
                cmsDateRestriction>
              </p-calendar>
            </span>
          <span class="inline-block col-5 ps-0">
              <p-calendar
                placeholder="To Date"
                formControlName="gasDayUtcEnd"
                [showIcon]="true"
                [monthNavigator]="true"
                [yearNavigator]="true"
                dateFormat="dd/mm/yy"
                cmsDateRestriction>
              </p-calendar>
              <span *ngIf="isGasDayUtcRuleConformError()" class="fa fa-info-circle absolute error-marker"></span>
            </span>
          <span class="col-auto">
            <cms-additional-date-range
              [form]="form"
              [startField]="'gasDayUtcStart'"
              [endField]="'gasDayUtcEnd'">
            </cms-additional-date-range>
          </span>
        </div>

        <div class="row position-relative">
          <label class="col-12 margin-0 col-12 text-medium">Received by eRR</label>
          <span class="inline-block col-5 ps-0">
            <p-calendar
              placeholder="From Date"
              formControlName="receivedByErrStart"
              [maxDate]="todayMaxDate"
              [showIcon]="true"
              [monthNavigator]="true"
              [yearNavigator]="true"
              dateFormat="dd/mm/yy"
              cmsDateRestriction>
            </p-calendar>
          </span>
          <span class="inline-block col-5 ps-0">
            <p-calendar
              placeholder="To Date"
              formControlName="receivedByErrEnd"
              [maxDate]="todayMaxDate"
              [showIcon]="true"
              [monthNavigator]="true"
              [yearNavigator]="true"
              dateFormat="dd/mm/yy"
              cmsDateRestriction>
            </p-calendar>
            <span *ngIf="isReceivedByRuleConformError()" class="fa fa-info-circle absolute error-marker"></span>
          </span>
          <span class="col-auto">
            <cms-additional-date-range
              [form]="form"
              [startField]="'receivedByErrStart'"
              [endField]="'receivedByErrEnd'">
            </cms-additional-date-range>
          </span>
        </div>
        <div class="row position-relative">
          <label class="col-12 margin-0 text-medium">Last Update</label>
          <span class="inline-block col-5 ps-0">
            <p-calendar
              placeholder="From Date"
              formControlName="lastUpdateStart"
              [maxDate]="todayMaxDate"
              [showIcon]="true"
              [monthNavigator]="true"
              [yearNavigator]="true"
              dateFormat="dd/mm/yy"
              cmsDateRestriction>
            </p-calendar>
          </span>
          <span class="inline-block col-5 ps-0">
            <p-calendar
              placeholder="To Date"
              formControlName="lastUpdateEnd"
              [maxDate]="todayMaxDate"
              [showIcon]="true"
              [monthNavigator]="true"
              [yearNavigator]="true"
              dateFormat="dd/mm/yy"
              cmsDateRestriction>
            </p-calendar>
            <span *ngIf="isLastUpdateRuleConformError()" class="fa fa-info-circle absolute error-marker"></span>
          </span>
          <span class="col-auto">
            <cms-additional-date-range
              [form]="form"
              [startField]="'lastUpdateStart'"
              [endField]="'lastUpdateEnd'">
            </cms-additional-date-range>
          </span>
        </div>
      </div>

      <div class="col-12">
        <div class="btn-container text-end margin-top-12">
          <button
            id="btn-search-date-filter"
            type="submit"
            class="btn padding-6 margin-right-3"
            [disabled]="!isValidFilter() || (remitTransactionsService.getLoading() | async)">
            Search
          </button>
          <button
            type="button"
            id="btn-reset-date-filter"
            class="btn padding-6"
            (click)="reset()">
            Reset
          </button>
        </div>
      </div>

    </div>

  </form>
</div>


