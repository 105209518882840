import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import { RemitTradeEntryTable1ModifyService } from '../remit-trade-entry-table1-modify.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'cms-remit-trade-entry-table1-modify-settlement',
  templateUrl: './remit-trade-entry-table1-modify-settlement.component.html',
  styleUrls: ['./remit-trade-entry-table1-modify-settlement.component.scss']
})
export class RemitTradeEntryTable1ModifySettlementComponent implements OnInit, OnDestroy {

  @Input()
  formGroup: UntypedFormGroup;

  onDestroy$ = new Subject();

  settlementMethods: SelectItem[] = [];

  settlementModel: SettlementTradeEntryModel;

  constructor(private table1ModifyService: RemitTradeEntryTable1ModifyService) {
  }

  ngOnInit() {
    this.formGroup.addControl('settlementMethod', new UntypedFormControl(''));

    this.table1ModifyService.onTradeEntryModelChange().pipe(takeUntil(this.onDestroy$)).subscribe(tradeEntryModel => {
      if (tradeEntryModel) {
        this.settlementModel = tradeEntryModel.settlementResponseModel;
        this.initFormValues();
      }
    });

    this.table1ModifyService.onTradeEntrySetupDataChange().pipe(takeUntil(this.onDestroy$)).subscribe(setupData => {
      if (setupData) {
        this.initFormSetup(setupData.settlementMethods);
      }
    });

  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  get bilateral(): boolean {
    return this.settlementModel !== null;
  }

  private initFormValues(): void {
    if (this.settlementModel) {

      this.reset();
      this.formGroup.controls['settlementMethod'].setValue(this.settlementModel.settlementMethod);
    }
  }

  private initFormSetup(settlementMethods: any) {
    if (settlementMethods) {

      this.settlementMethods = settlementMethods.map(method => {
        return {
          label: method.value,
          value: method.key
        };
      });
    }

  }

  private reset(): void {
    this.formGroup.controls['settlementMethod'].reset();
  }
}

export interface SettlementTradeEntryModel {
  settlementMethod: string;
}

