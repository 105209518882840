import { Message } from 'primeng/api';
import { ErrorResponse } from '@common/error-response.model';
import { DatatableData, PagingFilter, SortingFilter } from '../../common/shared/results.model';
import { Column } from '../../common/shared/datatable.model';
import { AbstractControl } from '@angular/forms';

export const defaultStyles = {
  'width': '140px', 'text-align': 'left',
  'text-overflow': 'ellipsis',
  'overflow': 'hidden',
  'white-space': 'nowrap'
};

export const columns = [
  {name: 'assetClass', type: 'text', title: 'Asset Class', style: {...defaultStyles, width: '120px'}, sortable: true, visible: true},
  {name: 'agentId', type: 'text', title: 'Agent ID', style: {...defaultStyles, width: '180px'}, sortable: true, visible: true},
  {
    name: 'productCode',
    type: 'text',
    title: 'Product Code',
    style: {...defaultStyles, width: '125px'},
    sortable: true,
    visible: true
  },
  {
    name: 'productIdentification',
    type: 'text',
    title: 'Product Identification',
    style: {...defaultStyles, width: '170px'},
    sortable: true,
    visible: true
  },
  {
    name: 'productIdentificationType',
    type: 'text',
    title: 'Product Identification Type',
    style: {...defaultStyles, width: '205px'},
    sortable: true,
    visible: true
  },
  {name: 'underlying', type: 'text', title: 'Underlying', style: {...defaultStyles, 'width': '120px'}, sortable: true, visible: true},
  {
    name: 'underlyingCodeType',
    type: 'text',
    title: 'Underlying Code Type',
    style: {...defaultStyles, 'width': '190px'},
    sortable: true,
    visible: true
  },
  {
    name: 'underlyingName',
    type: 'text',
    title: 'Underlying Name',
    style: {...defaultStyles, 'width': '170px'},
    sortable: true,
    visible: true
  },
  {
    name: 'underlyingIndicator',
    type: 'text',
    title: 'Underlying Indicator',
    style: {...defaultStyles, 'width': '170px'},
    sortable: true,
    visible: true
  },
  {
    name: 'underlyingCurrency',
    type: 'text',
    title: 'Underlying Currency',
    style: {...defaultStyles, 'width': '170px'},
    sortable: true,
    visible: true
  },
  {
    name: 'notionalCurrency1',
    type: 'text',
    title: 'Notional Currency 1',
    style: {...defaultStyles, 'width': '170px'},
    sortable: true,
    visible: true
  },

  {
    name: 'notionalCurrency2',
    type: 'text',
    title: 'Notional Currency 2',
    style: {...defaultStyles, 'width': '170px'},
    sortable: true,
    visible: true
  },
  {
    name: 'deliverableCurrency',
    type: 'text',
    title: 'Deliverable Currency',
    style: {...defaultStyles, 'width': '170px'},
    sortable: true,
    visible: true
  },
  {
    name: 'priceNotation',
    type: 'text',
    title: 'Price Notation',
    style: {...defaultStyles, 'width': '150px'},
    sortable: true,
    visible: true
  },
  {
    name: 'priceMultiplier',
    type: 'currency',
    title: 'Price Multiplier',
    style: {...defaultStyles, 'width': '150px', 'text-align': 'right'},
    sortable: true,
    visible: true
  },
  {
    name: 'totalVolumeUnit',
    type: 'text',
    title: 'Total Volume Unit',
    style: {...defaultStyles, 'width': '150px'},
    sortable: true,
    visible: true
  },
  {
    name: 'deliveryType',
    type: 'text',
    title: 'Delivery Type',
    style: {...defaultStyles, 'width': '150px'},
    sortable: true,
    visible: true
  },
  {
    name: 'effectiveDate',
    type: 'text',
    title: 'Effective Date',
    style: {...defaultStyles, 'width': '150px'},
    sortable: true,
    visible: true
  },
  {
    name: 'maturityDate',
    type: 'text',
    title: 'Maturity Date',
    style: {...defaultStyles, 'width': '130px'},
    sortable: true,
    visible: true
  },
  {
    name: 'baseProduct',
    type: 'text',
    title: 'Base Product',
    style: {...defaultStyles, 'width': '130px'},
    sortable: true,
    visible: true
  },
  {
    name: 'subProduct',
    type: 'text',
    title: 'Sub-Product',
    style: {...defaultStyles, 'width': '130px'},
    sortable: true,
    visible: true
  },
  {
    name: 'furtherSubProduct',
    type: 'text',
    title: 'Further Sub-Product',
    style: {...defaultStyles, 'width': '130px'},
    sortable: true,
    visible: true
  },
  {
    name: 'commodityBase',
    type: 'text',
    title: 'Commodity Base',
    style: {...defaultStyles, 'width': '150px'},
    sortable: true,
    visible: true
  },
  {
    name: 'commodityDetail',
    type: 'text',
    title: 'Commodity Detail',
    style: {...defaultStyles, 'width': '150px'},
    sortable: true,
    visible: true
  },
  {
    name: 'deliveryPointOrZone',
    type: 'text',
    title: 'Delivery Point or Zone',
    style: {...defaultStyles, 'width': '173px'},
    sortable: true,
    visible: true
  },
  {
    name: 'interconnectionPoint',
    type: 'text',
    title: 'Interconnection Point',
    style: {...defaultStyles, 'width': '170px'},
    sortable: true,
    visible: true
  },

  {name: 'loadType', type: 'text', title: 'Load Type', style: {...defaultStyles, 'width': '110px'}, sortable: true, visible: true},
  {name: 'duration', type: 'text', title: 'Duration', style: {...defaultStyles, 'width': '110px'}, sortable: true, visible: true},
  {
    name: 'loadDeliverySchedules',
    type: 'text',
    title: 'Load Delivery Schedules',
    style: {...defaultStyles, 'width': '188px'},
    sortable: true,
    visible: true
  },
  {name: 'capacity', type: 'text', title: 'Capacity', style: {...defaultStyles, 'width': '110px'}, sortable: true, visible: true},
  {
    name: 'quantityUnit',
    type: 'text',
    title: 'Quantity Unit',
    style: {...defaultStyles, 'width': '130px'},
    sortable: true,
    visible: true
  },
  {
    name: 'deliveryStartDate',
    type: 'text',
    title: 'Delivery Start Date',
    style: {...defaultStyles, width: '160px'},
    sortable: true,
    visible: true
  },
  {
    name: 'deliveryEndDate',
    type: 'text',
    title: 'Delivery End Date',
    style: {...defaultStyles, width: '160px'},
    sortable: true,
    visible: true
  },
  {name: 'currency2', type: 'text', title: 'Currency 2', style: {...defaultStyles, 'width': '110px'}, sortable: true, visible: true},
  {
    name: 'exchangeRate1',
    type: 'text',
    title: 'Exchange Rate 1',
    style: {...defaultStyles, 'width': '170px'},
    sortable: true,
    visible: true
  },
  {
    name: 'exchangeRateBasis',
    type: 'text',
    title: 'Exchange Rate Basis',
    style: {...defaultStyles, 'width': '220px'},
    sortable: true,
    visible: true
  },
  {
    name: 'fixedRateOfLeg2',
    type: 'text',
    title: 'Fixed Rate Of Leg 2',
    style: {...defaultStyles, 'width': '190px'},
    sortable: true,
    visible: true
  },
  {
    name: 'fixedRateDayCountLeg1',
    type: 'text',
    title: 'Fixed Rate Day Count Leg 1',
    style: {...defaultStyles, 'width': '220px'},
    sortable: true,
    visible: true
  },

  {
    name: 'fixedRateDayCountLeg2',
    type: 'text',
    title: 'Fixed Rate Day Count Leg 2',
    style: {...defaultStyles, 'width': '220px'},
    sortable: true,
    visible: true
  },
  {
    name: 'fixedLegPaymentFrequencyLeg1',
    type: 'text',
    title: 'Fixed Leg Payment Frequency Leg 1',
    style: {...defaultStyles, 'width': '270px'},
    sortable: true,
    visible: true
  },
  {
    name: 'fixedLegPaymentFrequencyLeg2',
    type: 'text',
    title: 'Fixed Leg Payment Frequency Leg 2',
    style: {...defaultStyles, 'width': '270px'},
    sortable: true,
    visible: true
  },
  {
    name: 'floatingRatePaymentFrequencyLeg1',
    type: 'text',
    title: 'Floating Rate Payment Frequency Leg 1',
    style: {...defaultStyles, 'width': '285px'},
    sortable: true,
    visible: true
  },
  {
    name: 'floatingRatePaymentFrequencyLeg2',
    type: 'text',
    title: 'Floating Rate Payment Frequency Leg 2',
    style: {...defaultStyles, 'width': '285px'},
    sortable: true,
    visible: true
  },
  {
    name: 'floatingRateResetFrequencyLeg1',
    type: 'text',
    title: 'Floating Rate Reset Frequency Leg 1',
    style: {...defaultStyles, 'width': '270px'},
    sortable: true,
    visible: true
  },
  {
    name: 'floatingRateResetFrequencyLeg2',
    type: 'text',
    title: 'Floating Rate Reset Frequency Leg 2',
    style: {...defaultStyles, 'width': '270px'},
    sortable: true,
    visible: true
  },
  {
    name: 'floatingRateOfLeg1',
    type: 'text',
    title: 'Floating Rate Of Leg 1',
    style: {...defaultStyles, 'width': '200px'},
    sortable: true,
    visible: true
  },
  {
    name: 'floatingRateOfLeg2',
    type: 'text',
    title: 'Floating Rate Of Leg 2',
    style: {...defaultStyles, 'width': '200px'},
    sortable: true,
    visible: true
  },
  {
    name: 'floatingRateReferencePeriodLeg1',
    type: 'text',
    title: 'Floating Rate Reference Period Leg 1',
    style: {...defaultStyles, 'width': '270px'},
    sortable: true,
    visible: true
  },
  {
    name: 'floatingRateReferencePeriodLeg2',
    type: 'text',
    title: 'Floating Rate Reference Period Leg 2',
    style: {...defaultStyles, 'width': '270px'},
    sortable: true,
    visible: true
  },
  {
    name: 'cfi',
    type: 'text',
    title: 'CFI',
    style: {...defaultStyles, 'width': '130px'},
    visible: true
  },
];

export interface EtdProductsResponse extends ErrorResponse {
  count: number;
  values: EtdProducts[];
  currentPage: number;
  keyFieldColumns?: {key: string, value: string}[];
}

export interface OptionTypesModel {
  key: string;
  value: string;
}

export interface EtdProductsPageMessages {
  upload: Message[];
  datatable: Message[];
}

export interface FiltersDataModel {
  agentIds?: string[];
  assetClasses?: string[];
  optionTypes?: OptionTypesModel[];
}

export interface EtdProductsState {
  columns: Column[];
  file: File;
  data?: DatatableData<EtdProducts[]>;
  maxFileSize?: number;
  messages?: EtdProductsPageMessages;
  paging?: PagingFilter;
  sorting?: SortingFilter;
  loading?: boolean;
  filters?: EtdProductsFilters;
  agentIds?: string[];
  assetClasses?: string[];
  uploadPanelVisible: boolean;
  uploading: boolean;
  optionTypes?: {key: string, value: string}[];
  loaded: boolean;
}

export interface EtdProductsMaxFileSize extends ErrorResponse {
  response?: number;
}

export interface EtdProducts {
  assetClass?: string;
  agentId?: string;
  productCode?: string;
  deliveryStartKey?: string;
  deliveryEndKey?: string;
  optionType?: string;
  strikePrice?: string;
  keyField5?: string;
  productIdentification?: string;
  productIdentType?: string;
  underlying?: string;
  underlyingCodeType?: string;
  notionalCurrency1?: string;
  notionalCurrency2?: string;
  deliverableCurrency?: string;
  priceNotation?: string;
  priceMultiplier?: string;
  totalVolumeUnit?: string;
  deliveryType?: string;
  effectiveDate?: string;
  maturityDate?: string;
  commodityBase?: string;
  commodityDetail?: string;
  deliveryPointOrZone?: string;
  interconnectionPoint?: string;
  loadType?: string;
  duration?: string;
  loadDeliverySchedules?: string;
  capacity?: string;
  quantityUnit?: string;
  deliveryStartDate?: string;
  deliveryEndDate?: string;
  currency2?: string;
  exchangeRate1?: string;
  exchangeRateBasis?: string;
  fixedRateOfLeg2?: string;
  fixedRateDayCountLeg1?: string;
  fixedRateDayCountLeg2?: string;
  fixedLegPaymentFrequencyLeg1?: string;
  fixedLegPaymentFrequencyLeg2?: string;
  floatingRatePaymentFrequencyLeg1?: string;
  floatingRatePaymentFrequencyLeg2?: string;
  floatingRateResetFrequencyLeg1?: string;
  floatingRateResetFrequencyLeg2?: string;
  floatingRateOfLeg1?: string;
  floatingRateOfLeg2?: string;
  floatingRateReferencePeriodLeg1?: string;
  floatingRateReferencePeriodLeg2?: string;
  keyFieldValueMap?: KeyFieldValues;
  [key: string]: any;
}

export interface KeyFieldValues {
  KeyDeliveryStartDate: string;
  KeyDeliveryEndDate: string;
  KeyOptionType: string;
  KeyStrikePrice: string;
  [key: string]: string;
}

export interface EtdProductsFilters {
  agentId?: string[];
  assetClass?: string[];
  deliveryEndKey?: string;
  deliveryStartKey?: string;
  isin?: string;
  optionType?: string;
  productCode?: string;
  strikePrice?: string;
}

export interface EtdProductsSearchRequest {
  etdProductsFilterValuesModel: EtdProductsFilters;
  paging?: PagingFilter;
  sorting?: SortingFilter;
}

export interface UploadEtdProducts {
  file: File;
  agentId: string;
}

export function FileExtension(control: AbstractControl) {
  if (control.value && !control.value.endsWith('.csv')) {
    return { fileExtension: true };
  }
  return null;
}
