import {BehaviorSubject, Observable, throwError as observableThrowError} from 'rxjs';
import {EventEmitter, Injectable} from '@angular/core';
import {HttpErrorResponse, HttpParams, HttpResponse} from '@angular/common/http';
import {PagingFilter, SortingFilter} from '@common/shared/results.model';
import {
  defaultPaging,
  defaultSorting,
  RemitTransactionSearchMode,
  RemitTransactionsFilters,
  RemitTransactionsSearchFilter,
  RemitTransactionsSearchQuery
} from './remit-transactions.model';
import {ErrRemitDataTableModel} from './remit-datatable.model';
import {TransactionSheetResponseModel} from '@common/sheet/transaction-sheet-model';
import {EndPointConstants} from '@common/end-point-constants';
import {RemitDocumentType} from '../dashboard/filter/remit-dashboard-filter-model';
import {catchError, map} from 'rxjs/operators';
import {ApiRequestService} from '@common/api-request.service';
import {MessagesMapperService} from '@common/messages-mapper.service';
import {Message} from 'primeng/api';
import {StringUtils} from "@common/shared/string.utils";

@Injectable()
export class RemitTransactionsService {

  private apiUrlsForExport = new Map<RemitTransactionSearchMode, Map<RemitDocumentType, string>>([
    ['SEARCH_BUCKET_DETAIL', new Map([
      [<RemitDocumentType>'REMIT_TABLE_1', /*     */'/api/err/remit/remit_table_1/report'],
      [<RemitDocumentType>'REMIT_TABLE_2', /*     */'/api/err/remit/remit_table_2/report'],
      [<RemitDocumentType>'ELECTRICITY_RIGHTS', /**/'/api/err/remit/electricity_rights/report'],
      [<RemitDocumentType>'GAS_CAPACITY', /*      */'/api/err/remit/gas_capacity/report'],
      [<RemitDocumentType>'FUNDAMENTALS', /*      */'/api/err/remit/fundamentals/report']
    ])],
    ['SEARCH_FILTER', new Map([
      [<RemitDocumentType> 'REMIT_TABLE_1', /*     */'/api/err/remit/search/table1/byCriteria/report'],
      [<RemitDocumentType> 'REMIT_TABLE_2', /*     */'/api/err/remit/search/table2/byCriteria/report'],
      [<RemitDocumentType> 'ELECTRICITY_RIGHTS', /**/'/api/err/remit/search/table3/byCriteria/report'],
      [<RemitDocumentType> 'GAS_CAPACITY', /*      */'/api/err/remit/search/table4/byCriteria/report'],
      [<RemitDocumentType> 'FUNDAMENTALS', /*      */'/api/err/remit/search/fundamentals/byCriteria/report']
    ])],
    ['SEARCH_UTI', new Map([
      [<RemitDocumentType> 'REMIT_TABLE_1', /*     */'/api/err/remit/search/table1/byUtisAndOrderIds/report'],
      // [<RemitDocumentType> 'REMIT_TABLE_2', /*     */''],
      // [<RemitDocumentType> 'ELECTRICITY_RIGHTS', /**/''],
      // [<RemitDocumentType> 'GAS_CAPACITY', /*      */''],
      // [<RemitDocumentType> 'FUNDAMENTALS', /*      */'']
    ])],
    ['SEARCH_TRANSACTION_ID', new Map([
      [<RemitDocumentType> 'REMIT_TABLE_1', /*     */'/api/err/remit/search/table1/byTransactionIds/report'],
      // [<RemitDocumentType> 'REMIT_TABLE_2', /*     */''],
      // [<RemitDocumentType> 'ELECTRICITY_RIGHTS', /**/''],
      [<RemitDocumentType> 'GAS_CAPACITY', /*      */'/api/err/remit/search/table4/byTransactionIds/report'],
      // [<RemitDocumentType> 'FUNDAMENTALS', /*      */'']
    ])],
    ['SEARCH_CONTRACT_ID', new Map([
      // [<RemitDocumentType> 'REMIT_TABLE_1', /*     */''],
      [<RemitDocumentType> 'REMIT_TABLE_2', /*     */'/api/err/remit/search/table2/byUtis/report'],
      // [<RemitDocumentType> 'ELECTRICITY_RIGHTS', /**/''],
      // [<RemitDocumentType> 'GAS_CAPACITY', /*      */''],
      // [<RemitDocumentType> 'FUNDAMENTALS', /*      */'']
    ])],
    ['SEARCH_FILENAME', new Map([
      [<RemitDocumentType> 'REMIT_TABLE_1', /*     */'/api/err/remit/search/table1/byFilename/report'],
      [<RemitDocumentType> 'REMIT_TABLE_2', /*     */'/api/err/remit/search/table2/byFilename/report'],
      [<RemitDocumentType> 'ELECTRICITY_RIGHTS', /**/'/api/err/remit/search/table3/byFilename/report'],
      [<RemitDocumentType> 'GAS_CAPACITY', /*      */'/api/err/remit/search/table4/byFilename/report'],
      [<RemitDocumentType> 'FUNDAMENTALS', /*      */'/api/err/remit/search/fundamentals/byFilename/report']
    ])],
    ['SEARCH_UNIQUE_ID', new Map([
      [<RemitDocumentType> 'ELECTRICITY_RIGHTS', '/api/err/remit/search/table3/byUniqueId/report'],
    ])],
    ['SEARCH_REFERENCE_ID', new Map([
      [<RemitDocumentType> 'FUNDAMENTALS', '/api/err/remit/search/fundamentals/byReferenceId/report'],
    ])],
  ]);

  private cachedSearchQuery: { [key: string]: RemitTransactionsSearchQuery } = {};
  private searchQuerySubject = new BehaviorSubject<RemitTransactionsSearchQuery>(null);
  private resetDataSubject = new BehaviorSubject<boolean>(false);
  private resetSubject = new EventEmitter();
  private loading = new BehaviorSubject<boolean>(false);
  private validationMessages = new BehaviorSubject<Message[]>([]);

  constructor(private apiRequestService: ApiRequestService, private messageMapper: MessagesMapperService) {
  }

  public getCachedSearchQuery(key: string): RemitTransactionsSearchQuery {
    return this.cachedSearchQuery[key];
  }

  public setCachedSearchQuery(key: string, value: RemitTransactionsSearchQuery) {
    this.cachedSearchQuery[key] = value;
  }

  public setValidationMessages(value: Message[]) {
    this.validationMessages.next(value);
  }

  public emitResetFilter() {
    this.searchQuerySubject.next(null);
    this.resetSubject.emit();
  }


  public updateSearchQuery(key: string, updatedSearchQuery: RemitTransactionsSearchFilter) {
    const cachedQuery = this.getCachedSearchQuery(key);
    const sorting = (cachedQuery?.sorting != null) ? cachedQuery.sorting : defaultSorting;
    const query: RemitTransactionsSearchQuery = {
      sorting,
      paging: defaultPaging,
      ...updatedSearchQuery
    };
    this.setCachedSearchQuery(key, query);
    this.emitNewSearchQuery(query);
  }

  public emitNewSearchQuery(updateSearchQuery: RemitTransactionsSearchQuery) {
    this.searchQuerySubject.next(updateSearchQuery);
  }

  public getResetStatusChangeObservable() {
    return this.resetSubject.asObservable();
  }

  public fetchRemitBucketDetails(searchQuery: RemitTransactionsSearchQuery): Observable<ErrRemitDataTableModel> {
    this.setLoading(true);

    return this.apiRequestService.post('/api/err/remit/' + searchQuery.remitDocumentType.toLowerCase() + '/columns', {
      ...searchQuery,
      sorting: {
        columnName: StringUtils.camelCaseToSnakeUpperCase(searchQuery.sorting.columnName),
        sortOrder: searchQuery.sorting.sortOrder
      }
    }).pipe(
      map((result: ErrRemitDataTableModel | any) => {
        this.setLoading(false);
        return result;
      }),
      catchError(err => {
        this.setLoading(false);
        return err;
      }));
  }

  public searchBy(apiUrl: string, searchQuery: RemitTransactionsSearchQuery): Observable<ErrRemitDataTableModel> {
    this.setLoading(true);
    this.validationMessages.next([]);

    return this.apiRequestService.post(apiUrl, {
      ...searchQuery,
      sorting: {
        columnName: StringUtils.camelCaseToSnakeUpperCase(searchQuery.sorting.columnName),
        sortOrder: searchQuery.sorting.sortOrder
      }
    })
      .pipe(
        map((result: ErrRemitDataTableModel | any) => {
          this.setLoading(false);
          if (result.validationFailureMessages) {
            const messages = this.messageMapper.toErrorMessages(result);
            this.validationMessages.next(messages);
          }
          return result;
        }),
        catchError(err => {
          this.setLoading(false);
          return err;
        }));
  }

  public getLoading(): Observable<boolean> {
    return this.loading.asObservable();
  }

  public setLoading(value: boolean): void {
    this.loading.next(value);
  }

  public getSearchQueryChangeObservable(): Observable<RemitTransactionsSearchQuery> {
    return this.searchQuerySubject.asObservable();
  }

  public getCurrentSearchQuery() {
    return this.searchQuerySubject.getValue();
  }

  public updateSearchQueryByPagingAndSorting(pagingFilter: PagingFilter, sortingFilter: SortingFilter) {
    const nextSearchQuery = this.buildNextPagingSortingSearchQuery({paging: pagingFilter, sorting: sortingFilter});
    this.setCachedSearchQuery(nextSearchQuery.remitDocumentType, nextSearchQuery);
    this.searchQuerySubject.next(nextSearchQuery);
  }

  private buildNextPagingSortingSearchQuery(newFilters: RemitTransactionsFilters): RemitTransactionsSearchQuery {
    return {
      ...RemitTransactionsSearchQuery.createEmptySearchQuery(),
      ...this.getCurrentSearchQuery(),
      ...newFilters
    };
  }

  public downloadRemitXml(historyId: number, docType: RemitDocumentType): Observable<{ filename: string, blob: Blob }> {
    let params = new HttpParams();

    if (historyId) {
      params = params.append(EndPointConstants.PARAM_HISTORY_ID, historyId.toString());
    }
    if (docType) {
      params = params.append(EndPointConstants.PARAM_DOCUMENT_TYPE, docType.toString());
    }

  return this.apiRequestService.getBlob('/api/err/remit/search/' + this.getUrlPart(docType) + '/xml',null, params)
      .pipe(map((response: HttpResponse<Blob>) => {
        const filename = this.getFilenameFromHeader(response.headers);
        const blob: Blob = response.body;
        return { filename, blob };
      }), catchError(this.handleError));
  }

  private getUrlPart(docType: RemitDocumentType): string {
    switch (docType) {
      case 'ELECTRICITY_RIGHTS':
        return 'table3/electricity_rights';
      case 'GAS_CAPACITY':
        return 'table4/gas_capacity';
    }
  }

  private getFilenameFromHeader(headers): string {
    const contentDisposition = headers.get('content-disposition');
    return contentDisposition.split(';')[1].trim().split('=')[1];
  }

  private handleError(error: HttpErrorResponse) {
    console.error(error);
    const errMsg = (error.message) ? error.message : 'Server error';
    return observableThrowError(errMsg);
  }

  public downloadExcelExport(searchQuery?: RemitTransactionsSearchQuery): Observable<{ filename: string, blob: Blob }> {
    const nextSearchQuery = searchQuery || this.searchQuerySubject.getValue();

    const url = this.apiUrlsForExport.get(nextSearchQuery.searchMode).get(nextSearchQuery.remitDocumentType);

    return this.apiRequestService.getBlob(url, {
      ...nextSearchQuery,
      sorting: {
        columnName: StringUtils.camelCaseToSnakeUpperCase(nextSearchQuery.sorting.columnName),
        sortOrder: nextSearchQuery.sorting.sortOrder
      }
    })
      .pipe(map((response: HttpResponse<Blob>) => {
        const filename = this.getFilenameFromHeader(response.headers);
        const blob: Blob = response.body;
        return { filename, blob };
      }), catchError(this.handleError));
  }

  public getTransactionSheet(regime: string, recordType: string, documentType: RemitDocumentType, historyId: number): Observable<TransactionSheetResponseModel> {
    let params = new HttpParams();
    params = params.append(EndPointConstants.PARAM_REGIME, regime);
    if (recordType) {
      params = params.append(EndPointConstants.PARAM_RECORD_TYPE, recordType);
    }
    params = params.append(EndPointConstants.PARAM_DOCUMENT_TYPE, documentType);
    params = params.append(EndPointConstants.PARAM_HISTORY_ID, String(historyId));

    return this.apiRequestService.get(`/api/err/remit/transactions/sheet`, params);
  }

  public onSearchValidationMessages(): Observable<Message[]> {
    return this.validationMessages.asObservable();
  }

  public resetData() {
    this.resetDataSubject.next(true);
  }

  public onResetData(): Observable<boolean> {
    return this.resetDataSubject.asObservable();
  }

}
