import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemitTransactionsFilterComponent } from './remit-transactions-filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RemitTransactionsFilterService } from './remit-transactions-filter.service';
import { CmsCommonModule } from '../../../../common/cms-common.module';

@NgModule({
  imports: [
    CommonModule,
    CmsCommonModule,
    FormsModule, ReactiveFormsModule,
    DropdownModule, MultiSelectModule,
    CalendarModule,
  ],
  providers: [RemitTransactionsFilterService],
  exports: [RemitTransactionsFilterComponent],
  declarations: [RemitTransactionsFilterComponent]
})
export class RemitTransactionsFilterModule {
}
