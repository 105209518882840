<div class="panel-box">
  <form [formGroup]="filenameForm" class="row align-items-center col-12 margin-bottom-6">
    <div class="row col-12 decent-max-width">
      <div class="col-auto inline-block">
        Filename contains
      </div>
      <div class="col-8 col-lg-9">
        <input id="input-filename-search" type="text" class="form-control p-component padding-6 text-medium" formControlName="filename">
        <p class="error-note text-small mb-0" *ngIf="filenameForm.hasError('maxlength', 'filename')">Field 'Filename contains' has a maximum length of 100.</p>
      </div>

    </div>
    <span class="col-auto padding-left-15">
      <button class="btn form-control padding-6" style="width: 75px" [disabled]="filenameForm.invalid || (filesInService.getLoading() | async)" (click)="onSearchByFilename()">Search</button>
    </span>
  </form>

  <form [formGroup]="filterForm" class="row align-items-center col-12 margin-bottom-6">
    <div class="row col-12 decent-max-width">
      <div class="col-6 col-lg-4">
        <label class="col-12 text-medium margin-bottom-3 ps-0">Document types</label>
        <p-multiSelect [options]="documentTypes"
                       [filter]="false"
                       formControlName="documentTypes"
                       selectedItemsLabel="All ({0})"
                       [maxSelectedLabels]="5"
                       [style]="{ 'width': '150px'}">
        </p-multiSelect>
      </div>

      <div class="row col-6 col-lg-4">
        <label class="col-12 text-medium mb-0">Received from</label>
        <span class="col-auto inline-block pe-2">
          <p-calendar
            placeholder="From Date"
            formControlName="receivedFromDate"
            [showIcon]="true"
            [monthNavigator]="true"
            [yearNavigator]="true"
            dateFormat="dd/mm/yy"
            [style]="{ 'width': '125px'}"
            cmsDateRestriction>
          </p-calendar>
        </span>
        <span class="col-auto inline-block ps-0">
          <p-inputMask formControlName="receivedStartTime" mask="99:99:99" pattern="(([0-1]?[0-9])|(2[0-3])):[0-5][0-9]:[0-5][0-9]" placeholder="hh:mm:ss" slotChar="hh:mm:ss" styleClass="text-small padding-6 text-center" [style]="{ 'width': '75px'}"></p-inputMask>
        </span>
      </div>

      <div class="row col-6 col-lg-4">
        <label class="col-12 text-medium mb-0 ">to</label>
        <span class="col-auto inline-block pe-2">
          <p-calendar
            placeholder="to Date"
            formControlName="receivedToDate"
            [showIcon]="true"
            [monthNavigator]="true"
            [yearNavigator]="true"
            dateFormat="dd/mm/yy"
            [style]="{ 'width': '125px'}"
            cmsDateRestriction>
          </p-calendar>
        </span>
        <span class="col-auto inline-block ps-0">
          <p-inputMask formControlName="receivedEndTime" mask="99:99:99" pattern="(([0-1]?[0-9])|(2[0-3])):[0-5][0-9]:[0-5][0-9]" placeholder="hh:mm:ss" slotChar="hh:mm:ss" styleClass="text-small padding-6 text-center" [style]="{ 'width': '75px'}"></p-inputMask>
        </span>
      </div>

    </div>
    <span class="col-auto padding-left-15  margin-top-18">
      <button class="btn padding-3 fa fa-refresh" style="height: 32px; width: 32px; padding: 3px;" (click)="onRefresh()"></button>
    </span>
    <span class="col-auto padding-left-6 margin-top-18">
      <button class="btn form-control " style="width: 75px" [disabled]="filterForm.invalid || (filesInService.getLoading() | async)" (click)="onSearchByDocument()">Search</button>
    </span>
  </form>
</div>
