import { createSelector } from '@ngrx/store';
import { getDashboard } from '../dashboard.selectors';
import * as fromReducer from './deals.reducers';
import { getEcmDealFinderState } from '../../deal-finder/deal-finder.selectors';

export const getDeals = createSelector(getDashboard, fromReducer.getDeals);
export const getData = createSelector(getDeals, fromReducer.getData);
export const getSorting = createSelector(getDeals, fromReducer.getSorting);
export const getPaging = createSelector(getDeals, fromReducer.getPaging);
export const getLoading = createSelector(getDeals, fromReducer.getLoading);
export const getMessages = createSelector(getDeals, fromReducer.getMessages);
export const getColumns = createSelector(getDeals, fromReducer.getColumns);
export const getConfirmationIds = createSelector(getDeals, fromReducer.getConfirmationIds);
export const getSelected = createSelector(getDeals, fromReducer.getSelected);
export const getDialog = createSelector(getDeals, fromReducer.getDialog);
export const getDateRangeText = createSelector(getDeals, fromReducer.getDateRangeText);
export const getDealEditRights = createSelector(getDeals, fromReducer.getDealEditRights);
export const getDealNoteRights = createSelector(getDeals, fromReducer.getDealNoteRights);
