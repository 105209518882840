<section class="panel-box section1 overflow-auto" title="Verify uploaded document statistics">

    <p-fieldset class="fieldset1 inline-block" legend="Verify results">
      Of the UTIs you submitted,<br>
      <b>{{  this.countFound}}</b> were found, <br>
       <b>{{this.countDuplicate}}</b>   duplicate, <br>
       <b>{{this.countNotFound}}</b> were not found, <br>
      <b>{{ this.countNoPermission}}</b> you do not have MO permissions for and <br>
      <b>{{  this.countIssue}}</b> have issues.<br>
    </p-fieldset>


<div class="inline-block" style="text-align: left">
  <p-listbox class="inline-block" [readonly]="true" [options]="duplicates">
    <p-header>Duplicate</p-header>
  </p-listbox>

  <p-listbox  class="inline-block" [readonly]="true" [options]="notFound">
    <p-header>Not Found</p-header>
  </p-listbox>

  <p-listbox  class="inline-block" [readonly]="true" [options]="noPermission">
    <p-header>No Permission</p-header>
  </p-listbox>

  <p-listbox  class="inline-block" [readonly]="true" [options]="issues">
    <p-header>Manual Termination</p-header>
  </p-listbox>
  </div>
</section>



<section class="panel-box flex justify-content-between mt-2 overflow-visible" >
  <p-fieldset class="block flex-grow-0 flex-shrink-0 fieldset2" legend="Enter Terminate Date and Transaction Date and Time">
    <li>If you need to adapt your upload file, click "Cancel", edit your csv file and re-upload.</li>
    <br>
    <li>If you would like to generate early termination entries, please enter a UTC "Termination Date" as well as a "Transaction Date and Time" and click "OK".</li>
  </p-fieldset>
  <div class="mwidth" style="overflow-y: visible">
    <div class="flex flex-nowrap">
        <label>Termination Date</label>
        <label style="margin-left:35px">Transaction Date and Time</label>
    </div>
    <form [formGroup]="form" class="flex flex-nowrap">
        <p-calendar placeholder="Date"
                    formControlName="terminationDate"
                    [showIcon]="true"
                    [monthNavigator]="true"
                    [yearNavigator]="true"
                    dateFormat="dd/mm/yy"
                    [style]="{ 'width': '125px'}"
                    [title]="'Select a Date'"
                    cmsDateRestriction>
        </p-calendar>
        <p-calendar formControlName="date"
                    placeholder="Date"
                    [showIcon]="true"
                    [monthNavigator]="true"
                    [yearNavigator]="true"
                    dateFormat="dd/mm/yy"
                    [style]="{ 'width': '125px','margin-left':'20px'}"
                    [title]="'Select a Date'"
                    cmsDateRestriction>
        </p-calendar>
        <p-inputMask formControlName="time"
                     mask="99:99:99"
                     pattern="(([0-1]?[0-9])|(2[0-3])):[0-5][0-9]:[0-5][0-9]"
                     placeholder="hh:mm:ss"
                     slotChar="hh:mm:ss">
        </p-inputMask>
    </form>
    <div class="flex block justify-content-end align-items-end me-3" style="height: 63%;">
      <button pButton style="margin-right:15px;"
              class="btn big-btn"
              type="button"
              title="Cancel"
              (click)="onClose()">Cancel
      </button>
      <button pButton
              class="btn big-btn"
              type="button"
              [title]="toolTip"
              [disabled]="form.invalid || countFound===0"
              (click)="onSubmit()">OK
      </button>
    </div>
  </div>
</section>
