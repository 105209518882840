import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../auth/authentication.service';
import { select, Store } from '@ngrx/store';
import { LayoutState } from '../state/mail-alerts/layout/layout.reducer';
import { getTabs } from '../state/mail-alerts/layout/layout.selectors';
import { TabsVisibility } from '../state/mail-alerts/layout/layout.actions';
import { switchMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'cms-mail-alerts',
  templateUrl: './mail-alerts.component.html',
  styleUrls: ['./mail-alerts.component.scss']
})
export class MailAlertsComponent implements OnInit, OnDestroy {
  tabsVisibility: TabsVisibility;
  activeIndex: number = null;
  tabRouters = [];
  subscription$: Subscription;

  constructor(private route: ActivatedRoute,
              private store: Store<LayoutState>,
              private authService: AuthenticationService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.subscription$ = this.store.pipe(select(getTabs),
      switchMap((tabs: TabsVisibility) => {
        this.tabsVisibility = tabs;
        if (tabs) {
          this.tabRouters = [
            'preferences/mailAlerts/global',
            'preferences/mailAlerts/ecm'
          ];
        }
        return this.route.data;
      }))
      .subscribe((data: { panel: string }) => {
        if (data && this.tabsVisibility) {
          switch (data.panel) {
            case 'global':
              this.activeIndex = 0;
              break;
            case 'ecm':
              this.activeIndex = 1;
              break;
            case 'mail-alerts':
              this.router.navigate([this.tabRouters[0]]);
          }
        }
      });
  }

  onChangeTab(event: {index: number}): void {
    this.router.navigate([this.tabRouters[event.index]]);
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}
