<form [formGroup]="form">
  <div class="inline-block margin-right-15">
    <label>Start Date: <span class="asterisk margin-right-6"></span>
      <p-calendar formControlName="startDate"
                  class="inline-block"
                  showIcon="true"
                  dateFormat="dd/mm/yy"
                  monthNavigator="true"
                  yearNavigator="true"
                  [ngClass]="{'error': this.errors['startDate']}"
                  cmsDateRestriction>
      </p-calendar>
    </label>
  </div>
  <div class="inline-block">
    <label>End Date: <span class="asterisk margin-right-6"></span>
      <p-calendar formControlName="endDate"
                  class="inline-block"
                  showIcon="true"
                  dateFormat="dd/mm/yy"
                  monthNavigator="true"
                  yearNavigator="true"
                  [ngClass]="{'error': this.errors['endDate']}"
                  cmsDateRestriction>
      </p-calendar>
    </label>
  </div>
</form>
