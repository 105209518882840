<h1>eSM invoice types matched by {{(organisationService.getChangeOrganisationObservable() | async)?.displayName}}
  ({{(organisationService.getChangeOrganisationObservable() | async)?.eicCode}})</h1>
<div class="panel-box">
  <cms-info ngPreserveWhitespaces>
    <u>__Supported Invoice Types/eSM Gatekeeper__</u>

    The below settings control which invoice types the CMS accepts for eSM with your organisation.

    __Instructions__
    * __Activate / block product__ (master operators only): Click on the pass/block icon to toggle your matching
    preference for that invoice type.
    * __View partners' preferences:__ Click on "Download eSM Gatekeeper Settings" to learn which CMS party has
    implemented which eSM invoice type for matching.
    * __Request product:__ Wish to go live on a invoice type not listed here? Please contact Support to have it added.

    __Rules__
    * All invoice types are blocked by default. Participants have to opt in.
    * The gatekeeper is only available to parties on the CMS and not to parties on external eSM vendor platforms.
    * You cannot block invoice types for specific partners. Your preferences apply to all partners.
    * If you block an invoice type, neither you nor your partners can submit a confirmation for that product - it will
    get rejected ("invalid match attempt").
    * Reversely your invoice is rejected if your matching partner blocks.
  </cms-info>
  <div class="row margin-0">
    <button (click)="onDownload()" pButton class="btn export-btn col-auto" icon="fa fa-download" type="button" label="&nbsp;Download eSM Gatekeeper Settings"></button>
  </div>
  <cms-settings-add-panel (clear)="onClear()"
                          (confirm)="onAdd($event)"
                          *ngIf="editTypesAllowed$ | async"
                          [commodities]="commodities$ | async"
                          [data]="presetValues$ | async"
                          [messages]="messages$ | async"
                          [natureOfPrices]="natureOfPrices$ | async"
                          [priceTypes]="priceTypes$ | async"
                          [types]="types$ | async">
  </cms-settings-add-panel>
  <cms-datatable [autoLayout]="true"
                 [data]="data$ | async"
                 [lazy]="false"
                 [loading]="loading$ | async"
                 [paginator]="false"
                 [scrollable]="false"
                 [tableColumns]="dataTableColumns"
                 styleClass="decent-max-width margin-top-9">
    <ng-template let-column="column" let-row="row" pTemplate="body">
      <td *ngIf="column.type === 'status'" [ngStyle]="column.style">
        <cms-cell-status (confirm)="onChangeStatus(row)"
                         [column]="column"
                         [editable]="toggleMatchAllowed$ | async"
                         [row]="row"
                         emptyTemplate="N/A">
        </cms-cell-status>
      </td>
      <td *ngIf="column.type === 'deleteButton' && (editTypesAllowed$ | async)" [ngStyle]="column.style">
        <div *ngIf="row.deleteButtonVisible" class="btn-wrapper">
          <button (click)="onDeleteItem(row.invoiceTypeId)" class="btn btn-icon fa fa-trash-o" label="Delete"
                  title="Delete Product" type="button"></button>
        </div>
      </td>
    </ng-template>
  </cms-datatable>
</div>
<ng-container *ngIf="dialog$ | async as dialog">
  <cms-settings-dialog
    (activateItem)="onActivateButton($event)"
    (blockItem)="onBlockItem($event)"
    (cancel)="onCancelButton($event)"
    *ngIf="dialog.name === 'TOGGLE'"
    [data]="dialog.data"
    [displayDialog]="!!(dialog$ | async)"
    [messages]="dialog.messages">
  </cms-settings-dialog>
</ng-container>
