import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { Ecm_Dashboard_Categories, EcmDashboardCategoriesMap, EcmDashboardTabs } from '../ecm-dashboard.model';
import { SetCategoryAction } from '../../state/dashboard/category/category.actions';

@Component({
  selector: 'cms-ecm-dashboard-deals',
  templateUrl: './ecm-dashboard-deals.component.html',
  styleUrls: ['./ecm-dashboard-deals.component.scss']
})
export class EcmDashboardDealsComponent implements OnInit, OnDestroy {
  readonly tabs = EcmDashboardTabs;
  readonly maps: { [key: string]: Ecm_Dashboard_Categories } = EcmDashboardCategoriesMap;

  activeIndex: number;
  subscriptions: Subscription = new Subscription();

  constructor(private router: Router,
              private route: ActivatedRoute,
              private store: Store<any>) { }

  ngOnInit() {
    this.subscriptions.add(this.route.data.subscribe((data: { panel: string }) => {
      const { panel } = data;
      this.activeIndex = this.tabs.indexOf(panel);
      this.store.dispatch(new SetCategoryAction(this.maps[panel]));
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onChangeTab(event: { index: number }): void {
    const tab = this.tabs[event.index];
    this.router.navigate([`ecm/dashboard/${tab}`]);
  }
}
