<h1>Exceptions (all organisations)</h1>
<section class="panel-box">
  <p-messages [value]="messages$ | async" [closable]="true"></p-messages>
  <div *ngIf="(data$ | async)?.values" class="col-12 info-panel margin-bottom-30" id="info-exceptions-block">
    <div class="fa fa-info-circle float-left"></div>
    <div class="margin-left-18">
      {{ (data$ | async).values.length }} failed process events found.
    </div>
  </div>
  <div class="col-xl-6 col-12 ps-0">
    <cms-exceptions-filter
      [formData]="filtersValue$ | async"
      [availableQueues]="avalableQueues$ | async"
      [disabled]="loading$ | async"
      (confirm)="onHandleFilter($event)">
    </cms-exceptions-filter>
  </div>
  <div class="exceptions__table_container margin-top-15">
    <div *ngIf="(loading$ | async)" class="margin-3">
      <i class="pi-spin pi pi-spinner"></i>
    </div>
    <cms-datatable
      *ngIf="(data$ | async)?.values"
      [data]="(data$ | async)?.values"
      [tableColumns]="dataTableColumns"
      [totalRecords]="(data$ | async)?.values?.length"
      [autoLayout]="true"
      [scrollable]="false"
      [lazy]="false"
      sortOrder="ASCENDING"
      sortField="queueName"
      [currentPage]="(paging$ | async).page"
      [rows]="(paging$ | async).entriesPerPage"
      (sortFunction)="onSort($event)"
      (pageFunction)="onPageChange($event)">
      <ng-template pTemplate="caption">
        <div class="flex justify-content-end">
          <span>Select&nbsp;
            <a (click)="onSelectToggle(true)" id="exceptions-select-link">ALL</a>&nbsp;|&nbsp;
            <a (click)="onSelectToggle(false)" id="exceptions-unselect-link">NONE</a></span>
        </div>
      </ng-template>
      <ng-template pTemplate="body" let-row="row" let-column="column" let-rowIndex="rowIndex">
        <td *ngIf="column.type === 'infoButton'" [ngStyle]="column.style">
          <div class="btn-wrapper">
            <button
              class="btn btn-icon fa fa-info-circle"
              id="exceptions-info-btn-{{rowIndex}}"
              (click)="onShowDetails(row)"
              [title]="column.title">
            </button>
          </div>
        </td>
        <td *ngIf="column.type ==='checkbox'" [ngStyle]="column.style">
          <div class="btn-wrapper inline-block">
            <input class="checkbox"
              name="checkboxGroup"
              id="exceptions-checkbox-{{rowIndex}}"
              type="checkbox"
              [checked]="(selectedItems$ | async)[row.messageId]"
              (click)="onCheck($event.target.checked, row.messageId)">
          </div>
        </td>
      </ng-template>
    </cms-datatable>
    <div class="flex justify-content-end align-items-center" *ngIf="(data$ | async)?.values?.length">
      <button
        class="btn margin-top-9 margin-bottom-9"
        id="exceptions-delete-button"
        (click)="onHandleDelete()"
        [disabled]="disabled$ | async">Delete</button>
      <button
        class="btn margin-top-9 margin-bottom-9 margin-left-12"
        id="exceptions-retry-button"
        (click)="onHandleRetry()"
        [disabled]="disabled$ | async">Retry</button>
    </div>
  </div>
</section>
<section class="padding-top-9" *ngIf="(editedItems$ | async)?.length && !(loading$ | async)">
  <p *ngFor="let item of (editedItems$ | async)">
    {{item.queueName}}({{item.messageId}}): {{item.reason}}
  </p>
</section>
<ng-container *ngIf="dialog$ | async as dialog">
  <cms-delete-exceptions-dialog
    *ngIf="dialog?.name === 'DELETE'"
    [display]="dialog?.name === 'DELETE'"
    [messages]="dialog?.messages"
    (confirm)="onConfirmDelete($event)"
    (cancel)="onDialogCancel($event)">
  </cms-delete-exceptions-dialog>
</ng-container>
<ng-container *ngIf="dialog$ | async as dialog">
  <cms-exceptions-info-dialog
    *ngIf="dialog?.name === 'INFO'"
    [display]="dialog?.name === 'INFO'"
    [data]="dialog?.data"
    (cancel)="onDialogCancel($event)">
  </cms-exceptions-info-dialog>
</ng-container>
