import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemitFilesOutComponent } from './remit-files-out.component';
import { CmsCommonModule } from '@common/cms-common.module';
import { RemitFilesOutSearchComponent } from './remit-files-out-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RemitFilesOutService } from './remit-files-out.service';
import { RemitFilesOutDatatableComponent } from './remit-files-out-datatable.component';
import { RouterModule } from '@angular/router';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MessagesModule } from 'primeng/messages';

@NgModule({
    imports: [
        CommonModule,
        CmsCommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        TableModule,
        ButtonModule,
        DropdownModule,
        MultiSelectModule,
        CalendarModule,
        RadioButtonModule,
        MessagesModule
    ],
  providers: [RemitFilesOutService],
  declarations: [RemitFilesOutComponent, RemitFilesOutSearchComponent, RemitFilesOutDatatableComponent]
})
export class RemitFilesOutModule {
}
