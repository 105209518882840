<p-dialog *ngIf="display"
          header="Add User Account"
          [visible]="display"
          [modal]="true"
          (visibleChange)="onVisibleChange($event)"
          [style]="{'min-width': '600px'}">
  <p-messages [value]="messages" [closable]="true"></p-messages>
  <div class="margin-15">
    <form [formGroup]="form" class="row" autocomplete="off">
      <div class="col-12 margin-bottom-12 flex">
        <label class="col-4 float-left">User Name:<span class="text-required">*</span></label>
        <div class="col-8">
          <input autocomplete="off" id="account-username" type="text" formControlName="userName" class="form-control col-8 padding-6" [ngClass]="{'error': errors['userName']}" maxlength="25" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label class="col-4 float-left">First Name:<span class="text-required">*</span></label>
        <div class="col-8">
          <input autocomplete="off" id="account-firstname" type="text" formControlName="firstName" class="form-control col-8 padding-6" [ngClass]="{'error': errors['firstName']}" maxlength="25" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label class="col-4 float-left">Second Name:<span class="text-required">*</span></label>
        <div class="col-8">
          <input autocomplete="off" id="account-secondName" type="text" formControlName="secondName" class="form-control col-8 padding-6" [ngClass]="{'error': errors['secondName']}" maxlength="25" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label class="col-4 float-left">Email:<span class="text-required">*</span></label>
        <div class="col-8">
          <input autocomplete="off" id="account-email" type="text" formControlName="email" class="form-control col-8 padding-6" [ngClass]="{'error': errors['email']}" maxlength="60" />
        </div>
      </div>
      <div class="col-12 margin-bottom-21">

        <cms-info ngPreserveWhitespaces header="Password Policy Requirements">
          New password must have <strong>between 8 to 16</strong> characters.<br />
          It must contain at least<br />
          <strong>one lower-case letter</strong>: a-z<br />
          <strong>one upper-case letter</strong>: A-Z<br />
          <strong>one number or special character</strong>: 0-9 $ ! + - _ . ,<br />
          Only the above characters are allowed.<br />
          New password must be different from your last 3 passwords.
        </cms-info>

      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label class="col-4 float-left">Password:
          <span class="text-required">*</span>
        </label>
        <div class="col-8">
          <input autocomplete="off" type="password" formControlName="password" class="form-control col-8 padding-6" [ngClass]="{'error': errors['password']}" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label class="col-4 float-left">Confirm Password:
          <span class="text-required">*</span>
        </label>
        <div class="col-8">
        <input autocomplete="off" type="password" formControlName="confirmPassword" class="form-control col-8 padding-6" [ngClass]="{'error': errors['confirmPassword']}" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex" *ngIf="tokenIdVisible">
        <label class="col-4 float-left">Token ID:
          <span class="text-required">*</span>
        </label>
        <div class="col-8">
        <input autocomplete="off" type="text" formControlName="tokenId" class="form-control col-8 padding-6" [ngClass]="{'error': errors['tokenId']}" [maxlength]="32" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label class="col-4 float-left">Job Title:</label>
        <div class="col-8">
        <input autocomplete="off" id="account-jobtitle" type="text" formControlName="jobTitle" tabindex="2" class="form-control col-8 padding-6" [maxlength]="70" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label class="col-4 float-left">Phone:</label>
        <div class="col-8">
        <input autocomplete="off" id="account-phone" type="text" formControlName="phone" tabindex="3" class="form-control col-8 padding-6" [maxlength]="25" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label class="col-4 float-left">Mobile:</label>
        <div class="col-8">
        <input autocomplete="off" id="account-mobile" type="text" formControlName="mobile" tabindex="4" class="form-control col-8 padding-6" [maxlength]="25" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label class="col-4 float-left">Contact Other:</label>
        <div class="col-8">
        <textarea autocomplete="off" col="3" id="account-contactother" formControlName="contactOther" tabindex="5" class="form-control col-8 padding-6" style="height: 4em;"></textarea>
        </div>
      </div>
    </form>
  </div>
  <p-footer>
    <button class="btn margin-right-3" type="button" (click)="onCancel()">Cancel</button>
    <button class="btn" type="button" (click)="onConfirm()">Save</button>
  </p-footer>
</p-dialog>
