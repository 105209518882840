import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChange,
  ViewChildren
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'cms-non-editable-textarea',
  templateUrl: './non-editable-textarea.component.html',
  styleUrls: ['./non-editable-textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NonEditableTextareaComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChildren('textarea', { read: ElementRef }) inputEl$: QueryList<ElementRef>;
  @Input() value: string;
  @Input() container;
  form: UntypedFormGroup;
  readable: boolean;
  textarea: ElementRef;

  constructor(private formBuilder: UntypedFormBuilder,
              private changeDetection: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.readable = false;
    this.form = this.formBuilder.group({
      value: this.value
    });
  }

  ngAfterViewInit(): void {
    this.inputEl$.changes.subscribe((elementRef) => {
      if (elementRef.length) {
        setTimeout(() => {
          this.textarea = elementRef.first;
          const elem = this.textarea.nativeElement;
          elem.focus();
          elem.setSelectionRange(0, 0);
          elem.scrollTop = 0;
          this.container.classList.add('overflow-visible');
        });
      } else {
        this.textarea = null;
      }
    });
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (this.form && changes.value) {
      this.form.patchValue({
        value: this.value
      });
      this.form.updateValueAndValidity();
    }
  }

  onRead(): void {
    setTimeout(() => {
      this.readable = true;
      this.changeDetection.detectChanges();
    }, 0);
  }

  onBlur(): void {
   setTimeout(() => {
      this.form.patchValue({
        value: this.value
      });
      this.container.classList.remove('overflow-visible');
      this.readable = false;
     this.changeDetection.detectChanges();
    }, 0);
  }
}
