import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SelectItem, SelectItemGroup } from 'primeng/api';
import { RemitTradeEntryTable2Service } from '../remit-trade-entry-table2.service';
import { takeUntil } from 'rxjs/operators';
import { RemitTradeEntryTable2OptionDetailsDataModel } from '../remit-trade-entry-table2-data.model';
import { Subject } from 'rxjs';
import { RemitTable2TradeOptionDetailModel } from '../../../trade/table2/modify/remit-table2-trade-entry.model';

@Component({
  selector: 'cms-remit-trade-entry-table2-option-details',
  templateUrl: './remit-trade-entry-table2-option-details.component.html',
  styleUrls: ['./remit-trade-entry-table2-option-details.component.scss']
})
export class RemitTradeEntryTable2OptionDetailsComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  formGroup: UntypedFormGroup;

  @Input()
  onReset: Subject<boolean>;

  @Input()
  data: RemitTable2TradeOptionDetailModel[];

  optionTypes: SelectItem[];
  optionStyles: SelectItem[];
  exerciseFrequencies: SelectItem[];
  strikeIndices: SelectItemGroup[];
  strikeIndexTypes: SelectItemGroup[];
  currencies: SelectItemGroup[];

  optionDetailsData: RemitTradeEntryTable2OptionDetailsDataModel;

  private onDestroy$ = new Subject();

  constructor(private formBuilder: UntypedFormBuilder, private tradeEntryService: RemitTradeEntryTable2Service) {
    this.onReset = new Subject<boolean>();
  }

  ngOnInit() {

    this.initForm();

    this.tradeEntryService.onTradeEntryDataChange().pipe(takeUntil(this.onDestroy$)).subscribe(formData => {
      if (formData && formData.optionDetails) {
        this.optionDetailsData = formData.optionDetails;
      }

      this.initFormValues();
    });

    this.onReset.subscribe(reset => {
      if (reset) {
        this.resetForm();
      }
    });

    this.tradeEntryService.onValidationFieldErrors().pipe(takeUntil(this.onDestroy$))
      .subscribe(
        fieldName => {
          if (fieldName && fieldName.indexOf('optionDetails') >= 0) {
            const items = this.formGroup.controls['optionDetails'] as UntypedFormArray;

            const parse = fieldName.split('.');
            const index = parse[0].charAt(parse[0].length - 1);
            const formGroup = items.controls[parseInt(index, 10)] as UntypedFormGroup;

            if (parse[1]) {
              switch (parse[1]) {
                case 'lastExerciseDate':
                  formGroup.controls['lastExerciseDate'].setErrors({ error: true });
                  break;
                case 'price':
                  formGroup.controls['price'].setErrors({ error: true });
                  break;
              }
            }
          }
        }
      );
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.fillFormWithData();
  }

  get optionDetails(): AbstractControl[] {
    return (this.formGroup.controls['optionDetails'] as UntypedFormArray).controls;
  }

  add() {

    const optionDetails = this.formGroup.controls['optionDetails'] as UntypedFormArray;

    if (optionDetails.length < 2) {

      optionDetails.push(
        this.formBuilder.group({
          optionStyle: [''],
          optionType: [''],
          firstExerciseDate: [''],
          lastExerciseDate: [''],
          exerciseFrequency: [''],
          strikeIndex: [''],
          indexType: [''],
          strikeIndexSource: [''],
          price: [''],
          priceCurrencyCode: [''],
        })
      );
    }
  }

  remove() {
    const optionDetails = this.formGroup.controls['optionDetails'] as UntypedFormArray;

    if (optionDetails.length > 0) {
      optionDetails.removeAt(optionDetails.length - 1);
    }
  }

  get disableAddButton(): boolean {
    return this.optionDetails.length > 1 || this.formGroup.disabled;
  }

  get disableRemoveButton(): boolean {
    return this.optionDetails.length < 1 || this.formGroup.disabled;
  }

  convertKeyValueToSelectItem(keyValueData: any): SelectItem[] {
    if (keyValueData) {
      return keyValueData.map(method => ({
          label: method.value,
          value: method.key
        }));
    }
    return [];
  }

  getOptionDetailIndexErrorClass(index: number, fieldName: string): string {

    const items = this.formGroup.get('optionDetails') as UntypedFormArray;
    if (items && items.controls[index]) {

      const formGroup = items.controls[index] as UntypedFormGroup;

      if (formGroup.controls[fieldName].hasError('error')) {
        return ' error';
      }
    }

    return '';
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
    this.onReset.unsubscribe();
  }

  private initForm() {
    this.formGroup.addControl('optionDetails', this.formBuilder.array(
      []
    ));

  }

  private resetForm() {
    this.formGroup.removeControl('optionDetails');
    this.initForm();
  }

  private initFormValues() {
    if (this.optionDetailsData) {
      this.optionTypes = this.convertKeyValueToSelectItem(this.optionDetailsData.optionTypes);
      this.optionStyles = this.convertKeyValueToSelectItem(this.optionDetailsData.optionStyles);
      this.exerciseFrequencies = this.convertKeyValueToSelectItem(this.optionDetailsData.exerciseFrequencies);
      this.strikeIndices = this.tradeEntryService.getGroupedValues(this.optionDetailsData.strikeIndices);
      this.strikeIndexTypes = this.tradeEntryService.getGroupedValues(this.optionDetailsData.strikeIndexTypes);
      this.currencies = this.tradeEntryService.getGroupedValues(this.optionDetailsData.currencies);

      this.fillFormWithData();
    }
  }

  private fillFormWithData() {
    if (this.formGroup && this.data) {

      const optionDetails = this.formGroup.controls['optionDetails'] as UntypedFormArray;
      if (optionDetails) {
        let optionDetail: RemitTable2TradeOptionDetailModel;
        for (let i = 0; i < this.data.length; i++) {

          if (!optionDetails.at(i)) {
            this.add();
          }

          optionDetail = {
            optionStyle: this.data[i].optionStyle,
            optionType: this.data[i].optionType,
            exerciseFrequency: this.data[i].exerciseFrequency,
            strikeIndex: this.data[i].strikeIndex,
            indexType: this.data[i].indexType,
            strikeIndexSource: this.data[i].strikeIndexSource,
            price: this.data[i].price,
            priceCurrencyCode: this.data[i].priceCurrencyCode
          };

          if (this.data[i].firstExerciseDate) {
            optionDetail = {
              ...optionDetail,
              firstExerciseDate: new Date(this.data[i].firstExerciseDate)
            };
          }
          if (this.data[i].lastExerciseDate) {
            optionDetail = {
              ...optionDetail,
              lastExerciseDate: new Date(this.data[i].lastExerciseDate)
            };
          }

          optionDetails.at(i).patchValue(optionDetail);
        }

      }
    }
  }
}
