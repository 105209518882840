import { ErrorResponse } from '@common/error-response.model';
import { Column } from '@common/shared/datatable.model';
import { defaultStyles } from '../../../../invoice/dashboard/esm-dashboard.model';
import { MY_FILTERS_DIALOGS } from '@common/my-filters/my-filters.model';
import { DEALS_DIALOGS } from '@common/deals/deals.model';

export interface NettedInvoicesRequestModel {
  counterPartyId: number;
  caseNumber: number;
  myVAT: string;
  cpVAT: string;
  dueDate: string;
  physicalFinancial: string;
  commodities: string [];
  currency: string;
  agreement: string;
  masterAgreementVersion: string;
}

export interface AggregationKeys {
  physicalFinancial: 'PHYSICAL' | 'FINANCIAL';
  currency: string;
  commodities: string[];
  agreement: string;
  masterAgreementVersion: string;
}

export interface EsmNettedInvoicesTableDataResponse extends ErrorResponse {
  count: number;
  currentPage: number;
  values: EsmNettedInvoices[];
  notFoundIds?: string[];
  columnOrder: string[];

}

export const allColumnsDealConfig: Column[] = [
  { name: 'submissionTimestamp', type: 'dateWithoutTime', title: 'Sub. Date', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'invoiceNumber', type: 'text', title: 'Invoice no#', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'receiverId', type: 'text', title: 'Receiver EIC', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'physicalFinancial', type: 'text', title: 'Physical Financial', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'commodity', type: 'text', title: 'Commodity', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'deliveryPointArea', type: 'text', title: 'Delivery Point', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'agreement', type: 'text', title: 'Agreement', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'openDocument', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'openDocumentXml', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'auditTrail', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'paymentDate', type: 'text', title: 'Payment Date', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'invoicePeriodStart', type: 'text', title: 'Inv. Period Start', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'invoicePeriodEnd', type: 'text', title: 'Inv. Period End', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'totalVolumeUnit', type: 'text', title: 'Total Volume Unit', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'state', type: 'text', title: 'State', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'totalGrossAmount', type: 'currency', title: 'Total Gross Amount', style: { ...defaultStyles, 'text-align': 'right' }, sortable: true, visible: true },
  { name: 'currency', type: 'text', title: 'Curr.', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'totalAmount', type: 'currency', title: 'Total Amount', style: { ...defaultStyles, 'text-align': 'right' }, sortable: true, visible: true },
  { name: 'vatAmount', type: 'currency', title: 'VAT Amt', style: { ...defaultStyles, 'text-align': 'right' }, sortable: true, visible: true },
  { name: 'vatRate', type: 'percent', title: 'VAT rate', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'vatAmountCurrency', type: 'text', title: 'VAT Curr.', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'notes', type: 'button', title: '', style: { ...defaultStyles, width: '80px' }, sortable: false, visible: true },
  { name: 'nearMatch', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'misMatch', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'pdf', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'taxPoint', type: 'text', title: 'Tax Point', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'masterAgreementVersion', type: 'text', title: 'Master Agreement Version', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'invoiceDate', type: 'text', title: 'Invoice Date', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'totalVolume', type: 'text', title: 'Total Volume', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'vatAmountDomestic', type: 'text', title: 'Vat Amount Domestic', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'vatAmountDomesticCurrency', type: 'text', title: 'Vat Amount Domestic Currency', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'totalGrossAmountCurrency', type: 'text', title: 'Total Gross Amount Currency', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'totalAmountCurrency', type: 'text', title: 'Total Amount Currency', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'documentId', type: 'text', title: 'Document Id', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'documentVersion', type: 'text', title: 'Document Version', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'fixedFloating', type: 'text', title: 'Fixed Floating', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'natureOfPrice', type: 'text', title: 'Nature Of Price', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'marketInformation', type: 'text', title: 'Market Information', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'selfBilling', type: 'text', title: 'Self Billing', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'supplierId', type: 'text', title: 'Supplier Id', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'customerId', type: 'text', title: 'Customer Id', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'purchaseOrderNumber', type: 'text', title: 'Purchase Order Number', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'senderId', type: 'text', title: 'Sender Id', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'documentType', type: 'text', title: 'Document Type', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'customerDisplayName', type: 'text', title: 'Customer Name', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'supplierDisplayName', type: 'text', title: 'Supplier Name', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'resend', type: 'button', title: '', style: { ...defaultStyles, width: '80px' }, sortable: false, visible: false },
  { name: 'processType', type: 'text', title: 'Process Type', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'agree', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: false },
  { name: 'dispute', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: false }
];

export type INVOICE_FINDER_DIALOGS = MY_FILTERS_DIALOGS | DEALS_DIALOGS;

export interface EsmNettedInvoices {
  agreement: string;
  commodity: string;
  currency: string;
  customerId: string;
  deliveryPointArea: string;
  documentId: string;
  documentType: string;
  documentVersion: number;
  fixedFloating: string;
  id: number;
  invoiceDate: string;
  invoiceNumber: string;
  invoicePeriodEnd: string;
  invoicePeriodStart: string;
  lastUpdateUtc: string;
  marketInformation: string;
  masterAgreementVersion: string;
  matchType: string;
  natureOfPrice: string;
  paymentDate: string;
  physicalFinancial: string;
  purchaseOrderNumber: string;
  receiverId: string;
  selfBilling: boolean;
  senderId: string;
  state: string;
  submissionTimestamp: string;
  supplierId: string;
  taxPoint: string;
  totalAmount: number;
  totalAmountCurrency: string;
  totalGrossAmount: number;
  totalGrossAmountCurrency: string;
  totalVolume: number;
  totalVolumeUnit: string;
  vatAmount: number;
  vatAmountCurrency: string;
  vatAmountDomestic: number;
  vatAmountDomesticCurrency: string;
  vatRate: number;
  notes: string;
  nearMatchButtonVisible: boolean;
  pdfButtonVisible: boolean;
  customerDisplayName: string;
  supplierDisplayName: string;
  processType: string;
}


