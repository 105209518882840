import { Message } from 'primeng/api';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiRequestService } from '@common/api-request.service';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { toPayload } from '@common/cms-common.model';
import { AddingInvoiceTypeResponse, PresetValuesObj, URL_GATEKEEPER_INVOICE } from '../../../../settings/invoice/invoice-settings.model';
import * as fromEsmSettingsAddPanelAction from './add-panel.actions';
import * as fromTableActions from '../table/table.actions';
import { OrganisationService } from '@common/organisation.service';

@Injectable()
export class EsmSettingsAddPanelEffects {

  private actionsFiltered$ = this.actions$.pipe(filter(() => this.organisationService.getCurrentOrganisationId() !== null));


  constructor(private actions$: Actions,
              private apiGateway: ApiRequestService,
              private mapper: MessagesMapperService,
              private organisationService: OrganisationService) {
  }

  loadPresetValues$ = createEffect(() =>
    this.actionsFiltered$.pipe(
      ofType(fromEsmSettingsAddPanelAction.loadPresetValuesAction.type),
      switchMap(() =>
        this.apiGateway.get(`${URL_GATEKEEPER_INVOICE}/loadPresetValues`)
          .pipe(
            switchMap((response: PresetValuesObj) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return of(fromTableActions.setMessagesAction(messages));
              }
              return [
                fromEsmSettingsAddPanelAction.loadPresetValuesSuccessAction(response.value),
                fromTableActions.setToggleMatchAllowed(response.value.toggleMatchAllowed),
                fromTableActions.setEditTypesAllowed(response.value.editTypesAllowed),
                fromTableActions.setMessagesAction([]),
              ];
            }),
            catchError(error => of(fromTableActions.setMessagesAction([
              this.mapper.createErrorMessage(error.errorMessage || 'Server Error')
            ]))))
      )
    )
  );

  addInvoiceType$ = createEffect(() =>
    this.actionsFiltered$.pipe(
      ofType(fromEsmSettingsAddPanelAction.addInvoiceTypeAction.type),
      map(toPayload),
      switchMap(({ commodity, natureOfPrice, priceType, type }) =>
        this.apiGateway.post(`${URL_GATEKEEPER_INVOICE}/addInvoiceType`, { commodity, natureOfPrice, priceType, type })
          .pipe(
            switchMap((response: AddingInvoiceTypeResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return of(fromTableActions.setMessagesAction(messages));
              }
              return [
                fromEsmSettingsAddPanelAction.addInvoiceTypeSuccessAction(response.value),
                fromTableActions.loadTableDataAction(),
                fromEsmSettingsAddPanelAction.cleanAddProductAction(),
                fromTableActions.setMessagesAction(this.mapper.toSuccessMessages('Invoice type has been added successfully'))
              ];
            }),
            catchError(error => of(fromTableActions.setMessagesAction([
              this.mapper.createErrorMessage(error.errorMessage || 'Server Error')
            ]))))
      )
    )
  );
}
