import {createAction, union} from '@ngrx/store';

export const deleteElcomWhitelistAction = createAction('[ELCOM WHITELIST] Delete', (payload: string) => ({payload}));
export const addElcomWhitelistAction = createAction('[ELCOM WHITELIST] Add', (payload: string) => ({payload}));
export const addElcomWhitelistSuccessAction = createAction('[ELCOM WHITELIST] Add Success');
export const clearElcomWhitelistAction = createAction('[ELCOM WHITELIST] Clear');
export const setToDefaultAction = createAction('[ELCOM WHITELIST] Set To Default');

const actions = union({
  deleteElcomWhitelistAction,
  addElcomWhitelistAction,
  addElcomWhitelistSuccessAction,
  setToDefaultAction,
  clearElcomWhitelistAction
});

export type ElcomWhitelistActions = typeof actions;
