<div id="div-remit-omp-whitelist-upload-panel" class="panel-box margin-bottom-12" *ngIf="uploadPanelVisible">
  <form [formGroup]="uploadForm" class="row align-items-center col-12 col-lg-8" (ngSubmit)="submit()">
    <label class="margin-3 padding-6">Replace MP whitelist: (.csv file)</label>
    <div class="col-12 col-md-6 padding-0 margin-right-6">
      <input id="input-text-filename-remit-omp-whitelist" type="text" class="form-control p-component padding-6 text-medium" [value]="fileName" readonly>
    </div>
    <button id="btn-browse-remit-omp-whitelist" class="btn form-control margin-3 padding-6" style="width: 75px;" (click)="browseFiles();">Browse...</button>
    <button id="btn-submit-remit-omp-whitelist" type="submit" style="width: 75px;" class="btn form-control margin-3 padding-6" [disabled]="!fileSelected">Upload</button>
    <input id="input-file-filename-remit-omp-whitelist" formControlName="csvFile" type="file" class="hidden" (change)="onSelect($event)" accept=".csv" #fileUpload>
    <div class="margin-3 padding-6">
      <span style="font-weight: bold;">Attention:</span>
      By uploading MP codes you attest that REMIT reporting agreements are in place with the designated MPs
    </div>
  </form>

</div>
