import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  EsmNettingStatementFinderFormValues,
  EsmNettingStatementUserFilter,
  NettingStatementFinderPropertyFilter
} from '../esm-netting-finder.model';
import * as moment from 'moment';
import {
  emptyDealsValue,
  getFilterPropertyMultiselectLabelValue,
  Sender,
  updateDealsMultiSelect,
  updateDealsMultiSelectLabelValue
} from '@common/deals/deals.model';

@Component({
  selector: 'cms-esm-netting-finder-filter',
  templateUrl: './esm-netting-finder-filter.component.html',
  styleUrls: ['./esm-netting-finder-filter.component.scss']
})
export class EsmNettingFinderFilterComponent implements  OnInit, OnChanges {

  @Input() loading: boolean = false;
  @Input() myFilters: EsmNettingStatementUserFilter[];
  @Input() defaultMyFilter: EsmNettingStatementUserFilter;
  @Input() allDealsFilter: EsmNettingStatementUserFilter = {
    counterpartyFilter: [],
    defaultFilter: true,
    hiddenType: Sender.US,
    nettingStatementTypeFilter: [],
    deliveryPointFilter: [],
    commodityFilter: [],
    filterName: 'All Statements'
  };

  @Input() filter: NettingStatementFinderPropertyFilter;
  @Input() formValues: EsmNettingStatementFinderFormValues = {
    nettingStatementTypes: [],
    deliveryPoints: [],
    commodities: [],
    counterParties: []
  };
  @Output() onFilter = new EventEmitter<NettingStatementFinderPropertyFilter>();
  @Output() onReset = new EventEmitter();

  readonly defaultMaxDate = moment().toDate();
  defaultFilter: NettingStatementFinderPropertyFilter = {
    senderUs: true,
    nettingStatementTypes: [],
    deliveryPoints: [],
    commodities: [],
    counterParties: [],
    invoicePeriod: null,
    paymentDateRange: null,
    submissionDateRange: null
  };

  form: UntypedFormGroup;
  counterPartyOptions: { label: string, value: string }[] = [];
  deliveryPointOptions: { label: string, value: { keyObject: string, valueObject: string } }[] = [];
  commodityOptions: { label: string, value: string }[] = [];
  nettingStatementTypeOptions: { label: string, value: string }[] = [];

  constructor(private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {

    const {
      commodities,
      counterParties,
      deliveryPoints,
      nettingStatementTypes,
      submissionDateRange,
      invoicePeriod,
      paymentDateRange,
      senderUs
    } = this.filter || this.defaultFilter;

    this.form = this.formBuilder.group({
      commodities: { value: commodities, disabled: !this.formValues.commodities || !this.formValues.commodities.length },
      counterParties: { value: counterParties, disabled: !this.formValues.counterParties || !this.formValues.counterParties.length },
      deliveryPoints: { value: deliveryPoints, disabled: !this.formValues.deliveryPoints || !this.formValues.deliveryPoints.length },
      nettingStatementTypes: { value: nettingStatementTypes, disabled: !this.formValues.nettingStatementTypes || !this.formValues.nettingStatementTypes.length },
      submissionDateStart: submissionDateRange && submissionDateRange.start,
      submissionDateEnd: submissionDateRange && submissionDateRange.end,
      paymentDateStart: paymentDateRange && paymentDateRange.start,
      paymentDateEnd: paymentDateRange && paymentDateRange.end,
      invoicePeriodStartDate: invoicePeriod && invoicePeriod.start,
      invoicePeriodEndDate: invoicePeriod && invoicePeriod.start,
      senderUs,
    });

    this.manageFormControls();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.form && changes.filter) {
      const {
        commodities,
        counterParties,
        deliveryPoints,
        nettingStatementTypes,
        invoicePeriod,
        senderUs,
        paymentDateRange,
        submissionDateRange
      } = this.filter;

      this.form.patchValue({
        commodities: commodities,
        counterParties,
        deliveryPoints,
        nettingStatementTypes,
        invoicePeriodStartDate:  invoicePeriod ? invoicePeriod.start : null ,
        invoicePeriodEndDate:  invoicePeriod ? invoicePeriod.end : null ,
        senderUs,
        submissionDateStart: submissionDateRange ? submissionDateRange.start : null,
        submissionDateEnd: submissionDateRange ? submissionDateRange.end : null,
        paymentDateStart: paymentDateRange ? paymentDateRange.start : null,
        paymentDateEnd: paymentDateRange ? paymentDateRange.end : null,
      });
      this.manageFormControls();
    }

    if(changes.formValues && this.formValues){
      this.counterPartyOptions = this.formValues.counterParties ? this.formValues.counterParties.map(item => ({label: item.value, value: item.key})) : this.counterPartyOptions;
      this.deliveryPointOptions = this.formValues.deliveryPoints ? this.mapToMultiselect(this.formValues.deliveryPoints) : this.deliveryPointOptions;
      this.commodityOptions = this.formValues.commodities ? this.formValues.commodities.map(item => ({label: item, value: item})) : this.commodityOptions;
      this.nettingStatementTypeOptions = this.formValues.nettingStatementTypes ? this.formValues.nettingStatementTypes.map(item => ({label: item, value: item})) : this.nettingStatementTypeOptions;

    }
  }

  private manageFormControls(): void {
    ['commodities', 'counterParties', 'deliveryPoints', 'nettingStatementTypes'].forEach(key => {
      if(!this.form.controls[key].value || !this.form.controls[key].value.length){
        this.form.controls[key].disable();
        this.form.controls[key].clearValidators();
      }else {
        this.form.controls[key].enable();
        this.form.controls[key].setValidators(Validators.required);
      }
    });

    this.form.updateValueAndValidity();
  }

  setFilter(): void {
      const {
        commodities,
        counterParties,
        deliveryPoints,
        nettingStatementTypes,
        invoicePeriodStartDate,
        invoicePeriodEndDate,
        senderUs,
        submissionDateStart,
        submissionDateEnd,
        paymentDateStart,
        paymentDateEnd,
      } = this.form.getRawValue();

    this.onFilter.emit({
      commodities,
      counterParties,
      deliveryPoints,
      nettingStatementTypes,
      senderUs,
      invoicePeriod: {
        start: invoicePeriodStartDate,
        end: invoicePeriodEndDate,
      },
      paymentDateRange: {
        start: paymentDateStart,
        end: paymentDateEnd
      },
      submissionDateRange: {
        start: submissionDateStart,
        end: submissionDateEnd
      }
    });
  }

  reset(): void {
    this.onReset.emit();
  }

  private mapToMultiselect(values: { keyObject: string, valueObject: string }[]): { label: string; value: { valueObject: string; keyObject: string }}[] {
    return values.map(({ keyObject, valueObject }) => ({
      label: getFilterPropertyMultiselectLabelValue(keyObject, valueObject),
      value: { keyObject, valueObject }
    }));
  }
}
