import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Message } from 'primeng/api';
import { RoboDelegatorsService } from './robo-delegators.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ErrorMessageService } from '@common/errors/error-message.service';

@Component({
  selector: 'cms-robo-delegators',
  templateUrl: './robo-delegators.component.html',
  styleUrls: ['./robo-delegators.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class RoboDelegatorsComponent implements OnInit, OnDestroy {

  messages: Message[] = [];
  messages$: Observable<Message[]>;
  private onDestroy$ = new Subject();

  constructor(private roboDelegatorsService: RoboDelegatorsService, public errorMessageService:ErrorMessageService) {
  }

  ngOnInit() {

    this.messages$ = this.roboDelegatorsService.onMessageChange()
      .pipe(map(m => this.messages = m ? [...this.messages, m] : []));
  }

  ngOnDestroy(): void {
    this.roboDelegatorsService.setMessage(null);
    this.messages = [];
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

}
