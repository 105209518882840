import { Table, TableHeaderCheckbox } from 'primeng/table';
import { LazyLoadEvent, Message, SortEvent, PrimeTemplate } from 'primeng/api';
import {
  AfterContentInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges, OnInit,
  Output,
  QueryList,
  SimpleChange,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Column, DatatableConfig, PagerInfo } from '../shared/datatable.model';
import { SortingFilter, SortOrder, PagingFilter } from '../shared/results.model';
import { DatatableSortDirection, DatatableState } from './datatable.model';

@Component({
  selector: 'cms-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatatableComponent implements OnChanges, AfterContentInit {
  @ViewChild('primeTable', { static: true }) primeTable: Table;
  @Input() tableId: string = '';
  @Input() dataKey: string;
  @Input() loading: boolean = false;
  @Input() hidden: boolean = false;
  @Input() customSort = false;
  @Input() messages: Message[] = [];
  @Input() data: any[] = [];
  @Input() currentPage = 0;
  @Input() totalRecords: number;
  @Input() sortOrder: SortOrder = 'ASCENDING';
  @Input() rows: number = DatatableConfig.DEFAULT_ROWS;
  @Input() rowsPerPageOptions: number[] = DatatableConfig.ROW_SELECTION_NUMBERS;
  @Input() sortField = '';
  @Input() paginator = true;

  @Input() tableColumns: Column[] = [];
  @Input() selected: any;
  @Input() selectionMode: 'single' | null = null;
  @Input() lazy = true;
  @Input() lazyLoadOnInit = true;
  @Input() autoLayout: boolean = false;
  @Input() scrollable: boolean = true;
  @Input() checkboxRow: boolean = false;
  @Input() styleClass;
  @Input() postFix = 24;
  @Input() tooltipDisabled: boolean = true;
  @Output() selectedChange = new EventEmitter<any>();
  @Output() lazyLoad = new EventEmitter<DatatableState>();
  @Output() sortFunction = new EventEmitter<SortingFilter>();
  @Output() pageFunction = new EventEmitter<PagingFilter>();
  @ContentChildren(PrimeTemplate) templates: QueryList<PrimeTemplate>;
  sortOrderValue: DatatableSortDirection = 1;
  captionTemplate: TemplateRef<any>;
  columnsTemplate: TemplateRef<any>;
  customHeaderTemplate: TemplateRef<any>;
  customBodyTemplate: TemplateRef<any>;
  customEmptyMessageTemplate: TemplateRef<any>;
  footerTemplate: TemplateRef<any>;
  rowexpansionTemplate: TemplateRef<any>;
  pagerInfo: PagerInfo;
  first = 0;
  innerSortField;
  innerSortOrder: number;
  private sortFieldByLazyLoad;
  private sortOrderByLazyLoad;
  private textWidth = document.createElement('canvas').getContext('2d');

  private selectedAll = false;

  @Input() rowTrackBy = (index: number, item: any) => item;
  @Input() columnTrackBy = (index: number, column: Column) => column;

  onLazyLoad({first, rows, sortField, sortOrder}: LazyLoadEvent): void {
    this.currentPage = first / rows;
    this.rows = rows;
    this.sortFieldByLazyLoad = sortField;
    this.sortOrderByLazyLoad = sortOrder;

    setTimeout(() => {
      this.lazyLoad.emit({
        page: this.currentPage,
        sortField: sortField || this.sortField,
        perPage: this.rows,
        sortOrder: this.getOutOrder(sortOrder)
      });
    });
  }

  onSort(event: SortEvent): void {
    if(!this.customSort) {
      this.sortFunction.emit({
        columnName: event.field,
        sortOrder: this.getOutOrder(event.order)
      });
    }
  }

  onCustomSort(event: SortEvent): void {
    this.sortFunction.emit({
      columnName: event.field,
      sortOrder: this.getOutOrder(event.order)
    });

  }

  onSelectionChange(): void {
    this.selectedChange.emit(this.selected);
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (changes.data) {
      if (!this.data || !this.data.length) {
        this.first = 0;
        this.currentPage = 0;
        this.totalRecords = 0;
      } else {
        this.first = this.currentPage * this.rows;
      }

      this.pagerInfo = PagerInfo.calcPagerInfo(this.totalRecords, this.currentPage, this.rows);
    }

    if (changes.sortField) {
      // to prevent double lazy loading when sort by new field
      if (this.lazy) {
        if (this.sortFieldByLazyLoad !== this.sortField) {
          this.innerSortField = this.sortField;
        }
      } else {
        this.innerSortField = this.sortField;
      }
    }

    if (changes.sortOrder && changes.sortOrder.previousValue !== changes.sortOrder.currentValue) {
      // to prevent double lazy loading when sort by new field
      const innerOrderChange = this.getInnerOrder(this.sortOrder);
      if (this.lazy) {
        if (this.sortOrderByLazyLoad !== innerOrderChange) {
          this.innerSortOrder = innerOrderChange;
        }
      } else {
        this.innerSortOrder = innerOrderChange;
      }
    }
  }

  ngAfterContentInit(): void {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'customHeader':
          this.customHeaderTemplate = item.template;
          break;
        case 'caption':
          this.captionTemplate = item.template;
          break;
        case 'body':
          this.columnsTemplate = item.template;
          break;
        case 'customBody':
          this.customBodyTemplate = item.template;
          break;
        case 'customEmptyMessage':
          this.customEmptyMessageTemplate = item.template;
          break;
        case 'rowexpansion':
          this.rowexpansionTemplate = item.template;
          break;
        case 'footer':
          this.footerTemplate = item.template;
          break;
      }
    });
  }

  private getOutOrder(sortOrder: number): SortOrder {
    return sortOrder === 1 ? 'ASCENDING' : 'DESCENDING';
  }

  private getInnerOrder(sortOrder: SortOrder): number {
    switch (sortOrder) {
      case 'ASCENDING':
        return 1;
      case 'DESCENDING':
        return -1;
    }
  }

  get recordsFound(){
    return this.totalRecords > 0;
  }

  onPage(event: { first: number, rows: number }): void {
    if (this.paginator && !this.lazy) {
      this.currentPage = event.first / event.rows;
      this.pagerInfo = PagerInfo.calcPagerInfo(this.totalRecords, this.currentPage, event.rows);
    }
    this.pageFunction.emit({
      entriesPerPage: event.rows,
      page: this.currentPage
    });
  }

  public widthFit = (text: string, body) =>
    this.tooltipDisabled ? true : this.textWidth.measureText(text).width + this.postFix < body.offsetWidth

  workAroundFunction($component: TableHeaderCheckbox) {

    if (this.selectedAll) {
      if (this.selected.length === this.data.length) {
        $component.checked = true;
        this.selectedAll = true;
      } else {
        $component.checked = false;
        this.selectedAll = false;
      }
    } else {
      this.selectedAll = true;
      $component.checked = true;
    }
  }
}
