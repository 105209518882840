import { createSelector } from '@ngrx/store';
import { getDashboard } from '../dashboard.selectors';
import * as fromReducer from './tab.reducers';
import * as fromTabReducer from '../tab/tab.reducers';

export const getTab = createSelector(getDashboard, fromReducer.getTab);
export const getMessages = createSelector(getTab, fromReducer.getMessages);
export const getBucketsValues = createSelector(getTab, fromTabReducer.getBucketsValues);
export const getLoading = createSelector(getTab, fromTabReducer.getLoading);
export const getNavValues = createSelector(getTab, fromTabReducer.getNavValues);
export const getColumns = createSelector(getTab, fromTabReducer.getColumns);
