import { Message } from 'primeng/api';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { Column } from '@common/shared/datatable.model';
import { EsmBucketsRows, EsmInvoiceFinderDeal } from '../../../invoice/dashboard/esm-dashboard.model';
import { createReducer, on } from '@ngrx/store';
import * as fromActions from './table.actions';
import { DashboardTableActions } from './table.actions';
import * as fromDBFiltersActions from '../filters/filters.actions';
import * as fromDBActions from '../dashboard.actions';

export interface EsmDashboardTableState {
  dateRangeText: string;
  messages: Message[];
  data: DatatableData<EsmInvoiceFinderDeal[] | EsmBucketsRows[]>;
  loading: boolean;
  paging: PagingFilter;
  sorting: SortingFilter;
  columns: Column[];
  selectedIds: string[];
  invoices: EsmInvoiceFinderDeal[];
}

export const tableInitialState: EsmDashboardTableState = {
  dateRangeText: '',
  messages: [],
  data: {
    values: null,
    count: 0,
    currentPage: 0,
  },
  paging: {
    page: 0,
    entriesPerPage: 25
  },
  sorting: {
    columnName: 'submissionTimestamp',
    sortOrder: 'DESCENDING'
  },

  loading: false,
  columns: [],
  selectedIds: [],
  invoices: []
};

export const tableReducer = createReducer<EsmDashboardTableState, DashboardTableActions>(
  tableInitialState,
  on(fromDBActions.setToDefaultAction, () => ({ ...tableInitialState })),
  on(fromActions.setMessagesAction, (state: EsmDashboardTableState, { payload: messages }): EsmDashboardTableState => ({
    ...state,
    loading: false,
    messages
  })),
  on(fromDBFiltersActions.filterAction, (state: EsmDashboardTableState) => ({ ...state, loading: true })),
  on(fromActions.sortAndPageDataAction, (state: EsmDashboardTableState, { payload: { paging, sorting } }) => ({
    ...state,
    loading: true,
    messages: [],
    paging,
    sorting,
  })),
  on(fromDBFiltersActions.filterSuccessAction, (state: EsmDashboardTableState, { payload: data }) => ({
    ...state,
    data: {
      count: data.count || 0,
      values: data.values || data.rows || null,
    },
    paging: {
      ...state.paging,
      page: data.currentPage || 0
    },
    dateRangeText: data.dateRangeText,
    loading: false,
    selectedIds: []
  })),
  on(fromActions.setColumnsAction, (state: EsmDashboardTableState, {payload: columns}) => ({
    ...state,
    columns,
  })),
  on(fromActions.setSelectedIdsAction, (state: EsmDashboardTableState, {payload: {confirmationIds}}) => ({
    ...state,
    selectedIds: confirmationIds,
  })),
  on(fromActions.setSelectedInvoicesAction, (state: EsmDashboardTableState, {payload: {invoices}}) => ({
    ...state,
    invoices: invoices,
  })),
  on(fromActions.setTablePagingStateAction, (state: EsmDashboardTableState, {payload: {paging}}) => ({
    ...state,
    paging: paging,
  })),
);

