import { DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as FileSaver from 'file-saver';
import { Subject } from 'rxjs';
import { OrganisationService } from '@common/organisation.service';
import { Column, DefaultValues, PagerInfo } from '@common/shared/datatable.model';
import { DateUtils } from '@common/shared/date.utils';
import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import { StringUtils } from '@common/shared/string.utils';
import { SmtDealFinderTransactionsService } from './dealfinder/smt-dealfinder-transactions.service';
import { SmtDealFinderDataTableModel } from './smt-datatable.model';
import { SmtTransactionsSearchRequestModel, StmTransactionSearchQueryBuilder } from './smt-filter-model';
import { SmtDealFinderDataTableConfig } from './smt-datatable.config';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { DatatableState } from '@common/datatable/datatable.model';
import { DatatableComponent } from '@common/datatable/datatable.component';

@Component({
  selector: 'cms-smt-datatable',
  templateUrl: './smt-datatable.component.html',
  styleUrls: ['./smt-datatable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmtDataTableComponent implements OnInit, OnDestroy {
  @ViewChild(DatatableComponent, { static: true }) dataTableComponent: DatatableComponent;

  private onDestroy$ = new Subject();
  private smtDealFinderDataTableColumnOrder: string[];
  private tableColumns: Column[];
  searchQuery: SmtTransactionsSearchRequestModel;
  private defaults = new DefaultValues();
  rowsPerPage = this.defaults.getRowsPerPage();
  rowsPerPageOptions = this.defaults.getRowsPerPageOptions();

  loading: boolean;
  pagerInfo: PagerInfo;

  currentSortField: string = SmtDealFinderDataTableConfig.getDefaultSortingColumn();
  transactionsResult: SmtDealFinderDataTableModel = { rows: [], recordCount: 0, searchQuery: null };

  constructor(private transactionsService: SmtDealFinderTransactionsService,
              private organisationService: OrganisationService,
              private changeDetectorRef: ChangeDetectorRef,
              private currencyPipe: DecimalPipe) {
    this.transactionsService.emitResetFilter();
  }

  public ngOnInit(): void {
    this.loading = false;
    this.pagerInfo = PagerInfo.calcPagerInfo(0, 0, this.rowsPerPage);
    this.transactionsService.getResetStatusChangeObservable().pipe(
      takeUntil(this.onDestroy$))
      .subscribe(_ => this.resetView());

    this.transactionsService.getSearchQueryChangeObservable().pipe(
      takeUntil(this.onDestroy$), distinctUntilChanged(
        (oldQuery, newQuery) => JSON.stringify(oldQuery) === JSON.stringify(newQuery)))
      .subscribe((searchQuery: SmtTransactionsSearchRequestModel) => {
        this.searchQuery = searchQuery;
        this.loadTransactions();
      });

    this.tableColumns = this.getTableColumns();
    this.resetView();

    this.organisationService.getChangeOrganisationObservable()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.resetView();
        this.loadTransactions();
        this.transactionsResult = {
          recordCount: 0,
          rows: [],
          searchQuery: null
        };
      });
  }

  public onLazyLoad({sortOrder, sortField, perPage: entriesPerPage, page}: DatatableState) {
    const pagingFilter: PagingFilter = {
      entriesPerPage,
      page
    };
    this.currentSortField = sortField || SmtDealFinderDataTableConfig.getDefaultSortingColumn();
    const columnName = StringUtils.camelCaseToSnakeUpperCase(this.currentSortField);
    const sortingFilter: SortingFilter = { columnName, sortOrder };
    this.transactionsService.updateSearchQueryByPagingAndSortingFilter(pagingFilter, sortingFilter);
  }

  private resetView() {
    if (this.dataTableComponent) {
      this.dataTableComponent.primeTable.reset();
    }
  }

  public isValidSearchQuery(): boolean {
    return StmTransactionSearchQueryBuilder.isNotEmptySearchQuery(this.searchQuery);
  }

  public loadTransactionsReport(): void {
    this.transactionsService.downloadTransactionsReport().pipe(
      takeUntil(this.onDestroy$))
      .subscribe(data => {
        FileSaver.saveAs(data.blob, data.filename);
      });
  }

  private formatTransactions(data) {
    const rows = data.rows.map(row => ({
      ...row,
      reportingTimestamp: DateUtils.transformDateWithTimestamp(row.reportingTimestamp),
      transactionDate: DateUtils.transformDate(row.transactionDate),
      deliveryStartDate: DateUtils.transformDate(row.deliveryStartDate),
      deliveryEndDate: DateUtils.transformDate(row.deliveryEndDate),
      price: this.formatDecimal(row.price)
    }));
    return { ...data, rows };
  }

  public get cols() {
    return this.tableColumns;
  }

  get rows() {
    return this.transactionsResult.rows;
  }

  get numberOfTotalRows() {
    return this.transactionsResult.recordCount;
  }

  hasData(): boolean {
    return this.transactionsResult.rows.length > 0;
  }

  private getDefaultColumns(): string[] {
    return SmtDealFinderDataTableConfig.getDefaultColumns();
  }

  private getTableColumns(): Column[] {
    return SmtDealFinderDataTableConfig.getColumns(this.getDefaultColumns());
  }

  private formatDecimal(value) {
    return this.currencyPipe.transform(value, '1.2-5');
  }

  public loadTransactionXml(regime: string, dataStoreId: number): void {
    this.transactionsService.downloadTransactionReport(regime, dataStoreId).pipe(
      takeUntil(this.onDestroy$))
      .subscribe(data => {
        FileSaver.saveAs(data.blob, data.filename);
      });
  }

  private loadTransactions() {
    if (this.isValidSearchQuery() && !this.loading) {
      const currentPage = this.searchQuery.paging.page;
      const resultsPerPage = this.searchQuery.paging.entriesPerPage;
      this.loading = true;
      this.changeDetectorRef.markForCheck();

      this.transactionsService.fetchTransactions(this.searchQuery).pipe(
        takeUntil(this.onDestroy$))
        .subscribe((result: SmtDealFinderDataTableModel) => {
          this.loading = false;
          this.transactionsResult = this.formatTransactions(result);
          this.pagerInfo = PagerInfo.calcPagerInfo(result.recordCount, currentPage, resultsPerPage);
          this.changeDetectorRef.markForCheck();
        });
    }
  }

  public ngOnDestroy(): void {
    // Clean up all subscriptions at once:
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
    this.loading = false;
  }

}
