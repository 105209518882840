import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RemitFilesOutSearchQuery, RemitFilesOutService } from './remit-files-out.service';
import { DateUtils } from '../../../../common/shared/date.utils';
import { SelectItem } from 'primeng/api';
import { OrganisationService } from '@common/organisation.service';

@Component({
  selector: 'cms-remit-files-out-search',
  templateUrl: './remit-files-out-search.component.html',
  styleUrls: ['./remit-files-out-search.component.scss']
})
export class RemitFilesOutSearchComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  documentTypes: SelectItem[] = [];
  private onDestroy$ = new Subject();

  constructor(private formBuilder: UntypedFormBuilder,
              public filesOutService: RemitFilesOutService,
              private  orgService: OrganisationService) {
    const date = new Date();
    date.setDate(date.getDate() - 1);

    this.form = this.formBuilder.group({
      documentTypes: ['', Validators.required],
      submissionDate: [date, Validators.required],
      reportingRole: ['MP', Validators.required]
    });
  }

  ngOnInit(): void {
    this.filesOutService.loadDocumentTypes()
      .pipe(takeUntil(this.onDestroy$)).subscribe(
      docTypes => {
        this.documentTypes = docTypes.map(dt => ({ label: dt, value: dt }));
        this.form.patchValue({
          documentTypes: docTypes
        });
        this.form.updateValueAndValidity();
      });

    this.orgService.getOrganisationChangeIfAccessible().pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.onUpdateSearchFilter();
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  onUpdateSearchFilter(): void {
    if (this.form.valid) {
      const query = RemitFilesOutSearchQuery.createEmptySearchQuery();
      const {
        documentTypes,
        submissionDate,
        reportingRole: searchAsRole,
      } = this.form.value;
      query.documentSearchModel = {
        documentTypes,
        searchAsRole,
        submissionDate: DateUtils.toISOStringWithIgnoreTimezoneOffset(new Date(submissionDate))
      };
      query.paging = this.filesOutService.getSearchQuery() ? this.filesOutService.getSearchQuery().paging : query.paging;
      query.sorting = this.filesOutService.getSearchQuery() ? this.filesOutService.getSearchQuery().sorting : query.sorting;
      this.filesOutService.setSearchQuery(query);
    }
  }
}
