import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener, Input,
  Renderer2
} from '@angular/core';
import {  FormGroupDirective } from '@angular/forms';

@Directive({
  selector: '[cmsDigitOnly]'
})
export class DigitOnlyDirective implements AfterViewInit {

  private regex: RegExp = new RegExp(/^-?[0-9]+(\.[0-9]{0,5})?$/g);
  private leadingDot: RegExp = new RegExp(/^-?((\.[0-9]+)|([0-9]+\.))$/g);
  private specialKeys: Array<number> = [9, 37,39]; // [8, 9, 37,39]
  input: HTMLInputElement;
  _absLength: number = 100;

  @Input()
  formControlName: string;

  @Input() set maxlength(length) {
    this._absLength = length - 2; // max length includes '-', '.'
  }

  constructor(private el: ElementRef,
              private renderer: Renderer2,
              public formGroup: FormGroupDirective) {
  }


  ngAfterViewInit(): void {
    this.input = this.el.nativeElement;
    this.renderer.setStyle(this.el.nativeElement, 'text-align', 'right');
  }

  @HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
    const inputField = e.target as HTMLInputElement;
    const next = this.combineStrings(inputField.value, e.key, inputField.selectionStart, e.keyCode);
    const cursor = inputField.selectionStart;
    const selection = (this.input.selectionEnd - this.input.selectionStart) > 0 ? this.input.selectionEnd : null;


    if (this.specialKeys.includes(e.keyCode) || this.isCopyPaste(e)) {
      return;

    } else if (e.key != null && e.key.includes('-') && inputField.selectionStart === 0 && inputField.value[0] !== '-') {
      return;

    } else if (e.keyCode === 8||e.keyCode === 46) {
      e.preventDefault();
      inputField.value = next;

      if (selection || e.keyCode === 46) {
        inputField.setSelectionRange(cursor, cursor);
      } else if (e.keyCode === 8) {
        inputField.setSelectionRange(cursor - 1, cursor - 1);
      }

      this.formGroup.form.patchValue({ [this.formControlName]: next });
      e.currentTarget.dispatchEvent(new CustomEvent('change', { bubbles: true }));

    } else if (!next?.match(this.regex) || this.checkLength(next)) {
      e.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event) {
    const inputFiled = event.target as HTMLInputElement;
    let pastedInput: string;
    if (window['clipboardData']) {  // IE
      pastedInput = window['clipboardData'].getData('Text');
    } else if (event.clipboardData && event.clipboardData.getData) {  // other Browsers
      pastedInput = event.clipboardData.getData('text/plain');
    }

    const replaceAll: boolean =
      inputFiled.value.length === (inputFiled.selectionEnd - inputFiled.selectionStart) && !!pastedInput.match(this.regex);

    if(replaceAll) {
      this.clipBoardPaste(pastedInput);
    } else {
      this.combineStrings(inputFiled.value, pastedInput, inputFiled.selectionStart).match(this.regex) ?
        this.clipBoardPaste(pastedInput) :
        event.preventDefault();
    }
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    const inputFiled = event.target as HTMLInputElement;
    const dropData = event.dataTransfer.getData('text');
    inputFiled.focus();

    this.combineStrings(inputFiled.value, dropData, inputFiled.selectionStart).match(this.regex) ? this.clipBoardPaste(dropData) : event.preventDefault();

  }

  @HostListener('blur', ['$event']) onblur(event) {
    if (this.input?.value === '-') {
      this.formGroup.form.patchValue({ [this.formControlName]: '' });
    }
  }

// ================= helper methods=================

  isCopyPaste(e: KeyboardEvent): boolean {
    const controlSequence = ['a', 'c', 'v', 'x'];
    return ((e.metaKey || e.ctrlKey) && controlSequence.includes(e.key));
  }

  private clipBoardPaste(input: string): void {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(input);
      document.execCommand('paste');
    }
  }

  private combineStrings(current:string, input, index:number, keyCode?): string {
    let result: string;

    if(keyCode === 8){
      if ((this.input.selectionEnd - this.input.selectionStart) > 0) {
        result = current.substring(0,this.input.selectionStart)
          .concat(current.substring(this.input.selectionEnd,current.length));

      } else {
        result = current.substring(0, index - 1)
          .concat(current.substring(index, current.length));
      }
      result = result.match(this.leadingDot) ? result.replace('.', '') : result;

    } else if(keyCode === 46){
      result = current.substring(0, index)
        .concat(current.substring(index+1, current.length));

      result = result.match(this.leadingDot) ? result.replace('.', '') : result;
    } else {
      result= current.substring(0, index)
        .concat(input)
        .concat(current.substring(index, current.length));
    }

    return result;
  }

  checkLength = (input): boolean => input.replace(/\D/g, '').length > this._absLength;

}
