<div class="container-fluid row justify-content-center margin-top-60">
  <div class="col-12">
    <div class="panel-box margin-bottom-15">
      <div class="col-12 text-center">
        <img class="margin-bottom-50 text-center" src="assets/img/equias_logo.png" />
      </div>
      <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
        <div class="col-12">
          <h1 class="text-center">Choose a new Password</h1>
          <div class="col-12 info-panel margin-bottom-30" id="info-password">
            <div class="fa fa-info-circle float-left"></div>
            <div class="margin-left-18">
              New password must have <strong>between 8 to 16</strong> characters.<br />
              It must contain at least<br />
              <strong>one lower-case letter</strong>: a-z<br />
              <strong>one upper-case letter</strong>: A-Z<br />
              <strong>one number or special character</strong>: 0-9 $ ! + - _ . ,<br />
              Only the above characters are allowed.<br />
              New password must be different from your last 3 passwords.
            </div>
          </div>
        </div>

        <label class="col-12 margin-bottom-3" for="password">Password:<span class="text-required">*</span></label>
        <div class="col-12 margin-bottom-15">
          <input type="password" formControlName="password" id="password" tabindex="1" class="form-control" [ngClass]="{'error': hasInputErrors()}" maxlength="16">
        </div>
        <label class="col-12 margin-bottom-3" for="password">Repeat Password:<span class="text-required">*</span></label>
        <div class="col-12 margin-bottom-15">
          <input type="password" formControlName="repeatPassword" id="re-password" tabindex="1" class="form-control" [ngClass]="{'error': hasInputErrors()}" maxlength="16">
        </div>
        <div class="col-12 margin-bottom-15 text-center">
          <span class="error-note" [innerHTML]="getErrorMsg()"></span>
        </div>

        <div class="col-12 clearfix margin-bottom-15">
          <button type="submit" [disabled]="!resetPasswordForm.valid" id="btn-login" tabindex="2" class="btn float-right" value="Send">Send</button>
        </div>

      </form>
    </div>
  </div>
</div>
