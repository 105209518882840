import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ErrorInformation } from './error.model';
import { filter, map } from 'rxjs/operators';
import { Message } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ErrorMessageService {

  errorSubject = new BehaviorSubject<ErrorInformation>(null);

  constructor() {
  }

  publish(error: ErrorInformation) {
    this.errorSubject.next(error);
  }

  clear(): void {
    this.errorSubject.next(null);
  }

  onError(): Observable<ErrorInformation> {
    return this.errorSubject.asObservable();
  }

  on503Error(): Observable<Message[]> {
    const severity = 'warn',
      summary = ' This process is not available right now, please try again later. We apologize for the technical difficulties.';

    return this.errorSubject.asObservable().pipe(
      filter(Boolean),
      filter((err:ErrorInformation) => err.httpStatusCode === 503),
      map(() => [{ severity, summary }]));
  }

  hasError(): boolean {
    return this.errorSubject.getValue() !== null;
  }

}
