<div style="max-width: 585px;" class="settlement" *ngIf="bilateral">
  <div class="form-title">Contract | Settlement</div>
  <div class="form-body" [formGroup]="formGroup">
    <div class="row gutter-0 !me-[40px]">
      <div class="col-5 label">Settlement <span class="text-required">*</span></div>
      <div class="col-7 ps-2 pe-1">
        <p-dropdown [options]="settlementMethods" formControlName="settlementMethod" [styleClass]="'margin-3 full-width'"></p-dropdown>
      </div>
      <!--<span *ngIf="formGroup.controls['beneficiaryCode'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>-->
    </div>

  </div>
</div>
