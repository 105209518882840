export interface NettingAmount {
  cashFlow: string;
  ourNettingCalculation: number;
  cpNettingCalculation: number;
  ourOpenUpToDate: number;
  ourAgreedToDate: number;
  ourDisputedUpToDate: number;
}

export interface InvoiceFinderQueryParameters {
  caseNumber: number;
  counterpartyId: number;
  counterparty: string;
  rowName: string;
  columnName: string;
  invoiceStates: InvoiceState[];
  invoiceDocumentTypes: InvoiceDocumentType[];
  invoiceSenderOrgId: number;
  myVAT: string;
  cpVAT: string;
  invoiceReceiverOrgId: number;
  invoicePaymentDate: string;
  nettingAgreement: string;
}

export enum InvoiceState {
  pending = 'PENDING',
  matched = 'MATCHED',
  mismatched = 'MISMATCHED'
}

export enum InvoiceDocumentType {
  official = 'OFFICIAL_INVOICE',
  shadow = 'SHADOW_INVOICE',
}


const defaultStyles = {
  'text-align': 'right',
  'text-overflow': 'ellipsis',
  'overflow': 'hidden',
  'white-space': 'nowrap',
  'height': '22px'
};


export const amountTableColumns = [
  { name: 'cashFlow', type: 'text', title: 'Cash Flow', style: { ...defaultStyles, 'width': '8%' ,'text-align':'left','color':'rgb(85,85,85)' }, sortable: false, visible: true },
  { name: 'ourNettingCalculation',  type: 'number', title: 'Our Netting Calculation', style: { ...defaultStyles}, sortable: false, visible: true },
  { name: 'cpNettingCalculation',   type: 'number', title: 'CP Netting Calculation',  style: { ...defaultStyles}, sortable: false, visible: true },
  { name: 'ourOpenUpToDate',        type: 'number', title: 'Our Open To Date',        style: { ...defaultStyles}, sortable: false, visible: true },
  { name: 'ourAgreedToDate',        type: 'number', title: 'Our Agreed To Date',      style: { ...defaultStyles}, sortable: false, visible: true },
  { name: 'ourDisputedUpToDate',    type: 'number', title: 'Our Disputed To Date',    style: { ...defaultStyles}, sortable: false, visible: true },
];
