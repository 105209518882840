<p-messages [closable]="true" [value]="messages"></p-messages>
<p-panel id="account-box" header="Edit Personal Data" [styleClass]="'col-12 col-md-8 col-lg-6 no-gutters account-box'">
  <div class="me-2">
    <form [formGroup]="accountForm" class="grid grid-cols-3 gap-2" (ngSubmit)="onSubmit()">
      <label class="col-span-1">User Name:<span class="text-required">*</span></label>
      <div class="col-span-2">
        <input id="account-username" type="text" formControlName="userName" class="form-control  padding-6" />
      </div>
      <label class="col-span-1">First Name:<span class="text-required">*</span></label>
      <div class="col-span-2">
        <input id="account-firstname" type="text" formControlName="firstName" class="form-control padding-6" />
      </div>
      <label class="col-span-1">Last Name:<span class="text-required">*</span></label>
      <div class="col-span-2">
        <input id="account-lastname" type="text" formControlName="lastName" class="form-control padding-6" />
      </div>
      <label class="col-span-1">Email:<span class="text-required">*</span></label>
      <div class="col-span-2">
        <input id="account-email" type="text" formControlName="email" class="form-control padding-6" />
      </div>
      <label class="col-span-1">Default Organisation</label>
      <div class="col-span-2">
        <p-dropdown id="organisation-dropdown" formControlName="organisation" [options]="organisations" [filter]="true" [filterFields]="['label']" [styleClass]="'org-selection'"></p-dropdown>
      </div>
      <label class="col-span-1">Job Title</label>
      <div class="col-span-2">
        <input id="account-jobtitle" type="text" formControlName="job" tabindex="2" class="form-control col-8 padding-6" [maxlength]="70" />
      </div>
      <label class="col-span-1">Phone</label>
      <div class="col-span-2">
        <input id="account-phone" type="text" formControlName="phone" tabindex="3" class="form-control col-8 padding-6" [maxlength]="25" />
      </div>
      <label class="col-span-1">Mobile</label>
      <div class="col-span-2">
        <input id="account-mobile" type="text" formControlName="mobile" tabindex="4" class="form-control col-8 padding-6" [maxlength]="25" />
      </div>
      <label class="col-span-1">Contact Other:</label>
      <div class="col-span-2">
        <textarea col="3" id="account-contactother" formControlName="contactOther" tabindex="5" class="form-control col-8 padding-6" [maxlength]="100"></textarea>
      </div>
      <div class="col-auto col-start-3 text-right">
        <button type="submit" id="btn-account-submit" tabindex="5" class="btn" [disabled]="getUpdateDisabled()">Update</button>
      </div>
    </form>
  </div>
</p-panel>
