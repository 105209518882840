<nav class="navbar py-2 px-0 md:!py-0 min-h-[51px] " *ngIf="isAuthenticated()">
  <a class="px-3 col-auto" routerLink="/" title="Equias"><img class="" src="/assets/img/equias_logo.png" alt="Equias Logo" style="max-width: 126px;" /></a>
  <span class="col mt-1" style="font-weight: bold;" *ngIf="systemInformations">
        {{ systemInformations.instanceName }}
    </span>
  <div class="col-auto col-xl-9 row justify-content-end">
    <div class="col-auto padding-6 username" *ngIf="userInformation">{{ userInformation.userName }}&#64;</div>
    <div class="col-6 col-md-4 col-lg-3 inline-block org-selector">
            <cms-org-selector></cms-org-selector>
    </div>
    <div class="col-auto mt-2 me-3 d-inline">
            <a (click)="onLogout()" id="logout"><span class="fa fa-power-off padding-right-3" style="font-weight: bold;"></span>Logout</a>
    </div>
  </div>
</nav>
