import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { RemitTable1TradeService } from '../remit-table1-trade.service';
import { Observable, of, Subject } from 'rxjs';
import { Message } from 'primeng/api';
import { EndPointConstants } from '@common/end-point-constants';
import { Title } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cms-remit-table1-trade-error',
  templateUrl: './remit-table1-trade-error.component.html',
  styleUrls: ['./remit-table1-trade-error.component.scss']
})
export class RemitTable1TradeErrorComponent implements OnInit, OnDestroy {

  private onDestroy$ = new Subject();

  msgs: Message[] = [];
  disabled: boolean = false;
  inProgress: boolean = false;
  recordType: string;
  historyId: number;

  submitEnabled$: Observable<boolean> = of(false);
  internalError: boolean = false;
  authorisation: string;

  constructor(private service: RemitTable1TradeService, private route: ActivatedRoute, private titleService: Title) {
  }

  ngOnInit() {
    this.msgs = [];
    this.inProgress = false;
    this.titleService.setTitle('Delete Trade');

    this.route.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe((params: Params) => {
      this.recordType = params[EndPointConstants.PARAM_RECORD_TYPE];
      this.historyId = params[EndPointConstants.PARAM_HISTORY_ID];
    });

    this.service.loadPresetsDeletion(this.recordType, this.historyId).pipe(takeUntil(this.onDestroy$))
      .subscribe(
        response => {
          if (response) {
            this.submitEnabled$ = of(response.submitEnabled);

            if (response.validationFailureMessages) {
              this.disabled = true;
              this.internalError = true;
              for (const message of response.validationFailureMessages) {
                this.msgs = [...this.msgs, { severity: 'error', summary: message }];
              }
            }
          }

        }, error => {
          this.disabled = true;
          this.internalError = true;

          let errorDetail = '';
          if (error.error && error.error.errorMessage) {
            errorDetail = error.error.errorMessage;
          }

          this.msgs = [...this.msgs, { severity: 'error', summary: error.statusText, detail: errorDetail }];
        });
  }

  submitDelete() {
    this.msgs = [];
    this.disabled = true;
    this.inProgress = true;
    this.service.submitError(this.recordType, this.historyId).pipe(takeUntil(this.onDestroy$)).subscribe(
      response => {
        if (response.validationFailureMessages) {
          for (const message of response.validationFailureMessages) {
            this.msgs = [...this.msgs, { severity: 'error', summary: message }];
          }
          this.disabled = false;
        } else {
          this.msgs = [...this.msgs, { severity: 'success', summary: 'Deletion submitted successfully.' }];
        }
        this.inProgress = false;
      }, error => {
        let errorDetail = '';
        if (error.error && error.error.errorMessage) {
          errorDetail = error.error.errorMessage;
        }
        this.inProgress = false;
        this.msgs = [...this.msgs, { severity: 'error', summary: error.statusText, detail: errorDetail }];
      }
    );
  }

  isDisabled(): boolean {
    return this.disabled;
  }

  isInProgress(): boolean {
    return this.inProgress;
  }

  ngOnDestroy() {
    this.submitEnabled$ = of(false);
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

}
