import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { OrganisationService } from '@common/organisation.service';
import { select, Store } from '@ngrx/store';
import { MyFilter } from '@common/my-filters/my-filters.model';
import { Ecm_View_By_Values, EcmDashboardPropertiesFilters } from '../ecm-dashboard.model';
import { EcmMyFilter } from '../../deal-finder/deal-finder.model';
import { EcmDashboardState } from '../../state/dashboard/dashboard.reducers';
import * as fromFilterSelectors from '../../state/dashboard/filters/filters.selectors';
import { getFilterProperties, getViewBy } from '../../state/dashboard/filters/filters.selectors';
import * as fromDbAction from '../../state/dashboard/dashboard.actions';
import * as fromFilterActions from '../../state/dashboard/filters/filters.actions';
import * as fromMyFiltersActions from '../../state/dashboard/my-filters/my-filters.actions';
import * as fromMyFiltersSelectors from '../../state/dashboard/my-filters/my-filters.selectors';
import * as fromDialogSelectors from '../../state/dashboard/dialog/dialog.selectors';
import * as fromDialogActions from '../../state/dashboard/dialog/dialog.actions';
import * as fromTabSelectors from '../../state/dashboard/tab/tab.selectors';
import { EcmDashboardPropertiesFiltersComponent } from '../ecm-dashboard-properties-filters/ecm-dashboard-properties-filters.component';

import { StateService } from '@common/state.service';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import {  Router } from '@angular/router';

@Component({
  selector: 'cms-ecm-filters-container',
  templateUrl: './ecm-filters-container.component.html',
  styleUrls: ['./ecm-filters-container.component.scss']
})

export class EcmFiltersContainerComponent implements OnDestroy, OnInit, AfterViewInit {

  viewBy$: Observable<Ecm_View_By_Values> = this.store.pipe(select(getViewBy));
  filtersProps$: Observable<EcmDashboardPropertiesFilters> = this.store.pipe(select(getFilterProperties),
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)));

  broker$ = this.store.pipe(select(fromFilterSelectors.getBroker));
  transactionTypes$ = this.store.pipe(select(fromFilterSelectors.getTransactionTypes));
  markets$ = this.store.pipe(select(fromFilterSelectors.getMarkets));
  counterParties$ = this.store.pipe(select(fromFilterSelectors.getCounterParties));
  commodities$ = this.store.pipe(select(fromFilterSelectors.getCommodities));

  dialog$ = this.store.pipe(select(fromDialogSelectors.getDialog));
  myFilters$ = this.store.pipe(select(fromMyFiltersSelectors.getAllMyFilters));
  defaultFilter$ = this.store.pipe(select(fromMyFiltersSelectors.getDefaultFilter));
  allDealsFilter$ = this.store.pipe(select(fromMyFiltersSelectors.getAllDealsFilter));
  loaded$ = this.store.pipe(select(fromFilterSelectors.getLoaded));
  loading$ = this.store.pipe(select(fromTabSelectors.getLoading));
  subscription$: Subscription = new Subscription();

  @Input() index: string;

  @ViewChild(EcmDashboardPropertiesFiltersComponent, { static: false })
  filter: EcmDashboardPropertiesFiltersComponent;

  constructor(private organisationService: OrganisationService,
              private store: Store<EcmDashboardState>,
              private stateService: StateService,
              private chDf: ChangeDetectorRef,
              private  router: Router) {}

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription$.add(this.loaded$.subscribe((loaded: boolean) => {
        if (!loaded) {this.store.dispatch(new fromMyFiltersActions.LoadMyFiltersAction());}}));

    this.subscription$.add(this.organisationService.getOrganisationChangeIfAccessible()
        .subscribe(() => this.store.dispatch(new fromDbAction.SetToDefaultAction())));

    this.subscription$.add(this.organisationService.onRefresh().pipe(
      filter(auth=> auth && this.organisationService.getCurrentOrganisationId()!==null),
      filter(()=>!this.router.url.includes('deals')))
      .subscribe(() => this.store.dispatch(new fromFilterActions.GetFiltersValuesAction())));

  }


  ngAfterViewInit(): void {

   this.subscription$.add(this.organisationService.onRefresh().pipe(filter(Boolean)).subscribe(() => this.populateForm()));

   this.subscription$ .add(this.filtersProps$.subscribe(changes => this.populateForm(changes)));

   this.subscription$.add(this.filter.form.valueChanges.subscribe(val => this.stateService.cacheState(val, this.index + '_ECM_DASHBOARD')));
  }

  onFilter(propFilters: EcmDashboardPropertiesFilters): void {
    this.store.dispatch(new fromFilterActions.FilterAction({
      propFilters
    }));
  }

  onResetFilters(): void {
    this.store.dispatch(new fromFilterActions.ClearByPropertiesFiltersAction());
  }

  onApplyViewByFilter(viewByFilter: Ecm_View_By_Values): void {
    this.store.dispatch(new fromFilterActions.FilterAction({
      viewByFilter
    }));
  }

  onSelectMyFilter(myFilter: EcmMyFilter): void {
    this.store.dispatch(new fromMyFiltersActions.SelectMyFilterAction(myFilter));
  }

  onDeleteFilter(filterName: string): void {
    this.store.dispatch(new fromMyFiltersActions.DeleteMyFilterAction({ filterName }));
  }

  onConfirmReplaceFilter(replace: MyFilter): void {
    this.store.dispatch(new fromMyFiltersActions.ReplaceMyFiltersAction(replace as EcmMyFilter));
  }

  onConfirmSaveFilter(saveFilter: EcmMyFilter): void {
    this.store.dispatch(new fromMyFiltersActions.SaveMyFiltersAction(saveFilter));
  }

  onManageMyFilters(): void {
    this.store.dispatch(new fromDialogActions.OpenDialogAction({
      name: 'MANAGE_FILTERS'
    }));
  }

  onOpenSaveCurrent(): void {
    this.store.dispatch(new fromDialogActions.OpenDialogAction({
      name: 'SAVE_CURRENT'
    }));
  }

  onCloseDialog(): void {
    this.store.dispatch(new fromDialogActions.CloseDialogAction());
  }

  onSetDefault(defaultFilter: MyFilter): void {
    this.store.dispatch(new fromMyFiltersActions.SetDefaultFilterAction(defaultFilter));
  }



  private populateForm(changes?:EcmDashboardPropertiesFilters) {
    const model = this.stateService.getCache(this.index + '_ECM_DASHBOARD') as EcmDashboardPropertiesFilters;
    if (model && 'counterParties' in model) {
      for (const [key, value] of Object.entries(model)) {
        if (key === 'hiddenDeals') { continue; }
        const options = this.filter[`${key}`] || [];
        if (options.length > 0 && !!value) {
          model[`${key}`] = model[`${key}`].filter(item => options.includes(item));
        } else {model[`${key}`] = null;}
      }
      this.filter.form.patchValue(model);
      if (!!!changes && this.organisationService.getCurrentOrganisationId()!==null) {
        this.store.dispatch(new fromFilterActions.FilterAction({ propFilters: model }));
      }

    } else if(!!changes) {
      const {
        brokers,
        commodities,
        counterParties,
        hiddenDeals,
        markets,
        transactionTypes
      } = changes;
      this.filter.form.patchValue({ brokers, commodities, counterParties, markets, transactionTypes, hiddenDeals });
    }

    this.filter.form.markAsPristine();
    this.filter.form.updateValueAndValidity();
    this.chDf.detectChanges();
  }

}
