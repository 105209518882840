import { createSelector } from '@ngrx/store';
import { getErrState, State } from '../reducers';
import * as fromReducer from './retry-queue.reducers';

export const getRetryQueueState = createSelector(getErrState, (state: State) => state.retryQueue);

export const getOrganisationId = createSelector(getRetryQueueState, fromReducer.getOrganisationId);
export const getSorting = createSelector(getRetryQueueState, fromReducer.getSorting);
export const getPaging = createSelector(getRetryQueueState, fromReducer.getPaging);
export const getLoading = createSelector(getRetryQueueState, fromReducer.getLoading);
export const getMessages = createSelector(getRetryQueueState, fromReducer.getMessages);
export const getData = createSelector(getRetryQueueState, fromReducer.getData);
export const getFilter = createSelector(getRetryQueueState, fromReducer.getFilter);
export const getReasons = createSelector(getRetryQueueState, fromReducer.getReasons);
export const getDialog = createSelector(getRetryQueueState, fromReducer.getDialog);
export const getDisabled = createSelector(getRetryQueueState, fromReducer.getDisabled);
export const getSelected = createSelector(getRetryQueueState, (state: fromReducer.ErrRetryQueueState) => state.selected);

