import { Component, OnInit, OnChanges, ChangeDetectionStrategy, SimpleChange, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { EsmRoleByType } from '../esm-dashboard.model';

@Component({
  selector: 'cms-esm-role-by-filter',
  templateUrl: './esm-role-by-filter.component.html',
  styleUrls: ['./esm-role-by-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EsmRoleByFilterComponent implements OnInit, OnChanges {
  @Input() value: string;
  @Input() disabled: boolean;
  @Output() onChange = new EventEmitter<EsmRoleByType>();
  form: UntypedFormGroup;
  readonly EsmRoleByType = EsmRoleByType;

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      roleBy: this.value || '',
    });
  }

  handleChange(): void {
    const value = this.form.getRawValue().roleBy || null;
    this.onChange.emit(value);
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (this.form && changes.value && this.value !== this.form.value.roleBy) {
      this.form.patchValue({ roleBy: this.value || '' });
      this.form.updateValueAndValidity();
    }
  }
}
