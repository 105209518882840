import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { EditorState, HelpTextEditorModel } from './help-text-editor.model';
import { HelpService } from '../help.service';

@Component({
  selector: 'cms-help-text-editor',
  templateUrl: './help-text-editor.component.html',
  styleUrls: ['./help-text-editor.component.scss']
})
export class HelpTextEditorComponent implements OnInit, OnDestroy {

  @Input() state: EditorState;

  @Output() canceled = new EventEmitter();
  model: HelpTextEditorModel = {content: '', title: ''};

  constructor(private helpService: HelpService) {
  }

  @Input('content')
  set content(value: string) {
    this.model.content = value;
  }

  @Input('title')
  set title(value: string) {
    this.model.title = value;
  }

  cancel(): void {
    this.canceled.emit();
  }

  submit(): void {
    switch (this.state) {
      case 'RELEASE_NOTES': this.submitReleaseNotes(); break;
      case 'SUPPORT': this.submitSupport(); break;
      case 'ANNOUNCEMENTS': this.submitAnnoucement(); break;
    }
  }

  private submitAnnoucement(){
    this.helpService.submitAnnouncement(this.model).then(response => {
      this.helpService.fetchAnnouncement();
      this.cancel();
    });
  }

  private submitReleaseNotes(){
    this.helpService.submitReleaseNotes(this.model).then(response => {
      this.helpService.fetchReleaseNotes();
      this.cancel();
    });
  }

  private submitSupport(){
    this.helpService.submitSupport(this.model).then(response => {
      this.helpService.fetchSupportMessage();
      this.cancel();
    });
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {
  }

}
