<div class="panel-box margin-bottom-12">
  <form class="row align-items-center" [formGroup]="form">
    <div class="col-12 row">
      <div class="col multiselect-col">
        <div>
          <label class="text-bold label--multiselect form__label">Counterparty</label>
          <p-multiSelect selectedItemsLabel="All"
                         [maxSelectedLabels]="counterPartyOptions.length - 1"
                         [filter]="false"
                         [options]="counterPartyOptions"
                         tabindex="5"
                         placeholder="None"
                         [ngClass]="{'error': errors['counterParties']}"
                         formControlName="counterParties">
            <ng-template pTemplate="header">
              <div class="checkbox-all-text">All</div>
            </ng-template>
          </p-multiSelect>
        </div>
        <div>
          <label class="text-bold label--multiselect form__label">Broker</label>
          <p-multiSelect [options]="brokerOptions"
                         selectedItemsLabel="All"
                         [maxSelectedLabels]="brokerOptions.length - 1"
                         [filter]="false"
                         tabindex="6"
                         placeholder="None"
                         [ngClass]="{'error': errors['brokers']}"
                         formControlName="brokers">
            <ng-template pTemplate="header">
              <div class="checkbox-all-text">All</div>
            </ng-template>
          </p-multiSelect>
        </div>
        <div>
          <label class="text-bold label--multiselect form__label">Transaction Type</label>
          <p-multiSelect selectedItemsLabel="All"
                         [maxSelectedLabels]="transactionTypeOptions.length - 1"
                         [filter]="false"
                         [options]="transactionTypeOptions"
                         tabindex="7"
                         placeholder="None"
                         [ngClass]="{'error': errors['transactionTypes']}"
                         formControlName="transactionTypes">
            <ng-template pTemplate="header">
              <div class="checkbox-all-text">All</div>
            </ng-template>
          </p-multiSelect>
        </div>
      </div>
      <div class="col multiselect-col">
        <div>
          <label class="text-bold label--multiselect form__label">Commodity</label>
          <p-multiSelect selectedItemsLabel="All"
                         [maxSelectedLabels]="commodityOptions.length - 1"
                         [filter]="false"
                         [options]="commodityOptions"
                         tabindex="8"
                         placeholder="None"
                         [ngClass]="{'error': errors['commodities']}"
                         formControlName="commodities">
            <ng-template pTemplate="header">
              <div class="checkbox-all-text">All</div>
            </ng-template>
          </p-multiSelect>
        </div>
        <div>
          <label class="text-bold label--multiselect form__label">Market</label>
          <p-multiSelect selectedItemsLabel="All"
                         [maxSelectedLabels]="marketOptions.length - 1"
                         [filter]="false"
                         [options]="marketOptions"
                         tabindex="9"
                         placeholder="None"
                         [ngClass]="{'error': errors['markets']}"
                         formControlName="markets">
            <ng-template pTemplate="header">
              <div class="checkbox-all-text">All</div>
            </ng-template>
          </p-multiSelect>
        </div>
        <div class="price-container">
          <label class="text-bold label--input form__label">Price</label>
          <input formControlName="price"
                 type="text"
                 cmsDigitOnly
                 class="form-control p-component padding-6 text-medium"
                 [ngClass]="{'error': errors['price']}"
                 (keypress)="onKeyPress($event)">
        </div>
      </div>
      <div class="col-12 date-col">
        <div class="row position-relative">
          <label class="col-12 text-bold label--date form__label ps-0">Trade Date Range</label>
          <div class="col-12 col-md-5 ps-0">
            <p-calendar placeholder="From Date"
                        id="tradeDateStart"
                        formControlName="tradeDateStart"
                        showIcon="true"
                        monthNavigator="true"
                        yearNavigator="true"
                        dateFormat="dd/mm/yy"
                        [maxDate]="tradeSubmissionMaxDate"
                        [ngClass]="{'error': errors['tradeDateStart']}"
                        cmsDateRestriction>
            </p-calendar>
          </div>
          <div class="col-12 col-md-5 date ps-0">
            <p-calendar placeholder="To Date"
                        id="tradeDateEnd"
                        formControlName="tradeDateEnd"
                        showIcon="true"
                        monthNavigator="true"
                        yearNavigator="true"
                        dateFormat="dd/mm/yy"
                        [maxDate]="tradeSubmissionMaxDate"
                        [ngClass]="{'error': errors['tradeDateEnd']}"
                        cmsDateRestriction>
            </p-calendar>
          </div>
          <span class="col-2">
            <cms-additional-date-range [form]="form"
                                       [startField]="'tradeDateStart'"
                                       [endField]="'tradeDateEnd'">
            </cms-additional-date-range>
          </span>
        </div>
        <div class="row calendar position-relative">
          <label class="text-bold label--date form__label ps-0">Submission Date Range</label>
          <div class="col-12 col-xs-12 col-md-5 ps-0">
            <p-calendar placeholder="From Date"
                        id="submissionDateStart"
                        formControlName="submissionDateStart"
                        showIcon="true"
                        monthNavigator="true"
                        yearNavigator="true"
                        dateFormat="dd/mm/yy"
                        [maxDate]="tradeSubmissionMaxDate"
                        [ngClass]="{'error': errors['submissionDateStart']}"
                        cmsDateRestriction>
            </p-calendar>
          </div>
          <div class="col-12 col-xs-12 col-md-5 ps-0 date">
            <p-calendar placeholder="To Date"
                        id="submissionDateEnd"
                        formControlName="submissionDateEnd"
                        showIcon="true"
                        monthNavigator="true"
                        yearNavigator="true"
                        dateFormat="dd/mm/yy"
                        [maxDate]="tradeSubmissionMaxDate"
                        [ngClass]="{'error': errors['submissionDateEnd']}"
                        cmsDateRestriction>
            </p-calendar>
          </div>
          <span class="col-2">
            <cms-additional-date-range [form]="form"
                                       [startField]="'submissionDateStart'"
                                       [endField]="'submissionDateEnd'">
            </cms-additional-date-range>
          </span>
        </div>
        <div class="row calendar">
          <div class="col-12 col-xs-12 col-md-5 ps-0">
            <label class="text-bold label--date  form__label">Delivery Start</label>
            <p-calendar id="deliveryStartDate"
                        placeholder="From Date"
                        formControlName="deliveryStartDate"
                        showIcon="true"
                        monthNavigator="true"
                        yearNavigator="true"
                        dateFormat="dd/mm/yy"
                        [ngClass]="{'error': errors['deliveryStartDate']}"
                        cmsDateRestriction>
            </p-calendar>
          </div>
          <div class="col-12 col-xs-12 col-md-5 ps-0">
            <label class="text-bold label--date form__label">Delivery End</label>
            <p-calendar id="deliveryEndDate"
                        placeholder="To Date"
                        formControlName="deliveryEndDate"
                        showIcon="true"
                        monthNavigator="true"
                        yearNavigator="true"
                        dateFormat="dd/mm/yy"
                        [ngClass]="{'error': errors['deliveryEndDate']}"
                        cmsDateRestriction>
            </p-calendar>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 margin-top-9">
      <label class="text-bold margin-right-9">Sender</label>
      <label class="margin-right-9"><input type="radio"
                                           formControlName="senderType"
                                           id="{{Sender.US}}" [value]="Sender.US"
                                           class="margin-right-3" [ngClass]="{'error': errors['senderType']}"
                                           name="senderType" />Us</label>
      <label><input type="radio"
                    formControlName="senderType" id="{{Sender.THEM}}" [value]="Sender.THEM" [ngClass]="{'error': errors['senderType']}"
                    class="margin-right-3"
                    name="senderType" />Them</label>
    </div>
    <div class="col-12 row align-items-baseline">
      <div class="col-12 col-xs-6 col-md-6">
        <label class="text-bold margin-right-9">Hidden</label>
        <label class="margin-right-9"><input type="radio"
                                             formControlName="hiddenDeals"
                                             id="{{HiddenDeals.EXCLUDE}}"
                                             [value]="HiddenDeals.EXCLUDE"
                                             class="margin-right-3" [ngClass]="{'error': errors['hiddenDeals']}"
                                             name="hiddenDeals" />Exclude</label>
        <label class="margin-right-9"><input type="radio"
                                             formControlName="hiddenDeals"
                                             id="{{HiddenDeals.INCLUDE}}"
                                             [value]="HiddenDeals.INCLUDE"
                                             class="margin-right-3" [ngClass]="{'error': errors['hiddenDeals']}"
                                             name="hiddenDeals" />Include</label>
        <label><input type="radio"
                      formControlName="hiddenDeals"
                      id="{{HiddenDeals.HIDE_ONLY}}"
                      [value]="HiddenDeals.HIDE_ONLY" [ngClass]="{'error': errors['hiddenDeals']}"
                      class="margin-right-3"
                      name="hiddenDeals" />Hidden Only</label>
      </div>
      <div class="col-12 col-xs-6 col-md-6 btn__container text-end pe-0">
        <cms-filters-management-menu [filters]="myFilters"
                                     [defaultFilter]="defaultFilter"
                                     [dialog]="dialog"
                                     [disableSaveCurrent]="disableSaveCurrent || (form && form.invalid)"
                                     (select)="selectMyFilter.emit($event)"
                                     (replaceFilter)="onReplaceFilter($event)"
                                     (saveAs)="onSaveFilter($event)"
                                     (setDefault)="setDefaultFilter.emit($event)"
                                     (closeDialog)="closeDialog.emit();"
                                     (delete)="deleteFilter.emit($event)"
                                     (manageFilters)="manageFilters.emit()"
                                     (saveCurrent)="openSaveCurrent.emit()">
        </cms-filters-management-menu>
        <button class="btn margin-top-3 margin-bottom-3 margin-left-12" type="submit" (click)="onSearch()" [disabled]="!(form && form.valid) || disabled">Search
        </button>
        <button class="btn margin-top-3 margin-bottom-3 margin-left-12" type="button" (click)="onReset()">Reset</button>
      </div>
    </div>
  </form>
</div>
