import { Message } from 'primeng/api';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { DialogState, TableDataValuesResponse } from '../../retry-queue/retry-queue.model';
import * as fromActions from './retry-queue.actions';

export interface ErrRetryQueueState {
  paging?: PagingFilter;
  sorting?: SortingFilter;
  loading: boolean;
  messages: Message[];
  data: DatatableData<TableDataValuesResponse[]>;
  organisationId?: number;
  filter: string;
  reasons: { key: string, value: string }[];
  dialog: DialogState;
  disabled: boolean;
  selected: TableDataValuesResponse[];
}

export const initialState: ErrRetryQueueState = {
  data: null,
  selected: [],
  messages: [],
  loading: false,
  paging: {
    page: 0,
    entriesPerPage: 25
  },
  sorting: {
    columnName: 'receiptTimestamp',
    sortOrder: 'ASCENDING'
  },
  organisationId: null,
  filter: '',
  reasons: [],
  dialog: null,
  disabled: true
};

export function errRetryQueueReducer(state: ErrRetryQueueState = { ...initialState },
                                     action: fromActions.ErrRetryQueueActions) {
  switch (action.type) {
    case fromActions.retrySuccessAction.type: {
      return {
        ...state,
        disabled: true
      };
    }
    case fromActions.rejectSuccessAction.type: {
      return {
        ...state,
        disabled: true
      };
    }
    case fromActions.setSelectedItems.type: {
      return {
        ...state,
        selected: action.payload,
        disabled: !action.payload.some(value => !!value)
      };
    }
    case fromActions.openDialogAction.type: {
      return {
        ...state,
        dialog: action.payload
      };
    }
    case fromActions.closeDialogAction.type: {
      return {
        ...state,
        dialog: null
      };
    }
    case fromActions.getRejectReasonsSuccessAction.type: {
      return {
        ...state,
        reasons: action.payload
      };
    }
    case fromActions.loadDataSuccessAction.type: {
      return {
        ...state,
        loading: false,
        disabled: true,
        data: action.payload,
        paging: {
          ...state.paging,
          page: action.payload.currentPage
        }
      };
    }
    case fromActions.loadDataAction.type: {
      return {
        ...state,
        loading: true,
        filter: action.payload.filter
       };
    }
    case fromActions.sortAndPageDataAction.type: {
      return {
       ...state,
        loading: true,
        messages: [],
        paging: action.payload.paging,
        sorting: action.payload.sorting,
      };
    }
    case fromActions.setMessagesAction.type: {
      return {
        ...state,
        messages: action.payload
      };
    }
    case fromActions.setOrganisationIdAction.type: {
      return {
        ...state,
        organisationId: action.payload
      };
    }
    case fromActions.setToDefaultAction.type: {
      return { ...initialState };
    }
    default: {
      return state;
    }
  }
}

export const getData = (datatableState: ErrRetryQueueState): DatatableData<TableDataValuesResponse[]> => datatableState.data;
export const getOrganisationId = (state: ErrRetryQueueState) => state.organisationId;
export const getSorting = (state: ErrRetryQueueState) => state.sorting;
export const getPaging = (state: ErrRetryQueueState) => state.paging;
export const getLoading = (state: ErrRetryQueueState) => state.loading;
export const getMessages = (state: ErrRetryQueueState) => state.messages;
export const getFilter = (state: ErrRetryQueueState) => state.filter;
export const getReasons = (state: ErrRetryQueueState) => state.reasons;
export const getDialog = (state: ErrRetryQueueState) => state.dialog;
export const getDisabled = (state: ErrRetryQueueState) => state.disabled;
