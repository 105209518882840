import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cms-deal-table-caption',
  templateUrl: './table-caption.component.html',
  styleUrls: ['./table-caption.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableCaptionComponent{
  @Input() exportDisable: boolean = true;
  @Input() addNoteDisable: boolean = true;
  @Input() showCustomise = true;
  @Input() showTerminationButton: boolean = false;
  @Input() terminateDisable: boolean = true;
  @Output() openAddNoteDialog = new EventEmitter();
  @Output() openTerminateDialog = new EventEmitter();
  @Output() exportEvent = new EventEmitter();
  @Output() clickCustomize = new EventEmitter();
  @Input() showMultiAgreeButton: boolean = false;
  @Input() multiAgreeDisable: boolean = true;
  @Output() openAgreeDialog = new EventEmitter();

  onOpenAddNoteDialog(): void {
    this.openAddNoteDialog.emit();
  }

  onOpenTerminateDialog(): void {
    this.openTerminateDialog.emit();
  }

  onExport(): void {
    this.exportEvent.emit();
  }

  onClickCustomize(): void {
    this.clickCustomize.emit();
  }

  onOpenAgreeDialog() {
    this.openAgreeDialog.emit();
  }
}
