import { Component, ComponentFactoryResolver, ComponentRef, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AuditTrailDocumentType, AudittrailEndPointConstants, AudittrailService, AudittrailUrlPart } from './audittrail.service';
import * as FileSaver from 'file-saver';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { EndPointConstants } from '@common/end-point-constants';
import { OrganisationService } from '@common/organisation.service';

@Component({
  selector: 'cms-dynamic-audit-trail-description',
  template: `<span #container></span>`
})
/**
 * Used example from part "Dynamic Components" https://medium.com/@DenysVuika/dynamic-content-in-angular-2-3c85023d9c36
 * (An easier way would be possible, if dataStoreId is guarantied to appear only once in the description.)
 */
export class DynamicAuditTrailDescriptionComponent implements OnInit, OnDestroy {
  @ViewChild('container', { read: ViewContainerRef, static: true })
  container: ViewContainerRef;

  @Input() text: string;
  @Input() auditEntryId: number;

  componentRefs: ComponentRef<{}>[] = [];

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private auditTrailService: AudittrailService) {
  }

  ngOnInit() {
    this.parseAuditTrailText();
  }

  private createPlainTextComponentRef(): ComponentRef<DynamicAuditTrailDescriptionPlainTextComponent> {
    return this.container.createComponent(this.componentFactoryResolver.resolveComponentFactory(DynamicAuditTrailDescriptionPlainTextComponent));
  }

  private createDataStoreLinkComponentRef(): ComponentRef<DynamicAuditTrailDescriptionDataStoreLinkComponent> {
    return this.container.createComponent(this.componentFactoryResolver.resolveComponentFactory(DynamicAuditTrailDescriptionDataStoreLinkComponent));
  }

  parseAuditTrailText() {
    if (!this.text) {
      return;
    }
    const regExp: RegExp = /\blink:(dataStoreId=(\d+))\b/g;
    let previousLastIndex: number = 0;
    let regExpExecArray: RegExpExecArray;
    while (regExpExecArray = regExp.exec(this.text)) {
      const linkText: string = regExpExecArray[1];          // example: 'dataStoreId=42'
      const dataStoreId: number = Number(regExpExecArray[2]); // example: 42
      const currentFirstIndex = regExpExecArray.index;
      if (previousLastIndex < currentFirstIndex) {
        this.setAuditTrailText(this.text.substring(previousLastIndex, currentFirstIndex));
      }
      this.setAuditTrailLinkText(linkText, this.auditEntryId, dataStoreId);
      previousLastIndex = regExp.lastIndex;
    }
    if (previousLastIndex < this.text.length) {
      this.setAuditTrailText(this.text.substring(previousLastIndex, this.text.length));
      // if no match has been found, previousLastIndex is still 0 -> whole text as plain text
    }
  }

  setAuditTrailText(text: string) {
    const plainTextComponentRef: ComponentRef<DynamicAuditTrailDescriptionPlainTextComponent> = this.createPlainTextComponentRef();
    plainTextComponentRef.instance.text = text;
    this.componentRefs.push(plainTextComponentRef);
  }

  setAuditTrailLinkText(text: string, auditEntryId, dataStoreId: number) {
    const dataStoreLinkComponentRef: ComponentRef<DynamicAuditTrailDescriptionDataStoreLinkComponent> = this.createDataStoreLinkComponentRef();
    const query = this.auditTrailService.getCurrentSearchQuery();
    dataStoreLinkComponentRef.instance.confirmationId = query.confirmationId;
    dataStoreLinkComponentRef.instance.id = query.id;
    dataStoreLinkComponentRef.instance.dataStoreId = query.dataStoreId;
    dataStoreLinkComponentRef.instance.historyId = query.historyId;
    dataStoreLinkComponentRef.instance.extId = query.extId;
    dataStoreLinkComponentRef.instance.auditGroupId = query.auditGroupId;
    dataStoreLinkComponentRef.instance.documentType = query.documentType;
    dataStoreLinkComponentRef.instance.auditEntryId = auditEntryId;
    dataStoreLinkComponentRef.instance.dataStoreIdToDownload = dataStoreId;
    dataStoreLinkComponentRef.instance.linkText = text;
    dataStoreLinkComponentRef.instance.urlPart = query.urlPart;
    dataStoreLinkComponentRef.instance.senderOrgEicCode = query.senderOrgEicCode;
    dataStoreLinkComponentRef.instance.invoiceId = query.invoiceId;
    dataStoreLinkComponentRef.instance.nettingId = query.nettingId;

    this.componentRefs.push(dataStoreLinkComponentRef);
  }

  ngOnDestroy() {
    this.componentRefs.forEach((componentRef) => {
      componentRef.destroy();
      componentRef = null;
    });
  }
}

@Component({ template: `{{text}}` })
export class DynamicAuditTrailDescriptionPlainTextComponent {
  @Input() text: string;
}


@Component({ template: `<a [routerLink]="['/api/audittrail/' + this.urlPart + '/xml']" [queryParams]="getQueryParams()" target="_blank" download>{{linkText}}</a>` })
export class DynamicAuditTrailDescriptionDataStoreLinkComponent implements OnDestroy {
  @Input() confirmationId: number;
  @Input() dataStoreId: number;
  @Input() historyId: number;
  @Input() extId: number;
  @Input() auditGroupId: number;
  @Input() documentType: AuditTrailDocumentType;
  @Input() auditEntryId: number;
  @Input() dataStoreIdToDownload: number;
  @Input() linkText: string;
  @Input() urlPart: AudittrailUrlPart;
  @Input() fileId: number;
  @Input() id: number;
  @Input() invoiceId: number;
  @Input() nettingId: number;
  @Input() senderOrgEicCode: string;
  private onDestroy$ = new Subject();

  constructor(private auditTrailService: AudittrailService, private organisationService: OrganisationService) {
  }

  getQueryParams(): any{
    const params = {};
    if (this.confirmationId != null) {
      params[AudittrailEndPointConstants.CONFIRMATION_ID] = this.confirmationId.toString();
    }
    if (this.dataStoreId != null) {
      params[AudittrailEndPointConstants.DATA_STORE_ID] = this.dataStoreId.toString();
    }
    if (this.historyId != null) {
      params[AudittrailEndPointConstants.HISTORY_ID] = this.historyId.toString();
    }
    if (this.auditGroupId != null) {
      params[AudittrailEndPointConstants.AUDIT_GROUP_ID] = this.auditGroupId.toString();
    }
    if (this.documentType != null) {
      params[AudittrailEndPointConstants.DOCUMENT_TYPE] = this.documentType.toString();
    }
    if (this.extId != null) {
      params[AudittrailEndPointConstants.EXT_ID] = this.extId.toString();
    }
    if (this.auditEntryId != null) {
      params[AudittrailEndPointConstants.AUDIT_ENTRY_ID] = this.auditEntryId.toString();
    }
    if (this.dataStoreIdToDownload != null) {
      params[AudittrailEndPointConstants.DATA_STORE_ID_TO_DOWNLOAD] = this.dataStoreIdToDownload.toString();
    }
    if (this.fileId != null) {
      params[AudittrailEndPointConstants.FILE_ID] = this.fileId.toString();
    }
    if (this.id != null) {
      params[AudittrailEndPointConstants.ID] = this.id.toString();
    }
    if (this.invoiceId != null) {
      params[AudittrailEndPointConstants.INVOICE_ID] = this.invoiceId.toString();
    }

    if (this.nettingId != null) {
      params[AudittrailEndPointConstants.NETTING_ID] = this.nettingId.toString();
    }

    if (this.senderOrgEicCode) {
      params[AudittrailEndPointConstants.SENDER_ORG_EIC_CODE] = this.senderOrgEicCode;
    }
    if (this.organisationService.getCachedOrganisationId()) {
      params[EndPointConstants.PARAM_ORGANISATION_ID] = this.organisationService.getCachedOrganisationId().toString();
    }

    return params;
  }
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }
}
