<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>

<h1>External RRM</h1>
<section class="panel-box">
  <cms-info ngPreserveWhitespaces>
    <!--@formatter:off (markdown rendering breaks, when the following tags are formatted)-->
    __External RRM View__

    Purpose: Displays the traffic between the ERR3 Module and Aris, external RRM and OMP

    | Status | Meaning |
    | ---------------------- | ------------- |
    | RECEIVED_FROM_CMH | The message was received from OMP. Waiting for further processing. |
    | RECEIVED_FROM_FTP | The message was received from sFTP Server. Waiting for further processing. |
    | SENT_TO_CMH | (final) The message has been sent to the ERR3 Remit module |
    | SENT_TO_XML | (final) The message has been sent to the OMP Backend. |
    | SENDING_TO_FTP | The message transfer via sFTP is currently in progress. |
    | SENT_TO_FTP | (final) The message has been sent to the sFTP endpoint (ARIS or External RRM) |
    | ERROR_TECHNICAL_RECEIPT | A RemitReceipt including technical errors was received. This is usually technical nature like signature failures etc. |
    | ERROR_LOCAL_PROCESSING | Any of the local message processing steps have failed. After solving the issue themessage can be resend. |
    | INVALID | (final) Received a XSD Schema invalid message which will be not further processed. |
  </cms-info>
  <p-messages styleClass="col-10" [value]="messages$ | async" [closable]="true"></p-messages>
  <cms-external-rrm-filter
    [status]="(status$ | async)"
    [formData]="(formData$ | async)"
    [isButtonVisible]="(formData$ | async)?.resendButtonColumnVisible"
    [messages]="messages$ | async"
    [filterData]="filterValue$ | async"
    (filter)="onHandleFilter($event)"
    (refresh)="onHandleRefresh($event)">
  </cms-external-rrm-filter>
  <div class="margin-top-15 external__table-btn-container">
    <cms-datatable
      *ngIf="data$ | async as data"
      [data]="(data$ | async)?.values"
      [tableColumns]="dataTableColumns"
      [totalRecords]="(data$ | async)?.count"
      [sortField]="(sorting$ | async)?.columnName"
      [sortOrder]="(sorting$ | async)?.sortOrder"
      [currentPage]="(paging$ | async)?.page"
      [loading]="loading$ | async"
      [autoLayout]="true"
      [scrollable]="false"
      [lazy]="!!((data$ | async)?.values?.length)"
      (lazyLoad)="onLazyLoad($event)">
      <ng-template pTemplate="body" let-row="row" let-column="column" let-rowIndex="rowIndex">
        <td *ngIf="column.type === 'resendButton'" [ngStyle]="column.style">
          <div class="btn-wrapper">
            <button
              *ngIf="row.messageStatus !== messageStatus.SENT_TO_CMH"
              id="external-err-resend-btn-{{rowIndex}}"
              class="btn btn-icon"
              (click)="onHandleResend(row.id)"
              title="Trigger Resend">
              <i class="pi pi-replay align-middle"></i>
            </button>
          </div>
        </td>
        <td *ngIf="column.type === 'downloadButton'" [ngStyle]="column.style">
          <div class="btn-wrapper">
            <button
              class="btn btn-icon fa fa-file"
              id="external-err-download-btn-{{rowIndex}}"
              (click)="onHandleDownload(row.id)"
              title="Document">
            </button>
          </div>
        </td>
        <td *ngIf="column.type === 'auditButton'" [ngStyle]="column.style">
          <div class="btn-wrapper">
            <a routerLink="/err/rrm_audittrail"
              target="_blank"
              id="external-err-audit-link-{{rowIndex}}"
              title="Audit Trail"
              [queryParams]="getDisplayParams(row)">
              <button
                class="btn btn-icon fa fa-search"
                id="external-err-audit-btn-{{rowIndex}}">
              </button>
            </a>
          </div>
        </td>
      </ng-template>
    </cms-datatable>
  </div>
</section>

</ng-template>
