import { Action } from '@ngrx/store';
import { Message } from 'primeng/api';
import { MailAlert } from '../../mail-alerts/mail-alerts.model';
import { DatatableData } from '../../../common/shared/results.model';
import { EditableCell } from '../../../common/datatable/cell-editor/cell-editor.model';
import { Column } from '../../../common/shared/datatable.model';
import { Prefix } from './mail-alerts.service';

export const SET_MESSAGES = 'Set Messages';
export const LOAD_DATA = 'Load Data';
export const SET_ORGANISATION_ID = 'Set Organisation Id';
export const SET_TO_DEFAULT = 'Set To Default';
export const LOAD_DATA_SUCCESS = 'Load Data Success';
export const LOAD_DATA_FAIL = 'Load Data Fail';
export const UPDATE_STATUS = 'Update Status';
export const UPDATE_STATUS_SUCCESS = 'Update Status Success';
export const UPDATE_COLUMNS = 'Update Columns';

export class UpdateColumnsAction implements Action {
  get type() {
    return `${this.prefix} ${UPDATE_COLUMNS}`;
  }
  constructor(public prefix: Prefix,
              public payload: Column[]) { }
}

export class SetMessagesAction implements Action {
  get type() {
    return `${this.prefix} ${SET_MESSAGES}`;
  }
  constructor(public prefix: Prefix,
              public payload: Message[]) { }
}

export class LoadDataAction implements Action {
  get type() {
    return `${this.prefix} ${LOAD_DATA}`;
  }
  constructor(public prefix: Prefix) { }
}

export class LoadDataSuccessAction implements Action {
  get type() {
    return `${this.prefix} ${LOAD_DATA_SUCCESS}`;
  }
  constructor(public prefix: Prefix,
              public payload: DatatableData<MailAlert[]>) { }
}

export class LoadDataFailedAction implements Action {
  get type() {
    return `${this.prefix} ${LOAD_DATA_FAIL}`;
  }
  constructor(public prefix: Prefix) { }
}

export class SetOrganisationIdAction implements Action {
  get type() {
    return `${this.prefix} ${SET_ORGANISATION_ID}`;
  }
  constructor(public prefix: Prefix,
              public payload: number) { }
}

export class SetToDefaultAction implements Action {
  get type() {
    return `${this.prefix} ${SET_TO_DEFAULT}`;
  }
  constructor(public prefix: Prefix) { }
}

export class UpdateStatusAction implements Action {
  get type() {
    return `${this.prefix} ${UPDATE_STATUS}`;
  }
  constructor(public prefix: Prefix,
              public payload: EditableCell) { }
}

export class UpdateStatusSuccessAction implements Action {
  get type() {
    return `${this.prefix} ${UPDATE_STATUS_SUCCESS}`;
  }
  constructor(public prefix: Prefix,
              public payload: MailAlert) { }
}

export type MailAlertsActions = UpdateStatusSuccessAction| UpdateStatusAction | SetToDefaultAction
  | SetMessagesAction | LoadDataAction | SetOrganisationIdAction;
