<p-dialog *ngIf="display"
          [header]="headerText"
          [(visible)]="display"
          [modal]="true"
          (visibleChange)="onVisibleChange($event)"
          [style]="{'min-width': '600px'}">
  <p-messages [value]="messages" [closable]="true"></p-messages>
  <form [formGroup]="form">
    <label for="text">Text:</label>
    <textarea [ngClass]="{'error': this.errors['text']}" id="text" name="text" formControlName="text" class="form-control" rows="10"></textarea>
  </form>
  <p-footer>
    <button class="btn margin-right-3" type="button" (click)="onCancel()">Cancel</button>
    <button class="btn" type="button" (click)="onSave()">Save</button>
  </p-footer>
</p-dialog>
