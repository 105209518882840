import { Message } from 'primeng/api';
import { createAction, union } from '@ngrx/store';
import { DialogState } from '@common/state/reducers';
import { MY_FILTERS_DIALOGS } from '@common/my-filters/my-filters.model';
import { DEALS_DIALOGS } from '@common/deals/deals.model';

export const openDialogAction = createAction('[ESM-NTT-PREVIEW] Open Dialog', (payload: DialogState<MY_FILTERS_DIALOGS | DEALS_DIALOGS>) => ({ payload }));
export const updateDialogAction = createAction('[ESM-NTT-PREVIEW] Update Dialog', (payload: Message[]) => ({ payload }));
export const closeDialogAction = createAction('[ESM-NTT-PREVIEW] Close Dialog');
export const setToDefaultAction = createAction('[ESM-NTT-PREVIEW] Set To Default');

const action = union({ openDialogAction, updateDialogAction, closeDialogAction ,setToDefaultAction});

export type DialogActions = typeof action;
