import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsErrorHandler } from './cms-error-handler';
import { ErrorMessageComponent } from './error-message.component';
import { ErrorMessageService } from './error-message.service';
import { HttpErrorInterceptor } from './http-error.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { PanelModule } from 'primeng/panel';
import { ButtonModule } from 'primeng/button';
import { RouterModule } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { ClientErrorsService } from '@common/errors/client-error-service';

@NgModule({
  providers: [
    {
      provide: ErrorHandler,
      useClass: CmsErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    ErrorMessageService,
    ClientErrorsService
  ],
  declarations: [
    ErrorMessageComponent,
    NotFoundComponent
  ],
  imports: [
    CommonModule,
    PanelModule,
    ButtonModule,
    RouterModule,
  ],
  exports: [
    ErrorMessageComponent
  ]
})
export class ErrorsModule {
}
