import { Component, OnDestroy, OnInit } from '@angular/core';
import { OrganisationService } from '../../../../common/organisation.service';
import { RemitOmpWhitelistService } from './remit-omp-whitelist.service';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { Message } from 'primeng/api';
import { ErrorMessageService } from '@common/errors/error-message.service';
import { Messages } from 'primeng/messages';
import { logging } from '@angular-devkit/core';

@Component({
  selector: 'cms-remit-omp-whitelist',
  templateUrl: './remit-omp-whitelist.component.html',
  styleUrls: ['./remit-omp-whitelist.component.scss']
})
export class RemitOmpWhitelistComponent implements OnInit, OnDestroy {

  private onDestroy$ = new Subject();
  selectedOrganisatationName: string = '';
  messages: Message[] = [];

  constructor(private orgService: OrganisationService,
              private remitOmpWhitelistService: RemitOmpWhitelistService,
              public readonly errorMessageService:ErrorMessageService) {
  }

  ngOnInit() {

    this.orgService.getChangeOrganisationObservable().subscribe(org => {
      if (org) {
        this.selectedOrganisatationName = org.displayName;
        this.remitOmpWhitelistService.refreshSite();
      }
    });

    this.remitOmpWhitelistService.onMessageChange().pipe(takeUntil(this.onDestroy$)).subscribe(message => {
      if(message != null) {
        this.messages = [...this.messages,this.map(message)];
      } else {
        this.messages = [];
      }
    });
  }

  ngOnDestroy() {
    this.remitOmpWhitelistService.setMessage(null);
    this.messages = [];
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  map(message: Message) {
   return {severity: message.severity, summary:message.summary, detail:message.detail, id:message.id, key: message.key}
  }
}
