<div class="panel-box" style="max-width: 850px;">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-12 col-sm-4 col-md-4 col-lg-6 col-xl-4">
        <div>
          <label for="documentType">Document Type</label>
          <p-multiSelect id="documentType"
                         inputId="documentType"
                         formControlName="documentType"
                         optionLabel="label"
                         optionValue="value"
                         [filter]="false"
                         [maxSelectedLabels]="docTypeOptions.length - 1"
                         [selectedItemsLabel]="'All ({0})'"
                         [options]="docTypeOptions"
                         [style]="{width:'200px'}">
          </p-multiSelect>
        </div>
      </div>

      <div class="col-12 col-sm-4 col-md-5 col-lg-6 col-xl-4">
        <div>
          <label for="documentType">Trade Repository</label>
          <p-multiSelect id="tradeRepository"
                         inputId="tradeRepository"
                         formControlName="tradeRepository"
                         [filter]="false"
                         [maxSelectedLabels]="6"
                         [selectedItemsLabel]="'All ({0})'"
                         [options]="tradeRepositoryOptions"
                         [style]="{width:'200px'}">
          </p-multiSelect>
        </div>
      </div>
      <div class="col flex align-items-end">
        <button type="submit"
                id="btn-search-by-document-type"
                class="form-control btn margin-3 padding-6"
                [disabled]="(cpmlDealsService.getLoading() | async)">Search</button>
        <button type="button" id="btn-reset-types" class="form-control btn margin-3 padding-6" (click)="onResetFilters()">Reset</button>
      </div>
    </div>
  </form>
</div>
