import { createAction, union } from '@ngrx/store';
import { Message } from 'primeng/api';
import { DatatableConfig } from '@common/shared/results.model';
import {
  DialogState,
  FilterRequest,
  RetryQueueIdentifier, TableDataValuesResponse
} from '../../retry-queue/retry-queue.model';

export const setToDefaultAction = createAction('[ERR RETRY QUEUE] Set To Default');
export const setOrganisationIdAction = createAction('[ERR RETRY QUEUE] Set Organisation Id', (payload: number) => ({payload}));
export const setMessagesAction = createAction('[ERR RETRY QUEUE] Set Messages', (payload: Message[]) => ({payload}));
export const loadDataAction = createAction('[ERR RETRY QUEUE] Load Data', (payload: FilterRequest) => ({payload}));
export const loadDataSuccessAction = createAction('[ERR RETRY QUEUE] Load Data Success', (payload: any) => ({payload}));
export const sortAndPageDataAction = createAction('[ERR RETRY QUEUE] Sort And Page Data', (payload: DatatableConfig) => ({payload}));
export const downloadDocumentAction = createAction('[ERR RETRY QUEUE] Download Document', (payload: number) => ({payload}));
export const retryAction = createAction('[ERR RETRY QUEUE] Retry');
export const retrySuccessAction = createAction('[ERR RETRY QUEUE] Retry Success');
export const getRejectReasonsAction = createAction('[ERR RETRY QUEUE] Get Reject Reasons', (payload: RetryQueueIdentifier[]) => ({payload}));
export const getRejectReasonsSuccessAction = createAction('[ERR RETRY QUEUE] Get Reject Reasons Success', (payload: { key: string, value: string }[]) => ({payload}));
export const rejectAction = createAction('[ERR RETRY QUEUE] Reject', (payload: { reason: string }) => ({payload}));
export const rejectSuccessAction = createAction('[ERR RETRY QUEUE] Reject Success');
export const openDialogAction = createAction('[ERR RETRY QUEUE] Open Dialog', (payload: DialogState) => ({payload}));
export const closeDialogAction = createAction('[ERR RETRY QUEUE] Close Dialog');
export const setSelectedItems = createAction('[ERR RETRY QUEUE] Set Selected Items', (payload: TableDataValuesResponse[]) => ({payload}));

const actions = union({
  setToDefaultAction,
  setOrganisationIdAction,
  setMessagesAction,
  loadDataAction,
  loadDataSuccessAction,
  sortAndPageDataAction,
  downloadDocumentAction,
  retryAction,
  getRejectReasonsAction,
  getRejectReasonsSuccessAction,
  rejectAction,
  openDialogAction,
  closeDialogAction,
  setSelectedItems,
  rejectSuccessAction,
  retrySuccessAction
});

export type ErrRetryQueueActions = typeof actions;
