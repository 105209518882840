import { datatableReducer, DatatableState } from '../datatable/datatable.reducer';
import { Contacts, ContactsFilters } from '../../contacts/contacts.model';

export const orgGroupDatatableInitialState: DatatableState<Contacts, ContactsFilters> = {
  selected: null,
  data: {
    values: [],
    count: 0
  },
  messages: [],
  filters: {
    organisationGroupId: null
  },
  loading: false,
  paging: {
    page: 0,
    entriesPerPage: 10
  },
  sorting: {
    columnName: 'organisationGroupDisplayName',
    sortOrder: 'ASCENDING'
  },
};

export const orgGroupsDatatableReducer = datatableReducer('[ORG GROUPS CONTACTS]', orgGroupDatatableInitialState);
