import { createSelector } from '@ngrx/store';
import { getAdminState, State } from '../../reducers';
import { DatatableState } from '../../datatable/datatable.reducer';
import { CmsBillingRunsState } from './cms-runs.reducers';
import * as fromDatatable from '../../datatable/datatable.reducer';

export const getBillingRunsState = createSelector(getAdminState, (state: State) => state.billingRuns.cmsBillingRuns);

export const getBillingRunParams = createSelector(getBillingRunsState, (billingRuns: CmsBillingRunsState) => billingRuns.billingRunParams);
export const billingRunPermission = createSelector(getBillingRunsState, (billingRuns: CmsBillingRunsState) => billingRuns.billingRunPermission);
export const startBillingRun = createSelector(getBillingRunsState, (billingRuns: CmsBillingRunsState) => billingRuns.startBillingRun);
export const isBillinRunLoading = createSelector(getBillingRunsState, (billingRuns: CmsBillingRunsState) => billingRuns.isBillinRunLoading);

export const getDatatable = createSelector(getBillingRunsState, (billingRuns: CmsBillingRunsState): DatatableState => billingRuns.datatable);
export const getOrganisationId = createSelector(getBillingRunsState, (state: CmsBillingRunsState) => state.organisationId);
export const getDialog = createSelector(getBillingRunsState, (state: CmsBillingRunsState) => state.dialog);

export const getData = createSelector(getDatatable, fromDatatable.getData);
export const getPaging = createSelector(getDatatable, fromDatatable.getPaging);
export const getLoading = createSelector(getDatatable, fromDatatable.getLoading);
export const getMessages = createSelector(getDatatable, fromDatatable.getMessages);
export const getSelected = createSelector(getDatatable, fromDatatable.getSelected);
