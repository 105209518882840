import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange} from '@angular/core';
import { Message } from 'primeng/api';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { Store } from '@ngrx/store';
import {DialogState} from '../../../administration/state/dialog/dialog.reducer';
import { EcmDealFinder } from '../../../ecm/deal-finder/deal-finder.model';

@Component({
  selector: 'cms-terminate-deal-dialog',
  templateUrl: './terminate-deal-dialog.component.html',
  styleUrls: ['./terminate-deal-dialog.component.scss']
})
export class TerminateDealDialogComponent implements OnInit, OnChanges, OnDestroy {
  @Input() messages: Message[];
  @Input() display = false;
  @Input() type: string;
  @Input() tradeId: string;
  @Input() closeButton: boolean;
  @Input() dialog: DialogState;
  @Input() deals: EcmDealFinder[];
  @Output() exit = new EventEmitter<Message[]>();
  @Output() terminate = new EventEmitter();
  errors: {[key: string]: ValidationErrors} = {};
  form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder, private store: Store<DialogState>) { }

  ngOnInit(): void {
    this.closeButton = false;
    this.form = this.formBuilder.group({
      reasonTextarea: ''
    });

  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (this.form) {
      if (changes.display && !this.display) {
        this.hide();
      }
      if (changes.data) {
        this.form.updateValueAndValidity();
      }
    }

    if (changes.messages) {
      this.errors = {};
      if (this.messages) {
        this.messages.forEach((obj: Message) => {
          const { key: controlName } = obj;
          this.errors[controlName] = { 'server': true };
        });

      }
    }
  }

  onCancel(): void {
    this.hide();
  }

  onClose(): void {
    this.hide();
  }

  onTerminate(): void {
    this.terminate.emit({...this.dialog, reason: this.form.controls.reasonTextarea.value});
  }

  hide(): void {
    this.messages = [];
    this.display = false;
    this.exit.emit();
    this.form.reset();
    this.form.updateValueAndValidity();
    this.closeButton = false;
  }

  hasTerminationErrorMessages(){
    for(const message of this.messages){
      if(message.data && message.severity === 'error'){
        return true;
      }
    }
    return false;
  }

  hasAnySuccessfullMessages(){
    for(const message of this.messages){
      if(message.data && message.severity !== 'error'){
        return true;
      }
    }
    return false;
  }

  hasTerminationErrorMessage(deal:EcmDealFinder ): boolean{
    for(const message of this.messages){
      if( message.severity === 'error' && message.data === '' + deal.id){
        return true;
      }
    }

    return false;
  }

  createTerminationFeedbackText(message: Message){
    let text = '';
    if(message && message.data && this.deals && this.deals.length > 0){

      for(const deal of this.deals){
         if('' + deal.id === message.data){
           text = deal.documentType + ' for trade ' + deal.tradeId;
            break;
         }
      }
    }

    return text;
  }

  ngOnDestroy(): void {
    this.closeButton = false;
  }
}
