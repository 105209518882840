import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { DateUtils } from '@common/shared/date.utils';
import { Message } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { RemitTradeEntryTable1ModifyService } from './remit-trade-entry-table1-modify.service';
import { EndPointConstants } from '@common/end-point-constants';

@Component({
  selector: 'cms-remit-table1-trade-modify',
  templateUrl: './remit-table1-trade-modify.component.html',
  styleUrls: ['./remit-table1-trade-modify.component.scss']
})
export class RemitTable1TradeModifyComponent implements OnInit, OnDestroy {

  private onDestroy$ = new Subject();

  modifyForm: UntypedFormGroup;

  transactionTimeUtc: string;
  recordType: string;
  historyId: number;

  showModificationValues: boolean = false;
  messages: Message[] = [];
  disabled: boolean = false;
  inProgress: boolean = false;
  internalError: boolean = false;
  submitEnabled: boolean = false;
  modifyTrade: boolean = false;
  acerV3DisabledMessage: Message[] = [];

  constructor(formBuilder: UntypedFormBuilder, private route: ActivatedRoute, private titleService: Title, private remitTradeTable1ModifyService: RemitTradeEntryTable1ModifyService) {
    this.modifyForm = formBuilder.group({});
  }

  ngOnInit() {
    this.modifyTrade = false;
    this.messages = [];
    this.inProgress = false;

    this.titleService.setTitle('Modify Trade');

    this.remitTradeTable1ModifyService.onTradeEntryModelChange()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(trade => {
        this.showModificationValues = trade !== null;
        this.submitEnabled = trade ? trade.submitEnabled : false;
        this.updateAcerV3Message();
      });

    this.remitTradeTable1ModifyService.onModifyTradeDisabled()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(modified => {
        if (modified) {
          this.modifyForm.disable();
        } else {
          this.modifyForm.enable();
          this.disabled = false;
          this.inProgress = false;
        }

      });

    this.remitTradeTable1ModifyService.onValidationMessages()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(message => {
        if (message && !this.hasFieldErrorMessage(message)) {
          this.messages = [...this.messages, message];
        }
      });

    if (this.isDisabled()) {
      this.modifyForm.disable();
    } else {
      this.modifyForm.enable();
    }

    this.remitTradeTable1ModifyService.onModifyTradeFinished()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(submitInProgress => {
        this.inProgress = submitInProgress;
      });

    this.route.queryParams
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((params: Params) => {
        this.recordType = params[EndPointConstants.PARAM_RECORD_TYPE];
        this.historyId = params[EndPointConstants.PARAM_HISTORY_ID];

        if (this.historyId) {
          this.messages = [];
          this.remitTradeTable1ModifyService.loadTradeEntrySetupData(this.historyId);
          this.remitTradeTable1ModifyService.loadTradeEntry(this.historyId);
        }
      });
  }

  private updateAcerV3Message() {
      this.acerV3DisabledMessage = [{ severity: 'info', detail: 'Reporting ACER Remit Table 1 Version 3. Version 2 trades will be converted to version 3 on modify.'}];

  }

  isFormValid(): boolean {

    return this.modifyForm.valid;
  }

  submit() {
    this.messages = [];

    this.markFormControlsAsDirty(this.modifyForm);

    if (this.submitEnabled && this.isFormValid()) {
      this.inProgress = true;
      this.disabled = true;

      const transactionDate: Date = this.modifyForm.controls['transactionDate'].value;
      if (transactionDate) {

        const time = this.modifyForm.controls['transactionTime'].value.split(':');
        transactionDate.setHours(parseInt(time[0], 10), parseInt(time[1], 10), parseInt(time[2], 10));
      }

      const deliveryStartDate: Date = this.modifyForm.controls['deliveryStartDate'].value;
      const deliveryEndDate: Date = this.modifyForm.controls['deliveryEndDate'].value;

      const modificationValues = {
        ...this.modifyForm.value,
        transactionDateTime: DateUtils.formatToISOIgnoreZone(transactionDate),
        deliveryStartDate: DateUtils.getLocalDateFormat(deliveryStartDate),
        deliveryEndDate: DateUtils.getLocalDateFormat(deliveryEndDate)
      };

      this.remitTradeTable1ModifyService.modifyTrade(modificationValues, this.historyId);
    }
  }

  private markFormControlsAsDirty(formGroup: UntypedFormGroup) {
    formGroup.markAsTouched();
  }

  private hasFieldErrorMessage(message: Message): boolean {
    for (const msg of this.messages) {
      if (msg.summary === message.summary && msg.severity === message.severity) {
        return true;
      }
    }

    return false;
  }

  isDisabled(): boolean {
    return this.disabled || !this.submitEnabled;
  }

  isInProgress(): boolean {
    return this.inProgress;
  }

  ngOnDestroy(): void {
    this.submitEnabled = false;
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

}
