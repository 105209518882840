import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { CpmlDealsSearchQuery, DocumentType, } from './cpml-dealfinder-filter.model';
import { CpmlDealsService } from '../../cpml-deals.service';
import { CpmlDashboardTradeRepositoryType } from '../../dashboard/filter/cpml-dashboard-filter.types';

@Component({
  selector: 'cms-cpml-dealfinder-doctypefilter',
  templateUrl: './cpml-dealfinder-doctypefilter.component.html',
  styleUrls: ['./cpml-dealfinder-doctypefilter.component.scss'],
})
export class CpmlDealFinderDocTypeFilterComponent implements OnInit {
  @Output() changeDtVisibilityDocFilter = new EventEmitter();
  form: UntypedFormGroup;
  readonly docTypeOptions: SelectItem[] = [
    { label: 'CNF', value: 'CNF' },
    { label: 'IRT', value: 'IRT' },
    { label: 'ETD', value: 'ETD' },
    { label: 'FXT', value: 'FXT' },
    { label: 'VAL', value: 'VAL' },
    { label: 'COL', value: 'COL' }
  ];
  readonly tradeRepositoryOptions: SelectItem[] = [
    { label: 'REGIS_TR', value: 'REGIS_TR' },
    { label: 'UNA_VISTA', value: 'UNA_VISTA' },
    { label: 'UNA_VISTA_MIFIR', value: 'UNA_VISTA_MIFIR' },
    { label: 'DTCC', value: 'DTCC' },
    { label: 'ACER', value: 'ACER' },
    { label: 'ELCOM', value: 'ELCOM' },
    { label: 'NONE', value: 'NONE' }
  ];

  readonly defaultDocTypes: DocumentType[] = ['CNF', 'IRT', 'ETD', 'FXT', 'VAL', 'COL'];
  readonly defaultTradeRepositories: CpmlDashboardTradeRepositoryType[] =  ['REGIS_TR', 'UNA_VISTA', 'UNA_VISTA_MIFIR', 'DTCC', 'ACER', 'ELCOM', 'NONE'];

  constructor(public cpmlDealsService: CpmlDealsService,
              private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      documentType: [[...this.defaultDocTypes], Validators.required],
      tradeRepository: [[...this.defaultTradeRepositories], Validators.required],
    });
  }

  onSubmit(): void {
    this.cpmlDealsService.emitNewSearchQuery(CpmlDealsSearchQuery.createEmptySearchQuery());
    if (this.form.valid) {
      this.cpmlDealsService.emitChangeDealFinderFilter(this.form.value.documentType, this.form.value.tradeRepository);
      this.changeDtVisibilityDocFilter.emit();
    }
  }

  onResetFilters(): void {

    if (this.form) {
      this.form.reset({
        documentType:[...this.docTypeOptions.map(item => item.value)],
        tradeRepository:[ ...this.tradeRepositoryOptions.map(item => item.value)]
      })
      this.form.updateValueAndValidity();
    }
  }
}



