import { createSelector } from '@ngrx/store';
import { getErrState, State } from '../reducers';
import * as fromReducer from '../mifid-details/mifid-details.reducer';

export const getMifidFileDetailsInState = createSelector(getErrState, (state: State) => state.mifidFileDetails);
export const getOrganisationId = createSelector(getMifidFileDetailsInState, (state: fromReducer.ErrMifidFileDetailsState) => state.organisationId);

export const getData = createSelector(getMifidFileDetailsInState, fromReducer.getData);
export const getSorting = createSelector(getMifidFileDetailsInState, fromReducer.getSorting);
export const getPaging = createSelector(getMifidFileDetailsInState, fromReducer.getPaging);
export const getLoading = createSelector(getMifidFileDetailsInState, fromReducer.getLoading);
export const getMessages = createSelector(getMifidFileDetailsInState, fromReducer.getMessages);
export const getColumns = createSelector(getMifidFileDetailsInState, fromReducer.getColumns);
export const getFileId = createSelector(getMifidFileDetailsInState, fromReducer.getFileId);
export const getInformation = createSelector(getMifidFileDetailsInState, fromReducer.getInformation);
export const getEditable = createSelector(getMifidFileDetailsInState, fromReducer.getEditable);
export const getFormValues = createSelector(getMifidFileDetailsInState, fromReducer.getFormValues);


