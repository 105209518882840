import { Injectable } from '@angular/core';
import { ApiRequestService } from '../api-request.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { MenuItem } from './menu.model';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  get navigation$(): Observable<MenuItem[]> {
    return this.navigationSubject$.asObservable();
  }

  private navigationSubject$ = new BehaviorSubject<MenuItem[]>([]);

  constructor(private apiGatewayService: ApiRequestService) {
  }

  fetchNavigation(): void {
    this.apiGatewayService.get('/cms/gui/navigation')
      .subscribe((navigation: MenuItem[]) => this.navigationSubject$.next(navigation));
  }

  cleanNavigation(): void {
    this.navigationSubject$.next(null);
  }

  onNavigationChanged(): Observable<MenuItem[]> {
    return this.navigationSubject$.asObservable();
  }

  containsPath(path: string, menu: MenuItem[]): boolean {

    let nodes: MenuItem[] = menu ? menu : this.navigationSubject$.getValue();
    if (nodes && nodes.length > 0) {
      const subPathes = path.split('/');
      for (let i = 0; i < subPathes.length; i++) {
        const node = this.getNode(nodes, subPathes[i]);

        nodes = node ? node.children : null;

        if (node && node.path.indexOf(subPathes[subPathes.length - 1]) >= 0) {
          return true;
        }
      }
    }
    return false;
  }

  private getNode(nodes: MenuItem[], name: string): MenuItem {
    const n = nodes ? nodes : this.navigationSubject$.value;
    for (const node of n) {
      if (node.path.indexOf(name) >= 0) {
        return node;
      }
    }
    return null;
  }

}
