import * as fromAction from './ecm-products.actions';
import { Message } from 'primeng/api';
import { EcmAddProduct, EcmProductsDataItem } from '../../products/ecm-products';

export interface EcmProductsState {
  add: EcmAddProduct;
  commodities: string[];
  markets: string[];
  transactionTypes: string[];
  data: EcmProductsDataItem[];
  loading: boolean;
  messages: Message[];
}

export const initialState: EcmProductsState = {
  add: {
    transactionType: null,
    market: null,
    commodity: null
  },
  commodities: [],
  markets: [],
  transactionTypes: [],
  data: null,
  loading: false,
  messages: []
};

export function ecmProductsReducer(state: EcmProductsState = {...initialState},
                                  action: fromAction.EcmProductsActions): EcmProductsState {
  switch (action.type) {
    case fromAction.setToDefaultAction.type: {
      return {...initialState};
    }
    case fromAction.loadPresetsSuccessAction.type: {
      return {
        ...state,
        ...action.payload
      };
    }
    case fromAction.setMessagesAction.type: {
      return {
        ...state,
        messages: action.payload
      };
    }
    case fromAction.loadDataActionSuccess.type: {
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    }
    case fromAction.loadDataAction.type: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromAction.cleanAddProductAction.type: {
      return {
        ...state,
        messages: [],
        add: {
          transactionType: null,
          market: null,
          commodity: null
        }
      };
    }
    default:
      return state;
  }
}

export const getCommodities = (state: EcmProductsState) => state.commodities;
export const getMarkets = (state: EcmProductsState) => state.markets;
export const getTransactionTypes= (state: EcmProductsState) => state.transactionTypes;
export const getMessages = (state: EcmProductsState) => state.messages;
export const getAddPanel = (state: EcmProductsState) => state.add;
export const getData = (state: EcmProductsState) => state.data;
export const getLoading = (state: EcmProductsState) => state.loading;

