import { createAction, union } from '@ngrx/store';
import {
  EsmDashboardPresetFilters,
  EsmDashboardPresetFiltersValues,
  EsmRoleByType
} from '../../../invoice/dashboard/esm-dashboard.model';

export const loadPresetValuesAction = createAction('[ESM DASHBOARD] Load Preset Values');
export const loadPresetValuesSuccessAction = createAction('[ESM DASHBOARD] Load Preset Values Success', (payload: EsmDashboardPresetFiltersValues) => ({ payload }));
export const setRoleByFilterValueAction = createAction('[ESM DASHBOARD] Set Role By Filter Action', (payload: EsmRoleByType) => ({ payload }));
export const setFilterValuesAction = createAction('[ESM DASHBOARD] Set Filter Values Action', (payload: EsmDashboardPresetFilters) => ({ payload }));
export const setDateRangeAction = createAction('[ESM DASHBOARD] Set Date Range Action', (payload: string) => ({ payload }));
export const setCategoryAction = createAction('[ESM DASHBOARD] Set Category Action', (payload: string) => ({ payload }));
export const filterAction = createAction('[ESM DASHBOARD] Filter');
export const filterSuccessAction = createAction('[ESM DASHBOARD] Filter Success', (payload: any) => ({ payload }));
export const refreshFiltersAction = createAction('[ESM DASHBOARD] Refresh Filters');
export const resetFiltersAction = createAction('[ESM DASHBOARD] Reset Filters');

const actions = union({
  loadPresetValuesAction,
  loadPresetValuesSuccessAction,
  setRoleByFilterValueAction,
  setFilterValuesAction,
  setCategoryAction,
  filterAction,
  filterSuccessAction,
  refreshFiltersAction,
  resetFiltersAction
});

export type EsmDashboardFiltersAction = typeof actions;
