import { Message } from 'primeng/api';
import { createAction, union } from '@ngrx/store';
import { DialogState } from '@common/state/reducers';
import { NETTING_STATEMENT_DIALOGS } from './dialog.reducers';

export const openDialogAction = createAction('[NETTING_STATEMENT_FINDER] Open Dialog', (payload: DialogState<NETTING_STATEMENT_DIALOGS>) => ({ payload }));
export const updateDialogAction = createAction('[NETTING_STATEMENT_FINDER] Update Dialog', (payload: Message[]) => ({ payload }));
export const closeDialogAction = createAction('[NETTING_STATEMENT_FINDER] Close Dialog');

const action = union({ openDialogAction, updateDialogAction, closeDialogAction });

export type DialogActions = typeof action;
