import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { RemitReportService } from '../remit-report.service';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'cms-emir-reconciliation-report-upload-panel',
  templateUrl: './emir-reconciliation-report-upload-panel.component.html',
  styleUrls: ['./emir-reconciliation-report-upload-panel.component.scss']
})
export class EmirReconciliationReportUploadPanelComponent implements OnInit, OnDestroy {

  @ViewChild('fileUpload', { static: false })
  fileUpload: ElementRef;

  private onDestroy$ = new Subject();
  public uploadForm: UntypedFormGroup;
  cvsFile: File;
  fileName: string = '';
  fileSelected: boolean = false;
  uploadAuthorised: boolean = false;

  constructor(private formBuilder: UntypedFormBuilder, private remitReportService: RemitReportService) {
    this.uploadForm = formBuilder.group({
      cvsFile: ['', Validators.required]
    });
  }

  ngOnInit() {
  }

  onSelect(event): void {
    this.remitReportService.setMessage(null);
    const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    this.fileSelected = this.remitReportService.validateEmirCsvFile(files[0]);
    if (this.fileSelected) {
      this.cvsFile = files[0];
      this.fileName = this.cvsFile.name;
    }
  }

  ngOnDestroy(): void {
    this.reset();
    this.uploadForm.reset();
    this.uploadAuthorised = false;
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  submit() {
    this.remitReportService.uploadEmirCsvFile(this.cvsFile);
  }

  downloadFormatInfo(){
    this.remitReportService.downloadFormatInfoFile().then(data => {
      FileSaver.saveAs(data.blob, data.filename);
    });

    return false;
  }

  downloadTemplate(){
    this.remitReportService.downloadTemplateFile().then(data => {
      FileSaver.saveAs(data.blob, data.filename);
    });

    return false;
  }

  browseFiles() {

    this.reset();

    document.getElementById('input-file-filename-emir-reconciliation').click();

    // prevents page reload
    return false;
  }

  private reset(){
    if(this.fileUpload){
      this.fileUpload.nativeElement.value = null;
    }
    this.cvsFile = null;
    this.fileName = '';
    this.fileSelected = false;
    this.remitReportService.setMessage(null);
  }

}
