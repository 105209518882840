import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {
  CpmlDashboardAssetClassType,
  CpmlDashboardBucketViewByType,
  CpmlDashboardDocumentFormatType,
  CpmlDashboardFilterStateType,
  CpmlDashboardTradeRepositoryType,
  getAllCpmlDashboardAssetClasses,
  getAllCpmlDashboardDocumentFormats,
  getAllCpmlDashboardTradeRepositories
} from './cpml-dashboard-filter.types';
import {CpmlDashboardService} from '../cpml-dashboard.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cms-cpml-dashboard-filter',
  templateUrl: './cpml-dashboard-filter.component.html',
  styleUrls: ['./cpml-dashboard-filter.component.scss']
})
export class CpmlDashboardFilterComponent implements OnInit, OnDestroy {

  @Input() category: string;

  resetEventEmitter = new EventEmitter<void>();
  dashboardFilterFormGroup: UntypedFormGroup;
  tradeRepositoryOptions: CpmlDashboardTradeRepositoryType[];
  assetClassOptions: CpmlDashboardAssetClassType[];
  otcEtdOptions: CpmlDashboardDocumentFormatType[];
  currentFilterState: CpmlDashboardFilterStateType;

  private subscription$ = new Subscription();

  constructor(private cpmlDashboardService: CpmlDashboardService, private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.tradeRepositoryOptions = getAllCpmlDashboardTradeRepositories();
    this.assetClassOptions = getAllCpmlDashboardAssetClasses();
    this.otcEtdOptions = getAllCpmlDashboardDocumentFormats();

    this.subscription$.add(this.cpmlDashboardService.getCurrentFilterStateChangeObservable().subscribe(filter => {
      this.currentFilterState = filter;
    }));

    this.dashboardFilterFormGroup = this.formBuilder.group({
      tradeRepository: new UntypedFormControl(this.currentFilterState.tradeRepositories, Validators.required),
      assetClass: new UntypedFormControl(this.currentFilterState.assetClasses, Validators.required),
      otcEtd: new UntypedFormControl(this.currentFilterState.documentFormats, Validators.required)
    });
  }

  onChangeViewByDateFilter(viewByDateType: CpmlDashboardBucketViewByType) {
    this.currentFilterState.viewBy=viewByDateType;
  }

  onChangeTradeRepostoryOptions(options: CpmlDashboardTradeRepositoryType[]) {
    this.currentFilterState.tradeRepositories=options;
  }

  onChangeAssetClassOptions(options: CpmlDashboardAssetClassType[]) {
    this.currentFilterState.assetClasses=options;
  }

  onChangeOtcEtdOptions(options: CpmlDashboardDocumentFormatType[]) {
    this.currentFilterState.documentFormats=options;
  }

  onFullFilter() {
    this.cpmlDashboardService.emitFullFilter(this.currentFilterState);
  }

  onResetFilters() {
    this.cpmlDashboardService.resetFilterState();
    this.resetEventEmitter.emit();
  }

  hasErrors() {
    return !this.dashboardFilterFormGroup.valid;
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

}
