import { createSelector } from '@ngrx/store';
import { EsmInternalMasterDataState } from './internal-master-data.reducer';
import { EsmVatIds } from '../../../master-data/master-data.model';
import { EsmMasterDataState } from '../master-data.reducer';
import { getEsmMasterDataState } from '../master-data.selectors';

export const getInternalState = createSelector(getEsmMasterDataState, (state: EsmMasterDataState) => state.internal);
export const getEsmPresetValues = createSelector(getInternalState, (state: EsmInternalMasterDataState) => state.presetValues);
export const getEsmPdfSettingsValues  = createSelector(getInternalState, (state: EsmInternalMasterDataState) => state.pdfSettings);
export const getMessages = createSelector(getInternalState, (state: EsmInternalMasterDataState) => state.messages);
export const getVatIds = createSelector(getInternalState, (state: EsmInternalMasterDataState) => state.vatIds);
export const getVersion = createSelector(getInternalState, (state: EsmInternalMasterDataState) => state.version);
export const getMasterDataId  = createSelector(getInternalState, (state: EsmInternalMasterDataState) => state.masterDataId);
export const isStale = createSelector(getInternalState, (state: EsmInternalMasterDataState) => state.stale);
export const getEsmFormValues = createSelector(getInternalState, (state: EsmInternalMasterDataState) => state.formValues);
export const getIsFormDisable = createSelector(getInternalState, (state: EsmInternalMasterDataState) => state.isFormDisable);
export const getIsNewMDRecord = createSelector(getInternalState, (state: EsmInternalMasterDataState) => state.isNewMDRecord);
export const getDialog = createSelector(getInternalState, (state: EsmInternalMasterDataState) => state.dialog);
export const getSelectedVatId = createSelector(getVatIds, getMasterDataId,
  (vatIds: EsmVatIds[], masterDataId: number) => vatIds ?
    vatIds.find(item => item.masterDataId === masterDataId) : null);
export const getLoading = createSelector(getInternalState, (state: EsmInternalMasterDataState) => state.loading);
export const getSorting = createSelector(getInternalState, (state: EsmInternalMasterDataState) => state.sorting);
export const getPaging = createSelector(getInternalState, (state: EsmInternalMasterDataState) => state.paging);
export const getData = createSelector(getInternalState, (state: EsmInternalMasterDataState) => state.data);
export const getBankDetailValue = createSelector(getInternalState, (state: EsmInternalMasterDataState) => state.bankDetailValue);
export const getIsVisibleDetails = createSelector(getInternalState, (state: EsmInternalMasterDataState) => state.isVisibleDetails);
