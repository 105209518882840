import { Message } from 'primeng/api';
import { createAction } from '@ngrx/store';
import { DialogState } from '@common/state/reducers';
import { SETTINGS_DIALOGS } from '../../../settings/invoice/invoice-settings.model';

export const setToDefaultAction = createAction('[ESM INVOICE SETTINGS] Set to default');
export const openDialogAction = createAction('[ESM INVOICE SETTINGS] Open Dialog', (payload: DialogState<SETTINGS_DIALOGS>) => ({ payload }));
export const updateDialogAction = createAction('[ESM INVOICE SETTINGS] Update Dialog', (payload: Message[]) => ({ payload }));
export const closeDialogAction = createAction('[ESM INVOICE SETTINGS] Close Dialog');
export const defaultDialogAction = createAction('[ESM INVOICE SETTINGS] Default Dialog');


export type EsmInvoiceSettingsAction = | typeof setToDefaultAction
  | typeof openDialogAction
  | typeof updateDialogAction
  | typeof closeDialogAction
  | typeof defaultDialogAction;

