import { Action } from '@ngrx/store';
import { PresetValues } from '../../robo-delegators.model';

export enum RoboDelegatorsUploadActionsTypes  {
  GetPresetValues = '[ROBO DELEGATORS] Get Preset Values',
  GetPresetValuesSuccess = '[ROBO DELEGATORS] Get Preset Values Success'
}

export class GetPresetValues implements Action {
    public readonly type = RoboDelegatorsUploadActionsTypes.GetPresetValues;
}

export class GetPresetValuesSuccess implements Action {
    public readonly type = RoboDelegatorsUploadActionsTypes.GetPresetValuesSuccess;
    constructor(public payload: PresetValues ){}
}

export type RoboDelegatorsUploadActions = GetPresetValues | GetPresetValuesSuccess;
