<div class="panel-box margin-left-50 margin-right-50 margin-top-50">
  <h1>Delete Trade</h1>
  <div id="divRemitTradeErrorFeedback">
    <p-messages [(value)]="msgs" [closable]="false"></p-messages>
  </div>
  <ng-container *ngIf="!internalError">
    <div class="p-warn margin-bottom-15">
      <div class="absolute top-3">
        <em class="fa fa-exclamation-triangle fa-2x"></em>
      </div>
      <div class="padding-left-30 margin-left-15">
        <strong>Confirm that you wish to logically delete all previously sent trade records for this trade from ARIS.</strong>
        <p>You cannot undo this action. Press "Delete Trade" to proceed or close this tab to abort.</p>
      </div>
    </div>
    <div class="col-12 text-center">
      <button id="btnRemitTradeErrorSubmit" class="btn margin-3 padding-6" type="button" (click)="submitDelete()" [disabled]="(disabled || !(submitEnabled$ | async))">
        Delete Trade
        <p-progressSpinner class="margin-top-9" *ngIf="isInProgress()" [style]="{width: '15px', height: '15px'}" strokeWidth="8"></p-progressSpinner>
      </button>
    </div>
  </ng-container>
</div>
