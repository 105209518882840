import { Action } from '@ngrx/store';
import { Message } from 'primeng/api';
import { ProductsModel } from '../../../settings/ecm-settings-tab-panel/products/products.model';
import { DialogState } from '../../../settings/ecm-settings-tab-panel/products/products.model';

export const SET_TO_DEFAULT = '[ECM SETTINGS PRODUCTS] Set to default';
export const SET_MESSAGES = '[ECM SETTINGS PRODUCTS] Set Messages';
export const SORT_AND_PAGE_DATA = '[ECM SETTINGS PRODUCTS] Sort and Page Data';
export const LOAD_DATA = '[ECM SETTINGS PRODUCTS] Load Data';
export const LOAD_DATA_SUCCESS = '[ECM SETTINGS PRODUCTS] Load Data Success';
export const DOWNLOAD_PRODUCTS_DATA = '[ECM SETTINGS PRODUCTS] Download Products Data';
export const OPEN_DIALOG = '[ECM SETTINGS PRODUCTS] Open Dialog';
export const CLOSE_DIALOG = '[ECM SETTINGS PRODUCTS] Close Dialog';
export const CONFIRM_BLOCK = '[ECM SETTINGS PRODUCTS] Confirm Block';
export const CONFIRM_ACTIVATE = '[ECM SETTINGS PRODUCTS] Confirm Activate';

export class SetToDefaultAction implements Action {
  readonly type = SET_TO_DEFAULT;
}

export class SetMessagesAction implements Action {
  readonly type = SET_MESSAGES;
  constructor(public payload: Message[]) { }
}

export class SortAndPageDataAction implements Action {
  readonly type = SORT_AND_PAGE_DATA;
}

export class LoadDataAction implements Action {
  readonly type = LOAD_DATA;
}

export class LoadDataSuccessAction implements Action {
  readonly type = LOAD_DATA_SUCCESS;
  constructor(public payload: ProductsModel) { }
}

export class DownloadProductsDataAction implements Action {
  readonly type = DOWNLOAD_PRODUCTS_DATA;
}

export class OpenDialogAction implements Action {
  readonly type = OPEN_DIALOG;
  constructor(public payload: DialogState) { }
}

export class CloseDialogAction implements Action {
  readonly type = CLOSE_DIALOG;
}

export class ConfirmBlockAction implements Action {
  readonly type = CONFIRM_BLOCK;
  constructor(public payload: number) { }
}

export class ConfirmActivateAction implements Action {
  readonly type = CONFIRM_ACTIVATE;
  constructor(public payload: number) { }
}

export type EcmSettingsProductsActions = SetToDefaultAction | SetMessagesAction | LoadDataAction |
  LoadDataSuccessAction | SortAndPageDataAction | OpenDialogAction | CloseDialogAction | ConfirmBlockAction | ConfirmActivateAction;
