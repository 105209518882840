import { datatableReducer, DatatableState } from '../datatable/datatable.reducer';
import { UserContacts, UserContactsFilters } from '../../contacts/contacts.model';

export const userDatatableInitialState: DatatableState<UserContacts, UserContactsFilters> = {
  selected: null,
  data: {
    values: [],
    count: 0
  },
  messages: [],
  filters: {
    idOfSelectedOrganisation: null
  },
  loading: false,
  paging: {
    page: 0,
    entriesPerPage: 10
  },
  sorting: {
    columnName: 'lastName',
    sortOrder: 'ASCENDING'
  },
};

export const userDatatableReducer = datatableReducer('[USERS CONTACTS]', userDatatableInitialState);
