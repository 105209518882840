import { createAction, union } from '@ngrx/store';
import { Message } from 'primeng/api';
import { InvoiceTypeModel } from '../../../../settings/invoice/invoice-settings.model';

export const setMessagesAction = createAction('[ESM INVOICE SETTINGS] Set Messages', (payload: Message[]) => ({ payload }));
export const setToggleMatchAllowed = createAction('[ESM INVOICE SETTINGS] Set Toggle Match Allowed', (payload: boolean) => ({ payload }));
export const setEditTypesAllowed = createAction('[ESM INVOICE SETTINGS] Edit Types Allowed', (payload: boolean) => ({ payload }));
export const loadTableDataAction = createAction('[ESM INVOICE SETTINGS] Load Table Data');
export const loadTableDataSuccessAction = createAction('[ESM INVOICE SETTINGS] Load Table Data Success', (payload: InvoiceTypeModel[]) => ({ payload }));
export const deleteAction = createAction('[ESM INVOICE SETTINGS] Delete', (payload: number) => ({ payload }));
export const confirmActivateAction = createAction('[ESM INVOICE SETTINGS] Confirm Activate', (payload: number) => ({ payload }));
export const confirmBlockAction = createAction('[ESM INVOICE SETTINGS] Confirm Block', (payload: number) => ({ payload }));



export type EsmSettingsTableActions =  | typeof  setMessagesAction
  | typeof  setToggleMatchAllowed
  | typeof  setEditTypesAllowed
  | typeof  loadTableDataAction
  | typeof  loadTableDataSuccessAction
  | typeof  deleteAction
  | typeof  confirmActivateAction
  | typeof  confirmBlockAction;

