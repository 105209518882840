<cms-filters-management-menu
  [filters]="myFilters$ | async"
  [dialog]="dialog$ | async"
  [defaultFilter]="defaultMyFilter$ | async"
  (select)="onSelectMyFilter($event)"
  (setDefault)="onSetDefaultMyFilter($event)"
  (replaceFilter)="onReplaceMyFilter($event)"
  (saveAs)="onSaveMyFilter($event)"
  (delete)="onDeleteMyFilter($event)"
  (manageFilters)="onManageMyFilters()"
  [disableSaveCurrent]="(myForm && myForm.invalid)"
  (saveCurrent)="openSaveMyFilterDialog()"
  (closeDialog)="onCloseMyFiltersDialog()"
>
</cms-filters-management-menu>
