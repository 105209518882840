import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { DialogState, HiddenDeals, Sender, updateDealsMultiSelect } from '@common/deals/deals.model';
import { MyFilter } from '@common/my-filters/my-filters.model';
import { DealFinderPropertiesFilters, EcmMyFilter } from '../deal-finder.model';
import { Message } from 'primeng/api';
import { getSaveReplaceMyFilter, shouldDisableSaveCurrent } from '../../ecm.model';
import * as moment from 'moment';

@Component({
  selector: 'cms-deal-finder-properties-filters',
  templateUrl: './deal-finder-properties-filters.component.html',
  styleUrls: ['./deal-finder-properties-filters.component.scss'],
})
export class DealFinderPropertiesFiltersComponent implements OnInit, OnChanges {
  @Input() myFilters: EcmMyFilter[];
  @Input() filters: DealFinderPropertiesFilters;
  @Input() allDealsFilter: EcmMyFilter;
  @Input() brokers = [];
  @Input() commodities = [];
  @Input() counterParties = [];
  @Input() markets = [];
  @Input() transactionTypes = [];
  @Input() dialog: DialogState;
  @Input() defaultFilter: EcmMyFilter;
  @Input() messages: Message[] = [];
  @Input() disabled: boolean = false;

  @Output() filter = new EventEmitter<DealFinderPropertiesFilters>();
  @Output() reset = new EventEmitter();
  @Output() confirmSaveCurrent = new EventEmitter();
  @Output() openSaveCurrent = new EventEmitter();
  @Output() manageFilters = new EventEmitter();
  @Output() selectMyFilter = new EventEmitter<MyFilter | null>();
  @Output() replaceFilter = new EventEmitter<EcmMyFilter>();
  @Output() deleteFilter = new EventEmitter<string>();
  @Output() setDefaultFilter = new EventEmitter<MyFilter>();
  @Output() closeDialog = new EventEmitter();

  form: UntypedFormGroup;
  brokerOptions: { label: string, value: string}[] = [];
  commodityOptions: { label: string, value: string}[] = [];
  counterPartyOptions: { label: string, value: string}[] = [];
  marketOptions: { label: string, value: string}[] = [];
  transactionTypeOptions: { label: string, value: string}[] = [];
  errors: {[key: string]: ValidationErrors} = {};
  disableSaveCurrent = true;
  updateDealsMultiSelect;
  tradeSubmissionMaxDate: Date;

  readonly HiddenDeals = HiddenDeals;
  readonly Sender = Sender;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.updateDealsMultiSelect = updateDealsMultiSelect.bind(this);
  }

  ngOnInit(): void {
    const {
      brokers,
      commodities,
      counterParties,
      deliveryStartDate,
      deliveryEndDate,
      hiddenDeals,
      markets,
      price,
      senderType,
      submissionDateRange,
      tradeDateRange,
      transactionTypes
    } = this.filters;

    const now = moment();
    this.tradeSubmissionMaxDate = now.toDate();

    this.form = this.formBuilder.group({
      brokers: { value: brokers, disabled: !this.brokers || !this.brokers.length },
      commodities: { value: commodities, disabled: !this.commodities || !this.commodities.length },
      counterParties: { value: counterParties, disabled: !this.counterParties || !this.counterParties.length },
      markets: { value: markets, disabled: !this.markets || !this.markets.length },
      transactionTypes: { value: transactionTypes, disabled: !this.transactionTypes || !this.transactionTypes.length },
      submissionDateStart: submissionDateRange && submissionDateRange.start,
      submissionDateEnd: submissionDateRange && submissionDateRange.end,
      tradeDateStart: tradeDateRange && tradeDateRange.start,
      tradeDateEnd: tradeDateRange && tradeDateRange.end,
      deliveryStartDate,
      deliveryEndDate,
      hiddenDeals,
      price,
      senderType,
    });

    this.updateDealsMultiSelect(
      'brokers',
      'brokerOptions',
      this.brokers,
      Validators.required);

    this.updateDealsMultiSelect(
      'commodities',
      'commodityOptions',
      this.commodities,
      Validators.required);

    this.updateDealsMultiSelect(
      'counterParties',
      'counterPartyOptions',
      this.counterParties,
      Validators.required);

    this.updateDealsMultiSelect(
      'markets',
      'marketOptions',
      this.markets,
      Validators.required);

    this.updateDealsMultiSelect(
      'transactionTypes',
      'transactionTypeOptions',
      this.transactionTypes,
      Validators.required);

    this.disableSaveCurrent = shouldDisableSaveCurrent(this.form.getRawValue(), this.allDealsFilter);
    this.form.valueChanges.subscribe(() => {
      this.disableSaveCurrent = shouldDisableSaveCurrent(this.form.getRawValue(), this.allDealsFilter);
    });

    this.form.statusChanges.subscribe(status => {
      if (status === 'INVALID') {
        Object.keys(this.form.controls)
          .filter(key => this.form.get(key).invalid)
          .forEach(key => this.form.get(key).markAsDirty());
      }
    });
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (this.form && changes.filters) {
      const {
        brokers,
        commodities,
        counterParties,
        deliveryStartDate,
        deliveryEndDate,
        hiddenDeals,
        markets,
        price,
        senderType,
        submissionDateRange,
        tradeDateRange,
        transactionTypes
      } = this.filters;
      this.form.patchValue({
        brokers,
        commodities,
        counterParties,
        markets,
        transactionTypes,
        deliveryStartDate,
        deliveryEndDate,
        hiddenDeals,
        price,
        senderType,
        submissionDateStart: submissionDateRange && submissionDateRange.start,
        submissionDateEnd: submissionDateRange && submissionDateRange.end,
        tradeDateStart: tradeDateRange && tradeDateRange.start,
        tradeDateEnd: tradeDateRange && tradeDateRange.end,
      });
      this.form.updateValueAndValidity();
    }

    if (changes.brokers) {
      this.updateDealsMultiSelect(
        'brokers',
        'brokerOptions',
        this.brokers,
        Validators.required);
    }

    if (changes.commodities) {
      this.updateDealsMultiSelect(
        'commodities',
        'commodityOptions',
        this.commodities,
        Validators.required);
    }

    if (changes.counterParties) {
      this.updateDealsMultiSelect(
        'counterParties',
        'counterPartyOptions',
        this.counterParties,
        Validators.required);
    }

    if (changes.markets) {
      this.updateDealsMultiSelect(
        'markets',
        'marketOptions',
        this.markets,
        Validators.required);
    }

    if (changes.transactionTypes) {
      this.updateDealsMultiSelect(
        'transactionTypes',
        'transactionTypeOptions',
        this.transactionTypes,
        Validators.required);
    }

    if (changes.messages) {
      this.errors = {};
      if (this.messages) {
        this.messages.forEach((obj: Message) => {
          const { key: controlName } = obj;
          this.errors[controlName] = { 'server': true };
        });
      }
    }

    if (this.form) {
      this.disableSaveCurrent = shouldDisableSaveCurrent(this.form.getRawValue(), this.allDealsFilter);
    }
  }

  onKeyPress(event) {
    if (this.form.value.price && this.form.value.price.toString().length >= 25) {
      event.preventDefault();
      return false;
    }
  }

  onSearch(): void {
    const {
      brokers,
      commodities,
      counterParties,
      deliveryStartDate,
      deliveryEndDate,
      hiddenDeals,
      markets,
      price,
      senderType,
      transactionTypes,
      submissionDateStart,
      submissionDateEnd,
      tradeDateStart,
      tradeDateEnd,
    } = this.form.value;
    this.filter.emit({
      brokers,
      commodities,
      counterParties,
      deliveryStartDate,
      deliveryEndDate,
      hiddenDeals,
      markets,
      price,
      senderType,
      transactionTypes,
      submissionDateRange: {
        start: submissionDateStart,
        end: submissionDateEnd
      },
      tradeDateRange: {
        start: tradeDateStart,
        end: tradeDateEnd
      }
    });
  }

  onSaveFilter(filter: MyFilter) : void {
    this.confirmSaveCurrent.emit(getSaveReplaceMyFilter(this.form.value, filter));
  }

  onReset(): void {
    this.reset.emit();
  }

  onReplaceFilter(filter): void {
    this.replaceFilter.emit(getSaveReplaceMyFilter(this.form.value, filter));
  }
}
