import { Action } from '@ngrx/store';
import { PresetValues } from '../standing-instructions-upload.model';

export const GET_PRESET_VALUES = '[Standing Instructions] Get Preset Values';
export const GET_PRESET_VALUES_SUCCESS = '[Standing Instructions] Get Preset Values Success';

export class GetPresetValues implements Action {
    public readonly type = GET_PRESET_VALUES;
}

export class GetPresetValuesSuccess implements Action {
    public readonly type = GET_PRESET_VALUES_SUCCESS;
    constructor(public payload: PresetValues ){}
}

export type StandingInstructionsUploadActions = GetPresetValues | GetPresetValuesSuccess;
