import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ExceptionFilter } from '../exceptions.model';

interface QueueValue {
  label: string;
  value: string;
}
@Component({
  selector: 'cms-exceptions-filter',
  templateUrl: './exceptions-filter.component.html',
  styleUrls: ['./exceptions-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExceptionsFilterComponent implements OnInit, OnChanges {
  @Input() formData: ExceptionFilter;
  @Input() availableQueues: string[];
  @Input() disabled: boolean;
  @Output() confirm = new EventEmitter<ExceptionFilter>();

  form: UntypedFormGroup;
  todayMaxDate: Date;
  queueValueOptions: QueueValue[] = [];
  defaultFilterData: ExceptionFilter = {
    queue: '',
    to: '',
    from: ''
  };

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({ ...(this.formData || this.defaultFilterData) });
    this.todayMaxDate = new Date();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes.availableQueues) {
      this.queueValueOptions = this.availableQueues ? this.availableQueues.map(item => ({
        label: item,
        value: item === 'All' ? '' : item
      })) : [{ value: '', label: 'All' }];
    }
    if (this.form && changes.formData) {
      this.form.patchValue({ ...this.formData });
      this.form.updateValueAndValidity();
    }
  }

  onSubmit(): void {
    const obj = this.form.getRawValue();
    this.confirm.emit(obj);
  }
}
