import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import { Message } from 'primeng/api';
import { of } from 'rxjs';
import { ApiRequestService } from '@common/api-request.service';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { withFirstLetterUpperCase } from '@common/deals/deals.model';
import { InitialStartAndEndDate, InitialStartAndEndDateResponse } from '../../reports/reports.model';
import { ErrorResponse } from '@common/error-response.model';
import * as fromActions from './reports.actions';
import * as moment from 'moment';
import { Action } from '@ngrx/store';
import { saveBlob, toPayload } from '@common/cms-common.model';

@Injectable()
export class ReportsEffects {
  private baseUrl = '/api/reporting/ecm/reporting';

  constructor(private actions$: Actions,
              private apiGateway: ApiRequestService,
              private mapper: MessagesMapperService) { }

   validate$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.VALIDATE),
      map(toPayload),
      concatMap(({filter, reportType}) =>
        this.apiGateway.post(`${this.baseUrl}/${reportType}/validate/input`, this.getRequest(filter))
          .pipe(
            concatMap((response: ErrorResponse | any) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              const result: Action[] = [];
              if (messages && messages.length) {
                result.push(new fromActions.SetMessagesAction(messages));
                result.push(new fromActions.ValidationFailed({reportType}));
              } else {
                result.push(...[
                  new fromActions.DownloadEcmDetailsAction({
                    filter,
                    reportType
                  }),
                  new fromActions.SetMessagesAction(
                    [{ severity: 'info', detail: `Generating eCM ${withFirstLetterUpperCase(reportType)} report...` }])
                ]);
              }
              return result;
            }),
            catchError(() => [new fromActions.SetMessagesAction([this.mapper.createErrorMessage('Server error')])])
          )
      )));

   initialStartAndEndDate$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.LOAD_INITIAL_START_AND_END_DATE),
      concatMap(() =>
        this.apiGateway.get(`${this.baseUrl}/initialStartAndEndDate`)
          .pipe(
            switchMap((response: InitialStartAndEndDateResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [new fromActions.SetMessagesAction(messages)];
              }
              return [
                new fromActions.LoadInitialStartAndEndDateSuccessAction(response),
                new fromActions.SetMessagesAction([])
              ];
            }),
            catchError(() => of(new fromActions.SetMessagesAction([this.mapper.createErrorMessage('Server error')])))
          )
      ),
    ));

   billingDetails$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.DOWNLOAD_ECM_REPORT),
      map(toPayload),
      concatMap(({ reportType, filter }) =>
        this.apiGateway.getBlob(`${this.baseUrl}/${reportType}/excel`, this.getRequest(filter))
          .pipe(
            concatMap((response: any) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [new fromActions.SetMessagesAction(messages)];
              }
              saveBlob(response);
              return [
                new fromActions.DownloadSuccesAction({reportType}),
                new fromActions.SetMessagesAction(
                this.mapper.toSuccessMessages(`Download of eCM ${withFirstLetterUpperCase(reportType)} report successful.`))
              ];
            }),
            catchError(() => of(new fromActions.SetMessagesAction([this.mapper.createErrorMessage('Server error')])))
          )
      )
    ));

  private getRequest({ start, end }: { start, end}): InitialStartAndEndDate {
    return {
      start: start && moment(start, 'DD/MM/YYYY').format('YYYY-MM-DD') || null,
      end: end && moment(end, 'DD/MM/YYYY').format('YYYY-MM-DD') || null
    };
  }
}
