<div class="datatable-container" style="max-width: 1800px">
  <p-table
    [value]="records"
    [rows]="defaults.DEFAULT_ROWS"
    [totalRecords]="recordCount"
    [rowsPerPageOptions]="defaults.ROW_SELECTION_NUMBERS"
    [lazy]="!!(records && records.length)"
    [sortOrder]="1"
    [scrollable]="true"
    [paginator]="true"
    [loading]="loading"
    (onLazyLoad)="onLazyLoad($event)"
    sortField="displayName"
  >
    <ng-template pTemplate="caption">
      <div class="padding-6" style="max-width: 650px;">
        <form [formGroup]="filterForm" class="row" (submit)="filter();">
          <div class="col-8">
            <input formControlName="filter" [maxlength]="20" type="text" class="form-control p-component padding-6 text-medium" placeholder="EIC Code / LEI Code / Display Name">
          </div>
          <div class="col-4">
            <button class="btn form-control margin-3 padding-6" style="width: 75px;" type="submit" [disabled]="filterForm.invalid">Search</button>
            <button style="width: 75px;" class="btn form-control margin-3 padding-6" type="button" (click)="reset()">Reset</button>
          </div>
        </form>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th scope="col" *ngFor="let col of cols" [pSortableColumn]="col.sortable ? col.name : ''" [ngStyle]="col.style">
          {{col.title}}
          <p-sortIcon [field]="col.name" *ngIf="col.sortable"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
      <tr>
        <td *ngFor="let col of cols" [ngStyle]="col.style" [pTooltip]="row[col.name]" >
          <span>{{row[col.name]}}</span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="paginatorleft">
      <span class="paginator-info">Showing {{pagerInfo.fromNumberOfResults}} to {{pagerInfo.toNumberOfResults}} of {{pagerInfo.numberOfAllRecords}}</span>
    </ng-template>
  </p-table>
</div>
