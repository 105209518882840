import { createSelector } from '@ngrx/store';
import { getPreferencesState } from '../../reducers';
import { DatatableData } from '../../../../common/shared/results.model';
import { MailAlert } from '../../../mail-alerts/mail-alerts.model';
import { Column } from '../../../../common/shared/datatable.model';
import { MailAlertState, State } from '../mail-alerts.reducer';

export const getGlobalState = createSelector(getPreferencesState, (state: State) => state.global);
export const getColumns = createSelector(getGlobalState, (global: MailAlertState): Column[] => global.columns);
export const getData = createSelector(getGlobalState, (global: MailAlertState): DatatableData<MailAlert[]> => global.data);
export const getLoading = createSelector(getGlobalState, (state: MailAlertState) => state.loading);
export const getMessages = createSelector(getGlobalState, (state: MailAlertState) => state.messages);
export const getOrganisationId = createSelector(getGlobalState, (state: MailAlertState) => state.organisationId);
export const getSorting = createSelector(getGlobalState, (state: MailAlertState) => state.sorting);
