import {DatatableConfig} from '@common/shared/results.model';
import {createAction, union} from '@ngrx/store';
import {Column} from '@common/shared/datatable.model';
import {SearchBy} from '../esm-netting-finder.reducers';
import {Message} from 'primeng/api';
import {AddNote} from '@common/deals/deals.model';
import {EsmNettingStatementModel} from '../../../../netting/finder/esm-netting-finder.model';

// is this ok in table actions? better place may be main component netting finder.actions
export const setMessagesAction = createAction('[NETTING STATEMENT FINDER TABLE] Set Messages', (payload: Message[]) => ({payload}));

export const setSearchByAction = createAction('[NETTING STATEMENT FINDER TABLE] Set Search By', (payload: SearchBy) => ({payload}));
export const sortAndPageDataAction = createAction('[NETTING STATEMENT FINDER TABLE] Sort And Page Data', (payload: DatatableConfig) => ({payload}));
export const reloadTableDataAction = createAction('[NETTING STATEMENT FINDER TABLE] Reload Table Data');
export const setColumnsAction = createAction('[NETTING STATEMENT FINDER TABLE] Set Columns', (payload: Column[]) => ({payload}));
export const downloadHtmlAction = createAction('[NETTING STATEMENT FINDER TABLE] Download Html', (payload: number) => ({payload}));
export const downloadXmlAction = createAction('[NETTING STATEMENT FINDER TABLE] Download Xml', (payload: number) => ({payload}));
export const downloadPdfAction = createAction('[NETTING STATEMENT FINDER TABLE] Download Pdf', (payload: string) => ({payload}));

export const setHtmlAction = createAction('[NETTING STATEMENT FINDER TABLE] Set Html', (payload: Blob) => ({payload}));

// set visible
export const setVisibleAction = createAction('[NETTING STATEMENT FINDER TABLE] Set Table Visible', (payload: boolean) => ({payload}));

// toggle notes
export const setSelectedIdsAction = createAction('[NETTING STATEMENT FINDER TABLE] Set Selected Ids Action', (payload: { confirmationIds: string[] }) => ({payload}));
export const setSelectedNettingStatementsAction = createAction('[NETTING STATEMENT FINDER TABLE] Set Selected Netting Statements Action', (payload: { nettingStatements: EsmNettingStatementModel[] }) => ({payload}));
export const addNoteAction = createAction('[NETTING STATEMENT FINDER TABLE] Add Note', (payload: AddNote) => ({payload}));
export const addNoteSuccessAction = createAction('[NETTING STATEMENT FINDER TABLE] Add Note Success');

export const exportAction = createAction('[NETTING STATEMENT FINDER TABLE] Export');

const actions = union({
  setMessagesAction,
  setSearchByAction,
  sortAndPageDataAction,
  reloadTableDataAction,
  setColumnsAction,
  exportAction,
  downloadHtmlAction,
  downloadXmlAction,
  downloadPdfAction,
  setHtmlAction,
  setVisibleAction,
  setSelectedIdsAction,
  setSelectedNettingStatementsAction,
  addNoteAction,
  addNoteSuccessAction
});

export type NettingStatementFinderTableActions = typeof actions;
