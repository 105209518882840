import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { forkJoin, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { DateTimeRangeFilter } from '@common/shared/shared.model';
import { OrganisationService } from '@common/organisation.service';
import { RemitFilesInSearchQuery, RemitFilesInService } from './remit-files-in.service';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'cms-remit-files-in-search',
  templateUrl: './remit-files-in-search.component.html',
  styleUrls: ['./remit-files-in-search.component.scss']
})
export class RemitFilesInSearchComponent implements OnInit, OnDestroy {
  filenameForm: UntypedFormGroup;
  filterForm: UntypedFormGroup;
  documentTypes: SelectItem[] = [];
  private onDestroy$ = new Subject();

  constructor(private orgService: OrganisationService,
              private formBuilder: UntypedFormBuilder,
              public filesInService: RemitFilesInService) {
    this.filenameForm = this.formBuilder.group({
      filename: [null, Validators.compose([Validators.required, Validators.maxLength(100)])]
    });

    this.filterForm = this.formBuilder.group({
      documentTypes: ['', Validators.required],
      receivedFromDate: ['', Validators.required],
      receivedStartTime: ['', Validators.required],
      receivedToDate: ['', Validators.required],
      receivedEndTime: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    forkJoin([
      this.filesInService.loadDocumentTypes(),
      this.filesInService.loadReceiptDate()
    ])
    .pipe(take(1))
    .subscribe(data => {
      this.onLoadDocumentTypesHandler(data[0]);
      this.onLoadReceiptDateHandler(data[1]);
    });

    this.orgService.getOrganisationChangeIfAccessible()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        forkJoin([
          this.filesInService.loadDocumentTypes(),
          this.filesInService.loadReceiptDate()
        ])
        .pipe(takeUntil(this.onDestroy$)).subscribe(data => {
          this.onLoadDocumentTypesHandler(data[0]);
          this.onLoadReceiptDateHandler(data[1]);
        });
    });
  }

  ngOnDestroy(): void {
    this.filenameForm.reset();
    this.filterForm.reset();
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  onLoadDocumentTypesHandler(docTypes) {
    this.documentTypes = docTypes.map(dt => ({ label: dt, value: dt }));
    this.filterForm.patchValue({
      documentTypes: docTypes
    });
    this.filterForm.updateValueAndValidity();
  }

  onLoadReceiptDateHandler(dateRange): void {
    if (dateRange.start) {
      const start = new Date(dateRange.start);
      this.filterForm.patchValue({
        receivedStartTime: this.getFormattedTime(start),
        receivedFromDate: start
      });
    }
    if (dateRange.end) {
      const end = new Date(dateRange.end);
      this.filterForm.patchValue({
        receivedEndTime: this.getFormattedTime(end),
        receivedToDate: end
      });
    }
    this.filterForm.updateValueAndValidity();
  }

  onSearchByFilename(): void {
    if (this.filenameForm.valid) {
      const searchQuery = RemitFilesInSearchQuery.createFilenameSearchQuery();
      searchQuery.filenameSearchModel.filename = this.filenameForm.controls['filename'].value;
      this.filesInService.setSearchQuery(searchQuery);
    }
  }

  onSearchByDocument(): void {
    if (this.filterForm.valid) {
      const searchQuery = RemitFilesInSearchQuery.createDocumentSearchQuery();
      const {
        documentTypes,
        receivedFromDate: startDate,
        receivedToDate: endDate,
        receivedStartTime,
        receivedEndTime
      } = this.filterForm.value;

      searchQuery.documentSearchModel.documentTypes = documentTypes;

      const startTime = receivedStartTime.split(':');
      if (receivedStartTime && startTime.length === 3) {
        startDate.setHours(parseInt(startTime[0], 10), parseInt(startTime[1], 10), parseInt(startTime[2], 10));
      }

      const endTime = receivedEndTime.split(':');
      if (receivedEndTime && endTime.length === 3) {
        endDate.setHours(parseInt(endTime[0], 10), parseInt(endTime[1], 10), parseInt(endTime[2], 10));
      }
      searchQuery.documentSearchModel.receivedDateTimeRange = new DateTimeRangeFilter(startDate, endDate);
      searchQuery.paging = this.filesInService.getSearchQuery() ? this.filesInService.getSearchQuery().paging : searchQuery.paging;
      searchQuery.sorting = this.filesInService.getSearchQuery() ? this.filesInService.getSearchQuery().sorting : searchQuery.sorting;
      this.filesInService.setSearchQuery(searchQuery);
    }
  }

  onRefresh(): void {
    this.filenameForm.reset();
    forkJoin([
      this.filesInService.loadDocumentTypes(),
      this.filesInService.loadReceiptDate()
    ])
    .pipe(take(1))
    .subscribe(data => {
      this.onLoadDocumentTypesHandler(data[0]);
      this.onLoadReceiptDateHandler(data[1]);
      this.onSearchByDocument();
    });
  }

  private getFormattedTime(date: Date): string {
    const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    const seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();

    return hour + ':' + minutes + ':' + seconds;
  }
}