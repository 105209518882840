import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { Message } from 'primeng/api';
import { MoveOrganisation, Organisation } from '../../organisations.model';
import { MessagesMapperService } from '../../../../../common/messages-mapper.service';
import { OrganisationGroup } from '../../../administration.model';

@Component({
  selector: 'cms-move-organisation-dialog',
  templateUrl: './move-organisation-dialog.component.html',
  styleUrls: ['./move-organisation-dialog.component.scss']
})
export class MoveOrganisationDialogComponent implements OnInit, OnChanges {
  @Input() data: Organisation;
  @Input() display = false;
  @Input() messages: Message[];
  @Input() orgGroups: OrganisationGroup[] = [];
  @Output() displayChange = new EventEmitter<boolean>();
  @Output() confirm = new EventEmitter<MoveOrganisation>();
  @Output() cancel = new EventEmitter<Message[]>();
  errors: {[key: string]: ValidationErrors} = {};
  form: UntypedFormGroup;
  orgGroupsOptions: {label: string, value: any}[] = [];

  constructor(private formBuilder: UntypedFormBuilder,
              private mapper: MessagesMapperService) {
  }

  onCancel(): void {
    this.hide();
    this.cancel.emit(this.mapper.toWarningMessages(`Moving of organisation '${this.data.displayName}' was cancelled.`));
  }

  onVisibleChange(visible: boolean): void {
    if (!visible) {
      this.onCancel();
    }
  }

  onConfirm(): void {
    const { currentOrganisationGroupId, newOrganisationGroup, reason } = this.form.value;
    const out: MoveOrganisation = {
      currentOrganisationGroup: currentOrganisationGroupId,
      newOrganisationGroup,
      reason,
      organisationId: this.data.orgId
    };

    this.confirm.emit(out);
  }

  ngOnInit(): void {
    const {
      orgGroupId: currentOrganisationGroupId,
      organisationGroupDisplayName,
    } = this.data;
    this.form = this.formBuilder.group({
      currentOrganisationGroupId,
      organisationGroupDisplayName: [{disabled: true, value: organisationGroupDisplayName}],
      newOrganisationGroup: null,
      reason: ''
    });
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (this.form) {
      if (changes.display && !this.display) {
        this.hide();
      }
      if (changes.data) {
        const {
          orgGroupId: currentOrganisationGroupId,
          organisationGroupDisplayName,
        } = this.data;
        this.form.patchValue({
          currentOrganisationGroupId,
          organisationGroupDisplayName,
          newOrganisationGroup: null,
          reason: ''
        });
        this.form.updateValueAndValidity();
      }
    }

    if (changes.messages) {
      this.errors = {};
      if (this.messages) {
        this.messages.forEach((obj: Message) => {
          const { key: controlName } = obj;
          this.errors[controlName] = { 'server': true };
        });
      }
    }

    if (changes.orgGroups) {
      this.orgGroupsOptions = this.orgGroups ?
        this.orgGroups.map(item =>({ value: +item.key, label: item.value })) :
        [];
    }
  }

  private hide(): void {
    this.messages = [];
    this.display = false;
    this.displayChange.emit(this.display);
    this.form.reset();
    this.form.updateValueAndValidity();
  }
}
