<form class="panel-box" [formGroup]="form">
  <div class="inline-block">
    <label class="inline-block margin-right-12">From:</label>
    <p-calendar id="sf-filter-from-date-calendar"
                class="date-input inline-block margin-right-12"
                placeholder="From Date"
                formControlName="fromDate"
                dateFormat="dd/mm/yy"
                [showIcon]="true"
                [monthNavigator]="true"
                [yearNavigator]="true"
                [style]="{ 'max-width': '105px' }"
                cmsDateRestriction>
    </p-calendar>
    <label class="inline-block margin-right-12">Time:</label>
    <p-inputMask id="sf-filter-time-from-input"
                 styleClass="text-small padding-6 text-center margin-right-12"
                 formControlName="fromTime"
                 mask="99:99:99"
                 pattern="(([0-1]?[0-9])|(2[0-3])):[0-5][0-9]:[0-5][0-9]"
                 placeholder="hh:mm:ss"
                 slotChar="hh:mm:ss"
                 [style]="{ 'max-width': '70px' }">
    </p-inputMask>
  </div>
  <div class="inline-block">
    <label class="inline-block margin-right-12">To:</label>
    <p-calendar id="sf-filter-to-date-calendar"
                class="date-input inline-block margin-right-12"
                placeholder="To Date"
                formControlName="toDate"
                dateFormat="dd/mm/yy"
                [showIcon]="true"
                [monthNavigator]="true"
                [yearNavigator]="true"
                [style]="{ 'max-width': '105px' }"
                cmsDateRestriction>
    </p-calendar>
    <label class="inline-block margin-right-12">Time:</label>
    <p-inputMask id="sf-filter-time-to-input"
                 styleClass="text-small padding-6 text-center margin-right-12"
                 formControlName="toTime"
                 mask="99:99:99"
                 pattern="(([0-1]?[0-9])|(2[0-3])):[0-5][0-9]:[0-5][0-9]"
                 placeholder="hh:mm:ss"
                 slotChar="hh:mm:ss"
                 [style]="{ 'max-width': '70px' }">
    </p-inputMask>
  </div>
  <div class="inline-block buttons">
    <button pButton
            icon="fa fa-refresh"
            id="sf-filter-refresh-btn"
            class="btn form-control padding-6 margin-right-12"
            (click)="onRefresh()">
    </button>
    <button id="sf-filter-search-btn" class="btn padding-6"
            (click)="onSearch()"
            [disabled]="form.invalid">Search</button>
  </div>
</form>
