import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ErrorMessageService } from '@common/errors/error-message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from '@common/menu/navigation.service';
import { TitleMap } from './master-data.model';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'cms-master-data',
  templateUrl: './master-data.component.html',
  styleUrls: ['./master-data.component.scss']
})
export class MasterDataComponent implements OnInit, OnDestroy {
  activeIndex: number;
  tabs = ['internal', 'external'];
  routeSubscription$ = new Subscription();
  internalVisible = false;
  externalVisible = false;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private changeDetectorRef: ChangeDetectorRef,
    private titleService: Title,
    public readonly errorMessageService: ErrorMessageService) {
  }

  ngOnInit(): void {
    this.routeSubscription$.add(this.route.data.subscribe(({panel}: { panel: string }) => {
      this.activeIndex = this.tabs.indexOf(panel);
      this.titleService.setTitle(TitleMap[panel]);
    }));

    this.routeSubscription$.add(this.navigationService.onNavigationChanged().subscribe(navigation => {
      this.internalVisible = this.navigationService.containsPath('esm/masterdata/internal', navigation);
      this.externalVisible = this.navigationService.containsPath('esm/masterdata/external', navigation);
      this.changeDetectorRef.detectChanges();
    }));
  }

  ngOnDestroy(): void {
    this.routeSubscription$.unsubscribe();
  }

  onChangeTab({index}: {index: number}): void {
    this.router.navigate([`esm/masterdata/${this.tabs[index]}`]);
  }

}
