import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import {
  CpmlDashboardAssetClassType,
  CpmlDashboardBucketCategoryType,
  CpmlDashboardBucketViewByType,
  CpmlDashboardDocumentFormatType,
  CpmlDashboardDocumentStateType,
  CpmlDashboardTradeRepositoryType
} from './filter/cpml-dashboard-filter.types';
import { CpmlDealsService } from '../cpml-deals.service';
import { CpmlDealsSearchQuery } from '../dealfinder/filter/cpml-dealfinder-filter.model';
import { Message } from 'primeng/api';

@Component({
  selector: 'cms-cpml-dealview',
  templateUrl: './cpml-dealview.component.html',
  styleUrls: ['./cpml-dealview.component.scss']
})
export class CpmlDealviewComponent implements OnInit, OnDestroy {

  searchQuery: CpmlDealsSearchQuery;
  state: CpmlDashboardDocumentStateType;
  tableMessage: Message[] = [];


  constructor(private dealService: CpmlDealsService, private route: ActivatedRoute) {
    this.searchQuery = CpmlDealsSearchQuery.createEmptyBucketSearchQuery();
  }

  public ngOnInit(): void {
    this.route.queryParams
      .subscribe((params: Params) => {
        const parameters = {
          tradeRepositories: <CpmlDashboardTradeRepositoryType>params['tradeRepositories'],
          assetClasses: <CpmlDashboardAssetClassType>params['assetClasses'],
          documentFormats: <CpmlDashboardDocumentFormatType>params['documentFormats'],
          state: <CpmlDashboardDocumentStateType>params['state'],
          viewBy: <CpmlDashboardBucketViewByType>params['viewBy'],
          category: <CpmlDashboardBucketCategoryType>params['category'],
          dateRange: <string>params['dateRange']
        };

        this.state = parameters.state;
        this.updateDealsSearchQuery(parameters);
      });
  }

  ngOnDestroy(): void {
    this.dealService.emitNewSearchQuery(null);
  }

  private updateDealsSearchQuery(parameters) {
    this.searchQuery.tradeRepositories = [].concat(parameters.tradeRepositories);
    this.searchQuery.assetClasses = [].concat(parameters.assetClasses);
    this.searchQuery.state = parameters.state;
    this.searchQuery.documentFormats = [].concat(parameters.documentFormats);
    this.searchQuery.viewBy = parameters.viewBy;
    this.searchQuery.category = parameters.category;
    this.searchQuery.dateRange = parameters.dateRange;

    this.dealService.emitNewSearchQuery({ ...CpmlDealsSearchQuery.createEmptySearchQuery(), ...this.searchQuery });
  }

  getRepositories() {
    return this.searchQuery.tradeRepositories.join(', ');
  }

  getAssetClasses() {
    return this.searchQuery.assetClasses.join(', ');
  }

  getDocumentFormats() {
    return this.searchQuery.documentFormats.join(', ');
  }
}
