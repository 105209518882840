import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Column } from '@common/shared/datatable.model';
import { OrganisationService } from '@common/organisation.service';
import { merge, Observable, timer } from 'rxjs';
import { debounce, filter, mergeMap, map, tap } from 'rxjs/operators';
import { CpmlDashboardService } from '../cpml-dashboard.service';
import { CpmlDashboardBucketRowModel } from './cpml-bucket.model';


@Component({
  selector: 'cms-cpml-bucket',
  templateUrl: './cpml-bucket.component.html',
  styleUrls: ['./cpml-bucket.component.scss']
})
export class CpmlBucketComponent implements OnInit, OnChanges {
  @Input() category: string;
  buckets$: Observable<CpmlDashboardBucketRowModel[]>;
  columns: Column[] = [];
  loading: boolean;
  selection: CpmlDashboardBucketRowModel;

  constructor(private cpmlDashboardService: CpmlDashboardService,
              private organisationService: OrganisationService) { }

  ngOnInit(): void {

    this.buckets$ = merge(
      this.cpmlDashboardService.getCurrentFilterStateChangeObservable(),
      this.organisationService.getChangeOrganisationObservable().pipe(filter(Boolean))) // reload buckets on organisation changes
      .pipe(
        debounce(() => timer(50)) // discard emitted state changes that take less than 50 milliseconds (e.g. to discard multiple reset state changes)
        , tap(_ => this.loading = true) // show loading indicator
        , mergeMap(_ => this.cpmlDashboardService.loadBuckets())
        , tap(_ => this.loading = false)  // hide loading indicator
        , filter(result => result.rows != null)
        , map(resultWithRows => resultWithRows.rows));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initColumns(this.category);
  }

  getQueryParams(row, field): {[key: string]: any} {
    const queryParams = {};
    const state = row.state;
    const tradeRepositories = this.cpmlDashboardService.getCurrentFilterState().tradeRepositories;
    const assetClasses = this.cpmlDashboardService.getCurrentFilterState().assetClasses;
    const documentFormats = this.cpmlDashboardService.getCurrentFilterState().documentFormats;
    const viewBy = this.cpmlDashboardService.getCurrentFilterState().viewBy;
    const category = this.cpmlDashboardService.getCurrentFilterState().category;
    const dateRange = this.createDateRangeParam(field, category);

    if (state) {
      queryParams['state'] = state;
    }
    if (dateRange) {
      queryParams['dateRange'] = dateRange;
    }
    if (tradeRepositories) {
      queryParams['tradeRepositories'] = tradeRepositories;
    }
    if (assetClasses) {
      queryParams['assetClasses'] = assetClasses;
    }
    if (documentFormats) {
      queryParams['documentFormats'] = documentFormats;
    }
    if (viewBy) {
      queryParams['viewBy'] = viewBy;
    }
    if (category) {
      queryParams['category'] = category;
    }
    return queryParams;
  }

  createDateRangeParam(field, category): string {
    const dateRanges = [];
    dateRanges['state'] = category === 'COMPLETE' ? 'ALL_TO_30_DAYS' : 'ALL';
    dateRanges['todayLast4Hours'] = 'RANGE_0_4_HOURS';
    dateRanges['todayEarlier'] = 'RANGE_4_24_HOURS';
    dateRanges['daysAgo1'] = 'YESTERDAY';
    dateRanges['daysAgo2'] = 'TWO_DAYS_AGO';
    dateRanges['daysAgo3To6'] = 'RANGE_3_6_DAYS';

    const dateRange = dateRanges[field];

    if (dateRange) {
      return dateRange;
    }

    return category === 'COMPLETE' ? 'RANGE_7_30_DAYS' : 'RANGE_7_PLUS_DAYS';
  }

  initColumns(category: string): void {
    // inprogrss & issue
    this.columns = [{ title: ' Age', name: 'state' },
      { title: 'Today (0-4h)', name: 'todayLast4Hours', style: {'text-align': 'right'} },
      { title: 'Today (earlier)', name: 'todayEarlier', style: {'text-align': 'right'} },
      { title: 'Yesterday', name: 'daysAgo1', style: {'text-align': 'right'} },
      { title: '2 days ago', name: 'daysAgo2', style: {'text-align': 'right'} },
      { title: '3-6 days ago', name: 'daysAgo3To6', style: {'text-align': 'right'} }];
    //   Age	Today (0-4h)	Today (earlier)	Yesterday	2 days ago	3-6 days ago	7-30 days ago
    category !== 'COMPLETE' ? this.columns.push({
      title: '7+ days ago',
      name: 'daysAgo7AndMore',
      style: {'text-align': 'right'}
    }) : this.columns.push({ title: '7-30 days ago', name: 'daysAgo7AndMore', style: {'text-align': 'right'} });

  }

}
