import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { OrganisationService } from '@common/organisation.service';
import { Message } from 'primeng/api';
import { ErrRemitSettingsProcessingModeModel } from './err-remit-settings-processing-mode.model';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ErrRemitSettingsService } from './err-remit-settings.service';
import { ErrorMessageService } from '@common/errors/error-message.service';

@Component({
  selector: 'cms-err-remit-settings',
  templateUrl: './err-remit-settings.component.html',
  styleUrls: ['./err-remit-settings.component.scss']
})
export class ErrRemitSettingsComponent implements OnInit, OnChanges, OnDestroy {
  private onDestroy$ = new Subject();
  private apiUrl = '/api/err/remit/settings/processingModes/';
  public processingModeForm: UntypedFormGroup;
  public messages: Message[] = [];
  processingModes: { label: string, value: string }[];
  selectedErrRemitSettingsProcessingModeModel: ErrRemitSettingsProcessingModeModel[] = [];
  selectedProcessingMode: string;
  errRemitSettingsProcessingModeModels: ErrRemitSettingsProcessingModeModel[];
  writePermission = false;

  processModePanelVisible$: Observable<boolean>;

  constructor(private formBuilder: UntypedFormBuilder,
              private settingsService: ErrRemitSettingsService,
              private organisationService: OrganisationService,
              public readonly errorMessageService:ErrorMessageService) {
    this.processingModeForm = this.formBuilder.group({});
  }

  ngOnInit() {

    this.processModePanelVisible$ = this.settingsService.onChangePanelVisible();

    this.processModePanelVisible$.pipe(
      takeUntil(this.onDestroy$))
      .subscribe(visible => {
        if (visible) {
          this.processingModeForm = this.createProcessingModeForm();
        }
      });

      this.settingsService.loadPresetValues();
      this.loadErrRemitSettingsProcessingModeModel();
      this.organisationService.getOrganisationChangeIfAccessible().pipe(
        takeUntil(this.onDestroy$))
        .subscribe(() => this.loadErrRemitSettingsProcessingModeModel());

    this.processingModes = [ // see RemitProcessingModeEnum
      { value: '', label: ''},
      { value: 'BLOCK', label: 'Block' },
      { value: 'PASS', label: 'Pass Through' },
      { value: 'PAIR_STRICT', label: 'Pair (Strict)' },
      { value: 'PAIR_RELEASE', label: 'Pair (Release)' }
    ];

  }

  private createProcessingModeForm() {
    return this.formBuilder.group({
      selectedProcessingMode: [null,Validators.required],
    });
  }

  ngOnChanges() {
    this.selectedProcessingMode = this.processingModeForm.value.selectedProcessingMode;
  }

  loadErrRemitSettingsProcessingModeModel() {
      this.settingsService.getProcessingModes().then(data =>  {
        if(data){
          this.errRemitSettingsProcessingModeModels = data;
        }else{
          this.errRemitSettingsProcessingModeModels = [];
        }
      }, err => err);
  }

  onSubmit() {
    if (this.processingModeForm.invalid) {
      this.messages = [{
        severity: 'error',
        summary: 'Error',
        detail: 'Select a processing mode.'
      }];
      return;
    }

    if (this.selectedErrRemitSettingsProcessingModeModel.length <= 0) {
      this.messages = [{
        severity: 'error',
        summary: 'Error',
        detail: 'Select one or more OMPs.'
      }];
      return;
    }

    this.settingsService.setProcessingModes(this.getSelectedPermission(),this.getSelectedGroupIds()).then(
      value => {
        this.loadErrRemitSettingsProcessingModeModel();
        this.messages = [{
          severity: 'success',
          detail: 'Processing modes updated successfully.'
        }];
      },
      (err: HttpErrorResponse) => {
        this.messages = [{
          severity: 'error',
          summary: 'Error',
          detail: `Internal error: data update failed. Please contact Equias support with error reference number ${err.error.errorReferenceCode}`
        }];
      }
    );
  }

  getSelectedGroupIds(): string[] {
    const selectedIds = [];
    this.selectedErrRemitSettingsProcessingModeModel.forEach(entry => selectedIds.push(entry.remitOmpId));
    return selectedIds;
  }

  getErrRemitSettingsProcessingModeModels(): ErrRemitSettingsProcessingModeModel[] {
    return this.errRemitSettingsProcessingModeModels;
  }

  getSelectedPermission(): string {
    return this.processingModeForm.value.selectedProcessingMode;
  }

  hasWritePermission(): boolean {
    return this.writePermission;
  }

  public ngOnDestroy(): void {
    // Clean up all subscriptions at once:
    this.settingsService.setChangePanelVisible(false);
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }
}
