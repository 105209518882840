<div class="margin-0 position-relative">
  <div class="row position-relative">
    <p-messages [value]="messages$ | async" [closable]="true" class="col-12"></p-messages>
    <cms-ecm-filters-container class="col-12" [index]="category"></cms-ecm-filters-container>
    <cms-datatable [paginator]="false"
                   [data]="datatableData$ | async"
                   [tableColumns]="columns$ | async"
                   [autoLayout]="true"
                   [scrollable]="false"
                   [loading]="loading$ | async"
                   [lazy]="!!(datatableData$ | async)?.length"
                   class="!my-[15px]">
      <ng-template pTemplate="body" let-row="row" let-column="column">
        <td *ngIf="column.type === 'state'" [ngStyle]="column.style" (click)="onClick(row, column)">
          <span>{{ageValuesMapping[row[column.name]] ? ageValuesMapping[row[column.name]] : row[column.name]}}</span>
        </td>
        <td *ngIf="column.type === 'ecm'" [ngStyle]="column.style" (click)="onClick(row, column)">
          {{row[column.name]}}
        </td>
      </ng-template>
      <ng-template pTemplate="customEmptyMessage">
        <tr>
          <td colspan="100" *ngIf="!(loading$ | async)">
            No records found
          </td>
        </tr>
      </ng-template>
    </cms-datatable>
  </div>
</div>
