import {Column} from '../../../common/shared/datatable.model';
import {RemitDocumentType} from '../dashboard/filter/remit-dashboard-filter-model';
import {ErrRemitDataTableRemitTable1Config} from './remit-datatable-remit-table-1.config';
import {ErrRemitDataTableRemitTable2Config} from './remit-datatable-remit-table-2.config';
import {ErrRemitDataTableRemitTable3Config} from './remit-datatable-remit-table-3.config';
import {ErrRemitDataTableRemitTable4Config} from './remit-datatable-remit-table-4.config';
import {ErrRemitDataTableRemitFundamentalsConfig} from './remit-datatable-remit-fundamentals.config';
import {ecmDealFinderCustomizeConfig} from '../../../ecm/deal-finder/deal-finder.model';
import {esmDealFinderCustomizeConfig} from '../../../esm/invoice/finder/invoice-finder.model';
import {EsmNettingStatementTableCustomizeConfig} from '../../../esm/netting/finder/esm-netting-finder.model';

export namespace ErrRemitDataTableConfig {

  const TABLE_COLUMNS = new Map<(RemitDocumentType), { [name: string]: Column }>([
    ['REMIT_TABLE_1', ErrRemitDataTableRemitTable1Config.TABLE_COLUMNS],
    ['REMIT_TABLE_2', ErrRemitDataTableRemitTable2Config.TABLE_COLUMNS],
    ['ELECTRICITY_RIGHTS', ErrRemitDataTableRemitTable3Config.TABLE_COLUMNS],
    ['GAS_CAPACITY', ErrRemitDataTableRemitTable4Config.TABLE_COLUMNS],
    ['FUNDAMENTALS', ErrRemitDataTableRemitFundamentalsConfig.TABLE_COLUMNS],
    ['ECM', ecmDealFinderCustomizeConfig],
    ['ESM_INVOICE', esmDealFinderCustomizeConfig],
    ['ESM_NETTING', EsmNettingStatementTableCustomizeConfig]
  ]);

  export function getColumns(docType: RemitDocumentType, columnNames: string[]): Column[] {
    return columnNames.map(name => {
      const column = TABLE_COLUMNS.get(docType)[name];
      return Object.assign({ // default values
        name: name,
        width: '400px',
        sortable: true,
        visible: true,
        editable: false
      }, column);
    });
  }

  export function getColumnTitles(docType: RemitDocumentType, columnNames: string[]) {
    return columnNames.map(name => {
      const column: Column = TABLE_COLUMNS.get(docType)[name];
      if (!column) {
        return { name: '', title: ''};
      } else {
        const title: string = column.longTitle ? column.longTitle : column.title;
        return { name: name, title: title };
      }
    });
  }
}
