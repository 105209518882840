import { Message } from 'primeng/api';
import * as fromAction from './products.actions';
import { DialogState, ProductsModel } from '../../../settings/ecm-settings-tab-panel/products/products.model';

export interface ProductsState {
  data: ProductsModel;
  messages: Message[];
  dialog: DialogState;
}

export const initialState: ProductsState = {
  data: null,
  messages: [],
  dialog: null,
};

export function ecmSettingsProductsReducer(state: ProductsState = {...initialState},
                                           action: fromAction.EcmSettingsProductsActions) {
  switch (action.type) {
    case fromAction.OPEN_DIALOG:
      return {
        ...state,
        dialog: { ...action.payload }
      };
    case fromAction.CLOSE_DIALOG:
      return {
        ...state,
        dialog: null
      };
    case fromAction.LOAD_DATA_SUCCESS:
      return {
        ...state,
        data: (action as fromAction.LoadDataSuccessAction).payload,
        dialog: null
      };
    case fromAction.SET_TO_DEFAULT:
      return {...initialState};
    default:
      return state;
  }
}

export const getData = (datatableState: ProductsState) => datatableState.data;
export const getMessages = (state: ProductsState) => state.messages;
export const getDialog = (state: ProductsState) => state.dialog;
