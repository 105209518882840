<p-dialog *ngIf="display"
          header="Documents in state 'Start'."
          [resizable]="false"
          [(visible)]="display"
          [modal]="true"
          [style]="{ 'width': '550px', 'min-height': '200px' }"
          (visibleChange)="onVisibleChange()">
  <div class="warning-container">
    <i class="fa fa-exclamation-triangle"></i>
    <p class="mb-0">You are about to resend documents in state "Start". </p>
    <p class="mb-0">ElCom Reporting for this entity will stop with immediate effect.</p>
    <p class="mb-0">Are you sure that you want to continue?</p>
  </div>
  <p-footer>
    <button pButton type="button" class="btn" label="Cancel" (click)="onCancel()" id="err-sf-resend-dialog-cancel-btn"></button>
    <button pButton type="button" class="btn" label="Yes" (click)="onConfirm()" id="err-sf-resend-dialog-confirm-btn"></button>
  </p-footer>
</p-dialog>
