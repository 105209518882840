import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AudittrailDatatableComponent } from './audittrail-datatable.component';
import { AudittrailComponent } from './audittrail.component';
import {
  DynamicAuditTrailDescriptionComponent,
  DynamicAuditTrailDescriptionDataStoreLinkComponent,
  DynamicAuditTrailDescriptionPlainTextComponent
} from './dynamic-audit-trail-description.component';
import { AudittrailService } from './audittrail.service';
import { CmsCommonModule } from '../cms-common.module';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        CmsCommonModule,
        TableModule,
        ButtonModule,
    ],
    declarations: [
        AudittrailDatatableComponent,
        AudittrailComponent,
        DynamicAuditTrailDescriptionComponent,
        DynamicAuditTrailDescriptionPlainTextComponent,
        DynamicAuditTrailDescriptionDataStoreLinkComponent
    ],
    providers: [
        AudittrailService,
    ],
    exports: [
        AudittrailComponent
    ]
})
export class AudittrailModule {
}
