import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { EcmDealFinder } from '../../../ecm/deal-finder/deal-finder.model';
import { NotesCellEvent, NotesCellViewEvent, Note } from '@common/deals/deals.model';

@Component({
  selector: 'cms-notes-cell',
  templateUrl: './notes-cell.component.html',
  styleUrls: ['./notes-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotesCellComponent {
  @Input() notes: Note[] = [];
  @Input() data: EcmDealFinder;
  @Input() confirmationId: string;
  @Output() view = new EventEmitter<NotesCellViewEvent>();

  title = () => this.data.documentType ? `${this.data.documentType}${this.tradeID}` : 'Notes';

  onClick(): void {
    this.view.emit({
        confirmationId: this.confirmationId,
        notes: this.notes
      }
    );
  }

  onMouseEnter(panel: any, event: any): void {
    if (this.notes && this.notes.length) {
      panel.show(event);
    }
  }

  onMouseLeave(panel: any): void {
    if (this.notes && this.notes.length) {
      panel.hide();
    }
  }

  get tradeID(){
    return !!this.data.tradeId ? this.data.tradeId : ' notes';
  }


  get style(){

    return {
      'maxWidth': '600px',
    };
  }

}
