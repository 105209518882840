import { datatableReducer, DatatableState } from '../datatable/datatable.reducer';
import { organisationIdReducer } from '../organisation-id/organisation-id.reducer';
import { OrganisationGroup } from '../../administration-tab-panel/organisation-groups/organisation-groups.model';
import { dialogReducer, DialogState } from '../dialog/dialog.reducer';

export interface OrganisationGroupsState {
  organisationId?: number;
  datatable: DatatableState<OrganisationGroup, string>;
  dialog: DialogState;
}

export const datatableInitialState: DatatableState<OrganisationGroup> = {
  selected: null,
  filters: null,
  data: {
    values: [],
    count: 0
  },
  sorting: {
    columnName: 'displayName',
    sortOrder: 'ASCENDING'
  },
  paging: {
    entriesPerPage: 25,
    page: 0
  },
  messages: [],
  loading: false,
  editableData: null
};

export const initialState: OrganisationGroupsState = {
  organisationId: null,
  datatable: datatableInitialState,
  dialog: null
};

export function organisationGroupsReducer(state: OrganisationGroupsState = {...initialState},
                                          action): OrganisationGroupsState {
  return {
    datatable: datatableReducer('[ORGANISATION GROUPS]', datatableInitialState)(state.datatable, action as any),
    organisationId: organisationIdReducer('[ORGANISATION GROUPS]')(state.organisationId, action as any),
    dialog: dialogReducer('[ORGANISATION GROUPS]')(state.dialog, action as any),
  };
}
