import { createReducer, on } from '@ngrx/store';
import { EsmNettingDashboardPresetFilters, EsmNettingRoleByType } from '../../../../netting/dashboard/esm-netting-dashboard.model';
import { EsmNettingDashboardFiltersAction } from './filters.actions';
import * as fromDBActions from '../dashboard.actions';
import * as fromDBFiltersActions from './filters.actions';

export interface EsmNettingDashboardFiltersState {
  value: EsmNettingDashboardPresetFilters;
  category: string;
  commodities: string[];
  counterParties: { key: number, value: string }[];
  deliveryPoints: {keyObject:string,valueObject:string}[];
  nettingStatementTypes: string[];
  nettingStatementRole: EsmNettingRoleByType;
  dateRange: string;
}

export const filtersInitialState: EsmNettingDashboardFiltersState = {
  value: {
    commodities: null,
    counterParties: null,
    deliveryPoints: null,
    nettingStatementTypes: null,
    paymentDateRange: null,
    invoiceStartDate: null,
    invoiceEndDate: null
  },
  category: 'IN_PROGRESS',
  commodities: null,
  counterParties: null,
  deliveryPoints: null,
  nettingStatementTypes: null,
  nettingStatementRole: null,
  dateRange: ''
};

export const filtersReducer = createReducer<EsmNettingDashboardFiltersState, EsmNettingDashboardFiltersAction>(
  filtersInitialState,
  on(fromDBActions.setToDefaultAction, () => ({ ...filtersInitialState })),
  on(fromDBFiltersActions.loadPresetValuesSuccessAction, (state: EsmNettingDashboardFiltersState, { payload: values }): EsmNettingDashboardFiltersState => {
    return {
      ...state,
      nettingStatementRole: null,
      ...values,
      deliveryPoints: values.deliveryPoints.map(({ key, value }) => ({ keyObject: key, valueObject: value })),
      value: {
        commodities: values.commodities,
        deliveryPoints: values.deliveryPoints.map(({ key, value }) => ({ keyObject: key, valueObject: value })),
        nettingStatementTypes: values.nettingStatementTypes,
        counterParties: values.counterParties.map(item => item.key),
        paymentDateRange: values.paymentDateRange,
        invoiceStartDate: values.invoiceStartDate,
        invoiceEndDate: values.invoiceEndDate
      }
    };
  }),
  on(fromDBFiltersActions.setRoleByFilterValueAction, (state: EsmNettingDashboardFiltersState, { payload: nettingStatementRole }): EsmNettingDashboardFiltersState => {
    return {
      ...state,
      value: { ...state.value },
      nettingStatementRole: nettingStatementRole
    };
  }),
  on(fromDBFiltersActions.setFilterValuesAction, (state: EsmNettingDashboardFiltersState, { payload: value }): EsmNettingDashboardFiltersState => (
    {
      ...state,
      value
    }
  )),
  on(fromDBFiltersActions.setDateRangeAction, (state: EsmNettingDashboardFiltersState, { payload: dateRange }): EsmNettingDashboardFiltersState => (
    {
      ...state,
      dateRange
    }
  )),
  on(fromDBFiltersActions.setCategoryAction, (state: EsmNettingDashboardFiltersState, { payload: category }): EsmNettingDashboardFiltersState => (
    {
      ...state,
      category
    }
  ))
);
