import { Component, EventEmitter, Input, OnChanges, Output, SimpleChange } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Moment } from 'moment';
import { FileFilters } from './file-filters-panel.model';

@Component({
  selector: 'cms-file-filters-panel',
  templateUrl: './file-filters-panel.component.html',
  styleUrls: ['./file-filters-panel.component.scss']
})
export class FileFiltersPanelComponent implements OnChanges {
  @Input() filters: FileFilters;
  @Input() options: string[];
  @Input() disabled: boolean = false;
  @Output() searchByTime = new EventEmitter();
  @Output() searchByFilename = new EventEmitter();
  @Output() reset = new EventEmitter();
  documentTypesOptions: { label: string, value: any }[] = [];

  filenameForm: UntypedFormGroup;
  filterForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.filenameForm = formBuilder.group({
      filename: [null, Validators.required]
    });

    this.filterForm = formBuilder.group({
      documentTypes: ['', Validators.required],
      receivedFromDate: ['', Validators.required],
      receivedFromTime: ['', Validators.required],
      receivedToDate: ['', Validators.required],
      receivedToTime: ['', Validators.required]
    });
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (this.filters && changes.filters && changes.filters.currentValue) {
      const { filename = '', timeRange, documentTypes = [] } = this.filters as FileFilters;
      if (timeRange) {
        const startTime = this.getFormattedTime(timeRange.receivedFromTime);
        const endTime = this.getFormattedTime(timeRange.receivedToTime);
        this.documentTypesOptions = this.options.length ? this.options.map(type => ({ label: type, value: type })) : [];
        this.filenameForm.setValue({
          filename: filename ? filename : '',
        });

        this.filterForm.setValue({
          receivedFromDate: moment(timeRange.receivedFromDate).toDate(),
          receivedFromTime: startTime.format('HH:mm:ss'),
          receivedToDate: moment(timeRange.receivedToDate).toDate(),
          receivedToTime: endTime.format('HH:mm:ss'),
          documentTypes: documentTypes,
        });

      }
      this.filterForm.updateValueAndValidity();
      this.filenameForm.updateValueAndValidity();
    }
  }


  onRefresh(): void {
    this.filenameForm.reset();
    this.filterForm.reset();
    this.reset.emit();
  }

  onSearchByTimeFilter(): void {
    const filter = this.filterForm.getRawValue();
    const request = {
      timeRange: {
        receivedFromDate: moment(filter.receivedFromDate).format('YYYY-MM-DD'),
        receivedToDate: moment(filter.receivedToDate).format('YYYY-MM-DD'),
        receivedFromTime: filter.receivedFromTime,
        receivedToTime: filter.receivedToTime,
      },
      documentTypes: filter.documentTypes,
    };
    this.searchByTime.emit(request);
  }

  onSearchByFilename(): void {
    this.searchByFilename.emit(this.filenameForm.getRawValue());
  }

  private getFormattedTime(timeString: string = ''): Moment {
    let time = moment(new Date());
    const timeArr = timeString.split(':');
    time = moment(time).set({
      'hour': parseInt(timeArr[0], 10),
      'minute': parseInt(timeArr[1], 10),
      'second': parseInt(timeArr[2], 10),
    });
    return time;
  }

}
