<cms-master-data-header [data]="presetValues$ | async" [loading]="!(presetValues$ | async)">
</cms-master-data-header>
<cms-master-data-select-vat-id [valID]="vatIds$ | async" [newVisible]="(presetValues$ | async)?.editButtonsVisible"
  [masterDataId]="masterDataId$ | async" (runNew)="onRunNew()" (selectVatId)="onSelectVatId($event)"
  (toggleAllowBankingDetailsDownload)="onToggleAllowBankingDetailsDownload($event)"
  [allowBankingDetailsDownload]="(presetValues$ | async)?.allowBankingDetailsDownload"
  [enableBankingDetailsCheckbox]="(presetValues$ | async)?.editButtonsVisible">
</cms-master-data-select-vat-id>
<ng-container *ngIf="isVisibleDetails$ |async">
  <p-tabView [activeIndex]="activeIndex">
    <p-tabPanel header="Master Data">
      <cms-master-data-tab>

      </cms-master-data-tab>
    </p-tabPanel>
    <p-tabPanel header="PDF Settings" *ngIf="(presetValues$ | async)?.pdfSettingsTabVisible">
      <cms-master-data-pdf-settings>

      </cms-master-data-pdf-settings>
    </p-tabPanel>
  </p-tabView>
</ng-container>