<p-dialog
          [closable]="false"
          [resizable]="false"
          *ngIf="display"
          [(visible)]="display"
          [modal]="true"
>
  <ng-template pTemplate="header">
    <div style="font-size: 1.25em;">Terminate Deal</div>
    <div id="hbutton">
      <button pButton id="bbutton" icon="pi pi-times" (click)="onCancel()"></button>
    </div>
  </ng-template>


  <div class="warning" *ngIf="!messages">
    <div class="warning__id-container">
      <i class="fa fa-exclamation-triangle"></i>
      <span>
        Confirm you wish to terminate the following deals:<br>
        <div style="max-height: 320px; overflow-y: auto">

        <div class="padding-left-15" *ngFor="let deal of deals; let i = index" >
         {{deal.documentType}} for trade {{deal.tradeId}}
        </div>
        </div>
        Note: You can revert this decision by amending the trade.
      </span>
    </div>
  </div>


  <div *ngIf="messages">
    <div class="error feedback-container" *ngIf="hasTerminationErrorMessages()">
      <i class="fa fa-exclamation-triangle"></i>
      <span>
        Error reported (please seek help desk assistance if problem persists).<br>
        <div style="max-height: 320px; overflow-y: auto">
        The following deals could not be terminated:
             <div class="padding-left-15" *ngFor="let message of messages;">
                <span *ngIf="message.data && message.severity === 'error'">
                  {{createTerminationFeedbackText(message)}}
                </span>
            </div>

        </div>
        <div style="max-height: 320px; overflow-y: auto" *ngIf="hasAnySuccessfullMessages()">
        The following deals could be terminated successfully:
             <div class="padding-left-15" *ngFor="let deal of deals;">
                <span *ngIf="!hasTerminationErrorMessage(deal)">
                  {{deal.documentType}} for trade {{deal.id}}
                </span>
            </div>
        </div>
      </span>
    </div>
    <div class="success feedback-container" *ngIf="!hasTerminationErrorMessages()">
      <i class="fa fa-exclamation-triangle"></i>
      <span>
        All selected Deals terminated successfully. <br> Note: It may take a few moments until this is reflected in the user interface.<br>
      </span>
    </div>
  </div>

  <div class="margin-15" *ngIf="!closeButton">
    <form [formGroup]="form">
      <label class="block">
        <span class="block">Enter mandatory reason for termination: *</span>
        <textarea class="reason-textarea" formControlName="reasonTextarea" [maxLength]="50"></textarea>
      </label>
    </form>
  </div>
  <p-footer>
    <button class="btn margin-right-3" type="button" (click)="onCancel()" *ngIf="!closeButton">Cancel</button>
    <button class="btn" type="button" (click)="onTerminate()" *ngIf="!closeButton">Yes - Terminate</button>
    <button class="btn" type="button" (click)="onClose()" *ngIf="closeButton">Close</button>
  </p-footer>
</p-dialog>
