import { Message } from 'primeng/api';
import { InitialStartAndEndDate } from '../../reports/reports.model';
import * as moment from 'moment';
import * as fromAction from './reports.actions';

export interface ReportsState {
  date: InitialStartAndEndDate;
  disableBilling: boolean;
  disableRanking: boolean;
  messages: Message[];
}

export const initialState: ReportsState = {
  date: {
    start: null,
    end: null,
  },
  disableBilling: false,
  disableRanking: false,
  messages: []
};

export function ecmReportsReducer(state: ReportsState = {...initialState},
                                  action: fromAction.EcmReportsActions): ReportsState {
  switch (action.type) {
    case fromAction.VALIDATE: {
      const { reportType } = action.payload;
      return {
        ...state,
        disableRanking: reportType === 'ranking' ? true : state.disableRanking,
        disableBilling: reportType === 'billing' ? true : state.disableBilling
      };
    }
    case fromAction.DOWNLOAD_SUCCESS:
    case fromAction.VALIDATION_FAILED: {
      const { reportType } = action.payload;
      return {
        ...state,
        disableRanking: reportType === 'ranking' ? false : state.disableRanking,
        disableBilling: reportType === 'billing' ? false : state.disableBilling
      };
    }
    case fromAction.SET_MESSAGES:
      return {
        ...state,
        messages: [...action.payload],
      };
    case fromAction.LOAD_INITIAL_START_AND_END_DATE_SUCCESS:
      return {
        ...state,
        date: {
          start: moment(action.payload.start).format('DD/MM/YYYY'),
          end: moment(action.payload.end).format('DD/MM/YYYY')
        }
      };
    case fromAction.SET_TO_DEFAULT:
      return state;
    default:
      return state;
  }
}
