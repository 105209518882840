import { ErrorResponse } from '@common/error-response.model';
import { Column } from '../../../../common/shared/datatable.model';

export interface EcmSettingsProcessData {
  userOrgGroupData: ProcessDatatable[];
  brokerData: ProcessDatatable[];
  traderData: ProcessDatatable[];
  editable: boolean;
  userOrgGroupDataVisible: boolean;
}

export interface ProcessDatatable {
  editActionEnabled: boolean;
  info: ProcessDatatableInfo;
  orgId: number;
  settings: ProcessDatatableSettings;
}

export interface ProcessDatatableInfo {
  orgDisplayName: string;
  orgEicCode: string;
  orgGroupDisplayName: string;
}

export interface ProcessDatatableSettings {
  amendmentOfCancelledDeals: boolean;
  amendmentOfMatchedDeals: boolean;
  autoTerminate: boolean;
  boxResultContainsUSI: boolean;
  boxResultContainsUTI: boolean;
  boxResultForTerminate: boolean;
  matchBrokerFee: boolean;
  tearUpMatches: boolean;
  amendmentOfBrokerLeg: boolean;
  boxResultRmPending: boolean;
  boxResultTearUpRequested: boolean;
}

export interface ProcessUpdate {
  organisationId: number;
  settings: ProcessDatatableSettings;
}

export interface ProcessDataInDatatable extends ProcessDatatableInfo, ProcessDatatableSettings {
  editActionEnabled: boolean;
  orgId: number;
}

export interface EcmSettingsProcessDataResponse extends ErrorResponse, EcmSettingsProcessData { }

export const defaultStyles = {
  'text-align': 'left',
  'text-overflow': 'ellipsis',
  'overflow': 'hidden',
  'white-space': 'nowrap'
};

export let allColumnsConfigOrgGroup: Column[] = [
  { name: 'orgGroupDisplayName', type: 'text', title: 'Org. Group', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'orgEicCode', type: 'text', title: 'EIC Code', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'orgDisplayName', type: 'text', title: 'Display Name', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'amendmentOfCancelledDeals', type: 'on/off', title: 'Amend Cancelled', style: { ...defaultStyles, 'text-align': 'center' }, sortable: false, visible: true },
  { name: 'amendmentOfMatchedDeals', type: 'on/off', title: 'Amend Matches', style: { ...defaultStyles, 'text-align': 'center' }, sortable: false, visible: true },
  { name: 'tearUpMatches', type: 'on/off', title: 'Tear-Up Matches', style: { ...defaultStyles, 'text-align': 'center' }, sortable: false, visible: true },
  { name: 'matchBrokerFee', type: 'on/off', title: 'Match Broker Fee', style: { ...defaultStyles, 'text-align': 'center' }, sortable: false, visible: true },
  { name: 'autoTerminate', type: 'on/off', title: 'Auto-Terminate', style: { ...defaultStyles, 'text-align': 'center' }, sortable: false, visible: true },
  { name: 'boxResultForTerminate', type: 'on/off', title: 'BoxResult for Terminate', style: { ...defaultStyles, 'text-align': 'center' }, sortable: false, visible: true },
  { name: 'boxResultContainsUSI', type: 'on/off', title: 'BoxResult contains USI', style: { ...defaultStyles, 'text-align': 'center' }, sortable: false, visible: true },
  { name: 'boxResultContainsUTI', type: 'on/off', title: 'BoxResult contains UTI', style: { ...defaultStyles, 'text-align': 'center' }, sortable: false, visible: true },
  { name: 'amendmentOfBrokerLeg', type: 'on/off', title: 'Amend Broker Leg', style: { ...defaultStyles, 'text-align': 'center' }, sortable: false, visible: true },
  { name: 'boxResultRmPending', type: 'on/off', title: 'BoxResult RM Pending', style: { ...defaultStyles, 'text-align': 'center' }, sortable: false, visible: true },
  { name: 'boxResultTearUpRequested', type: 'on/off', title: 'BoxResult TUR', style: { ...defaultStyles, 'text-align': 'center' }, sortable: false, visible: true }
];

export let allColumnsConfigBrokersAndTraders: Column[] = [
  { name: 'orgGroupDisplayName', type: 'text', title: 'Org. Group', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'orgEicCode', type: 'text', title: 'EIC Code', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'orgDisplayName', type: 'text', title: 'Display Name', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'amendmentOfCancelledDeals', type: 'on/off', title: 'Amend Cancelled', style: { ...defaultStyles, 'text-align': 'center' }, sortable: false, visible: true },
  { name: 'amendmentOfMatchedDeals', type: 'on/off', title: 'Amend Matches', style: { ...defaultStyles, 'text-align': 'center' }, sortable: false, visible: true },
  { name: 'tearUpMatches', type: 'on/off', title: 'Tear-Up Matches', style: { ...defaultStyles, 'text-align': 'center' }, sortable: false, visible: true },
  { name: 'matchBrokerFee', type: 'on/off', title: 'Match Broker Fee', style: { ...defaultStyles, 'text-align': 'center' }, sortable: false, visible: true },
  { name: 'autoTerminate', type: 'on/off', title: 'Auto-Terminate', style: { ...defaultStyles, 'text-align': 'center' }, sortable: false, visible: true },
  { name: 'boxResultForTerminate', type: 'on/off', title: 'BoxResult for Terminate', style: { ...defaultStyles, 'text-align': 'center' }, sortable: false, visible: true },
  { name: 'boxResultContainsUSI', type: 'on/off', title: 'BoxResult contains USI', style: { ...defaultStyles, 'text-align': 'center' }, sortable: false, visible: true },
  { name: 'boxResultContainsUTI', type: 'on/off', title: 'BoxResult contains UTI', style: { ...defaultStyles, 'text-align': 'center' }, sortable: false, visible: true },
  { name: 'amendmentOfBrokerLeg', type: 'on/off', title: 'Amend Broker Leg', style: { ...defaultStyles, 'text-align': 'center' }, sortable: false, visible: true },
  { name: 'boxResultRmPending', type: 'on/off', title: 'BoxResult RM Pending', style: { ...defaultStyles, 'text-align': 'center' }, sortable: false, visible: true },
  { name: 'boxResultTearUpRequested', type: 'on/off', title: 'BoxResult TUR', style: { ...defaultStyles, 'text-align': 'center' }, sortable: false, visible: true }
];

