<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>

<h1>eCM Monitoring</h1>
<p-messages [value]="messages$ | async" [closable]="true"></p-messages>
<div class="panel-box row margin-0">
  <div class="panel-box col-2 change-date-container">
    <cms-change-date (changeDate)="onChangeDate($event)"
                     [value]="getChangeDate$ | async">
    </cms-change-date>
  </div>
  <div class="panel-box col-6 flex align-items-center new-spapshot-container">
    <cms-new-snapshot [lastSnapshotMessage]="lastSnapshotMessage$ | async"
                      [newSnapshotLoading]="newSnapshotLoading$ | async"
                      (newSnapshot)="onNewSnapshot()">
    </cms-new-snapshot>
  </div>
</div>
<ng-container *ngIf="categories">
  <div class="panel-box margin-0" *ngIf="categories && categories.length">
    <cms-info *ngIf="logsText" ngPreserveWhitespaces header="Audit Log (click to open)" [value]="logsText"></cms-info>
    <p-tabView [activeIndex]="activeIndex$ | async" (onChange)="onChangeTab($event)">
      <p-tabPanel leftIcon="{{!category.checked ? 'fa fa-times' : 'fa fa-check'}}" *ngFor="let category of categories" [header]="categoryToTitleMap[category.categoryName]">
        <span *ngIf="category.checkedMessage" class="text-bold header-font">{{category.checkedMessage}}</span>
        <button *ngIf="!category.checkedMessage" pButton label="Done Checking"  type="button" (click)="onDoneChecking(category)"></button><cms-button-spinner *ngIf="doneCheckingLoading$ | async as doneCheckingLoading" [show]="doneCheckingLoading"></cms-button-spinner>
        <cms-datatable [tableColumns]="columns$ | async"
                       [paginator]="false"
                       [autoLayout]="true"
                       [scrollable]="false"
                       [tooltipDisabled]="true"
                       [data]="data$ | async"
                       [loading]="loading$ | async"
                       sortField="organisationName"
                       sortOrder="ASCENDING"
                       [lazy]="false">
          <ng-template pTemplate="body" let-row="row" let-column="column">
            <td *ngIf="column.type === 'button'" [ngStyle]="column.style">
              <div class="btn-wrapper">
                <a routerLink="/ecm/monitoring/deals" [queryParams]="{categoryName: category.categoryName, senderOrgEicCode: row.eicCode, organisationId: organisationId$ | async, submissionDate: getChangeDate$ | async}" target="_blank">
                  <button class="btn btn-icon fa fa-table" type="button" label="Delete" title="eCM Deals"></button>
                 </a>
              </div>
            </td>
          </ng-template>
        </cms-datatable>
      </p-tabPanel>
    </p-tabView>
  </div>
  <span *ngIf="!(categories && categories.length) && loaded$ | async" class="text-bold header-font">No data available</span>
</ng-container>

</ng-template>
