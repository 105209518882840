import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogState } from '@common/state/reducers';
import { ESM_MD_DIALOGS } from 'app/esm/state/master-data/master-data.reducer';

@Component({
  selector: 'cms-master-data-dialog',
  templateUrl: './master-data-dialog.component.html',
  styleUrls: ['./master-data-dialog.component.scss']
})
export class MasterDataDialogComponent implements OnInit {
  @Input() selectedVat: number;
  @Input() dialog: DialogState<ESM_MD_DIALOGS>;
  @Output() cancel = new EventEmitter();
  @Output() confirm = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onCancelDelete(){
    this.cancel.emit();
  }

  onConfirmDelete() {
    this.confirm.emit();
  }

}
