import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'cms-button-spinner',
  templateUrl: './button-spinner.component.html',
  styleUrls: ['./button-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonSpinnerComponent {
  @Input() show = false;
}
