<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>

<h1>Retry Queue</h1>
<p-messages [value]="messages$ | async" [closable]="true"></p-messages>
<div class="panel-box">
  <cms-datatable
    #datatable
    [tableColumns]="dataTableColumns"
    [data]="(data$ | async)?.values"
    [currentPage]="(paging$ | async)?.page"
    [sortField]="(sorting$ | async)?.columnName"
    [sortOrder]="(sorting$ | async)?.sortOrder"
    [loading]="loading$ | async"
    [totalRecords]="(data$ | async)?.count"
    [lazy]="!!((data$ | async)?.values.length)"
    [selected]="this.selected$ | async"
    [checkboxRow]="true"
    [scrollable]="false"
    [autoLayout]="true"
    (selectedChange)="onSelected($event)"
    (lazyLoad)="onLazyLoad($event)">


    <ng-template pTemplate="caption">
      <cms-retry-queue-filter
        (filter)="onFilter($event)"
        (reset)="onReset()"
        [paging]="paging$ | async"
        [sorting]="sorting$ | async"
        [value]="filter$ | async">
      </cms-retry-queue-filter>
    </ng-template>
    <ng-template pTemplate="body" let-row="row" let-column="column">
      <td #tdContainer *ngIf="column.type === 'textarea'" [ngStyle]="column.style" class="position-relative non-editable-textarea-cell">
        <cms-non-editable-textarea [value]="row[column.name]"
                                   [container]="tdContainer">
        </cms-non-editable-textarea>
      </td>
      <td *ngIf="column.type === 'button'" [ngStyle]="column.style">
        <div class="btn-wrapper inline-block">
          <button pButton type="button" icon="fa fa-file-code-o" title="Document" (click)="onDownload(row.datastoreId)"></button>
        </div>
      </td>
    </ng-template>
  </cms-datatable>
  <div class="btn-container margin-top-6 margin-right-6">
    <button pButton class="btn margin-right-6" type="button" label="Retry" (click)="onRetry()" [disabled]="disabled$ | async"></button>
    <button pButton class="btn" type="button" label="Reject" (click)="onReject()" [disabled]="disabled$ | async"></button>
  </div>
</div>
<cms-retry-queue-dialog
  *ngIf="dialog$ | async as dialog"
  [display]="!!dialog"
  [messages]="dialog?.messages"
  [reasons]="reasons$ | async"
  (cancelDialog)="onCancelDialog()"
  (rejectDialog)="onRejectDialog($event)">
</cms-retry-queue-dialog>

</ng-template>
