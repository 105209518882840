<cms-datatable id="amount_table" style="min-width: 600px"
               [tableColumns]="columns"
               [rows]="3"
               [scrollable]="false"
               [loading]="loading$ | async"
               [data]="(data$ | async).values"
               [autoLayout]="false"
               [lazy]="true"
               [lazyLoadOnInit]="false">

  <ng-template pTemplate="caption" style="background: transparent;border: none;">
    <h6 class="m-0 text-start">Netted Invoice Amount</h6>
  </ng-template>

  <ng-template pTemplate="body" let-row="row" let-rowIndex="rowIndex" let-column="column">

    <td *ngIf="column.name === 'ourNettingCalculation'"
        [ngStyle]="{'text-align': 'end'}">
      <a *ngIf="row.ourNettingCalculation"
         [routerLink]="route"
         [queryParams]="getQueryParametersByIndex(rowIndex, 1)"
         target="_blank">{{row.ourNettingCalculation | number:'1.2-15':'en-US'}}
      </a>
      <span *ngIf="!row.ourNettingCalculation">-</span>
    </td>

    <td *ngIf="column.name === 'cpNettingCalculation'"
        [ngStyle]="{'text-align': 'end'}">
      <a *ngIf="row.cpNettingCalculation"
         [routerLink]="route"
         [queryParams]="getQueryParametersByIndex(rowIndex, 2)"
         target="_blank">{{row.cpNettingCalculation | number:'1.2-15':'en-US'}}
      </a>
      <span *ngIf="!row.cpNettingCalculation">-</span>
    </td>

    <td *ngIf="column.name === 'ourOpenUpToDate'"
        [ngStyle]="{'text-align': 'end'}">
      <a *ngIf="row.ourOpenUpToDate"
         [routerLink]="route"
         [queryParams]="getQueryParametersByIndex(rowIndex, 3)"
         target="_blank">{{row.ourOpenUpToDate | number:'1.2-15':'en-US'}}
      </a>
      <span *ngIf="!row.ourOpenUpToDate">-</span>
    </td>

    <td *ngIf="column.name === 'ourAgreedToDate'"
        [ngStyle]="{'text-align': 'end', 'background-color': '#add9ad'}">
      <a *ngIf="row.ourAgreedToDate"
         [routerLink]="route"
         [queryParams]="getQueryParametersByIndex(rowIndex, 4)"
         target="_blank">{{row.ourAgreedToDate | number:'1.2-15':'en-US'}}
      </a>
      <span *ngIf="!row.ourAgreedToDate">-</span>
    </td>

    <td *ngIf="column.name === 'ourDisputedUpToDate'"
        [ngStyle]="{'text-align': 'end','background-color': '#F1B6BD'}">
      <a *ngIf="row.ourDisputedUpToDate"
         [routerLink]="route"
         [queryParams]="getQueryParametersByIndex(rowIndex, 5)"
         target="_blank">{{row.ourDisputedUpToDate | number:'1.2-15':'en-US'}}
      </a>
      <span *ngIf="!row.ourDisputedUpToDate">-</span>
    </td>

  </ng-template>

</cms-datatable>
