import * as fromActions from './user-role.actions';
import {
  OrgGroupFilterEntry,
  ProcessRoleFilterEntry, ProcessRoles,
  UserFilterEntry, UserRoleDatatableItem,
  UserRoleFilter, UserRolesEditableData, UserRolesFilterResponse,
  UserRolesFilters
} from '../../administration-tab-panel/user-roles/user-roles.model';
import { Column } from '../../../common/shared/datatable.model';
import { DatatableData } from '../../../common/shared/results.model';
import { EditableCell } from '../../../common/datatable/cell-editor/cell-editor.model';
import { Message } from 'primeng/api';

export interface UserRolesState {
  loading: boolean;
  columns: Column[];
  data: DatatableData<UserRoleDatatableItem[]>;
  organisationId?: number;
  organisationGroupFilter: UserRoleFilter<OrgGroupFilterEntry>;
  processRoleFilter: UserRoleFilter<ProcessRoleFilterEntry>;
  userFilter: UserRoleFilter<UserFilterEntry>;
  filters: UserRolesFilters;
  messages: Message[];
  editableData: UserRolesEditableData;
}

export const initialState: UserRolesState = {
  loading: true,
  columns: [],
  data: {
    count: 0,
    values: []
  },
  processRoleFilter: {
    filterEntries: [],
    visible: false,
  },
  organisationGroupFilter: {
    filterEntries: [],
    visible: false,
  },
  userFilter: {
    filterEntries: [],
    visible: false,
  },
  filters: {
    selectedProcessRole: ProcessRoles.ECM,
    selectedUsername: null,
    selectedOrganisationGroupId: null
  },
  messages: [],
  editableData: null
};

export function reducer(state = initialState, action: fromActions.UserRoleActionsActions): UserRolesState {
  switch (action.type) {
    case fromActions.UPDATE_MATRIX:
      return {
        ...state,
        loading: true,
        messages: []
      };
    case fromActions.UPDATE_FILTERS_DATA:
      return {
        ...state,
        loading: true
      };
    case fromActions.LOAD_FILTERS_DATA_SUCCESS: {
      const data: UserRolesFilterResponse = (action as fromActions.LoadFiltersDataSuccessAction).payload;
      const organisationGroupFilter = data.organisationGroupFilter || { visible: false, filterEntries: [] };
      const processRoleFilter = data.processRoleFilter || { visible: false, filterEntries: [] };
      const userFilter = data.userFilter || { visible: false, filterEntries: [] };

      return {
        ...state,
        loading: false,
        organisationGroupFilter,
        processRoleFilter,
        userFilter,
        editableData: null
      };
    }
    case fromActions.FILTER_DATA:
      return {
        ...state,
        loading: true,
        filters: (action as fromActions.FilterDataAction).payload,
      };
    case fromActions.SET_ORGANISATION_ID:
      return {
        ...state,
        organisationId: (action as fromActions.SetOrganisationIdAction).payload
      };
    case fromActions.SET_TO_DEFAULT:
      return {
        ...initialState
      };
    case fromActions.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...(action as fromActions.SetFiltersAction).payload
        }
      };
    case fromActions.FILTER_DATA_SUCCESS: {
      const { columns, data } = (action as fromActions.FilterDataSuccessAction).payload;
      return {
        ...state,
        loading: false,
        columns,
        data: {
          ...data
        },
        editableData: null
      };
    }
    case fromActions.SET_EDITABLE_DATA: {
      const obj: EditableCell = (action as fromActions.SetEditableDataAction).payload;
      const { index, column: { name }} = obj;

      const editableData = {
        ...(state.editableData ? state.editableData : {}),
      };

      if (!editableData[index]) {
        editableData[index] = {};
      }
      editableData[index][name] = obj;

      return {
        ...state,
        editableData,
        messages: []
      };
    }
    case fromActions.REMOVE_EDITABLE_DATA: {
      const { column: { name }, index } = (action as fromActions.RemoveEditableDataAction).payload;

      let editableData = {
        ...(state.editableData ? state.editableData : {}),
      };
      delete editableData[index][name];
      if (!Object.keys(editableData[index]).length) {
        editableData[index] = null;
        delete editableData[index];
      }

      if (!Object.keys(editableData).length) {
        editableData = null;
      }

      return {
        ...state,
        editableData,
        messages: []
      };
    }
    case fromActions.SET_MESSAGES:
      return {
        ...state,
        messages: (action as fromActions.SetMessagesAction).payload
      };
    default:
      return state;
  }
}
