import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiRequestService } from '@common/api-request.service';
import { MessagesMapperService } from '@common/messages-mapper.service';
import {
  loadData,
  loadDataSuccess,
  setDocumentSide,
  setDocumentType,
  setMatchedInvoicePdf,
  setMatchedInvoiceXml,
  setMatchedNettingPdf,
  setMatchedNettingXml,
  setMessages
} from './subscription-settings.actions';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import { Message } from 'primeng/api';
import { of } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { toPayload } from '@common/cms-common.model';

@Injectable()
export class SubscriptionSettingsEffects {
  private baseUrl = '/api/esm/email/subscription';

  constructor(private apiGateway: ApiRequestService,
              private actions$: Actions,
              private mapper: MessagesMapperService) {
  }

  load_data$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadData),
      switchMap(() =>
        this.apiGateway.get(`${this.baseUrl}/loadData`)
          .pipe(
            switchMap(response => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              return messages?.length ?
                [setMessages({ messages })] :
                [loadDataSuccess(response.value)];
            }),
            catchError(error => of(setMessages(this.mapper.toErrorMessagesObj(error.errorMessage || 'Server Error')))
            ))
      ))
  );

  check_matched_invoice_pdf$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setMatchedInvoicePdf.type),
      map(toPayload),
      concatMap((value: boolean) => {
        const params = new HttpParams().set('matchedInvoicePdf', String(value));
        return this.apiGateway.post(`${this.baseUrl}/update/invoicePdf`, null, params)
          .pipe(
            switchMap((response: any) => this.defaultResponse(response)),
            catchError(error => [setMessages(this.mapper.toErrorMessagesObj(error.errorMessage || 'Server Error'))])
          );
      })
    )
  );

  check_matched_netting_pdf$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setMatchedNettingPdf.type),
      map(toPayload),
      concatMap((value: boolean) => {
        const params = new HttpParams().set('matchedNettingStatementPdf', String(value));
        return this.apiGateway.post(`${this.baseUrl}/update/nettingStatementPdf`, null, params)
          .pipe(
            switchMap((response: any) => this.defaultResponse(response)),
            catchError(error => [setMessages(this.mapper.toErrorMessagesObj(error.errorMessage || 'Server Error'))])
          );
      })
    )
  );

  check_matched_invoice_xml$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setMatchedInvoiceXml.type),
      map(toPayload),
      concatMap((value: boolean) => {
        const params = new HttpParams().set('matchedInvoiceXml', String(value));
        return this.apiGateway.post(`${this.baseUrl}/update/invoiceXml`, null, params)
          .pipe(
            switchMap((response: any) => this.defaultResponse(response)),
            catchError(error => [setMessages(this.mapper.toErrorMessagesObj(error.errorMessage || 'Server Error'))])
          );
      })
    )
  );

  check_matched_netting_xml$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setMatchedNettingXml.type),
      map(toPayload),
      concatMap((value: boolean) => {
        const params = new HttpParams().set('matchedNettingStatementXml', String(value));
        return this.apiGateway.post(`${this.baseUrl}/update/nettingStatementXml`, null, params)
          .pipe(
            switchMap((response: any) => this.defaultResponse(response)),
            catchError(error => [setMessages(this.mapper.toErrorMessagesObj(error.errorMessage || 'Server Error'))])
          );
      })
    )
  );

  set_document_type$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setDocumentType.type),
      map(toPayload),
      concatMap((value: string) => {
        const params = new HttpParams().set('type', value);
        return this.apiGateway.post(`${this.baseUrl}/update/type`, null, params)
          .pipe(
            switchMap((response: any) => this.defaultResponse(response)),

            catchError(error => [setMessages(this.mapper.toErrorMessagesObj(error.errorMessage || 'Server Error'))])
          );
      })
    )
  );

  set_document_side$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setDocumentSide.type),
      map(toPayload),
      concatMap((value: string) => {
        const params = new HttpParams().set('side', value);
        return this.apiGateway.post(`${this.baseUrl}/update/side`, null, params)
          .pipe(
            switchMap((response: any) => this.defaultResponse(response)),

            catchError(error => [setMessages(this.mapper.toErrorMessagesObj(error.errorMessage || 'Server Error'))])
          );
      })
    )
  );

  private defaultResponse = (response: any) => {
    const messages: Message[] = this.mapper.toErrorMessages(response);
    if (messages && messages.length) {
      return [
        loadData(), setMessages({ messages })];
    }
    return [setMessages({ messages: [] })];
  }

}
