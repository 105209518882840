import { Action } from '@ngrx/store';
import { Ecm_View_By_Values, EcmDashboardPropertiesFilters } from '../../../ecm-dashboard/ecm-dashboard.model';
import { EcmPropertyFiltersValuesResponse } from '../../../deal-finder/deal-finder.model';

export const GET_FILTERS_VALUES_SUCCESS = '[ECM DASHBOARD] Get Filters Values Success';
export const FILTER = '[ECM DASHBOARD] Filter';
export const GET_FILTERS_VALUES = '[ECM DASHBOARD] Get Filters Values';
export const CLEAN_BY_PROPERTIES_FILTERS = '[ECM DASHBOARD] Clean By Properties Filters';
export const UPDATE_FILTERS = '[ECM DASHBOARD] Update Filters';

export class FilterAction implements Action {
  readonly type = FILTER;

  constructor(public payload: {
    viewByFilter?: Ecm_View_By_Values,
    propFilters?: EcmDashboardPropertiesFilters }) { }
}

export class GetFiltersValuesAction implements Action {
  readonly type = GET_FILTERS_VALUES;
}

export class GetFiltersValuesSuccessAction {
  readonly type = GET_FILTERS_VALUES_SUCCESS;
  constructor(public payload: EcmPropertyFiltersValuesResponse) { }
}

export class ClearByPropertiesFiltersAction implements Action {
  readonly type = CLEAN_BY_PROPERTIES_FILTERS;
}

export class UpdateFiltersAction implements Action {
  readonly type = UPDATE_FILTERS;
  constructor(public payload: EcmDashboardPropertiesFilters) { }
}

export type EcmFiltersActions = FilterAction | GetFiltersValuesAction | GetFiltersValuesSuccessAction |
  ClearByPropertiesFiltersAction | UpdateFiltersAction;
