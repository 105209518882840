import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChange } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Column } from '../../../common/shared/datatable.model';
import { DatatableState } from '../../../common/datatable/datatable.model';
import { DatatableData, PagingFilter, SortingFilter } from '../../../common/shared/results.model';
import { Prefix } from '../../state/reducers';
import { ContactsState } from '../../state/contacts/contacts.reducers';
import { Contacts, UserContacts, UserContactsFilters } from '../contacts.model';
import {
  FilterDataAction,
  SetFiltersAction, SetToDefaultAction,
  SortAndPageDataAction
} from '../../state/datatable/datatable.actions';
import * as userSelector from '../../state/user/user-dt.selectors';

const defaultStyles = {
  'text-align': 'left',
  'text-overflow': 'ellipsis',
  'white-space': 'nowrap',
  'height': '29px'
};

@Component({
  selector: 'cms-contacts-user-contact',
  templateUrl: './contacts-user-contact.component.html',
  styleUrls: ['./contacts-user-contact.component.scss']
})
export class ContactsUserContactComponent implements OnInit, OnChanges, OnDestroy {
  @Input() orgContact: Contacts;
  filters$: Observable<UserContactsFilters>;
  data$: Observable<DatatableData<UserContacts[]>>;
  loading$: Observable<boolean>;
  sorting$: Observable<SortingFilter>;
  paging$: Observable<PagingFilter>;
  readonly dataTableColumns: Column[] = [
    { name: 'lastName', type: 'text', title: 'Last Name', style: { ...defaultStyles }, sortable: true, visible: true },
    { name: 'firstName', type: 'text', title: 'First Name', style: { ...defaultStyles }, sortable: true, visible: true },
    { name: 'jobTitle', type: 'text', title: 'Job Title', style: { ...defaultStyles }, sortable: true, visible: true },
    { name: 'mail', type: 'email-ref', title: 'Mail', style: { ...defaultStyles }, sortable: true, visible: true },
    { name: 'phone', type: 'text', title: 'Phone', style: { ...defaultStyles }, sortable: true, visible: true },
    { name: 'mobile', type: 'text', title: 'Mobile', style: { ...defaultStyles }, sortable: true, visible: true },
    { name: 'other', type: 'text', title: 'Other', style: { ...defaultStyles }, sortable: true, visible: true },
  ];
  prefix: Prefix = '[USERS CONTACTS]';

  constructor(private store: Store<ContactsState>,) {
    this.data$ = this.store.pipe(select(userSelector.getData));
    this.loading$ = this.store.pipe(select(userSelector.getLoading));
    this.sorting$ = this.store.pipe(select(userSelector.getSorting));
    this.paging$ = this.store.pipe(select(userSelector.getPaging));
    this.filters$ = this.store.pipe(select(userSelector.getFilters));
  }

  onLazyLoad(state: DatatableState): void {
    this.store.dispatch(new SortAndPageDataAction(this.prefix, {
      paging: {
        page: state.page,
        entriesPerPage: state.perPage
      },
      sorting: {
        columnName: state.sortField,
        sortOrder: state.sortOrder
      }
    }));
  }

  ngOnInit(): void {
    this.store.dispatch(new SetFiltersAction(this.prefix, {idOfSelectedOrganisation: this.orgContact.organisationId}));
  }

  ngOnDestroy(): void {
    this.store.dispatch(new SetToDefaultAction(this.prefix));
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (changes.orgContact && this.orgContact !== null) {
      this.store.dispatch(new FilterDataAction(this.prefix,
        {idOfSelectedOrganisation: this.orgContact.organisationId}));
    }
  }
}
