<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>

<h1>Preferred Values for eRR lite Trade Entry</h1>
<div class="panel-box">
  <cms-info ngPreserveWhitespaces>
    <!--@formatter:off (markdown rendering breaks, when the following tags are formatted)-->
    <u>__eRR lite Settings__</u>

    These settings are to adjust the eRR lite trade entry forms to your needs.

    **Changing Your Preferred Values**

    Use the respective edit icon to pick your preferred values for a field.<br>
    An asterisk in the *Preferences* column means that you have not stored preferences for that particular field.<br>
    When entering or modifying trades in the eRR lite entry forms your preferred values will be listed before all other values.

    **Note**

    At present this feature is restricted to the entry and modification of REMIT Table 1 trades.<br>
    It will be extended to REMIT Table 2 in an upcoming CMS release after appraisal by the user community.<br>
    Please contact the CMS Service Team if you would like to provide feedback on this feature.

    <!--@formatter:on-->
  </cms-info>

  <div class="col-12 col-md-12 padding-0">
    <div class="decent-max-width">

      <p-table [value]="preferredValues" [sortOrder]="-1" [autoLayout]="true" dataKey="configurationName" scrollHeight="520px" selectionMode="single" [(selection)]="selection">
        <ng-template pTemplate="header">
          <tr>
            <ng-container *ngFor="let col of cols">
              <th *ngIf="col.name == 'configurationName'" [ngStyle]="col.style">

              </th>
              <th *ngIf="col.name != 'configurationName'" [ngStyle]="col.style">
                {{col.title}}
              </th>
            </ng-container>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-row>
          <tr [pSelectableRow]="row">
            <ng-container *ngFor="let col of cols">
              <td *ngIf="col.name == 'configurationName'" [ngStyle]="col.style">
                <div class="btn-wrapper inline-block">
                  <a routerLink="/err/settings/trade_entry/customise" title="Customise" [queryParams]="customizeQueryParams(row)">
                    <button class="customize_button" pButton type="button" title="Customize" icon="fa fa-pencil"></button>
                  </a>
                </div>
              </td>
              <td *ngIf="col.name != 'configurationName'" [ngStyle]="col.style">
                <span class="truncated-text">{{row[col.name]}}</span>
              </td>
            </ng-container>
          </tr>
        </ng-template>
      </p-table>


    </div>
  </div>

</div>

</ng-template>
