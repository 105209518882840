import { createReducer, on } from '@ngrx/store';
import * as GenerationAction from './generation-dialog.actions';
import * as DialogAction from './generation-dialog.actions';
import { NettingStatementData } from '../../../netting-preview/generation-dialog/generation-dialog.model';
import { InvoiceDivEntry } from '../../../netting-preview/netting-table-overview/netting-table-overview.model';
import { GenerationDialogActions } from './generation-dialog.actions';

export interface GenerationDialogState {
  nettingStatement: NettingStatementData;
  tableSelected: InvoiceDivEntry;
}

export const generationDialogInitialState: GenerationDialogState = {
  nettingStatement: null,
  tableSelected: null
};

export const generationDialogReducer = createReducer<GenerationDialogState, GenerationDialogActions>(
  generationDialogInitialState,

  on(DialogAction.openDialog, (state, { selected }) => ({
    ...state,
    tableSelected: selected
  })),
  on(GenerationAction.setConfirmationGeneration, (state, {payload}) => ({
    ...state,
    nettingStatement: payload
  }))
);
