<div class="panel-box margin-left-50 margin-right-50 margin-top-50">
  <h1>Cancel Trade</h1>
  <div id="divRemitTradeCancelFeedback">
    <p-messages [(value)]="msgs" [closable]="false"></p-messages>
  </div>
  <ng-container *ngIf="!internalError">
    <div class="p-warn margin-bottom-15 list-style-warn">
      <div class="absolute top-3">
        <i class="fa fa-exclamation-triangle fa-2x"></i>
      </div>
      <div class="padding-left-30 margin-left-15">
        <b>Confirm you wish to report an early termination for this REMIT trade by sending a cancel ("C") record to ARIS.</b>
        <p></p>
        Please note:
        <ul class="list-style-inherit">
          <li>This is for reporting early terminations and not for error corrections. Use the error ("E") function for this purpose.</li>
          <li>You cannot make further modifications to a cancelled trade other than sending error records.</li>
          <li>"Transaction Time (UTC)" is the time at which the early termination was agreed - it must be after any other reported trade event.</li>
          <li>"Termination Date" is the day on which the early termination becomes effective which must not be after delivery end.</li>
        </ul>
        <p>Press "Cancel Trade" to proceed or close this tab to abort.</p>
      </div>
    </div>
    <form [formGroup]="cancelForm" class="row align-items-center col-12" (ngSubmit)="submit()">
      <div class="col-6 margin-bottom-9 decent-max-width">
        <div class="margin-bottom-3">
          <label class="text-bold margin-right-6 ps-0" style="width: 165px;">Transaction Time (UTC) <span class="text-required">*</span></label>
          <span class="margin-right-6">
                    <p-calendar
                      formControlName="transactionDateUtc"
                      [showIcon]="true"
                      [monthNavigator]="true"
                      [yearNavigator]="true"
                      dateFormat="dd/mm/yy"
                      [style]="{ 'width': '125px'}"
                      cmsDateRestriction>
                    </p-calendar>
                </span>
          <span class="margin-right-3">
                    <p-inputMask formControlName="transactionTimeUtc" mask="99:99:99" pattern="(([0-1]?[0-9])|(2[0-3])):[0-5][0-9]:[0-5][0-9]" placeholder="hh:mm:ss" slotChar="hh:mm:ss" styleClass="text-small padding-6 text-center" [style]="{ 'width': '75px'}"></p-inputMask>
                </span>
        </div>
        <div class="margin-bottom-3">
          <label class="text-bold margin-right-6 ps-0" style="width: 165px;">Termination Date <span class="text-required">*</span></label>
          <span class="inline-block padding-right-3">
                    <p-calendar
                      formControlName="terminationDate"
                      [showIcon]="true"
                      [monthNavigator]="true"
                      [yearNavigator]="true"
                      dateFormat="dd/mm/yy"
                      [style]="{ 'width': '125px'}"
                      cmsDateRestriction>
                    </p-calendar>
                </span>
        </div>
        <div class="margin-bottom-3">
          <label class="text-bold margin-bottom-3 margin-right-6 ps-0" style="width: 165px;">Trader ID (for MP) <span class="text-required">*</span></label>
          <span class="inline-block padding-right-3">
                    <input class="form-control padding-6 text-medium" type="text" formControlName="traderId">
                </span>
        </div>
      </div>
      <div class="col-12 text-center">
        <button id="btnRemitTradeCancelSubmit" type="submit" class="btn margin-3 padding-6" [disabled]="(disabled || !(submitEnabled$ | async))">
          Cancel Trade
          <p-progressSpinner class="margin-top-9" *ngIf="isInProgress()" [style]="{width: '15px', height: '15px'}" strokeWidth="8"></p-progressSpinner>
        </button>
      </div>
    </form>
  </ng-container>
</div>
