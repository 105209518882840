import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// modules
import { PipeModule } from '../../pipe/pipe.module';
import { PermissionsComponent } from './permission/permissions.component';
import { CmsCommonModule } from '@common/cms-common.module';
import { TableModule } from 'primeng/table';
import { RoboPermissionsService } from './permission/robo-permissions.service';
import { DropdownModule } from 'primeng/dropdown';
import { AccordionModule } from 'primeng/accordion';
import { PanelModule } from 'primeng/panel';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { AppModule } from '../../app.module';

@NgModule({
  imports: [
    BrowserModule,
    PanelModule,
    AccordionModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    PipeModule,
    MessageModule,
    MessagesModule,
    CmsCommonModule,
    TableModule,
    TableModule
  ],
  declarations: [
    PermissionsComponent,
  ],
  providers: [
    RoboPermissionsService
  ]
})

export class RoboModule {
}

