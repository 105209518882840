import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { ApiRequestService } from '@common/api-request.service';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { Message } from 'primeng/api';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';

import * as fromFinderArctions from '../esm-netting-finder.actions';
import * as fromMyFilterActions from './my-filter.actions';
import {
  EsmNettingStatementUserFilter,
  EsmNettingStatementUserFilterRequest,
  EsmNettingStatementUserFilterResponse,
  EsmNettingStatementUserFiltersResponse,
  NettingStatementFinderPropertyFilter
} from '../../../../netting/finder/esm-netting-finder.model';
import { EsmNettingStatementFinderFilterState, EsmNettingStatementFinderState } from '../esm-netting-finder.reducers';
import { toPayload } from '@common/cms-common.model';
import * as fromFiltersSelectors from '../esm-netting-finder.selectors';
import { Sender } from '@common/deals/deals.model';
import { MyFilter } from '@common/my-filters/my-filters.model';
import { HttpParams } from '@angular/common/http';
import { ErrorResponse } from '@common/error-response.model';
import * as fromDialogActions from '../../../netting/finder/dialog/dialog.actions';
import * as fromTableActions from '../../../netting/finder/table/table.actions';
import { of } from 'rxjs';
import * as fromMyFiltersActions from '../../../invoice-finder/my-filters/my-filters.actions';
import { EsmInvoiceFinderMyFilter, mapToEsmInvoiceFinderMyFilterRequest } from '../../../../invoice/finder/invoice-finder.model';
import { EsmInvoiceFinderFiltersState } from '../../../invoice-finder/filters/filters.reducers';

@Injectable()
export class EsmNettingStatementFinderUserFilterEffects {
  private baseUrl = '/api/esm/nettingstatement/finder';

  constructor(private apiGateway: ApiRequestService,
              private actions$: Actions,
              private store$: Store<EsmNettingStatementFinderState>,
              private router: Router,
              private mapper: MessagesMapperService) {
  }

  loadMyFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMyFilterActions.loadUserFilterAction),
      switchMap(() =>
        this.apiGateway.get(`${this.baseUrl}/userfilters`)
          .pipe(
            map((response: EsmNettingStatementUserFiltersResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
        //        return fromTableActions.setMessagesAction(messages);
              }
              return fromMyFilterActions.onUserFilterLoadedAction(this.mapMyFiltersResponse(response));
            }),
           // catchError(() => of(fromTableActions.setMessagesAction([this.mapper.createErrorMessage('Server error')])))
          )
      )
    )
  );

  selectMyFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMyFilterActions.selectMyFilterAction),
      map(toPayload),
      withLatestFrom(this.store$.pipe(select(fromFiltersSelectors.getEsmNettingFinderFilterState))),
      switchMap(([selectedMyFilter, state]: [EsmNettingStatementUserFilter, EsmNettingStatementFinderFilterState]) => {
        return [
          fromFinderArctions.updateFiltersAction(this.updateMyFilter(state, state.filter, selectedMyFilter)),
          // reload table
        ];
      })
    )
  );

  setDefaultFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMyFilterActions.setDefaultFilterAction),
      map(toPayload),
      concatMap(({ filterName }: MyFilter) =>
        this.apiGateway.post(`${this.baseUrl}/userfilter/default`, null,
          filterName !== 'All Deals' ? new HttpParams().set('filterName', filterName) : null)
          .pipe(
            switchMap((response: ErrorResponse | any) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [fromTableActions.setMessagesAction(messages)];
              }
              return [
                fromMyFilterActions.loadUserFilterAction(),
                fromTableActions.setMessagesAction([]),
                fromDialogActions.updateDialogAction(this.mapper.toSuccessMessages(`"${filterName}" is your new default filter.`)),
              ];
            })
          )
      )
    )
  );

  reloadMyFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMyFilterActions.reloadMyFiltersAction),
      switchMap(() =>
        this.apiGateway.get(`${this.baseUrl}/userfilters`)
          .pipe(
            map((response: EsmNettingStatementUserFiltersResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return fromTableActions.setMessagesAction(messages);
              }
              return fromMyFilterActions.reloadMyFilterSuccessAction(this.mapMyFiltersResponse(response));
            }),
            catchError(() => of(fromTableActions.setMessagesAction([this.mapper.createErrorMessage('Server error')])))
          )
      )
    )
  );

  saveMyFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMyFilterActions.saveMyFiltersAction),
      map(toPayload),
      withLatestFrom(this.store$.pipe(select(fromFiltersSelectors.getFilter))),
      concatMap(([myFilter, state]: [EsmNettingStatementUserFilter, NettingStatementFinderPropertyFilter]) =>
        this.apiGateway.post(`${this.baseUrl}/userfilter`, this.mapToEsmNettingStatementFinderMyFilterRequest(state, myFilter))
          .pipe(
            switchMap((response: any & ErrorResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [fromDialogActions.updateDialogAction(messages)];
              }
              return [
                fromMyFilterActions.loadUserFilterAction(),
                fromDialogActions.updateDialogAction([]),
                fromDialogActions.closeDialogAction()
              ];
            }),
            catchError(() => of(fromTableActions.setMessagesAction([this.mapper.createErrorMessage('Server error')])))
          )
      )
    )
  );


  replaceMyFilter$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromMyFilterActions.replaceMyFiltersAction),
      map(toPayload),
      withLatestFrom(this.store$.pipe(select(fromFiltersSelectors.getFilter))),
      concatMap(([myFilter, state]: [EsmNettingStatementUserFilter, NettingStatementFinderPropertyFilter]) =>
        this.apiGateway.put(`${this.baseUrl}/userfilter`, this.mapToEsmNettingStatementFinderMyFilterRequest(state, myFilter))
          .pipe(
            switchMap((response: EsmNettingStatementUserFilter & ErrorResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response as ErrorResponse);
              if (messages && messages.length) {
                return [fromDialogActions.updateDialogAction(messages)];
              }
              return [
                fromMyFilterActions.loadUserFilterAction(),
                fromDialogActions.updateDialogAction([]),
                fromDialogActions.closeDialogAction()
              ];
            }),
            catchError(() => of(fromTableActions.setMessagesAction([this.mapper.createErrorMessage('Server error')])))
          )
      )
    ));



  deleteMyFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMyFilterActions.deleteMyFilterAction),
      map(toPayload),
      concatMap(({ filterName }: { filterName }) =>
        this.apiGateway.delete(`${this.baseUrl}/userfilter`, new HttpParams().set('filterName', filterName))
          .pipe(
            switchMap((response: any & ErrorResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [fromDialogActions.updateDialogAction(messages)];
              }
              return [
                fromMyFilterActions.deleteMyFilterSuccessAction({ filterName }),
                fromDialogActions.updateDialogAction(this.mapper.toSuccessMessages(`Filter "${filterName}" deleted.`)),
              ];
            }),
            catchError(() => of(fromTableActions.setMessagesAction([this.mapper.createErrorMessage('Server error')])))
          )
      )
    )
  );


  updateMyFilter = (
    filterValues: EsmNettingStatementFinderFilterState,
    filter: NettingStatementFinderPropertyFilter,
    defaultFilter: EsmNettingStatementUserFilter): NettingStatementFinderPropertyFilter => {
    let filters: NettingStatementFinderPropertyFilter;
    if (!defaultFilter) {
      filters = {
        ...filter,
        commodities: filterValues.commodities || [],
        counterParties: filterValues.counterParties.map(item => item.key) || [],
        deliveryPoints: filterValues.deliveryPoints || [],
        nettingStatementTypes: filterValues.nettingStatementTypes || [],
        senderUs: true
      };
    } else {
      filters = {
        ...filter,
        commodities: (defaultFilter.commodityFilter &&
          defaultFilter.commodityFilter.filter(item => filterValues.commodities.includes(item))) || filterValues.commodities,
        counterParties: this.setCpFilter(defaultFilter, filterValues),
        deliveryPoints: this.setDpFilter(defaultFilter, filterValues),
        nettingStatementTypes: (defaultFilter.nettingStatementTypeFilter &&
          defaultFilter.nettingStatementTypeFilter.filter(item => filterValues.nettingStatementTypes.includes(item))) || filterValues.nettingStatementTypes,
        senderUs: defaultFilter.hiddenType === Sender.US || true
      };
    }
    return filters;
  }

  mapToEsmNettingStatementFinderMyFilterRequest = ({
                                                  nettingStatementTypes,
                                                  deliveryPoints,
                                                  counterParties,
                                                  commodities,
                                                }: NettingStatementFinderPropertyFilter,
                                                {
                                                  filterName,
                                                  defaultFilter,
                                                  hiddenType,
                                                  commodityFilter,
                                                  counterpartyFilter,
                                                  deliveryPointFilter,
                                                     nettingStatementTypeFilter
                                                   }: EsmNettingStatementUserFilter): EsmNettingStatementUserFilterRequest =>
    ({
      filterName,
      isDefaultFilter: defaultFilter,
      hiddenType,
      commodityFilter: commodityFilter && commodities ? commodityFilter.length === commodities.length ? null : commodityFilter : [],
      counterpartyFilter: counterpartyFilter && counterParties ? commodityFilter.length === counterParties.length ? null : counterpartyFilter : [],
      deliveryPointFilter: deliveryPointFilter && deliveryPoints ? deliveryPointFilter.length === deliveryPoints.length ? null : deliveryPointFilter : [],
      nettingStatementTypeFilter: nettingStatementTypeFilter && nettingStatementTypes ? nettingStatementTypeFilter.length === nettingStatementTypes.length ? null : nettingStatementTypeFilter : [],
    })

  mapMyFiltersResponse = ({ defaultFilter, filterSelections }: EsmNettingStatementUserFiltersResponse): {
    defaultFilter: EsmNettingStatementUserFilter,
    filterSelections: EsmNettingStatementUserFilter[]
  } =>
    ({
      defaultFilter: defaultFilter ? this.mapToMyFilter(defaultFilter) : null,
      filterSelections: Object.values(filterSelections).map(this.mapToMyFilter)
    })

  mapToMyFilter = ({ isDefaultFilter, ...userFilter }: EsmNettingStatementUserFilterResponse): EsmNettingStatementUserFilter =>  ({
    ...userFilter,
    deliveryPointFilter: userFilter.deliveryPointFilter.map(({ value, key }: any) => ({ keyObject: key, valueObject: value })),
    counterpartyFilter: userFilter.counterpartyFilter ? userFilter.counterpartyFilter.map(Number) : null,
    defaultFilter: isDefaultFilter
  })


  setDpFilter(defaultFilter:EsmNettingStatementUserFilter,filterValues:EsmNettingStatementFinderFilterState){
    const result  = defaultFilter.deliveryPointFilter?.filter(a => filterValues.deliveryPoints.some(b => a.valueObject === b.valueObject));
    return Boolean(result) ? result.length > 0 ? result : filterValues.deliveryPoints : filterValues.deliveryPoints;
  }

  setCpFilter(defaultFilter, filterValues) {
    const result = defaultFilter.counterpartyFilter?.filter(key => filterValues.counterParties.some(obj => Number(obj.key) === Number(key)));
    return Boolean(result) ? result.length > 0 ? result : filterValues.counterParties.map(item => item.key) : filterValues.counterParties.map(item => item.key);
  }

}
