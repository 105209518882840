import { AcerBillingRunsActions } from './acer/acer-runs.actions';
import { CmsBillingRunsActions } from './cms/cms-runs.actions';
import { acerBillingRunsReducer, AcerBillingRunsState, initialAcerBillingRunsState } from './acer/acer-runs.reducers';
import { cmsBillingRunsReducer, CmsBillingRunsState, initialCmsBillingRunsState } from './cms/cms-runs.reducers';


export interface BillingRunsState {
  cmsBillingRuns: CmsBillingRunsState;
  acerBillingRuns: AcerBillingRunsState;
}

export const initialState: BillingRunsState = {
  cmsBillingRuns: initialCmsBillingRunsState,
  acerBillingRuns: initialAcerBillingRunsState,
};

export function adminBillingRunsReducer(
  state: BillingRunsState = { ...initialState },
  action: AcerBillingRunsActions & CmsBillingRunsActions): BillingRunsState {
  return {
    cmsBillingRuns: cmsBillingRunsReducer(state.cmsBillingRuns, action),
    acerBillingRuns: acerBillingRunsReducer(state.acerBillingRuns, action)
  };
}





