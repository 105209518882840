import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { Message } from 'primeng/api';

import { EcmEnterTradeFormData, EcmEnterTradeFormValues, EcmEnterTradeTotalVolumeData } from '../enter-trade.model';
import * as moment from 'moment';

@Component({
  selector: 'cms-enter-trade-form',
  templateUrl: './enter-trade-form.component.html',
  styleUrls: ['./enter-trade-form.component.scss']
})
export class EnterTradeFormComponent implements OnInit, OnChanges {

  @Input() formValues: EcmEnterTradeFormValues = null;
  @Input() deliveryPeriod: string = null;
  @Input() totalVolumeData: EcmEnterTradeTotalVolumeData = null;
  @Input() totalContractValue: any = null;
  @Input() messages: Message[];
  @Input() editableFormData: EcmEnterTradeFormData = null;
  @Input() isEditable = false;
  @Output() getPeriodDelivery = new EventEmitter();
  @Output() calculateTotalValue = new EventEmitter();
  @Output() calculateTotalContractValue = new EventEmitter();
  @Output() formChange = new EventEmitter();

  form: UntypedFormGroup;
  errors: { [key: string]: ValidationErrors } = {};
  agreementsOptions: { label: string, value: any }[];
  currenciesOptions: { label: string, value: any }[];
  counterPartiesOptions: { label: string, value: any }[];
  deliveryPointsOptions: { label: string, value: any }[];
  deliveryTimesOptions: { label: string, value: any }[];
  currency: { label: string, value: number } = null;
  counterParty: { legalName: string, partyId: number } = null;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.form = this.formBuilder.group({
      agreementId: '',
      brokerCode: '',
      bscCounterpartyId: '',
      bscIsCounterpartyProductionAccount: '',
      bscIsSchedule5On: '',
      bscIsSenderNotificationAgent: '',
      bscIsSenderProductionAccount: '',
      bscOurPartyId: '',
      capacityValue: '',
      counterPartyId: '',
      cpHubCode: '',
      currencyId: '',
      deliveryStartDate: '',
      deliveryStartTime: '',
      deliveryEndDate: '',
      deliveryEndTime: '',
      deliveryPointId: '',
      isActingAsBuyer: '',
      ourHubCode: '',
      priceValue: '',
      productSelectionId: '',
      tradeId: '',
      traderName: '',
      tradeDate: '',
      tradeTimeInUtc: '',
    });
  }

  ngOnInit() {
    this.form.valueChanges.subscribe(values => {
      this.formChange.emit(this.form.getRawValue());
    });
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes.formValues && this.formValues) {
      this.agreementsOptions = this.getOptionsArray('agreements', 'code', 'agreementId');
      this.currenciesOptions = this.getOptionsArray('currencies', 'displayName', 'id');
      this.counterPartiesOptions = this.getOptionsArray('counterParties', 'displayName', 'partyId');
      this.deliveryPointsOptions = this.getOptionsArray('deliveryPoints', 'displayName', 'id');
      this.deliveryTimesOptions = this.formValues.deliveryTimes && this.formValues.deliveryTimes.length ?
        this.formValues.deliveryTimes.map(item => ({ label: item.slice(0, 5), value: item.slice(0, 5) })) : [];
      this.currency = this.currenciesOptions && this.currenciesOptions.length === 1 ? this.currenciesOptions[0] : null;
      this.counterParty = this.formValues.counterParties.length === 1 ? this.formValues.counterParties[0] : null;
      if (this.form && !this.editableFormData) {
        this.form.patchValue({
          agreementId: this.getSingleArrValue(this.agreementsOptions),
          currencyId: this.getSingleArrValue(this.currenciesOptions),
          counterPartyId: this.getSingleArrValue(this.counterPartiesOptions),
          deliveryPointId: this.getSingleArrValue(this.deliveryPointsOptions),
          deliveryEndTime: this.getSingleArrValue(this.deliveryTimesOptions),
          deliveryStartTime: this.getSingleArrValue(this.deliveryTimesOptions),
          tradeDate: new Date(this.formValues.tradeTimeInUtc),
          tradeTimeInUtc: new Date(this.formValues.tradeTimeInUtc)
        });
        if (this.getSingleArrValue(this.deliveryTimesOptions)) {
          this.form.get('deliveryEndTime').disable();
          this.form.get('deliveryStartTime').disable();
        }
        this.formChange.emit(this.form.getRawValue());
      }
    }

    if (this.isEditable && changes.editableFormData && changes.editableFormData.currentValue) {
      const data: EcmEnterTradeFormData = changes.editableFormData.currentValue;
      const tradeTimeInUTC = data.tradeTimeInUtc;
      this.form.patchValue({
        ...data,
        deliveryStartDate: moment(data.deliveryStartDate).toDate(),
        deliveryEndDate: moment(data.deliveryEndDate).toDate(),
        deliveryStartTime: moment(data.deliveryStartTime, 'HH:mm:ss').format('HH:mm'),
        deliveryEndTime: moment(data.deliveryEndTime, 'HH:mm:ss').format('HH:mm'),
        tradeDate: moment(data.tradeTimeInUtc).toDate(),
        tradeTimeInUtc: new Date(tradeTimeInUTC),
      });
      this.counterParty = this.formValues.counterParties.find(item => item.partyId === data.counterPartyId);
      this.form.get('counterPartyId').disable();
      this.form.get('tradeId').disable();
      this.formChange.emit(this.form.getRawValue());
      this.changeCurrency({ value: data.currencyId });
      this.onCalculatedValuesChange();
    }

    if (changes.messages) {
      this.errors = {};
      if (this.messages) {
        this.messages.forEach((obj: Message) => {
          const { key: controlName } = obj;
          this.errors[controlName] = { 'server': true };
        });
      }
    }
  }

  private getOptionsArray(propertyName: string, labelName: string, value: string): any[] {
    return this.formValues[propertyName] && this.formValues[propertyName].length ?
      this.formValues[propertyName].map(item => ({ label: item[labelName], value: item[value] })) : [];
  }

  private getSingleArrValue(array: { label: string, value: any }[]): any {
    return array && array.length === 1 ? array[0].value : null;
  }

  onCalculatedValuesChange() {
    const formData = this.form.getRawValue();
    this.formChange.emit(formData);
    if (formData.deliveryEndDate && formData.deliveryEndTime
      && formData.deliveryStartDate && formData.deliveryStartTime) {
      this.getPeriodDelivery.emit();
    }

    if (formData.deliveryEndDate && formData.deliveryEndTime
      && formData.deliveryStartDate && formData.deliveryStartTime
      && formData?.capacityValue &&  Number(formData.capacityValue)) {
      this.calculateTotalValue.emit();
    }
    if (formData.deliveryEndDate && formData.deliveryEndTime
      && formData.deliveryStartDate && formData.deliveryStartTime
      && formData.capacityValue && Number(formData.capacityValue) && formData.priceValue && Number(formData.priceValue)) {
      this.calculateTotalContractValue.emit();
    }
  }

  changeCurrency(item: { value: number }) {
    this.currency = this.currenciesOptions.find(currency => currency.value === item.value);
  }

  changeCountryParty(item: { value: number }) {
    this.counterParty = this.formValues.counterParties.find(counter => counter.partyId === item.value);
  }

}
