import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RemitTradeEntryTable1Service } from '../remit-trade-entry-table1.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'cms-remit-trade-entry-table1-references',
  templateUrl: './remit-trade-entry-table1-references.component.html',
  styleUrls: ['./remit-trade-entry-table1-references.component.scss']
})
export class RemitTradeEntryTable1ReferencesComponent implements OnInit, OnDestroy {

  @Input()
  formGroup: UntypedFormGroup;
  execution: boolean;

  private onDestroy$ = new Subject();

  constructor(private service: RemitTradeEntryTable1Service) {
  }

  ngOnInit() {
    this.formGroup.addControl('execution', new UntypedFormControl(''));
    this.formGroup.addControl('transactionDate', new UntypedFormControl(''));
    this.formGroup.addControl('transactionTime', new UntypedFormControl(''));
    this.formGroup.addControl('uti', new UntypedFormControl(''));
    this.formGroup.addControl('linkedTransactionId', new UntypedFormControl(''));

    this.service.onReset().pipe(takeUntil(this.onDestroy$)).subscribe(
      response => {
        if (response) {
          this.onExecution({ target: { checked: false } });
        }
      }
    );
  }

  public onExecution($event) {
    this.execution = $event.target.checked;
  }

  getValidationErrorStyleClass(fieldName: string): string {
    if (this.formGroup.controls[fieldName].hasError('error')) {
      return 'error';
    }
  }

  public onTransactionTimeChange() {
    this.service.transactionTimeValid.next(this.isTransactionTimeValid());
  }

  public isTransactionTimeValid(): boolean {
    const tt = this.formGroup.controls['transactionTime'];
    return tt.value != null && tt.value.length > 0 && tt.errors == null;
  }

  ngOnDestroy(): void {
  }

}
