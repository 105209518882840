import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Message } from 'primeng/api';
import { EditableData } from '../../../../common/datatable/cell-editor/cell-editor.model';
import { DialogState } from '../../../state/dialog/dialog.reducer';
import { UserAccount } from '../../user-accounts/user-accounts.model';
import { OrganisationGroup } from '../../administration.model';
import {
  DeleteOrganisation,
  EditEcm,
  EditErr,
  MoveOrganisation,
  EditOrganisation,
  AddOrganisation, EditEsm, Organisation
} from '../organisations.model';
import { select, Store } from '@ngrx/store';
import { AdminOrganisationFormState, OrganisationsState } from '../../../state/organisation/organisation.reducer';
import * as fromSelectors from '../../../state/organisation/organisation.selectors';
import { Observable } from 'rxjs';

@Component({
  selector: 'cms-organisation-dialogs-container',
  templateUrl: './organisation-dialogs-container.component.html',
  styleUrls: ['./organisation-dialogs-container.component.scss']
})
export class OrganisationDialogsContainerComponent {
  @Input() dialog: DialogState;
  @Input() editableData: EditableData;
  @Input() organisationGroupId: number;
  @Input() selected: Organisation;
  @Input() orgGroups: OrganisationGroup[];
  @Output() cancel = new EventEmitter<Message[]>();
  @Output() changeActivation = new EventEmitter<any>();
  @Output() deactivate = new EventEmitter<any>();
  @Output() add = new EventEmitter<AddOrganisation>();
  @Output() edit = new EventEmitter<EditOrganisation>();
  @Output() delete = new EventEmitter<DeleteOrganisation>();
  @Output() move = new EventEmitter<MoveOrganisation>();
  @Output() editEcm = new EventEmitter<EditEcm>();
  @Output() editErr = new EventEmitter<EditErr>();
  @Output() editEsm = new EventEmitter<EditEsm>();

  form$: Observable<AdminOrganisationFormState>;

  constructor(private store: Store<OrganisationsState>) {
    this.form$ = this.store.pipe(select(fromSelectors.getFormValues));
  }

  onCancel(messages: Message[]): void {
    this.cancel.emit(messages);
    this.dialog = null;
  }

  onConfirmChangeActivation(obj: any): void {
    this.changeActivation.emit(obj);
  }

  onConfirmAddOrg(obj: AddOrganisation): void {
    this.add.emit(obj);
  }

  onConfirmEditOrg(obj: EditOrganisation): void {
    this.edit.emit(obj);
  }

  onConfirmDeleteOrg(obj: DeleteOrganisation): void {
    this.delete.emit(obj);
  }

  onConfirmMoveOrg(obj: MoveOrganisation): void {
    this.move.emit(obj);
  }

  onConfirmEditEcm(obj: EditEcm): void {
    this.editEcm.emit(obj);
  }

  onConfirmEditErr(obj: EditErr): void {
    this.editErr.emit(obj);
  }

  onConfirmEditEsm(obj: EditEsm): void {
    this.editEsm.emit(obj);
  }
}
