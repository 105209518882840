import { Column } from '../../common/shared/datatable.model';

export namespace SmtDealFinderDataTableConfig {

  const TABLE_COLUMNS: { [name: string]: Column } = {
    dataStoreId: { title: 'DataStore Id', style: { 'width': '120px', 'white-space': 'nowrap' }, visible: false },
    id: { title: 'History Id', style: { 'width': '120px', 'white-space': 'nowrap' }, visible: false },
    reportingTimestamp: { title: 'Reporting Timestamp', style: { 'width': '210px', 'text-align': 'left', 'white-space': 'nowrap' } },
    regime: { title: 'Regime', style: { 'width': '120px', 'text-align': 'left', 'white-space': 'nowrap' } },
    reporter: { title: 'Reporter', style: { 'width': '240px', 'text-align': 'left', 'white-space': 'nowrap' } },
    buttons: { title: '', style: { 'width': '70px', 'white-space': 'nowrap' }, sortable: false },
    buySellIndicator: { title: 'B/S', style: { 'width': '80px', 'text-align': 'left', 'white-space': 'nowrap' } },
    type: { title: 'Type', style: { 'width': '90px', 'text-align': 'left', 'white-space': 'nowrap' } },
    action: { title: 'Action', style: { 'width': '90px', 'text-align': 'left', 'white-space': 'nowrap' } },
    transactionDate: { title: 'Transaction Date', style: { 'width': '170px', 'text-align': 'left', 'white-space': 'nowrap' } },
    state: { title: 'State', style: { 'width': '120px', 'text-align': 'left', 'white-space': 'nowrap' } },
    tradeId: { title: 'Trade ID', style: { 'width': '120px', 'text-align': 'left', 'white-space': 'nowrap' } },
    reportee: { title: 'Reportee', style: { 'width': '240px', 'text-align': 'left', 'white-space': 'nowrap' } },
    cpId: { title: 'Counterparty ID', style: { 'width': '315px', 'text-align': 'left', 'white-space': 'nowrap' } },
    commodity: { title: 'Comm', style: { 'width': '120px', 'text-align': 'left', 'white-space': 'nowrap' } },
    deliveryStartDate: { title: 'Delivery Start', style: { 'width': '150px', 'text-align': 'left', 'white-space': 'nowrap' } },
    deliveryEndDate: { title: 'Delivery End', style: { 'width': '150px', 'text-align': 'left', 'white-space': 'nowrap' } },
    quantity: { title: 'Quantity', style: { 'width': '120px', 'text-align': 'right', 'white-space': 'nowrap' }, type: 'currency' },
    quantityUnit: { title: 'Quantity Unit', style: { 'width': '150px', 'text-align': 'left', 'white-space': 'nowrap' } },
    price: { title: 'Price', style: { 'width': '120px', 'text-align': 'right', 'white-space': 'nowrap' }, type: 'currency' },
    priceCurrency: { title: 'Price Currency', style: { 'width': '150px', 'text-align': 'left', 'white-space': 'nowrap' } },
    uti: { title: 'UTI', style: { 'width': '500px', 'text-align': 'left', 'white-space': 'nowrap' } },
    linkedTransactionId: { title: 'Linked Transaction ID', style: { 'width': '210px', 'text-align': 'left', 'white-space': 'nowrap' } },
    documentType: { title: 'Doc Type', style: { 'width': '120px', 'text-align': 'left', 'white-space': 'nowrap' } }
  };

  /**
   * Only the following entries are visible columns in the given order.
   */
  const DEFAULT_TABLE_COLUMNS = [
    'dataStoreId',
    'id', // historyId
    'reportingTimestamp',
    'regime',
    'reporter',
    'buttons',
    'buySellIndicator',
    'type',
    'action',
    'transactionDate',
    'state',
    'tradeId',
    'reportee',
    'cpId',
    'commodity',
    'deliveryStartDate',
    'deliveryEndDate',
    'quantity',
    'quantityUnit',
    'price',
    'priceCurrency',
    'uti',
    'linkedTransactionId',
    'documentType'
  ];

  export function getDefaultSortingColumn(): string {
    return 'reportingTimestamp';
  }

  export function getDefaultColumns(): string[] {
    return DEFAULT_TABLE_COLUMNS;
  }

  export function getColumns(columnNames: string[]): Column[] {
    return columnNames.map(name => {
      const column = TABLE_COLUMNS[name];
      return Object.assign({
        name: name,
        width: '200px',
        sortable: true,
        visible: true
      }, column);
    });
  }

}
