<div class="container-fluid row margin-bottom-15">
  <div class="col-auto">
    <cms-back-button label="Dashboard" (back)="onBack()"></cms-back-button>
  </div>
  <div class="col-auto btn-group flex flex-wrap" role="group">
    <button *ngFor="let item of data"
            type="button" class="btn btn-secondary"
            (click)="onChangeState(item)"
            [ngClass]="{'selected' : state === item.state}">
      {{ item.title }}
    </button>
  </div>
</div>
