import { AbstractControl, ValidationErrors } from '@angular/forms';

export class ValidationUtils {

  public static addError(control: AbstractControl, errorCode: string, errorValue: any): void {
    const errors: ValidationErrors = control.errors || {};
    errors[errorCode] = errorValue;
    control.setErrors(errors);
  }

  public static removeError(control: AbstractControl, errorCode: string): void {
    if (control.hasError(errorCode)) {
      const errors: ValidationErrors = control.errors;
      delete errors[errorCode];

      const noErrorsLeft: boolean = (Object.getOwnPropertyNames(errors).length === 0);

      control.setErrors(noErrorsLeft ? null : errors);
    }
  }

}
