import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { InjectionToken } from '@angular/core';
import * as fromInvoiceFinder from '../invoice-finder/invoice-finder.reducers';
import * as fromFileUpload from '../file-upload/file-upload.reducer';
import * as fromDashboard from '../dashboard/dashboard.reducers';
import * as fromSettings from '../settings/settings.reducers';
import * as fromMasterData from '../master-data/master-data.reducer';
import * as fromNettingFinderData from '../netting/finder/esm-netting-finder.reducers';
import * as fromNettingDashboard from '../netting/dashboard/dashboard.reducers';
import { esmNettingPreviewReducer, NettingPreviewState } from '../netting-preview';
import { permissionsReducer, PermissionsState } from '../permissions/permissions.reducer';

export const getEsmState = createFeatureSelector<State>('esm');

export interface State {
  invoiceFinder: fromInvoiceFinder.EsmInvoiceFinderState;
  nettingStatementFinder: fromNettingFinderData.EsmNettingStatementFinderState;
  fileUpload: fromFileUpload.EsmFileUploadState;
  dashboard: fromDashboard.EsmDashboardState;
  nettingdashboard: fromNettingDashboard.EsmNettingDashboardState;
  settings: fromSettings.EsmSettingsState;
  masterData: fromMasterData.EsmMasterDataState;
  nettingPreview: NettingPreviewState;
  permissions: PermissionsState;
}

export const reducers: ActionReducerMap<State> = {
  invoiceFinder: fromInvoiceFinder.esmInvoiceFinderReducer,
  nettingStatementFinder: fromNettingFinderData.initEsmNettingStatementFinderReducer,
  fileUpload: fromFileUpload.esmFileUploadReducer,
  dashboard: fromDashboard.esmDashboardReducer,
  nettingdashboard: fromNettingDashboard.esmNettingDashboardReducer,
  settings: fromSettings.esmSettingsReducer,
  masterData: fromMasterData.initEsmMasterDataReducer,
  nettingPreview: esmNettingPreviewReducer,
  permissions: permissionsReducer
};

export const reducerToken = new InjectionToken<ActionReducerMap<State>>(
  'ESM Registered Reducers'
);

export const getReducers = () => reducers;

