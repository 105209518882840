<div class="panel-box margin-top-9">
  <form class="form-row" [formGroup]="form">
    <div class="container">
      <div class="row">
        <div class="container col-md-12 col-lg-6">
          <div class="row">
            <div class="col" formGroupName="vatDetails">
              <label class="text-bold form__label">VAT Details</label>
              <div class="row">
                <div class="col-5">
                  <label class="col-form-label">VAT ID <span class="asterisk"></span></label>
                </div>
                <div class="col-7">
                  <input id="vatIdInput" type="text" [ngClass]="{'error': errors['vatId']}" placeholder="VAT ID" class="form-control" formControlName="vatId" maxlength="16">
                </div>
              </div>
              <div class="row">
                <div class="col-5">
                  <label class="col-form-label">Legal Name <span class="asterisk"></span></label>
                </div>
                <div class="col-7">
                  <input id="legalNameInput" maxlength="255" type="text" [ngClass]="{'error': errors['legalName']}" placeholder="Legal Name" class="form-control" formControlName="legalName">
                </div>
              </div>
              <div class="row">
                <div class="col-5">
                  <label class="col-form-label">VAT Representative</label>
                </div>
                <div class="col-7">
                  <input id="vatRepresentativeInput" maxlength="255" type="text" class="form-control " placeholder="VAT Representative" formControlName="vatRepresentative">
                </div>
              </div>
              <div class="row">
                <div class="col-5">
                  <label class="col-form-label">VAT Jurisdiction Currency <span class="asterisk"></span></label>
                </div>
                <div class="col-7">
                  <input id="vatJurisdictionCurrencyInput" type="text" [ngClass]="{'error': errors['vatJurisdictionCurrency']}" placeholder="VAT Jurisdiction Currency" cmsUpperCase class="form-control ng-trim-ignore" formControlName="vatJurisdictionCurrency" maxlength="3">
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col" formGroupName="addressDetails">
              <label class="text-bold form__label">Address Details</label>
              <div class="row">
                <div class="col-5">
                  <label class="col-form-label">Street <span class="asterisk"></span></label>
                </div>
                <div class="col-5">
                  <input id="streetInput" type="text" maxlength="255" [ngClass]="{'error': errors['street']}" placeholder="Street" class="form-control" formControlName="street">
                </div>
                <div class="col-2">
                  <input id="streetNumberInput" type="text" maxlength="20" [ngClass]="{'error': errors['streetNumber']}" placeholder="No." class="form-control" formControlName="streetNumber">
                </div>
              </div>
              <div class="row">
                <div class="col-5">
                  <label class="col-form-label">City <span class="asterisk"></span></label>
                </div>
                <div class="col-7">
                  <input id="cityInput" maxlength="255" type="text" class="form-control " [ngClass]="{'error': errors['addressDetails.city']}" placeholder="City" formControlName="city">
                </div>
              </div>
              <div class="row">
                <div class="col-5">
                  <label class="col-form-label">Country <span class="asterisk"></span> / Postal Code <span class="asterisk"></span></label>
                </div>
                <div class="col-2">
                  <p-inputMask [ngClass]="{'error': errors['addressDetails.country']}"
                               id="countryInput"
                               formControlName="country"
                               mask="aa"
                               styleClass="form-control"
                               type="text"
                               slotChar=""
                               placeholder="CC"
                               [style]="{ 'max-width': '79px', 'text-transform': 'uppercase' }">
                  </p-inputMask>
                </div>
                <div class="col-5">
                  <input id="postalCodeInput" [ngClass]="{'error': errors['postalCode']}" placeholder="Postal Code" class="form-control " formControlName="postalCode" maxlength="10" type="text">
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col" formGroupName="otherAddressDetails">
              <label class="text-bold form__label">Other Address Details</label>
              <div class="row">
                <div class="col-5">
                  <label class="col-form-label">Street</label>
                </div>
                <div class="col-5">
                  <input id="otherStreetInput" type="text" maxlength="255" [ngClass]="{'error': errors['otherAddressDetails.street']}" placeholder="Street" class="form-control" formControlName="street">
                </div>
                <div class="col-2">
                  <input id="otherStreetNumberInput" type="text" maxlength="20" [ngClass]="{'error': errors['otherAddressDetails.streetNumber']}" placeholder="No." class="form-control" formControlName="streetNumber">
                </div>
              </div>
              <div class="row">
                <div class="col-5">
                  <label class="col-form-label">City</label>
                </div>
                <div class="col-7">
                  <input id="otherCityInput" maxlength="255" type="text" class="form-control " [ngClass]="{'error': errors['otherAddressDetails.city']}" placeholder="City" formControlName="city">
                </div>
              </div>
              <div class="row">
                <div class="col-5">
                  <label class="col-form-label">Country / Postal Code</label>
                </div>
                <div class="col-2">
                  <p-inputMask [ngClass]="{'error': errors['otherAddressDetails.country']}"
                               id="otherCountryInput"
                               formControlName="country"
                               mask="aa"
                               styleClass="form-control"
                               type="text"
                               slotChar=""
                               placeholder="CC"
                               [style]="{ 'max-width': '79px', 'text-transform': 'uppercase' }">
                  </p-inputMask>
                </div>
                <div class="col-5">
                  <input id="otherPostalCodeInput" [ngClass]="{'error': errors['otherAddressDetails.postalCode']}" placeholder="Postal Code" class="form-control " formControlName="postalCode" maxlength="10" type="text">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container col-md-12 col-lg-6">
          <div class="row">
            <div class="col" formGroupName="companyRegistry">
              <label class="text-bold form__label">Company Registry</label>
              <div class="row">
                <div class="col-5">
                  <label class="col-form-label">Registry Number <span class="asterisk"></span></label>
                </div>
                <div class="col-7">
                  <input id="registryNumberInput" maxlength="255" type="text" [ngClass]="{'error': errors['registryNumber']}" placeholder="Registry Number" class="form-control" formControlName="registryNumber">
                </div>
              </div>
              <div class="row">
                <div class="col-5">
                  <label class="col-form-label">Registry Name <span class="asterisk"></span></label>
                </div>
                <div class="col-7">
                  <input id="registryNameInput" maxlength="255" type="text" [ngClass]="{'error': errors['registryName']}" placeholder="Registry Name" class="form-control" formControlName="registryName">
                </div>
              </div>
              <div class="row">
                <div class="col-5">
                  <label class="col-form-label">City <span class="asterisk"></span> / Country <span class="asterisk"></span></label>
                </div>
                <div class="col-5">
                  <input id="registryCityInput" maxlength="255" type="text" class="form-control" [ngClass]="{'error': errors['companyRegistry.city']}" placeholder="City" formControlName="city">
                </div>
                <div class="col-2">
                  <p-inputMask [ngClass]="{'error': errors['companyRegistry.country']}"
                               id="registryCountryInput"
                               formControlName="country"
                               mask="aa"
                               type="text"
                               styleClass="form-control"
                               slotChar=""
                               placeholder="CC"
                               [style]="{ 'max-width': '79px', 'text-transform': 'uppercase' }">
                  </p-inputMask>
                </div>
              </div>
              <div class="row">
                <div class="col-5">
                  <label class="col-form-label">Branch</label>
                </div>
                <div class="col-7">
                  <input id="registryBranchInput" maxlength="255" type="text" placeholder="Branch" class="form-control" formControlName="branch">
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col" formGroupName="contactDetails">
              <label class="text-bold form__label">Contact Details</label>
              <div class="row">
                <div class="col-5">
                  <label class="col-form-label">First Name</label>
                </div>
                <div class="col-7">
                  <input id="firstNameInput" maxlength="255" type="text" placeholder="First Name" class="form-control" formControlName="firstName">
                </div>
              </div>
              <div class="row">
                <div class="col-5">
                  <label class="col-form-label">Last Name</label>
                </div>
                <div class="col-7">
                  <input id="lastNameInput" maxlength="255" type="text" placeholder="Last Name" class="form-control" formControlName="lastName">
                </div>
              </div>
              <div class="row">
                <div class="col-5">
                  <label class="col-form-label">Email <span class="asterisk"></span></label>
                </div>
                <div class="col-7">
                  <input id="emailInput" maxlength="255" type="text" [ngClass]="{'error': errors['email']}" placeholder="Email" class="form-control" formControlName="email">
                </div>
              </div>
              <div class="row">
                <div class="col-5">
                  <label class="col-form-label">Phone</label>
                </div>
                <div class="col-7">
                  <input id="phoneInput" class="form-control" placeholder="Phone" formControlName="phone" maxlength="30" type="text">
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="includeEic">
            <div class="row">
              <div class="col">
                <label class="text-bold form__label">EIC Code</label>
                <div class="row">
                  <div class="col-5">
                    <label class="col-form-label">EIC Code <span class="asterisk"></span></label>
                  </div>
                  <div class="col-7">
                    <input id="eicCode" maxlength="255" type="text" [ngClass]="{'error': errors['eicCode']}" placeholder="EIC Code" class="form-control" formControlName="eicCode">
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="row">
            <div class="col" >
              <label class="text-bold form__label">VAT Force Of Attraction</label>
              <div class="row">
                <div class="col-5">
                  <label class="col-form-label">use VAT 'Force Of Attraction'</label>
                </div>
                <div class="col-7">
                  <div class="p-field-checkbox">
                    <p-checkbox inputId="enableForceOfAttraction"
                                id="enableFOA_check_box"
                                binary="true"
                                class="mt-2"
                                formControlName="enableForceOfAttraction">
                    </p-checkbox>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-5">
                  <label class="col-form-label">Head Office VAT</label>
                </div>
                <div class="col-7">
                  <input id="headOfficeVATInput" maxlength="255" type="text" placeholder="HeadOfficeVAT" class="form-control" formControlName="headOfficeVatId"/>
                </div>
              </div>
              <div class="row">
                <div class="col-5">
                  <label class="col-form-label">Head Office: </label>
                </div>
                <div class="col-7">
                  <label class="col-form-label">{{formValues?.headOfficeCompany}}<br>{{formValues?.headOfficeAddress1}}<br>{{formValues?.headOfficeAddress2}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-container">
        <button id="deleteButton" type="button" *ngIf="editVisible" class="btn margin-right-6" pButton label="Delete" (click)="onDelete()" [disabled]="isFormDisable || isNewMDRecord"></button>
        <button id="updateButton" *ngIf="!isNewMDRecord && editVisible" type="submit" class="btn p-button margin-right-6" pButton label="Update" (click)="onSave()" [disabled]="isFormDisable"></button>
        <button id="saveButton" *ngIf="isNewMDRecord && editVisible" type="submit" class="btn p-button margin-right-6" pButton label="Save" (click)="onSave()" [disabled]="isFormDisable"></button>
        <button id="cancelButton" *ngIf="editVisible" class="btn margin-right-6" pButton label="Cancel" (click)="onCancel()" [disabled]="isFormDisable"></button>
      </div>
    </div>
  </form>
</div>
