<h1>CMS Billing Reports</h1>
<div class="panel-box margin-0">
  <div class="row">
    <div>
      <p-messages [value]="messages$ | async" class="col-12 padding-0"></p-messages>
      <cms-billing-reports-filters #dates
                                   [dates]="dates$ | async">
      </cms-billing-reports-filters>
      <div class="billing-reports__btn-container panel-box">
        <p class="block-title">Internal</p>
        <div>
          <span class="report-title">eCM</span>
          <div class="downloading-report">
            <a class="ref margin-right-6" (click)="onBilling('ecm')">Billing</a>
            <i class="pi-spin pi pi-spinner" *ngIf="(loading$ | async).ecm"></i>
          </div>
          <div class="downloading-report downloading-report--rank-int">
            <a class="ref margin-right-6" (click)="onBilling('rankingInternal')">Ranking (Internal)</a>
            <i class="pi-spin pi pi-spinner" *ngIf="(loading$ | async).rankingInternal"></i>
          </div>
          <div class="downloading-report downloading-report--rank-pub">
            <a class="ref margin-right-6" (click)="onBilling('rankingPublic')">Ranking (Public)</a>
            <i class="pi-spin pi pi-spinner" *ngIf="(loading$ | async).rankingPublic"></i>
          </div>
        </div>
        <div>
          <span class="report-title">eRR</span>
          <div class="downloading-report">
            <a class="ref margin-right-6" (click)="onBilling('err')">Billing</a>
            <i class="pi-spin pi pi-spinner" *ngIf="(loading$ | async).err"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
