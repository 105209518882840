import { Component, OnDestroy } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { checkPresence, getSelected } from '../../state/netting-preview/invoice-overview/invoice-overview.selectors';
import { InvoiceOverview } from './netting-overview.model';
import * as fromTableSelectors from '../../state/netting-preview/netting-table/netting-table.selectors';
import * as dropDownSelectors from '../../state/netting-preview/counterparty/counterparty.selectors';
import * as invoiceSelectors from '../../state/netting-preview/invoice-overview/invoice-overview.selectors';

import * as InvoiceOverviewActions from '../../state/netting-preview/invoice-overview/invoice-overview.actions';
import * as AmountTableActions from '../../state/netting-preview/amount-table/amount-table.actions';
import { filter, switchMap } from 'rxjs/operators';
import { NettingPreviewState } from '../../state/netting-preview';
import { InvoiceDivEntry } from '../netting-table-overview/netting-table-overview.model';
import { GenerationDialogComponent } from '../generation-dialog/generation-dialog.component';
import * as GenerationDialogActions from '../../state/netting-preview/generation-dialog/generation-dialog.actions';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'cms-netting-overview',
  templateUrl: './netting-overview.component.html',
  styleUrls: ['./netting-overview.component.scss'],
  providers: [DialogService]
})
export class NettingOverviewComponent implements OnDestroy {

  selectedItem: InvoiceOverview;
  tableSelected: InvoiceDivEntry;
  subscription$: Subscription = new Subscription();

  getTableEntry$ = this.store$.pipe(select(fromTableSelectors.getSelected));
  tableBusy$ = this.store$.pipe(select(fromTableSelectors.getLoading));
  dropDownBusy$ = this.store$.pipe(select(dropDownSelectors.getLoading));
  allowDialog$ = this.store$.pipe(select(invoiceSelectors.getAllowDialog));

  selectItem$ = this.getTableEntry$.pipe(
    filter(row => Boolean(row)),
    switchMap(({ id }) => this.store$.select(checkPresence, { id })));

  constructor(private store$: Store<NettingPreviewState>, public dialogService: DialogService) {

    this.subscription$
      .add(this.selectItem$.pipe(filter(item => !!item)).subscribe(item => {
        this.store$.dispatch(InvoiceOverviewActions.select({ item }));
        this.store$.dispatch(AmountTableActions.select({ id: item.id }));
      }));

    this.subscription$.add(combineLatest([this.getTableEntry$, this.tableBusy$, this.dropDownBusy$])
        .pipe(filter(([selected, tLoad, dLoad]) => !dLoad && !tLoad && !!selected))
        .subscribe(([selected,]) => {
          this.tableSelected = selected;
          this.store$.dispatch(InvoiceOverviewActions.LoadView({ selected }));
        })
      );

    this.subscription$.add(this.store$.pipe(select(getSelected)).subscribe(item => this.selectedItem = item));

    this.subscription$.add(this.allowDialog$.subscribe((isAllowDialog) => {
        if (isAllowDialog) {
          this.dialogService.open(GenerationDialogComponent, {
            header: 'Netting Generation Confirmation',
            width: '25%',
            closeOnEscape: true,
            dismissableMask: true
          });
        }
      }));
  }

  onOpenDialog() {
    this.store$.dispatch(GenerationDialogActions.checkCondition(this.tableSelected));
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
  }
}


