import { createAction, props } from '@ngrx/store';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { Message } from 'primeng/api';
import { TableEntry } from '../../permissions/web-user/web-user.model';

export const loadPermissions = createAction('[ESM_PERMISSIONS] Load Permissions');

export const cleanState = createAction('[ESM_PERMISSIONS] Clear permissions state');
export const loading = createAction('[ESM_PERMISSIONS] un/loading', props<{ isLoading: boolean }>());

export const loadPresets = createAction('[ESM_PERMISSIONS] Load presets');
export const loadPresetsSuccess = createAction('[ESM_PERMISSIONS] Load presets success', props<{ editAllowed: boolean }>());


export const setMessages = createAction('[ESM_PERMISSIONS] set messages', props<{ messages: Message[] }>());
export const resetMessages = createAction('[ESM_PERMISSIONS] Reset Messages');

export const loadTable = createAction('[ESM_PERMISSIONS] Load permissions');
export const loadTableSuccess = createAction('[ESM_PERMISSIONS] Search permissions successfully', props<{ data: DatatableData<TableEntry[]>}>());
export const updateEntries = createAction('[ESM_PERMISSIONS] Update entries', props<{ selectedPermissions: TableEntry[], newPermission }>());
export const updateStoreEntries = createAction('[ESM_PERMISSIONS] Update entries', props<{ selectedPermissions: TableEntry[], newPermission: 'Block' | 'Allow'}>());
export const resetEntries = createAction('[ESM_PERMISSIONS] Reset entries');


export const pagingAndSorting = createAction('[ESM_PERMISSIONS] Get sorting and paging', props<{ sorting: SortingFilter, paging: PagingFilter}>());
export const setPagingAndSorting = createAction('[ESM_PERMISSIONS] Set sorting and paging', props<{ sorting: SortingFilter, paging: PagingFilter}>());

