import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, combineLatest, switchMap, withLatestFrom, map } from 'rxjs/operators';
import { Message } from 'primeng/api';
import { ApiRequestService } from '@common/api-request.service';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { AllDealsMyFilterName } from '@common/my-filters/my-filters.model';
import * as fromMyFilterActions from '../my-filters/my-filters.actions';
import * as fromTableActions from '../table/table.actions';
import * as fromIFSelectors from '../invoice-finder.selectors';
import * as fromFiltersActions from './filters.actions';
import { EsmInvoiceFinderState } from '../invoice-finder.reducers';
import {
  EsmInvoiceFilterPropertiesResponse,
  updateFilters,
  generateInitialDate
} from '../../../invoice/finder/invoice-finder.model';
import { Sender } from '@common/deals/deals.model';
import { filtersInitialState } from './filters.reducers';


@Injectable()
export class EsmInvoiceFinderFiltersEffects {
  private baseUrl = '/api/esm/invoice/finder';

  constructor(private apiGateway: ApiRequestService,
              private actions$: Actions,
              private store$: Store<EsmInvoiceFinderState>,
              private mapper: MessagesMapperService) {
  }

  getFiltersValues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromFiltersActions.getFiltersValuesAction),
      switchMap(() =>
        this.apiGateway.get(`${this.baseUrl}/form`)
          .pipe(
            switchMap((response: EsmInvoiceFilterPropertiesResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [fromTableActions.setMessagesAction(messages)];
              }
              const { commodities, counterParties, invoiceTypes, deliveryPoints} = response.value;
              return [
                fromTableActions.setMessagesAction([]),
                fromFiltersActions.getFiltersValuesSuccess(response.value),
                fromMyFilterActions.setAllDealsFilterAction({
                  defaultFilter: true,
                  filterName: AllDealsMyFilterName,
                  commodityFilter: commodities,
                  deliveryPointFilter:deliveryPoints.map(({ key, value }) => ({ keyObject: key, valueObject: value })),
                  invoiceTypeFilter: invoiceTypes,
                  counterpartyFilter: counterParties.map(item => item.key),
                  hiddenType: Sender.US
                })
              ];
            }),
            catchError(error => of(fromTableActions.setMessagesAction([this.mapper.createErrorMessage(error.errorMessage)])))
          )
      )
    )
  );

  getFiltersSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromFiltersActions.getFiltersValuesSuccess),
      combineLatest(this.actions$.pipe(ofType(fromMyFilterActions.loadMyFiltersSuccessAction))),
      withLatestFrom(this.store$.pipe(select(fromIFSelectors.getEsmInvoiceFinderState))),
      map(([, { filters, myFilters }]: [[Action, Action], EsmInvoiceFinderState]) =>
        fromFiltersActions.updateFiltersAction(updateFilters(filters, filters.propFilters, myFilters.defaultFilter)),
      )
    )
  );

  clearPropertiesFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromFiltersActions.clearPropertiesFiltersAction),
      withLatestFrom(this.store$.pipe(select(fromIFSelectors.getEsmInvoiceFinderState))),
      switchMap(([, { filters, myFilters }]: [Action, EsmInvoiceFinderState]) => {
        const propFilters = {
          ...filtersInitialState.propFilters,
          submissionDateRange: { start: generateInitialDate(19), end: null }
        };
        return [
          fromTableActions.setMessagesAction([]),
          fromFiltersActions.updateFiltersAction(updateFilters(filters, propFilters, myFilters.defaultFilter))
        ];
      }))
  );
}
