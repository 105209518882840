import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { Message } from 'primeng/api';
import { AddBankDetailValue, initialBankValue } from '../../master-data.model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'cms-master-data-add-bank-detail',
  templateUrl: './master-data-add-bank-detail.component.html',
  styleUrls: ['./master-data-add-bank-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MasterDataAddBankDetailComponent implements OnInit, OnChanges {
  @Input() data: AddBankDetailValue;
  @Input() loading: boolean = false;
  @Input() messages: Message[] = [];
  @Input() types: string[] = [];
  enabled = new BehaviorSubject<boolean>(false);
  @Input() version: number;
  @Input() commodities: string[] = [];
  @Input() priceTypes: string[] = [];
  @Input() natureOfPrices: string[] = [];
  @Output() clear = new EventEmitter();
  @Output() confirm = new EventEmitter<AddBankDetailValue>();
  errors: { [key: string]: ValidationErrors } = {};
  form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      bankAcctHolder: this.data ? this.data.bankAcctHolder : '',
      bic:  this.data ? this.data.bic : '',
      currency: this.data ? this.data.currency : '',
      iban: this.data ? this.data.iban : ''
    });
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes.messages) {
      this.errors = {};
      if (this.messages) {
        this.messages.forEach((obj: Message) => {
          const { key: controlName } = obj;
          this.errors[controlName] = { 'server': true };
        });
      }
    }

    if(changes.version){
      this.enabled.next(changes.version.currentValue != null);
    }

    if (this.form && changes.data) {
      this.form.patchValue({ ...(this.data || initialBankValue) });
      this.form.updateValueAndValidity();
    }
  }

  onAdd(): void {
    const data = this.form.getRawValue();
    this.confirm.emit({ ...data });
  }

  onClear(): void {
    this.form.reset();
    this.errors = {};
    this.clear.emit();
  }
}
