import { Message } from 'primeng/api';
import * as fromDialogActions from './dialog.actions';
import { DialogActions } from './dialog.actions';
import { EcmDashboardState } from '../dashboard.reducers';
import * as fromDBActions from '../dashboard.actions';
import { MY_FILTERS_DIALOGS } from '@common/my-filters/my-filters.model';

export interface DialogState {
  name: MY_FILTERS_DIALOGS;
  messages?: Message[];
  data?: any;
}

export function dialogReducer(state: DialogState, action: DialogActions | fromDBActions.SetToDefaultAction): DialogState {
  switch (action.type) {
    case fromDialogActions.OPEN_DIALOG:
      return {
        ...state,
        ...action.payload
      };
    case fromDialogActions.UPDATE_DIALOG:
      return {
        ...state,
        messages: action.payload
      };
    case fromDialogActions.CLOSE_DIALOG:
    case fromDBActions.SET_TO_DEFAULT:
      return null;
    default:
      return state;
  }
}

export const getDialog = (state: EcmDashboardState) => state.dialog;
