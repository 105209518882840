import { RoboDelegatorsUploadActions, RoboDelegatorsUploadActionsTypes } from './robo-delegators-upload.actions';

export interface RoboDelegatorsUploadState {
  uploadPanelVisible: boolean;
  uploadAllowed: boolean;
}

export const initialRoboDelegatorsUploadState: RoboDelegatorsUploadState = {
  uploadPanelVisible: false,
  uploadAllowed: false
};

export function RoboDelegatorsUploadReducer (state = initialRoboDelegatorsUploadState,
                                             action: RoboDelegatorsUploadActions): RoboDelegatorsUploadState {
  switch (action.type) {
    case RoboDelegatorsUploadActionsTypes.GetPresetValues: {
      return state;
    }
    case RoboDelegatorsUploadActionsTypes.GetPresetValuesSuccess: {
      return {
        ...state,
        ...action.payload
      };
  }
    default: return state;
  }
}
