import { Component, OnDestroy, OnInit } from '@angular/core';
import { HelpService } from '../help.service';
import { AnnouncementResponseModel } from '../announcementResponseModel';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cms-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit, OnDestroy {
  supportMessage: AnnouncementResponseModel;
  private onDestroy$ = new Subject();
  editMode: boolean = false;

  constructor(private helpService: HelpService) {
  }

  ngOnInit() {
    this.helpService.onSupportChange.pipe(
      takeUntil(this.onDestroy$))
      .subscribe(notification =>
        this.supportMessage = notification
      );

    this.helpService.fetchSupportMessage();
  }

  ngOnDestroy(): void {
    // Clean up all subscriptions at once:
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
    this.editMode = false;
    if(this.supportMessage){
      this.supportMessage.editable = false;
    }
  }

  get supportMessage$(): Observable<AnnouncementResponseModel>{
    return this.helpService.onSupportChange;
  }

  get isEditable(): Observable<boolean> {
    return of(this.supportMessage.editable);
  }

  get isEditMode(): Observable<boolean> {
    return of(this.supportMessage.editable && this.editMode);
  }

  get isDisplayMode(): Observable<boolean> {
    return of(!(this.supportMessage.editable && this.editMode));
  }

  switchEditMode(): void {
    this.editMode = !this.editMode;
  }
}

