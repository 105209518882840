import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TransactionSheetResponseModel } from '@common/sheet/transaction-sheet-model';
import { EndPointConstants } from '@common/end-point-constants';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { SmtDealFinderTransactionsService } from './dealfinder/smt-dealfinder-transactions.service';

@Component({
  selector: 'cms-smt-transaction-sheet',
  template: `
        <cms-transaction-sheet [data]="transactionSheetResponse$ | async"></cms-transaction-sheet>`
})
export class SmtTransactionSheetComponent implements OnInit {
  transactionSheetResponse$: Observable<TransactionSheetResponseModel>;

  constructor(private smtDealFinderTransactionsService: SmtDealFinderTransactionsService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.transactionSheetResponse$ = this.route.queryParams.pipe(
      mergeMap((params: Params) => {
        const regime = params[EndPointConstants.PARAM_REGIME];
        const recordType = params[EndPointConstants.PARAM_RECORD_TYPE];
        const documentType = params[EndPointConstants.PARAM_DOCUMENT_TYPE];
        const historyId = params[EndPointConstants.PARAM_HISTORY_ID];
        return this.smtDealFinderTransactionsService.getTransactionSheet(regime, recordType, documentType, historyId);
      }));
  }
}
