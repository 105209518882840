import { INVOICE_FINDER_DIALOGS } from '../../../invoice/finder/invoice-finder.model';
import * as fromCommon from '@common/state/reducers';
import * as fromDialogActions from './dialog.actions';
import * as IFActions from '../invoice-finder.actions';

export const esmInvoiceFinderDialogReducer = fromCommon.dialogReducer<INVOICE_FINDER_DIALOGS>({
  OPEN_DIALOG: fromDialogActions.openDialogAction.type,
  UPDATE_DIALOG: fromDialogActions.updateDialogAction.type,
  CLOSE_DIALOG: fromDialogActions.closeDialogAction.type,
  SET_TO_DEFAULT: IFActions.setToDefaultAction.type
});
