<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>
  <h1>eSM Email Whitelist of {{(organisationService.getChangeOrganisationObservable() | async)?.displayName}}
    ({{(organisationService.getChangeOrganisationObservable() | async)?.eicCode}})</h1>

  <p-messages [value]="messages$ | async" [closable]="true"></p-messages>

  <div class="panel-box">
    <cms-info ngPreserveWhitespaces>

      <u>**eSM Email Whitelist**</u>

      **Purpose of the Whitelist**

      Master operators of eSM organisations can upload email addresses of their eSM counterparties, their own organisation(s) and personalised messages which should be send to the counterparts.
      The 'Locale' column describes the language to be used for the messages. The associated optional text blocks should be in this language.

      Each eSM organisation is identified via their EIC Code and their VAT ID and it is possible to define one or more email addresses for each organisation. Currently, the email addresses for the own organisation will be used to send an emails with attached invoice- or netting PDF and/or XML to the organisation in the case of an invoice or netting statement match. The documents that are available for sending are maintained in
      the eSM Email Subscription screen. It is also possible to send emails with the attached invoice PDF and XML to counterparties which are not registered in the CMS or are registered in the CMS but do not participate in the regular eSM matching process.

      * **Counterparties which are not registered to the CMS:** After an upload of an invoice, these counterparties will instantly receive an email notification with the attached invoice PDF and XML. After a successful sending of the invoice, the invoices can be found in the invoice dashboard under the tab 'Complete' with the state 'PDF sent'. If its not possible to send them, those invoices can be found in the invoice
      dashboard on the tab 'Issue' with the state 'PDF not sent'.
      * **Counterparties which are registered to the CMS but do not participate in the regular eSM process:** Those counterparties are called eSM lite users. They have the possibility to look at invoice via the eSM Invoice Dashboard or Finder which have been uploaded by a full eSM counterparty. After checking the invoices, the eSM lite user can use the click-and-agree functionality and agree or dispute invoices. After the
      agreement both counterparties receive an email notification with the attached invoice PDF and/or XML. After the agreement of invoices by an eSM lite user, the invoice can be found in the eSM Dashboard on the Tab 'Complete' and the state 'Matched (Web User)'. If an invoice gets disputed, the full eSM user receives an email with the notification of the change of state. The reason for dispute can be found in the notes in
      the GUI as disputed invoices have an obligatory note attached. If the eSM lite user has disputed an invoice, the invoice can be found on the tab 'Issue' with the state 'Mismatched'.

      **Support for eSM offsite parties**

      * **Support offsite eSM process**: When checked, this means that your party supports the eSM offsite process. Counterparties which are not known to the CMS and which are not registered as eSM lite users are called 'offsite counterparties' and will receive emails with the invoice PDF for each uploaded invoice. Precondition for receiving an email is that the counterparty is whitelisted in the email whitelist with their EIC
      Code, VAT ID and email addresses. If the flag 'support offsite eSM process' is switched off, any invoices uploaded for such a counterparty will be rejected with a FAILED boxresult by eSM.

      **Information in the List**

      * **EIC Code** The EIC code of organisations whitelisted for receiving eSM emails by you
      * **VAT Code** The vat code of organisations whitelisted for receiving eSM emails by you
      * **Display name**: The display name of the organisation identified by EIC code and VAT code
      * **eSM Lite status** : If the co defined by this entry is an eSM Lite party, this column will show the permissions granted by the cp to your organisation: 'Allow' means the party allows you to interact with them as eSM Lite party, 'Block' means that the party is blocking your organisation from interacting with them as eSM Lite party. 'Block Default' is the default setting - this means the party has not yet changed their permissions for your organisation. 'Inactive' means that the cp is not an eSM Lite party anymore. If the column is empty, the cp is not set up as eSM Lite party. If you are uploading invoices for this party, invoices will be processed as offsite invoices if the setting is different than 'Allow'.
      * **Email**: The Email address(es) for the organisation
      * **Commodity**: You can specify a commodity to separate email recipients, an empty value indicates that this entry is the default
      * **Locale**: A 2 digit country code which set the rendered PDF language. Currently only 'DE' (for german language) and 'GB' (for english language) are supported, If no Locale is specified, the PDF is rendered in english.
      * **Boilerplate 1, 2**:  The text provided will be rendered into the PDF at defined locations (Consult the section 'The eSM menu/PDF Settings' of the CMS 7.4 user manual for a more detailed description of the PDF layout). The number of characters which can be entered for each boilerplate is limited to 4000.
      * **Attach XML**:  'Y' means the invoice XML will be attached in addition to the invoice PDF to mails sent to this cp, 'N' means only the PDF will be sent.


      **Searching the List**

      * Use the "Filter" field below to check for the existence of specific EIC codes, VAT Codes or email addresses
      * Filtering is case-insensitive and also finds parts of codes

      **Maintaining the List**

      * Only eSM users with Master Operator (MO) privileges can modify the list
      * Upload - if successful - replaces the existing whitelist
      * Download - will download the current list as CSV file

      **Upload Format**

      The upload format of the Email Whitelist is a CSV file which contains the EIC code, the VAT Number and the email addresses which should be used for the organisation identified by the EIC code and VAT number.

      * **EIC_CODE** - The EIC code (16 characters long) of the organisation (external or internal) for which email addresses should be defined
      * **VAT_ID** - The VAT Code of the organisation (external or internal) for which email addresses should be defined
      * **EMAIL** - The email address(es) which should be used in email sending eSM processes for the organisation defined by EIC_CODE and VAT_CODE. Multiple email addresses must be separated with a blank
      * **COMMODITY** - Optional attribute to separate the email recipients depending on the given value, an empty value indicates the default case or the fallback, i.e. all other not specified commodities
      * **LOCALE**: Optional attribute. Provide a 2 digit country code which set the rendered PDF language
      * **BOILERPLATE_1**: Optional attribute. First text provided will be rendered into the PDF at defined locations 4000.
      * **BOILERPLATE_2**: Optional attribute. Second text provided will be rendered into the PDF at defined locations 4000.
      * **ATTACH_XML**: Optional attribute. Used to enable or disable attaching the invoice XML to email sent to offsite or eSM lite parties. Allowed values : 'Y' or 'N' ('Y' means that the invoice xml will be attached to the email sent to the CP, 'N' means XML will not be attached).

      * File format is .csv with comma delimiter, quoting of values is optional
      * File size limit is 1 MB
      * Required header line is: EIC_CODE, VAT_ID, EMAIL, COMMODITY
      * The combination of EIC_CODE, VAT_ID, COMMODITY must be unique
    </cms-info>


    <p-confirmDialog #cd [style]="{width: '40rem'}">
      <ng-template pTemplate="header">
        <h3>Confirm Change</h3>
      </ng-template>
      <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-times" label="Cancel" class="btn me-4" (click)="cd.reject()"></button>
        <button type="button" pButton icon="pi pi-check" label="Confirm"  class="btn" (click)="cd.accept()"></button>
      </ng-template>
    </p-confirmDialog>


    <p-fieldset id="whitelist_check" [legend]="legend" class="mb-4 block">
      <p-checkbox inputId="esm_whitelist_check_box"
                  [disabled]="!(allowedToUpload$|async)"
                  [ngClass]="{'l-disabled':!(allowedToUpload$|async)}"
                  binary="true"
                  [(ngModel)]="checked" (onChange)="toggle($event)"
                  label='eSM Offsite Parties Support Is Enabled'
                  class="mb-2 ms-2"></p-checkbox>
    </p-fieldset>


    <div id="whitelist">
      <cms-upload-panel *ngIf="allowedToUpload$ | async"
                        [accept]="'.csv'"
                        [maxFileSize]="allowedMaxSize$ | async"
                        [inBytesSize]="true"
                        [busy]="(loading$|async)?.upload"
                        title="Upload eSM email whitelist (.csv file)"
                        [fullWidth]="true"
                        (error)="onUploadError($event)"
                        (upload)="onUpload($event)">
      </cms-upload-panel>

      <cms-datatable [scrollable]="true"
                     [tableColumns]="columns"
                     [currentPage]="(pagingAndSorting$ | async)?.paging.page"
                     [rows]="(pagingAndSorting$ | async)?.paging.entriesPerPage"
                     [sortField]="(pagingAndSorting$ | async)?.sorting.columnName"
                     [sortOrder]="(pagingAndSorting$ | async)?.sorting.sortOrder"
                     [loading]="(loading$ | async)?.table"
                     [data]="(data$ | async)?.values"
                     [totalRecords]="(data$ | async)?.count"
                     [autoLayout]="true"
                     [lazy]="true"
                     [postFix]="36"
                     [lazyLoadOnInit]="false"
                     (lazyLoad)="onLazyLoad($event)">


        <ng-template pTemplate="caption">
          <div class="row margin-0">
            <cms-input-text-filter placeholder="Display Name / EIC Code / VAT ID / Email address" (filter)="onTextFieldFilter($event)" class="col-5 margin-0 padding-0"></cms-input-text-filter>
            <button id="downloadBtn" class="btn mt-1 col-auto" pButton type="button" icon="fa fa-download" label="Download" (click)="onDownload()"></button>
          </div>
        </ng-template>


        <ng-template pTemplate="body" let-row="row" let-column="column">


          <td *ngIf="column.name === 'boilerplate1'" [ngStyle]="column.style" [pTooltip]="row.boilerplate1" tooltipPosition="left">
            <span >{{row.boilerplate1 | slice:0:50}}</span>
          </td>


          <td *ngIf="column.name === 'boilerplate2'" [ngStyle]="column.style" [pTooltip]="row.boilerplate2" tooltipPosition="left">
            <span  >{{row.boilerplate2 | slice:0:50}}</span>
          </td>
          <td *ngIf="column.type === 'toggleButton' && column.visible" [ngStyle]="column.style" cmsCellEditor>
            <cms-cell-status [column]="column"
                             [row]="row"
                             (clickToggleAttachXML)="toggleAttachXML($event)"

                              >
            </cms-cell-status>
          </td>

        </ng-template>
      </cms-datatable>
    </div>
  </div>
</ng-template>


