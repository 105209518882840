<div class="panel-box margin-left-50 margin-right-50 margin-top-50">
  <h1>Cancel Trade</h1>
  <div id="divRemitTradeCancelFeedback">
    <p-messages [(value)]="msgs" [closable]="false"></p-messages>
  </div>
  <ng-container *ngIf="!internalError">
    <div class="p-warn margin-bottom-15 list-style-warn">
      <div class="absolute top-3">
        <i class="fa fa-exclamation-triangle fa-2x"></i>
      </div>
      <div class="padding-left-30 margin-left-15">
        <b>Confirm you wish to report an early termination for this REMIT trade by sending a cancel ("C") record to ARIS.</b>
        <p></p>
        Please note:
        <ul class="list-style-inherit">
          <li>you must enter a termination date which will be reported in field "Contract Date"</li>
          <li>it must not be before the contract date of the latest reported record (shown below)</li>
          <li>you cannot undo this action other than sending an error record for this cancellation</li>
          <li>you cannot make further modifications to a cancelled trade other than sending error records</li>
        </ul>
        <p>Press "Cancel Trade" to proceed or close this tab to abort.</p>
      </div>
    </div>
    <form [formGroup]="cancelForm" class="row align-items-center col-12" (ngSubmit)="submit()">
      <div class="col-6 margin-bottom-9 decent-max-width">
        <div class="margin-bottom-3">
          <label class="text-bold margin-right-6 ps-0" style="width: 165px;">Termination Date <span class="text-required">*</span></label>
          <span class="inline-block padding-right-3">
        <p-calendar
          formControlName="terminationDate"
          [showIcon]="true"
          [monthNavigator]="true"
          [yearNavigator]="true"
          dateFormat="dd/mm/yy"
          [style]="{ 'width': '125px'}"
          cmsDateRestriction>
        </p-calendar>
        </span>
        </div>
      </div>
      <div class="col-12 text-center">
        <button id="btnRemitTradeCancelSubmit" type="submit" class="btn margin-3 padding-6" [disabled]="(disabled || !(submitEnabled$ | async))">
          Cancel Trade
          <p-progressSpinner class="margin-top-9" *ngIf="isInProgress()" [style]="{width: '15px', height: '15px'}" strokeWidth="8"></p-progressSpinner>
        </button>
      </div>
    </form>
  </ng-container>
</div>
