import { Component, Input } from '@angular/core';

@Component({
  selector: 'cms-transaction-sheet-group',
  template: `
        <div class="group-{{name}} group-container">
            <div class="row group-header">
                <div class="col-auto"><span class="group-label">{{label}}</span></div>
            </div>
            <ng-content></ng-content>
        </div>
    `
})
export class TransactionSheetGroupComponent {

  @Input() name: string;

  @Input() label: string;

}
