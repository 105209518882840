import { createSelector } from '@ngrx/store';
import { EsmSettingsTableState } from './table.reducers';
import { getEsmInvoiceSettingsState } from '../invoice-settings.selectors';
import { EsmInvoiceSettingsState } from '../invoice-settings.reducers';

export const getTable = createSelector(getEsmInvoiceSettingsState, (state: EsmInvoiceSettingsState) => state.table);
export const getMessages = createSelector(getTable, (state: EsmSettingsTableState) => state.messages);
export const getData = createSelector(getTable, (state: EsmSettingsTableState) => state.data);
export const getLoading = createSelector(getTable, (state: EsmSettingsTableState) => state.loading);
export const getToggleMatchAllowed = createSelector(getTable, (state: EsmSettingsTableState) => state.toggleMatchAllowed);
export const getEditTypesAllowed = createSelector(getTable, (state: EsmSettingsTableState) => state.editTypesAllowed);
