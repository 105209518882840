<section>
  <cms-info ngPreserveWhitespaces>
    __Purpose__

    Enable administrators to associate party codes with display names.

    Note: mappings for internal CMS parties must be maintained on screen "Organisations".

    **Finding Entries**

    Fill a search term into the field "must contain" and press **Filter**.

    This will return all entries whose party code or display name contain the term.

    Searching is case-insensitive. Press **Reset** to remove the filter.

    **Adding Entries**

    Fill in input fields "Party Code" and "Display Name" and press **Add**.

    Note: display names must have 1-20 characters (letters are converted to uppercase).

    Press **Clear** to empty the input fields.

    **Modifying Entries**

    Click on a display name (external parties only) to make it editable.


    When done editing press ```&lt;Enter&gt;``` to apply your changes or ```&lt;Esc&gt;``` to discard them.


    **Deleting Entries**

    Click on the delete button (external parties only) marked with **X**.
  </cms-info>
  <cms-party-data-add-panel [data]="addData$ | async" [messages]="addPartyDataMessages$ | async"
    (clear)="onClearAddPartyData()" (confirm)="onConfirmAddPartyData($event)">
  </cms-party-data-add-panel>
  <section class="panel-box">
    <div class="">
      <p-messages [value]="messages$ | async" [closable]="true"></p-messages>
      <cms-datatable [data]="(data$ | async)?.values" [tableColumns]="dataTableColumns"
        [totalRecords]="(data$ | async)?.count" [sortField]="(sorting$ | async)?.columnName"
        [sortOrder]="(sorting$ | async)?.sortOrder" [currentPage]="(paging$ | async)?.page" [loading]="loading$ | async"
        [autoLayout]="true" [scrollable]="false" [lazy]="!!((data$ | async)?.values?.length)"
        (lazyLoad)="onLazyLoad($event)" selectionMode="single">
        <ng-template pTemplate="caption">
          <div class="row margin-0">
            <cms-input-text-filter [text]="filters$ | async" placeholder="must contain"
              (filter)="onTextFieldFilter($event)" class="col-6 margin-0 padding-0">
            </cms-input-text-filter>
          </div>
        </ng-template>
        <ng-template pTemplate="body" let-row="row" let-column="column" let-rowIndex="rowIndex">
          <td *ngIf="column.type === 'deleteButton'" [ngStyle]="column.style">
            <div class="btn-wrapper">
              <button *ngIf="!row.isInternalParty" class="btn btn-icon fa fa-trash-o" type="button" label="Delete"
                (click)="onDeleteItem(row)" [title]="column.title">
              </button>
            </div>
          </td>
          <td *ngIf="column.type === 'editable'" [ngClass]="{'editable-text': !row.isInternalParty}"
            [ngStyle]="column.style" cmsCellEditor>
            <span *ngIf="!!row.isInternalParty">{{ row[column.name] }}</span>
            <cms-cell-editor *ngIf="!row.isInternalParty"
                            [column]="column" [row]="row"
                            [value]="row[column.name]"
                            [editable]="true"
                            [messages]="messages$ | async"
                            (editComplete)="onCompleteEdit($event)"
                            (cancelEdit)="onCancelEdit($event)"
                            [maxValueLength]="column.maxLength">
            </cms-cell-editor>
          </td>
          <td *ngIf="column.type === 'yes'" [ngStyle]="column.style">
            <span>{{!!row[column.name] ? 'YES' : 'NO'}}</span>
          </td>
        </ng-template>
      </cms-datatable>
    </div>
  </section>
</section>
