import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';

import { StringUtils } from '../../../../common/shared/string.utils';
import { SmtDealFinderTransactionsService } from '../smt-dealfinder-transactions.service';
import { AbstractFormComponent } from './abstract-filter.component';

@Component({
  selector: 'cms-smt-dealfinder-uti-filter',
  templateUrl: './smt-dealfinder-uti-filter.component.html',
  styleUrls: ['./smt-dealfinder-uti-filter.component.scss']
})
export class SmtDealFinderUtiFilterComponent extends AbstractFormComponent implements OnInit {
  utiFilterFormGroup: UntypedFormGroup;

  constructor(private transactionsService: SmtDealFinderTransactionsService, private formBuilder: UntypedFormBuilder) {
    super();
  }

  public ngOnInit(): void {
    this.createForm();
  }

  private createForm(): void {
    this.utiFilterFormGroup = this.formBuilder.group({
      uti: [null, this.utisValidator()],
    });
  }

  public onSubmitUtiFilter() {
    this.setFormSubmitAttempt(true);
    if (this.isFormValid()) {
      const utis: string[] = StringUtils.tokenizeByWhitespace(this.getUtiFormControlValue());
      this.transactionsService.emitChangeUtiFilter(utis);
    }
  }

  public onResetUtiFilter() {
    this.resetForm();
    this.transactionsService.emitResetFilter();
  }

  public isUtisNotRuleConformError() {
    return this.isError('uti', 'utisNotRuleConform');
  }

  private getUtiFormControlValue(): any {
    return this.getFormControlValue('uti');
  }

  protected getFormGroup(): UntypedFormGroup {
    return this.utiFilterFormGroup;
  }

  private utisValidator(): ValidatorFn {

    const ruleConformValidator: ValidatorFn = (control: AbstractControl): { [key: string]: any } => {
      const utis = StringUtils.tokenizeByWhitespace(control.value);
      const utisNotRuleConform = utis.length > 50 || utis.find(uti => uti.length > 100) || utis[0] === '';
      return utisNotRuleConform ? { 'utisNotRuleConform': { value: control.value } } : null;
    };

    return Validators.compose([
      Validators.required,
      ruleConformValidator
    ]);

  }
}
