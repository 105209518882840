import { Injectable } from '@angular/core';
import { ApiRequestService } from '@common/api-request.service';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { saveBlob } from '@common/cms-common.model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Message } from 'primeng/api';
import { ProductDataResponse, ProductsBlockActivateResponse } from '../../../settings/ecm-settings-tab-panel/products/products.model';
import * as fromActions from './products.actions';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class ProductsEffects {
  private baseUrl = '/api/ecm/settings/products';

  constructor(private actions$: Actions,
              private apiGateway: ApiRequestService,
              private mapper: MessagesMapperService) { }

   loadProductData$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.LOAD_DATA),
      concatMap(() =>
        this.apiGateway.get(`${this.baseUrl}/loadProductData`)
          .pipe(
            switchMap((response: ProductDataResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [new fromActions.SetMessagesAction(messages)];
              }
              return [
                new fromActions.SetMessagesAction([]),
                new fromActions.LoadDataSuccessAction(response)
              ];
            }),
            catchError(() => of(new fromActions.SetMessagesAction([
              this.mapper.createErrorMessage('Server error')
            ])))
          )
      )));

   downloadProductsData$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.DOWNLOAD_PRODUCTS_DATA),
      concatMap(() =>
        this.apiGateway.getBlob(`${this.baseUrl}/download/xls`, null, null)
          .pipe(
            switchMap((response: any) => {
              saveBlob(response);
              return of(new fromActions.SetMessagesAction([]));
            }),
            catchError(() => of(new fromActions.SetMessagesAction([
              this.mapper.createErrorMessage('Server error')
            ])))
          )
      )));

   activateItem$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.CONFIRM_ACTIVATE),
      concatMap(({ payload }: fromActions.ConfirmActivateAction) =>
        this.apiGateway.post(`${this.baseUrl}/${payload}/activate`, null, new HttpParams().set('id', payload.toString()))
          .pipe(
            switchMap((response: ProductsBlockActivateResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [new fromActions.SetMessagesAction(messages)];
              }
              return [
                new fromActions.SetMessagesAction([]),
                new fromActions.LoadDataAction()
              ];

            }),
            catchError(() => of(new fromActions.SetMessagesAction([
              this.mapper.createErrorMessage('Server error')
            ])))
          )
      )));

   blockItem$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.CONFIRM_BLOCK),
      concatMap(({ payload }: fromActions.ConfirmBlockAction) =>
        this.apiGateway.post(`${this.baseUrl}/${payload}/block`, null, new HttpParams().set('id', payload.toString()))
          .pipe(
            switchMap((response: ProductsBlockActivateResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [new fromActions.SetMessagesAction(messages)];
              }
              return [
                new fromActions.SetMessagesAction([]),
                new fromActions.LoadDataAction()
              ];

            }),
            catchError(() => of(new fromActions.SetMessagesAction([
              this.mapper.createErrorMessage('Server error')
            ])))
          )
      )));
}
