import { createSelector } from '@ngrx/store';
import { getEsmState, State } from '../reducers';
import { EsmFileUploadState } from './file-upload.reducer';

export const getEsmFileUploadState = createSelector(getEsmState, (state: State) => state.fileUpload);
export const getData = createSelector(getEsmFileUploadState, (state: EsmFileUploadState) => state.data);
export const getLoading = createSelector(getEsmFileUploadState, (state: EsmFileUploadState) => state.loading);
export const getSorting = createSelector(getEsmFileUploadState, (state: EsmFileUploadState) => state.sorting);
export const getPaging = createSelector(getEsmFileUploadState, (state: EsmFileUploadState) => state.paging);
export const getMessages = createSelector(getEsmFileUploadState, (state: EsmFileUploadState) => state.messages);
export const getMaxUploadSize = createSelector(getEsmFileUploadState, (state: EsmFileUploadState) => state.maxUploadSizeInBytes);
export const getAllowedToUpload = createSelector(getEsmFileUploadState, (state: EsmFileUploadState) => state.allowedToUpload);
export const getFilters = createSelector(getEsmFileUploadState, (state: EsmFileUploadState) => state.filters);
export const getBusy = createSelector(getEsmFileUploadState, (state: EsmFileUploadState) => state.busy);


