import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { AuthenticationService } from '../auth/authentication.service';
import { map } from 'rxjs/operators';

@Injectable()
export class LoginGuard  {

  authenticated$: Observable<boolean>;

  constructor(private authService: AuthenticationService, private router: Router) {
    this.authenticated$ = this.authService.isAuthenticated();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>{

    return this.authenticated$.pipe(map(value =>{
      if (value && this.authService.forcePassword()) {
        this.router.navigate(['/change_password_immediately'], { queryParamsHandling: 'preserve' });
      } else if (value) {
        this.router.navigate(['/help/welcome'], { queryParamsHandling: 'preserve' });
      }

      return !value;
    }));

  }

}
