import { Component, OnDestroy, OnInit } from '@angular/core';
import { HelpService } from '../help.service';
import { AnnouncementResponseModel } from '../announcementResponseModel';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cms-release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.scss']
})
export class ReleaseNotesComponent implements OnInit, OnDestroy {
  releaseNotes: AnnouncementResponseModel = {content: '', title:'', editable: false};
  private onDestroy$ = new Subject();
  editMode: boolean = false;

  constructor(private helpService: HelpService) {
  }

  ngOnInit() {
    this.helpService.onReleaseNotesChange.pipe(
      takeUntil(this.onDestroy$))
      .subscribe(notification =>
        this.releaseNotes = notification
      );

    this.helpService.fetchReleaseNotes();
  }

  public ngOnDestroy(): void {
    // Clean up all subscriptions at once:
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
    this.editMode = false;
    this.releaseNotes.editable = false;
  }

  get releaseNotes$(): Observable<AnnouncementResponseModel>{
    return this.helpService.onReleaseNotesChange;
  }

  get isEditable(): Observable<boolean> {
    return of(this.releaseNotes.editable);
  }

  get isEditMode(): Observable<boolean> {
    return of(this.releaseNotes.editable && this.editMode);
  }

  get isDisplayMode(): Observable<boolean> {
    return of(!(this.releaseNotes.editable && this.editMode));
  }

  switchEditMode(): void {
    this.editMode = !this.editMode;
  }
}

