import { createAction, union } from '@ngrx/store';
import { EsmPdfAccessRights, EsmPdfSettings, EsmPdfSettingsFormModel } from '../../../../master-data/internal-master-data/internal-master-data.model';
import { EsmPdfSettingsState } from './pdf-settings.reducer';
import { Message } from 'primeng/api';

export const loadPdfSettingsAction = createAction('[ESM MASTER DATA] Load PDF Settings');
export const loadPdfSettingsSuccessAction = createAction('[ESM MASTER DATA] Load PDF Settings Success', (payload: EsmPdfSettingsFormModel) => ({payload}));

export const storePdfSettingsAction = createAction('[ESM MASTER DATA] Store PDF Settings', (payload: EsmPdfSettings) => ({payload}));
export const storePdfTemplateSettingsAction = createAction('[ESM MASTER DATA] Store PDF Template Settings', (payload: EsmPdfSettings) => ({payload}));
export const storePdfSettingsSuccessAction = createAction('[ESM MASTER DATA] Store PDF Settings Success', (payload: EsmPdfSettingsFormModel) => ({payload}));

export const createPdfSettingsAction = createAction('[ESM MASTER DATA] Create PDF Settings',(payload: EsmPdfSettings) => ({payload}));
export const createPdfSettingsSuccessAction = createAction('[ESM MASTER DATA] Create PDF Settings Success',(payload: EsmPdfSettingsFormModel) => ({payload}));

export const loadPdfPresetValueAction = createAction('[ESM MASTER DATA] Pdf Preset Values');
export const loadPdfPresetValuesSuccessAction = createAction('[ESM MASTER DATA] Pdf Preset Values Success', (payload: EsmPdfAccessRights) => ({payload}));

export const uploadPdfSettingsLogoAction = createAction('[ESM MASTER DATA] Upload PDF Settings Image', (payload: File) => ({payload}));
export const uploadPdfSettingsLogoSuccessAction = createAction('[ESM MASTER DATA] Upload PDF Settings Image Success', (payload: File) => ({payload}));

export const setPdfMessagesAction = createAction('[ESM MASTER DATA] Set Pdf Messages', (payload: Message[]) => ({ payload }));
export const resetPdfFormAction = createAction('[ESM MASTER DATA] Reset Pdf Messages', (payload: EsmPdfSettingsState) => ({ payload }));

const actions = union({
  setPdfMessagesAction,
  loadPdfSettingsAction,
  loadPdfSettingsSuccessAction,
  storePdfSettingsAction,
  storePdfSettingsSuccessAction,
  createPdfSettingsAction,
  loadPdfPresetValuesSuccessAction,
  uploadPdfSettingsLogoAction,
  uploadPdfSettingsLogoSuccessAction,
  resetPdfFormAction,
  storePdfTemplateSettingsAction
});

export type masterDataPdfSettingsActions = typeof actions;
