import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { OrganisationService } from '../../../common/organisation.service';
import { AuthenticationService } from '../../../auth/authentication.service';
import { Observable } from 'rxjs';
import {
  ColumnSettingsResponseModel,
  DatatableCustomizeSettingsDocumentType,
  LoadUsersOwningPersistedColumnSettingResponseModel,
  PersistColumnSettingsResponseModel
} from './datatable-customize-settings.model';
import { EndPointConstants } from '../../../common/end-point-constants';

@Injectable()
export class DatatableCustomizeSettingsService {

  constructor(private authService: AuthenticationService, private orgService: OrganisationService, private httpClient: HttpClient) {
  }

  public loadColumnSettings(userId: number, documentType: DatatableCustomizeSettingsDocumentType): Observable<ColumnSettingsResponseModel> {

    const organisationId = this.orgService.getCachedOrganisationId();

    let httpParams = new HttpParams();

    if (organisationId) {
      httpParams = httpParams.append(EndPointConstants.PARAM_ORGANISATION_ID, organisationId.toString());
    }

    if (userId > 0) {
      httpParams = httpParams.append('userId', userId.toString());
    }

    return this.httpClient.get<ColumnSettingsResponseModel>('api/customisation/columnsettings/' + documentType, {
      params: httpParams
    });
  }

  public loadDefaultColumnSettings(documentType: DatatableCustomizeSettingsDocumentType): Observable<ColumnSettingsResponseModel> {

    const organisationId = this.orgService.getCachedOrganisationId();

    let httpParams = new HttpParams();

    if (organisationId) {
      httpParams = httpParams.append(EndPointConstants.PARAM_ORGANISATION_ID, organisationId.toString());
    }

    return this.httpClient.get<ColumnSettingsResponseModel>('api/customisation/columnsettings/' + documentType + '/default', {
      params: httpParams
    });
  }

  public saveChanges(userId: number, documentType: DatatableCustomizeSettingsDocumentType, activeColumns: any): Observable<PersistColumnSettingsResponseModel> {

    const organisationId = this.orgService.getCachedOrganisationId();

    let httpParams = new HttpParams();

    if (organisationId) {
      httpParams = httpParams.append(EndPointConstants.PARAM_ORGANISATION_ID, organisationId.toString());
    }

    if (userId > 0) {
      httpParams = httpParams.append('userId', userId.toString());
    }

    return this.httpClient.post<PersistColumnSettingsResponseModel>('/api/customisation/columnsettings/' + documentType + '/persist', activeColumns, {
      params: httpParams
    });
  }

  public loadUsers(documentType: DatatableCustomizeSettingsDocumentType): Observable<LoadUsersOwningPersistedColumnSettingResponseModel> {

    const organisationId = this.orgService.getCachedOrganisationId();

    let httpParams = new HttpParams();

    if (organisationId) {
      httpParams = httpParams.append(EndPointConstants.PARAM_ORGANISATION_ID, organisationId.toString());
    }

    return this.httpClient.get<LoadUsersOwningPersistedColumnSettingResponseModel>('/api/customisation/columnsettings/' + documentType + '/users', {
      params: httpParams
    });
  }

}
