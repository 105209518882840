<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>

<h1>eSM Netting Statement Finder</h1>
<div class="panel-box margin-0">
  <div class="row">
    <p-messages [value]="messages$ | async" [closable]="true" class="col-12"></p-messages>
    <cms-not-found-ids-warning class="col-12"
                               [ids]="notFoundIds$ | async"
                               [allCount]="idsCount$ | async">
    </cms-not-found-ids-warning>
    <div class="col-12 col-xl-4 col-lg-6 col-md-12 cms-id-filters">
      <cms-id-filters [idFiltersLabels]="referenceSearchConfig"
                      [disabled]="loading$ | async"
                      (filter)="onReferenceSearch($event)"
                      (reset)="onResetByIds()">
      </cms-id-filters>
    </div>
    <div class="col-12 col-xl-8 col-lg-12 col-md-12 properties-filters">
      <cms-esm-netting-finder-filter
        [filter]="filter$ | async"
        [formValues]="formValues$ | async"
        [loading]="loading$ | async"
        (onFilter)="onFilter($event)"
        (onReset)="onReset()">
      </cms-esm-netting-finder-filter>
    </div>

  </div>
  <cms-datatable *ngIf="tableVisible$ | async"
                 [currentPage]="(paging$ | async).page"
                 [tableColumns]="columns$ | async"
                 [sortField]="(sorting$ | async).columnName"
                 [sortOrder]="(sorting$ | async).sortOrder"
                 [rows]="(paging$ | async)?.entriesPerPage"
                 [loading]="loading$ | async"
                 [data]="(data$ | async)?.values"
                 [totalRecords]="(data$ | async)?.count"
                 [autoLayout]="true"
                 [scrollable]="false"
                 [lazy]="true"
                 [lazyLoadOnInit]="false"
                 (lazyLoad)="onLazyLoad($event)"
                 [checkboxRow]="true"
                 [selected]="selected$ | async"
                 (selectedChange)="onSelected($event)">


    <ng-template pTemplate="caption">
      <div class="float-left p-1 !font-bold">Found netting statements</div>
      <div class="float-left date-label"></div>
      <cms-deal-table-caption (exportEvent)="onExport()" [exportDisable]="!(data$ | async)?.values"
                              [addNoteDisable]="!(selectedIds$ | async).length"
                              (openAddNoteDialog)="onOpenAddNoteDialog()"
                              (clickCustomize)="onClickCustomize()">
      </cms-deal-table-caption>
    </ng-template>
    <ng-template pTemplate="body" let-row="row" let-rowNum="rowIndex" let-column="column">
      <!--document button-->
      <!--<td *ngIf="column.type === 'button' && column.name === 'openDocument'" [ngStyle]="column.style">
        <div class="btn-wrapper inline-block">
          <a target="_blank"
             id="invoicefinder-html"
             routerLink="/esm/nettingfinder/esm_document"
             [queryParams]="{ id: row.id }">
            <button pButton type="button" icon="fa fa-file" title="Document"></button>
          </a>
        </div>
      </td>-->
      <!-- document (xml) button -->
      <td *ngIf="column.type === 'list' && column.name === 'deliveryPointArea'" [ngStyle]="column.style">
        <cms-esm-netting-finder-table-aggregation-keys-dropdown [name]="column.name" [width]="'160px'" [id]="row.id" [placeholder]="row.deliveryPointArea" [even]="rowNum % 2 == 0">

        </cms-esm-netting-finder-table-aggregation-keys-dropdown>
      </td>
      <td *ngIf="column.type === 'list' && column.name === 'physicalFinancial'" [ngStyle]="column.style">
        <cms-esm-netting-finder-table-aggregation-keys-dropdown [name]="column.name" [width]="'120px'" [id]="row.id" [placeholder]="singleNttType||row.physicalFinancial" [even]="rowNum % 2 == 0">

        </cms-esm-netting-finder-table-aggregation-keys-dropdown>
      </td>
      <td *ngIf="column.type === 'list' && column.name === 'commodity'" [ngStyle]="column.style">
        <cms-esm-netting-finder-table-aggregation-keys-dropdown [width]="'120px'" [name]="column.name" [id]="row.id" [placeholder]="singleCommodity||row.commodity" [even]="rowNum % 2 == 0">

        </cms-esm-netting-finder-table-aggregation-keys-dropdown>
      </td>
      <td *ngIf="column.type === 'list' && column.name === 'agreementDetails'" [ngStyle]="column.style">
        <cms-esm-netting-finder-table-aggregation-keys-dropdown [name]="column.name" [width]="'140px'" [id]="row.id" [placeholder]="row.agreementDetails" [even]="rowNum % 2 == 0">

        </cms-esm-netting-finder-table-aggregation-keys-dropdown>
      </td>
      <td *ngIf="column.type === 'list' && column.name === 'currency'" [ngStyle]="column.style">
        <cms-esm-netting-finder-table-aggregation-keys-dropdown [name]="column.name" [width]="'100px'" [id]="row.id" [placeholder]="row.currency" [even]="rowNum % 2 == 0">

        </cms-esm-netting-finder-table-aggregation-keys-dropdown>
      </td>
      <td *ngIf="column.type === 'list' && column.name === 'fixedFloating'" [ngStyle]="column.style">
        <cms-esm-netting-finder-table-aggregation-keys-dropdown [name]="column.name" [id]="row.id" [placeholder]="row.fixedFloating" [even]="rowNum % 2 == 0">

        </cms-esm-netting-finder-table-aggregation-keys-dropdown>
      </td>
      <td *ngIf="column.type === 'list' && column.name === 'natureOfPrice'" [ngStyle]="column.style">
        <cms-esm-netting-finder-table-aggregation-keys-dropdown [name]="column.name" [width]="'120px'" [id]="row.id" [placeholder]="row.natureOfPrice" [even]="rowNum % 2 == 0">

        </cms-esm-netting-finder-table-aggregation-keys-dropdown>
      </td>
      <td *ngIf="column.type === 'list' && column.name === 'marketInformation'" [ngStyle]="column.style">
        <cms-esm-netting-finder-table-aggregation-keys-dropdown [name]="column.name" [id]="row.id" [placeholder]="row.marketInformation" [even]="rowNum % 2 == 0">

        </cms-esm-netting-finder-table-aggregation-keys-dropdown>
      </td>
      <td *ngIf="column.type === 'list' && column.name === 'totalVolumeUnit'" [ngStyle]="column.style">
        <cms-esm-netting-finder-table-aggregation-keys-dropdown [name]="column.name" [id]="row.id" [placeholder]="row.totalVolumeUnit" [even]="rowNum % 2 == 0">

        </cms-esm-netting-finder-table-aggregation-keys-dropdown>
      </td>
      <!-- document (xml) button -->
      <td *ngIf="column.type === 'button' && column.name === 'openDocumentXml'" [ngStyle]="column.style">
        <div class="btn-wrapper inline-block">
          <button (click)="onDownloadXml(row)" icon="fa fa-file-code-o" pButton title="Document (XML)" type="button"></button>
        </div>
      </td>
      <!-- netted (pdf) button -->
      <td *ngIf="column.type === 'button' && column.name === 'nettingPdf'" [ngStyle]="column.style">
        <div class="btn-wrapper inline-block">
          <button (click)="onDownloadPdf(row)" icon="fa fa-file-pdf-o" pButton title="Document (PDF)" type="button"></button>
        </div>
      </td>
      <!--audit trail button-->
      <td *ngIf="column.type === 'button' && column.name === 'auditTrail'" [ngStyle]="column.style">
        <div class="btn-wrapper inline-block">
          <a routerLink="/esm/audittrail/netting/" title="Audit Trail" [queryParams]="getDisplayParams(row)" target="_blank">
            <button pButton type="button" icon="fa fa-search" title="Audit Trail"></button>
          </a>
        </div>
      </td>
      <!--near match button-->
      <td *ngIf="column.type === 'button' && column.name === 'nearMatch'">
        <cms-esm-near-match-button class="comp-match-cell" [row]="row" route="/esm/match/netting"></cms-esm-near-match-button>
      </td>
      <!--mismatch button-->
      <td *ngIf="column.type === 'button' && column.name === 'misMatch'">
        <cms-esm-mis-match-button class="comp-match-cell" [row]="row" route="/esm/compare/netting"></cms-esm-mis-match-button>
      </td>
      <!--note button-->
      <td *ngIf="column.type === 'button' && column.name === 'notes'" [ngStyle]="column.style">
        <cms-notes-cell [confirmationId]="row.id"
                        [data]="row"
                        [notes]="row.notes"
                        (view)="onViewNotes($event)">
        </cms-notes-cell>
      </td>
    </ng-template>
    <ng-template pTemplate="customEmptyMessage">
      <tr>
        <td colspan="100" *ngIf="!(loading$ | async)">
          No records found
        </td>
      </tr>
    </ng-template>
  </cms-datatable>
</div>
<section>
  <cms-add-note-dialog *ngIf="(dialog$ | async)?.name === 'ADD_NOTES'"
                       [display]="(dialog$ | async)?.name === 'ADD_NOTES'"
                       [messages]="(dialog$ | async)?.messages"
                       [headerText]="'Add note to netting statements'"
                       [confirmationIds]="selectedIds$ | async"
                       (cancel)="onCancelNotes()"
                       (save)="onConfirmAddNotes($event)">

  </cms-add-note-dialog>
  <cms-view-confirmation-notes-dialog *ngIf="(dialog$ | async)?.name === 'VIEW_NOTES'"
                                      [display]="(dialog$ | async)?.name === 'VIEW_NOTES'"
                                      [messages]="(dialog$ | async)?.messages"
                                      [data]="(dialog$ | async)?.data"
                                      [headerText]="'View netting statement notes'"
                                      (cancel)="onCancelNotes()"
                                      (save)="onConfirmAddNotes($event)">

  </cms-view-confirmation-notes-dialog>
</section>

</ng-template>
