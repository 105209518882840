import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { BillingRunParams } from '../billing-runs.model';

@Component({
  selector: 'cms-billing-params',
  templateUrl: './billing-params.component.html',
  styleUrls: ['./billing-params.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillingParamsComponent {
  @Input() data: BillingRunParams;
  @Output() handleClick = new EventEmitter<BillingRunParams>();

  constructor() { }

  onClick(): void {
    this.handleClick.emit(this.data);
  }
}
