<div style="max-width: 585px;" class="other-counterparty">
  <div class="form-title">Trade | Other Counterparty</div>
  <div class="form-body " [formGroup]="formGroup">
    <div class="row gutter-0">
      <div class="col-5 label">Report both sides</div>
      <div class="col-7 ps-2 !pe-[42px]"><input type="checkbox" class="margin-3" id="isReportBothSides" formControlName="reportBothSides" (change)="onReportBoth($event);" style="vertical-align: bottom;" /></div>
    </div>
    <div class="row gutter-0">
      <div class="col-5 label">Other Market Participant <span class="text-required">*</span></div>
      <div id="remit-trade-entry-otherMarketParicipant-reportBoth" class="col-7 ps-2" *ngIf="reportBoth">
        <p-dropdown [options]="otherMarketParticipants" [filter]="true" [filterFields]="['label']" formControlName="otherMarketParticipantCode" [style]="{'width': '100%'}" [styleClass]="'margin-3 ' + getValidationErrorStyleClass('otherMarketParticipantCode')"></p-dropdown>
      </div>
      <div id="remit-trade-entry-otherMarketParicipant-BeneficiaryType" class="col-2 ps-2 " *ngIf="!reportBoth">
        <p-dropdown
          [options]="otherBeneficiaryType"
          formControlName="otherMarketParticipantCodeType"
          [styleClass]="'margin-3 ' + getValidationErrorStyleClass('otherMarketParticipantCodeType')">
        </p-dropdown>
      </div>
      <div id="remit-trade-entry-otherMarketParicipant" class="col-5 ps-2 !pe-[42px]" *ngIf="!reportBoth">
        <input id="remit-trade-entry-otherMarketParticipantInput" type="text" class="form-control padding-6 text-medium margin-3" formControlName="otherMarketParticipantCode" [maxLength]="20" [ngClass]="{'error':formGroup.controls['otherMarketParticipantCode'].hasError('error')}">
      </div>
      <span *ngIf="formGroup.controls['otherMarketParticipantCode'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0" *ngIf="reportBoth">
      <div class="col-5 label">Trader ID (for other MP) <span class="text-required" *ngIf="!execution">*</span></div>
      <div class="col-7 ps-2 !pe-[42px]">
        <input id="remit-trade-entry-traderIdForOtherMarketParticipant" type="text" class="form-control p-component padding-6 text-medium margin-3" formControlName="traderIdForOtherMarketParticipant" maxlength="100" [ngClass]="{'error':formGroup.controls['traderIdForOtherMarketParticipant'].hasError('error')}">
      </div>
      <span *ngIf="formGroup.controls['traderIdForOtherMarketParticipant'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0" *ngIf="reportBoth">
      <div class="col-5 label">Beneficiary (for other MP)</div>
      <div class="col-2 ps-2">
        <p-dropdown [options]="otherBeneficiaryType" formControlName="beneficiaryCodeTypeForOtherMarketParticipant"  [styleClass]="'margin-3 ' + getValidationErrorStyleClass('beneficiaryCodeTypeForOtherMarketParticipant')"></p-dropdown>
      </div>
      <div class="col-5 !pe-[42px]">
        <input id="remit-trade-entry-benficiaryForOtherMp" type="text" class="form-control padding-6 text-medium margin-3" formControlName="beneficiaryCodeForOtherMarketParticipant" [ngClass]="{'error':formGroup.controls['beneficiaryCodeForOtherMarketParticipant'].hasError('error')}">
      </div>
      <span *ngIf="formGroup.controls['beneficiaryCodeForOtherMarketParticipant'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>

  </div>
</div>
