import { createSelector } from '@ngrx/store';
import { SubscriptionSettingsState } from './subscription-settings.reducers';
import { EsmSettingsState } from '../settings.reducers';
import { getEsmSettingsState } from '../settings.selectors';

export const getSubscriptionState  = createSelector(getEsmSettingsState, (state: EsmSettingsState) => state.subscription);

//
export const getMessages = createSelector(getSubscriptionState, (state: SubscriptionSettingsState) => state.messages);
export const getMatchedInvoicePdf = createSelector(getSubscriptionState, (state: SubscriptionSettingsState) => state.matchedInvoicePdf);
export const getMatchedNettingPdf = createSelector(getSubscriptionState, (state: SubscriptionSettingsState) => state.matchedNettingStatementPdf);
export const getMatchedInvoiceXml = createSelector(getSubscriptionState, (state: SubscriptionSettingsState) => state.matchedInvoiceXml);
export const getMatchedNettingXml = createSelector(getSubscriptionState, (state: SubscriptionSettingsState) => state.matchedNettingStatementXml);
export const getDocumentType = createSelector(getSubscriptionState, (state: SubscriptionSettingsState) => state.documentType);
export const getDocumentSide = createSelector(getSubscriptionState, (state: SubscriptionSettingsState) => state.documentSide);
export const getEditAllowed = createSelector(getSubscriptionState, (state: SubscriptionSettingsState) => state.editAllowed);
export const getTableData = createSelector(getSubscriptionState, (state: SubscriptionSettingsState) =>
  state.esmEmailWhitelistRecipients == null ? null : state.esmEmailWhitelistRecipients.map((item) => Object.assign({}, item))
);
