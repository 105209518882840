import { Component, OnDestroy} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { InvoiceDivEntry, overviewTableColumns} from './netting-table-overview.model';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { NettingPreviewState } from '../../state/netting-preview';
import * as fromTableSelectors from '../../../esm/state/netting-preview/netting-table/netting-table.selectors';
import * as CpSelectors from '../../state/netting-preview/counterparty/counterparty.selectors';
import * as OverviewActions from '../../state/netting-preview/invoice-overview/invoice-overview.actions';
import {filter, first, tap} from 'rxjs/operators';
import {
  cleanState,
  loadTable,
  selectInvoice,
  setPagingAndSorting,
  withDiscrepancies
} from '../../state/netting-preview/netting-table/netting-table.actions';

@Component({
  selector: 'cms-netting-table-overview',
  templateUrl: './netting-table-overview.component.html',
  styleUrls: ['./netting-table-overview.component.scss']
})
export class NettingTableOverviewComponent implements OnDestroy{
  selectable: string;
  selected: InvoiceDivEntry;
  columns = overviewTableColumns;
  init = false;
  data$: Observable<DatatableData<InvoiceDivEntry[]>> = this.store.pipe(select(fromTableSelectors.getData), tap(() => this.selected = null));
  paging$: Observable<PagingFilter> = this.store.pipe(select(fromTableSelectors.getPaging));
  sorting$: Observable<SortingFilter> = this.store.pipe(select(fromTableSelectors.getSorting));
  loading$: Observable<boolean> = this.store.pipe(select(fromTableSelectors.getLoading));
  selectable$: Observable<any> = this.store.pipe(select(fromTableSelectors.getSelectable), tap(x => this.selectable = x));
  selected$: Observable<number[]> = this.store.pipe(select(CpSelectors.getSelected));
  subscription$: Subscription = new Subscription();

  refreshTable = () => {
    this.selected = null;
    this.store.dispatch(loadTable());
  }

  constructor(private store: Store<NettingPreviewState>) {

    this.data$.pipe(filter(({count}) => Boolean(count)), tap(() => this.init = true), first())
    .subscribe();

    this.subscription$.add(this.selected$.subscribe(() => this.store.dispatch(OverviewActions.cleanState())));

}

  filter(selected: boolean) {
    this.store.dispatch(withDiscrepancies({ selected }));
    this.refreshTable();
  }


  onLazyLoad({ page, perPage, sortField, sortOrder }): void {

    this.store.dispatch(setPagingAndSorting({
      paging: {
        page,
        entriesPerPage: perPage
      },
      sorting: {
        sortOrder,
        columnName: sortField
      }
    }));
    this.refreshTable();
  }

  switchPreview(selected: InvoiceDivEntry) {
    if (this.selectable) {
      this.selected = selected;
      this.store.dispatch(selectInvoice({ selected }));
    }
  }

  setColor = (selected, { myNetting, cpNetting }, { style }, index) => {

    if (!this.init){
      return style;

    } else if (selected) {
      return { ...style, 'background-color': '#0e628b' };

    } else {

      return Math.abs(myNetting) - Math.abs(cpNetting) === 0 ?
        { ...style, 'background-color': '#add9ad' } :
        { ...style, 'background-color': index % 2 === 0 ? '#FFFFFF' : '#FAFAFB' };
    }
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
    this.store.dispatch(cleanState());
  }

}

