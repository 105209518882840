<cms-info ngPreserveWhitespaces>
  <!--@formatter:off (markdown rendering breaks, when the following tags are formatted)-->
  <u>**eRR Billing Details Report**</u>

  Select a time period (a full month or shorter) and click on **eRR Billing Details**.

  The report contains all billable eRR transactions that the CMS registered for your organisations group within the selected time period.

  Important note: it will only include legal entities to which you have OP (operator) or MO (master operator) access.

  Find more detailed field-by-field information within the report.
  <!--@formatter:on-->
</cms-info>
<div id="div-remit-omp-whitelist-feedback-message">
  <p-messages [(value)]="messages" [closable]="false"></p-messages>
</div>
<div style="max-width: 500px">
  <div class="panel-box">
    <form [formGroup]="filterForm" class="form-horizontal" (ngSubmit)="onSubmit()">
            <span class="padding-right-21">
                <label>Start Date: <span class="text-required">*</span></label>
                <p-calendar
                  placeholder="Start Date"
                  formControlName="startDate"
                  [ngClass]="displayFieldClass('startDate')"
                  [showIcon]="true"
                  [monthNavigator]="true"
                  [yearNavigator]="true"
                  dateFormat="dd/mm/yy"
                  [style]="{ 'width': '125px'}"
                  cmsDateRestriction>
                </p-calendar>
            </span>

      <span>
                <label>End Date: <span class="text-required">*</span></label>
                <p-calendar
                  placeholder="End Date"
                  formControlName="endDate"
                  [ngClass]="displayFieldClass('endDate')"
                  [showIcon]="true"
                  [monthNavigator]="true"
                  [yearNavigator]="true"
                  dateFormat="dd/mm/yy"
                  [style]="{ 'width': '125px'}"
                  cmsDateRestriction>
                </p-calendar>
            </span>

      <button id='btn-submit-remit-billing-report' class="w-init btn margin-top-9" type="submit" [disabled]="isDownloading() | async">
        eRR Billing Details
        <p-progressSpinner class="margin-top-9" *ngIf="isDownloading() | async" [style]="{width: '15px', height: '15px'}" strokeWidth="8"></p-progressSpinner>
      </button>
    </form>
  </div>

</div>
