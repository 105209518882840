import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ErrMifidDetailsFormValues, ErrMifidDetailsItem, ErrMifidFile } from '../mifid.model';
import { Message } from 'primeng/api';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'cms-err-mifid-details-view',
  templateUrl: './mifid-details-view.component.html',
  styleUrls: ['./mifid-details-view.component.scss']
})
export class MifidDetailsViewComponent implements OnChanges {
  @Input() display = false;
  @Input() data: ErrMifidDetailsItem;
  @Input() messages: Message[];
  @Input() formValues: ErrMifidDetailsFormValues;
  @Input() isFCAPlus: boolean = false;
  @Output() exit = new EventEmitter<Message[]>();
  @Output() save = new EventEmitter<any>();

  errors: { [key: string]: ValidationErrors } = {};
  form: UntypedFormGroup;
  isEditView = false;
  clientOptions = [
    { label: '', value: null },
    { label: 'TRUE', value: true },
    { label: 'FALSE', value: false }];
  positionUOMCodesOptions: { label: string, value: string }[] = [];
  positionTypesOptions: { label: string, value: string }[] = [];
  positionMaturityOptions: { label: string, value: string }[] = [];
  positionHolderCategoryOptions: { label: string, value: string }[] = [];
  header: string = 'Add Position';

  constructor(private store: Store<ErrMifidFile>,
              private formBuilder: UntypedFormBuilder) {
    this.form = this.formBuilder.group({
      clientHedgingExemption: '',
      clientNonInvestFirm: '',
      editable: '',
      id: '',
      isin: '',
      parentEntity: '',
      parentPosHolderEmail: '',
      posAccount: '',
      posDelta: '',
      posHolder: '',
      posHolderCategory: '',
      posHolderEmail: '',
      posHolderIndependent: false,
      posMaturity: '',
      posQuantity: '',
      posRepFileId: '',
      posType: '',
      posUOM: '',
      posUomDetails: '',
      positionAction: '',
      positionState: '',
      refNumber: '',
      reportingDate: '',
      reportingEntity: '',
      reportingEntityMemberId: '',
      riskReducingIndicator: false,
      tradeDate: '',
      venueId: '',
      venueProductCode: '',
    });
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (this.form && changes.data && changes.data.currentValue) {
      this.isEditView = true;
      this.form.patchValue({ ...this.data });
      this.header = this.data ? `Edit Position Ref. No. ${this.data.refNumber}` : 'Edit Position Ref. No.';
    }
    if (changes.formValues && changes.formValues.currentValue) {
      if (this.formValues.positionUOMCodes) {
        this.positionUOMCodesOptions = this.formValues.positionUOMCodes.map(item => ({ label: item, value: item }));
      }
      if (this.formValues.positionTypes) {
        this.positionTypesOptions = this.formValues.positionTypes.map(item => ({ label: item, value: item }));
      }
      if (this.formValues.positionMaturity) {
        this.positionMaturityOptions = this.formValues.positionMaturity.map(item => ({ label: item, value: item }));
      }
      if (this.formValues.positionHolderCategories) {
        this.positionHolderCategoryOptions = this.formValues.positionHolderCategories.map(item =>
          ({ label: item ? item.value : '', value: item ? item.key : '' }));
      }
    }
    if (changes.messages) {
      this.errors = {};
      if (this.messages) {
        this.messages.forEach((obj: Message) => {
          const { key: controlName } = obj;
          this.errors[controlName] = { 'server': true };
        });
      }
    }
  }

  onCancel(): void {
    this.display = false;
    this.exit.emit();
  }

  onSave() {
    this.save.emit({ isEditView: this.isEditView, item: this.form.getRawValue() });
  }

  onVisibleChange(visible: boolean): void {
    if (!visible) {
      this.onCancel();
    }
  }
}
