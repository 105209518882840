import { BehaviorSubject, Observable, ReplaySubject, throwError as observableThrowError } from 'rxjs';
import { EventEmitter, Injectable } from '@angular/core';

import { AuthenticationService } from '../../../auth/authentication.service';
import { DateUtils } from '../../../common/shared/date.utils';
import { DateRangeFilter } from '../../../common/shared/shared.model';
import { PagingFilter, SortingFilter } from '../../../common/shared/results.model';

import {
  SmtTransactionDateFilter,
  smtTransactionDayAsRange,
  SmtTransactionsSearchRequestModel,
  SmtUtiFilter,
  StmTransactionDashboardFilterParameters,
  StmTransactionSearchQueryBuilder
} from '../smt-filter-model';
import { SmtDealFinderDataTableModel } from '../smt-datatable.model';
import { OrganisationService } from '../../../common/organisation.service';
import { TransactionSheetResponseModel } from '../../../common/sheet/transaction-sheet-model';
import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { EndPointConstants } from '../../../common/end-point-constants';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class SmtDealFinderTransactionsService {

  private apiUrl = '/api/err/smt/transactions';
  private apiUrlTransactionsReport = '/api/err/smt/transactions/report';

  private transactionDateFilterSubject = new ReplaySubject<SmtTransactionDateFilter>(1);
  private resetSubject = new EventEmitter();
  private utiFilterSubject = new EventEmitter<SmtUtiFilter>();
  private dashboardFilterSubject = new EventEmitter<StmTransactionDashboardFilterParameters>();
  private changeSearchQuerySubject = new BehaviorSubject<SmtTransactionsSearchRequestModel>(null);

  constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService, private organisationService: OrganisationService) {
  }

  public emitResetFilter() {
    this.changeSearchQuerySubject.next(null);
    this.resetSubject.emit();
  }

  public getResetStatusChangeObservable() {
    return this.resetSubject.asObservable();
  }

  public emitChangeTransactionDateFilter(transactionDateFilter: DateRangeFilter) {
    this.transactionDateFilterSubject.next(transactionDateFilter);
    this.updateSearchQueryByTransactionDateFilter(transactionDateFilter);
  }

  public emitChangeUtiFilter(utis: SmtUtiFilter) {
    this.utiFilterSubject.emit(utis);
    this.updateSearchQueryByUtiFilter(utis);
  }

  public updateSearchQueryByPagingAndSortingFilter(pagingFilter: PagingFilter, sortingFilter: SortingFilter) {
    const nextSearchQuery = this.buildNextSearchQuery({ paging: pagingFilter, sorting: sortingFilter });
    this.changeSearchQuerySubject.next(nextSearchQuery);
  }

  public updateSearchQueryByUtiFilter(utiFilter: SmtUtiFilter) {
    const nextSearchQuery = this.buildNextSearchQuery({
      utis: utiFilter,
      transactionDates: undefined
    });
    this.changeSearchQuerySubject.next(nextSearchQuery);
  }

  public updateSearchQueryByTransactionDateFilter(transactionDateFilter: SmtTransactionDateFilter) {
    const nextSearchQuery = this.buildNextSearchQuery({ transactionDates: transactionDateFilter, utis: undefined });
    this.changeSearchQuerySubject.next(nextSearchQuery);
  }

  public updateSearchQueryByDashboardFilters({ regime, reporterId, status, day }: StmTransactionDashboardFilterParameters) {
    if (regime || reporterId || status || day) {
      const nextSearchQuery = this.buildNextSearchQuery({
        regime: regime,
        reporterId: reporterId,
        status: status
      });

      if (day) {
        const transactionDates = DateRangeFilter.of(DateUtils.calcDateRangeWithDaysAgo(smtTransactionDayAsRange(day)));
        nextSearchQuery.transactionDates = transactionDates;
        this.changeSearchQuerySubject.next(nextSearchQuery);
        this.transactionDateFilterSubject.next(transactionDates);
      } else {
        this.changeSearchQuerySubject.next(nextSearchQuery);
      }
    }
  }

  public getSearchQueryChangeObservable(): Observable<SmtTransactionsSearchRequestModel> {
    return this.changeSearchQuerySubject.asObservable();
  }

  public fetchTransactions(searchQuery: SmtTransactionsSearchRequestModel): Observable<SmtDealFinderDataTableModel> {


    let params = new HttpParams();
    const orgId = this.organisationService.getCachedOrganisationId();
    if (orgId) {
      params = params.append(EndPointConstants.PARAM_ORGANISATION_ID, orgId.toString());
    }
    const options = { params: params };

    return this.httpClient.post<SmtDealFinderDataTableModel>(this.apiUrl, searchQuery, options).pipe(
      catchError(this.handleError));
  }

  public downloadTransactionsReport(searchQuery?: SmtTransactionsSearchRequestModel): Observable<{ filename: string, blob: Blob }> {

    const nextSearchQuery = searchQuery || this.changeSearchQuerySubject.getValue();


    let params = new HttpParams();
    const orgId = this.organisationService.getCachedOrganisationId();
    if (orgId) {
      params = params.append(EndPointConstants.PARAM_ORGANISATION_ID, orgId.toString());
    }
    const options = {  observe: 'response' as 'body', params: params, responseType: 'blob' as 'json' };

    const body = nextSearchQuery;
    return this.httpClient.post(this.apiUrlTransactionsReport, body, options).pipe(
      map((response: HttpResponse<Blob>) => {
        const filename = this.getFilenameFromHeader(response.headers);
        const blob: Blob = response.body;
        return { filename, blob };
      }), catchError(this.handleError));
  }

  public downloadTransactionReport(regime: string, dataStoreId: number): Observable<{ filename: string, blob: Blob }> {


    let params = new HttpParams();
    if (this.organisationService.getCachedOrganisationId()) {
      params = params.append(EndPointConstants.PARAM_ORGANISATION_ID, this.organisationService.getCachedOrganisationId().toString());
    }
    const options = {observe: 'response' as 'body', params: params, responseType: 'blob' as 'json' };

    return this.httpClient.get(`/api/err/smt/transactions/${regime}/${dataStoreId}/xml`, options).pipe(
      map((response: HttpResponse<Blob>) => {
        const filename = this.getFilenameFromHeader(response.headers);
        const blob: Blob = response.body;
        return { filename, blob };
      }), catchError(this.handleError));
  }

  public getTransactionSheet(regime: string, recordType: string, documentType: string, historyId: number): Observable<TransactionSheetResponseModel> {

    let params = new HttpParams();
    if (this.organisationService.getCachedOrganisationId()) {
      params = params.append(EndPointConstants.PARAM_ORGANISATION_ID, this.organisationService.getCachedOrganisationId().toString());
    }
    params = params.append(EndPointConstants.PARAM_REGIME, regime);
    if (recordType) {
      params = params.append(EndPointConstants.PARAM_RECORD_TYPE, recordType);
    }
    params = params.append(EndPointConstants.PARAM_DOCUMENT_TYPE, documentType);
    params = params.append(EndPointConstants.PARAM_HISTORY_ID, String(historyId));

    const options = {params: params };
    return this.httpClient.get<TransactionSheetResponseModel>(`/api/err/smt/transactions/sheet`, options).pipe(catchError(this.handleError));
  }

  private getFilenameFromHeader(headers): string {
    const contentDisposition = headers.get('content-disposition');
    return contentDisposition.split(';')[1].trim().split('=')[1];
  }

  private buildNextSearchQuery(subQuery: any): SmtTransactionsSearchRequestModel {
    return Object.assign({},
      StmTransactionSearchQueryBuilder.createEmptySearchQuery(),
      this.changeSearchQuerySubject.getValue(),
      subQuery
    );
  }

  private handleError(error: HttpErrorResponse) {
    const errMsg = (error.message) ? error.message : 'Server error';
    return observableThrowError(errMsg);
  }
}
