import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { DashboardDealsNavModel } from '@common/dashboard-deals-nav/dashboard-deals-nav.model';
import * as fromTabSelectors from '../../state/dashboard/tab/tab.selectors';
import * as fromCategorySelectors from '../../state/dashboard/category/category.selectors';
import {
  EcmDashboardCategoriesToLabelMap,
  EcmDashboardDealItemModel,
  EcmDateRange,
  EcmDealItemModel,
  EcmStatesEnum
} from '../ecm-dashboard.model';
import { DatatableState } from '@common/datatable/datatable.model';
import * as fromDealsActions from '../../state/dashboard/deals/deals.actions';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import * as fromDealsSelectors from '../../state/dashboard/deals/deals.selectors';
import * as fromFilterSelectors from '../../state/dashboard/filters/filters.selectors';
import { Message } from 'primeng/api';
import { Column } from '@common/shared/datatable.model';
import { AddNote, HideConfirmation, NotesCellViewEvent, UnhideConfirmation } from '@common/deals/deals.model';
import { OrganisationService } from '@common/organisation.service';
import { EcmDealFinder } from '../../deal-finder/deal-finder.model';
import * as fromFilterActions from '../../state/dashboard/filters/filters.actions';
import { filter, skip } from 'rxjs/operators';


@Component({
  selector: 'cms-ecm-dashboard-deals-tab',
  templateUrl: './ecm-dashboard-deals-tab.component.html',
  styleUrls: ['./ecm-dashboard-deals-tab.component.scss']
})
export class EcmDashboardDealsTabComponent implements OnInit, OnDestroy {
  readonly categoriesMap = EcmDashboardCategoriesToLabelMap;
  state: EcmStatesEnum;
  dateRange: EcmDateRange;
  navValues$ = this.store.pipe(select(fromTabSelectors.getNavValues));
  categoryLabel: string;
  data$: Observable<DatatableData<EcmDealItemModel[]>> = this.store.pipe(select(fromDealsSelectors.getData));
  loading$: Observable<boolean> = this.store.pipe(select(fromDealsSelectors.getLoading));
  loaded$: Observable<boolean> = this.store.pipe(select(fromFilterSelectors.getLoaded));
  sorting$: Observable<SortingFilter> = this.store.pipe(select(fromDealsSelectors.getSorting));
  messages$: Observable<Message[]> = this.store.pipe(select(fromDealsSelectors.getMessages));
  paging$: Observable<PagingFilter> = this.store.pipe(select(fromDealsSelectors.getPaging));
  columns$: Observable<Column[]> = this.store.pipe(select(fromDealsSelectors.getColumns));
  confirmationIds: Observable<string[]> = this.store.pipe(select(fromDealsSelectors.getConfirmationIds));
  selected$ = this.store.pipe(select(fromDealsSelectors.getSelected));
  dialog$ = this.store.pipe(select(fromDealsSelectors.getDialog));
  dateRangeText$ = this.store.pipe(select(fromDealsSelectors.getDateRangeText));
  editRights$: Observable<boolean> = this.store.pipe(select(fromDealsSelectors.getDealEditRights));
  noteRights$: Observable<boolean> = this.store.pipe(select(fromDealsSelectors.getDealNoteRights));
  subscriptions: Subscription = new Subscription();
  typeOfRow: string;
  tradeIdOfRow: string;

  private selectedDeals: EcmDealFinder[] = [];

  constructor(private router: Router,
              private route: ActivatedRoute,
              private store: Store<any>,
              private organisationService: OrganisationService) { }

  ngOnInit() {
    this.subscriptions.add(this.route.queryParams.subscribe(({ state, dateRange }) => {
      if (!(state && dateRange)) { this.backToDashboard();}
      this.state = state;
      this.dateRange = dateRange;
      this.store.dispatch(new fromDealsActions.SetParamsAction({ state, dateRange }));
      this.store.dispatch(new fromDealsActions.LoadNavStates());
      this.store.dispatch(new fromDealsActions.LoadDataAction());
      this.store.dispatch(new fromDealsActions.LoadPresets());
    }));

    this.subscriptions.add(this.store.pipe(select(fromCategorySelectors.getCategory))
      .subscribe(category => this.categoryLabel = this.categoriesMap[category]));

    this.subscriptions.add(this.organisationService.getOrganisationChangeIfAccessible()
      .subscribe(() => {
        this.store.dispatch(new fromDealsActions.LoadNavStates());
      }));

    this.subscriptions.add(this.data$.subscribe(data => {
      if(data.values && this.selectedDeals){
        const ids = this.selectedDeals.map(s => s.id);
        const selected: EcmDealFinder[] = data.values.filter(d => ids.indexOf(d.id) !== -1 );
        this.onSelected(selected);
      }
      })
    );

    this.subscriptions.add(this.organisationService.onRefresh().pipe(skip(1),
      filter(auth=> auth && this.organisationService.getCurrentOrganisationId()!==null))
      .subscribe(() => this.store.dispatch(new fromFilterActions.GetFiltersValuesAction())));
  }

  ngOnDestroy(): void {
    this.store.dispatch(new fromDealsActions.SetToDefaultAction());
    this.subscriptions.unsubscribe();
    this.selectedDeals = [];
  }

  backToDashboard() {
    this.router.navigate([`/ecm/dashboard/${this.categoryLabel}`]);
  }

  changeState(item: DashboardDealsNavModel) {
    this.router.navigate([`/ecm/dashboard/${this.categoryLabel}/deals`],
      { queryParams: { state: item.state, dateRange: this.dateRange } });
  }

  onLazyLoad({page, perPage, sortField, sortOrder}: DatatableState): void {
    this.store.dispatch(new fromDealsActions.SortAndPageDataAction({
      paging: {
        page,
        entriesPerPage: perPage
      },
      sorting: {
        columnName: sortField,
        sortOrder
      }
    }));
  }

  openTerminateDialog(): void {
    this.store.dispatch(new fromDealsActions.OpenDialogAction(
      {
        name: 'TERMINATE_DEAL',
        data: {
          confirmationIds: this.selectedDeals.map(d => d.id)
        }
      }));
  }

  onOpenAddNoteDialog(): void {
    this.store.dispatch(new fromDealsActions.OpenDialogAction({
      name: 'ADD_NOTES',
      messages: [],
    }));
  }

  onExport(): void {
    this.store.dispatch(new fromDealsActions.ExportAction());
  }

  onClickCustomize(): void {
    this.router.navigate(['/settings/datatable/customize'], { queryParams: { documentType: 'ECM' } });
  }

  onSelected(deals: EcmDealFinder[]){
    this.selectedDeals = deals;
    let confirmationIds: string[] = [];
    if (deals.length > 0) {
      confirmationIds = deals.map(d => d.id + '');
    }
    this.store.dispatch(new fromDealsActions.SetSelectedConfirmIdsAction({confirmationIds}));
    this.store.dispatch(new fromDealsActions.SetSelectedDealsAction({deals}));
  }

  get terminateDisable(): Boolean {
    return this.selectedDeals?.length <= 0;
  }

  onViewNotes(data: NotesCellViewEvent): void {
    this.store.dispatch(new fromDealsActions.OpenDialogAction({
      name: 'VIEW_NOTES',
      messages: [],
      data
    }));
  }

  getDisplayParams({documentId, id}: EcmDashboardDealItemModel) {
    return {
      displayValue: documentId,
      urlPart: 'ecm',
      confirmationId: id
    };
  }

  onDownloadXml({id}: EcmDashboardDealItemModel): void {
    this.store.dispatch(new fromDealsActions.DownloadXmlAction(id));
  }

  onDownloadHtml({id}: EcmDashboardDealItemModel): void {
    this.store.dispatch(new fromDealsActions.DownloadHtmlAction(id));
  }


  onEcmLiteCancel({id, tradeId}: EcmDashboardDealItemModel): void {
    this.store.dispatch(new fromDealsActions.OpenDialogAction({
        name: 'ECM_LITE_CANCEL',
        data: {
          confirmationId: id
        }
      }
    ));
    this.tradeIdOfRow = tradeId;
  }

  onCancelTerminateDealDialog(): void {
    this.store.dispatch(new fromDealsActions.CloseDialogAction());
    this.store.dispatch(new fromDealsActions.LoadDataAction());
  }

  onConfirmTerminateDeal(obj: any): void {
    this.store.dispatch(new fromDealsActions.TerminateConfirmAction({
        reason: obj.reason,
        confirmationIds: obj.data.confirmationIds
      }
    ));
  }

  onAbortEcmLiteCancelDialog(): void {
    this.store.dispatch(new fromDealsActions.CloseDialogAction());
    this.store.dispatch(new fromDealsActions.LoadDataAction());
  }

  onCancelAddNotes(): void {
    this.store.dispatch(new fromDealsActions.CloseDialogAction());
  }

  onCancelViewNotes(): void {
    this.store.dispatch(new fromDealsActions.CloseDialogAction());
  }

  onCancelHideUnhideConfirmation(): void {
    this.store.dispatch(new fromDealsActions.CloseDialogAction());
    this.store.dispatch(new fromDealsActions.LoadDataAction());
  }

  onConfirmUnHideConfirmation(obj: UnhideConfirmation): void {
    this.store.dispatch(new fromDealsActions.UnhideConfirmationAction(obj));
  }

  onConfirmHideConfirmation(data: HideConfirmation): void {
    this.store.dispatch(new fromDealsActions.HideConfirmationAction(data));
  }

  onConfirmAddNotes(obj: AddNote): void {
    this.store.dispatch(new fromDealsActions.AddNotesAction(obj));
  }

  onConfirmEcmLiteCancel({confirmationId}): void {
    this.store.dispatch(new fromDealsActions.ConfirmEcmLiteCancelAction({ confirmationId }));
  }

  onHide(data: { hideCheckboxVisible: boolean, hidden: boolean, hideCheckboxEnabled: boolean }): void {
    this.store.dispatch(new fromDealsActions.OpenDialogAction({
      name: 'HIDE_CONFIRMATION',
      data
    }));
  }

  onUnhide(data: { hideCheckboxVisible: boolean, hidden: boolean, hideCheckboxEnabled: boolean }): void {
    this.store.dispatch(new fromDealsActions.OpenDialogAction({
      name: 'UNHIDE_CONFIRMATION',
      data
    }));
  }
}
