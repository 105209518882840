import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { OrganisationService } from './organisation.service';
import { AuthenticationService } from '../auth/authentication.service';
import { EndPointConstants } from './end-point-constants';
import { RequestLoggingService } from '@common/request-logging.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable()
export class ApiRequestService implements OnDestroy,OnInit{

  private previousUrl = '';
  private currentUrl = '';
  private subscription:Subscription;
  private _fha: boolean;

  constructor(private authService: AuthenticationService,
              private requestLoggingService: RequestLoggingService,
              private orgService: OrganisationService,
              private router:Router,
              private httpClient: HttpClient) {

  }

  ngOnInit(): void {
    //  monitoring empty org selection
    this.subscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
    });
  }

  ngOnDestroy(): void {
       this.subscription.unsubscribe();
    }

  get(requestPath: string, params: HttpParams | object = null): Observable<any> {
    this.requestLoggingService.logRequest(requestPath);
    return this.httpClient.get(requestPath, {
      params: this.mapObjectToHttpParams(params)
    });
  }

  getBlob(requestPath: string, requestBody?: any, params?: HttpParams | object): Observable<any> {

    this.requestLoggingService.logRequest(requestPath);
    const options = {  observe: 'response' as 'body', params: this.mapObjectToHttpParams(params), responseType: 'blob' as 'json' };

    if (requestBody) {
      return this.httpClient.post(requestPath, requestBody, options);
    }

    return this.httpClient.get(requestPath, options);
  }

  download(requestPath: string, requestBody?: any, params?: HttpParams | object): Observable<any> {

    this.requestLoggingService.logRequest(requestPath);
    const options = { observe: 'response' as 'body', params: this.mapObjectToHttpParams(params), responseType: 'blob' as 'json' };

    if (requestBody) {
      return this.httpClient.post(requestPath, requestBody, options);
    }

    return this.httpClient.get(requestPath, options);
  }

  post(requestPath: string, requestBody?: any, params?: HttpParams | object, headers? : HttpHeaders): Observable<any> {
    this.requestLoggingService.logRequest(requestPath);
    return this.httpClient.post(requestPath, requestBody, {
      params: this.mapObjectToHttpParams(params),
      headers
    });
  }

  put(requestPath: string, requestBody: any, params?: HttpParams | object): Observable<any> {
    this.requestLoggingService.logRequest(requestPath);
    return this.httpClient.put(requestPath, requestBody, {
      params: this.mapObjectToHttpParams(params)
    });
  }

  delete(requestPath: string, params?: HttpParams | object): Observable<any> {
    this.requestLoggingService.logRequest(requestPath);
    return this.httpClient.delete(requestPath, {
      params: this.mapObjectToHttpParams(params)
    });
  }

  patch(requestPath: string, requestBody: any, params?: HttpParams | object) {
    this.requestLoggingService.logRequest(requestPath);
    return this.httpClient.patch(requestPath, requestBody, {
      params: this.mapObjectToHttpParams(params)
    });
  }

  private mapObjectToHttpParams(params: HttpParams | object): HttpParams {
    const organisationId = this.orgService.getCachedOrganisationId();
    const currentOrganisationId = this.orgService.getCurrentOrCachedOrganisationId();
    let httpParams = new HttpParams();

    if (organisationId) {
      httpParams = httpParams.append(EndPointConstants.PARAM_ORGANISATION_ID, currentOrganisationId.toString());
    }

    if (!this._fha && !this.isPresent(organisationId) && this.isLoggedIn) {
      this.logBackend(httpParams, currentOrganisationId);
    }

    if (params) {
      if (!(params instanceof HttpParams)) {
        let newParams = new HttpParams();
        Object.entries(params).forEach(([key, value]) => {
          if (value || value === 0) {
            newParams = newParams.set(key, value.toString());
          }
        });
        params = newParams;
      }
      for (const key of (params as HttpParams).keys()) {
        httpParams = httpParams.append(key, (params as HttpParams).get(key));
      }
    }
    return httpParams;
  }


  private isPresent(value):boolean {
    return value?.toString().length > 0;
  }

  private logBackend(httpParams: HttpParams, currentOrganisationId) {
    const user = this.authService?.getUser();
    const failingCachedOrganisationId = 'query backend with no organisation selected';
    this.httpClient.post('api/error/monitoring', JSON.stringify({
      route_previous: this.previousUrl,
      route_final: this.currentUrl,
      httpParams,
      state: { user, failingCachedOrganisationId, currentOrganisationId }
    })).subscribe();
  }

  public setFha(deselectable: boolean) {
    this._fha= deselectable;
  }

  private get isLoggedIn(): boolean {
    return this.previousUrl !== '/login' && this.currentUrl !== '';
  }
}
