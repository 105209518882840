<div class="panel-box margin-bottom-12 margin-top-12 decent-max-width">
  <p-messages [value]="messages" [closable]="true"></p-messages>
  <form class="row g-0 align-items-center" [formGroup]="form">
    <div class="col-auto">
      <cms-auto-complete [upperCase]="false"
                         [items]="transactionTypes"
                         [inputStyleClass]="errors['transactionType'] ? 'error transactionType margin-3' : 'transactionType margin-3'"
                         maxlength="20"
                         formControlName="transactionType"
                         placeholder="Transaction Type">
      </cms-auto-complete>
    </div>
    <div class="col-auto">
      <cms-auto-complete [upperCase]="false"
                         [items]="commodities"
                         [inputStyleClass]="errors['commodity'] ? 'error commodity margin-3' : 'commodity margin-3'"
                         formControlName="commodity"
                         maxlength="50"
                         placeholder="Commodity">
      </cms-auto-complete>
    </div>
    <div class="col-auto">
    <cms-auto-complete [upperCase]="true"
                       [items]="markets"
                       [inputStyleClass]="errors['market'] ? 'error markets margin-3' : 'markets margin-3'"
                       formControlName="market"
                       maxlength="2"
                       placeholder="Market">
    </cms-auto-complete>
    </div>
    <div class="col-auto btn__container">
      <button type="submit" class="btn margin-top-3 margin-bottom-3 margin-left-12" (click)="onAdd()">Add</button>
      <button class="btn margin-top-3 margin-bottom-3 margin-left-12" (click)="onClear()">Clear</button>
    </div>
  </form>
</div>
