import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EsmNettingFinderTableAggregationKeysService } from './esm-netting-finder-table-aggregation-keys.service';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'cms-esm-netting-finder-table-aggregation-keys-dropdown',
  templateUrl: './esm-netting-finder-table-aggregation-keys-dropdown.component.html',
  styleUrls: ['./esm-netting-finder-table-aggregation-keys-dropdown.component.scss']
})
export class EsmNettingFinderTableAggregationKeysDropdownComponent implements OnInit, OnChanges {

  @Input()
  id: string;

  @Input()
  placeholder: string;

  @Input()
  name: string;

   @Input()
   width: string;

  @Input()
  even: boolean;

  options$ = new BehaviorSubject<{label: string, value:string}[]>([]);
  options: {label: string, value:string}[] = [];

  constructor(private aggregationKeyService: EsmNettingFinderTableAggregationKeysService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }


  loadData(event$): void{

    if(this.options.length === 0){

    this.aggregationKeyService.onAggregationKeysLoaded().subscribe(value => {
        if(value === this.id){

          if(this.aggregationKeyService.getAggregationKeys(this.id, this.name)){
            this.options = this.aggregationKeyService.getAggregationKeys(this.id, this.name).map((v) => ({label: v.value, value: v.id}));
            this.options$.next(this.options);
          }

        }
    });

    this.aggregationKeyService.loadAggregationKeys(this.name, this.id);
    }

  }

}
