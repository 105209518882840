import { createReducer, on } from '@ngrx/store';
import { EsmDashboardPresetFilters, EsmRoleByType } from '../../../invoice/dashboard/esm-dashboard.model';
import * as fromDBFiltersActions from './filters.actions';
import { EsmDashboardFiltersAction } from './filters.actions';
import * as fromDBActions from '../dashboard.actions';
import { DateRange } from '../../../invoice/finder/invoice-finder.model';

export interface EsmDashboardFiltersState {
  value: EsmDashboardPresetFilters;
  category: string;
  commodities: string[];
  counterParties: { key: number, value: string }[];
  deliveryPoints: {keyObject:string,valueObject:string}[];
  invoiceTypes: string[];
  invoiceRole: EsmRoleByType;
  dateRange: string;
  invoiceStartDate: string;
  invoiceEndDate: string;
  paymentDateRange: DateRange;
  deliveryDateRange: DateRange;
}

export const filtersInitialState: EsmDashboardFiltersState = {
  value: {
    commodities: null,
    counterParties: null,
    deliveryPoints: null,
    invoiceTypes: null,
    multiAgreeButtonVisible: false,
    invoiceStartDate: null,
    invoiceEndDate: null,
    paymentDateRange: null,
    deliveryDateRange: null
  },
  category: 'IN_PROGRESS',
  commodities: null,
  counterParties: null,
  deliveryPoints: null,
  invoiceTypes: null,
  invoiceRole: null,
  dateRange: '',
  invoiceStartDate: null,
  invoiceEndDate: null,
  paymentDateRange: null,
  deliveryDateRange: null
};


export const filtersReducer = createReducer<EsmDashboardFiltersState, EsmDashboardFiltersAction>(
  filtersInitialState,
  on(fromDBActions.setToDefaultAction, () => ({ ...filtersInitialState })),
  on(fromDBFiltersActions.loadPresetValuesSuccessAction, (state: EsmDashboardFiltersState, { payload: values }): EsmDashboardFiltersState => {
    return {
      ...state,
      invoiceRole: null,
      ...values,
      deliveryPoints: values.deliveryPoints.map(({ key, value }) => ({ keyObject: key, valueObject: value })),
      value: {
        commodities: values.commodities,
        deliveryPoints: values.deliveryPoints.map(({ key, value }) => ({ keyObject: key, valueObject: value })),
        invoiceTypes: values.invoiceTypes,
        counterParties: values.counterParties.map(item => item.key),
        multiAgreeButtonVisible: values.multiAgreeButtonVisible,
        invoiceStartDate: values.invoiceStartDate,
        invoiceEndDate: values.invoiceEndDate,
        paymentDateRange: values.paymentDateRange,
        deliveryDateRange: values.deliveryDateRange,
      }
    };
  }),
  on(fromDBFiltersActions.setRoleByFilterValueAction, (state: EsmDashboardFiltersState, { payload: invoiceRole }): EsmDashboardFiltersState => {
    return {
      ...state,
      value: { ...state.value },
      invoiceRole
    };
  }),
  on(fromDBFiltersActions.setFilterValuesAction, (state: EsmDashboardFiltersState, { payload: value }): EsmDashboardFiltersState => (
    {
      ...state,
      value
    }
  )),
  on(fromDBFiltersActions.setDateRangeAction, (state: EsmDashboardFiltersState, { payload: dateRange }): EsmDashboardFiltersState => (
    {
      ...state,
      dateRange
    }
  )),
  on(fromDBFiltersActions.setCategoryAction, (state: EsmDashboardFiltersState, { payload: category }): EsmDashboardFiltersState => (
    {
      ...state,
      category
    }
  ))
);




