export type CpmlDashboardBucketCategoryType = 'IN_PROGRESS' | 'ISSUE' | 'COMPLETE';

export type CpmlDashboardBucketViewByType = 'TRADE_DATE' | 'SUBMISSION_DATE';
export type CpmlDashboardTradeRepositoryType = 'REGIS_TR' | 'UNA_VISTA' | 'UNA_VISTA_MIFIR' | 'DTCC' | 'ACER' | 'ELCOM' | 'NONE';

export type CpmlDashboardAssetClassType =
  'COMMODITY'
  | 'FOREIGN_EXCHANGE'
  | 'EQUITY'
  | 'EQUITY_BOND'
  | 'INTEREST_RATE'
  | 'CREDIT'
  | 'INTEREST_RATE_ODRF';

export type CpmlDashboardDocumentFormatType = 'OTC' | 'ETD';

export type CpmlDashboardDocumentStateType = 'PENDING' | 'ALLEGED' | 'RETRY' |
  'REJECTED_ERR' | 'REJECTED_TR' | 'COMMUNICATION_PROBLEM_TR' | 'PROCESSING_ERR' |
  'REPORTED_TR' | 'NOT_REPORTABLE';

export interface CpmlDashboardFilterStateType {
  category: CpmlDashboardBucketCategoryType;
  viewBy: CpmlDashboardBucketViewByType;
  tradeRepositories: CpmlDashboardTradeRepositoryType[];
  assetClasses: CpmlDashboardAssetClassType[];
  documentFormats: CpmlDashboardDocumentFormatType[];
  documentState?: CpmlDashboardDocumentStateType;
}

export type AnyOfCpmlDashboardFilterStateTypes
  = CpmlDashboardBucketCategoryType
  | CpmlDashboardBucketViewByType
  | CpmlDashboardTradeRepositoryType[]
  | CpmlDashboardAssetClassType[]
  | CpmlDashboardDocumentFormatType[]
  | CpmlDashboardDocumentStateType;

export function getDefaultBucketCategory(): CpmlDashboardBucketCategoryType {
  return 'IN_PROGRESS';
}

export function getDefaultBucketViewBy(): CpmlDashboardBucketViewByType {
  return 'SUBMISSION_DATE';
}

export function getAllCpmlDashboardTradeRepositories(): CpmlDashboardTradeRepositoryType[] {
  return ['REGIS_TR', 'UNA_VISTA', 'UNA_VISTA_MIFIR', 'DTCC', 'ACER', 'ELCOM', 'NONE'];
}

export function getAllCpmlDashboardAssetClasses(): CpmlDashboardAssetClassType[] {
  return ['COMMODITY', 'FOREIGN_EXCHANGE', 'EQUITY', 'EQUITY_BOND', 'INTEREST_RATE', 'CREDIT', 'INTEREST_RATE_ODRF'];
}

export function getAllCpmlDashboardDocumentFormats(): CpmlDashboardDocumentFormatType[] {
  return ['OTC', 'ETD'];
}

