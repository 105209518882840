import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import { RemitTradeEntryTable1Service } from '../remit-trade-entry-table1.service';
import { RemitTradeEntryTable1SettlementModel } from '../remit-trade-entry-table1-data.model';
import { Subject } from 'rxjs';
import { SelectItem, SelectItemGroup } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cms-remit-trade-entry-table1-settlement',
  templateUrl: './remit-trade-entry-table1-settlement.component.html',
  styleUrls: ['./remit-trade-entry-table1-settlement.component.scss']
})
export class RemitTradeEntryTable1SettlementComponent implements OnInit, OnDestroy {

  @Input()
  formGroup: UntypedFormGroup;

  private onDestroy$ = new Subject();
  tradeEntrySettlementData: RemitTradeEntryTable1SettlementModel = null;

  settlementMethod: SelectItem[];
  priceCurrency: SelectItemGroup[];
  optionPremiumCurrency: SelectItemGroup[];
  quantityUnit: SelectItemGroup[];
  totalQuantityUnits: SelectItemGroup[];
  showOptionals: boolean = false;
  private contractType;

  execution: boolean = false;

  constructor(private tradeEntryService: RemitTradeEntryTable1Service) {
  }

  ngOnInit() {

    this.formGroup.addControl('settlementMethod', new UntypedFormControl(''));
    this.formGroup.addControl('priceCurrency', new UntypedFormControl(''));
    this.formGroup.addControl('optionPremiumCurrency', new UntypedFormControl(''));
    this.formGroup.addControl('quantityUnit', new UntypedFormControl(''));
    this.formGroup.addControl('totalQuantityUnit', new UntypedFormControl(''));

    this.tradeEntryService.onTradeEntryDataChange().pipe(takeUntil(this.onDestroy$)).subscribe(tradeEntryData => {
      if (tradeEntryData) {
        this.tradeEntrySettlementData = tradeEntryData.settlement;
        this.settlementMethod = this.getSettlementMethods();
        this.priceCurrency = this.getPriceCurrencies();
        this.optionPremiumCurrency = this.getOptionalPremiumCurrencies();
        this.quantityUnit = this.getQuantityUnits();
        this.totalQuantityUnits = this.getTotalQuantities();
      }
    });

    this.formGroup.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(
      formValues => {
        if (this.contractType !== formValues.contractType) {
          this.contractType = formValues.contractType;
          this.setOptionals(formValues.contractType);
        }
        // toggle quantityUnit requiry
        if (this.execution !== formValues.execution) {
          this.execution = formValues.execution;
          // if (this.execution) {
          //   this.formGroup.controls['quantityUnit'].clearValidators();
          //   this.formGroup.controls['quantityUnit'].updateValueAndValidity();
          // } else {
          //   this.formGroup.controls['quantityUnit'].setValidators(Validators.required);
          //   this.formGroup.controls['quantityUnit'].updateValueAndValidity();
          // }
        }
        this.tradeEntryService.updateSelectedQuantityUnitValue(formValues.quantityUnit);
        this.tradeEntryService.updateSelectedTotalQuantityUnitValue(formValues.totalQuantityUnit);
      }
    );

  }

  selectCommodityFirstPlaceholder(fieldName: SelectItemGroup[]): string {
    return fieldName && fieldName[0].items.length === 0 ? 'Please select a Commodity first' : '';
  }

  private getSettlementMethods(): SelectItem[] {
    if (this.tradeEntrySettlementData) {
      return this.tradeEntrySettlementData.settlementMethods.map(item => (
        {
          label: item.value,
          value: item.key
        }
      ));
    }

    return [{ label: '', value: '' }];
  }

  private getPriceCurrencies(): SelectItemGroup[] {
    if (this.tradeEntrySettlementData) {
      return this.tradeEntryService.getGroupedValues(this.tradeEntrySettlementData.priceCurrencies);
    }

    return [{ label: '', items: [] }];
  }

  private getQuantityUnits(): SelectItemGroup[] {
    if (this.tradeEntrySettlementData) {
      return this.tradeEntryService.getGroupedValues(this.tradeEntrySettlementData.quantityUnits);
    }

    return [{ label: '', items: [] }];
  }

  getTotalQuantities(): SelectItemGroup[] {
    if (this.tradeEntrySettlementData) {
      return this.tradeEntryService.getGroupedValues(this.tradeEntrySettlementData.totalQuantityUnits);
    }

    return [{ label: '', items: [] }];
  }

  getOptionalPremiumCurrencies(): SelectItemGroup[] {
    if (this.tradeEntrySettlementData) {
      return this.tradeEntryService.getGroupedValues(this.tradeEntrySettlementData.optionPremiumCurrencies);
    }

    return [{ label: '', items: [] }];
  }

  private setOptionals(contractType: string) {
    this.showOptionals = contractType && contractType.startsWith('OP');
  }

  getValidationErrorStyleClass(fieldName: string): string {
    if (this.formGroup.controls[fieldName].hasError('error')) {
      return 'error';
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.showOptionals = false;
    this.formGroup.reset();
  }

}
