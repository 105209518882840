<form [formGroup]="form" class="form-row" autocomplete="off">
  <fieldset [disabled]="isDisabled" class="col-md-6 col-12">
    <div class="margin-bottom-12 flex align-items-center">
      <label class="col-6 pe-0">Broker Match Cap:
        <span class="text-required" *ngIf="!isDisabled">&nbsp;*</span>
      </label>
      <div class="col-3 margin-right-6">
        <input
          autocomplete="off"
          id="brokerMatchCap"
          pInputText
          formControlName="brokerMatchCap"
          class="form-control text-end"
          [ngClass]="{'error': errors['brokerMatchCap']}"
          (paste)="onPaste($event, intRegex)"
          (keypress)="onKeyPress($event, intRegex)" />
      </div>
      <span>matches</span>
    </div>
    <div class="margin-bottom-12 flex align-items-center">
      <label class="col-6 pe-0">Broker Match Fee:
        <span class="text-required" *ngIf="!isDisabled">&nbsp;*</span>
      </label>
      <div class="col-3 margin-right-6">
        <input
          autocomplete="off" id="brokerMatchFee"
          pInputText
          formControlName="brokerMatchFee"
          class="form-control text-end"
          [ngClass]="{'error': errors['brokerMatchFee']}"
          (paste)="onPaste($event, floatRegex)"
          (keypress)="onKeyPress($event, floatRegex)" />
      </div>
      <span>EUR</span>
    </div>
    <div class="margin-bottom-12 flex align-items-center">
      <label class="col-6 pe-0">eCM Lite Free Cap:
        <span class="text-required" *ngIf="!isDisabled">&nbsp;*</span>
      </label>
      <div class="col-3 margin-right-6">
        <input
          autocomplete="off"
          id="ecmLiteFreeCap"
          pInputText
          formControlName="ecmLiteFreeCap"
          class="col-3 form-control text-end"
          [ngClass]="{'error': errors['ecmLiteFreeCap']}"
          (paste)="onPaste($event, intRegex)"
          (keypress)="onKeyPress($event, intRegex)" />
      </div>
      <span>matches</span>
    </div>
    <div class="margin-bottom-12 flex align-items-center">
      <label class="col-6 pe-0">Trader Match Cap:<span class="text-required"
                                                                  *ngIf="!isDisabled">&nbsp;*</span></label>
      <div class="col-3 margin-right-6">
        <input
          autocomplete="off"
          id="traderMatchCap"
          pInputText
          formControlName="traderMatchCap"
          class="col-3 form-control text-end"
          [ngClass]="{'error': errors['traderMatchCap']}"
          (paste)="onPaste($event, intRegex)"
          (keypress)="onKeyPress($event, intRegex)" />
      </div>
      <span>matches</span>
    </div>
    <div class="margin-bottom-12 flex align-items-center">
      <label class="col-6 pe-0">Trader Match Fee: <span class="text-required"
                                                                   *ngIf="!isDisabled">&nbsp;*</span></label>
      <div class="col-3 margin-right-6">
        <input
          autocomplete="off"
          id="traderMatchFee"
          pInputText
          formControlName="traderMatchFee"
          class="form-control text-end"
          [ngClass]="{'error': errors['traderMatchFee']}"
          (paste)="onPaste($event, floatRegex)"
          (keypress)="onKeyPress($event, floatRegex)" />
      </div>
      <span>EUR</span>
    </div>
  </fieldset>
  <fieldset [disabled]="isDisabled" class="col-md-6 col-12">
    <div class="margin-bottom-12 flex align-items-center">
      <label class="col-6 pe-0">eRR Annual Fee:
        <span class="text-required" *ngIf="!isDisabled">&nbsp;*</span>
      </label>
      <div class="col-3 margin-right-6">
        <input
          autocomplete="off"
          id="errAnnualFee"
          pInputText
          formControlName="errAnnualFee"
          class="form-control text-end"
          [ngClass]="{'error': errors['errAnnualFee']}"
          (paste)="onPaste($event, floatRegex)"
          (keypress)="onKeyPress($event, floatRegex)" />
      </div>
      <span>EUR</span>
    </div>
    <div class="margin-bottom-12 flex align-items-center">
      <label class="col-6 pe-0">eRR Transaction Cap:
        <span class="text-required" *ngIf="!isDisabled">&nbsp;*</span>
      </label>
      <div class="col-3 margin-right-6">
        <input
          autocomplete="off"
          id="errTxCap"
          pInputText
          formControlName="errTxCap"
          class="form-control text-end"
          [ngClass]="{'error': errors['errTxCap']}"
          (paste)="onPaste($event, intRegex)"
          (keypress)="onKeyPress($event, intRegex)" />
      </div>
      <span>reports</span>
    </div>
    <div class="margin-bottom-12 flex align-items-center">
      <label class="col-6 pe-0">eRR Transaction Fee:<span class=" text-required"
                                                                     *ngIf="!isDisabled">&nbsp;*</span></label>
      <div class="col-3 margin-right-6">
        <input
          autocomplete="off"
          id="errTxFee"
          pInputText
          formControlName="errTxFee"
          class="form-control text-end"
          [ngClass]="{'error': errors['errTxFee']}"
          (paste)="onPaste($event, floatRegex)"
          (keypress)="onKeyPress($event, floatRegex)" />
      </div>
      <span>EUR</span>
    </div>
    <div class="margin-bottom-12 flex align-items-center">
      <label class="col-6 pe-0">eCM Fee Discount: <span class="text-required"
                                                                   *ngIf="!isDisabled">&nbsp;*</span></label>
      <div class="col-3 margin-right-6">
        <input
          autocomplete="off" id="ecmFeeDiscount"
          pInputText
          formControlName="ecmFeeDiscount"
          class=" form-control text-end"
          [ngClass]="{'error': errors['ecmFeeDiscount']}"
          (paste)="onPaste($event, floatRegex)"
          (keypress)="onKeyPress($event, floatRegex)" />
      </div>
      <span>%</span>
    </div>
    <div class="margin-bottom-12 flex align-items-center">
      <label class="col-6 pe-0">Invoice Trigger:
        <span class="text-required" *ngIf="!isDisabled">&nbsp;*</span></label>
      <div class="col-3 margin-right-6">
        <input
          autocomplete="off"
          id="invoiceTrigger"
          pInputText
          formControlName="invoiceTrigger"
          class="form-control text-end"
          [ngClass]="{'error': errors['invoiceTrigger']}"
          (paste)="onPaste($event, floatRegex)"
          (keypress)="onKeyPress($event, floatRegex)" />
      </div>
      <span>EUR</span>
    </div>
  </fieldset>
</form>
