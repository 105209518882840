import { Message } from 'primeng/api';
import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { BillingRunParams } from '../billing-runs.model';
import { BillingParamsFormComponent } from '../billing-params-form/billing-params-form.component';

@Component({
  selector: 'cms-edit-billing-params-dialog',
  templateUrl: './edit-billing-params-dialog.component.html',
  styleUrls: ['./edit-billing-params-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditBillingParamsDialogComponent {
  @ViewChild('form', { static: false }) form: BillingParamsFormComponent;
  @Input() display = false;
  @Input() data: BillingRunParams;
  @Input() errorMessages: Message[];
  @Output() confirm = new EventEmitter<BillingRunParams>();
  @Output() cancel = new EventEmitter<Message[]>();
  header: string = 'Edit Billing Parameters';

  constructor(private mapper: MessagesMapperService) { }

  onCancel(): void {
    this.cancel.emit(this.mapper.toWarningMessages(`Editing of billing parameters was canceled.`));
  }

  onConfirm(): void {
    const formData = this.form.getFormValues();
    this.confirm.emit(formData);
  }
}
