<p-dialog *ngIf="displayDialog"
          [resizable]="false"
          [(visible)]="displayDialog"
          [modal]="true"
          [style]="{width: '500px', minHeight: '225px'}"
          (visibleChange)="onVisibleChange($event)"
          (onHide)="onCancel()">
  <p-header>
    <span>{{action}} Cross Commodity Netting</span>
  </p-header>
  <p-messages [closable]="false" class="col-12" [value]="messages" *ngIf="messages"></p-messages>
  <div class="warning" *ngIf="isActivated">
    <div class="warning__id-container">
      <i class="fa fa-exclamation-triangle"></i>
      <span>
        <b *ngIf="action === 'Block'">Confirm you wish to stop cross commodity netting with the selected counterparty.</b>
      </span>
    </div>
  </div>
  <div class="warning" *ngIf="!isActivated">
    <div class="warning__id-container warning__id-container--info">
      <i class="fa fa-info-circle"></i>
      <span>
        <b *ngIf="action === 'Activate'">Confirm you wish to activate cross commodity netting with the selected counterparty.</b>
      </span>
    </div>
  </div>
  <p-footer>
    <button pButton
            type="button"
            class="btn margin-right-3"
            (click)="onCancel()"
            label="Cancel"
            id="esm-netting-settings-dialog-cancel">
    </button>
    <button pButton
            type="button"
            class="btn"
            (click)="onConfirm()"
            label="Yes - {{ action }}"
            id="esm-netting-settings-dialog-confirm">
    </button>
  </p-footer>
</p-dialog>
