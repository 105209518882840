import { Injectable } from '@angular/core';
import { ApiRequestService } from '@common/api-request.service';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CommercialTradeEntryModel } from './form/remit-trade-entry-table1-modify-commercials.component';
import { Message, SelectItemGroup } from 'primeng/api';
import { SettlementTradeEntryModel } from './form/remit-trade-entry-table1-modify-settlement.component';
import { ReferenceTradeEntryModel } from './form/remit-trade-entry-table1-modify-references.component';
import { DeliveryTradeEntryModifyModel } from './form/remit-trade-entry-table1-modify-contractdelivery.component';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RemitTradeEntryTable1ModifyService {

  apiPath: string = '/api/err/remit/lite/remitTable1';

  private remitTable1TradeEntry = new BehaviorSubject<RemitTable1TradeEntryModifyModel>(null);
  private remitTable1TradeEntrySetupData = new BehaviorSubject<any>(null);

  private message = new BehaviorSubject<Message>(null);
  private serverValidationErrors = new BehaviorSubject<any>(null);

  private modifyTradeDisabled = new BehaviorSubject<boolean>(false);
  private modifyTradeSubmitInProgress = new BehaviorSubject<boolean>(false);

  constructor(private apiRequestService: ApiRequestService) {
  }

  loadTradeEntry(historyId: number) {
    if (historyId) {

      const httpParams: HttpParams = new HttpParams().append('historyId', historyId.toString());

      this.apiRequestService.get(this.apiPath + '/loadPresetsModify', httpParams)
        .pipe(catchError(this.handleError))
        .subscribe(response => {
          if (response.error) {
            this.setMessage(response.error);
            this.remitTable1TradeEntry.next(null);
          } else if (response.validationFailureMessages && Object.keys(response.validationFailureMessages).length > 0) {
            this.handleLoadValidationErrors(response.validationFailureMessages);
            this.remitTable1TradeEntry.next(null);
          } else {
            // success
            this.remitTable1TradeEntry.next(response);
          }
        });
    }
  }

  loadTradeEntrySetupData(historyId: number) {

    if (historyId) {

      const httpParams: HttpParams = new HttpParams().append('historyId', historyId.toString());

      this.apiRequestService.get(this.apiPath + '/loadValueSetsModify', httpParams).subscribe(response => {
        this.remitTable1TradeEntrySetupData.next(response);
      });
    }
  }

  modifyTrade(modificationValues: any, historyId: number): void {
    this.modifyTradeDisabled.next(true);

    if (historyId) {
      const httpParams = new HttpParams().append('historyId', historyId.toString());
      this.serverValidationErrors.next({});
      this.modifyTradeSubmitInProgress.next(true);
      this.apiRequestService.post(this.apiPath + '/modify', modificationValues, httpParams)
        .pipe(catchError(this.handleError))
        .subscribe(response => {
          if (response.error) {
            this.setMessage(response.error);
            this.modifyTradeDisabled.next(false);
          } else if (response.fieldValidationMessages && Object.keys(response.fieldValidationMessages).length > 0) {
            this.handleUpdateValidationErrors(response.fieldValidationMessages);
          } else if (response.validationFailureMessages && response.validationFailureMessages.length > 0) {
            this.handleUpdateListValidationErrors(response.validationFailureMessages);
          } else {
            // success
            this.setMessage({ severity: 'success', summary: 'Modification submitted successfully.' });
            this.modifyTradeDisabled.next(false);
          }
          this.modifyTradeSubmitInProgress.next(false);
        });
    }
  }

  private handleError(error: HttpErrorResponse) {
    const response = { error: { severity: 'error', summary: error.statusText + ': ' + error.error.errorMessage } };
    return of(response);
  }

  private handleLoadValidationErrors(validationFailureMessages: any) {
    for (const errorMessage of validationFailureMessages) {
      this.setMessage({ severity: 'error', summary: errorMessage });
    }
  }

  private handleUpdateValidationErrors(fieldValidationMessages: any) {
    this.serverValidationErrors.next(fieldValidationMessages);
    this.modifyTradeDisabled.next(false);
    for (const errorMessage of Object.keys(fieldValidationMessages)) {
      this.setMessage({ severity: 'error', summary: fieldValidationMessages[errorMessage] });
    }
  }

  private handleUpdateListValidationErrors(listValidationMessages: any) {
    this.modifyTradeDisabled.next(false);
    for (const errorMessage of listValidationMessages) {
      this.setMessage({ severity: 'error', summary: errorMessage });
    }
  }

  onModifyTradeDisabled(): Observable<boolean> {
    return this.modifyTradeDisabled.asObservable();
  }

  onValidationMessages(): Observable<Message> {
    return this.message.asObservable();
  }

  onValidationErrors(): Observable<any> {
    return this.serverValidationErrors.asObservable();
  }

  onTradeEntryModelChange(): Observable<RemitTable1TradeEntryModifyModel> {
    return this.remitTable1TradeEntry.asObservable();
  }

  onTradeEntrySetupDataChange(): Observable<any> {
    return this.remitTable1TradeEntrySetupData.asObservable();
  }

  onModifyTradeFinished(): Observable<any> {
    return this.modifyTradeSubmitInProgress.asObservable();
  }

  getGroupedValues(array: any): SelectItemGroup[] {
    if (array) {
      if (array.preferredValues.length > 0) {
        return [
          {
            label: 'Preferred',
            items: array.preferredValues.map(item => (
              {
                label: item.value,
                value: item.key
              }
            ))
          },
          {
            label: 'Others',
            items:
              array.notPreferredValues.map(item => (
                {
                  label: item.value,
                  value: item.key
                }
              ))
          }
        ];
      } else {
        return [{
          label: ' ',
          items: array.notPreferredValues.map(item => (
            {
              label: item.value, value: item.key
            }
          ))
        }];
      }
    }

    return [{
      label: '',
      items: []
    }];
  }

  public setMessage(message: Message) {
    this.message.next(message);
  }

  public onMessageChange(): Observable<Message> {
    return this.message.asObservable();
  }

  public reset(): void {
    this.serverValidationErrors.next(null);
    this.message.next(null);
  }
}

export interface RemitTable1TradeEntryModifyModel {
  referenceResponseModel: ReferenceTradeEntryModel;
  commercialResponseModel?: CommercialTradeEntryModel;
  settlementResponseModel?: SettlementTradeEntryModel;
  deliveryResponseModel: DeliveryTradeEntryModifyModel;
  deliveryProfileResponseModel: any;
  submitEnabled: boolean;
}
