import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Message } from 'primeng/api';
import { catchError, concatMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Action, select, Store } from '@ngrx/store';
import { HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import * as fromTableSelectors from './table.selectors';
import * as fromFiltersSelectors from '../filters/filters.selectors';
import { EsmInvoiceTableState } from './table.reducer';
import { EsmInvoiceFinderFiltersState } from '../filters/filters.reducers';
import { ApiRequestService } from '@common/api-request.service';
import { EsmInvoiceFinderState } from '../invoice-finder.reducers';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import {
  esmInvoiceFinderColumns,
  EsmInvoiceFinderFilterByPropertiesRequest,
  EsmInvoiceFinderFilterResponse,
  EsmPDFResendResponse
} from '../../../invoice/finder/invoice-finder.model';
import { AddNote, Dispute, getDealsColumns, mapRequestValues } from '@common/deals/deals.model';
import { getBlobContent, saveBlob, toPayload } from '@common/cms-common.model';
import * as fromTableActions from './table.actions';
import * as fromDialogActions from '../dialog/dialog.actions';
import * as fromFiltersActions from '../filters/filters.actions';
import { ErrorResponse } from '@common/error-response.model';
import * as fromActions from '../../../../ecm-monitoring-deals/state/monitoring-deals/monitoring-deals.actions';
import { setMessageAction } from '../../dashboard/dashboard.actions';

@Injectable()
export class EsmInvoiceFinderTableEffects {

  constructor(private apiGateway: ApiRequestService,
              private actions$: Actions,
              private router: Router,
              private store$: Store<EsmInvoiceFinderState>,
              private mapper: MessagesMapperService) {
  }

  private baseUrl = '/api/esm/invoice/finder';

  filterToUrlMap: { [key: string]: string } = {
    'dealProperties': 'filter',
    'ourInvoiceNumber': 'invoicenumber/our',
    'theirInvoiceNumber': 'invoicenumber/their',
    'documentId': 'documentId',
    'purchaseOrderNumber': 'purchaseOrderNumber',
    'tradeId': 'tradeIdUti'
  };

  downloadHtml$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTableActions.downloadHtmlAction),
      map(toPayload),
      concatMap((id: number) => {
        const params = new HttpParams().set('invoiceId', id.toString());
        return this.apiGateway.getBlob(`${this.baseUrl}/invoices/html`, null, params)
          .pipe(
            switchMap((response: any) => {
              const blob: Blob = response.body;
              return [
                fromTableActions.setMessagesAction([]),
                fromTableActions.setHtmlAction(blob)
              ];
            }),
            catchError(() => {
              this.router.navigate(['/help/welcome'], { queryParamsHandling: 'preserve' });
              return of(null);
            })
          );
      })
    ));

  downloadDoc$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTableActions.downloadXmlAction),
      map(toPayload),
      concatMap((id: number) =>
        this.apiGateway.getBlob(`${this.baseUrl}/invoices/xml`, null, new HttpParams().set('invoiceId', id.toString()))
          .pipe(
            switchMap(this.handleBlob),
            catchError(error => of(fromTableActions.setMessagesAction([this.mapper.createErrorMessage(error.errorMessage)]))))
      ),
    )
  );

  downloadPdf$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTableActions.downloadPdfAction),
      map(toPayload),
      concatMap(id => this.apiGateway.getBlob(`${this.baseUrl}/invoices/pdf`, null,
          new HttpParams().set('invoiceId', id.toString()))
          .pipe(
            switchMap(this.handleBlob),
            catchError(response => this.handleBlobErrorContent(response)))
      )
    )
  );

  resend$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTableActions.resendAction),
      map(toPayload),
      switchMap(({ id, screen }) => this.apiGateway.post(`${this.baseUrl}/resend`, null, new HttpParams().set('invoiceId', id.toString()))
          .pipe(
            switchMap((response: EsmPDFResendResponse) => {

                const messages: Message[] = [{
                  severity: response.response.includes('fail') ? 'error' : 'success',
                  detail: response.response,
                  id: '',
                  key: '',
                  life: 0,
                  sticky: false,
                  closable: true,
                  data: id
                }];

                return screen ==='invoice'?
                  of(fromTableActions.setMessagesAction(messages)):
                  of(setMessageAction(messages));
              }
            ),
            catchError(e=>
              screen ==='invoice'?
                of(fromTableActions.setMessagesAction([this.mapper.createErrorMessage(e.errorMessage)])):
                of(setMessageAction([this.mapper.createErrorMessage(e.errorMessage)])))
          )
      )
    )
  );

  export$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTableActions.exportAction),
      withLatestFrom(
        this.store$.pipe(select(fromTableSelectors.getTable)),
        this.store$.pipe(select(fromFiltersSelectors.getFilters))),

      concatMap(([, { paging, sorting }, filters]: [Action, EsmInvoiceTableState, EsmInvoiceFinderFiltersState]) =>

        this.apiGateway.getBlob(`${this.baseUrl}/export/${this.filterToUrlMap[filters.filterBy]}`, this.getFilterRequest(filters, paging, sorting))
          .pipe(
            switchMap(this.handleBlob),
            catchError(error => of(fromTableActions.setMessagesAction([this.mapper.createErrorMessage(error.errorMessage)]))))
      )
    )
  );

  addNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTableActions.addNoteAction),
      map(toPayload),
      concatMap(({ confirmationIds: ids, text }: AddNote) => {
        return this.apiGateway.post(`${this.baseUrl}/createNote`, { ids, text })
          .pipe(
            switchMap((response: ErrorResponse & {}) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return of(fromDialogActions.updateDialogAction(messages));
              }
              return [
                fromDialogActions.closeDialogAction(),
                fromDialogActions.updateDialogAction([]),
                fromTableActions.addNoteSuccessAction()
              ];
            })
          );
      })
    ));

  addNotesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTableActions.addNoteSuccessAction),
      filter(() => (this.router.routerState.snapshot.url.includes('esm/invoicefinder'))),
      withLatestFrom(this.store$.pipe(select(fromFiltersSelectors.getFilters))),
      map(([, data]: [Action, EsmInvoiceFinderFiltersState]) => fromFiltersActions.filterAction(data)))
  );

  disputeInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTableActions.confirmDisputeInvoice),
      map(toPayload),
      concatMap(({ invoiceId, note }: Dispute) => {
        return this.apiGateway.post(`${this.baseUrl}/dispute`, null, new HttpParams().set('invoiceId', invoiceId.toString()).set('note', note))
          .pipe(
            switchMap((response: ErrorResponse & {}) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return of(fromDialogActions.updateDialogAction(messages));
              }
              return [
                fromDialogActions.closeDialogAction(),
                fromDialogActions.updateDialogAction([]),
                fromTableActions.setMessagesAction([{
                  severity: 'success',
                  summary: '',
                  detail: 'Successfully triggered dispute. Please reload invoice table data. '
                }]),
                setMessageAction([{
                  severity: 'success',
                  summary: '',
                  detail: 'Successfully triggered dispute. Please reload invoice table data. '
                }])
              ];
            })
          );
      })
    )
  );

  agreeInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTableActions.confirmAgreeInvoices.type),
      concatMap((action: ReturnType<typeof fromTableActions.confirmAgreeInvoices>) => {
        return this.apiGateway.post(`${this.baseUrl}/agree`, action.payload)
          .pipe(
            switchMap((response: ErrorResponse & {}) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return of(fromDialogActions.updateDialogAction(messages));
              }
              return [
                fromDialogActions.closeDialogAction(),
                fromDialogActions.updateDialogAction([]),
                fromTableActions.setMessagesAction([{
                  severity: 'success',
                  summary: '',
                  detail: 'Successfully triggered agree. Please reload invoice table data. '
                }]),
                setMessageAction([{
                  severity: 'success',
                  summary: '',
                  detail: 'Successfully triggered agree. Please reload invoice table data. '
                }])
              ];
            })
          );
      })
    )
  );

  filter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromFiltersActions.filterAction,
        fromTableActions.sortAndPageDataAction
      ),
      withLatestFrom(
        this.store$.pipe(select(fromTableSelectors.getTable)),
        this.store$.pipe(select(fromFiltersSelectors.getFilters)),
      ),
      switchMap(([, { paging, sorting }, filters]: [Action, EsmInvoiceTableState, EsmInvoiceFinderFiltersState]) =>
        this.loadData(filters, paging, sorting)
      )
    )
  );

  private scrollTop = () => window.scrollTo(0, 0);

  private loadData(filters: EsmInvoiceFinderFiltersState, paging: PagingFilter, sorting: SortingFilter) {
    return this.apiGateway
      .post(`${this.baseUrl}/search/${this.filterToUrlMap[filters.filterBy]}`,
        this.getFilterRequest(filters, paging, sorting))
      .pipe(
        switchMap((response: EsmInvoiceFinderFilterResponse) => {
          const messages: Message[] = this.mapper.toErrorMessages(response);
          if (messages && messages.length) {
            return [fromTableActions.setMessagesAction(messages)];
          }
          const columns = getDealsColumns(response.columnOrder, esmInvoiceFinderColumns);
          return [
            fromFiltersActions.filterSuccessAction(response),
            fromTableActions.setMessagesAction([]),
            fromTableActions.setColumnsAction(columns),
          ];
        }),
        catchError(error => of(fromTableActions.setMessagesAction([this.mapper.createErrorMessage(error.errorMessage)])))
      );
  }

  private getFilterRequest(filters: EsmInvoiceFinderFiltersState, paging: PagingFilter, sorting: SortingFilter) {
    let request: EsmInvoiceFinderFilterByPropertiesRequest;
    const { filterBy } = filters;

    if (filterBy === 'dealProperties') {
      const {
        submissionDateRange,
        paymentDateRange,
        invoiceStartDate,
        invoiceEndDate,
        counterPartyOrgIds,
        commodities,
        deliveryPoints,
        invoiceTypes,
        senderUs
      } = filters.propFilters;
      request = {
        commodities: mapRequestValues(commodities, filters.commodities),
        counterPartyOrgIds: mapRequestValues(counterPartyOrgIds, filters.counterParties.map(item => item.key)),
        deliveryPoints: mapRequestValues(deliveryPoints, filters.deliveryPoints),
        invoiceTypes: mapRequestValues(invoiceTypes, filters.invoiceTypes),
        invoiceStartDate: invoiceStartDate ? moment(invoiceStartDate).format('YYYY-MM-DD') : null,
        invoiceEndDate: invoiceEndDate ? moment(invoiceEndDate).format('YYYY-MM-DD') : null,
        submissionDateRange: {
          start: submissionDateRange && submissionDateRange.start ? this.transformSubmissionDateToUTC(submissionDateRange.start, 'startOfDay') : null,
          end: submissionDateRange && submissionDateRange.end ? this.transformSubmissionDateToUTC(submissionDateRange.end, 'endOfDay') : null
        },
        paymentDateRange: {
          start: paymentDateRange && paymentDateRange.start ? moment(paymentDateRange.start).format('YYYY-MM-DD') : null,
          end: paymentDateRange && paymentDateRange.end ? moment(paymentDateRange.end).format('YYYY-MM-DD') : null
        },
        senderUs,
        paging,
        sorting,
      } as EsmInvoiceFinderFilterByPropertiesRequest;
    } else {
      return { inputIds: filters.inputIds[filterBy], sorting, paging };
    }

    return request;
  }

  transformSubmissionDateToUTC(date
                                 :
                                 string | Date, time
                                 :
                                 'startOfDay' | 'endOfDay'
  ):
    Date {
    const utcDate = moment.utc(moment(date).format('YYYY-MM-DD'), 'YYYY-MM-DD');
    return time === 'startOfDay' ? utcDate.startOf('day').toDate() : utcDate.endOf('day').toDate();
  }

  private handleBlob(response): Observable<ReturnType<typeof fromActions.setMessagesAction>> {
    saveBlob(response);
    return of(fromActions.setMessagesAction([]));
  }

  /**
   * a perfect transformation of promise to observable response
   */
  private handleBlobErrorContent(errResponse): Observable<Action> {
    this.scrollTop();

    return new Observable(observer => {
      const controller = new AbortController();

      getBlobContent(errResponse)
        .then(message => {
          observer.next(fromTableActions.setMessagesAction([this.mapper.createErrorMessage(message)]));
          observer.complete();
        })
        .catch(err => {
          observer.error(err);
        });

      return () => controller.abort();
    });
  }
}
