import { Prefix } from './mail-alerts.service';
import * as fromActions from './mail-alerts.actions';
import { Column } from '../../../common/shared/datatable.model';
import { DatatableData, SortingFilter } from '../../../common/shared/results.model';
import { MailAlert } from '../../mail-alerts/mail-alerts.model';
import { Message } from 'primeng/api';
import { EditableCell } from '../../../common/datatable/cell-editor/cell-editor.model';
import { LayoutState } from './layout/layout.reducer';

export const defaultMailAlertsColumns: Column[] = [
  { name: 'username', type: 'text', title: 'User Name', style: { 'width': '155px', 'text-align': 'left' }, sortable: true, visible: true },
  { name: 'realName', type: 'text', title: 'Real Name', style: { 'width': '155px', 'text-align': 'left' }, sortable: true, visible: true },
  { name: 'userState', type: 'text', title: 'User State', style: { 'width': '155px', 'text-align': 'left' }, sortable: true, visible: true },
];

export interface MailAlertState {
  columns: Column[];
  data: DatatableData<MailAlert[]>;
  messages: Message[];
  loading: boolean;
  organisationId: number;
  editableData: EditableCell;
  sorting?: SortingFilter;
}

export const initialState: MailAlertState = {
  columns: [...defaultMailAlertsColumns],
  data: {
    values: [],
    count: null
  },
  sorting: {
    sortOrder: 'ASCENDING',
    columnName: 'realName'
  },
  messages: [],
  loading: false,
  organisationId: null,
  editableData: null
};

export interface State {
  global: MailAlertState;
  ecm: MailAlertState;
  layout: LayoutState;
}

export function updateStatus(state,
                             action: fromActions.UpdateStatusSuccessAction) {
  let values = state.data.values;
  const newDataItem: MailAlert = action.payload;
  const editableData = state.editableData;
  const index = values.indexOf(editableData.data);
  if (index !== -1) {
    values = [
      ...values.slice(0, index), newDataItem, ...values.slice(index + 1)
    ];
  }

  return {
    ...state,
    editableData: null,
    data: {
      values: values,
      count: values.length
    }
  };
}

export function mailAlertsReducer(prefix: Prefix) {
  return function (state = {...initialState}, action: fromActions.MailAlertsActions): MailAlertState {
    switch (action.type) {
      case `${prefix} ${fromActions.UPDATE_COLUMNS}`:
        return {
          ...state,
          columns: [...defaultMailAlertsColumns, ...(action as fromActions.UpdateColumnsAction).payload]
        };
      case `${prefix} ${fromActions.LOAD_DATA}`:
        return {
          ...state,
          messages: [],
          loading: true,
          editableData: null,
        };
      case `${prefix} ${fromActions.LOAD_DATA_SUCCESS}`:
        return {
          ...state,
          loading: false,
          data: (action as fromActions.LoadDataSuccessAction).payload
        };
      case `${prefix} ${fromActions.LOAD_DATA_FAIL}`:
        return {
          ...state,
          loading: false,
          data: {
            values: [],
            count: 0
          }
        };
      case `${prefix} ${fromActions.UPDATE_STATUS}`:
        return {
          ...state,
          editableData: (action as fromActions.UpdateStatusAction).payload
        };
      case `${prefix} ${fromActions.SET_ORGANISATION_ID}`:
        return {
          ...state,
          organisationId: (action as fromActions.SetOrganisationIdAction).payload
        };
      case `${prefix} ${fromActions.SET_MESSAGES}`:
        return {
          ...state,
          messages: (action as fromActions.SetMessagesAction).payload
        };
      case `${prefix} ${fromActions.UPDATE_STATUS_SUCCESS}`: {
        return updateStatus(state, action as fromActions.UpdateStatusSuccessAction);
      }
      default:
        return state;
    }
  };
}
