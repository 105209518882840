<p-messages [closable]="true" [value]="(messages$ | async)" class="col-12"></p-messages>
<div class="p-g">
  <div class="decent-max-width margin-top-9">

      <cms-info ngPreserveWhitespaces>

        # PDF Settings

        The below settings impact the PDF generation for eSM invoices. A PDF for an eSM invoice can be generated by clicking the 'Document (PDF)' button in the eSM Invoice Finder or eSM Dashboard result list.

        On the PDF Settings page, a master operator or CMS administrator can configure

        * the invoice PDF to display a company logo (logo images can only be uploaded by a CMS administrator)
        * the invoice PDF to display one or two boilerplates with user defined text at a defined location (can be altered by master operators of the selected CMS organisation)

        PDF Settings are applied in the PDF generation process subject to the Supplier VAT ID of an invoice for which a PDF is generated:

        * if PDF Settings exist for the invoice supplier VAT ID, the invoice PDF is generated with the company logo and the text boilerplates stored for this (supplier) VAT ID
        * if no PDF Settings exist for the invoice supplier VAT ID, the PDF is generated without company logo and text boilerplates.

        Consult the sections 'The eSM menu/Document (PDF)' and 'The eSM menu/PDF Settings' of the CMS 5.3 user manual for more detailed information about the generation of PDF's for eSM invoices.

        # Screen items:

        * **PDF Invoice Logo**: If a company logo in the PNG format was provided to the Equias Support for the selected VAT ID, it will appear here and will be rendered into PDF invoices matching the selected VAT ID in the invoice supplier section
        * **Boilerplate 1+2**: The text provided for Boilerplate 1 and 2 will be rendered into the PDF at defined locations (Consult the section 'The eSM menu/PDF Settings' of the CMS 5.3 user manual for a more detailed description of the PDF layout). The number of characters which can be entered for each boilerplate is limited to 3000.
        * **Button 'Create'**: The button 'Create' is only shown if no PDF settings yet exist for a selected VAT ID. After hitting the 'Create' button, it is possible to provide PDF Settings. The button will be removed and replaced with the buttons 'Save' and 'Reset' afterwards.
        * **Button 'Save'**: Hitting the 'Save' button will save any changes conducted on the PDF Settings page.
        * **Button 'Reset'**: Hitting the 'Save' button will save any changes conducted on the PDF Settings page.

      </cms-info>

    <form [formGroup]="form"  >

      <cms-image-upload
        [masterDataId]="(pdfSetting$ | async).masterDataId"
        [organisationId]="organisationId"
        [uploadAllowed]="(presetValues$ | async).uploadAllowed"
        (error)="onUploadError($event)">
      </cms-image-upload>

        <div class="panel-box margin-top-15 ">

          <div class="flex align-items-center me-3 ms-3 mb-4" formGroupName="fha">
            <label class="text-nowrap mb-0 me-3" for="specific-invoice-template">Company specific invoice template:</label>
            <input formControlName="invoiceTemplate"
                   id="specific-invoice-template"
                   class="form-control input-text">
          </div>

          <div formGroupName="mo">
          <label class="col-12 margin-bottom-12 col-form-label">Invoice Boilerplate 1
            <textarea formControlName="invoiceBoilerplate1"
                      id="invoiceBoilerplate1TextArea"
                      type="text"
                      maxlength="4000"
                      [rows]="7"
                      [ngClass]="{'error': errors['boilerplate1']}"
                      class="form-control"></textarea></label>

          <label class="col-12 margin-bottom-12 col-form-label">Invoice Boilerplate 2
            <textarea formControlName="invoiceBoilerplate2"
                      id="invoiceBoilerplate2TextArea"
                      type="text"
                      maxlength="4000"
                      [rows]="7"
                      [ngClass]="{'error': errors['boilerplate2']}"
                      class="form-control field-big"></textarea></label>
          </div>

          <hr class="solid">

          <div class="flex align-items-center me-3 ms-3 mb-4 mt-4" formGroupName="fha">
            <label class="text-nowrap mb-0 me-3" for="specific-netting-template">Company specific netting template:</label>
            <input formControlName="nettingTemplate"
                   id="specific-netting-template"
                   class="form-control input-text">
          </div>

          <div formGroupName="mo">
          <label class="col-12 margin-bottom-12 col-form-label">Netting Boilerplate 1
            <textarea formControlName="nettingBoilerplate1"
                      id="nettingBoilerplate1TextArea"
                      type="text"
                      maxlength="4000"
                      [rows]="7"
                      [ngClass]="{'error': errors['ntt_boilerplate1']}"
                      class="form-control"></textarea></label>
          <label class="col-12 margin-bottom-12 col-form-label">Netting Boilerplate 2
            <textarea formControlName="nettingBoilerplate2"
                      id="nettingBoilerplate2TextArea"
                      type="text"
                      maxlength="4000"
                      [rows]="7"
                      [ngClass]="{'error': errors['ntt_boilerplate2']}"
                      class="form-control field-big"></textarea></label>

          </div>
          <ng-container *ngIf="(presetValues$ | async).uploadAllowed && !!(pdfSetting$|async).masterDataId; else mo">
            <div class="btn-container ps-1">
              <button pButton
                      class="btn margin-left-9"
                      id="saveButtonFha"
                      label="Save Templates"
                      type="submit"
                      (click)="onSaveTemplate()"></button>
              <button pButton
                      class="btn margin-left-9"
                      id="resetButtonFha"
                      label="Reset Templates"
                      (click)="resetSettings()"></button>
            </div>

          </ng-container>
        </div>
    </form>
  </div>

</div>


<ng-template #mo>

  <div class="btn-container ps-1">
    <button pButton
            class="btn margin-left-9"
            id="createButton"
            label="Create"
            [disabled]="!(presetValues$ | async).createAllowed || (newMDRecord$| async)"
            *ngIf="!(pdfSetting$ | async).masterDataId || (newMDRecord$| async)"
            (click)="createPdfSettings()"></button>
    <button pButton
            class="btn margin-left-9"
            id="saveButton"
            label="Save"
            type="submit"
            [disabled]="!(presetValues$ | async).editAllowed"
            *ngIf="(canEdit$ | async).moAllowed"
            (click)="onSave()"></button>
    <button pButton
            class="btn margin-left-9"
            id="resetButton"
            label="Reset"
            [disabled]="!(presetValues$ | async).editAllowed"
            *ngIf="(canEdit$ | async).moAllowed"
            (click)="resetSettings()"></button>
  </div>
</ng-template>
