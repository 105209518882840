import { createAction, union } from '@ngrx/store';
import {
  AddEditOrganisationGroup,
  EditBillingData, EditBillingFormData,
  VatCode
} from '../../administration-tab-panel/organisation-groups/organisation-groups.model';

export const exportAction = createAction('[ORGANISATION GROUPS] Export');
export const addOrgGroupAction = createAction('[ORGANISATION GROUPS] Add Org Group', (payload: AddEditOrganisationGroup) => ({payload}));
export const editOrgGroupAction = createAction('[ORGANISATION GROUPS] Edit Org Group', (payload: AddEditOrganisationGroup) => ({payload}));
export const deleteOrgGroupAction = createAction('[ORGANISATION GROUPS] Delete Org Group', (payload: { organisationGroupId: number}) => ({payload}));
export const generateApiKeyAction = createAction('[ORGANISATION GROUPS] Generate API key', (payload: { organisationGroupId: number}) => ({payload}));
export const editBillingData = createAction('[ORGANISATION GROUPS] Edit Billing Data', (payload: {displayName: string, data: EditBillingFormData}) => ({payload}));
export const loadEditBillingData = createAction('[ORGANISATION GROUPS] Load Edit Billing Data', (payload: { organisationGroupId: number}) => ({payload}));
export const loadEditBillingDataSuccess = createAction('[ORGANISATION GROUPS] Load Edit Billing Data Success', (payload: {
  data: EditBillingFormData,
  vatCodes: VatCode[]
}) => ({payload}));

const action = union({
  exportAction,
  addOrgGroupAction,
  editOrgGroupAction,
  deleteOrgGroupAction,
  generateApiKeyAction,
  editBillingData,
  loadEditBillingData,
  loadEditBillingDataSuccess
});

export type OrganisationGroupsActions = typeof action;

