import { Observable, throwError as observableThrowError } from 'rxjs';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ErrorMessageService } from './error-message.service';
import { ErrorInformation } from './error.model';
import { BaseResponseModel } from '../shared/shared.model';
import * as moment from 'moment';
import { OrganisationService } from '../organisation.service';
import { UserService } from '../user.service';
import { AuthenticationService } from '../../auth/authentication.service';
import { RequestLoggingService } from '@common/request-logging.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private readonly noValue = '-';

  constructor(
    private injector: Injector, // To prevent problems (repeated call of constructors which normally would be called only once) when constructor of services lead to http requests
    private router: Router,
    private requestLoggingService : RequestLoggingService,
    private errorMessageService: ErrorMessageService
  ) {
  }

  private get authenticationService(): AuthenticationService {
    return this.injector.get(AuthenticationService);
  }

  private get userService(): UserService {
    return this.injector.get(UserService);
  }

  private get organisationService(): OrganisationService {
    return this.injector.get(OrganisationService);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      tap((event: HttpEvent<any>)=> {
        this.errorMessageService.clear();
        if (event instanceof HttpResponse) {
          this.requestLoggingService.removeRequest(request.url);
        }
      }),
      catchError((httpErrorResponse: HttpErrorResponse) => {

        if(httpErrorResponse) {

          if (httpErrorResponse.status === 503) {
            // SERVICE_UNAVAILABLE: hide screen interaction
            this.errorMessageService.publish(this.createErrorInformation(httpErrorResponse));

          } else if (httpErrorResponse.status === 504) {
            // GATEWAY_TIMEOUT: Can not reach backend, come back later
            this.errorMessageService.publish(this.createErrorInformation(httpErrorResponse));
            this.router.navigate(['/error']);

          } else if (httpErrorResponse.status >= 500) {
            // INTERNAL_SERVER_ERROR: redirect to error page and show details
            this.errorMessageService.publish(this.createErrorInformation(httpErrorResponse));
            this.router.navigate(['/error']);

          } else if (httpErrorResponse.status === 400) {
            // BAD_REQUEST: handle invalid cookie request
            this.handleBadRequest(httpErrorResponse);

          } else if (httpErrorResponse.status === 401) {
            // UNAUTHORIZED: user not logged in, redirect to login
            this.authenticationService.handleAuthError(httpErrorResponse);

          } else if (httpErrorResponse.status === 403) {
            // FORBIDDEN: user not authorised, redirect to homepage
            this.authenticationService.onRequestForbidden();

          } else if (httpErrorResponse.status === 404) {
            this.router.navigate(['/not_found']);

          } else if (httpErrorResponse.status === 408 && this.requestLoggingService.isRequestOnCurrentPage(request.url)) {
            this.errorMessageService.publish(this.createErrorInformation(httpErrorResponse));
            this.router.navigate(['/error']);

          } else if (httpErrorResponse.status === 423) {
            // LOCKED: user not authorised, redirect to homepage
            this.authenticationService.handleAuthError(httpErrorResponse);
          }

          this.requestLoggingService.removeRequest(request.url);

          return observableThrowError(httpErrorResponse);
        }
      }
    ));
  }

  private createErrorInformation(httpErrorResponse: HttpErrorResponse): ErrorInformation {
    const baseResponseModel: BaseResponseModel = httpErrorResponse.error; // not guarantied

    let referenceCode: string = this.noValue;
    let errorMessage: string = this.noValue;
    if (baseResponseModel.errorMessage) {
      if (httpErrorResponse.status === 500) {
        referenceCode = baseResponseModel.errorMessage;
      } else {
        errorMessage = baseResponseModel.errorMessage;
      }
    }

    const user = (this.userService.getCurrentInformations() && this.userService.getCurrentInformations().userName) ||
      (this.authenticationService.getUser() && this.authenticationService.getUser().userName) || this.noValue;
    const cmsError: ErrorInformation = {
      timeOfEvent: moment(baseResponseModel.timestamp).parseZone().format('YYYY-MM-DD HH:mm:ss.SSSZ'),
      user,
      selectedOrg: this.formatSelectedOrg(),
      page: this.router.url,
      url: httpErrorResponse.url,
      httpStatusCode: httpErrorResponse.status,
      referenceCode: referenceCode,
      errorMessage: errorMessage,
      browser: navigator.userAgent
    };
    return cmsError;
  }

  private formatSelectedOrg() {
    if (this.organisationService.getSelectedOrganisationName()) {
      return this.organisationService.getSelectedOrganisationName();
    }
    if (this.organisationService.getCachedOrganisationId()) {
      return `ID: ${this.organisationService.getCachedOrganisationId() }`;
    }
    return this.noValue;
  }

  private handleBadRequest(httpErrorResponse: HttpErrorResponse): void {
    if(httpErrorResponse.error &&
      httpErrorResponse.error.message &&
      httpErrorResponse.error.message.lastIndexOf('Missing cookie') >= 0){

      this.router.navigate(['/'], { queryParamsHandling: 'preserve' });
    }
  }
}
