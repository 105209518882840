<h1>ACER Billing Transactions Report</h1>
<div class="panel-box">
  <p-messages [value]="messages$ | async" [closable]="true"></p-messages>
  <cms-billing-tx-report-filters />

  <div class="margin-top-18">
    <cms-billing-tx-datatable />
  </div>

  <p-dialog [(visible)]="generationWarningVisible" [modal]="true" header="Are you sure that you want to perform this action?" [closable]=false
            [style]="{width: '35%','max-width': '50rem','filter': 'drop-shadow(5px 5px 5px #777)'}">
    <p>The report will contain one million or more <br />transactions. Do you wish to proceed?</p>
    <div class="flex full-width justify-content-between">
      <button pButton class="btn block mt-3" (click)="cancelReportGeneration()" label="Cancel"></button>
      <button pButton class="btn block mt-3 ms-3" (click)="confirmReportGeneration()" label="Yes - Generate"></button>
    </div>
  </p-dialog>
  <p-dialog [(visible)]="noRecordsWarningVisible" [modal]="true" header="No records found" [closable]=false
            [style]="{width: '35%','max-width': '50rem','filter': 'drop-shadow(5px 5px 5px #777)'}">
    <p>The requested report does not contain any transactions.</p>
    <div class="flex full-width justify-content-end">
      <button pButton class="btn block mt-3" (click)="confirmNoRecords()" label="Ok"></button>
    </div>
  </p-dialog>
</div>
