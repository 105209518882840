import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SelectItem, SelectItemGroup } from 'primeng/api';
import { RemitTradeEntryTable2Service } from '../remit-trade-entry-table2.service';
import { RemitTradeEntryTable2FixingIndexDataModel } from '../remit-trade-entry-table2-data.model';
import { RemitTable2TradeFixingIndexModel } from '../../../trade/table2/modify/remit-table2-trade-entry.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cms-remit-trade-entry-table2-fixing-index',
  templateUrl: './remit-trade-entry-table2-fixing-index.component.html',
  styleUrls: ['./remit-trade-entry-table2-fixing-index.component.scss']
})
export class RemitTradeEntryTable2FixingIndexComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  formGroup: UntypedFormGroup;

  @Input()
  onReset: Subject<boolean>;

  @Input()
  data: RemitTable2TradeFixingIndexModel[];

  indexNames: SelectItemGroup[];
  indexTypes: SelectItemGroup[];
  fixingFrequencies: SelectItem[];
  fixingIndexData: RemitTradeEntryTable2FixingIndexDataModel;

  private onDestroy$ = new Subject();

  constructor(private formBuilder: UntypedFormBuilder, private tradeEntryService: RemitTradeEntryTable2Service) {
    this.onReset = new Subject<boolean>();
  }

  get fixingIndices(): AbstractControl[] {
    return (this.formGroup.controls['fixingIndices'] as UntypedFormArray).controls;
  }

  get disableAddButton(): boolean {
    return this.fixingIndices.length > 4 || this.formGroup.disabled;
  }

  get disableRemoveButton(): boolean {
    return this.fixingIndices.length < 1 || this.formGroup.disabled;
  }

  ngOnInit() {
    this.initForm();

    this.tradeEntryService.onTradeEntryDataChange().pipe(takeUntil(this.onDestroy$)).subscribe(formData => {
      if (formData && formData.fixingIndex) {
        this.fixingIndexData = formData.fixingIndex;
      }

      this.initFormValues();
    });

    this.onReset.subscribe(reset => {
      if (reset) {
        this.resetForm();
      }
    });

    this.tradeEntryService.onValidationFieldErrors().pipe(takeUntil(this.onDestroy$))
      .subscribe(
        fieldName => {
          if (fieldName && fieldName.indexOf('fixingIndices') >= 0) {
            const items = this.formGroup.controls['fixingIndices'] as UntypedFormArray;

            const parse = fieldName.split('.');
            const index = parse[0].charAt(parse[0].length - 1);
            const formGroup = items.controls[parseInt(index, 10)] as UntypedFormGroup;

            if (parse[1]) {
              switch (parse[1]) {
                case 'indexName':
                  formGroup.controls['indexName'].setErrors({ error: true });
                  break;
                case 'lastFixingDate':
                  formGroup.controls['lastFixingDate'].setErrors({ error: true });
                  break;
              }
            }
          }
        }
      );
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.fillFormWithData();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
    this.onReset.unsubscribe();
  }

  add() {

    const fixingIndices = this.formGroup.controls['fixingIndices'] as UntypedFormArray;

    if (fixingIndices.length < 5) {
      fixingIndices.push(
        this.formBuilder.group({
          indexName: [''],
          indexType: [''],
          indexSource: [''],
          firstFixingDate: [''],
          lastFixingDate: [''],
          fixingFrequency: ['']
        })
      );
    }
  }

  remove() {
    const fixingIndices = this.formGroup.controls['fixingIndices'] as UntypedFormArray;

    if (fixingIndices.length > 0) {
      fixingIndices.removeAt(fixingIndices.length - 1);
    }
  }

  convertKeyValueToSelectItem(keyValueData: any): SelectItem[] {
    if (keyValueData) {
      return keyValueData.map(method => ({
        label: method.value,
        value: method.key
      }));
    }
    return [];
  }

  getFixingIndexErrorClass(index: number, fieldName: string): string {

    const items = this.formGroup.get('fixingIndices') as UntypedFormArray;
    if (items && items.controls[index]) {

      const formGroup = items.controls[index] as UntypedFormGroup;

      if (formGroup.controls[fieldName].hasError('error')) {
        return ' error';
      }
    }

    return '';
  }

  private initForm() {

    this.formGroup.addControl('fixingIndices', this.formBuilder.array(
      []
    ));

  }

  private resetForm() {
    this.formGroup.removeControl('fixingIndices');
    this.initForm();
  }

  private initFormValues() {
    if (this.fixingIndexData) {
      this.indexNames = this.tradeEntryService.getGroupedValues(this.fixingIndexData.indexNames);
      this.indexTypes = this.tradeEntryService.getGroupedValues(this.fixingIndexData.indexTypes);
      this.fixingFrequencies = this.convertKeyValueToSelectItem(this.fixingIndexData.fixingFrequencies);

      this.fillFormWithData();
    }
  }

  private fillFormWithData() {
    if (this.formGroup && this.data) {

      const fixingIndices = this.formGroup.controls['fixingIndices'] as UntypedFormArray;

      if (fixingIndices) {
        let fixingIndex: RemitTable2TradeFixingIndexModel;
        for (let i = 0; i < this.data.length; i++) {

          if (!fixingIndices.at(i)) {
            this.add();
          }

          fixingIndex = {
            indexName: this.data[i].indexName,
            indexType: this.data[i].indexType,
            indexSource: this.data[i].indexSource,
            fixingFrequency: this.data[i].fixingFrequency,
          };

          if (this.data[i].firstFixingDate) {
            fixingIndex = {
              ...fixingIndex,
              firstFixingDate: new Date(this.data[i].firstFixingDate)
            };
          }
          if (this.data[i].lastFixingDate) {
            fixingIndex = {
              ...fixingIndex,
              lastFixingDate: new Date(this.data[i].lastFixingDate)
            };
          }

          fixingIndices.at(i).patchValue(fixingIndex);
        }
      }
    }
  }

}
