import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'cms-change-date',
  templateUrl: './change-date.component.html',
  styleUrls: ['./change-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeDateComponent implements OnInit, OnChanges {
  @Input() value: string;
  @Output() changeDate = new EventEmitter<string>();
  form: UntypedFormGroup;
  invalid: any;

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    const date = new Date(this.value);
    this.form = this.formBuilder.group({
      changeDate: new UntypedFormControl(this.value ? date : null, Validators.required),
    });

  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (this.form && changes.value) {
      const date = new Date(this.value);
      this.form.patchValue({
        changeDate: this.value ? date : null
      });
      this.form.updateValueAndValidity();
    }
  }

  onSubmit(): void {
    this.changeDate.emit(moment(this.form.value.changeDate).format('YYYY-MM-DD'));
  }
}
