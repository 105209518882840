<p-dialog *ngIf="display"
          header="{{'Delete Organisation ' + data.displayName}}"
          [visible]="display"
          [modal]="true"
          (visibleChange)="onVisibleChange($event)"
          [style]="{width: '650px', minWidth: '500px'}">
  <p-messages [value]="messages" [closable]="true"></p-messages>
  <div class="margin-15">
    <form [formGroup]="form" class="row" autocomplete="off">
      <div class="col-12 margin-bottom-12">
        <input type="checkbox" class="margin-right-15" id="changeToExternalParty" formControlName="changeToExternalParty" />
        <label for="changeToExternalParty" class="col-8">Change eRR party from internal to external:</label>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label for="reason" class="col-2 float-left">Reason:<span class="required">*</span></label>
        <div class="col-8">
          <textarea autocomplete="off" tabindex="5" col="3" id="reason" formControlName="reason" class="form-control col-8 padding-6" style="height: 4em;" [ngClass]="{'error': errors['reason']}"></textarea>
        </div>
      </div>
    </form>
  </div>
  <p-footer>
    <button class="btn margin-right-3" type="button" (click)="onCancel()">Cancel</button>
    <button class="btn" type="button" (click)="onConfirm()">Delete</button>
  </p-footer>
</p-dialog>
