<div class="panel-box decent-max-width">

  <form [formGroup]="form" class="row align-items-center col-12 margin-bottom-6" (submit)="onUpdateSearchFilter();">
    <div class="row col-12 decent-max-width">
      <div class="row col-3">
        <label class="col-12 text-medium mb-0 pe-0 text-nowrap" style="height: 30px;">Show data submitted</label>
        <span class="inline-block padding-right-3 pt-0">
          <p-calendar placeholder="Submission date"
                      formControlName="submissionDate"
                      [showIcon]="true"
                      [monthNavigator]="true"
                      [yearNavigator]="true"
                      dateFormat="dd/mm/yy"
                      [style]="{ 'width': '130px','margin-top': '-27px'}"
                      cmsDateRestriction>
          </p-calendar>
        </span>
      </div>

      <div class="col-3 padding-0">
        <label class="col-12 text-medium margin-bottom-3 ps-0">with document type</label>
        <p-multiSelect [options]="documentTypes"
                       [filter]="false"
                       formControlName="documentTypes"
                       selectedItemsLabel="All ({0})"
                       [maxSelectedLabels]="5"
                       [style]="{ 'width': '150px'}">
        </p-multiSelect>
      </div>

      <div class="col-3">
        <label class="margin-bottom-3">View by</label>
        <div class="col-12 padding-0">
          <label class="margin-right-9">
            <input type="radio" class="margin-right-3" name="reportingRole" value="MP" formControlName="reportingRole">
            MP
          </label>
          <label class="margin-right-9">
            <input type="radio" class="margin-right-3" name="reportingRole" value="SENDER" formControlName="reportingRole">
            Sender
          </label>
        </div>
      </div>
      <div class="col-3">
        <span class="padding-left-6 margin-top-18 inline-block">
            <button class="btn form-contro " style="width: 75px" type="submit" [disabled]="form.invalid || (filesOutService.getLoading() | async)">Search</button>
        </span>
      </div>
    </div>
  </form>
</div>
