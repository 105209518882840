<p-tabView [activeIndex]="activeIndex" (onChange)="onChangeTab($event)">
  <p-tabPanel header="In Progress">
    <div class="tab-view-body">
      <div class="tab-view-body">
        <cms-ecm-dashboard-deals-tab *ngIf="activeIndex === 0"></cms-ecm-dashboard-deals-tab>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Issue">
    <div class="tab-view-body">
      <div class="tab-view-body">
        <cms-ecm-dashboard-deals-tab *ngIf="activeIndex === 1"></cms-ecm-dashboard-deals-tab>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Complete">
    <div class="tab-view-body">
      <div class="tab-view-body">
        <cms-ecm-dashboard-deals-tab *ngIf="activeIndex === 2"></cms-ecm-dashboard-deals-tab>
      </div>
    </div>
  </p-tabPanel>
</p-tabView>
