import * as moment from 'moment';

type DigitValidator = (char: string) => boolean;

const anyValidator = char => true;
const numericValidator = char => /[0-9]/.test(char);
const lowerCaseValidator = char => /[a-z]/.test(char);
const upperCaseValidator = char => /[A-Z]/.test(char);
const numberRangeValidator = (maxValue: number, char: string) => numericValidator(char) && Number(char) <= maxValue;

export const neverValidator = char => false;
export const dateValidator = (date: string, minDate: Date = null, maxDate: Date = null): string | boolean => {
  const isDate = /[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(date);

  if (isDate) {
    const currentDate = moment(date, 'DD/MM/YYYY').toDate();
    if (minDate && minDate > currentDate) {
      return minDate.toLocaleDateString('en-GB');
    } else if (maxDate && maxDate < currentDate) {
      return maxDate.toLocaleDateString('en-GB');
    } else {
      return date;
    }

  } else {
    return false;
  }
};

export const maskDigitValidators: { [key: string]: DigitValidator } = {
  'a': lowerCaseValidator,
  'A': upperCaseValidator,
  '*': anyValidator
};

for (let i = 0; i <= 9; i++) {
  maskDigitValidators[i] = numberRangeValidator.bind(undefined, i);
}
