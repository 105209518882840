import { Message } from 'primeng/api';
import * as fromDtAction from './datatable.actions';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { Prefix } from '../reducers';

export interface DatatableState<T = any, Filters = any> {
  data: DatatableData<T[]>;
  messages: Message[];
  selected: T;
  filters: Filters;
  paging?: PagingFilter;
  sorting?: SortingFilter;
  loading: boolean;
}

export function datatableReducer(prefix: Prefix, initialState) {
  return function (state: DatatableState, action: fromDtAction.DatatableActions): DatatableState {
    switch (action.type) {
      case `${prefix} ${fromDtAction.SELECT}`:
        return {
          ...state,
          selected: (action as fromDtAction.SelectAction).payload
        };
      case `${prefix} ${fromDtAction.FILTER_DATA}`:
      case `${prefix} ${fromDtAction.SET_FILTERS}`:
        return {
          ...state,
          loading: true,
          messages: [],
          selected: null,
          filters: (action as fromDtAction.FilterDataAction).payload,
        };
      case `${prefix} ${fromDtAction.FILTER_DATA_SUCCESS}`:
        const payload = (action as fromDtAction.FilterDataSuccessAction).payload;
        return {
          ...state,
          loading: false,
          data: {
            count: payload.count,
            values: payload.values
          },
          paging: {
            ...state.paging,
            page: payload.currentPage
          }
        };
      case `${prefix} ${fromDtAction.SORT_AND_PAGE_DATA}`:
        return {
          ...state,
          loading: true,
          messages: [],
          paging: (action as fromDtAction.SortAndPageDataAction).payload.paging,
          sorting: (action as fromDtAction.SortAndPageDataAction).payload.sorting
        };
      case `${prefix} ${fromDtAction.SET_MESSAGES}`:
        return {
          ...state,
          loading: false,
          messages: [...(action as fromDtAction.SetMessagesAction).payload],
        };
      case `${prefix} ${fromDtAction.SET_TO_DEFAULT}`:
        return {
          ...initialState
        };
      default:
        return state;
    }
  };
}

export const getData = (datatableState: DatatableState): DatatableData<any[]> => datatableState.data;
export const getSorting = (state: DatatableState) => state.sorting;
export const getPaging = (state: DatatableState) => state.paging;
export const getLoading = (state: DatatableState) => state.loading;
export const getMessages = (state: DatatableState) => state.messages;
export const getSelected = (state: DatatableState) => state.selected;

