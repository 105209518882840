import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'cms-not-found-ids-warning',
  templateUrl: './not-found-ids-warning.component.html',
  styleUrls: ['./not-found-ids-warning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotFoundIdsWarningComponent implements OnChanges {
  @Input() ids: string[] = [];
  @Input() allCount: number;
  open = false;

  onClick(event): void {
    this.open = !this.open;
    event.preventDefault();
  }

  ngOnChanges(): void {
    this.open = false;
  }
}
