import { NgModule } from '@angular/core';

import { DatePipe } from './date/date.pipe';
import { YesNoPipe } from './boolean/yes-no.pipe';
import { SplitPipe } from './string/split.pipe';
import { PercentagePipe } from './numbers/precentage.pipe';
import { AbsolutePipe } from './numbers/absolute.pipe';
import { SplitSpace } from './string/split-space.pipe';
import { SumPipe } from './numbers/sum.pipe';

@NgModule({
  declarations: [
    DatePipe,
    YesNoPipe,
    SplitPipe,
    SplitSpace,
    PercentagePipe,
    AbsolutePipe,
    SumPipe
  ],
  exports: [
    DatePipe,
    YesNoPipe,
    SplitPipe,
    PercentagePipe,
    AbsolutePipe,
    SplitSpace,
    SumPipe
  ]
})

export class PipeModule {
}

