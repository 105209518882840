import * as fromContacts from '../contacts/contacts.reducers';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

export type Prefix = '[ORG GROUPS CONTACTS]' | '[USERS CONTACTS]';

export const getHelpState = createFeatureSelector<State>('help');

export interface State {
  contacts: fromContacts.ContactsState;
}

export const reducers: ActionReducerMap<State> = {
  contacts: fromContacts.contactsReducer
};
