<p-toast key="sum" position="top-right" [showTransformOptions]="'translateX(100%)'" [hideTransformOptions]="'translateX(100%)'" [hideTransitionOptions]="'150ms'">

  <ng-template pTemplate="message">

    <p-panel header="Selected Invoice Amounts">

      <ng-template pTemplate="icons">
        <button pButton icon="fa fa-times" style="border-radius: 5px; background-color:  rgb(89, 182, 217)" (click)="close()"></button>
      </ng-template>

      <p-table [value]="result" id="calculation">
        <ng-template pTemplate="header"><tr><th>Gross Amount</th><th>Vat Amount</th></tr></ng-template>
        <ng-template pTemplate="body" let-select><tr>
          <td class="!font-bold">{{select.gross | number:'1.2-2'}}&nbsp;{{select.grossCur}}</td>
          <td>{{select.vat | number:'1.2-2'}}&nbsp;{{select.vatCur}}</td>  <!-- &nbsp;({{select.vatRat | percentage}}) -->
        </tr>
        </ng-template>
      </p-table>

    </p-panel>

  </ng-template>

</p-toast>
