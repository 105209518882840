import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { Column, DefaultValues, PagerInfo } from '../shared/datatable.model';
import { PagingFilter, SortOrder } from '../shared/results.model';
import { DateUtils } from '../shared/date.utils';
import { AudittrailDataModel, AudittrailDatatableModel } from './audittrail-datatable.model';
import { AudittrailDatatableConfig } from './audittrail-datatable.config';
import { AudittrailSearchQuery, AudittrailService } from './audittrail.service';
import * as FileSaver from 'file-saver';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { DatatableState } from '@common/datatable/datatable.model';

@Component({
  selector: 'cms-audittrail-datatable',
  templateUrl: './audittrail-datatable.component.html',
  styleUrls: ['./audittrail-datatable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AudittrailDatatableComponent implements OnInit, OnDestroy {
  @ViewChild(Table, { static: true }) dataTableComponent: Table;

  showMessagingDetails: boolean;
  showProcessingDetails: boolean;

  private onDestroy$ = new Subject();
  private tableColumns: Column[];
  private defaults = new DefaultValues();
  rowsPerPage = this.defaults.getRowsPerPage();
  rowsPerPageOptions = this.defaults.getRowsPerPageOptions();

  isEcmAuditTrail: boolean = false; // for ecm audit trail
  isEsmAuditTrail: boolean = false;

  pagerInfo: PagerInfo;
  currentSortField: string = AudittrailDatatableConfig.getDefaultSortingColumn();
  loading: boolean;
  sortOrder: SortOrder = 'DESCENDING';

  dealsResult: AudittrailDatatableModel = {// TODO CMSGUI-264 mr2 2018-01: rename? dealsResult seems to be copy&paste relict from CpmlDataTableComponent (find names which work in all cases?)
    rows: [], recordCount: 0, searchQuery: null, currentPage: 0,
  };

  constructor(private auditTrailService: AudittrailService, private changeDetectorRef: ChangeDetectorRef, private router: Router) {
  }

  ngOnInit(): void {
    this.initCheckBoxes();
    this.loading = false;
    this.pagerInfo = PagerInfo.calcPagerInfo(0, 0, 10);
    this.tableColumns = this.getTableColumns();

    this.auditTrailService.getSearchQueryChangeObservable()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(query => this.loadAuditTrail(query));
  }

  initCheckBoxes(): void {
    this.isEsmAuditTrail = this.router.url.includes('esm');
    this.isEcmAuditTrail = this.router.url.includes('ecm');
    if (this.isEsmAuditTrail || this.isEcmAuditTrail) {
      this.showMessagingDetails = false;
      this.showProcessingDetails = false;
    } else {
      this.showMessagingDetails = true;
      this.showProcessingDetails = true;
    }

  }

  onLazyLoad({ page, perPage: entriesPerPage, sortOrder }: DatatableState) {
    const pagingFilter: PagingFilter = {
      entriesPerPage,
      page
    };

    const query = this.auditTrailService.getCurrentSearchQuery();
    query.sortOrder = sortOrder;
    query.paging = pagingFilter;
    this.auditTrailService.updateSearchQuery(query);
  }

  updateShowMessagingDetails(event): void {
    this.showMessagingDetails = !this.showMessagingDetails;
    this.auditTrailService.updateMessagingDetails(this.showMessagingDetails);
  }

  updateShowProcessingDetails(event): void {
    this.showProcessingDetails = !this.showProcessingDetails;
    this.auditTrailService.updateProcessingDetails(this.showProcessingDetails);
  }

  lookupRowStyleClass(rowData) {
    switch (rowData.severity) {
      case 'ERROR':
        return 'audit-row-error';
      case 'WARN':
        return 'audit-row-warn';
      case 'INFO':
        return 'audit-row-info';
    }
  }

  get cols() {
    return this.tableColumns;
  }

  get rows() {
    return this.dealsResult.rows;
  }

  get page() {
    return this.dealsResult.currentPage;
  }

  hasData(): boolean {
    return this.dealsResult.rows.length > 0;
  }

  get numberOfTotalRows() {
    return this.dealsResult.recordCount;
  }

  getDefaultColumns(): string[] {
    return AudittrailDatatableConfig.getDefaultColumns();
  }

  getTableColumns(): Column[] {
    return AudittrailDatatableConfig.getColumns(this.getDefaultColumns());
  }

  public downloadAuditTrail(): void {
    this.auditTrailService.downloadAuditTrail()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(data => {
        FileSaver.saveAs(data.blob, data.filename);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  private format(data: AudittrailDatatableModel): AudittrailDatatableModel {
    const rows: AudittrailDataModel[] = data.rows.map((row: AudittrailDataModel) => ({
      ...row,
      timestamp: DateUtils.transformDateWithTimestamp(row.timestamp)
    }));
    return { ...data, rows };
  }

  private loadAuditTrail(searchQuery: AudittrailSearchQuery) {
    if (searchQuery !== null) {
      const resultsPerPage = searchQuery.paging.entriesPerPage;
      this.loading = true;
      this.changeDetectorRef.markForCheck();
      searchQuery.messagingDetails = this.showMessagingDetails;

      this.auditTrailService.fetchAuditTrail(searchQuery, searchQuery.urlPart)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result: AudittrailDatatableModel) => {
          const currentPage = (result.currentPage > -1) ? result.currentPage : searchQuery.paging.page;
          this.loading = false;
          this.dealsResult = this.format(result);
          this.pagerInfo = PagerInfo.calcPagerInfo(result.recordCount, currentPage, resultsPerPage);
          this.changeDetectorRef.markForCheck();
        });
    }
  }

}
