import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { InfoComponent } from './info/info.component';
import { ApiRequestService } from './api-request.service';
import { HeadlineComponent } from './headline/headline.component';
import { TransactionSheetComponent } from './sheet/transaction-sheet.component';
import { OrgSelectorComponent } from './nav/org-selector/org-selector.component';
import { TransactionSheetGroupComponent } from './sheet/transaction-sheet-group.component';
import { TransactionSheetGroupItemComponent } from './sheet/transaction-sheet-group-item.component';
import { MarkdownModule, MARKED_OPTIONS } from 'ngx-markdown';
import { UpperCaseDirective } from './upper-case.directive';
import { PipeModule } from '../pipe/pipe.module';
import { DatatableComponent } from './datatable/datatable.component';
import { CellEditorComponent } from './datatable/cell-editor/cell-editor.component';
import { CellEditorDirective } from './datatable/cell-editor/cell-editor.directive';
import { CellStatusComponent } from './datatable/cell-status/cell-status.component';
import { DeleteFilterComponent } from '@common/my-filters/filters-management-dialog/delete-filter/delete-filter.component';
import { FiltersManagementDialogComponent } from '@common/my-filters/filters-management-dialog/filters-management-dialog.component';
import { FiltersManagementMenuComponent } from '@common/my-filters/filters-management-menu/filters-management-menu.component';
import { SaveMyCurrentDialogComponent } from '@common/my-filters/save-my-current-dialog/save-my-current-dialog.component';
import { DashboardDealsNavComponent } from './dashboard-deals-nav/dashboard-deals-nav.component';
import {
  TableCaptionComponent,
  AddNoteDialogComponent,
  NotesCellComponent,
  ViewConfirmationNotesDialogComponent,
  NoteComponent,
  TerminateDealDialogComponent,
  DocumentHiddenCellComponent,
  EcmLiteCancelComponent,
} from '@common/deals';
import { FileFiltersPanelComponent } from './file-filters-panel/file-filters-panel.component';
import { UploadPanelComponent } from './upload-panel/upload-panel.component';
import { IdFiltersComponent } from '@common/id-filters/id-filters.component';
import { AdditionalDateRangeComponent } from './additional-date-range/additional-date-range.component';
import { HideConfirmationDialogComponent } from './deals/hide-confirmation-dialog/hide-confirmation-dialog.component';
import { UnhideConfirmationDialogComponent } from './deals/unhide-confirmation-dialog/unhide-confirmation-dialog.component';
import { NonEditableTextareaComponent } from './non-editable-textarea/non-editable-textarea.component';
import { TableModule } from 'primeng/table';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { MessagesModule } from 'primeng/messages';
import { MenuModule } from 'primeng/menu';
import { CalendarModule } from 'primeng/calendar';
import { PanelModule } from 'primeng/panel';
import { InputMaskModule } from 'primeng/inputmask';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TrimValueAccessorDirective } from '@common/trim-value-accessor/trim-value-accessor.directive';
import { NotFoundIdsWarningComponent } from '@common/not-found-ids-warning/not-found-ids-warning.component';
import { EcmNearMatchCellComponent } from '@common/deals/ecm-near-match-cell/ecm-near-match-cell.component';
import { NearMatchButtonComponent } from './near-match-button/near-match-button.component';
import { MisMatchButtonComponent } from './mis-match-button/mis-match-button.component';
import { DigitOnlyDirective } from './directives/digit-only.directive';
import { CalendarDirective } from '@common/directives/calendar/calendar.directive';
import { RemitTransResolver } from '@common/remit-trans-resolver/remit-trans-resolver.service';
import { RequestLoggingService } from '@common/request-logging.service';
import { OnlyNumberDirective } from '@common/directives/simple-digets-only.directive';
import { TooltipModule } from 'primeng/tooltip';
import { BackButtonComponent } from './back-button/back-button.component';



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PanelModule,
        OverlayPanelModule,
        ProgressSpinnerModule,
        AccordionModule,
        DropdownModule,
        TableModule,
        PipeModule,
        RouterModule,
        MessagesModule,
        DialogModule,
        MenuModule,
        ButtonModule,
        MultiSelectModule,
        CalendarModule,
        InputMaskModule,
        MarkdownModule.forRoot({
            markedOptions: {
                provide: MARKED_OPTIONS,
                useValue: {
                    gfm: true,
                    tables: true,
                    breaks: false,
                    pedantic: false,
                    sanitize: false,
                    smartLists: true,
                    smartypants: false,
                },
            },
        }),
        TooltipModule,
        // MarkdownToHtmlModule.forRoot()
    ],
  declarations: [
    DigitOnlyDirective,
    OnlyNumberDirective,
    CalendarDirective,
    InfoComponent,
    HeadlineComponent,
    OrgSelectorComponent,
    TransactionSheetComponent,
    TransactionSheetGroupComponent,
    TransactionSheetGroupItemComponent,
    DatatableComponent,
    CellEditorComponent,
    CellStatusComponent,
    CellEditorDirective,
    UpperCaseDirective,
    DeleteFilterComponent,
    FiltersManagementDialogComponent,
    FiltersManagementMenuComponent,
    SaveMyCurrentDialogComponent,
    DashboardDealsNavComponent,
    BackButtonComponent,
    TableCaptionComponent,
    AddNoteDialogComponent,
    DocumentHiddenCellComponent,
    ViewConfirmationNotesDialogComponent,
    NoteComponent,
    NotesCellComponent,
    TerminateDealDialogComponent,
    EcmNearMatchCellComponent,
    EcmLiteCancelComponent,
    FileFiltersPanelComponent,
    UploadPanelComponent,
    IdFiltersComponent,
    AdditionalDateRangeComponent,
    HideConfirmationDialogComponent,
    UnhideConfirmationDialogComponent,
    NonEditableTextareaComponent,
    TrimValueAccessorDirective,
    NotFoundIdsWarningComponent,
    NearMatchButtonComponent,
    MisMatchButtonComponent
  ],
  providers: [
    RemitTransResolver,
    ApiRequestService,
    RequestLoggingService
  ],
  exports: [
    CalendarDirective,
    DigitOnlyDirective,
    OnlyNumberDirective,
    CalendarDirective,
    InfoComponent,
    HeadlineComponent,
    OrgSelectorComponent,
    TransactionSheetComponent,
    DatatableComponent,
    CellEditorComponent,
    CellEditorDirective,
    CellStatusComponent,
    UpperCaseDirective,
    DeleteFilterComponent,
    FiltersManagementDialogComponent,
    FiltersManagementMenuComponent,
    SaveMyCurrentDialogComponent,
    DashboardDealsNavComponent,
    BackButtonComponent,
    TableCaptionComponent,
    AddNoteDialogComponent,
    DocumentHiddenCellComponent,
    ViewConfirmationNotesDialogComponent,
    NoteComponent,
    NotesCellComponent,
    TerminateDealDialogComponent,
    EcmNearMatchCellComponent,
    EcmLiteCancelComponent,
    FileFiltersPanelComponent,
    UploadPanelComponent,
    IdFiltersComponent,
    AdditionalDateRangeComponent,
    HideConfirmationDialogComponent,
    UnhideConfirmationDialogComponent,
    NonEditableTextareaComponent,
    TrimValueAccessorDirective,
    NotFoundIdsWarningComponent,
    NearMatchButtonComponent,
    MisMatchButtonComponent
  ]
})

export class CmsCommonModule { }
