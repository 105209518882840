import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Message } from 'primeng/api';
import { InvoiceTypeModel } from '../invoice-settings.model';

@Component({
  selector: 'cms-settings-dialog',
  templateUrl: './invoice-settings-dialog.component.html',
  styleUrls: ['./invoice-settings-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceSettingsDialogComponent implements OnInit {
  @Input() data: InvoiceTypeModel;
  @Input() displayDialog: boolean;
  @Input() messages: Message[];
  @Output() cancel = new EventEmitter();
  @Output() activateItem = new EventEmitter<number>();
  @Output() blockItem = new EventEmitter<number>();
  action: string;
  isActivated: boolean;

  ngOnInit(): void {
    this.isActivated = this.data.doWeMatch;
    this.action = this.isActivated ? 'Block' : 'Activate';
  }

  onCancel(): void {
    this.cancel.emit();
  }

  onConfirm(): void {
    const invoiceTypeId = this.data.invoiceTypeId;
    this.isActivated ? this.blockItem.emit(invoiceTypeId) : this.activateItem.emit(invoiceTypeId);
  }
  onVisibleChange(visible: boolean): void {
    if (!visible) {
      this.onCancel();
    }
  }

}
