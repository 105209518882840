import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import { Message } from 'primeng/api';
import * as FileSaver from 'file-saver';
import { getFilenameFromHeader } from '@common/deals/deals.model';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { ApiRequestService } from '@common/api-request.service';
import { BillingReportsEnum, BillingReportsParameters } from '../../administration-tab-panel/billing-reports/billing-reports.model';
import { FhaAccountsState } from '../fha-accounts/fha-accounts.reducers';
import { ErrorResponse } from '@common/error-response.model';
import * as fromActions from './billing-reports.actions';
import { DateRangeResponse } from '@common/shared/shared.model';

@Injectable()
export class BillingReportsEffects {
  private baseUrl = '/api/administration/billingreports';

  constructor(private apiGateway: ApiRequestService,
              private actions$: Actions,
              private store$: Store<FhaAccountsState>,
              private mapper: MessagesMapperService) { }


  getDateRange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getDateRangeAction),
      concatMap(() =>
        this.apiGateway.get('/api/administration/billingreports/presetValues')
          .pipe(
            map((response: DateRangeResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              const {start, end} = response;
              if (messages && messages.length) {
                return fromActions.setMessagesAction(messages);
              }
              const responseRange = {
                start: new Date(start),
                end: new Date(end)
              };
              return fromActions.setDateRangeAction(responseRange);
            }),
            catchError(error =>
              of(fromActions.setMessagesAction([this.mapper.createErrorMessage(error.errorMessage || 'Server Error')]))
            ))
      ))
  );

   validate$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.validateBillingReportsAction.type),
      switchMap((action: ReturnType<typeof fromActions.validateBillingReportsAction>) => {
        const { startDate, endDate, reportType } = action.payload;

        return this.apiGateway.get(`${this.baseUrl}/validate`, this.getHttpParams(action.payload))
          .pipe(
            switchMap((response: ErrorResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              const result: Action[] = [];
              if (messages && messages.length) {
                result.push(fromActions.setMessagesAction(messages));
                result.push(fromActions.validationFailedAction(reportType));
              } else {
                result.push(...[
                  fromActions.downloadBillingReportAction({ startDate, endDate, reportType }),
                  fromActions.setMessagesAction([{ severity: 'info', detail: 'Generating billing report...'}])
                ]);
              }
              return result;
            }),
            catchError(() => [
              fromActions.validationFailedAction(reportType),
              fromActions.setMessagesAction([this.mapper.createErrorMessage('Server error')])
            ])
          );
      })
    ));

   downloadReport$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.downloadBillingReportAction.type),
      switchMap((action: ReturnType<typeof fromActions.downloadBillingReportAction>) => {
        const { reportType } = action.payload;
        return this.apiGateway
          .getBlob(`${this.baseUrl}/${BillingReportsEnum[reportType]}`, null, this.getHttpParams(action.payload))
          .pipe(
            concatMap((response: any) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [fromActions.setMessagesAction(messages)];
              }

              const filename = getFilenameFromHeader(response.headers);
              const blob: Blob = response.body;
              FileSaver.saveAs(blob, filename);

              return [
                fromActions.downloadBillingReportSuccessAction(reportType),
                fromActions.setMessagesAction([])
              ];
            }),
            catchError(() => of(fromActions.setMessagesAction([this.mapper.createErrorMessage('Server error')])))
          );
      })
    ));

  private getHttpParams(param: BillingReportsParameters): HttpParams {
    const { startDate, endDate, reportType } = param;
    let params = new HttpParams();
    if (startDate) {
      params = params.set('startDate', startDate);
    }
    if (endDate) {
      params = params.set('endDate', endDate);
    }
    return params;
  }
}
