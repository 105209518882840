<div class="datatable-container">
  <cms-datatable selectionMode="single"
                 sortOrder="DESCENDING"
                 [data]="records"
                 [totalRecords]="recordCount"
                 [tableColumns]="cols"
                 [sortField]="sortBy"
                 [paginator]="true"
                 [loading]="(filesOutService.getLoading() | async)"
                 [lazy]="!!(records && records.length)"
                 [hidden]="hidden"
                 [autoLayout]="true"
                 [scrollable]="false"
                 (lazyLoad)="onLazyLoad($event)">
    <ng-template pTemplate="customBody" let-row="row" let-rowIndex="rowIndex">
      <tr>
        <ng-container *ngFor="let col of cols">
          <td *ngIf="col.visible && col.name === 'buttons_in'" [ngStyle]="col.style">
            <div class="btn-wrapper inline-block">
              <a routerLink="/api/err/remit/filesout/document/xml" style="padding: 3px;" [queryParams]="{'auditGroupId': row.auditGroupId, 'organisationId': organisationId, 'isIn': false}" title="Report File (XML)" target="_blank" download>
                <button pButton type="button" icon="fa fa-file-code-o"></button>
              </a>

              <a routerLink="/err/remit_filesout_audittrail" title="Audit Trail" target="_blank" [queryParams]="createAuditTrailQueryParams(row)">
                <button pButton type="button" icon="fa fa-search"></button>
              </a>
            </div>
          </td>
          <td *ngIf="col.visible && col.name === 'buttons_out'" [ngStyle]="col.style">
            <div class="btn-wrapper inline-block" *ngIf="row.responseTimestamp">
              <a routerLink="/api/err/remit/filesout/document/xml" [queryParams]="{'auditGroupId': row.auditGroupId,  'organisationId': organisationId,  'isIn': true}" title="Receipt (XML)" target="_blank" download>
                <button pButton type="button" icon="fa fa-file-code-o"></button>
              </a>
            </div>
          </td>
          <td *ngIf="col.visible && !col.editable && col.name !== 'buttons_out' && col.name !== 'buttons_in'" [ngStyle]="col.style">
            <span>{{row[col.name]}}</span>
          </td>
          <td pEditableColumn *ngIf="col.visible && col.editable" [ngStyle]="col.style">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input type="text" [(ngModel)]="row[col.name]" class="form-control">
              </ng-template>
              <ng-template pTemplate="output">
                <span>{{row[col.name]}}</span>
              </ng-template>
            </p-cellEditor>
          </td>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="customEmptyMessage">
      <tr>
        <td colspan="100" *ngIf="!(isloading | async)">
          No records found
        </td>
      </tr>
    </ng-template>
  </cms-datatable>
</div>
