import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { Message } from 'primeng/api';
import { EditEsm, EsmPresetValuesResponse, Organisation } from '../../organisations.model';

@Component({
  selector: 'cms-edit-esm-dialog',
  templateUrl: './edit-esm-dialog.component.html',
  styleUrls: ['./edit-esm-dialog.component.scss']
})
export class EditEsmDialogComponent implements OnInit, OnChanges {
  @Input() data: Organisation;
  @Input() presetValues: EsmPresetValuesResponse;
  @Input() display = false;
  @Input() messages: Message[];
  @Output() displayChange = new EventEmitter<boolean>();
  @Output() confirm = new EventEmitter<EditEsm>();
  @Output() cancel = new EventEmitter<Message[]>();
  errors: {[key: string]: ValidationErrors} = {};
  form: UntypedFormGroup;
  defaultPresetValues: EsmPresetValuesResponse = {
    partnerId: null,
    esmEnabled: true,
    interoperationParty: false,
    webUser: false,
  };

  checkStatusInterop(): void {
    if (this.form.controls['webUser'].value) {
      this.form.controls['interoperationParty'].reset({ value: false, disabled: true });
    } else {
      this.form.controls['interoperationParty'].reset({ value: false, disabled: false });
    }
  }

  checkStatusWebUser(): void {
    if (this.form.controls['interoperationParty'].value) {
      this.form.controls['webUser'].reset({ value: false, disabled: true });
    } else {
      this.form.controls['webUser'].reset({ value: false, disabled: false });
    }
  }

  constructor(private formBuilder: UntypedFormBuilder,
              private mapper: MessagesMapperService) {
  }

  onCancel(): void {
    this.hide();
    this.cancel.emit(this.mapper.toWarningMessages(`Editing of organisation '${this.data.displayName}' was cancelled.`));
  }

  onVisibleChange(visible: boolean): void {
    if (!visible) {
      this.onCancel();
    }
  }

  onConfirm(): void {
    const { esmEnabled, partnerId, interoperationParty, webUser } = this.form.value;
    this.confirm.emit({
      esmEnabled,
      partnerId: partnerId || null,
      selectedOrganisationId: this.data.orgId,
      interoperationParty: interoperationParty,
      webUser: webUser,
    });
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      ...(this.presetValues || this.defaultPresetValues)
    });
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (this.form) {
      if (changes.display && !this.display) {
        this.hide();
      }
      if (changes.data) {
        this.form.patchValue({
          ...(this.presetValues || this.defaultPresetValues)
        });
        this.form.updateValueAndValidity();
      }
    }

    if (changes.messages) {
      this.errors = {};
      if (this.messages) {
        this.messages.forEach(({ key: controlName }: Message) => this.errors[controlName] = { 'server': true });
      }
    }
  }

  private hide(): void {
    this.messages = [];
    this.display = false;
    this.displayChange.emit(this.display);
    this.form.reset();
    this.form.updateValueAndValidity();
  }
}
