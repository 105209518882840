<div class="table-container m-3 panel-box">

<h1>Invoice Mails Report of ACER Billing Run {{quarter}}</h1>

<section >
  <cms-datatable [scrollable]="false"
                 [tableColumns]="columns"
                 [currentPage]="(paging$ | async).page"
                 [rows]="(paging$ | async)?.entriesPerPage"
                 [sortField]="(sorting$ | async).columnName"
                 [sortOrder]="(sorting$ | async).sortOrder"
                 [loading]="(loading$ | async)"
                 [data]="(data$ | async)?.values"
                 [totalRecords]="(data$ | async)?.count"
                 [autoLayout]="true"
                 [lazy]="true"
                 [lazyLoadOnInit]="false"
                 (lazyLoad)="onLazyLoad($event)">

    <ng-template pTemplate="caption">

      <div style="text-align: right">
        <button pButton class="btn reload_btn" type="button" title="reload table"
                [icon]="(loading$ | async)?'fa fa-refresh fa-spin':'fa fa-refresh'"
                id="acer-billing-mail-report-reload-button"
                (click)="refreshTable()"></button></div>
    </ng-template>

    <ng-template pTemplate="customEmptyMessage">

      <tr>
        <td colspan="100" *ngIf="!(loading$ | async)">
          No records found</td></tr>

    </ng-template>

  </cms-datatable>

</section>
</div>
