import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ExceptionsComponent } from './exceptions/exceptions.component';
import { ErrSendingFailuresComponent } from './err-sending-failures/err-sending-failures.component';
import { SendingFailuresComponent } from './sending-failures/sending-failures.component';

const routes: Routes = [
  { path: 'exceptions', component: ExceptionsComponent },
  { path: 'sendingfailures_err', component: ErrSendingFailuresComponent },
  { path: 'sendingfailures', component: SendingFailuresComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProblemSolvingRoutingModule { }
