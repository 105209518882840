import { createReducer, on } from '@ngrx/store';
import { Message } from 'primeng/api';
import { InvoiceTypeModel } from '../../../../settings/invoice/invoice-settings.model';
import * as fromTableActions from './table.actions';
import * as fromAddPanelActions from '../add-panel/add-panel.actions';
import * as fromActions from '../invoice-settings.actions';

export interface EsmSettingsTableState {
  messages: Message[];
  loading: boolean;
  data: InvoiceTypeModel[];
  toggleMatchAllowed: boolean;
  editTypesAllowed: boolean;
}

export const tableInitialState: EsmSettingsTableState = {
  messages: [],
  loading: false,
  data: null,
  toggleMatchAllowed: false,
  editTypesAllowed: false
};

export const esmTableReducer = createReducer<EsmSettingsTableState,
  fromTableActions.EsmSettingsTableActions>(
  tableInitialState,
  on(fromTableActions.setMessagesAction, (state: EsmSettingsTableState, { payload: messages }): EsmSettingsTableState => ({
    ...state,
    messages,
  })),
  on(fromAddPanelActions.cleanAddProductAction, (state: EsmSettingsTableState): EsmSettingsTableState => ({
    ...state,
    messages: [],
  })),
  on(fromActions.setToDefaultAction, (state: EsmSettingsTableState): EsmSettingsTableState => ({
    ...state,
    messages: [],
  })),
  on(fromTableActions.loadTableDataAction, (state: EsmSettingsTableState): EsmSettingsTableState => ({
    ...state,
    loading: true
  })),
  on(fromTableActions.loadTableDataSuccessAction, (state: EsmSettingsTableState, { payload: data }): EsmSettingsTableState => ({
    ...state,
    data,
    loading: false
  })),
  on(fromTableActions.setToggleMatchAllowed, (state: EsmSettingsTableState, { payload: toggleMatchAllowed }): EsmSettingsTableState => ({
    ...state,
    toggleMatchAllowed
  })),
  on(fromTableActions.setEditTypesAllowed, (state: EsmSettingsTableState, { payload: editTypesAllowed }): EsmSettingsTableState => ({
    ...state,
    editTypesAllowed
  }))
);
