<div class="option-details">
  <div class="form-title">Trade | Option Details</div>
  <div class="form-body" [formGroup]="formGroup">
    <div formArrayName="optionDetails">
      <div *ngFor="let optionDetail of optionDetails; let i = index" [formGroupName]="i">

        <div class="row gutter-0 !me-[40px]">
          <div class="col-5 label">Option Style {{ i + 1 }}</div>
          <div class="col-7 ps-2 pe-1">
            <p-dropdown formControlName="optionStyle" [options]="optionStyles" [style]="{'width': '100%'}"
                        [styleClass]="'margin-3 '"></p-dropdown>
          </div>
        </div>
        <div class="row gutter-0 !me-[40px]">
          <div class="col-5 label">Option Type {{ i + 1 }}</div>
          <div class="col-7 ps-2 pe-1">
            <p-dropdown formControlName="optionType" [options]="optionTypes" [style]="{'width': '100%'}"
                        [styleClass]="'margin-3 '"></p-dropdown>
          </div>
        </div>
        <div class="row gutter-0 !me-[40px]">
          <div class="col-5 label">First Exercise Date {{ i + 1 }}</div>
          <div class="col-7 ps-2 ">
            <span class="inline-block margin-3 pe-1">
              <p-calendar
                formControlName="firstExerciseDate"
                [showIcon]="true"
                [monthNavigator]="true"
                [yearNavigator]="true"
                placeholder="dd/mm/yyyy"
                dateFormat="dd/mm/yy"
                [style]="{ 'width': '125px'}"
                cmsDateRestriction>
              </p-calendar>
            </span>
          </div>
        </div>
        <div class="row gutter-0 !me-[40px]">
          <div class="col-5 label">Last Exercise Date {{ i + 1 }}</div>
          <div class="col-7 ps-2">
            <span class="inline-block margin-3 pe-1">
              <p-calendar
                formControlName="lastExerciseDate"
                [showIcon]="true"
                [monthNavigator]="true"
                [yearNavigator]="true"
                placeholder="dd/mm/yyyy"
                dateFormat="dd/mm/yy"
                [styleClass]="getOptionDetailIndexErrorClass(i, 'lastExerciseDate')"
                [style]="{ 'width': '125px'}"
                cmsDateRestriction>
              </p-calendar>
            </span>
          </div>
          <span *ngIf="getOptionDetailIndexErrorClass(i, 'lastExerciseDate')"
                class="fa fa-info-circle absolute error-marker"></span>
        </div>
        <div class="row gutter-0 !me-[40px]">
          <div class="col-5 label">Exercise Frequency {{ i + 1 }}</div>
          <div class="col-7 ps-2 pe-1">
            <p-dropdown formControlName="exerciseFrequency" [options]="exerciseFrequencies" [style]="{'width': '100%'}"
                        [styleClass]="'margin-3 '"></p-dropdown>
          </div>
        </div>
        <div class="row gutter-0 !me-[40px]">
          <div class="col-5 label">Strike Index {{ i + 1 }}</div>
          <div class="col-7 ps-2 pe-1">
            <p-dropdown formControlName="strikeIndex" [options]="strikeIndices" [group]="true" [style]="{'width': '100%'}"
                        [styleClass]="'margin-3 '" class="nowrap-dropdown"></p-dropdown>
          </div>
        </div>
        <div class="row gutter-0 !me-[40px]">
          <div class="col-5 label">Strike Index Type {{ i + 1 }}</div>
          <div class="col-7 ps-2 pe-1">
            <p-dropdown formControlName="indexType" [options]="strikeIndexTypes" [group]="true" [style]="{'width': '100%'}"
                        [styleClass]="'margin-3 '"></p-dropdown>
          </div>
        </div>
        <div class="row gutter-0 !me-[40px]">
          <div class="col-5 label">Strike Index Source {{ i + 1 }}</div>
          <div class="col-7 ps-2 pe-1">
            <input type="text" class="form-control p-component padding-6 text-medium margin-3"
                   formControlName="strikeIndexSource" [maxlength]="150">
          </div>
        </div>
        <div class="row gutter-0 bottom-row-border !me-[40px]">
          <div class="col-5 label">Strike Price {{ i + 1 }}</div>
          <div class="col-7 row gutter-0 ps-2 pe-1 ">
            <div class="col-6">
              <input type="text" cmsDigitOnly
                     [class]="'form-control padding-6 text-medium margin-3' + getOptionDetailIndexErrorClass(i, 'price')"
                     formControlName="price">
            </div>
            <div class="col-6 ps-2">
              <p-dropdown formControlName="priceCurrencyCode" [options]="currencies" [group]="true"
                          [style]="{'width': '100%'}" [styleClass]="'margin-3 '"></p-dropdown>
            </div>
          </div>
          <span *ngIf="getOptionDetailIndexErrorClass(i, 'price')"
                class="fa fa-info-circle absolute error-marker"></span>
        </div>
      </div>
      <div class="row gutter-0 !me-[40px]">
        <div class="col-5 label"></div>
        <div class="col-7 ps-2 ">
          <button type="button" class="btn add-remove-line" (click)="add()" [disabled]="disableAddButton">Add</button>
          <button type="button" class="btn add-remove-line" (click)="remove()"
                  [disabled]="disableRemoveButton">Remove
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
