<p-dialog *ngIf="display"
          header="Cancel Trade {{tradeId}}"
          [resizable]="false"
          [(visible)]="display"
          (visibleChange)="onVisibleChange($event)"
          [modal]="true"
          [style]="{width: '500px', minHeight: '156px'}">
  <div class="warning" *ngIf="!messages && !execution">
    <div class="warning__id-container">
      <i class="fa fa-exclamation-triangle"></i>
      <span>
        Confirm you wish to cancel confirming trade "{{tradeId}}".
      </span>
    </div>
  </div>
  <div class="pending" *ngIf="execution">
    <div class="pending__container">
      <i class="fa fa-info-circle"></i>
      <span>
        Executing...
      </span>
    </div>
    <p-progressSpinner></p-progressSpinner>
  </div>
  <p-messages [value]="messages" [closable]="false"></p-messages>
  <p-footer>
    <button class="btn margin-right-3" type="button" (click)="onAbort()" *ngIf="!closeButton && !execution">No - Abort</button>
    <button class="btn" type="button" (click)="onCancel()" *ngIf="!closeButton && !execution">Yes - Cancel</button>
    <button class="btn" type="button" (click)="onClose()" *ngIf="closeButton && !execution">Close</button>
  </p-footer>
</p-dialog>
