import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { CpmlDealsService } from '../cpml-deals.service';
import { Column, DefaultValues, PagerInfo } from '@common/shared/datatable.model';
import { LazyLoadEvent, Message } from 'primeng/api';
import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import { StringUtils } from '@common/shared/string.utils';
import { CpmlDealFinderDataTableModel, CpmlDealModel } from './cpml-datatable.model';
import { CpmlDealFinderDataTableConfig } from './cpml-datatable.config';
import { CpmlDealsSearchQuery } from '../dealfinder/filter/cpml-dealfinder-filter.model';
import { DateUtils } from '@common/shared/date.utils';
import * as FileSaver from 'file-saver';
import { OrganisationService } from '@common/organisation.service';
import { of, Subject } from 'rxjs';
import { filter, mergeMap, takeUntil } from 'rxjs/operators';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
import { merge } from 'rxjs';

@Component({
  selector: 'cms-cpml-datatable',
  templateUrl: './cpml-datatable.component.html',
  styleUrls: ['./cpml-datatable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CpmlDataTableComponent implements OnInit, OnDestroy {

  @ViewChild(Table, { static: true }) dataTableComponent: Table;
  @Input() category: string;
  @Output() message = new EventEmitter<Message[]>();

  private dealsResult: CpmlDealFinderDataTableModel = { rows: [], recordCount: 0, filterRange: null, searchQuery: null, };
  private subscription: Subscription = new Subscription();
  private onDestroy$ = new Subject();
  private tableColumns: Column[];
  private defaults = new DefaultValues();

  pagerInfo: PagerInfo;
  currentSortField: string = CpmlDealFinderDataTableConfig.getDefaultSortingColumn();
  loading: boolean = false;
  selection: CpmlDealModel;
  rowsPerPage = this.defaults.getRowsPerPage();
  rowsPerPageOptions = this.defaults.getRowsPerPageOptions();

  currentSearchQueryOnChangeOrg$ = this.organisationService.getChangeOrganisationObservable()
    .pipe(mergeMap(() => of(this.cpmlDealsService.getCurrentSearchQuery())));

  searchQuery$ = merge(this.cpmlDealsService.getSearchQueryChangeObservable(), this.currentSearchQueryOnChangeOrg$); // update searchquery on organisation changes

  constructor(private cpmlDealsService: CpmlDealsService,
              private organisationService: OrganisationService,
              private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.pagerInfo = PagerInfo.calcPagerInfo(0, 0, 10);
    this.tableColumns = this.getTableColumns();

    this.subscription.add(this.cpmlDealsService.getLoading().subscribe(value => this.loading = value));
    this.subscription.add(this.cpmlDealsService.getResetStatusChangeObservable().subscribe(() => this.resetView()));
    this.subscription.add(this.searchQuery$.pipe(filter(val => this.isValidSearchQuery(val))).subscribe(val => this.loadTransactions(val)));
    this.subscription.add(this.searchQuery$.pipe(filter(val => this.isValidBucketSearchQuery(val))).subscribe(val => this.loadBucketDeals(val)));
  }

  onLazyLoad(event: LazyLoadEvent) {
    const currentPage = event.first / event.rows;
    const pagingFilter: PagingFilter = {
      entriesPerPage: event.rows,
      page: currentPage
    };

    this.currentSortField = event.sortField || CpmlDealFinderDataTableConfig.getDefaultSortingColumn();
    const columnName = StringUtils.camelCaseToSnakeUpperCase(this.currentSortField);

    const sortOrder = (event.sortOrder === -1) ? 'DESCENDING' : 'ASCENDING';
    const sortingFilter: SortingFilter = { columnName, sortOrder };
    // get current search query, replace paging + sorting
    const searchQuery = this.cpmlDealsService.getCurrentSearchQuery();
    if (searchQuery) {
      searchQuery.paging = pagingFilter;
      searchQuery.sorting = sortingFilter;
    }

    this.cpmlDealsService.emitNewSearchQuery(searchQuery);

  }

  get cols() {
    return this.tableColumns;
  }

  get rows() {
    return this.dealsResult.rows;
  }

  hasData(): boolean {
    return this.dealsResult.rows.length > 0;
  }

  get filterRangeAsString() {

    if (this.dealsResult.filterRange) {
      const dateRange = this.dealsResult.filterRange;

      const end = dateRange.end ? DateUtils.formatDate(new Date(dateRange.end), true) : 'Now';
      let start = 'Any';
      let until = ' - ';
      if(dateRange.start){
        start =  DateUtils.formatDate(new Date(dateRange.start), true);
      } else if(dateRange.end){
        start = 'Before';
        until = ' ';
      }

      return start + until + end;
    }

    return null;
  }

  get numberOfTotalRows() {
    return this.dealsResult.recordCount;
  }

  getDefaultColumns(): string[] {
    return CpmlDealFinderDataTableConfig.getDefaultColumns();
  }

  getTableColumns(): Column[] {
    return CpmlDealFinderDataTableConfig.getColumns(this.getDefaultColumns());
  }

  private isValidSearchQuery(searchQuery): boolean {
    return CpmlDealsSearchQuery.isNotEmptySearchQuery(searchQuery)
      && searchQuery.searchType != null && searchQuery.category == null;
  }

  private isValidBucketSearchQuery(searchQuery): boolean {
    return CpmlDealsSearchQuery.isNotEmptySearchQuery(searchQuery)
      && searchQuery.category != null && searchQuery.searchType == null;
  }

  private resetView() {
    if (this.dataTableComponent) {
      this.dataTableComponent.reset();
    }

  }

  private loadTransactions(searchQuery: CpmlDealsSearchQuery) {
    const currentPage = searchQuery.paging.page;
    const resultsPerPage = searchQuery.paging.entriesPerPage;

    this.changeDetectorRef.markForCheck();

    if (!this.loading) {
      this.cpmlDealsService.fetchCpmlDeals(searchQuery)
        .subscribe((result: CpmlDealFinderDataTableModel) => {
            this.dealsResult = this.formatDeals(result);
            this.pagerInfo = PagerInfo.calcPagerInfo(result.recordCount, currentPage, resultsPerPage);
            this.changeDetectorRef.markForCheck();
            if (currentPage === 0) {
              this.dataTableComponent.first = 0;
            }
          },
          err => {
            this.changeDetectorRef.markForCheck();
            this.message.emit([{ severity: 'error', detail: 'could not fetch cpml deals' }]);
          });
    }
  }

  private loadBucketDeals(searchQuery: CpmlDealsSearchQuery) {
    const currentPage = searchQuery.paging.page;
    const resultsPerPage = searchQuery.paging.entriesPerPage;

    if (!this.loading) {
      this.changeDetectorRef.markForCheck();
      this.cpmlDealsService.fetchCpmlBucketDetails(searchQuery)
        .subscribe((result: CpmlDealFinderDataTableModel) => {
            this.dealsResult = this.formatDeals(result);
            this.pagerInfo = PagerInfo.calcPagerInfo(result.recordCount, currentPage, resultsPerPage);
            this.changeDetectorRef.markForCheck();

            if (currentPage === 0) {
              this.dataTableComponent.first = 0;
            }
            this.dealsResult.searchQuery = searchQuery;
          },
          err => {
            this.changeDetectorRef.markForCheck();
            this.message.emit([{ severity: 'error', detail: 'could not fetch bucket deals' }]);
          });
    }
  }

  get isAlleged() {
    return this.dealsResult != null && this.dealsResult.searchQuery != null && this.dealsResult.searchQuery.state === 'ALLEGED';
  }

  private formatDeals(data) {
    const rows = data.rows.map(row => ({
      ...row,
      repositories: row.repositories?.join(', ') || "",
      receiptTimestamp: DateUtils.transformDateWithTimestamp(row.receiptTimestamp),
      updateTimestamp: DateUtils.transformDateWithTimestamp(row.updateTimestamp)
    }));
    return { ...data, rows };
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
    this.subscription.unsubscribe();
  }

  public loadCpmlXml(auditGroupId: number): void {
    this.cpmlDealsService.downloadCpmlXml(auditGroupId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(data => {
        FileSaver.saveAs(data.blob, data.filename);
      });
  }

  public loadExcelExport(): void {
    this.cpmlDealsService.downloadExcelExport()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(data => {
        FileSaver.saveAs(data.blob, data.filename);
      });
  }

  get records(){
    return this.numberOfTotalRows>0;

  }
}
