<div class="panel-box">
  <cms-info ngPreserveWhitespaces>
    <!--@formatter:off (markdown rendering breaks, when the following tags are formatted)-->
    **Purpose**

    Market Participants can use this form to report **bilateral standard trades** to ACER in **REMIT Table 2 format** as an alternative to an automated trading system integration or manual document upload under "REMIT Files IN".

    This function requires Master Operator (MO) privileges.

    **How to Report**

       * From the title bar select the organisational unit you wish to submit the report for
       * Enter the trade details and press "Report Trade"
       * On successful submission you can enter the next trade
       * Switch to screen "REMIT Transactions / Table 2" in order to check reporting progress for your entered trades

    **Guidance on Selected Fields**

    Market Participant (trade side for which to report)

       * Self-reporting (default case): "Market Participant" is populated with the selected internal organisation
       * Agent reporting: change "Market Participant" to any external party set up for your organisation group - your internal organisation will be captured as reporting agent (sender) for the external party
       * Double-sided reporting: check option "Report both sides" to submit reports for both trade sides at once - in this mode additional fields "Trader ID (for other MP)" and "Beneficiary (for other MP)" appear
       * Note that you can only report for internal/external parties for which an ACER code is registered in the CMS

    Repeating Elements - use the Add/Remove buttons to enter additional occurrences of...

       * the fields "Delivery Point or Zone" (1-4), "Market Participant" (1-4), "Other Market Participant" (1-4), "Beneficiary" (0-4)
       * the sections "Option Details" (0-2), "Volume Optionality Intervals" (0-36), "Fixing Index" (0-5)

    Price or Price Formula

       * ACER rules require that either a price of a price formula be specified (but not both)
       * Price formulas have no standardised format - options include mathematical notation or natural language

    Delivery Points and Index Names

       * Delivery Points and Price Indices are populated from CMS master data (see corresponding menu section)
       * Please contact Equias support to have missing values added

    <!--@formatter:on-->
  </cms-info>
  <div>
    <p-messages id="feedback-messages"></p-messages>
  </div>
  <form [formGroup]="tradeForm" class="form-row" (submit)="submit()" *ngIf="showTradeEntryForm">
    <div class="col-12 col-lg-5">
      <cms-remit-trade-entry-table2-references [formGroup]="tradeForm" [onReset]="onReset$"></cms-remit-trade-entry-table2-references>
      <cms-remit-trade-entry-table2-product-details [formGroup]="tradeForm" [onReset]="onReset$"></cms-remit-trade-entry-table2-product-details>
      <cms-remit-trade-entry-table2-reporting-counterparty [formGroup]="tradeForm" [onReset]="onReset$"></cms-remit-trade-entry-table2-reporting-counterparty>
      <cms-remit-trade-entry-table2-other-counterparty [formGroup]="tradeForm" [onReset]="onReset$"></cms-remit-trade-entry-table2-other-counterparty>
      <cms-remit-trade-entry-table2-delivery [formGroup]="tradeForm" [onReset]="onReset$"></cms-remit-trade-entry-table2-delivery>
      <cms-remit-trade-entry-table2-option-details [formGroup]="tradeForm" [onReset]="onReset$"></cms-remit-trade-entry-table2-option-details>
    </div>
    <div class="col-12 col-lg-5">
      <cms-remit-trade-entry-table2-volume [formGroup]="tradeForm" [onReset]="onReset$"></cms-remit-trade-entry-table2-volume>
      <cms-remit-trade-entry-table2-volume-optionality-intervals [formGroup]="tradeForm" [onReset]="onReset$"></cms-remit-trade-entry-table2-volume-optionality-intervals>
      <cms-remit-trade-entry-table2-price [formGroup]="tradeForm" [onReset]="onReset$"></cms-remit-trade-entry-table2-price>
      <cms-remit-trade-entry-table2-fixing-index [formGroup]="tradeForm" [onReset]="onReset$"></cms-remit-trade-entry-table2-fixing-index>
    </div>
    <div class="col-12">
      <div class="margin-15">
        <button id="remit-trade-entry-reset" type="button" class="btn margin-right-6 padding-6" (click)="reset()">Reset</button>
        <button id="remit-trade-entry-reportTrade" type="submit" class="btn padding-6" [disabled]="submitDisabled">Report Trade</button>
      </div>
    </div>
  </form>
</div>
