import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { Message } from 'primeng/api';
import { UntypedFormControl, ValidationErrors } from '@angular/forms';
import { TypedFormGroup } from '@common/typed-form-group.model';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { EditableState } from '../../editable-status-cell/editable-status-cell.component';

export interface LockInactivateUser {
  comment: string;
  currentState?: string;
  userIdForStateChange: number | string;
}

@Component({
  selector: 'cms-lock-inactivate-dialog',
  templateUrl: './lock-inactivate-dialog.component.html',
  styleUrls: ['./lock-inactivate-dialog.component.scss']
})
export class LockInactivateDialogComponent implements OnInit, OnChanges {
  @Input() data: EditableState;
  @Input() display = false;
  @Input() messages: Message[] = [];
  @Output() displayChange = new EventEmitter<boolean>();
  @Output() cancel = new EventEmitter<Message[]>();
  @Output() lock = new EventEmitter<LockInactivateUser>();
  @Output() inactivate = new EventEmitter<LockInactivateUser>();
  form: TypedFormGroup<{comment: string}>;
  errors: {[key: string]: ValidationErrors} = {};

  constructor(private mapper: MessagesMapperService) { }

  get header(): string {
    return this.data.newValue === 'LOCKED' ? 'Lock User Account' : 'Deactivate User Account';
  }

  onCancel(): void {
    this.hide();
    this.cancel.emit(this.mapper.toWarningMessages(this.data.newValue === 'LOCKED' ?
      `Locking of account '${this.data.data.userName}' was cancelled.` :
      `Account deactivation for user '${this.data.data.userName}' was cancelled.`));
  }

  onVisibleChange(visible: boolean): void {
    if (!visible) {
      this.onCancel();
    }
  }

  onConfirm(): void {
    const { comment } = this.form.value;
    const out: LockInactivateUser =  {
      comment,
      currentState: this.data.data.state,
      userIdForStateChange: this.data.data.id
    };

    this.data.newValue === 'LOCKED' ? this.lock.emit(out) : this.inactivate.emit(out);
  }

  ngOnInit(): void {
    this.form = new TypedFormGroup({
      comment: new UntypedFormControl('')
    });
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (this.form && changes.data) {
      this.form.updateValueAndValidity();
    }

    if (changes.messages) {
      this.errors = {};
      if (this.messages) {
        this.messages.forEach((obj: Message) => {
          const { key: controlName } = obj;
          this.errors[controlName] = { 'server': true };
        });
      }
    }
  }

  private hide(): void {
    this.messages = [];
    this.display = false;
    this.displayChange.emit(this.display);
    this.form.reset();
    this.form.updateValueAndValidity();
  }
}
