import { createSelector } from '@ngrx/store';
import { getEcmState, State } from '../reducers';
import * as fromReducer from './deal-finder.reducers';

export const getEcmDealFinderState = createSelector(getEcmState, (state: State) => state.dealFinder);

export const getData = createSelector(getEcmDealFinderState, fromReducer.getData);
export const getSorting = createSelector(getEcmDealFinderState, fromReducer.getSorting);
export const getPaging = createSelector(getEcmDealFinderState, fromReducer.getPaging);
export const getLoading = createSelector(getEcmDealFinderState, fromReducer.getLoading);
export const getMessages = createSelector(getEcmDealFinderState, fromReducer.getMessages);

export const getIdFilters = createSelector(getEcmDealFinderState, fromReducer.getIdFilters);
export const getPropFilters = createSelector(getEcmDealFinderState, fromReducer.getPropFilters);
export const filterBy = createSelector(getEcmDealFinderState, fromReducer.getFilterBy);
export const getColumns = createSelector(getEcmDealFinderState, fromReducer.getColumns);

export const getBroker = createSelector(getEcmDealFinderState, fromReducer.getBroker);
export const getTransactionTypes = createSelector(getEcmDealFinderState, fromReducer.getTransactionTypes);
export const getMarkets = createSelector(getEcmDealFinderState, fromReducer.getMarkets);
export const getCounterParties = createSelector(getEcmDealFinderState, fromReducer.getCounterParties);
export const getCommodities = createSelector(getEcmDealFinderState, fromReducer.getCommodities);
export const getNoFoundIds = createSelector(getEcmDealFinderState, fromReducer.getNoFoundIds);
export const getIdsCount = createSelector(getEcmDealFinderState, fromReducer.getIdsCount);
export const getSelected = createSelector(getEcmDealFinderState, fromReducer.getSelected);
export const getDialog = createSelector(getEcmDealFinderState, fromReducer.getDialog);
export const getMyFilters = createSelector(getEcmDealFinderState, fromReducer.getMyFilters);
export const getDefaultFilter = createSelector(getEcmDealFinderState, fromReducer.getDefaultFilter);
export const getConfirmationIds = createSelector(getEcmDealFinderState, fromReducer.getConfirmationIds);
export const getAllDealsFilter = createSelector(getEcmDealFinderState, fromReducer.getAllDealsFilter);
export const getDealFinderHtml = createSelector(getEcmDealFinderState, fromReducer.getDealFinderHtml);
export const getDealEditRights = createSelector(getEcmDealFinderState, fromReducer.getDealEditRights);
export const getDealNoteRights = createSelector(getEcmDealFinderState, fromReducer.getDealNoteRights);

