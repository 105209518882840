import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MailAlertsService } from '../mail-alerts.service';
import { switchMap } from 'rxjs/operators';
@Injectable()
export class EcmEffects {

  constructor(private mailAlertsService: MailAlertsService) { }

  loadData$ = createEffect(() => this.mailAlertsService.loadData('[MAIL ALERTS ECM]', 'ECM'));
  updateStatus$ = createEffect(() => () => this.mailAlertsService.updateStatus('[MAIL ALERTS ECM]','ECM'));

}






