import { ErrorResponse } from '@common/error-response.model';
import { Column } from '@common/shared/datatable.model';

export interface EcmSettingsBrokerRestrictionsData {
  values: [
    {
      agreement: string,
      broker: string,
      commodity: string,
      market: string,
      transactionType: string
    }];
}

export interface EcmSettingsBrokerRestrictionsDataResponse
  extends ErrorResponse, EcmSettingsBrokerRestrictionsData {}

export const defaultStyles = {
  'text-align': 'left',
  'text-overflow': 'ellipsis',
  'white-space': 'nowrap'
};

export const allColumnsConfig: Column[] = [
  { name: 'broker', type: 'text', title: 'Broker', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'commodity', type: 'text', title: 'Commodity', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'market', type: 'text', title: 'Market', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'transactionType', type: 'text', title: 'Transaction Type', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'agreement', type: 'text', title: 'Agreement', style: { ...defaultStyles }, sortable: false, visible: true },
];

export interface PressetValues {
  allowedToUpload: boolean;
  maxUploadSizeInMb: number;
}

export type PresetValueResponse = PressetValues & ErrorResponse;

