import { createAction, union } from '@ngrx/store';
import { Message } from 'primeng/api';
import {
  EsmFormValues,
  LoadBankDetailsResponse,
  EsmVatIds,
  AddBankDetailValue,
  UpdateBankdetailRequest, EsmBankDetails, EsmMessagesModel,
} from '../../../master-data/master-data.model';
import { DialogState } from '@common/state/reducers';
import { DatatableConfig } from '@common/shared/results.model';
import { ESM_MD_DIALOGS } from '../master-data.reducer';
import { EsmInternalMasterDataPresetValues } from 'app/esm/master-data/internal-master-data/internal-master-data.model';

export const setMessagesAction = createAction('[ESM MASTER DATA] Set Messages',
  (payload: EsmMessagesModel) => ({ payload }));
export const setToDefaultAction = createAction('[ESM MASTER DATA] Set to Default');
export const loadVatIdsAction = createAction('[ESM MASTER DATA] Load Vat Ids');
export const loadVatIdsSuccessAction = createAction('[ESM MASTER DATA] Load Vat Ids Success',
  (payload: {vatIds: EsmVatIds[]}) => ({payload}));
export const loadPresetValuesAction = createAction('[ESM MASTER DATA] Preset Values');
export const loadPresetValuesSuccessAction = createAction('[ESM MASTER DATA] Preset Values Success',
  (payload: {presetValues: EsmInternalMasterDataPresetValues}) => ({payload}));
export const loadFormValuesAction = createAction('[ESM MASTER DATA] Load Form Values',
  (payload: {masterDataId: number}) => ({ payload }));
export const loadFormValuesSuccessAction = createAction('[ESM MASTER DATA] Load Form Values Success',
  (payload: {formValues: EsmFormValues}) => ({payload}));
export const selectMasterDataIdAction = createAction('[ESM MASTER DATA] Select Master Data Id',
  (payload:  number) => ({payload}));
export const setMasterDataIdAction = createAction('[ESM MASTER DATA] Set Master Data Id',
  (payload:  number) => ({payload}));
export const setVersionAction = createAction('[ESM MASTER DATA] Set Version',
  (payload: number) => ({payload}));
export const setFormEditableAction = createAction('[ESM MASTER DATA] Set Form Editable',
  (payload: boolean) => ({payload}));
export const setBankDetailValueAction = createAction('[ESM MASTER DATA] Set Bank Detail Value', (payload: AddBankDetailValue) => ({payload}));
export const persistAction = createAction('[ESM MASTER DATA] Persist', (payload: EsmFormValues) => ({payload}));
export const persistSuccessAction = createAction('[ESM MASTER DATA] Persist Success', (payload: EsmFormValues) => ({payload}));
export const updateAction = createAction('[ESM MASTER DATA] Update', (payload: EsmFormValues) => ({payload}));
export const updateSuccessAction = createAction('[ESM MASTER DATA] Update Success', (payload: EsmFormValues) => ({payload}));
export const setStaleDataAction = createAction('[ESM MASTER DATA] set Stale Data',
  (payload: boolean) => ({payload}));
export const addBankDetailAction = createAction('[ESM MASTER DATA] Add Bank Details',
  (payload: AddBankDetailValue) => ({payload}));
export const deleteMDRecordAction = createAction('[ESM MASTER DATA] Delete Master Data Record');
export const deleteMDRecordSuccessAction = createAction('[ESM MASTER DATA] Delete Master Data Record Success');
export const cancelAction = createAction('[ESM MASTER DATA] Cancel', (payload: EsmFormValues) => ({payload}));
export const deleteBankDetail = createAction('[ESM MASTER DATA] Delete Bank Detail', (payload: EsmBankDetails) => ({payload}));
export const updateBankDetail = createAction('[ESM MASTER DATA] Update Bank Detail',
  (payload: UpdateBankdetailRequest) => ({payload}));
export const openDialogAction = createAction('[ESM MASTER DATA] Open Dialog', (payload: DialogState<ESM_MD_DIALOGS>) => ({payload}));
export const closeDialogAction = createAction('[ESM MASTER DATA] Close Dialog');
export const updateDialogAction = createAction('[ESM MASTER DATA] Close Dialog', (payload: Message[]) => ({payload}));
export const createNewMDRecordAction = createAction('[ESM MASTER DATA] Create New Master Data Record');
export const failMDRecordingAction = createAction('[ESM MASTER DATA] Fail Master Data Recording');
export const failBDRecordingAction = createAction('[ESM MASTER DATA] Fail Bank Detail Recording');
export const sortAndPageDataAction = createAction('[ESM MASTER DATA] Sort And Page Data', (payload: DatatableConfig) => ({ payload }));
export const loadBankDetailsAction = createAction('[ESM MASTER DATA] Load Bank Details', (payload: number) => ({payload}));
export const loadBankDetailsSuccessAction = createAction('[ESM MASTER DATA] Load Bank Details Success', (payload: LoadBankDetailsResponse) => ({payload}));
export const updateAllowBankingDetailsDownloadAction = createAction('[ESM MASTER DATA] Update Allow Banking Details Download', (payload: boolean) => ({payload}));

const actions = union({
  loadVatIdsAction,
  loadVatIdsSuccessAction,
  loadPresetValuesAction,
  loadPresetValuesSuccessAction,
  loadFormValuesAction,
  failBDRecordingAction,
  loadFormValuesSuccessAction,
  setToDefaultAction,
  selectMasterDataIdAction,
  persistAction,
  updateAction,
  updateSuccessAction,
  setStaleDataAction,
  setVersionAction,
  setBankDetailValueAction,
  deleteMDRecordAction,
  persistSuccessAction,
  addBankDetailAction,
  cancelAction,
  deleteBankDetail,
  updateBankDetail,
  createNewMDRecordAction,
  openDialogAction,
  closeDialogAction,
  updateDialogAction,
  setFormEditableAction,
  failMDRecordingAction,
  loadBankDetailsAction,
  loadBankDetailsSuccessAction,
  sortAndPageDataAction,
  deleteMDRecordSuccessAction,
  setMasterDataIdAction,
  updateAllowBankingDetailsDownloadAction
});

export type MasterDataActions = typeof actions;
