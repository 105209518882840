import { createAction, union } from '@ngrx/store';
import { EsmDashboardTableType, EsmStatesEnum } from '../../invoice/dashboard/esm-dashboard.model';
import { Message } from 'primeng/api';

export const setToDefaultAction = createAction('[ESM DASHBOARD] Set To Default');
export const setViewTypeAction = createAction('[ESM DASHBOARD] Set View Type', (payload: EsmDashboardTableType) => ({ payload }));
export const setMessageAction = createAction('[ESM DASHBOARD] Set Message', (payload: Message[]) => ({ payload }));
export const setStateAction = createAction('[ESM DASHBOARD] Set State', (payload: EsmStatesEnum | string) => ({ payload }));
export const getStateNamesAction = createAction('[ESM DASHBOARD] Get State Names');
export const setStateNamesAction = createAction('[ESM DASHBOARD] Set State Names', (payload: EsmStatesEnum[]) => ({ payload }));

const actions = union({
  setToDefaultAction,
  setStateAction,
  getStateNamesAction,
  setStateNamesAction,
  setViewTypeAction,
  setMessageAction
});

export type DashboardActions = typeof actions;
