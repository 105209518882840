<p-dialog *ngIf="display"
          header="Edit eRR Process Details"
          [visible]="display"
          [modal]="true"
          [contentStyle]="{overflow: 'auto', height: '692px'}"
          [positionTop]="110"
          [resizable]="false"
          (visibleChange)="onVisibleChange($event)"
          [style]="{width: '650px', minWidth: '500px'}">
  <p-messages [value]="messages" [closable]="true"></p-messages>
  <div class="margin-left-15 margin-right-15 margin-top-6 mb-0">
    <form [formGroup]="form" class="row" autocomplete="off">
      <div class="col-12 margin-bottom-12">
        <label for="processRole" class="col-4 float-left ps-0">eRR Process Role:<span class="required">*</span></label>
        <ng-container *ngIf="presetValues && presetValues.processRoles">
          <div class="process-role-container">
            <label *ngFor="let processRole of presetValues.processRoles.options" class="margin-left-12 radio-button">
              <input type="radio"
                     formControlName="processRole"
                     id="processRole"
                     class="margin-right-3"
                     [ngClass]="{'error': errors['processRole']}"
                     [value]="processRole.value"
                     name="processRole" />{{ processRole.label }}</label>
          </div>
        </ng-container>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label for="partnerId" class="col-4 float-left ps-0">Partner ID:<span class="required">*</span></label>
        <div class="col-8">
          <input cmsUpperCase autocomplete="off" id="partnerId" type="text" formControlName="partnerId" class="ng-trim-ignore form-control col-8 padding-6" [ngClass]="{'error': errors['partnerId']}" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label for="ompPartnerId" class="col-4 float-left ps-0">ACER Partner ID:<span class="required" *ngIf="form.get('processRole')?.value === ErrProcessRoles.OMP">*</span></label>
        <div class="col-8">
          <input cmsUpperCase autocomplete="off" id="ompPartnerId" type="text" formControlName="ompPartnerId" class="ng-trim-ignore form-control col-8 padding-6" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label for="leiCode" class="col-4 float-left ps-0">LEI Code:<span class="required">*</span></label>
        <div class="col-8">
          <input cmsUpperCase autocomplete="off" id="leiCode" type="text" formControlName="leiCode" class="ng-trim-ignore form-control col-8 padding-6" [ngClass]="{'error': errors['leiCode']}" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label for="acerCode" class="col-4 float-left ps-0">ACER Code:</label>
        <div class="col-8">
          <input cmsUpperCase autocomplete="off" id="acerCode" [readOnly]="errPartyExist" type="text" formControlName="acerCode" class="ng-trim-ignore form-control col-8 padding-6" [ngClass]="{'error': errors['acerCode']}" />
        </div>
      </div>
      <div class="col-12 row margin-bottom-12">
        <label for="defaultEmirRepository" class="col-4 float-left ps-0">Default EMIR
          Repository:<span class="required">*</span></label>
        <p-dropdown class="col-8 padding-0"
                    [options]="defaultEmirRepositoryOptions"
                    id="defaultEmirRepository"
                    [ngClass]="{'error': errors['defaultEmirRepository']}"
                    formControlName="defaultEmirRepository">
        </p-dropdown>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label for="timeZone" class="col-4 float-left ps-0">Time zone:<span class="required">*</span></label>
        <div class="col-8">
          <input autocomplete="off" id="timeZone" type="text" formControlName="timeZone" class="form-control col-8 padding-6" [ngClass]="{'error': errors['timeZone']}" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12">
        <label for="externalRrmScreenEnabled" class="col-4 float-left ps-0">External RRM Screen:</label>
        <input autocomplete="off" id="externalRrmScreenEnabled" type="checkbox" formControlName="externalRrmScreenEnabled" class="padding-6" />
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label for="acerCopyPartnerId" class="col-4 float-left ps-0">ACER Copy Partner ID:</label>
        <div class="col-8">
          <input cmsUpperCase autocomplete="off" id="acerCopyPartnerId" type="text" formControlName="acerCopyPartnerId" class="ng-trim-ignore form-control col-8 padding-6" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12" *ngIf="form.get('processRole')?.value === ErrProcessRoles.Trader">
        <label for="elcomReceipt" class="col-4 float-left ps-0">Receive ElCom Receipts:</label>
        <input autocomplete="off" id="elcomReceipt" type="checkbox" formControlName="elcomReceipt" class="padding-6" />
      </div>
      <ng-container *ngIf="presetValues && form.get('processRole')?.value === ErrProcessRoles.Trader">
        <div class="col-12 margin-bottom-12">
          <label for="mifidPositionReporting" class="col-4 float-left ps-0">MiFID II Position Reporting:</label>
          <input autocomplete="off" id="mifidPositionReporting" type="checkbox" formControlName="mifidPositionReporting" class="padding-6" />
        </div>
        <div class="col-12 margin-bottom-12">
          <label for="mifidMPPosRepXPSubscription" class="col-4 float-left ps-0">MiFID II PR MP XP subscription:</label>
          <input autocomplete="off" id="mifidMPPosRepXPSubscription" type="checkbox" formControlName="mifidMPPosRepXPSubscription" class="padding-6" />
        </div>
        <div class="col-12 margin-bottom-12">
          <label for="smallMediumTrader" class="col-4 float-left ps-0">Small Medium Trader:</label>
          <input autocomplete="off" id="smallMediumTrader" type="checkbox" formControlName="smallMediumTrader" class="padding-6" />
        </div>
        <div class="col-12 margin-bottom-12 flex">
          <label for="mifidXmlEbftpPartnerId" class="col-4 float-left ps-0">MIFID XmlEbftp Partner ID:</label>
          <div class="col-8">
            <input cmsUpperCase autocomplete="off" id="mifidXmlEbftpPartnerId" type="text" formControlName="mifidXmlEbftpPartnerId" class="ng-trim-ignore form-control col-8 padding-6" />
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="presetValues && form.get('processRole')?.value === ErrProcessRoles.OMP">
        <div class="col-12 margin-bottom-12">
          <label for="ompConnectionMethod" class="col-4 float-left ps-0 padding-right-9">MiFID 2 OMP PR Connection:</label>
          <ng-container *ngIf="presetValues && presetValues.ompConnectionMethods">
            <div class="process-role-container">
              <label *ngFor="let method of presetValues.ompConnectionMethods.options" class="margin-left-12 radio-button">
                <input type="radio"
                       formControlName="ompConnectionMethod"
                       id="ompConnectionMethod"
                       class="margin-right-3"
                       [value]="method.value"
                       name="ompConnectionMethod" />{{ method.label }}</label>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </form>
  </div>
  <p-footer>
    <button class="btn margin-right-3" type="button" (click)="onCancel()">Cancel</button>
    <button class="btn" type="button" (click)="onConfirm()">Update</button>
  </p-footer>
</p-dialog>
