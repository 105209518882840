<ng-container *ngIf="errorMessageService.hasError(); else content">
    <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>

<cms-headline pageTitle="ROBO Delegators" [displayOrganisationGroupName]="true"></cms-headline>
<div class="panel-box">
  <cms-info ngPreserveWhitespaces>
    <!--@formatter:off (markdown rendering breaks, when the following tags are formatted)-->
    <u>__ROBO Delegators (ROBO = Reporting On Behalf Of)__</u>

    **Purpose of the ROBO Delegator List**

    * ROBO (a.k.a. delegated reporting or agent reporting) is the submission of trade reports on behalf of group-external market participants.
    * This comprises all EMIR reporting and bilateral trades under REMIT.
    * On this screen you **view and maintain** all group-**external delegator parties** for which your group members intend to submit such trade reports.
    * You can further check the **ROBO permissioning** of those parties (allow/block) and which **ACER codes** will be used for REMIT reporting

    **Information in the List**

    * **LEI Code**: the LEI under which you registered the external party in your group for ROBO
    * **ACER Code**: the party's ACER code registered in the CMS for REMIT reporting - contact support if it says "unknown" and you intend to report to REMIT
    * **Permission**: whether the counterparty permits or vetoes your ROBO reporting
        * ALLOW = counterparty granted ROBO permission to your group
        * BLOCK = counterparty vetoed ROBO reporting for your group
        * BLOCK (DEFAULT) = counterparty has not set permissions yet - they are blocked by default
        * ALLOW (NON-CMS) = counterparty is not a CMS user and so cannot veto
    * **eRR Group**: name of organisation group to which this code is registered (only if counterparty is CMS user)
    * **Time Zone**: time zone eRR will assume if you submit execution timestamps without time zone information, e.g. "Europe/London"
    * **Display Name**: party name shown to your users in the eRR GUI
    * **Default EMIR TR**: the trade repository to which eRR will route EMIR reports for this party (if your report submission does not state it)
    * **Std. Instr.**: whether you configured standing instructions for this party (optional eRR enrichment feature)
    * **Registered Name (LEI Registry)**: for verification purposes: the legal name associated with the LEI in the LEI registry

    **Maintaining the List (Master Operators only)**

    * In the upload panel click on **Download** to export your current settings in CSV format
    * Edit the file and upload it with the **Browse** and **Upload** buttons

    **CSV Format for Uploads**

    * Your files need to be UTF-8 encoded and comma-separated with optional quoting of values
    * The header line with column names is required
    * Limits: 1000 records with a maximum file size of 1 MB.

    |#  | Name               | Length | Usage | Contents                   |
    |:-- | :------------------ |:---|:---|:-- |
    | 1 | LEI_CODE | 20 | M | LEI of external entity - primary identifier |
    | 2 | DISPLAY_NAME             | 1-20 | M | entity name to show to your users (eRR GUI), all letters will be converted to uppercase |
    | 3 | TIME_ZONE             | 1-50 | M | the time zone used for this party in case you send execution times without time zone, e.g. "Europe/London" |
    | 4 | DEFAULT_EMIR_REP        | 1-25 | M | default repository for EMIR trade reports in case report file specifies none: DTCC_EU or REGIS_TR or UNAVISTA |

    <!--@formatter:on-->
  </cms-info>
  <p-messages [value]="messages$ | async" closable="false"></p-messages>

  <cms-robo-delegators-upload></cms-robo-delegators-upload>
  <div>
    <cms-robo-delegators-datatable></cms-robo-delegators-datatable>
  </div>
</div>

</ng-template>
