<ng-container *ngIf="dialog && editableData">
  <cms-activation-dialog *ngIf="editableData && (form$ | async)?.toggleStatusAllowed"
                         [data]="editableData"
                         [display]="dialog?.name === 'STATE'"
                         [messages]="dialog?.messages"
                         (cancel)="onCancel($event)"
                         (changeActivation)="onConfirmChangeActivation($event)">
  </cms-activation-dialog>
</ng-container>
<cms-add-organisation-dialog *ngIf="dialog?.name === 'ADD' && (form$ | async)?.addAllowed"
                             [organisationGroupId]="organisationGroupId"
                             [display]="dialog?.name === 'ADD'"
                             [messages]="dialog?.messages"
                             (cancel)="onCancel($event)"
                             (confirm)="onConfirmAddOrg($event)">
</cms-add-organisation-dialog>
<ng-container *ngIf="selected">
  <div class="administration-dialogs">
  <cms-edit-organisation-dialog *ngIf="dialog?.name === 'EDIT' && (form$ | async)?.editAllowed"
                                [onlyContacts]="(form$ | async)?.onlyContactsEditable"
                                [data]="selected"
                                [messages]="dialog?.messages"
                                [display]="dialog?.name === 'EDIT'"
                                (cancel)="onCancel($event)"
                                (confirm)="onConfirmEditOrg($event)">
  </cms-edit-organisation-dialog>
  <cms-delete-organisation-dialog *ngIf="dialog?.name === 'DELETE' && (form$ | async)?.deleteAllowed"
                                  [data]="selected"
                                  [changeToExternalPartyAllowed]="dialog?.data"
                                  [messages]="dialog?.messages"
                                  [display]="dialog?.name === 'DELETE'"
                                  (cancel)="onCancel($event)"
                                  (confirm)="onConfirmDeleteOrg($event)">
  </cms-delete-organisation-dialog>
  <cms-move-organisation-dialog *ngIf="dialog?.name === 'MOVE' && (form$ | async)?.moveAllowed"
                                  [data]="selected"
                                  [orgGroups]="orgGroups"
                                  [messages]="dialog?.messages"
                                  [display]="dialog?.name === 'MOVE'"
                                  (cancel)="onCancel($event)"
                                  (confirm)="onConfirmMoveOrg($event)">
  </cms-move-organisation-dialog>
  <cms-edit-ecm-dialog  *ngIf="dialog?.name === 'EDIT_ECM' && (form$ | async)?.editEcmAllowed"
                        [data]="selected"
                        [presetValues]="dialog?.data"
                        [messages]="dialog?.messages"
                        [display]="dialog?.name === 'EDIT_ECM'"
                        (cancel)="onCancel($event)"
                        (confirm)="onConfirmEditEcm($event)">
  </cms-edit-ecm-dialog>
  <cms-edit-err-dialog *ngIf="dialog?.name === 'EDIT_ERR' && (form$ | async)?.editErrAllowed"
                       [data]="selected"
                       [presetValues]="dialog?.data"
                       [messages]="dialog?.messages"
                       [display]="dialog?.name === 'EDIT_ERR'"
                       (cancel)="onCancel($event)"
                       (confirm)="onConfirmEditErr($event)">
  </cms-edit-err-dialog>
  <cms-edit-esm-dialog *ngIf="dialog?.name === 'EDIT_ESM' && (form$ | async)?.editEsmAllowed"
                        [presetValues]="dialog?.data"
                        [data]="selected"
                        [messages]="dialog?.messages"
                        [display]="dialog?.name === 'EDIT_ESM'"
                        (cancel)="onCancel($event)"
                        (confirm)="onConfirmEditEsm($event)">
  </cms-edit-esm-dialog>
  </div>
</ng-container>
