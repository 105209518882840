import { createSelector } from '@ngrx/store';
import { NettingTableState } from './netting-table.reducers';
import { NettingPreviewState } from '../index';
import { getEsmState, State } from '../../reducers';

export const getNettingPreviewState = createSelector(getEsmState, (state: State) => state.nettingPreview);

export const getNettingTableState = createSelector(getNettingPreviewState, (state: NettingPreviewState) => state.nettingTable);
export const getData = createSelector(getNettingTableState, (preview: NettingTableState) => preview.data);
export const getLoading = createSelector(getNettingTableState, (preview: NettingTableState) => preview.loading);
export const getPaging = createSelector(getNettingTableState, (preview: NettingTableState) => preview.paging);
export const getSorting = createSelector(getNettingTableState, (preview: NettingTableState) => preview.sorting);
export const getDiscrepancy = createSelector(getNettingTableState, (preview: NettingTableState) => preview.discrepancy);
export const getSelectable = createSelector(getNettingTableState, (preview: NettingTableState) => preview.selectable);
export const getSelected = createSelector(getNettingTableState, (preview: NettingTableState) => preview.invoice);
export const getMessages = createSelector(getNettingTableState, (preview: NettingTableState) => preview.messages);

