<p-dialog *ngIf="display"
          [(visible)]="display"
          header="Replacing Broker Restrictions"
          (visibleChange)="onVisibleChange($event)"
          [resizable]="false"
          [modal]="true"
          [style]="{width: '500px', minWidth: '400px'}">
  <p-messages [value]="messages" [closable]="true"></p-messages>
  <div class="warning">
    Warning: Uploading will replace any existing broker restrictions
    for organisation {{ organisation?.eicCode }}
  </div>
  <p-footer>
    <button class="btn margin-right-3" type="button" (click)="onConfirm()">Yes - Replace</button>
    <button class="btn" type="button" (click)="onCancel()">Cancel</button>
  </p-footer>
</p-dialog>
