import { Component, OnDestroy, OnInit } from '@angular/core';
import { Column, DatatableConfig, PagerInfo } from '@common/shared/datatable.model';
import { Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { StandingInstructionsSearchQuery, StandingInstructionsService } from '../standing-instructions.service';
import { LazyLoadEvent } from 'primeng/api';
import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import { OrganisationService } from '@common/organisation.service';
import { takeUntil } from 'rxjs/operators';
import { OrganisationModel } from '@common/organisation.model';

@Component({
  selector: 'cms-standing-instructions-datatable',
  templateUrl: './standing-instructions-datatable.component.html',
  styleUrls: ['./standing-instructions-datatable.component.scss']
})
export class StandingInstructionsDatatableComponent implements OnInit, OnDestroy {

  private tableColumns: Column[] = StandingInstructionsDatatableConfig.TABLE_COLUMNS;
  pagerInfo: PagerInfo;
  loading: boolean = false;
  records = [];
  recordCount: number;
  hidden: boolean = true;
  private onDestroy$ = new Subject();
  filterForm: UntypedFormGroup;
  defaults = DatatableConfig;
  errPartyId: number;
  showStandingInstructionDetail: boolean = false;
  searchQuery: StandingInstructionsSearchQuery;
  orgId: number | undefined;

  private static valueMapper = {
    'cpFinancialNature': {
      'F': 'Financial',
      'FINANCIAL': 'Financial',
      'N': 'Non-financial institution',
      'NON_FINANCIAL': 'Non-financial institution',
      'C': 'Central',
      'CENTRAL': 'Central',
      'O': 'Other',
      'OTHER': 'Other'
    },
    'cpCorpSectors': {
      'A': 'Assurance undertaking',
      'C': 'Credit institution',
      'D': 'Central securities depository',
      'F': 'Investment firm',
      'I': 'Insure undertaking',
      'L': 'Alternative investment fund',
      'O': 'Occupational retirement provision',
      'R': 'Reinsurance undertaking',
      'U': 'UCITS',
      '1': 'Agriculture, forestry and fishing',
      '2': 'Mining and quarrying',
      '3': 'Manufacturing',
      '4': 'Electricity, gas, steam and air conditioning supply',
      '5': 'Water supply, sewerage, waste management and remediation activities',
      '6': 'Construction',
      '7': 'Wholesale and retail trade, repair of motor vehicles and motorcycles',
      '8': 'Transportation and storage',
      '9': 'Accommodation and food service activities',
      '10': 'Information and communication',
      '11': 'Financial and insurance activities',
      '12': 'Real estate activities',
      '13': 'Professional, scientific and technical activities',
      '14': 'Administrative and support service activities',
      '15': 'Public administration and defence; compulsory social security',
      '16': 'Education',
      '17': 'Human health and social work activities',
      '18': 'Arts, entertainment and recreation',
      '19': 'Other service activities',
      '20': 'Activities of households as employers',
      '21': 'Activities of extraterritorial organisations and bodies'
    },
    'tradingCapacity': {
      'A': 'Agent',
      'AGENT': 'Agent',
      'P': 'Principal',
      'PRINCIPAL': 'Principal'
    },
    'collateralisation': {
      'U': 'Uncollateralised',
      'UNCOLLATERALISED': 'Uncollateralised',
      'PC': 'Partially',
      'PARTIALLY_COLLATERALISED': 'Partially',
      'OC': 'One way',
      'ONE_WAY_COLLATERALISED': 'One way',
      'FC': 'Fully',
      'FULLY_COLLATERALISED': 'Fully'
    },
    'collateralPortfolio': {
      'Y': 'Yes',
      'YES': 'Yes',
      'N': 'No',
      'NO': 'No'
    },
    'clearingThreshold': {
      'Y': 'Above',
      'ABOVE': 'Above',
      'N': 'Below',
      'BELOW': 'Below'
    },
    'commercialActivity': {
      'Y': 'Yes',
      'YES': 'Yes',
      'N': 'No',
      'NO': 'No'
    },
    'partyType': {
      'INTERNAL': 'Internal',
      'EXTERNAL': 'RoboDelegator',
      'OTHER_CP': 'Other Cp'
    }
  };

  constructor(private standingInstructionsService: StandingInstructionsService, private organisationService: OrganisationService, private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.orgId = this.organisationService.getCachedOrganisationId();

    this.filterForm = this.formBuilder.group({
      filter: ['', Validators.maxLength(20)]
    });

    this.pagerInfo = PagerInfo.calcPagerInfo(0, 0, DatatableConfig.DEFAULT_ROWS);

    this.searchQuery = StandingInstructionsSearchQuery.createEmptySearchQuery();

    this.organisationService.getChangeOrganisationObservable().pipe(takeUntil(this.onDestroy$)).subscribe((organisation: OrganisationModel) => {
      if ((organisation && organisation.organisationId !== this.orgId) || (!organisation && this.orgId) || organisation == undefined) {
        this.orgId = organisation?.organisationId ?? undefined;
        this.loadStandingInstructions();
      }
    });

    this.loadStandingInstructions();

  }

  get cols() {
    return this.tableColumns;
  }

  // getRecordCount() {
  //   return this.recordCount;
  // }

  filter(): void {
    const searchQuery = this.searchQuery || StandingInstructionsSearchQuery.createEmptySearchQuery();
    searchQuery.filter = this.filterForm.controls['filter'].value;
    this.searchQuery = searchQuery;

    this.loadStandingInstructions();
  }

  reset(): void {
    this.filterForm.reset();
    this.filter();
  }

  // event fired by primeng dtable
  onLazyLoad(event: LazyLoadEvent) {

    const currentPage = event.first / event.rows;
    const pagingFilter: PagingFilter = {
      entriesPerPage: event.rows,
      page: currentPage
    };

    const columnName = event.sortField || 'displayName';

    const sortOrder = (event.sortOrder === -1) ? 'DESCENDING' : 'ASCENDING';
    const sortingFilter: SortingFilter = { columnName, sortOrder };

    if (this.searchQuery) {
      this.searchQuery.paging = pagingFilter;
      this.searchQuery.sorting = sortingFilter;
      this.loadStandingInstructions();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  viewStandingInstruction(errPartyId: number) {
    this.showStandingInstructionDetail = true;
    this.errPartyId = errPartyId;

    this.standingInstructionsService.loadStandingInstruction(errPartyId);
  }

  closeModal(): void {
    this.showStandingInstructionDetail = false;
    this.errPartyId = null;
  }

  loadStandingInstructions() {
    if (this.searchQuery) {
      const currentPage = this.searchQuery.paging.page;
      const resultsPerPage = this.searchQuery.paging.entriesPerPage;
      this.loading = true;

      this.standingInstructionsService.loadStandingInstructions(this.searchQuery).then(data => {
        this.loading = false;
        this.records = data.rows.map(row => ({
          ...row
        })).map(row => {
          Object.keys(row).forEach((key) => {
            if (['cpFinancialNature', 'cpCorpSectors', 'tradingCapacity', 'collateralisation', 'collateralPortfolio', 'clearingThreshold', 'commercialActivity', 'partyType'].includes(key)) {
              row = this.mapFieldToLabel(row, key);
            }
          })
          return row;
        });

        this.pagerInfo = PagerInfo.calcPagerInfo(data.recordCount, currentPage, resultsPerPage);
        this.recordCount = data.recordCount;
      });
    }
  }

  mapFieldToLabel(row: any, col: string) {
    if (row == null || !(col in row)) {
      return row;
    }
    const tmp = row[col];
    const result = row[col]?.split(/[ ,;]/).map(key => StandingInstructionsDatatableComponent.valueMapper[col][key]).join(', ');

    if (result != null && result !== tmp) {
      row[col] = result;
    }
    return row;
  }
}

export namespace StandingInstructionsDatatableConfig {

  export const TABLE_COLUMNS: Column[] = [
    { name: 'organisationGroupName', title: 'Org. Group', style: { 'min-width': '100px' }, sortable: true },
    { name: 'displayName', title: 'Display Name', style: { 'min-width': '150px' }, sortable: true },
    { name: 'eicCode', title: 'EIC Code', style: { 'min-width': '130px' }, sortable: true },
    { name: 'leiCode', title: 'LEI Code', style: { 'min-width': '145px' }, sortable: true },
    { name: 'partyType', title: 'Party Type', style: { 'min-width': '120px' }, sortable: true },
    { name: 'cpFinancialNature', title: 'Nature', style: { 'min-width': '145px' }, sortable: true },
    {
      name: 'cpCorpSectors',
      title: 'Sector',
      style: { 'min-width': '120px', 'max-width': '150px', 'text-overflow': 'ellipsis', 'overflow': 'hidden', 'white-space': 'nowrap' },
      sortable: true
    },
    { name: 'tradingCapacity', title: 'Trading Capacity', style: { 'min-width': '140px' }, sortable: true },
    { name: 'beneficiaryId', title: 'Beneficiary', style: { 'min-width': '120px' }, sortable: true },
    { name: 'commercialActivity', title: 'Comm. Activity', style: { 'min-width': '130px' }, sortable: true },
    { name: 'clearingThreshold', title: 'Clearing Threshold', style: { 'min-width': '150px' }, sortable: true },
    { name: 'collateralisation', title: 'Collateralisation', style: { 'min-width': '140px' }, sortable: true },
    { name: 'collateralPortfolio', title: 'Coll. Portfolio', style: { 'min-width': '120px' }, sortable: true },
    { name: 'collPortfolioCode', title: 'Coll. Portfolio Code', style: { 'min-width': '155px' }, sortable: true },
    { name: 'masterAgreeVersion', title: 'Agreement V.', style: { 'min-width': '120px' }, sortable: true }
  ];
}