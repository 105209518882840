<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>

<h1>REMIT Dashboard {{getOrganisationName()}} </h1>

<div class="panel-box margin-bottom-12">
  <cms-info ngPreserveWhitespaces>
    <!--@formatter:off (markdown rendering breaks, when the following tags are formatted)-->
    __REMIT Dashboard__

    This screen informs you about REMIT reporting activity within the last 60 days and helps you spot reporting problems.
    Click on a number in the state / age matrix or on a state to display the corresponding REMIT report records.

    **What Records Are Included?**

    The dashboard supports all 6 ACER formats:
    * standard supply trades and orders, executions of framework contracts - REMIT Table 1
    * non-standard supply trades - REMIT Table 2
    * transportation trades - Table 3 (Electricity Rights) and Table 4 (Gas Capacity)
    * fundamental data - LNG and gas storage reports

    Note that multiple lifecycle events (New, Modify, Cancel, Error) can be reported simultaneously such that multiple records are shown for the same trade.
    This view aims at filtering out irrelevant records including:
    * rejected records for which corrections were sent
    * records that were voided by an "E" (error) record and the accepted "E" records themselves

    REMIT records are shown regardless of their source (automatic submission in CpML or ACER XML, manual entry or file upload).

    **Filter Options (for MPs only)**

    Filter **"View by"** determines how the age of records is shown:
    * Transaction Date = the record's reported transaction date (compliance-centric)
    * Submission Date = the time at which the CMS received the record (operations-centric)

    Filter **"Document Type"** switches between the ACER report types.

    Filter **"Reporting Role"** determines whether the legal entity you selected in the CMS title bar should be the:
    * MP = counterparty on whose behalf the trade was reported
    * Sender = party who submitted the document

    Note that as "MP" you cannot search for trades reported for you by other organisation groups (exception: OMPs).
    The "Sender" setting is particularly useful to find trades which your organisation sent on behalf of external parties.

    **Meaning of the States**

    | State               | Meaning                   |
    | :------------------ |:-- |
    | Manual Intervention | A mapping issue with party codes occurred - the CMS service team is investigating |
    | Not Reported        | Not reported to ACER but reported to ElCom |
    | Pairing             | An OMP-executed trade is waiting in pairing mode (either the OMP or the MP data is outstanding) |
    | Pending             | Submission to ACER ongoing - ACER reply outstanding |
    | Rejected eRR        | eRR rejected the record and will not report it to ACER - correction required |
    | Rejected ACER       | ACER rejected the record - correction required |
    | Accepted ACER       | Successfully reported to ACER |
    <!--@formatter:on-->
  </cms-info>
  <div class="panel-box margin-bottom-15 decent-max-width">
    <cms-remit-dashboard-filter></cms-remit-dashboard-filter>
  </div>
  <div class="margin-bottom-15" style="max-width: 1050px">
    <cms-remit-dashboard-bucket></cms-remit-dashboard-bucket>
  </div>
</div>
<div>
  <cms-remit-datatable></cms-remit-datatable>
</div>

</ng-template>
