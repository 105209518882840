import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RemitFilesInService } from './remit-files-in.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { OrganisationService } from '../../../../common/organisation.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'cms-remit-files-in-upload-panel',
  templateUrl: './remit-files-in-upload-panel.component.html',
  styleUrls: ['./remit-files-in-upload-panel.component.scss']
})
export class RemitFilesInUploadPanelComponent implements OnInit, OnDestroy {

  @ViewChild('fileUpload', { static: false })
  fileUpload: ElementRef;
  private subscription: Subscription;
  private onDestroy$ = new Subject();
  public uploadForm: UntypedFormGroup;
  xmlFile: File;
  fileName: string = '';
  elcomOnly: boolean = false;
  fileSelected: boolean = false;
  uploadAllowed = new BehaviorSubject<boolean>(false);

  constructor(private formBuilder: UntypedFormBuilder, public filesInService: RemitFilesInService, private orgService: OrganisationService) {
    this.uploadForm = formBuilder.group({
      xmlFile: ['', Validators.required],
      elcomOnly: [false]
    });

    this.subscription = filesInService.onResetInput().pipe(filter(Boolean)).subscribe(() => this.reset());
  }

  ngOnInit() {
    this.hasUploadPermission();
    this.hasElcomOnlyPermission();
    this.orgService.getOrganisationChangeIfAccessible()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.hasUploadPermission();
        this.hasElcomOnlyPermission();
      });
  }

  get onUploadPermission(): Observable<boolean> {
    return this.uploadAllowed.asObservable();
  }

  hasUploadPermission() {
    this.filesInService.hasUploadPermission()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(value => {
        this.uploadAllowed.next(value.response);
      });
  }

  hasElcomOnlyPermission() {
    this.filesInService.hasElcomOnlyPermission()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(value => {
        if (value.response == false) {
          this.uploadForm.get('elcomOnly')?.disable();
        } else {
          this.uploadForm.get('elcomOnly')?.enable();
        }
      });
  }

  onSelect(event): void {
    const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    this.fileSelected = this.filesInService.isValidFile(files[0]);
    if (this.fileSelected) {
      this.xmlFile = files[0];
      this.fileName = this.xmlFile.name;
    }
  }

  onCheckbox(event): void {
    this.elcomOnly = event.target.checked;
  }

  ngOnDestroy(): void {
    this.filesInService.setMessage(null);
    this.subscription.unsubscribe();
    this.uploadForm.reset();
    this.reset();
    this.uploadAllowed.next(false);
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  submit() {
    this.filesInService.sendFile(this.xmlFile, this.elcomOnly);
  }

  browseFiles() {
    this.reset();
    this.filesInService.setMessage(null);
    document.getElementById('remit-files-in-upload-button').click();

    // prevents page reload
    return false;
  }

  private reset() {
    if (this.fileUpload) {
      this.fileUpload.nativeElement.value = null;
    }
    this.xmlFile = null;
    this.fileName = '';
    this.fileSelected = false;
  }

}
