import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import {
  CpmlDashboardAssetClassType,
  CpmlDashboardBucketCategoryType,
  CpmlDashboardBucketViewByType,
  CpmlDashboardDocumentFormatType,
  CpmlDashboardDocumentStateType,
  CpmlDashboardTradeRepositoryType
} from '../../dashboard/filter/cpml-dashboard-filter.types';

export type IdType = 'DOCUMENT_ID' | 'UTI' | 'TRADE_ID';

export class CpmlIdFilter {
  searchType: IdType;
  ids: string[];

  public static mapIdType(searchType: IdType): CpmlSearchType {
    switch (searchType) {
      case 'DOCUMENT_ID':
        return CpmlSearchType.DOCUMENT_ID;
      case 'UTI':
        return CpmlSearchType.UTI;
      case 'TRADE_ID':
        return CpmlSearchType.TRADE_ID;
    }
  }

  public static mapISearchType(searchType: CpmlSearchType): IdType {
    switch (searchType) {
      case CpmlSearchType.DOCUMENT_ID:
        return 'DOCUMENT_ID';
      case CpmlSearchType.UTI:
        return 'UTI';
      case CpmlSearchType.TRADE_ID:
        return 'TRADE_ID';
    }
  }
}

export type DocumentType = 'CNF' | 'IRT' | 'ETD' | 'FXT' | 'VAL' | 'COL';

export interface CpmlFilters {
  ids?: string[];
  searchType?: CpmlSearchType;
  documentTypes?: DocumentType[];
  tradeRepositories?: CpmlDashboardTradeRepositoryType[];
  sorting?: SortingFilter;
  paging?: PagingFilter;
}

export enum CpmlSearchType {
  FILTER_SEARCH = 'FILTER_SEARCH',
  DOCUMENT_ID = 'DOCUMENT_ID',
  UTI = 'UTI',
  TRADE_ID = 'TRADE_ID'
}

export class CpmlDealsSearchQuery {

  ids?: string[];
  searchType: CpmlSearchType;
  documentTypes?: DocumentType[];
  sorting: SortingFilter;
  paging: PagingFilter;
  tradeRepositories?: CpmlDashboardTradeRepositoryType[];
  assetClasses?: CpmlDashboardAssetClassType[];
  state?: CpmlDashboardDocumentStateType;
  documentFormats?: CpmlDashboardDocumentFormatType[];
  viewBy?: CpmlDashboardBucketViewByType;
  category?: CpmlDashboardBucketCategoryType;
  dateRange?: string;

  public static isNotEmptySearchQuery(query: CpmlDealsSearchQuery): boolean {
    return query != null && JSON.stringify(query) !== JSON.stringify(CpmlDealsSearchQuery.createEmptySearchQuery());
  }

  public static isDealFinderSearchQuery(query: CpmlDealsSearchQuery): boolean {
    return (query.ids != null && query.ids.length > 0)
      || (query.documentTypes != null && query.documentTypes.length > 0);
  }

  public static createEmptySearchQuery(): CpmlDealsSearchQuery {
    return {
      ids: undefined,
      documentTypes: undefined,
      tradeRepositories: undefined,
      searchType: undefined,
      paging: {
        entriesPerPage: 25,
        page: 0
      },
      sorting: {
        columnName: 'RECEIPT_TIMESTAMP',
        sortOrder: 'DESCENDING',
      }
    };
  }

  public static createEmptyBucketSearchQuery(): CpmlDealsSearchQuery {
    return {
      ids: undefined,
      documentTypes: undefined,
      searchType: undefined,
      viewBy: undefined,
      category: undefined,
      dateRange: undefined,
      state: undefined,
      tradeRepositories: undefined,
      assetClasses: [],
      documentFormats: [],
      paging: {
        entriesPerPage: 25,
        page: 0
      },
      sorting: {
        columnName: 'RECEIPT_TIMESTAMP',
        sortOrder: 'DESCENDING',
      }
    };
  }

}
