import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import { DialogState } from '@common/state/reducers';
import { createAction, union } from '@ngrx/store';
import { EsmExternalMasterDataPresetValues, TableValues } from 'app/esm/master-data/external-master-data/external-master-data.model';
import { AddBankDetailValue, EsmBankDetails, EsmExternalFormValues, EsmMessagesModel, LoadBankDetailsResponse, UpdateBankdetailRequest } from 'app/esm/master-data/master-data.model';
import { Message } from 'primeng/api';
import { ESM_MD_DIALOGS } from '../master-data.reducer';

const PREFIX = '[ESM EXTERNAL MASTER DATA] ';

export const setMessages = createAction(PREFIX + 'Set Messages', (payload: Message[]) => ({ payload }));
export const setDetailsMessages = createAction(PREFIX + 'Set Details Messages', (payload: EsmMessagesModel) => ({ payload}));
export const setTableLoading = createAction(PREFIX + 'Set Table Loading', (payload: boolean) => ({ payload }));
export const setUploadLoading = createAction(PREFIX + 'Set Upload Loading', (payload: boolean) => ({ payload }));
export const setBankDetailsLoading = createAction(PREFIX + 'Set Bank Details Loading', (payload: boolean) => ({ payload }));

export const loadPresetValues = createAction(PREFIX + 'Preset Values');
export const loadPresetValuesSuccess = createAction(PREFIX + 'Preset Values Success', (payload: EsmExternalMasterDataPresetValues) => ({payload}));

export const loadFormValues = createAction(PREFIX + 'Load Form Values', (payload: {masterDataId: number}) => ({ payload}));
export const loadFormValuesSuccess = createAction(PREFIX + 'Load Form Values Success', (payload: EsmExternalFormValues) => ({payload}));

export const loadBankDetailsAction = createAction(PREFIX + 'Load Bank Details', (payload: number) => ({ payload} ));
export const loadBankDetailsSuccessAction = createAction(PREFIX + 'Load Bank Details Success', (payload: LoadBankDetailsResponse) => ({ payload }));

export const addBankDetailAction = createAction(PREFIX + 'Add Bank Detail', (payload: {data: AddBankDetailValue, masterDataId: number, version: number}) => ( { payload } ));
export const deleteBankDetailAction = createAction(PREFIX + 'Delete Bank Detail', (payload: EsmBankDetails) => ({ payload}));
export const updateBankDetailAction = createAction(PREFIX + 'Update Bank Detail', (payload: {data: UpdateBankdetailRequest, masterDataId: number}) => ( {payload}));

export const downloadCsv = createAction(PREFIX + 'Download Csv-File');
export const uploadCsv = createAction(PREFIX + 'Upload Csv-File', (payload: File) => ({ payload }));
export const uploadCsvSuccess = createAction(PREFIX + 'Upload Csv-File succeeded');

export const loadTableData = createAction(PREFIX + 'Load Table Data');
export const loadTableDataSuccess = createAction(PREFIX + 'Load Table Data Success', (payload: TableValues) => ({ payload }));

export const pagingAndSorting = createAction(PREFIX + 'Paging and Sorting', (payload: { sorting: SortingFilter, paging: PagingFilter }) => ({ payload }));
export const bankDetailsPagingAndSorting = createAction(PREFIX + 'Bank Details Paging and Sorting', (payload: { sorting: SortingFilter, paging: PagingFilter }) => ({ payload }));

export const setSearchToken = createAction(PREFIX + 'Setting Search Token', (payload: string) => ({payload}));

export const cleanState = createAction(PREFIX + 'Clean State');
export const clearDetails = createAction(PREFIX + 'Clear Details');

export const deleteMDRecord = createAction(PREFIX + 'Delete Master Data Record', (payload: {masterDataId: number, version: number}) => ({ payload }));

export const persistAction = createAction(PREFIX + 'Persist', (payload: EsmExternalFormValues) => ({payload}));
export const persistSuccessAction = createAction(PREFIX + 'Persist Success', (payload: EsmExternalFormValues) => ({payload}));

export const updateAction = createAction(PREFIX + 'Update', (payload: EsmExternalFormValues) => ({payload}));
export const updateSuccessAction = createAction(PREFIX + 'Update Success', (payload: EsmExternalFormValues) => ({payload}));

export const closeDialogAction = createAction(PREFIX + 'Close Dialog');
export const openDialogAction = createAction(PREFIX + 'Open Dialog', (payload: DialogState<ESM_MD_DIALOGS>) => ({payload}));
export const updateDialogAction = createAction(PREFIX + 'Close Dialog', (payload: Message[]) => ({payload}));
export const setToDefaultAction = createAction(PREFIX + 'Set to Default');

export const createNewRecord = createAction(PREFIX + 'Create New Master Data Record');


const actions = union({
    setMessages,
    setDetailsMessages,
    setTableLoading,
    setUploadLoading,
    setBankDetailsLoading,
    loadPresetValues,
    loadPresetValuesSuccess,
    loadFormValues,
    loadFormValuesSuccess,
    loadBankDetailsAction,
    loadBankDetailsSuccessAction,
    addBankDetailAction,
    deleteBankDetailAction,
    updateBankDetailAction,
    downloadCsv,
    uploadCsv,
    uploadCsvSuccess,
    loadTableData,
    loadTableDataSuccess,
    pagingAndSorting,
    bankDetailsPagingAndSorting,
    setSearchToken,
    cleanState,
    clearDetails,
    deleteMDRecord,
    persistAction,
    persistSuccessAction,
    updateAction,
    updateSuccessAction,
    closeDialogAction,
    openDialogAction,
    updateDialogAction,
    setToDefaultAction,
    createNewRecord
});

export type MasterDataAction = typeof actions;
