import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RemitTradeEntryTable1Service } from '../remit-trade-entry-table1.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cms-remit-trade-entry-table1-delivery-profile',
  templateUrl: './remit-trade-entry-table1-delivery-profile.component.html',
  styleUrls: ['./remit-trade-entry-table1-delivery-profile.component.scss']
})
export class RemitTradeEntryTable1DeliveryProfileComponent implements OnInit, OnDestroy {

  @Input()
  formGroup: UntypedFormGroup;

  private onDestroy$ = new Subject();

  componentCount: number = 0;

  constructor(private tradeEntryService: RemitTradeEntryTable1Service, private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.componentCount = 0;

    this.formGroup.addControl('deliveryProfiles', this.formBuilder.array(
      [this.createItem()]
    ));

    this.tradeEntryService.onReset().pipe(takeUntil(this.onDestroy$)).subscribe(reseted => {
      if (reseted) {
        while (!this.isRemoveDisabled()) {
          this.remove();
        }
      }
    });



    this.tradeEntryService.onValidationErrorsFieldNamesChanged().pipe(takeUntil(this.onDestroy$))
      .subscribe(
        fieldName => {
          if (fieldName && fieldName.indexOf('startTime') >= 0) {
            const items = this.formGroup.controls['deliveryProfiles'] as UntypedFormArray;

            const index = fieldName.charAt(fieldName.length - 1);
            const formGroup = items.controls[parseInt(index, 10)] as UntypedFormGroup;
            formGroup.controls['startTime'].setErrors({ error: true });
          }

          if (fieldName && fieldName.indexOf('endTime') >= 0) {
            const items = this.formGroup.controls['deliveryProfiles'] as UntypedFormArray;

            const index = fieldName.charAt(fieldName.length - 1);
            const formGroup = items.controls[parseInt(index, 10)] as UntypedFormGroup;
            formGroup.controls['endTime'].setErrors({ error: true });
          }
        }
      );
  }

  createItem(): UntypedFormGroup {
    return this.formBuilder.group({
      daysOfTheWeek: '',
      startTime: [''],
      endTime: ['']
    });
  }

  add() {
    if (!this.isAddDisabled()) {
      this.formGroup.markAsTouched();
      this.componentCount++;
      const items = this.formGroup.get('deliveryProfiles') as UntypedFormArray;
      items.push(this.createItem());
      // re-validate times
      this.onStartTimeChange();
      this.onEndTimeChange();
    }
  }

  isAddDisabled(): boolean {
    return this.componentCount > 1;
  }

  remove() {
    if (!this.isRemoveDisabled()) {
      const items = this.formGroup.get('deliveryProfiles') as UntypedFormArray;
      items.removeAt(this.componentCount);
      this.componentCount--;
      // re-validate times
      this.onStartTimeChange();
      this.onEndTimeChange();
    }
  }

  getValidationErrorStyleClass(index: number, fieldName: string): string {

    const items = this.formGroup.get('deliveryProfiles') as UntypedFormArray;
    if (items && items.controls[index]) {

      const formGroup = items.controls[index] as UntypedFormGroup;

      if (formGroup.controls[fieldName].hasError('error')) {
        return ' error';
      }
    }

    return '';
  }

  isRemoveDisabled(): boolean {
    return this.componentCount === 0;
  }

  onStartTimeChange() {
    this.tradeEntryService.startTimeValid.next(this.areStartTimesValid());
  }

  onEndTimeChange() {
    this.tradeEntryService.endTimeValid.next(this.areEndTimesValid());
  }

  public areStartTimesValid(): boolean {
    let valid = true;
    for (let i = 0; i < (this.componentCount+1); i++) {
      const st = ((this.formGroup.controls['deliveryProfiles'] as UntypedFormArray).controls[i] as UntypedFormGroup).controls['startTime'];
      valid = valid && (st.value != null && st.value.length > 0 && st.errors == null);
    }
    return valid;
  }

  public areEndTimesValid(): boolean {
    let valid = true;
    for (let i = 0; i < (this.componentCount+1); i++) {
      const et = ((this.formGroup.controls['deliveryProfiles'] as UntypedFormArray).controls[i] as UntypedFormGroup).controls['endTime'];
      valid = valid && (et.value != null && et.value.length > 0 && et.errors == null);
    }
    return valid;
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

}
