<p-dialog *ngIf="display"
          header="Edit eSM Process Details"
          [visible]="display"
          [modal]="true"
          [contentStyle]="{overflow: 'auto', height: '692px'}"
          [positionTop]="110"
          [resizable]="false"
          (visibleChange)="onVisibleChange($event)"
          [style]="{width: '650px', minWidth: '500px'}">
  <p-messages [value]="messages" [closable]="true"></p-messages>
  <div class="margin-left-15 margin-right-15 margin-top-6 mb-0">
    <form [formGroup]="form" class="row" autocomplete="off">
      <div class="col-12 margin-bottom-12">
        <label for="esmEnabled" class="col-4 float-left">enable eSM process</label>
        <input type="checkbox" class=" margin-0" id="esmEnabled" formControlName="esmEnabled" />
      </div>
      <div class="col-12 margin-bottom-12">
        <label for="interoperationParty" class="col-4 float-left">Interoperation Party</label>
        <input type="checkbox" class=" margin-0" id="interoperationParty" formControlName="interoperationParty" (change)="checkStatusWebUser()" />
      </div>
      <div class="col-12 margin-bottom-12">
        <label for="eSMLiteUser" class="col-4 float-left">eSM Lite User</label>
        <input type="checkbox" class=" margin-0" id="eSMLiteUser" formControlName="webUser" (change)="checkStatusInterop()" />
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label for="partnerId" class="col-4 float-left">Partner ID:<span class="required">*</span></label>
        <div class="col-8">
          <input cmsUpperCase autocomplete="off" id="partnerId" type="text" formControlName="partnerId" [ngClass]="{'error': errors['partnerId']}" class="ng-trim-ignore form-control col-8 padding-6" />
        </div>
      </div>
    </form>
  </div>
  <p-footer>
    <button class="btn margin-right-3" type="button" (click)="onCancel()">Cancel</button>
    <button class="btn" type="button" (click)="onConfirm()">Update</button>
  </p-footer>
</p-dialog>
