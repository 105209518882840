<form class="row col-12 padding-0" [formGroup]="form">
  <div class="col-lg-2 col-md-12 col-12 margin-3 padding-0 text-start">
    <p-dropdown class="padding-0 margin-3"
                [style]="{'width':'190px'}"
                [filter]="true"
                [filterFields]="['label']"
                [options]="orgGroupsOptions"
                formControlName="organisationGroupId"
                filterMatchMode="startsWith"
                (onChange)="onChange()">
    </p-dropdown>
  </div>
</form>
