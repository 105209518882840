import { Action } from '@ngrx/store';
import { Message } from 'primeng/api';
import { InitialStartAndEndDate, InitialStartAndEndDateResponse } from '../../reports/reports.model';
import { ReportType } from '../../ecm.model';

export const LOAD_INITIAL_START_AND_END_DATE = '[ECM REPORTS] Load Initial Start And End Date';
export const LOAD_INITIAL_START_AND_END_DATE_SUCCESS = '[ECM REPORTS] Load Initial Start And End Date Success';
export const DOWNLOAD_ECM_REPORT = '[ECM REPORTS] Download Report';
export const DOWNLOAD_SUCCESS = '[ECM REPORTS] Download Success';
export const VALIDATION_FAILED = '[ECM REPORTS] Validation Failed';
export const VALIDATE = '[ECM REPORTS] Validate Input';
export const SET_MESSAGES = '[ECM REPORTS] Set Messages';
export const SET_TO_DEFAULT = '[ECM REPORTS] Set To Default';

export class LoadInitialStartAndEndDateAction implements Action {
  readonly type = LOAD_INITIAL_START_AND_END_DATE;
}

export class ValidateAction implements Action {
  readonly type = VALIDATE;
  constructor(public payload: {
    filter: InitialStartAndEndDate,
    reportType: ReportType
  }) { }
}

export class DownloadSuccesAction implements Action {
  readonly type = DOWNLOAD_SUCCESS;
  constructor(public payload: { reportType: ReportType }) { }
}

export class ValidationFailed implements Action {
  readonly type = VALIDATION_FAILED;
  constructor(public payload: { reportType: ReportType }) { }
}

export class LoadInitialStartAndEndDateSuccessAction implements Action {
  readonly type = LOAD_INITIAL_START_AND_END_DATE_SUCCESS;
  constructor(public payload: InitialStartAndEndDateResponse) { }
}

export class DownloadEcmDetailsAction implements Action {
  readonly type = DOWNLOAD_ECM_REPORT;
  constructor(public payload: {
    filter: InitialStartAndEndDate,
    reportType: ReportType
  }) { }
}

export class SetMessagesAction implements Action {
  readonly type = SET_MESSAGES;
  constructor(public payload: Message[]) { }
}

export class SetToDefault implements Action {
  readonly type = SET_TO_DEFAULT;
}

export type EcmReportsActions = LoadInitialStartAndEndDateAction | LoadInitialStartAndEndDateSuccessAction |
  DownloadEcmDetailsAction | DownloadSuccesAction |
  SetMessagesAction | SetToDefault | ValidateAction | ValidationFailed;
