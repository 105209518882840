import { Column } from '../../../common/shared/datatable.model';

export namespace ErrRemitDataTableRemitTable2Config {


  const defaultStyles = {
    'text-align': 'left',
    'text-overflow': 'ellipsis',
    'overflow': 'hidden',
    'white-space': 'nowrap',
    'height': '27px'
  };


  export const TABLE_COLUMNS: { [name: string]: Column } = {
    // Keep this and the following files aligned.
    // app.bsl/de.ponton.cmh.bsl.bslimpl/src/de/ponton/cmh/bsl/bslimpl/internal/service/userinterfacecustomisation/ErrRemitT2PersonalColumnEnum.java
    // app.gui/de.ponton.cmh.err.ui.data/src/de/ponton/cmh/err/ui/data/uio/remit/transactions/table2/ErrRemitTable2TransactionsUIO.properties
    // app.gui/de.ponton.cmh.err.ui.components/src/de/ponton/cmh/err/ui/components/remit/transactions/table2/columnorder/ErrRemitTable2ColumnOrderDataPanel.properties

    creationTimestamp: { title: 'Received by eRR', style: { 'width': '165px',  ...defaultStyles } },
    lastUpdate: { title: 'Last Update', style: { 'width': '165px',  ...defaultStyles } },
    originatorName: { title: 'Sender Name', style: { 'width': '150px',  ...defaultStyles } },
    buySellIndicator: { title: 'B/S', longTitle: 'Buy/Sell Indicator', style: { 'width': '70px',  ...defaultStyles } },
    action: { title: 'Action', longTitle: 'Action Type', style: { 'width': '90px',  ...defaultStyles } },
    contractDate: { title: 'Contract Date', style: { 'width': '140px',  ...defaultStyles } },
    buttons: {
      title: 'Buttons',
      style: { 'width': '230px',  ...defaultStyles },
      sortable: false,
      hiddenTitle: true
    },
    state: { title: 'State', style: { 'width': '130px',  ...defaultStyles } },
    contractId: { title: 'UTI (Contract ID)', style: { 'width': '150px',  ...defaultStyles } },
    mpName: { title: 'MP Name', longTitle: 'MP Name (LEI Registry)', style: { 'width': '150px',  ...defaultStyles } },
    otherMpName: {
      title: 'Other MP Name',
      longTitle: 'Other MP Name (LEI Registry)',
      style: { 'width': '150px',  ...defaultStyles }
    },
    deleted: { title: 'Deleted', style: { 'width': '100px',  ...defaultStyles } },

    contractType: { title: 'Contract Type', style: { 'width': '150px',  ...defaultStyles } },
    beneficiaryPartyCode: { title: 'Beneficiary Code', style: { 'width': '170px',  ...defaultStyles } },
    commodity: { title: 'Comm', longTitle: 'Commodity', style: { 'width': '100px',  ...defaultStyles } },
    deliveryPoint: { title: 'Delivery Point', style: { 'width': '150px',  ...defaultStyles } },
    deliveryStartDate: { title: 'Delivery Start', style: { 'width': '140px',  ...defaultStyles } },
    deliveryEndDate: { title: 'Delivery End', style: { 'width': '135px',  ...defaultStyles } },
    elcomLastUpdate: { title: 'ElCom Last Update', style: { 'width': '180px',  ...defaultStyles } },
    elcomState: { title: 'ElCom State', style: { 'width': '135px',  ...defaultStyles } },
    errorDetails: { title: 'Error Details', style: { 'width': '350px',  ...defaultStyles, 'cursor': 'help' }, editable: true },
    inputFilename: {
      title: 'Filename (Input File)',
      style: { 'width': '210px',  ...defaultStyles, 'cursor': 'help' },
      editable: true
    },
    reportedFilename: {
      title: 'Filename (Reported)',
      style: { 'width': '210px',  ...defaultStyles, 'cursor': 'help' },
      editable: true
    },
    otherMpPartyCode: { title: 'Other MP Code', style: { 'width': '190px',  ...defaultStyles } },
    mpPartyCode: { title: 'MP Code', style: { 'width': '190px' } },
    price: { title: 'Price', style: { 'width': '100px', 'text-align': 'right' } },
    currency: { title: 'Cur.', longTitle: 'Price Currency', style: { 'width': '90px',  ...defaultStyles } },
    notionalAmountValue: { title: 'Notional Amount', style: { 'width': '170px', 'text-align': 'right' } },
    notionalAmountCurrency: {
      title: 'Not. Cur.',
      longTitle: 'Notional Amount Currency',
      style: { 'width': '110px',  ...defaultStyles }
    },
    totalQuantityValue: {
      title: 'Total Quantity',
      longTitle: 'Total Notional Contract Quantity',
      style: { 'width': '150px', 'text-align': 'right' }
    },
    totalQuantityUnit: {
      title: 'Total Unit',
      longTitle: 'Total Notional Contract Quantity Unit',
      style: { 'width': '120px',  ...defaultStyles }
    },

    // always hidden
    // dataStoreId: { title: 'DataStore Id', style: { 'width': '120px' }, visible: false },
  };
}
