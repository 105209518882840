import { Message } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Column } from '@common/shared/datatable.model';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { DatatableState } from '@common/datatable/datatable.model';
import { DashboardDealsNavModel } from '@common/dashboard-deals-nav/dashboard-deals-nav.model';
import { AddNote, Dispute, NotesCellViewEvent } from '@common/deals/deals.model';
import {
  EsmBucketsRows,
  EsmDashboardPresetFilters,
  EsmDashboardTableType,
  EsmDashboardTabsType,
  EsmDateRange,
  EsmInvoiceFinderDeal,
  EsmRoleByType,
  EsmStatesEnum,
  esmStatesToLabelMaps
} from '../esm-dashboard.model';
import { INVOICE_FINDER_DIALOGS } from '../../finder/invoice-finder.model';
import { EsmDashboardState } from '../../../state/dashboard/dashboard.reducers';
import * as fromDBActions from '../../../state/dashboard/dashboard.actions';
import * as fromEsmDBFiltersActions from '../../../state/dashboard/filters/filters.actions';
import * as fromDBTableActions from '../../../state/dashboard/table/table.actions';
import * as fromIFTableActions from '../../../state/invoice-finder/table/table.actions';
import * as fromTableActions from '../../../state/invoice-finder/table/table.actions';
import * as fromIFDialogActions from '../../../state/invoice-finder/dialog/dialog.actions';
import * as fromDialogActions from '../../../state/invoice-finder/dialog/dialog.actions';
import * as fromEsmDBFiltersSelectors from '../../../state/dashboard/filters/filters.selectors';
import * as fromEsmDBTableSelectors from '../../../state/dashboard/table/table.selectors';
import * as fromEsmDashboardSelectors from '../../../state/dashboard/dashboard.selectors';
import * as fromCommon from '@common/state/reducers';
import { Amounts } from '../../../common/table-toast/table-toast.model';

interface BucketsHeader {
  title: string;
  colspan: number;
  style: any;
}

@Component({
  selector: 'cms-esm-dashboard-tab',
  templateUrl: './esm-dashboard-tab.component.html',
  styleUrls: ['./esm-dashboard-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EsmDashboardTabComponent implements OnInit, OnDestroy {
  @Input() category: EsmDashboardTabsType;
  tableViewType = EsmDashboardTableType;
  selectedIds$: Observable<string[]> = this.store.pipe(select(fromEsmDBTableSelectors.getSelectedIds));
  dialog$: Observable<fromCommon.DialogState<INVOICE_FINDER_DIALOGS>> = this.store.pipe(select(fromEsmDashboardSelectors.getDialog));
  viewType$: Observable<EsmDashboardTableType> = this.store.pipe(select(fromEsmDashboardSelectors.getViewType));
  loading$: Observable<boolean> = this.store.pipe(select(fromEsmDBTableSelectors.getLoading));
  messages$: Observable<Message[]> = this.store.pipe(select(fromEsmDBTableSelectors.getMessages));
  filters$: Observable<EsmDashboardPresetFilters> = this.store.pipe(select(fromEsmDBFiltersSelectors.getFiltersValues));
  counterParties$: Observable<{ key: number, value: string }[]> = this.store.pipe(select(fromEsmDBFiltersSelectors.getCounterPartiesValues));
  commodities$: Observable<string[]> = this.store.pipe(select(fromEsmDBFiltersSelectors.getCommoditiesValues));
  deliveryPoints$: Observable<{keyObject:string,valueObject:string}[]> = this.store.pipe(select(fromEsmDBFiltersSelectors.getDeliveryPointsValues));
  invoiceTypes$: Observable<string[]> = this.store.pipe(select(fromEsmDBFiltersSelectors.getInvoiceTypesValues));
  invoiceRole$: Observable<EsmRoleByType> = this.store.pipe(select(fromEsmDBFiltersSelectors.getInvoiceRoleValue));
  state$: Observable<EsmStatesEnum | string> = this.store.pipe(select(fromEsmDashboardSelectors.getState));
  stateNames$: Observable<DashboardDealsNavModel[]> = this.store.pipe(select(fromEsmDashboardSelectors.getStateNames));
  columns$: Observable<Column[]> = this.store.pipe(select(fromEsmDBTableSelectors.getColumns));
  data$: Observable<DatatableData<EsmInvoiceFinderDeal[] | EsmBucketsRows[]>> = this.store.pipe(select(fromEsmDBTableSelectors.getData));
  sorting$: Observable<SortingFilter> = this.store.pipe(select(fromEsmDBTableSelectors.getSorting));
  paging$: Observable<PagingFilter> = this.store.pipe(select(fromEsmDBTableSelectors.getPaging));
  dateRangeText$: Observable<string> = this.store.pipe(select(fromEsmDBFiltersSelectors.getDateRangeText));
  subscription$: Subscription = new Subscription();
  additionalHeader: BucketsHeader[] = [];
  readonly stateValuesMapping: { [key: string]: string } = esmStatesToLabelMaps;
  private selectedInvoices: EsmInvoiceFinderDeal[] = [];
  selected$: Observable<EsmInvoiceFinderDeal[]> = this.store.pipe(select(fromEsmDBTableSelectors.getSelectedInvoices));
  selected: Amounts[] = [];
  isMultiAgreeVisible : boolean = false;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private store: Store<EsmDashboardState>) {
  }

  ngOnInit(): void {
    this.subscription$ = this.columns$
      .pipe(withLatestFrom(this.viewType$))
      .subscribe(([data, type]) => {
        if (data && data.length && EsmDashboardTableType.BUCKETS === type) {
          this.additionalHeader = this.buildAdditionalHeader(data);
        }
      });
    this.subscription$.add(this.data$.pipe(
      filter(d => d && this.isEsmInvoiceFinderDeal(d)),
      map(d => d as DatatableData<EsmInvoiceFinderDeal[]>)).subscribe(data => {
      if (data && data.values && this.selectedInvoices) {
        const ids = this.selectedInvoices.map(s => s.id);
        const selected: EsmInvoiceFinderDeal[] = data.values.filter(v => ids.indexOf(v.id) !== -1);
        this.onSelected(selected);
      }
    }));
    this.subscription$.add(this.filters$.subscribe(filters => {
      this.isMultiAgreeVisible = Boolean(filters?.multiAgreeButtonVisible);
    }));
  }

  private isEsmInvoiceFinderDeal(d: DatatableData<EsmInvoiceFinderDeal[] | EsmBucketsRows[]>): d is DatatableData<EsmInvoiceFinderDeal[]> {
    return (<DatatableData<EsmInvoiceFinderDeal[]>>d) !== undefined;
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
    this.selectedInvoices = [];
  }

  onClickCustomize(): void {
    this.router.navigate(['/settings/datatable/customize'], { queryParams: { documentType: 'ESM_INVOICE' } });
  }

  onLazyLoad(state: DatatableState): void {
    this.store.dispatch(fromDBTableActions.sortAndPageDataAction({
      paging: {
        page: state.page,
        entriesPerPage: state.perPage
      },
      sorting: {
        columnName: state.sortField,
        sortOrder: state.sortOrder
      }
    }));
  }

  onExport(): void {
    this.store.dispatch(fromDBTableActions.exportAction());
  }

  onDownloadXml({ id }: EsmInvoiceFinderDeal): void {
    this.store.dispatch(fromIFTableActions.downloadXmlAction(id));
  }

  onDownloadPdf({ id }: EsmInvoiceFinderDeal): void {
    this.store.dispatch(fromIFTableActions.downloadPdfAction(id));
  }

  getDisplayParams({ id, documentId }: EsmInvoiceFinderDeal): object {
    return {
      displayValue: documentId,
      urlPart: 'esm/invoice',
      invoiceId: id
    };
  }

  onFilter(searchFilter: EsmDashboardPresetFilters): void {
    this.store.dispatch(fromEsmDBFiltersActions.setFilterValuesAction(searchFilter));
    this.store.dispatch(fromEsmDBFiltersActions.filterAction());
  }

  onRoleByFilter(roleBy: EsmRoleByType): void {
    this.store.dispatch(fromEsmDBFiltersActions.setRoleByFilterValueAction(roleBy));
    this.store.dispatch(fromEsmDBFiltersActions.filterAction());
  }

  onReset(): void {
    this.store.dispatch(fromEsmDBFiltersActions.resetFiltersAction());
  }

  backToDashboard(): void {
    this.store.dispatch(fromDBTableActions.setTablePagingStateAction({
      paging: {
        page: 0,
        entriesPerPage: 25
      }
    }));
    this.router.navigate([`/esm/dashboard/${this.category}`]);
    this.store.dispatch(fromDBActions.setStateAction(null));
    this.store.dispatch(fromDBActions.setStateNamesAction([]));
    this.store.dispatch(fromEsmDBFiltersActions.setDateRangeAction(null));
  }

  changeState({ state }: DashboardDealsNavModel): void {
    const dateRange = this.route.snapshot.queryParamMap.get('dateRange');
    const currState = this.route.snapshot.queryParamMap.get('state');
    if (currState !== state) {
      this.store.dispatch(fromDBActions.setStateAction(state));
      this.router.navigate([`/esm/dashboard/${this.category}/deals`],
        {
          queryParams: { state, dateRange }
        });
    }
  }

  onClick({ state }: EsmBucketsRows, { name }: Column): void {
    const dateRange = name !== 'state' ? name : EsmDateRange.TOTAL;
    this.store.dispatch(fromDBActions.setStateAction(state));
    this.store.dispatch(fromEsmDBFiltersActions.setDateRangeAction(dateRange));
    this.store.dispatch(fromDBActions.setViewTypeAction(EsmDashboardTableType.TABLE_DATA));
    this.router.navigate([`/esm/dashboard/${this.category}/deals`],
      {
        queryParams: { state, dateRange }
      });
  }

  onOpenAddNoteDialog(): void {
    this.store.dispatch(fromIFDialogActions.openDialogAction({
      name: 'ADD_NOTES',
      messages: [],
    }));
  }

  onViewNotes(data: NotesCellViewEvent): void {
    this.store.dispatch(fromIFDialogActions.openDialogAction({
      name: 'VIEW_NOTES',
      messages: [],
      data
    }));
  }

  onSelected(invoices: EsmInvoiceFinderDeal[]) {
    this.selectedInvoices = invoices;
    let confirmationIds: string[] = [];
    if (invoices) {
      confirmationIds = invoices.map(d => d.id + '');
      this.selected = invoices.map(this.amounts);

    }
    this.store.dispatch(fromDBTableActions.setSelectedIdsAction({confirmationIds}));
    this.store.dispatch(fromDBTableActions.setSelectedInvoicesAction({invoices}));
  }

  onConfirmAddNotes(obj: AddNote): void {
    this.store.dispatch(fromIFTableActions.addNoteAction(obj));
  }

  onCancelNotesDialog(): void {
    this.store.dispatch(fromIFDialogActions.closeDialogAction());
  }

  private buildAdditionalHeader(columns: Column[]): BucketsHeader[] {
    const index = columns.map(item => item.name).indexOf(EsmDateRange.TOTAL) + 1;
    const style = { 'color': '#1b1d1f' };
    return [
      {
        title: '',
        colspan: index,
        style
      },
      {
        title: 'Days Until Payment Date',
        colspan: columns.length - index,
        style
      }
    ];
  }

  resendPDF({ id }: EsmInvoiceFinderDeal) {
    this.store.dispatch(fromIFTableActions.resendAction({ id, screen: 'dashboard' }));
  }

  agreeItem(invoice: EsmInvoiceFinderDeal) {
    this.store.dispatch(fromDialogActions.openDialogAction({
      name: 'AGREE',
      messages: [],
      data: [invoice]
    }));
  }
  disputeItem(invoice : EsmInvoiceFinderDeal) {
    this.store.dispatch(fromDialogActions.openDialogAction({
      name: 'DISPUTE',
      messages: [],
      data: invoice
    }));
  }

  onOpenMultiAgreeDialog() {
    this.store.dispatch(fromDialogActions.openDialogAction({
      name: 'AGREE',
      messages: [],
      data : this.selectedInvoices
    }));
  }

  onCancelAgree() {
    this.store.dispatch(fromDialogActions.closeDialogAction());
  }

  onConfirmAgree(confirmationIds: string[]) {
    this.store.dispatch(fromTableActions.confirmAgreeInvoices({invoiceIds: confirmationIds}));
  }

  onCancelDispute() {
    this.store.dispatch(fromDialogActions.closeDialogAction());
  }

  onConfirmDispute(data: Dispute) {
    this.store.dispatch(fromTableActions.confirmDisputeInvoice(data));
  }

  // ======= Helper ======= //

  amounts = (item: EsmInvoiceFinderDeal):Amounts => ({vatRat:item.vatRate, gross: item.totalGrossAmount, vat: item.vatAmount, grossCur: item.totalGrossAmountCurrency, vatCur:item.vatAmountCurrency });

}
