import { Directive, HostListener, forwardRef } from '@angular/core';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[cmsUpperCase]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UpperCaseDirective),
    multi: true
  }]
})
export class UpperCaseDirective extends DefaultValueAccessor {
  @HostListener('input', ['$event.target.value']) ngOnChange(val: string): void {
    this.writeValue(val.trim().toUpperCase());
    this.onChange(val.trim().toUpperCase());
  }

  @HostListener('blur', ['$event.target.value']) ngOnBlur(val: string): void {
    this.writeValue(val.trim().toUpperCase());
    this.onTouched();
  }

  writeValue(value: any): void {
    if (typeof value === 'string') {
      value = value.trim().toUpperCase();
    }

    super.writeValue(value);
  }
}

