import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Message } from 'primeng/api';
import { select, Store } from '@ngrx/store';
import { EsmInternalMasterDataState } from '../../../../state/master-data/internal-master-data/internal-master-data.reducer';
import { uploadPdfSettingsLogoAction } from '../../../../state/master-data/internal-master-data/pdf-settings/pdf-settings.actions';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { EsmPdfSettings } from '../../../internal-master-data/internal-master-data.model';
import * as fromMDPdfSelectors from '../../../../state/master-data/internal-master-data/pdf-settings/pdf-settings.selectors';
import { takeUntil } from 'rxjs/operators';
import { OrganisationService } from '@common/organisation.service';

@Component({
  selector: 'cms-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit, OnDestroy {

  @Input()
  masterDataId: number;
  @Input()
  organisationId: number;

  @Input()
  uploadAllowed: boolean = false;
  @Output()
  error = new EventEmitter<Message[]>();

  logo = new Image();
  loading: boolean = false;
  busy: boolean = false;
  private logoVersion: number = 0;
  logoImageSrc$ = new BehaviorSubject<string>('');
  pdfSetting$: Observable<EsmPdfSettings> = this.store.pipe(select(fromMDPdfSelectors.getEsmPdfSettingsValues));
  logoAvailable$: Observable<boolean> = this.store.pipe(select(fromMDPdfSelectors.isEsmPdfSettingsLogoAvailable));
  private onDestroy$ = new Subject();

  constructor(private store: Store<EsmInternalMasterDataState>, private organisationService: OrganisationService) {
  }

  ngOnInit() {

    this.pdfSetting$.pipe(takeUntil(this.onDestroy$)).subscribe(value => {
      if (value.logoAvailable && this.organisationService.getCurrentOrganisationId() !== null) {
        this.loading = true;
        const src: string = `/api/esm/masterdata/pdfsettings/downloadLogo?organisationId=${this.organisationId}&masterDataId=${value.masterDataId}&v=${this.logoVersion}`.toString();
        this.logoImageSrc$.next(src);
        this.logoVersion++;
      }

      this.busy = false;
    });
  }

  onUpload(file: File): void {
    this.busy = true;
    this.store.dispatch(uploadPdfSettingsLogoAction(file));
  }

  get logoAsBackgroundSrc$(): Observable<string> {
    return this.logoImageSrc$.asObservable();
  }

  onLogoLoaded(event: any): void {
   this.loading = false;
  }
  onUploadError(messages: Message[]): void {
    this.error.emit(messages);
  }

  ngOnDestroy(): void {
    this.busy = false;
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

}
