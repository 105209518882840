import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OrganisationService } from '@common/organisation.service';
import { ErrorMessageService } from '@common/errors/error-message.service';
import { Observable, Subscription } from 'rxjs';
import { Message } from 'primeng/api';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { TableEntry, esmPresetsTableColumns } from './web-user.model';
import { getLoading, getMessages, getPaging, getSorting, getRights, getTableData } from '../../state/permissions/permissions.selectors';
import { cleanState, loadPresets, loadTable, setPagingAndSorting, updateEntries } from '../../state/permissions/permissions.actions';
import { initialState, PermissionsState } from '../../state/permissions/permissions.reducer';
import { filter} from 'rxjs/operators';

@Component({
  selector: 'cms-web-user',
  templateUrl: './web-user.component.html',
  styleUrls: ['./web-user.component.scss']
})
export class WebUserComponent implements OnInit, OnDestroy{

  columns = esmPresetsTableColumns;
  selectedEntries: TableEntry[] = [];
  newPermission = 'BLOCK';
  data = initialState;
  checkbox: false;

  subscriptions$: Subscription = new Subscription();
  messages$: Observable<Message[]> = this.store.select(getMessages);
  hasEditRights$: Observable<boolean> = this.store.pipe(select(getRights));
  loading$: Observable<boolean> = this.store.pipe(select(getLoading));
  paging$: Observable<PagingFilter> = this.store.select(getPaging);
  sorting$: Observable<SortingFilter> = this.store.select(getSorting);
  data$: Observable<DatatableData<TableEntry[]>> = this.store.select(getTableData);
  organisation$: Observable<boolean> = this.organisationService.onRefresh().pipe(filter(auth => auth));

  constructor(private store: Store<PermissionsState>,
              public organisationService: OrganisationService,
              public readonly errorMessageService: ErrorMessageService) {
  }

  refreshTable = () => this.store.dispatch(loadTable());
  refreshPresets = () => this.store.dispatch(loadPresets());

  ngOnInit(): void {
    this.subscriptions$.add(this.organisation$.subscribe(() => {
      this.refreshTable();
      this.refreshPresets();
    }));

  }

  onLazyLoad({ page, perPage, sortField, sortOrder }): void {

    this.store.dispatch(setPagingAndSorting({
      paging: {
        page,
        entriesPerPage: perPage
      },
      sorting: {
        sortOrder,
        columnName: sortField
      }
    }));
    this.refreshTable();
  }

  execute() {
    this.store.dispatch(updateEntries({ selectedPermissions: this.selectedEntries, newPermission: this.newPermission }));
    this.selectedEntries = [];
  }

  ngOnDestroy(): void {
    this.store.dispatch(cleanState());
    this.subscriptions$.unsubscribe();
  }


  select($event: TableEntry[]) {
    this.selectedEntries = $event;
  }
}
