import { Directive, HostListener } from '@angular/core';
import { Subject } from 'rxjs';

@Directive({
  selector: '[cmsCellEditor]'
})
export class CellEditorDirective {
  click$ = new Subject<MouseEvent>();
  keydown$ = new Subject<KeyboardEvent>();

  @HostListener('click', ['$event']) onClick(event: MouseEvent) {
    this.click$.next(event);
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    this.keydown$.next(event);
  }
}
