<p-dialog *ngIf="display"
          [header]="headerText"
          [(visible)]="display"
          [modal]="true"
          (visibleChange)="onVisibleChange($event)"
          [style]="{'min-width': '750px'}">
  <p-messages [value]="messages" [closable]="true"></p-messages>
  <section>
    <div>
      <p class="text-bold">Please confirm to dispute the invoice. Please insert an obligatory note.</p>
      <p>{{invoiceInfo}}</p>
      <p class="text-bold">Please note that when confirming, you do not agree to the terms and conditions of the invoice issued by the payee.</p>
    </div>
  </section>
  <form [formGroup]="form">
    <label for="note">Text:</label>
    <textarea [ngClass]="{'error': this.errors['note']}" id="note" name="note" formControlName="note" class="form-control" rows="10"></textarea>
  </form>
  <p-footer>
    <button class="btn margin-right-3" type="button" (click)="onCancel()">Cancel</button>
    <button class="btn" type="button" [disabled]="!validInvoice" (click)="onConfirmDispute()">Dispute</button>
  </p-footer>
</p-dialog>
