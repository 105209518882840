import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Message } from 'primeng/api';
import { EsmExternalFormValues } from '../../master-data.model';
import { EsmFormValues, initialFormValues } from '../../master-data.model';

@Component({
  selector: 'cms-master-data-vat-details',
  templateUrl: './master-data-vat-details.component.html',
  styleUrls: ['./master-data-vat-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MasterDataVatDetailsComponent implements OnChanges {
  @Input() formValues: EsmFormValues | EsmExternalFormValues;
  @Input() isFormDisable: boolean;
  @Input() isNewMDRecord: boolean;
  @Input() messages: Message[] = [];
  @Input() editVisible: boolean;
  @Input() organisationLegalName: string = '';
  @Input() includeEic: boolean = false;
  @Output() save = new EventEmitter<EsmFormValues>();
  @Output() cancel = new EventEmitter<EsmFormValues>();
  @Output() delete = new EventEmitter();
  errors: { [key: string]: ValidationErrors } = {};
  form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.form = this.formBuilder.group({
        vatDetails: this.formBuilder.group(initialFormValues.vatDetails),
        companyRegistry: this.formBuilder.group(initialFormValues.companyRegistry),
        addressDetails: this.formBuilder.group(initialFormValues.addressDetails),
        otherAddressDetails: this.formBuilder.group(initialFormValues.otherAddressDetails),
        contactDetails: this.formBuilder.group(initialFormValues.contactDetails),
        headOfficeVatId:initialFormValues.headOfficeVatId,
        enableForceOfAttraction:initialFormValues.enableForceOfAttraction
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.includeEic && this.form &&!this.form.controls['eicCode']) {
      this.form.addControl('eicCode', this.formBuilder.control('', Validators.required));
    }
    if (this.form && changes.organisationLegalName) {
      this.form.patchValue({
        vatDetails: { legalName: this.organisationLegalName ? this.organisationLegalName : '' },
      });
      this.form.updateValueAndValidity();
    }

    this.editVisible ?this.form.enable():this.form.disable();

    if (this.form && changes.formValues) {
      const data = this.formValues || initialFormValues;
      this.form.patchValue({
        vatDetails: { ...data.vatDetails, legalName: data.vatDetails.legalName || this.organisationLegalName },
        companyRegistry: { ...data.companyRegistry },
        addressDetails: { ...data.addressDetails },
        otherAddressDetails: { ...data.otherAddressDetails },
        contactDetails: { ...data.contactDetails },
        headOfficeVatId: data.headOfficeVatId,
        enableForceOfAttraction: data.enableForceOfAttraction,
        headOfficeCompany: data.headOfficeCompany,
        headOfficeAddress1: data.headOfficeAddress1,
        headOfficeAddress2: data.headOfficeAddress2

      });
      if (data['eicCode']) {
        this.form.patchValue({
          eicCode: data['eicCode']
        });
      }
      this.form.updateValueAndValidity();
    }

    if (changes.messages) {
      this.errors = {};
      if (this.messages) {
        this.messages.forEach((obj: Message) => {
          const { key: controlName } = obj;
          this.errors[controlName] = { 'server': true };
        });
      }
    }
  }

  onDelete(): void {
    this.delete.emit();
  }

  onSave(): void {
    const { masterDataId, version } = this.formValues;
    this.save.emit({ ...this.form.value, masterDataId, version });
  }

  onCancel(): void {
    this.cancel.emit({ ...this.formValues });
  }
}
