<div [ngClass]="'panel-box margin-bottom-12 upload-panel'+ full">
  <p>{{title}}</p>
  <div class="flex">
    <input id="input-file" type="text"
           [value]="file?.name || ''" readonly>

    <button class="btn" id="upload_btn"
            (click)="onBrowseFiles()"
            [disabled]="disabled || busy"> Browse File
    </button>

    <p-progressSpinner class="cus_spinner" [ngStyle]="{'visibility':  busy?'visible':'hidden'}"
                       styleClass="custom-spinner" animationDuration=".5s"
                       strokeWidth="7"></p-progressSpinner>
    <button pButton
            label="Upload"
            id="btn btn-submit"
            type="submit"
            (click)="onSubmit()"
            class="btn"
            [disabled]="!file || busy"></button>

    <input #upload id="upload-button" type="file" class="hidden"
           (change)="onSelect($event)" accept="{{accept}}">
  </div>
</div>
