<p-tabView (onChange)="setCategoryTab($event)" [activeIndex]="activeIndex">
  <p-tabPanel header="Table 1">

    <div *ngIf="activeIndex === 0" class="tab-view-body">
      <cms-info ngPreserveWhitespaces>
        <!--@formatter:off (markdown rendering breaks, when the following tags are formatted)-->
        __REMIT Transaction Finder - Table 1 (Standard Trades and Order Data / Executions)__

        **Purpose**

        Find trade and order records submitted for REMIT reporting here.

        * OMPs can investigate trade and order records submitted by them
        * MPs can investigate trade and order records submitted by them or on their behalf

        **Search Modes**

        1. **UTI / Order ID**: finds trade records by their UTI and order records by their Order ID (field accepts up to 50 IDs at once)
        2. **Linked Transaction ID**: finds all trade or execution records having the given "Linked Transaction ID" (field accepts up to 50 IDs at once)
        3. **Filename**: finds all records in a file; either search by your input filename (as on screen "Files IN") or the reported filename (as on screen "Files OUT")
        4. **Filtered**: in absence of IDs you can search by various record properties (parties, times, states, commercials)

        **Filter**

        * **OMP**: The OMP filter can be used to show all trades and order records submitted by an OMP on your behalf
        * **MP**: The MP filter can be used to show all trades and order records submitted by you on the MP's behalf
        * **State**: The State filter can be used to select the record's reporting state
        * **Commodity**: The Commodity filter can be used to select a specific commodity (Electricity, Gas and LNG)
        * **Record Type**: The Record Type filter can be used to filter on Trade and Order
        * **Action Type**: The Action Type filter can be used to by lifecycle events
        * **Quantity**: This filter selects the delivery rate (e.g. 10 MW), not the total notional quantity
        * **Price**: The Price filter can be used to filter the unit price
        * **Sender**: The Sender filter can be used to select all trades and order records submitted on your behalf

        **When searching by times the following rules apply:**

        1. at least one from date must be given
        2. there is a maximum date range
        3. if multiple time ranges are given at least one range must be less than the maximum date range allowed

        **Data Customisation and Export**

        Press "Export" above the result list to download your current search result as an Excel sheet.

        Press "Customise" above the list to change your preferred column selection and ordering. It also influences the Excel export.

        **Detail Information in the List**

        In each row press:
        * the **document icon** to show the contents of the transaction as received by eRR
        * the **magnifier icon** to inspect the reporting audit trail

        **eRR lite - manual lifecycle reporting (master operator privileges required)**

        Market Participants find "M" (modify), "C" (cancel) and "E" (error) buttons on trade records which are in an appropriate state for these lifecycle events.
        This function is for parties without an automated reporting interface (and as a fallback function if the reporting interface does not support lifecycles).
        You can enter lifecycles for OMP-executed trades if the party you selected for display equals the **MP** of the shown trade (and you are master operator).
        You can enter lifecycles for bilateral trades if the party you selected for display equals the sender party of the shown trade (and you are master operator).

        **About the List**

        The column **Originator** shows whether data originated from the OMP or MP.

        The column **Deleted** tells whether a displayed transaction (trade or order) record has been logically deleted or not.
        All records with value "N" (not deleted) represent the currently reported lifecycle of the transaction (new, modify, cancel).
        Records with value "Y" (deleted) are not part of the transaction lifecycle. This group consists of error events ("E") and records logically deleted by error events.

        Guidance on the **State** column:

        | State                | Meaning                                                                                                                 |
        |:---------------------|:------------------------------------------------------------------------------------------------------------------------|
        | RECEIVED             | (temporary) trade/order record received, eRR started processing                                                         |
        | MANUAL_INTERVENTION  | (trades only) enrichment data missing, support team is inspecting the mapping issue and will retry or reject the trade  |
        | NOT_REPORTED         | (final) trade/order has been sent to ElCom only                                                                         |
        | PAIRING              | (trades only) trade waiting in pairing mode, either the MP side waits for a matching OMP submission or vice versa       |
        | REPLACED             | (trades only) trade was superseded by a new version while waiting to pair                                               |
        | PENDING              | trade/order being sent to ACER, response from ACER outstanding                                                          |
        | REJECTED_ERR         | (final) eRR has rejected the trade/order; it is not forwarded to ACER                                                   |
        | REJECTED_ACER        | (final) ACER has received and rejected the trade/order                                                                  |
        | ACCEPTED_ACER        | (final) ACER has received and accepted the trade/order                                                                  |

        **Finding the amount of reports for the ACER Billing**

        To find the total amount of reports for the ACER Billing the following settings to the REMIT Table 1 needs to applied:

        * The filter **State** needs to be applied to: **REJECTED_ACER** and **ACCEPTED_ACER**.
        * All **Action Types** need to be selected
        * **OMP, MP, Commodity** and **Record Type** need to be set to **All**
        * To select the correct date range, the Last Update filter must be set to the appropriate months. For an entire quarter or for the total number of records in the year to date, the records for the individual months must be added together
        * Please be aware that the only documents with a **Filename (Reported)** are taken into account in the calculation. That means only records with an entry in that column are calculated
        * To find specific reports sent by other market participants the filter Sender can be selected
        * Please do not forget the records from Table 2
        <!--@formatter:on-->
      </cms-info>

      <div class="row">
        <div class="col-12 col-md-12 col-lg-3 col-xl-3">
          <cms-remit-transactions-search documentType="REMIT_TABLE_1"></cms-remit-transactions-search>
        </div>
        <div class="col-12 col-lg-8 col-xl-8">
          <cms-remit-transactions-filter documentType="REMIT_TABLE_1"></cms-remit-transactions-filter>
        </div>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Table 2">

    <div *ngIf="activeIndex === 1" class="tab-view-body">
      <cms-info ngPreserveWhitespaces>
        <!--@formatter:off (markdown rendering breaks, when the following tags are formatted)-->
        __REMIT Transaction Finder - Table 2 (Non-Standard Contracts)__

        Use this screen to investigate the reporting status of non-standard contracts (REMIT Table 2).

        Executions of such non-standard contract are found on tab "Table 1" since they are reported in REMIT Table 1 format.

        **Search Modes**

        1. **UTI (Contract ID)**: you can paste a list of max. 50 transaction IDs here at once
        2. **Filename**: finds all records in a file; either search by your input filename (as on screen "Files IN") or the reported filename (as on screen "Files OUT")
        3. **Filtered**: in absence of IDs you can search by various record properties (times, states, commercials)

        **Data Customisation and Export**

        Press "Export" above the result list to download your current search result as an Excel sheet.

        Press "Customise" above the list to change your preferred column selection and ordering. It also influences the Excel export.

        **Detail Information in the List**

        In each row press:
        * the **document icon** to show the contents of the transaction as received by eRR
        * the **search button** "Exec" (Find Executions) for finding all REMIT Table 1 records linked this contract (typically executions)
        * the **magnifier icon** to inspect the reporting audit trail

        **eRR lite - manual lifecycle reporting (master operator privileges required)**

        Market Participants find "M" (modify), "C" (cancel) and "E" (error) buttons on trade records which are in an appropriate state for these lifecycle events.

        This function is for parties without an automated reporting interface (and as a fallback function if the reporting interface does not support lifecycles).

        You can enter lifecycles if the party you selected for display equals the sender party of the shown trade given you have master operator privileges for it.

        **About the List**

        The column **Deleted** tells whether a displayed trade record has been logically deleted or not. All records with value "N" (not deleted) represent the currently reported lifecycle of the trade (new, modify, cancel). Records with value "Y" (deleted) are not part of the trade lifecycle. This group consists of error events ("E") and records logically deleted by error events.

        | Sate                | Meaning                                                                                                  |
        |:--------------------|:---------------------------------------------------------------------------------------------------------|
        | RECEIVED            | (temporary) trade record received, eRR started processing                                                |
        | MANUAL_INTERVENTION | enrichment data missing, support team is inspecting the mapping issue and will retry or reject the trade |
        | PENDING             | trade being sent to ACER, response from ACER outstanding                                                 |
        | REJECTED_ERR        | (final) eRR has rejected the trade; it is not forwarded to ACER                                          |
        | REJECTED_ACER       | (final) ACER has received and rejected the trade                                                         |
        | ACCEPTED_ACER       | (final) ACER has received and accepted the trade                                                         |

        **Finding the amount of reports for the ACER Billing**

        To find the total amount of reports for the ACER Billing the following settings to the REMIT Table 1 needs to applied:

        * The filter **State** needs to be applied to: **REJECTED_ACER** and **ACCEPTED_ACER**.
        * All **Action Types** need to be selected
        * **Commodity** and **Record Type** need to be set to **All**
        * To select the correct date range, the **Last Update** filter must be set to the appropriate months. For an entire quarter or for the total number of records in the year to date, the records for the individual months must be added together
        * Please be aware that the only documents with a **Filename (Reported)** are taken into account in the calculation. That means only records with an entry in that column are calculated
        * To find specific reports sent by other market participants the filter Sender can be selected
        * Please do not forget the records from Table 1
        <!--@formatter:on-->
      </cms-info>

      <div class="row tab-view-body">
        <div class="col-12 col-md-12 col-lg-3 col-xl-3">
          <cms-remit-transactions-search documentType="REMIT_TABLE_2"></cms-remit-transactions-search>
        </div>
        <div class="col-12 col-lg-8 col-xl-8">
          <cms-remit-transactions-filter documentType="REMIT_TABLE_2"></cms-remit-transactions-filter>
        </div>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Table 3">

    <div *ngIf="activeIndex === 2" class="tab-view-body">
      <cms-info ngPreserveWhitespaces>
        <!--@formatter:off (markdown rendering breaks, when the following tags are formatted)-->
        **REMIT Transaction Finder - Table 3 (Electricity Rights)**

        **Purpose**

        Find ElectricityRights documents for REMIT reporting here.

        **Search Modes**

        1. **Unique ID**: you can paste a list of max. 50 transaction IDs here at once
        2. **Filename**: finds all records in a file; either search by your input filename (as on screen "Files IN") or the reported filename (as on screen "Files OUT")
        3. **Filtered**: in absence of IDs you can search by various record properties (states and times)

        **Data Customisation and Export**

        Press "Export" above the result list to download your current search result as an Excel sheet.

        Press "Customise" above the list to change your preferred column selection and ordering. It also influences the Excel export.

        **Detail Information in the List**

        In each row press:

        * the **document icon** to show the contents of the transaction as received by eRR
        * the **magnifier icon** to inspect the reporting audit trail

        **About the List**

        Guidance on the **State** column:

        | State               | Meaning                   |
        | :------------------ |:-- |
        | RECEIVED            | (temporary) trade/order record received, eRR started processing |
        | PENDING             | trade/order being sent to ACER, response from ACER outstanding |
        | REJECTED_ERR        | (final) eRR has rejected the trade/order; it is not forwarded to ACER |
        | REJECTED_ACER       | (final) ACER has received and rejected the trade/order |
        | ACCEPTED_ACER       | (final) ACER has received and accepted the trade/order |
        <!--@formatter:on-->
      </cms-info>

      <div class="row tab-view-body">
        <div class="col-12 col-md-12 col-lg-3 col-xl-3">
          <cms-remit-transactions-search documentType="ELECTRICITY_RIGHTS"></cms-remit-transactions-search>
        </div>
        <div class="col-12 col-lg-8 col-xl-8">
          <cms-remit-transactions-filter documentType="ELECTRICITY_RIGHTS"></cms-remit-transactions-filter>
        </div>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Table 4">

    <div *ngIf="activeIndex === 3" class="tab-view-body">
      <cms-info ngPreserveWhitespaces>
        <!--@formatter:off (markdown rendering breaks, when the following tags are formatted)-->
        **REMIT Transaction Finder - Table 4 (Gas Capacity)**

        **Purpose**

        Find GasCapacity documents for REMIT reporting here.

        **Search Modes**

        1. **Transaction ID**: you can paste a list of max. 50 transaction IDs here at once
        2. **Filename**: finds all records in a file; either search by your input filename (as on screen "Files IN") or the reported filename (as on screen "Files OUT")
        3. **Filtered**: in absence of IDs you can search by various record properties (states and times)

        **Data Customisation and Export**

        Press "Export" above the result list to download your current search result as an Excel sheet.

        Press "Customise" above the list to change your preferred column selection and ordering. It also influences the Excel export.

        **Detail Information in the List**

        In each row press:

        * the **document icon** to show the contents of the transaction as received by eRR
        * the **magnifier icon** to inspect the reporting audit trail

        **About the List**

        Guidance on the **State** column:

        | State               | Meaning                   |
        | :------------------ |:-- |
        | RECEIVED            | (temporary) trade/order record received, eRR started processing |
        | PENDING             | trade/order being sent to ACER, response from ACER outstanding |
        | REJECTED_ERR        | (final) eRR has rejected the trade/order; it is not forwarded to ACER |
        | REJECTED_ACER       | (final) ACER has received and rejected the trade/order |
        | ACCEPTED_ACER       | (final) ACER has received and accepted the trade/order |
        <!--@formatter:on-->
      </cms-info>

      <div class="row tab-view-body">
        <div class="col-12 col-md-12 col-lg-3 col-xl-3">
          <cms-remit-transactions-search documentType="GAS_CAPACITY"></cms-remit-transactions-search>
        </div>
        <div class="col-12 col-lg-8 col-xl-8">
          <cms-remit-transactions-filter documentType="GAS_CAPACITY"></cms-remit-transactions-filter>
        </div>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Fundamentals">

    <div *ngIf="activeIndex === 4" class="tab-view-body">
      <cms-info ngPreserveWhitespaces>
        <!--@formatter:off (markdown rendering breaks, when the following tags are formatted)-->
        **REMIT Transaction Finder - Fundamental Data**

        **Purpose**

        Find fundamental data reports submitted for REMIT reporting here - formats REMITLNG and REMITStorage.

        **Search Modes**

        1. **Reference ID**:  finds reports by their "Reporting Entity Reference ID" field (field accepts up to 50 IDs at once)
        2. **Filename**: find all records in a file; either search by your input filename (as on screen "Files IN") or the reported filename (as on screen "Files OUT")
        3. **Filtered**: in absence of IDs you can search by various record properties (times, states, report format)

        **Data Customisation and Export**

        Press "Export" above the result list to download your current search result as an Excel sheet.

        Press "Customise" above the list to change your preferred column selection and ordering. It also influences the Excel export.

        **Detail Information in the List**

        In each row press the **magnifier icon** to inspect the reporting audit trail and access the documents exchanged with ACER

        **Guidance on the Selected Fields**

        * Fields **Gas Day Start/End (UTC)** contain the unavailability start/end for "Unavailability" reports
        * Field **Report Subtype** is one of "Participant Activity", "Planned Usage" (LNG only), "Facility" and "Unavailability"
        * Field **Storage (Inventory)** only applies to types "Participant Activity" (Gas) and "Facility" (Gas/LNG)

        <!--@formatter:on-->
      </cms-info>

      <div class="row tab-view-body">
        <div class="col-12 col-md-12 col-lg-3 col-xl-3">
          <cms-remit-transactions-search documentType="FUNDAMENTALS"></cms-remit-transactions-search>
        </div>
        <div class="col-12 col-lg-8 col-xl-8">
          <cms-remit-transactions-filter documentType="FUNDAMENTALS"></cms-remit-transactions-filter>
        </div>
      </div>
    </div>
  </p-tabPanel>
</p-tabView>
