import { createAction, union } from '@ngrx/store';
import { DatatableConfig } from '@common/shared/results.model';
import { Message } from 'primeng/api';
import {
  ExternalRrmFilter,
  ResendRequest,
  ExternalRrmResponse,
  ExternalRrmForm,
  RRMPresetValuesResponse, ExternalRrmStatus
} from '../../external-rrm/external-rrm.model';

export const setOrganisationId = createAction('[EXTERNAL RRM] Set Organisation Id', (payload: number) => ({ payload }));
export const setToDefaultAction = createAction('[EXTERNAL RRM] Set to default');
export const setMessagesAction = createAction('[EXTERNAL RRM] Set Messages', (payload: Message[]) => ({ payload }));
export const setFiltersValueAction = createAction('[EXTERNAL RRM] Set Filters Value', (payload: ExternalRrmFilter) => ({ payload }));
export const filterAction = createAction('[EXTERNAL RRM] Filter', (payload: ExternalRrmFilter) => ({ payload }));
export const getFormDataAction = createAction('[EXTERNAL RRM] Get Form Data');
export const getPresetValuesAction = createAction('[EXTERNAL RRM] Get Preset Values');
export const onPresetValuesAction = createAction('[EXTERNAL RRM] On Preset Values', (payload: RRMPresetValuesResponse) => ({ payload }));
export const getStatusAction = createAction('[EXTERNAL RRM] Get Status Values');
export const setStatusAction = createAction('[EXTERNAL RRM] Set Status Action', (payload: ExternalRrmStatus) => ({ payload }));
export const setFormDataAction = createAction('[EXTERNAL RRM] Set Form Data', (payload: ExternalRrmForm) => ({ payload }));
export const filterSuccessAction = createAction('[EXTERNAL RRM] Filter Success Action', (payload: ExternalRrmResponse) => ({ payload }));
export const sortAndPageDataAction = createAction('[EXTERNAL RRM] Sort And Page Data', (payload: DatatableConfig) => ({ payload }));
export const downloadDocumentAction = createAction('[EXTERNAL RRM] Download Document', (payload: number) => ({ payload }));
export const resendAction = createAction('[EXTERNAL RRM] Resend', (payload: ResendRequest) => ({ payload }));
export const loadDefaultDataAction = createAction('[EXTERNAL RRM] Load Default Data');

const actions = union({
  setOrganisationId,
  setToDefaultAction,
  setMessagesAction,
  setFiltersValueAction,
  filterAction,
  filterSuccessAction,
  getFormDataAction,
  setFormDataAction,
  getPresetValuesAction,
  onPresetValuesAction,
  sortAndPageDataAction,
  downloadDocumentAction,
  getStatusAction,
  setStatusAction,
  resendAction,
  loadDefaultDataAction
});

export type ExternalRrmActions = typeof actions;
