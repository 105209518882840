import { createReducer, on } from '@ngrx/store';
import * as NttPreviewAction from './netting-table.actions';
import { NettingTableActions } from './netting-table.actions';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';

import { InvoiceDivEntry } from '../../../netting-preview/netting-table-overview/netting-table-overview.model';
import { Message } from 'primeng/api';

export interface NettingTableState {
  messages: Message[];
  data: DatatableData<InvoiceDivEntry[]>;
  paging: PagingFilter;
  sorting: SortingFilter;
  invoice: InvoiceDivEntry;
  loading: boolean;
  selectable: string;
  discrepancy: boolean;
}

const placeHolderValues: InvoiceDivEntry[] = [1, 2, 3, 4, 5, 6].map(() => ({
  messages: null,
  id: null,
  counterPartyId: null,
  dueDate: null,
  counterParty: null,
  nettingAgreement: null,
  myNetting: null,
  cpNetting: null,
  myVAT: null,
  cpVAT: null,
  discrepancy: null,
  commodities:null,
  openInvoices:null
}));

export const placeHolder: DatatableData<InvoiceDivEntry[]> = {
  values: placeHolderValues
};

export const nettingPreviewTableInitialState: NettingTableState = {
  messages: null,
  data: placeHolder,
  paging: { page: 0, entriesPerPage: 5 },
  sorting: { columnName: 'dueDate', sortOrder: 'DESCENDING' },
  invoice: null,
  loading: false,
  selectable: null,
  discrepancy: false
};

export const nettingPreviewTableReducer = createReducer<NettingTableState, NettingTableActions>(
  nettingPreviewTableInitialState,

  on(NttPreviewAction.loading, (state, { loading }) => ({
    ...state,
    loading: loading,
  })),
  on(NttPreviewAction.setMessages, (state, { messages }) => ({
    ...state,
    messages: messages,
  })),
  on(NttPreviewAction.withDiscrepancies, (state, { selected }) => ({
    ...state,
    discrepancy: selected,
  })),
  on(NttPreviewAction.setPagingAndSorting, (state, { paging, sorting }) => ({
    ...state,
    paging: paging,
    sorting: sorting,
  })),
  on(NttPreviewAction.selectInvoice, (state, { selected }) => ({
    ...state,
    invoice: selected,
  })),
  on(NttPreviewAction.querySuccess, (state, { data }) => ({
    ...state,
    data: data,
    loading: false,
    selectable: 'single',
    invoice: null,
    paging:{...state.paging,page: data.currentPage}
  })),
  on(NttPreviewAction.cleanState, () => ({
    ...nettingPreviewTableInitialState
  })));

