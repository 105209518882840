import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'cms-of-on-cell',
  templateUrl: './ecm-of-on-cell.component.html',
  styleUrls: ['./ecm-of-on-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EcmOfOnCellComponent implements OnInit, OnChanges {
  @Input() data: boolean | null;
  @Input() column: string;
  @Input() selectedRowIndex: number;
  @Input() rowIndex: number;
  @Input() editable: boolean;
  @Output() save = new EventEmitter<{ column: string, newValue: boolean }>();
  currentData: boolean | null;

  ngOnInit(): void {
    this.currentData = this.data;
    this.selectedRowIndex = null;
  }

  onClick(column, newValue): void {
    setTimeout(() => {
      this.currentData = !newValue;
      this.save.emit({
        column: column,
        newValue: this.currentData
      });
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedRowIndex) {
      this.currentData = this.data;
    }

    if (changes.data) {
      this.currentData = this.data;
    }
  }
}
