import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { OrgGroupFilterEntry, ProcessRoleFilterEntry, ProcessRoles, UserFilterEntry, UserRolesFilters } from '../user-roles.model';

@Component({
  selector: 'cms-user-roles-filters',
  templateUrl: './user-roles-filters.component.html',
  styleUrls: ['./user-roles-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserRolesFiltersComponent implements OnInit, OnChanges {
  @Input() filters: UserRolesFilters;
  @Input() orgGroups: OrgGroupFilterEntry[];
  @Input() users: UserFilterEntry[];
  @Input() userVisible = false;
  @Input() groupsVisible = false;
  @Input() processRoles: ProcessRoleFilterEntry[];
  @Input() processRolesVisible = false;
  @Output() filter = new EventEmitter<UserRolesFilters>();
  @Output() export = new EventEmitter<UserRolesFilters>();
  orgGroupsOptions: {label: string, value: any}[] = [];
  usersOptions: {label: string, value: any}[] = [];
  form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    const {
     selectedOrganisationGroupId = null,
      selectedUsername = this.users && this.users.length ? this.users[0] : null,
      selectedProcessRole = ProcessRoles.ECM
    } = this.filters;
    this.form = this.formBuilder.group({
      selectedOrganisationGroupId,
      selectedUsername,
      selectedProcessRole
    });

    this.initGroups();
    this.initUsers();
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (changes.orgGroups) {
      this.initGroups();
    }

    if (changes.users) {
      this.initUsers();
    }

    if (this.form && changes.filters) {
      const {
        selectedOrganisationGroupId = null,
        selectedUsername = this.users && this.users.length ? this.users[0] : null,
        selectedProcessRole = ProcessRoles.ECM
      } = this.filters;
      this.form.patchValue({
        selectedOrganisationGroupId,
        selectedUsername,
        selectedProcessRole
      });
      this.form.updateValueAndValidity();
    }
  }

  onOrgGroupChange(): void {
    this.form.patchValue({
      selectedUsername: null
    });

    this.filter.emit({
      ...this.form.value
    });
  }

  onUsersChange(): void {
    this.filter.emit({
      ...this.form.value
    });
  }

  onProcessRoleChange(): void {
    this.filter.emit({
      ...this.form.value
    });
  }

  onExport(): void {
    this.export.emit({
      ...this.form.value
    });
  }

  private initGroups(): void {
    this.orgGroupsOptions = this.orgGroups && this.orgGroups.length ?
      this.orgGroups.map(item => ({ value: item.organisationGroupId.toString(), label: item.displayName })) : [];
  }

  private initUsers(): void {
    this.usersOptions = this.users && this.users.length ?
      this.users.map(item => ({ value: item.userName, label: item.displayName })) : [];
  }
}
