import { Message } from 'primeng/api';
import { SettingsTableModel } from '../../../settings/tolerance/tolerance.model';
import { createReducer, on } from '@ngrx/store';
import {
  EsmToleranceSettingsAction,
  loadPresetsSuccess,
  pagingAndSorting,
  resetRow,
  triggerCheckboxSuccess,
  updateRow
} from './tolerance-settings.actions';
import { loadTableDatSuccess, setMessages, cleanState, loading } from './tolerance-settings.actions';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';

export interface EsmToleranceSettingsState {
  data: DatatableData<SettingsTableModel[]>;
  paging: PagingFilter;
  sorting:SortingFilter;
  //
  supportToleranceMatching: boolean;
  editable: boolean;
  //
  messages: Message[];
  loading: boolean;
}

export const initialState: EsmToleranceSettingsState = {
  messages: [],
  loading: false,
  sorting:{ columnName: 'currency', sortOrder: 'ASCENDING' },
  data: null,
  supportToleranceMatching: null,
  editable: null,
  paging: {
    entriesPerPage: 25,
    page: 0
  },
};

export const esmToleranceSettingsReducer = createReducer<EsmToleranceSettingsState, EsmToleranceSettingsAction>(
  initialState,

  on(loadTableDatSuccess, (state, { data }) => ({ ...state, data: data })),
  on(loadPresetsSuccess, (state, { supportToleranceMatching, editable }) => ({ ...state, supportToleranceMatching, editable })),
  on(triggerCheckboxSuccess, (state, { checked }) => ({ ...state, toleranceMatchEnabled: checked })),
  on(loading, (state, { load }) => ({ ...state, loading: load })),
  on(pagingAndSorting, (state, { paging, sorting }) => ({ ...state, paging, sorting })),
  on(setMessages, (state, { messages }) => ({ ...state, messages: messages })),
  on(updateRow, (state, { data }) => ({ ...state, data: { ...data, values: updateChangedRow(state, data) } })),
  on(resetRow, (state) => ({ ...state, data: { values: [...state.data.values] } })),
  on(cleanState, () => ({ ...initialState }))
);


 function updateChangedRow (state, data){
  const clone = copy(state);
  const i = clone.findIndex(item=>item.id===data.id);
  clone[i] = {...data };
  return clone;
}

function copy(state): SettingsTableModel[] {
  return state.data.values.map((item) => Object.assign({}, item));
}

