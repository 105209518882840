<router-outlet></router-outlet>
<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>

<div class="panel-box margin-left-50 margin-right-50 margin-top-3" *ngIf="showHtmlTrade">
  <p-messages [(value)]="msgs" [closable]="false"></p-messages>
  <cms-transaction-sheet [data]="transactionSheetResponse$ | async"></cms-transaction-sheet>
</div>

</ng-template>
