import { Message } from 'primeng/api';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { saveBlob, toPayload } from '@common/cms-common.model';
import { DatatableConfig, DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { ApiRequestService } from '@common/api-request.service';
import { ErrorResponse } from '@common/error-response.model';
import {
  DateRange,
  EsmFile,
  EsmFileResponse,
  EsmFileUploadFilters,
  EsmFilterType,
  FilterByDateRequest,
  FilterByNameRequest,
  MaxUploadSizeResponse,
  PresetValuesModelResponse
} from '../../file-upload/file-upload.model';
import { EsmFileUploadState } from './file-upload.reducer';
import * as fromSelectors from './file-upload.selectors';
import * as fromActions from './file-upload.actions';

@Injectable()
export class EsmFileUploadEffects {
  private baseUrl = '/api/esm/fileupload';

  constructor(private actions$: Actions,
              private apiGateway: ApiRequestService,
              private mapper: MessagesMapperService,
              private store$: Store<EsmFileUploadState>) { }

  loadPresetValuesAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadPresetValuesAction.type),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getData))),
      switchMap(([, { values }]: [Action, DatatableData<EsmFile[]>]) =>
        this.apiGateway.get(`${this.baseUrl}/loadPresetValues`)
          .pipe(
            switchMap((response: PresetValuesModelResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [fromActions.setMessagesAction(messages)];
              }
              const { uploadPanelVisible, receivedDateTimeRange } = response.value;
              const filters = {
                filename: '',
                dateTimeRange: receivedDateTimeRange,
                searchBy: EsmFilterType.ByDate
              };
              return [
                fromActions.setMessagesAction([]),
                fromActions.setFilterValuesAction(filters),
                fromActions.setAllowedToUploadAction(uploadPanelVisible),
                uploadPanelVisible && fromActions.getMaxUploadSize(),
                values && fromActions.loadDataAction()
              ].filter(Boolean);
            }),
            catchError(error =>
              of(fromActions.setMessagesAction([this.mapper.createErrorMessage(error.errorMessage || 'Server Error')]))
            ))
      ))
  );

  getMaxUploadSize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getMaxUploadSize.type),
      concatMap(() =>
        this.apiGateway.get(`${this.baseUrl}/maxUploadSizeInBytes`)
          .pipe(
            map((response: MaxUploadSizeResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return fromActions.setMessagesAction(messages);
              }
              return fromActions.setMaxUploadSize(response.response);
            }),
            catchError(error =>
              of(fromActions.setMessagesAction([this.mapper.createErrorMessage(error.errorMessage || 'Server Error')]))
            ))
      ))
  );

  upload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.uploadFileAction.type),
      map(toPayload),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getData))),
      switchMap(([value, { values }]: [File, DatatableData<EsmFile[]>]) => {
        const formData = new FormData();
        formData.append('file', value);
        return this.apiGateway.post(`${this.baseUrl}/uploadCSV`, formData, null)
          .pipe(
            concatMap((response: ErrorResponse | null) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return of(
                  fromActions.setBusy(false),
                  fromActions.setMessagesAction(messages));
              }
              return [
                fromActions.setBusy(false),
                fromActions.setMessagesAction(this.mapper.toSuccessMessages('File uploaded.')),
                values && fromActions.loadDataAction()
              ].filter(Boolean);
            }),
            catchError(error => of(fromActions.setMessagesAction([
              this.mapper.createErrorMessage(error.errorMessage || 'Server Error')
            ])))
          );
      }))
  );

  loadDataAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadDataAction.type),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getEsmFileUploadState))),
      concatMap(([, state]: [Action, EsmFileUploadState]) => this.loadData(state)))
  );

  filterByName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.filterByName.type),
      map(toPayload),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getFilters))),
      switchMap(([filename, filters]: [string, EsmFileUploadFilters]) => {
        return [
          fromActions.setFilterValuesAction({ ...filters, filename, searchBy: EsmFilterType.ByFilename }),
          fromActions.loadDataAction()
        ];
      }))
  );

  filterByDateFilter$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(fromActions.filterByDateAction.type),
        map(toPayload),
        withLatestFrom(this.store$.pipe(select(fromSelectors.getFilters))),
        switchMap(([dateTimeRange, filters]: [DateRange, EsmFileUploadFilters]) => {
          return [
            fromActions.setFilterValuesAction({ ...filters, dateTimeRange, searchBy: EsmFilterType.ByDate }),
            fromActions.loadDataAction()
          ];
        }))
  );

  clearFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.clearFilters.type),
      map(() => fromActions.loadPresetValuesAction()))
  );

  downloadCsvFile$ = createEffect(() =>
    this.actions$
    .pipe(
      ofType(fromActions.downloadDocumentAction.type),
      map(toPayload),
      switchMap((dataStoreId: number) => {
        let params = new HttpParams();
        params = params.set('dataStoreId', dataStoreId.toString());
        return this.apiGateway.getBlob(`${this.baseUrl}/downloadCSV`, null, params)
          .pipe(
            switchMap((response: any) => {
              saveBlob(response);
              return [
                fromActions.setMessagesAction([])
              ];
            }),
            catchError(() => of(fromActions.setMessagesAction([this.mapper.createErrorMessage('Server error')])))
          );
      })
    )
  );

  exportDocument$ = createEffect(() =>
    this.actions$
    .pipe(
      ofType(fromActions.exportDocumentAction.type),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.getFilters)),
        this.store$.pipe(select(fromSelectors.getSorting)),
        this.store$.pipe(select(fromSelectors.getPaging)),
      ),
      switchMap(([, { searchBy, dateTimeRange, filename }, sorting, paging]: [Action, EsmFileUploadFilters, SortingFilter, PagingFilter]) =>
        this.apiGateway.getBlob(
          `${this.baseUrl}/${searchBy}/report`,
          searchBy === EsmFilterType.ByDate ? { dateTimeRange, sorting, paging } : { filename, sorting, paging } )
          .pipe(
            switchMap((response: any) => {
              saveBlob(response);
              return [
                fromActions.setMessagesAction([])
              ];
            }),
            catchError(() => of(fromActions.setMessagesAction([this.mapper.createErrorMessage('Server error')])))
          ))
    ));

  sortAndPage$ = createEffect(() =>
    this.actions$
    .pipe(
      ofType(fromActions.sortAndPageDataAction.type),
      map(toPayload),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getEsmFileUploadState))),
      switchMap(([{ sorting, paging }, esmFileUploadState]: [DatatableConfig, EsmFileUploadState]) =>
        this.loadData({
            ...esmFileUploadState,
            sorting,
            paging
        }))
    ));

  private loadData(state: EsmFileUploadState): Observable<Action> {
    const { sorting, paging, filters } = state;
    const { searchBy } = filters;
    const url = searchBy || EsmFilterType.ByDate;
    const request = this.getRequestModel(sorting, paging, filters);

    return this.apiGateway.post(`${this.baseUrl}/${url}`, request)
      .pipe(
        switchMap((response: EsmFileResponse) => {
          const messages: Message[] = this.mapper.toErrorMessages(response);
          if (messages && messages.length) {
            return [fromActions.setMessagesAction(messages)];
          }
          return [fromActions.loadDataSuccessAction(response)];
        }),
        catchError(error => of(fromActions.setMessagesAction([
          this.mapper.createErrorMessage(error.errorMessage || 'Server Error')
        ])))
      );
  }

  private getRequestModel(sorting: SortingFilter,
    paging: PagingFilter, filters: EsmFileUploadFilters): FilterByDateRequest | FilterByNameRequest {
    const { filename, dateTimeRange, searchBy } = filters;
    switch (searchBy) {
      case EsmFilterType.ByFilename:
        return {
          sorting,
          paging,
          filename
        };
      case EsmFilterType.ByDate:
        return {
          sorting,
          paging,
          dateTimeRange
        };
      default:
        return null;
    }
  }
}
