
  <cms-datatable id="previewTable"
    [tableColumns]="columns"
    [currentPage]="(paging$ | async).page"
    [rows]="(paging$ | async)?.entriesPerPage"
    [sortField]="(sorting$ | async).columnName"
    [sortOrder]="(sorting$ | async).sortOrder"
    [loading]="loading$ | async"
    [data]="(data$ | async)?.values"
    [totalRecords]="(data$ | async)?.count"
    [autoLayout]="false"
    [lazy]="true"
    [rowsPerPageOptions]=" [5, 10, 15, 20]"
    [lazyLoadOnInit]="false"
    [selectionMode]="selectable$| async"
    [scrollable]="false"
    (lazyLoad)="onLazyLoad($event)">

    <ng-template pTemplate="caption">
      <div class="flex justify-content-between align-items-center">
        <h6 class="m-0 ">Netting Overview</h6>
        <div>
          <input #tick class="me-2" type="checkbox" title="filter" (change)="filter(tick.checked)"/>
          <i class="me-2" (click)="tick.click()">show netting with discrepancies only</i>
        </div>
      </div>
    </ng-template>


    <ng-template pTemplate="body" let-row="row" let-column="column" let-index="rowIndex">

      <td *ngIf="column.name === 'dueDate'"  [ngStyle]="setColor(selected?.id===row.id,row,column,index)">
        <div class="inline-block width-full">
          <button [ngStyle]="{'color' : selected===row? 'white':'black','text-align': 'left'}"
                  (click)="switchPreview(row)"
                  title="Due Date"
                  class="link transparent bg-transparent width-full border-0 "
                  type="button">
            {{row.dueDate?row.dueDate:'-'}} </button></div></td>

      <td *ngIf="column.name === 'counterParty'" [ngStyle]="setColor(selected?.id===row.id,row,column,index)">
        <div class="inline-block width-full">
          <button [ngStyle]="{'color' : selected===row? 'white':'black','text-align': 'left'}"
                  (click)="switchPreview(row)"
                  title="Counterparty"
                  class="link transparent bg-transparent width-full border-0 btn-outline-none"
                  type="button">
            {{row.counterParty?row.counterParty:'-'}} </button></div></td>

      <td *ngIf="column.name === 'nettingAgreement'" [ngStyle]="setColor(selected?.id===row.id,row,column,index)">
        <div class="inline-block width-full">
          <button [ngStyle]="{'color' : selected===row? 'white':'black','text-align': 'left'}"
                  (click)="switchPreview(row)"
                  title="Netting Agreement"
                  class="link transparent bg-transparent width-full border-0"
                  type="button">
            {{row.nettingAgreement? row.nettingAgreement   :'-'}} </button></div></td>

      <td *ngIf="column.name === 'myNetting'" [ngStyle]="setColor(selected?.id===row.id,row,column,index)">
        <div class="inline-block width-full">
          <button [ngStyle]="{'color' : selected===row? 'white':'black','text-align': 'right'}"
                  (click)="switchPreview(row)"
                  title="My Netting"
                  class="link transparent bg-transparent width-full border-0"
                  type="button">
            {{row.myNetting?(row.myNetting | number:'1.2-15':'en-US'):'-'}} </button></div></td>

      <td *ngIf="column.name === 'cpNetting'" [ngStyle]="setColor(selected?.id===row.id,row,column,index)">
        <div class="inline-block width-full">
          <button [ngStyle]="{'color' : selected===row? 'white':'black','text-align': 'right'}"
                  (click)="switchPreview(row)"
                  title="Counterparty Netting"
                  class="link transparent bg-transparent width-full border-0"
                  type="button">
            {{row.cpNetting ? (row.cpNetting | number:'1.2-15':'en-US') : '-'}} </button>
        </div>
      </td>

    </ng-template>

    <ng-template pTemplate="customEmptyMessage">
      <tr>
        <td colspan="100" *ngIf="!(loading$ | async)">No records found</td>
      </tr>
    </ng-template>

  </cms-datatable>
