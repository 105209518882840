<ng-container *ngIf="rowIndex !== selectedRowIndex || !editable">
  <span *ngIf="currentData === null">N/A</span>
  <i class="fa fa-check" *ngIf="currentData === true"></i>
  <i class="fa fa-times" *ngIf="currentData === false"></i>
</ng-container>
<ng-container *ngIf="rowIndex === selectedRowIndex && editable">
  <button *ngIf="currentData === true"
          pButton
          icon="fa fa-check"
          title="On"
          class="btn btn-on"
          (click)="onClick(column, currentData)">
    ON
  </button>
  <button *ngIf="currentData === false"
          pButton
          icon="fa fa-times"
          title="Off"
          class="btn btn-off"
          (click)="onClick(column, currentData)">
    OFF
  </button>
  <span *ngIf="currentData === null">N/A</span>
</ng-container>
