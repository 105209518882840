import { createAction, union } from '@ngrx/store';
import { Message } from 'primeng/api';

import {
  BillingRunQuartersRequest, BillingRunQuartersResponse,
  BillingTxCountRequest,
  BillingTxGenerationRequest, BillingTxGenerationResponse, BillingTxListResponse, BillingTxReportDownloadRequest, OrganisationGroupItem
} from '../../administration-tab-panel/billing-tx-report/billing-tx-report.models';

export const prefix = '[ACER BILLING TX REPORT]';
export const loadOrgGroupData =
  createAction(`${prefix} Load Organisation Group Data`
  );

export const loadOrgGroupDataSuccess =
  createAction(`${prefix} Load Organisation Group Data Success`,
    (payload: OrganisationGroupItem[]) => ({ payload })
  );

export const setMessagesAction =
  createAction(`${prefix} Set Messages`,
    (payload: Message[]) => ({ payload })
  );

export const loadTxCount =
  createAction(`${prefix} Load Transaction Counts`,
    (payload: BillingTxCountRequest) => ({ payload }));

export const loadTxCountSuccess =
  createAction(`${prefix} Load Transaction Counts Success`,
    (payload: {
      overLimit: boolean;
      noResults: boolean;
      submitPayload: BillingTxGenerationRequest
    }) => ({ payload }));

export const loadBillingRunQuarters =
  createAction(`${prefix} Load Billing Run Quarters`,
    (payload: BillingRunQuartersRequest) => ({ payload }));

export const loadBillingRunQuartersSuccess =
  createAction(`${prefix} Load Billing Run Quarters Success`,
    (payload: BillingRunQuartersResponse) => ({ payload }));

export const hideCountWarning =
  createAction(`${prefix} Hide Transaction Count Warning`);

export const submitReportGeneration =
  createAction(`${prefix} Generate Report`,
    (payload: BillingTxGenerationRequest) => ({ payload }));

export const submitReportGenerationSuccess =
  createAction(`${prefix} Generate Report Success`,
    (payload: BillingTxGenerationResponse) => ({ payload }));

export const listBillingReports =
  createAction(`${prefix} List Billing Reports`);

export const listBillingReportsSuccess =
  createAction(`${prefix} List Billing Reports Success`,
    (payload: BillingTxListResponse) => ({ payload }))

export const downloadBillingReport =
  createAction(`${prefix} Download Billing Report`,
    (payload: BillingTxReportDownloadRequest) => ({ payload }));

export const downloadBillingReportSuccess =
  createAction(`${prefix} Download Billing Report Success`,
    (payload: any) => ({ payload }));

const actions = union({
  setMessagesAction,
  hideCountWarning,
  loadOrgGroupData,
  loadOrgGroupDataSuccess,
  loadTxCount,
  loadTxCountSuccess,
  submitReportGeneration,
  submitReportGenerationSuccess,
  listBillingReports,
  listBillingReportsSuccess,
  downloadBillingReport,
  downloadBillingReportSuccess,
  loadBillingRunQuarters,
  loadBillingRunQuartersSuccess
});

export type BillingTxReportActions = typeof actions;
