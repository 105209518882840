<h1>Organisation Contact Directory</h1>
<div class="panel-box">
  <p-messages [value]="messages$ | async" [closable]="true"></p-messages>

    <cms-datatable *ngIf="(data$ | async) as data"
                   [currentPage]="(paging$ | async).page"
                   [rows]="(paging$ | async).entriesPerPage"
                   [tableColumns]="dataTableColumns"
                   [sortField]="(sorting$ | async).columnName"
                   [sortOrder]="(sorting$ | async).sortOrder"
                   [loading]="loading$ | async"
                   [data]="data.values"
                   [totalRecords]="data.count"
                   (lazyLoad)="onLazyLoad($event)"
                   [selected]="selected$ | async"
                   [scrollable]="false"
                   [autoLayout]="true"
                   (selectedChange)="onSelectedChange($event)"
                   selectionMode="single">
      <ng-template pTemplate="caption">
        <cms-contacts-filters [filters]="filters$ | async"
                              [orgGroups]="orgGroups$ | async"
                              (filter)="onFilter($event)">
        </cms-contacts-filters>
      </ng-template>
      <ng-template pTemplate="body" let-row="row" let-column="column">
        <td *ngIf="column.type === 'button'" [ngStyle]="column.style">
          <div class="btn-wrapper">
            <div class="btn btn-icon fa fa-user" [title]="column.title"></div>
          </div>
        </td>
      </ng-template>
    </cms-datatable>
    <div *ngIf="(selected$ | async) as selected" class="margin-top-27">
      <h1>People at {{selected.displayName}} ({{selected.legalName}})</h1>
    </div>
    <cms-contacts-user-contact *ngIf="selected$ | async"
                               [orgContact]="selected$ | async">
    </cms-contacts-user-contact>

</div>
