import { NgModule, SecurityContext } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CmsCommonModule } from '@common/cms-common.module';
import { MarkdownModule } from 'ngx-markdown';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MiFidComponent } from './mifid/mifid.component';
import { ErrMifidEffects } from './state/mifid/mifid.effects';
import { getReducers, reducerToken } from './state/reducers';
import { MifidDetailsComponent } from './mifid/mifid-details/mifid-details.component';
import { ErrMifidDetailsEffects } from './state/mifid-details/mifid-details.effects';
import { MifidDetailsViewComponent } from './mifid/mifid-details-view/mifid-details-view.component';
import { MifidConfirmDialogComponent } from './mifid/mifid-confirm-dialog/mifid-confirm-dialog.component';
import { RetryQueueComponent } from './retry-queue/retry-queue.component';
import { RetryQueueFilterComponent } from './retry-queue/retry-queue-filter/retry-queue-filter.component';
import { ErrRetryQueueEffects } from './state/retry-queue/retry-queue.effects';
import { ExternalErrEffects } from './state/external-rrm/external-rrm.effects';
import { TableModule } from 'primeng/table';
import { RetryQueueDialogComponent } from './retry-queue/retry-queue-dialog/retry-queue-dialog.component';
import { ExternalRrmComponent } from './external-rrm/external-rrm.component';
import { ExternalRrmFilterComponent } from './external-rrm/external-rrm-filter/external-rrm-filter.component';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ButtonModule } from 'primeng/button';
import { MessagesModule } from 'primeng/messages';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { InputMaskModule } from 'primeng/inputmask';
import { TerminationComponent } from './remit/termination/termination.component';

import { ListDialogComponent } from './remit/termination/list-dialog/list-dialog.component';
import { TerminationDialogComponent } from './remit/termination/termination-dialog/termination-dialog.component';
import { ErrTerminationEffects } from './state/remit/remit.effects';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ListboxModule } from 'primeng/listbox';
import { SliderModule } from 'primeng/slider';
import { FieldsetModule } from 'primeng/fieldset';
import { TooltipModule } from 'primeng/tooltip';
import { SpinnerModule } from 'primeng/spinner';
import { MessageModule } from 'primeng/message';
import { StandingInstructionsModule } from './standing-instructions/standing-instructions.module';

@NgModule({
    imports: [
        CommonModule,
        CmsCommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        TableModule,
        ButtonModule,
        DropdownModule,
        CalendarModule,
        MessagesModule,
        MessageModule,
        InputMaskModule,
        MarkdownModule.forRoot(),
        StoreModule.forFeature('err', reducerToken),
        EffectsModule.forFeature([
            ErrMifidEffects,
            ErrMifidDetailsEffects,
            ErrRetryQueueEffects,
            ErrTerminationEffects,
            ExternalErrEffects
        ]),
        DialogModule,
        KeyFilterModule,
        TableModule,
        CheckboxModule,
        ProgressSpinnerModule,
        DynamicDialogModule,
        ListboxModule,
        FieldsetModule,
        SliderModule,
        SpinnerModule,
        TooltipModule,
        StandingInstructionsModule
    ],
    declarations: [
        MiFidComponent,
        MifidDetailsComponent,
        MifidDetailsViewComponent,
        MifidConfirmDialogComponent,
        RetryQueueComponent,
        RetryQueueFilterComponent,
        RetryQueueDialogComponent,
        ExternalRrmComponent,
        ExternalRrmFilterComponent,
        TerminationComponent,
        ListDialogComponent,
        TerminationDialogComponent,
    ],
    providers: [
        {
            provide: reducerToken,
            useFactory: getReducers
        }
    ]
})
export class ErrModule { }
