<div class="note note__header">
  <span>
    <i class="fa fa-address-card"></i>
    <span (click)="openNoteInfo=!openNoteInfo" class="note__header-title">{{note.reporter}}</span><span> <span class="note__org-info"> ({{note.orgInfo}})</span> - {{note.createdAtTimestamp | date: 'dd/MM/yyyy HH:mm'}} - v. {{note.docVersion}}</span>
  </span>
  <div *ngIf="noteInfoEnabled" class="note__info">
    <div class="note__info-icon">
      <i class="fa fa-info-circle"></i>
    </div>
    <div class="note__info-fields">
      <p *ngIf="note.jobTitle"><span>Role: </span>{{note.jobTitle}}</p>
      <p *ngIf="note.email"><span>Email: </span>{{note.email}}</p>
      <p *ngIf="note.phone"><span>Phone: </span>{{note.phone}}</p>
      <p *ngIf="note.mobile"><span>Mobile: </span>{{note.mobile}}</p>
      <p *ngIf="note.contactOther"><span>Other: </span>{{note.contactOther}}</p>
    </div>
  </div>
</div>
<div class="note">
  <p>{{note.text}}</p>
</div>
