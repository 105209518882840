import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsCommonModule } from '@common/cms-common.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RemitTradeEntryTable2ReferencesComponent } from './remit-trade-entry-table2-references.component';
import { RemitTradeEntryTable2ProductDetailsComponent } from './remit-trade-entry-table2-product-details.component';
import { RemitTradeEntryTable2DeliveryComponent } from './remit-trade-entry-table2-delivery.component';
import { RemitTradeEntryTable2ReportingCounterpartyComponent } from './remit-trade-entry-table2-reporting-counterparty.component';
import { RemitTradeEntryTable2PriceComponent } from './remit-trade-entry-table2-price.component';
import { RemitTradeEntryTable2VolumeOptionalityIntervalsComponent } from './remit-trade-entry-table2-volume-optionality-intervals.component';
import { RemitTradeEntryTable2OtherCounterpartyComponent } from './remit-trade-entry-table2-other-counterparty.component';
import { RemitTradeEntryTable2VolumeComponent } from './remit-trade-entry-table2-volume.component';
import { RemitTradeEntryTable2FixingIndexComponent } from './remit-trade-entry-table2-fixing-index.component';
import { RemitTradeEntryTable2OptionDetailsComponent } from './remit-trade-entry-table2-option-details.component';
import { DropdownModule } from 'primeng/dropdown';
import { TabViewModule } from 'primeng/tabview';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { CalendarModule } from 'primeng/calendar';
import { InputMaskModule } from 'primeng/inputmask';
import { CheckboxModule } from 'primeng/checkbox';

@NgModule({
  imports: [
    CommonModule,
    CmsCommonModule,
    FormsModule,
    ReactiveFormsModule,
    TabViewModule,
    CalendarModule,
    DropdownModule,
    InputMaskModule,
    CheckboxModule,
    MessageModule,
    MessagesModule,
    RouterModule,
  ],
  declarations: [
    RemitTradeEntryTable2ReferencesComponent,
    RemitTradeEntryTable2ProductDetailsComponent,
    RemitTradeEntryTable2DeliveryComponent,
    RemitTradeEntryTable2ReportingCounterpartyComponent,
    RemitTradeEntryTable2VolumeComponent,
    RemitTradeEntryTable2OtherCounterpartyComponent,
    RemitTradeEntryTable2VolumeOptionalityIntervalsComponent,
    RemitTradeEntryTable2PriceComponent,
    RemitTradeEntryTable2OptionDetailsComponent,
    RemitTradeEntryTable2FixingIndexComponent
  ],
  exports:[
    RemitTradeEntryTable2ReferencesComponent,
    RemitTradeEntryTable2ProductDetailsComponent,
    RemitTradeEntryTable2DeliveryComponent,
    RemitTradeEntryTable2ReportingCounterpartyComponent,
    RemitTradeEntryTable2VolumeComponent,
    RemitTradeEntryTable2OtherCounterpartyComponent,
    RemitTradeEntryTable2VolumeOptionalityIntervalsComponent,
    RemitTradeEntryTable2PriceComponent,
    RemitTradeEntryTable2OptionDetailsComponent,
    RemitTradeEntryTable2FixingIndexComponent
  ]
})
export class RemitTradeEntryTable2FormModule {
}
