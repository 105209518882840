<p-tabView (onChange)="navigateToBucket($event)" [activeIndex]="activeIndex" [styleClass]="'margin-bottom-15'">
  <p-tabPanel header="In Progress">
    <button type="button" class="btn margin-right-12 dashboard" (click)="backToDashboard()"><i class="fa fa-arrow-left" aria-hidden="true"></i> Dashboard</button>
    <div class="btn-group" role="group" aria-label="In Progress">
      <button type="button" class="btn btn-secondary" (click)="changeState('PENDING')" [ngClass]="{'selected' : state === 'PENDING'}">Pending</button>
      <button type="button" class="btn btn-secondary" (click)="changeState('ALLEGED')" [ngClass]="{'selected' : state === 'ALLEGED'}">Alleged</button>
      <button type="button" class="btn btn-secondary" (click)="changeState('RETRY')" [ngClass]="{'selected' : state === 'RETRY'}">Retry</button>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Issue">
    <button type="button" class="btn margin-right-12 dashboard" (click)="backToDashboard()"><i class="fa fa-arrow-left" aria-hidden="true"></i> Dashboard</button>
    <div class="btn-group" role="group" aria-label="Issue">
      <button type="button" class="btn btn-secondary" (click)="changeState('REJECTED_ERR')" [ngClass]="{'selected' : state === 'REJECTED_ERR'}">Rejected - eRR</button>
      <button type="button" class="btn btn-secondary" (click)="changeState('REJECTED_TR')" [ngClass]="{'selected' : state === 'REJECTED_TR'}">Rejected - TR</button>
      <button type="button" class="btn btn-secondary" (click)="changeState('COMMUNICATION_PROBLEM_TR')" [ngClass]="{'selected' : state === 'COMMUNICATION_PROBLEM_TR'}">Communication prob - TR</button>
      <button type="button" class="btn btn-secondary" (click)="changeState('PROCESSING_ERR')" [ngClass]="{'selected' : state === 'PROCESSING_ERR'}">Processing - eRR</button>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Complete">
    <button type="button" class="btn margin-right-12 dashboard" (click)="backToDashboard()"><i class="fa fa-arrow-left" aria-hidden="true"></i> Dashboard</button>
    <div class="btn-group" role="group" aria-label="Complete">
      <button type="button" class="btn btn-secondary" (click)="changeState('REPORTED_TR')" [ngClass]="{'selected' : state === 'REPORTED_TR'}">Reported to TR</button>
      <button type="button" class="btn btn-secondary" (click)="changeState('NOT_REPORTABLE')" [ngClass]="{'selected' : state === 'NOT_REPORTABLE'}">Not reportable</button>
    </div>
  </p-tabPanel>
</p-tabView>
