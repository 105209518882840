<section class="panel-box cms-invoice-dashboard-filters-container">
  <form class="form align-items-center" [formGroup]="form">
    <div class="row">
      <div class="col-12 col-md-4">
        <div>
          <label class="text-bold">Counterparty</label>
          <p-multiSelect selectedItemsLabel="All ({0})"
                         [maxSelectedLabels]="counterPartiesOptions.length - 1"
                         id="esm-invoice-filter-multiselect-cp"
                         placeholder="None"
                         [filter]="true"
                         [options]="counterPartiesOptions"
                         formControlName="counterParties">
          </p-multiSelect>
        </div>
        <div>
          <label class="text-bold">Delivery Point</label>
          <p-multiSelect selectedItemsLabel="All ({0})"
                         [maxSelectedLabels]="deliveryPointsOptions.length - 1"
                         id="esm-invoice-filter-multiselect-dp"
                         placeholder="None"
                         [filter]="false"
                         [options]="deliveryPointsOptions"
                         formControlName="deliveryPoints">
          </p-multiSelect>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div>
          <label class="text-bold">Commodity</label>
          <p-multiSelect selectedItemsLabel="All ({0})"
                         [maxSelectedLabels]="commoditiesOptions.length - 1"
                         id="esm-invoice-filter-multiselect-cmdt"
                         placeholder="None"
                         [filter]="false"
                         [options]="commoditiesOptions"
                         formControlName="commodities">
          </p-multiSelect>
        </div>
        <div>
          <label class="text-bold">Invoice Type</label>
          <p-multiSelect selectedItemsLabel="All ({0})"
                         [maxSelectedLabels]="invoiceTypesOptions.length - 1"
                         id="esm-invoice-filter-multiselect-it"
                         placeholder="None"
                         [filter]="false"
                         [options]="invoiceTypesOptions"
                         formControlName="invoiceTypes">
          </p-multiSelect>
        </div>
      </div>
      <div class="col-12 col-md-12 col-xl-4 px-0">
        <div class="row gutter-0 calendar">
          <label class="col text-bold">Payment Date Range</label>
          <div class="col-12 col-md-5">
            <p-calendar id="paymentDateStart"
                        formControlName="paymentDateStart"
                        placeholder="From Date"
                        showIcon="true"
                        monthNavigator="true"
                        yearNavigator="true"
                        dateFormat="dd/mm/yy"
                        [ngClass]="{'error': errors['paymentDateStart']}"
                        cmsDateRestriction>
            </p-calendar>
          </div>
          <div class="col-12 col-xs-12 col-md-5 ps-2">
            <p-calendar id="paymentDateEnd"
                        formControlName="paymentDateEnd"
                        placeholder="To Date"
                        showIcon="true"
                        monthNavigator="true"
                        yearNavigator="true"
                        dateFormat="dd/mm/yy"
                        [ngClass]="{'error': errors['paymentDateEnd']}"
                        cmsDateRestriction>
            </p-calendar>
          </div>
          <cms-additional-date-range
            class="col-auto ps-2"
            [form]="form"
            [startField]="'paymentDateStart'"
            [endField]="'paymentDateEnd'"
            [dateRanges]="dateRanges">
          </cms-additional-date-range>
        </div>
        <div class="row gutter-0 calendar">
          <div class="col-12 col-xs-12 col-md-5">
            <label class="text-bold">Invoice Period Start</label>
            <p-calendar id="invoiceStartDate"
                        formControlName="invoiceStartDate"
                        showIcon="true"
                        monthNavigator="true"
                        yearNavigator="true"
                        dateFormat="dd/mm/yy"
                        placeholder="From Date"
                        [ngClass]="{'error': errors['invoiceStartDate']}"
                        cmsDateRestriction>
            </p-calendar>
          </div>
          <div class="col-12 col-xs-12 col-md-5 ps-2">
            <label class="text-bold  ">Invoice Period End</label>
            <p-calendar id="invoiceEndDate"
                        formControlName="invoiceEndDate"
                        showIcon="true"
                        monthNavigator="true"
                        placeholder="To Date"
                        yearNavigator="true"
                        dateFormat="dd/mm/yy"
                        [ngClass]="{'error': errors['invoiceEndDate']}"
                        cmsDateRestriction>
            </p-calendar>
          </div>
          <div class="col-auto ps-2">
            <label class="text-bold  ">&nbsp;</label>
            <cms-additional-date-range
              [form]="form"
              [startField]="'invoiceStartDate'"
              [endField]="'invoiceEndDate'"
              [dateRanges]="dateRanges">
            </cms-additional-date-range>
          </div>
        </div>
        <div class="row gutter-0">
          <label class="col text-bold">Delivery Date Range</label>
          <div class="col-12 col-xs-12 col-md-5">
            <p-calendar placeholder="From Date"
                        id="deliveryDateRangeStart"
                        formControlName="deliveryDateRangeStart"
                        showIcon="true"
                        monthNavigator="true"
                        yearNavigator="true"
                        dateFormat="dd/mm/yy"
                        [ngClass]="{'error': errors['deliveryDateRangeStart']}"
                        cmsDateRestriction>
            </p-calendar>
          </div>
          <div class="col-12 col-xs-12 col-md-5 date ps-2">
            <p-calendar placeholder="To Date"
                        id="deliveryDateRangeEnd"
                        formControlName="deliveryDateRangeEnd"
                        showIcon="true"
                        monthNavigator="true"
                        yearNavigator="true"
                        dateFormat="dd/mm/yy"
                        [ngClass]="{'error': errors['deliveryDateRangeEnd']}"
                        cmsDateRestriction>
            </p-calendar>
          </div>
          <cms-additional-date-range
            class="col-auto ps-2"
            [form]="form"
            [startField]="'deliveryDateRangeStart'"
            [endField]="'deliveryDateRangeEnd'"
            [dateRanges]="dateRanges">
          </cms-additional-date-range>
        </div>
      </div>
    </div>
    <div class="row gutter-0 align-items-baseline margin-top-12 btn__container justify-content-end">
      <div class="col-12 col-xs-6 col-md-6 btn__container text-end">
        <button class="btn margin-top-3 margin-bottom-3 margin-left-12" type="submit" (click)="onFilter()" [disabled]="form.invalid">Search</button>
        <button class="btn margin-top-3 margin-bottom-3 margin-left-12 me-0" type="button" (click)="onReset()">Reset</button>
      </div>
    </div>
    <div *ngIf="errorMessage" class="col-12 col-md-12 col-xl-4 error-note" style="white-space: pre-line">
      {{errorMessage}}
    </div>
  </form>
</section>

