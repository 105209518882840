import {PagingFilter, SortingFilter} from '@common/shared/results.model';

export interface InvoiceDivEntry {
  id: number;
  dueDate: string;
  counterPartyId: number;
  counterParty: string;
  nettingAgreement: string;
  myNetting: number;
  cpNetting: number;
  myVAT: string;
  cpVAT: string;
  commodities: string;
}


const defaultStyles = {
  'text-overflow': 'ellipsis',
  'overflow': 'hidden',
  'white-space': 'nowrap',
  'height':'22px',
  'width':'11rem',
  'background-color':'transparent'
};


export const overviewTableColumns = [
  { name: 'counterParty', type: 'button', title: 'Counterparty', style: { ...defaultStyles,'width':'16rem'}, sortable: true, visible: true },
  { name: 'nettingAgreement', type: 'button', title: 'Netting Agreement', style: { ...defaultStyles,'width':'20rem'}, sortable: true, visible: true },
  { name: 'dueDate', type: 'button', title: 'Due Date', style: { ...defaultStyles,  'width':'6rem'}, sortable: true, visible: true },
  { name: 'myNetting', type: 'button', title: 'My Netting', style: { ...defaultStyles}, sortable: true, visible: true },
  { name: 'cpNetting', type: 'button', title: 'Counterparty Netting', style: { ...defaultStyles}, sortable: true, visible: true },
  { name: 'myVAT', type: null, title: null, style: null, sortable: false, visible: false  },
  { name: 'cpVAT', type: null, title: null, style: null, sortable: false, visible: false },
  { name: 'id', type: null, title: null, style: null, sortable: false, visible: false },
];

