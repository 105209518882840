import { Component, OnInit } from '@angular/core';
import { DatatableState } from '@common/datatable/datatable.model';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { Store } from '@ngrx/store';
import { Message } from 'primeng/api';
import { EsmExternalMasterDataModel, EsmExternalMasterDataPresetValues, esmExternalMasterDataTableColumns } from '../external-master-data.model';
import * as fromMDSelectors from '../../../state/master-data/external-master-data/external-master-data.selectors';
import * as fromActions from '../../../state/master-data/external-master-data/external-master-data.actions';
import { Observable } from 'rxjs';
import { DialogState } from '@common/state/reducers';
import { ESM_MD_DIALOGS } from 'app/esm/state/master-data/master-data.reducer';

@Component({
  selector: 'cms-external-master-data-datatable',
  templateUrl: './external-master-data-datatable.component.html',
  styleUrls: ['./external-master-data-datatable.component.scss']
})
export class ExternalMasterDataDatatableComponent implements OnInit {
  columns = esmExternalMasterDataTableColumns;
  uploadFileType: string = '.csv';
  uploadStatus$: Observable<boolean> = this.store.select(fromMDSelectors.getUploadStatus);
  tableStatus$: Observable<boolean> = this.store.select(fromMDSelectors.getTableStatus);
  data$: Observable<DatatableData<EsmExternalMasterDataModel[]>> = this.store.select(fromMDSelectors.getTableData);
  paging$: Observable<PagingFilter> = this.store.select(fromMDSelectors.getTablePaging);
  sorting$: Observable<SortingFilter> = this.store.select(fromMDSelectors.getTableSorting);
  presetValues$: Observable<EsmExternalMasterDataPresetValues> = this.store.select(fromMDSelectors.getPresetValues);
  dialog$: Observable<DialogState<ESM_MD_DIALOGS>> = this.store.select(fromMDSelectors.getDialog);
  selectedRecord: EsmExternalMasterDataModel;

  constructor(private store: Store) { }

  ngOnInit() {
  }

  onLazyLoad({page, perPage, sortField, sortOrder}: DatatableState) {
    this.store.dispatch(fromActions.pagingAndSorting({
      paging: { page, entriesPerPage: perPage},
      sorting: { sortOrder, columnName: sortField}
    }));
  }

  onError(messages: Message[]) {
    this.store.dispatch(fromActions.setMessages(messages));
  }

  onUpload(file: File) {
    this.store.dispatch(fromActions.uploadCsv(file));
  }

  onDownload() {
    this.store.dispatch(fromActions.downloadCsv());
  }

  onNew() {
    this.store.dispatch(fromActions.createNewRecord());
  }

  onFilter($event: string) {
    this.store.dispatch(fromActions.setSearchToken($event));
    this.store.dispatch(fromActions.loadTableData());
  }

  onDelete(masterDataRow: EsmExternalMasterDataModel) {
    this.selectedRecord = masterDataRow;
    this.store.dispatch(fromActions.openDialogAction({
      name: 'DELETE'
    }));
  }

  onConfirmDelete() {
    this.store.dispatch(fromActions.deleteMDRecord({
      masterDataId: this.selectedRecord.masterDataId,
      version: this.selectedRecord.version
    }));
    this.selectedRecord = null;
  }

  onCancelDelete() {
    this.store.dispatch(fromActions.closeDialogAction());
    this.selectedRecord = null;
  }

  onEdit(masterDataRow: EsmExternalMasterDataModel) {
    this.store.dispatch(fromActions.loadFormValues({
      masterDataId: masterDataRow.masterDataId
    }));
  }



}
