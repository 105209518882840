import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { Message } from 'primeng/api';
import { AddNote, Note, NotesCellViewEvent } from '@common/deals/deals.model';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'cms-view-confirmation-notes-dialog',
  templateUrl: './view-confirmation-notes-dialog.component.html',
  styleUrls: ['./view-confirmation-notes-dialog.component.scss']
})
export class ViewConfirmationNotesDialogComponent implements OnInit, OnChanges {
  @Input() data: NotesCellViewEvent;
  @Input() display = false;
  @Input() headerText = 'View confirmation notes';
  @Input() messages: Message[] = [];
  @Output() displayChange = new EventEmitter<boolean>();
  @Output() cancel = new EventEmitter<Message[]>();
  @Output() save = new EventEmitter<AddNote>();
  form: UntypedFormGroup;
  errors: {[key: string]: ValidationErrors} = {};

  constructor(private formBuilder: UntypedFormBuilder) { }

  onCancel(): void {
    this.hide();
  }

  onVisibleChange(visible: boolean): void {
    if (!visible) {
      this.onCancel();
    }
  }

  onSave(): void {
    const { text } = this.form.value;
    const { confirmationId } = this.data;
    this.save.emit({confirmationIds: [confirmationId], text });
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      text: ''
    });
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (changes.messages) {
      this.errors = {};
      if (this.messages) {
        this.messages.forEach((obj: Message) => {
          const { key: controlName } = obj;
          this.errors[controlName] = { 'server': true };
        });
      }
    }
  }

  private hide(): void {
    this.messages = [];
    this.display = false;
    this.displayChange.emit(this.display);
    this.form.reset();
    this.form.updateValueAndValidity();
    this.cancel.emit();
  }
}
