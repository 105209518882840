import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutesModule } from './app.routes';
import { AuthorisationGuard } from './auth/authorisation.guard';
import { PasswordGuard } from './login/password/password.guard';
import { LoginGuard } from './login/login.guard';
import { ForcePasswordGuard } from './login/password/force-password.guard';
import { SystemService } from './system/system.service';
import { UserService } from '@common/user.service';
import { AuthenticationService } from './auth/authentication.service';
import { CookieService } from '@common/cookie.service';
import { PasswordService } from './login/password/password.service';
import { SmtModule } from './err/smt/smt.module';
import { AppComponent } from './app.component';
import { EmptyComponent } from './empty.component';
import { PageComponent } from './page.component';
import { NavbarComponent } from '@common/nav/navbar.component';
import { MenuComponent } from '@common/menu/menu.component';
import { LoginModule } from './login/login.module';
import { HelpModule } from './help/help.module';
import { PreferencesModule } from './preferences/preferences.module';
import { RoboModule } from './err/robo/robo.module';
import { OrganisationService } from '@common/organisation.service';
import { CpmlModule } from './err/cpml/cpml.module';
import { ReportModule } from './err/remit/report/report.module';
import { ErrRemitSettingsModule } from './err/remit/settings/err-remit-settings.module';
import { RemitDashboardModule } from './err/remit/dashboard/remit-dashboard.module';
import { RemitTransactionsModule } from './err/remit/transactions/remit-transactions.module';
import { RemitTransactionsSearchModule } from './err/remit/transactions/search/remit-transactions-search.module';
import { DatatableCustomizeSettingsModule } from './settings/datatable/customize/datatable-customize-settings.module';
import { AudittrailModule } from '@common/audittrail/audittrail.module';
import { UrlReferenceService } from '@common/url-reference.service';
import { RemitFilesInModule } from './err/remit/files/in/remit-files-in.module';
import { RemitFilesOutModule } from './err/remit/files/out/remit-files-out.module';
import { RemitOmpWhitelistModule } from './err/remit/omp/whitelist/remit-omp-whitelist.module';
import { SettingsModule } from './err/settings/settings.module';
import { RemitTable1TradeModule } from './err/remit/trade/table1/remit-table1-trade.module';
import { RoboDelegatorsModule } from './err/robo/delegators/robo-delegators.module';
import { CmsCommonModule } from '@common/cms-common.module';
import { StateService } from '@common/state.service';
import { CacheInterceptor } from '@common/cache-interceptor';
import { RemitTable2TradeModule } from './err/remit/trade/table2/remit-table2-trade.module';
import { RemitTradeEntryModule } from './err/remit/trade-entry/remit-trade-entry.module';
import { ErrorsModule } from '@common/errors/errors.module';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { environment } from '../environments/environment';
import { EcmModule } from './ecm/ecm.module';
import { ErrModule } from './err/err.module';
import { EsmModule } from './esm/esm.module';
import { ProblemSolvingModule } from './problem-solving/problem-solving.module';
import { AccordionModule } from 'primeng/accordion';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { RequestLoggingService } from '@common/request-logging.service';
import { EsmNttPreviewTableModule } from './esm/netting-preview/netted-amount/table/esm-ntt-preview-table.module';
import { BrowserDetectionService } from '@common/browser-detection/browser-detection.service';
import { MailsReportModule } from './administration/administration-tab-panel/billing-runs/acer/mails-report/mails-report.module';

@NgModule({
  declarations: [
    AppComponent,
    EmptyComponent,
    PageComponent,
    NavbarComponent,
    MenuComponent,
  ],
  imports: [
    EsmNttPreviewTableModule,
    MailsReportModule,
    EcmModule,
    EsmModule,
    BrowserModule,
    CommonModule,
    AppRoutesModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AccordionModule,
    CmsCommonModule,
    ErrorsModule,
    LoginModule,
    PreferencesModule,
    ErrRemitSettingsModule,
    RoboModule,
    RoboDelegatorsModule,
    SmtModule,
    CpmlModule,
    RemitDashboardModule,
    RemitTransactionsModule,
    RemitTransactionsSearchModule,
    HelpModule,
    ReportModule,
    DatatableCustomizeSettingsModule,
    AudittrailModule,
    RemitFilesInModule,
    RemitFilesOutModule,
    RemitOmpWhitelistModule,
    SettingsModule,
    RemitTradeEntryModule,
    RemitTable1TradeModule,
    RemitTable2TradeModule,
    ErrModule,
    StoreModule.forRoot({}, {
      runtimeChecks: { // make selector lists sortable in dev mode, do research to make them all true
        strictStateImmutability: false,
        strictStateSerializability: false,
        strictActionImmutability: false,
        strictActionSerializability: false
      }
    }),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument({
      maxAge: 25
    }) : [],
    MessageModule,
    MessagesModule,
    ProblemSolvingModule
  ],
  providers: [
    CookieService,
    StateService,
    SystemService,
    DecimalPipe,
    UserService,
    OrganisationService,
    AuthenticationService,
    RequestLoggingService,
    PasswordService,
    AuthorisationGuard,
    LoginGuard,
    PasswordGuard,
    ForcePasswordGuard,
    UrlReferenceService,
    MessagesMapperService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true
    },
    BrowserDetectionService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
