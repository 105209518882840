import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RemitTable2TradeReferencesModel } from '../../../trade/table2/modify/remit-table2-trade-entry.model';

@Component({
  selector: 'cms-remit-trade-entry-table2-references',
  templateUrl: './remit-trade-entry-table2-references.component.html',
  styleUrls: ['./remit-trade-entry-table2-references.component.scss']
})
export class RemitTradeEntryTable2ReferencesComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  formGroup: UntypedFormGroup;

  @Input()
  readonly: boolean = false;

  @Input()
  onReset: Subject<boolean>;

  @Input()
  data: RemitTable2TradeReferencesModel;

  private onDestroy$ = new Subject();

  constructor() {
    this.onReset = new Subject<boolean>();
  }

  ngOnInit() {
    this.formGroup.addControl('uti', new UntypedFormControl('', Validators.required));
    this.formGroup.addControl('contractDate', new UntypedFormControl('', Validators.required));

    if(this.data){
      this.formGroup.setValue({uti: this.data.uti, contractDate: new Date(this.data.contractDate)});
    }

    this.onReset.pipe(takeUntil(this.onDestroy$))
      .subscribe(   response => {
        if (response) {
          this.reset();
        }
      });
  }

  getValidationErrorStyleClass(fieldName: string): string {
    if (this.formGroup.controls[fieldName].hasError('error')) {
      return 'error';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.formGroup && this.data) {
      this.formGroup.patchValue({uti: this.data.uti, contractDate: new Date(this.data.contractDate)});
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.reset();
    this.onDestroy$.unsubscribe();
  }

  private reset() {
    this.formGroup.controls['uti'].reset();
    this.formGroup.controls['contractDate'].reset();
  }


}
