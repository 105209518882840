export interface OrganisationGroup {
  key: string;
  value: string;
}

export const getCursorPosition = function (input) {
  if (!input)  {
    return;
  }
  if ('selectionStart' in input) {
    return input.selectionStart;
  } else if ((document as any).selection) {
    input.focus();
    const sel = (document as any).selection.createRange();
    const selLen = (document as any).selection.createRange().text.length;
    sel.moveStart('character', -input.value.length);
    return sel.text.length - selLen;
  }
};

export const keyPressByRegex = ({target, target: {value = ''}, keyCode, which}, regex: RegExp): boolean => {
  const charCode = keyCode || which;
  const currentPos = getCursorPosition(target);
  const charStr = String.fromCharCode(charCode);
  const newValue = value.substr(0, currentPos) + charStr + value.substr(currentPos, value.length);
  return regex.test(newValue);
};



