import { createReducer, on } from '@ngrx/store';
import { InvoicePreviewActions } from './invoice-overview.actions';
import { NettingTableActions } from '../netting-table/netting-table.actions';
import { InvoiceOverview } from '../../../netting-preview/netting-overview/netting-overview.model';
import * as IpAction from './invoice-overview.actions';
import * as NttAction from '../netting-table/netting-table.actions';

export interface InvoiceOverviewState {
  loading: boolean;
  previewItems: InvoiceOverview[];
  selected: InvoiceOverview;
  allowDialog: boolean;
}

export const invoiceOverviewInitialState: InvoiceOverviewState = {
  loading: false,
  previewItems: [],
  selected: null,
  allowDialog: false
};

export const invoiceOverviewReducer = createReducer<InvoiceOverviewState, InvoicePreviewActions & NettingTableActions>(
  invoiceOverviewInitialState,

  on(IpAction.LoadViewSuccess, (state, { item }) => ({
    ...state,
    previewItems: [...state.previewItems, item],
    selected: item
  })),

  on(NttAction.loadTable, () => ({ ...invoiceOverviewInitialState })),

  on(IpAction.select, (state, { item }) => ({
    ...state,
    selected: item
  })),

  on(IpAction.loading, (state, { loading }) => ({
    ...state,
    loading
  })),

  on(IpAction.setAllowDialog, (state, { payload}) => ( {
    ...state,
    allowDialog: payload
  })),
  on(IpAction.cleanState, () => ({ ...invoiceOverviewInitialState })));





