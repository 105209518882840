import { ErrorResponse } from '@common/error-response.model';
import { Column } from '@common/shared/datatable.model';

export interface Exception {
  endDateTime: string;
  lastRetry: string;
  messageId: string;
  payload: string;
  producer: string;
  queueName: string;
  queued: string;
  rootCause: string;
  stacktrace: string;
  startDateTime: string;
}

export interface RetryDeleteRequest {
  messageId: string;
  queueName: string;
  queued: string;
}

export interface EditedItem {
  messageId: string;
  queueName: string;
  reason: string;
}

export interface RetryDeleteResponse {
  values: EditedItem[];
}

export interface SelectedException {
  [value: string]: boolean;
}

export interface ExceptionDeleteModel {
  messageId: string;
  queueName: string;
  queued: string;
}

export interface ExceptionFilter {
  queue: string;
  to: string;
  from: string;
}

export interface ExceptionResponse extends ErrorResponse {
  values: Exception[];
  connectionErrors?: string;
}

export interface AvailableQueuesResponse extends ErrorResponse {
  values: string[];
}

const defaultStyles = {
  'text-align': 'left',
  'text-overflow': 'ellipsis',
  'white-space': 'nowrap'
};

export const dataTableColumns: Column[] = [
  { name: 'queueName', type: 'text', title: 'Queue Name', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'messageId', type: 'text', title: 'Message ID', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'queued', type: 'date', title: 'Queued', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'lastRetry', type: 'date', title: 'Last Retry', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'producer', type: 'text', title: 'Producer', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'details', type: 'infoButton', title: 'Details', style: { ...defaultStyles, 'text-align': 'center', 'max-width': '80px' }, sortable: false, visible: true },
  { name: 'select', type: 'checkbox', title: 'Select', style: { ...defaultStyles, 'text-align': 'center', 'max-width': '80px' }, sortable: false, visible: true },
];
