export class EndPointConstants { // keep in sync with java version: de.ponton.cms.restfulapi.web.common.EndPointConstants
  static PARAM_ORGANISATION_ID = 'organisationId';
  static PARAM_IS_ELCOM_ONLY = 'isElComOnly';
  static PARAM_REGIME = 'regime';
  static PARAM_RECORD_TYPE = 'recordType';
  static PARAM_DOCUMENT_TYPE = 'documentType';
  static PARAM_HISTORY_ID = 'historyId';
  static PARAM_USERNAME = 'username';
  static PARAM_PASSWORD = 'password';
  static PARAM_TOKEN = 'token';
  static HEADER_PARAM_AUTHENTICATION = 'Authentication';
}
