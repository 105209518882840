import { Message } from 'primeng/api';
import * as fromActions from './monitoring.actions';
import { MonitoringCategory, MonitoringDataTableItem, MonitoringLogs } from '../../monitoring/monitoring.model';
import { Column } from '@common/shared/datatable.model';

export interface MonitoringState {
  messages: Message[];
  lastSnapshotMessage: string;
  newSnapshotLoading: boolean;
  changeDate: string;
  logs: MonitoringLogs[];
  categories: MonitoringCategory[];
  success: boolean | null;
  loaded: boolean;
  columns: Column[];
  data: MonitoringDataTableItem[];
  loading: boolean;
  doneCheckingLoading: boolean;
}

export const initialState: MonitoringState = {
  messages: [],
  lastSnapshotMessage: null,
  newSnapshotLoading: false,
  changeDate: null,
  logs: null,
  categories: [],
  success: null,
  loaded: false,
  columns: [],
  data: null,
  loading: false,
  doneCheckingLoading: false
};

export function ecmMonitoringReducer(state: MonitoringState = {...initialState},
                                     action: fromActions.MonitoringActions): MonitoringState {
  switch (action.type) {
    case fromActions.loadDataAction.type: {
      return {
        ...state,
        loading: true
      };
    }
    case fromActions.loadDataSuccessAction.type: {
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    }
    case fromActions.setColumnsAction.type: {
      return {
        ...state,
        columns: action.payload
      };
    }
    case fromActions.getLogsSuccessAction.type: {
      return {
        ...state,
        logs: action.payload
      };
    }
    case fromActions.createSnapshotAction.type: {
      return {
        ...state,
        newSnapshotLoading: true
      };
    }
    case fromActions.doneCheckingAction.type : {
      return {
        ...state,
        doneCheckingLoading: true
      };
    }
    case fromActions.createSnapshotLoadingOffAction.type: {
      return {
        ...state,
        newSnapshotLoading: false
      };
    }
    case fromActions.getStatesSuccessAction.type: {
      const { lastSnapshotMessage, changeDate, categories, success } = action.payload;
      return {
        ...state,
        lastSnapshotMessage,
        changeDate,
        categories,
        success,
        loaded: true
      };
    }
    case fromActions.setMessagesAction.type: {
      return {
        ...state,
        messages: action.payload,
        doneCheckingLoading: false
      };
    }
    case fromActions.setToDefaultAction.type: {
      return {
        ...initialState
      };
    }
    default: {
      return state;
    }
  }
}
