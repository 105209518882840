import { Injectable } from '@angular/core';
import { ApiRequestService } from '@common/api-request.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { EsmNettingStatementFinderFilterState, EsmNettingStatementFinderState } from './esm-netting-finder.reducers';
import * as fromFinderActions from '../../netting/finder/esm-netting-finder.actions';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import * as fromTableActions from '../../netting/finder/table/table.actions';
import {
  EsmNettingStatementFinderFormResponse,
  EsmNettingStatementUserFilter, EsmNettingStatementUserFiltersResponse,
  NettingStatementFinderPropertyFilter
} from '../../../netting/finder/esm-netting-finder.model';
import { generateInitialDate } from '../../../invoice/finder/invoice-finder.model';
import * as fromFiltersSelectors from '../../netting/finder/esm-netting-finder.selectors';
import * as fromMyFiltersActions from '../../netting/finder/my-filter/my-filter.actions';
import { Sender } from '@common/deals/deals.model';
import { Message } from 'primeng/api';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { of } from 'rxjs';
import * as fromMyFilterActions from './my-filter/my-filter.actions';

@Injectable()
export class EsmNettingStatementFinderEffects {
  private baseUrl = '/api/esm/nettingstatement/finder';

  constructor(private apiGateway: ApiRequestService,
              private actions$: Actions,
              private store$: Store<EsmNettingStatementFinderState>,
              private mapper: MessagesMapperService) {
  }

  getFormValues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromFinderActions.getFormValuesAction),
      switchMap(() =>
        this.apiGateway.get(`${this.baseUrl}/form`)
          .pipe(
            switchMap((response: EsmNettingStatementFinderFormResponse) => {
               const messages: Message[] = this.mapper.toErrorMessages(response);
               if (messages && messages.length) {
                 return [fromTableActions.setMessagesAction(messages)];
                }

              const { commodities, counterParties, nettingStatementTypes, deliveryPoints, } = response.value;

              return [
                 fromTableActions.setMessagesAction([]),
                fromMyFiltersActions.loadUserFilterAction(),
                fromFinderActions.onFormValuesResponseAction(response.value),
                fromMyFiltersActions.setAllDealsFilterAction({
                  defaultFilter: true,
                  filterName: 'All',
                  commodityFilter: commodities,
                  deliveryPointFilter: deliveryPoints.map(({ key, value }) => ({ keyObject: key, valueObject: value })),
                  nettingStatementTypeFilter: nettingStatementTypes,
                  counterpartyFilter: counterParties.map(item => item.key),
                  hiddenType: Sender.US
                })
              ];
            }),
            catchError(error => of(fromTableActions.setMessagesAction([this.mapper.createErrorMessage(error.errorMessage)])))
          )
      )
    )
  );

  resetPropertyFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromFinderActions.resetPropertyFilterAction),
      withLatestFrom(this.store$.pipe(select(fromFiltersSelectors.getEsmNettingFinderState))),
      switchMap(([, { filterState, myFilterState }]: [Action, EsmNettingStatementFinderState]) => {
        const propFilters = {
          ...filterState.filter,
          submissionDateRange: { start: generateInitialDate(19), end: null }
        };
        return [
          fromTableActions.setMessagesAction([]),
          fromFinderActions.updateFiltersAction(this.updateFilters(filterState, propFilters, myFilterState.defaultFilter))
        ];
      }))
  );

  applyMyFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMyFilterActions.onUserFilterLoadedAction),
      withLatestFrom(this.store$.pipe(select(fromFiltersSelectors.getEsmNettingFinderState))),
      switchMap(([, { filterState, myFilterState }]: [Action, EsmNettingStatementFinderState]) => {
        const propFilters = {
          ...filterState.filter,
          submissionDateRange: { start: generateInitialDate(19), end: null }
        };
        return [
          fromTableActions.setMessagesAction([]),
          fromFinderActions.updateFiltersAction(this.updateFilters(filterState, propFilters, myFilterState.defaultFilter))
        ];
      })
    )
  );



  private updateFilters = (
    filterValues: EsmNettingStatementFinderFilterState,
    propFilters: NettingStatementFinderPropertyFilter,
    defaultFilter: EsmNettingStatementUserFilter): NettingStatementFinderPropertyFilter => {
    let filters: NettingStatementFinderPropertyFilter;
    if (!defaultFilter) {
      filters = {
        ...propFilters,
        commodities: filterValues.commodities || [],
        counterParties: filterValues.counterParties.map(item => item.key) || [],
        deliveryPoints: filterValues.deliveryPoints || [],
        nettingStatementTypes: filterValues.nettingStatementTypes|| [],
        senderUs: true
      };
    } else {
      filters = {
        ...propFilters,
        commodities: (defaultFilter.commodityFilter &&
          defaultFilter.commodityFilter.filter(item => filterValues.commodities.includes(item))) || filterValues.commodities,
        counterParties: (defaultFilter.counterpartyFilter &&
          defaultFilter.counterpartyFilter.filter(key => filterValues.counterParties.some(obj => obj.key === key))) || filterValues.counterParties.map(item => item.key),
        deliveryPoints: (defaultFilter.deliveryPointFilter &&
          defaultFilter.deliveryPointFilter.filter(item => filterValues.deliveryPoints.includes(item))) || filterValues.deliveryPoints,
        nettingStatementTypes: (defaultFilter.nettingStatementTypeFilter &&
          defaultFilter.nettingStatementTypeFilter.filter(item => filterValues.nettingStatementTypes.includes(item))) || filterValues.nettingStatementTypes,
        senderUs: defaultFilter.hiddenType === Sender.US || true
      };
    }
    return filters;
  }
}
