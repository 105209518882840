import { createSelector } from '@ngrx/store';
import { getAdminState, State } from '../reducers';
import { UserRolesState } from './user-role.reducer';

export const getUserRolesState = createSelector(getAdminState, (state: State) => state.userRole);

export const getOrgGroups = createSelector(getUserRolesState,
  (state: UserRolesState) => (state.organisationGroupFilter && state.organisationGroupFilter.filterEntries) || []);
export const orgGroupVisible = createSelector(getUserRolesState,
  (state: UserRolesState) => state.organisationGroupFilter && state.organisationGroupFilter.visible);

export const getUsers = createSelector(getUserRolesState,
  (state: UserRolesState) => (state.userFilter && state.userFilter.filterEntries) || []);
export const usersVisible = createSelector(getUserRolesState,
  (state: UserRolesState) => !!(state.userFilter && state.userFilter.visible));

export const getProcessRoles= createSelector(getUserRolesState,
  (state: UserRolesState) => (state.processRoleFilter && state.processRoleFilter.filterEntries) || []);
export const processRolesVisible = createSelector(getUserRolesState,
  (state: UserRolesState) => !!(state.processRoleFilter && state.processRoleFilter.visible));

export const getFilters = createSelector(getUserRolesState,
  (state: UserRolesState) => state.filters);

export const getColumns = createSelector(getUserRolesState,
  (state: UserRolesState) => state.columns);

export const loading = createSelector(getUserRolesState,
  (state: UserRolesState) => state.loading);

export const getData = createSelector(getUserRolesState,
  (state: UserRolesState) => state.data);

export const getOrganisationId = createSelector(getUserRolesState, (state: UserRolesState) => state.organisationId);

export const getEditableData = createSelector(getUserRolesState, (state: UserRolesState) => state.editableData);

export const getMessages = createSelector(getUserRolesState, (state: UserRolesState) => state.messages);
