<div class="external-rrm-filter_container">
  <section class="row gutter-6 status_container">
    <div class="col-xl-3 col-12 margin-bottom-15">
      <span class="!font-bold padding-right-9">Last successfully sent&nbsp;</span>
      <span *ngIf="status">{{ status.lastSuccessfullSent }}</span>
      <span *ngIf="!status"><i class="pi-spin pi pi-spinner"></i></span>
    </div>
    <div class="col-xl-3 col-12 margin-bottom-15">
      <span class="!font-bold padding-right-9">received&nbsp;</span>
      <span *ngIf="status">{{ status.lastSuccessfullReceived }}</span>
      <span *ngIf="!status"><i class="pi-spin pi pi-spinner"></i></span>
    </div>
    <div class="col-xl-3 col-12 margin-bottom-15">
      <span class="!font-bold padding-right-9">Oldest outstanding Response&nbsp;</span>
      <span *ngIf="status">{{ status.oldestOutstandingResponse }}</span>
      <span *ngIf="!status"><i class="pi-spin pi pi-spinner"></i></span>
    </div>
    <div class="col-xl-3 col-12 margin-bottom-15">
      <span class="!font-bold padding-right-9">Outstanding Responses&nbsp;</span>
      <span *ngIf="status">{{ status.outstandingResponses }}</span>
      <span *ngIf="!status"><i class="pi-spin pi pi-spinner"></i></span>
    </div>
  </section>
  <form class="panel-box form-row flex align-items-end" [formGroup]="form">
    <div class="col-xl-2 col-12 margin-bottom-15">
      <label class="margin-0 text-medium padding-bottom-6">Status:</label>
      <p-dropdown
        *ngIf="statusOptions"
        id="messageStatus"
        [options]="statusOptions"
        formControlName="messageStatus"
        [style]="{width: '100%'}">
      </p-dropdown>
    </div>
    <div class="col-xl-2 col-12 margin-bottom-15">
      <label class="margin-0 text-medium padding-bottom-6">Receiver:</label>
      <p-dropdown
        *ngIf="receiverOptions"
        id="receiver"
        [options]="receiverOptions"
        formControlName="receiver"
        [style]="{width: '100%'}">
      </p-dropdown>
    </div>
    <div class="col-xl-2 col-12 margin-bottom-15">
      <label class="margin-0 text-medium padding-bottom-6">Message Type:</label>
      <p-dropdown
        *ngIf="messageTypeOptions"
        id="messageType"
        [options]="messageTypeOptions"
        formControlName="messageType"
        [style]="{width: '100%'}">
      </p-dropdown>
    </div>
    <div class="col-xl-2 col-12 margin-bottom-15">
      <label class="margin-0 text-medium padding-bottom-6 block">Received from:</label>
      <p-calendar
        id="startDate"
        class="margin-right-6"
        [style]="{ 'min-width': '105px', 'width': '60%' }"
        placeholder="From Date"
        formControlName="startDate"
        [showIcon]="true"
        [monthNavigator]="true"
        [yearNavigator]="true"
        dateFormat="dd/mm/yy"
        cmsDateRestriction>
      </p-calendar>
      <p-inputMask
        id="startTime"
        formControlName="startTime"
        mask="99:99:99"
        pattern="(([0-1]?[0-9])|(2[0-3])):[0-5][0-9]:[0-5][0-9]"
        placeholder="hh:mm:ss"
        slotChar="hh:mm:ss"
        styleClass="text-small text-center"
        [style]="{'min-width': '70px', 'width': '30%'}">
      </p-inputMask>
    </div>
    <div class="col-xl-2 col-12 margin-bottom-15">
      <label class="margin-0 text-medium padding-bottom-6 block">To:</label>
      <p-calendar
        id="endDate"
        placeholder="To Date"
        formControlName="endDate"
        [showIcon]="true"
        [monthNavigator]="true"
        [yearNavigator]="true"
        dateFormat="dd/mm/yy"
        class="margin-right-6"
        [style]="{ 'min-width': '105px', 'width': '60%' }"
        cmsDateRestriction>
      </p-calendar>
      <p-inputMask
        id="endTime"
        formControlName="endTime"
        mask="99:99:99"
        pattern="(([0-1]?[0-9])|(2[0-3])):[0-5][0-9]:[0-5][0-9]"
        placeholder="hh:mm:ss"
        slotChar="hh:mm:ss"
        styleClass="text-small text-center"
        [style]="{'min-width': '70px', 'width': '30%'}">
      </p-inputMask>
    </div>
    <div class="col-xl-2 col-12 margin-bottom-15">
      <button
        id="err-filter-btn-refresh"
        *ngIf="isButtonVisible"
        class="btn btn-icon fa fa-refresh margin-right-6"
        (click)="onRefreshTime()">
      </button>
      <button
        id="err-filter-btn-filter"
        class="btn"
        [disabled]="form.get('startTime').invalid || form.get('startDate').invalid ||
                form.get('endTime').invalid || form.get('endDate').invalid"
        (click)="onSubmit()">
        Search
      </button>
    </div>
  </form>
</div>

