import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Message } from 'primeng/api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Action, select, Store } from '@ngrx/store';
import { getDealsColumns, mapRequestValues } from '@common/deals/deals.model';
import { saveBlob, toPayload } from '@common/cms-common.model';
import { EsmDashboardState } from '../dashboard.reducers';
import { EsmDashboardTableState } from './table.reducer';
import { EsmDashboardFiltersState } from '../filters/filters.reducers';
import { ApiRequestService } from '@common/api-request.service';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import {
  allColumnsDealConfig,
  buildColumns,
  EsmDashboardBuckets,
  EsmDashboardTableDataRequest,
  EsmDashboardTableType,
  FilterRequestModel
} from '../../../invoice/dashboard/esm-dashboard.model';
import * as fromTableSelectors from './table.selectors';
import * as fromFiltersSelectors from '../filters/filters.selectors';
import * as fromDashboardSelectors from '../dashboard.selectors';
import * as fromDBActions from '../dashboard.actions';
import * as fromDBTableActions from './table.actions';
import * as fromDBFiltersActions from '../filters/filters.actions';
import * as fromIFTableActions from '../../invoice-finder/table/table.actions';
import { HttpParams } from '@angular/common/http';
import { EsmPDFResendResponse } from '../../../invoice/finder/invoice-finder.model';
import * as moment from 'moment';

@Injectable()
export class EsmDashboardTableEffects {
  private baseUrl = '/api/esm/dashboard';

  constructor(private apiGateway: ApiRequestService,
              private actions$: Actions,
              private router: Router,
              private store$: Store<EsmDashboardState>,
              private mapper: MessagesMapperService) { }

  filter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromDBFiltersActions.filterAction,
        fromDBFiltersActions.refreshFiltersAction,
        fromDBTableActions.sortAndPageDataAction
      ),
      withLatestFrom(
        this.store$.pipe(select(fromTableSelectors.getTable)),
        this.store$.pipe(select(fromFiltersSelectors.getFilters)),
        this.store$.pipe(select(fromDashboardSelectors.getDashboardState)),
      ),
      switchMap(([, { paging, sorting }, filters, { viewType, state }]: [Action, EsmDashboardTableState, EsmDashboardFiltersState, EsmDashboardState]) => (
        this.loadData(filters, paging, sorting, viewType, state)
      ))
    ));

  resend$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromDBTableActions.resendPDFAction),
      map(toPayload),
      switchMap((invoiceId) =>
        this.apiGateway.post(`${this.baseUrl}/resend`, null, new HttpParams().set('invoiceId', invoiceId.toString()))
          .pipe(
            switchMap((response: EsmPDFResendResponse) => {
                const messages: Message[] = [{
                  severity: 'info',
                  summary: 'INFO:',
                  detail: response.response,
                  id: '',
                  key: '',
                  life: 0,
                  sticky: false,
                  closable: true,
                  data: invoiceId
                }];

                return [fromDBTableActions.setMessagesAction(messages)];
              }
            )
          ))
    ));

  addNotesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromIFTableActions.addNoteSuccessAction),
      filter(() => (this.router.routerState.snapshot.url.includes('esm/dashboard'))),
      map(() => fromDBFiltersActions.filterAction())
    ));

  export$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromDBTableActions.exportAction),
      withLatestFrom(
        this.store$.pipe(select(fromTableSelectors.getTable)),
        this.store$.pipe(select(fromFiltersSelectors.getFilters)),
        this.store$.pipe(select(fromDashboardSelectors.getDashboardState)),
      ),
      concatMap(([, { paging, sorting }, filters, { viewType, state }]: [Action, EsmDashboardTableState, EsmDashboardFiltersState, EsmDashboardState]) =>

          this.apiGateway.getBlob(
            `${this.baseUrl}/loadTableData/export`, this.getFilterRequest(filters, paging, sorting, viewType, state))
            .pipe(
              switchMap(this.handleBlob),
              catchError(error => of(fromDBTableActions.setMessagesAction([this.mapper.createErrorMessage(error.errorMessage || 'Server Error')]))))
      )
    )
  );

  private loadData(filters: EsmDashboardFiltersState, paging: PagingFilter, sorting: SortingFilter, viewType: EsmDashboardTableType, state: string) {
    const request = this.getFilterRequest(filters, paging, sorting, viewType, state);
    return this.apiGateway
      .post(`${this.baseUrl}/${viewType}`, request)
      .pipe(
        switchMap((response: EsmDashboardTableDataRequest | EsmDashboardBuckets | any) => {
          const messages: Message[] = this.mapper.toErrorMessages(response);
          if (messages && messages.length) {
            return [fromDBTableActions.setMessagesAction(messages)];
          }
          const isBucketView = viewType === EsmDashboardTableType.BUCKETS;
          const columns = isBucketView ? buildColumns(response) : getDealsColumns(response.columnOrder, allColumnsDealConfig);
          return [
            isBucketView ? fromDBActions.setStateNamesAction(response.stateNames) : null,
            fromDBTableActions.setColumnsAction(columns),
            fromDBFiltersActions.filterSuccessAction(response),
            fromDBTableActions.setMessagesAction([]),
          ].filter(Boolean);
        }),
        catchError(error => of(fromDBTableActions.setMessagesAction([this.mapper.createErrorMessage(error.errorMessage || 'Server Error')])))
      );
  }

  private getFilterRequest(filters: EsmDashboardFiltersState,
    paging: PagingFilter,
    sorting: SortingFilter,
    viewType: EsmDashboardTableType,
    state: string) {
    const {
      counterParties,
      commodities,
      deliveryPoints,
      invoiceTypes,
      invoiceStartDate,
      invoiceEndDate,
      deliveryDateRange,
      paymentDateRange
    } = filters.value;
    const { invoiceRole, category, dateRange } = filters;
    const filterRequestModel: FilterRequestModel = {
      commodities: mapRequestValues(commodities, filters.commodities),
      deliveryPoints: mapRequestValues(deliveryPoints, filters.deliveryPoints),
      invoiceTypes: mapRequestValues(invoiceTypes, filters.invoiceTypes),
      counterParties: mapRequestValues(counterParties, filters.counterParties ? filters.counterParties.map(item => item.key) : []),
      invoiceRole,
      category,
      invoiceStartDate: invoiceStartDate ? moment(invoiceStartDate).format('YYYY-MM-DD') : null,
      invoiceEndDate: invoiceEndDate ? moment(invoiceEndDate).format('YYYY-MM-DD') : null,
      paymentDateRange: {
        start: paymentDateRange && paymentDateRange.start ? moment(paymentDateRange.start).format('YYYY-MM-DD') : null,
        end: paymentDateRange && paymentDateRange.end ? moment(paymentDateRange.end).format('YYYY-MM-DD') : null
      },
      deliveryDateRange: {
        start: deliveryDateRange && deliveryDateRange.start ? moment(deliveryDateRange.start).format('YYYY-MM-DD') : null,
        end: deliveryDateRange && deliveryDateRange.end ? moment(deliveryDateRange.end).format('YYYY-MM-DD') : null
      }
    };



    if (viewType === EsmDashboardTableType.BUCKETS) {
      return filterRequestModel;
    } else if (viewType === EsmDashboardTableType.TABLE_DATA) {
      return {
        filterRequestModel,
        sorting,
        paging,
        dateRange,
        state
      };
    }
    return filters.value;
  }

  private handleBlob(response): Observable<ReturnType<typeof fromDBTableActions.setMessagesAction>> {
    saveBlob(response);
    return of(fromDBTableActions.setMessagesAction([]));
  }
}

