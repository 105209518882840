import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { EsmInternalMasterDataState } from '../../../state/master-data/internal-master-data/internal-master-data.reducer';
import { EsmPdfAccessRights, EsmPdfSettings, initialPdfFormValues } from '../internal-master-data.model';
import * as fromMDActions from '../../../state/master-data/internal-master-data/internal-master-data.actions';
import * as fromMDSelectors from '../../../state/master-data/internal-master-data/internal-master-data.selectors';
import * as fromMDPdfActions from '../../../state/master-data/internal-master-data/pdf-settings/pdf-settings.actions';
import * as fromMDPdfSelectors from '../../../state/master-data/internal-master-data/pdf-settings/pdf-settings.selectors';
import { filter, map, shareReplay, take, tap } from 'rxjs/operators';
import { OrganisationService } from '@common/organisation.service';
import { initialMDPdfSettingsState } from '../../../state/master-data/internal-master-data/pdf-settings/pdf-settings.reducer';
import { Message } from 'primeng/api';
import { storePdfTemplateSettingsAction } from '../../../state/master-data/internal-master-data/pdf-settings/pdf-settings.actions';

@Component({
  selector: 'cms-master-data-pdf-settings',
  styleUrls: ['./master-data-pdf-settings.component.scss'],
  templateUrl: './master-data-pdf-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MasterDataPdfSettingsComponent implements OnInit, OnDestroy {

  subscription$: Subscription = new Subscription();
  newMDRecord$: Observable<boolean> = this.store.pipe(select(fromMDSelectors.getIsNewMDRecord));
  selectedMasterDataId$: Observable<number> = this.store.pipe(select(fromMDSelectors.getMasterDataId));
  pdfSetting$: Observable<EsmPdfSettings> = this.store.pipe(select(fromMDPdfSelectors.getEsmPdfSettingsValues));
  messages$: Observable<Message[]> = this.store.pipe(select(fromMDPdfSelectors.getPdfMessages));
  presetValues$: Observable<EsmPdfAccessRights> = this.store.pipe(select(fromMDPdfSelectors.getEsmPdfSettingsPresetValues), shareReplay());
  canEdit$: Observable<{ moAllowed: boolean, fhaAllowed: boolean }>;

  masterDataId: number;
  version: number;

  errors: { [key: string]: ValidationErrors } = {};
  form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder,
              private store: Store<EsmInternalMasterDataState>,
              private organisationService: OrganisationService) {

    this.store.dispatch(fromMDPdfActions.loadPdfPresetValueAction());
    this.store.dispatch(fromMDPdfActions.loadPdfSettingsAction());

    this.form = this.fb.group(
      {
        fha: this.fb.group({
          invoiceTemplate: '',
          nettingTemplate: ''
        }),
        mo: this.fb.group({
          invoiceBoilerplate1: '',
          invoiceBoilerplate2: '',
          nettingBoilerplate1: '',
          nettingBoilerplate2: ''
        })
      }
    );



    this.canEdit$ = combineLatest([this.pdfSetting$, this.newMDRecord$, this.presetValues$]).pipe(
        map(([{masterDataId},newRecord,{editAllowed,uploadAllowed}]) => {
          this.form.disable();

         const moAllowed = !!editAllowed && !!masterDataId && !newRecord;
         const fhaAllowed= !!uploadAllowed && !!masterDataId;

          if (fhaAllowed) {
            this.form.controls.fha.enable();
          } else if (moAllowed) {
            this.form.controls.mo.enable();
          }

          return { moAllowed, fhaAllowed };
        }),
      shareReplay());
  }

  get organisationId(): number {
    return this.organisationService.getCachedOrganisationId();
  }

  ngOnInit(): void {

    this.subscription$
      .add(this.organisationService.getOrganisationChangeIfAccessible().subscribe(() => {
        this.store.dispatch(fromMDActions.setToDefaultAction());
        this.store.dispatch(fromMDActions.loadPresetValuesAction());
        this.store.dispatch(fromMDActions.loadVatIdsAction());
        this.store.dispatch(fromMDPdfActions.loadPdfSettingsAction());}));

    this.subscription$.add(this.pdfSetting$.subscribe(this.populateForm));

    this.subscription$.add(this.newMDRecord$.subscribe(() =>this.store.dispatch(fromMDPdfActions.resetPdfFormAction(initialMDPdfSettingsState))));

    this.subscription$.add(this.selectedMasterDataId$.pipe(filter(id=>!!id), tap(()=>this.store.dispatch(fromMDPdfActions.loadPdfSettingsAction())))
      .subscribe(id => this.masterDataId = id));

  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

  onSave(): void {
    const settings = this.form.controls.mo.getRawValue() as EsmPdfSettings;
    settings.version = this.version;
    settings.masterDataId = this.masterDataId;
    this.store.dispatch(fromMDPdfActions.storePdfSettingsAction(settings));
  }

  onSaveTemplate() {

    const settings = this.form.controls.fha.getRawValue() as EsmPdfSettings;
    settings.version = this.version;
    settings.masterDataId = this.masterDataId;
    this.store.dispatch(fromMDPdfActions.storePdfTemplateSettingsAction(settings));
  }

  private populateForm = ({masterDataId,
                            version,
                            invoiceBoilerplate1,
                            invoiceBoilerplate2,
                            nettingBoilerplate1,
                            nettingBoilerplate2,
                            invoiceTemplate,
                            nettingTemplate,
                            logoAvailable,
                            accessRights}): void => {


    if (!!masterDataId) { // if pdf settings exits
      this.version = version;

      this.form.setValue({
        fha: {
          invoiceTemplate: invoiceTemplate,
          nettingTemplate: nettingTemplate,
        },
        mo: {
          invoiceBoilerplate1: invoiceBoilerplate1,
          invoiceBoilerplate2: invoiceBoilerplate2,
          nettingBoilerplate1: nettingBoilerplate1,
          nettingBoilerplate2: nettingBoilerplate2
        }
      });

      // fha mo check
      this.form.disable();
      if (accessRights.uploadAllowed) {
        this.form.controls.fha.enable();
      } else if (accessRights.editAllowed) {
        this.form.controls.mo.enable();
      }

    } else { // pdf settings does not exits
      this.form.reset();
      this.form.disable();
    }
  }

  resetSettings(){
    this.store.pipe(
      take(1),
      select(fromMDPdfSelectors.getEsmPdfSettingsValues),
      tap(this.populateForm))
      .subscribe();
  }

  createPdfSettings() {
    this.store.dispatch(fromMDPdfActions.createPdfSettingsAction({
      ...initialPdfFormValues,
      'masterDataId': this.masterDataId,
      'version': 0,
      'logoAvailable': false
    }));
  }

  onUploadError($event: Message[]) {
    this.store.dispatch(fromMDPdfActions.setPdfMessagesAction($event));
  }


}
