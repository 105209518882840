import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApiRequestService } from '@common/api-request.service';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { getFilenameFromHeader } from '@common/deals/deals.model';
import { Message } from 'primeng/api';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import { EcmProductsDataResponse, EcmProductsResponse } from '../../products/ecm-products';
import { ErrorResponse } from '@common/error-response.model';
import { EcmProductsState } from './ecm-products.reducers';
import * as FileSaver from 'file-saver';
import * as fromActions from './ecm-products.actions';

@Injectable()
export class EcmProductsEffects {
  private baseUrl = '/api/ecm/edit/products';

  constructor(private actions$: Actions,
              private apiGateway: ApiRequestService,
              private store$: Store<EcmProductsState>,
              private mapper: MessagesMapperService) {
  }

   add$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.addProductAction.type),
      concatMap((action: ReturnType<typeof fromActions.addProductAction>) => {
        const { commodity, market, transactionType } = action.payload;
        const body = {
          commodity: commodity ? commodity.trim() : null,
          market: market ? market.trim() : null,
          transactionType: transactionType ? transactionType.trim() : null
        };
        return this.apiGateway.post(`${this.baseUrl}/add`, body)
          .pipe(
            concatMap((response: any | ErrorResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response as ErrorResponse);
              if (messages && messages.length) {
                return [fromActions.setMessagesAction(messages)];
              }
              return [
                fromActions.loadPresetsAction(),
                fromActions.cleanAddProductAction(),
                fromActions.loadDataAction(),
                fromActions.setMessagesAction(this.mapper.toSuccessMessages('Product added.'))
              ];
            }),
            catchError(() => of(fromActions.setMessagesAction([this.mapper.createErrorMessage('Server error')])))
          );
      })));

   loadPresets$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.loadPresetsAction.type),
      concatMap(() => {
        return this.apiGateway.get(`${this.baseUrl}/preset`)
          .pipe(
            map((response: EcmProductsResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response as ErrorResponse);
              if (messages && messages.length) {
                return fromActions.setMessagesAction(messages);
              }
              return fromActions.loadPresetsSuccessAction(response.value);
            }),
            catchError(() => of(fromActions.setMessagesAction([this.mapper.createErrorMessage('Server error')])))
          );
      })));

   loadData$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.loadDataAction.type),
      switchMap(() => {
        return this.apiGateway.get(`${this.baseUrl}/data`)
          .pipe(
            switchMap((response: EcmProductsDataResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response as ErrorResponse);
              if (messages && messages.length) {
                return [fromActions.setMessagesAction(messages)];
              }
              return [
                fromActions.loadDataActionSuccess(response.values)
              ];
            }),
            catchError(() => of(fromActions.setMessagesAction([this.mapper.createErrorMessage('Server error')]))));
      })));

   download$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.downloadAction.type),
      concatMap(() => {
        return this.apiGateway.getBlob(`${this.baseUrl}/download/xls`)
          .pipe(
            switchMap((response: any) => {

              if (response.status === 204) {
                throw new Error(' File has no content ');
              }

              const filename = getFilenameFromHeader(response.headers);
              const blob: Blob = response.body;
              FileSaver.saveAs(blob, filename);
              return of(fromActions.setMessagesAction([]));
            }),
            catchError((e) => of(fromActions.setMessagesAction([this.mapper.createErrorMessage('Server error' + e)]))));
      })
    ));

   delete$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.deleteAction.type),
      concatMap((action: ReturnType<typeof fromActions.deleteAction>) => {
        const { productId } = action.payload;
        return this.apiGateway.delete(`${this.baseUrl}/data/${productId}/delete`)
          .pipe(
            switchMap((response: any | ErrorResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response as ErrorResponse);
              if (messages && messages.length) {
                return [fromActions.setMessagesAction(messages)];
              }
              return [
                fromActions.loadPresetsAction(),
                fromActions.setMessagesAction([]),
                fromActions.loadDataAction(),
                fromActions.cleanAddProductAction()
              ];
            }),
            catchError(() => of(fromActions.setMessagesAction([this.mapper.createErrorMessage('Server error')]))));
      })));
}
