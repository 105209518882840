<form class="panel-box margin-bottom-12" [formGroup]="form">
  <div class="form-group row gutter-6 align-items-center">
    <label for="esm-filename-filter-input" class="col-md-2 col-form-label col-form-label-md">Filename contains</label>
    <div class="col-md-7">
      <input id="esm-filename-filter-input" type="text" class="form-control form-control-md margin-right-6" formControlName="filename">
    </div>
    <div class="col-md-2">
      <button class="btn form-control margin-6"
        id="esm-filename-filter-btn"
        [disabled]="form.get('filename').invalid || disabled"
        (click)="onSearchByFilename()"
        style="width:125px">
        Search
      </button>
    </div>
  </div>
  <div class="form-group row gutter-6 align-items-center">
    <label class="col-md-2 col-form-label col-form-label-md margin-bottom-9">Subm. date from</label>
    <div class="col-md-3 margin-bottom-9">
      <p-calendar
        placeholder="From Date"
        id="esm-start-date-filter-calendar"
        class="inline-block margin-right-6"
        formControlName="startDate"
        [showIcon]="true"
        [monthNavigator]="true"
        [yearNavigator]="true"
        dateFormat="dd/mm/yy"
        [style]="{ 'max-width': '105px'}"
        cmsDateRestriction>
      </p-calendar>
      <p-inputMask
        id="esm-start-time-filter-input"
        class="inline-block"
        formControlName="startTime"
        mask="99:99:99"
        pattern="(([0-1]?[0-9])|(2[0-3])):[0-5][0-9]:[0-5][0-9]"
        placeholder="hh:mm:ss"
        slotChar="hh:mm:ss"
        styleClass="text-small padding-6 text-center"
        [style]="{ 'max-width': '75px'}">
      </p-inputMask>
    </div>
    <label class="col-auto inline-block padding-right-15  margin-bottom-9">to</label>
    <div class="col-md-3 margin-bottom-9">
      <p-calendar
        placeholder="to Date"
        id="esm-end-date-filter-calendar"
        class="inline-block margin-right-6"
        formControlName="endDate"
        [showIcon]="true"
        [monthNavigator]="true"
        [yearNavigator]="true"
        dateFormat="dd/mm/yy"
        [style]="{ 'max-width': '105px'}"
        cmsDateRestriction>
      </p-calendar>
      <p-inputMask
        id="esm-end-time-filter-input"
        formControlName="endTime"
        class="inline-block"
        mask="99:99:99"
        pattern="(([0-1]?[0-9])|(2[0-3])):[0-5][0-9]:[0-5][0-9]"
        placeholder="hh:mm:ss"
        slotChar="hh:mm:ss"
        styleClass="text-small padding-6 text-center"
        [style]="{ 'max-width': '75px'}">
      </p-inputMask>
    </div>
    <div class="col-auto text-end margin-bottom-9">
      <button
        id="esm-filter-refresh-btn"
        class="btn btn-icon fa fa-refresh margin-right-6 w-init"
        (click)="onReset()">
      </button>
    </div>
    <div class="col-8 col-md-2 margin-bottom-9">
      <button
        id="esm-filter-search-btn"
        style="width:125px"
        class="btn form-control"
        (click)="onSearchByTimeFilter()"
        [disabled]="form.get('startDate').invalid || form.get('startTime').invalid || form.get('endTime').invalid || form.get('endDate').invalid || disabled">
        Search
      </button>
    </div>
  </div>
</form>
