<h1>List Of Organisation Groups</h1>
<div class="panel-box">
  <p-messages [value]="messages$ | async" [closable]="true"></p-messages>
    <cms-datatable *ngIf="(data$ | async) as data"
                 selectionMode="single"
                 [currentPage]="(paging$ | async)?.page"
                 [tableColumns]="dataTableColumns"
                 [sortField]="(sorting$ | async)?.columnName"
                 [sortOrder]="(sorting$ | async)?.sortOrder"
                 [loading]="loading$ | async"
                 [data]="data.values"
                 [totalRecords]="data.count"
                 [selected]="selected"
                 (selectedChange)="onSelected($event)"
                 [scrollable]="false"
                 [autoLayout]="true"
                 (lazyLoad)="onLazyLoad($event)">
    <ng-template pTemplate="caption">
      <div class="row margin-0">
        <cms-input-text-filter [text]="filters$ | async" placeholder="Display Name / Legal Name" (filter)="onTextFieldFilter($event)" class="col-6 margin-0 padding-0"></cms-input-text-filter>
        <div class="col text-end">
          <button class="btn export-btn" pButton type="button" icon="fa fa-download" label="Export" (click)="onExport()"></button>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="body" let-row="row" let-column="column">
      <td *ngIf="column.type === 'active'" [ngStyle]="column.style">
        <span>{{row[column.name] === true ? 'ACTIVE' : ''}}</span>
      </td>
      <td *ngIf="column.type === 'yes'" [ngStyle]="column.style">
        <span>{{!!row[column.name] ? 'YES' : ''}}</span>
      </td>
      <td *ngIf="column.name === 'excludeFromBilling'" [ngStyle]="column.style">
        <cms-cell-status *ngIf="row.excludeFromBilling == true"
                         [column]="column"
                         [editable]="false"
                         [showBlock]="false"
                         [row]="row">
        </cms-cell-status>
      </td>
    </ng-template>
  </cms-datatable>
    <div class="padding-0 btn__container margin-3 flex">
      <div class="col padding-0 margin-0 flex justify-content-start align-items-center">
        <button [disabled]="!selected" class="btn margin-top-3 margin-bottom-3" type="submit" (click)="onManageUsers()">Manage Users</button>
        <button [disabled]="!selected" class="btn margin-top-3 margin-bottom-3 margin-left-12" type="submit" (click)="onManageOrganisations()">Manage Organisations</button>
      </div>

      <div class="padding-0 margin-0 flex justify-content-end align-items-center">
        <button [disabled]="!selected" class="btn margin-top-3 margin-bottom-3 " type="submit" (click)="onEditGroup()">Edit Group</button>
        <button [disabled]="!selected" class="btn margin-top-3 margin-bottom-3 margin-left-12" type="submit" (click)="onEditBillingData()">Edit Billing Data</button>
        <a target="_blank" class="margin-left-12" routerLink="/admin/billing_history" [queryParams]="{ organisationGroupId: selected?.organisationGroupId, displayName: selected?.displayName }">
          <button class="btn" [disabled]="!selected">
            Billing History
          </button>
        </a>
        <button [disabled]="!(selected && selected.withDeleteActionEnabled)" class="btn margin-top-3 margin-bottom-3 margin-left-12" type="submit" (click)="onDeleteGroup()">Delete Group</button>
        <button class="btn margin-top-3 margin-bottom-3 margin-left-12" type="submit" (click)="onAddGroup()">Add Group</button>
      </div>
    </div>
</div>
<ng-container *ngIf="dialog$ | async as dialog">
  <cms-add-edit-group-dialog *ngIf="dialog?.name === 'ADD'"
                             [display]="dialog?.name === 'ADD'"
                             [mode]="dialog?.name"
                             [messages]="dialog?.messages"
                             (cancel)="onDialogCancel($event)"
                             (confirm)="onAddGroupConfirm($event)">
  </cms-add-edit-group-dialog>
  <cms-add-edit-group-dialog *ngIf="dialog?.name === 'EDIT'"
                             [data]="selected"
                             [apiKey]="dialog?.data?.apiKey"
                             [display]="dialog?.name === 'EDIT'"
                             [mode]="dialog?.name"
                             [messages]="dialog?.messages"
                             (generate)="onGenerate($event)"
                             (cancel)="onDialogCancel($event)"
                             (confirm)="onEditGroupConfirm($event)">
  </cms-add-edit-group-dialog>
  <cms-edit-billing-data-dialog *ngIf="dialog?.name === 'EDIT_BILLING_DATA'"
                                [orgGroup]="selected"
                                [display]="dialog?.name === 'EDIT_BILLING_DATA'"
                                [data]="dialog?.data?.data"
                                [vatCodes]="dialog?.data?.vatCodes"
                                [messages]="dialog?.messages"
                                (cancel)="onDialogCancel($event)"
                                (confirm)="onEditBillingDataConfirm($event)">
  </cms-edit-billing-data-dialog>
</ng-container>
