import { Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import { OrganisationService } from '../organisation.service';
import { OrganisationModel } from '../organisation.model';
import { OrganisationGroupModel } from '../organisation-group.model';

@Component({
  selector: 'cms-headline',
  templateUrl: './headline.component.html'
})
export class HeadlineComponent implements OnInit {
  @Input() pageTitle: string;
  @Input() displayOrganisationName = false;
  @Input() displayOrganisationNameWithoutSeparator = false;
  @Input() displayOrganisationGroupName = false;
  @Input() displayOrganisationEicCode = false;

  organization$: Observable<OrganisationModel>;
  organisationGroup$: Observable<OrganisationGroupModel>;

  constructor(private orgService: OrganisationService) {
  }

  ngOnInit(): void {
    this.organization$ = this.orgService.getChangeOrganisationObservable();
    this.organisationGroup$ = this.orgService.getOrganisationGroup();
  }
}

