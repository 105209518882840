import { Message } from 'primeng/api';
import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import { ErrorResponse } from '@common/error-response.model';

export interface TableDataValuesResponse {
  id: number;
  processId: number;
  datastoreId: number;
  receiptTimestamp: string;
  payloadDocumentType: string;
  senderId: string;
  tradeId: string;
  uti: string;
  errorMessage: string;
}

export interface TableDataResponse extends ErrorResponse {
  values: TableDataValuesResponse[];
  count: string;
  currentPage: string;
}

export interface FilterRequest {
  filter: string;
  paging: PagingFilter;
  sorting: SortingFilter;
}

export interface RetryQueueIdentifier {
  datastoreId: number;
  id: number;
  processId: number;
}

export interface RejectReasons extends ErrorResponse{
  values: {key: string, value: string}[];
}

export interface Reject {
  reason: string;
  retryQueueIdentifier: RetryQueueIdentifier[];
}

export interface DialogState {
  messages?: Message[];
}

export const defaultStyles = {
  'text-align': 'left',
  'text-overflow': 'ellipsis',
  'overflow': 'hidden',
  'white-space': 'nowrap'
};

export const allColumnsConfig = [
  { name: 'datastoreId', type: 'button', title: '', style: { ...defaultStyles, 'width': '45px' }, sortable: false, visible: true },
  { name: 'receiptTimestamp', type: 'date', title: 'Sub Date / Time', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'errorMessage', type: 'textarea', title: 'Error Message', style: { ...defaultStyles, 'width': '450px' }, sortable: false, visible: true },
  { name: 'senderId', type: 'text', title: 'Sender', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'tradeId', type: 'textarea', title: 'Trade ID', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'uti', type: 'textarea', title: 'UTI', style: { ...defaultStyles }, sortable: true, visible: true }
];
