<div style="max-width: 585px;" class="commercials">
  <div class="form-title">Trade | Commercials</div>
  <div class="form-body" [formGroup]="formGroup">
    <div class="row gutter-0 !me-[40px]" *ngIf="showQuantity">
      <div class="col-5 label">Quantity <span class="text-required">*</span></div>
      <div class="row col-7 gutter-0 ps-2 pe-1">
        <div class="col-8">
          <input type="text" cmsDigitOnly class="form-control padding-6 text-medium text-start margin-3"
            [ngClass]="appendValidationErrorClass('quantity')" formControlName="quantity">
        </div>
        <div class="col-4 ps-2">
          <p-dropdown [options]="quantityUnits" [group]="true" formControlName="quantityUnit"             [styleClass]="'margin-3 full-width'"></p-dropdown>
        </div>
      </div>
      <span *ngIf="appendValidationErrorClass('quantity')" class="fa fa-info-circle absolute error-marker"></span>
    </div>

    <div class="row gutter-0 !me-[40px]" *ngIf="showPrice">
      <div class="col-5 label">Price</div>
      <div class="row col-7 gutter-0 ps-2 pe-1">
        <div class="col-8">
          <input type="text" cmsDigitOnly class="form-control padding-6 text-medium text-start margin-3"
            [ngClass]="appendValidationErrorClass('price')" formControlName="price">
        </div>
        <div class="col-4 ps-2">
          <p-dropdown [options]="currencies" [group]="true" formControlName="priceCurrency"             [styleClass]="'margin-3 full-width'"></p-dropdown>
        </div>
      </div>
      <span *ngIf="appendValidationErrorClass('price')" class="fa fa-info-circle absolute error-marker"></span>
    </div>

    <div class="row gutter-0 !me-[40px]" *ngIf="showNotionalQuantity">
      <div class="col-5 label">Total Notional Quantity <span class="text-required">*</span></div>
      <div class="row col-7 gutter-0 ps-2 pe-1">
        <div class="col-8">
          <input type="text" cmsDigitOnly class="form-control padding-6 text-medium text-start margin-3"
            [ngClass]="appendValidationErrorClass('totalNotionalQuantity')" formControlName="totalNotionalQuantity">
        </div>
        <div class="col-4 ps-2">
          <p-dropdown [options]="totalQuantityUnits" [group]="true" formControlName="totalNotionalQuantityUnit"
            [styleClass]="'margin-3 full-width'"></p-dropdown>
        </div>
      </div>
      <span *ngIf="appendValidationErrorClass('totalNotionalQuantity')"
        class="fa fa-info-circle absolute error-marker"></span>
    </div>

    <div class="row gutter-0 !me-[40px]" *ngIf="showNotionalAmount">
      <div class="col-5 label">Notional Amount</div>
      <div class="row col-7 gutter-0 ps-2 pe-1">
        <div class="col-8">
          <input type="number" class="form-control padding-6 text-medium text-start margin-3"
            [ngClass]="appendValidationErrorClass('notionalAmount')" formControlName="notionalAmount">
        </div>
        <div class="col-4 ps-2">
          <p-dropdown [options]="currencies" [group]="true" formControlName="notionalAmountCurrency"
            [styleClass]="'margin-3 full-width'"></p-dropdown>
        </div>
      </div>
      <span *ngIf="appendValidationErrorClass('notionalAmount')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
  </div>
</div>
