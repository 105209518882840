import { Action } from '@ngrx/store';
import { CloseDialogAction, OpenDialogAction, UpdateDialogAction } from '../dialog/dialog.actions';
import { ReactivateUser } from '../../administration-tab-panel/common/dialogs-container/activate-dialog/activate-dialog.component';
import { LockInactivateUser } from '../../administration-tab-panel/common/dialogs-container/lock-inactivate-dialog/lock-inactivate-dialog.component';
import { AddUserAccount } from '../../administration-tab-panel/common/dialogs-container/add-user-account-dialog/add-user-account-dialog.component';
import { EditUserAccount } from '../../administration-tab-panel/common/dialogs-container/edit-user-account-dialog/edit-user-account-dialog.component';
import { ResetPassword } from '../../administration-tab-panel/common/dialogs-container/reset-password-dialog/reset-password-dialog.component';

export const LOCK_USER = '[USER ACCOUNT] Lock User';
export const INACTIVATE_USER = '[USER ACCOUNT] Inactivate User';
export const REACTIVATE_USER = '[USER ACCOUNT] Reactivate User';
export const EDIT_USER_ACCOUNT = '[USER ACCOUNT] Edit User Account';
export const RESET_PASSWORD = '[USER ACCOUNT] Reset Password';
export const ADD_USER_ACCOUNT = '[USER ACCOUNT] Add User Account';

export class EditUserAccountAction implements Action {
  type = EDIT_USER_ACCOUNT;
  constructor(public payload: EditUserAccount) { }
}

export class ResetPasswordAction implements Action {
  type = RESET_PASSWORD;
  constructor(public payload: ResetPassword) { }
}

export class AddUserAccountAction implements Action {
  type = ADD_USER_ACCOUNT;
  constructor(public payload: AddUserAccount) { }
}

export class ReactivateUserAction implements Action {
  type = REACTIVATE_USER;
  constructor(public payload: ReactivateUser) { }
}

export class LockUserAction implements Action {
  type = LOCK_USER;
  constructor(public payload: LockInactivateUser) { }
}

export class InactivateUserAction implements Action {
  type = INACTIVATE_USER;
  constructor(public payload: LockInactivateUser) { }
}

export type UserAccountActions = UpdateDialogAction | CloseDialogAction | OpenDialogAction | CloseDialogAction;
