import { createSelector } from '@ngrx/store';
import { getEsmState,State } from '../reducers';
import { PermissionsState } from './permissions.reducer';

const getPermissionsState = createSelector(getEsmState, (state: State) => state.permissions);

export const getTableData = createSelector(getPermissionsState, (permissions: PermissionsState) => permissions.data);
export const getLoading = createSelector(getPermissionsState, (permissions: PermissionsState) => permissions.loading);
export const getPaging = createSelector(getPermissionsState, (permissions: PermissionsState) => permissions.paging);
export const getSorting = createSelector(getPermissionsState, (permissions: PermissionsState) => permissions.sorting);
export const getMessages = createSelector(getPermissionsState, (permissions: PermissionsState) => permissions.messages);
export const getRights = createSelector(getPermissionsState, (permissions: PermissionsState) => permissions.hasEditRights);
