import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { Message } from 'primeng/api';
import { EditOrganisation, Organisation } from '../../organisations.model';
import { MessagesMapperService } from '../../../../../common/messages-mapper.service';

@Component({
  selector: 'cms-edit-organisation-dialog',
  templateUrl: './edit-organisation-dialog.component.html',
  styleUrls: ['./edit-organisation-dialog.component.scss']
})
export class EditOrganisationDialogComponent implements OnInit, OnChanges {
  @Input() data: Organisation;
  @Input() display = false;
  @Input() onlyContacts = false;
  @Input() messages: Message[];
  @Output() displayChange = new EventEmitter<boolean>();
  @Output() confirm = new EventEmitter<EditOrganisation>();
  @Output() cancel = new EventEmitter<Message[]>();
  errors: {[key: string]: ValidationErrors} = {};
  form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder,
              private mapper: MessagesMapperService) {
  }

  onCancel(): void {
    this.hide();
    this.cancel.emit(this.mapper.toWarningMessages(`Editing of organisation '${this.data.displayName}' was cancelled.`));
  }

  onVisibleChange(visible: boolean): void {
    if (!visible) {
      this.onCancel();
    }
  }

  onConfirm(): void {
    const out: EditOrganisation = {
      ...this.form.value,
      organisationId: this.data.orgId,
    };

    this.confirm.emit(out);
  }

  ngOnInit(): void {
    const {
      businessContact = '',
      displayName = '',
      eicCode = '',
      legalName = '',
      technicalContact = ''
    } = this.data;
    this.form = this.formBuilder.group({
      businessContact,
      displayName: { value: displayName, disabled: this.onlyContacts },
      eicCode: { value: eicCode, disabled: this.onlyContacts },
      legalName: { value: legalName, disabled: this.onlyContacts },
      technicalContact
    });
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (this.form) {
      if (changes.display && !this.display) {
        this.hide();
      }
      if (changes.data) {
        const {
          businessContact = '',
          displayName = '',
          eicCode = '',
          legalName = '',
          technicalContact = ''
        } = this.data;
        this.form.patchValue({
            businessContact,
            displayName: { value: displayName, disabled: this.onlyContacts },
            eicCode: { value: eicCode, disabled: this.onlyContacts },
            legalName: { value: legalName, disabled: this.onlyContacts },
            technicalContact
          }
        );
        this.form.updateValueAndValidity();
      }
    }

    if (changes.messages) {
      this.errors = {};
      if (this.messages) {
        this.messages.forEach((obj: Message) => {
          const { key: controlName } = obj;
          this.errors[controlName] = { 'server': true };
        });
      }
    }
  }

  private hide(): void {
    this.messages = [];
    this.display = false;
    this.displayChange.emit(this.display);
    this.form.reset();
    this.form.updateValueAndValidity();
  }
}
