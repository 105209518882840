import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { Message } from 'primeng/api';
import {AddingInvoiceTypeRequest} from '../invoice-settings.model';

@Component({
  selector: 'cms-settings-add-panel',
  templateUrl: './invoice-settings-add-panel.component.html',
  styleUrls: ['./invoice-settings-add-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceSettingsAddPanelComponent implements OnInit, OnChanges {
  @Input() data: AddingInvoiceTypeRequest = {
    type: null,
    commodity: null,
    priceType: null,
    natureOfPrice: null
  };
  @Input() messages: Message[] = [];
  @Input() types: string[] = [];
  @Input() commodities: string[] = [];
  @Input() priceTypes: string[] = [];
  @Input() natureOfPrices: string[] = [];
  @Output() clear = new EventEmitter();
  @Output() confirm = new EventEmitter<AddingInvoiceTypeRequest>();
  errors: { [key: string]: ValidationErrors } = {};
  form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      type: this.data ? this.data.type : '',
      commodity: this.data ? this.data.commodity : '',
      priceType: this.data ? this.data.priceType : '',
      natureOfPrice: this.data ? this.data.natureOfPrice : '',
    });
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes.messages) {
      this.errors = {};
      if (this.messages) {
        this.messages.forEach((obj: Message) => {
          const { key: controlName } = obj;
          this.errors[controlName] = { 'server': true };
        });
      }
    }

    if (this.form && changes.data) {
      const { type = '', commodity = '', priceType = '', natureOfPrice = '' } = this.data;
      this.form.patchValue({
        type,
        commodity,
        priceType,
        natureOfPrice
      });
      this.form.updateValueAndValidity();
    }
  }

  onAdd(): void {
      const {type, commodity, priceType, natureOfPrice} = this.form.value;
      this.confirm.emit({
        'type': type.value,
        'commodity': commodity.value,
        'priceType': priceType.value,
        'natureOfPrice': natureOfPrice.value
      });
  }

  onClear(): void {
    this.form.reset();
    this.errors = {};
    this.clear.emit();
  }
}
