import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {withLatestFrom} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {Message} from 'primeng/api';
import {allColumnsConfig, FhaAccount} from './fha-accounts.model';
import {Column} from '@common/shared/datatable.model';
import {DatatableData} from '@common/shared/results.model';
import {OrganisationModel} from '../../../common/organisation.model';
import {DialogState} from '../../state/dialog/dialog.reducer';
import {OrganisationService} from '@common/organisation.service';
import {Prefix} from '../../state/reducers';
import {SetOrganisationIdAction} from '../../state/organisation-id/organisation-id.actions';
import {SetToDefaultAction} from '../../state/shared/shared.actions';
import {EditableState} from '../common/editable-status-cell/editable-status-cell.component';
import {ResetPassword} from '../common/dialogs-container/reset-password-dialog/reset-password-dialog.component';
import {ReactivateUser} from '../common/dialogs-container/activate-dialog/activate-dialog.component';
import {LockInactivateUser} from '../common/dialogs-container/lock-inactivate-dialog/lock-inactivate-dialog.component';
import {EditUserAccount} from '../common/dialogs-container/edit-user-account-dialog/edit-user-account-dialog.component';
import {AddUserAccount} from '../common/dialogs-container/add-user-account-dialog/add-user-account-dialog.component';
import {FhaAccountsState} from '../../state/fha-accounts/fha-accounts.reducers';
import * as fromSelectors from '../../state/fha-accounts/fha-accounts.selectors';
import * as fromActions from '../../state/fha-accounts/fha-accounts.actions';
import * as fromDtActions from '../../state/datatable/datatable.actions';
import * as fromDialogActions from '../../state/dialog/dialog.actions';

@Component({
  selector: 'cms-fha-accounts',
  templateUrl: './fha-accounts.component.html',
  styleUrls: ['./fha-accounts.component.scss']
})
export class FhaAccountsComponent implements OnInit, OnDestroy {
  data$: Observable<DatatableData<FhaAccount[]>> = this.store.pipe(select(fromSelectors.getData));
  editableData$: Observable<EditableState> = this.store.pipe(select(fromSelectors.getEditableData));
  messages$: Observable<Message[]> = this.store.pipe(select(fromSelectors.getMessages));
  dialog$: Observable<DialogState> = this.store.pipe(select(fromSelectors.getDialog));
  selected$: Observable<FhaAccount> = this.store.pipe(select(fromSelectors.getSelected));
  subscription$: Subscription;
  dataTableColumns: Column[] = allColumnsConfig;
  prefix: Prefix = '[FHA ACCOUNTS]';
  tokenIdVisible = true;

  constructor(private store: Store<FhaAccountsState>,
              private organisationService: OrganisationService) { }

  onUserAccountSelected(fhaAccount: FhaAccount): void {
    this.store.dispatch(new fromDtActions.SelectAction(this.prefix, fhaAccount));
  }

  ngOnInit(): void {
    this.store.dispatch(new SetOrganisationIdAction(this.prefix, this.organisationService.getCachedOrganisationId()));
    this.store.dispatch(new fromDtActions.LoadDataAction(this.prefix));
    this.onOrganisationChangeSubscription();
  }

  ngOnDestroy(): void {
    this.store.dispatch(new SetToDefaultAction(this.prefix));
    this.subscription$.unsubscribe();
  }

  onResetPassword(): void {
    this.store.dispatch(new fromDialogActions.OpenDialogAction(this.prefix, {
      name: 'RESET',
    }));
  }

  onEditUserAccount(): void {
    this.store.dispatch(new fromDialogActions.OpenDialogAction(this.prefix, {
      name: 'EDIT',
    }));
  }

  onAddUserAccount(): void {
    this.store.dispatch(new fromDialogActions.OpenDialogAction(this.prefix, {
      name: 'ADD',
    }));
  }

  onCancelEdit(): void {
    this.store.dispatch(new fromDtActions.SetEditableDataAction(this.prefix, null));
  }

  onInitEdit(editableState: EditableState): void {
    this.store.dispatch(new fromDtActions.SetEditableDataAction(this.prefix, editableState));
  }

  onEditComplete(editableState: EditableState): void {
    this.store.dispatch(new fromDtActions.SetEditableDataAction(this.prefix, editableState));
    this.store.dispatch(new fromDialogActions.OpenDialogAction(this.prefix, {
      name: 'STATE'
    }));
  }

  onConfirmResetPassword(obj: ResetPassword): void {
    this.store.dispatch(fromActions.resetPasswordAction(obj));
  }

  onCancelDialog(messages: Message[]): void {
    this.store.dispatch(new fromDtActions.SetMessagesAction(this.prefix, messages));
    this.store.dispatch(new fromDtActions.SetEditableDataAction(this.prefix, null));
    this.store.dispatch(new fromDialogActions.CloseDialogAction(this.prefix));
  }

  onConfirmReactivate(obj: ReactivateUser): void {
    this.store.dispatch(fromActions.reactivateUserAction(obj));
  }

  onConfirmInactivate(obj: LockInactivateUser): void {
    this.store.dispatch(fromActions.inactivateUserAction(obj));
  }

  onConfirmLock(obj: LockInactivateUser): void {
    this.store.dispatch(fromActions.lockUserAction(obj));
  }

  onConfirmEditUserAccount(obj: EditUserAccount): void {
    this.store.dispatch(fromActions.editUserAccountAction(obj));
  }

  onConfirmAddUser(obj: AddUserAccount): void {
    this.store.dispatch(fromActions.addUserAction(obj));
  }

  private onOrganisationChangeSubscription() {
    this.subscription$ = this.organisationService
    .getChangeOrganisationObservable()
    .pipe(withLatestFrom(this.store.pipe(select(fromSelectors.getOrganisationId))))
    .subscribe(([organisation, organisationId]: [OrganisationModel, number]) => {
      if ((organisation && organisation.organisationId !== organisationId) || (!organisation && organisationId)) {
        this.store.dispatch(new SetToDefaultAction(this.prefix));
        this.store.dispatch(new SetOrganisationIdAction(this.prefix, organisation ? organisation.organisationId : null));
        this.store.dispatch(new fromDtActions.LoadDataAction(this.prefix));
      }
    });
  }
}
