import { Action } from '@ngrx/store';
import { Prefix } from '../reducers';

export const SET_TO_DEFAULT = 'Set to Default';

export class SetToDefaultAction implements Action {
  get type() {
    return `${this.prefix} ${SET_TO_DEFAULT}`;
  }

  constructor(public prefix: Prefix) {
  }
}
