import { createSelector } from '@ngrx/store';
import { getEsmState, State } from '../../reducers';
import { AmountTableState } from './amount-table.reducers';

export const getNettingAmountTableState = createSelector(getEsmState, (state: State) => state.nettingPreview.amountTable);

export const getLoading = createSelector(getNettingAmountTableState, (state: AmountTableState) => state.loading);

export const getData = createSelector(getNettingAmountTableState, (state: AmountTableState) => state.data);
export const getSelected = createSelector(getNettingAmountTableState, (state: AmountTableState) => state.selected);
export const checkAmountPresence = createSelector(getNettingAmountTableState, (state: AmountTableState, props) =>
  state.data.find(element => element?.id === props.id));


