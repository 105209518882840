<div class="panel-box">
  <p-messages [value]="messages$ | async" [closable]="true"></p-messages>
  <cms-datatable *ngIf="(data$ | async) as data"
                 [paginator]="false"
                 [scrollable]="false"
                 [tableColumns]="columns$ | async"
                 [loading]="loading$ | async"
                 [sortField]="(sorting$ | async)?.columnName"
                 [sortOrder]="(sorting$ | async)?.sortOrder"
                 [lazy]="false"
                 [autoLayout]="true"
                 [data]="data.values">
    <ng-template pTemplate="body" let-row="row" let-column="column">
      <td *ngIf="column.type === 'status'" [ngStyle]="column.style">
        <cms-cell-status [column]="column"
                         [row]="row"
                         [editable]="true"
                         emptyTemplate="N/A"
                         (confirm)="onStatusChange($event)">
        </cms-cell-status>
      </td>
    </ng-template>
  </cms-datatable>
</div>

