import { Action } from '@ngrx/store';

export const SET_TO_DEFAULT = '[ECM DASHBOARD] Set To Default';

export class SetToDefaultAction implements Action {
  readonly type = SET_TO_DEFAULT;
}

export type DbActions = SetToDefaultAction;

