import { Injectable } from '@angular/core';
import { ApiRequestService } from '@common/api-request.service';
import { checkErrors, saveBlob, toPayload } from '@common/cms-common.model';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import * as fromActions from './external-master-data.actions';
import { EsmExternalMasterDataPresetValues, TableValues } from 'app/esm/master-data/external-master-data/external-master-data.model';
import * as fromSelectors from './external-master-data.selectors';
import { EsmExternalMasterDataState } from './external-master-data.reducer';
import { select, Store } from '@ngrx/store';
import { ErrorResponse } from '@common/error-response.model';
import { HttpErrorResponse } from '@angular/common/http';
import { EsmBankDetails, EsmExternalFormValues, LoadBankDetailsResponse } from 'app/esm/master-data/master-data.model';
import { propNameToUpdateUrlMap } from '../master-data.effects';

@Injectable()
export class EsmExternalMasterDataEffects {

  private baseUrl = '/api/esm/extmasterdata';


  constructor(private actions: Actions,
      private apiGateway: ApiRequestService,
      private mapper: MessagesMapperService,
      private store: Store<EsmExternalMasterDataState>) {
  }

  loadPresetValues$ = createEffect(() =>
    this.actions.pipe(
      ofType(fromActions.loadPresetValues),
      switchMap(() =>
      this.apiGateway.get(`${this.baseUrl}/loadPresetValues`)
      .pipe(
        switchMap(checkErrors),
          switchMap((response) => {
              const presets: EsmExternalMasterDataPresetValues = {
                  uploadAllowed: response.value?.uploadAllowed,
                  maxUploadSize: response.value?.maxUploadSize
              };
             return [fromActions.setMessages([]), fromActions.loadPresetValuesSuccess(presets)];
          }),
          catchError(error => [
            fromActions.setMessages(this.mapper.toErrorMessages(error, 'Server Error'))
          ])
      ))
  ));

  downloadCsv$ = createEffect(() =>
    this.actions.pipe(
      ofType(fromActions.downloadCsv),
      switchMap(() =>
          this.apiGateway.getBlob(`${this.baseUrl}/download`)
          .pipe(
              switchMap((response: any) => {
                  saveBlob(response);
                  return of(fromActions.setMessages([]));
              }),
              catchError(error => [
                fromActions.setMessages(this.mapper.toErrorMessages(error, 'Server Error'))
                ])
          )
      )
  ));

  uploadCsv$ = createEffect(() =>
    this.actions.pipe(
      ofType(fromActions.uploadCsv),
      switchMap(({ payload }) => {
          const formData = new FormData();
          formData.set('file', payload);
          return this.apiGateway.post(`${this.baseUrl}/uploadCSV`, formData, null)
          .pipe(
            switchMap(checkErrors),
              concatMap(response =>
                  [fromActions.uploadCsvSuccess(),
                    fromActions.loadTableData(),
                    fromActions.setMessages(this.mapper.toSuccessMessages(response.response))]
              ),
              catchError(error => {
                return [
                  fromActions.setUploadLoading(false),
                  fromActions.setMessages(this.mapper.toErrorMessages(error, 'Server Error'))
                ];})
          );
      })
    ));

loadTableData$ = createEffect(() =>
   this.actions.pipe(
    ofType(fromActions.loadTableData, fromActions.pagingAndSorting),
    withLatestFrom(
      this.store.pipe(select(fromSelectors.getTablePaging)),
      this.store.pipe(select(fromSelectors.getTableSorting)),
      this.store.pipe(select(fromSelectors.getTableSearchToken))),
      switchMap(([, paging, sorting, filter]) => {
        return this.apiGateway.post(`${this.baseUrl}/filter`, { paging, filter, sorting})
        .pipe(
          switchMap(checkErrors),
          switchMap(response => {
            const { values, currentPage, count } = response;
            const tableValues: TableValues = {
              data: { values, currentPage, count},
              paging: paging,
              sorting: sorting
            };
            return [fromActions.setTableLoading(false), fromActions.loadTableDataSuccess(tableValues)];
          }),
          catchError(error => [
            fromActions.setTableLoading(false),
            fromActions.setMessages(this.mapper.toErrorMessages(error, 'Server Error'))
          ]));
      })
  )
    );

  deleteMDRecord$ = createEffect(() =>
    this.actions.pipe(
      ofType(fromActions.deleteMDRecord),
      map(toPayload),
      switchMap((value) => {
        return this.apiGateway.delete(`${this.baseUrl}/delete`, value)
        .pipe(
          switchMap(checkErrors),
          switchMap(() => [
            fromActions.setMessages(this.mapper.toSuccessMessages('Successfully deleted')),
            fromActions.closeDialogAction(),
            fromActions.clearDetails(),
            fromActions.loadTableData()
          ]),
          catchError(error => [
            fromActions.setMessages(mapErrors(error, this.mapper))
          ]));
      })
    ));

  loadFormValues$ = createEffect(() =>
    this.actions.pipe(
      ofType(fromActions.loadFormValues, fromActions.bankDetailsPagingAndSorting),
      map(toPayload),
      switchMap((value) => {
        return this.apiGateway.get(`${this.baseUrl}/loadFormValues`, value).pipe(
          switchMap(checkErrors),
          switchMap((response) => {
            return [fromActions.loadFormValuesSuccess(response.value),
              fromActions.setMessages([]),
              fromActions.loadBankDetailsAction(response.value.masterDataId)];}
          ),
          catchError(error => [
            fromActions.setMessages(this.mapper.toErrorMessages(error, 'Server Error'))
          ])
        );
        })
    )
  );

  loadBankDetails$ = createEffect(() =>
    this.actions.pipe(
      ofType(fromActions.loadBankDetailsAction),
      map(toPayload),
      withLatestFrom(
        this.store.pipe(select(fromSelectors.getBankDetailsPaging)),
        this.store.pipe(select(fromSelectors.getBankDetailsSorting))),
      switchMap(([masterDataId, paging, sorting]) =>
        this.apiGateway.post(`${this.baseUrl}/loadBankDetails`, { paging, masterDataId, sorting})
          .pipe(
            switchMap(checkErrors),
            switchMap((data: LoadBankDetailsResponse) => [
              fromActions.loadBankDetailsSuccessAction(data)
            ]),
            catchError(error => [
              fromActions.setDetailsMessages({bankDetails: this.mapper.toErrorMessages(error, 'Server Error')}),
              fromActions.setBankDetailsLoading(false)
            ])
          )
      )
    )
  );

  save$ = createEffect(() =>
    this.actions.pipe(
      ofType(fromActions.persistAction),
      map(toPayload),
      switchMap((request: EsmExternalFormValues) =>
      this.apiGateway.post(`${this.baseUrl}/save`, request)
      .pipe(
        switchMap(checkErrors),
        switchMap((response) => {
          return [fromActions.persistSuccessAction(response.value),
            fromActions.setDetailsMessages({masterData: this.mapper.toSuccessMessages('Successfully done'), bankDetails: []})];
        }),
        catchError(error => [
                fromActions.setDetailsMessages({masterData: mapErrors(error, this.mapper)}),
        ])
      ))
  ));


  update$ = createEffect(() =>
  this.actions.pipe(
    ofType(fromActions.updateAction),
    map(toPayload),
    switchMap((request: EsmExternalFormValues) =>
      this.apiGateway.post(`${this.baseUrl}/update`, request)
        .pipe(
          switchMap(checkErrors),
          switchMap((response) => {
          return [
            fromActions.updateSuccessAction(response.value),
            fromActions.setDetailsMessages({masterData: this.mapper.toSuccessMessages('Successfully done'), bankDetails: []}),
          ];}),
          catchError(error => [
            fromActions.setDetailsMessages({masterData: mapErrors(error, this.mapper)}),
          ])
        )
    ))
  );

  addBankDetail$ = createEffect(() =>
    this.actions.pipe(
      ofType(fromActions.addBankDetailAction),
      map(toPayload),
      switchMap((value) =>
        this.apiGateway.post(`${this.baseUrl}/addBankDetails`, {...value.data, version: value.version, masterDataId: value.masterDataId})
          .pipe(
            switchMap(checkErrors),
            switchMap(() => [
              fromActions.setDetailsMessages({bankDetails: this.mapper.toSuccessMessages('Successfully done'), masterData: []}),
              fromActions.loadBankDetailsAction(value.masterDataId)
            ]),
            catchError(error => [
              fromActions.setDetailsMessages({bankDetails: mapErrors(error, this.mapper)})
            ])
          )
      )
    )
  );

  updateBankDetail$ = createEffect(() =>
    this.actions.pipe(
      ofType(fromActions.updateBankDetailAction),
      map(toPayload),
      switchMap((value) =>
        this.apiGateway.post(`${this.baseUrl}/${propNameToUpdateUrlMap[value.data.name]}`,
          {newValue: value.data.newValue},
          {...value.data, masterDataId: value.masterDataId }).pipe(
          switchMap(checkErrors),
          switchMap(() => [
            fromActions.setDetailsMessages({bankDetails: this.mapper.toSuccessMessages('Bank details successfully updated')}),
            fromActions.loadBankDetailsAction(value.masterDataId)
          ]),
          catchError(error =>
            [
              fromActions.setDetailsMessages({bankDetails: mapErrors(error, this.mapper)}),
            ]
          )
        )
      )
    )
  );

  deleteBankDetail$ = createEffect(() =>
    this.actions.pipe(
      ofType(fromActions.deleteBankDetailAction),
      map(toPayload),
      switchMap((bankDetails: EsmBankDetails) =>
        this.apiGateway.delete(`${this.baseUrl}/deleteBankDetails`,
          {
            bankDetailsId: bankDetails.bankDetailsId,
            masterDataId: bankDetails.masterDataId,
            version: bankDetails.version
          }
        )
          .pipe(
            switchMap(checkErrors),
            switchMap(() => [
              fromActions.setDetailsMessages({bankDetails: this.mapper.toSuccessMessages('Successfully deleted')}),
              fromActions.loadBankDetailsAction(bankDetails.masterDataId)
            ]),
            catchError(error => [
                fromActions.setDetailsMessages({bankDetails: mapErrors(error, this.mapper)})
              ]
            )
          )
      )
    )
  );
}

const checkVatRecordExistError = (error: ErrorResponse): string => {
  const vatIdrecordField = error.fieldValidationMessages && error.fieldValidationMessages.vatIdRecord;
  const isExistError = vatIdrecordField && (vatIdrecordField.includes('master data record already exists') || vatIdrecordField.includes('master data record for the selected VAT ID does not exist'));
  return isExistError ? vatIdrecordField : error.validationFailureMessages ? error.validationFailureMessages[0] : '';
};
const checkStaleErrorExists = (error: ErrorResponse): boolean => {
  return error instanceof HttpErrorResponse ? (error as HttpErrorResponse).status === 409 : false;
};

const mapErrors = (error: ErrorResponse, mapper: MessagesMapperService) => {
  const stale = checkStaleErrorExists(error);
  if(stale) {
    return mapper.toWarningMessages('This master data record is no longer available. Someone else might have updated it.');
  }
  const vatRecordExistError = checkVatRecordExistError(error);
  if (vatRecordExistError) {
    return [mapper.createErrorMessage(vatRecordExistError)];
  }
  return mapper.toErrorMessages(error, 'Server Error');
};
