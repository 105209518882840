
export interface SubscriptionData {
  editAllowed: boolean;
  matchedInvoicePdf: boolean;
  matchedNettingStatementPdf: boolean;
  matchedInvoiceXml: boolean;
  matchedNettingStatementXml: boolean;
  documentType: DocumentType;
  documentSide: DocumentSide;
  esmEmailWhitelistRecipients: WhitelistRecipientsData[];
}

export enum DocumentType {
  OFFICIAL= 'OFFICIAL',
  SHADOW_REVERSE = 'SHADOW_REVERSE',
  BOTH = 'BOTH'
}

export enum DocumentSide {
  OWN_DOCUMENT= 'OWN_DOCUMENT',
  CP_DOCUMENT = 'CP_DOCUMENT',
  BOTH = 'BOTH'
}

export interface WhitelistRecipientsData {
  vatId: string;
  email: string;
  commodity: string;
}

const defaultStyles = {
  'text-align': 'left',
  'text-overflow': 'ellipsis',
  'overflow': 'hidden',
  'white-space': 'nowrap',
  'width':'9%'
};

export const whitelistRecipientsTableColumns = [
  { name: 'vatId', type: 'text', title: 'VAT ID', style: {...defaultStyles}, sortable: false, visible: true },
  { name: 'email',  type: 'text', title: 'Email Address(es)', style: {...defaultStyles},  sortable: false, visible: true },
  { name: 'commodity',   type: 'text', title: 'Commodity', style: {...defaultStyles},  sortable: false, visible: true },
];
