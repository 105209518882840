<div class="datatable-container">
  <p-table [value]="rows"
           [rows]="rowsPerPage"
           [totalRecords]="numberOfTotalRows"
           [rowsPerPageOptions]="rowsPerPageOptions"
           [sortField]="currentSortField"
           dataKey="dataStoreId"
           [lazy]="!!rows?.length"
           [sortOrder]="-1"
           (onLazyLoad)="onLazyLoad($event)"
           [paginator]="records"
           [loading]="loading"
           selectionMode="single"
           [(selection)]="selection"
           [autoLayout]="true"
           [scrollable]="false"
           scrollHeight="520px">
    <ng-template pTemplate="caption">
      <div class="row">
        <div class="col text-start">
          <span class="time-filter-info" *ngIf="filterRangeAsString != null; else elseBlock">Time of receipt filter: {{ filterRangeAsString }}</span>
          <ng-template #elseBlock>
            <span class="time-filter-info">Time of receipt filter: None</span>
          </ng-template>
        </div>
        <div class="col text-end">
          <button pButton type="button" icon="fa fa-download" (click)="loadExcelExport()" label="Export" [disabled]="!hasData()"></button>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <ng-container *ngFor="let col of cols">
          <th *ngIf="col.visible" [pSortableColumn]="col.sortable ? col.name : ''" [ngStyle]="col.style">
            {{ col.title }}
            <p-sortIcon [field]="col.name" *ngIf="col.sortable"></p-sortIcon>
          </th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
      <tr [pSelectableRow]="row">
        <ng-container *ngFor="let col of cols">
          <td *ngIf="col.visible" [ngStyle]="col.style">
            <span *ngIf="col.visible && col.name != 'buttons'" class="truncated-text">{{ row[col.name] }}</span>
            <span *ngIf="col.visible && col.name == 'buttons' && !isAlleged">
              <div class="btn-wrapper">
                <button pButton type="button" icon="fa fa-file-code-o" (click)="loadCpmlXml(row.auditGroupId)" title="XML Export"></button>
              </div>
              <div class="btn-wrapper">
                <a routerLink="/err/cpml_audittrail" title="Audit Trail" [queryParams]="{ auditGroupId: row.auditGroupId, documentId: row.documentId, displayValue: row.documentId, urlPart: 'cpml' }" target="_blank">
                    <button pButton type="button" icon="fa fa-search"></button>
                </a>
              </div>
            </span>
          </td>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="paginatorleft">
      <span class="paginator-info">Showing {{ pagerInfo.fromNumberOfResults }} to {{ pagerInfo.toNumberOfResults }} of {{ pagerInfo.numberOfAllRecords }}</span>
    </ng-template>
  </p-table>
</div>
