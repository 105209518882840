import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DealFinderComponent } from './deal-finder/deal-finder.component';
import { CmsCommonModule } from '@common/cms-common.module';
import { getReducers, reducerToken } from './state/reducers';
import { EcmRoutingModule } from './ecm-routing.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TabViewModule } from 'primeng/tabview';
import { DealFinderEffects } from './state/deal-finder/deal-finder.effects';
import { ReportsEffects } from './state/reports/reports.effects';
import { EcmSettingsProcessEffects } from './state/settings/process/process.effects';
import {
  DealFinderPropertiesFiltersComponent
} from './deal-finder/deal-finder-properties-filters/deal-finder-properties-filters.component';
import { EnterTradeComponent } from './enter-trade/enter-trade.component';
import { ReportsComponent } from './reports/reports.component';
import { EcmSettingsTabPanelComponent } from './settings/ecm-settings-tab-panel/ecm-settings-tab-panel.component';
import { EcmProcessComponent } from './settings/ecm-settings-tab-panel/process/ecm-process.component';
import { EcmProcessEditButtonComponent } from './settings/ecm-settings-tab-panel/process/edit-button/ecm-process-edit-button.component';
import { EcmOfOnCellComponent } from './settings/ecm-settings-tab-panel/process/editable-fields/ecm-of-on-cell.component';
import { EcmSettingsBrokerRestrictionsEffects } from './state/settings/broker-restrictions/broker-restrictions.effects';
import {
  UploadEcmBrokerDialogComponent
} from './settings/ecm-settings-tab-panel/broker-restrictions/upload-ecm-broker-dialog/upload-ecm-broker-dialog.component';
import { BrokerRestrictionsComponent } from './settings/ecm-settings-tab-panel/broker-restrictions/broker-restrictions.component';
import { ProductsEffects } from './state/settings/products/products.effects';
import { ProductsDialogComponent } from './settings/ecm-settings-tab-panel/products/products-dialog/products-dialog.component';
import { EcmDashboardComponent } from './ecm-dashboard/ecm-dashboard.component';
import { EcmViewByFilterComponent } from './ecm-dashboard/ecm-view-by-filter/ecm-view-by-filter.component';
import { EcmDashboardTabComponent } from './ecm-dashboard/ecm-dashboard-tab/ecm-dashboard-tab.component';
import {
  EcmDashboardPropertiesFiltersComponent
} from './ecm-dashboard/ecm-dashboard-properties-filters/ecm-dashboard-properties-filters.component';
import { EcmFiltersEffects } from './state/dashboard/filters/filters.effects';
import { EcmMyFiltersEffects } from './state/dashboard/my-filters/my-filters.effects';
import { EnterTradeFormComponent } from './enter-trade/enter-trade-form/enter-trade-form.component';
import { EcmEnterTradeEffects } from './state/enter-trade/enter-trade.effects';
import { EcmTabEffects } from './state/dashboard/tab/tab.effects';
import { EcmDashboardDealsComponent } from './ecm-dashboard/ecm-dashboard-deals/ecm-dashboard-deals.component';
import { EcmFiltersContainerComponent } from './ecm-dashboard/ecm-filters-container/ecm-filters-container.component';
import { EcmDashboardDealsTabComponent } from './ecm-dashboard/ecm-dashboard-deals-tab/ecm-dashboard-deals-tab.component';
import { EcmDealsEffects } from './state/dashboard/deals/deals.effects';
import { EcmProductsComponent } from './products/ecm-products.component';
import { MarkdownModule } from 'ngx-markdown';
import { EcmProductsAddPanelComponent } from './products/ecm-products-add-panel/ecm-products-add-panel.component';
import { EcmProductsEffects } from './state/products/ecm-products.effects';
import { ProductsComponent } from './settings/ecm-settings-tab-panel/products/products.component';
import { AutoCompleteComponent } from './products/ecm-products-add-panel/auto-complete/auto-complete.component';
import { MonitoringComponent } from './monitoring/monitoring.component';
import { MonitoringEffects } from './state/monitoring/monitoring.effects';
import { ChangeDateComponent } from './monitoring/change-date/change-date.component';
import { NewSnapshotComponent } from './monitoring/new-snapshot/new-snapshot.component';
import { ButtonSpinnerComponent } from './monitoring/button-spinner/button-spinner.component';
import { EcmDealsDatatableComponent } from './ecm-deals-datatable/ecm-deals-datatable.component';
import { ButtonModule } from 'primeng/button';
import { MessagesModule } from 'primeng/messages';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { MenuModule } from 'primeng/menu';
import { DialogModule } from 'primeng/dialog';
import { SharedModule } from 'primeng/api';
import { RadioButtonModule } from 'primeng/radiobutton';
import { KeyFilterModule } from 'primeng/keyfilter';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ReportsDateFiltersComponent } from './reports/reports-date-filters/reports-date-filters.component';
import { DealFinderHtmlComponent } from './deal-finder/deal-finder-html/deal-finder-html.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@NgModule({
  imports: [
    CommonModule,
    CmsCommonModule,
    ProgressSpinnerModule,
    EcmRoutingModule,
    MessagesModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    DropdownModule,
    MultiSelectModule,
    CalendarModule,
    MenuModule,
    DialogModule,
    MarkdownModule.forRoot(),
    StoreModule.forFeature('ecm', reducerToken),
    EffectsModule.forFeature([
      DealFinderEffects,
      ReportsEffects,
      EcmSettingsProcessEffects,
      EcmSettingsBrokerRestrictionsEffects,
      ProductsEffects,
      EcmFiltersEffects,
      EcmMyFiltersEffects,
      EcmEnterTradeEffects,
      EcmTabEffects,
      EcmDealsEffects,
      EcmProductsEffects,
      MonitoringEffects
    ]),
    TabViewModule,
    SharedModule,
    RadioButtonModule,
    KeyFilterModule,
    AutoCompleteModule,
  ],
  declarations: [
    DealFinderComponent,
    DealFinderPropertiesFiltersComponent,
    EnterTradeComponent,
    EnterTradeFormComponent,
    ReportsComponent,
    EcmSettingsTabPanelComponent,
    EcmProcessComponent,
    EcmProcessEditButtonComponent,
    EcmOfOnCellComponent,
    BrokerRestrictionsComponent,
    UploadEcmBrokerDialogComponent,
    EcmProductsComponent,
    ProductsDialogComponent,
    EcmDashboardComponent,
    EcmViewByFilterComponent,
    EcmDashboardTabComponent,
    EcmDashboardPropertiesFiltersComponent,
    EcmDashboardDealsComponent,
    EcmFiltersContainerComponent,
    EcmDashboardDealsTabComponent,
    EcmProductsAddPanelComponent,
    ProductsComponent,
    AutoCompleteComponent,
    MonitoringComponent,
    ChangeDateComponent,
    NewSnapshotComponent,
    ButtonSpinnerComponent,
    EcmDealsDatatableComponent,
    ReportsDateFiltersComponent,
    DealFinderHtmlComponent,
    EcmDashboardComponent,
  ],
  exports: [
    EcmDealsDatatableComponent
  ],
  providers: [
    {
      provide: reducerToken,
      useFactory: getReducers
    }
  ]
})
export class EcmModule { }
