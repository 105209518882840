import * as fromCommon from '@common/state/reducers';
import * as fromActions from './dialog.actions';
import { SET_TO_DEFAULT } from '../mifid-details/mifid-details.action';

export type ERR_MIFID_DIALOGS = 'ADD_NEW' | 'EDIT' | 'CONFIRM_FILE' | 'DISCARD_FILE';

export const errDialogReducer = fromCommon.dialogReducer<ERR_MIFID_DIALOGS>({
  OPEN_DIALOG: fromActions.openDialogAction.type,
  UPDATE_DIALOG: fromActions.updateDialogAction.type,
  CLOSE_DIALOG: fromActions.closeDialogAction.type,
  SET_TO_DEFAULT,
});
