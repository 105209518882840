import { createSelector } from '@ngrx/store';
import { getAdminState, State } from '../reducers';
import { BillingTxReportState } from './billing-tx-report.reducers';

export const getBillingTxReportState = createSelector(getAdminState, (state: State) => state.billingTxReport)
export const getMessages = createSelector(getBillingTxReportState, (state: BillingTxReportState) => state.messages);
export const getOrgGroups = createSelector(getBillingTxReportState, (state: BillingTxReportState) => state.orgGroups);
export const getWarningVisible = createSelector(getBillingTxReportState, (state: BillingTxReportState) => state.generationWarningVisible);
export const getNoResultsVisible = createSelector(getBillingTxReportState, (state: BillingTxReportState) => state.noRecordsWarning);
export const getBillingReportList = createSelector(getBillingTxReportState, (state: BillingTxReportState) => state.billingReportList);
export const getSubmitPayload = createSelector(getBillingTxReportState, (state: BillingTxReportState) => state.submitPayload);
export const getReportGenerateRequest = createSelector(getBillingTxReportState, (state: BillingTxReportState) => state.generateReportId != null);
export const getLatestQuarter = createSelector(getBillingTxReportState, (state: BillingTxReportState) => state.latestQuarter);
