import { Column } from '../../../common/shared/datatable.model';

export namespace ErrRemitDataTableRemitFundamentalsConfig {

  const defaultStyles = {
    'text-align': 'left',
    'text-overflow': 'ellipsis',
    'overflow': 'hidden',
    'white-space': 'nowrap',
    'height': '27px'
  };


  export const TABLE_COLUMNS: { [name: string]: Column } = {
    // Keep this and the following files aligned.
    // app.bsl/de.ponton.cmh.bsl.bslimpl/src/de/ponton/cmh/bsl/bslimpl/internal/service/userinterfacecustomisation/ErrRemitFundamentalsPersonalColumnEnum.java
    // app.gui/de.ponton.cmh.err.ui.data/src/de/ponton/cmh/err/ui/data/uio/remit/transactions/fundamentals/ErrRemitFundamentalsTransactionsUIO.properties
    // app.gui/de.ponton.cmh.err.ui.components/src/de/ponton/cmh/err/ui/components/remit/transactions/fundamentals/columnorder/ErrRemitFundamentalsColumnOrderDataPanel.properties

    creationTimestamp: { title: 'Received by eRR', style: { ...defaultStyles} },
    lastUpdate: { title: 'Last Update', style: { ...defaultStyles} },
    senderName: { title: 'Sender Name', style: { ...defaultStyles} },
    documentType: { title: 'Document Type', style: { ...defaultStyles} },
    reportSubtype: { title: 'Report Subtype', style: { ...defaultStyles} },
    buttons: { title: 'Button', style: { ...defaultStyles,'text-align': 'center' }, sortable: false, hiddenTitle: true },
    state: { title: 'State', style: { ...defaultStyles} },
    referenceIdentifier: { title: 'Reference ID', style: { ...defaultStyles}},
    facilityCode: { title: 'Facility Code', style: { ...defaultStyles} },
    mpPartyCode: { title: 'MP or Operator Code', style: { ...defaultStyles} },
    startTimestampUtc: {
      title: 'Gas Day Start (UTC)',
      longTitle: 'Gas Day (or Unavailability) Start',
      style: { ...defaultStyles}
    },
    endTimestampUtc: {
      title: 'Gas Day End (UTC)',
      longTitle: 'Gas Day (or Unavailability) End',
      style: { ...defaultStyles}
    },
    storageValue: { title: 'Storage', longTitle: 'Storage (Inventory)', style: { ...defaultStyles,'text-align': 'right' } },

    storageUnit: { title: 'Storage Unit', longTitle: 'Storage (Inventory) Unit', style: { ...defaultStyles} },
    facilityName: { title: 'Facility Name', style: { ...defaultStyles} },
    filenameInbound: {
      title: 'Filename (Input File)',
      style: { ...defaultStyles, 'cursor': 'help', 'text-align': 'left' },
      editable: true
    },
    filenameOutbound: {
      title: 'Filename (Reported)',
      style: { ...defaultStyles, 'cursor': 'help', 'text-align': 'left' },
      editable: true
    },
    injectionValue: { title: 'Injection', style: { ...defaultStyles, 'text-align': 'right' } },
    injectionUnit: { title: 'Injection Unit', style: { ...defaultStyles, 'text-align': 'left' } },
    mpName: { title: 'MP or Operator Name', style: { ...defaultStyles, 'text-align': 'left' } },
    withdrawalValue: { title: 'Withdrawal', style: { ...defaultStyles, 'text-align': 'right' } },
    withdrawalUnit: { title: 'Withdrawal Unit', style: { ...defaultStyles, 'text-align': 'left' } },

    // always hidden
    // dataStoreId: { title: 'DataStore Id', style: { 'width': '120px' }, visible: false },
  };
}
