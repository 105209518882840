import { createSelector } from '@ngrx/store';
import { getProblemSolvingState, State } from '../reducers';
import { ErrSendingFailuresState } from './err-sending-failures.reducers';
import * as fromReducer from './err-sending-failures.reducers';
import {
  ErrSendingFailuresSelected,
  ErrSendingFailuresValues
} from '../../err-sending-failures/err-sending-failures.model';

export const getErrSendingFailuresState = createSelector(getProblemSolvingState, (state: State) => state.errSendingFailures);
export const getOrganisationId = createSelector(getErrSendingFailuresState, (state: ErrSendingFailuresState) => state.organisationId);
export const getInitializing = createSelector(getErrSendingFailuresState, fromReducer.getInitializing);
export const getMessages = createSelector(getErrSendingFailuresState, fromReducer.getMessages);
export const getFilters = createSelector(getErrSendingFailuresState, fromReducer.getFilters);
export const getProcessesNames = createSelector(getErrSendingFailuresState, fromReducer.getProcessesNames);
export const getData = createSelector(getErrSendingFailuresState, fromReducer.getData);
export const getSelectedItems = createSelector(getErrSendingFailuresState, fromReducer.getSelectedItems);
export const getLoadingTable = createSelector(getErrSendingFailuresState, fromReducer.getLoadingTable);
export const getDialog = createSelector(getErrSendingFailuresState, fromReducer.getDialog);

export const getProcessesOptions = createSelector(getProcessesNames, (values: string[] = []): { label: string, value: string }[] =>
  values.map((item: string) => ({ label: item, value: item })));

export const isStartedState = createSelector(getSelectedItems,
  (value: ErrSendingFailuresSelected): boolean =>
    Object.values(value).some((item: ErrSendingFailuresValues) => item.state === 'START')
  );

export const getIsSelected = createSelector(getSelectedItems,
  (value: ErrSendingFailuresSelected): boolean => !(Object.keys(value) && Object.keys(value).length));
