<form class="panel-box container" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="row flex align-items-end gutter-6">
    <div class="col-xl-4 col-12">
      <label class="margin-0 text-medium">Queue:</label>
      <p-dropdown
        *ngIf="queueValueOptions"
        id="exceptions-queue"
        [options]="queueValueOptions"
        formControlName="queue"
        [style]="{width: '100%'}">
      </p-dropdown>
    </div>
    <div class="col-xl-3 col-6">
      <label class="margin-0 text-medium">From:</label>
      <p-calendar
        id="exceptions-calendar-from"
        placeholder="From Date"
        formControlName="from"
        [maxDate]="todayMaxDate"
        [showIcon]="true"
        [monthNavigator]="true"
        [yearNavigator]="true"
        dateFormat="dd/mm/yy"
        cmsDateRestriction>
      </p-calendar>
    </div>
    <div class="col-xl-3 col-6">
      <label class="margin-0 text-medium inline-block">To:</label>
      <p-calendar
        id="exceptions-calendar-to"
        placeholder="To Date"
        formControlName="to"
        [maxDate]="todayMaxDate"
        [showIcon]="true"
        [monthNavigator]="true"
        [yearNavigator]="true"
        dateFormat="dd/mm/yy"
        cmsDateRestriction>
      </p-calendar>
    </div>
    <div class="col-xl-2 col-12">
      <button [disabled]="disabled" id="btn-search-date-filter" type="submit" class="btn form-control padding-6 margin-top-9">Search</button>
    </div>
  </div>
</form>
