<cms-datatable *ngIf="(data$ | async) as data"
               [tableColumns]="dataTableColumns"
               [currentPage]="(paging$ | async).page"
               [rows]="(paging$ | async).entriesPerPage"
               [sortField]="(sorting$ | async).columnName"
               [sortOrder]="(sorting$ | async).sortOrder"
               [loading]="loading$ | async"
               [data]="data.values"
               [totalRecords]="data.count"
               [autoLayout]="true"
               [scrollable]="false"
               [lazy]="!!(data.values && data.values.length)"
               (lazyLoad)="onLazyLoad($event)">
  <ng-template pTemplate="body" let-row="row" let-column="column">
    <td *ngIf="column.type === 'email-ref'" [ngStyle]="column.style">
      <a href="mailto: {{row[column.name]}}">{{row[column.name]}}</a>
    </td>
  </ng-template>
</cms-datatable>
