import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Title } from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';
import { Message } from 'primeng/api';
import { Column } from '../../../common/shared/datatable.model';
import { DatatableData, SortingFilter } from '../../../common/shared/results.model';
import { MailAlert } from '../mail-alerts.model';
import { OrganisationService } from '../../../common/organisation.service';
import * as fromSelectors from '../../state/mail-alerts/global/global.selectors';
import * as fromActions from '../../state/mail-alerts/mail-alerts.actions';
import { EditableCell } from '../../../common/datatable/cell-editor/cell-editor.model';
import { withLatestFrom } from 'rxjs/operators';
import { OrganisationModel } from '../../../common/organisation.model';
import { Prefix } from '../../state/mail-alerts/mail-alerts.service';
import { MailAlertState } from '../../state/mail-alerts/mail-alerts.reducer';

@Component({
  selector: 'cms-global',
  templateUrl: './global.component.html',
  styleUrls: ['./global.component.scss']
})
export class GlobalComponent implements OnInit, OnDestroy {
  data$: Observable<DatatableData<MailAlert[]>>;
  loading$: Observable<boolean>;
  sorting$: Observable<SortingFilter>;
  messages$: Observable<Message[]>;
  columns$: Observable<Column[]>;
  subscription$: Subscription;
  prefix: Prefix = '[MAIL ALERTS GLOBAL]';

  constructor(private store: Store<MailAlertState>,
              private titleService: Title,
              private organisationService: OrganisationService) {
    this.columns$ = this.store.pipe(select(fromSelectors.getColumns));
    this.data$ = this.store.pipe(select(fromSelectors.getData));
    this.loading$ = this.store.pipe(select(fromSelectors.getLoading));
    this.messages$ = this.store.pipe(select(fromSelectors.getMessages));
    this.sorting$ = this.store.pipe(select(fromSelectors.getSorting));
  }

  onStatusChange(cell: EditableCell): void {
    this.store.dispatch(new fromActions.UpdateStatusAction(this.prefix, cell));
  }

  ngOnInit(): void {
    this.store.dispatch(new fromActions.SetOrganisationIdAction(this.prefix, this.organisationService.getCachedOrganisationId()));
    this.store.dispatch(new fromActions.LoadDataAction(this.prefix));
    this.titleService.setTitle('Mail Alerts');

    this.subscription$ = this.organisationService
      .getChangeOrganisationObservable()
      .pipe(
        withLatestFrom(this.store.pipe(select(fromSelectors.getOrganisationId))))
      .subscribe(((data: [OrganisationModel, number]) => {
        const [organisation, organisationId] = data;
        if ((organisation && organisation.organisationId !== organisationId) || (!organisation && organisationId)) {
          this.store.dispatch(new fromActions.SetToDefaultAction(this.prefix));
          this.store.dispatch(new fromActions.SetOrganisationIdAction(this.prefix, organisation ? organisation.organisationId : null));
          this.store.dispatch(new fromActions.LoadDataAction(this.prefix));
        }
      }));
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}
