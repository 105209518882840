<div style="max-width: 585px;" class="option-details" *ngIf="visible">
  <div class="form-title">Contract | Option Details</div>
  <div class="form-body" [formGroup]="formGroup">
    <div class="row gutter-0">
      <div class="col-5 label">Option Style <span class="text-required">*</span></div>
      <div id="remit-trade-entry-optionStyle" class="col-7 ps-2 !pe-[42px]">
        <p-dropdown formControlName="optionStyle" [options]="optionStyles" [style]="{'width': '100%'}" [styleClass]="'margin-3 ' + getValidationErrorStyleClass('optionStyle')" ></p-dropdown>
      </div>
      <span *ngIf="formGroup.controls['optionStyle'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0">
      <div class="col-5 label">Option Type <span class="text-required">*</span></div>
      <div id="remit-trade-entry-optionType" class="col-7 ps-2 !pe-[42px]">
        <p-dropdown formControlName="optionType" [options]="optionTypes" [style]="{'width': '100%'}" [styleClass]="'margin-3 ' + getValidationErrorStyleClass('optionType')" ></p-dropdown>
      </div>
      <span *ngIf="formGroup.controls['optionType'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0">
      <div class="col-5 label">Strike Price <span class="text-required">*</span></div>
      <div class="col-6">
        <input id="remit-trade-entry-strikePrice" type="text" cmsDigitOnly class="form-control padding-6 text-medium margin-3" formControlName="strikePrice" [ngClass]="{'error':formGroup.controls['traderId'].hasError('error')}">
      </div>
      <div class="col-1 padding-0 padding-top-9">
        {{currency}}
      </div>
      <span *ngIf="formGroup.controls['strikePrice'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0">
      <div class="col-5 label">Exercise Date<span class="text-required"> *</span></div>
      <div class="col-7 ps-2 !pe-[42px]">
        <span id="remit-trade-entry-exerciseDate" class="inline-block padding-right-3 margin-3">
            <p-calendar
              formControlName="exerciseDate"
              [showIcon]="true"
              [monthNavigator]="true"
              [yearNavigator]="true"
              dateFormat="dd/mm/yy"
              [style]="{ 'width': '125px'}"
              [styleClass]="getValidationErrorStyleClass('exerciseDate')"
              cmsDateRestriction>
            </p-calendar>
        </span>
      </div>
      <span *ngIf="formGroup.controls['exerciseDate'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
  </div>
</div>
