import { createAction, union } from '@ngrx/store';
import { EcmAddProduct, EcmProductsDataItem, EcmProductsPresets } from '../../products/ecm-products';
import { Message } from 'primeng/api';

export const addProductAction = createAction('[ECM PRODUCTS] Add Products', (payload: EcmAddProduct) => ({payload}));
export const cleanAddProductAction = createAction('[ECM PRODUCTS] Clean Add Products');
export const loadPresetsAction = createAction('[ECM PRODUCTS] Load Presets');
export const loadDataAction = createAction('[ECM PRODUCTS] Load Data Action');
export const loadDataActionSuccess = createAction('[ECM PRODUCTS] Load Data Action Success',
  (payload: EcmProductsDataItem[]) => ({payload}));
export const loadPresetsSuccessAction = createAction('[ECM PRODUCTS] Load Presets Success',
  (payload: EcmProductsPresets) => ({payload}));
export const setToDefaultAction = createAction('[ECM PRODUCTS] Set To Default');
export const setMessagesAction = createAction('[ECM PRODUCTS] Set Messages', (payload: Message[]) => ({payload}));
export const downloadAction = createAction('[ECM PRODUCTS] Download');
export const deleteAction = createAction('[ECM PRODUCTS] Delete Product', (payload: {productId}) => ({payload}));

const action = union({
  addProductAction,
  loadPresetsAction,
  setToDefaultAction,
  downloadAction,
  setMessagesAction,
  loadPresetsSuccessAction,
  loadDataAction,
  loadDataActionSuccess,
  cleanAddProductAction,
  deleteAction
});

export type EcmProductsActions = typeof action;
