import * as fromEsmInvoiceSettingsReducers from './invoice/invoice-settings.reducers';
import { esmInvoiceSettingsReducer } from './invoice/invoice-settings.reducers';
import * as fromEsmNettingSettingsReducers from './netting/netting-settings.reducers';
import * as fromEsmToleranceSettingsReducers from './tolerance/tolerance-settings.reducers';
import { esmNettingSettingsReducer } from './netting/netting-settings.reducers';
import * as fromActions from './invoice/invoice-settings.actions';
import * as fromAddPanelActions from './invoice/add-panel/add-panel.actions';
import * as fromTableActions from './invoice/table/table.actions';
import * as fromNettingActions from './netting/netting-settings.actions';
import * as fromToleranceActions from './tolerance/tolerance-settings.actions';
import { esmToleranceSettingsReducer } from './tolerance/tolerance-settings.reducers';
import { esmSubscriptionSettingsReducer } from './subscription/subscription-settings.reducers';
import { esmEmailWhitelistReducer } from './whitelist/whitelist.reducers';
import * as fromSubscriptionReducers from './subscription/subscription-settings.reducers';
import * as fromEmailWhitelistReducers from './whitelist/whitelist.reducers';
import { EsmEmailWhitelistAction } from './whitelist/whitelist.actions';
import {  SubscriptionSettingsAction } from './subscription/subscription-settings.actions';

export interface EsmSettingsState {
  invoice: fromEsmInvoiceSettingsReducers.EsmInvoiceSettingsState;
  netting: fromEsmNettingSettingsReducers.EsmNettingSettingsState;
  tolerance: fromEsmToleranceSettingsReducers.EsmToleranceSettingsState;
  subscription: fromSubscriptionReducers.SubscriptionSettingsState;
  whitelist: fromEmailWhitelistReducers.EsmEmailWhitelistState;
}

export const initialState: EsmSettingsState = {
  invoice: fromEsmInvoiceSettingsReducers.initialState,
  netting: fromEsmNettingSettingsReducers.initialState,
  tolerance: fromEsmToleranceSettingsReducers.initialState,
  subscription: fromSubscriptionReducers.initialState,
  whitelist: fromEmailWhitelistReducers.initialState
};



export function esmSettingsReducer(
  state: EsmSettingsState = { ...initialState },
  action: fromActions.EsmInvoiceSettingsAction
    & fromAddPanelActions.EsmSettingsAddPanelActions
    & fromTableActions.EsmSettingsTableActions
    & fromNettingActions.EsmNettingSettingsActions
    & fromToleranceActions.EsmToleranceSettingsAction
    | (EsmEmailWhitelistAction & SubscriptionSettingsAction)): EsmSettingsState {

  return {
    ...state,
    invoice: esmInvoiceSettingsReducer(state.invoice, action),
    netting: esmNettingSettingsReducer(state.netting, action),
    tolerance: esmToleranceSettingsReducer(state.tolerance, action),
    subscription:esmSubscriptionSettingsReducer(state.subscription, action),
    whitelist:esmEmailWhitelistReducer(state.whitelist, action)
  };
}
