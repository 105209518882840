export interface MyFilter {
  defaultFilter: boolean;
  filterName: string;
}

export type MY_FILTERS_DIALOGS = 'MANAGE_FILTERS' | 'SAVE_CURRENT';

export const AllDealsMyFilterName = 'All Deals';


