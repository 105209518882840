<div *ngIf="initializing > 0">
<div class="panel-box margin-left-50 margin-right-50 margin-top-15 margin-bottom-15" *ngIf="showModifyForm">
  <h1>Modify Trade</h1>

  <cms-info ngPreserveWhitespaces>
    <!--@formatter:off (markdown rendering breaks, when the following tags are formatted)-->
      __Confirm you wish to report a trade modification to ARIS.__

      Please note:
      * The field "Contract Date" signifies on which date the trade modification becomes effective. It must be on or after the last reported contract date.
      * You cannot change the "Market Participant" or "UTI" field of a reported trade. ACER requires you to delete and re-report trades with incorrect data in these fields.
      * Your are free to modify all remaining fields.

    Press "Modify Trade" to submit the modification or close this tab to abort.

    <!--@formatter:on-->
  </cms-info>
  <div id="divRemitTradeModifyFeedback">
    <p-messages id="modify-feedback-messages" [(value)]="messages" [closable]="true"></p-messages>
  </div>
  <div class="panel-box">
    <ng-container>
      <div class="relative margin-center" style="max-width: 1200px;">
        <div class="component-box">
          <cms-remit-trade-entry-table2-references [formGroup]="tradeForm" [data]="referenceModel" [readonly]="true"></cms-remit-trade-entry-table2-references>
          <cms-remit-trade-entry-table2-product-details [formGroup]="tradeForm" [data]="productDetailsModel"></cms-remit-trade-entry-table2-product-details>
          <cms-remit-trade-entry-table2-reporting-counterparty [formGroup]="tradeForm" [data]="reportingCounterpartyModel" [modifyTrade]="true"></cms-remit-trade-entry-table2-reporting-counterparty>
          <cms-remit-trade-entry-table2-other-counterparty [formGroup]="tradeForm" [data]="otherMarketParticipants" [modifyTrade]="true"></cms-remit-trade-entry-table2-other-counterparty>
          <cms-remit-trade-entry-table2-delivery [formGroup]="tradeForm" [data]="deliveryModel"></cms-remit-trade-entry-table2-delivery>
          <cms-remit-trade-entry-table2-option-details [formGroup]="tradeForm" [data]="optionDetails"></cms-remit-trade-entry-table2-option-details>
        </div>
        <div class="component-box">
          <cms-remit-trade-entry-table2-volume [formGroup]="tradeForm" [data]="volumeModel"></cms-remit-trade-entry-table2-volume>
          <cms-remit-trade-entry-table2-volume-optionality-intervals [formGroup]="tradeForm" [data]="volumeOptionalityIntervals"></cms-remit-trade-entry-table2-volume-optionality-intervals>
          <cms-remit-trade-entry-table2-price [formGroup]="tradeForm" [data]="priceModel"></cms-remit-trade-entry-table2-price>
          <cms-remit-trade-entry-table2-fixing-index [formGroup]="tradeForm" [data]="fixingIndices"></cms-remit-trade-entry-table2-fixing-index>
        </div>
      </div>

      <div class="col-12 text-center">
        <button id="btnRemitTradeCancelSubmit" type="button" (click)="submit()" class="btn margin-3 padding-6" [disabled]="!submitEnabled">
          Modify Trade
          <p-progressSpinner class="margin-top-9" *ngIf="isInProgress()" [style]="{width: '15px', height: '15px'}" strokeWidth="8"></p-progressSpinner>
        </button>
      </div>

    </ng-container>
  </div>
</div>
<div class="panel-box margin-left-50 margin-right-50 margin-top-15 margin-bottom-15" *ngIf="isDisabled()">
  <h1>Trade cannot be modified.</h1>
  <p-messages [(value)]="messages" [closable]="false"></p-messages>
</div>
</div>
