import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SelectItemGroup } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RemitTradeEntryTable1ModifyService } from '../remit-trade-entry-table1-modify.service';
import { FixingIndexTradeEntryModel } from './remit-trade-entry-table1-modify-fixing-index.component';

@Component({
  selector: 'cms-remit-trade-entry-table1-modify-commercials',
  templateUrl: './remit-trade-entry-table1-modify-commercials.component.html',
  styleUrls: ['./remit-trade-entry-table1-modify-commercials.component.scss']
})
export class RemitTradeEntryTable1ModifyCommercialsComponent implements OnInit, OnDestroy {

  @Input()
  formGroup: UntypedFormGroup;

  onDestroy$ = new Subject();

  quantityUnits: SelectItemGroup[];
  totalQuantityUnits: SelectItemGroup[];
  currencies: SelectItemGroup[];

  showPrice: boolean = false;
  showQuantity: boolean = false;
  showNotionalQuantity: boolean = false;
  showNotionalAmount: boolean = false;

  validationErrors: string[] = [];

  commercialModel: CommercialTradeEntryModel;

  constructor(private table1ModifyService: RemitTradeEntryTable1ModifyService) {
  }

  ngOnInit() {
    this.formGroup.addControl('quantity', new UntypedFormControl(''));
    this.formGroup.addControl('quantityUnit', new UntypedFormControl(''));
    this.formGroup.addControl('price', new UntypedFormControl(''));
    this.formGroup.addControl('priceCurrency', new UntypedFormControl(''));
    this.formGroup.addControl('totalNotionalQuantity', new UntypedFormControl(''));
    this.formGroup.addControl('totalNotionalQuantityUnit', new UntypedFormControl(''));
    this.formGroup.addControl('notionalAmount', new UntypedFormControl(''));
    this.formGroup.addControl('notionalAmountCurrency', new UntypedFormControl(''));

    this.table1ModifyService.onTradeEntryModelChange().pipe(takeUntil(this.onDestroy$)).subscribe(tradeEntryModel => {
      if (tradeEntryModel) {
        this.commercialModel = tradeEntryModel.commercialResponseModel;
        this.initFormValues();
      }
    });

    this.table1ModifyService.onTradeEntrySetupDataChange().pipe(takeUntil(this.onDestroy$)).subscribe(setupData => {
      if (setupData) {
        this.initFormSetup(setupData.commercialValueSetsResponseModel);
      }
    });

    this.table1ModifyService.onValidationErrors().pipe(takeUntil(this.onDestroy$)).subscribe(validationErrors => {
      this.handleServerValidationErrors(validationErrors);
    });

  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
    this.reset();
  }

  private initFormValues(): void {
    if (this.commercialModel) {

      this.reset();

      this.formGroup.controls['quantity'].setValue(this.commercialModel.quantityValue);
      this.formGroup.controls['quantityUnit'].setValue(this.commercialModel.quantityUnit);

      if (this.commercialModel.quantityValue !== null) {
        this.showQuantity = true;
      }

      this.formGroup.controls['price'].setValue(this.commercialModel.priceValue);
      this.formGroup.controls['priceCurrency'].setValue(this.commercialModel.priceUnit);

      if (this.commercialModel.priceValue !== null) {
        this.showPrice = true;
      }

      this.formGroup.controls['totalNotionalQuantity'].setValue(this.commercialModel.totalQuantityValue);
      this.formGroup.controls['totalNotionalQuantityUnit'].setValue(this.commercialModel.totalQuantityUnit);

      if (this.commercialModel.totalQuantityValue !== null) {
        this.showNotionalQuantity = true;
      }

      this.formGroup.controls['notionalAmount'].setValue(this.commercialModel.notionalAmountValue);
      this.formGroup.controls['notionalAmountCurrency'].setValue(this.commercialModel.notionalAmountUnit);

      if (this.commercialModel.notionalAmountValue !== null) {
        this.showNotionalAmount = true;

      }
    }
  }

  private initFormSetup(commercialSetupData: any) {
    if (commercialSetupData) {
      this.quantityUnits = this.table1ModifyService.getGroupedValues(commercialSetupData.quantityUnits);
      this.totalQuantityUnits = this.table1ModifyService.getGroupedValues(commercialSetupData.totalQuantityUnits);
      this.currencies = this.table1ModifyService.getGroupedValues(commercialSetupData.currencies);
    }
  }

  private reset(): void {
    this.formGroup.controls['quantity'].reset();
    this.formGroup.controls['quantityUnit'].reset();
    this.formGroup.controls['price'].reset();
    this.formGroup.controls['priceCurrency'].reset();
    this.formGroup.controls['totalNotionalQuantity'].reset();
    this.formGroup.controls['totalNotionalQuantityUnit'].reset();
    this.formGroup.controls['notionalAmount'].reset();
    this.formGroup.controls['notionalAmountCurrency'].reset();

    this.showQuantity = false;
    this.showPrice = false;
    this.showNotionalAmount = false;
    this.showNotionalQuantity = false;
  }

  appendValidationErrorClass(fieldName: string): string {
    if (this.validationErrors[fieldName]) {
      return 'error';
    }
  }

  private getFieldLabel(fieldName: string): string {
    const names: any = [];
    names['quantity'] = 'Quantity';
    names['price'] = 'Price';
    names['notionalAmount'] = 'Notional Amount';
    names['totalNotionalQuantity'] = 'Total Notional Quantity';

    return names[fieldName];
  }

  private handleServerValidationErrors(errors: any) {
    this.validationErrors = [];
    if (errors) {
      this.validationErrors['quantity'] = errors.quantity;
      this.validationErrors['price'] = errors.price;
      this.validationErrors['notionalAmount'] = errors.notionalAmount;
      this.validationErrors['totalNotionalQuantity'] = errors.totalNotionalQuantity;
    }
  }
}

export interface CommercialTradeEntryModel {
  priceUnit: string;
  priceValue: number;
  quantityUnit: string;
  quantityValue: number;
  totalQuantityUnit: string;
  totalQuantityValue: number;
  notionalAmountUnit: string;
  notionalAmountValue: number;
  fixingIndexModels?: FixingIndexTradeEntryModel[];
}
