import {
  AfterViewInit,
  Component,
  ElementRef, EventEmitter,
  Input, OnChanges, OnDestroy,
  OnInit, Output,
  QueryList, SimpleChange,
  ViewChildren
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Message } from 'primeng/api';
import { Column } from '../../shared/datatable.model';
import { CellEditorDirective } from './cell-editor.directive';
import { EditableCell, EditableData, EditableDataItem } from './cell-editor.model';

@Component({
  selector: 'cms-cell-editor',
  templateUrl: './cell-editor.component.html',
  styleUrls: ['./cell-editor.component.scss'],
})
export class CellEditorComponent implements OnChanges, OnInit, OnDestroy, AfterViewInit {
  @Input() row: {[key: string]: EditableDataItem<any>};
  @Input() column: Column;
  @Input() selected: EditableData;
  @Input() editable = false;
  @Input() messages: Message[];
  @Input() value: any;
  @Input() maxValueLength: number;
  @Output() editComplete = new EventEmitter<EditableCell>();
  @Output() initEdit = new EventEmitter<EditableCell>();
  @Output() cancelEdit = new EventEmitter();
  @ViewChildren('value', { read: ElementRef }) inputEl$: QueryList<ElementRef>;
  form: UntypedFormGroup;
  edit = false;
  clickSubscription$: Subscription;
  keydownSubscription$: Subscription;
  errors: {[key: string]: ValidationErrors} = {};

  get isCurrentElementSelected(): boolean {
    return this.selected.data === this.row && this.selected.column === this.column;
  }

  constructor(public editableColumn: CellEditorDirective) { }

  setToDefault(): void {
    this.form.setValue({
      value: this.value
    });
    this.errors = {};
    this.edit = false;
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if ((changes.row || changes.column) && this.form && this.column && this.row) {
      this.setToDefault();
    }

    if (changes.selected && this.edit && !this.isCurrentElementSelected) {
      this.setToDefault();
    }

    if (this.edit && changes.messages && this.messages.length) {
      this.errors = { value: { 'server': true }};
    }
  }

  ngAfterViewInit(): void {
    this.inputEl$.changes.subscribe((elementRef) => {
      if (elementRef.length) {
        elementRef.first.nativeElement.focus();
      }
    });
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      value: new UntypedFormControl(this.value || ''),
    });

    this.clickSubscription$ = this.editableColumn.click$.subscribe(() => {
      setTimeout(() => {
        if (this.editable && !this.edit) {
          this.initEdit.emit({
            column: this.column,
            data: this.row,
            newValue: this.form.value.value
          });
          this.edit = true;
        }
      });
    });

    this.keydownSubscription$ = this.editableColumn.keydown$
      .subscribe((event: KeyboardEvent) => {
        if (this.editable && this.edit) {
          // enter
          if (event.keyCode === 13) {
            this.completeEditing();
            event.preventDefault();
          } else if (event.keyCode === 27) {
            this.setToDefault();
            this.cancelEdit.emit();

            event.preventDefault();
          }
        }
      });
  }

  onBlur(): void {
    this.setToDefault();
    this.cancelEdit.emit();
  }

  ngOnDestroy(): void {
    if (this.clickSubscription$) {
      this.clickSubscription$.unsubscribe();
    }
    if (this.keydownSubscription$) {
      this.keydownSubscription$.unsubscribe();
    }
  }

  private completeEditing(): void {
    const value = this.form.controls['value'].value;
    this.form.setValue({
      value: value ? value.trim() : value
    });
    if (this.value !== this.form.controls['value'].value) {
      this.editComplete.emit({ column: this.column, data: this.row, newValue: this.form.value.value });
    } else {
      this.setToDefault();
      this.editComplete.emit(null);
    }
  }
}
