<p-dialog *ngIf="display"
          header="{{'Delete Entry: ' + acerCode}}"
          [visible]="display"
          [modal]="true"
          (visibleChange)="onVisibleChange($event)"
          [style]="{width: '400px', minWidth: '400px'}">
  <div class="warning-container">
    <i class="fa fa-exclamation-triangle"></i>
    <span class="text-bold">Confirm you wish to delete this entry.</span>
    <span class="block">ElCom Reporting for this entity will stop with immediate effect.</span>
  </div>
  <p-footer>
    <button id="elcom-whitelist-dialog-cancel-btn" class="btn margin-right-3" type="button" (click)="onCancel()">Cancel</button>
    <button id="elcom-whitelist-dialog-confirm-btn" class="btn" type="button" (click)="onConfirm()">Yes - Delete</button>
  </p-footer>
</p-dialog>
