<div style="max-width: 585px;" class="contract-delivery" *ngIf="bilateral">
  <div class="form-title">Contract | Delivery</div>
  <div class="form-body" [formGroup]="formGroup">
    <div class="row gutter-0 !me-[40px]">
      <div class="col-5 label">Load Type</div>
      <div id="remit-trade-entry-loadType" class="col-7 ps-2 pe-1">
        <p-dropdown formControlName="loadType" [options]="loadTypes" [style]="{'width': '100%'}" [styleClass]="'margin-3 '" [group]="true" ></p-dropdown>
      </div>
      <span *ngIf="formGroup.controls['loadType'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div formArrayName="deliveryZones">
      <div class="row gutter-0 !me-[40px] bottom-row-border" *ngFor="let deliveryZone of deliveryZones; let i = index" [formGroupName]="i">
        <div class="col-5 label">Delivery Point or Zone {{i +1}} <span class="text-required" *ngIf="i === 0">*</span></div>
        <div id="remit-trade-entry-deliveryType" class="col-7 ps-2 pe-1">
          <p-dropdown formControlName="deliveryPointOrZone" [filter]="true" [filterFields]="['label']" [options]="deliveryPointOrZones" [style]="{'width': '100%'}" [styleClass]="'margin-3 ' + appendValidationErrorClassOfArray(i, 'deliveryPointOrZone' )" [group]="true"></p-dropdown>
        </div>
        <!--<span *ngIf="formGroup.controls['deliveryPointOrZone'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>-->

      </div>
    </div>
    <div class="row gutter-0 !me-[40px]">
      <div class="col-5 label"></div>
      <div class="col-7 ps-2 pe-1">
        <button type="button" class="btn add-remove-line" (click)="add('')" [disabled]="disableAddButton">Add</button>
        <button type="button" class="btn add-remove-line" (click)="remove()" [disabled]="disableRemoveButton">Remove</button>
      </div>
    </div>
    <div class="row gutter-0 !me-[40px]">
      <div class="col-5 label">Delivery Start <span class="text-required">*</span></div>
      <div class="col-7 ps-2 pe-1">
                <span id="remit-trade-entry-deliveryStartDate" class="inline-block padding-right-3 margin-3">
                    <p-calendar
                      formControlName="deliveryStartDate"
                      [showIcon]="true"
                      [monthNavigator]="true"
                      [yearNavigator]="true"
                      dateFormat="dd/mm/yy"
                      [style]="{ 'width': '125px'}"
                      [styleClass]="appendValidationErrorClass('deliveryStartDate')"
                      cmsDateRestriction>
                    </p-calendar>
                </span>
      </div>
      <span *ngIf="appendValidationErrorClass('deliveryStartDate')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0 !me-[40px]">
      <div class="col-5 label">Delivery End <span class="text-required">*</span></div>
      <div class="col-7 ps-2 pe-1">
                <span id="remit-trade-entry-deliveryEndDate" class="inline-block padding-right-3 margin-3">
                    <p-calendar
                      formControlName="deliveryEndDate"
                      [showIcon]="true"
                      [monthNavigator]="true"
                      [yearNavigator]="true"
                      dateFormat="dd/mm/yy"
                      [style]="{ 'width': '125px'}"
                      [styleClass]="appendValidationErrorClass('deliveryEndDate')"
                      cmsDateRestriction>
                    </p-calendar>
                </span>
      </div>
      <span *ngIf="appendValidationErrorClass('deliveryEndDate')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
  </div>
</div>
