import {Component, OnDestroy, OnInit} from '@angular/core';
import {RemitDashboardService} from '../remit-dashboard.service';
import {RemitDashBoardBucketModel} from './remit-dashboard-bucket.model';
import {Column} from '@common/shared/datatable.model';
import {RemitTransactionsService} from '../../datatable/remit-transactions.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {OrganisationService} from '@common/organisation.service';
import {RemitTransactionsSearchFilter} from "../../datatable/remit-transactions.model";

@Component({
  selector: 'cms-remit-dashboard-bucket',
  templateUrl: './remit-dashboard-bucket.component.html',
  styleUrls: ['./remit-dashboard-bucket.component.scss']
})
export class RemitDashboardBucketComponent implements OnInit, OnDestroy {

  loading: boolean;
  buckets: RemitDashBoardBucketModel[];
  selectedCell: { column: Column, data: RemitDashBoardBucketModel };
  columns: Column[] = [
    { type: 'link',  title: '', name: 'state', visible: true, sortable: false },
    { type: 'link', title: 'Today (0-4h)', name: 'todayLast4Hours', style: { 'width': '130px', 'text-align': 'right' }, visible: true, sortable: false },
    { type: 'link', title: 'Today (earlier)', name: 'todayEarlier', style: { 'width': '130px', 'text-align': 'right' }, visible: true, sortable: false },
    { type: 'link', title: 'Yesterday', name: 'daysAgo1', style: { 'width': '130px', 'text-align': 'right' }, visible: true, sortable: false },
    { type: 'link', title: '2 days ago', name: 'daysAgo2', style: { 'width': '130px', 'text-align': 'right' }, visible: true, sortable: false },
    { type: 'link', title: '3-6 days ago', name: 'daysAgo3To6', style: { 'width': '130px', 'text-align': 'right' }, visible: true, sortable: false },
    { type: 'link', title: '7-30 days ago', name: 'daysAgo7To30', style: { 'width': '130px', 'text-align': 'right' }, visible: true, sortable: false },
    { type: 'link', title: '31-60 days ago', name: 'daysAgo31To60', style: { 'width': '130px', 'text-align': 'right' }, visible: true, sortable: false }
  ];
  organisationId: number;

  private onDestroy$ = new Subject();

  constructor(private remitDashboardService: RemitDashboardService,
              private remitTransactionsService: RemitTransactionsService,
              private organisationsService: OrganisationService) {
  }

  ngOnInit() {
    this.organisationId = this.organisationsService.getCachedOrganisationId();
    this.loading = true;
    this.remitDashboardService.getSearchFilter().pipe(takeUntil(this.onDestroy$)).subscribe(filter => {
      this.remitDashboardService.loadBuckets(filter)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(data => {
          this.buckets = data.rows;
          this.loading = false;
        }, () => this.loading = false);
    });

    this.organisationsService.getChangeOrganisationObservable().pipe(takeUntil(this.onDestroy$)).subscribe((org) => {
      if ((org && this.organisationId !== org.organisationId) ||
        (!org && this.organisationId)) {
        this.remitDashboardService.loadBuckets(this.remitDashboardService.getCurrentSearchFilter())
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(data => {
            this.buckets = data.rows;
            this.loading = false;
          }, () => this.loading = false);
      }
      this.organisationId = org ? org.organisationId : null;
    });
  }

  loadTransactionTable(column: Column, row: RemitDashBoardBucketModel) {
    const searchQuery = this.buildTransactionSearchQuery(row, column.name);
    this.remitTransactionsService.updateSearchQuery(this.remitDashboardService.getCurrentSearchFilter().documentType, searchQuery);
    this.selectedCell = {column, data: row};
  }

  private buildTransactionSearchQuery(row, field): RemitTransactionsSearchFilter {
    const searchFilter = this.remitDashboardService.getCurrentSearchFilter();
    return {
      state: this.createStateParam(row.state),
      viewBy: searchFilter.viewBy,
      remitDocumentType: searchFilter.documentType,
      remitReportingRole: searchFilter.reportingRoleType,
      dateRangeType: this.createDateRangeParam(field),
      searchMode: 'SEARCH_BUCKET_DETAIL'
    };
  }

  createStateParam(state): string {
    const states = [];
    states['Manual Intervention'] = 'MANUAL_INTERVENTION';
    states['Not Reported'] = 'NOT_REPORTED';
    states['Pairing'] = 'PAIRING';
    states['Pending'] = 'PENDING';
    states['Rejected eRR'] = 'REJECTED_ERR';
    states['Rejected ACER'] = 'REJECTED_ACER';
    states['Accepted ACER'] = 'ACCEPTED_ACER';

    return states[state];
  }

  createDateRangeParam(field): string {
    const dateRanges = [];
    dateRanges['state'] = 'ALL';
    dateRanges['todayLast4Hours'] = 'RANGE_0_4_HOURS';
    dateRanges['todayEarlier'] = 'RANGE_4_24_HOURS';
    dateRanges['daysAgo1'] = 'YESTERDAY';
    dateRanges['daysAgo2'] = 'TWO_DAYS_AGO';
    dateRanges['daysAgo3To6'] = 'RANGE_3_6_DAYS';
    dateRanges['daysAgo7To30'] = 'RANGE_7_30_DAYS';
    dateRanges['daysAgo31To60'] = 'RANGE_31_60_DAYS';

    return dateRanges[field];
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }
}
