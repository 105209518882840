import { Message } from 'primeng/api';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { Column } from '@common/shared/datatable.model';
import { createReducer, on } from '@ngrx/store';
import * as fromActions from './invoices-table.actions';
import { NettingPreviewInvoicesTableActions } from './invoices-table.actions';
import { EsmNettedInvoices, NettedInvoicesRequestModel } from '../../netted-invoices/netted-invoices.model';



export interface InvoicesTableState {
  header: string;
  messages: Message[];
  data: DatatableData<EsmNettedInvoices[]>;
  loading: boolean;
  paging: PagingFilter;
  sorting: SortingFilter;
  columns: Column[];
  selectedIds: string[];
  invoices: EsmNettedInvoices[];
  requestModel: NettedInvoicesRequestModel;
}

export const invoicesTableInitialState: InvoicesTableState = {
  header: '',
  messages: [],
  data: {
    values: null,
    count: 0,
    currentPage: 0,
  },
  paging: {
    page: 0,
    entriesPerPage: 50
  },
  sorting: {
    columnName: 'submissionTimestamp',
    sortOrder: 'DESCENDING'
  },

  loading: false,
  columns: [],
  selectedIds: [],
  invoices: [],
  requestModel: {
    counterPartyId: 0,
    caseNumber: 0,
    myVAT: null,
    cpVAT: null,
    dueDate: null,
    physicalFinancial: null,
    commodities: null,
    currency: null,
    agreement: null,
    masterAgreementVersion: null
  }
};

export const invoicesTableReducer = createReducer<InvoicesTableState, NettingPreviewInvoicesTableActions>(
  invoicesTableInitialState,
  on(fromActions.setMessagesAction, (state: InvoicesTableState, { payload: messages }): InvoicesTableState => ({
    ...state,
    loading: false,
    messages
  })),

  on(fromActions.loadDataAction, (state: InvoicesTableState) : InvoicesTableState => ({...state, loading: true})),
  on(fromActions.sortAndPageDataAction, (state: InvoicesTableState, {payload: { paging, sorting }}): InvoicesTableState => ({
    ...state,
    loading: true,
    messages: [],
    paging,
    sorting
  })),
  on(fromActions.loadDataSuccessAction, (state: InvoicesTableState, { payload: { values,count,currentPage } }) => ({
    ...state,
    data: {
      count: count||0,
      currentPage: currentPage||0,
      values: [...values],
    },
    loading: false,
    selectedIds: []
  })),

  on(fromActions.setColumnsAction, (state: InvoicesTableState, {payload: columns}) => ({
    ...state,
    columns,
  })),
  on(fromActions.setSelectedIdsAction, (state: InvoicesTableState, {payload: {confirmationIds}}) => ({
    ...state,
    selectedIds: confirmationIds,
  })),
  on(fromActions.setSelectedInvoicesAction, (state: InvoicesTableState, {payload: {invoices}}) => ({
    ...state,
    invoices: invoices,
  })),
  on(fromActions.setLoadingAction, (state: InvoicesTableState, { payload: loading}): InvoicesTableState => ({ ...state, loading })),
  on(fromActions.setRequestModelAction, (state: InvoicesTableState, { payload: requestModel}): InvoicesTableState => ({ ...state, requestModel })),
  on(fromActions.setHeaderAction, (state: InvoicesTableState, { payload: header }): InvoicesTableState => ( { ...state, header }))
);

