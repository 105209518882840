import { ErrorResponse } from '@common/error-response.model';
import { Column } from '@common/shared/datatable.model';
import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import { DateRange } from '../finder/invoice-finder.model';

// export enum EsmStatesEnum {
//   Pending = 'PENDING',
//   Alleged = 'ALLEGED',
//   Failed = 'FAILED',
//   Mismatched = 'MISMATCHED',
//   MismatchedTimeout = 'MISMATCHED_TIMEOUT',
//   UnmatchedTimeout = 'UNMATCHED_TIMEOUT',
//   Matched = 'MATCHED',
//   Cancelled = 'CANCELLED'
// }

export enum EsmRoleByType {
  OFFICIAL = 'OFFICIAL_INVOICE',
  SHADOW = 'SHADOW_INVOICE'
}

export enum EsmDashboardTableType {
  BUCKETS = 'loadBuckets',
  TABLE_DATA = 'loadTableData'
}

export enum CategoriesMap {
  inprogress = 'IN_PROGRESS',
  issue = 'ISSUE',
  complete = 'COMPLETE'
}

export enum EsmDateRange {
  TOTAL = 'TOTAL',
  EXCEEDED = 'EXCEEDED',
  TOMORROW = 'TOMORROW',
  RANGE2 = 'RANGE2',
  RANGE1 = 'RANGE1',
  RANGE3 = 'RANGE3',
  TODAY = 'TODAY',
  RANGE4_7 = 'RANGE4_7',
  RANGE8_31 = 'RANGE8_31',
  LATER = 'LATER',
}

export enum EsmStatesEnum {
  PENDING = 'PENDING',
  ALLEGED = 'ALLEGED',
  MISMATCHED = 'MISMATCHED',
  FAILED = 'FAILED',
  MISMATCHED_TIMEOUT = 'MISMATCHED_TIMEOUT',
  MISMATCHED_DISPUTE = 'MISMATCHED_DISPUTE',
  UNMATCHED_TIMEOUT = 'UNMATCHED_TIMEOUT',
  MATCHED_COMPLETE = 'MATCHED_COMPLETE',
  MATCHED_INVOICE = 'MATCHED_INVOICE',
  MATCHED_TOLERANCE = 'MATCHED_TOLERANCE',
  MATCHED_WEB_USER ='MATCHED_WEB_USER',
  MATCHED = 'MATCHED',
  CANCELLED = 'CANCELLED',
  PDF_SENT_FAILED = 'PDF_NOT_SENT',
  PDF_SENT = 'PDF_SENT',
  RECEIVING = 'RECEIVING',
  NOT_RESPONDED_YET = 'NOT_RESPONDED_YET',
  MATCH_SUGGESTED = 'MATCH_SUGGESTED',
  MISMATCH_SUGGESTED = 'MISMATCH_SUGGESTED',
  MATCHING_ERROR = 'MATCHING_ERROR',
  ERROR = 'ERROR',
  ERROR_TIMEOUT = 'ERROR_TIMEOUT'
}

export interface FilterRequestModel {
  category: string;
  commodities: string[];
  counterParties: number[];
  deliveryPoints: { keyObject: string, valueObject: string }[];
  invoiceRole: EsmRoleByType;
  invoiceTypes: string[];
  invoiceStartDate: string;
  invoiceEndDate: string;
  paymentDateRange: DateRange;
  deliveryDateRange: DateRange;
}

export interface EsmDashboardBuckets extends ErrorResponse {
  bucketNames: string[];
  stateNames: EsmStatesEnum[];
  rows: EsmBucketsRows[];
}

export interface EsmDashboardPresetFiltersResponse extends ErrorResponse {
  value: EsmDashboardPresetFiltersValues;
}

export interface EsmDashboardTableDataRequest {
  dateRange: string;
  filterRequestModel: FilterRequestModel;
  paging: PagingFilter;
  sorting: SortingFilter;
  state: EsmStatesEnum;
}

export interface EsmDashboardTableDataResponse extends ErrorResponse {
  count: number;
  currentPage: number;
  values: EsmInvoiceFinderDeal[];
  notFoundIds?: string[];
  dateRangeText: string;
}

export interface EsmBucketValues {
  TOTAL: number;
  EXCEEDED: number;
  TOMORROW: number;
  RANGE2: number;
  RANGE1: number;
  RANGE3: number;
  TODAY: number;
  RANGE4_7: number;
  RANGE8_31: number;
  LATER: number;
}

export interface EsmBucketsRows extends EsmBucketValues {
  state: EsmStatesEnum;
}

export interface EsmDashboardPresetFiltersValues {
  commodities: string[];
  counterParties: { key: number, value: string }[];
  deliveryPoints: any[];
  invoiceTypes: string[];
  multiAgreeButtonVisible: boolean;
  invoiceStartDate: string;
  invoiceEndDate: string;
  paymentDateRange: DateRange;
  deliveryDateRange: DateRange;
}

export interface EsmDashboardPresetFilters {
  commodities: string[];
  counterParties: number[];
  deliveryPoints: {keyObject:string,valueObject:string}[];
  invoiceTypes: string[];
  multiAgreeButtonVisible: boolean;
  invoiceEndDate: string;
  invoiceStartDate: string;
  paymentDateRange: DateRange;
  deliveryDateRange: DateRange;
}

export interface EsmDashboardTab {
  route: EsmDashboardTabsType;
  title: string;
  header: string;
}

export interface EsmInvoiceFinderDeal {
  agreement: string;
  commodity: string;
  currency: string;
  customerId: string;
  deliveryPointArea: string;
  documentId: string;
  documentType: string;
  documentVersion: number;
  fixedFloating: string;
  id: number;
  invoiceDate: string;
  invoiceNumber: string;
  invoicePeriodEnd: string;
  invoicePeriodStart: string;
  lastUpdateUtc: string;
  marketInformation: string;
  masterAgreementVersion: string;
  matchType: string;
  natureOfPrice: string;
  paymentDate: string;
  physicalFinancial: string;
  purchaseOrderNumber: string;
  receiverId: string;
  selfBilling: boolean;
  senderId: string;
  state: string;
  submissionTimestamp: string;
  supplierId: string;
  taxPoint: string;
  totalAmount: number;
  totalAmountCurrency: string;
  totalGrossAmount: number;
  totalGrossAmountCurrency: string;
  totalVolume: number;
  totalVolumeUnit: string;
  vatAmount: number;
  vatAmountCurrency: string;
  vatAmountDomestic: number;
  vatAmountDomesticCurrency: string;
  vatRate: number;
  notes: string;
  nearMatchButtonVisible: boolean;
  pdfButtonVisible: boolean;
  resendButtonVisible: boolean;
  customerDisplayName: string;
  supplierDisplayName: string;
  processType: string;
  agreeButtonVisible: boolean;
  disputeButtonVisible: boolean;
}

export type EsmDashboardTabsType = 'inprogress' | 'issue' | 'complete';

export const EsmDashboardTabs: EsmDashboardTab[] = [
  { route: 'inprogress', title: 'Dashboard In Progress Page', header: 'In Progress' },
  { route: 'issue', title: 'Dashboard Issue Page', header: 'Issue' },
  { route: 'complete', title: 'Dashboard Complete Page', header: 'Complete' },
];

export const esmDateRangeToLabelMaps = {
  [EsmDateRange.TODAY]: '0 (Today)',
  [EsmDateRange.TOMORROW]: '1 (Tomorrow)',
  [EsmDateRange.EXCEEDED]: 'Exceeded',
  [EsmDateRange.TOTAL]: 'Total',
  [EsmDateRange.RANGE1]: '1',
  [EsmDateRange.RANGE2]: '2',
  [EsmDateRange.RANGE3]: '3',
  [EsmDateRange.RANGE4_7]: '4-7',
  [EsmDateRange.RANGE8_31]: '8-31',
  [EsmDateRange.LATER]: 'Later',
};

export const esmStatesToLabelMaps = {
  [EsmStatesEnum.PENDING]: 'Pending',
  [EsmStatesEnum.ALLEGED]: 'Alleged',
  [EsmStatesEnum.MISMATCHED]: 'Mismatched',
  [EsmStatesEnum.FAILED]: 'Failed',
  [EsmStatesEnum.MISMATCHED_TIMEOUT]: 'Mismatched (Timeout)',
  [EsmStatesEnum.MISMATCHED_DISPUTE]: 'Mismatched (Disputed)',
  [EsmStatesEnum.UNMATCHED_TIMEOUT]: 'Unmatched (Timeout)',
  [EsmStatesEnum.MATCHED_INVOICE]: 'Matched (Invoice Totals)',
  [EsmStatesEnum.MATCHED_COMPLETE]: 'Matched (Complete)',
  [EsmStatesEnum.MATCHED_TOLERANCE]: 'Matched (Tolerance)',
  [EsmStatesEnum.MATCHED_WEB_USER]: 'Matched (eSM Lite)',
  [EsmStatesEnum.MATCHED]: 'Matched',
  [EsmStatesEnum.CANCELLED]: 'Cancelled',
  [EsmStatesEnum.PDF_SENT]: 'PDF sent',
  [EsmStatesEnum.PDF_SENT_FAILED]: 'PDF not sent',
  [EsmStatesEnum.RECEIVING]: 'Receiving',
  [EsmStatesEnum.NOT_RESPONDED_YET]: 'No response yet',
  [EsmStatesEnum.MATCH_SUGGESTED]: 'Match suggested',
  [EsmStatesEnum.MISMATCH_SUGGESTED]: 'Mismatch suggested',
  [EsmStatesEnum.MATCHING_ERROR]: 'Matching error',
  [EsmStatesEnum.ERROR]: 'Error',
  [EsmStatesEnum.ERROR_TIMEOUT]: 'Error (Timeout)',
};

export const defaultStyles = {
  'text-align': 'left',
  'text-overflow': 'ellipsis',
  'overflow': 'hidden',
  'white-space': 'nowrap'
};

const defaultBucketColumn = {
  name: '',
  type: 'esm',
  title: '',
  style: { ...defaultStyles, 'color': '#006591', 'text-align': 'right', 'width': '150px' },
  sortable: false,
  visible: true
};

export const allColumnsDealConfig: Column[] = [
  { name: 'submissionTimestamp', type: 'dateWithoutTime', title: 'Sub. Date', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'invoiceNumber', type: 'text', title: 'Invoice no#', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'receiverId', type: 'text', title: 'Receiver EIC', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'physicalFinancial', type: 'text', title: 'Physical Financial', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'commodity', type: 'text', title: 'Commodity', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'deliveryPointArea', type: 'text', title: 'Delivery Point', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'agreement', type: 'text', title: 'Agreement', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'openDocument', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'openDocumentXml', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'auditTrail', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'paymentDate', type: 'text', title: 'Payment Date', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'invoicePeriodStart', type: 'text', title: 'Inv. Period Start', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'invoicePeriodEnd', type: 'text', title: 'Inv. Period End', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'totalVolumeUnit', type: 'text', title: 'Total Volume Unit', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'state', type: 'text', title: 'State', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'totalGrossAmount', type: 'currency', title: 'Total Gross Amount', style: { ...defaultStyles, 'text-align': 'right' }, sortable: true, visible: true },
  { name: 'currency', type: 'text', title: 'Curr.', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'totalAmount', type: 'currency', title: 'Total Amount', style: { ...defaultStyles, 'text-align': 'right' }, sortable: true, visible: true },
  { name: 'vatAmount', type: 'currency', title: 'VAT Amt', style: { ...defaultStyles, 'text-align': 'right' }, sortable: true, visible: true },
  { name: 'vatRate', type: 'percent', title: 'VAT rate', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'vatAmountCurrency', type: 'text', title: 'VAT Curr.', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'notes', type: 'button', title: '', style: { ...defaultStyles, width: '80px' }, sortable: false, visible: true },
  { name: 'nearMatch', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'misMatch', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'pdf', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'resend', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'taxPoint', type: 'text', title: 'Tax Point', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'masterAgreementVersion', type: 'text', title: 'Master Agreement Version', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'invoiceDate', type: 'text', title: 'Invoice Date', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'totalVolume', type: 'text', title: 'Total Volume', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'vatAmountDomestic', type: 'text', title: 'Vat Amount Domestic', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'vatAmountDomesticCurrency', type: 'text', title: 'Vat Amount Domestic Currency', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'totalGrossAmountCurrency', type: 'text', title: 'Total Gross Amount Currency', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'totalAmountCurrency', type: 'text', title: 'Total Amount Currency', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'documentId', type: 'text', title: 'Document Id', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'documentVersion', type: 'text', title: 'Document Version', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'fixedFloating', type: 'text', title: 'Fixed Floating', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'natureOfPrice', type: 'text', title: 'Nature Of Price', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'marketInformation', type: 'text', title: 'Market Information', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'selfBilling', type: 'text', title: 'Self Billing', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'supplierId', type: 'text', title: 'Supplier Id', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'customerId', type: 'text', title: 'Customer Id', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'purchaseOrderNumber', type: 'text', title: 'Purchase Order Number', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'senderId', type: 'text', title: 'Sender Id', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'documentType', type: 'text', title: 'Document Type', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'customerDisplayName', type: 'text', title: 'Customer Name', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'supplierDisplayName', type: 'text', title: 'Supplier Name', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'processType', type: 'text', title: 'Process Type', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'agree', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'dispute', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: true }
];

export function buildColumns(response: EsmDashboardBuckets) {
  const dateRanges = response.bucketNames;
  const columns = [
    {
      ...defaultBucketColumn,
      name: 'state',
      type: 'state',
      title: 'State',
    }
  ];
  dateRanges.forEach((dateRange: EsmDateRange) => {
    columns.push({
      ...defaultBucketColumn,
      name: dateRange,
      title: esmDateRangeToLabelMaps[dateRange],
    });
  });
  return columns;
}

