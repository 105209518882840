import * as fromRoboDelegators from './robo-delegators-upload.reducer';
import { ActionReducerMap } from '@ngrx/store';

export interface State {
  roboDelegators: fromRoboDelegators.RoboDelegatorsUploadState;
}

export const reducers: ActionReducerMap<State> = {
  roboDelegators: fromRoboDelegators.RoboDelegatorsUploadReducer
};


