import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { OrganisationService } from '../../../common/organisation.service';
import { BehaviorSubject, from, Observable, Subject, Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from '../../../auth/authentication.service';
import { filter, mergeMap, startWith, takeUntil } from 'rxjs/operators';
import { NavigationService } from '../../../common/menu/navigation.service';
import { ErrorMessageService } from '@common/errors/error-message.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'cms-remit-report',
  templateUrl: './remit-report.component.html',
  styleUrls: ['./remit-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RemitReportComponent implements OnInit, OnDestroy {
  isRefitInactive$ = new BehaviorSubject<boolean>(true);
  activeIndex: Observable<number>;
  private activeIndexSubscription: Subscription;
  tabCategories: string[] = ['ACTIVITY', 'REMIT_RECONCILIATION', 'EMIR_RECONCILIATION', 'BILLING'];

  selectedCategory: string;
  billingVisible: boolean = true;
  private onDestroy$ = new Subject();

  constructor(private organisationService: OrganisationService,
              private router: Router,
              private authenticationService: AuthenticationService,
              private navigationService: NavigationService,
              private changeDetectorRef: ChangeDetectorRef,
              public readonly errorMessageService: ErrorMessageService) {
  }

  ngOnInit() {
    this.updateBillingVisible();
    this.activeIndex =
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
        , mergeMap((event: NavigationEnd) => this.calcActiveTabIndexObservable(event.url))
        , startWith(this.calcActiveTabIndex(this.router.url))); // this is for F5 reload to add the init router url to the stream

    this.activeIndexSubscription = this.activeIndex.pipe(takeUntil(this.onDestroy$))
      .subscribe(this.changeCategoryTab.bind(this));

    this.organisationService.getChangeOrganisationObservable().pipe(takeUntil(this.onDestroy$)).subscribe(org => {
      this.updateBillingVisible();
    });

    this.authenticationService.authorisUrl('err/report/emir_reconciliation', this.organisationService.getCachedOrganisationId()).then((authorised) => {
      this.isRefitInactive$.next(authorised);
    });
  }

  private updateBillingVisible() {
    this.navigationService.onNavigationChanged().subscribe(navigation => {
      this.billingVisible = this.navigationService.containsPath('err/report/billing', navigation);
      this.changeDetectorRef.markForCheck();
    });
  }

  handleChange(event) {
    this.changeCategoryTab(event.index);
  }

  ngOnDestroy(): void {
    // Clean up all subscriptions at once:
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  private changeCategoryTab(index: number) {
    this.selectedCategory = this.tabCategories[index];
    this.router.navigate(['/err/report/' + this.selectedCategory.toLowerCase()]);
  }

  // this is for F5 reload to add the init router url to the stream
  private calcActiveTabIndexObservable(url: string): Observable<number> {
    return from([this.calcActiveTabIndex(url)]);
  }

  // this is for F5 reload to add the init router url to the stream
  private calcActiveTabIndex(url: string): number {
    return this.tabCategories.reduce((activeIndex, category, arrIndex) => {
      const urlEndsWithCategory = new RegExp('\/' + category + '$', 'i').test(url);
      return urlEndsWithCategory ? arrIndex : activeIndex;
    }, 0);
  }
}
