import { Injectable } from '@angular/core';
import { ApiRequestService } from '@common/api-request.service';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrRemitSettingsService {

  private apiUrl = '/api/err/remit/settings';

  private modeChangePanelVisible = new BehaviorSubject<boolean>(false);

  constructor(private apiRequestService: ApiRequestService) { }

  getProcessingModes(): Promise<any> {
    return this.apiRequestService.get(this.apiUrl + '/processingModes').pipe(map(data => data.values)).toPromise();
  }

  setProcessingModes(processingMode: String, ids: String[]): Promise<any> {
    if(ids && ids.length > 0){
      const httpParams: HttpParams = new HttpParams().append('ids', ids.toString());
      return this.apiRequestService.put(this.apiUrl + '/processingModes', processingMode, httpParams).toPromise();
    }else {
      return of(null).toPromise();
    }
  }

  setChangePanelVisible(value: boolean){
    this.modeChangePanelVisible.next(value);
  }

  onChangePanelVisible():Observable<boolean>{
    return this.modeChangePanelVisible.asObservable();
  }

  loadPresetValues(): void {
    this.apiRequestService.get(this.apiUrl + '/loadPresetValues').pipe(
      map((response: any) => response.value)).subscribe(value => {
        this.modeChangePanelVisible.next(value.modeChangePanelVisible);
      });
  }
}
