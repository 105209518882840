import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ErrSendingFailuresSearchRequest } from '../err-sending-failures.model';
import { Subject } from 'rxjs';

@Component({
  selector: 'cms-err-sending-failures-filter',
  templateUrl: './err-sending-failures-filter.component.html',
  styleUrls: ['./err-sending-failures-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrSendingFailuresFilterComponent implements OnInit, OnChanges, OnDestroy {
  @Input() values: { label: string, value: string }[] = [];
  @Input() filters: ErrSendingFailuresSearchRequest;
  @Output() search = new EventEmitter<ErrSendingFailuresSearchRequest>();
  @Output() refresh = new EventEmitter<string>();
  form: UntypedFormGroup;
  options: { label: string, value: string }[] = [{ label: 'All Processes', value: '' }];

  private onDestroy$ = new Subject();


  constructor(private formBuilder: UntypedFormBuilder) {
    this.form = this.formBuilder.group({
      processName: '',
      fromDateFilter: [null, Validators.required],
      toDateFilter: [null, Validators.required],
      fromTimeFilter: [null, Validators.required],
      toTimeFilter: [null, Validators.required],
    });
  }

  ngOnInit() : void {
    this.options = this.values && this.values.length ? [...this.options, ...this.values] : [...this.options];

  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (this.form && changes.values) {
      this.options = this.values && this.values.length ? [...this.options, ...this.values] : [...this.options];
    }

    if (this.form && changes.filters) {
      const { processName, start, end } = this.filters;
      this.form.patchValue({
        fromDateFilter: start ? moment(start).format('DD/MM/YYYY') : null,
        toDateFilter: end ? moment(end).format('DD/MM/YYYY') : null,
        fromTimeFilter: start ? moment(start).format('HH:mm:ss') : null,
        toTimeFilter: end ? moment(end).format('HH:mm:ss') : null
      });
      this.form.updateValueAndValidity();
    }
  }

  onSearch(): void {
    this.search.emit(this.getDates());
  }

  onRefresh(): void {
    const {processName} = this.form.value;
    this.refresh.emit(processName);
  }

  private getDates(): ErrSendingFailuresSearchRequest {
    const { processName, fromDateFilter, fromTimeFilter, toDateFilter, toTimeFilter } = this.form.value;
    const formattedFromDate = moment(fromDateFilter, 'DD/MM/YYYY');
    const formattedToDate = moment(toDateFilter, 'DD/MM/YYYY');
    const formattedFromTime = moment(fromTimeFilter, 'HH:mm:ss');
    const formattedToTime = moment(toTimeFilter, 'HH:mm:ss');

    const startMoment = moment([
      formattedFromDate.year(),
      formattedFromDate.month(),
      formattedFromDate.date(),
      formattedFromTime.hours(),
      formattedFromTime.minutes(),
      formattedFromTime.seconds(),
      formattedFromTime.milliseconds()
    ]);
    const start = startMoment.toDate();

    const endMoment = moment([
      formattedToDate.year(),
      formattedToDate.month(),
      formattedToDate.date(),
      formattedToTime.hours(),
      formattedToTime.minutes(),
      formattedToTime.seconds(),
      formattedToTime.milliseconds()
    ]);
    const end = endMoment.toDate();

    return { processName, start, end };
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }
}
