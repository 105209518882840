<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>
  <section class="panel-box">

    <h1>REMIT Early Termination</h1>

    <cms-info ngPreserveWhitespaces [fullWidth]="true">

      **REMIT Early Termination**

      **Purpose**

      This screen enables users with Master Operator (MO) privileges to create a file for the bulk-termination of REMIT trade reports.
      Trade reports are selected via their UTI, which must be provided in csv file format.
      After creation of the file for bulk-termination, users must download and review it and then upload it via the REMIT Files IN screen for submission to ACER.

      Please note that this tool will only create cancel records for REMIT Table 1.

      **Procedure**
      * Click “Browse ..”, select your upload file (which must have a “.csv” file extension) and click “Upload”.
      * On the popup screen, analyse that the results of file processing meet your expectation. The statistics shown are:
        * Number of UTIs that were found
        * Number and list of UTIs that are duplicated in the file you submitted
        * Number and list of UTIs you submitted that were not found or not available for cancellation
          * Unknown UTIs
          * Trades that were already cancelled
          * Trades that were deleted or not accepted by ACER
        * Number and list of UTIs that you do not have permission to terminate
        * Number and list of UTIs that require manual termination
          * Trades involving more than one OMP
          * Trades involving more than two MPs from your organisation group
      * If you need to adapt your upload file, click “Cancel”, edit your csv file and reupload.
      * If you want to proceed with the bulk-termination of the trade reports whose UTIs were found and for which you have permission to terminate, enter a Termination Date and a Transaction Date and Time, then click “OK”.
      * The system will then generate cancel records for the selected trade reports and - depending on file size – create on or several download files in ACER XML format. Depending on the number of UTIs you uploaded this may take some time. You may leave this screen and return to it at a later time to collect your download file(s).
      * Once the file is created, it can be downloaded by clicking the Folder icon list view (in the column “Cancel Records File”).
      * Review the contents of the file(s). If it is as intended, you may upload it via the REMIT Files IN screen for submission to ACER.


    </cms-info>
    <p-messages [value]="messages$ | async"  [closable]="true" ></p-messages>

    <div class="col-12 info-panel margin-bottom-12 mt-1" *ngIf="allowedToUpload$ | async">
      <span class="fa fa-info-circle padding-right-6"></span>
      <u>You are allowed to request early terminations for the following organisations:</u>  {{orgList$|async}}
    </div>

    <cms-upload-panel *ngIf="allowedToUpload$ | async"
                      [accept]="'.csv'"
                      [maxFileSize]="allowedMaxSize$|async"
                      [inBytesSize]="true"
                      [busy]="(loading$|async).upload || wizardOpen"
                      title="Upload UTIs (.csv)"
                      [fullWidth]="true"
                      (error)="onUploadError($event)"
                      (upload)="onUpload($event)">

    </cms-upload-panel>

    <div>
      <cms-datatable [scrollable]="false"
                     [tableColumns]="columns"
                     [currentPage]="(paging$ | async).page"
                     [rows]="(paging$ | async)?.entriesPerPage"
                     [sortField]="(sorting$ | async).columnName"
                     [sortOrder]="(sorting$ | async).sortOrder"
                     [loading]="(loading$ | async).table"
                     [data]="(data$ | async)?.values"
                     [totalRecords]="(data$ | async)?.count"
                     [autoLayout]="true"
                     [lazy]="true"
                     [lazyLoadOnInit]="false"
                     (lazyLoad)="onLazyLoad($event)">

        <ng-template pTemplate="caption">
          <div style="text-align: right">
              <button pButton
                      class="btn reload_btn"
                      type="button"
                      [icon]="(loading$| async).table?'fa fa-refresh fa-spin':'fa fa-refresh'"
                      title="reload table"
                      (click)="refreshTable()"></button></div>
        </ng-template>

        <ng-template pTemplate="body" let-row="row" let-column="column">

          <td *ngIf="column.type === 'button' && column.name === 'duplicateCount'" [ngStyle]="column.style">
            <div class="inline-block ">
              <button (click)="openDialog(row,column)"
                      title="Duplicates"
                      class="link transparent"
                      type="button">
                {{row.duplicateCount}} </button></div></td>

          <td *ngIf="column.type === 'button' && column.name === 'notFoundCount'" [ngStyle]="column.style">
            <div class="inline-block">
              <button (click)="openDialog(row,column)"
                      title="Not FoundCount"
                      class="link transparent"
                      type="button">
                {{row.notFoundCount}} </button></div></td>

          <td *ngIf="column.type === 'button' && column.name === 'withoutPermissionCount'" [ngStyle]="column.style">
            <div class="inline-block">
              <button (click)="openDialog(row,column)"
                      title="Without Permissions"
                      class="link transparent"
                      type="button">
              {{row.withoutPermissionCount}} </button></div></td>

          <td *ngIf="column.type === 'button' && column.name === 'issueCount'" [ngStyle]="column.style">
            <div class="inline-block ">
              <button (click)="openDialog(row,column)"
                      title="Manual Termination"
                      class="link transparent"
                      type="button">
                {{row.issueCount}} </button></div></td>


          <td *ngIf="column.type === 'button' && column.name === 'downloadResultZip'" [ngStyle]="column.style">
            <div class="btn-wrapper inline-block">
              <button *ngIf="row.downloadResultZip" (click)="onDownloadZip(row)" icon="fa fa-file-archive-o" pButton title="Download (Zip)" type="button"></button>
            </div>
          </td>
        </ng-template>

        <ng-template pTemplate="customEmptyMessage">

          <tr>
            <td colspan="100" *ngIf="!(loading$ | async)">
              No records found</td></tr>

        </ng-template>

      </cms-datatable></div>


  </section>

</ng-template>
