import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {NavigationStart, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import { ErrorMessageService } from '@common/errors/error-message.service';

@Component({
  selector: 'cms-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {
  activeIndex: number = 0;
  tabs = ['invoice', 'netting', 'tolerance', 'subscription', 'whitelist'];
  selectedTab: string;
  @Output() onTabChange = new EventEmitter<string>();
  private activeTabSubscription$: Subscription;

  constructor(
    private router: Router,
    public errorMessageService: ErrorMessageService) {
  }

  ngOnInit(): void {
    this.setIndexByUrl(this.router.url);
    this.activeTabSubscription$ =
      this.router.events.pipe(filter(event => event instanceof NavigationStart && event.navigationTrigger === 'popstate'))
        .subscribe((event: NavigationStart) => this.setIndexByUrl(event.url));
  }

  setTab(event: any): void {
    this.setActiveTabData(event.index);
    this.onTabChange.emit(this.selectedTab);
    this.router.navigate([`/esm/settings/${this.selectedTab.toLowerCase()}`]);
  }

  ngOnDestroy(): void {
    this.activeTabSubscription$.unsubscribe();
  }

  private setIndexByUrl(url: string): void {
    const index = this.calcActiveTabIndex(url);
    this.setActiveTabData(index);
  }

  private setActiveTabData(index: number): void {
    this.activeIndex = index;
    this.selectedTab = this.tabs[index];
  }

  private calcActiveTabIndex(url: string): number {
    const index = this.tabs.findIndex(el => url.includes(el));
    return (index > -1) ? index : 0;
  }
}
