import {Message} from 'primeng/api';
import {Observable, Subscription} from 'rxjs';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Column} from '@common/shared/datatable.model';
import {DatatableData, PagingFilter, SortingFilter} from '@common/shared/results.model';
import {DatatableState} from '@common/datatable/datatable.model';
import {DashboardDealsNavModel} from '@common/dashboard-deals-nav/dashboard-deals-nav.model';
import {AddNote, NotesCellViewEvent} from '@common/deals/deals.model';
import {
  EsmBucketsRows,
  EsmDateRange,
  EsmInvoiceFinderDeal,
  EsmNettingDashboardPresetFilters,
  EsmNettingDashboardTableType,
  EsmNettingDashboardTabsType,
  EsmNettingRoleByType,
  EsmStatesEnum,
  esmStatesToLabelMaps
} from '../esm-netting-dashboard.model';
import {NETTING_STATEMENT_DIALOGS} from '../../../state/netting/finder/dialog/dialog.reducers';
import {EsmNettingDashboardState} from '../../../state/netting/dashboard/dashboard.reducers';
import * as fromDBActions from '../../../state/netting/dashboard/dashboard.actions';
import * as fromEsmDBFiltersActions from '../../../state/netting/dashboard/filters/filters.actions';
import * as fromDBTableActions from '../../../state/netting/dashboard/table/table.actions';
import * as fromIFTableActions from '../../../state/netting/finder/table/table.actions';
import * as fromIFDialogActions from '../../../state/netting/finder/dialog/dialog.actions';
import * as fromEsmDBFiltersSelectors from '../../../state/netting/dashboard/filters/filters.selectors';
import * as fromEsmDBTableSelectors from '../../../state/netting/dashboard/table/table.selectors';
import * as fromEsmDashboardSelectors from '../../../state/netting/dashboard/dashboard.selectors';
import * as fromCommon from '@common/state/reducers';

interface BucketsHeader {
  title: string;
  colspan: number;
  style: any;
}

@Component({
  selector: 'cms-esm-netting-dashboard-tab',
  templateUrl: './esm-netting-dashboard-tab.component.html',
  styleUrls: ['./esm-netting-dashboard-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EsmNettingDashboardTabComponent implements OnInit, OnDestroy {
  @Input() category: EsmNettingDashboardTabsType;
  tableViewType = EsmNettingDashboardTableType;
  selectedIds$: Observable<string[]> = this.store.pipe(select(fromEsmDBTableSelectors.getSelectedIds));
  dialog$: Observable<fromCommon.DialogState<NETTING_STATEMENT_DIALOGS>> = this.store.pipe(select(fromEsmDashboardSelectors.getDialog));
  viewType$: Observable<EsmNettingDashboardTableType> = this.store.pipe(select(fromEsmDashboardSelectors.getViewType));
  loading$: Observable<boolean> = this.store.pipe(select(fromEsmDBTableSelectors.getLoading));
  messages$: Observable<Message[]> = this.store.pipe(select(fromEsmDBTableSelectors.getMessages), map(m=>[...m]));
  filters$: Observable<EsmNettingDashboardPresetFilters> = this.store.pipe(select(fromEsmDBFiltersSelectors.getFiltersValues));
  counterParties$: Observable<{ key: number, value: string }[]> = this.store.pipe(select(fromEsmDBFiltersSelectors.getCounterPartiesValues));
  commodities$: Observable<string[]> = this.store.pipe(select(fromEsmDBFiltersSelectors.getCommoditiesValues));
  deliveryPoints$: Observable<{keyObject:string,valueObject:string}[]> = this.store.pipe(select(fromEsmDBFiltersSelectors.getDeliveryPointsValues));
  nettingStatementTypes$: Observable<string[]> = this.store.pipe(select(fromEsmDBFiltersSelectors.getNettingTypesValues));
  nettingStatementRole$: Observable<EsmNettingRoleByType> = this.store.pipe(select(fromEsmDBFiltersSelectors.getNettingStatementRoleValue));
  state$: Observable<EsmStatesEnum | string> = this.store.pipe(select(fromEsmDashboardSelectors.getState));
  stateNames$: Observable<DashboardDealsNavModel[]> = this.store.pipe(select(fromEsmDashboardSelectors.getStateNames));
  columns$: Observable<Column[]> = this.store.pipe(select(fromEsmDBTableSelectors.getColumns));
  data$: Observable<DatatableData<EsmInvoiceFinderDeal[] | EsmBucketsRows[]>> = this.store.pipe(select(fromEsmDBTableSelectors.getData));
  sorting$: Observable<SortingFilter> = this.store.pipe(select(fromEsmDBTableSelectors.getSorting));
  paging$: Observable<PagingFilter> = this.store.pipe(select(fromEsmDBTableSelectors.getPaging));
  dateRangeText$ : Observable<string> = this.store.pipe(select(fromEsmDBFiltersSelectors.getDateRangeText));
  selected$: Observable<EsmInvoiceFinderDeal[]> = this.store.pipe(select(fromEsmDBTableSelectors.getSelectedNettingStatements));
  subscription$: Subscription = new Subscription();
  additionalHeader: BucketsHeader[] = [];
  singleNttType: string;
  singleCommodity: string;
  readonly stateValuesMapping: { [key: string]: string } = esmStatesToLabelMaps;
  private selectedNettingStatements: EsmInvoiceFinderDeal[] = [];

  constructor(private router: Router,
              private route: ActivatedRoute,
              private store: Store<EsmNettingDashboardState>) { }

  ngOnInit() {
    this.subscription$ = this.columns$
      .pipe(withLatestFrom(this.viewType$))
      .subscribe(([data, type]) => {
        if (data && data.length && EsmNettingDashboardTableType.BUCKETS === type) {
          this.additionalHeader = this.buildAdditionalHeader(data);
        }
      });
    this.subscription$.add(this.data$.pipe(
      filter(d => d && this.isEsmInvoiceFinderDeal(d)),
      map(d => d as DatatableData<EsmInvoiceFinderDeal[]>)).subscribe(data => {
        if (data && data.values && this.selectedNettingStatements) {
          const ids = this.selectedNettingStatements.map(s => s.id);
          const selected: EsmInvoiceFinderDeal[] = data.values.filter(v => ids.indexOf(v.id) !== -1);
          this.onSelected(selected);
        }
      }
    ));
  }

  private isEsmInvoiceFinderDeal(d: DatatableData<EsmInvoiceFinderDeal[] | EsmBucketsRows[]>): d is DatatableData<EsmInvoiceFinderDeal[]> {
    return (<DatatableData<EsmInvoiceFinderDeal[]>>d) !== undefined;
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
    this.selectedNettingStatements = [];
  }

  onClickCustomize(): void {
    this.router.navigate(['/settings/datatable/customize'], { queryParams: { documentType: 'ESM_NETTING' } });
  }

  onLazyLoad(state: DatatableState): void {
    this.store.dispatch(fromDBTableActions.sortAndPageDataAction({
      paging: {
        page: state.page,
        entriesPerPage: state.perPage
      },
      sorting: {
        columnName: state.sortField,
        sortOrder: state.sortOrder
      }
    }));
  }

  onExport(): void {
    this.store.dispatch(fromDBTableActions.exportAction());
  }

  onDownloadXml({ id }: EsmInvoiceFinderDeal): void {
    this.store.dispatch(fromIFTableActions.downloadXmlAction(id));
  }

  getDisplayParams({ id, documentId }: EsmInvoiceFinderDeal): object {
    return {
      displayValue: documentId,
      urlPart: 'esm/netting',
      nettingId: id
    };
  }

  onFilter(searchFilter: EsmNettingDashboardPresetFilters): void {
    this.store.dispatch(fromEsmDBFiltersActions.setFilterValuesAction(searchFilter));
    this.store.dispatch(fromEsmDBFiltersActions.filterAction());
    this.singleValueSearchPreview(searchFilter);
  }

  onRoleByFilter(roleBy: EsmNettingRoleByType): void {
    this.store.dispatch(fromEsmDBFiltersActions.setRoleByFilterValueAction(roleBy));
    this.store.dispatch(fromEsmDBFiltersActions.filterAction());
  }

  onReset(): void {
    this.store.dispatch(fromEsmDBFiltersActions.resetFiltersAction());
  }

  backToDashboard(): void {
    this.router.navigate([`/esm/nettingdashboard/${this.category}`]);
    this.store.dispatch(fromDBActions.setStateAction(null));
    this.store.dispatch(fromDBActions.setStateNamesAction([]));
    this.store.dispatch(fromEsmDBFiltersActions.setDateRangeAction(null));
  }

  changeState({ state }: DashboardDealsNavModel): void {
    const dateRange = this.route.snapshot.queryParamMap.get('dateRange');
    const currState = this.route.snapshot.queryParamMap.get('state');
    if (currState !== state) {
      this.store.dispatch(fromDBActions.setStateAction(state));
      this.router.navigate([`/esm/nettingdashboard/${this.category}/deals`],
        {
          queryParams: { state, dateRange }
        });
    }
  }

  onClick({ state }: EsmBucketsRows, { name }: Column): void {
    const dateRange = name !== 'state' ? name : EsmDateRange.TOTAL;
    this.store.dispatch(fromDBActions.setStateAction(state));
    this.store.dispatch(fromEsmDBFiltersActions.setDateRangeAction(dateRange));
    this.store.dispatch(fromDBActions.setViewTypeAction(EsmNettingDashboardTableType.TABLE_DATA));
    this.router.navigate([`/esm/nettingdashboard/${this.category}/deals`],
      {
        queryParams: { state, dateRange }
      });
  }

  onOpenAddNoteDialog(): void {
    this.store.dispatch(fromIFDialogActions.openDialogAction({
      name: 'ADD_NOTES',
      messages: [],
    }));
  }

  onViewNotes(data: NotesCellViewEvent): void {
    this.store.dispatch(fromIFDialogActions.openDialogAction({
      name: 'VIEW_NOTES',
      messages: [],
      data
    }));
  }

  onSelected(nettingStatements: EsmInvoiceFinderDeal[]) {
    this.selectedNettingStatements = nettingStatements;
    let confirmationIds: string[] = [];
    if (nettingStatements) {
      confirmationIds = nettingStatements.map(d => d.id + '');
    }
    this.store.dispatch(fromDBTableActions.setSelectedIdsAction({confirmationIds}));
    this.store.dispatch(fromDBTableActions.setSelectedNettingStatementsAction({nettingStatements}));
  }

  onConfirmAddNotes(obj: AddNote): void {
    this.store.dispatch(fromDBTableActions.addNoteAction(obj));
  }

  onCancelNotesDialog(): void {
    this.store.dispatch(fromIFDialogActions.closeDialogAction());
  }

  private buildAdditionalHeader(columns: Column[]): BucketsHeader[] {
    const index = columns.map(item => item.name).indexOf(EsmDateRange.TOTAL)+1;
    const style = { 'color': '#1b1d1f' };
    return [
      {
        title: '',
        colspan: index,
        style
      },
      {
        title: 'Days Until Payment Date',
        colspan: columns.length - index,
        style
      }
    ];
  }

  private singleValueSearchPreview({ nettingStatementTypes, commodities }) {
     this.singleNttType = nettingStatementTypes.length === 1 ? nettingStatementTypes[0] : null;
     this.singleCommodity = commodities.length === 1 ? commodities[0] : null;
  }

  onDownloadPdf = ({ documentId }) => this.store.dispatch(fromDBTableActions.downloadPdfAction(documentId));

}
