import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Message } from 'primeng/api';
import { UserAccountsState } from '../../state/user-account/user-account.reducer';
import { UserRoleDatatableItem, UserRolesEditableData, UserRolesFilters } from './user-roles.model';
import * as fromActions from '../../state/user-role/user-role.actions';
import * as fromSelectors from '../../state/user-role/user-roles.selector';
import { OrganisationService } from '../../../common/organisation.service';
import { Column } from '../../../common/shared/datatable.model';
import { DatatableData } from '../../../common/shared/results.model';
import { EditableCell } from '../../../common/datatable/cell-editor/cell-editor.model';

@Component({
  selector: 'cms-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.scss']
})
export class UserRolesComponent implements OnInit, OnDestroy {
  messages$: Observable<Message[]>;
  filters$: Observable<UserRolesFilters>;
  orgGroupVisible$: Observable<any>;
  orgGroups$: Observable<any>;
  users$: Observable<any>;
  usersVisible$: Observable<any>;
  processRole$: Observable<any>;
  processRoleVisible$: Observable<any>;
  columns$: Observable<Column[]>;
  loading$: Observable<boolean>;
  data$: Observable<DatatableData<UserRoleDatatableItem[]>>;
  editableData$: Observable<UserRolesEditableData>;
  editableData: UserRolesEditableData;

  subscription$: Subscription;

  constructor(private store: Store<UserAccountsState>,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private organisationService: OrganisationService,) {
    this.filters$ = this.store.pipe(select(fromSelectors.getFilters));
    this.orgGroups$ = this.store.pipe(select(fromSelectors.getOrgGroups));
    this.orgGroupVisible$ = this.store.pipe(select(fromSelectors.orgGroupVisible));
    this.users$ = this.store.pipe(select(fromSelectors.getUsers));
    this.usersVisible$ = this.store.pipe(select(fromSelectors.usersVisible));
    this.processRole$ = this.store.pipe(select(fromSelectors.getProcessRoles));
    this.processRoleVisible$ = this.store.pipe(select(fromSelectors.processRolesVisible));
    this.columns$ = this.store.pipe(select(fromSelectors.getColumns));
    this.loading$ = this.store.pipe(select(fromSelectors.loading));
    this.data$ = this.store.pipe(select(fromSelectors.getData));
    this.editableData$ = this.store.pipe(select(fromSelectors.getEditableData));
    this.messages$ = this.store.pipe(select(fromSelectors.getMessages));
  }

  onFilter(obj: any): void {
    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: {...obj, organisationGroupId: obj.selectedOrganisationGroupId }});
  }

  onExport(obj: UserRolesFilters): void {
    this.store.dispatch(new fromActions.ExportAction(obj));
  }

  onChange(obj: EditableCell): void {
    this.store.dispatch(new fromActions.SetEditableDataAction(obj));
  }

  onUnChange(obj: EditableCell): void {
    this.store.dispatch(new fromActions.RemoveEditableDataAction(obj));
  }

  onSaveChangesClick(): void {
    this.store.dispatch(new fromActions.UpdateMatrixAction());
  }

  ngOnInit(): void {
    this.store.dispatch(new fromActions.SetOrganisationIdAction(this.organisationService.getCachedOrganisationId()));

    this.subscription$ = this.editableData$.subscribe((data: UserRolesEditableData) => {
      this.editableData = data;
    });

    // this.store.dispatch(new fromActions.LoadFiltersDataAction());
    const querySubscr = this.activatedRoute.queryParams.subscribe((queryParams:any) => {
      const userRolesFilter: UserRolesFilters = {
        ...queryParams,
        selectedOrganisationGroupId: queryParams.organisationGroupId
      };

     this.store.dispatch(new fromActions.SetFiltersAction(userRolesFilter));
      this.store.dispatch(new fromActions.UpdateFilterDataAction(userRolesFilter));
    });

    this.subscription$.add(querySubscr);
  }

  ngOnDestroy(): void {
    this.store.dispatch(new fromActions.SetToDefaultAction());
    this.subscription$.unsubscribe();
  }
}
