import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Event, NavigationEnd, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import { select, Store } from '@ngrx/store';
import {EsmNettingDashboardState} from '../../state/netting/dashboard/dashboard.reducers';
import {EsmNettingDashboardTabs, CategoriesMap, EsmNettingDashboardTableType} from './esm-netting-dashboard.model';
import {Subscription} from 'rxjs';
import { filter, withLatestFrom } from 'rxjs/operators';
import * as fromDBFilterActions from '../../state/netting/dashboard/filters/filters.actions';
import * as fromDBActions from '../../state/netting/dashboard/dashboard.actions';
import * as fromEsmDBFiltersActions from '../../state/netting/dashboard/filters/filters.actions';
import {OrganisationService} from '@common/organisation.service';
import * as dashboardSelectors from '../../state/netting/dashboard/filters/filters.selectors';
import { ErrorMessageService } from '@common/errors/error-message.service';

@Component({
  selector: 'cms-esm-netting-dashboard',
  templateUrl: './esm-netting-dashboard.component.html',
  styleUrls: ['./esm-netting-dashboard.component.scss']
})
export class EsmNettingDashboardComponent implements OnInit, OnDestroy {
  activeIndex: number;
  routeSubscription$: Subscription;
  readonly tabs = EsmNettingDashboardTabs;
  nttDashboard$: Subscription;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private title: Title,
              private store: Store<EsmNettingDashboardState>,
              private organisationService: OrganisationService,
              public errorMessageService:ErrorMessageService) { }

  ngOnInit() {
    this.routeSubscription$ = this.router.events.pipe(
      filter((event: Event) => event instanceof NavigationEnd)).subscribe(() => {
      this.setStateDataByUrl();
      this.store.dispatch(fromDBFilterActions.filterAction());
    });

    this.setStateDataByUrl(); // not cool chain of actions in onInit method with race conditions on initialisation

    this.nttDashboard$ = this.organisationService.onRefresh()
      .pipe(
        withLatestFrom( this.store.pipe(select(dashboardSelectors.getType))),
        filter(([auth, type]) => auth && this.organisationService.getCurrentOrganisationId()!==null && !!type))
      .subscribe(() => this.store.dispatch(fromDBFilterActions.loadPresetValuesAction()));
  }

  ngOnDestroy(): void {
    this.store.dispatch(fromDBActions.setToDefaultAction());
    this.routeSubscription$.unsubscribe();
    this.nttDashboard$.unsubscribe();
  }

  onChangeTab({index}: { index: number }): void {
    const tab = this.tabs[index];
    this.router.navigate([`esm/nettingdashboard/${tab.route}`]);
  }

  private setStateDataByUrl(): void {
    const index = this.getActiveIndexByUrl();
    const isDealsView = this.router.url.includes('/deals');
    const viewType = isDealsView ? EsmNettingDashboardTableType.TABLE_DATA : EsmNettingDashboardTableType.BUCKETS;
    this.store.dispatch(fromDBActions.setViewTypeAction(viewType));

    if (index !== this.activeIndex) {
      this.activeIndex = index;
      this.title.setTitle(this.tabs[index].title);
      this.store.dispatch(fromDBActions.setStateNamesAction([]));
      this.store.dispatch(fromEsmDBFiltersActions.setCategoryAction(CategoriesMap[this.tabs[index].route]));
    }

    if (isDealsView) {
      this.initDealsData();
    }
  }

  private getActiveIndexByUrl(): number {
    const childData = this.route.snapshot.firstChild.data;
    const index = this.tabs.findIndex(item => item.route === childData.panel);
    return index > -1 ? index : 0;
  }

  private initDealsData(): void {
    const stateParam = this.route.snapshot.queryParamMap.get('state');
    const dateRangeParam = this.route.snapshot.queryParamMap.get('dateRange');
    this.store.dispatch(fromDBActions.setStateAction(stateParam));
    this.store.dispatch(fromEsmDBFiltersActions.setDateRangeAction(dateRangeParam));
    this.store.dispatch(fromDBActions.getStateNamesAction());
  }
}
