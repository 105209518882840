<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>

<h1>MiFID 2 Files (Position Reports)</h1>
<div class="panel-box">
  <div class="col-12 infoText">
    <p><b>DISCLAIMER</b></p>
    <div>By logging into the Equias MIFID II service, user acknowledges and agrees that this service is offered free of
      charge by Equias BV for evaluation purposes only.
    </div>
    <div>All other rights are expressly reserved by Equias BV. The service is provided "as is", and no guarantee is
      given that this service will perform in the desired way.
    </div>
    <div>Any other terms not expressly provided herein shall be supplemented by the general provisions in Equias BV GTCs
      and privacy policy available at <a href="https://www.equias.org" target="_blank"> <b>www.equias.org.</b></a></div>
  </div>
  <cms-info ngPreserveWhitespaces>
    <!--@formatter:off (markdown rendering breaks, when the following tags are formatted)-->

    **Purpose**

    * This screen is a facility to inspect, edit and confirm MiFID II postion reports.
    * This screen is file-centric, it shows the traffic for position reports between eRR and OMPs for the selected MP
    party.
    * This screen does **not show** the traffic between the OMP and the authorities to which position reports must be
    reported under MiFID II.

    **File Monitoring**

    The MP must be enabled for MiFID II position reporting in the eRR CMS.

    Procedure
    1. From the title bar choose your organisational MP unit whose position reports where the selected MP organisation
    is the position holder should be displayed.
    2. Option 1: search by a specific filename or parts of it, not case-sensitive
    3. Option 2: search any file received in the selected time range - optionally modify the report type and time window
    of interest

    Guidance on Listed Information
    * use the document icon to download the report file
    * use the magnifier icon to open the file's audit trail with processing details
    * the counts in the last four columns signify the processing state of reporting records in the file (total / pending
    / accepted / rejected)


    **File Upload**

    Users with **Master Operator (MO) privileges** can upload files in MiFID II position report XML formats for which no
    automated system-to-system upload exists The uploaded file will be sent to the OMP which is listed as Reporting
    Entity in the file

    Procedure

    1. In the title bar choose your organisational MP unit for which a position report with the MP as position holder
    should be uploaded.
    2. The MP must be enabled for MiFID II position reporting in the CMS.
    3. If you have master operator rights an upload panel appears.
    4. Click "Browse...", choose your upload file (which must have an ".xml" file extension) and press "Upload".

    Supported file types for Market Participants (MP)

    * FCA ISO 20022 position report
    * FCA EFET Extended ISO 20022 position report
    <!--@formatter:on-->
  </cms-info>
  <p-messages [value]="messages$ | async" [closable]="true" class="col-12"></p-messages>
  <cms-upload-panel *ngIf="uploadPaneVisible$ | async"
                    [maxFileSize]="maxUploadFileSizeInBytes$ | async"
                    [inBytesSize]="true"
                    [title]="'Upload Position report file (.xml)'"
                    (upload)="onUpload($event)"
                    (error)="onUploadError($event)">
                  </cms-upload-panel>
  <cms-file-filters-panel [filters]="filters$ | async"
                          [options]="options$ | async"
                          [disabled]="loading$ | async"
                          (reset)="onReset()"
                          (searchByFilename)="onSearchByFilename($event)"
                          (searchByTime)="onSearchByTime($event)"
  ></cms-file-filters-panel>
  <cms-datatable *ngIf="viewBy$ | async"
                 selectionMode="single"
                 [currentPage]="(paging$ | async).page"
                 [tableColumns]="columns$ | async"
                 [sortField]="(sorting$ | async).columnName"
                 [sortOrder]="(sorting$ | async).sortOrder"
                 [loading]="loading$ | async"
                 [data]="(data$ | async)?.values"
                 [totalRecords]="(data$ | async)?.count"
                 [autoLayout]="true"
                 [scrollable]="false"
                 [lazy]="!!((data$ | async)?.values.length)"
                 (lazyLoad)="onLazyLoad($event)">
    <ng-template pTemplate="caption">
      <div class="row margin-0 table-caption">
        <div class="col text-end">
          <button class="btn export-btn" pButton type="button" icon="fa fa-download" label="Export"
                  (click)="onExport()"
                  [disabled]="!(data$ | async)"></button>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="body" let-row="row" let-column="column">
      <!--document (xml) button-->
      <td *ngIf="column.type === 'button' && column.name === 'openDocument'" [ngStyle]="column.style">
        <div class="btn-wrapper inline-block">
          <button pButton type="button" icon="fa fa-file-code-o" title="Document"
                  (click)="onDownloadDocument(row)"></button>
        </div>
      </td>
      <!--audit trail button-->
      <td *ngIf="column.type === 'button' && column.name === 'auditTrail'" [ngStyle]="column.style">
        <div class="btn-wrapper inline-block">
          <a routerLink="/err/mifid_audittrail" title="Audit Trail" target="_blank"
             [queryParams]="getDisplayParams(row)">
            <button pButton type="button" icon="fa fa-search" title="Audit Trail"></button>
          </a>
        </div>
      </td>
      <!-- details button -->
      <td *ngIf="column.type === 'button' && column.name === 'details'" [ngStyle]="column.style">
        <div class="btn-wrapper inline-block">
          <a [queryParams]="getDetailsParams(row)" routerLink="/err/mifid/details" title="Details" target="_blank">
            <button pButton type="button" icon="fa fa-search-plus" title="Details"></button>
          </a>
        </div>
      </td>
      <!-- confirm button -->
      <td *ngIf="column.type === 'button' && column.name === 'confirm' && column.visible === true" [ngStyle]="column.style">
        <div class="btn-wrapper inline-block" *ngIf="row.confirmButtonVisible">
          <button type="button" (click)="onConfirm(row)">Confirm</button>
        </div>
      </td>
    </ng-template>
  </cms-datatable>
</div>

</ng-template>
