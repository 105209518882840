import {
  AfterViewInit, ChangeDetectionStrategy,
  Component, EventEmitter, Input,
  OnChanges, OnDestroy,
  OnInit, Output, QueryList,
  SimpleChange, ViewChildren
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CellEditorDirective } from '@common/datatable/cell-editor/cell-editor.directive';
import { UserAccount } from '../../user-accounts/user-accounts.model';
import { FhaAccount } from '../../fha-accounts/fha-accounts.model';
import { Dropdown } from 'primeng/dropdown';

export type userStates = 'ACTIVE' | 'INACTIVE' | 'LOCKED';

export interface EditableState {
  data: UserAccount | FhaAccount;
  newValue: userStates;
}

@Component({
  selector: 'cms-editable-status-cell',
  templateUrl: './editable-status-cell.component.html',
  styleUrls: ['./editable-status-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditableStatusCellComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() state: userStates;
  @Input() row: UserAccount | FhaAccount;
  @Input() selected: EditableState;
  @Input() editable = true;
  @Output() editComplete = new EventEmitter<EditableState>();
  @Output() initEdit = new EventEmitter<EditableState>();
  @Output() cancelEdit = new EventEmitter();
  @ViewChildren(Dropdown) inputEl$: QueryList<Dropdown>;
  edit = false;
  clickSubscription$: Subscription;
  keydownSubscription$: Subscription;
  statesOptions: {label: string, value: userStates, invisible?: boolean}[] = [];
  form: UntypedFormGroup;

  constructor(public editableColumn: CellEditorDirective) { }

  ngAfterViewInit(): void {
    this.inputEl$.changes.subscribe((elementRef) => {
      if (elementRef.length) {
        setTimeout(() => {
          if (elementRef.first) {
            elementRef.first.focus();
          }
        });
      }
    });
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if ((changes.row || changes.state) && this.form && this.row) {
      this.setToDefault();
    }

    if (changes.selected && this.edit && !(this.selected && this.selected.data === this.row)) {
      this.setToDefault();
    }
  }

  onChange(): void {
    if (this.state !== this.form.controls['value'].value) {
      this.editComplete.emit({ data: this.row, newValue: this.form.value.value });
    }
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      value: new UntypedFormControl(this.state),
    });

    this.initOptions();

    this.clickSubscription$ = this.editableColumn.click$.subscribe(() => {
      if (this.editable && !this.edit) {
        this.initEdit.emit({
          data: this.row,
          newValue: this.form.value.value
        });
        this.edit = true;
      }
    });

    this.keydownSubscription$ = this.editableColumn.keydown$
      .subscribe((event: KeyboardEvent) => {
        if (this.editable && this.edit) {
          // enter
          if (event.keyCode === 13) {
            this.completeEditing();
            event.preventDefault();
          } else if (event.keyCode === 27) {
            this.setToDefault();
            this.cancelEdit.emit();

            event.preventDefault();
          }
        }
      });
  }

  private initOptions(): void {
    switch (this.state) {
      case 'ACTIVE':
        this.statesOptions = [
          { label: 'Active', value: 'ACTIVE' },
          { label: 'Inactive', value: 'INACTIVE' },
          { label: 'Locked', value: 'LOCKED' },
        ];
        break;
      case 'INACTIVE':
        this.statesOptions = [
          { label: 'Inactive', value: 'INACTIVE' },
          { label: 'Active', value: 'ACTIVE' },
        ];
        break;
      case 'LOCKED':
        this.statesOptions = [
          { label: 'Locked', value: 'LOCKED' },
          { label: 'Active', value: 'ACTIVE' },
          { label: 'Inactive', value: 'INACTIVE' },
        ];
    }
  }

  private completeEditing(): void {
    if (this.state !== this.form.controls['value'].value) {
      this.editComplete.emit({ data: this.row, newValue: this.form.value.value });
    } else {
      this.setToDefault();
      this.editComplete.emit(null);
    }
  }

  setToDefault(): void {
    this.form.setValue({
      value: this.state
    });
    this.edit = false;
  }

  ngOnDestroy(): void {
    this.clickSubscription$.unsubscribe();
    this.keydownSubscription$.unsubscribe();
  }
}
