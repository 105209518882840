import { Message } from 'primeng/api';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import { Action, select, Store } from '@ngrx/store';
import { ApiRequestService } from '@common/api-request.service';
import { EsmNettingDashboardState } from './dashboard.reducers';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { EsmNettingDashboardFiltersState } from './filters/filters.reducers';
import * as fromDBActions from './dashboard.actions';
import * as fromFiltersSelectors from './filters/filters.selectors';
import * as fromDBTableActions from './table/table.actions';
import * as fromDBSelectors from './dashboard.selectors';
import {
  EsmNettingDashboardBuckets,
  FilterRequestModel,
  EsmNettingDashboardTableType
} from '../../../netting/dashboard/esm-netting-dashboard.model';
import { DashboardDealsNavModel } from '@common/dashboard-deals-nav/dashboard-deals-nav.model';

@Injectable()
export class EsmNettingDashboardEffects {
  private baseUrl = '/api/esm/nettingstatement/dashboard';

  constructor(private apiGateway: ApiRequestService,
              private actions$: Actions,
              private store$: Store<EsmNettingDashboardState>,
              private mapper: MessagesMapperService) { }

  getStateNames$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromDBActions.getStateNamesAction.type),
      withLatestFrom(
        this.store$.pipe(select(fromFiltersSelectors.getFilters)),
        this.store$.pipe(select(fromDBSelectors.getStateNames))),
      switchMap(([, filters, stateNames]: [Action, EsmNettingDashboardFiltersState, DashboardDealsNavModel[]]) => {
        if (!stateNames || !stateNames.length) {
          return this.loadData(filters);
        }
        return [fromDBTableActions.setMessagesAction([])];
      }))
  );

  private loadData(filters: EsmNettingDashboardFiltersState) {
    const { counterParties, commodities, deliveryPoints, nettingStatementTypes, paymentDateRange, invoiceStartDate, invoiceEndDate } = filters.value;
    const { nettingStatementRole, category } = filters;
    const request: FilterRequestModel = {
      commodities,
      deliveryPoints,
      nettingStatementTypes,
      counterParties,
      nettingStatementRole: nettingStatementRole,
      category,
      invoiceStartDate,
      invoiceEndDate,
      paymentDateRange
    };
    return this.apiGateway
      .post(`${this.baseUrl}/${EsmNettingDashboardTableType.BUCKETS}`, request)
      .pipe(
        switchMap((response: EsmNettingDashboardBuckets) => {
          const messages: Message[] = this.mapper.toErrorMessages(response);
          if (messages && messages.length) {
            return [fromDBTableActions.setMessagesAction(messages)];
          }
          return [
            fromDBActions.setStateNamesAction(response.stateNames),
            fromDBTableActions.setMessagesAction([]),
          ];
        }),
        catchError(error => of(fromDBTableActions.setMessagesAction([this.mapper.createErrorMessage(error.errorMessage)])))
      );
  }
}
