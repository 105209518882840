import { Message } from 'primeng/api';
import * as fromAction from './enter-trade.actions';
import {
  EcmEnterTradeFormData,
  EcmEnterTradeFormValues,
  EcmEnterTradeProduct,
  EcmEnterTradeTotalVolumeData
} from '../../enter-trade/enter-trade.model';

export interface EcmEnterTradeState {
  organisationId?: number;
  messages: Message[];
  productId: number;
  formValues: EcmEnterTradeFormValues;
  formData: EcmEnterTradeFormData;
  editableData: EcmEnterTradeFormData;
  editableId: number;
  isEditable: boolean;
  productsList: EcmEnterTradeProduct[];
  deliveryPeriod: string;
  totalVolumeData: EcmEnterTradeTotalVolumeData;
  totalContractValue: object;
}

export const initialState: EcmEnterTradeState = {
  organisationId: null,
  messages: [],
  productId: null,
  formValues: null,
  editableData: null,
  formData: null,
  productsList: [],
  deliveryPeriod: null,
  totalVolumeData: null,
  totalContractValue: null,
  isEditable: false,
  editableId: null,
};

export function ecmEnterTradeReducer(
  state: EcmEnterTradeState = { ...initialState },
  action: fromAction.EcmEnterTradeActions) {
  switch (action.type) {
    case fromAction.SET_MESSAGES:
      return {
        ...state,
        messages: [...(action as fromAction.SetMessagesAction).payload]
      };
    case fromAction.SET_TO_DEFAULT:
      return { ...initialState };
    case fromAction.SET_TO_CLEAR_FORM:
      return {
        ...initialState,
        productsList: state.productsList
      };
    case fromAction.SET_ORGANISATION_ID:
      return {
        ...state,
        organisationId: (action as fromAction.SetOrganisationIdAction).payload
      };
    case fromAction.GET_PRODUCTS_LIST_SUCCESS:
      return {
        ...state,
        productsList: (action as fromAction.GetProductsListSuccessAction).payload.values,
      };

    case fromAction.GET_FORM_VALUES_SUCCESS:
      return {
        ...state,
        formValues: (action as fromAction.GetFormValuesSuccessAction).payload,
      };

    case fromAction.SET_FORM_DATA:
      return {
        ...state,
        formData: (action as fromAction.SetFormDataAction).payload,
      };

    case fromAction.SET_PRODUCT_ID:
      return {
        ...state,
        productId: action.payload ? action.payload.productId : null
      };

    case fromAction.GET_DELIVERY_PERIOD_SUCCESS:
      return {
        ...state,
        deliveryPeriod: action.payload.response
      };

    case fromAction.GET_TOTAL_VOLUME_SUCCESS:
      return {
        ...state,
        totalVolumeData: action.payload
      };
    case fromAction.GET_TOTAL_CONTRACT_SUCCESS:
      return {
        ...state,
        totalContractValue: action.payload.value
      };
    case fromAction.GET_EDITABLE_ITEM:
      return {
        ...state,
        editableId: action.payload,
      };
    case fromAction.GET_EDITABLE_ITEM_SUCCESS:
      return {
        ...state,
        editableData: action.payload
      };
    default:
      return state;
  }
}


export const getMessages = (state: EcmEnterTradeState) => state.messages;
export const getProductId = (state: EcmEnterTradeState) => state.productId;
export const getFormData = (state: EcmEnterTradeState) => state.formData;
export const getEditableData = (state: EcmEnterTradeState) => state.editableData;
export const getEditableId = (state: EcmEnterTradeState) => state.editableId;
export const getFormValues = (state: EcmEnterTradeState) => state.formValues;
export const getOrganisationId = (state: EcmEnterTradeState) => state.organisationId;
export const getProductsList = (state: EcmEnterTradeState) => state.productsList;
export const getDeliveryPeriod = (state: EcmEnterTradeState) => state.deliveryPeriod;
export const getTotalVolumeData = (state: EcmEnterTradeState) => state.totalVolumeData;
export const getTotalContractValue = (state: EcmEnterTradeState) => state.totalContractValue;
export const getCapacityValue = (state: EcmEnterTradeState) => state.formData ? state.formData.capacityValue : null;
export const getPriceValue = (state: EcmEnterTradeState) => state.formData ? state.formData.priceValue : null;
export const getPeriodsValues = (state: EcmEnterTradeState) => {
  if (!state.formData) { return null;}
  return {
    deliveryEndDate: state.formData.deliveryEndDate,
    deliveryEndTime: state.formData.deliveryEndTime,
    deliveryStartDate: state.formData.deliveryStartDate,
    deliveryStartTime: state.formData.deliveryStartTime,
  };
};
