import { ErrorResponse } from '@common/error-response.model';
import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import { Column } from '@common/shared/datatable.model';


// =========  loadTable mask
export interface PresetValues {
  allowedToUpload: boolean;
  maxUploadFileSizeInBytes: number;
  organisationNames: string[];
}

export type PresetValuesResponse = PresetValues & ErrorResponse;

export interface UploadResponse extends ErrorResponse {
  uploadMetaData: UploadMetaData;
}

export interface UploadMetaData {
  identifier:number;
  countFound: number;
  countDuplicate: number;
  countNotFound: number;
  countNoPermission: number;
  countIssue: number;
  notFound: string[];
  noPermission: string[];
  duplicates: string[];
  issues: string[];
}

// =========  table loadTable
export interface TerminateEntry {
  id: number;
  fileName: string;
  uploadTimestamp: Date;
  foundCount: number;
  notFoundCount: number;
  duplicateCount: number;
  withoutPermissionsCount: number;
  issueCount: number;
  downloadResultZip: boolean;
}

export interface Filter {
  filename?: string;
  timeRange: TimeRanges;
  queryType: FilterType;
}

export interface TimeRanges {
  terminationDate: string;
  submission: { date: string, time: string };
}

export enum FilterType {
  ByDate = 'timeRange',
  ByFilename = 'filename'
}

export interface FilterByDateRequest {
  timeRange: TimeRanges;
  paging: PagingFilter;
  sorting: SortingFilter;
}

export interface FilterByNameRequest {
  filename: string;
  paging: PagingFilter;
  sorting: SortingFilter;
}

export interface FileResponse extends ErrorResponse {
  values: TerminateEntry[];
  recordCount: number;
  currentPage: number;
}

const defaultStyles = {
  'text-align': 'left',
  'text-overflow': 'ellipsis',
  'overflow': 'hidden',
  'white-space': 'nowrap',
  'width':'9%'
};

export const tableColumns: Column[] = [
  { name: 'fileName', type: 'text', title: 'File Name', style: { ...defaultStyles, 'width':'auto'}, sortable: true, visible: true },
  { name: 'uploadTimestamp', type: 'date', title: 'Upload Time', style: { ...defaultStyles,'width': '166px'}, sortable: true, visible: true },
  { name: 'foundCount', type: 'text', title: 'UTIs Found', style: { ...defaultStyles}, sortable: false, visible: true },
  { name: 'duplicateCount', type: 'button', title: 'Duplicates', style: { ...defaultStyles}, sortable: false, visible: true },
  { name: 'notFoundCount', type: 'button', title: 'Not Found', style: { ...defaultStyles}, sortable: false, visible: true },
  { name: 'withoutPermissionCount', type: 'button', title: 'Without Permissions', style: { ...defaultStyles,'width':'10%' }, sortable: false, visible: true },
  { name: 'issueCount', type: 'button', title: 'Manual Termination', style: { ...defaultStyles}, sortable: false, visible: true },
  { name: 'downloadResultZip', type: 'button', title: 'Download Zip', style: { ...defaultStyles, 'width':'7%' }, sortable: false, visible: true },
  { name: 'id', type: null, title: null, style: null, sortable: false, visible: false},
];
