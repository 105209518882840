import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { Message } from 'primeng/api';

import { OrganisationService } from '@common/organisation.service';
import { Column } from '@common/shared/datatable.model';
import { OrganisationModel } from '@common/organisation.model';

import { EcmSettingsBrokerRestrictionsState } from '../../../state/settings/broker-restrictions/broker-restrictions.reducers';
import { allColumnsConfig, EcmSettingsBrokerRestrictionsData } from './broker-restrictions.model';
import * as fromSelectors from '../../../state/settings/broker-restrictions/broker-restrictions.selectors';
import * as fromAction from '../../../state/settings/broker-restrictions/broker-restrictions.actions';

@Component({
  selector: 'cms-broker-restrictions',
  templateUrl: './broker-restrictions.component.html',
  styleUrls: ['./broker-restrictions.component.scss'],
})
export class BrokerRestrictionsComponent implements OnInit, OnDestroy {
  messages$: Observable<Message[]> = this.store.pipe(select(fromSelectors.getMessages));
  data$: Observable<EcmSettingsBrokerRestrictionsData> = this.store.pipe(select(fromSelectors.getData));
  subscription$: Subscription;
  maxFileSize$: Observable<number> = this.store.pipe(select(fromSelectors.getMaxUploadSize));
  allowedToUpload$: Observable<boolean> = this.store.pipe(select(fromSelectors.getAllowedToUpload));
  loading$: Observable<boolean> = this.store.pipe(select(fromSelectors.getLoading));
  dialog$ = this.store.pipe(select(fromSelectors.getDialog));
  organisation: OrganisationModel = null;
  file: File = null;

  readonly dataTableColumns: Column[] = allColumnsConfig;

  constructor(private store: Store<EcmSettingsBrokerRestrictionsState>,
              private organisationService: OrganisationService) { }

  ngOnDestroy(): void {
    this.store.dispatch(new fromAction.SetToDefaultAction());
    this.subscription$.unsubscribe();
  }

  ngOnInit() {
    this.store.dispatch(new fromAction.LoadDataAction());
    this.store.dispatch(new fromAction.LoadPresetValuesAction());

    this.subscription$ = this.organisationService.getOrganisationChangeIfAccessible()
      .subscribe(() => {
        this.store.dispatch(new fromAction.SetToDefaultAction());
        this.store.dispatch(new fromAction.LoadDataAction());
        this.store.dispatch(new fromAction.LoadPresetValuesAction());
      });
  }

  onDownloadXML(): void {
    this.store.dispatch(new fromAction.DownloadXmlAction());
  }

  onUpload(file: File): void {
    this.file = file;
    this.store.dispatch(new fromAction.OpenDialog());
  }

  onUploadError(messages: Message[]): void {
    this.store.dispatch(new fromAction.SetMessagesAction(messages));
  }

  onCancelUpload() {
    this.store.dispatch(new fromAction.ConfirmDialogCancel());
  }

  onConfirmUpload() {
    if (this.file) {
      this.store.dispatch(new fromAction.UploadAction(this.file));
    }
    this.store.dispatch(new fromAction.CloseDialog());
  }
}
