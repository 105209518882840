import {createAction, union} from '@ngrx/store';
import {Message} from 'primeng/api';
import { DatatableConfig, PagingFilter } from '@common/shared/results.model';
import {Column} from '@common/shared/datatable.model';
import {EsmInvoiceFinderDeal} from '../../../invoice/dashboard/esm-dashboard.model';

export const setMessagesAction = createAction('[ESM DASHBOARD] Set Messages', (payload: Message[]) => ({payload}));
export const sortAndPageDataAction = createAction('[ESM DASHBOARD] Sort And Page Data', (payload: DatatableConfig) => ({payload}));
export const setColumnsAction = createAction('[ESM DASHBOARD] Set Columns', (payload: Column[]) => ({payload}));
export const exportAction = createAction('[ESM DASHBOARD] Export');
export const setSelectedIdsAction = createAction('[ESM DASHBOARD] Set Selected Ids Action', (payload: { confirmationIds: string[] }) => ({payload}));
export const setSelectedInvoicesAction = createAction('[ESM DASHBOARD] Set Selected Invoices Action', (payload: { invoices: EsmInvoiceFinderDeal[] }) => ({payload}));
export const resendPDFAction = createAction('[ESM DASHBOARD] Resend PDF Action', (payload: number) => ({payload}));
export const openAgreeDialogAction = createAction('[ESM DASHBOARD] Agree the Invoice', (payload: string[]) => ({payload}));
export const setTablePagingStateAction = createAction('[ESM DASHBOARD] Set Table Paging State', (payload: {paging: PagingFilter}) => ({payload}));

const actions = union({
  setMessagesAction,
  sortAndPageDataAction,
  setColumnsAction,
  exportAction,
  setSelectedIdsAction,
  setSelectedInvoicesAction,
  openAgreeDialogAction,
  setTablePagingStateAction
});

export type DashboardTableActions = typeof actions;
