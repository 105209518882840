<h1>Match PDF Email Subscriptions of {{(organisationService.getChangeOrganisationObservable() | async)?.displayName}}
  ({{(organisationService.getChangeOrganisationObservable() | async)?.eicCode}})</h1>

<div class="panel-box">
  <cms-info ngPreserveWhitespaces>
    <u>__eSM PDF Email Subscriptions__</u>

    __Purpose of the PDF email subscriptions__

    An eSM organisation can opt to receive the email notifications with attached PDFs and XMLs of an invoice or netting statement matched by the eSM process. The email will be sent immediately after the match.

    To do so, a master operator of the eSM organisation must activate the respective options and make sure that email recipients of the selected organisation are configured in the eSM Email Whitelist.

    __Checkboxes underneath 'Receive Email with PDF for ...'__

    * __Matched Invoice__ - Check this option to receive an email with the PDF(s) of the matched invoice(s) in case of a eSM invoice match.
    * __Matched Netting Statement__ - Check this option to receive an email with the PDF(s) of the matched netting statement(s) in case of a eSM netting match.
    * __Matched Invoice XML__ - Check this option to receive an email with the XML(s) of the matched invoice(s) in case of a eSM invoice match.
    * __Matched Netting Statement XML__ - Check this option to receive an email with the XML(s) of the matched netting statement(s) in case of a eSM netting match.

    __Options underneath 'Document Type...'__

    * __Official (INV/NET)__ - Activate this option to only receive a PDF of the official invoice or official netting statement in case of eSM match in the respective invoice or netting process.
    * __Shadow/Reverse (INV/NET)__ - Activate this option to only receive a PDF of the shadow invoice or the PDF/XML reverse netting statement in case of eSM match in the respective invoice or netting process. *Please Note:* Starting with the CMS release 6.10, the PDF rendering of shadow invoices is discontinued! It is not possible anymore to subscribe to receive the PDF for a matched shadow invoice alone. It is still
    possible to receive the shadow invoice XML of a matched invoice, to do so, activate the option 'Matched Invoice XML' (see above).
    * __Both__ - Activate this option to receive PDF of the official invoice/netting statement and the shadow invoice/reverse netting statement in case of eSM match in the respective invoice or netting process.

    __Options underneath 'Document Side...'__

    * __Own document__ - Activate this option to only receive a PDF of your own document (invoice|netting statement) in case of eSM match in the respective invoice or netting process.
    * __CP document__ - Activate this option to only receive a PDF of the counterparty document (invoice|netting statement) in case of eSM match in the respective invoice or netting process.
    * __Both documents__ - Activate this option to receive a PDF of your own document (invoice|netting statement) and of the counterparty document (invoice|netting statement) in case of eSM match in the respective invoice or netting process.

    __Table 'Email Whitelist Recipients configured for "eSM party name"__

    This table shows the email addresses which are currently configured per VAT ID of your own organisation and which will be used to send out the emails with eSM PDFs as configured in this page. All email addresses are maintained on the 'eSM Email Whitelist' settings page. If you want to edit the email recipients shown for your organisation, this must be done by uploading an edited email whitelist in the eSM Email Whitelist
    settings screen.

  </cms-info>

  <p-messages [value]="messages$ | async" [closable]="true"></p-messages>
  <div class="row">
    <div class="col-5">
      <div class="panel-box mt-3 mb-3 ">
        <fieldset>
          <legend class="!font-bold" style="font-size: 14px" >Receive Email with ...</legend>
          <div class="p-field-checkbox">
            <p-checkbox inputId="subscription_check_box_invoice_pdf"
                        [disabled]="!(editAllowed$|async)"
                        [ngModel]="matchedInvoicePdf$ | async" binary="true"
                        (ngModelChange)="onMatchedInvoicePdfChanged($event)"
                        class="m-2">
            </p-checkbox>
            <label>Matched Invoice PDF</label>
          </div>
          <div class="p-field-checkbox">
            <p-checkbox inputId="subscription_check_box_netting_pdf"
                        [disabled]="!(editAllowed$|async)"
                        [ngModel]="matchedNettingPdf$ | async" binary="true"
                        (ngModelChange)="onMatchedNettingPdfChanged($event)"
                        class="m-2">
            </p-checkbox>
            <label>Matched Netting Statement PDF</label>
          </div>
          <div class="p-field-checkbox">
            <p-checkbox inputId="subscription_check_box_invoice_xml"
                        [disabled]="!(editAllowed$|async)"
                        [ngModel]="matchedInvoiceXml$ | async" binary="true"
                        (ngModelChange)="onMatchedInvoiceXmlChanged($event)"
                        class="m-2">
            </p-checkbox>
            <label>Matched Invoice XML</label>
          </div>
          <div class="p-field-checkbox">
            <p-checkbox inputId="subscription_check_box_netting_xml"
                        [disabled]="!(editAllowed$|async)"
                        [ngModel]="matchedNettingXml$ | async" binary="true"
                        (ngModelChange)="onMatchedNettingXmlChanged($event)"
                        class="m-2">
            </p-checkbox>
            <label>Matched Netting Statement XML</label>
          </div>
        </fieldset>
      </div>
      <div class="panel-box mt-3 mb-3 ">
        <div class="row">
          <div class="col-6">
            <fieldset>
              <legend class="!font-bold" style="font-size: 14px" >Document Type ...</legend>
              <div class="container row">
                <div class="col-12">
                  <label><input type="radio"
                                id="OFFICIAL" [value]="DocumentType.OFFICIAL"
                                [disabled]="!(editAllowed$|async)"
                                (click)="setDocumentType(DocumentType.OFFICIAL)"
                                [checked]="(documentType$ | async) === DocumentType.OFFICIAL"
                                class="margin-right-9"
                                name="documentType"/>Official (INV/NET)</label>
                </div>
                <div class="col-12">
                  <label><input type="radio"
                                id="SHADOW_REVERSE" [value]="DocumentType.SHADOW_REVERSE"
                                [disabled]="!(editAllowed$|async)"
                                (click)="setDocumentType(DocumentType.SHADOW_REVERSE)"
                                [checked]="(documentType$ | async) === DocumentType.SHADOW_REVERSE"
                                class="margin-right-9"
                            name="documentType"/>Shadow/Reverse (INV/NET)</label>
                </div>
                <div class="col-12">
                  <label><input type="radio"
                                id="BOTH" [value]="DocumentType.BOTH"
                                [disabled]="!(editAllowed$|async)"
                                (click)="setDocumentType(DocumentType.BOTH)"
                                [checked]="(documentType$ | async) === DocumentType.BOTH"
                                class="margin-right-9"
                                name="documentType"/>Both</label>
                </div>
              </div>
            </fieldset>
          </div>

          <div class="col-6">
            <fieldset>
              <legend class="!font-bold" style="font-size: 14px" >Document Side ...</legend>
              <div class="row">
                <div class="col-12">
                  <label><input type="radio"
                                id="OWN_DOCUMENT" [value]="DocumentSide.OWN_DOCUMENT"
                                [disabled]="!(editAllowed$|async)"
                                (click)="setDocumentSide(DocumentSide.OWN_DOCUMENT)"
                                [checked]="(documentSide$ | async) === DocumentSide.OWN_DOCUMENT"
                                class="margin-right-9"
                                name="documentSide"/>Own Document</label>
                </div>
                <div class="col-12">
                  <label><input type="radio"
                                id="CP_DOCUMENT" [value]="DocumentSide.CP_DOCUMENT"
                                [disabled]="!(editAllowed$|async)"
                                (click)="setDocumentSide(DocumentSide.CP_DOCUMENT)"
                                [checked]="(documentSide$ | async) === DocumentSide.CP_DOCUMENT"
                                class="margin-right-9"
                                name="documentSide"/>CP Document</label>
                </div>
                <div class="col-12">
                  <label><input type="radio"
                                id="BOTH_DOCUMENTS" [value]="DocumentSide.BOTH"
                                [disabled]="!(editAllowed$|async)"
                                (click)="setDocumentSide(DocumentSide.BOTH)"
                                [checked]="(documentSide$ | async) === DocumentSide.BOTH"
                                class="margin-right-9"
                                name="documentSide"/>Both Document</label>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>

    </div>

    <div class="col-7 mt-3">
      <fieldset>
        <legend class="!font-bold" style="font-size: 14px"> Email whitelist recipients configured for {{(organisationService.getChangeOrganisationObservable() | async)?.displayName}} ({{(organisationService.getChangeOrganisationObservable() | async)?.eicCode}})</legend>
      </fieldset>
      <p-table id="whitelist_recipients_table"
               [paginator]="false"
               [columns]="columns"
               [value]="(data$ | async)"
               [autoLayout]="true"
               [scrollable]="true"
               [lazy]="true"
               scrollHeight="215px"
      >
        <ng-template pTemplate="header">
          <tr>
            <ng-container *ngFor="let col of columns">
              <th *ngIf="col.visible">{{col.title}}
              </th>
            </ng-container>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <ng-container *ngFor="let col of columns"  >
              <td *ngIf="col.visible" [ngStyle]="col.style" [pTooltip]="rowData[col.name]" tooltipPosition="top"  >
                <span>{{rowData[col.name]}}</span>
              </td>
            </ng-container>
          </tr>
        </ng-template>
      </p-table>
    </div>

  </div>

</div>
