<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>

<cms-headline [pageTitle]="'REMIT File Download'" [displayOrganisationName]="true"></cms-headline>
<div class="panel-box margin-bottom-15">
  <cms-info ngPreserveWhitespaces>
    <!--@formatter:off (markdown rendering breaks, when the following tags are formatted)-->
    __REMIT File Download__

    **Purpose**

    * Access all report and response **files exchanged between eRR and ACER** for your organisation
    * Swiss clients additionally find files reported to ELCOM under StromVV
    * Show record volumes, response times and success rates for each day
    * This screen only reflects records accepted by eRR and actually sent out to the repository
    * This screen is restricted to privileged master operator (MO) users

    **Searching for Files**

    * Pick a submission date and press Search
    * This returns all files submitted to the repository on the chosen date (00:00 to 24:00 UK time)
    * Market Participants (MPs) can search in roles
        * sender = find reported data **submitted by them** (including data sent as agent for other parties)
        * MP = find data reported **on their behalf** (regardless of submitter)
    * Note: fundamental data reports (LNG, gas storage) can only be found in **sender** role

    **Information in the File List**

    | Column                   | Description                  |
    | :----------------------- |:-- |
    | Repository            | receiver of data (ACER, ELCOM) |
    | Reported (UK time)             | transmission time of report data to repository (local UK time) |
    | Icon "Report File (XML)"        | download the report file exactly as transmitted |
    | Icon "Audit Trail"       | view transmission details of the report file and response (if received already) |
    | Response (UK time)        | time at which repository returned a receipt file or empty if response outstanding <br>*note: ELCOM does not return receipts presently - only a file transmission acknowledgement* |
    | Icon "Receipt (XML)"      | download the receipt file exactly as returned by repository <br>*note: ELCOM does not return receipts presently* |
    | Document Type       | reporting format |
    | Records / ACC / REJ / PEN     | number of report records contained in the file and their respective current state <br>ACC = records accepted by repository <br>REJ = records rejected by repository <br>PEN = records for which a response is outstanding |
    | Sender Name      | name of data originator (CMS-internal display name) |
    | MP Name       | name of MP (CMS-internal name for CMS parties, otherwise LEI-registered name or reported code) <br>*field not applicable to fundamental data reports (LNG, gas storage)* |
    | OMP Name       | name of the organized market place (CMS-internal display name) <br>*only applicable to trade and order records submitted by the OMP* |
    | Filename       | name of report file transmitted to ACER <br>*note: the ELCOM interface does not use filenames*|
    <!--@formatter:on-->
  </cms-info>
  <cms-remit-files-out-search></cms-remit-files-out-search>
</div>
<cms-remit-files-out-datatable></cms-remit-files-out-datatable>

</ng-template>
