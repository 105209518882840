<p-dialog *ngIf="display"
          [visible]="display"
          [modal]="true"
          (visibleChange)="onVisibleChange($event)"
          appendTo="body"
          styleClass="details-view-dialog"
          [style]="{width: '900px'}"
          [header]="header">
  <p-messages [value]="messages" [closable]="true"></p-messages>
  <div class="m-3">
    <form [formGroup]="form" class="row text-medium" autocomplete="off">
      <div class="col-md-6 col-12">
        <div class="row">
          <label class="col form__label">Type:</label>
          <div class="col">{{data?.positionAction}}</div>
        </div>

        <div class="row">
          <label class="col form__label" for="riskReducingIndicator">
            Risk Reducing Indicator: <span class="asterisk"></span></label>
          <div class="col">
            <input id="riskReducingIndicator" type="checkbox"
                   formControlName="riskReducingIndicator"
                   [ngClass]="{'error': errors['posQuantity']}">
          </div>
        </div>
        <div class="row">
          <label class="col form__label" for="posQuantity">
            Position Quantity:<span class="asterisk"></span></label>
          <div class="col">
            <input id="posQuantity" type="text" formControlName="posQuantity"
                   class="form-control"
                   [ngClass]="{'error': errors['posQuantity']}"
                   maxlength="25"
                   pInputText [pKeyFilter]="'num'">
          </div>
        </div>
        <div class="row">
          <label class="col form__label" for="posDelta">
            Position Quantity Delta:</label>
          <div class="col">
            <input id="posDelta" type="text" formControlName="posDelta"
                   class="form-control"
                   [ngClass]="{'error': errors['posDelta']}"
                   maxlength="25"
                   pInputText [pKeyFilter]="'num'">
          </div>
        </div>
        <div class="row">
          <label class="col form__label" for="posUOM">
            Position UOM:</label>
          <div class="col">
            <p-dropdown id="posUOM" formControlName="posUOM"
                        [options]="positionUOMCodesOptions"
                        [showClear]="false"
                        [style]="{'width':'100%'}"
                        [ngClass]="{'error': errors['posUOM']}">
            </p-dropdown>
          </div>
        </div>
        <div class="row">
          <label class="col form__label" for="posUomDetails">
            Position UOM Details:</label>
          <div class="col">
            <input id="posUomDetails" type="text"
                   formControlName="posUomDetails"
                   class="form-control"
                   [ngClass]="{'error': errors['posUomDetails']}"
                   maxlength="25">
          </div>
        </div>
        <div class="row">
          <label class="col form__label" for="isin">
            ISIN:<span class="asterisk"></span></label>
          <div class="col">
            <input id="isin" type="text"
                   formControlName="isin"
                   class="form-control"
                   [ngClass]="{'error': errors['isin']}"
                   maxlength="12">
          </div>
        </div>
        <div class="row">
          <label class="col form__label" for="reportingDate">
            Reporting Date:<span class="asterisk"></span></label>
          <div class="col">
            <input id="reportingDate" type="text"
                   formControlName="reportingDate"
                   class="form-control"
                   [ngClass]="{'error': errors['reportingDate']}"
                   maxlength="100">
          </div>
        </div>
        <div class="row">
          <label class="col form__label" for="tradeDate">
            Trade Date:<span class="asterisk"></span></label>
          <div class="col">
            <input id="tradeDate" type="text"
                   formControlName="tradeDate"
                   class="form-control"
                   [ngClass]="{'error': errors['tradeDate']}"
                   maxlength="10">
          </div>
        </div>
        <div class="row">
          <label class="col form__label" for="reportingEntity">
            Reporting Entity:<span class="asterisk"></span></label>
          <div class="col">
            <input id="reportingEntity" type="text"
                   formControlName="reportingEntity"
                   class="form-control"
                   [ngClass]="{'error': errors['reportingEntity']}"
                   maxlength="20">
          </div>
        </div>

        <ng-container *ngIf="isFCAPlus">
          <div class="row">
            <label class="col form__label" for="reportingEntityMemberId">
              Reporting Entity Member ID:</label>
            <div class="col">
              <input id="reportingEntityMemberId" type="text"
                     formControlName="reportingEntityMemberId"
                     class="form-control"
                     [ngClass]="{'error': errors['reportingEntityMemberId']}"
                     maxlength="35">
            </div>
          </div>
          <div class="row">
            <label class="col form__label" for="posAccount">
              Position Account:</label>
            <div class="col">
              <input id="posAccount" type="text"
                     formControlName="posAccount"
                     class="form-control"
                     [ngClass]="{'error': errors['posAccount']}"
                     maxlength="200">
            </div>
          </div>
          <div class="row">
            <label class="col form__label" for="posHolderCategory">
              Position Holder Category:</label>
            <div class="col">
              <p-dropdown id="posHolderCategory"
                          formControlName="posHolderCategory"
                          [options]="positionHolderCategoryOptions"
                          [showClear]="false"
                          appendTo="body"
                          panelStyleClass="details-dropdown"
                          [style]="{'width':'100%'}"
                          [ngClass]="{'error': errors['posHolderCategory']}">
              </p-dropdown>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="col-md-6 col-12">
        <div class="row">
          <label class="col form__label" for="posHolder">
            Pos. Holder:<span class="asterisk"></span></label>
          <div class="col">
            <input id="posHolder" type="text"
                   formControlName="posHolder"
                   class="form-control"
                   [ngClass]="{'error': errors['posHolder']}"
                   maxlength="20">
          </div>
        </div>
        <div class="row">
          <label class="col form__label" for="posHolderEmail">
            Pos. Holder Email:<span class="asterisk"></span></label>
          <div class="col">
            <input id="posHolderEmail" type="text"
                   formControlName="posHolderEmail"
                   class="form-control"
                   [ngClass]="{'error': errors['posHolderEmail']}"
                   maxlength="255">
          </div>
        </div>
        <div class="row">
          <label class="col form__label" for="parentPosHolderEmail">
            Parent Entity Email:<span class="asterisk"></span></label>
          <div class="col">
            <input id="parentPosHolderEmail" type="text"
                   formControlName="parentPosHolderEmail"
                   class="form-control"
                   [ngClass]="{'error': errors['parentPosHolderEmail']}"
                   maxlength="255">
          </div>
        </div>
        <div class="row">
          <label class="col form__label" for="posHolderIndependent">
            Pos. Holder Is Independent: <span class="asterisk"></span></label>
          <div class="col">
            <input id="posHolderIndependent" type="checkbox"
                   formControlName="posHolderIndependent"
                   [ngClass]="{'error': errors['posHolderIndependent']}">
          </div>
        </div>
        <div class="row">
          <label class="col form__label" for="parentEntity">
            Parent Entity:<span class="asterisk"></span></label>
          <div class="col">
            <input id="parentEntity" type="text"
                   formControlName="parentEntity"
                   class="form-control"
                   [ngClass]="{'error': errors['parentEntity']}"
                   maxlength="20">
          </div>
        </div>
        <div class="row">
          <label class="col form__label" for="venueProductCode">
            Venue Product Code:</label>
          <div class="col">
            <input id="venueProductCode" type="text"
                   formControlName="venueProductCode"
                   class="form-control"
                   [ngClass]="{'error': errors['venueProductCode']}"
                   maxlength="12">
          </div>
        </div>
        <div class="row">
          <label class="col form__label" for="venueId">
            Trade Venue ID:</label>
          <div class="col">
            <input id="venueId" type="text"
                   formControlName="venueId"
                   class="form-control"
                   [ngClass]="{'error': errors['venueId']}"
                   maxlength="4">
          </div>
        </div>
        <div class="row">
          <label class="col form__label" for="posType">
            Pos. Type:</label>
          <div class="col">
            <p-dropdown id="posType"
                        formControlName="posType"
                        [options]="positionTypesOptions"
                        [showClear]="false"
                        appendTo="body"
                        panelStyleClass="details-dropdown"
                        [style]="{'width':'100%'}"
                        [ngClass]="{'error': errors['posType']}">
            </p-dropdown>
          </div>
        </div>
        <div class="row">
          <label class="col form__label" for="posMaturity">
            Pos. Maturity:</label>
          <div class="col">
            <p-dropdown id="posMaturity"
                        formControlName="posMaturity"
                        [options]="positionMaturityOptions"
                        [showClear]="false"
                        appendTo="body"
                        panelStyleClass="details-dropdown"
                        [style]="{'width':'100%'}"
                        [ngClass]="{'error': errors['posMaturity']}">
            </p-dropdown>
          </div>
        </div>

        <ng-container *ngIf="isFCAPlus">
          <div class="row">
            <label class="col form__label" for="clientNonInvestFirm">
              Client Non-Invest Firm:</label>
            <div class="col">
              <p-dropdown id="clientNonInvestFirm"
                          formControlName="clientNonInvestFirm"
                          [options]="clientOptions"
                          [showClear]="false"
                          [style]="{'width':'100%'}"
                          appendTo="body"
                          panelStyleClass="details-dropdown"
                          [ngClass]="{'error': errors['clientNonInvestFirm']}">
              </p-dropdown>
            </div>
          </div>
          <div class="row">
            <label class="col form__label" for="clientHedgingExemption">
              Client Hedging Exempt:</label>
            <div class="col">
              <p-dropdown id="clientHedgingExemption"
                          formControlName="clientHedgingExemption"
                          [options]="clientOptions"
                          [showClear]="false"
                          appendTo="body"
                          panelStyleClass="details-dropdown"
                          [style]="{'width':'100%'}"
                          [ngClass]="{'error': errors['clientHedgingExemption']}">
              </p-dropdown>
            </div>
          </div>
        </ng-container>
      </div>
    </form>
  </div>
  <p-footer>
    <button class="btn margin-right-3" type="button" (click)="onSave()">Save</button>
    <button class="btn" type="button" (click)="onCancel()">Cancel</button>
  </p-footer>
</p-dialog>
