import {createSelector} from '@ngrx/store';
import {EsmNettingStatementTableState} from './table.reducers';
import {EsmNettingStatementFinderState} from '../esm-netting-finder.reducers';
import {getEsmNettingFinderState} from '../esm-netting-finder.selectors';

export const getTable = createSelector(getEsmNettingFinderState, (state: EsmNettingStatementFinderState) => state.table);

export const getMessages = createSelector(getTable, (state: EsmNettingStatementTableState) => state.messages);

export const getData = createSelector(getTable, (state: EsmNettingStatementTableState) => state.data);
export const getSelectedIds = createSelector(getTable, (state: EsmNettingStatementTableState) => state.selectedIds);
export const getSelectedNettingStatements = createSelector(getTable, (state: EsmNettingStatementTableState) => state.nettingStatements);
export const getLoading = createSelector(getTable, (state: EsmNettingStatementTableState) => state.loading);
export const getNettingFinderHtml = createSelector(getTable, (state: EsmNettingStatementTableState) => state.nettingStatementFinderHtml);
export const isVisible = createSelector(getTable, (state: EsmNettingStatementTableState) => state.visible);
export const getPaging = createSelector(getTable, (state: EsmNettingStatementTableState) => state.paging);
export const getSorting = createSelector(getTable, (state: EsmNettingStatementTableState) => state.sorting);
export const getColumns = createSelector(getTable, (state: EsmNettingStatementTableState) => state.columns);
export const getSearchBy = createSelector(getTable, (state: EsmNettingStatementTableState) => state.searchBy);
