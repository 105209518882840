import { getFilenameFromHeader } from '@common/deals/deals.model';
import { HttpHeaders } from '@angular/common/http';
import { Action } from '@ngrx/store';
import * as FileSaver from 'file-saver';
import { ErrorResponse } from '@common/error-response.model';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { of, throwError } from 'rxjs';

export const saveBlob = ({body, headers}: {body: Blob, headers: HttpHeaders}): void => FileSaver.saveAs(body, getFilenameFromHeader(headers));

export const toPayload = <T>(action: Action & { payload: T }): T => action.payload;

export const checkErrors = (response: ErrorResponse | any) => {
  return response && MessagesMapperService.hasAnyError(response) ?
    throwError(response) :
    of(response);
};

export const getBlobContent = ({ error }: { error: Blob }) => {

  return new Promise<string>((resolve, reject) => {
    let errorString: string;

    const reader = new FileReader();
    reader.readAsText(error);

    reader.onloadend = function () {
      errorString = reader.result.toString();
      resolve(errorString);
    };
  });

};
