import {  PagingFilter, SortingFilter } from '@common/shared/results.model';
import { OrganisationModel } from '@common/organisation.model';

export interface EmailWhitelistModel {
  eicCode: string;
  vatId: string;
  email: string;
  commodity: string;
  displayName: string;
  invTemplate: string;
  boilerplate1: string;
  boilerplate2: string;
  attachXML: boolean;
  esmLightStatus: string;
}

export interface PresetValues {
  allowedToUpload: boolean;
  maxUploadSizeInBytes: number;
  pdfOffsiteEnabled:boolean;
}

export interface TableValues {
  data: { values, currentPage, count };
  paging: PagingFilter;
  sorting: SortingFilter;
}

const defaultStyles = {
  'text-align': 'left',
  'text-overflow': 'ellipsis',
  'overflow': 'hidden',
  'white-space': 'nowrap',
  'width':'9%'
};

export const defaultOrg: OrganisationModel = {
  organisationId: -1,
  displayName: '',
  eicCode: ''
};

export const esmEmailTableColumns = [
  {name: 'eic', type: 'text', title: 'EIC Code', style: {...defaultStyles}, sortable: true, visible:true },
  {name: 'vat', type: 'text', title: 'VAT ID', style: {...defaultStyles}, sortable: true, visible:true },
  {name: 'displayName', type: 'text', title: 'Display Name', style: {...defaultStyles}, sortable: true, visible:true },
  {name: 'esmLiteStatus', type: 'text', title: 'eSM Lite Status', style: {...defaultStyles, 'text-align': 'center'}, sortable: true, visible:true },
  {name: 'emailAddress', type: 'text', title: 'Email Address(es)', style: {...defaultStyles}, sortable: true, visible:true },
  {name: 'commodity', type: 'text', title: 'Commodity', style: {...defaultStyles,'max-width':'8rem'}, sortable: true, visible:true },
  {name: 'invTemplate', type: 'text', title: 'Locale', style: {...defaultStyles, 'max-width':'5rem'}, sortable: true, visible:true },
  {name: 'boilerplate1', type: 'tip', title: 'Boilerplate 1', style: {...defaultStyles}, sortable: false, visible:true },
  {name: 'boilerplate2', type: 'tip', title: 'Boilerplate 2', style: {...defaultStyles}, sortable: false, visible:true },
  {name: 'attachXML', type: 'toggleButton', title: 'Attach XML', style: {...defaultStyles, 'text-align': 'center'}, sortable: false, visible:true },

];
