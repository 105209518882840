<div style="max-width: 585px;" class="trade-reference">
  <div class="form-title">Trade | References</div>
  <div class="form-body" [formGroup]="formGroup">
    <div class="row gutter-0">
      <div class="col-5 label">Execution</div>
      <div class="col-7 ps-2 !pe-[42px]"><input type="checkbox" class="margin-3" id="execution" name="execution" formControlName="execution" (change)="onExecution($event);" style="vertical-align: bottom;" /></div>
    </div>
    <div class="row gutter-0">
      <div class="col-5 label">Transaction time (UTC) <span class="text-required">*</span></div>
      <div class="col-7 ps-2 !pe-[42px]">
                 <span id="remit-trade-entry-transactionDate" class="inline-block padding-right-3 margin-3">
                        <p-calendar
                          formControlName="transactionDate"
                          [showIcon]="true"
                          [monthNavigator]="true"
                          [yearNavigator]="true"
                          dateFormat="dd/mm/yy"
                          [style]="{ 'width': '125px'}"
                          [styleClass]="getValidationErrorStyleClass('transactionTime')"
                          cmsDateRestriction>
                        </p-calendar>
                </span>
        <span id="remit-trade-entry-transactionTime" class="inline-block padding-left-3 margin-3">
                        <p-inputMask formControlName="transactionTime" mask="99:99:99" pattern="(([0-1]?[0-9])|(2[0-3])):[0-5][0-9]:[0-5][0-9]" placeholder="hh:mm:ss" slotChar="hh:mm:ss" [styleClass]="'text-small padding-6 text-center ' + getValidationErrorStyleClass('transactionTime')" [style]="{ 'width': '75px'}" (change)="onTransactionTimeChange();"></p-inputMask>
                </span>
      </div>
      <span *ngIf="formGroup.controls['transactionTime'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0">
      <div class="col-5 label ">UTI <span class="text-required">*</span></div>
      <div class="col-7 ps-2 !pe-[42px]">
        <input id="remit-table1-trade-entry-uti" type="text" class="form-control p-component padding-6 text-medium margin-3" formControlName="uti" maxlength="100" [ngClass]="{'error':formGroup.controls['uti'].hasError('error')}">
      </div>
      <span *ngIf="formGroup.controls['uti'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0">
      <div class="col-5 label">Linked Transaction ID <span class="text-required" *ngIf="execution">*</span></div>
      <div class="col-7 ps-2 !pe-[42px]">
        <input id="remit-trade-entry-transactionId" type="text" class="form-control p-component padding-6 text-medium margin-3" formControlName="linkedTransactionId" maxlength="100" [ngClass]="{'error':formGroup.controls['linkedTransactionId'].hasError('error')}">
      </div>
      <span *ngIf="formGroup.controls['linkedTransactionId'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
  </div>
</div>
