import * as moment from 'moment';
import { Column } from '@common/shared/datatable.model';
import { MY_FILTERS_DIALOGS, MyFilter } from '@common/my-filters/my-filters.model';
import { ErrorResponse } from '@common/error-response.model';
import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import { DEALS_DIALOGS, Sender } from '@common/deals/deals.model';
import { EsmInvoiceFinderFiltersState } from '../../state/invoice-finder/filters/filters.reducers';

export type FilterBy = 'dealProperties' | 'ourInvoiceNumber' | 'theirInvoiceNumber' | 'documentId' | 'purchaseOrderNumber' | 'tradeId';

export interface EsmInvoiceFinderFilterData {
  count: number;
  currentPage: number;
  values: EsmInvoiceFinder[];
  notFoundIds?: string[];
  columnOrder: string[];
}

export type EsmInvoiceFinderFilterResponse = EsmInvoiceFinderFilterData & ErrorResponse;

export interface EsmPDFResendResponse {
  response: string;
}

export type INVOICE_FINDER_DIALOGS = MY_FILTERS_DIALOGS | DEALS_DIALOGS;

export interface EsmInvoiceFinderFilterByPropertiesRequest {
  commodities: string[];
  counterPartyOrgIds: string[];
  deliveryPoints: { keyObject: string, valueObject: string }[] | string[];
  invoiceEndDate: string;
  invoiceStartDate: string;
  invoiceTypes: string[];
  paymentDateRange: DateRange;
  submissionDateRange: DateRange;
  senderUs: boolean;
  sorting: SortingFilter;
  paging: PagingFilter;
}

export interface DateRange {
  end: string | Date;
  start: string | Date;
}

export interface KeyValueProp {
  value: string;
  key: string;
}

export interface EsmInvoiceFilterPropertiesResponse extends ErrorResponse {
  value: EsmInvoiceFilterValues;
}

export interface EsmInvoiceFilterValues extends MyFilter {
  commodities: string[];
  counterParties: KeyValueProp[];
  invoiceTypes: string[];
  deliveryPoints: any[];
  webUser: boolean;
  multiAgreeButtonVisible: boolean;
}

export interface InvoiceFinderPropertiesFilters {
  commodities: string[];
  counterPartyOrgIds: string[];
  deliveryPoints: {keyObject:string,valueObject:string}[];
  invoiceEndDate: string;
  invoiceStartDate: string;
  invoiceTypes: string[];
  paymentDateRange: DateRange;
  submissionDateRange: DateRange;
  senderUs: boolean;
  webUser: boolean;
  multiAgreeButtonVisible: boolean;
}

export interface EsmInvoiceFinderMyFilter extends MyFilter {
  commodityFilter: string[];
  counterpartyFilter: string[];
  deliveryPointFilter: { keyObject: string, valueObject: string }[];
  invoiceTypeFilter: string[];
  hiddenType: Sender;
}

export interface EsmInvoiceFinderMyFilterRequest {
  commodityFilter: string[];
  counterpartyFilter: string[];
  deliveryPointFilter: {keyObject:string,valueObject:string}[];
  invoiceTypeFilter: string[];
  filterName: string;
  hiddenType: Sender;
  isDefaultFilter: boolean;
}

export interface EsmInvoiceMyFiltersResponse extends ErrorResponse {
  defaultFilter: EsmInvoiceFinderMyFilterResponse;
  filterSelections: { [key: string]: EsmInvoiceFinderMyFilterResponse };
}

export interface EsmInvoiceFinderMyFilterResponse {
  commodityFilter: string[];
  counterpartyFilter: string[];
  deliveryPointFilter: {keyObject:string,valueObject:string}[];
  invoiceTypeFilter: string[];
  filterName: string;
  hiddenType: Sender;
  isDefaultFilter: boolean;
}

export const generateInitialDate = (daysDefore: number) => moment().subtract(daysDefore, 'days').toDate();

export const shouldDisableSaveCurrent = (values: InvoiceFinderPropertiesFilters,
  allDealsFilter: EsmInvoiceFinderMyFilter): boolean => {
  const {
    commodities,
    counterPartyOrgIds,
    deliveryPoints,
    invoiceTypes,
    senderUs
  } = values;
  const {
    commodityFilter,
    counterpartyFilter,
    deliveryPointFilter,
    invoiceTypeFilter,
    hiddenType
  } = allDealsFilter;
  return commodities && commodities.length === commodityFilter.length &&
    counterPartyOrgIds && counterPartyOrgIds.length === counterpartyFilter.length &&
    deliveryPoints && deliveryPoints.length === deliveryPointFilter.length &&
    invoiceTypes && invoiceTypes.length === invoiceTypeFilter.length &&
    senderUs === (hiddenType === Sender.US);
};

export const getSaveReplaceMyFilter = (value: InvoiceFinderPropertiesFilters,
  filter: MyFilter): EsmInvoiceFinderMyFilter => {
  const {
    commodities,
    counterPartyOrgIds,
    deliveryPoints,
    invoiceTypes,
    senderUs
  } = value;
  return {
    filterName: filter.filterName,
    defaultFilter: filter.defaultFilter,
    commodityFilter: commodities,
    counterpartyFilter: counterPartyOrgIds,
    deliveryPointFilter: deliveryPoints,
    invoiceTypeFilter: invoiceTypes,
    hiddenType: senderUs ? Sender.US : Sender.THEM
  };
};


export const updateFilters = (
  filterValues: EsmInvoiceFinderFiltersState,
  propFilters: InvoiceFinderPropertiesFilters,
  defaultFilter: EsmInvoiceFinderMyFilter): InvoiceFinderPropertiesFilters => {
  let filters: InvoiceFinderPropertiesFilters;
  if (!defaultFilter) {
    filters = {
      ...propFilters,
      commodities: filterValues.commodities || [],
      counterPartyOrgIds: filterValues.counterParties? filterValues.counterParties.map(item => item.key) : [],
      deliveryPoints: filterValues.deliveryPoints || [],
      invoiceTypes: filterValues.invoiceTypes || [],
      webUser: filterValues.propFilters.webUser || false,
      senderUs: filterValues.propFilters.senderUs || true,
      multiAgreeButtonVisible: filterValues.propFilters.multiAgreeButtonVisible || false
    };
  } else {
    filters = {
      ...propFilters,
      commodities: (defaultFilter.commodityFilter &&
        defaultFilter.commodityFilter.filter(item => filterValues.commodities.includes(item))) || filterValues.commodities,
      counterPartyOrgIds:(defaultFilter.counterpartyFilter?.length>0 && defaultFilter.counterpartyFilter?.filter(key => filterValues.counterParties.some(obj => obj.key === key))) || filterValues.counterParties.map(item => item.key),
      deliveryPoints:(defaultFilter.deliveryPointFilter?.length>0 && defaultFilter.deliveryPointFilter?.filter(item => filterValues.deliveryPoints.some(i=>i.valueObject === item.valueObject))) || filterValues.deliveryPoints,
      invoiceTypes: (defaultFilter.invoiceTypeFilter &&
        defaultFilter.invoiceTypeFilter.filter(item => filterValues.invoiceTypes.includes(item))) || filterValues.invoiceTypes,
      senderUs: defaultFilter.hiddenType === Sender.US || true
    };
  }
  return filters;
};

export const mapToEsmInvoiceFinderMyFilterRequest = ({
                                                       invoiceTypes,
                                                       deliveryPoints,
                                                       counterParties,
                                                       commodities,
                                                     }: EsmInvoiceFinderFiltersState,
                                                     {
                                                       filterName,
                                                       defaultFilter,
                                                       hiddenType,
                                                       commodityFilter,
                                                       counterpartyFilter,
                                                       deliveryPointFilter,
                                                       invoiceTypeFilter
                                                     }: EsmInvoiceFinderMyFilter): EsmInvoiceFinderMyFilterRequest =>
  ({
    filterName,
    isDefaultFilter: defaultFilter,
    hiddenType,
    commodityFilter: commodityFilter && commodities ? commodityFilter.length === commodities.length ? null : commodityFilter : [],
    counterpartyFilter: counterpartyFilter && counterParties ? commodityFilter.length === counterParties.length ? null : counterpartyFilter : [],
    deliveryPointFilter: deliveryPointFilter && deliveryPoints ? deliveryPointFilter.length === deliveryPoints.length ? null : deliveryPointFilter : [],
    invoiceTypeFilter: invoiceTypeFilter && invoiceTypes ? invoiceTypeFilter.length === invoiceTypes.length ? null : invoiceTypeFilter : [],
  });


export interface InvoiceFinderFiltersIdInputs {
  ourInvoiceNumber?: string;
  theirInvoiceNumber?: string;
  documentId?: string;
  purchaseOrderNumber?: string;
  tradeId?: string;
}


export interface InvoiceFinderFilters extends InvoiceFinderFiltersIdInputs{
  filterBy?: FilterBy;
}

export enum InvoiceFinderIdFiltersUrls {
  ourInvoiceNumber = 'invoicenumber/our',
  theirInvoiceNumber = 'invoicenumber/their',
  documentId = 'documentId',
  purchaseOrderNumber = 'purchaseOrderNumber',
  tradeId = 'tradeIdUti'
}

export interface InvoiceFinderIdFiltersResponse extends ErrorResponse, EsmInvoiceFinder {
  notFoundIds: string[];
  count: number;
  currentPage: number;
}

export interface InvoiceFinderFilters {
  inputIds?: InvoiceFinderFiltersIdInputs;
  propFilters?: InvoiceFinderPropertiesFilters;
  filterBy?: FilterBy;
}

export const defaultStyles = {
  'text-align': 'left',
  'text-overflow': 'ellipsis',
  'overflow': 'hidden',
  'white-space': 'nowrap'
};

export const esmInvoiceFinderColumns: Column[] = [
  { name: 'submissionTimestamp', type: 'dateWithoutTime', title: 'Sub. Date', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'invoiceNumber', type: 'text', title: 'Invoice no#', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'receiverId', type: 'text', title: 'Receiver Id', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'physicalFinancial', type: 'text', title: 'Physical Financial', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'commodity', type: 'text', title: 'Commodity', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'deliveryPointArea', type: 'text', title: 'Delivery Point', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'agreement', type: 'text', title: 'Agreement', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'openDocument', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'openDocumentXml', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'auditTrail', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'nearMatch', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'misMatch', type: 'button', title: '', style: { ...defaultStyles, width: '80px' }, sortable: false, visible: true },
  { name: 'pdf', type: 'button', title: '', style: { ...defaultStyles, width: '80px' }, sortable: false, visible: true },
  { name: 'resend', type: 'button', title: '', style: { ...defaultStyles, width: '80px' }, sortable: false, visible: true },
  { name: 'paymentDate', type: 'text', title: 'Payment Date', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'invoicePeriodStart', type: 'text', title: 'Inv. Period Start', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'invoicePeriodEnd', type: 'text', title: 'Inv. Period End', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'totalVolumeUnit', type: 'text', title: 'Total Volume Unit', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'state', type: 'text', title: 'State', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'totalGrossAmount', type: 'currency', title: 'Total Gross Amount', style: { ...defaultStyles, 'text-align': 'right' }, sortable: true, visible: true },
  { name: 'currency', type: 'text', title: 'Curr.', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'totalAmount', type: 'currency', title: 'Total Amount', style: { ...defaultStyles, 'text-align': 'right' }, sortable: true, visible: true },
  { name: 'vatAmount', type: 'currency', title: 'VAT Amt', style: { ...defaultStyles, 'text-align': 'right' }, sortable: true, visible: true },
  { name: 'vatRate', type: 'percent', title: 'VAT rate', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'vatAmountCurrency', type: 'text', title: 'VAT Curr.', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'notes', type: 'button', title: '', style: { ...defaultStyles, width: '80px' }, sortable: false, visible: true },
  { name: 'taxPoint', type: 'text', title: 'Tax Point', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'masterAgreementVersion', type: 'text', title: 'Master Agreement Version', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'invoiceDate', type: 'text', title: 'Invoice Date', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'totalVolume', type: 'text', title: 'Total Volume', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'vatAmountDomestic', type: 'text', title: 'Vat Amount Domestic', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'vatAmountDomesticCurrency', type: 'text', title: 'Vat Amount Domestic Currency', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'totalGrossAmountCurrency', type: 'text', title: 'Total Gross Amount Currency', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'totalAmountCurrency', type: 'text', title: 'Total Amount Currency', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'documentId', type: 'text', title: 'Document Id', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'documentVersion', type: 'text', title: 'Document Version', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'fixedFloating', type: 'text', title: 'Fixed Floating', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'natureOfPrice', type: 'text', title: 'Nature Of Price', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'marketInformation', type: 'text', title: 'Market Information', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'selfBilling', type: 'text', title: 'Self Billing', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'supplierId', type: 'text', title: 'Supplier Id', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'customerId', type: 'text', title: 'Customer Id', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'purchaseOrderNumber', type: 'text', title: 'Purchase Order Number', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'senderId', type: 'text', title: 'Sender Id', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'documentType', type: 'text', title: 'Document Type', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'customerDisplayName', type: 'text', title: 'Customer Name', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'supplierDisplayName', type: 'text', title: 'Supplier Name', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'processType', type: 'text', title: 'Process Type', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'agree', type: 'button', title: '', style: { ...defaultStyles, width: '80px' }, sortable: false, visible: true },
  { name: 'dispute', type: 'button', title: '', style: { ...defaultStyles, width: '80px' }, sortable: false, visible: true }
];

export interface EsmInvoiceFinder {
  counterParty: string;
  agreement: string;
  commodity: string;
  currency: string;
  customerId: string;
  deliveryPointArea: string;
  documentId: string;
  documentType: string;
  documentVersion: number;
  fixedFloating: string;
  id: number;
  invoiceDate: string;
  invoiceNumber: string;
  invoicePeriodEnd: string;
  invoicePeriodStart: string;
  lastUpdateUtc: string;
  marketInformation: string;
  masterAgreementVersion: string;
  matchType: string;
  natureOfPrice: string;
  paymentDate: string;
  physicalFinancial: string;
  purchaseOrderNumber: string;
  receiverId: string;
  selfBilling: boolean;
  senderId: string;
  state: string;
  submissionTimestamp: string;
  supplierId: string;
  taxPoint: string;
  totalAmount: number;
  totalAmountCurrency: string;
  totalGrossAmount: number;
  totalGrossAmountCurrency: string;
  totalVolume: number;
  totalVolumeUnit: string;
  vatAmount: number;
  vatAmountCurrency: string;
  vatAmountDomestic: number;
  vatAmountDomesticCurrency: string;
  vatRate: number;
  nearMatchButtonVisible: boolean;
  misMatchButtonVisible: boolean;
  pdfButtonVisible: boolean;
  resendButtonVisible: boolean;
  customerDisplayName: string;
  supplierDisplayName: string;
  processType: string;
  agreeButtonVisible: boolean;
  disputeButtonVisible: boolean;
}

export const esmDealFinderCustomizeConfig: { [name: string]: Column } = {
  taxPoint: { name: 'taxPoint', title: 'Tax Point' },
  invoiceNumber: { name: 'invoiceNumber', title: 'Invoice Number' },
  submissionTimestamp: { name: 'submissionTimestamp', title: 'Submitted (Timestamp)' },
  receiverId: { name: 'receiverId', title: 'Receiver EIC', },
  physicalFinancial: { name: 'physicalFinancial', title: 'Physical Financial', },
  commodity: { name: 'commodity', title: 'Commodity' },
  deliveryPointArea: { name: 'deliveryPointArea', title: 'Delivery Point Area' },
  agreement: { name: 'agreement', title: 'Agreement' },
  masterAgreementVersion: { name: 'masterAgreementVersion', title: 'Master Agreement Version' },
  paymentDate: { name: 'paymentDate', title: 'Payment Date' },
  invoicePeriodStart: { name: 'invoicePeriodStart', title: 'Invoice Period Start' },
  invoicePeriodEnd: { name: 'invoicePeriodEnd', title: 'Invoice Period End' },
  invoiceDate: { name: 'invoiceDate', title: 'Invoice Date' },
  totalVolume: { name: 'totalVolume', title: 'Total Volume' },
  totalVolumeUnit: { name: 'totalVolumeUnit', title: 'Volume Unit' },
  state: { name: 'state', title: 'State' },
  totalGrossAmount: { name: 'totalGrossAmount', title: 'Total Gross Amount' },
  currency: { name: 'currency', title: 'Currency' },
  totalAmount: { name: 'totalAmount', title: 'Total Amount' },
  vatAmount: { name: 'vatAmount', title: 'Vat Amount' },
  vatRate: { name: 'vatRate', title: 'Vat Rate' },
  vatAmountCurrency: { name: 'vatAmountCurrency', title: 'vat Amount Currency' },
  openDocument: { name: 'openDocument', title: 'Button "Document"' },
  openDocumentXml: { name: 'openDocumentXml', title: 'Button "Document (XML)', },
  auditTrail: { name: 'auditTrail', title: 'Button "Audit Trail"' },
  notes: { name: 'notes', title: 'Button "Notes"' },
  nearMatch: { name: 'nearMatch', title: 'Button "Near Match"' },
  misMatch: { name: 'misMatch', title: 'Button "Mismatched"' },
  pdf: { name: 'pdf', title: 'Button "PDF Download"' },
  resend: { name: 'resend', title: 'Button "Resend"' },
  vatAmountDomestic: { name: 'vatAmountDomestic', title: 'Vat Amount Domestic' },
  vatAmountDomesticCurrency: { name: 'vatAmountDomesticCurrency', title: 'Vat Amount Domestic Currency' },
  totalGrossAmountCurrency: { name: 'totalGrossAmountCurrency', title: 'Total Gross Amount Currency' },
  totalAmountCurrency: { name: 'totalAmountCurrency', title: 'Total Amount Currency' },
  documentId: { name: 'documentId', title: 'Document ID' },
  documentVersion: { name: 'documentVersion', title: 'Document Version', },
  fixedFloating: { name: 'fixedFloating', title: 'Fixed Floating', },
  natureOfPrice: { name: 'natureOfPrice', title: 'Nature Of Price', },
  marketInformation: { name: 'marketInformation', title: 'Market Information', },
  selfBilling: { name: 'selfBilling', title: 'Self Billing', },
  supplierId: { name: 'supplierId', title: 'Supplier Id', },
  customerId: { name: 'customerId', title: 'Customer Id', },
  purchaseOrderNumber: { name: 'purchaseOrderNumber', title: 'Purchase Order Number', },
  senderId: { name: 'senderId', title: 'Sender EIC' },
  documentType: { name: 'documentType', title: 'Document Type' },
  customerDisplayName: { name: 'customerDisplayName', title: 'Customer Name' },
  supplierDisplayName: { name: 'customerDisplayName', title: 'Supplier Name' },
  processType: {name: 'processType', title: 'Process Type' },
  agree: { name: 'agree', title: 'Button "Agree"' },
  dispute: { name: 'dispute', title: 'Button "Dispute"' },
};
