<div style="max-width: 585px;" class="settlement">
  <div class="form-title">Contract | Settlement</div>
  <div class="form-body" [formGroup]="formGroup">
    <div class="row gutter-0">
      <div class="col-5 label">Settlement Method <span class="text-required">*</span></div>
      <div id="remit-trade-entry-settlementMethod" class="col-7 ps-2 !pe-[42px]">
        <p-dropdown [options]="settlementMethod" formControlName="settlementMethod" [style]="{'width': '100%'}" [styleClass]="'margin-3 ' + getValidationErrorStyleClass('settlementMethod')" ></p-dropdown>
      </div>
      <span *ngIf="formGroup.controls['settlementMethod'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0">
      <div class="col-5 label">Price Currency <span class="text-required">*</span></div>
      <div id="remit-trade-entry-priceCurrency" class="col-7 ps-2 !pe-[42px]">
        <p-dropdown [options]="priceCurrency" formControlName="priceCurrency" [style]="{'width': '100%'}" [styleClass]="'margin-3 ' + getValidationErrorStyleClass('priceCurrency')" [group]="true" ></p-dropdown>
      </div>
      <span *ngIf="formGroup.controls['priceCurrency'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0" *ngIf="showOptionals">
      <div class="col-5 label">Option Premium Currency <span class="text-required">*</span></div>
      <div id="remit-trade-entry-optionPremiumCurrency" class="col-7 ps-2 !pe-[42px]">
        <p-dropdown [options]="optionPremiumCurrency" formControlName="optionPremiumCurrency" [style]="{'width': '100%'}" [styleClass]="'margin-3 ' + getValidationErrorStyleClass('optionPremiumCurrency')" [group]="true" ></p-dropdown>
      </div>
      <span *ngIf="formGroup.controls['optionPremiumCurrency'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0">
      <div class="col-5 label">Quantity Unit <span class="text-required" *ngIf="!execution">*</span></div>
      <div id="remit-trade-entry-quantityUnit" class="col-7 ps-2 !pe-[42px]">
        <p-dropdown [options]="quantityUnit" formControlName="quantityUnit" [placeholder]="selectCommodityFirstPlaceholder(quantityUnit)" [style]="{'width': '100%'}" [styleClass]="'margin-3 ' + getValidationErrorStyleClass('quantityUnit')" [group]="true" ></p-dropdown>
      </div>
      <span *ngIf="formGroup.controls['quantityUnit'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0">
      <div class="col-5 label">Total Quantity Unit <span class="text-required">*</span></div>
      <div id="remit-trade-entry-totalQuantityUnit" class="col-7 ps-2 !pe-[42px]">
        <p-dropdown [options]="totalQuantityUnits" formControlName="totalQuantityUnit" [placeholder]="selectCommodityFirstPlaceholder(totalQuantityUnits)" [style]="{'width': '100%'}" [styleClass]="'margin-3 ' + getValidationErrorStyleClass('totalQuantityUnit')" [group]="true" ></p-dropdown>
      </div>
      <span *ngIf="formGroup.controls['totalQuantityUnit'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
  </div>
</div>
