import { Injectable } from '@angular/core';
import { ApiRequestService } from '@common/api-request.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { MessagesMapperService } from '@common/messages-mapper.service';
import * as FileSaver from 'file-saver';
import { Message } from 'primeng/api';
import { of } from 'rxjs';
import { PagingFilter, SortingFilter } from '@common/shared/results.model';

import * as fromActions from './mifid.action';
import * as fromSelectors from './mifid.selectors';
import { getMifidFileInState } from './mifid.selectors';
import { ErrMifidFileState } from './mifid.reducer';
import {
  allColumnsConfig,
  defaultStyles,
  ErrMifidFileFilters,
  ErrMifidFileResponse,
  ErrMifidViewByTypes,
  PresetValuesResponse
} from '../../mifid/mifid.model';
import { ErrorResponse } from '@common/error-response.model';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class ErrMifidEffects {
  private baseUrl = '/api/err/mifid';

  constructor(private apiGateway: ApiRequestService,
              private actions$: Actions,
              private store$: Store<ErrMifidFileState>,
              private mapper: MessagesMapperService) {
  }

   loadPresetValues$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.LOAD_PRESET_VALUES),
      switchMap((action: fromActions.LoadPresetValuesAction) => {
          return this.apiGateway.post(`${this.baseUrl}/loadPresetSearchFilter`)
            .pipe(map((response: { value: PresetValuesResponse } | any) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return new fromActions.SetMessagesAction(messages);
              }
              return new fromActions.LoadPresetValuesSuccessAction(response);
            }));
        }
      )));

   filterByName$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.FILTER_BY_NAME),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getMifidFileInState))),
      switchMap((data: [fromActions.FilterByNameAction, ErrMifidFileState]) => {
        const [, state] = data;
        return this.loadData(state);
      })));

   filterByDateFilter$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.FILTER_BY_DATE, fromActions.LOAD_DATA),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getMifidFileInState))),
      switchMap((data: [fromActions.FilterByDateAction | fromActions.LoadDataAction, ErrMifidFileState]) => {
        const [, state] = data;
        return this.loadData(state);
      })));

   sortAndPage$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.SORT_AND_PAGE_DATA),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getMifidFileInState))),
      switchMap((data: [fromActions.SortAndPageDataAction, ErrMifidFileState]) => {
          const [action, state] = data;
          const { sorting, paging } = action.payload;

          return this.loadData({
            ...state,
            sorting,
            paging
          });
        }
      )));

   clear$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.CLEAR_FILTERS),
      switchMap(() => {
        this.store$.dispatch(new fromActions.LoadPresetValuesAction());
        return this.actions$
          .pipe(ofType(fromActions.LOAD_PRESET_VALUES_SUCCESS),
            withLatestFrom(this.store$.pipe(select(fromSelectors.getFilters))),
            switchMap((data: [fromActions.FilterByDateAction, ErrMifidFileFilters]) =>
              of(new fromActions.FilterByDateAction(data[1]))
            )
          );
      })));

   upload$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.UPLOAD_FILE),
      switchMap(({ payload }) => {
        const formData = new FormData();
        formData.append('file', payload);
        return this.apiGateway.post(`${this.baseUrl}/upload`, formData, null)
          .pipe(
            concatMap((response: ErrorResponse | null) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return of(new fromActions.SetMessagesAction(messages));
              }
              return [
                new fromActions.SetMessagesAction(this.mapper.toSuccessMessages('File uploaded.'))
              ];
            }),
            catchError(error => of(new fromActions.SetMessagesAction([
              this.mapper.createErrorMessage('Server Error')
            ])))
          );
      })));

   confirmItem$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.CONFIRM_ITEM),
      concatMap((action: fromActions.ConfirmItemAction) => {
        let params = new HttpParams();
        params = params.set('fileId', action.payload);

        return this.apiGateway.post(`${this.baseUrl}/position/confirm`, null, params)
          .pipe(
            concatMap((response: ErrorResponse | null) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return of(new fromActions.SetMessagesAction(messages));
              }
              return [
                new fromActions.SetMessagesAction([]),
                new fromActions.ConfirmItemSuccessAction(),
                new fromActions.LoadDataAction(),
              ];
            }),
            catchError(error => of(new fromActions.SetMessagesAction([
              this.mapper.createErrorMessage('Server Error')
            ])))
          );
      })
    ));

   export$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.EXPORT),
      withLatestFrom(this.store$.pipe(select(getMifidFileInState))),
      concatMap((data: [fromActions.ExportAction, ErrMifidFileState]) => {
        const [, state] = data;
        const { sorting, paging, filters, viewBy } = state;
        const request = this.getRequestModel(viewBy, sorting, paging, filters);

        return this.apiGateway.getBlob(`${this.baseUrl}/positions/${viewBy}/export`, request, null)
          .pipe(
            switchMap((response: any) => {
              const filename = this.getFilenameFromHeader(response.headers);
              const blob: Blob = response.body;
              FileSaver.saveAs(blob, filename);
              return of(new fromActions.SetMessagesAction([]));
            }),
            catchError(() =>
              of(new fromActions.SetMessagesAction(
                [this.mapper.createErrorMessage('Server error')]))
            )
          );
      })));

   downloadXml$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.DOWNLOAD_DOCUMENT),
      switchMap((action: fromActions.DownloadDocumentAction) => {
        let params = new HttpParams();
        params = params.set('dataStoreId', action.payload.dataStoreId);
        params = params.set('filename', action.payload.filename);
        return this.apiGateway.getBlob(`${this.baseUrl}/position/xml`, null, params)
          .pipe(switchMap((response: any) => {
              const filename = this.getFilenameFromHeader(response.headers);
              const blob: Blob = response.body;
              FileSaver.saveAs(blob, filename);
              return of(new fromActions.SetMessagesAction([]));
            }),
            catchError(() => of(new fromActions.SetMessagesAction([
              this.mapper.createErrorMessage('Server error')
            ])))
          );
      })));

  private loadData(state: ErrMifidFileState) {
    const { sorting, paging, filters, viewBy } = state;
    const request = this.getRequestModel(viewBy, sorting, paging, filters);
    const url = viewBy === 'filename' ? 'byFilename' : 'byFilter';
    return this.apiGateway.post(`${this.baseUrl}/positions/${url}`, request)
      .pipe(switchMap((response: ErrMifidFileResponse) => {
          const messages: Message[] = this.mapper.toErrorMessages(response);
          if (messages && messages.length) {
            return [new fromActions.SetMessagesAction(messages)];
          }

          const confirmButton = { name: 'confirm', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: response.confirmColumnVisible };
          const columns = [...allColumnsConfig, confirmButton];
          return [
            new fromActions.SetColumnsAction(columns),
            new fromActions.FilterDataSuccessAction(response),
            new fromActions.SetMessagesAction([]),];
        }),
        catchError(error => of(new fromActions.SetMessagesAction([
          this.mapper.createErrorMessage(error.errorMessage)
        ]))));
  }

  private getFilenameFromHeader(headers): string {
    const contentDisposition = headers.get('content-disposition');
    return contentDisposition.split(';')[1].trim().split('=')[1];
  }

  private getRequestModel(viewBy: ErrMifidViewByTypes, sorting: SortingFilter,
                          paging: PagingFilter, filters: ErrMifidFileFilters): any {
    switch (viewBy) {
      case 'filename':
        return {
          sorting,
          paging,
          filename: filters.filename,
        };
      case 'filter':
        return {
          sorting,
          paging,
          selectedDocumentTypes: filters.documentTypes,
          timeRange: filters.timeRange,
        };
      default:
        return {};
    }
  }
}
