import { Injectable } from '@angular/core';
import {BrowserInfo} from "@common/browser-detection/browser-detection.model";

@Injectable()
export class BrowserDetectionService {

  constructor() { }

  public currentBrowser: { browser: string, current: number, required: number  };


  public isBrowserSupported(browserInfo: BrowserInfo): boolean {
    /*
     * Unsupported:
     * not support of Internet Explorer at all
     * Opera version 108 or older
     * Google Chrome version 121 or older
     * Mozilla Firefox version 124 or older
     * Edge version 115 (same as chrome) or older
     * */
    const operaVersion = 108;  // Opera 108 uses chromium 121, keep this in sync
    const chromeVersion = 121;
    const firefoxVersion = 124;

    switch (browserInfo.browser) {
      case 'Chrome':
        this.currentBrowser = { browser: 'Chrome', current: browserInfo.browserVersion, required: chromeVersion };
        return browserInfo.browserVersion > chromeVersion;
      case 'Opera':
        this.currentBrowser = { browser: 'Opera', current: browserInfo.browserVersion, required: operaVersion };
        return browserInfo.browserVersion > operaVersion;
      case 'MS-Edge-Chromium': {
        this.currentBrowser = { browser: 'Microsoft Edge', current: browserInfo.browserVersion, required: chromeVersion };
        return browserInfo.browserVersion > chromeVersion;
      }
      case 'Firefox': {
        this.currentBrowser = { browser: 'Firefox', current: browserInfo.browserVersion, required: firefoxVersion };
        return browserInfo.browserVersion > firefoxVersion;
      }
      case 'IE':
      case 'MSIE': {
        this.currentBrowser = { browser: 'Internet Explorer', current: browserInfo.browserVersion, required: null };
        return false;
      }
    }

    return true;
  }
}
