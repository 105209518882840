import {
  AfterViewInit,
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange, ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { Message } from 'primeng/api';
import { MessagesMapperService } from '../../../../../common/messages-mapper.service';
import { AddOrganisation } from '../../organisations.model';

@Component({
  selector: 'cms-add-organisation-dialog',
  templateUrl: './add-organisation-dialog.component.html',
  styleUrls: ['./add-organisation-dialog.component.scss']
})

export class AddOrganisationDialogComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() organisationGroupId: number;
  @Input() display = false;
  @Input() messages: Message[];
  @Output() displayChange = new EventEmitter<boolean>();
  @Output() confirm = new EventEmitter<AddOrganisation>();
  @Output() cancel = new EventEmitter<Message[]>();
  errors: {[key: string]: ValidationErrors} = {};
  form: UntypedFormGroup;
  @ViewChild('eicCode', { static: false }) elem: ElementRef;

  constructor(private formBuilder: UntypedFormBuilder,
              private mapper: MessagesMapperService) {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.elem.nativeElement.focus();
    }, 100);
  }

  onCancel(): void {
    this.hide();
    this.cancel.emit(this.mapper.toWarningMessages(`Adding a new organisation was cancelled.`));
  }

  onVisibleChange(visible: boolean): void {
    if (!visible) {
      this.onCancel();
    }
  }

  onConfirm(): void {
    const out: AddOrganisation = {
      ...this.form.value,
      organisationGroupId: this.organisationGroupId
    };

    this.confirm.emit(out);
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      businessContact: '',
      displayName: '',
      eicCode: '',
      legalName: '',
      technicalContact: ''
    });
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (this.form) {
      if (changes.display && !this.display) {
        this.hide();
      }
      if (changes.data) {
        this.form.updateValueAndValidity();
      }
    }

    if (changes.messages) {
      this.errors = {};
      if (this.messages) {
        this.messages.forEach((obj: Message) => {
          const { key: controlName } = obj;
          this.errors[controlName] = { 'server': true };
        });
      }
    }
  }


  private hide(): void {
    this.messages = [];
    this.display = false;
    this.displayChange.emit(this.display);
    this.form.reset();
    this.form.updateValueAndValidity();
  }
}
