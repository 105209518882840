<p-dialog *ngIf="display"
          header="{{'Hide Confirmation' + (data.tradeId ?  ' (' + data.tradeId + ')' : '')}}"
          [(visible)]="display"
          [modal]="true"
          (visibleChange)="onVisibleChange($event)"
          [style]="{'min-width': '600px'}">
  <p-messages [value]="messages" [closable]="true"></p-messages>
  <div class="info-panel">
    <span class="fa fa-info-circle padding-right-6"></span>
    Marking a confirmation as "hidden" allows users of your organisation to suppress its
    display in the eCM Dashboard/Deal Finder, otherwise has no effect on the eCM process.
    If the match process continues the confirmation will automatically be unhidden.
  </div>
  <form [formGroup]="form">
    <form [formGroup]="form">
      <label for="reason">Enter reason (1-50 characters) and confirm: <span class="text-required">*</span></label>
      <input type="text" [ngClass]="{'error': this.errors['reason']}" id="reason" name="reason" formControlName="reason" class="form-control" [maxlength]="50">
    </form>
  </form>
  <p-footer>
    <button class="btn margin-right-3" type="button" (click)="onCancel()">Cancel</button>
    <button class="btn" type="button" (click)="onSave()">Hide</button>
  </p-footer>
</p-dialog>
