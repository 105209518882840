import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CmsCommonModule } from '../../common/cms-common.module';
import { RouterModule } from '@angular/router';
import { AudittrailModule } from '../../common/audittrail/audittrail.module';
import { TradeEntryComponent } from './trade_entry/trade_entry.component';
import { TradeEntryService } from './trade_entry/trade-entry.service';
import { TradeEntryCustomiseComponent } from './trade_entry/customise/trade-entry-customise.component';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { TabViewModule } from 'primeng/tabview';
import { MultiSelectModule } from 'primeng/multiselect';
import { MessagesModule } from 'primeng/messages';
import { PickListModule } from 'primeng/picklist';
import { RadioButtonModule } from 'primeng/radiobutton';

@NgModule({
  imports: [
    AudittrailModule,
    BrowserModule,
    BrowserAnimationsModule,
    ButtonModule,
    CmsCommonModule,
    TableModule,
    DropdownModule,
    FormsModule,
    MessagesModule,
    MessagesModule,
    MultiSelectModule,
    PickListModule,
    RadioButtonModule,
    ReactiveFormsModule,
    RouterModule,
    TabViewModule,
    TableModule
  ],
  exports: [],
  declarations: [
    TradeEntryComponent,
    TradeEntryCustomiseComponent
  ],
  providers: [
    TradeEntryService
  ]
})

export class SettingsModule {
}
