<h1>Dashboard Dataview</h1>

<div class="panel-box margin-bottom-15" style="max-width:800px;">
  <div class="row">
    <div class="col-6">
      <label>Regime: </label>
      <span>{{parameters.regime}}</span>
    </div>
    <div class="col-6">
      <label>State: </label>
      <span>{{getStatusOutput(parameters.status)}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <label>Reporter: </label>
      <span>{{getReporterOutput(parameters.reporter)}}</span>
    </div>
    <div class="col-6">
      <label>Transaction Time: </label>
      <span>{{getTransactionDayOutput(parameters.day)}}</span>
    </div>
  </div>
</div>
<cms-smt-datatable></cms-smt-datatable>
