import { Component, OnDestroy, OnInit } from '@angular/core';
import { ErrorMessageService } from '@common/errors/error-message.service';
import { select, Store } from '@ngrx/store';
import { ErrTerminationState } from '../../state/remit/remit.reducer';
import * as RemitActions from '../../state/remit/remit.actions';
import { tableColumns, TerminateEntry, UploadMetaData } from './err.termination.model';
import { Observable, Subscription } from 'rxjs';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import * as fromTableSelectors from '../../state/remit/remit.selectors';

import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ListDialogComponent } from './list-dialog/list-dialog.component';

import { Column } from '@common/shared/datatable.model';
import { Message } from 'primeng/api';
import { filter, map } from 'rxjs/operators';
import { TerminationDialogComponent } from './termination-dialog/termination-dialog.component';

@Component({
  selector: 'cms-termination',
  templateUrl: './termination.component.html',
  styleUrls: ['./termination.component.scss'],
  providers: [DialogService]
})
export class TerminationComponent implements OnInit, OnDestroy {


  constructor( public readonly errorMessageService:ErrorMessageService,
               private store: Store<ErrTerminationState>,
               public dialogService: DialogService) {


  }

  columns = tableColumns;
  modal: DynamicDialogRef;
  data$: Observable<DatatableData<TerminateEntry[]>> = this.store.pipe(select(fromTableSelectors.getData));
  paging$: Observable<PagingFilter> = this.store.pipe(select(fromTableSelectors.getPaging));
  sorting$: Observable<SortingFilter> = this.store.pipe(select(fromTableSelectors.getSorting));
  loading$: Observable<{ table: boolean, upload: boolean }> = this.store.pipe(select(fromTableSelectors.getLoading));
  messages$: Observable<Message[]> = this.store.pipe(select(fromTableSelectors.getMessages));
  allowedToUpload$: Observable<boolean> = this.store.pipe(select(fromTableSelectors.getAllowedToUpload));
  allowedMaxSize$: Observable<number> = this.store.pipe(select(fromTableSelectors.getMaxUploadSize));
  orgList$: Observable<string> = this.store.pipe(select(fromTableSelectors.getOrgInfo), map(list => list.join(', ')));
  csvMetaData$: Observable<UploadMetaData> = this.store.pipe(select(fromTableSelectors.getCsvMetaData), filter(value => !!value));
  subscription$: Subscription;
  wizardOpen: boolean;
  dialogItem: number;
  refreshTable = () => this.store.dispatch(RemitActions.loadTable());


  ngOnInit(){
    this.store.dispatch(RemitActions.loadPresets());
    this.store.dispatch(RemitActions.loadTable());
    this.subscription$ = this.csvMetaData$.subscribe(meta => {
      this.dialogItem = meta.identifier;
      this.openMetaDataDialog(meta);
    });
  }

  onLazyLoad({page, perPage, sortField, sortOrder}): void {

    this.store.dispatch(RemitActions.pagingAndSorting({
      paging: {
        page,
        entriesPerPage: perPage
      },
      sorting: {
        sortOrder,
        columnName: sortField
      }
    }));
  }

  onUpload(file: File): void {
    this.store.dispatch(RemitActions.uploadCsv({ file }));
  }

  onUploadError(messages: Message[]): void {
    this.store.dispatch(RemitActions.setMessages({ messages }));
  }

  openDialog(row: TerminateEntry, column: Column) {

    this.modal = this.dialogService.open(ListDialogComponent, {
      data: {
        id: row.id,
        column: column.name
      },
      header: column.title,
      width: '25%',
      closeOnEscape:true,
      dismissableMask:true
    });
  }

  openMetaDataDialog(meta: UploadMetaData) {
    let timeOut = 0;
    if (this.modal) {
      timeOut = 1000;
      this.modal.close();
    }

    setTimeout(() => this.modal = this.dialogService.open(TerminationDialogComponent, {
      data: {
        csvMetaData: meta
      },
      header: 'Execute early termination task',
      width: '85%',
      contentStyle: { 'overflow': 'visible' },
      closeOnEscape: false,
      dismissableMask: false,
      closable: false
    }), timeOut);
  }

  onDownloadZip({ id }) {
    this.store.dispatch(RemitActions.downloadZip({ id }));
  }

  ngOnDestroy(): void {
    this.modal?.close();
    this.subscription$.unsubscribe();
    this.store.dispatch(RemitActions.cleanState());
  }
}
