import { AllDealsMyFilterName } from '@common/my-filters/my-filters.model';
import * as fromCommon from '@common/state/reducers';
import { Sender } from '@common/deals/deals.model';
import { EsmInvoiceFinderMyFilter } from '../../../invoice/finder/invoice-finder.model';
import * as fromIFActions from '../invoice-finder.actions';
import * as fromMFActions from './my-filters.actions';

export type EsmInvoiceFinderMyFiltersState = fromCommon.MyFiltersState<EsmInvoiceFinderMyFilter>;

export const myFilterInitialState: EsmInvoiceFinderMyFiltersState = {
  defaultFilter: null,
  filterSelections: null,
  allDealsFilter: {
    defaultFilter: true,
    filterName: AllDealsMyFilterName,
    counterpartyFilter: [],
    commodityFilter: [],
    deliveryPointFilter: [],
    invoiceTypeFilter: [],
    hiddenType: Sender.US
  }
};

export const esmInvoiceFinderMyFiltersReducer = fromCommon.myFiltersReducer<EsmInvoiceFinderMyFilter>({
  SET_TO_DEFAULT: fromIFActions.setToDefaultAction.type,
  RELOAD_MY_FILTER_SUCCESS: fromMFActions.reloadMyFilterSuccessAction.type,
  LOAD_MY_FILTERS_SUCCESS: fromMFActions.loadMyFiltersSuccessAction.type,
  DELETE_MY_FILTER_SUCCESS: fromMFActions.deleteMyFilterSuccessAction.type,
  SET_ALL_DEALS: fromMFActions.setAllDealsFilterAction.type,
}, myFilterInitialState);
