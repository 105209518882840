import { Component, OnDestroy } from '@angular/core';
import {
  amountTableColumns,
  InvoiceDocumentType,
  InvoiceFinderQueryParameters,
  InvoiceState,
  NettingAmount,
} from './netting-amount.model';
import { Observable, Subscription } from 'rxjs';
import { DatatableData } from '@common/shared/results.model';
import { select, Store } from '@ngrx/store';
import * as fromNettingTableSelectors from '../../state/netting-preview/netting-table/netting-table.selectors';
import * as fromAmountTableSelectors from '../../state/netting-preview/amount-table/amount-table.selectors';
import { AmountTableState } from '../../state/netting-preview/amount-table/amount-table.reducers';
import { NettingTableState } from '../../state/netting-preview/netting-table/netting-table.reducers';
import { InvoiceDivEntry } from '../netting-table-overview/netting-table-overview.model';
import { OrganisationService } from '@common/organisation.service';
import { filter } from 'rxjs/operators';
import { cleanState } from '../../state/netting-preview/amount-table/amount-table.actions';

@Component({
  selector: 'cms-netted-amount',
  templateUrl: './netted-amount.component.html',
  styleUrls: ['./netted-amount.component.scss']
})
export class NettedAmountComponent implements OnDestroy {

  route = '/esm/netting_preview/netted_invoices';
  columns = amountTableColumns;

  data$: Observable<DatatableData<NettingAmount[]>> = this.store1$.pipe(select(fromAmountTableSelectors.getSelected));
  loading$: Observable<boolean> = this.store1$.pipe(select(fromAmountTableSelectors.getLoading));
  private selectedEntry$: Subscription;
  private orgChange$: Subscription;

  selectedEntry: InvoiceDivEntry;
  selectedOrg: number;

  tableAmountMetaData;

  constructor(private store1$: Store<AmountTableState>,
              private store2$: Store<NettingTableState>,
              private organisationService: OrganisationService) {
    this.selectedEntry$ = this.store2$.pipe(select(fromNettingTableSelectors.getSelected), filter(l => !!l))
      .subscribe(val => {
        this.selectedEntry = val;
        this.initMetaData();
      });
    this.orgChange$ = this.organisationService.getChangeOrganisationObservable()
      .subscribe(org => this.selectedOrg = org?.organisationId);
  }

  initMetaData() {
    this.tableAmountMetaData = [
      [
        {
          invoiceStates: [InvoiceState.pending, InvoiceState.matched, InvoiceState.mismatched],
          invoiceDocumentTypes: [InvoiceDocumentType.official],
          invoiceSenderOrgId: this.selectedOrg,
          invoiceReceiverOrgId: this.selectedEntry.counterPartyId
        },
        {
          invoiceStates: [InvoiceState.pending, InvoiceState.matched, InvoiceState.mismatched],
          invoiceDocumentTypes: [InvoiceDocumentType.official],
          invoiceSenderOrgId: this.selectedEntry.counterPartyId,
          invoiceReceiverOrgId: this.selectedOrg
        },
        {
          invoiceStates: [InvoiceState.pending],
          invoiceDocumentTypes: [InvoiceDocumentType.official],
          invoiceSenderOrgId: this.selectedOrg,
          invoiceReceiverOrgId: this.selectedEntry.counterPartyId
        },
        {
          invoiceStates: [InvoiceState.matched],
          invoiceDocumentTypes: [InvoiceDocumentType.official],
          invoiceSenderOrgId: this.selectedOrg,
          invoiceReceiverOrgId: this.selectedEntry.counterPartyId
        },
        {
          invoiceStates: [InvoiceState.mismatched],
          invoiceDocumentTypes: [InvoiceDocumentType.official],
          invoiceSenderOrgId: this.selectedOrg,
          invoiceReceiverOrgId: this.selectedEntry.counterPartyId
        }
      ],
      [
        {
          invoiceStates: [InvoiceState.pending, InvoiceState.matched, InvoiceState.mismatched],
          invoiceDocumentTypes: [InvoiceDocumentType.shadow],
          invoiceSenderOrgId: this.selectedOrg,
          invoiceReceiverOrgId: this.selectedEntry.counterPartyId
        },
        {
          invoiceStates: [InvoiceState.pending, InvoiceState.matched, InvoiceState.mismatched],
          invoiceDocumentTypes: [InvoiceDocumentType.shadow],
          invoiceSenderOrgId: this.selectedEntry.counterPartyId,
          invoiceReceiverOrgId: this.selectedOrg
        },
        {
          invoiceStates: [InvoiceState.pending],
          invoiceDocumentTypes: [InvoiceDocumentType.shadow],
          invoiceSenderOrgId: this.selectedOrg,
          invoiceReceiverOrgId: this.selectedEntry.counterPartyId
        },
        {
          invoiceStates: [InvoiceState.matched],
          invoiceDocumentTypes: [InvoiceDocumentType.shadow],
          invoiceSenderOrgId: this.selectedOrg,
          invoiceReceiverOrgId: this.selectedEntry.counterPartyId
        },
        {
          invoiceStates: [InvoiceState.mismatched],
          invoiceDocumentTypes: [InvoiceDocumentType.shadow],
          invoiceSenderOrgId: this.selectedOrg,
          invoiceReceiverOrgId: this.selectedEntry.counterPartyId
        }
      ],
      [
        {
          invoiceStates: [InvoiceState.pending, InvoiceState.matched, InvoiceState.mismatched],
          invoiceDocumentTypes: [InvoiceDocumentType.official, InvoiceDocumentType.shadow],
          invoiceSenderOrgId: this.selectedOrg,
          invoiceReceiverOrgId: this.selectedEntry.counterPartyId
        },
        {
          invoiceStates: [InvoiceState.pending, InvoiceState.matched, InvoiceState.mismatched],
          invoiceDocumentTypes: [InvoiceDocumentType.official, InvoiceDocumentType.shadow],
          invoiceSenderOrgId: this.selectedEntry.counterPartyId,
          invoiceReceiverOrgId: this.selectedOrg
        },
        {
          invoiceStates: [InvoiceState.pending],
          invoiceDocumentTypes: [InvoiceDocumentType.official, InvoiceDocumentType.shadow],
          invoiceSenderOrgId: this.selectedOrg,
          invoiceReceiverOrgId: this.selectedEntry.counterPartyId
        },
        {
          invoiceStates: [InvoiceState.matched],
          invoiceDocumentTypes: [InvoiceDocumentType.official, InvoiceDocumentType.shadow],
          invoiceSenderOrgId: this.selectedOrg,
          invoiceReceiverOrgId: this.selectedEntry.counterPartyId
        },
        {
          invoiceStates: [InvoiceState.mismatched],
          invoiceDocumentTypes: [InvoiceDocumentType.official, InvoiceDocumentType.shadow],
          invoiceSenderOrgId: this.selectedOrg,
          invoiceReceiverOrgId: this.selectedEntry.counterPartyId
        }
      ]
    ];
  }

  ngOnDestroy(): void {
    this.selectedEntry$.unsubscribe();
    this.store1$.dispatch(cleanState());
  }

  getQueryParametersByIndex = (rowIdx: number, columnIdx: number): InvoiceFinderQueryParameters => {
    const caseNumber = rowIdx * 5 + columnIdx;
    const rowNames = ['To Receive', 'To Pay', 'Overall'];

    const ret = {
      rowName: rowNames[rowIdx],
      columnName: this.columns[columnIdx].title,
      caseNumber,
      counterpartyId: this.selectedEntry.counterPartyId,
      counterparty: this.selectedEntry.counterParty,
      ...this.tableAmountMetaData[rowIdx][columnIdx - 1],
      myVAT: this.selectedEntry.myVAT,
      cpVAT: this.selectedEntry.cpVAT,
      invoicePaymentDate: this.selectedEntry.dueDate,
      nettingAgreement: this.selectedEntry.nettingAgreement,
      commodities: this.selectedEntry.commodities
    };

    return ret;
  }

}
