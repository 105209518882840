import { ErrorResponse } from '@common/error-response.model';
import { PagingFilter, SortingFilter } from '@common/shared/results.model';

export interface OrganisationGroup {
  allowedIpRange: string;
  apiKey: string;
  displayName: string;
  fourthPartyPermission: boolean;
  legalName: string;
  numberOfActiveOperators: number;
  numberOfActiveOrganisations: number;
  numberOfActiveUserAdmins: number;
  organisationGroupId: number;
  reportFeedPartner: string;
  withBillingData: boolean;
  withDeleteActionEnabled: boolean;
  reportLevel: ReportLevel;
  excludeFromBilling: boolean;
  activityReport: boolean;
  reconciliationReport: boolean;
}

export interface GetOrganisationGroupResponse extends ErrorResponse {
  values: OrganisationGroup[];
  currentPage?: number;
  count?: number;
}

export interface GetOrganisationGroupsRequest extends ErrorResponse {
  filterValue?: string;
  paging?: PagingFilter;
  sorting?: SortingFilter;
}

export interface BillingHistoryRequest {
  organisationGroupId: any;
  paging: PagingFilter;
  sorting: SortingFilter;
}

export interface BillingHistoryResponse extends ErrorResponse {
  values: BillingHistoryValues[];
  count: number;
  currentPage: number;
}

export interface BillingHistoryValues {
  invoiceDate: string;
  invoiceNumber: number;
  invoiceTotal: number;
  state: string;
}

export interface AddEditOrganisationGroup {
  displayName: string;
  fourthPartyPermissions: boolean;
  ipRange: string;
  legalName: string;
  reportFeedPartnerId: string;
  apiKey?: string;
  organisationGroupId: number;
  reportLevel: ReportLevel;
  activityReport: boolean;
  reconciliationReport: boolean;
}

export interface EditBillingData {
  [key: string]: string;
}

export interface GetEditBillingDataResponse {
 value: {
   data: EditBillingFormData,
   vatCodes: VatCode[]
 };
}

export interface VatCode {
  key: number;
  value: string;
}

export interface EditBillingFormData {
  additionalNotes: string;
  additionalServices: {
    amount: number,
    description: string;
  }[];
  address00: string;
  address01: string;
  address02: string;
  address03: string;
  address04: string;
  contactEmails: string[];
  debtorId: string;
  invoiceRecipientOverride: string;
  organisationGroupID: number;
  vatCode: number;
  vatNumber: string;
  vatRate: number;
  errFreeTransactions: number;
  errOutstandingBalance: number;
  excludeFromBilling: boolean;
  separateAcerBilling: boolean;
  acerInvoiceRecipientOverride: string;
  acerAddress00: string;
  acerAddress01: string;
  acerAddress02: string;
  acerAddress03: string;
  acerAddress04: string;
  acerContactEmails: string[];
  acerAdditionalNotes: string;
}

export interface EditOrgGroupResponse extends ErrorResponse {
  value: {
    message: string;
    success:boolean;
    organisationGroup: OrganisationGroup;
  };
}

export enum ReportLevel {
  ORGANISATION = 'Organisation',
  GROUP = 'Group'
}

export enum Reporting {
  ACTIVITY = 'Activity Report',
  RECONCILIATION = 'Reconciliation Report'
}
