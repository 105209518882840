import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'cms-input-text-filter',
  templateUrl: './input-text-filter.component.html',
  styleUrls: ['./input-text-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputTextFilterComponent implements OnInit, OnChanges {
  @Input() text: string;
  @Input() placeholder = '';
  @Output() filter = new EventEmitter<string>();
  @Input() maxLength = 255;
  form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      filter: this.text || null,
    });
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
     if (this.form && changes.text) {
      this.form.setValue({
        filter: this.text
      });
      this.form.updateValueAndValidity();
    }
  }

  onFilter(): void {
    this.filter.emit(this.form.value.filter);
  }

  onReset(): void {
    this.form.setValue({
      filter: null,
    });
    this.filter.emit(this.form.value.filter);
  }
}
