import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cms-esm-mis-match-button',
  templateUrl: './esm-mis-match-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EsmMisMatchButtonComponent implements OnInit{
  @Input() row: { misMatchButtonVisible: boolean, id?: number };
  @Input() route: string;
  title: string;
  queryParams;


  ngOnInit(): void {
    if(typeof this.route !== 'undefined') {
    if (this.route.includes('netting')) {
      this.title = 'eSM Netting Mismatches';
      this.queryParams = { nettingStatementId: this.row.id };
    } else {
      this.title = 'eSM Invoice Mismatch';
      this.queryParams = { invoiceId: this.row.id };
    }
    }
  }
}
