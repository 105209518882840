import { createReducer, on } from '@ngrx/store';
import * as fromAddPanelActions from './add-panel.actions';
import * as fromActions from '../invoice-settings.actions';

export interface EsmSettingsAddPanelState {
  values: {
    type: string;
    commodity: string;
    priceType: string;
    natureOfPrice: string;
  };
  types: string[];
  commodities: string[];
  priceTypes: string[];
  natureOfPrices: string[];
}

export const addPanelInitialState: EsmSettingsAddPanelState = {
  values: {
    type: null,
    commodity: null,
    priceType: null,
    natureOfPrice: null
  },
  types: null,
  commodities: null,
  priceTypes: null,
  natureOfPrices: null,
};

export const esmAddPanelReducer = createReducer<EsmSettingsAddPanelState,
  fromAddPanelActions.EsmSettingsAddPanelActions>(
  addPanelInitialState,
  on(fromAddPanelActions.loadPresetValuesSuccessAction, (state: EsmSettingsAddPanelState, { payload: { types, commodities, priceTypes, natureOfPrices } }): EsmSettingsAddPanelState => ({
    ...state,
    values: {
      type: null,
      commodity: null,
      priceType: null,
      natureOfPrice: null
    },
    types,
    commodities,
    priceTypes,
    natureOfPrices
  })),
  on(fromAddPanelActions.cleanAddProductAction, (state: EsmSettingsAddPanelState): EsmSettingsAddPanelState => ({
    ...state,
    values: {
      type: null,
      commodity: null,
      priceType: null,
      natureOfPrice: null
    },
  })),
  on(fromActions.setToDefaultAction, (state: EsmSettingsAddPanelState): EsmSettingsAddPanelState => ({
    ...addPanelInitialState,
  })),
);
