import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { Message } from 'primeng/api';
import { Column } from '@common/shared/datatable.model';
import { OrganisationService } from '@common/organisation.service';
import { allColumnsConfig, ProductsModel } from './products.model';
import { ProductsState } from '../../../state/settings/products/products.reducers';
import * as fromSelectors from '../../../state/settings/products/products.selectors';
import * as fromAction from '../../../state/settings/products/products.actions';

@Component({
  selector: 'cms-ecm-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit, OnDestroy {
  data$: Observable<ProductsModel> = this.store.pipe(select(fromSelectors.getData));
  messages$: Observable<Message[]> = this.store.pipe(select(fromSelectors.getMessages));
  dialog$ = this.store.pipe(select(fromSelectors.getDialog));
  subscription$: Subscription;
  transactionType: string;
  commodity: string;
  market: string;
  productId: number;
  orgName: string;
  readonly allColumnsConfig: Column[] = allColumnsConfig;

  constructor(private store: Store<ProductsState>,
              private organisationService: OrganisationService) { }

  ngOnInit() {
    this.store.dispatch(new fromAction.LoadDataAction());

    this.subscription$ = this.organisationService
      .getOrganisationChangeIfAccessible()
      .subscribe(() => {
        this.orgName = this.organisationService.getSelectedOrganisationName();
        this.store.dispatch(new fromAction.SetToDefaultAction());
        this.store.dispatch(new fromAction.LoadDataAction());
      });
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
    this.store.dispatch(new fromAction.SetToDefaultAction());
  }

  onChangeStatus(row) {
    this.transactionType = row.transactionType;
    this.commodity = row.commodity;
    this.market = row.market;
    this.productId = row.productId;
    this.store.dispatch(new fromAction.OpenDialogAction({
      messages: [],
      data: row.doWeMatch
    }));
  }

  onDownloadProducts(): void {
    this.store.dispatch(new fromAction.DownloadProductsDataAction());
  }

  onCancelButton(): void {
    this.store.dispatch(new fromAction.CloseDialogAction());
    this.store.dispatch(new fromAction.LoadDataAction());
  }

  onActivateButton(productId): void {
    this.store.dispatch(new fromAction.ConfirmActivateAction(productId));
  }

  onBlockItem(productId): void {
    this.store.dispatch(new fromAction.ConfirmBlockAction(productId));
  }
}
