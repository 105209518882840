import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { InjectionToken } from '@angular/core';
import * as nttInvoiceTable from './netted-invoices/invoices-table.reducer';
import * as nttInvoiceDialog from './dialog/dialog.reducers';
import * as fromCommon from '@common/state/reducers';
import { INVOICE_FINDER_DIALOGS } from '../../../../invoice/finder/invoice-finder.model';

export const getNttInvoiceTable = createFeatureSelector<State>('ntt_invoice_table');

export interface State {
  dialog: fromCommon.DialogState<INVOICE_FINDER_DIALOGS>;
  nttTable: nttInvoiceTable.InvoicesTableState;
}

export const reducers: ActionReducerMap<State> = {
  nttTable: nttInvoiceTable.invoicesTableReducer,
  dialog: nttInvoiceDialog.esmInvoiceFinderDialogReducer
};

export const initialState: State = {
  dialog: null,
  nttTable: nttInvoiceTable.invoicesTableInitialState
};


export const reducerToken = new InjectionToken<ActionReducerMap<State>>(
  'Netted Invoice Reducers'
);

export const getReducers = () => reducers;

