import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Column } from '@common/shared/datatable.model';
import { EcmBucketsTableRows, EcmDateRange, ecmStatesToLabelMaps } from '../ecm-dashboard.model';
import { EcmDashboardState } from '../../state/dashboard/dashboard.reducers';
import * as fromDialogSelectors from '../../state/dashboard/dialog/dialog.selectors';
import * as fromTabSelectors from '../../state/dashboard/tab/tab.selectors';
import { GetBucketsAction } from '../../state/dashboard/tab/tab.actions';
import { OrganisationService } from '@common/organisation.service';

@Component({
  selector: 'cms-ecm-dashboard-tab',
  templateUrl: './ecm-dashboard-tab.component.html',
  styleUrls: ['./ecm-dashboard-tab.component.scss']
})
export class EcmDashboardTabComponent implements OnInit, OnDestroy {
  @Input() category: string = '';
  dialog$ = this.store.pipe(select(fromDialogSelectors.getDialog));
  messages$ = this.store.pipe(select(fromTabSelectors.getMessages));
  loading$ = this.store.pipe(select(fromTabSelectors.getLoading));
  datatableData$ = this.store.pipe(select(fromTabSelectors.getBucketsValues));
  columns$ = this.store.pipe(select(fromTabSelectors.getColumns));

  readonly ageValuesMapping: { [key: string]: string } = ecmStatesToLabelMaps;
  navigationSubscription;

  constructor(private store: Store<EcmDashboardState>,
              private router: Router,
              private organisationService: OrganisationService) { }

  ngOnInit() {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd  &&  this.organisationService.getCurrentOrganisationId()!==null) {
        this.store.dispatch(new GetBucketsAction());
      }
    });
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  onClick({state}: EcmBucketsTableRows, {name}: Column): void {
    this.router.navigate([`/ecm/dashboard/${this.category}/deals`],
      {
        queryParams: {
          state,
          dateRange: name !== 'state' ? name : EcmDateRange.TOTAL,
        }
      });
  }
}
