import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {OrganisationService} from '@common/organisation.service';
import {Subscription} from 'rxjs';
import {EcmProductsState} from '../state/products/ecm-products.reducers';
import {EcmAddProduct} from './ecm-products';
import * as fromActions from '../state/products/ecm-products.actions';
import * as fromProductsActions from '../state/products/ecm-products.actions';
import * as fromSelectors from '../state/products/ecm-products.selectors';
import {Title} from '@angular/platform-browser';
import { ErrorMessageService } from '@common/errors/error-message.service';

export const defaultStyles = {
  'text-align': 'left',
  'text-overflow': 'ellipsis',
  'white-space': 'nowrap'
};

@Component({
  selector: 'cms-ecm-edit-products',
  templateUrl: './ecm-products.component.html',
  styleUrls: ['./ecm-products.component.scss']
})
export class EcmProductsComponent implements OnInit, OnDestroy {
  subscription$: Subscription;
  commodities$ = this.store.pipe(select(fromSelectors.getCommodities));
  markets$ = this.store.pipe(select(fromSelectors.getMarkets));
  transactionTypes$ = this.store.pipe(select(fromSelectors.getTransactionTypes));
  messages$ = this.store.pipe(select(fromSelectors.getMessages));
  add$ = this.store.pipe(select(fromSelectors.getAddPanel));
  data$ = this.store.pipe(select(fromSelectors.getData));
  loading$ = this.store.pipe(select(fromSelectors.getLoading));
  dataTableColumns = [
    { name: 'transactionType', type: 'text', title: 'Transaction Type', style: { ...defaultStyles }, sortable: false, visible: true },
    { name: 'commodity', type: 'text', title: 'Commodity', style: { ...defaultStyles }, sortable: false, visible: true },
    { name: 'market', type: 'text', title: 'Market', style: { ...defaultStyles, 'width': '66px' }, sortable: false, visible: true },
    { name: 'matchedBy', type: 'text', title: 'Matched by', style: { ...defaultStyles, 'width': '100px' }, sortable: false, visible: true },
    { name: 'delete', type: 'deleteButton', title: 'Delete', style: { ...defaultStyles, 'text-align': 'center', 'width': '60px' }, sortable: false, visible: true }
  ];

  constructor(private store: Store<EcmProductsState>,
              private titleService: Title,
              private organisationService: OrganisationService,
              public readonly errorMessageService: ErrorMessageService) { }

  onDownload(): void {
    this.store.dispatch(fromActions.downloadAction());
  }

  onAdd(obj: EcmAddProduct): void {
    this.store.dispatch(fromActions.addProductAction(obj));
  }

  onClear(): void {
    this.store.dispatch(fromActions.cleanAddProductAction());
  }

  onDeleteItem(productId: string): void {
    this.store.dispatch(fromActions.deleteAction({productId}));
  }

  ngOnInit(): void {
    this.titleService.setTitle('eCM Product Maintenance');
    this.store.dispatch(fromProductsActions.loadPresetsAction());
    this.store.dispatch(fromProductsActions.loadDataAction());

    this.subscription$ = this.organisationService.getOrganisationChangeIfAccessible()
      .subscribe(() => {
        this.store.dispatch(fromProductsActions.setToDefaultAction());
        this.store.dispatch(fromProductsActions.loadPresetsAction());
        this.store.dispatch(fromProductsActions.loadDataAction());
      });
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}
