import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Exception } from '../exceptions.model';

@Component({
  selector: 'cms-exceptions-info-dialog',
  templateUrl: './exceptions-info-dialog.component.html',
  styleUrls: ['./exceptions-info-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExceptionsInfoDialogComponent {
  @Input() data: Exception;
  @Input() display: boolean = false;
  @Output() cancel = new EventEmitter();
  header: string = 'Exception Details';

  onCancel(): void {
    this.cancel.emit();
  }
}
