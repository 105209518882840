<p-dialog *ngIf="display"
          header="Manage Saved Filters"
          [visible]="display"
          [modal]="true"
          (visibleChange)="onVisibleChange($event)"
          styleClass="filter-management"
          appendTo="body"
          [style]="{width: '815px', minHeight: '285px'}">
  <p-messages [value]="messages" [closable]="true"></p-messages>
  <div class="margin-15">
    <form [formGroup]="form">
      <cms-datatable [tableColumns]="columns"
                     [scrollable]="false"
                     [loading]="false"
                     [data]="data"
                     [paginator]="false"
                     [lazy]="false">
          <ng-template pTemplate="body" let-row="row" let-column="column">
            <td *ngIf="column.type === 'radio'" [ngStyle]="column.style">
              <input type="radio"
                     id="{{'radio' + row.filterName}}"
                     [value]="row.filterName"
                     (change)="onDefaultChange(row)"
                     formControlName="defaultFilter"
                     name="defaultFilter"/>
            </td>
            <td *ngIf="column.type === 'button'" [ngStyle]="column.style">
              <cms-delete-filter [row]="row"
                                 [hide]="row.filterName === AllDealsMyFilterName"
                                 (delete)="onDeleteItem($event)">
              </cms-delete-filter>
            </td>
          </ng-template>
      </cms-datatable>
    </form>
  </div>
  <p-footer>
    <button #exit class="btn" type="button" (click)="onExit()">Exit</button>
  </p-footer>
</p-dialog>
