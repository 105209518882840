import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MarkdownModule } from 'ngx-markdown';
import { PipeModule } from '../pipe/pipe.module';
import { WelcomeComponent } from './welcome/welcome.component';
import { StatusComponent } from './welcome/status/status.component';
import { AnnouncementComponent } from './welcome/announcement/announcement.component';
import { ReleaseNotesComponent } from './release-notes/release-notes.component';
import { SupportComponent } from './support/support.component';
import { HelpService } from './help.service';
import { ContactsComponent } from './contacts/contacts.component';
import { DropdownModule } from 'primeng/dropdown';
import { CmsCommonModule } from '../common/cms-common.module';
import { ContactsFiltersComponent } from './contacts/contacts-filters/contacts-filters.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from './state/reducers';
import { ContactsUserContactComponent } from './contacts/contacts-user-contact/contacts-user-contact.component';
import { OrganisationGroupsEffects } from './state/organisation-groups/organisation-groups.effects';
import { UserDtEffects } from './state/user/user-dt.effects';
import { OrgGroupsDtEffects } from './state/org-group-dt/org-groups-dt.effects';
import { MessagesModule } from 'primeng/messages';
import { PanelModule } from 'primeng/panel';
import { HelpTextEditorComponent } from './editor/help-text-editor.component';
import { MessageModule } from 'primeng/message';

@NgModule({
  imports: [
    BrowserModule,
    PanelModule,
    PipeModule,
    FormsModule,
    MarkdownModule.forRoot(),
    MessagesModule,
    CmsCommonModule,
    DropdownModule,
    ReactiveFormsModule,
    StoreModule.forFeature('help', reducers),
    EffectsModule.forFeature([
      OrganisationGroupsEffects,
      UserDtEffects,
      OrgGroupsDtEffects
    ]),
  ],
  declarations: [
    AnnouncementComponent,
    WelcomeComponent,
    ReleaseNotesComponent,
    StatusComponent,
    SupportComponent,
    ContactsComponent,
    ContactsFiltersComponent,
    ContactsUserContactComponent,
    HelpTextEditorComponent
  ],
  providers: [
    HelpService
  ]
})
export class HelpModule { }

