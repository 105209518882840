import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { ConfirmationService, ConfirmEventType, Message } from 'primeng/api';
import { defaultOrg, EmailWhitelistModel, esmEmailTableColumns } from './whitelist.model';
import { DatatableData, } from '@common/shared/results.model';
import * as fromSelectors from '../../state/settings/whitelist/whitelist.selectors';
import * as fromActions from '../../state/settings/whitelist/whitelist.actions';
import { setMessages, toggleAttachXML, triggerCheckbox } from '../../state/settings/whitelist/whitelist.actions';
import { OrganisationService } from '@common/organisation.service';
import { ErrorMessageService } from '@common/errors/error-message.service';
import { filter } from 'rxjs/operators';
import { EditableCell } from '@common/datatable/cell-editor/cell-editor.model';

@Component({
  selector: 'cms-whitelist',
  templateUrl: './whitelist.component.html',
  styleUrls: ['./whitelist.component.scss'],
  providers: [ConfirmationService]
})
export class WhitelistComponent implements OnInit, OnDestroy {

  messages$: Observable<Message[]> = this.store.select(fromSelectors.getMessages);

  allowedToUpload$: Observable<boolean> = this.store.pipe(select(fromSelectors.isUploadAllowed));
  allowedMaxSize$: Observable<number> = this.store.pipe(select(fromSelectors.getMaxUploadSize));
  pdfOffsiteEnabled$: Observable<boolean> = this.store.pipe(select(fromSelectors.getPdfOffsiteEnabled));
  loading$: Observable<{ table: boolean, upload: boolean }> = this.store.pipe(select(fromSelectors.getLoading));
  pagingAndSorting$: Observable<any> = this.store.select(fromSelectors.getPagingAndSorting);
  data$: Observable<DatatableData<EmailWhitelistModel[]>> = this.store.select(fromSelectors.getTableData);
  searchToken$: Observable<string> = this.store.select(fromSelectors.getSearchToken);
  organisation$: Observable<boolean> = this.organisationService.onRefresh().pipe(filter(auth => auth));

  currentOrg= defaultOrg;
  columns = esmEmailTableColumns;
  checked: boolean= false;
  subscriptions$: Subscription = new Subscription();

  constructor(private store: Store<any>,
              public organisationService: OrganisationService,
              public readonly errorMessageService:ErrorMessageService,
              private confirmationService: ConfirmationService) {
  }

  ngOnInit(): void {

    this.subscriptions$.add(this.organisation$.subscribe(() => {
        this.store.dispatch(fromActions.loadPresets());
        this.store.dispatch(fromActions.loadTableData());
      }));
    this.subscriptions$.add(this.organisationService.getChangeOrganisationObservable()
      .subscribe(i => this.currentOrg = i));
    this.subscriptions$.add(this.pdfOffsiteEnabled$.subscribe(ck => this.checked = ck));
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
    this.store.dispatch(fromActions.cleanState());
  }

  onUploadError(messages: Message[]) {
    this.store.dispatch(fromActions.setMessages(messages));
  }

  onUpload(file: File) {
    this.store.dispatch(fromActions.uploadCsv(file));
  }

  onDownload() {
    this.store.dispatch(fromActions.downloadCsv(this.organisationService.getCurrentOrganisationId()));
  }

  onLazyLoad({ page, perPage, sortField, sortOrder }) {
    this.store.dispatch(fromActions.pagingAndSorting({
      paging: {
        page,
        entriesPerPage: perPage
      },
      sorting: {
        sortOrder,
        columnName: sortField
      }
    }));
  }

  onTextFieldFilter(filterInput: string) {
    this.store.dispatch(fromActions.setSearchToken(filterInput));
  }

  get legend():string{
    return this.currentOrg.displayName + ' (' + this.currentOrg.eicCode + ') Support For eSM Offsite Parties';
  }


  toggleAttachXML(cell: EditableCell) {
    const data = cell.data;
    this.store.dispatch(toggleAttachXML({ data }));
  }



  toggle({ checked }) {

    if (checked) {
      this.store.dispatch(triggerCheckbox({ checked }));
    } else {

      this.confirmationService.confirm({
        message: '<b>Please Note:</b> \n' +
          'If the checkbox \'<i>eSM Offsite Parties support is enabled</i> \' is switched off, ' +
          'any invoices uploaded for offsite counterparties listed in your email whitelist will be rejected with a FAILED boxresult by eSM. ' +
          'Your company will not be able to send offsite invoices anymore. \n' +
          '<br> Do you really want to proceed and turn off support for offsite invoices?',

        icon: 'fa fa-exclamation',
        accept: () => {
          this.store.dispatch(triggerCheckbox({ checked }));
        },

        reject: (type) => {
          switch (type) {
            case ConfirmEventType.REJECT:
              this.checked = true;
              this.store.dispatch(setMessages([{ severity: 'warn', detail: 'Canceled' }]));
              break;
          }
        }
      });

    }

  }
}
