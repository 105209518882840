import { Component, OnDestroy, OnInit } from '@angular/core';
import { NettingStatementData } from './generation-dialog.model';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromTableSelectors from '../../state/netting-preview/netting-table/netting-table.selectors';
import { filter, switchMap } from 'rxjs/operators';
import { checkPresence } from '../../state/netting-preview/invoice-overview/invoice-overview.selectors';
import { NettingPreviewState } from '../../state/netting-preview';
import * as GenerationDialogActions from '../../state/netting-preview/generation-dialog/generation-dialog.actions';
import { InvoiceDivEntry } from '../netting-table-overview/netting-table-overview.model';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import * as nettingDialogSelectors from '../../state/netting-preview/generation-dialog/generation-dialog.selectors';
import * as InvoiceActions from '../../state/netting-preview/invoice-overview/invoice-overview.actions';

@Component({
  selector: 'cms-generation-dialog',
  templateUrl: './generation-dialog.component.html',
  styleUrls: ['./generation-dialog.component.scss'],
  providers: [DialogService]
})
export class GenerationDialogComponent implements OnInit, OnDestroy {

  nettingStatement: NettingStatementData;
  tableSelected: InvoiceDivEntry;

  subscription$: Subscription = new Subscription();
  getTableEntry$: Observable<InvoiceDivEntry> = this.store$.pipe(select(fromTableSelectors.getSelected));
  nettingStatement$: Observable<NettingStatementData> = this.store$.pipe(select(nettingDialogSelectors.getNettingStatement));
  openInvoices: any[]=[];

  selectItem$ = this.getTableEntry$.pipe(
    filter(row => Boolean(row)),
    switchMap(({ id }) => this.store$.select(checkPresence, { id })));


  noScroll= {
    'max-height':'150px',
    'font-size':'0.8rem'};

  scrollStyle = {
    'max-height':'150px',
    'font-size':'0.8rem',
    'border-color': '#d1d1d1',
    'border-style': 'solid',
    'border-radius': '5px'
  };

  constructor(public config: DynamicDialogConfig,
              public ref: DynamicDialogRef,
              private store$: Store<NettingPreviewState>) {

    this.subscription$.add(this.nettingStatement$.subscribe((nettingStatement) => {
      this.nettingStatement = nettingStatement;
    }));

    this.subscription$.add(this.getTableEntry$.subscribe((tableEntry) => {
      this.tableSelected = tableEntry;
      }));
  }

  ngOnInit(): void {
    this.openInvoices = this.nettingStatement.openInvoices.map( openInvoice => openInvoice).map(label => ({ label }));
  }

  onConfirm() {
    this.store$.dispatch(GenerationDialogActions.generateNetting({ selected: this.tableSelected }));
    this.ref.close();
    this.ref.destroy();
  }

  onCancel() {
    this.store$.dispatch(GenerationDialogActions.closeDialog());
    this.ref.close();
    this.ref.destroy();
  }

  ngOnDestroy() {
    this.store$.dispatch(InvoiceActions.setAllowDialog(false));

    this.ref.close();
    this.ref.destroy();
    this.subscription$.unsubscribe();
  }

  getStyle() {
    return this.openInvoices.length > 4 ? this.scrollStyle : this.noScroll;
  }
}
