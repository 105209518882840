import { Component, OnDestroy, OnInit } from '@angular/core';
import { ErrorMessageService } from './error-message.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ErrorInformation } from './error.model';
import { AuthenticationService } from '../../auth/authentication.service';
import { StateService } from '../state.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'cms-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject();
  errors: ErrorInformation[] = [];
  errorIndex: number = 0;
  showNextButton: boolean = false;
  isLoggedIn: boolean = false;

  constructor(private router: Router,
              private errorMessageService: ErrorMessageService,
              private authenticationService: AuthenticationService,
              private titleService: Title,
              private stateService: StateService
  ) {
  }

  ngOnInit() {
    this.isLoggedInCheck();
    this.titleService.setTitle('Error');
    if (!this.errorMessageService.hasError()) { // e.g. when user reloads the tab when on error page
      this.navigateToHomepage();
    }

    this.errorMessageService.onError().pipe(takeUntil(this.onDestroy$)).subscribe((errorInformation: ErrorInformation) => {
      if (errorInformation) {
        this.errors.push(errorInformation);
        this.showNextButton = this.errors.length > 1;
      }
    });
  }

  private isLoggedInCheck(): void {
    this.authenticationService.isAuthenticated()
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(isLoggedIn => {
      const user = this.authenticationService.getUser();
      this.isLoggedIn = !!(isLoggedIn && user);
    });
  }

  get error(): ErrorInformation {
    if (this.errors.length > 0) {
      return this.errors[this.errorIndex];
    }
    return null;
  }

  get currentErrorNumber(): number {
    return this.errorIndex + 1;
  }

  get errorCount(): number {
    return this.errors.length;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  next(): void {
    this.errorIndex = ((this.errorIndex + 1) >= this.errors.length) ? 0 : this.errorIndex + 1;
  }

  logout(): void {
    // used in stead of authenticationService.logout to avoid new errors
    this.authenticationService.setAuthenticated(false);
    this.stateService.reset();
    this.returnToHomePage();
  }

  returnToHomePage(): void {
    this.navigateToHomepage();
    this.reset();
  }

  private reset() {
    this.errors = [];
    this.errorMessageService.clear();
    this.showNextButton = false;
    this.errorIndex = 0;
  }

  private navigateToHomepage() {
    this.router.navigate(['/'], { queryParamsHandling: 'preserve' });
  }

}
