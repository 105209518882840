import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AdministrationRoutingModule } from './administration-routing.module';
import { AdministrationTabPanelComponent } from './administration-tab-panel/administration-tab-panel.component';
import { CmsCommonModule } from '@common/cms-common.module';
import { UserAccountsComponent } from './administration-tab-panel/user-accounts/user-accounts.component';
import { UserAccountEffects, } from './state/user-account/user-account.effects';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { reducers } from './state/reducers';
import { UserRolesComponent } from './administration-tab-panel/user-roles/user-roles.component';
import { UserRolesFiltersComponent } from './administration-tab-panel/user-roles/user-roles-filters/user-roles-filters.component';
import { UserRoleEffects } from './state/user-role/user-role.effects';
import { UserRoleStatusComponent } from './administration-tab-panel/user-roles/user-role-status/user-role-status.component';
import { OrganisationsComponent } from './administration-tab-panel/organisations/organisations.component';
import { OrganisationGroupFilterComponent } from './administration-tab-panel/common/organisation-group-filter/organisation-group-filter.component';
import { OrganisationEffects } from './state/organisation/organisation.effects';
import { PartyDataEffects } from './state/party-data/party-data.effects';
import { ActivationDialogComponent } from './administration-tab-panel/organisations/dialogs-container/activation-dialog/activation-dialog.component';
import { OrganisationDialogsContainerComponent } from './administration-tab-panel/organisations/dialogs-container/organisation-dialogs-container.component';
import { AddOrganisationDialogComponent } from './administration-tab-panel/organisations/dialogs-container/add-organisation-dialog/add-organisation-dialog.component';
import { EditOrganisationDialogComponent } from './administration-tab-panel/organisations/dialogs-container/edit-organisation-dialog/edit-organisation-dialog.component';
import { DeleteOrganisationDialogComponent } from './administration-tab-panel/organisations/dialogs-container/delete-organisation-dialog/delete-organisation-dialog.component';
import { MoveOrganisationDialogComponent } from './administration-tab-panel/organisations/dialogs-container/move-organisation-dialog/move-organisation-dialog.component';
import { EditEcmDialogComponent } from './administration-tab-panel/organisations/dialogs-container/edit-ecm-dialog/edit-ecm-dialog.component';
import { EditErrDialogComponent } from './administration-tab-panel/organisations/dialogs-container/edit-err-dialog/edit-err-dialog.component';
import { FhaAccountsComponent } from './administration-tab-panel/fha-accounts/fha-accounts.component';
import { FhaAccountsEffects } from './state/fha-accounts/fha-accounts.effects';
import { EditableStatusCellComponent } from './administration-tab-panel/common/editable-status-cell/editable-status-cell.component';
import { UserStatesComponent } from './administration-tab-panel/common/user-states/user-states.component';
import { DialogsContainerComponent } from './administration-tab-panel/common/dialogs-container/dialogs-container.component';
import { LockInactivateDialogComponent } from './administration-tab-panel/common/dialogs-container/lock-inactivate-dialog/lock-inactivate-dialog.component';
import { ActivateDialogComponent } from './administration-tab-panel/common/dialogs-container/activate-dialog/activate-dialog.component';
import { EditUserAccountDialogComponent } from './administration-tab-panel/common/dialogs-container/edit-user-account-dialog/edit-user-account-dialog.component';
import { ResetPasswordDialogComponent } from './administration-tab-panel/common/dialogs-container/reset-password-dialog/reset-password-dialog.component';
import { AddUserAccountDialogComponent } from './administration-tab-panel/common/dialogs-container/add-user-account-dialog/add-user-account-dialog.component';
import { OrganisationGroupsComponent } from './administration-tab-panel/organisation-groups/organisation-groups.component';
import { InputTextFilterComponent } from './administration-tab-panel/common/input-text-filter/input-text-filter.component';
import { OrganisationGroupsEffects } from './state/organisation-groups/organisation-groups.effects';
import { AddEditGroupDialogComponent } from './administration-tab-panel/organisation-groups/add-edit-group-dialog/add-edit-group-dialog.component';
import { EditBillingDataDialogComponent } from './administration-tab-panel/organisation-groups/edit-billing-data-dialog/edit-billing-data-dialog.component';
import { BillingReportsComponent } from './administration-tab-panel/billing-reports/billing-reports.component';
import { BillingReportsEffects } from './state/billing-reports/billing-reports.effects';
import { BillingReportsFiltersComponent } from './administration-tab-panel/billing-reports/billing-reports-filters/billing-reports-filters.component';
import { PartyDataComponent } from './administration-tab-panel/party-data/party-data.component';
import { PartyDataAddPanelComponent } from './administration-tab-panel/party-data/party-data-add-panel/party-data-add-panel.component';
import { CmsBillingRunTabComponent } from './administration-tab-panel/billing-runs/cms/cms-billing-run-tab.component';
import { DeleteBillingRunsDialogComponent } from './administration-tab-panel/billing-runs/cms/delete-billing-runs-dialog/delete-billing-runs-dialog.component';
import { ErrorDetailsDialogComponent } from './administration-tab-panel/billing-runs/cms/error-details-dialog/error-details-dialog.component';
import { EditBillingParamsDialogComponent } from './administration-tab-panel/billing-runs/cms/edit-billing-params-dialog/edit-billing-params-dialog.component';
import { BillingParamsComponent } from './administration-tab-panel/billing-runs/cms/billing-params/billing-params.component';
import { StartBillingRunComponent } from './administration-tab-panel/billing-runs/cms/start-billing-run/start-billing-run.component';
import { BillingParamsFormComponent } from './administration-tab-panel/billing-runs/cms/billing-params-form/billing-params-form.component';
import { ElcomWhitelistComponent } from './administration-tab-panel/elcom-whitelist/elcom-whitelist.component';
import { ElcomWhitelistEffects } from './state/elcom-whitelist/elcom-whitelist.effects';
import { ElcomWhitelistAddPanelComponent } from './administration-tab-panel/elcom-whitelist/elcom-whitelist-add-panel/elcom-whitelist-add-panel.component';
import { ElcomWhitelistDialogComponent } from './administration-tab-panel/elcom-whitelist/elcom-whitelist-dialog/elcom-whitelist-dialog.component';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { KeyFilterModule } from 'primeng/keyfilter';
import { TabViewModule } from 'primeng/tabview';
import { ButtonModule } from 'primeng/button';
import { MessagesModule } from 'primeng/messages';
import { CalendarModule } from 'primeng/calendar';
import { PanelModule } from 'primeng/panel';
import { EditEsmDialogComponent } from './administration-tab-panel/organisations/dialogs-container/edit-esm-dialog/edit-esm-dialog.component';
import { UserAccountsFilterComponent } from './administration-tab-panel/user-accounts/user-accounts-filter/user-accounts-filter.component';
import { CheckboxModule } from 'primeng/checkbox';
import { AcerBillingRunTabComponent } from './administration-tab-panel/billing-runs/acer/acer-billing-run-tab.component';
import { BillingRunsComponent } from './administration-tab-panel/billing-runs/billing-runs.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { CmsBillingRunsEffects } from './state/billing-runs/cms/cms-runs.effects';
import { AcerBillingRunsEffects } from './state/billing-runs/acer/acer-runs.effects';
import { ShowErrorsComponent } from './administration-tab-panel/billing-runs/acer/show-errors/show-errors.component';
import { ConfirmDeleteComponent } from './administration-tab-panel/billing-runs/acer/confirm-delete/confirm-delete.component';
import { BillingTxReportComponent } from './administration-tab-panel/billing-tx-report/billing-tx-report.component';
import { MessageModule } from 'primeng/message';
import { ListboxModule } from 'primeng/listbox';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { BillingTxReportFiltersComponent } from './administration-tab-panel/billing-tx-report/billing-tx-report-filters/billing-tx-report-filters.component';
import { BillingTxReportEffects } from './state/billing-tx-report/billing-tx-report.effects';
import { BillingTxDatatableComponent } from './administration-tab-panel/billing-tx-report/billing-tx-datatable/billing-tx-datatable.component';
import { TableModule } from 'primeng/table';

@NgModule({
  imports: [
    CommonModule,
    CmsCommonModule,
    AdministrationRoutingModule,
    TabViewModule,
    DropdownModule,
    ReactiveFormsModule,
    DialogModule,
    MessagesModule,
    PanelModule,
    ButtonModule,
    CalendarModule,
    KeyFilterModule,
    StoreModule.forFeature('admin', reducers),
    EffectsModule.forFeature([
      UserAccountEffects,
      OrganisationEffects,
      UserRoleEffects,
      FhaAccountsEffects,
      OrganisationGroupsEffects,
      BillingReportsEffects,
      PartyDataEffects,
      CmsBillingRunsEffects,
      AcerBillingRunsEffects,
      ElcomWhitelistEffects,
      BillingTxReportEffects
    ]),
    CheckboxModule,
    InputNumberModule,
    MessageModule,
    ListboxModule,
    FormsModule,
    DynamicDialogModule,
    TableModule,
  ],
    declarations: [
        AdministrationTabPanelComponent,
        UserAccountsComponent,
        UserAccountsFilterComponent,
        OrganisationGroupFilterComponent,
        UserRolesComponent,
        UserRolesFiltersComponent,
        UserRoleStatusComponent,
        OrganisationsComponent,
        ActivationDialogComponent,
        OrganisationDialogsContainerComponent,
        AddOrganisationDialogComponent,
        EditOrganisationDialogComponent,
        DeleteOrganisationDialogComponent,
        MoveOrganisationDialogComponent,
        EditEcmDialogComponent,
        EditErrDialogComponent,
        FhaAccountsComponent,
        EditableStatusCellComponent,
        UserStatesComponent,
        DialogsContainerComponent,
        LockInactivateDialogComponent,
        ActivateDialogComponent,
        EditUserAccountDialogComponent,
        ResetPasswordDialogComponent,
        AddUserAccountDialogComponent,
        OrganisationGroupsComponent,
        InputTextFilterComponent,
        AddEditGroupDialogComponent,
        EditBillingDataDialogComponent,
        BillingReportsComponent,
        BillingReportsFiltersComponent,
        PartyDataComponent,
        PartyDataAddPanelComponent,
        CmsBillingRunTabComponent,
        DeleteBillingRunsDialogComponent,
        ErrorDetailsDialogComponent,
        EditBillingParamsDialogComponent,
        BillingParamsComponent,
        StartBillingRunComponent,
        BillingParamsFormComponent,
        ElcomWhitelistComponent,
        ElcomWhitelistAddPanelComponent,
        ElcomWhitelistDialogComponent,
        EditEsmDialogComponent,
        AcerBillingRunTabComponent,
        BillingRunsComponent,
        ShowErrorsComponent,
        ConfirmDeleteComponent,
        BillingTxReportComponent,
        BillingTxReportFiltersComponent,
        BillingTxDatatableComponent
    ],
    exports: [
        InputTextFilterComponent
    ]
})
export class AdministrationModule {
}
