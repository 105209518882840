import { createAction, union } from '@ngrx/store';
import { Message } from 'primeng/api';
import { EsmFileResponse, EsmFileUploadFilters } from '../../file-upload/file-upload.model';
import { DateRange } from 'app/esm/invoice/finder/invoice-finder.model';
import { DatatableConfig } from '@common/shared/results.model';

export const setToDefaultAction = createAction('[FILE UPLOAD] Set to default');
export const setMessagesAction = createAction('[FILE UPLOAD] Set Messages', (payload: Message[]) => ({ payload }));
export const clearFilters = createAction('[FILE UPLOAD] Clear Filters');
export const loadPresetValuesAction = createAction('[FILE UPLOAD] Load Preset Values');
export const setAllowedToUploadAction = createAction('[FILE UPLOAD] Set Allowed To Upload', (payload: boolean) => ({ payload }));
export const getMaxUploadSize = createAction('[FILE UPLOAD] Get Max Upload Size');
export const setMaxUploadSize = createAction('[FILE UPLOAD] Set Max Upload Size', (payload: number) => ({ payload }));
export const setFilterValuesAction = createAction('[FILE UPLOAD] Set Filter Values', (payload: EsmFileUploadFilters) => ({ payload }));
export const uploadFileAction = createAction('[FILE UPLOAD] Upload File', (payload: File) => ({ payload }));
export const filterByName = createAction('[FILE UPLOAD] Filter By Name', (payload: string) => ({ payload }));
export const filterByDateAction = createAction('[FILE UPLOAD] Filter By Date', (payload: DateRange) => ({ payload }));
export const loadDataAction = createAction('[FILE UPLOAD] Load Data');
export const loadDataSuccessAction = createAction('[FILE UPLOAD] Load Data Success', (payload: EsmFileResponse) => ({ payload }));
export const filterAction = createAction('[FILE UPLOAD] Filter', (payload: EsmFileUploadFilters) => ({ payload }));
export const sortAndPageDataAction = createAction('[FILE UPLOAD] Sort And Page', (payload: DatatableConfig) => ({ payload }));
export const downloadDocumentAction = createAction('[FILE UPLOAD] Download Document', (payload: number) => ({ payload }));
export const exportDocumentAction = createAction('[FILE UPLOAD] Export Document');
export const setBusy = createAction('[FILE UPLOAD] Set busy', (payload: boolean) => ({ payload }));

const actions = union({
  setToDefaultAction,
  setMessagesAction,
  clearFilters,
  loadPresetValuesAction,
  setAllowedToUploadAction,
  setFilterValuesAction,
  getMaxUploadSize,
  setMaxUploadSize,
  uploadFileAction,
  filterByName,
  filterByDateAction,
  loadDataAction,
  loadDataSuccessAction,
  filterAction,
  sortAndPageDataAction,
  downloadDocumentAction,
  exportDocumentAction,
  setBusy,
});

export type FileUploadActions = typeof actions;
