<section class="padding-12">
  <p-messages [value]="messages$ | async" [closable]="true"></p-messages>
  <cms-dashboard-deal-nav *ngIf="(viewType$ | async) === tableViewType.TABLE_DATA && (stateNames$ | async)"
                          [data]="stateNames$ | async"
                          [state]="state$ | async"
                          (back)="backToDashboard()"
                          (changeState)="changeState($event)">
  </cms-dashboard-deal-nav>
  <div class="row">
    <div class="col-8 col-xl-2 col-lg-8 col-md-8">
      <cms-esm-netting-role-by-filter [value]="nettingStatementRole$ | async"
                                      [disabled]="loading$ | async"
                                      (onChange)="onRoleByFilter($event)">
      </cms-esm-netting-role-by-filter>
    </div>
    <div class="col-12 col-xl-8 col-lg-12 col-md-12">
      <cms-netting-statement-filter *ngIf="filters$ | async"
                                    (reset)="onReset()"
                                    (filter)="onFilter($event)"
                                    [filters]="filters$ | async"
                                    [commodities]="commodities$ | async"
                                    [counterParties]="counterParties$ | async"
                                    [deliveryPoints]="deliveryPoints$ | async"
                                    [nettingStatementTypes]="nettingStatementTypes$ | async">
      </cms-netting-statement-filter>
    </div>
  </div>
  <!-- buckets table -->
  <cms-datatable *ngIf="(viewType$ | async) === tableViewType.BUCKETS && (data$ | async)?.values"
                 [paginator]="false"
                 [data]="(data$ | async)?.values"
                 [tableColumns]="columns$ | async"
                 [autoLayout]="true"
                 [scrollable]="false"
                 [loading]="loading$ | async"
                 [lazyLoadOnInit]="false"
                 selectionMode="single"
                 class="margin-15">
    <ng-template pTemplate="customHeader">
      <tr>
        <ng-container *ngFor="let col of additionalHeader">
          <th [attr.colspan]="col.colspan" [ngStyle]="col.style">
            {{ col.title }}
          </th>
        </ng-container>
      </tr>
      <tr>
        <ng-container *ngFor="let col of (columns$ | async)">
          <th *ngIf="col.visible" [ngStyle]="col.style">
            {{ col.title }}
          </th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row="row" let-column="column">
      <td class="td-active" *ngIf="column.type === 'state'" [ngStyle]="column.style" (click)="onClick(row, column)">
        <span>{{stateValuesMapping[row[column.name]] ? stateValuesMapping[row[column.name]] : row[column.name]}}</span>
      </td>
      <td class="td-active" *ngIf="column.type === 'esm'" [ngStyle]="column.style" (click)="onClick(row, column)">
        {{row.bucketValues[column.name]}}
      </td>
    </ng-template>
  </cms-datatable>
  <!-- dataTable table -->
  <cms-datatable *ngIf="(viewType$ | async) === tableViewType.TABLE_DATA && (data$ | async)?.values"
                 [currentPage]="(paging$ | async)?.page"
                 [tableColumns]="columns$ | async"
                 [sortField]="(sorting$ | async)?.columnName"
                 [sortOrder]="(sorting$ | async)?.sortOrder"
                 [loading]="loading$ | async"
                 [data]="(data$ | async)?.values"
                 [totalRecords]="(data$ | async)?.count"
                 [scrollable]="false"
                 [autoLayout]="true"
                 [lazyLoadOnInit]="false"
                 class="margin-15"
                 (lazyLoad)="onLazyLoad($event)"
                 [checkboxRow]="true"
                 [selected]="selected$ | async"
                 (selectedChange)="onSelected($event)">
    <ng-template pTemplate="caption">
      <div class="float-left date-label">{{dateRangeText$ | async}}</div>
      <cms-deal-table-caption (openAddNoteDialog)="onOpenAddNoteDialog()"
                              (exportEvent)="onExport()"
                              (clickCustomize)="onClickCustomize()"
                              [exportDisable]="!(data$ | async)?.values?.length"
                              [addNoteDisable]="!(selectedIds$ | async)?.length">
      </cms-deal-table-caption>
    </ng-template>
    <ng-template pTemplate="body" let-row="row" let-rowNum="rowIndex" let-column="column">
      <td *ngIf="column.type === 'list' && column.name === 'deliveryPointArea'" [ngStyle]="column.style">
        <cms-esm-netting-finder-table-aggregation-keys-dropdown [name]="column.name" [id]="row.id" [placeholder]="row.deliveryPointArea" [even]="rowNum % 2 == 0">

        </cms-esm-netting-finder-table-aggregation-keys-dropdown>
      </td>
      <td *ngIf="column.type === 'list' && column.name === 'physicalFinancial'" [ngStyle]="column.style">
        <cms-esm-netting-finder-table-aggregation-keys-dropdown [name]="column.name" [id]="row.id" [placeholder]="singleNttType||row.physicalFinancial" [even]="rowNum % 2 == 0">

        </cms-esm-netting-finder-table-aggregation-keys-dropdown>
      </td>
      <td *ngIf="column.type === 'list' && column.name === 'commodity'" [ngStyle]="column.style">
        <cms-esm-netting-finder-table-aggregation-keys-dropdown [name]="column.name" [id]="row.id" [placeholder]="singleCommodity||row.commodity" [even]="rowNum % 2 == 0">

        </cms-esm-netting-finder-table-aggregation-keys-dropdown>
      </td>
      <td *ngIf="column.type === 'list' && column.name === 'agreementDetails'" [ngStyle]="column.style">
        <cms-esm-netting-finder-table-aggregation-keys-dropdown [name]="column.name" [id]="row.id" [placeholder]="row.agreementDetails" [even]="rowNum % 2 == 0">

        </cms-esm-netting-finder-table-aggregation-keys-dropdown>
      </td>
      <td *ngIf="column.type === 'list' && column.name === 'currency'" [ngStyle]="column.style">
        <cms-esm-netting-finder-table-aggregation-keys-dropdown [name]="column.name" [id]="row.id" [placeholder]="row.currency" [even]="rowNum % 2 == 0">

        </cms-esm-netting-finder-table-aggregation-keys-dropdown>
      </td>
      <td *ngIf="column.type === 'list' && column.name === 'fixedFloating'" [ngStyle]="column.style">
        <cms-esm-netting-finder-table-aggregation-keys-dropdown [name]="column.name" [id]="row.id" [placeholder]="row.fixedFloating" [even]="rowNum % 2 == 0">

        </cms-esm-netting-finder-table-aggregation-keys-dropdown>
      </td>
      <td *ngIf="column.type === 'list' && column.name === 'natureOfPrice'" [ngStyle]="column.style">
        <cms-esm-netting-finder-table-aggregation-keys-dropdown [name]="column.name" [id]="row.id" [placeholder]="row.natureOfPrice" [even]="rowNum % 2 == 0">

        </cms-esm-netting-finder-table-aggregation-keys-dropdown>
      </td>
      <td *ngIf="column.type === 'list' && column.name === 'marketInformation'" [ngStyle]="column.style">
        <cms-esm-netting-finder-table-aggregation-keys-dropdown [name]="column.name" [id]="row.id" [placeholder]="row.marketInformation" [even]="rowNum % 2 == 0">

        </cms-esm-netting-finder-table-aggregation-keys-dropdown>
      </td>
      <td *ngIf="column.type === 'list' && column.name === 'totalVolumeUnit'" [ngStyle]="column.style">
        <cms-esm-netting-finder-table-aggregation-keys-dropdown [name]="column.name" [id]="row.id" [placeholder]="row.totalVolumeUnit" [even]="rowNum % 2 == 0">

        </cms-esm-netting-finder-table-aggregation-keys-dropdown>
      </td>
      <!-- document (xml) button -->
      <td *ngIf="column.type === 'button' && column.name === 'openDocumentXml'" [ngStyle]="column.style">
        <div class="btn-wrapper inline-block">
          <button (click)="onDownloadXml(row)" icon="fa fa-file-code-o" pButton title="Document (XML)" type="button"></button>
        </div>
      </td>
      <!-- netted (pdf) button -->
      <td *ngIf="column.type === 'button' && column.name === 'nettingPdf'" [ngStyle]="column.style">
        <div class="btn-wrapper inline-block">
          <button (click)="onDownloadPdf(row)" icon="fa fa-file-pdf-o" pButton title="Document (PDF)" type="button"></button>
        </div>
      </td>
      <!--audit trail button-->
      <td *ngIf="column.type === 'button' && column.name === 'auditTrail'" [ngStyle]="column.style">
        <div class="btn-wrapper inline-block">
          <a routerLink="/esm/audittrail/netting/" title="Audit Trail" [queryParams]="getDisplayParams(row)" target="_blank">
            <button pButton type="button" icon="fa fa-search" title="Audit Trail"></button>
          </a>
        </div>
      </td>
      <!--near match button-->
      <td *ngIf="column.type === 'button' && column.name === 'nearMatch'">
        <cms-esm-near-match-button class="comp-match-cell" [row]="row" route="/esm/match/netting"></cms-esm-near-match-button>
      </td>

      <!--mismatch button-->
      <td *ngIf="column.type === 'button' && column.name === 'misMatch'">
        <cms-esm-mis-match-button class="comp-match-cell" [row]="row" route="/esm/compare/netting"></cms-esm-mis-match-button>
      </td>
      <!--note button-->
      <td *ngIf="column.type === 'button' && column.name === 'notes'" [ngStyle]="column.style">
        <cms-notes-cell [confirmationId]="row.id"
                        [data]="row"
                        [notes]="row.notes"
                        (view)="onViewNotes($event)">

        </cms-notes-cell>
      </td>
    </ng-template>
    <ng-template pTemplate="customEmptyMessage">
      <tr>
        <td colspan="100" *ngIf="!(loading$ | async)">
          No records found
        </td>
      </tr>
    </ng-template>
  </cms-datatable>
</section>
<section>
  <cms-add-note-dialog *ngIf="(dialog$ | async)?.name === 'ADD_NOTES'"
                       [display]="(dialog$ | async)?.name === 'ADD_NOTES'"
                       [messages]="(dialog$ | async)?.messages"
                       [headerText]="'Add note to netting statements'"
                       [confirmationIds]="selectedIds$ | async"
                       (cancel)="onCancelNotesDialog()"
                       (save)="onConfirmAddNotes($event)">
  </cms-add-note-dialog>
  <cms-view-confirmation-notes-dialog *ngIf="(dialog$ | async)?.name === 'VIEW_NOTES'"
                                      [display]="(dialog$ | async)?.name === 'VIEW_NOTES'"
                                      [messages]="(dialog$ | async)?.messages"
                                      [data]="(dialog$ | async)?.data"
                                      [headerText]="'View netting statement notes'"
                                      (cancel)="onCancelNotesDialog()"
                                      (save)="onConfirmAddNotes($event)">
  </cms-view-confirmation-notes-dialog>
</section>
