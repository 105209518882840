<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>
  <section class="panel-box " style="overflow: auto">

    <h1>eSM Netting Preview</h1>

    <cms-info ngPreserveWhitespaces [fullWidth]="true">

      **Netting Preview**

      The Netting Preview screen displays the netted amounts of invoices in which your eSM party is involved and whose payment is still due. The table 'Netting Overview' shows these amounts grouped by invoice payment date, counterparty and netting agreement. By clicking an entry in the 'Netting Overview' table, a detailed representation of the selected netting preview entry is opened underneath the table.

      Please note: any invoices with a payment date in the past are not considered in the Netting Preview.

      **Netting Overview table**

      The Netting Overview table groups invoices in which your eSM organisation is either the invoice sender party or the invoice receiver party by

      * Invoice payment date (table column 'Due Date)
      * Counterparty (table column 'Counterparty')
      * Netting Agreement (table Column 'Netting Agreement')

      The *Netting Agreement* groups invoices by the following eSM aggregation keys:

      * PhysicalFinancial
      * Commodity
      * Currency
      * Agreement + Master Agreement Version

      The amounts of the grouped invoices are shown in the table columns

      * My Netting - this column shows the invoice amounts of all invoices grouped by the attributes described above where your party is the sender (invoices which were sent to eSM by you, this includes both official and shadow invoices)
      * CP Netting - this column shows the invoice amounts of all invoices grouped by the attributes described above where your party is the receiver (invoices which were sent to eSM by your counterparty, this includes both official and shadow invoices)

      Entries with identical values of 'My Netting' and 'CP Netting' - this means that the all the invoices submitted by your counterparty sum up to the same overall amounts as the invoices submitted by your eSM party - are displayed in green in the table.

      **Table user interaction**

      The contents of the table can be filtered by the user

      * Dropdown 'Counterparty' restricts the displayed table entries to the selected counterparties
      * Checkbox 'show nettings with discrepancies only' switches the table between displaying all entries (checkbox is unchecked) and displaying only entries where discrepancies between the netted invoice amounts between your invoices and your counterparty invoices exist (checkbox is checked).

      **Detailed Netting Preview**

      The Detailed Netting Preview is opened underneath the Netting Overview table by clicking a table entry. It shows the payer and receiver including address- and contact data (if available in eSM) for the selected netting preview entry from the perspective of your eSM party.


      **Table 'Netted Invoice Amounts'**

      This table shows the netted invoice amount of the selected netting preview entry broken down into the following categories (displayed in the respective table row)

      * To Receive - netted amounts of invoices in which the considered eSM party is the receiver of the money (invoices the considered eSM party is the official document issuer)
      * To Pay - netted amounts of invoices in which the considered eSM party is the payer of the money (invoices where the considered eSM party is the shadow document issuer)
      * Overall - the netted amounts of 'To Receive' and 'To Pay'

      **Table columns**

      * 'Our Netting Calculation' - this column sums up the amounts of all invoices in which your eSM party is the sender party in the respective category (To Receive, To Pay, Overall)
      * 'CP Netting Calculation' - this column sums up the amounts of all invoices in which your counterparty is the invoice sender party in the respective category.
      * 'Our Open To Date' - this column sums up the amounts of all 'Pending' invoices in which your eSM party is the sender party in the respective category
      * 'Our Agreed To Date' - this column sums up the amounts of all 'Matched' invoices in which your eSM party is the sender party in the respective category
      * 'Our Disputed To Date' - this column sums up the amounts of all 'Mismatched' invoices in which your eSM party is the sender party in the respective category


    </cms-info>

    <p-messages [value]="messages$ | async" [closable]="true" class="col-12"></p-messages>

    <div class="flex">
      <cms-counterparty-dropdown class="flex-grow-0"></cms-counterparty-dropdown>
      <cms-netting-table-overview class="align-self-end ms-5 flex-grow-1"></cms-netting-table-overview>
    </div>
    <cms-netting-overview class="block mt-4"></cms-netting-overview>

  </section>

</ng-template>
