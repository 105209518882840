import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OrganisationService } from '../../organisation.service';
import { UserService } from '../../user.service';
import { OrganisationModel, SelectableOrganisationResponse } from '../../organisation.model';
import { UserInformationModel } from '../../user-informations.model';
import { ApiRequestService } from '@common/api-request.service';

@Component({
  selector: 'cms-org-selector',
  templateUrl: './org-selector.component.html',
  styleUrls: ['./org-selector.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrgSelectorComponent implements OnInit, OnDestroy {
  organisations: SelectItem[];
  selectedOrganisation: number;
  deselectable: boolean; // fha does not need to select an organisation: extra empty entry in selector
  private onDestroy$ = new Subject();

  constructor(private organisationService: OrganisationService,
              private userService: UserService,
              private apiGatewayService: ApiRequestService) {

    this.deselectable = false;
  }

  ngOnInit() {
    this.selectedOrganisation = this.organisationService.getCachedOrganisationId();
    this.organisations = [];

    // initOrganisations after
    this.loadOrganisations();

    this.organisationService.getChangeOrganisationObservable()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(organisationModel => {
        let orgId = null;
        if (organisationModel) {
          orgId = organisationModel.organisationId;
          this.selectedOrganisation = orgId;
        } else if (this.deselectable) {
          this.organisationService.deselectCachedOrganisation();
        }
      });
  }

  ngOnDestroy(): void {
    // Clean up all subscriptions at once:
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  onChangeOrganisation(organisationId: number): void {
    this.organisationService.cacheCurrentOrganisationId(organisationId);
    this.organisationService.setCurrentOrganisationId(organisationId);
  }

  onHide(): void {
    this.onChangeOrganisation(this.selectedOrganisation);
  }

  initOrganisation(): void {
    if (this.deselectable) {
      this.organisations = [{label: '', value: null}, ...this.organisations];
    }

    if ((this.selectedOrganisation > 0) ||
      (this.deselectable && this.selectedOrganisation === null)) {
      this.onChangeOrganisation(this.selectedOrganisation);
    } else {
      this.selectDefaultOrganisation();
    }
  }

  selectDefaultOrganisation(): void {
    this.userService.getInformations()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((user: UserInformationModel) => {
        if (user && user.organisation) {
          this.onChangeOrganisation(parseInt(user.organisation as string, 10));
        } else {
          const organisationId = this.organisations.length ? this.organisations[0].value : null;
          this.onChangeOrganisation(organisationId);
        }
      });
  }

  private loadOrganisations(): void {
    this.organisationService.loadSelectableOrganisations()
      .then((response: SelectableOrganisationResponse) => {
        this.deselectable = response.selectionOptional;
        this.apiGatewayService.setFha(this.deselectable);
        this.organisations = this.mapSelectableOrganisations(response.organisations);
        if (this.organisations.length > 0) {
          this.initOrganisation();
        }
      });
  }

  private mapSelectableOrganisations(organisations: OrganisationModel[]): SelectItem[] {
    return organisations.map(o => ({ label: `${o.displayName} (${o.eicCode})`, value: o.organisationId }));
  }
}
