<p-dialog *ngIf="display"
          header="{{mode === 'ADD' ? 'Add New Organisation Group' : 'Edit Selected Organisation Group'}}"
          [visible]="display"
          [modal]="true"
          (visibleChange)="onVisibleChange($event)"
          [style]="{width: '700px'}">
  <p-messages [value]="messages" [closable]="true"></p-messages>
  <div class="margin-15">
    <form [formGroup]="form" class="row" autocomplete="off">
      <div class="col-12 margin-bottom-12 flex">
        <label class="col-4 float-left">Display Name: <span class="text-required">*</span></label>
        <div class="col-8">
          <input cmsUpperCase autocomplete="off" id="displayName" type="text" formControlName="displayName" class="form-control col-8 padding-6 ng-trim-ignore" [ngClass]="{'error': errors['displayName']}" maxlength="20" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label class="col-4 float-left">Legal Name: <span class="text-required">*</span></label>
        <div class="col-8">
          <input autocomplete="off" id="legalName" type="text" formControlName="legalName" class="form-control col-8 padding-6" [ngClass]="{'error': errors['legalName']}" maxlength="100" />
        </div>
      </div>
      <cms-info ngPreserveWhitespaces [selected]="false" class="col-12">
        Range of IP addresses from which users of the organisation group may access the CMS.

        You must specify **at least one entry**.

        Use **one line per entry.**

        Valid address formats are:
        * IPv4 address, e.g. 14.2.123.8
        * IPv4 range in CIDR format, e.g. 14.2.123.0/24
        * or IPv6 address, e.g. 2001:0db8:85a3:08d3:1319:8a2e:0370:7344
      </cms-info>
      <div class="col-12 margin-bottom-12 flex">
        <label class="col-4 float-left">IP Range (GUI Access): <span class="text-required">*</span></label>
        <div class="col-8">
          <textarea autocomplete="off" id="ipRange" formControlName="ipRange" class="form-control p-component col-8 padding-6" [ngClass]="{'error': errors['ipRange']}"></textarea>
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label class="col-4 float-left">4th Party Permission (eRR):</label>
        <div class="col-8">
          <input autocomplete="off" id="fourthPartyPermissions" type="checkbox" formControlName="fourthPartyPermissions" class="padding-6" [ngClass]="{'error': errors['fourthPartyPermissions']}" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12">
        <label class="col-4 float-left">Report Level:</label>
        <label class="margin-right-9">
          <input [ngClass]="{'error': errors['reportLevel']}" [value]="ReportLevel.GROUP" class="margin-right-3" formControlName="reportLevel" id="{{ReportLevel.GROUP}}" name="reportLevel" type="radio">{{ ReportLevel.GROUP }}
        </label>
        <label>
          <input (click)="onClickOrganisation()" [ngClass]="{'error': errors['reportLevel']}" [value]="ReportLevel.ORGANISATION" class="margin-right-3" formControlName="reportLevel" id="{{ReportLevel.ORGANISATION}}" name="reportLevel" type="radio">{{ ReportLevel.ORGANISATION }}
        </label>
      </div>
      <div class="col-12 margin-bottom-12">
        <label class="col-4 float-left">XML Reports:</label>
        <label class="margin-right-9">
          <input [attr.disabled]="form.value.reportLevel==ReportLevel.ORGANISATION ? true : null" [ngClass]="{'error': errors['activityReport']}" [value]="Reporting.ACTIVITY" class="margin-right-3" formControlName="activityReport" id="{{Reporting.ACTIVITY}}" name="activityReport" type="checkbox">{{ Reporting.ACTIVITY }}
        </label>
        <label>
          <input [attr.disabled]="form.value.reportLevel==ReportLevel.ORGANISATION ? true : null" [ngClass]="{'error': errors['reconciliationReport']}" [value]="Reporting.RECONCILIATION" class="margin-right-3" formControlName="reconciliationReport" id="{{Reporting.RECONCILIATION}}" name="reconciliationReport" type="checkbox">{{ Reporting.RECONCILIATION }}
        </label>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label class="col-4 float-left">Report Feed Partner ID:</label>
        <div class="col-8">
          <input [attr.disabled]="form.value.reportLevel==ReportLevel.ORGANISATION ? true : null" [ngClass]="{'error': errors['reportFeedPartnerId']}" autocomplete="off" class="form-control col-8 padding-6" formControlName="reportFeedPartnerId" id="reportFeedPartnerId" maxlength="30" type="text" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex" *ngIf="mode === 'EDIT'">
        <div *ngIf="!(data?.apiKey)" class="col-4 float-left">
          <button class="btn" type="button" (click)="onGenerate()">Generate API key</button>
        </div>
        <label *ngIf="data?.apiKey" class="col-4 float-left">API Key:</label>
        <div class="col-8">
          <input autocomplete="off" id="apiKey" type="text" formControlName="apiKey" class="form-control col-8 padding-6" [ngClass]="{'error': errors['apiKey']}" />
        </div>
      </div>
    </form>
  </div>
  <p-footer>
    <button class="btn margin-right-3" type="button" (click)="onCancel()">Cancel</button>
    <button class="btn" type="button" (click)="onConfirm()">Save</button>
  </p-footer>
</p-dialog>
