<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>

<cms-headline [displayOrganisationName]="true" [pageTitle]="'REMIT Settings'"></cms-headline>
<div class="panel-box">
  <p-messages [(value)]="messages" [closable]="false"></p-messages>
  <cms-info ngPreserveWhitespaces>
    <!--@formatter:off (markdown rendering breaks, when the following tags are formatted)-->
    <u>__REMIT OMP Settings__</u>


    __Purpose__
    * As market participant you can configure which OMPs are authorized to submit your REMIT trades and orders to ARIS via the CMS
    * For each authorized OMP you can define how their trade reports are processed (see information on processing modes below)

    __Updating Your Preferences - Master Operator (MO) privileges required__
    * Tick the checkboxes of the OMPs whose processing mode you wish to change
    * From the dropdown box choose the intended target mode
    * Click "Apply"

    __Info: Processing Modes__

    As market participant using the CMS eRR for REMIT reporting you have full control over which OMP data eRR forwards to ARIS:

    The processing modes you set per legal entity of your group are:
    * __Block__(default): no data from this OMP is reported to ARIS
    * __Pass Through__: all data from this OMP is reported to ARIS
    * __Pair (Strict)**__: all order data from this OMP is sent to ARIS; trade data from this OMP is held until you submit a corresponding* trade to eRR
    * __Pair (Release)**__: like strict pairing; but OMP trade data is only held until the T+1 reporting deadline and then reported even if you have not submitted a corresponding* trade to eRR

    \*) Note: "corresponding" means that your submission designates the same UTI, MP and OMP<br />
    \*\*) Note: only choose the pairing modes if you have an automated report interface (you cannot enter pairing records using the eRR lite trade entry form)

    __Info: List of REMIT OMPs__
    * Please contact the CMS service team should you find any errors or omissions in this list.
    * The column "eRR Party" shows whether an OMP sends REMIT data directly to the CMS (yes/no).
    * In case the OMP is not connected to eRR you can submit the OMP's trade and order data yourself. In this case you need to unblock this OMP and choose a processing mode, as well.
    <!--@formatter:on-->
  </cms-info>

  <div id="processing-mode-panel" class="col-12 panel-box margin-bottom-15 decent-max-width" *ngIf="processModePanelVisible$ | async">
    <form [formGroup]="processingModeForm" class="row col-12" (ngSubmit)="onSubmit()">
      <label class="col-auto margin-3 padding-6">Change mode of selected parties to:</label>
      <div class="col-auto" id="remitSettingsProcessingModeDropDown">
        <p-dropdown
          class="margin-3"
          [options]="processingModes"
          formControlName="selectedProcessingMode"
          [style]="{width: '120px'}">
        </p-dropdown>
      </div>
      <button type="submit" tabindex="5" class="btn col-auto margin-top-3 margin-bottom-3 margin-left-12">Apply</button>
    </form>
  </div>

  <div class="col-12 col-md-12 padding-0">
    <div style="max-width: 1300px; overflow-x: auto;">
      <p-table [value]="getErrRemitSettingsProcessingModeModels()" dataKey="remitOmpId" [sortOrder]="1"
               selectionMode="multiple"
               sortField="ompDisplayName"
               [(selection)]="selectedErrRemitSettingsProcessingModeModel"
               [autoLayout]="true"
               [scrollable]="false"
               scrollHeight="520px">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 30px">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th pSortableColumn="processingMode">
              Mode
              <p-sortIcon field="processingMode"></p-sortIcon>
            </th>
            <th pSortableColumn="ompDisplayName">
              OMP Name
              <p-sortIcon field="ompDisplayName"></p-sortIcon>
            </th>
            <th pSortableColumn="assignedToParty">
              eRR Party
              <p-sortIcon field="assignedToParty"></p-sortIcon>
            </th>
            <th pSortableColumn="leiCode">
              LEI
              <p-sortIcon field="leiCode"></p-sortIcon>
            </th>
            <th pSortableColumn="ompLegalName">
              Legal Name
              <p-sortIcon field="ompLegalName"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-setting>
          <tr>
            <td style="width: 30px">
              <p-tableCheckbox [value]="setting"></p-tableCheckbox>
            </td>
            <td>{{setting.processingMode}}</td>
            <td>{{setting.ompDisplayName}}</td>
            <td><span>{{setting.assignedToParty | yesNo}}</span></td>
            <td>{{setting.leiCode}}</td>
            <td>{{setting.ompLegalName}}</td>
          </tr>
        </ng-template>

      </p-table>
    </div>
  </div>
</div>

</ng-template>
