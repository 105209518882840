import { createSelector } from '@ngrx/store';
import { EsmEmailWhitelistState } from './whitelist.reducers';
import { getEsmSettingsState } from '../settings.selectors';
import { EsmSettingsState } from '../settings.reducers';


export const getEmailWhitelistState  = createSelector(getEsmSettingsState, (state: EsmSettingsState) => state.whitelist);

export const getMessages = createSelector(getEmailWhitelistState, (state: EsmEmailWhitelistState) => state.messages);
export const getLoading = createSelector(getEmailWhitelistState, (state: EsmEmailWhitelistState) => state.loading);
export const getPagingAndSorting = createSelector(getEmailWhitelistState, (state: EsmEmailWhitelistState) => state.pagingAndSorting);
export const getPaging = createSelector(getEmailWhitelistState, (state: EsmEmailWhitelistState) => state.pagingAndSorting.paging);
export const getSorting = createSelector(getEmailWhitelistState, (state: EsmEmailWhitelistState) => state.pagingAndSorting.sorting);
export const getSearchToken = createSelector(getEmailWhitelistState, (state: EsmEmailWhitelistState) => state.searchToken);

export const isUploadAllowed = createSelector(getEmailWhitelistState, (state: EsmEmailWhitelistState) => state.isUploadAllowed);
export const getMaxUploadSize = createSelector(getEmailWhitelistState, (state: EsmEmailWhitelistState) => state.maxUploadSize);
export const getPdfOffsiteEnabled = createSelector(getEmailWhitelistState, (state: EsmEmailWhitelistState) => state.pdfOffsiteEnabled);
export const getTableData = createSelector(getEmailWhitelistState, (state: EsmEmailWhitelistState) => state.data);
