import { Injectable } from '@angular/core';
import { ApiRequestService } from '@common/api-request.service';
import { BehaviorSubject, lastValueFrom, Observable, of } from 'rxjs';
import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import { StandingInstructionModel } from './standing-instructions.model';
import { HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Message } from 'primeng/api';
import { catchError, map } from 'rxjs/operators';
import { PresetValues, PresetValuesResponse } from './upload/standing-instructions-upload.model';

@Injectable()
export class StandingInstructionsService {

  private apiPath: string = '/api/err/sis';

  private messages = new BehaviorSubject<Message[]>([]);

  standingInstruction = new BehaviorSubject<StandingInstructionModel>(null);

  serverValidationErrors = new BehaviorSubject<any>(null);

  constructor(private apiRequestService: ApiRequestService) {

  }

  //------------------

  private uploading: boolean = false;
  private refresh = new BehaviorSubject<boolean>(false);

  loadPresetValues(): Observable<PresetValues> {
    return this.apiRequestService.get(this.apiPath + '/loadPresetValues').pipe(
      map((response: PresetValuesResponse) => {
        return {
          uploadAllowed: response.value.uploadAllowed,
          uploadPanelVisible: response.value.uploadPanelVisible,
        }
      }));
  }

  public sendFile(csvFile: File) {
    this.setMessages(null);

    if (this.isValidFile(csvFile) && !this.uploading) {
      this.uploading = true;
      const formData = new FormData();
      formData.append('file', csvFile);

      this.apiRequestService.post(this.apiPath + '/importCsv', formData).subscribe(response => {
        if (response.validationFailureMessages) {
          this.uploading = false;
          for (let i = 0; i < response.validationFailureMessages.length; i++) {
            this.setMessages([{ severity: 'error', detail: response.validationFailureMessages[i] }]);
          }
        } else {
          this.uploading = false;
          this.setMessages([{
            severity: 'success',
            summary: '',
            detail: response.numbersProcessed + ' Standing Instructions registered successfully.'
          }]);
          this.refresh.next(true);
        }
      }, error => {
        this.uploading = false;
        this.setMessages([{ severity: 'error', summary: '', detail: error.statusText }]);
      });
    }
  }

  onStandingInstructionLoaded(): Observable<StandingInstructionModel> {
    return this.standingInstruction.asObservable();
  }

  public onServerValidationErrors(): Observable<any> {
    return this.serverValidationErrors.asObservable();
  }

  public download(): Observable<{ filename: string, blob: Blob } | { error: { severity: string; detail: string } }> {

    return this.apiRequestService.getBlob(this.apiPath + '/download/csv', null, null).pipe(map((response: HttpResponse<Blob>) => {
      const filename = this.getFilenameFromHeader(response.headers);
      const blob: Blob = response.body;
      return { filename, blob };
    }), catchError(this.handleError));
  }

  public isValidFile(file: File): boolean {
    let valid: boolean = true;
    if (file) {
      if (file.name.length > 100) {
        valid = false;
        this.setMessages([{ severity: 'error', summary: '', detail: 'File name length over limit.' }]);
      }

      const sizeInMB = parseFloat(((file.size / 1000) / 1024).toFixed(2));

      if (sizeInMB > 1) {
        valid = false;
        this.setMessages([{ severity: 'error', summary: '', detail: 'File exceeds limit of 1 MB.' }]);
      }

      if (!file.name.endsWith('.csv')) {
        valid = false;
        this.setMessages([{ severity: 'error', summary: '', detail: 'Please choose a .csv file.' }]);
      }
    }

    return valid;
  }

  public onRefresh(): Observable<boolean> {
    return this.refresh.asObservable();
  }

  private getFilenameFromHeader(headers): string {
    const contentDisposition = headers.get('content-disposition');
    return contentDisposition.split(';')[1].trim().split('=')[1];
  }

  //------------------

  loadStandingInstructions(searchQuery: StandingInstructionsSearchQuery): Promise<any> {
    return this.apiRequestService.post(this.apiPath + '/fetchAll', searchQuery, null).toPromise();
  }

  loadStandingInstruction(errPartyId: number): void {
    let route = '/fetchByOrganisationId';
    let httpParams = null;

    if (errPartyId) {
      route = '/fetchByErrPartyIdent';
      httpParams = new HttpParams().append('errPartyId', errPartyId.toString());
    }
    this.setMessages([]);
    this.serverValidationErrors.next([]);
    this.apiRequestService.get(this.apiPath + route, httpParams).subscribe(response => {
      this.standingInstruction.next(<StandingInstructionModel>response);
    });
  }

  loadRoboDelegatorsStandingInstruction(errPartyId: number): void {
    const route = '/api/robo/delegators/fetchByErrPartyIdent';
    const httpParams = new HttpParams().append('errPartyId', errPartyId.toString());
    this.setMessages([]);
    this.serverValidationErrors.next([]);
    this.apiRequestService.get(route, httpParams).subscribe(response => {
      this.standingInstruction.next(<StandingInstructionModel>response);
    });
  }

  private handleError(error: HttpErrorResponse) {

    const response = { error: { severity: 'error', detail: error.statusText + ': ' + error.error.errorMessage } };
    return of(response);
  }

  public setMessages(messages: Message[]) {
    this.messages.next(messages);
  }

  public onMessageChange(): Observable<Message[]> {
    return this.messages.asObservable();
  }

  public reset(): void {
    this.serverValidationErrors.next(null);
    this.messages.next([]);
  }
}

export class StandingInstructionsSearchQuery {

  filter?: string;
  sorting: SortingFilter;
  paging: PagingFilter;

  public static isNotEmptySearchQuery(query: StandingInstructionsSearchQuery): boolean {
    return query != null && JSON.stringify(query) !== JSON.stringify(StandingInstructionsSearchQuery.createEmptySearchQuery());
  }

  public static createEmptySearchQuery(): StandingInstructionsSearchQuery {
    return {
      paging: {
        entriesPerPage: 25,
        page: 0
      },
      sorting: {
        columnName: 'displayName',
        sortOrder: 'ASCENDING',
      }
    };
  }

}
