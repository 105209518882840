<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>

<h1>SMT Dashboard</h1>
<div class="panel-box">
  <cms-info ngPreserveWhitespaces>
    <!--@formatter:off (markdown rendering breaks, when the following tags are formatted)-->
    __SMT Dashboard__

    This screen informs you about transactions reported to regulators on your behalf within the last 60 days.
    Click on a number, reporter or reporting state within the matrix below to display the corresponding trade records.
    Click on the (&#9654;) symbols to reveal the reporting party detail level.

    **What Records Are Included?**

    The following reporting regimes are covered:
    * **REMIT**: standard supply trades, executions of framework contracts and non-standard trades reportable to ACER
    * **ELCOM** ("Swiss REMIT"): REMIT transactions of Swiss companies co-reported to ElCom as per the "StromVV" regulation
    * **EMIR**: cleared and uncleared trades reported to an EMIR trade repository

    The reported records include lifecycle information, i.e. modifications and cancellation to trades and orders.

    **State / Age Matrix**

    Transactions are initially shown as PENDING (reported, awaiting feedback) and move to ACCEPTED or REJECTED when a response is received.
    The age columns organise the trade and order events by their original transaction date, not the reporting date.

    **Transaction Table**

    Key information about the transactions is available from the table.
    Use the **document icons** in each row to display all reported fields or download the electronic document as it was reported.
    Use the **Export** button to download the displayed results as an Excel sheet.
    <!--@formatter:on-->
  </cms-info>


  <div class="col-12 col-md-12 padding-0">
    <div style="max-width: 1300px; overflow-x: auto;">
      <p-treeTable [value]="data" expandedIcon="fa fa-fw fa-caret-down" collapsedIcon="fa fa-fw fa-caret-right" [loading]="loading">
        <ng-template pTemplate="header">
          <tr>
            <th rowspan="3">Regime</th>
            <th>State</th>
            <th style="width: 200px;">Reporter</th>
            <th>Today</th>
            <th>1-7 days ago</th>
            <th>8-30 days ago</th>
            <th>31-60 days ago</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-data="rowData">
          <tr [ngClass]="{'regime-line' : data['disabled'], 'leaf' : data['leaf'], 'data-line' :  data['data-line']}">
            <td>
              {{data['regime']}}
            </td>
            <td>
              <p-treeTableToggler [id]="'tree-toggler-' + data['_regime']" [rowNode]="rowNode" *ngIf="!data['disabled']"></p-treeTableToggler>
              <a routerLink="deals" [queryParams]="getQueryParams(data,'DAYS_AGO_0_60')">{{data['status']}}</a>
            </td>
            <td style="width: 200px;">
              <a routerLink="deals" [queryParams]="getQueryParams(data,'DAYS_AGO_0_60')">{{data['reporter']}}</a>
            </td>
            <td>
              <a routerLink="deals" [queryParams]="getQueryParams(data,'TODAY')">{{data['today']}}</a>
            </td>
            <td>
              <a routerLink="deals" [queryParams]="getQueryParams(data,'DAYS_AGO_1_7')">{{data['daysAgo1To7']}}</a>
            </td>
            <td><a routerLink="deals" [queryParams]="getQueryParams(data,'DAYS_AGO_8_30')">{{data['daysAgo8To30']}}</a></td>

            <td><a routerLink="deals" [queryParams]="getQueryParams(data,'DAYS_AGO_31_60')">{{data['daysAgo31To60']}}</a></td>
          </tr>
        </ng-template>
      </p-treeTable>
      <div class="p-datatable-emptymessage no-records-label" *ngIf="!hasBuckets()">No records found for the selected organisation.</div>
    </div>
  </div>

</div>

</ng-template>
