import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Note } from '@common/deals/deals.model';

@Component({
  selector: 'cms-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoteComponent {
  @Input() note: Note;
  @Input() showNoteInfo = false;
  get noteInfoEnabled(): boolean {
    return this.note && this.showNoteInfo && this.openNoteInfo &&
      !!(this.note.jobTitle || this.note.email || this.note.phone || this.note.mobile || this.note.contactOther);
  }


  openNoteInfo = false;
}
