export type RemitDashboardBucketViewByType = 'TRANSACTION_DATE' | 'SUBMISSION_DATE';
export type RemitDashboardBucketReportingRoleType = 'MARKET_PARTICIPANT' | 'SENDER';
export type RemitDocumentType =
  'REMIT_TABLE_1'
  | 'REMIT_TABLE_2'
  | 'ELECTRICITY_RIGHTS'
  | 'GAS_CAPACITY'
  | 'FUNDAMENTALS'
  | 'REMITStorage'
  | 'REMITLNG'
  | 'ESM_INVOICE'
  | 'ESM_NETTING'
  | 'ECM';

export interface RemitDashboardSearchFilter {
  viewBy: RemitDashboardBucketViewByType;
  reportingRoleType: RemitDashboardBucketReportingRoleType;
  documentType: RemitDocumentType;
}

export const actionTypesMap = new Map([
  [ 'REMIT_TABLE_1', 'Action Type' ],
  [ 'REMIT_TABLE_2',  'Action Type' ],
  [ 'ELECTRICITY_RIGHTS', 'Doc Status Code' ],
  [ 'GAS_CAPACITY', 'Action Status Code' ]
]);
