import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemitDatatableComponent } from './remit-datatable.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RemitTransactionsService } from './remit-transactions.service';
import { CmsCommonModule } from '@common/cms-common.module';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule,
        FormsModule,
        ButtonModule,
        ReactiveFormsModule,
        CmsCommonModule,
        TableModule,
        TooltipModule,
    ],
  declarations: [
    RemitDatatableComponent
  ],
  exports: [
    RemitDatatableComponent
  ],
  providers: [
    RemitTransactionsService
  ]
})
export class RemitTableModule {
}
