import { AfterContentChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ErrTerminationState } from '../../../state/remit/remit.reducer';
import * as fromTableSelectors from '../../../state/remit/remit.selectors';
import * as remitActions from '../../../state/remit/remit.actions';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'cms-list-dialog',
  templateUrl: './list-dialog.component.html',
  styleUrls: ['./list-dialog.component.scss']
})
export class ListDialogComponent implements AfterContentChecked {
  loading$: Observable<{ dropDown }> = this.store.pipe(select(fromTableSelectors.getLoading));
  list$: Observable<any>;

  // WORK
  constructor(public config: DynamicDialogConfig,
              private store: Store<ErrTerminationState>,
              public cdRef: ChangeDetectorRef) {

     const columnList = config.data as { id: number, column: string };

    this.list$ = this.store.pipe(
      select(fromTableSelectors.getList, { id: columnList.id, column: columnList.column }),
      filter(values => {
        if (values?.length >= 0) {
          return true;
        } else {
          if (this.config.data?.id) {
            this.store.dispatch(remitActions.loading({ load: { dropDown: true } }));
            this.store.dispatch(remitActions.loadModalList({ id: columnList.id, column: columnList.column }));
          }
          return false;
        }
      }),
      map(list => list.map(label => ({ label }))));
  }

  ngAfterContentChecked() : void {
    this.cdRef.detectChanges();
  }

}
