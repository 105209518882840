import {
  EsmNettingStatementFinderFormValues,
  NettingStatementFinderPropertyFilter,
  NettingStatementFinderReferencesFilter,
  NettingStatementTableData
} from '../../../netting/finder/esm-netting-finder.model';
import { createAction, union } from '@ngrx/store';

export const searchByFilterAction = createAction('[NETTING STATEMENT FINDER] Search By Filter', (payload: NettingStatementFinderPropertyFilter) => ({ payload }));
export const searchByReferencesAction = createAction('[NETTING STATEMENT FINDER] Search By References', (payload: NettingStatementFinderReferencesFilter) => ({ payload }));
export const onFilterResponseAction = createAction('[NETTING STATEMENT FINDER] on Filter Response',(payload: NettingStatementTableData) => ({payload}));
export const getFormValuesAction = createAction('[NETTING STATEMENT FINDER] get Form Values');
export const onFormValuesResponseAction = createAction('[NETTING STATEMENT FINDER] on Form Values Response', (payload: EsmNettingStatementFinderFormValues) => ({ payload }));
export const updateFiltersAction = createAction('[NETTING STATEMENT FINDER] Update Filters', (payload: NettingStatementFinderPropertyFilter) => ({ payload }));
export const setDefaultStateAction = createAction('[NETTING STATEMENT FINDER] Set default State');
export const setReferencesFilterIdsAction = createAction('[NETTING STATEMENT FINDER] Set References Ids', (payload: {notFoundIds: string[], idCount: number}) => ({ payload }));
export const resetPropertyFilterAction = createAction('[NETTING STATEMENT FINDER] Reset Property Filter');
export const resetReferenceFilterAction = createAction('[NETTING STATEMENT FINDER] Reset Reference Filter');

const actions = union({
  searchByFilterAction,
  searchByReferencesAction,
  onFilterResponseAction,
  getFormValuesAction,
  onFormValuesResponseAction,
  setDefaultStateAction,
  setReferencesFilterIdsAction,
  resetPropertyFilterAction,
  resetReferenceFilterAction
});

export type NettingFinderActions = typeof actions;
