<div class="panel-box">
<section class="billing-runs_header_container col-lg-6">
  <p-messages [value]="messages$ | async" [closable]="true"></p-messages>
  <cms-billing-params
    *ngIf="billingRunParams$ | async"
    [data]="billingRunParams$ | async"
    (handleClick)="onEditParams($event)">
  </cms-billing-params>
  <cms-start-billing-run
    [formData]="startBillingRunModel$ | async"
    [isDisabled]="!(billingRunPermission$ | async)"
    [isLoading]="isBillingRunLoading$ | async"
    [messages]="messages$ | async"
    (handleSubmit)="onStartBillingRun($event)">
  </cms-start-billing-run>
</section>
<div class="panel-box billing-runs_table_container">
  <cms-datatable *ngIf="data$ | async as data"
    [data]="(data$ | async)?.values"
    [tableColumns]="dataTableColumns"
    [totalRecords]="(data$ | async)?.count"
    [currentPage]="(paging$ | async)?.page"
    [loading]="loading$ | async"
    [autoLayout]="true"
    [scrollable]="false"
    [lazy]="!!(data$ | async)?.values?.length"
    (lazyLoad)="onLazyLoad($event)">
    <ng-template pTemplate="body" let-row="row" let-column="column" let-rowIndex="rowIndex">
      <td *ngIf="column.type === 'deleteButton'" [ngStyle]="column.style">
        <div class="btn-wrapper">
          <button
            *ngIf="row.deletable"
            id="billing-run-delete-button-{{rowIndex}}"
            class="btn btn-icon fa fa-trash-o"
            label="Delete"
            (click)="onDeleteItem(row)"
            [title]="column.title"></button>
        </div>
      </td>
      <td *ngIf="column.type === 'reportButton'" [ngStyle]="column.style">
        <div class="btn-wrapper">
          <a *ngIf="row.reportButtonVisible"
            target="_blank"
            routerLink="/admin/mailing_details"
            [queryParams]="{ billingRunId: row.billingRunId, billingMonth: row.billingMonth }">
            <button class="btn btn-icon fa fa-search"
              type="button"
              id="billing-run-report-button-{{rowIndex}}"
              (click)="onOpenReport(row)"
              [title]="column.title">
            </button>
          </a>
        </div>
      </td>
      <td *ngIf="column.type === 'downloadButton'" [ngStyle]="column.style">
        <div class="btn-wrapper">
          <a *ngIf="row.errors === 0"
            class="btn btn-icon fa fa-file"
            id="billing-run-download-link-{{rowIndex}}"
             (click)="onDownloadZip(row.billingRunId)"
             style="padding: 3px;"
            [title]="column.title"></a>
          <button *ngIf="row.errors !== 0"
            class="btn btn-icon fa fa-file"
            id="billing-run-download-button-{{rowIndex}}"
            [title]="column.title"
            [disabled]="!!row.errors"></button>
        </div>
      </td>
      <td *ngIf="column.type === 'mailButton'" [ngStyle]="column.style">
        <div class="btn-wrapper">
          <button
            class="btn btn-icon fa fa-envelope-o"
            id="billing-run-mail-button-{{rowIndex}}"
            *ngIf="row.mailingButtonVisible"
            [disabled]="!row.mailingButtonEnabled"
            (click)="onSendMail(row.billingRunId)"
            title="Send invoice mails">
          </button>
        </div>
      </td>
      <td *ngIf="column.type === 'error'"
        [ngStyle]="column.style"
        id="billing-run-error-detail-{{rowIndex}}"
        [style.cursor]="!!row[column.name]?'pointer':'default'"
        (click)="onShowErrorDetails(row)">
        <span class="font-italic" [ngClass]="{ 'text-danger': !!row[column.name] }">
          {{ row[column.name] }}
        </span>
      </td>
    </ng-template>
  </cms-datatable>
</div>
<ng-container *ngIf="dialog$ | async as dialog">
  <cms-delete-billing-runs-dialog
    *ngIf="dialog?.name === 'DELETE'"
    [display]="dialog?.name === 'DELETE'"
    [data]="dialog?.data"
    [errorMessages]="dialog?.messages"
    (confirm)="onConfirmDelete($event)"
    (cancel)="onDialogCancel($event)">
  </cms-delete-billing-runs-dialog>
</ng-container>
<ng-container *ngIf="dialog$ | async as dialog">
  <cms-error-details-dialog
    *ngIf="dialog?.name === 'STATE'"
    [display]="dialog?.name === 'STATE'"
    [data]="dialog?.data"
    (cancel)="onDialogCancel($event)">
  </cms-error-details-dialog>
</ng-container>
<ng-container *ngIf="dialog$ | async as dialog">
  <cms-edit-billing-params-dialog
    *ngIf="dialog?.name === 'EDIT'"
    [display]="dialog?.name === 'EDIT'"
    [data]="dialog?.data"
    [errorMessages]="dialog?.messages"
    (cancel)="onDialogCancel($event)"
    (confirm)="onConfirmEdit($event)">
  </cms-edit-billing-params-dialog>
</ng-container>
</div>
