import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../auth/authentication.service';

@Injectable()
export class ForcePasswordGuard  {

  constructor(private authService: AuthenticationService, private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (this.authService.forcePassword()) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }
}
