<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>

<h1>REMIT File Upload</h1>
<div class="panel-box margin-bottom-15">
  <cms-info ngPreserveWhitespaces>
    <!--@formatter:off (markdown rendering breaks, when the following tags are formatted)-->
    __REMIT File Upload__

    **Purpose**

    * This screen is a **monitoring and upload facility** for REMIT report files based on the **ACER reporting formats** ("ACER XML").
    * This screen is **file-centric** and not for investigating single transactions since most ACER report formats are batch files containing many report records.
    * This screen shows traffic between eRR and data submitting parties whereas the traffic between eRR and ACER is shown under **REMIT Files OUT**.
    * Investigate the state and details of single REMIT reporting records on the complementary screen **REMIT Transactions**.

    **File Monitoring**

    Procedure

    1. From the title bar choose your organisational unit whose file submissions you wish to list.
    2. Option 1: search by a specific filename or parts of it, not case-sensitive
    3. Option 2: search any file received in the selected time range - optionally modify the report type and time window of interest

    Guidance on Listed Information

    * use the document icon to download the report file
    * use the magnifier icon to open the file's audit trail with processing details
    * the counts in the last four columns signify the processing state of reporting records in the file (total / pending / accepted / rejected)

    **File Upload**

    Users with **Master Operator (MO) privileges** can upload files in ACER XML formats for which no automated system-to-system upload exists

    Procedure

    1. In the title bar choose your organisational unit which has been authorized to submit REMIT report files
    2. If you have master operator rights an upload panel appears.
    3. If you check the 'ElCom Only' checkbox, the uploaded documents are only sent to ElCom. The box is only enabled if you have the eRR role 'Trader'.
    4. Click "Browse...", choose your upload file (which must have an ".xml" file extension) and press "Upload".

    Supported file types for Organized Market Places (OMP)

    * REMITTable1 (MoP Annex V.I): OMP-executed standard trades and orders (for white-listed organisations)

    Supported file types for Market Participants (MP)

    * REMITTable1 (MoP Annex V.I): OMP-executed standard trades and orders (if not submitted by your OMP)
    * REMITTable1 (MoP Annex V.I): bilateral standard trades and executions
    * REMITTable2 (MoP Annex V.II): non-standard contracts
    * ElectricityRights (MoP Annex V.III): secondary electricity capacity trades ("REMIT Table 3")
    * GasCapacity (MoP Annex V.IV): secondary gas capacity allocation reports ("REMIT Table 4")
    * REMITLNG (MoP Annex VI.V): fundamental LNG data
    * REMITStorage (MoP Annex VI.VI): fundamental gas storage data
    <!--@formatter:on-->
  </cms-info>
  <div class="col-12 info-panel margin-bottom-12" *ngIf="hasGrants()"><span class="fa fa-info-circle padding-right-6"></span>{{acerUploadGrantText}}</div>
  <p-messages class="mb-1" [(value)]="messages" [closable]="false"></p-messages>
  <cms-remit-files-in-upload-panel></cms-remit-files-in-upload-panel>
  <cms-remit-files-in-search></cms-remit-files-in-search>
</div>
<cms-remit-files-in-datatable></cms-remit-files-in-datatable>

</ng-template>
