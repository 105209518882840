import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';
import { RemitTradeEntryTable2Service } from '../remit-trade-entry-table2.service';
import { RemitTradeEntryTable2VolumeOptionalityIntervalsDataModel } from '../remit-trade-entry-table2-data.model';
import { RemitTable2TradeVolumeOptionalityIntervalModel } from '../../../trade/table2/modify/remit-table2-trade-entry.model';

@Component({
  selector: 'cms-remit-trade-entry-table2-volume-optionality-intervals',
  templateUrl: './remit-trade-entry-table2-volume-optionality-intervals.component.html',
  styleUrls: ['./remit-trade-entry-table2-volume-optionality-intervals.component.scss']
})
export class RemitTradeEntryTable2VolumeOptionalityIntervalsComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  formGroup: UntypedFormGroup;

  @Input()
  onReset: Subject<boolean>;

  @Input()
  data: RemitTable2TradeVolumeOptionalityIntervalModel[];


  capacityType: SelectItem[];

  volumeOptionalityIntervalsData: RemitTradeEntryTable2VolumeOptionalityIntervalsDataModel;

  private onDestroy$ = new Subject();

  constructor(private formBuilder: UntypedFormBuilder, private tradeEntryService: RemitTradeEntryTable2Service) {
    this.onReset = new Subject<boolean>();
  }

  ngOnInit() {
    this.initForm();

    this.tradeEntryService.onTradeEntryDataChange().pipe(takeUntil(this.onDestroy$)).subscribe(formData => {
      if (formData && formData.volumeOptionalityIntervals) {
        this.volumeOptionalityIntervalsData = formData.volumeOptionalityIntervals;
      }

      this.initFormValues();
      this.fillFormWithData();
    });

    this.onReset.subscribe(reset => {
      if (reset) {
        this.resetForm();
      }
    });

    this.tradeEntryService.onValidationFieldErrors().pipe(takeUntil(this.onDestroy$))
      .subscribe(
        fieldName => {
          if (fieldName && fieldName.indexOf('volumeOptionalityIntervals') >= 0) {
            const items = this.formGroup.controls['volumeOptionalityIntervals'] as UntypedFormArray;

            const parse = fieldName.split('.');
            const index = parse[0].charAt(parse[0].length - 1);
            const formGroup = items.controls[parseInt(index, 10)] as UntypedFormGroup;

            if (parse[1]) {
              switch (parse[1]) {
                case 'capacityUnit':
                  formGroup.controls['capacityUnit'].setErrors({ error: true });
                  break;
                case 'capacityValue':
                  formGroup.controls['capacityValue'].setErrors({ error: true });
                  break;
                case 'startDate':
                  formGroup.controls['startDate'].setErrors({ error: true });
                  break;
                case 'endDate':
                  formGroup.controls['endDate'].setErrors({ error: true });
                  break;
              }
            }
          }
        }
      );
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.fillFormWithData();
  }

  getValidationErrorStyleClass(fieldName: string): string {
    if (this.formGroup.controls[fieldName].hasError('error')) {
      return 'error';
    }
  }

  isAddIntervalDisabled(): boolean {
    return this.intervals.length > 35 || this.formGroup.disabled;
  }

  isRemoveIntervalDisabled(): boolean {
    return this.intervals.length < 1 || this.formGroup.disabled;
  }

  addInterval() {
    const intervals = this.formGroup.controls['volumeOptionalityIntervals'] as UntypedFormArray;

    intervals.push(
      this.formBuilder.group({ startDate: [''], endDate: [''], capacityValue: [''], capacityUnit: [''] })
    );

  }

  get intervals(): AbstractControl[] {
    return (this.formGroup.controls['volumeOptionalityIntervals'] as UntypedFormArray).controls;
  }

  removeInterval() {
    const intervals = this.formGroup.controls['volumeOptionalityIntervals'] as UntypedFormArray;

    if (intervals.length >= 1) {
      intervals.removeAt(intervals.length - 1);
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
    this.onReset.unsubscribe();
  }

  convertKeyValueToSelectItem(keyValueData: any): SelectItem[] {
    if (keyValueData) {
      return keyValueData.map(method => ({
          label: method.value,
          value: method.key
        }));
    }
    return [];
  }

  getVolumeOptionalityIntervalsErrorClass(index: number, fieldName: string): string {

    const items = this.formGroup.get('volumeOptionalityIntervals') as UntypedFormArray;
    if (items && items.controls[index]) {

      const formGroup = items.controls[index] as UntypedFormGroup;

      if (formGroup.controls[fieldName].hasError('error')) {
        return ' error';
      }
    }

    return '';
  }

  private initForm() {
    this.formGroup.addControl('volumeOptionalityIntervals', this.formBuilder.array(
      []
    ));

  }

  private resetForm() {

    this.formGroup.removeControl('volumeOptionalityIntervals');
    this.initForm();
  }

  private initFormValues() {
    if (this.volumeOptionalityIntervalsData) {
      this.capacityType = this.convertKeyValueToSelectItem(this.volumeOptionalityIntervalsData.capacityType);
    }
  }

  private fillFormWithData() {
    if (this.formGroup && this.data) {

      const volumeOptionalityIntervals = this.formGroup.controls['volumeOptionalityIntervals'] as UntypedFormArray;

      if (volumeOptionalityIntervals) {

        let volumeOptionalityInterval: RemitTable2TradeVolumeOptionalityIntervalModel;
        for (let i = 0; i < this.data.length; i++) {

          if (!volumeOptionalityIntervals.at(i)) {
            this.addInterval();
          }

          volumeOptionalityInterval = {
            startDate: null,
            endDate: null,
            capacityValue: this.data[i].capacityValue,
            capacityUnit: this.data[i].capacityUnit
          };

          if (this.data[i].startDate) {
            volumeOptionalityInterval = {
              ...volumeOptionalityInterval,
              startDate: new Date(this.data[i].startDate)
            };
          }
          if (this.data[i].endDate) {
            volumeOptionalityInterval = {
              ...volumeOptionalityInterval,
              endDate: new Date(this.data[i].endDate)
            };
          }

          volumeOptionalityIntervals.at(i).patchValue(volumeOptionalityInterval);
        }
      }

    }
  }
}
