import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { toPayload } from '@common/cms-common.model';
import { ErrorResponse } from '@common/error-response.model';
import { ApiRequestService } from '@common/api-request.service';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { MyFilter } from '@common/my-filters/my-filters.model';
import { Message } from 'primeng/api';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { EsmInvoiceFinderState } from '../invoice-finder.reducers';
import {
  EsmInvoiceFinderMyFilter, EsmInvoiceFinderMyFilterResponse,
  EsmInvoiceMyFiltersResponse,
  mapToEsmInvoiceFinderMyFilterRequest, updateFilters
} from '../../../invoice/finder/invoice-finder.model';
import { EsmInvoiceFinderFiltersState } from '../filters/filters.reducers';
import * as fromTableActions from '../table/table.actions';
import * as fromMyFiltersActions from './my-filters.actions';
import * as fromFiltersSelectors from '../filters/filters.selectors';
import * as fromDialogActions from '../dialog/dialog.actions';
import * as fromFiltersActions from '../filters/filters.actions';


@Injectable()
export class EsmInvoiceFinderMyFiltersEffects {
  private baseUrl = '/api/esm/invoice/finder';

  constructor(private apiGateway: ApiRequestService,
              private actions$: Actions,
              private store$: Store<EsmInvoiceFinderState>,
              private router: Router,
              private mapper: MessagesMapperService) {
  }

  replaceMyFilter$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromMyFiltersActions.replaceMyFiltersAction),
      map(toPayload),
      withLatestFrom(this.store$.pipe(select(fromFiltersSelectors.getFilters))),
      concatMap(([myFilter, state]: [EsmInvoiceFinderMyFilter, EsmInvoiceFinderFiltersState]) =>
        this.apiGateway.put(`${this.baseUrl}/filter`, mapToEsmInvoiceFinderMyFilterRequest(state, myFilter))
          .pipe(
            switchMap((response: EsmInvoiceFinderMyFilter & ErrorResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response as ErrorResponse);
              if (messages && messages.length) {
                return [fromDialogActions.updateDialogAction(messages)];
              }
              return [
                fromMyFiltersActions.loadMyFiltersAction(),
                fromDialogActions.updateDialogAction([]),
                fromDialogActions.closeDialogAction()
              ];
            }),
            catchError(() => of(fromTableActions.setMessagesAction([this.mapper.createErrorMessage('Server error')])))
          )
      )
    ));

  setDefaultFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMyFiltersActions.setDefaultFilterAction),
      map(toPayload),
      concatMap(({ filterName }: MyFilter) =>
        this.apiGateway.post(`${this.baseUrl}/filter/default`, null,
          filterName !== 'All Deals' ? new HttpParams().set('filterName', filterName) : null)
          .pipe(
            switchMap((response: ErrorResponse | any) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [fromTableActions.setMessagesAction(messages)];
              }
              return [
                fromMyFiltersActions.loadMyFiltersAction(),
                fromTableActions.setMessagesAction([]),
                fromDialogActions.updateDialogAction(this.mapper.toSuccessMessages(`"${filterName}" is your new default filter.`)),
              ];
            })
          )
      )
    )
  );

  selectMyFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMyFiltersActions.selectMyFilterAction),
      map(toPayload),
      withLatestFrom(this.store$.pipe(select(fromFiltersSelectors.getFilters))),
      switchMap(([selectedMyFilter, state]: [EsmInvoiceFinderMyFilter, EsmInvoiceFinderFiltersState]) => {
        return [
          fromFiltersActions.updateFiltersAction(updateFilters(state, state.propFilters, selectedMyFilter)),
          // reload table
        ];
      })
    )
  );

  reloadMyFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMyFiltersActions.reloadMyFiltersAction),
      switchMap(() =>
        this.apiGateway.get(`${this.baseUrl}/filters`)
          .pipe(
            map((response: EsmInvoiceMyFiltersResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return fromTableActions.setMessagesAction(messages);
              }
              return fromMyFiltersActions.reloadMyFilterSuccessAction(this.mapMyFiltersResponse(response));
            }),
            catchError(() => of(fromTableActions.setMessagesAction([this.mapper.createErrorMessage('Server error')])))
          )
      )
    )
  );

  saveMyFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMyFiltersActions.saveMyFiltersAction),
      map(toPayload),
      withLatestFrom(this.store$.pipe(select(fromFiltersSelectors.getFilters))),
      concatMap(([myFilter, state]: [EsmInvoiceFinderMyFilter, EsmInvoiceFinderFiltersState]) =>
        this.apiGateway.post(`${this.baseUrl}/filter`, mapToEsmInvoiceFinderMyFilterRequest(state, myFilter))
          .pipe(
            switchMap((response: any & ErrorResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [fromDialogActions.updateDialogAction(messages)];
              }
              return [
                fromMyFiltersActions.reloadMyFiltersAction(),
                fromDialogActions.updateDialogAction([]),
                fromDialogActions.closeDialogAction()
              ];
            }),
            catchError(() => of(fromTableActions.setMessagesAction([this.mapper.createErrorMessage('Server error')])))
          )
      )
    )
  );

  deleteMyFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMyFiltersActions.deleteMyFilterAction),
      map(toPayload),
      concatMap(({ filterName }: { filterName }) =>
        this.apiGateway.delete(`${this.baseUrl}/filter`, new HttpParams().set('filterName', filterName))
          .pipe(
            switchMap((response: any & ErrorResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [fromDialogActions.updateDialogAction(messages)];
              }
              return [
                fromMyFiltersActions.deleteMyFilterSuccessAction({ filterName }),
                fromDialogActions.updateDialogAction(this.mapper.toSuccessMessages(`Filter "${filterName}" deleted.`)),
              ];
            }),
            catchError(() => of(fromTableActions.setMessagesAction([this.mapper.createErrorMessage('Server error')])))
          )
      )
    )
  );

  loadMyFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMyFiltersActions.loadMyFiltersAction),
      switchMap(() =>
        this.apiGateway.get(`${this.baseUrl}/filters`)
          .pipe(
            map((response: EsmInvoiceMyFiltersResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return fromTableActions.setMessagesAction(messages);
              }
              return fromMyFiltersActions.loadMyFiltersSuccessAction(this.mapMyFiltersResponse(response));
            }),
            catchError(() => of(fromTableActions.setMessagesAction([this.mapper.createErrorMessage('Server error')])))
          )
      )
    )
  );

  mapMyFiltersResponse = ({ defaultFilter, filterSelections }: EsmInvoiceMyFiltersResponse): {
    defaultFilter: EsmInvoiceFinderMyFilter,
    filterSelections: EsmInvoiceFinderMyFilter[]
  } =>
  ({
    defaultFilter: defaultFilter ? this.mapToMyFilter(defaultFilter) : null,
    filterSelections: Object.values(filterSelections).map(this.mapToMyFilter)
  })

  mapToMyFilter = ({ isDefaultFilter, ...restDefaultFilter }: EsmInvoiceFinderMyFilterResponse): EsmInvoiceFinderMyFilter => ({
    ...restDefaultFilter,
    counterpartyFilter: restDefaultFilter.counterpartyFilter ? restDefaultFilter.counterpartyFilter : null,
    defaultFilter: isDefaultFilter
  })
}
