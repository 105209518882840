import { Message } from 'primeng/api';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';

import {  TerminateEntry, UploadMetaData } from '../../remit/termination/err.termination.model';
import { createReducer, on } from '@ngrx/store';
import * as RemitActions from './remit.actions';
import { RemitTerminationActions } from './remit.actions';

export interface ErrTerminationState {
  data: DatatableData<TerminateEntry[]>;
  paging: PagingFilter;
  sorting: SortingFilter;
  messages: Message[];
  maxUploadFileSizeInBytes: number;
  allowedToUpload: boolean;
  organisationNames: string[];
  loading: { table: boolean, upload: boolean, dropDown:boolean };
  previewLists: TerminationPreViewList[];
  uploadMetaData: UploadMetaData;
}

export interface TerminationPreViewList {
  id: number;
  column: string;
  values: string[];
}

export const initialState: ErrTerminationState = {
  allowedToUpload: false,
  maxUploadFileSizeInBytes: null,
  data: null,
  messages: [],
  previewLists: [],
  organisationNames: [],
  paging: { page: 0, entriesPerPage: 25 },
  sorting: { columnName: 'uploadTimestamp', sortOrder: 'DESCENDING' },
  loading: { table: false, upload: false, dropDown: false },
  uploadMetaData: null
};

export const terminationReducer = createReducer<ErrTerminationState, RemitTerminationActions>(
  initialState,

  on(RemitActions.setMessages, (state, { messages }) => ({
      ...state,
      messages: messages })
  ),
  on(RemitActions.resetMessages, (state) => ({
    ...state,
    messages: [] })
  ),
  on(RemitActions.loadPresetsSuccess, (state, { presets }) => ({
      ...state,
      ...presets })
  ),
  on(RemitActions.querySuccess, (state, { sorting, paging, data }) => ({
      ...state,
      sorting,
      paging,
      data,
      previewLists: []
    })
  ),
  on(RemitActions.loadModalListSuccess, (state, {list }) => ({
      ...state,
      previewLists: [...state.previewLists, list]})
  ),
  on(RemitActions.loading, (state, { load }) => ({
      ...state,
      loading: { ...state.loading, ...load }})
  ),
  on(RemitActions.cleanState, () => ({
      ...initialState })
  ),
  on(RemitActions.uploadCsvSuccess, (state, { metaData }) => ({
      ...state,
      uploadMetaData: { ...metaData }})
  ),
  on(RemitActions.resetFilInput, (state) => ({
    ...state,
    file:null })
  ),
  on(RemitActions.cleanCsvMeta, (state) => ({
      ...state,
      uploadMetaData: null
    })
));

