import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { Ecm_Dashboard_Categories, EcmDashboardCategoriesMap, EcmDashboardTabs, TabToTitleMap } from './ecm-dashboard.model';
import { EcmDashboardState } from '../state/dashboard/dashboard.reducers';
import { SetCategoryAction } from '../state/dashboard/category/category.actions';
import { ErrorMessageService } from '@common/errors/error-message.service';

@Component({
  selector: 'cms-ecm-dashboard',
  templateUrl: './ecm-dashboard.component.html',
  styleUrls: ['./ecm-dashboard.component.scss']
})
export class EcmDashboardComponent implements OnInit {
  readonly tabs = EcmDashboardTabs;
  readonly maps: { [key: string]: Ecm_Dashboard_Categories } = EcmDashboardCategoriesMap;

  activeIndex: number;

  constructor(private route: ActivatedRoute,
              private store: Store<EcmDashboardState>,
              private title: Title,
              private router: Router,
              public readonly errorMessageService: ErrorMessageService) { }

  ngOnInit(): void {
    this.route.data.subscribe(({panel}: { panel: string }) => {
      this.activeIndex = this.tabs.indexOf(panel);
      this.title.setTitle(TabToTitleMap[panel]);
      this.store.dispatch(new SetCategoryAction(this.maps[panel]));
    });
  }

  onChangeTab({index}: { index: number }): void {
    const tab = this.tabs[index];
    this.router.navigate([`ecm/dashboard/${tab}`]);
    this.title.setTitle(TabToTitleMap[tab]);
  }
}
