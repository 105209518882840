<p-dialog *ngIf="display"
          header="Reject documents"
          [resizable]="false"
          [(visible)]="display"
          [modal]="true"
          [style]="{width: '650px'}"
          (visibleChange)="onVisibleChange($event)"
          (onHide)="onCancelDialog()">
  <p-messages [closable]="true" [value]="messages"></p-messages>
  <form [formGroup]="form">
    <p-dropdown (onChange)="onChange($event.value.value)"
                [options]="reasonsOptions"
                optionLabel="key"
                class="dialog-dropdown"
                formControlName="reason">
    </p-dropdown>
    <label class="label">
      <p class="margin-top-9 margin-bottom-9">Enter mandatory reason for rejection: <span class="asterisk"></span></p>
      <textarea maxlength="400" id="textarea" class="textarea" formControlName="value"></textarea>
    </label>
  </form>
  <p-footer>
    <button pButton class="btn margin-right-3" type="button" label="Cancel" (click)="onCancelDialog()"></button>
    <button pButton class="btn" type="button" label="Yes - Reject" (click)="onRejectDialog()"></button>
  </p-footer>
</p-dialog>
