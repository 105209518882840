import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { mergeMap } from 'rxjs/operators';
import { NavigationService } from '@common/menu/navigation.service';
import { MenuItem } from '@common/menu/menu.model';
import { SetTabsVisibility } from './layout.actions';

@Injectable()
export class LayoutEffects {
  constructor(private navigation: NavigationService) {
  }


   navigationChange$ = createEffect(() => this.navigation.navigation$.pipe(
    mergeMap((navigation: MenuItem[]) => {
      if (navigation) {
        const preferences: MenuItem = navigation.find(item => item.path.includes('preferences'));
        if (preferences) {
          const mailAlerts: MenuItem = preferences.children.find(item => item.path.includes('mailAlerts'));
          if(mailAlerts){
            return [
              new SetTabsVisibility({
                global: !!(mailAlerts && mailAlerts.children.some(item => item.path.toLowerCase().includes('global'))),
                eCM: !!(mailAlerts.children.some(item => item.path.toLowerCase().includes('ecm')))
              })];
          }
        }
      return [new SetTabsVisibility(null)];
    }
    return [new SetTabsVisibility(null)];
  })));
}
