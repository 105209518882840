import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { Message } from 'primeng/api';
import { Column } from '@common/shared/datatable.model';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { DatatableState } from '@common/datatable/datatable.model';
import { allColumnsConfig, DateRange, EsmFile, EsmFileUploadFilters } from './file-upload.model';
import { EsmFileUploadState } from '../state/file-upload/file-upload.reducer';
import * as fromSelectors from '../state/file-upload/file-upload.selectors';
import * as fromAction from '../state/file-upload/file-upload.actions';
import { OrganisationService } from '@common/organisation.service';
import { filter, withLatestFrom } from 'rxjs/operators';
import { ErrorMessageService } from '@common/errors/error-message.service';

@Component({
  selector: 'cms-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, OnDestroy {
  uploadFileTitle: string = 'Upload Invoice CSV:';
  uploadFileType: string = '.csv';
  messages$: Observable<Message[]> = this.store.pipe(select(fromSelectors.getMessages));
  maxFileSize$: Observable<number> = this.store.pipe(select(fromSelectors.getMaxUploadSize));
  allowedToUpload$: Observable<boolean> = this.store.pipe(select(fromSelectors.getAllowedToUpload));
  busy$: Observable<boolean> = this.store.pipe(select(fromSelectors.getBusy));
  loading$: Observable<boolean> = this.store.pipe(select(fromSelectors.getLoading));
  filters$: Observable<EsmFileUploadFilters> = this.store.pipe(select(fromSelectors.getFilters));
  paging$: Observable<PagingFilter> = this.store.pipe(select(fromSelectors.getPaging));
  sorting$: Observable<SortingFilter> = this.store.pipe(select(fromSelectors.getSorting));
  data$: Observable<DatatableData<EsmFile[]>> = this.store.pipe(select(fromSelectors.getData));
  subscription$: Subscription = new Subscription();
  readonly columns: Column[] = allColumnsConfig;

  constructor(private store: Store<EsmFileUploadState>,
              private organisationService: OrganisationService,
              public errorMessageService:ErrorMessageService) { }

  ngOnInit(): void {

    const fUpload$ = this.organisationService.onRefresh()
      .pipe(
        withLatestFrom(this.organisationService.getChangeNotNullOrganisationObservable()),
        filter(([auth, org]) => auth && !!org && this.organisationService.getCurrentOrganisationId()!==null))
      .subscribe(() => this.store.dispatch(fromAction.loadPresetValuesAction()));

    this.subscription$.add(fUpload$);
  }

  ngOnDestroy(): void {
    this.store.dispatch(fromAction.setToDefaultAction());
    this.subscription$.unsubscribe();
  }

  onUpload(file: File): void {
    this.store.dispatch(fromAction.setBusy(true));
    this.store.dispatch(fromAction.uploadFileAction(file));
  }

  onUploadError(messages: Message[]): void {
    this.store.dispatch(fromAction.setMessagesAction(messages));
  }

  onReset(): void {
    this.store.dispatch(fromAction.clearFilters());
  }

  onSearchByFilename(fileName: string): void {
    this.store.dispatch(fromAction.filterByName(fileName));
  }

  onSearchByDate(request: DateRange): void {
    this.store.dispatch(fromAction.filterByDateAction(request));
  }

  onLazyLoad({page, perPage, sortOrder, sortField}: DatatableState): void {
    this.store.dispatch(fromAction.sortAndPageDataAction({
      paging: {
        page,
        entriesPerPage: perPage
      },
      sorting: {
        columnName: sortField,
        sortOrder
      }
    }));
  }

  onDownloadDocument(datastoreId: number): void {
    this.store.dispatch(fromAction.downloadDocumentAction(datastoreId));
  }

  getDisplayParams(row: EsmFile): any {
    return {
      displayValue: `${row.filename} received at ${row.submissionDate}`,
      urlPart: 'esm/invoice/fileUpload',
      id: row.id,
    };
  }

  onExport(): void {
    this.store.dispatch(fromAction.exportDocumentAction());
  }
}
