import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EcmDealFinder } from '../../../ecm/deal-finder/deal-finder.model';

@Component({
  selector: 'cms-ecm-near-match-cell',
  templateUrl: './ecm-near-match-cell.component.html',
  styleUrls: ['./ecm-near-match-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EcmNearMatchCellComponent {
  @Input() data: EcmDealFinder;
  @Input() isBroker = false;
}
