import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Message } from 'primeng/api';
import * as moment from 'moment';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { AllDealsMyFilterName, MY_FILTERS_DIALOGS, MyFilter } from '@common/my-filters/my-filters.model';
import { DialogState } from '@common/state/reducers';
import { Sender, updateDealsMultiSelect, updateDealsMultiSelectLabelValue } from '@common/deals/deals.model';
import {
  EsmInvoiceFinderMyFilter,
  getSaveReplaceMyFilter,
  InvoiceFinderPropertiesFilters,
  KeyValueProp,
  shouldDisableSaveCurrent
} from '../invoice-finder.model';

@Component({
  selector: 'cms-invoice-finder-properties-filter',
  templateUrl: './invoice-finder-properties-filter.component.html',
  styleUrls: ['./invoice-finder-properties-filter.component.scss'],
})
export class InvoiceFinderPropertiesFilterComponent implements OnInit, OnChanges {
  @Input() myFilters: EsmInvoiceFinderMyFilter[];
  @Input() defaultFilter: EsmInvoiceFinderMyFilter;
  @Input() allDealsFilter: EsmInvoiceFinderMyFilter = {
    counterpartyFilter: [],
    defaultFilter: true,
    hiddenType: Sender.US,
    invoiceTypeFilter: [],
    deliveryPointFilter: [],
    commodityFilter: [],
    filterName: AllDealsMyFilterName
  };
  @Input() dialog: DialogState<MY_FILTERS_DIALOGS>;
  @Input() messages: Message[] = [];
  @Input() disabled: boolean = false;
  @Input() filters: InvoiceFinderPropertiesFilters;
  @Input() commodities: string[] = [];
  @Input() counterParties: KeyValueProp[] = [];
  @Input() invoiceTypes: string[] = [];
  @Input() deliveryPoints: { keyObject: string; valueObject: string }[] = [];
  @Output() filter = new EventEmitter<InvoiceFinderPropertiesFilters>();
  @Output() reset = new EventEmitter();
  @Output() confirmSaveCurrent = new EventEmitter<MyFilter>();
  @Output() openSaveCurrent = new EventEmitter();
  @Output() manageFilters = new EventEmitter();
  @Output() selectMyFilter = new EventEmitter<MyFilter>();
  @Output() replaceFilter = new EventEmitter<MyFilter>();
  @Output() deleteFilter = new EventEmitter<string>();
  @Output() setDefaultFilter = new EventEmitter<MyFilter>();
  @Output() closeDialog = new EventEmitter();
  defaultFilters: InvoiceFinderPropertiesFilters = {
    senderUs: true,
    invoiceTypes: [],
    deliveryPoints: [],
    commodities: [],
    counterPartyOrgIds: [],
    invoiceEndDate: null,
    invoiceStartDate: null,
    paymentDateRange: null,
    submissionDateRange: null,
    webUser: false,
    multiAgreeButtonVisible: false
  };

  disableSaveCurrent = true;
  form: UntypedFormGroup;
  counterPartyOptions: { label: string; value: string }[] = [];
  deliveryPointsOptions: { label: string; value: { keyObject: string; valueObject: string } }[] = [];
  commodityOptions: { label: string; value: string }[] = [];
  invoiceTypesOptions: { label: string; value: string }[] = [];

  errors: { [key: string]: ValidationErrors } = {};
  updateMultiSelect;
  updateMultiSelectKeyValue;
  readonly defaultMaxDate = moment().toDate();

  constructor(private formBuilder: UntypedFormBuilder) {
    this.updateMultiSelect = updateDealsMultiSelect.bind(this);
    this.updateMultiSelectKeyValue = updateDealsMultiSelectLabelValue.bind(this);
  }

  ngOnInit(): void {
    const {
      commodities,
      counterPartyOrgIds,
      deliveryPoints,
      invoiceTypes,
      invoiceStartDate,
      invoiceEndDate,
      senderUs,
      webUser,
      multiAgreeButtonVisible,
      paymentDateRange,
      submissionDateRange
    } = this.filters || this.defaultFilters;

    this.form = this.formBuilder.group({
      commodities: { value: commodities, disabled: !this.commodities || !this.commodities.length },
      counterPartyOrgIds: { value: counterPartyOrgIds, disabled: !this.counterParties || !this.counterParties.length },
      deliveryPoints: { value: deliveryPoints, disabled: !this.deliveryPoints || !this.deliveryPoints.length },
      invoiceTypes: { value: invoiceTypes, disabled: !this.invoiceTypes || !this.invoiceTypes.length },
      submissionDateStart: submissionDateRange && submissionDateRange.start,
      submissionDateEnd: submissionDateRange && submissionDateRange.end,
      paymentDateStart: paymentDateRange && paymentDateRange.start,
      paymentDateEnd: paymentDateRange && paymentDateRange.end,
      invoiceStartDate,
      invoiceEndDate,
      senderUs: webUser ? false : senderUs,
      webUser,
      multiAgreeButtonVisible
    });

    this.updateMultiSelect(
      'commodities',
      'commodityOptions',
      this.commodities,
      Validators.required);

    this.updateMultiSelectKeyValue(
      'deliveryPoints',
      'deliveryPointsOptions',
      this.deliveryPoints,
      Validators.required);

    this.updateMultiSelect(
      'invoiceTypes',
      'invoiceTypesOptions',
      this.invoiceTypes,
      Validators.required);

    this.updateCounterPartiesMultiselect();

    this.disableSaveCurrent = shouldDisableSaveCurrent(this.form.getRawValue(), this.allDealsFilter);
    this.form.valueChanges.subscribe(() => {
      this.disableSaveCurrent = shouldDisableSaveCurrent(this.form.getRawValue(), this.allDealsFilter);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.form && changes.filters) {
      const {
        commodities,
        counterPartyOrgIds,
        deliveryPoints,
        invoiceTypes,
        invoiceStartDate,
        invoiceEndDate,
        senderUs,
        webUser,
        multiAgreeButtonVisible,
        paymentDateRange,
        submissionDateRange
      } = this.filters;

      this.form.patchValue({
        commodities,
        counterPartyOrgIds,
        deliveryPoints,
        invoiceTypes,
        invoiceStartDate,
        invoiceEndDate,
        senderUs: webUser ? false : senderUs,
        webUser,
        multiAgreeButtonVisible,
        submissionDateStart: submissionDateRange ? submissionDateRange.start : null,
        submissionDateEnd: submissionDateRange ? submissionDateRange.end : null,
        paymentDateStart: paymentDateRange ? paymentDateRange.start : null,
        paymentDateEnd: paymentDateRange ? paymentDateRange.end : null,
      });
      this.form.updateValueAndValidity();
    }

    if (changes.commodities) {
      this.updateMultiSelect(
        'commodities',
        'commodityOptions',
        this.commodities,
        Validators.required);
    }

    if (changes.deliveryPoints) {
      this.updateMultiSelectKeyValue(
        'deliveryPoints',
        'deliveryPointsOptions',
        this.deliveryPoints,
        Validators.required);
    }

    if (changes.invoiceTypes) {
      this.updateMultiSelect(
        'invoiceTypes',
        'invoiceTypesOptions',
        this.invoiceTypes,
        Validators.required);
    }

    if (changes.counterParties) {
      this.updateCounterPartiesMultiselect();
    }

    if (changes.messages) {
      this.errors = {};
      if (this.messages) {
        this.messages.forEach((obj: Message) => {
          const { key: controlName } = obj;
          this.errors[controlName] = { server: true };
        });
      }
    }

    if (this.form) {
      this.disableSaveCurrent = shouldDisableSaveCurrent(this.form.getRawValue(), this.allDealsFilter);
    }
  }

  onFilter(): void {
    const {
      commodities,
      counterPartyOrgIds,
      deliveryPoints,
      invoiceTypes,
      invoiceStartDate,
      invoiceEndDate,
      senderUs,
      webUser,
      multiAgreeButtonVisible,
      submissionDateStart,
      submissionDateEnd,
      paymentDateStart,
      paymentDateEnd,
    } = this.form.getRawValue();

    this.filter.emit({
      commodities,
      counterPartyOrgIds,
      deliveryPoints,
      invoiceTypes,
      invoiceStartDate,
      invoiceEndDate,
      senderUs,
      webUser,
      multiAgreeButtonVisible,
      paymentDateRange: {
        start: paymentDateStart,
        end: paymentDateEnd
      },
      submissionDateRange: {
        start: submissionDateStart,
        end: submissionDateEnd
      }
    });
  }

  onReset(): void {
    this.reset.emit();
  }

  // unused ???
  onSelectMyFilter(filter: MyFilter): void {
    this.selectMyFilter.emit(filter as EsmInvoiceFinderMyFilter);
  }

  onReplaceFilter(filter: MyFilter): void {
    this.replaceFilter.emit(getSaveReplaceMyFilter(this.form.getRawValue(), filter));
  }

  onSaveFilter(filter: MyFilter): void {
    this.confirmSaveCurrent.emit(getSaveReplaceMyFilter(this.form.getRawValue(), filter));
  }

  private updateCounterPartiesMultiselect(): void {
    const isValues = this.counterParties && this.counterParties.length;
    this.counterPartyOptions = isValues ?
      this.counterParties.map((item: KeyValueProp) => ({ label: item.value, value: item.key })) : [];
    if (!this.form) {
      return;
    }
    const counterPartyOrgIdsControl = this.form.controls['counterPartyOrgIds'];
    if (isValues) {
      counterPartyOrgIdsControl.enable();
      counterPartyOrgIdsControl.setValidators(Validators.required);
    } else {
      counterPartyOrgIdsControl.disable();
      counterPartyOrgIdsControl.clearValidators();
    }
    this.form.updateValueAndValidity();
  }

}
