import { PagingFilter, SortingFilter } from '../../../common/shared/results.model';
import { ErrorResponse } from '@common/error-response.model';
import { OrganisationGroup } from '../administration.model';

export enum ErrProcessRoles {
  Trader = 'ERR_TRADER',
  OMP = 'ERR_BROKER'
}

export interface ChangeToExternalPartyAllowedResponse extends ErrorResponse {
  value: boolean;
}

export interface ChangeOrganisationResponse extends ErrorResponse {
  feedbackMessage: string;
  organisation?: Organisation;
}

export interface OrganisationsFilters {
  organisationGroupId?: string;
  filterValue?: string;
}

export interface OrganisationsRequest {
  organisationGroupId?: string;
  filterValue?: string;
  paging?: PagingFilter;
  sorting?: SortingFilter;
}

export interface EcmPresetValuesRequest {
  selectedOrganisationId: number;
}

export interface EsmPresetValuesRequest {
  selectedOrganisationId: number;
}

export interface EsmPresetValuesResponse extends ErrorResponse {
  esmEnabled: boolean;
  partnerId: string;
  interoperationParty: boolean;
  webUser: boolean;
}

export interface EcmPresetValuesResponse extends ErrorResponse {
  partnerId: string;
  processRoles: PresetValuesOptions;
}

export interface ErrPresetValuesResponse extends ErrorResponse {
  acerCode: string;
  acerCopyPartnerId: string;
  elcomReceipt: boolean;
  emirRepositoryTypes: PresetValuesOptions;
  errorMessage: string;
  externalRrmScreenEnabled: boolean;
  leiCode: string;
  mifidMPPosRepXPSubscription: boolean;
  mifidPositionReporting: boolean;
  mifidXmlEbftpPartnerId: string;
  ompConnectionMethods: PresetValuesOptions;
  ompPartnerId: string;
  partnerId: string;
  processRoles: PresetValuesOptions;
  smallMediumTrader: boolean;
  timeZone: boolean;
}

export interface PresetValuesOptions {
  options: {label: string, value: string}[];
  selectedOption: string;
}

export interface AdminOrganisationFormResponse extends ErrorResponse {
  addAllowed: boolean;
  editAllowed: boolean;
  editEcmAllowed: boolean;
  editErrAllowed: boolean;
  editEsmpAllowed: boolean;
  deleteAllowed: boolean;
  moveAllowed: boolean;
  onlyContactsEditable: boolean;
  orgGroupSelectorVisible: boolean;
  orgGroupIdColumnVisible: boolean;
  organisationGroups: OrganisationGroup[];
  toggleStatusAllowed: boolean;
}

export interface Organisation {
  id?: number;
  active: boolean;
  businessContact: string;
  displayName: string;
  ecmLightAllowed: boolean;
  ecmProcessRole: string;
  eicCode: string;
  elcomCode: string;
  errProcessRole: string;
  legalName: string;
  orgGroupId: number;
  orgId: number;
  organisationGroupDisplayName: string;
  smt: boolean;
  technicalContact: string;
  esmRole: string;
}

export interface OrganisationsResponse extends ErrorResponse {
  currentPage?: number;
  organisations?: Organisation[];
  recordCount?: number;
}

export interface EditOrganisation {
  businessContact?: string;
  displayName: string;
  eicCode: string;
  legalName: string;
  organisationId: number;
  technicalContact?: string;
}

export interface AddOrganisation {
  businessContact?: string;
  displayName: string;
  eicCode: string;
  legalName: string;
  organisationGroupId: number;
  technicalContact?: string;
}

export interface DeleteOrganisation {
  changeToExternalParty: boolean;
  organisationIdForDeleting: number;
  reason: string;
}

export interface MoveOrganisation {
  currentOrganisationGroup: number;
  newOrganisationGroup: number;
  organisationId: number;
  reason: string;
}

export interface EditEcm {
  partnerId: string;
  processRole: string;
  selectedOrganisationId: number;
}

export interface EditEsm {
  esmEnabled: boolean;
  partnerId: string;
  selectedOrganisationId: number;
  interoperationParty: boolean;
  webUser: boolean;
}

export interface EditErr {
  acerCode: string;
  acerCopyPartnerId: string;
  defaultEmirRepository: string;
  elcomReceipt?: boolean;
  externalRrmScreenEnabled?: boolean;
  leiCode: string;
  mifidMPPosRepXPSubscription?: boolean;
  mifidPositionReporting?: boolean;
  mifidXmlEbftpPartnerId?: string;
  ompConnectionMethod?: string;
  ompPartnerId: string;
  partnerId: string;
  processRole: string;
  selectedOrganisationId: number;
  smallMediumTrader?: boolean;
  timeZone: string;
}
