import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Message } from 'primeng/api';

@Component({
  selector: 'cms-products-dialog',
  templateUrl: './products-dialog.component.html',
  styleUrls: ['./products-dialog.component.scss']
})
export class ProductsDialogComponent {
  @Input() displayDialog: boolean;
  @Input() transactionType: string;
  @Input() commodity: string;
  @Input() market: string;
  @Input() messages: Message[];
  @Input() value: boolean;
  @Output() cancel = new EventEmitter();
  @Output() activateItem = new EventEmitter();
  @Output() blockItem = new EventEmitter();

  onCancel(): void {
    this.cancel.emit();
  }

  onActivateItem(): void {
    this.activateItem.emit();
  }

  onBlockItem(): void {
    this.blockItem.emit();
  }
}
