import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { Message } from 'primeng/api';
import { TypedFormGroup } from '../../../../../common/typed-form-group.model';
import { UntypedFormControl, ValidationErrors } from '@angular/forms';
import { MessagesMapperService } from '../../../../../common/messages-mapper.service';
import { EditableCell } from '../../../../../common/datatable/cell-editor/cell-editor.model';
import { Organisation } from '../../organisations.model';
import { OrganisationActivation } from './activation.model';

@Component({
  selector: 'cms-activation-dialog',
  templateUrl: './activation-dialog.component.html',
  styleUrls: ['./activation-dialog.component.scss']
})
export class ActivationDialogComponent implements OnInit, OnChanges {
  @Input() data: EditableCell<Organisation, boolean>;
  @Input() display = false;
  @Input() messages: Message[] = [];
  @Output() displayChange = new EventEmitter<boolean>();
  @Output() cancel = new EventEmitter<Message[]>();
  @Output() changeActivation = new EventEmitter<OrganisationActivation>();
  form: TypedFormGroup<{comment: string}>;
  errors: {[key: string]: ValidationErrors} = {};

  constructor(private mapper: MessagesMapperService) { }

  get header(): string {
    return this.data.data.active === true ? 'Deactivate Organisation' : 'Reactivate Organisation';
  }

  onCancel(): void {
    this.hide();
    const { displayName } = this.data.data;
    this.cancel.emit(this.mapper.toWarningMessages(this.data.data.active === true ?
      `Deactivation of organisation '${displayName}' was cancelled.` :
      `Reactivation of organisation '${displayName}' was cancelled.`));
  }

  onVisibleChange(visible: boolean): void {
    if (!visible) {
      this.onCancel();
    }
  }

  onConfirm(): void {
    const { comment } = this.form.value;
    const { orgId: organisationIdForStateChange } = this.data.data;
    const out: OrganisationActivation =  {
      active: this.data.newValue,
      comment: comment,
      organisationIdForStateChange
    };

    this.changeActivation.emit(out);
  }

  ngOnInit(): void {
    this.form = new TypedFormGroup({
      comment: new UntypedFormControl('')
    });
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (this.form && changes.data) {
      this.form.updateValueAndValidity();
    }

    if (changes.messages) {
      this.errors = {};
      if (this.messages) {
        this.messages.forEach((obj: Message) => {
          const { key: controlName } = obj;
          this.errors[controlName] = { 'server': true };
        });
      }
    }
  }

  private hide(): void {
    this.messages = [];
    this.display = false;
    this.displayChange.emit(this.display);
    this.form.reset();
    this.form.updateValueAndValidity();
  }
}
