import { Component, OnDestroy, OnInit} from '@angular/core';
import {EsmVatIds} from '../master-data.model';
import {EsmInternalMasterDataState} from '../../state/master-data/internal-master-data/internal-master-data.reducer';
import {select, Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {OrganisationService} from '@common/organisation.service';
import * as fromMDSelectors from '../../state/master-data/internal-master-data/internal-master-data.selectors';
import * as fromMDActions from '../../state/master-data/internal-master-data/internal-master-data.actions';
import { ErrorMessageService } from '@common/errors/error-message.service';
import { EsmInternalMasterDataPresetValues } from './internal-master-data.model';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'cms-internal-master-data',
  templateUrl: './internal-master-data.component.html',
  styleUrls: ['./internal-master-data.component.scss']
})
export class InternalMasterDataComponent implements OnInit, OnDestroy {
  presetValues$: Observable<EsmInternalMasterDataPresetValues> = this.store.pipe(select(fromMDSelectors.getEsmPresetValues));
  vatIds$: Observable<EsmVatIds[]> = this.store.pipe(select(fromMDSelectors.getVatIds));
  masterDataId$: Observable<number> = this.store.pipe(select(fromMDSelectors.getMasterDataId));
  isVisibleDetails$: Observable<boolean> = this.store.pipe(select(fromMDSelectors.getIsVisibleDetails));
  activeIndex: number;
  subscription$: Subscription = new Subscription();


  constructor(private store: Store<EsmInternalMasterDataState>,
              private organisationService: OrganisationService,
              public errorMessageService: ErrorMessageService) {
  }

  ngOnInit(): void {

    this.subscription$.add(this.organisationService.onRefresh()
      .subscribe((authenticated) => {
        if(authenticated){
          this.store.dispatch(fromMDActions.setToDefaultAction());
          this.store.dispatch(fromMDActions.loadPresetValuesAction());
          this.store.dispatch(fromMDActions.loadVatIdsAction());
        }}));
  }

  onRunNew(): void {
    this.store.dispatch(fromMDActions.createNewMDRecordAction());
  }

  onSelectVatId(masterDataId: number): void {
    this.store.dispatch(fromMDActions.selectMasterDataIdAction(masterDataId));
  }

  ngOnDestroy(): void {
    this.store.dispatch(fromMDActions.setToDefaultAction());
    this.subscription$.unsubscribe();
  }

  onToggleAllowBankingDetailsDownload(allow: boolean) {
    this.store.dispatch(fromMDActions.updateAllowBankingDetailsDownloadAction(allow));
  }
}
