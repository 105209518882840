import { ErrorResponse } from '@common/error-response.model';
import { Message } from 'primeng/api';


export interface EsmInternalLoadPresetValuesResponse extends ErrorResponse {
    timestamp: string;
    value: EsmInternalMasterDataPresetValues;
  }

  export interface EsmInternalMasterDataPresetValues {
    editButtonsVisible: true;
    organisationCode: string;
    organisationCodeType: string;
    organisationLegalName: string;
    pdfSettingsTabVisible: false;
    allowBankingDetailsDownload: false;
  }

export interface EsmPdfAccessRights {
    editAllowed: boolean ;
    uploadAllowed: boolean;
    createAllowed: boolean;
  }

export interface EsmPdfSettings {
    id?:number;
    version: number;
    masterDataId: number;
    accessRights?: EsmPdfAccessRights;
    logo?: File;
    logoAvailable: boolean;
    invoiceBoilerplate1?: string;
    invoiceBoilerplate2?: string;
    nettingBoilerplate1?: string;
    nettingBoilerplate2?: string;
    invoiceTemplate?:string;
    nettingTemplate?:string;
  }

export interface EsmPdfSettingsFormModel extends ErrorResponse {
    masterDataId: number;
    version: number;
    logoAvailable: boolean;
    invoiceBoilerplate1?: string;
    invoiceBoilerplate2?: string;
    nettingBoilerplate1?: string;
    nettingBoilerplate2?: string;
    invoiceTemplate?:string;
    nettingTemplate?:string;
  }

  export interface EsmPdfSettingsSetupModel extends ErrorResponse {
    editAllowed: boolean;
    uploadAllowed: boolean;
  }

export const initialPdfFormValues = {
  fha: {
    invoiceTemplate: '',
    nettingTemplate: '',
  },
  mo: {
    invoiceBoilerplate1: '',
    invoiceBoilerplate2: '',
    nettingBoilerplate1: '',
    nettingBoilerplate2: ''
  }
}
