import { NettingAmount } from '../../../netting-preview/netted-amount/netting-amount.model';
import { createReducer, on } from '@ngrx/store';
import { AmountTableActions, cleanState, loadAmountSuccess, loading, select } from './amount-table.actions';
import * as NttAction from '../netting-table/netting-table.actions';
import { NettingTableActions } from '../netting-table/netting-table.actions';

export interface AmountSelected {
  id: number;
  values: NettingAmount[];
}

export interface AmountTableState {
  data: AmountSelected[]; // array for state management of partial row selection
  selected: AmountSelected; // for the three rows
  loading: boolean;
}

const list = ['To Receive', 'To Pay', 'Overall'];

const placeHolderValues: NettingAmount[] = [0, 1, 2].map((i) => ({
  cashFlow: list[i],
  ourNettingCalculation: null,
  cpNettingCalculation: null,
  ourOpenUpToDate: null,
  ourAgreedToDate: null,
  ourDisputedUpToDate: null
}));

const placeHolder: AmountSelected = {
  values: placeHolderValues,
  id: null
};

export const amountTableInitialState: AmountTableState = {
  data: [],
  loading: false,
  selected: placeHolder
};

export const nettingAmountTableReducer = createReducer<AmountTableState, AmountTableActions & NettingTableActions>(
  amountTableInitialState,

  on(loading, (state, { load }) => ({ ...state, loading: load })),
  on(select, (state, { id }) => ({ ...state, selected: state.data.find(e => e?.id === id) })),
  on(loadAmountSuccess, (state, { item }) => ({ ...state, data: [...state.data, item], selected: item })),
  on(cleanState, () => ({ ...amountTableInitialState })),
  on(NttAction.loadTable, () => ({ ...amountTableInitialState })),
);



