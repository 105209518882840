import { createAction, props, union } from '@ngrx/store';
import { InvoiceOverview } from '../../../netting-preview/netting-overview/netting-overview.model';
import { InvoiceDivEntry } from '../../../netting-preview/netting-table-overview/netting-table-overview.model';

export let select = createAction('[ESM-NTT-PREVIEW] select master data', props<{ item: InvoiceOverview }>());
export const loading = createAction('[ESM-NTT-PREVIEW] loading (on|off)', props<{ loading: boolean }>());

export const LoadView = createAction('[ESM-NTT-PREVIEW] load preview invoice', props<{ selected: InvoiceDivEntry }>());
export const LoadViewSuccess = createAction('[ESM-NTT-PREVIEW] load preview invoice success', props<{ item: InvoiceOverview }>());

export const cleanState = createAction('[ESM-NTT-PREVIEW] clean invoice preview state');
export const downlandPdf = createAction('[ESM-NTT-PREVIEW] download pdf', props<{ id: number }>());
export const setAllowDialog = createAction('[ESM-NTT-PREVIEW] allow to open dialog', (payload: boolean) => ({payload}));



const actions = union({
  LoadView,
  downlandPdf,
  cleanState,
  setAllowDialog
});

export type InvoicePreviewActions = typeof actions;

