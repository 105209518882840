<div class="padding-left-15 padding-top-6">
  <label *ngIf="isEcmAuditTrail || isEsmAuditTrail" class="margin-right-12"><input [checked]="showProcessingDetails"
      id="checkbox-show-processing-details" type="checkbox" (change)="updateShowProcessingDetails($event)"> Show
    processing details</label>
  <label><input id="checkbox-show-messaging-details" type="checkbox" [checked]="showMessagingDetails"
      (change)="updateShowMessagingDetails($event)"> Show messaging details</label>
</div>
<div class="datatable-container">
  <cms-datatable
    selectionMode="single"
    *ngIf="!!rows"
    [scrollable]="false"
    [data]="rows"
    [currentPage]="page"
    [totalRecords]="numberOfTotalRows"
    [tableColumns]="cols"
    [sortField]="currentSortField"
    dataKey="id"
    [sortOrder]="sortOrder"
    [paginator]="true"
    [autoLayout]="true"
    [loading]="loading"
    [lazy]="!!(rows && rows.length)"
    (lazyLoad)="onLazyLoad($event)">
    <ng-template pTemplate="caption">
      <div class="row">
        <div class="col text-start">
          <span>Audit Trail</span>
        </div>
        <div class="col-2 text-end" *ngIf="!isEcmAuditTrail">
          <button pButton type="button"
            icon="fa fa-download"
            (click)="downloadAuditTrail()"
            label="Download"
            [disabled]="!hasData()"></button>

        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="customBody" let-row="row" let-rowIndex="rowIndex">
      <tr>
        <ng-container *ngFor="let col of cols">
          <ng-container *ngIf="col.name === 'text'; else otherColumn">
            <td *ngIf="col.visible" [ngStyle]="col.style" [ngClass]="lookupRowStyleClass(row)">
              <cms-dynamic-audit-trail-description [text]="row.text" [auditEntryId]="row.id">
              </cms-dynamic-audit-trail-description>
            </td>
          </ng-container>
          <ng-template #otherColumn>
            <td *ngIf="col.visible" [ngStyle]="col.style" [ngClass]="lookupRowStyleClass(row)">
              <span>{{row[col.name]}}</span>
            </td>
          </ng-template>
        </ng-container>
      </tr>
    </ng-template>
  </cms-datatable>
</div>
