import { createAction, props, union } from '@ngrx/store';

import { AmountSelected } from './amount-table.reducers';

export const select = createAction('[ESM-NTT-PREVIEW] Select amount table', props<{ id: number }>());
export const loadAmountSuccess = createAction('[ESM-NTT-PREVIEW] Load amount table', props<{ item: AmountSelected }>());
export const cleanState = createAction('[ESM-NTT-PREVIEW] Clear amount state');
export const loading = createAction('[ESM-NTT-PREVIEW] Loading amount table', props<{ load: boolean }>());

const actions = union({
  select,
  cleanState,
  loading
});

export type AmountTableActions = typeof actions;
