import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { StandingInstructionsService } from '../standing-instructions.service';
import { of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';
import { OrganisationService } from '@common/organisation.service';
import {
  ErrCollateralisationEnum,
  ErrCollateralPortfolioEnum,
  ErrCpFinancialNatureEnum,
  ErrTradingCapacityEnum,
  StandingInstructionModel
} from '../standing-instructions.model';
import { AuthenticationService } from '../../../auth/authentication.service';

enum ControlName {
  leiCode = 'leiCode',
  cpFinancialNature = 'cpFinancialNature',
  cpCorpSectors = 'cpCorpSectors',
  tradingCapacity = 'tradingCapacity',
  beneficiaryId = 'beneficiaryId',
  commercialActivity = 'commercialActivity',
  clearingThreshold = 'clearingThreshold',
  collateralisation = 'collateralisation',
  collateralPortfolio = 'collateralPortfolio',
  collPortfolioCode = 'collPortfolioCode',
  masterAgreeVersion = 'masterAgreeVersion'
}

@Component({
  selector: 'cms-standing-instructions-editor',
  templateUrl: './standing-instructions-editor.component.html',
  styleUrls: ['./standing-instructions-editor.component.scss']
})
export class StandingInstructionsEditorComponent implements OnInit, OnDestroy {
  @Input()
  editable: boolean = false;
  corpSectorsDefaultLabel = 'None';
  // data presets
  financialNatures: SelectItem[] = [];
  cpCorpSectors: SelectItem[] = [];
  tradingCapacities: SelectItem[] = [];
  collateralisations: SelectItem[] = [];
  editForm: UntypedFormGroup;
  submitFormButtonLabel: string = 'Save';
  validationErrors: any = {};
  query: any = {};
  private onDestroy$ = new Subject();

  constructor(private standingInstructionsService: StandingInstructionsService,
              private authService: AuthenticationService,
              private orgService: OrganisationService,
              private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.editForm = this.formBuilder.group({
      [ControlName.leiCode]: ['', Validators.required],
      [ControlName.cpFinancialNature]: [''],
      [ControlName.cpCorpSectors]: [[]],
      [ControlName.tradingCapacity]: [''],
      [ControlName.beneficiaryId]: [''],
      [ControlName.commercialActivity]: [''],
      [ControlName.clearingThreshold]: [''],
      [ControlName.collateralisation]: [''],
      [ControlName.collateralPortfolio]: [''],
      [ControlName.collPortfolioCode]: [''],
      [ControlName.masterAgreeVersion]: ['']
    });

    this.disableFormFields();

    this.orgService.getChangeOrganisationObservable().pipe(takeUntil(this.onDestroy$)).subscribe(organisation => {
      if (this.editable && organisation.organisationId) {
        this.loadStandingInstruction();
        this.standingInstructionsService.setMessages([]);
      }
    });

    this.standingInstructionsService.onStandingInstructionLoaded().pipe(takeUntil(this.onDestroy$)).subscribe((standingInstruction: StandingInstructionModel) => {
      if (standingInstruction) {
        this.fillForm(standingInstruction);

        // fill presets
        // readonly for fha
        if (standingInstruction.cpFinancialNature) {
          this.financialNatures = [{
            label: standingInstruction.cpFinancialNature.value,
            value: standingInstruction.cpFinancialNature.key
          }];
        }

        if (standingInstruction.cpCorpSectors) {
          this.cpCorpSectors = standingInstruction.cpCorpSectors.map(corpSector => {
            return { label: corpSector.value, value: corpSector.key };
          });
        }

        if (standingInstruction.tradingCapacity) {
          this.tradingCapacities = [{
            label: standingInstruction.tradingCapacity.value,
            value: standingInstruction.tradingCapacity.key
          }];
        }

        if (standingInstruction.collateralisation) {
          this.collateralisations = [{
            label: standingInstruction.collateralisation.value,
            value: standingInstruction.collateralisation.key
          }];
        }
        this.disableFormFields();
      }
    });

    this.loadFormValues({}).then(loaded => {
      if (loaded) {
        this.initFormChangeObservables();
      }
    });

    this.standingInstructionsService.onServerValidationErrors().subscribe(errors => {
      this.validationErrors = errors;
    });
  }

  private initFormChangeObservables(): void {

    this.editForm.controls[ControlName.cpFinancialNature].valueChanges.subscribe(cpFinancialNature => {
      if (this.editable && cpFinancialNature) {
        this.corpSectorsDefaultLabel = 'None';

        if (this.editForm.controls[ControlName.cpFinancialNature].touched) {
          this.query = { ...this.query, cpFinancialNature: cpFinancialNature };
          this.loadFormValues(this.query);
          this.editForm.patchValue({
            [ControlName.cpCorpSectors]: []
          });
          this.editForm.updateValueAndValidity();
        }
        this.updateFieldsDependentOnCpFinancialNature(cpFinancialNature);
      }
    });

    this.editForm.controls[ControlName.tradingCapacity].valueChanges.subscribe(tradingCapacity => {
      if (tradingCapacity) {
        this.updateFieldsDependentOnTradingCapacity(tradingCapacity);
      }
    });
    this.editForm.controls[ControlName.collateralisation].valueChanges.subscribe(collateralisation => {
      if (this.editable) {
        if (this.editForm.controls[ControlName.collateralisation].touched) {
          this.query = { ...this.query, collateralPortfolio: collateralisation };
          this.loadFormValues(this.query);
        }
        this.updateFieldsDependentOnCollateralisation(collateralisation);
      }
    });
    this.editForm.controls[ControlName.collateralPortfolio].valueChanges.subscribe(collateralPortfolio => {
      this.updateFieldsDependentOnCollateralPortfolio(collateralPortfolio);
    });
  }

  private updateFieldsDependentOnCpFinancialNature(cpFinancialNature: string): void {
    if (cpFinancialNature === ErrCpFinancialNatureEnum.FINANCIAL) {
      this.editForm.controls[ControlName.cpCorpSectors].enable();
      this.disableAndReset(this.editForm.controls[ControlName.commercialActivity]);
      this.disableAndReset(this.editForm.controls[ControlName.clearingThreshold]);
      this.editForm.controls[ControlName.collateralisation].enable();
    } else if (cpFinancialNature === ErrCpFinancialNatureEnum.NON_FINANCIAL) {
      this.editForm.controls[ControlName.cpCorpSectors].enable();
      this.editForm.controls[ControlName.commercialActivity].enable();
      this.editForm.controls[ControlName.clearingThreshold].enable();
      this.editForm.controls[ControlName.collateralisation].enable();
    } else if (!cpFinancialNature || cpFinancialNature === ErrCpFinancialNatureEnum.CENTRAL) {
      this.disableAndReset(this.editForm.controls[ControlName.cpCorpSectors]);
      this.disableAndReset(this.editForm.controls[ControlName.commercialActivity]);
      this.disableAndReset(this.editForm.controls[ControlName.clearingThreshold]);
      this.disableAndReset(this.editForm.controls[ControlName.collateralisation]);
    } else if (cpFinancialNature === ErrCpFinancialNatureEnum.OTHER) {
      this.disableAndReset(this.editForm.controls[ControlName.cpCorpSectors]);
      this.disableAndReset(this.editForm.controls[ControlName.commercialActivity]);
      this.disableAndReset(this.editForm.controls[ControlName.clearingThreshold]);
      this.editForm.controls[ControlName.collateralisation].enable();
    }
  }

  private updateFieldsDependentOnTradingCapacity(tradingCapacity: string): void {
    if (tradingCapacity === ErrTradingCapacityEnum.AGENT) {
      this.editForm.controls[ControlName.beneficiaryId].enable();
    } else {
      this.disableAndReset(this.editForm.controls[ControlName.beneficiaryId]);
    }
  }

  private updateFieldsDependentOnCollateralisation(collateralisation: string): void {
    if (!collateralisation || collateralisation === ErrCollateralisationEnum.UNCOLLATERALISED) {
      this.disableAndReset(this.editForm.controls[ControlName.collateralPortfolio]);
    } else {
      this.editForm.controls[ControlName.collateralPortfolio].enable();
    }
  }

  private updateFieldsDependentOnCollateralPortfolio(collateralPortfolio: string): void {
    if (collateralPortfolio === ErrCollateralPortfolioEnum.YES) {
      this.editForm.controls[ControlName.collPortfolioCode].enable();
    } else {
      this.disableAndReset(this.editForm.controls[ControlName.collPortfolioCode]);
    }
  }

  private disableAndReset(abstractControl: AbstractControl): void {
    abstractControl.disable();
    abstractControl.reset();
  }

  // is<field>Required

  get isCpCorpSectorsRequired(): boolean {
    return this.editForm.controls[ControlName.cpCorpSectors].enabled;
  }

  get isBeneficiaryIdRequired(): boolean {
    return this.editForm.controls[ControlName.beneficiaryId].enabled;
  }

  get isCommercialActivityRequired(): boolean {
    return this.editForm.controls[ControlName.commercialActivity].enabled;
  }

  get isClearingThresholdRequired(): boolean {
    return this.editForm.controls[ControlName.clearingThreshold].enabled;
  }

  get isCollateralisationRequired(): boolean {
    return false; // field is optional. this method is just for consistency
  }

  get isCollateralPortfolioRequired(): boolean {
    return this.editForm.controls[ControlName.collateralPortfolio].enabled;
  }

  get isCollateralPortfolioCodeRequired(): boolean {
    return this.editForm.controls[ControlName.collPortfolioCode].enabled;
  }

  // has<field>ValidationErrors
  get hasCpFinancialNatureValidationErrors(): boolean {
    return this.validationErrors && this.validationErrors.cpFinancialNature;
  }

  get hasCpCorpSectorsValidationErrors(): boolean {
    return this.validationErrors && this.validationErrors.cpCorpSectors;
  }

  get hasTradingCapacityValidationErrors(): boolean {
    return this.validationErrors && this.validationErrors.tradingCapacity;
  }

  get hasBeneficiaryIdValidationErrors(): boolean {
    return this.validationErrors && this.validationErrors.beneficiaryId;
  }

  get hasCommercialActivityValidationErrors(): boolean {
    return this.validationErrors && this.validationErrors.commercialActivity;
  }

  get hasClearingThresholdValidationErrors(): boolean {
    return this.validationErrors && this.validationErrors.clearingThreshold;
  }

  get hasCollateralisationValidationErrors(): boolean {
    return this.validationErrors && this.validationErrors.collateralisation;
  }

  get hasCollateralPortfolioValidationErrors(): boolean {
    return this.validationErrors && this.validationErrors.collateralisationPortfolio;
  }

  get hasCollPortfolioCodeValidationErrors(): boolean {
    return this.validationErrors && this.validationErrors.collPortfolioCode;
  }

  get hasMasterAgreeVersionValidationErrors(): boolean {
    return this.validationErrors && this.validationErrors.masterAgreeVersion;
  }

  private loadStandingInstruction() {
    this.standingInstructionsService.loadStandingInstruction(null);
  }

  private loadFormValues(query): Promise<boolean> {
    if (this.editable) {
      return Promise.resolve(true);
      // return this.standingInstructionsService.loadFormValues(query).then(formValues => {
      //   this.fillFormValues(formValues);
      //   return true;
      // });
    } else {
      return Promise.resolve(false);
    }

  }

  private fillFormValues(formValues: any): void {
    if (formValues) {

      this.financialNatures = [];
      if (formValues.cpFinancialNature) {
        this.financialNatures = formValues.cpFinancialNature.map(keyValue => {
          return { label: keyValue.value, value: keyValue.key };
        });
      }

      this.cpCorpSectors = [];
      if (formValues.cpCorpSectors) {
        this.cpCorpSectors = formValues.cpCorpSectors.map(keyValue => {
          return { label: keyValue.value, value: keyValue.key };
        });
      }

      this.tradingCapacities = [];
      if (formValues.tradingCapacity) {
        this.tradingCapacities = formValues.tradingCapacity.map(keyValue => {
          return { label: keyValue.value, value: keyValue.key };
        });
      }

      this.collateralisations = [{ label: ' ', value: null }];
      if (formValues.collateralisation) {
        const values = formValues.collateralisation.map(keyValue => {
          return { label: keyValue.value, value: keyValue.key };
        });

        this.collateralisations = this.collateralisations.concat(values);
      }
    }
  }

  private fillForm(standingInstruction: StandingInstructionModel): void {
    this.editForm.reset();

    // select current values
    this.editForm.controls[ControlName.leiCode].setValue(standingInstruction.leiCode);
    if (standingInstruction.cpFinancialNature) {
      this.editForm.controls[ControlName.cpFinancialNature].setValue(standingInstruction.cpFinancialNature.key);
    }
    this.editForm.patchValue({
      [ControlName.cpCorpSectors]: standingInstruction.cpCorpSectors ?
        standingInstruction.cpCorpSectors.map(sector => sector.key) : []
    });

    if (standingInstruction.tradingCapacity) {
      this.editForm.controls[ControlName.tradingCapacity].setValue(standingInstruction.tradingCapacity.key);
    }

    this.editForm.controls[ControlName.beneficiaryId].setValue(standingInstruction.beneficiaryId);

    if (standingInstruction.commercialActivity) {
      this.editForm.controls[ControlName.commercialActivity].setValue(standingInstruction.commercialActivity.key);
    }

    if (standingInstruction.clearingThreshold) {
      this.editForm.controls[ControlName.clearingThreshold].setValue(standingInstruction.clearingThreshold.key);
    }

    if (standingInstruction.collateralisation) {
      this.editForm.controls[ControlName.collateralisation].setValue(standingInstruction.collateralisation.key);
    }

    if (standingInstruction.collateralPortfolio) {
      this.editForm.controls[ControlName.collateralPortfolio].setValue(standingInstruction.collateralPortfolio.key);
    }

    this.editForm.controls[ControlName.collPortfolioCode].setValue(standingInstruction.collPortfolioCode);
    this.editForm.controls[ControlName.masterAgreeVersion].setValue(standingInstruction.masterAgreeVersion);
  }

  ngOnDestroy(): void {
    this.editForm.reset();
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
    this.standingInstructionsService.reset();
  }

  private disableFormFields(): void {
    this.editForm.controls[ControlName.cpFinancialNature].disable();
    this.editForm.controls[ControlName.cpCorpSectors].disable();
    this.editForm.controls[ControlName.tradingCapacity].disable();
    this.editForm.controls[ControlName.beneficiaryId].disable();
    this.editForm.controls[ControlName.commercialActivity].disable();
    this.editForm.controls[ControlName.clearingThreshold].disable();
    this.editForm.controls[ControlName.collateralisation].disable();
    this.editForm.controls[ControlName.collateralPortfolio].disable();
    this.editForm.controls[ControlName.collPortfolioCode].disable();
    this.editForm.controls[ControlName.masterAgreeVersion].disable();
  }
}
