<div class="trade-reference">
  <div class="form-title">Trade | Price</div>
  <div class="form-body" [formGroup]="formGroup">
    <div class="row gutter-0 !me-[40px]">
      <div class="col-5 label">Price</div>
      <div class="col-7 row gutter-0 ps-2 pe-1">
        <div class="col-6">
          <input type="text" cmsDigitOnly maxlength="22"
            [class]="'form-control padding-6 text-medium margin-3 ' + getValidationErrorStyleClass('price')"
            formControlName="price">
        </div>
        <div class="col-6 ps-2">
          <p-dropdown formControlName="priceUnit" [options]="priceCurrency" [group]="true"
            [style]="{'width': '100%'}" [styleClass]="'margin-3 ' + getValidationErrorStyleClass('priceUnit')"
            ></p-dropdown>
        </div>
      </div>
      <span *ngIf="formGroup.controls['priceUnit'].hasError('error') || formGroup.controls['price'].hasError('error')"
        class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0 !me-[40px]">
      <div class="col-5 label">Price Formula (alternatively)</div>
      <div class="col-7 ps-2 pe-1">
        <textarea
          [class]="'form-control p-component padding-6 text-medium margin-3 trade-entry-2-price-formula-textarea ' + getValidationErrorStyleClass('priceFormula')"
          formControlName="priceFormula" maxlength="1000"
          [ngClass]="{'error':formGroup.controls['priceFormula'].hasError('error')}"></textarea>
      </div>
      <span *ngIf="formGroup.controls['priceFormula'].hasError('error')"
        class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0 !me-[40px]">
      <div class="col-5 label">Type of Index Price</div>
      <div class="col-7 ps-2 pe-1">
        <p-dropdown formControlName="typeOfIndexPrice" [options]="typeOfIndexPrice" [style]="{'width': '100%'}"
          [styleClass]="'margin-3 ' + getValidationErrorStyleClass('typeOfIndexPrice')" >
        </p-dropdown>
      </div>
      <span *ngIf="formGroup.controls['typeOfIndexPrice'].hasError('error')"
        class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0 !me-[40px]">
      <div class="col-5 label">Estimated Notional Amount</div>
      <div class="col-7 row gutter-0 ps-2 pe-1">
        <div class="col-6">
          <input type="text" cmsDigitOnly maxlength="22"
            [class]="'form-control padding-6 text-medium margin-3 ' + getValidationErrorStyleClass('estimatedNotionalAmountValue')"
            formControlName="estimatedNotionalAmountValue">
        </div>
        <div class="col-6 ps-2">
          <p-dropdown formControlName="estimatedNotionalAmountUnit" [options]="estimatedNotionalAmountUnit"
             [style]="{'width': '100%'}" [group]="true"
            [styleClass]="'margin-3 ' + getValidationErrorStyleClass('estimatedNotionalAmountUnit')"
            ></p-dropdown>
        </div>
      </div>
      <span *ngIf="formGroup.controls['estimatedNotionalAmountValue'].hasError('error')"
        class="fa fa-info-circle absolute error-marker"></span>
    </div>
  </div>
</div>
