import { createSelector } from '@ngrx/store';
import { getEsmSettingsState } from '../settings.selectors';
import { EsmSettingsState } from '../settings.reducers';
import { EsmToleranceSettingsState } from './tolerance-settings.reducers';


export const getToleranceSettings = createSelector(getEsmSettingsState, (state: EsmSettingsState) => state.tolerance);
//
export const getMessages = createSelector(getToleranceSettings, (state: EsmToleranceSettingsState) => state.messages);
export const getLoading = createSelector(getToleranceSettings, (state: EsmToleranceSettingsState) => state.loading);
export const getPaging = createSelector(getToleranceSettings, (state: EsmToleranceSettingsState) => state.paging);
export const getSorting = createSelector(getToleranceSettings, (state: EsmToleranceSettingsState) => state.sorting);
//
export const isToleranceMatchAllowed = createSelector(getToleranceSettings, (state: EsmToleranceSettingsState) => state.supportToleranceMatching);
export const hasEditRights = createSelector(getToleranceSettings, (state: EsmToleranceSettingsState) => state.editable);
export const getTableData = createSelector(getToleranceSettings, (state: EsmToleranceSettingsState) =>
  state.data == null ? null : state.data.values.map((item) => Object.assign({}, item))
);
