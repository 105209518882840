import { DateUtils } from './date.utils';
import { ErrorResponse } from '@common/error-response.model';

export interface ApiError { // de.ponton.cms.restfulapi.web.ApiError
                            // private HttpStatus status;
  errorMessage: string;
  // private List<String> errors;
  // private Map<String, String> fieldErrors;
  // private String errorReferenceCode;
}

export interface BaseResponseModel { // de.ponton.cms.restfulapi.model.BaseResponseModel
  errorMessage?: string;
  validationFailureMessages?: string[];
  timestamp?: string;
}

export interface DateRangeResponse extends ErrorResponse {
  start: string;
  end: string;
}

export interface CommonListResponseModel<T> extends BaseResponseModel { // de.ponton.cms.restfulapi.model.CommonListResponseModel
  values: T[];
}

export interface NumberRange {
  start: number;
  end: number;
}

export interface StartEndDate {
  start: Date;
  end: Date;
}

export class DateRangeFilter {
  start: string;
  end: string;

  public static of({ start, end }: StartEndDate) {
    return new DateRangeFilter(start, end);
  }

  public getStartAsDate(): Date {
    return new Date(this.start);
  }

  public getEndAsDate(): Date {
    return new Date(this.end);
  }

  public constructor(start: Date, end: Date) {
    if (start) {
      this.start = DateUtils.toISOStringWithIgnoreTimezoneOffset(start);
    }
    if (end) {
      this.end = DateUtils.toISOStringWithIgnoreTimezoneOffset(end);
    }
  }

}

export class DateTimeRangeFilter {
  start: string;
  end: string;

  public static of({ start, end }: StartEndDate) {
    return new DateRangeFilter(start, end);
  }

  public getStartAsDate(): Date {
    return new Date(this.start);
  }

  public getEndAsDate(): Date {
    return new Date(this.end);
  }

  public constructor(start: Date, end: Date) {
    if (start) {
      this.start = DateUtils.formatToLocalDateTime(start);
    }
    if (end) {
      this.end = DateUtils.formatToLocalDateTime(end);
    }
  }

}


