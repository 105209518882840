import { Injectable } from '@angular/core';
import { catchError, filter, switchMap, withLatestFrom } from 'rxjs/operators';
import { Message } from 'primeng/api';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { EcmDashboardState } from '../dashboard.reducers';
import { ApiRequestService } from '@common/api-request.service';
import { MessagesMapperService } from '@common/messages-mapper.service';
import {
  defaultStyles,
  EcmBuckectsRequest,
  EcmBuckectsResponse,
  EcmDateRange,
  ecmDateRangeToLabelMaps,
} from '../../../ecm-dashboard/ecm-dashboard.model';
import { getDashboard } from '../dashboard.selectors';
import * as fromFilterActions from '../filters/filters.actions';
import * as fromTabActions from '../tab/tab.actions';
import * as fromCategoryActions from '../category/category.actions';
import * as fromDealsActions from '../deals/deals.actions';
import { mapRequestValues } from '@common/deals/deals.model';

export const defaultColumn = {
  name: '',
  type: 'ecm',
  title: '',
  style: { ...defaultStyles, 'color': '#006591', 'text-align': 'right' },
  sortable: false,
  visible: true
};

@Injectable()
export class EcmTabEffects {
  private baseUrl = '/api/ecm/dashboard/buckets';

  constructor(private apiGateway: ApiRequestService,
              private actions$: Actions,
              private store$: Store<EcmDashboardState>,
              private router: Router,
              private mapper: MessagesMapperService) {
  }

   loadBuckets$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromTabActions.GET_BUCKETS,
        fromFilterActions.FILTER,
       // fromCategoryActions.SET_CATEGORY,
        fromDealsActions.LOAD_NAV_STATES),
      filter((action: any) => {
        return !this.router.routerState.snapshot.url.includes('/deals') ||
          (action instanceof fromDealsActions.LoadNavStates);
      }),
      withLatestFrom(this.store$.pipe(select(getDashboard))),
      switchMap(([action, state]: [fromTabActions.GetBucketsAction, EcmDashboardState]) => {
          const { filters, filters: {propFilters},  category } = state;
          if (!filters.loaded && !(action instanceof fromDealsActions.LoadNavStates)) {
            return [new fromTabActions.SetMessagesAction([])];
          }
          const newPropFilters: EcmBuckectsRequest = {
            broker: mapRequestValues(propFilters.brokers, filters.broker),
            category,
            commodity: mapRequestValues(propFilters.commodities, filters.commodities),
            counterParty: mapRequestValues(propFilters.counterParties, filters.counterParties),
            hiddenDeals: filters.propFilters.hiddenDeals,
            market: mapRequestValues(propFilters.markets, filters.markets),
            transactionType: mapRequestValues(propFilters.transactionTypes, filters.transactionTypes),
            viewBy: filters.viewByFilter
          };

          return this.apiGateway.post(this.baseUrl, newPropFilters)
            .pipe(
              switchMap((response: EcmBuckectsResponse) => {
                const messages: Message[] = this.mapper.toErrorMessages(response);
                if (messages && messages.length) {
                  return [new fromTabActions.SetMessagesAction(messages)];
                }

                const rows = response.rows.map(item =>
                  ({ state: item.state, ...item.bucketValues }));
                const dateRanges = response.bucketNames;
                const columns = [];
                columns.push({
                  ...defaultColumn,
                  name: 'state',
                  title: 'Age',
                  type: 'state',
                  style: { ...defaultStyles, 'color': '#006591', 'text-align': 'left' }
                });
                dateRanges.forEach((dateRange: EcmDateRange) => {
                  columns.push({
                    ...defaultColumn,
                    name: dateRange,
                    title: [ecmDateRangeToLabelMaps[dateRange]]
                  });
                });

                return [
                  new fromTabActions.SetMessagesAction([]),
                  new fromTabActions.SetColumnsAction(columns),
                  new fromTabActions.GetBucketsSuccessAction({ rows, stateNames: response.stateNames })
                ];
              }),
              catchError(error => of(new fromTabActions.SetMessagesAction([this.mapper.createErrorMessage(error.errorMessage)])))
            );
        }
      )
    ));

}
