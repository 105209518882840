import { Component } from '@angular/core';
import { ErrorMessageService } from '@common/errors/error-message.service';

@Component({
  selector: 'cms-smt-dealfinder',
  templateUrl: './smt-dealfinder.component.html'
})
export class SmtDealFinderComponent {

  constructor( public readonly errorMessageService:ErrorMessageService) {
  }
}
