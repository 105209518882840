import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { PreferredValuesRowModel } from './trade_entry.model';
import { Column } from '../../../common/shared/datatable.model';
import { TradeEntryService } from './trade-entry.service';
import { Router } from '@angular/router';
import { TradeEntryCustomiseComponent } from './customise/trade-entry-customise.component';
import { ErrorMessageService } from '@common/errors/error-message.service';

@Component({
  selector: 'cms-trade-entry',
  templateUrl: './trade_entry.component.html',
  styleUrls: ['./trade_entry.component.scss']
})
export class TradeEntryComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject();
  preferredValues: PreferredValuesRowModel[] = [];

  private tableColumns: Column[] = TradeEntryDatatableConfig.TABLE_COLUMNS;
  selection: PreferredValuesRowModel;

  constructor(private tradeEntryService: TradeEntryService,
              private router: Router,
              public readonly errorMessageService:ErrorMessageService) {
  }

  get cols() {
    return this.tableColumns;
  }

  ngOnInit() {
    this.tradeEntryService.loadPreferredValues().subscribe(entries => {
      this.preferredValues = entries;
    });
  }

  public ngOnDestroy(): void {
    // Clean up all subscriptions at once:
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  public customize(fieldName) {
    this.router.navigate(['err/settings/trade_entry/customise?field=' + fieldName]);
  }

  private customizeQueryParams(row: PreferredValuesRowModel) {
    const queryParams = {};
    queryParams[TradeEntryCustomiseComponent.queryParamConfigurationName] = row.configurationName;
    queryParams[TradeEntryCustomiseComponent.queryParamFieldDisplayName] = row.fieldDisplayName;

    return queryParams;
  }
}

export namespace TradeEntryDatatableConfig {
  export const TABLE_COLUMNS: Column[] = [
    { name: 'configurationName', title: '', style: { 'width': '25px', 'text-align': 'center' }, sortable: false },
    { name: 'fieldDisplayName', title: 'Field', style: { 'width': '100px', 'text-align': 'left' }, },
    { name: 'preferences', title: 'Preferences', style: { 'width': 'calc(100vw - 125px)', 'text-align': 'left' }, sortable: false },
  ];
}


