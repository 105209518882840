<div *ngIf="ids && ids.length" class="warning">
  <div *ngIf="ids.length === 1" class="warning__id-container">
    <i class="fa fa-exclamation-triangle"></i>
    <span>ID not found: {{ids[0]}}</span>
  </div>
  <div *ngIf="ids.length > 1" class="warning__id-container">
    <i class="fa fa-exclamation-triangle"></i>
    <span>{{ids.length}} of {{allCount}} IDs not found</span>
    <a id="warnings-show-hide-btn" (click)="onClick($event)" class="warning__collapse">{{open ? 'hide...' : 'show...'}}</a>
    <ul *ngIf="open" class="warning__id-list">
      <li *ngFor="let id of ids">{{id}}</li>
    </ul>
  </div>
</div>
