import { UntypedFormGroup } from '@angular/forms';

export abstract class AbstractFormComponent {

  private formSubmitAttempt: boolean;

  constructor() {
    this.formSubmitAttempt = false;
  }

  protected isFormValid(): boolean {
    return this.getFormGroup().valid;
  }

  protected resetForm(): void {
    this.getFormGroup().reset();
    this.setFormSubmitAttempt(false);
  }

  protected isFieldInvalid(fieldName: string): boolean {
    const field = this.getFormGroup().get(fieldName);
    const invalid = !field.valid;
    return ((invalid && field.touched)) || (invalid && field.untouched && this.isFormSubmitAttempt());
  }

  protected isFieldInvalidAndIsSubmitAttempt(field: string): boolean {
    return this.isFormSubmitAttempt() && this.isFieldInvalid(field);
  }

  protected getFormControls() {
    return this.getFormGroup().controls;
  }

  protected getFormControl(name: string) {
    return this.getFormControls()[name];
  }

  protected getFormControlValue(name: string) {
    return this.getFormControls()[name].value;
  }

  protected setFormControlValue(name: string, value: any) {
    return this.getFormControls()[name].setValue(value);
  }

  protected getFormFieldErrors(field: string) {
    return this.getFormGroup().get(field).errors;
  }

  protected getFormFieldError(field: string, error: string) {
    return this.getFormFieldErrors(field)[error];
  }

  protected hasErrorsAndIsSubmitAttempt(field: string) {
    return this.getFormFieldErrors(field) && this.isFormSubmitAttempt();
  }

  protected isError(field: string, error: string) {
    if (this.hasErrorsAndIsSubmitAttempt(field)) {
      return this.getFormFieldError(field, error);
    }
    return false;
  }

  isRequiredError(field: string) {
    return this.isError(field, 'required');
  }

  protected isFormSubmitAttempt(): boolean {
    return this.formSubmitAttempt;
  }

  protected setFormSubmitAttempt(formSubmitAttempt: boolean): void {
    this.formSubmitAttempt = formSubmitAttempt;
  }

  public displayFieldClass(field: string): any {
    return { 'error': this.isFieldInvalidAndIsSubmitAttempt(field) };
  }

  protected abstract getFormGroup(): UntypedFormGroup;

}
