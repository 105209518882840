import { Message } from 'primeng/api';
import { Prefix} from '../reducers';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { EditableCell } from '@common/datatable/cell-editor/cell-editor.model';
import * as fromDtAction from './datatable.actions';
import { SET_TO_DEFAULT } from '../shared/shared.actions';

export interface DatatableState<T = any, Filters = any> {
  data: DatatableData<T[]>;
  messages: Message[];
  selected?: T;
  filters?: Filters;
  paging?: PagingFilter;
  sorting?: SortingFilter;
  loading: boolean;
  editableData?: EditableCell;
}

export function datatableReducer(prefix: Prefix, initialState) {
  return function (state: DatatableState, action: fromDtAction.DatatableActions): DatatableState {
    switch (action.type) {
      case `${prefix} ${fromDtAction.INSERT_ITEM}`:
        const values = state.data.values;
        const newValue = (action as fromDtAction.InsertItemAction).payload;
        const index = values.findIndex(item => item.id === newValue.id);
        const newValues = [...values.slice(0, index),
          newValue,
          ...values.slice(index + 1)
        ];

        return {
          ...state,
          data: {
            values: newValues,
            count: state.data.count
          },
          messages: [],
          selected: state.selected && newValue.id === state.selected.id ? newValue : null,
          editableData: null
        };
      case `${prefix} ${fromDtAction.SELECT}`:
        const selected: any = (action as fromDtAction.SelectAction).payload;
        let editableData = state.editableData;
        if (editableData && selected && selected !== state.editableData.data) {
          editableData = null;
        }
        return {
          ...state,
          selected,
          editableData
        };
      case `${prefix} ${fromDtAction.FILTER_DATA}`:
        return {
          ...state,
          loading: true,
          messages: [],
          editableData: null,
          selected: null,
          filters: (action as fromDtAction.FilterDataAction).payload,
        };
      case `${prefix} ${fromDtAction.UPDATE_FILTER_DATA}`:
        return {
          ...state,
          filters: { ...state.filters, ...(action as fromDtAction.UpdateFilterAction).payload }
        };
      case `${prefix} ${fromDtAction.SET_EDITABLE_DATA}`:
        return {
          ...state,
          editableData: (action as fromDtAction.SetEditableDataAction).payload
        };
      case `${prefix} ${fromDtAction.SORT_AND_PAGE_DATA}`:
        return {
          ...state,
          loading: true,
          messages: [],
          editableData: null,
          selected: null,
          paging: (action as fromDtAction.SortAndPageDataAction).payload.paging,
          sorting: (action as fromDtAction.SortAndPageDataAction).payload.sorting
        };
      case `${prefix} ${fromDtAction.LOAD_DATA_SUCCESS}`:
      case `${prefix} ${fromDtAction.FILTER_DATA_SUCCESS}`:
        const payload = (action as fromDtAction.FilterDataSuccessAction).payload;
        return {
          ...state,
          loading: false,
          editableData: null,
          selected: null,
          data: {
            count: payload.count,
            values: payload.values
          },
          paging: {
            ...state.paging,
            page: payload.currentPage
          }
        };
      case `${prefix} ${fromDtAction.SET_MESSAGES}`:
        return {
          ...state,
          loading: false,
          editableData: null,
          messages: [...(action as fromDtAction.SetMessagesAction).payload],
        };
      case `${prefix} ${SET_TO_DEFAULT}`:
        return {
          ...initialState
        };
      default:
        return state;
    }
  };
}

export const getData = (datatableState: DatatableState): DatatableData<any[]> => datatableState.data;
export const getSorting = (state: DatatableState) => state.sorting;
export const getPaging = (state: DatatableState) => state.paging;
export const getLoading = (state: DatatableState) => state.loading;
export const getEditableData = (state: DatatableState) => state.editableData;
export const getMessages = (state: DatatableState) => state.messages;
export const getSelected = (state: DatatableState) => state.selected;
