import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { ErrorMessageService } from '@common/errors/error-message.service';
import { Observable } from 'rxjs';
import { Message } from 'primeng/api';
import { select, Store } from '@ngrx/store';
import * as fromTableSelectors from '../state/netting-preview/netting-table/netting-table.selectors';
import { OrganisationService } from '@common/organisation.service';
import { NettingPreviewState } from '../state/netting-preview';
import { CounterpartyDropdownComponent } from './counterparty-dropdown/counterparty-dropdown.component';

@Component({
  selector: 'cms-netting-preview',
  templateUrl: './netting-preview.component.html',
  styleUrls: ['./netting-preview.component.scss']
})
export class NettingPreviewComponent implements AfterViewInit{

  @ViewChild(CounterpartyDropdownComponent) dropdown : CounterpartyDropdownComponent;

  messages$: Observable<Message[]> = this.store$.pipe(select(fromTableSelectors.getMessages));


  constructor( public readonly errorMessageService:ErrorMessageService,
               private organisationService: OrganisationService,
               private store$: Store<NettingPreviewState>,
               private cdRef:ChangeDetectorRef) {
  }

  ngAfterViewInit(): void {
    this.dropdown.init();
    this.cdRef.detectChanges();
  }

}
