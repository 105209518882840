import { Component, EventEmitter, OnInit, OnDestroy, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { StringUtils } from '../../../../common/shared/string.utils';
import { CpmlDealsService } from '../../cpml-deals.service';
import { CpmlDealsSearchQuery } from './cpml-dealfinder-filter.model';
import { Subscription } from 'rxjs';
import { OrganisationService } from '@common/organisation.service';

@Component({
  selector: 'cms-cpml-dealfinder-idfilter',
  templateUrl: './cpml-dealfinder-idfilter.component.html',
  styleUrls: ['./cpml-dealfinder-idfilter.component.scss'],
})
export class CpmlDealFinderIdFilterComponent implements OnInit, OnDestroy {
  @Output() changeDtVisibilityIdFilter = new EventEmitter();

  documentIdFilterFormGroup: UntypedFormGroup;
  documentIdFilterFormSubmitted: boolean = false;
  documentIdField: UntypedFormControl;

  utiFilterFormGroup: UntypedFormGroup;
  utiFilterFormSubmitted: boolean = false;
  utiField: UntypedFormControl;

  tradeIdFilterFormGroup: UntypedFormGroup;
  tradeIdFilterFormSubmitted: boolean = false;
  tradeIdField: UntypedFormControl;

  subscription$: Subscription;

  constructor(private formBuilder: UntypedFormBuilder,
              private organisationService: OrganisationService,
              public cpmlDealService: CpmlDealsService) {
  }

  public ngOnInit(): void {
    this.documentIdField = new UntypedFormControl('', this.idsValidator());
    this.documentIdFilterFormGroup = this.formBuilder.group({
      documentId: this.documentIdField
    });

    this.utiField = new UntypedFormControl('', this.idsValidator());
    this.utiFilterFormGroup = this.formBuilder.group({
      uti: this.utiField
    });

    this.tradeIdField = new UntypedFormControl('', this.idsValidator());
    this.tradeIdFilterFormGroup = this.formBuilder.group({
      tradeId: this.tradeIdField
    });

    this.subscription$ = this.organisationService.getChangeOrganisationObservable()
    .subscribe(this.onResetFilters.bind(this));
  }

  public ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

  onSubmitDocumentIdFilterForm() {
    this.cpmlDealService.emitNewSearchQuery(CpmlDealsSearchQuery.createEmptySearchQuery());
    this.documentIdFilterFormSubmitted = true;
    if (this.documentIdFilterFormGroup.valid) {
      const ids = this.getIds(this.documentIdField);
      this.cpmlDealService.emitChangeIdFilter({ searchType: 'DOCUMENT_ID', ids });
      this.changeDtVisibilityIdFilter.emit();
    }
  }

  onSubmitUtiFilterForm() {
    this.cpmlDealService.emitNewSearchQuery(CpmlDealsSearchQuery.createEmptySearchQuery());
    this.utiFilterFormSubmitted = true;
    if (this.utiFilterFormGroup.valid) {
      const ids = this.getIds(this.utiField);
      this.cpmlDealService.emitChangeIdFilter({ searchType: 'UTI', ids });
      this.changeDtVisibilityIdFilter.emit();
    }
  }

  onSubmitTradeIdFilterForm() {
    this.cpmlDealService.emitNewSearchQuery(CpmlDealsSearchQuery.createEmptySearchQuery());
    this.tradeIdFilterFormSubmitted = true;
    if (this.tradeIdFilterFormGroup.valid) {
      const ids = this.getIds(this.tradeIdField);
      this.cpmlDealService.emitChangeIdFilter({ searchType: 'TRADE_ID', ids });
      this.changeDtVisibilityIdFilter.emit();
    }
  }

  onResetFilters() {
    this.documentIdFilterFormGroup.reset();
    this.documentIdFilterFormSubmitted = false;
    this.utiFilterFormGroup.reset();
    this.utiFilterFormSubmitted = false;
    this.tradeIdFilterFormGroup.reset();
    this.tradeIdFilterFormSubmitted = false;
    this.cpmlDealService.emitResetFilter();
  }

  isDocumentIdsNotRuleConformError() {
    return this.isNotRuleConformError(this.documentIdField);
  }

  isUtisNotRuleConformError() {
    return this.isNotRuleConformError(this.utiField);
  }

  isTradeIdsNotRuleConformError() {
    return this.isNotRuleConformError(this.tradeIdField);
  }

  private getIds(field: UntypedFormControl): string[] {
    const ids = StringUtils.tokenizeByCommaOrLineBreaks(field.value);
    return [...new Set(ids)];
  }

  private isNotRuleConformError(field: UntypedFormControl) {
    return field.errors && field.errors['idsNotRuleConform'];
  }

  private idsValidator(): ValidatorFn {

    const ruleConformValidator: ValidatorFn = (control: AbstractControl): { [key: string]: any } => {
      const ids = StringUtils.tokenizeByCommaOrLineBreaks(control.value);
      const idsNotRuleConform = ids.length > 50 || ids[0] === '';
      return idsNotRuleConform ? { 'idsNotRuleConform': { value: control.value } } : null;
    };

    return Validators.compose([
      Validators.required,
      ruleConformValidator
    ]);
  }
}
