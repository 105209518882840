import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { createReducer, on } from '@ngrx/store';
import { EsmExternalMasterDataModel, EsmExternalMasterDataPresetValues } from 'app/esm/master-data/external-master-data/external-master-data.model';
import { EsmBankDetails, EsmExternalFormValues, EsmMessagesModel, initialFormValues } from 'app/esm/master-data/master-data.model';
import { Message } from 'primeng/api';
import * as fromCommon from '@common/state/reducers';

import * as fromMDActions from './external-master-data.actions';
import { ESM_MD_DIALOGS } from '../master-data.reducer';


export interface EsmExternalMasterDataTableState {
    data: DatatableData<EsmExternalMasterDataModel[]>;
    paging: PagingFilter;
    sorting: SortingFilter;
    searchToken: string;
    loading: boolean;
}

export interface EsmExternalMasterDataDetailsState {
    formValues: EsmExternalFormValues;
    bankDetails: EsmExternalBankDetails;
    messages: EsmMessagesModel;
}
export interface EsmExternalBankDetails {
    paging: PagingFilter;
    sorting: SortingFilter;
    count: number;
    loading: boolean;
    bankDetails: EsmBankDetails[];
}
export interface EsmExternalMasterDataState {
    presetValues: EsmExternalMasterDataPresetValues;
    upload: {
        loading: boolean;
    };
    tableData: EsmExternalMasterDataTableState;
    details: EsmExternalMasterDataDetailsState;
    isDetailsVisible: boolean;
    isNewRecord: boolean;
    dialog: fromCommon.DialogState<ESM_MD_DIALOGS>;
    messages: Message[];
}

export const initialTableState: EsmExternalMasterDataTableState = {
    data: {
        values: [],
        count: 0,
        currentPage: 0
    },
    paging: {
        page: 0,
        entriesPerPage: 25
    },
    sorting: {
        columnName: 'eic',
        sortOrder: 'ASCENDING'
    },
    searchToken: null,
    loading: false
};

export const initialBankDetails: EsmExternalBankDetails = {
    bankDetails: null,
    paging: {
        page: 0,
        entriesPerPage: 25
    },
    sorting: {
        columnName: 'currency',
        sortOrder: 'ASCENDING'
    },
    count: 0,
    loading: false
};

export const initialDetailsState: EsmExternalMasterDataDetailsState = {
    formValues: null,
    bankDetails: initialBankDetails,
    messages: {
        masterData: [],
        bankDetails: []
    }
};

export const initialState: EsmExternalMasterDataState = {
    presetValues: null,
    upload: {
        loading: false
    },
    tableData: initialTableState,
    details: initialDetailsState,
    isDetailsVisible: false,
    isNewRecord: false,
    dialog: null,
    messages: []
};

export const baseEsmExternalMasterDataReducer = createReducer<EsmExternalMasterDataState, fromMDActions.MasterDataAction>(
    initialState,
    on(fromMDActions.loadPresetValuesSuccess, (state, { payload }) => ({
        ...state,
        presetValues: payload
    })),
    on(fromMDActions.setMessages, (state, {payload}) => ({
        ...state,
        messages: payload
    })),
    on(fromMDActions.setDetailsMessages, (state, {payload}) => ({
        ...state,
        details: {
            ...state.details,
            messages: payload
        }
    })),
    on(fromMDActions.loadTableDataSuccess, (state, {payload}) => ({
        ...state,
        tableData: {
            ...state.tableData,
            ...payload,
            loading: false,
        }
    })),
    on(fromMDActions.loadPresetValues, (state) => ({
        ...state
    })),
    on(fromMDActions.loadTableData, (state) => ({
        ...state,
        tableData: {
            ...state.tableData,
            loading: true
        }
    })),
    on(fromMDActions.uploadCsv, (state) => ({
        ...state,
        upload: { loading: true }
    })),
    on(fromMDActions.uploadCsvSuccess, (state) => ({
        ...state,
        upload: { loading: false }
    })),
    on(fromMDActions.pagingAndSorting, (state, { payload }) => ({
        ...state,
        tableData: {
            ...state.tableData,
            paging: payload.paging,
            sorting: payload.sorting
        }
      })),
      on(fromMDActions.setSearchToken, (state, { payload }) => ({
        ...state,
        tableData: {
            ...state.tableData,
            searchToken: payload
        }
      })),
    on(fromMDActions.cleanState, () => ({
        ...initialState
    })),
    on(fromMDActions.clearDetails, (state) => ({
        ...state,
        details: initialDetailsState,
        isDetailsVisible: false,
        isNewRecord: false
    })),
    on(fromMDActions.setTableLoading, (state, {payload}) => ({
        ...state,
        tableData: {
            ...state.tableData,
            loading: payload
        }
    })),
    on(fromMDActions.setUploadLoading, (state, {payload}) => ({
        ...state,
        upload: {
            loading: payload
        }
    })),
    on(fromMDActions.setBankDetailsLoading, (state, {payload}) => ({
        ...state,
        details: {
            ...state.details,
            bankDetails: {
                ...state.details.bankDetails,
                loading: payload
            }
        }
    })),
    on(fromMDActions.loadFormValuesSuccess, (state, { payload }) => ({
        ...state,
        isDetailsVisible: true,
        details: {
            formValues: payload,
            bankDetails: state.details.bankDetails,
            messages: state.details.messages
        }
    })),
    on(fromMDActions.createNewRecord, (state) => ({
        ...state,
        isDetailsVisible: true,
        isNewRecord: true,
        details: {
            ...state.details,
            formValues: {...initialFormValues},
        }
    })),
    on(fromMDActions.updateSuccessAction, (state, {payload }) => ({
        ...state,
        details: {
            formValues: payload,
            bankDetails: state.details.bankDetails,
            messages: state.details.messages
        }
    })),
    on(fromMDActions.persistSuccessAction, (state, {payload }) => ({
        ...state,
        isNewRecord: false,
        details: {
            formValues: payload,
            bankDetails: state.details.bankDetails,
            messages: state.details.messages
        }
    })),
    on(fromMDActions.loadBankDetailsAction, (state) => ({
        ...state,
        details: {
            ...state.details,
            bankDetails: {
                ...state.details.bankDetails,
                loading: true
            }
        }
    })),
    on(fromMDActions.loadBankDetailsSuccessAction, (state, { payload }) => ({
        ...state,
        details: {
            bankDetails: {
                bankDetails: payload.values,
                paging: {
                    ...state.details.bankDetails.paging,
                    page: payload.currentPage
                },
                sorting: state.details.bankDetails.sorting,
                count: payload.count,
                loading: false
            },
            formValues: state.details.formValues,
            messages: state.details.messages
        }
    })),
    on(fromMDActions.bankDetailsPagingAndSorting, (state, { payload }) => ({
        ...state,
        details: {
            bankDetails: {
                ...state.details.bankDetails,
                paging: payload.paging,
                sorting: payload.sorting
            },
            formValues: state.details.formValues,
            messages: state.details.messages
        }
    }))
);

export const esmExternalMasterDataReducer =
  fromCommon.withDialog<ESM_MD_DIALOGS, EsmExternalMasterDataState>(
      {
        OPEN_DIALOG: fromMDActions.openDialogAction.type,
        UPDATE_DIALOG: fromMDActions.updateDialogAction.type,
        CLOSE_DIALOG: fromMDActions.closeDialogAction.type,
        SET_TO_DEFAULT: fromMDActions.setToDefaultAction.type
      }
  )(baseEsmExternalMasterDataReducer);

export function initEsmMasterDataReducer (
    state: EsmExternalMasterDataState = { ...initialState},
    action: fromMDActions.MasterDataAction) {
        return {
            ...esmExternalMasterDataReducer(state, action)
        };
    }
