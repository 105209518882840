<h1>Sending Failures ({{(organisationService.getChangeOrganisationObservable() | async)?.displayName ?
  (organisationService.getChangeOrganisationObservable() | async)?.displayName : 'all organisations'}})</h1>
<section class="panel-box">
  <div class="col-12 ps-0">
    <p-messages [value]="messages$ | async" [closable]="true"></p-messages>
    <div *ngIf="(data$ | async)?.values" class="col-12 info-panel margin-bottom-9">
      <div class="fa fa-info-circle float-left"></div>
      <div class="margin-left-18">
        {{(data$ | async)?.values.length}} failures found.
      </div>
    </div>
    <cms-sending-failures-filter [filters]="filters$ | async"
                                 (search)="onSearch($event)"
                                 (refresh)="onRefresh()">
    </cms-sending-failures-filter>
  </div>
  <div *ngIf="initializing$ | async" class="margin-3">
    <i class="pi-spin pi pi-spinner"></i>
  </div>
  <cms-datatable *ngIf="(data$ | async)?.values"
                 [tableColumns]="tableColumns"
                 [data]="(data$ | async)?.values"
                 [autoLayout]="true"
                 [scrollable]="false"
                 [lazy]="false"
                 [paginator]="false"
                 [loading]="loadingTable$ | async"
                 class="datatable"
                 selectionMode="single">
    <ng-template pTemplate="caption">
      <div class="text-end">
        <span>
          Select
          <a id="sending-failures-select-all" (click)="onToggleSelectAll(true)">ALL</a> |
          <a id="sending-failures-select-none" (click)="onToggleSelectAll(false)">NONE</a>
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="body" let-row="row" let-column="column" let-rowIndex="rowIndex">
      <td *ngIf="column.type === 'button'" [ngStyle]="column.style">
        <div class="btn-wrapper">
          <button pButton
                  class="btn btn-icon"
                  icon="fa fa-info-circle"
                  type="button"
                  title="Details"
                  id="sending-failures-details-btn-{{rowIndex}}"
                  (click)="onDetailing(row.errorDetails)">
          </button>
        </div>
      </td>
      <td *ngIf="column.type === 'check'" [ngStyle]="column.style">
        <div class="btn-wrapper">
          <input type="checkbox"
                 class="btn"
                 id="sending-failures-checkbox-{{rowIndex}}"
                 (click)="onCheck($event.target.checked, row, rowIndex)"
                 [checked]="(selectedItems$ | async)[rowIndex] === row">
        </div>
      </td>
    </ng-template>
  </cms-datatable>
  <div class="btn-wrapper text-end margin-9" *ngIf="(data$ | async)?.values?.length">
    <button pButton
            class="btn"
            type="button"
            label="Resend"
            title="Resend"
            id="sending-failures-resend-btn"
            [disabled]="isSelected$ | async"
            (click)="onResend()">
    </button>
  </div>
</section>
<cms-info-dialog *ngIf="(dialog$ | async)?.name === 'INFO'"
                 [display]="(dialog$ | async)?.name === 'INFO'"
                 [errorDetails]="(dialog$ | async)?.data"
                 (close)="onCloseDialog()">
</cms-info-dialog>
