import { createSelector } from '@ngrx/store';
import { getAdminState, State } from '../reducers';
import { UserAccountsState } from './user-account.reducer';
import { DatatableState } from '../datatable/datatable.reducer';
import * as fromDatatable from '../datatable/datatable.reducer';
import * as fromOrgGroups from '../organisation-group/organisation-group.reducer';

export const getUserAccountState = createSelector(getAdminState, (state: State) => state.userAccount);
export const getDatatable = createSelector(getUserAccountState, (userAccount: UserAccountsState): DatatableState => userAccount.datatable);
export const getOrganisationId = createSelector(getUserAccountState, (state: UserAccountsState) => state.organisationId);
export const getDialog = createSelector(getUserAccountState, (state: UserAccountsState) => state.dialog);
export const getFilters = createSelector(getDatatable, (state: DatatableState) => state.filters);
export const getOrgGroups = createSelector(getUserAccountState, (state: UserAccountsState) => state.orgGroups);

export const getData = createSelector(getDatatable, fromDatatable.getData);
export const getSorting = createSelector(getDatatable, fromDatatable.getSorting);
export const getPaging = createSelector(getDatatable, fromDatatable.getPaging);
export const getLoading = createSelector(getDatatable, fromDatatable.getLoading);
export const getEditableData = createSelector(getDatatable, fromDatatable.getEditableData);
export const getMessages = createSelector(getDatatable, fromDatatable.getMessages);
export const getSelected = createSelector(getDatatable, fromDatatable.getSelected);
export const getOrgGroupsList = createSelector(getOrgGroups, fromOrgGroups.getOrgGroupsList);
export const getOrgGroupSelectorVisible = createSelector(getOrgGroups, fromOrgGroups.getOrgGroupsVisible);
