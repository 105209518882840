<p-panel class="status-panel" *ngIf="userInformations" [showHeader]="false">

  <div class="flex justify-content-between mb-2">
    <div><span id="status-user-info" class="user-title">Welcome {{getUserInformations()}}</span></div>
    <div [ngClass]="{error: isShowPasswordExpiresWarning() }">
            <span class="user-password-expires">
                Your password expires in <strong>{{ userInformations.passwordExpiration }}</strong> {userInformations.passwordExpiration, plural, =1 {day} other {days}} <a (click)="onChangePassword()">(change)</a>
            </span>
    </div>
  </div>

  <div class="flex justify-content-between">
    <div class=""><span id="status-password-info" class="user-lastlogin">Last login: {{ (getUserInformationsDate() | dateFormatter:'timestamp') || "the first time"}}</span></div>
    <div class=""><span id="status-system-info" class="system-information text-small">{{ getInstanceName() }} {{ getVersion() }} ({{ getRevisionNumber() }})</span></div>
  </div>

</p-panel>
