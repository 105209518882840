import { createSelector } from '@ngrx/store';
import { getAdminState, State } from '../reducers';
import { ElcomWhitelistState } from './elcom-whitelist.reducers';
import * as fromDatatable from '../datatable/datatable.reducer';

export const getElcomWhitelistState = createSelector(getAdminState, (state: State) => state.elcomWhitelist);
export const getDatatable = createSelector(getElcomWhitelistState, (state: ElcomWhitelistState): fromDatatable.DatatableState => state.datatable);
export const getOrganisationId = createSelector(getElcomWhitelistState, (state: ElcomWhitelistState) => state.organisationId);
export const getFilters = createSelector(getDatatable, (state: fromDatatable.DatatableState) => state.filters);
export const getAcerCodeValue = createSelector(getElcomWhitelistState, (state: ElcomWhitelistState) => state.acerCodeValue);

export const getData = createSelector(getDatatable, fromDatatable.getData);
export const getSorting = createSelector(getDatatable, fromDatatable.getSorting);
export const getPaging = createSelector(getDatatable, fromDatatable.getPaging);
export const getLoading = createSelector(getDatatable, fromDatatable.getLoading);
export const getMessages = createSelector(getDatatable, fromDatatable.getMessages);
