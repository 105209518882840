import { Action } from '@ngrx/store';
import { Message } from 'primeng/api';
import { DialogState } from './dialog.reducers';

export const OPEN_DIALOG = '[ECM DASHBOARD] Open Dialog';
export const CLOSE_DIALOG = '[ECM DASHBOARD] Close Dialog';
export const UPDATE_DIALOG = '[ECM DASHBOARD] Update Dialog';

export class OpenDialogAction implements Action {
  readonly type = OPEN_DIALOG;
  constructor(public payload: DialogState) { }
}

export class CloseDialogAction implements Action {
  readonly type = CLOSE_DIALOG;
}

export class UpdateDialogAction implements Action {
  readonly type = UPDATE_DIALOG;
  constructor(public payload: Message[]) { }
}

export type DialogActions = OpenDialogAction | CloseDialogAction | UpdateDialogAction;
