import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from './authentication.service';
import { OrganisationService } from '../common/organisation.service';

@Injectable()
export class AuthorisationGuard  {

  constructor(private authService: AuthenticationService,
              private organisationService: OrganisationService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const path = state.url.startsWith('/') ? state.url.substr(1) : state.url;
    return this.authService.authorise(path, this.organisationService.getCachedOrganisationId()).then(authorised => {
      if (!authorised) {
        this.router.navigate(['/'], { queryParamsHandling: 'preserve' });
      }
      return authorised;
    });
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.canActivate(route, state);
  }

}
