import { ErrorResponse } from '@common/error-response.model';

export interface PresetValuesObj extends ErrorResponse {
  value: PresetValues;
}

export interface PresetValues {
  toggleNettingAllowed: boolean;
  toggleCentralNettingAllowed: boolean;
  toggleCrossCommodityNettingAllowed: boolean;
  nettingSupported: boolean;
  centralNettingSupported: boolean;
  crossCommodityNettingSupported: boolean;
  downloadAllowed: boolean;
  generationDatePhysical: number;
  generationDateFinancial: number;
  vatIds: VatIds[];
  searchString: string;
}

export interface VatIds {
  masterDataId: number;
  vatId: string;
}

export const defaultStyles = {
  'text-align': 'left',
  'text-overflow': 'ellipsis',
  'overflow': 'hidden',
  'white-space': 'nowrap'
};

export const emptyData = {
  values: null,
  count: 25,
  currentPage: 0
};

// Defined in https://confluence.ponton.de/pages/viewpage.action?spaceKey=CG&title=eSM+Settings+-+Support+of+Netting
export const PhysicalBusinessOffsetRange = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
  { label: '11', value: 11 },
  { label: '12', value: 12 },
  { label: '13', value: 13 },
  { label: '14', value: 14 },
  { label: '15', value: 15 },
  { label: '16', value: 16 }
];

export const FinancialBusinessOffsetRange = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 }
];


export interface NettingSettingsModel {
  vatNumber: string;
  cpEicCode: string;
  cpVatNumber: string;
  cpLegalName: string;
  agreement: boolean;
  cpAgreement: boolean;
  organisationId: number;
  cpOrganisationId: number;
  masterDataId: number;
  cpMasterDataId: number;
}

export interface NettingSettingsActivateBlockRequestModel {
  agreement: boolean;
  cpMasterDataId: number;
  cpOrganisationId: number;
  masterDataId: number;
  organisationId: number;
}

export const esmNettingSettingsColumns = [
  { name: 'vatNumber', type: 'text', title: 'Our VAT', sortable: true, visible: true, style: { ...defaultStyles } },
  { name: 'cpEicCode', type: 'text', title: 'CP EIC Code', sortable: true, visible: true, style: { ...defaultStyles } },
  { name: 'cpVatNumber', type: 'text', title: 'CP VAT ID', sortable: true, visible: true, style: { ...defaultStyles } },
  { name: 'cpLegalName', type: 'text', title: 'CP Legal Name', sortable: true, visible: true, style: { ...defaultStyles } },
  { name: 'agreement', type: 'toggleButton', title: `Agreement \n CC Netting`, sortable: true, visible: true, style: { ...defaultStyles, 'text-align': 'center' } },
  { name: 'cpAgreement', type: 'text', title: 'CP Agreement CC Netting', sortable: true, visible: true, style: { ...defaultStyles } },
  { name: 'cpOrganisationId', type: 'text', title: 'CP_ORG_ID', sortable: true, visible: false, style: { ...defaultStyles } },
  { name: 'masterDataId', type: 'text', title: 'OWN_ESM_MD_ID', sortable: true, visible: false, style: { ...defaultStyles } },
  { name: 'cpMasterDataId', type: 'text', title: 'CP_ESM_MD_ID', sortable: true, visible: false, style: { ...defaultStyles } },
  { name: 'organisationId', type: 'text', title: 'organisationId', sortable: true, visible: false, style: { ...defaultStyles } },
];

export const URL_GATEKEEPER_NETTING = 'api/esm/netting/gatekeeper';
