import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { RemitTransactionsService } from '../datatable/remit-transactions.service';

@Component({
  selector: 'cms-remit-transactions-panel',
  templateUrl: './remit-transactions-panel.component.html',
  styleUrls: ['./remit-transactions-panel.component.scss']
})
export class RemitTransactionsPanelComponent implements OnInit, OnDestroy {
  @Output() onTabChange = new EventEmitter<string>();
  @Output() onInitialDocType = new EventEmitter<string>();
  activeIndexSubject$ = new BehaviorSubject<number>(0);
  tabDocTypes: string[] = ['table1', 'table2', 'table3', 'table4', 'fundamentals'];
  tableDoctypeMap = {
    'table1': 'REMIT_TABLE_1',
    'table2': 'REMIT_TABLE_2',
    'table3': 'ELECTRICITY_RIGHTS',
    'table4': 'GAS_CAPACITY',
    'fundamentals': 'FUNDAMENTALS'
  };
  selectedTabDocType: string;
  private activeIndexSubscription$: Subscription;

  constructor(private router: Router, private transactionService: RemitTransactionsService) { }

  ngOnInit(): void {
    this.setIndexByUrl(this.router.url);
    this.emitInitialTabDocType(this.router.url);
    this.activeIndexSubscription$ =
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => this.setIndexByUrl(event.url));
  }

  ngOnDestroy(): void {
    if (this.activeIndexSubscription$ != null) {
      this.activeIndexSubscription$.unsubscribe();
    }
  }

  setCategoryTab(event: any): void {
    this.transactionService.setValidationMessages([]);
    this.setActiveTabData(event.index);
    this.onTabChange.emit(this.tableDoctypeMap[this.selectedTabDocType]);
    this.router.navigate([`/err/remit_transactions/${this.selectedTabDocType.toLowerCase()}`]);
  }

  private setIndexByUrl(url: string): void {
    const index = this.calcActiveTabIndex(url);
    this.setActiveTabData(index);
  }

  get activeIndex(): number {
    return this.activeIndexSubject$.getValue();
  }

  private setActiveTabData(index: number): void {
    this.activeIndexSubject$.next(index);
    this.selectedTabDocType = this.tabDocTypes[index];
  }

  private emitInitialTabDocType(url: string) {
    const index = this.calcActiveTabIndex(url);
    this.onInitialDocType.emit(this.tableDoctypeMap[this.tabDocTypes[index]]);
  }


  private calcActiveTabIndex(url: string): number {
    return this.tabDocTypes.reduce((activeIndex, category, arrIndex) => {
      return new RegExp('\/' + category + '$', 'i').test(url) ? arrIndex : activeIndex;
    }, 0);
  }
}
