import { Injectable } from '@angular/core';
import { ApiRequestService } from '../../../../common/api-request.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { SubmitRemitTable1CancellationRequestModel } from './remit-table1-trade.model';
import { EndPointConstants } from '../../../../common/end-point-constants';
import { TransactionSheetResponseModel } from '../../../../common/sheet/transaction-sheet-model';
import { map } from 'rxjs/operators';

@Injectable()
export class RemitTable1TradeService {

  tradeSubject = new BehaviorSubject<TransactionSheetResponseModel>(null);

  constructor(private apiRequestService: ApiRequestService) {
  }

  public loadPresetsCancellation(recordType: string, historyId: number): Observable<any> {
    let params = new HttpParams()
      .append('recordType', recordType);

    if (historyId) {
      params = params.append('historyId', historyId.toString());
    }

    return this.apiRequestService.get('/api/err/remit/lite/remitTable1/loadPresetsCancellation', params)
      .pipe(map(data => {
        return data;
      }));
  }

  public loadPresetsDeletion(recordType: string, historyId: number): Observable<any> {
    let params = new HttpParams() //
      .append('recordType', recordType);

    if (historyId) {
      params = params.append('historyId', historyId.toString());
    }

    return this.apiRequestService.get('/api/err/remit/lite/remitTable1/loadPresetsDeletion', params)
      .pipe(map(data => {
        return data;
      }));
  }

  public submitCancellation(recordType: string, historyId: number, searchQuery: SubmitRemitTable1CancellationRequestModel): Observable<any> {
    const params = new HttpParams() //
      .append('recordType', recordType) //
      .append('historyId', historyId.toString());

    return this.apiRequestService.post('/api/err/remit/lite/remitTable1/cancellation', searchQuery, params)
      .pipe(map(data => {
        return data;
      }));
  }

  public submitError(recordType: string, historyId: number): Observable<any> {
    const params = new HttpParams() //
      .append('recordType', recordType) //
      .append('historyId', historyId.toString());

    return this.apiRequestService.post('/api/err/remit/lite/remitTable1/deletion', null, params)
      .pipe(map(data => {
        return data;
      }));
  }

  public getTransactionSheet(regime: string, recordType: string, documentType: string, historyId: number): Observable<TransactionSheetResponseModel> {
    let params = new HttpParams();
    params = params.append(EndPointConstants.PARAM_REGIME, regime);
    if (recordType) {
      params = params.append(EndPointConstants.PARAM_RECORD_TYPE, recordType);
    }
    params = params.append(EndPointConstants.PARAM_DOCUMENT_TYPE, documentType);
    params = params.append(EndPointConstants.PARAM_HISTORY_ID, String(historyId));

    return this.apiRequestService.get('/api/err/remit/transactions/sheet', params).pipe(map(response => {
      this.tradeSubject.next(response);
      return response;
    }));
  }

  public onTradeEntryLoaded(): Observable<TransactionSheetResponseModel> {
    return this.tradeSubject.asObservable();
  }

}
