<form class="row margin-0 lign-items-center" [formGroup]="form">
  <input class="col-lg-6 col-12 margin-3 delivery-points__input p-component padding-6 text-medium"
         type="text"
         formControlName="filter"
         id="filter"
         [placeholder]="placeholder"
         [maxlength]="maxLength">
  <div class="col-auto btn__container">
    <button class="btn margin-top-3 margin-bottom-3 margin-left-12" type="submit" (click)="onFilter()">Filter</button>
    <button class="btn margin-top-3 margin-bottom-3 margin-left-12" type="button" (click)="onReset()">Reset</button>
  </div>
</form>
