import { createSelector } from '@ngrx/store';
import { getEcmState, State } from '../reducers';
import * as fromReducers from './ecm-products.reducers';

export const getProductState = createSelector(getEcmState, (state: State) => state.products);
export const getCommodities = createSelector(getProductState, fromReducers.getCommodities);
export const getMarkets = createSelector(getProductState, fromReducers.getMarkets);
export const getTransactionTypes = createSelector(getProductState, fromReducers.getTransactionTypes);
export const getMessages = createSelector(getProductState, fromReducers.getMessages);
export const getAddPanel = createSelector(getProductState, fromReducers.getAddPanel);
export const getData = createSelector(getProductState, fromReducers.getData);
export const getLoading = createSelector(getProductState, fromReducers.getLoading);


