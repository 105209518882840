import { Component, OnDestroy, OnInit } from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { Message } from 'primeng/api';
import { Column } from '@common/shared/datatable.model';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AggregationKeys, EsmNettedInvoices, INVOICE_FINDER_DIALOGS, NettedInvoicesRequestModel } from './netted-invoices.model';
import { InvoicesTableState } from '../state/netted-invoices/invoices-table.reducer';
import { DatatableState } from '@common/datatable/datatable.model';
import { AddNote, NotesCellViewEvent } from '@common/deals/deals.model';
import { ErrorMessageService } from '@common/errors/error-message.service';
import * as fromCommon from '@common/state/reducers';
import * as fromActions from '../state/netted-invoices/invoices-table.actions';
import * as fromSelectors from '../state/netted-invoices/invoices-table.selectors';
import * as fromDialogActions from './../state/dialog/dialog.actions';
import { filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

//

@Component({
  selector: 'cms-netted-invoices',
  templateUrl: './netted-invoices.component.html',
  styleUrls: ['./netted-invoices.component.scss']
})
export class NettedInvoicesComponent implements OnInit, OnDestroy {

  header$: Observable<string> = this.store.pipe(select(fromSelectors.getHeader));
  selectedIds$: Observable<string[]> = this.store.pipe(select(fromSelectors.getSelectedIds));
  loading$: Observable<boolean> = this.store.pipe(select(fromSelectors.getLoading));
  messages$: Observable<Message[]> = this.store.pipe(select(fromSelectors.getMessages));
  columns$: Observable<Column[]> = this.store.pipe(select(fromSelectors.getColumns), filter(x => x.length > 0));
  data$: Observable<DatatableData<EsmNettedInvoices[]>> = this.store.pipe(select(fromSelectors.getData));
  sorting$: Observable<SortingFilter> = this.store.pipe(select(fromSelectors.getSorting));
  paging$: Observable<PagingFilter> = this.store.pipe(select(fromSelectors.getPaging));
  selected$: Observable<EsmNettedInvoices[]> = this.store.pipe(select(fromSelectors.getSelectedInvoices));
  dialog$: Observable<fromCommon.DialogState<INVOICE_FINDER_DIALOGS>> = this.store.pipe(select(fromSelectors.getDialog));
  subscription$: Subscription = new Subscription();
  private selectedInvoices: EsmNettedInvoices[] = [];

  constructor(private router: Router,
              private route: ActivatedRoute,
              private store: Store<InvoicesTableState>,
              public errorMessageService: ErrorMessageService,
              private titleService: Title) {
    this.titleService.setTitle('Invoice Details');
  }

  ngOnInit(): void {

    const routeSubscription = this.route.queryParams.subscribe((params: Params) => {
      const requestModel: NettedInvoicesRequestModel = {
        caseNumber: params['caseNumber'],
        counterPartyId: params['counterpartyId'],
        cpVAT: params['cpVAT'],
        dueDate: params['invoicePaymentDate'],
        myVAT: params['myVAT'],
        ...this.splitAggregationKeys(params['nettingAgreement'] || '', params['commodities'] || ''),
      };
      this.store.dispatch(fromActions.setHeaderAction(this.getHeaderString(params['rowName'], params['columnName'], params['counterparty'], params['nettingAgreement'])));
      this.store.dispatch(fromActions.setRequestModelAction(requestModel));
      this.store.dispatch(fromActions.loadDataAction());
    });

    this.subscription$.add(routeSubscription);
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
    this.selectedInvoices = [];
  }

  onLazyLoad(state: DatatableState): void {
    this.store.dispatch(fromActions.sortAndPageDataAction({
      paging: {
        page: state.page,
        entriesPerPage: state.perPage
      },
      sorting: {
        columnName: state.sortField,
        sortOrder: state.sortOrder
      }
    }));
  }

  onExport(): void {
    this.store.dispatch(fromActions.exportAction());
  }

  onSelected(invoices: EsmNettedInvoices[]) {
    this.selectedInvoices = invoices;
    let confirmationIds: string[] = [];
    if (invoices) {
      confirmationIds = invoices.map(d => d.id + '');
    }
    this.store.dispatch(fromActions.setSelectedIdsAction({ confirmationIds }));
    this.store.dispatch(fromActions.setSelectedInvoicesAction({ invoices }));
  }

  onConfirmAddNotes(obj: AddNote): void {
    this.store.dispatch(fromActions.addNoteAction(obj));
  }

  onCancelAddNotes() {
    this.store.dispatch(fromDialogActions.closeDialogAction());
  }

  onCancelViewNotes(): void {
    this.store.dispatch(fromDialogActions.closeDialogAction());
  }

  onOpenAddNoteDialog(): void {
    this.store.dispatch(fromDialogActions.openDialogAction({
      name: 'ADD_NOTES',
      messages: [],
    }));
  }

  onViewNotes(data: NotesCellViewEvent): void {
    this.store.dispatch(fromDialogActions.openDialogAction({
      name: 'VIEW_NOTES',
      messages: [],
      data
    }));
  }

  onClickCustomize(): void {
    this.router.navigate(['/settings/datatable/customize'], { queryParams: { documentType: 'ESM_INVOICE' } });
  }

  onDownloadXml({ id }: EsmNettedInvoices): void {
    this.store.dispatch(fromActions.downloadXmlAction(id));
  }

  onDownloadPdf({ id }: EsmNettedInvoices): void {
    this.store.dispatch(fromActions.downloadPdfAction(id));
  }

  getDisplayParams({ id, documentId }: EsmNettedInvoices): object {
    return {
      displayValue: documentId,
      urlPart: 'esm/invoice',
      invoiceId: id
    };
  }

  splitAggregationKeys = (aggregationKeysString: string, commodities: string): AggregationKeys => {
    // eg 'PHYSICAL/Power/EUR/EFET 2019'
    const aggregationKeysArray: string[] = aggregationKeysString.split('/');
    const fullAgreement: string = aggregationKeysArray[aggregationKeysArray.length - 1];

    const indexOfLastSpaceInAgreement: number = fullAgreement.trim().lastIndexOf(' ');

    return {
      physicalFinancial: aggregationKeysArray[0] as 'PHYSICAL' | 'FINANCIAL',
      commodities: commodities.replace(/ /g,'').split(','),
      currency: aggregationKeysArray[aggregationKeysArray.length - 2],
      agreement: fullAgreement.substr(0, indexOfLastSpaceInAgreement).trim(),
      masterAgreementVersion: fullAgreement.substr(indexOfLastSpaceInAgreement).trim(),
    };
  }

  getHeaderString(rowName: string, columnName: string, counterparty: string, nettingAgreement: string): string {
    let ret = counterparty + ' for ' + nettingAgreement;

    switch (columnName) {
      case 'Our Netting Calculation':
        ret = 'Our invoices with ' + ret;
        break;
      case 'CP Netting Calculation':
        ret += ' invoices with us';
        break;
      case 'Our Open To Date':
        ret = 'Our open invoices with ' + ret;
        break;
      case 'Our Agreed To Date':
        ret = 'Our agreed invoices with ' + ret;
        break;
      case 'Our Disputed To Date':
        ret = 'Our disputed invoices with ' + ret;
        break;
    }

    switch (rowName) {
      case 'To Receive':
        ret += columnName === 'CP Netting Calculation' ? ', us to pay' : ', us to receive';
        break;
      case 'To Pay':
        ret += columnName === 'CP Netting Calculation' ? ', us to receive' : ', us to pay';
        break;
      case 'Overall':
        ret += ' overall';
        break;
    }

    return ret;
  }

}
