<p-panel *ngIf="supportMessage" header="{{supportMessage.title}}">
  <div class="padding-6" [ngClass]="{'editable': (supportMessage$ | async).editable}" *ngIf="isDisplayMode | async">
    <span class="edit-content-icon fa fa-pencil" *ngIf="(supportMessage$ | async).editable" (click)="switchEditMode()"></span>
    <markdown [data]="(supportMessage$ | async).content"></markdown>
  </div>
  <cms-help-text-editor [state]="'SUPPORT'" (canceled)="switchEditMode()"
                        [content]="(supportMessage$ | async).content"
                        [title]="(supportMessage$ | async).title"
                        *ngIf="isEditMode | async"></cms-help-text-editor>
</p-panel>
