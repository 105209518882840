import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

import { PasswordService } from './password.service';

@Injectable()
export class PasswordGuard  {

  constructor(private passwordService: PasswordService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const params = route.queryParams;
    if (params.hash) {
      return this.passwordService.isResetPasswordAllowed(params.hash);
    } else {
      this.router.navigate(['/']);
      return of(false);
    }
  }

}
