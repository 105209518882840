import { Injectable } from '@angular/core';
import { ApiRequestService } from '@common/api-request.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { catchError, switchMap } from 'rxjs/operators';
import { Message } from 'primeng/api';
import { of } from 'rxjs';
import * as NttTableActions from '../netting-table/netting-table.actions';
import { GenerationDialogState } from './generation-dialog.reducers';
import * as GenerationDialogActions from './generation-dialog.actions';
import * as InvoiceActions from '../invoice-overview/invoice-overview.actions';
import { NettingTableState } from '../netting-table/netting-table.reducers';

@Injectable()
export class GenerationDialogEffects {
  constructor(private apiGateway: ApiRequestService,
              private actions$: Actions,
              private store1$: Store<GenerationDialogState>,
              private store2$: Store<NettingTableState>,
              private mapper: MessagesMapperService) {
  }

  private baseUrl = '/api/esm/netting/preview';

  check_condition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GenerationDialogActions.checkCondition),
      switchMap(({ payload }) =>
        this.apiGateway.post(`${this.baseUrl}/data/confirmGeneration`, createRequest(payload))
          .pipe(
            switchMap(response => {
              const messages: Message[] = this.mapper.toErrorMessages(response);

              return messages?.length ?
                [
                  NttTableActions.setMessages({ messages }),
                  InvoiceActions.setAllowDialog(false)
                ] :
                [
                  GenerationDialogActions.setConfirmationGeneration(response.value),
                  InvoiceActions.setAllowDialog(true)
                ];
            }),

            catchError(error => of(NttTableActions.setMessages(this.mapper.toErrorMessagesObj(error.errorMessage || 'Server Error')))
            ))
      ))
  );

  generate_netting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GenerationDialogActions.generateNetting),
      switchMap(({ selected }) =>
        this.apiGateway.post(`${this.baseUrl}/data/generate`, createRequest(selected))
          .pipe(
            switchMap(response => {
              const messages: Message[] = this.mapper.toErrorMessages(response);

              return messages?.length ?
                [NttTableActions.setMessages({ messages })] :
                [
                  NttTableActions.setMessages({ messages: this.mapper.toSuccessMessages('Successfully generated netting statement: ' + response.value.documentId + ', ' + response.value.documentVersion) })
                ];
            }),
            catchError(error => of(NttTableActions.setMessages(this.mapper.toErrorMessagesObj(error.errorMessage || 'Server Error')))
            ))
      ))
  );
}

const createRequest = ({ myVAT, cpVAT, counterPartyId, nettingAgreement, dueDate, commodities}) => ({
  myVAT,
  cpVAT,
  counterPartyId,
  nettingAgreement,
  dueDate,
  commodities
});
