import { DealFinderPropertiesFilters, EcmMyFilter, EcmMyFiltersResponse, } from './deal-finder/deal-finder.model';
import { EcmDashboardPropertiesFilters } from './ecm-dashboard/ecm-dashboard.model';
import { MyFilter } from '@common/my-filters/my-filters.model';
import { emptyDealsValue, HiddenDeals } from '@common/deals/deals.model';

export type ReportType = 'billing' | 'ranking';

export const mapMyFilter = (state: {
                              broker: string[];
                              commodities: string[];
                              counterParties: string[];
                              markets: string[];
                              transactionTypes: string[];
                            },
                            filter: EcmMyFilter): EcmMyFilter =>{
  const {
    filterName,
    defaultFilter,
    counterpartyFilter,
    brokerFilter,
    hiddenType,
    commodityFilter,
    marketFilter,
    transactionTypeFilter
  } = filter;
  return {
    filterName,
    defaultFilter,
    commodityFilter: commodityFilter && state.commodities ? commodityFilter.length === state.commodities.length ? null : commodityFilter : [],
    transactionTypeFilter: transactionTypeFilter && state.transactionTypes ? transactionTypeFilter.length === state.transactionTypes.length ? null : transactionTypeFilter : [],
    marketFilter: marketFilter && state.markets ? marketFilter.length === state.markets.length ? null : marketFilter : [],
    brokerFilter: brokerFilter && state.broker ? brokerFilter.length === state.broker.length ? null : brokerFilter : [],
    hiddenType,
    counterpartyFilter: counterpartyFilter && state.counterParties ? counterpartyFilter.length === state.counterParties.length ? null : counterpartyFilter : [],
  };
};

export const updateFilters = (
  filterValues: {
    broker: string[];
    commodities: string[];
    counterParties: string[];
    markets: string[];
    transactionTypes: string[];
  },
  propFilters: DealFinderPropertiesFilters | EcmDashboardPropertiesFilters,
  defaultFilter: EcmMyFilter): DealFinderPropertiesFilters | EcmDashboardPropertiesFilters => {
  let filters: DealFinderPropertiesFilters | EcmDashboardPropertiesFilters;
  if (!defaultFilter) {
    filters = {
      ...propFilters,
      brokers: filterValues.broker || [],
      commodities: filterValues.commodities || [],
      counterParties: filterValues.counterParties || [],
      markets: filterValues.markets || [],
      transactionTypes: filterValues.transactionTypes || [],
      hiddenDeals: HiddenDeals.EXCLUDE,
    };
  } else {
    filters = {
      ...propFilters,
      brokers: (defaultFilter.brokerFilter &&
        defaultFilter.brokerFilter.filter(item => filterValues.broker.includes(item))) || filterValues.broker,
      commodities: (defaultFilter.commodityFilter &&
        defaultFilter.commodityFilter.filter(item => filterValues.commodities.includes(item))) || filterValues.commodities,
      counterParties: (defaultFilter.counterpartyFilter &&
        defaultFilter.counterpartyFilter.filter(item => filterValues.counterParties.includes(item))) || filterValues.counterParties,
      markets: (defaultFilter.marketFilter &&
        defaultFilter.marketFilter.filter(item => filterValues.markets.includes(item))) || filterValues.markets,
      transactionTypes: (defaultFilter.transactionTypeFilter &&
        defaultFilter.transactionTypeFilter.filter(item => filterValues.transactionTypes.includes(item))) || filterValues.transactionTypes,
      hiddenDeals: defaultFilter.hiddenType || HiddenDeals.EXCLUDE
    };
  }

  return filters;
};

export const mapResponse = (response: EcmMyFiltersResponse) => {
  if (response.defaultFilter && response.defaultFilter.brokerFilter) {
    response.defaultFilter.brokerFilter = response.defaultFilter
      .brokerFilter.map(item => item || emptyDealsValue);
    if (response.filterSelections) {
      Object.entries(response.filterSelections).forEach(([,value ]: [string, EcmMyFilter]) => {
        if (value.brokerFilter) {
          value.brokerFilter = value.brokerFilter.map(item => item || emptyDealsValue);
        }
      });
    }
  }
};

export const getSaveReplaceMyFilter = (
  value: EcmDashboardPropertiesFilters | DealFinderPropertiesFilters,
  filter: MyFilter): EcmMyFilter => {
  const {
    brokers,
    commodities,
    counterParties,
    hiddenDeals,
    markets,
    transactionTypes,
  } = value;
  return {
    filterName: filter.filterName,
    defaultFilter: filter.defaultFilter,
    transactionTypeFilter: transactionTypes,
    marketFilter: markets,
    commodityFilter: commodities,
    hiddenType: hiddenDeals,
    brokerFilter: brokers,
    counterpartyFilter: counterParties
  };
};

export const shouldDisableSaveCurrent = (values: EcmDashboardPropertiesFilters | DealFinderPropertiesFilters,
                                         allDealsFilter: EcmMyFilter): boolean => {
  const {
    transactionTypes,
    markets,
    commodities,
    counterParties,
    brokers,
    hiddenDeals
  } = values;
  const {
    transactionTypeFilter,
    marketFilter,
    counterpartyFilter,
    commodityFilter,
    hiddenType,
    brokerFilter
  } = allDealsFilter;
  return transactionTypes && transactionTypes.length === transactionTypeFilter.length &&
    markets && markets.length === marketFilter.length &&
    commodities && commodities.length === commodityFilter.length &&
    counterParties && counterParties.length === counterpartyFilter.length &&
    brokers && brokers.length === brokerFilter.length &&
    hiddenType && hiddenType === hiddenDeals;
};

export const TitleMap = {
  'process': 'eCM Features Page',
  'products': 'Product Subscription Page',
  'brokerRestrictions': 'eCM Broker Restrictions Page'
};
