import { BehaviorSubject, Observable, of, throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { PagingFilter, SortingFilter } from '../../../../common/shared/results.model';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { ApiRequestService } from '../../../../common/api-request.service';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class RemitFilesOutService {

  private api_route: string = '/api/err/remit/filesout';
  private loading = new BehaviorSubject<boolean>(false);
  private searchQuery = new BehaviorSubject<RemitFilesOutSearchQuery>(null);

  constructor(private apiRequestService: ApiRequestService) {
  }

  public getLoading(): Observable<boolean> {
    return this.loading.asObservable();
  }

  public setLoading(value: boolean): void {
    this.loading.next(value);
  }

  public setSearchQuery(searchQuery: RemitFilesOutSearchQuery) {
    this.searchQuery.next(searchQuery);
  }

  public getSearchQuery(): RemitFilesOutSearchQuery {
    return this.searchQuery.getValue();
  }

  public onSearchQueryChange(): Observable<RemitFilesOutSearchQuery> {
    return this.searchQuery.asObservable();
  }

  public loadDocumentTypes(): Observable<string[]> {
    return this.apiRequestService.get(`${this.api_route}/documentTypes`, null).pipe(map(data => data.values));
  }

  public search(searchModel: RemitFilesOutSearchQuery): Observable<any> {
    this.setLoading(true);
    const req =  (searchModel) ? this.apiRequestService.post(`${this.api_route}/search`, searchModel, null) : of([]);
    return req.pipe(
      map((result) => {
        this.setLoading(false);
        return result;
    }),
    catchError(err => {
      this.setLoading(false);
      return err;
    }));
  }

  public downloadExcel(): Observable<any> {
    return of(false);
  }

  private handleError(error: any) {
    console.error(error);
    const errMsg = (error.message) ? error.message : 'Server error';
    return observableThrowError(errMsg);
  }
}

export class RemitFilesOutSearchQuery {

  documentSearchModel: ByDocumentSearchModel;

  sorting: SortingFilter;
  paging: PagingFilter;

  public static isNotEmptySearchQuery(query: RemitFilesOutSearchQuery): boolean {
    return query != null && JSON.stringify(query) !== JSON.stringify(RemitFilesOutSearchQuery.createEmptySearchQuery());
  }

  public static createEmptySearchQuery(): RemitFilesOutSearchQuery {
    return {
      documentSearchModel: new ByDocumentSearchModel(),

      paging: {
        entriesPerPage: 25,
        page: 0
      },
      sorting: {
        columnName: 'reportedTimestamp',
        sortOrder: 'DESCENDING',
      }
    };
  }

}

export class ByDocumentSearchModel {
  documentTypes: string[];
  submissionDate: string;
  searchAsRole: string;
}
