import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'cms-document-hidden-cell',
  templateUrl: './document-hidden-cell.component.html',
  styleUrls: ['./document-hidden-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentHiddenCellComponent implements OnInit, OnChanges {
  @Input() data: { hideCheckboxVisible: boolean, hidden: boolean, hideCheckboxEnabled: boolean };
  @Output() hide = new EventEmitter<{ hideCheckboxVisible: boolean, hidden: boolean, hideCheckboxEnabled: boolean }>();
  @Output() unhide = new EventEmitter<{ hideCheckboxVisible: boolean, hidden: boolean, hideCheckboxEnabled: boolean }>();
  form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    const {
      hidden: value,
      hideCheckboxEnabled
    } = this.data || {
      hidden: false,
      hideCheckboxEnabled: false
    };
    this.form = this.formBuilder.group({
      hidden: { value, disabled: !hideCheckboxEnabled },
    });
  }

  onChange({ target }): void {
    if (this.data && this.data.hideCheckboxVisible && this.data.hideCheckboxEnabled) {
      target.checked ? this.hide.emit(this.data) : this.unhide.emit(this.data);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && this.form) {
      const {
        hidden,
        hideCheckboxEnabled
      } = this.data;
      const control = this.form.controls['hidden'];
      control.patchValue({
        hidden
      });
      hideCheckboxEnabled ? control.enable() : control.disable();
    }
  }
}
