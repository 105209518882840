import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'cms-cpml-dealfinder-filter',
  templateUrl: './cpml-dealfinder-filter.component.html'
})
export class CpmlDealFinderFilterComponent {
  @Output() changeDatatableVisibility = new EventEmitter();

  changeDtVisibilityFilters(): void {
    this.changeDatatableVisibility.emit();
  }
}
