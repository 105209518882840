<form class="panel-box margin-bottom-15" [formGroup]="form">
  <fieldset>
    <label class="text-bold">Netting Statement Role</label>
    <div class="form-check">
      <input type="radio"
             class="form-check-input"
             (change)="handleChange()"
             id="esm-roleBy-all-radio"
             value=""
             formControlName="roleBy"
             name="roleBy" />
      <label class="form-check-label" for="esm-roleBy-all-radio">All</label>
    </div>
    <div class="form-check">
      <input type="radio"
             class="form-check-input"
             (change)="handleChange()"
             id="esm-roleBy-official-radio"
             value="{{EsmNettingRoleByType.OFFICIAL}}"
             formControlName="roleBy"
             name="roleBy" />
      <label class="form-check-label" for="esm-roleBy-official-radio">Official Documents</label>
    </div>
    <div class="form-check">
      <input type="radio"
             class="form-check-input"
             (change)="handleChange()"
             id="esm-roleBy-reverse-radio"
             value="{{EsmNettingRoleByType.REVERSE}}"
             formControlName="roleBy"
             name="roleBy" />
      <label class="form-check-label" for="esm-roleBy-reverse-radio">Reverse Documents</label>
    </div>
  </fieldset>
</form>
