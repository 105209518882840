import * as fromCommon from '@common/state/reducers';
import * as fromDialogActions from './dialog.actions';
import { MY_FILTERS_DIALOGS } from '@common/my-filters/my-filters.model';
import * as fromFiltersActions from '../../../netting/finder/esm-netting-finder.actions';

export type NETTING_STATEMENT_DIALOGS = MY_FILTERS_DIALOGS | 'ADD_NOTES' | 'VIEW_NOTES' ;

export const esmNettingStatementFinderDialogReducer = fromCommon.dialogReducer<NETTING_STATEMENT_DIALOGS>({
  OPEN_DIALOG: fromDialogActions.openDialogAction.type,
  UPDATE_DIALOG: fromDialogActions.updateDialogAction.type,
  CLOSE_DIALOG: fromDialogActions.closeDialogAction.type,
  SET_TO_DEFAULT: fromFiltersActions.setDefaultStateAction.type
});
