import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cms-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent {
  @Input() label: string = 'Back';
  @Output() back = new EventEmitter();
  
  onBack(): void {
    this.back.emit();
  }

}
