<h1>eSM Netting Support by {{(organisationService.getChangeOrganisationObservable() | async)?.displayName}}
  ({{(organisationService.getChangeOrganisationObservable() | async)?.eicCode}})</h1>
<div class="panel-box">
  <cms-info ngPreserveWhitespaces>
    <u>__eSM Netting support/eSM Gatekeeper__</u>


    The below settings control if your organisation supports netting for eSM on the CMS.

    <u>Instructions</u>

    * check the checkbox '{{ '{' }}Organisation EIC code{{ '}' }} supports netting' to enable support of eSM netting for your organisation on the CMS.
    * check the checkbox '{{ '{' }}Organisation EIC code{{ '}' }} supports netting on the CMS' to enable support of central netting statement generation from the matched invoices of your organisation at a specfied point of time.
    * check the checkbox '{{ '{' }}Organisation EIC code{{ '}' }} supports cross commodity netting on the CMS' to enable support of cross commodity netting.
    * The dropdowns '{{ '{' }}X{{ '}' }} Payment date offset for central netting of physical|financial invoices' define offset values (in days) which are subtracted from the payment date of active invoices to compute the date when the central netting of these invoices is performed if you are a central netting party. Example: offset for physical invoices is set to 5 - then physical invoices with a payment date of the 20th of the month would be netted on the 15th of this month (20-5=15).

    <u>Impact</u>

    * checkbox '{{ '{' }}Organisation EIC code{{ '}' }} supports netting'
        * Only if netting support is enabled for your organisation, the CMS will allow the submission of netting statements to eSM containing your organisation is sender or receiver, supplier or customer
        * If you do not support netting, neither you nor your matching partner can submit a netting statement containing your organisation - it will get rejected ("invalid match attempt").
        * Reversely your netting statement is rejected if your matching partner does not support netting.
    * checkbox '{{ '{' }}Organisation EIC code{{ '}' }} supports central netting'
        * If central netting support is enabled for your organisation, the CMS will generate netting statements centrally from your matched invoices in the CMS.
        * If you do not support central netting, your organisation has to create netting statements yourself and send them to the CMS for matching.
    * checkbox '{{ '{' }}Organisation EIC code{{ '}' }} supports cross commodity netting on the CMS'
        * Central netting enabled for your organisation:  the CMS will generate cross commodity netting statements if the counterparty in the netting does also supports cross commodity netting. Otherwise (you, your counterparty or both do not support cross commodity netting), netting statements will be generated per commodity
        * Central netting not enabled, netting statements are created and sent to the CMS by your party: The CMS will only accept a netting statement with more than one commodity if your netting counterpart also supports cross commodity netting. Otherwise, if your counterpart does not support cross commodity netting, your netting statement will be rejected by the CMS if it contains more than one commodity and vice versa (you do not support cross commodity netting but your netting counterparty submits a netting statement with more than 1 commodity to the CMS).

    <u>__Support Netting for counterparties__</u>

    The configuration table of cross commodity netting per counterparties controls with which counterparty you would like to activate cross commodity netting.

    <u>Instructions</u>

    * __Our VAT:__ The cross commodity agreements are individually set per VAT number or your organisation, therefore the agreements need to be adjusted accordingly. Please select the VAT number in the drop-down menu.
    * __Activate / block cross commodity netting__ (master operators only): Click on the Activate/Block icon to toggle your cross commodity netting preferences for that counterparty.

    <u>Information in the List</u>

    * __CP EIC Code:__ The EIC code of any possible eSM counterparty
    * __CP VAT Code:__ The vat code of any possible eSM counterparty
    * __CP Legal Name:__ Legal name of the organisation identified by EIC code and VAT code
    * __Agreement CC Netting:__ Activate/ block your cross commodity netting preferences
    * __CP Agreement CC Netting:__ Cross commodity netting settings which your counterparty have set for you. If these settings do not meet your expectations, please contact the counterparty contact person.

    The 'Download eSM Netting Gatekeeper settings' button triggers a report which lists the netting settings of all organisations which are enabled for eSM on the CMS.
  </cms-info>
  <div class="row margin-0">
    <button (click)="onDownload()" [disabled]="!(downloadAllowed$ | async)" class="btn export-btn col-auto" icon="fa fa-download" label="&nbsp;Download eSM Gatekeeper Settings" pButton type="button">
    </button>
  </div>
  <p-messages [value]="messages$ | async" [closable]="true" [escape]="false"></p-messages>
  <div class="panel-box mt-3 mb-3 decent-max-width">
    <div class="p-field-checkbox">
      <p-checkbox inputId="esm_netting_check_box"
                  id="ntt_check_box"
                  [disabled]="!(toggleNettingAllowed$ | async)"
                  [ngModel]="nettingSupported$ | async"
                  (ngModelChange)="onNettingSupportedChanged($event)"
                  binary="true"
                  class="m-2">
      </p-checkbox>
      <label for="esm_netting_check_box">{{(organisationService.getChangeOrganisationObservable() | async)?.eicCode}} supports netting</label>
    </div>
    <div class="p-field-checkbox">
      <p-checkbox inputId="esm_netting_on_cms_check_box"
                  id="ntt_on_cms_check_box"
                  [disabled]="!(toggleNettingAllowed$ | async) || !(nettingSupported$ | async)"
                  [ngModel]="centralNettingSupported$ | async"
                  binary="true"
                  (ngModelChange)="onCentralNettingSupportedChanged($event)"
                  class="m-2">
      </p-checkbox>
      <label for="esm_netting_on_cms_check_box">{{(organisationService.getChangeOrganisationObservable() | async)?.eicCode}} supports central netting on the CMS</label>
    </div>
    <div class="p-field-checkbox">
      <p-checkbox inputId="esm_netting_commodity_on_cms_check_box"
                  id="ntt_commodity_on_cms_check_box"
                  [disabled]="!(toggleNettingAllowed$ | async) || !(nettingSupported$ | async)"
                  [ngModel]="crossCommodityNettingSupported$ | async"
                  binary="true"
                  (ngModelChange)="onCCNettingSupportChanged($event)"
                  class="m-2">
      </p-checkbox>
      <label for="esm_netting_commodity_on_cms_check_box">{{(organisationService.getChangeOrganisationObservable() | async)?.eicCode}} supports cross commodity netting on the CMS</label>
    </div>
    <div>
      <p-dropdown [style]="{'width':'10%'}"
                  class="col-lg-2 col-12 padding-0 margin-3"
                                    [options]="businessOffsetUntilPhysical"
                  [disabled]="!(toggleNettingAllowed$ | async) || (!(centralNettingSupported$ | async) || !(nettingSupported$ | async))"
                  (ngModelChange)="onPhysicalDropDownDayRangeChange($event)"
                  [ngModel]="selectedCallDayPhysicalNettingGeneration$ | async">
      </p-dropdown>
      <label>Payment date offset for central netting of physical invoices</label>
    </div>

    <div>
      <p-dropdown [style]="{'width':'10%'}"
                  class="col-lg-2 col-12 padding-0 margin-3"
                                    [options]="businessOffsetUntilFinancial"
                  [disabled]="!(toggleNettingAllowed$ | async) || (!(centralNettingSupported$ | async) || !(nettingSupported$ | async))"
                  (ngModelChange)="onFinancialDropDownDayRangeChange($event)"
                  [ngModel]="selectedCallDayFinancialNettingGeneration$ | async">
      </p-dropdown>
      <label>Payment date offset for central netting of financial invoices</label>
    </div>

  </div>
  <fieldset id="netting_settings_table" *ngIf="(crossCommodityNettingSupported$ | async)">
    <p-header>Configuration of cross commodity netting with counterparties</p-header>
    <p-table id="cross_comoodity_netting_settings_table"
             [paginator]="true"
             [totalRecords]="(dataWithPaging$ | async)?.count"
             [rows]="25"
             [columns]="columns"
             [value]="(data$ | async)"
             [sortMode]="'single'"
             [sortField]="(sorting$|async)?.columnName"
             [sortOrder]="(sorting$|async)?.sortOrder === 'ASCENDING' ? 1: -1"
             [loading]="loading$ | async"
             [autoLayout]="true"
             [lazy]="true"
             [lazyLoadOnInit]="false"
             (onLazyLoad)="onLazyLoad($event)">
      <ng-template pTemplate="caption">
        <div class="row gutter-0 margin-0">
          <label class="margin-3 padding-6 col-auto">Our VAT:</label>
          <p-dropdown class="col-auto" [options]="vatIds" [ngModel]="selectedVatId$" optionLabel="vatId" (onChange)="dropdownValueChanged($event)" [minlength]="10"></p-dropdown>
          <cms-input-text-filter placeholder="Name or Code" (filter)="onTextFieldFilter($event)" class="col-6 margin-0 padding-0"></cms-input-text-filter>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <ng-container *ngFor="let col of columns">
            <th [pSortableColumn]="col.name" *ngIf="col.visible">{{col.title}}
              <p-sortIcon [field]="col.name" *ngIf="col.sortable"></p-sortIcon>
            </th>
          </ng-container>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-row>
        <tr [pSelectableRow]="row">
          <ng-container *ngFor="let col of columns">
            <td *ngIf="col.type === 'toggleButton' && col.visible" [ngStyle]="col.style" cmsCellEditor>
              <cms-cell-status [column]="col"
                               [row]="row"
                               [editable]="(toggleNettingAllowed$ | async)"
                               (confirm)="openDialog(row)">
              </cms-cell-status>
            </td>
            <td *ngIf="col.type !== 'toggleButton' && col.visible" [ngStyle]="col.style">
              <span class="truncated-text">{{row[col.name]}}</span>
            </td>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="paginatorleft">
        <span class="paginator-info">Showing {{pagerInfo.fromNumberOfResults}} to {{pagerInfo.toNumberOfResults}} of {{pagerInfo.numberOfAllRecords}}</span>
      </ng-template>
    </p-table>
  </fieldset>

  <ng-container *ngIf="dialog$ | async as dialog">
    <cms-netting-settings-dialog
      (activateItem)="onActivateButton($event)"
      (blockItem)="onBlockItem($event)"
      (cancel)="onCancelButton()"
      *ngIf="dialog.name === 'TOGGLE'"
      [data]="dialog.data"
      [displayDialog]="!!(dialog$ | async)"
      [messages]="dialog.messages">
    </cms-netting-settings-dialog>
  </ng-container>
</div>
