import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'cms-elcom-whitelist-add-panel',
  templateUrl: './elcom-whitelist-add-panel.component.html',
  styleUrls: ['./elcom-whitelist-add-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElcomWhitelistAddPanelComponent implements OnInit, OnChanges {
  @Input() value: string;
  @Output() add = new EventEmitter<string>();
  @Output() clear = new EventEmitter();
  form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      acerCode: this.value || null
    });
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (this.form && changes.value) {
      this.form.setValue({
        acerCode: this.value
      });
      this.form.updateValueAndValidity();
    }
  }

  onAdd(): void {
    this.add.emit(this.form.value.acerCode);
  }

  onClear(): void {
    this.form.reset();
    this.clear.emit();
  }
}
