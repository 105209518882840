export interface TableEntry {
  quarter: string;
  executed: string;
  executedBy: string;
  executionTimeInSeconds: number;
  errors: number;
  draft: boolean;
  invoiced: number;
  downloadButtonVisible: boolean;
  deleteButtonVisible: boolean;
  mailingButtonEnabled: boolean;
  mailingButtonVisible: boolean;
  reportButtonVisible: boolean;
  acerBillingRunId: number;
  notProcessedByAcer: number;
  progress: string;
}

const defaultStyles = {
  'text-align': 'left',
  'text-overflow': 'ellipsis',
  'overflow': 'hidden',
  'white-space': 'nowrap',
  'width': '9%'
};

export const acerBillingTableColumns = [
  {name: 'quarter', type: 'text', title: 'Billing Quarter', style: {...defaultStyles}, sortable: false, visible: true},
  {name: 'executed', type: 'date', title: 'Executed', style: {...defaultStyles}, sortable: false, visible: true},
  {name: 'executedBy', type: 'text', title: 'Executed By', style: {...defaultStyles}, sortable: false, visible: true},
  {name: 'executionTimeInSeconds', type: 'text', title: 'Exec. time(s)', style: {...defaultStyles, 'text-align': 'right'}, sortable: false, visible: true},
  {name: 'invoiced', type: 'text', title: 'Invoiced', style: {...defaultStyles, 'text-align': 'right'}, sortable: false, visible: true},
  {name: 'errors', type: 'button', title: 'Errors', style: {...defaultStyles, 'text-align': 'right'}, sortable: false, visible: true},
  {name: 'draft', type: 'yes/no', title: 'Draft', style: {...defaultStyles}, sortable: false, visible: true},
  {name: 'downloadButtonVisible', type: 'button', title: 'Download', style: {...defaultStyles, 'text-align': 'center'}, sortable: false, visible: true},
  {name: 'mailingButtonVisible', type: 'button', title: 'Send', style: {...defaultStyles, 'text-align': 'center'}, sortable: false, visible: true},
  {name: 'reportButtonVisible', type: 'button', title: 'Report', style: {...defaultStyles, 'text-align': 'center'}, sortable: false, visible: true},
  {name: 'deleteButtonVisible', type: 'button', title: 'Delete', style: {...defaultStyles, 'text-align': 'center'}, sortable: false, visible: true},
  {name: 'acerBillingRunId', type: 'hidden', title: '', sortable: false, visible: false},
  {name: 'mailingButtonEnabled', type: 'hidden', title: '', sortable: false, visible: false},
  {name: 'notProcessedByAcer', type: 'button', title: 'Not processed by ACER', style: {...defaultStyles, 'text-align': 'center'}, sortable: false, visible: true},
  {name: 'progress', type: 'text', title: 'Progress', style: {...defaultStyles, 'text-align': 'right'}, sortable: false, visible: true},

];

