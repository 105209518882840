export class StandingInstructionModel {
  organisationGroupName: string;
  displayName: string;
  eicCode: string;
  leiCode: string;
  partyType: string;
  cpFinancialNature: { key: string, value: string };
  cpCorpSectors: { key: string, value: string }[];
  tradingCapacity: { key: string, value: string };
  beneficiaryId: string;
  commercialActivity: { key: string, value: string };
  clearingThreshold: { key: string, value: string };
  collateralisation: { key: string, value: string };
  collateralPortfolio: { key: string, value: string };
  collPortfolioCode: string;
  masterAgreeVersion: string;
  errPartyId: number;
}

export enum ErrCpFinancialNatureEnum { // see de.ponton.cmh.err3.domain.standingins.ErrCpFinancialNatureEnum
  FINANCIAL = 'FINANCIAL', //
  NON_FINANCIAL = 'NON_FINANCIAL', //
  CENTRAL = 'CENTRAL', //
  OTHER = 'OTHER'
}

export enum ErrTradingCapacityEnum { // see de.ponton.cmh.err3.domain.standingins.ErrTradingCapacityEnum
  AGENT = 'AGENT', //
  PRINCIPAL = 'PRINCIPAL'
}

export enum ErrCollateralisationEnum { // see de.ponton.cmh.err3.domain.standingins.ErrCollateralisationEnum
  UNCOLLATERALISED = 'UNCOLLATERALISED', //
  PARTIALLY_COLLATERALISED = 'PARTIALLY_COLLATERALISED', //
  ONE_WAY_COLLATERALISED = 'ONE_WAY_COLLATERALISED', //
  FULLY_COLLATERALISED = 'FULLY_COLLATERALISED'
}

export enum ErrCollateralPortfolioEnum { // see de.ponton.cmh.err3.domain.standingins.ErrCollateralPortfolioEnum
  YES = 'YES', //
  NO = 'NO'
}
