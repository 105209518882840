import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Message } from 'primeng/api';

@Component({
  selector: 'cms-delete-exceptions-dialog',
  templateUrl: './delete-exceptions-dialog.component.html',
  styleUrls: ['./delete-exceptions-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteExceptionsDialogComponent {
  @Input() display: boolean = false;
  @Input() messages: Message[];
  @Output() confirm = new EventEmitter();
  @Output() cancel = new EventEmitter();
  header: string = 'Confirm Deletion';
  contentMessages: Message[] = [{
    severity: 'warn',
    summary: '',
    detail: 'Really delete selected queue events?'
  }];

  onCancel(): void {
    this.cancel.emit();
  }

  onConfirm(): void {
    this.confirm.emit();
  }
}
