<p-tabView [activeIndex]="(activeIndex$ | async)" (onChange)="onChangeTab($event)">
  <p-tabPanel header="Organisation Groups" *ngIf="(tabs$ | async).includes('organisationGroups')">
    <div class="tab-view-body">
        <div class="tab-view-body">
          <cms-organisation-groups *ngIf="(activeIndex$ | async) === orgGroupIndex"></cms-organisation-groups>
        </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Organisations" *ngIf="(tabs$ | async).includes('organisations')">
    <div class="tab-view-body">
        <div class="tab-view-body">
          <cms-organisations *ngIf="(activeIndex$ | async) === organisationIndex"></cms-organisations>
        </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="User Accounts" *ngIf="(tabs$ | async).includes('userAccounts')">
    <div class="tab-view-body">
        <div class="tab-view-body">
          <cms-user-accounts *ngIf="(activeIndex$ | async) === userAccountIndex"></cms-user-accounts>
        </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Assign User Roles" *ngIf="(tabs$ | async).includes('userRoles')">
    <div class="tab-view-body">
        <div class="tab-view-body">
          <cms-user-roles *ngIf="(activeIndex$ | async) === userRoleIndex"></cms-user-roles>
        </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="FHA Accounts" *ngIf="(tabs$ | async).includes('fhaAccounts')">
    <div class="tab-view-body">
        <div class="tab-view-body">
          <cms-fha-accounts *ngIf="(activeIndex$ | async) === fhaAccountIndex"></cms-fha-accounts>
        </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Party Data" *ngIf="(tabs$ | async).includes('partydata')">
    <div class="tab-view-body">
        <div class="tab-view-body">
          <cms-party-data *ngIf="(activeIndex$ | async) === partydataIndex"></cms-party-data>
        </div>
    </div>
  </p-tabPanel>
</p-tabView>
