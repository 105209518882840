import { createSelector } from '@ngrx/store';
import { EsmSettingsAddPanelState } from './add-panel.reducers';
import { getEsmInvoiceSettingsState } from '../invoice-settings.selectors';
import { EsmInvoiceSettingsState } from '../invoice-settings.reducers';

export const getAdding = createSelector(getEsmInvoiceSettingsState, (state: EsmInvoiceSettingsState) => state.adding);
export const getTypes = createSelector(getAdding, (state: EsmSettingsAddPanelState) => state.types);
export const getCommodities = createSelector(getAdding, (state: EsmSettingsAddPanelState) => state.commodities);
export const getPriceTypes = createSelector(getAdding, (state: EsmSettingsAddPanelState) => state.priceTypes);
export const getNatureOfPrices = createSelector(getAdding, (state: EsmSettingsAddPanelState) => state.natureOfPrices);
export const getPresetValues = createSelector(getAdding, (state: EsmSettingsAddPanelState) => state.values);
