import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromExceptions from '../exceptions/exceptions.reducers';
import * as fromErrSendingFailures from '../err-sending-failures/err-sending-failures.reducers';
import * as fromSendingFailures from '../sending-failures/sending-failures.reducers';

export const getProblemSolvingState = createFeatureSelector<State>('problemsolving');

export interface State {
  exceptions: fromExceptions.ExceptionsState;
  errSendingFailures: fromErrSendingFailures.ErrSendingFailuresState;
  sendingFailures: fromSendingFailures.SendingFailuresState;
}

export const reducers: ActionReducerMap<State> = {
  exceptions: fromExceptions.exceptionsReducer,
  errSendingFailures: fromErrSendingFailures.errSendingFailuresReducer,
  sendingFailures: fromSendingFailures.sendingFailuresReducer
};
