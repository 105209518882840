export interface TransactionSheetMultiItemValueModel {
  values: TransactionSheetItemValueModel[];
  valueType: string;
}

export interface TransactionSheetItemValueModel {
  name: string;
  label: string;
  values: string[];
  valueType: string;
}

export type ItemValueType = TransactionSheetMultiItemValueModel | TransactionSheetItemValueModel;

export interface TransactionSheetValuesGroupModel {
  name: string;
  label: string;
  values: ItemValueType[];
}

export interface TransactionSheetResponseModel {
  organisationId: number;
  regime: string;
  recordType: string;
  documentType: string;
  historyId: number;
  valuesGroups: TransactionSheetValuesGroupModel[];
}

export namespace TransactionSheetTableConfig {

  const SHEET_GROUPS_T1_TRADE = [
    [
      'tradeReferences',
      'tradeCounterparties',
      'tradeOmpDetails',
      'tradeCommercials',
      'tradePriceQuantityIntervals',
      'tradeClickAndTradeDetails'
    ], [
      'tradeFixingIndexes',
      'tradeAdditionalReferences',
      'contractReferences',
      'contractProductDetails',
      'contractOptionDetails',
      'contractDeliveryProfiles',
      'contractTradingHours'
    ]
  ];

  const SHEET_GROUPS_T1_ORDER = [
    [
      'orderReferences',
      'orderParties',
      'orderDetails',
      'orderCommercials',
      'orderPriceQuantityIntervals'
    ], [
      'orderFixingIndexes',
      'orderAdditionalReferences',
      'contractReferences',
      'contractProductDetails',
      'contractOptionDetails',
      'contractDeliveryProfiles',
      'contractTradingHours'
    ]
  ];

  const SHEET_GROUPS_T2_TRADE = [
    [
      'tradeReferences',
      'tradeProductDetails',
      'tradeCounterparties',
      'tradeDelivery',
      'tradeOptionDetails'
    ], [
      'tradeVolume',
      'tradeVolumeOptionalityIntervals',
      'tradePrice',
      'tradeFixingIndex'
    ]
  ];

  const SHEET_GROUPS_EMIR_TRADE = [
    [
      'tradeReferences',
      'tradeReportingDetails',
      'tradeParties',
      'tradeCommercials'
    ],
    []
  ];

  export function getLeftTableGroupNamesByDocumentType(documentType: string, recordType: string) {
    // TODO CMSGUI-291 mr2 2018-02: move knowledge regarding left/right out of common code
    if (documentType === 'REMIT_TABLE_1') {
      if (recordType === 'Order') {
        return SHEET_GROUPS_T1_ORDER[0];
      } else {
        return SHEET_GROUPS_T1_TRADE[0];
      }
    } else if (documentType === 'REMIT_TABLE_2') {
      return SHEET_GROUPS_T2_TRADE[0];
    } else if (documentType === 'CNF' || documentType === 'ETD') {
      return SHEET_GROUPS_EMIR_TRADE[0];
    }
    return [];
  }

}
