import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'cms-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent {

  @Input() id: string;
  @Input() formGroup: UntypedFormGroup;
  @Input() label: string;
  @Input() maxlength: number;
  @Input() disabled: boolean;
  valid: boolean = true;

  isValidForm(): boolean {
    return this.formGroup.valid;
  }

  submitForm(): void {
    this.valid = !!this.formGroup.valid;
  }
}
