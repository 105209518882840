import { Component, DoCheck, ElementRef, IterableDiffers, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DatatableCustomizeSettingsService } from './datatable-customize-settings.service';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ErrRemitDataTableConfig } from '../../../err/remit/datatable/remit-datatable.config';
import {
  DatatableCustomizeSettingsDocumentType,
  LoadUsersOwningPersistedColumnSettingResponseModel,
  PersistColumSettingsRequestModel
} from './datatable-customize-settings.model';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { RemitDocumentType } from '../../../err/remit/dashboard/filter/remit-dashboard-filter-model';
import { takeUntil } from 'rxjs/operators';
import { Message } from 'primeng/api';
import { PickList } from 'primeng/picklist';

@Component({
  selector: 'cms-datatable-customize-settings',
  templateUrl: './datatable-customize-settings.component.html',
  styleUrls: ['./datatable-customize-settings.component.scss']
})
export class DatatableCustomizeSettingsComponent implements OnInit, OnDestroy, DoCheck {
  @ViewChild(PickList, { static: true }) pickList: PickList;

  private datatableCustomizeSettingsRemitDocumentTypeToRemitDocumentType = new Map<DatatableCustomizeSettingsDocumentType, RemitDocumentType>([
    ['remitt1', 'REMIT_TABLE_1'],
    ['remitt2', 'REMIT_TABLE_2'],
    ['remitt3', 'ELECTRICITY_RIGHTS'],
    ['remitt4', 'GAS_CAPACITY'],
    ['remitfund', 'FUNDAMENTALS'],
    ['ecm', 'ECM'],
    ['esmInvoice', 'ESM_INVOICE'],
    ['esmNettingstatement', 'ESM_NETTING'],
  ]);
  disabled: boolean;
  selectedColumns: { name: string, title: string }[];
  hiddenColumns: { name: string, title: string }[];
  users: LoadUsersOwningPersistedColumnSettingResponseModel = new LoadUsersOwningPersistedColumnSettingResponseModel();
  messages: Message[] = [];
  selectedUserId: number = -1;
  documentType: DatatableCustomizeSettingsDocumentType;
  dragging: boolean = false;
  dropItem: Element[];
  userSelectionDisabled: boolean = true;

  private onDestroy$ = new Subject();

  currentElem: any;
  differ: any;

  constructor(private customizeService: DatatableCustomizeSettingsService,
              private route: ActivatedRoute, private location: Location,
              private differs: IterableDiffers, private elementRef: ElementRef) {
    this.differ = differs.find([]).create(null);
  }

  ngOnInit() {
    this.userSelectionDisabled = true;
    this.route.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe(params => {
      this.determineDocType(params['documentType']);
    });

    this.customizeService.loadColumnSettings(this.selectedUserId, this.documentType).pipe(takeUntil(this.onDestroy$)).subscribe(model => {
      this.selectedColumns = ErrRemitDataTableConfig.getColumnTitles(this.convert(this.documentType), model.activeColumns);
      this.hiddenColumns = ErrRemitDataTableConfig.getColumnTitles(this.convert(this.documentType), model.inactiveColumns);
    });

    this.customizeService.loadUsers(this.documentType).pipe(takeUntil(this.onDestroy$)).subscribe(next => {
      this.users = next;
      if (this.users && this.users.users.length > 0) {
        this.userSelectionDisabled = false;
        this.users.users.unshift({ ident: -1, value: 'Choose user' });
      } else {
        this.userSelectionDisabled = true;
      }
    });

    this.disabled = true;

  }

  loadDefault() {
    this.customizeService.loadDefaultColumnSettings(this.documentType).pipe(takeUntil(this.onDestroy$)).subscribe(model => {
      this.selectedColumns = ErrRemitDataTableConfig.getColumnTitles(this.convert(this.documentType), model.activeColumns);
      this.hiddenColumns = ErrRemitDataTableConfig.getColumnTitles(this.convert(this.documentType), model.inactiveColumns);
      this.enableSaveButton();
      this.messages = [{
        severity: 'warn',
        summary: '',
        detail: '"Save Changes" now if you wish to replace your current settings with the system defaults.'
      }];
    });
  }

  ngDoCheck() {
    const change = this.differ.diff(this.selectedColumns);

    if (change) {
      change.forEachMovedItem((record) => {
        this.disabled = false;
      });

    }
  }

  saveChanges() {
    const model = new PersistColumSettingsRequestModel();
    model.activeColumns = this.selectedColumns.map(val => {
      return val.name;
    });
    this.customizeService.saveChanges(this.selectedUserId, this.documentType, model).pipe(takeUntil(this.onDestroy$)).subscribe( //
      next => {
        this.messages = [{
          severity: 'success',
          summary: '',
          detail: 'Settings saved.'
        }];
      },
      (err: HttpErrorResponse) => {
        this.messages = [{
          severity: 'error',
          summary: '',
          detail: err.message
        }];
      }
    );
    this.disabled = true;
  }

  get isUserSelectionDisabled(): boolean {
    return this.userSelectionDisabled;
  }

  back() {
    this.location.back();
  }

  change(eventValue: number) {
    this.selectedUserId = eventValue;
    const user = this.users.users.find(item => item.ident === this.selectedUserId);
    this.customizeService.loadColumnSettings(this.selectedUserId, this.documentType).pipe(takeUntil(this.onDestroy$)).subscribe(model => {
      this.selectedColumns = ErrRemitDataTableConfig.getColumnTitles(this.convert(this.documentType), model.activeColumns);
      this.hiddenColumns = ErrRemitDataTableConfig.getColumnTitles(this.convert(this.documentType), model.inactiveColumns);
      this.enableSaveButton();
      this.messages = [
        {
          severity: 'warn',
          summary: '',
          detail: `"Save Changes" now if you wish to adopt the settings of user "${user.value}"`
        }
      ];
    });
  }

  enableSaveButton() {
    this.disabled = false;
  }

  determineDocType(params) {
    switch (params) {
      case 'REMIT_TABLE_1':
        this.documentType = 'remitt1';
        break;
      case 'REMIT_TABLE_2':
        this.documentType = 'remitt2';
        break;
      case 'ELECTRICITY_RIGHTS':
        this.documentType = 'remitt3';
        break;
      case 'GAS_CAPACITY':
        this.documentType = 'remitt4';
        break;
      case 'FUNDAMENTALS':
        this.documentType = 'remitfund';
        break;
      case 'ECM':
        this.documentType = 'ecm';
        break;
      case 'ESM_INVOICE':
        this.documentType = 'esmInvoice';
        break;
      case 'ESM_NETTING':
        this.documentType = 'esmNettingstatement';
        break;
      default: {
        this.location.back();
        break;
      }
    }
  }

  moveList($event) {

    if (!this.dragging) {
      this.dragging = true;
      this.dropItem = this.elementRef.nativeElement.querySelectorAll('.p-picklist-item');

      let hit = false;

      if (this.dropItem) {

        for (const element of this.dropItem) {

          if (this.isDragHitBoxTop(element, $event)) {
            element.previousElementSibling.setAttribute('style', 'height: 29px');
            this.currentElem = element.previousElementSibling;
            hit = true;
            break;
          } else if (this.isDragHitBoxBottom(element, $event)) {
            element.nextElementSibling.setAttribute('style', 'height: 29px');
            this.currentElem = element.nextElementSibling;
            hit = true;
            break;
          }

        }

        if(!hit){
          this.currentElem = null;
        }

        const dropPoints = this.elementRef.nativeElement.querySelectorAll('.p-picklist-droppoint');

        if (dropPoints) {
          for (const dp of dropPoints) {

            if (this.currentElem !== dp) {
              dp.setAttribute('style', 'height: 2px');
            }
          }
        }
      }
      this.dragging = false;

    }
  }

  private isDragHitBoxTop(element: Element, $event): boolean {
    const rect = element.getBoundingClientRect();
    return rect.left < $event.x && rect.right > $event.x && rect.top < $event.y && rect.bottom - 14 > $event.y;
  }

  private isDragHitBoxBottom(element: Element, $event): boolean {
    const rect = element.getBoundingClientRect();
    return rect.left < $event.x && rect.right > $event.x && rect.top + 15 < $event.y && rect.bottom > $event.y;
  }

  resetDropPoints() {
    this.currentElem = null;
    const dropPoints = this.elementRef.nativeElement.querySelectorAll('.p-picklist-droppoint');

    if (dropPoints) {
      for (const dp of dropPoints) {
        dp.setAttribute('style', 'height: 2px');
      }
    }
  }

  private convert(datatableCustomizeSettingsRemitDocumentType: DatatableCustomizeSettingsDocumentType): RemitDocumentType {
    return this.datatableCustomizeSettingsRemitDocumentTypeToRemitDocumentType.get(datatableCustomizeSettingsRemitDocumentType);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }
}
