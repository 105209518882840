import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabViewModule } from 'primeng/tabview';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { AccountComponent } from './account/account.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { CmsCommonModule } from '@common/cms-common.module';
import { MailAlertsComponent } from './mail-alerts/mail-alerts.component';
import { GlobalComponent } from './mail-alerts/global/global.component';
import { PreferencesRoutingModule } from './preferences-routing.module';
import { getReducers, reducerToken } from './state/reducers';
import { GlobalEffects } from './state/mail-alerts/global/global.effects';
import { EcmComponent } from './mail-alerts/ecm/ecm.component';
import { LayoutEffects } from './state/mail-alerts/layout/layout.effects';
import { EcmEffects } from './state/mail-alerts/ecm/ecm.effects';
import { MailAlertsService } from './state/mail-alerts/mail-alerts.service';
import { DropdownModule } from 'primeng/dropdown';
import { PanelModule } from 'primeng/panel';

@NgModule({
  imports: [
    PanelModule,
    FormsModule,
    DropdownModule,
    ReactiveFormsModule,
    CmsCommonModule,
    TabViewModule,
    MessagesModule,
    MessageModule,
    PreferencesRoutingModule,
    CommonModule,
    StoreModule.forFeature('preferences', reducerToken),
    EffectsModule.forFeature([
      GlobalEffects,
      EcmEffects,
      LayoutEffects
    ])
  ],
  declarations: [
    AccountComponent,
    ChangePasswordComponent,
    MailAlertsComponent,
    GlobalComponent,
    EcmComponent
  ],
  providers: [
    MailAlertsService,
    {
      provide: reducerToken,
      useFactory: getReducers
    }
  ]
})
export class PreferencesModule { }

