import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class PasswordService {

  private FORGOT_PASSWORD_API_URL: string = '/api/user/password/reset/';
  private RESET_PASSWORD_API_URL: string = '/api/user/password/reset';
  private checkHashApiUrl = '/api/user/password/reset/allowed/';
  private hash: string;
  private status = new BehaviorSubject<any>({});
  private successMsg: string;
  private errorMsg = new BehaviorSubject<string>('');

  constructor(private httpClient: HttpClient, private router: Router) {

  }

  public isResetPasswordAllowed(hash: string): Observable<boolean> {
    this.hash = hash;

    return this.httpClient.get(this.checkHashApiUrl + hash).pipe(map(
      response => this.handlePasswordAllowedResponse(response)
    ), catchError(err => this.handleError(err)));
  }

  public reset(password: any) {

    this.isResetPasswordAllowed(this.hash).subscribe(
      response => {
        if (response) {
          const headers = new HttpHeaders();
          this.httpClient.post(this.RESET_PASSWORD_API_URL, password, { headers: headers })
            .subscribe(
              (data) => {
                this.successMsg = 'Reset successful. Please log in.';
                this.router.navigate(['/']);
              },
              (err) => {
                this.handleError(err);
              });
        }
      }
    );
  }

  private handlePasswordAllowedResponse(status: any): boolean {
    this.status.next(status);
    const allowed = status.response === 'SUCCESS';
    if (!allowed) {
      this.router.navigate(['/']);
    }
    return allowed;
  }

  public request(username: string) {

    this.httpClient.get(this.FORGOT_PASSWORD_API_URL + username)
      .subscribe(
        (data) => {
          this.successMsg = 'Please check your emails for further instructions.';
          this.router.navigate(['/']);
        },
        (err: HttpErrorResponse) => {
          this.errorMsg.next(err.error.response);
        });
  }

  public getSuccessMessage(): string {
    return this.successMsg;
  }

  public getErrorMessage(): Observable<string> {
    return this.errorMsg.asObservable();
  }

  private handleError(error: any): Observable<boolean> {
    if (error.error) {
      this.status.next(JSON.parse(error.error));
    }
    this.router.navigate(['/']);
    return of(false);
  }

  public getHash(): string {
    return this.hash;
  }

  setHash(hash: string) {
    this.hash = hash;
  }

  public getStatus(): Observable<any> {
    return this.status.asObservable();
  }

}
