import {createSelector} from '@ngrx/store';
import {getDashboardState} from '../dashboard.selectors';
import {EsmDashboardState} from '../dashboard.reducers';
import {EsmDashboardTableState} from './table.reducer';

export const getTable = createSelector(getDashboardState, (state: EsmDashboardState) => state.table);
export const getMessages = createSelector(getTable, (state: EsmDashboardTableState) => state.messages);
export const getLoading = createSelector(getTable, (state: EsmDashboardTableState) => state.loading);
export const getSelectedIds = createSelector(getTable, (state: EsmDashboardTableState) => state.selectedIds);
export const getSelectedInvoices = createSelector(getTable, (state: EsmDashboardTableState) => state.invoices);

export const getData = createSelector(getTable, (state: EsmDashboardTableState) => state.data);
export const getPaging = createSelector(getTable, (state: EsmDashboardTableState) => state.paging);
export const getSorting = createSelector(getTable, (state: EsmDashboardTableState) => state.sorting);
export const getColumns = createSelector(getTable, (state: EsmDashboardTableState) => state.columns);
