import { Component, OnDestroy, OnInit } from '@angular/core';
import { OrganisationService } from '../../../../common/organisation.service';
import { OrganisationGroupModel } from '@common/organisation-group.model';
import { Column } from '../../../../common/shared/datatable.model';
import { RemitReconciliationReportModel } from './remit-reconciliation-report.model';
import { RemitReportService } from '../remit-report.service';
import { DateUtils } from '../../../../common/shared/date.utils';

import * as FileSaver from 'file-saver';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cms-remit-reconciliation-report',
  templateUrl: './remit-reconciliation-report.component.html',
  styleUrls: ['./remit-reconciliation-report.component.scss']
})
export class RemitReconciliationReportComponent implements OnInit, OnDestroy {

  organisationGroup: OrganisationGroupModel = { legalName: '', displayName: '' };
  rows = new BehaviorSubject<RemitReconciliationReportModel[]>([]);
  columns: Column[] = [
    { title: 'As of', name: 'asOfDate', style: { 'width': '85px', 'text-align': 'center' } },
    { title: 'Result', name: 'result', style: { 'width': '85px', 'text-align': 'center' } },
    { title: 'Executed', name: 'executionTimestamp', style: { 'width': '140px', 'text-align': 'center' } },
    { title: 'Matched', name: 'matchedCount', style: { 'width': '85px', 'text-align': 'right' } },
    { title: 'Mismatched', name: 'mismatchedCount', style: { 'width': '110px', 'text-align': 'right' } },
    { title: 'Pairing Failed', name: 'pairingFailedCount', style: { 'width': '120px', 'text-align': 'right' } },
    { title: 'Pending', name: 'pendingCount', style: { 'width': '85px', 'text-align': 'right' } },
    { title: 'Alleged', name: 'allegedCount', style: { 'text-align': 'right' } }
  ];
  isOverlayPanelVisible: boolean = false;
  isBackgroundBlocked: boolean = true;
  version: number;
  executedBy: string;
  executionDurationInSec;
  retentionRunsNumber: string = '30';
  selection: RemitReconciliationReportModel;
  private onDestroy$ = new Subject();

  constructor(private organisationService: OrganisationService, private remitReportService: RemitReportService) {
  }

  ngOnInit() {
    this.organisationService.getOrganisationGroup().pipe(takeUntil(this.onDestroy$)).subscribe(orgGrp => {
      this.organisationGroup = orgGrp;
      this.remitReportService.loadRemitReconciliationReport(this.organisationGroup.id);
    });

    this.remitReportService.getRemitReconciliationReport().pipe(takeUntil(this.onDestroy$)).subscribe(
      report => {
        if (report) { // redundant || possible ?
          const data = report.map(
            rep => ({
              ...rep,
              asOfDate: DateUtils.transformDate(rep.asOfDate),
              executionTimestamp: DateUtils.transformDateWithTimestamp(rep.executionTimestamp)
            })
          );

          this.rows.next(data);
        }
      }
    );

  }

  public loadExcelExport(reconciliationReportId): void {
    this.remitReportService.downloadExcelReconciliationReport(reconciliationReportId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(data => {
        FileSaver.saveAs(data.blob, data.filename);
      });
  }

  public getInfo(model: RemitReconciliationReportModel) {
    this.isOverlayPanelVisible = true;
    this.version = model.version;
    this.executedBy = model.executedBy;
    this.executionDurationInSec = model.executionDurationInSec;
  }

  public closeInfo() {
    this.isOverlayPanelVisible = false;
  }

  ngOnDestroy(): void {
    // Clean up all subscriptions at once:
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

}
