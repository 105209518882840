import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RemitTable2TradeDeliveryModel } from '../../../trade/table2/modify/remit-table2-trade-entry.model';

@Component({
  selector: 'cms-remit-trade-entry-table2-delivery',
  templateUrl: './remit-trade-entry-table2-delivery.component.html',
  styleUrls: ['./remit-trade-entry-table2-delivery.component.scss']
})
export class RemitTradeEntryTable2DeliveryComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  formGroup: UntypedFormGroup;

  @Input()
  onReset: Subject<boolean>;

  @Input()
  data: RemitTable2TradeDeliveryModel;

  validationErrors: string[] = [];

  private onDestroy$ = new Subject();

  constructor() {
    this.onReset = new Subject<boolean>();
  }

  ngOnInit() {
    this.formGroup.addControl('deliveryStart', new UntypedFormControl(''));
    this.formGroup.addControl('deliveryEnd', new UntypedFormControl(''));


    if (this.data) {
      this.formGroup.patchValue({deliveryStart: new Date(this.data.deliveryStart), deliveryEnd: new Date(this.data.deliveryEnd)});
    }

    this.onReset.pipe(takeUntil(this.onDestroy$))
      .subscribe(   response => {
        if (response) {
          this.reset();
        }
      });

  }

  getValidationErrorStyleClass(fieldName: string): string {
    if (this.formGroup.controls[fieldName] && this.formGroup.controls[fieldName].hasError('error')) {
      return 'error';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.formGroup && this.data) {
      this.formGroup.patchValue({deliveryStart: new Date(this.data.deliveryStart), deliveryEnd: new Date(this.data.deliveryEnd)});
    }
  }


  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  private reset() {
      this.formGroup.controls['deliveryStart'].reset();
      this.formGroup.controls['deliveryEnd'].reset();
  }

}
