import { Message } from 'primeng/api';
import { Column } from '@common/shared/datatable.model';
import { SortingFilter } from '@common/shared/results.model';
import {
  EcmSettingsProcessData,
  ProcessDataInDatatable
} from '../../../settings/ecm-settings-tab-panel/process/ecm-process.model';
import * as fromAction from './process.actions';

export interface EcmSettingsProcessState {
  columns: Column[];
  data: EcmSettingsProcessData;
  messages: Message[];
  sorting?: SortingFilter;
  loading: boolean;
  selected: ProcessDataInDatatable;
}

export const initialState: EcmSettingsProcessState = {
  columns: [],
  data: null,
  messages: [],
  sorting: {
    columnName: 'orgDisplayName',
    sortOrder: 'DESCENDING'
  },
  loading: false,
  selected: null
};

export function ecmSettingsProcessReducer(state: EcmSettingsProcessState = {...initialState},
                                          action: fromAction.EcmSettingsProcessActions) {
  switch (action.type) {
    case fromAction.LOAD_DATA_SUCCESS:
      let data = action.payload;
      let userOrgGroupData = null;
      let brokerData = null;
      let traderData = null;

      if (data.userOrgGroupData) {
      userOrgGroupData = [];

      Object.entries(data.userOrgGroupData).forEach(entry => {
        const [key, value] = entry;
        userOrgGroupData[key] = { ...value.info, ...value.settings, editActionEnabled: value.editActionEnabled, orgId: value.orgId };
      });
      }

      if (data.brokerData) {
        brokerData = [];

        Object.entries(data.brokerData).forEach(entry => {
          const [key, value] = entry;
          brokerData[key] = { ...value.info, ...value.settings, editActionEnabled: value.editActionEnabled, orgId: value.orgId };
        });
      }

      if (data.traderData) {
        traderData = [];

        Object.entries(data.traderData).forEach(entry => {
          const [key, value] = entry;
          traderData[key] = { ...value.info, ...value.settings, editActionEnabled: value.editActionEnabled, orgId: value.orgId };
        });

      }

      data = {
        editable: data.editable,
        userOrgGroupDataVisible: data.userOrgGroupDataVisible,
        userOrgGroupData: userOrgGroupData,
        brokerData: brokerData,
        traderData: traderData
      };

      return {
        ...state,
        data
      };
    case fromAction.SET_COLUMNS:
      return {
        ...state,
        columns: (action as fromAction.SetColumnsAction).payload
      };
    case fromAction.SET_TO_DEFAULT:
      return {...initialState};
    default:
      return state;
  }
}

export const getData = (datatableState: EcmSettingsProcessState) => datatableState.data;

export const getSorting = (state: EcmSettingsProcessState) => state.sorting;
export const getLoading = (state: EcmSettingsProcessState) => state.loading;
export const getMessages = (state: EcmSettingsProcessState) => state.messages;
export const getColumns = (state: EcmSettingsProcessState) => state.columns;
export const getSelected = (state: EcmSettingsProcessState) => state.selected;
