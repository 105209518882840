<div class="container-fluid row justify-content-center margin-top-60">
  <div class="col-12">
    <div class="panel-box margin-bottom-15">
      <div class="col-12 text-center">
        <img class="margin-bottom-50 text-center" src="assets/img/equias_logo.png" />
      </div>
      <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
        <div class="col-12">
          <h1 class="text-center">Reset My Password</h1>
          <div class="col-12 info-panel margin-bottom-30" id="info-password">
            <div class="fa fa-info-circle float-left"></div>
            <div class="margin-left-18">Enter your login name. You will receive an email with instructions on how to complete the password reset.</div>
          </div>
        </div>

        <label class="col-12 margin-bottom-3" for="username">Username:<span class="text-required">*</span></label>
        <div class="col-12 margin-bottom-15">
          <input formControlName="username" type="text" id="username" tabindex="1" class="form-control" placeholder="username" style="font-size: 13px;">
        </div>
        <div class="col-12 margin-bottom-15 text-center">
          <span class="error-note" [innerHTML]="errorMsg"></span>
        </div>
        <div class="col-12 clearfix margin-bottom-15">
          <button type="submit" [disabled]="!forgotPasswordForm.valid" id="btn-login" tabindex="2" class="btn float-right" value="Send">Send</button>
        </div>

      </form>
    </div>
  </div>
</div>
