<form [formGroup]="formGroup" (ngSubmit)="submitForm()">
  <div class="row col">
    <label class="margin-0 text-medium">{{label}}</label>
  </div>
  <div class="row align-items-center">
    <div class="col-12 col-sm-12 col-md-6 col-lg-12 col-xl-8">
      <input type="text" class="form-control p-component padding-6 text-medium" [id]="'input-'+id" formControlName="{{id}}" maxlength="{{maxlength}}" />
    </div>
    <div class="col">
      <button type="submit" id="btn-search-{{id}}" class="form-control btn margin-top-6 margin-bottom-6 padding-6" [disabled]="!isValidForm() || disabled">Search</button>
    </div>
  </div>
  <div class="col-12">
    <p class="error-note" style="font-size: 12px" *ngIf="!valid">Field {{label}} is required.</p>
  </div>
</form>
