import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { select, Store } from '@ngrx/store';
import { Message } from 'primeng/api';
import { withLatestFrom } from 'rxjs/operators';
import { OrganisationModel } from '@common/organisation.model';
import { OrganisationService } from '@common/organisation.service';
import { DatatableState } from '@common/datatable/datatable.model';
import { Column } from '@common/shared/datatable.model';

import * as fromAction from '../state/mifid/mifid.action';
import * as fromSelectors from '../state/mifid/mifid.selectors';
import { ErrMifidFile, ErrMifidFileFilters, ErrMifidViewByTypes } from './mifid.model';
import * as moment from 'moment';
import { ErrorMessageService } from '@common/errors/error-message.service';

@Component({
  selector: 'cms-err-mifid',
  templateUrl: './mifid.component.html',
  styleUrls: ['./mifid.component.scss']
})
export class MiFidComponent implements OnInit, OnDestroy {
  documentTypes$: Observable<string[]> = this.store.pipe(select(fromSelectors.getDocumentTypes));
  data$: Observable<DatatableData<ErrMifidFile[]>> = this.store.pipe(select(fromSelectors.getData));
  loading$: Observable<boolean> = this.store.pipe(select(fromSelectors.getLoading));
  messages$: Observable<Message[]> = this.store.pipe(select(fromSelectors.getMessages));
  paging$: Observable<PagingFilter> = this.store.pipe(select(fromSelectors.getPaging));
  sorting$: Observable<SortingFilter> = this.store.pipe(select(fromSelectors.getSorting));
  filters$: Observable<ErrMifidFileFilters> = this.store.pipe(select(fromSelectors.getFilters));
  uploadPaneVisible$: Observable<boolean> = this.store.pipe(select(fromSelectors.uploadPaneVisible));
  maxUploadFileSizeInBytes$: Observable<number> = this.store.pipe(select(fromSelectors.maxUploadFileSizeInBytes));
  columns$: Observable<Column[]> = this.store.pipe(select(fromSelectors.getColumns));
  viewBy$: Observable<ErrMifidViewByTypes> = this.store.pipe(select(fromSelectors.viewBy));
  options$: Observable<string[]> = this.store.pipe(select(fromSelectors.getAvailableDocumentTypes));

  subscription$: Subscription;

  constructor(private store: Store<ErrMifidFile>,
              private organisationService: OrganisationService,
              public readonly errorMessageService:ErrorMessageService) { }

  ngOnInit(): void {
    this.store.dispatch(new fromAction.SetOrganisationIdAction(this.organisationService.getCachedOrganisationId()));
    this.store.dispatch(new fromAction.LoadPresetValuesAction());

    this.subscription$ = this.organisationService
      .getChangeOrganisationObservable()
      .pipe(
        withLatestFrom(this.store.pipe(select(fromSelectors.getOrganisationId))))
      .subscribe(((data: [OrganisationModel, number]) => {
        const [organisation, organisationId] = data;
        if ((organisation && organisation.organisationId !== organisationId) || (!organisation && organisationId)) {
          this.store.dispatch(new fromAction.SetToDefaultAction());
          this.store.dispatch(new fromAction.SetOrganisationIdAction(organisation ? organisation.organisationId : null));
          this.store.dispatch(new fromAction.LoadPresetValuesAction());
        }
      }));
  }

  ngOnDestroy(): void {
    this.store.dispatch(new fromAction.SetToDefaultAction());
    this.subscription$.unsubscribe();
  }

  onReset() {
    this.store.dispatch(new fromAction.ClearFilters());
  }

  onSearchByFilename(filter) {
    this.store.dispatch(new fromAction.FilterByNameAction(filter));
  }

  onSearchByTime(request) {
    this.store.dispatch(new fromAction.FilterByDateAction(request));
  }

  onUpload(file: File): void {
    this.store.dispatch(new fromAction.UploadAction(file));
  }

  onUploadError(messages: Message[]): void {
    this.store.dispatch(new fromAction.SetMessagesAction(messages));
  }

  onLazyLoad(state: DatatableState): void {
    this.store.dispatch(new fromAction.SortAndPageDataAction({
      paging: {
        page: state.page,
        entriesPerPage: state.perPage
      },
      sorting: {
        columnName: state.sortField,
        sortOrder: state.sortOrder
      }
    }));
  }

  onExport(): void {
    this.store.dispatch(new fromAction.ExportAction());
  }

  onDownloadDocument(row: ErrMifidFile): void {
    this.store.dispatch(new fromAction.DownloadDocumentAction(
      { dataStoreId: row.dataStoreId, filename: row.filename }));
  }

  getDisplayParams(row: ErrMifidFile) {
    return {
      displayValue: `${row.filename} ${row.receiptDate ?
        ` received at ${moment(row.receiptDate).format('DD/MM/YYYY HH:mm:ss')}` : ''}`,
      fileId: row.id,
      urlPart: 'mifid',
      auditGroupId: row.auditGroupId
    };
  }

  onConfirm(row: ErrMifidFile) {
    this.store.dispatch(new fromAction.ConfirmItemAction(row.id));
  }

  getDetailsParams(row: ErrMifidFile) {
    return { fileId: row.id };
  }
}
