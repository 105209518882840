import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'cms-search-textarea',
  templateUrl: './search-textarea.component.html',
  styleUrls: ['./search-textarea.component.scss']
})
export class SearchTextareaComponent {
  @Input() disabled: boolean;
  @Input() id: string;
  @Input() formGroup: UntypedFormGroup;
  @Input() label: string;
  @Output() submit: EventEmitter<string> = new EventEmitter<string>();
  valid: boolean = true;

  submitForm(): void {
      this.valid = !!this.formGroup.valid;
  }

}
