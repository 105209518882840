import { ButtonModule } from 'primeng/button';
import { CmsCommonModule } from '@common/cms-common.module';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EffectsModule } from '@ngrx/effects';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { NgModule } from '@angular/core';
import { RadioButtonModule } from 'primeng/radiobutton';
import { StoreModule } from '@ngrx/store';
import { TableModule } from 'primeng/table';
import { metaReducers } from '@common/state/reducers';
import { StandingInstructionsComponent } from './standing-instructions.component';
import { StandingInstructionsUploadComponent } from './upload/standing-instructions-upload.component';
import { StandingInstructionsService } from './standing-instructions.service';
import { StandingInstructionsDatatableComponent } from './datatable/standing-instructions-datatable.component';
import { reducers } from './upload/store';
import { StandingInstructionsUploadEffects } from './upload/store/standing-instructions-upload.effects';
import { BrowserModule } from '@angular/platform-browser';
import { TooltipModule } from 'primeng/tooltip';
import { StandingInstructionsEditorComponent } from './form/standing-instructions-editor.component';

@NgModule({
  imports: [
    CommonModule,
    CmsCommonModule,
    BrowserModule,
    FormsModule,
    MessageModule,
    MessagesModule,
    ReactiveFormsModule,
    TableModule,
    StoreModule.forFeature('standing-instructions', reducers, { metaReducers }),
    EffectsModule.forFeature([
      StandingInstructionsUploadEffects
    ]),
    DialogModule,
    RadioButtonModule,
    ButtonModule,
    MultiSelectModule,
    DropdownModule,
    TooltipModule,
  ],
  providers: [
    StandingInstructionsService
  ],
  exports: [
    StandingInstructionsEditorComponent
  ],
  declarations: [
    StandingInstructionsComponent,
    StandingInstructionsUploadComponent,
    StandingInstructionsDatatableComponent,
    StandingInstructionsEditorComponent
  ]
})
export class StandingInstructionsModule {
}
