<section class="panel-box cms-invoice-finder-filters-container">
  <form class="align-items-center form" [formGroup]="form">
    <div class="row">
      <div class="col-12 col-md-4">
        <div>
          <label class="text-bold">Counterparty</label>
          <p-multiSelect selectedItemsLabel="All ({0})"
                         [style]="{'width':'100%'}"
                         [maxSelectedLabels]="counterPartyOptions.length - 1"
                         inputId="esm-finder-multiselect-cp"
                         id="esm-finder-multiselect-cp"
                         [filter]="true"
                         [options]="counterPartyOptions"
                         tabindex="5"
                         ariaFilterLabel="None"
                         [ngClass]="{'error': errors['counterPartyOrgIds']}"
                         formControlName="counterPartyOrgIds">
          </p-multiSelect>
        </div>
        <div>
          <label class="text-bold">Delivery Point</label>
          <p-multiSelect selectedItemsLabel="All ({0})"
                         inputId="esm-finder-multiselect-dp"
                         [options]="deliveryPointsOptions"
                         [maxSelectedLabels]="deliveryPointsOptions.length - 1"
                         id="esm-finder-multiselect-dp"
                         [filter]="false"
                         tabindex="6"
                         [style]="{'width':'100%'}"
                         placeholder="None"
                         [ngClass]="{'error': errors['deliveryPoints']}"
                         formControlName="deliveryPoints">
          </p-multiSelect>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div>
          <label class="text-bold">Commodity</label>
          <p-multiSelect selectedItemsLabel="All ({0})"
                         [maxSelectedLabels]="commodityOptions.length - 1"
                         id="esm-finder-multiselect-cmdt"
                         [filter]="false"
                         [options]="commodityOptions"
                         [style]="{'width':'100%'}"
                         tabindex="8"
                         placeholder="None"
                         [ngClass]="{'error': errors['commodities']}"
                         formControlName="commodities">
          </p-multiSelect>
        </div>
        <div>
          <label class="text-bold">Invoice Type</label>
          <p-multiSelect selectedItemsLabel="All ({0})"
                         [maxSelectedLabels]="invoiceTypesOptions.length - 1"
                         id="esm-finder-multiselect-it"
                         [filter]="false"
                         [options]="invoiceTypesOptions"
                         [style]="{'width':'100%'}"
                         tabindex="9"
                         placeholder="None"
                         [ngClass]="{'error': errors['invoiceTypes']}"
                         formControlName="invoiceTypes">
          </p-multiSelect>
        </div>
      </div>
      <div class="col-12 col-md-4 px-0">
        <div class="row position-relative">
          <label class="text-bold col">Submission Date Range</label>
          <div class="col-12 col-xs-12 col-md-5">
            <p-calendar placeholder="From Date"
                        id="submissionDateStart"
                        formControlName="submissionDateStart"
                        showIcon="true"
                        monthNavigator="true"
                        yearNavigator="true"
                        dateFormat="dd/mm/yy"
                        [maxDate]="defaultMaxDate"
                        [ngClass]="{'error': errors['submissionDateStart']}"
                        cmsDateRestriction>
            </p-calendar>
          </div>
          <div class="col-12 col-xs-12 col-md-5 ps-2 date">
            <p-calendar placeholder="To Date"
                        id="submissionDateEnd"
                        formControlName="submissionDateEnd"
                        showIcon="true"
                        monthNavigator="true"
                        yearNavigator="true"
                        dateFormat="dd/mm/yy"
                        [maxDate]="defaultMaxDate"
                        [ngClass]="{'error': errors['submissionDateEnd']}"
                        cmsDateRestriction>
            </p-calendar>
          </div>
          <cms-additional-date-range
            class="col-auto ps-2"
            [form]="form"
            [startField]="'submissionDateStart'"
            [endField]="'submissionDateEnd'">
          </cms-additional-date-range>
        </div>
        <div class="row gutter-0 calendar">
          <div class="col-12 col-xs-12 col-md-5">
            <label class="text-bold">Invoice Period Start</label>
            <p-calendar id="invoiceStartDate"
                        formControlName="invoiceStartDate"
                        showIcon="true"
                        monthNavigator="true"
                        yearNavigator="true"
                        dateFormat="dd/mm/yy"
                        placeholder="From Date"
                        [ngClass]="{'error': errors['invoiceStartDate']}"
                        cmsDateRestriction>
            </p-calendar>
          </div>
          <div class="col-12 col-xs-12 col-md-5 ps-2">
            <label class="text-bold">Invoice Period End</label>
            <p-calendar id="invoiceEndDate"
                        formControlName="invoiceEndDate"
                        showIcon="true"
                        monthNavigator="true"
                        placeholder="To Date"
                        yearNavigator="true"
                        dateFormat="dd/mm/yy"
                        [ngClass]="{'error': errors['invoiceEndDate']}"
                        cmsDateRestriction>
            </p-calendar>
          </div>
          <div class="col-auto ps-2">
              <label class="text-bold">&nbsp;</label>
              <cms-additional-date-range
                class="col-auto ps-2"
                [form]="form"
                [startField]="'invoiceStartDate'"
                [endField]="'invoiceEndDate'">
              </cms-additional-date-range>
          </div>
        </div>
        <div class="row gutter-0 calendar">
          <label class="text-bold col px-0">Payment Date Range</label>
          <div class="col-12 col-xs-12 col-md-5">
            <p-calendar id="paymentDateStart"
                        formControlName="paymentDateStart"
                        placeholder="From Date"
                        showIcon="true"
                        monthNavigator="true"
                        yearNavigator="true"
                        dateFormat="dd/mm/yy"
                        [ngClass]="{'error': errors['paymentDateStart']}"
                        cmsDateRestriction>
            </p-calendar>
          </div>
          <div class="col-12 col-xs-12 col-md-5 ps-2">
            <p-calendar id="paymentDateEnd"
                        formControlName="paymentDateEnd"
                        placeholder="To Date"
                        showIcon="true"
                        monthNavigator="true"
                        yearNavigator="true"
                        dateFormat="dd/mm/yy"
                        [ngClass]="{'error': errors['paymentDateEnd']}"
                        cmsDateRestriction>
            </p-calendar>
          </div>
        </div>
      </div>
    </div>
    <div class="row align-items-baseline margin-top-12 btn-container">
      <div class="col-12 col-xs-6 col-md-6" *ngIf="filters && !filters.webUser">
        <label class="radio-label text-bold margin-right-9">Sender</label>
        <label class="radio-label margin-right-9">
          <input type="radio"
                formControlName="senderUs"
                id="US"
                [value]="true"
                class="margin-right-3"
                [ngClass]="{'error': errors['senderUs']}"
                name="senderUs"/>Us
        </label>
        <label class="radio-label margin-right-9">
          <input type="radio"
                formControlName="senderUs"
                id="THEM"
                [value]="false"
                class="margin-right-3"
                [ngClass]="{'error': errors['senderUs']}"
                name="senderUs"/>Them
        </label>
      </div>
      <div class="col-12 col-xs-6 col-md-6" *ngIf="filters && filters.webUser"></div>
      <div class="col-12 col-xs-6 col-md-6 btn__container text-end">
         <cms-filters-management-menu [filters]="myFilters"
                                      [defaultFilter]="defaultFilter"
                                      [dialog]="dialog"
                                      [disableSaveCurrent]="disableSaveCurrent || (form && form.invalid)"
                                      (select)="selectMyFilter.emit($event)"
                                      (replaceFilter)="onReplaceFilter($event)"
                                      (saveAs)="onSaveFilter($event)"
                                      (setDefault)="setDefaultFilter.emit($event)"
                                      (closeDialog)="closeDialog.emit();"
                                      (delete)="deleteFilter.emit($event)"
                                      (manageFilters)="manageFilters.emit()"
                                      (saveCurrent)="openSaveCurrent.emit()">
        </cms-filters-management-menu>
        <button class="btn margin-top-3 margin-bottom-3 margin-left-12" type="submit" id="submitSearchFilter" (click)="onFilter()" [disabled]="!(form && form.valid) || disabled">Search
        </button>
        <button class="btn margin-top-3 margin-bottom-3 margin-left-12  margin-right-12" type="button" (click)="onReset()">Reset</button>
      </div>
    </div>
  </form>
</section>
