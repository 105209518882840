import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { RemitTradeEntryTable2ModifyService } from './remit-trade-entry-table2-modify.service';
import { AuthenticationService } from '../../../../../auth/authentication.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { RemitTradeEntryTable2Service } from '../../../trade-entry/table2/remit-trade-entry-table2.service';
import { ActivatedRoute } from '@angular/router';
import {
  RemitTable2TradeDeliveryModel,
  RemitTable2TradeEntryModel,
  RemitTable2TradeFixingIndexModel,
  RemitTable2TradeOptionDetailModel,
  RemitTable2TradeOtherMarketparticipantModel,
  RemitTable2TradePriceModel,
  RemitTable2TradeProductDetailsModel,
  RemitTable2TradeReferencesModel,
  RemitTable2TradeReportingCounterpartyModel,
  RemitTable2TradeVolumeModel,
  RemitTable2TradeVolumeOptionalityIntervalModel
} from './remit-table2-trade-entry.model';
import { DateUtils } from '../../../../../common/shared/date.utils';
import { Message } from 'primeng/api';

@Component({
  selector: 'cms-remit-trade-table2-modify',
  templateUrl: './remit-trade-table2-modify.component.html',
  styleUrls: ['./remit-trade-table2-modify.component.scss']
})
export class RemitTradeTable2ModifyComponent implements OnInit, OnDestroy {

  private onDestroy$ = new Subject();
  tradeForm: UntypedFormGroup;

  inProgress: boolean = false;
  messages: Message[] = [];
  modifyDisabled: boolean;

  historyId: string;

  showModifyForm: boolean = true;
  submitEnabled: boolean = false;
  initializing: number = -1;
  tradeEntry: RemitTable2TradeEntryModel;

  constructor(private formBuilder: UntypedFormBuilder, private titleService: Title, private tradeEntryService: RemitTradeEntryTable2Service, private remitTradeModifyService: RemitTradeEntryTable2ModifyService,  private route: ActivatedRoute) {
    this.tradeForm = this.formBuilder.group({});
  }

  ngOnInit() {

    this.titleService.setTitle('Modify Trade');
    this.messages = [];

    this.route.queryParams.subscribe(params => {
      this.historyId = params['historyId'];
      this.remitTradeModifyService.loadTrade(this.historyId);
    });

    // load form values for trade modification
    this.tradeEntryService.loadTradeModifyData();

    this.tradeEntryService.onMessageChange()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        errorMessages => {
          if (errorMessages) {
            this.messages = [...this.messages, errorMessages];
          } else {
            this.messages = [];
          }
        }
      );

    // when trade loaded
    this.remitTradeModifyService.onTradeLoaded().pipe(takeUntil(this.onDestroy$)).subscribe(tradeEntry => {
      if(tradeEntry && tradeEntry.references){
        this.showModifyForm = true;
        this.tradeEntry = tradeEntry;
        this.submitEnabled = tradeEntry.submitEnabled;
      }else{
        this.showModifyForm = false;
      }
     this.initializing++;
    });

    // handle errors of modification setup
    this.remitTradeModifyService.onMessageChange()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        errorMessages => {
          if (errorMessages) {
            this.messages = [...this.messages, errorMessages];
          } else {
            this.messages = [];
          }
        }
      );

    // handle errors of modification
    this.tradeEntryService.onValidationFieldErrors()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        fieldName => {
          if (this.tradeForm.controls[fieldName]) {
            this.tradeForm.controls[fieldName].setErrors({ error: true });
          }
        }
      );

    // set modified disabled if modification was successful
    this.tradeEntryService.onTradeModified().pipe(takeUntil(this.onDestroy$))
      .subscribe(modified => {
        if(modified){
          this.modifyDisabled = true;
          window.scrollTo(0, 0);
          this.tradeForm.disable();

        }
      });

    this.initializing = 0;
  }

  submit() {
    const trade = this.tradeForm.value;
    this.messages = [];

    Object.keys(this.tradeForm.controls).forEach(key => {
      this.tradeForm.controls[key].setErrors(null);
    });

    // format dates - contract
    const contractDate = DateUtils.toISOStringWithIgnoreTimezoneOffset(this.tradeForm.controls['contractDate'].value);

    // format dates -startDate
    const startDate: Date = this.tradeForm.controls['deliveryStart'].value;
    const isoStartDate = DateUtils.toISOStringWithIgnoreTimezoneOffset(startDate);

    // format dates - end date
    const endDate: Date = this.tradeForm.controls['deliveryEnd'].value;
    const isoEndDate = DateUtils.toISOStringWithIgnoreTimezoneOffset(endDate);

    // format date in option Details
    let optionDetails = this.tradeForm.controls['optionDetails'].value;
    optionDetails = optionDetails.map( o => {
      return {
        ...o,
        firstExerciseDate: DateUtils.toISOStringWithIgnoreTimezoneOffset(o.firstExerciseDate),
        lastExerciseDate: DateUtils.toISOStringWithIgnoreTimezoneOffset(o.lastExerciseDate),
      };
    });

    let volumeOptionalityIntervals = this.tradeForm.controls['volumeOptionalityIntervals'].value;
    volumeOptionalityIntervals = volumeOptionalityIntervals.map( o => {
      return {
        ...o,
        startDate: DateUtils.toISOStringWithIgnoreTimezoneOffset(o.startDate),
        endDate: DateUtils.toISOStringWithIgnoreTimezoneOffset(o.endDate),
      };
    });

    // format dates - in fixing index
    let fixingIndices = this.tradeForm.controls['fixingIndices'].value;
    fixingIndices = fixingIndices.map( o => {
      return {
        ...o,
        firstFixingDate: DateUtils.toISOStringWithIgnoreTimezoneOffset(o.firstFixingDate),
        lastFixingDate: DateUtils.toISOStringWithIgnoreTimezoneOffset(o.lastFixingDate),
      };
    });

    const deliveryPointsOrZones = this.tradeForm.controls['deliveryPointOrZones'].value;
    const dpzs = deliveryPointsOrZones.map(v => v.deliveryPointOrZone );

    const tradeEntry = {
      ...this.tradeForm.value,
      contractDate: contractDate,
      deliveryStart: isoStartDate,
      deliveryEnd: isoEndDate,
      optionDetails: optionDetails,
      volumeOptionalityIntervals: volumeOptionalityIntervals,
      fixingIndices: fixingIndices,
      deliveryPointOrZones: dpzs
    };

    this.tradeEntryService.modify(tradeEntry,  this.historyId);
    this.submitEnabled = false;
  }

  get referenceModel(): RemitTable2TradeReferencesModel{
    return this.tradeEntry ? this.tradeEntry.references : null;
  }
  get productDetailsModel(): RemitTable2TradeProductDetailsModel{
    return this.tradeEntry ? this.tradeEntry.productDetails : null;
  }
  get reportingCounterpartyModel(): RemitTable2TradeReportingCounterpartyModel{
    return this.tradeEntry ? this.tradeEntry.reportingCounterparty : null;
  }
  get deliveryModel(): RemitTable2TradeDeliveryModel {
    return this.tradeEntry ? this.tradeEntry.delivery : null;
  }
  get optionDetails(): RemitTable2TradeOptionDetailModel[] {
    return this.tradeEntry ? this.tradeEntry.optionDetails : null;
  }

  get volumeModel(): RemitTable2TradeVolumeModel {
    return this.tradeEntry ? this.tradeEntry.volume : null;
  }
  get volumeOptionalityIntervals(): RemitTable2TradeVolumeOptionalityIntervalModel[] {
    return this.tradeEntry ? this.tradeEntry.volumeOptionalityIntervals : null;
  }
  get priceModel(): RemitTable2TradePriceModel {
    return this.tradeEntry ? this.tradeEntry.price : null;
  }
  get fixingIndices(): RemitTable2TradeFixingIndexModel[] {
    return this.tradeEntry ? this.tradeEntry.fixingIndices: null;
  }
  get otherMarketParticipants(): RemitTable2TradeOtherMarketparticipantModel[] {
    return this.tradeEntry ? this.tradeEntry.otherMarketParticipants: null;
  }

  isInProgress(): boolean {
    return this.inProgress;
  }

  isDisabled(): boolean {
    return !this.submitEnabled && this.tradeForm.enabled;
  }

  ngOnDestroy(): void {
    this.submitEnabled = false;
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
    this.tradeEntryService.clearTradeEntryFormData();
    this.remitTradeModifyService.reset();
    this.messages = [];
    this.historyId = null;
  }
}
