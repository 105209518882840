import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { AddBankDetailValue, dataTableColumns, EsmBankDetails, EsmExternalFormValues, EsmMessagesModel, initialBankValue } from '../../master-data.model';
import { EsmExternalMasterDataPresetValues } from '../external-master-data.model';
import * as fromMDSelectors from '../../../state/master-data/external-master-data/external-master-data.selectors';
import * as fromActions from '../../../state/master-data/external-master-data/external-master-data.actions';
import { ESM_MD_DIALOGS } from 'app/esm/state/master-data/master-data.reducer';
import { DialogState } from '@common/state/reducers';
import { EsmExternalBankDetails } from 'app/esm/state/master-data/external-master-data/external-master-data.reducer';
import { DatatableState } from '@common/datatable/datatable.model';
import { EditableCell } from '@common/datatable/cell-editor/cell-editor.model';

@Component({
  selector: 'cms-external-master-data-details',
  templateUrl: './external-master-data-details.component.html',
  styleUrls: ['./external-master-data-details.component.scss']
})
export class ExternalMasterDataDetailsComponent implements OnInit, OnDestroy {
  
  presetValues$: Observable<EsmExternalMasterDataPresetValues> = this.store.select(fromMDSelectors.getPresetValues);
  formValues$: Observable<EsmExternalFormValues> = this.store.select(fromMDSelectors.getDetailsFormValues);
  bankData$: Observable<EsmExternalBankDetails> = this.store.select(fromMDSelectors.getDetailsBankData);
  messages$: Observable<EsmMessagesModel> = this.store.select(fromMDSelectors.getDetailsMessages);
  dialog$: Observable<DialogState<ESM_MD_DIALOGS>> = this.store.select(fromMDSelectors.getDialog);
  isNewRecord$: Observable<boolean> = this.store.select((fromMDSelectors.getIsNewRecord));
  masterDataId: number;
  version: number;
  subscription$ = new Subscription();
  bankDetailValue: AddBankDetailValue = initialBankValue;

  bankDetailColumns = dataTableColumns;

  constructor(private store: Store) { }

  ngOnInit() {
    this.subscription$.add(this.formValues$.subscribe(formValues => {
      this.masterDataId = formValues? formValues.masterDataId : null;
      this.version = formValues? formValues.version : null;
    }));
    this.subscription$.add(this.presetValues$.subscribe(value => {
      this.bankDetailColumns = value && value.uploadAllowed ?
        dataTableColumns : dataTableColumns.filter(item => item.type !== 'deleteButton');
    }));
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
  }

  onBack() {
    this.store.dispatch(fromActions.clearDetails());
  }

  onMasterDataDelete() {
    this.store.dispatch(fromActions.openDialogAction({
      name: 'DELETE'
    }));
  }

  onConfirmDelete() {
    this.store.dispatch(fromActions.deleteMDRecord({
      masterDataId: this.masterDataId,
      version: this.version
    }));
  }

  onCancelDelete() {
    this.store.dispatch(fromActions.closeDialogAction());
  }

  onSave(value: EsmExternalFormValues) {
    if (value) {
      if (value.addressDetails.country) {
        value.addressDetails.country = value.addressDetails.country.toUpperCase();
      }
      if (value.otherAddressDetails.country) {
        value.addressDetails.country = value.addressDetails.country.toUpperCase();
      }
      if (value.companyRegistry.country) {
        value.companyRegistry.country = value.companyRegistry.country.toUpperCase();
      }
      value.version != null ? this.store.dispatch(fromActions.updateAction(value)) : this.store.dispatch(fromActions.persistAction(value));
    }
  }

  onAddBankDetail(data: AddBankDetailValue) {
    this.store.dispatch(fromActions.addBankDetailAction(
      {
        data: data,
        masterDataId: this.masterDataId,
        version: this.version
      }
    ));
  }

  onLazyLoad({page, perPage, sortField, sortOrder}: DatatableState) {
    this.store.dispatch(fromActions.bankDetailsPagingAndSorting({
      paging: { page, entriesPerPage: perPage},
      sorting: { sortOrder, columnName: sortField}
    }));
  }

  onDeleteBankDetail(data: EsmBankDetails) {
    this.store.dispatch(fromActions.deleteBankDetailAction(data));
  }

  onCancelBDEdit() {
    this.store.dispatch(fromActions.setDetailsMessages({ bankDetails: [] }));
  }

  onCompleteBDEdit(editingCell: EditableCell) {
    if (!editingCell) {
      return;
    }
    const { newValue, data: { bankDetailsId, version }, column: { name } } = editingCell;
    this.store.dispatch(fromActions.updateBankDetailAction({ 
      data :{
        newValue, bankDetailsId, name, version
      }, 
      masterDataId: this.masterDataId
    }));
  }

}
