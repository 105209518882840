import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { Message } from 'primeng/api';
import { select, Store } from '@ngrx/store';
import { OrganisationService } from '@common/organisation.service';
import { Column } from '@common/shared/datatable.model';
import { DatatableState } from '@common/datatable/datatable.model';
import { OrganisationModel } from '@common/organisation.model';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { SetOrganisationIdAction } from '../../state/organisation-id/organisation-id.actions';
import { defaultStyles, ElcomWhitelistFilters, ElcomWhitelistValues } from './elcom-whitelist.model';
import { Prefix } from '../../state/reducers';
import { ElcomWhitelistState } from '../../state/elcom-whitelist/elcom-whitelist.reducers';
import * as fromSelectors from '../../state/elcom-whitelist/elcom-whitelist.selectors';
import * as fromDbActions from '../../state/datatable/datatable.actions';
import * as fromActions from '../../state/elcom-whitelist/elcom-whitelist.actions';
import * as fromDtActions from '../../state/datatable/datatable.actions';

@Component({
  selector: 'cms-elcom-whitelist',
  templateUrl: './elcom-whitelist.component.html',
  styleUrls: ['./elcom-whitelist.component.scss']
})
export class ElcomWhitelistComponent implements OnInit, OnDestroy {
  data$: Observable<DatatableData<ElcomWhitelistValues[]>> = this.store.pipe(select(fromSelectors.getData));
  loading$: Observable<boolean> = this.store.pipe(select(fromSelectors.getLoading));
  sorting$: Observable<SortingFilter> = this.store.pipe(select(fromSelectors.getSorting));
  paging$: Observable<PagingFilter> = this.store.pipe(select(fromSelectors.getPaging));
  messages$: Observable<Message[]> = this.store.pipe(select(fromSelectors.getMessages));
  filters$: Observable<ElcomWhitelistFilters> = this.store.pipe(select(fromSelectors.getFilters));
  getAcerCodeValue$: Observable<string> = this.store.pipe(select(fromSelectors.getAcerCodeValue));
  subscription$: Subscription;
  prefix: Prefix = '[ELCOM WHITELIST]';
  display: boolean;
  acerCode: string;
  readonly dataTableColumns: Column[] = [
    { name: 'acerCode', type: 'text', title: 'ACER Code', style: { ...defaultStyles }, sortable: true, visible: true },
    { name: 'orgGroupName', type: 'text', title: 'Organisation Group', style: { ...defaultStyles }, sortable: true, visible: true },
    { name: 'orgName', type: 'text', title: 'Organisation', style: { ...defaultStyles }, sortable: true, visible: true },
    { name: 'orgEic', type: 'text', title: 'EIC Code', style: { ...defaultStyles }, sortable: true, visible: true },
    { name: 'lei', type: 'text', title: 'LEI', style: { ...defaultStyles }, sortable: true, visible: true },
    { name: 'leiRegName', type: 'textarea', title: 'LEI Registered Name', style: { ...defaultStyles, 'width': '270px', 'top': '1px' }, sortable: true, visible: true },
    { name: 'leiCountry', type: 'text', title: 'Country', style: { ...defaultStyles }, sortable: true, visible: true },
    { name: 'extParty', type: 'text', title: 'External', style: { ...defaultStyles, 'text-align': 'right' }, sortable: true, visible: true },
    { name: 'delete', type: 'button', title: 'Delete', style: { ...defaultStyles, 'text-align': 'center' }, sortable: false, visible: true }
  ];

  constructor(private store: Store<ElcomWhitelistState>,
              private organisationService: OrganisationService,
              private mapper: MessagesMapperService) { }

  ngOnInit(): void {
    this.store.dispatch(new SetOrganisationIdAction(this.prefix, this.organisationService.getCachedOrganisationId()));
    this.store.dispatch(new fromDbActions.FilterDataAction(this.prefix, { filter: null }));
    this.display = false;

    this.subscription$ = this.organisationService
      .getChangeOrganisationObservable()
      .pipe(
        withLatestFrom(this.store.pipe(select(fromSelectors.getOrganisationId))))
      .subscribe(([organisation, organisationId]: [OrganisationModel, number]) => {
        if ((organisation && organisation.organisationId !== organisationId) || (!organisation && organisationId)) {
          this.store.dispatch(fromActions.setToDefaultAction());
          this.store.dispatch(new SetOrganisationIdAction(this.prefix, organisation ? organisation.organisationId : null));
          this.store.dispatch(new fromDbActions.FilterDataAction(this.prefix, { filter: null }));
        }
      });
  }

  onLazyLoad(state: DatatableState): void {
    this.store.dispatch(new fromDbActions.SortAndPageDataAction(this.prefix,{
      paging: {
        page: state.page,
        entriesPerPage: state.perPage
      },
      sorting: {
        columnName: state.sortField,
        sortOrder: state.sortOrder
      }
    }));
  }

  onTextFieldFilter(filters: string): void {
    this.store.dispatch(new fromDbActions.FilterDataAction(this.prefix, { filter: filters }));
  }

  onDelete(acerCode: string): void {
    this.acerCode = acerCode;
    this.display = true;
  }

  onConfirm(): void {
    this.store.dispatch(fromActions.deleteElcomWhitelistAction(this.acerCode));
    this.display = false;
  }

  onCancel(): void {
    this.display = false;
    this.store.dispatch(new fromDtActions.SetMessagesAction(this.prefix, this.mapper.toWarningMessages(`Deletion of ${this.acerCode} cancelled.`)));
  }

  onAdd(acerCode: string): void {
    this.store.dispatch(fromActions.addElcomWhitelistAction(acerCode));
  }

  onClear(): void {
    this.store.dispatch(fromActions.clearElcomWhitelistAction());
  }

  ngOnDestroy(): void {
    this.store.dispatch(fromActions.setToDefaultAction());
    this.subscription$.unsubscribe();
  }
}
