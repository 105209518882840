<cms-datatable [paginator]="false"
               [data]="buckets"
               [tableColumns]="columns"
               [autoLayout]="true"
               [scrollable]="false"
               [loading]="loading"
               [lazy]="false">
  <ng-template pTemplate="body" let-row="row" let-column="column">
    <td *ngIf="column.type === 'link'" [ngStyle]="column.style" (click)="loadTransactionTable(column, row)" [ngClass]="selectedCell && selectedCell.data === row && selectedCell.column === column ? 'active' : ''">
      <a>{{row[column.name]}}</a>
    </td>
  </ng-template>
</cms-datatable>
