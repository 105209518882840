import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { MailAlertsService } from '../mail-alerts.service';

@Injectable()
export class GlobalEffects {
   loadData$ = createEffect(() => this.mailAlertsService.loadData('[MAIL ALERTS GLOBAL]', 'GLOBAL'));
    updateStatus$ = createEffect(() => () => this.mailAlertsService.updateStatus('[MAIL ALERTS GLOBAL]','GLOBAL'));

  constructor(private mailAlertsService: MailAlertsService) { }
}
