import { ErrorResponse } from '@common/error-response.model';
import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import { Column } from '@common/shared/datatable.model';

export interface EsmFile {
  auditGroupId: number;
  commodity: string;
  currency: string;
  customerEic: string;
  datastoreId: number;
  filename: string;
  id: number;
  invPeriodEnd: string;
  invPeriodStart: string;
  market: string;
  organisationId: number;
  state: string;
  submissionDate: string;
  supplierEic: string;
}

export interface EsmFileUploadFilters {
  filename: string;
  dateTimeRange: DateRange;
  searchBy: string;
}

export interface EsmFileResponse extends ErrorResponse {
  values: EsmFile[];
  count: number;
  currentPage: number;
}

export enum EsmFilterType {
  ByDate = 'searchByDateRange',
  ByFilename = 'searchByFilename'
}

export interface DateRange {
  end: string | Date;
  start: string | Date;
}

export interface LoadPresetValuesModel {
  technicalErrors: string[];
  accessValidationFailures: string[];
  validationFailures: string[];
  fieldValidationFailures: { [key: string]: string };
  receivedDateTimeRange: DateRange;
  uploadPanelVisible: boolean;
  success: boolean;
}

export interface PresetValuesModelResponse extends ErrorResponse {
  value: LoadPresetValuesModel;
}

export interface FilterByDateRequest {
  dateTimeRange: DateRange;
  paging: PagingFilter;
  sorting: SortingFilter;
}

export interface FilterByNameRequest {
  filename: string;
  paging: PagingFilter;
  sorting: SortingFilter;
}

export interface MaxUploadSizeResponse extends ErrorResponse {
  response: number;
}

export const defaultStyles = {
  'text-align': 'left',
  'text-overflow': 'ellipsis',
  'overflow': 'hidden',
  'white-space': 'nowrap'
};

export let allColumnsConfig: Column[] = [
  { name: 'submissionDate', type: 'text', title: 'Sub. Date', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'filename', type: 'text', title: 'Filename', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'invPeriodStart', type: 'text', title: 'Inv. Period Start', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'invPeriodEnd', type: 'text', title: 'Inv. Period End', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'supplierEic', type: 'text', title: 'Supplier EIC', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'customerEic', type: 'text', title: 'Customer EIC', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'commodity', type: 'text', title: 'Commodity', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'market', type: 'text', title: 'Market', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'currency', type: 'text', title: 'Currency', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'state', type: 'text', title: 'State', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'document', type: 'button', title: '', style: { ...defaultStyles, 'text-align': 'center' }, sortable: false, visible: true },
  { name: 'auditTrail', type: 'button', title: '', style: { ...defaultStyles, 'text-align': 'center' }, sortable: false, visible: true },
  { name: 'id', type: '', title: '', style: { ...defaultStyles }, sortable: true, visible: false },
  { name: 'organisationId', type: '', title: '', style: { ...defaultStyles }, sortable: true, visible: false },
  { name: 'datastoreId', type: '', title: '', style: { ...defaultStyles }, sortable: true, visible: false },
  { name: 'auditGroupId', type: '', title: '', style: { ...defaultStyles }, sortable: true, visible: false },
];
