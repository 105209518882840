import {Component, OnDestroy, OnInit} from '@angular/core';
import {HelpService} from '../../help.service';
import {AnnouncementResponseModel} from '../../announcementResponseModel';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'cms-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss']
})
export class AnnouncementComponent implements OnInit, OnDestroy {
  announcement: AnnouncementResponseModel = {content: '', title:'', editable: false};
  private onDestroy$ = new Subject();
  editMode: boolean = false;


  constructor(public helpService: HelpService) {
  }

  ngOnInit() {
    this.helpService.onAnnoucementChange.pipe(
      takeUntil(this.onDestroy$))
      .subscribe(announcement => {
        this.announcement = announcement;
    }
      );

    this.helpService.fetchAnnouncement();

  }

  public ngOnDestroy(): void {
    // Clean up all subscriptions at once:
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
    this.editMode = false;
    this.announcement.editable = false;
  }

  get annoucement$(): Observable<AnnouncementResponseModel>{
    return this.helpService.onAnnoucementChange;
  }

  get isEditable(): Observable<boolean> {
   return of(this.announcement.editable);
  }

  get isEditMode(): Observable<boolean> {
    return of(this.announcement.editable && this.editMode);
  }

  get isDisplayMode(): Observable<boolean> {
    return of(!(this.announcement.editable && this.editMode));
  }

  switchEditMode(): void {
    this.editMode = !this.editMode;
  }
}

