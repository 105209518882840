<p-dialog *ngIf="display"
          header="Edit Billing Data Of Selected Organisation Group"
          [visible]="display"
          [modal]="true"
          (visibleChange)="onVisibleChange($event)"
          styleClass="edit-billing-data-dialog"
          [style]="{width: '700px', height: '662px'}">
  <p-messages [value]="messages" [closable]="true"></p-messages>
  <div class="margin-15">
    <form [formGroup]="form" class="row" autocomplete="off">
      <h4 class="col-12 margin-bottom-12">General Billing Data: </h4>
      <div class="col-12 margin-bottom-12 flex">
        <label class="col-4 float-left">Legal Group Name:</label>
        <div class="col-8">
          <input autocomplete="off" id="legalName" type="text" formControlName="legalName" class="form-control col-8 padding-6" [ngClass]="{'error': errors['legalName']}" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label class="col-4 float-left">VAT Number: <span class="text-required">*</span></label>
        <div class="col-8">
          <input autocomplete="off" id="vatNumber" type="text" formControlName="vatNumber" class="form-control col-8 padding-6" [ngClass]="{'error': errors['vatNumber']}" maxlength="25" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label class="col-4 float-left">VAT Rate: <span class="text-required">*</span></label>
        <div class="col-8">
          <input (paste)="onPaste($event, vatRateRegex)" (keypress)="onKeyPress($event, vatRateRegex)" autocomplete="off" pInputText [pKeyFilter]="vatRateRegex" id="vatRate" type="text" formControlName="vatRate" class="form-control col-8 padding-6" [ngClass]="{'error': errors['vatRate']}" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12">
        <label class="col-4 float-left">VAT Code: <span class="text-required">*</span></label>
        <p-dropdown [style]="{'width': '100%'}" [options]="vatCodeOptions"
                    [(ngModel)]="selectedVatId"
                    dataKey="value"
                    optionLabel="label"
                    styleClass="col-8 padding-6"
                    formControlName="vatCode"></p-dropdown>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label class="col-4 float-left">Debtor ID:</label>
        <div class="col-8">
          <input autocomplete="off" id="debtorId" type="text" formControlName="debtorId" class="form-control col-8 padding-6" [ngClass]="{'error': errors['debtorId']}" maxlength="50" />
        </div>
      </div>
      <span class="separator margin-bottom-12"></span>
      <h4 class="col-12 margin-bottom-12">CMS Billing Data: </h4>
      <div class="col-12 margin-bottom-12 flex">
        <label class="col-4 float-left">Invoice Recipient Override:</label>
        <div class="col-8">
          <input autocomplete="off" id="invoiceRecipientOverride" type="text" formControlName="invoiceRecipientOverride" class="form-control col-8 padding-6" [ngClass]="{'error': errors['invoiceRecipientOverride']}" maxlength="100" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label class="col-4 float-left">eRR Free Transactions:</label>
        <div class="col-8">
          <input autocomplete="off" id="errFreeTransactions" type="text" formControlName="errFreeTransactions" class="form-control col-8 padding-6" [ngClass]="{'error': errors['errFreeTransactions']}" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label class="col-4 float-left">eRR Outstanding Balance:</label>
        <div class="col-8">
          <input autocomplete="off" id="errOutstandingBalance" type="text" formControlName="errOutstandingBalance" class="form-control col-8 padding-6" [ngClass]="{'error': errors['errOutstandingBalance']}" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label class="col-4 float-left">Address: <span class="text-required">*</span></label>
        <div class="col-8">
          <input autocomplete="off" id="address00" type="text" formControlName="address00" class="form-control col-8 padding-6" [ngClass]="{'error': errors['address00']}" maxlength="130" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex justify-content-end align-items-right">
        <div class="col-8">
          <input autocomplete="off" id="address01" type="text" formControlName="address01" class="form-control col-8 padding-6" [ngClass]="{'error': errors['address01']}" maxlength="130" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex justify-content-end align-items-right">
        <div class="col-8">
          <input autocomplete="off" id="address02" type="text" formControlName="address02" class="form-control col-8 padding-6" [ngClass]="{'error': errors['address02']}" maxlength="130" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex justify-content-end align-items-right">
        <div class="col-8">
          <input autocomplete="off" id="address03" type="text" formControlName="address03" class="form-control col-8 padding-6" [ngClass]="{'error': errors['address03']}" maxlength="130" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex justify-content-end align-items-right">
        <div class="col-8">
          <input autocomplete="off" id="address04" type="text" formControlName="address04" class="form-control col-8 padding-6" [ngClass]="{'error': errors['address04']}" maxlength="130" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12">
        <label class="col-4 float-left">Exclude From CMS Billing:</label>
        <input autocomplete="off" id="excludeFromBilling" type="checkbox" formControlName="excludeFromBilling" [ngClass]="{'error': errors['excludeFromBilling']}" />
      </div>
      <div class="col-12 margin-bottom-12">
        <label class="col-4 float-left">Contact Details:</label>
      </div>
      <ng-container formArrayName="contactEmails" *ngFor="let email of form.controls['contactEmails'].controls; let i = index">
        <ng-container [formGroupName]="i">
          <div class="col-12 margin-bottom-6 flex">
            <label class="col-4 float-left">{{ 'Email ' + (i + 1) }}: <span class="text-required" *ngIf="i === 0">*</span></label>
            <div class="col-8">
              <input autocomplete="off" id="{{'email' + i}}" type="text" formControlName="email" class="form-control col-8 padding-6" [ngClass]="{'error': errors['contactEmails' + (i + 1)]}" maxlength="60" />
            </div>
          </div>
        </ng-container>
        <span class="separator margin-bottom-6"></span>
      </ng-container>
      <div class="col-12 margin-bottom-12 flex justify-content-end align-items-right">
        <div class="col-8 padding-6"><span class="add margin-right-3" [ngClass]="{'disabled': form.controls['contactEmails'].controls.length === 5}" (click)="onAddEmail()">Add</span><span [ngClass]="{'disabled': form.controls['contactEmails'].controls.length === 1}" class="remove" (click)="onRemoveEmail()">Remove</span></div>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label class="col-4 float-left">Additional Notes:</label>
        <div class="col-8">
          <input autocomplete="off" id="additionalNotes" type="text" formControlName="additionalNotes" class="form-control col-8 padding-6" [ngClass]="{'error': errors['additionalNotes']}" maxlength="80" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12">
        <label class="col-4 float-left">Additional Services:</label>
      </div>
      <ng-container formArrayName="additionalServices" *ngFor="let service of form.controls['additionalServices'].controls; let i = index">
        <ng-container [formGroupName]="i">
          <div class="col-12 margin-bottom-12 flex">
            <label class="col-4 float-left">{{ 'Description ' + (i + 1) }}: <span class="text-required">*</span></label>
            <div class="col-8">
              <input autocomplete="off" id="{{'description' + i}}" type="text" formControlName="description" class="form-control col-8 padding-6" [ngClass]="{'error': errors['description' + (i + 1)]}" maxlength="50" />
            </div>
          </div>
          <div class="col-12 margin-bottom-6 flex">
            <label class="col-4 float-left">{{ 'Amount ' + (i + 1) }}: <span class="text-required">*</span></label>
            <div class="col-8">
              <input pInputText (paste)="onPaste($event, amountRegex)" (keypress)="onKeyPress($event, amountRegex)" [pKeyFilter]="amountRegex" autocomplete="off" id="{{'amount' + i}}" type="text" formControlName="amount" class="form-control col-8 padding-6" [ngClass]="{'error': errors['amount' + (i + 1)]}" />
            </div>
          </div>
        </ng-container>
        <span class="separator margin-bottom-6"></span>
      </ng-container>
      <div class="col-12 margin-bottom-12 flex justify-content-end align-items-right">
        <div class="col-8 padding-6"><span class="add margin-right-3" [ngClass]="{'disabled': form.controls['additionalServices'].controls.length === 10}" (click)="onAddService()">Add</span><span [ngClass]="{'disabled': form.controls['additionalServices'].controls.length === 0}" class="remove" (click)="onRemoveService()">Remove</span></div>
      </div>
      <h4 class="col-12 margin-bottom-12">ACER Billing Data: </h4>
      <div class="col-12 margin-bottom-12">
        <label class="col-4 float-left">Separate ACER Billing Data: </label>
        <input autocomplete="off" id="separateAcerBilling" type="checkbox" formControlName="separateAcerBilling" [ngClass]="{'error': errors['separateAcerBilling']}" />
      </div>
      <ng-container *ngIf="form.controls['separateAcerBilling'].value">
        <div class="col-12 margin-bottom-12 flex">
          <label class="col-4 float-left">ACER Billing Invoice Recipient Override:</label>
          <div class="col-8">
            <input autocomplete="off" id="acerInvoiceRecipientOverride" type="text" formControlName="acerInvoiceRecipientOverride" class="form-control col-8 padding-6" [ngClass]="{'error': errors['acerInvoiceRecipientOverride']}" maxlength="100" />
          </div>
        </div>
        <div class="col-12 margin-bottom-12 flex">
          <label class="col-4 float-left">ACER Billing Address: <span class="text-required">*</span></label>
          <div class="col-8">
            <input autocomplete="off" id="acerAddress00" type="text" formControlName="acerAddress00" class="form-control col-8 padding-6" [ngClass]="{'error': errors['acerAddress00']}" maxlength="130" />
          </div>
        </div>
        <div class="col-12 margin-bottom-12 flex justify-content-end align-items-right">
          <div class="col-8">
            <input autocomplete="off" id="acerAddress01" type="text" formControlName="acerAddress01" class="form-control col-8 padding-6" [ngClass]="{'error': errors['acerAddress01']}" maxlength="130" />
          </div>
        </div>
        <div class="col-12 margin-bottom-12 flex justify-content-end align-items-right">
          <div class="col-8">
            <input autocomplete="off" id="acerAddress02" type="text" formControlName="acerAddress02" class="form-control col-8 padding-6" [ngClass]="{'error': errors['acerAddress02']}" maxlength="130" />
          </div>
        </div>
        <div class="col-12 margin-bottom-12 flex justify-content-end align-items-right">
          <div class="col-8">
            <input autocomplete="off" id="acerAddress03" type="text" formControlName="acerAddress03" class="form-control col-8 padding-6" [ngClass]="{'error': errors['acerAddress03']}" maxlength="130" />
          </div>
        </div>
        <div class="col-12 margin-bottom-12 flex justify-content-end align-items-right">
          <div class="col-8">
            <input autocomplete="off" id="acerAddress04" type="text" formControlName="acerAddress04" class="form-control col-8 padding-6" [ngClass]="{'error': errors['acerAddress04']}" maxlength="130" />
          </div>
        </div>
        <div class="col-12 margin-bottom-12">
          <label class="col-4 float-left">Contact Details:</label>
        </div>
        <ng-container formArrayName="acerContactEmails" *ngFor="let acerEmail of form.controls['acerContactEmails'].controls; let i = index">
          <ng-container [formGroupName]="i">
            <div class="col-12 margin-bottom-6 flex">
              <label class="col-4 float-left">{{ 'Email ' + (i + 1) }}: <span class="text-required" *ngIf="i === 0">*</span></label>
              <div class="col-8">
                <input autocomplete="off" id="{{'acerEmail' + i}}" type="text" formControlName="acerEmail" class="form-control col-8 padding-6" [ngClass]="{'error': errors['acerContactEmails' + (i + 1)]}" maxlength="60" />
              </div>
            </div>
          </ng-container>
          <span class="separator margin-bottom-6"></span>
        </ng-container>
        <div class="col-12 margin-bottom-12 flex justify-content-end align-items-right">
          <div class="col-8 padding-6"><span class="add margin-right-3" [ngClass]="{'disabled': form.controls['acerContactEmails'].controls.length === 5}" (click)="onAddAcerEmail()">Add</span><span [ngClass]="{'disabled': form.controls['acerContactEmails'].controls.length === 1}" class="remove" (click)="onRemoveAcerEmail()">Remove</span></div>
        </div>
        <div class="col-12 margin-bottom-12 flex">
          <label class="col-4 float-left">ACER Billing Additional Notes:</label>
          <div class="col-8">
            <input autocomplete="off" id="acerAdditionalNotes" type="text" formControlName="acerAdditionalNotes" class="form-control col-8 padding-6" [ngClass]="{'error': errors['acerAdditionalNotes']}" maxlength="80" />
          </div>
        </div>
      </ng-container>
    </form>
  </div>
  <p-footer>
    <button class="btn margin-right-3" type="button" (click)="onCancel()">Cancel</button>
    <button class="btn" type="button" (click)="onConfirm()">Update</button>
  </p-footer>
</p-dialog>
