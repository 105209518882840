import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  commitRow,
  loading,
  loadPresets,
  loadPresetsSuccess,
  loadTableData, loadTableDatSuccess, resetRow,
  setMessages, triggerCheckbox, triggerCheckboxSuccess, updateRow
} from './tolerance-settings.actions';
import { catchError, shareReplay, switchMap, withLatestFrom } from 'rxjs/operators';
import { Message } from 'primeng/api';
import {  Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiRequestService } from '@common/api-request.service';
import { Action, select, Store } from '@ngrx/store';
import { MessagesMapperService } from '@common/messages-mapper.service';

import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import { EsmToleranceSettingsState } from './tolerance-settings.reducers';
import { getPaging, getSorting } from './tolerance-settings.selectors';



@Injectable()
export class ToleranceSettingsEffects {
  private baseUrl = '/api/esm/invoice/tolerance/settings';


  constructor(private apiGateway: ApiRequestService,
              private actions$: Actions,
              private store$: Store<EsmToleranceSettingsState>,
              private mapper: MessagesMapperService) {
  }

  load_presets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPresets),
      switchMap(() =>
        this.apiGateway.get(`${this.baseUrl}/presets`)
          .pipe(
            switchMap(response => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              const { supportToleranceMatching, editable } = response.value;

              return messages?.length ?
                [setMessages({ messages })] :
                [setMessages({ messages:[] }), loadPresetsSuccess({ supportToleranceMatching, editable})];
            }),

            catchError(error => of(setMessages(this.mapper.toErrorMessagesObj(error.errorMessage || 'Server Error')))
            ))
      ))
  );


  save_entry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(commitRow),
      switchMap(({ field, data }) =>
        this.apiGateway.post(`${this.baseUrl}/edit/${this.endpoint(field)}`,this.body(field, data))
          .pipe(
            switchMap(response => {
              const messages: Message[] = this.mapper.toErrorMessages(response);

              return messages?.length ?
                [setMessages({ messages }), resetRow()] :
                [setMessages({ messages: [] }), updateRow({ data })];
            }),

            catchError(error => of(setMessages(this.mapper.toErrorMessagesObj(error.errorMessage || 'Server Error'))))
          ))
    )
  );


  toggle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(triggerCheckbox),
      switchMap(({ checked }) =>
        this.apiGateway.post(`${this.baseUrl}/toggle`, { supportToleranceMatching: checked })
          .pipe(
            switchMap(response => {
              const messages: Message[] = this.mapper.toErrorMessages(response);

              return messages?.length ?
                [setMessages({ messages  }), loadPresets()] :
                [setMessages({ messages:[] }), triggerCheckboxSuccess({ checked })];
            }),

            catchError(error => [setMessages(this.mapper.toErrorMessagesObj(error.errorMessage || 'Server Error'))])
          ))
    )
  );


  load_table$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTableData),
      shareReplay(),
      withLatestFrom(this.store$.pipe(select(getPaging)), this.store$.pipe(select(getSorting))),
      switchMap(([, paging, sorting]) => this.loadData(paging, sorting)))
  );


  // ======  helper ====== //

  private loadData(paging: PagingFilter, sorting: SortingFilter): Observable<Action> {
    this.store$.dispatch(loading({load:true }));
    return this.apiGateway.post(`${this.baseUrl}/data`, { sorting, paging })
      .pipe(
        switchMap(response => {
          const messages: Message[] = this.mapper.toErrorMessages(response);
          const { values, currentPage, count } = response;

          return messages?.length ?
            [setMessages({ messages })]:
            [loadTableDatSuccess({ data: { values, count, currentPage }}), loading({load: false})];
        }),

        catchError(error => [
          loading({ load: false }),
          setMessages(this.mapper.toErrorMessagesObj(error.errorMessage || 'Server Error'))])
      );
  }


  // ======  mapper ====== //

  private endpoint = (col: 'absTolerance' | 'prectTolerance') => col === 'absTolerance' ? 'abs' : 'percentage';

  private body = (col, { absTolerance, prectTolerance, id }) => col === 'absTolerance' ? { id, newValue: absTolerance } : { id, newValue: prectTolerance };


}
