import { Action } from '@ngrx/store';
import { Message } from 'primeng/api';
import { UserAccount, UserAccountsFilters } from '../../administration-tab-panel/user-accounts/user-accounts.model';
import { Organisation, OrganisationsFilters } from '../../administration-tab-panel/organisations/organisations.model';
import { DatatableConfig, DatatableData } from '@common/shared/results.model';
import { EditableCell } from '@common/datatable/cell-editor/cell-editor.model';
import { Prefix } from '../reducers';
import { FhaAccount } from '../../administration-tab-panel/fha-accounts/fha-accounts.model';
import { BillingRuns, MailingDetailsValues } from '../../administration-tab-panel/billing-runs/cms/billing-runs.model';
import { PartyData } from '../../administration-tab-panel/party-data/party-data.model';
import {
  BillingHistoryValues,
  OrganisationGroup
} from '../../administration-tab-panel/organisation-groups/organisation-groups.model';
import {
  ElcomWhitelistFilters,
  ElcomWhitelistValues
} from '../../administration-tab-panel/elcom-whitelist/elcom-whitelist.model';

export const INSERT_ITEM = 'Insert Item';
export const LOAD_DATA = 'Load Data';
export const LOAD_DATA_SUCCESS = 'Load Data Success';
export const SELECT = 'Select';
export const FILTER_DATA = 'Filter Data';
export const FILTER_DATA_SUCCESS = 'Filter Data Success';
export const UPDATE_FILTER_DATA = 'Update Filter Data';
export const SET_MESSAGES = 'Set Messages';
export const SORT_AND_PAGE_DATA = 'Sort and Page Data';
export const SET_EDITABLE_DATA = 'Set Editable Data';

export class UpdateFilterAction implements Action {
  get type() {
    return `${this.prefix} ${UPDATE_FILTER_DATA}`;
  }

  constructor(public prefix: Prefix,
              public payload: Partial<OrganisationsFilters | ElcomWhitelistFilters>) {
  }
}

export class FilterDataAction implements Action {
  get type() {
    return `${this.prefix} ${FILTER_DATA}`;
  }

  constructor(public prefix: Prefix,
              public payload: UserAccountsFilters | OrganisationsFilters | ElcomWhitelistFilters | string | number) {
  }
}

export class SetEditableDataAction implements Action {
  get type() {
    return `${this.prefix} ${SET_EDITABLE_DATA}`;
  }

  constructor(public prefix: Prefix,
              public payload: EditableCell) {
  }
}

export class SortAndPageDataAction implements Action {
  get type() {
    return `${this.prefix} ${SORT_AND_PAGE_DATA}`;
  }

  constructor(public prefix: Prefix, public payload: DatatableConfig) {
  }
}

export class FilterDataSuccessAction implements Action {
  get type() {
    return `${this.prefix} ${FILTER_DATA_SUCCESS}`;
  }

  constructor(public prefix: Prefix,
              public payload: DatatableData<(UserAccount | Organisation | FhaAccount | OrganisationGroup | BillingHistoryValues | PartyData | BillingRuns | MailingDetailsValues | ElcomWhitelistValues)[]>) {
  }
}

export class SetMessagesAction implements Action {
  get type() {
    return `${this.prefix} ${SET_MESSAGES}`;
  }

  constructor(public prefix: Prefix,
              public payload: Message[]) {
  }
}

export class InsertItemAction implements Action {
  get type() {
    return `${this.prefix} ${INSERT_ITEM}`;
  }

  constructor(public prefix: Prefix,
              public payload: any) {
  }
}

export class LoadDataAction implements Action {
  get type() {
    return `${this.prefix} ${LOAD_DATA}`;
  }

  constructor(public prefix: Prefix) {
  }
}

export class LoadDataSuccessAction implements Action {
  get type() {
    return `${this.prefix} ${LOAD_DATA_SUCCESS}`;
  }

  constructor(public prefix: Prefix,
              public payload: DatatableData<(FhaAccount | BillingRuns)[]>) {
  }
}

export class SelectAction implements Action {
  get type() {
    return `${this.prefix} ${SELECT}`;
  }

  constructor(public prefix: Prefix,
              public payload: UserAccount | Organisation | FhaAccount | OrganisationGroup) {
  }
}

export type DatatableActions = SelectAction | LoadDataAction | InsertItemAction | SetMessagesAction |
  FilterDataSuccessAction | SortAndPageDataAction | SetEditableDataAction | FilterDataAction | UpdateFilterAction;
