import { Message } from 'primeng/api';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiRequestService } from '@common/api-request.service';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { EsmFileUploadState } from '../../file-upload/file-upload.reducer';
import { catchError, switchMap } from 'rxjs/operators';
import { EsmDashboardPresetFiltersResponse } from '../../../invoice/dashboard/esm-dashboard.model';
import * as fromEsmDBFiltersAction from './filters.actions';
import * as fromEsmDBTableActions from '../table/table.actions';
import * as fromTableActions from '../../invoice-finder/table/table.actions';

@Injectable()
export class EsmDashboardFiltersEffects {
  private baseUrl = '/api/esm/dashboard';

  constructor(private actions$: Actions,
              private apiGateway: ApiRequestService,
              private mapper: MessagesMapperService,
              private store$: Store<EsmFileUploadState>) { }

  loadPresetValues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromEsmDBFiltersAction.loadPresetValuesAction.type,
        fromEsmDBFiltersAction.resetFiltersAction.type
      ),
      switchMap(() =>
        this.apiGateway.get(`${this.baseUrl}/loadPresetValues`)
          .pipe(
            switchMap((response: EsmDashboardPresetFiltersResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return of(fromTableActions.setMessagesAction(messages));
              }
              return [
                fromEsmDBFiltersAction.loadPresetValuesSuccessAction(response.value),
                fromEsmDBTableActions.setMessagesAction([]),
                fromEsmDBFiltersAction.filterAction()
              ];
            }),
            catchError(error => of(fromEsmDBTableActions.setMessagesAction([
              this.mapper.createErrorMessage(error.errorMessage || 'Server Error')
            ]))))
      )
    )
  );
}
