import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Message } from 'primeng/api';
import { ApiRequestService } from '@common/api-request.service';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { Prefix } from '../reducers';
import { select, Store } from '@ngrx/store';
import { PartyDataState } from './party-data.reducers';
import { DatatableState } from '../datatable/datatable.reducer';
import * as fromDtbActions from '../datatable/datatable.actions';
import * as fromSelectors from './party-data.selectors';
import * as fromActions from './party-data.actions';
import { GetPartyDataRequest, GetPartyDataResponse, PartyDataEditableResponse } from '../../administration-tab-panel/party-data/party-data.model';

@Injectable()
export class PartyDataEffects {
  private baseUrl = 'api/administration/partydata';
  private prefix: Prefix = '[PARTY DATA]';

  constructor(private apiGateway: ApiRequestService,
              private actions$: Actions,
              private store$: Store<PartyDataState>,
              private mapper: MessagesMapperService) {}

   loadData$ = createEffect(() => this.actions$
  .pipe(ofType(`${this.prefix} ${fromDtbActions.LOAD_DATA}`),
    withLatestFrom(this.store$.pipe(select(fromSelectors.getFilters))),
    switchMap((data: [fromDtbActions.LoadDataAction, string]) => {
        return of(new fromDtbActions.FilterDataAction(this.prefix, data[1]));
      }
    )));

   filter$ = createEffect(() => this.actions$
  .pipe(ofType(`${this.prefix} ${fromDtbActions.FILTER_DATA}`),
    withLatestFrom(this.store$.pipe(select(fromSelectors.getDatatable))),
    switchMap(([action, state]: [fromDtbActions.FilterDataAction, DatatableState]) => {
      const request: GetPartyDataRequest = {
        paging: state.paging,
        sorting: state.sorting,
        filter: action.payload as string
      };
      return this.loadData(request);
    }
  )));

   sortAndPage$ = createEffect(() => this.actions$
  .pipe(ofType(`${this.prefix} ${fromDtbActions.SORT_AND_PAGE_DATA}`),
    withLatestFrom(this.store$.pipe(select(fromSelectors.getFilters))),
    switchMap(([action, filter]: [fromDtbActions.SortAndPageDataAction, string]) => {
      const { sorting, paging } = action.payload;
      return this.loadData({
        filter,
        sorting,
        paging,
      });
    }
  )));

   deletePartyData$ = createEffect(() => this.actions$
  .pipe(
    ofType(fromActions.deletePartyDataAction.type),
    concatMap((action: ReturnType<typeof fromActions.deletePartyDataAction>) => {
      return this.apiGateway.delete(`${this.baseUrl}/${action.payload}`)
        .pipe(
          map((response: PartyDataEditableResponse) => {
            const messages: Message[] = this.mapper.toErrorMessages(response);
            if (messages && messages.length) {
              return new fromDtbActions.SetMessagesAction(this.prefix, messages);
            }
            return new fromDtbActions.LoadDataAction(this.prefix);
          }),
          catchError(error =>
            of(new fromDtbActions.SetMessagesAction(this.prefix, [this.mapper.createErrorMessage(error.errorMessage)]))
          )
        );
    }),
    catchError(error =>
      of(new fromDtbActions.SetMessagesAction(this.prefix, [this.mapper.createErrorMessage(error.errorMessage)]))
  )));

   editPartyData$ = createEffect(() => this.actions$
  .pipe(
    ofType(fromActions.editPartyDataAction.type),
    concatMap((action: ReturnType<typeof fromActions.editPartyDataAction>) => {
      return this.apiGateway.post(`${this.baseUrl}/edit`, action.payload)
        .pipe(
          map((response: PartyDataEditableResponse) => {
            const messages: Message[] = this.mapper.toErrorMessages(response);
            if (messages && messages.length) {
              return new fromDtbActions.SetMessagesAction(this.prefix, messages);
            }
            return new fromDtbActions.LoadDataAction(this.prefix);
          }),
          catchError(error =>
            of(new fromDtbActions.SetMessagesAction(this.prefix, [this.mapper.createErrorMessage(error.errorMessage)]))
          )
        );
    }),
    catchError(error =>
      of(new fromDtbActions.SetMessagesAction(this.prefix, [this.mapper.createErrorMessage(error.errorMessage)]))
  )));

   addPartyData$ = createEffect(() => this.actions$
  .pipe(
    ofType(fromActions.addPartydataAction.type),
    concatMap((action: ReturnType<typeof fromActions.addPartydataAction>) => {
      return this.apiGateway.post(`${this.baseUrl}/add`, action.payload)
        .pipe(
          switchMap((response: PartyDataEditableResponse) => {
            const messages: Message[] = this.mapper.toErrorMessages(response);
            if (messages && messages.length) {
              return [ fromActions.setMessagesAction(messages) ];
            }
            return [
              fromActions.setMessagesAction([]),
              fromActions.updateAddPartyDataAction({ displayName: '', partyCode: '' }),
              new fromDtbActions.LoadDataAction(this.prefix)
            ];
          }),
          catchError(error =>
            of(fromActions.setMessagesAction([this.mapper.createErrorMessage(error.errorMessage)]))
          )
        );
    }),
    catchError(error =>
      of(fromActions.setMessagesAction([this.mapper.createErrorMessage(error.errorMessage)]))
  )));

  private loadData(request: GetPartyDataRequest) {
    return this.apiGateway.post(`${this.baseUrl}/data`, request)
      .pipe(
        map((response: GetPartyDataResponse) => {
          const messages: Message[] = this.mapper.toErrorMessages(response);
          if (messages && messages.length) {
            return new fromDtbActions.SetMessagesAction(this.prefix, messages);
          }
          const {currentPage, values, count} = response;
          return new fromDtbActions.FilterDataSuccessAction(this.prefix, {
            currentPage, values, count
          });
        }),
        catchError(error =>
          of(new fromDtbActions.SetMessagesAction(this.prefix, [this.mapper.createErrorMessage(error.errorMessage)]))
        )
      );
  }
}
