<div class="btn-wrapper">
  <button *ngIf="!hide && !showConfirmationButtons"
          class="btn btn-icon fa fa-trash-o"
          type="button"
          label="Delete"
          (click)="onToggle(true)"
          title="Delete Filter">
  </button>
  <ng-container *ngIf="showConfirmationButtons">
    <button class="btn btn-icon fa fa-check confirm-button"
            type="button"
            label="Delete"
            title="Confirm"
            (click)="onConfirmDelete()">
    </button>
    <button class="btn btn-icon fa fa-times"
            type="button"
            label="Delete"
            (click)="onToggle(false)"
            title="Cancel">
    </button>
  </ng-container>
</div>
