<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>

<h1>REMIT OMP Whitelist - {{selectedOrganisatationName}}</h1>
<div class="panel-box margin-bottom-15">
  <cms-info ngPreserveWhitespaces>
    <!--@formatter:off (markdown rendering breaks, when the following tags are formatted)-->
    __OMP Whitelist__

    **Purpose of the Whitelist**

    * Organised Market Places (OMP) are required to maintain whitelists of Market Participants (MP) with whom they entered a REMIT reporting agreement
    * All REMIT Table 1 submissions from the OMP are validated against this whitelist
    * Records referring to an MP by a code not contained in the whitelist are rejected by eRR and not forwarded to ARIS
    * This page also highlights setup issues with MPs that can prevent successful REMIT reporting

    **Information in the List**

    * **MP Code** and **Code Type**: the MP codes whitelisted by you
    * **REMIT Mode**: how the MP party elected to forward your trade and order submissions; Block and Pair modes are only available to eRR Parties
    * **eRR Party**: if the MP code is registered to a CMS eRR organisation its CMS-internal display name appears here
    * **Reported ACER code**: eRR converts all MP codes to ACER codes prior to submission to ACER; should you use other code types you find the target code here
    * **Registered Name (LEI Registry)**: eRR identifies traders by their LEI code internally; find the registered name from the LEI registry here

    **Configuration Warnings**

    * **REMIT Mode "Blocked"**: the MP party needs to authorize your reporting in their CMS REMIT settings; contact the MP
    * **eRR Party "not registered"**: the MP party is not a CMS client and therefore not available for reporting; contact the support team
    * **Reported ACER code "unknown"**: no ACER code has been associated with this MP; contact the support team

    **Searching the List**

    * Use the "Filter" field below to check for the existence of specific MP codes
    * Filtering is case-insensitive and also finds parts of codes

    **Maintaining the List**

    * Only OMP users with Master Operator (MO) privileges can modify the list
    * OMPs having multiple legal entities have to upload separately for each entity
    * An upload - if successful - replaces the existing whitelist

    **Upload Format**

    * File format is .csv with comma delimiter, quoting of values is optional
    * File size limit is 1 MB
    * Required header line is: MP_CODE_TYPE,MP_CODE
    * Column 1 contains the type of code, one of
      * ACE = 12-character ACER code
      * LEI = 20-character LEI code
      * EIC = 16-character EIC code
      * BIC = 11-character BIC code
      * GLN = 13-character GLN/GS1 code
    * Column 2 contains the MP code itself
    * Duplicates are removed on import

    **Example**

        MP_CODE_TYPE,MP_CODE
        ACE,A1234567Y.SE
        ACE,A0027384Y.FR
        EIC,21X000CAPE01081F
        LEI,529900YIN68X122OXP12

    <!--@formatter:on-->
  </cms-info>
  <div id="div-remit-omp-whitelist-feedback-message">
    <p-messages [value]="messages" [closable]="false"></p-messages>
  </div>
  <cms-remit-omp-whitelist-upload-panel></cms-remit-omp-whitelist-upload-panel>

  <cms-remit-omp-whitelist-datatable></cms-remit-omp-whitelist-datatable>
</div>

</ng-template>
