import { createAction, union } from '@ngrx/store';
import { Message } from 'primeng/api';
import { DialogState } from '@common/state/reducers';
import {
  ErrSendingFailuresPresetModel,
  ErrSendingFailuresSearchRequest,
  ErrSendingFailuresSelected,
  ErrSendingFailuresValues
} from '../../err-sending-failures/err-sending-failures.model';
import { ERR_SF_DIALOGS } from './err-sending-failures.reducers';
import { SendingFailuresPresetValuesModel } from '../../sending-failures/sending-failures.model';

export const setOrganisationId = createAction('[ERR SENDING FAILURES] Set Organisation Id', (payload: number) => ({ payload }));
export const setToDefault = createAction('[ERR SENDING FAILURES] Set to default');
export const setMessages = createAction('[ERR SENDING FAILURES] Set Messages', (payload: Message[]) => ({ payload }));
export const setFilter = createAction('[ERR SENDING FAILURES] Set Filter', (payload: ErrSendingFailuresSearchRequest) => ({ payload }));
export const getPreset = createAction('[ERR SENDING FAILURES] Get Preset');
export const onPresetSuccess = createAction('[ERR SENDING FAILURES] On Preset Success', (payload: ErrSendingFailuresPresetModel) => ({ payload }));
export const search = createAction('[ERR SENDING FAILURES] Search', (payload: ErrSendingFailuresSearchRequest) => ({ payload }));
export const searchSuccess = createAction('[ERR SENDING FAILURES] Search Success', (payload: ErrSendingFailuresValues[]) => ({ payload }));
export const refresh = createAction('[ERR SENDING FAILURES] Refresh', (payload: string) => ({ payload }));
export const reload = createAction('[ERR SENDING FAILURES] Reload');
export const select = createAction('[ERR SENDING FAILURES] Select', (payload: ErrSendingFailuresSelected) => ({ payload }));
export const deselect = createAction('[ERR SENDING FAILURES] Deselect', (payload: ErrSendingFailuresSelected) => ({ payload }));
export const resend = createAction('[ERR SENDING FAILURES] Resend');
export const resendSuccess = createAction('[ERR SENDING FAILURES] Resend Success');
export const checkResend = createAction('[ERR SENDING FAILURES] Check Resend');
export const openDialog = createAction('[ERR SENDING FAILURES] Open Dialog', (payload: DialogState<ERR_SF_DIALOGS>) => ({ payload }));
export const closeDialogAction = createAction('[ERR SENDING FAILURES] Close Dialog');
export const toggleSelectAll = createAction('[ERR SENDING FAILURES] Toggle Check All', (payload: boolean) => ({ payload }));

const actions = union({
  setOrganisationId,
  setToDefault,
  setMessages,
  getPreset,
  setFilter,
  onPresetSuccess,
  search,
  searchSuccess,
  refresh,
  reload,
  select,
  deselect,
  resend,
  resendSuccess,
  openDialog,
  closeDialogAction,
  toggleSelectAll,
  checkResend
});

export type ErrSendingFailuresActions = typeof actions;
