import { Action } from '@ngrx/store';

export interface TabsVisibility {
  global: boolean;
  eCM: boolean;
}

export const SET_TABS_VISIBILITY = '[MAIL ALERTS] Set Tabs Visibility';

export class SetTabsVisibility implements Action {
  type = SET_TABS_VISIBILITY;
  constructor(public payload: TabsVisibility) { }
}

export type LayoutActions = SetTabsVisibility;
