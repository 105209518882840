import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AuthenticationService } from '../../../auth/authentication.service';
import { TitleMap } from '../../ecm.model';
import { NavigationService } from '@common/menu/navigation.service';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { ProductsState } from '../../state/settings/products/products.reducers';
import { ErrorMessageService } from '@common/errors/error-message.service';

@Component({
  selector: 'cms-ecm-settings-tab-panel',
  templateUrl: './ecm-settings-tab-panel.component.html',
  styleUrls: ['./ecm-settings-tab-panel.component.scss']
})
export class EcmSettingsTabPanelComponent implements OnInit, OnDestroy {
  activeIndex: number;
  tabs = [
    'process',
    'products',
    'brokerRestrictions'
  ];
  processVisible = false;
  productsVisible = false;
  brokerRestrictionsVisible = false;
  subscr$: Subscription;

  constructor(public authService: AuthenticationService,
              private route: ActivatedRoute,
              private store: Store<ProductsState>,
              private router: Router,
              private titleService: Title,
              private navigationService: NavigationService,
              private changeDetectorRef: ChangeDetectorRef,
              public readonly errorMessageService: ErrorMessageService) { }

  ngOnDestroy(): void {
    this.subscr$.unsubscribe();
  }

  ngOnInit(): void {
    this.route.data.subscribe(({panel}: { panel: string }) => {
      this.activeIndex = this.tabs.indexOf(panel);
      this.titleService.setTitle(TitleMap[panel]);
    });

    this.subscr$ = this.navigationService.onNavigationChanged().subscribe(navigation => {
      this.processVisible = this.navigationService.containsPath('ecm/settings/process', navigation);
      this.brokerRestrictionsVisible = this.navigationService.containsPath('ecm/settings/brokerRestrictions', navigation);
      this.productsVisible = this.navigationService.containsPath('ecm/settings/products', navigation);
      this.changeDetectorRef.detectChanges();
    });
  }

  onChangeTab({index}: {index: number}): void {
    this.router.navigate([`ecm/settings/${this.tabs[index]}`]);
  }
}
