import { Component } from '@angular/core';

@Component({
  selector: 'cms-empty',
  templateUrl: './empty.component.html',
  styleUrls: [
    './empty.component.scss'
  ],
})
export class EmptyComponent {}
