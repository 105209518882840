import { TabViewModule } from 'primeng/tabview';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RemitTransactionsComponent } from './remit-transactions.component';
import { RemitTransactionsPanelComponent } from './remit-transactions-panel.component';
import { RemitTransactionsSearchModule } from './search/remit-transactions-search.module';
import { RemitTransactionsFilterModule } from './filter/remit-transactions-filter.module';
import { RemitTransactionSheetComponent } from './remit-transaction-sheet.component';
import { CmsCommonModule } from '../../../common/cms-common.module';
import { RemitTableModule } from '../datatable/remit-datatable.module';
import { MessagesModule } from 'primeng/messages';

@NgModule({
  imports: [
    CommonModule,
    CmsCommonModule,
    MessagesModule,
    TabViewModule,
    FormsModule,
    ReactiveFormsModule,
    RemitTransactionsSearchModule,
    RemitTransactionsFilterModule,
    RemitTableModule
  ],
  declarations: [
    RemitTransactionsComponent,
    RemitTransactionsPanelComponent,
    RemitTransactionSheetComponent
  ]
})
export class RemitTransactionsModule {
}
