import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Column, DefaultValues, PagerInfo } from '@common/shared/datatable.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { RemitFilesOutSearchQuery, RemitFilesOutService } from './remit-files-out.service';
import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import * as FileSaver from 'file-saver';
import { DateUtils } from '@common/shared/date.utils';
import { takeUntil } from 'rxjs/operators';
import { DatatableState } from '@common/datatable/datatable.model';
import { ObjectUtils } from 'primeng/utils';
import { Table, TableService } from 'primeng/table';
import { DatatableComponent } from '@common/datatable/datatable.component';
import { OrganisationService } from '@common/organisation.service';
import { DomHandler } from 'primeng/dom';

@Component({
  selector: 'cms-remit-files-out-datatable',
  templateUrl: './remit-files-out-datatable.component.html',
  styleUrls: ['./remit-files-out-datatable.component.scss'],
  providers: [
    DomHandler,
    ObjectUtils,
    TableService,
    Table,
    {
      provide: Table,
      useFactory: (datalist: RemitFilesOutDatatableComponent): Table => datalist.datatable.primeTable,
      deps: [
        RemitFilesOutDatatableComponent
      ]
    }
  ],
})
export class RemitFilesOutDatatableComponent implements OnInit, OnDestroy {
  @ViewChild(DatatableComponent, { static: false }) datatable: DatatableComponent;
  private tableColumns: Column[] = RemitFilesOutDatatableConfig.TABLE_COLUMNS;
  pagerInfo: PagerInfo;
  records = [];
  recordCount: number;
  sortBy: string;
  private loading$ = new BehaviorSubject<boolean>(false);
  hidden: boolean = true;
  private onDestroy$ = new Subject();
  private defaults = new DefaultValues();
  rowsPerPage = this.defaults.getRowsPerPage();
  rowsPerPageOptions = this.defaults.getRowsPerPageOptions();

  constructor(public filesOutService: RemitFilesOutService, private organsationService: OrganisationService) {
    this.sortBy = 'reportedTimestamp';
  }

  ngOnInit() {
    this.pagerInfo = PagerInfo.calcPagerInfo(0, 0, 20);
    this.filesOutService.onSearchQueryChange()
      .pipe(takeUntil(this.onDestroy$)).subscribe(this.loadData.bind(this));
  }

  get cols() {
    return this.tableColumns;
  }

  get organisationId(): number {
    return this.organsationService.getCachedOrganisationId();
  }

  getRecordCount() {
    return this.recordCount;
  }

  // event fired by primeng datatable
  onLazyLoad({sortOrder, sortField, perPage: entriesPerPage, page}: DatatableState) {
    const pagingFilter: PagingFilter = {
      entriesPerPage,
      page
    };

    this.sortBy = sortField || 'reportedTimestamp';
    const columnName = this.sortBy;
    const sortingFilter: SortingFilter = { columnName, sortOrder };
    const searchQuery = this.filesOutService.getSearchQuery();

    // update search query to table status
    if (searchQuery) {
      searchQuery.paging = pagingFilter;
      searchQuery.sorting = sortingFilter;
      this.filesOutService.setSearchQuery(searchQuery);
    }
  }

  get isLoading(): Observable<boolean>{
    return this.loading$.asObservable();
  }

  // load data from via service
  private loadData(searchQuery: RemitFilesOutSearchQuery) {
    if (searchQuery) {
      this.loading$.next(true);
      this.hidden = false;
      const currentPage = searchQuery.paging.page;
      const resultsPerPage = searchQuery.paging.entriesPerPage;
      this.filesOutService.search(searchQuery).subscribe(result => {
        this.records = this.formatRows(result.rows);
        this.pagerInfo = PagerInfo.calcPagerInfo(result.recordCount, currentPage, resultsPerPage);
        this.recordCount = result.recordCount;
        this.loading$.next(false);
      });
    }
  }

  // format data to rows
  private formatRows(rows) {
    let records = [];
    if (rows) {
      records = rows.map(row => ({
        ...row,
        reportedTimestamp: DateUtils.transformDateWithTimestamp(row.reportedTimestamp),
        responseTimestamp: DateUtils.transformDateWithTimestamp(row.responseTimestamp),

      }));
    }

    return records;
  }

  public downloadExcel(): void {
    this.filesOutService.downloadExcel()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(data => {
        FileSaver.saveAs(data.blob, data.filename);
      });
  }


  ngOnDestroy(): void {
    this.loading$.next(false);
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  createAuditTrailQueryParams(row: any) {
    const queryParams = {};

    if (row.auditGroupId) {
      queryParams['auditGroupId'] = row.auditGroupId;
    }

    queryParams['displayValue'] = `${row.fileName}${row.reportedTimestamp ?
      ` received at ${row.reportedTimestamp}` : ''}`;

    queryParams['urlPart'] = 'remit_filesout';

    return queryParams;
  }

}

export const defaultStyles = {
  'text-overflow': 'ellipsis',
  'overflow': 'hidden',
  'white-space': 'nowrap'
};

export namespace RemitFilesOutDatatableConfig {
  export const TABLE_COLUMNS: Column[] = [
    { name: 'repository', title: 'Repository', style: { ...defaultStyles, 'width': '120px', 'text-align': 'left' }, sortable: true, visible: true },
    { name: 'reportedTimestamp', title: 'Reported (UK time)', style: { ...defaultStyles, 'width': '180px', 'text-align': 'left' }, sortable: true, visible: true },
    { name: 'buttons_in', title: '', style: { ...defaultStyles, 'width': '70px', 'text-align': 'center' }, sortable: false, visible: true },
    { name: 'responseTimestamp', title: 'Response (UK time)', style: { ...defaultStyles, 'width': '180px', 'text-align': 'left' }, sortable: true, visible: true },
    { name: 'buttons_out', title: '', style: { ...defaultStyles, 'width': '40px', 'text-align' : 'center' }, sortable: false, visible: true },
    { name: 'documentType', title: 'Document Type', style: { ...defaultStyles, 'width': '140px', 'text-align': 'left' }, sortable: true, visible: true },
    { name: 'recordCount', title: 'Records', style: { ...defaultStyles, 'width': '90px', 'text-align': 'right' }, sortable: true, visible: true },
    { name: 'acceptedCount', title: 'ACC', style: { ...defaultStyles, 'width': '60px', 'text-align': 'right' }, sortable: true, visible: true },
    { name: 'rejectedCount', title: 'REJ', style: { ...defaultStyles, 'width': '60px', 'text-align': 'right' }, sortable: true, visible: true },
    { name: 'pendingCount', title: 'PEN', style: { ...defaultStyles, 'width': '60px', 'text-align': 'right' }, sortable: true, visible: true },
    { name: 'senderDisplayName', title: 'Sender Name', style: { ...defaultStyles, 'width': '140px', 'text-align': 'left' }, sortable: true, visible: true },
    {
      name: 'mpDisplayName',
      title: 'MP Name',
      style: { ...defaultStyles, 'width': '140px', 'text-align': 'left', 'cursor': 'help' },
      editable: true,
      sortable: true,
      visible: true
    },
    { name: 'ompDisplayName', title: 'OMP Name', style: { ...defaultStyles, 'width': '140px', 'text-align': 'left' }, sortable: true, visible: true },
    {
      name: 'fileName',
      title: 'Filename',
      style: { ...defaultStyles, 'width': '210px', 'text-align': 'left', 'cursor': 'help' },
      editable: true,
      sortable: true,
      visible: true
    },

  ];

}
