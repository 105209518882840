<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>

<h1>eSM File Upload</h1>
<section class="panel-box">
  <cms-info ngPreserveWhitespaces>
    __eSM File Upload__

    **Purpose**

    - This screen is an **upload facility** for eSM CSV invoice files.
    - A CSV invoice file is allowed to contain a single invoice including line item data. The **maximum size** for an eSM
    CSV file to be uploaded is **5 MB**.
    - This screen is **file-centric**, it displays eSM CSV invoices which were successfully uploaded into the eSM
    process.
    - An eSM CSV invoice which has passed the eSM CSV technical and business validations will be converted to the
    eSM CpML format before being passed on to the eSM match processing.
    - To track the state of an uploaded invoice which was successfully converted to CpML, please use the eSM Invoice
    Finder or the eSM Dashboard

    **File Monitoring**

    Procedure

    1. From the title bar choose your organisational unit whose file submissions you wish to list.
    2. Option 1: search by a specific filename or parts of it, not case-sensitive.
    3. Option 2: search for any file received in the selected time range - optionally modify the report type and time window
    of interest.

    Guidance on Listed Information

    - use the document icon to download the uploaded eSM CSV invoice file.
    - use the magnifier icon to open the file's audit trail with processing details.

    **File Upload**

    Users with **Master Operator (MO) privileges** can upload eSM CSV invoice files.

    Procedure

    1. In the title bar choose your organisational unit which has been authorized to participate in eSM.
    2. If you have master operator rights, an upload panel appears.
    3. Click "Browse...", choose your upload file (which must have a ".csv" file extension) and select "Upload".
  </cms-info>
  <div id="div-esm-file-upload-feedback-message">
    <p-messages [value]="messages$ | async" [closable]="true"></p-messages>
  </div>
  <cms-upload-panel
      *ngIf="allowedToUpload$ | async"
      [accept]="uploadFileType"
      [inBytesSize]="true"
      [title]="uploadFileTitle"
      [maxFileSize]="maxFileSize$ | async"
      [busy]="busy$ | async"
      (upload)="onUpload($event)"
      (error)="onUploadError($event)">
  </cms-upload-panel>
  <cms-file-upload-filters
    *ngIf="filters$ | async"
    [filters]="filters$ | async"
    [disabled]="loading$ | async"
    (reset)="onReset()"
    (searchByFilename)="onSearchByFilename($event)"
    (searchByTime)="onSearchByDate($event)">
  </cms-file-upload-filters>
  <cms-datatable selectionMode="single"
                 *ngIf="(data$ | async)?.values"
                 [currentPage]="(paging$ | async).page"
                 [sortField]="(sorting$ | async).columnName"
                 [sortOrder]="(sorting$ | async).sortOrder"
                 [rows]="(paging$ | async)?.entriesPerPage"
                 [loading]="loading$ | async"
                 [data]="(data$ | async)?.values"
                 [totalRecords]="(data$ | async)?.count"
                 [autoLayout]="true"
                 [scrollable]="false"
                 [lazy]="!!(data$ | async)?.values?.length"
                 (lazyLoad)="onLazyLoad($event)"
                 [tableColumns]="columns">
    <ng-template pTemplate="caption">
      <div class="row">
        <div class="col-12 text-end">
          <button class="btn" pButton type="button" icon="fa fa-download" label="Export" (click)="onExport()"></button>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="body" let-row="row" let-column="column">
      <td *ngIf="column.type === 'button' && column.name === 'auditTrail'" [ngStyle]="column.style">
        <div class="btn-wrapper inline-block">
          <a routerLink="/esm/audittrail/invoice" target="_blank" [queryParams]="getDisplayParams(row)">
            <button class="audit_trail_button" pButton type="button" icon="fa fa-search" title="Audit Trail"></button>
          </a>
        </div>
      </td>
      <td *ngIf="column.type === 'button' && column.name === 'document'" [ngStyle]="column.style">
        <div class="btn-wrapper inline-block">
          <button class="document_button2" pButton type="button" icon="fa fa-download" title="Document" (click)="onDownloadDocument(row.datastoreId)"></button>
        </div>
      </td>
    </ng-template>
  </cms-datatable>
</section>

</ng-template>
