<div class="datatable-container">

  <p-table [value]="records"
           [rows]="defaults.DEFAULT_ROWS"
           [totalRecords]="recordCount"
           [rowsPerPageOptions]="defaults.ROW_SELECTION_NUMBERS"
           sortField="displayName"
           dataKey=""
           [lazy]="!!(records && records.length)"
           [sortOrder]="1"
           (onLazyLoad)="onLazyLoad($event)"
           [paginator]="true"
           [loading]="loading"
           selectionMode="single"
           [(selection)]="selection"
           [scrollable]="true"
           scrollHeight="flex"
           scrollDirection="both">
    <ng-template pTemplate="caption">
      <div class="row">
        <div class="col-12" style="max-width: 650px;">
          <form [formGroup]="filterForm" class="flex " (ngSubmit)="filter()">
            <input formControlName="filter" id="input-filter-remit-omp-whitelist" type="text" class="form-control p-component me-2 padding-6 text-medium" style="margin-top: .21rem;" placeholder="Party Code or Name / Group Name">
            <p class="error-note text-small mb-0" *ngIf="filterForm.hasError('maxlength', 'filter')">The filter field has a maximum length of 255.</p>
            <button id="btn-submit-remit-robo-delegators-filter" class="btn form-control margin-3 padding-6" style="width: 75px;" type="submit">Filter</button>
            <button id="btn-submit-remit-robo-delegators-reset" style="width: 75px;" class="btn form-control margin-3 padding-6" type="button" (click)="reset();">Reset</button>
          </form>
        </div>
      </div>
    </ng-template>


    <ng-template pTemplate="header">
      <tr>
        <ng-container *ngFor="let col of cols">
          <th [pSortableColumn]="col.sortable ? col.name : ''" [ngStyle]="col.style">
            {{col.title}}
            <p-sortIcon [field]="col.name" *ngIf="col.sortable"></p-sortIcon>
          </th>
        </ng-container>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-row>
      <tr [pSelectableRow]="row">
        <ng-container *ngFor="let col of cols">
          <td [ngStyle]="col.style">
            <span *ngIf="col.name != 'hasStandingInstruction'" [ngClass]="{'text-alert' : isFieldAlert(row[col.name])}">{{row[col.name]}}</span>
            <span *ngIf="col.name == 'hasStandingInstruction' && row[col.name]=='Y'">
              <button class="btn btn-icon fa fa-search" type="button" title="View Instruction" [disabled]="!(presetValues$ | async)?.viewDetailsAllowed" (click)="viewStandingInstruction(row['errPartyId'])">
              </button>
            </span>
          </td>
        </ng-container>
      </tr>
    </ng-template>

    <ng-template pTemplate="paginatorleft">
      <span class="paginator-info">Showing {{pagerInfo.fromNumberOfResults}} to {{pagerInfo.toNumberOfResults}} of {{pagerInfo.numberOfAllRecords}}</span>
    </ng-template>
  </p-table>
</div>


<!-- detail overview panel -->
<p-dialog header="View Standing Instruction" [(visible)]="showStandingInstructionDetail" [modal]="true" [style]="{width: '850', minWidth: '420px'}">
  <div>
    <cms-standing-instructions-editor [editable]="false"></cms-standing-instructions-editor>
  </div>

  <p-footer>
    <button type="button" id="cancelAddInfoBtn" pButton label="Close" (click)="closeModal()"></button>
  </p-footer>
</p-dialog>
