import { createAction, union } from '@ngrx/store';
import { EsmNettingDashboardTableType, EsmStatesEnum } from '../../../netting/dashboard/esm-netting-dashboard.model';

export const setToDefaultAction = createAction('[ESM NETTING DASHBOARD] Set To Default');
export const setViewTypeAction = createAction('[ESM NETTING DASHBOARD] Set View Type', (payload: EsmNettingDashboardTableType) => ({ payload }));
export const setStateAction = createAction('[ESM NETTING DASHBOARD] Set State', (payload: EsmStatesEnum | string) => ({ payload }));
export const getStateNamesAction = createAction('[ESM NETTING DASHBOARD] Get State Names');
export const setStateNamesAction = createAction('[ESM NETTING DASHBOARD] Set State Names', (payload: EsmStatesEnum[]) => ({ payload }));

const actions = union({
  setToDefaultAction,
  setStateAction,
  getStateNamesAction,
  setStateNamesAction,
  setViewTypeAction
});

export type NettingDashboardActions = typeof actions;
