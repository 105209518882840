import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { SelectItem, SelectItemGroup } from 'primeng/api';
import { RemitTradeEntryTable2Service } from '../remit-trade-entry-table2.service';
import { takeUntil } from 'rxjs/operators';
import { RemitTradeEntryTable2PriceDataModel } from '../remit-trade-entry-table2-data.model';
import { RemitTable2TradePriceModel, RemitTable2TradeReferencesModel } from '../../../trade/table2/modify/remit-table2-trade-entry.model';

@Component({
  selector: 'cms-remit-trade-entry-table2-price',
  templateUrl: './remit-trade-entry-table2-price.component.html',
  styleUrls: ['./remit-trade-entry-table2-price.component.scss']
})
export class RemitTradeEntryTable2PriceComponent implements OnInit,  OnChanges, OnDestroy {

  @Input()
  formGroup: UntypedFormGroup;

  @Input()
  onReset: Subject<boolean>;

  @Input()
  data: RemitTable2TradePriceModel;

  private onDestroy$ = new Subject();

  priceCurrency: SelectItemGroup[];
  typeOfIndexPrice: SelectItem[];
  estimatedNotionalAmountUnit: SelectItemGroup[];

  constructor(private tradeEntryService: RemitTradeEntryTable2Service) {
    this.onReset = new Subject<boolean>();
  }

  ngOnInit() {
    this.formGroup.addControl('price', new UntypedFormControl(''));
    this.formGroup.addControl('priceUnit', new UntypedFormControl(''));
    this.formGroup.addControl('priceFormula', new UntypedFormControl(''));
    this.formGroup.addControl('typeOfIndexPrice', new UntypedFormControl(''));
    this.formGroup.addControl('estimatedNotionalAmountValue', new UntypedFormControl(''));
    this.formGroup.addControl('estimatedNotionalAmountUnit', new UntypedFormControl(''));

    this.tradeEntryService.onTradeEntryDataChange().pipe(takeUntil(this.onDestroy$)).subscribe(formData => {
      if (formData && formData.price) {
        this.initFormValues(formData.price);
        this.fillFormWithData();
      }
    });


    this.onReset.pipe(takeUntil(this.onDestroy$))
      .subscribe(   response => {
        if (response) {
          this.reset();
        }
      });

  }

  private initFormValues(price: RemitTradeEntryTable2PriceDataModel) {

    this.priceCurrency = this.tradeEntryService.getGroupedValues(price.currencies);

    this.typeOfIndexPrice = [];
    this.typeOfIndexPrice = [
      { label: ' ', value: null }
    ];
    this.typeOfIndexPrice = this.typeOfIndexPrice.concat(this.tradeEntryService.convertKeyValueToSelectItem(price.typesOfIndexPrice));

    this.estimatedNotionalAmountUnit = this.priceCurrency;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.fillFormWithData();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  getValidationErrorStyleClass(fieldName: string): string {
    if (this.formGroup.controls[fieldName] && this.formGroup.controls[fieldName].hasError('error')) {
      return 'error';
    }
  }

  private reset() {

    this.formGroup.controls['price'].reset();
    this.formGroup.controls['priceUnit'].reset();
    this.formGroup.controls['priceFormula'].reset();
    this.formGroup.controls['typeOfIndexPrice'].reset();
    this.formGroup.controls['estimatedNotionalAmountValue'].reset();
    this.formGroup.controls['estimatedNotionalAmountUnit'].reset();

  }

  private fillFormWithData(){
    if (this.formGroup && this.data) {
      this.formGroup.patchValue({
        price: this.data.price,
        priceUnit: this.data.priceUnit,
        priceFormula: this.data.priceFormula,
        typeOfIndexPrice: this.data.typeOfIndexPrice,
        estimatedNotionalAmountValue: this.data.estimatedNotionalAmountValue,
        estimatedNotionalAmountUnit: this.data.estimatedNotionalAmountUnit
      });
    }
  }
}
