<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>

  <cms-headline [displayOrganisationName]="true" [pageTitle]="'eCM lite Trade Entry'"></cms-headline>
  <div class="panel-box margin-0">
    <p-messages [value]="messages$ | async" [closable]="true" class="col-12"></p-messages>

    <div class="panel-box" [formGroup]="form">
      <div class="col-12 row">
        <label class="col-auto label--multiselect form__label">Product</label>
        <div class="product-list col-auto">
          <p-dropdown
            id="et-product-selector"
            tabindex="1"
            [options]="productOptions$ | async"
            formControlName="product"
            (onChange)="onChangeProduct($event)"
            [showClear]="false"
            [style]="{'width':'100%'}"
          ></p-dropdown>
        </div>
        <div class="ms-5 col-auto">
          <button *ngIf="!isEditable" (click)="submitForm()" type="submit" class="btn btn-primary"
                  [disabled]="!(formValues$ | async)">Confirm
          </button>
          <button *ngIf="isEditable" (click)="amendForm()" type="submit" class="btn btn-primary"
                  [disabled]="!(formValues$ | async)">Amend
          </button>
        </div>
      </div>
    </div>

    <div class="panel-box" *ngIf="formValues$ | async as formValues">
      <cms-enter-trade-form [formValues]="formValues"
                            [editableFormData]="editableFormData"
                            [isEditable]="isEditable"
                            [deliveryPeriod]="deliveryPeriod$ | async"
                            [totalVolumeData]="totalVolumeData$ | async"
                            [totalContractValue]="totalContractValue$ | async"
                            [messages]="messages$ | async"
                            (formChange)="onFormChange($event)"
                            (getPeriodDelivery)="onPeriodChange()"
                            (calculateTotalContractValue)="onCalculateTotalContractValue()"
                            (calculateTotalValue)="onCalculateTotalValue()">
      </cms-enter-trade-form>
    </div>
  </div>

</ng-template>
