import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AuditTrailDocumentType, AudittrailSearchQuery, AudittrailService, AudittrailUrlPart } from './audittrail.service';

@Component({
  selector: 'cms-audittrail',
  templateUrl: './audittrail.component.html',
  styleUrls: ['./audittrail.component.scss']
})
export class AudittrailComponent implements OnInit {
  confirmationId: number = -1;
  dataStoreId: number = -1;
  historyId: number = -1;
  auditGroupId: number = -1;
  documentType: AuditTrailDocumentType = undefined;
  displayValue: string;
  extId: number = -1;
  fileId: number = -1;
  id: number = -1;
  invoiceId: number = -1;
  urlPart: AudittrailUrlPart;
  senderOrgEicCode: string;
  private nettingId: number = -1;

  constructor(private activatedRoute: ActivatedRoute, private titleService: Title, private auditTrailService: AudittrailService) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.confirmationId = params['confirmationId'];
      this.dataStoreId = params['dataStoreId'];
      this.historyId = params['historyId'];
      this.auditGroupId = params['auditGroupId'];
      this.documentType = params['documentType'];
      this.extId = params['extId'];
      this.fileId = params['fileId'];
      this.id = params['id'];
      this.displayValue = params['displayValue'];
      this.urlPart = params['urlPart'];
      this.senderOrgEicCode = params['senderOrgEicCode'];
      this.invoiceId = params['invoiceId'];
      this.nettingId = params['nettingId'];

      const query = AudittrailSearchQuery.createEmptySearchQuery();
      query.confirmationId = this.confirmationId;
      query.dataStoreId = this.dataStoreId;
      query.historyId = this.historyId;
      query.auditGroupId = this.auditGroupId;
      query.documentType = this.documentType;
      query.extId = this.extId;
      query.fileId = this.fileId;
      query.id = this.id;
      query.urlPart = this.urlPart;
      query.senderOrgEicCode = this.senderOrgEicCode;
      query.invoiceId = this.invoiceId;
      query.nettingId= this.nettingId;
      this.auditTrailService.updateSearchQuery(query);
    });

    this.titleService.setTitle('Details for ' + this.displayValue);
  }

}
