import {Title} from '@angular/platform-browser';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {withLatestFrom} from 'rxjs/operators';
import {Message} from 'primeng/api';
import {Contacts, ContactsFilters, OrganisationGroup} from './contacts.model';
import {ContactsState} from '../state/contacts/contacts.reducers';
import {DatatableData, PagingFilter, SortingFilter} from '@common/shared/results.model';
import {Column} from '@common/shared/datatable.model';
import {DatatableState} from '@common/datatable/datatable.model';
import {OrganisationService} from '@common/organisation.service';
import {Prefix} from '../state/reducers';
import {LoadOrganisationGroupsAction} from '../state/organisation-groups/organisation-groups.actions';
import {SetOrganisationIdAction} from '../state/organisation-id/organisation-id.actions';
import {getOrganisationId} from '../state/organisation-id/organisation-id.selectors';
import * as fromOrgGroupsDtSelectors from '../state/org-group-dt/org-groups-dt.selectors';
import * as fromOrgGroupsSelectors from '../state/organisation-groups/organisation-groups.selectors';
import * as fromDtActions from '../state/datatable/datatable.actions';
import {SetToDefaultAction} from '../state/datatable/datatable.actions';
import {OrganisationModel} from '@common/organisation.model';

const defaultStyles = {
  'text-align': 'left',
  'text-overflow': 'ellipsis',
  'white-space': 'nowrap'
};

@Component({
  selector: 'cms-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit, OnDestroy {
  messages$: Observable<Message[]>;
  filters$: Observable<ContactsFilters>;
  orgGroups$: Observable<OrganisationGroup[]>;
  data$: Observable<DatatableData<Contacts[]>>;
  loading$: Observable<boolean>;
  sorting$: Observable<SortingFilter>;
  organisationsId$: Observable<number>;
  paging$: Observable<PagingFilter>;
  selected$: Observable<Contacts>;
  selectedContact: Contacts;
  readonly dataTableColumns: Column[] = [
    { name: '', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: true },
    { name: 'organisationGroupDisplayName', type: 'text', title: 'Org. Group', style: { ...defaultStyles }, sortable: true, visible: true },
    { name: 'displayName', type: 'text', title: 'Display Name', style: { ...defaultStyles }, sortable: true, visible: true },
    { name: 'legalName', type: 'text', title: 'Legal Name', style: { ...defaultStyles }, sortable: true, visible: true },
    { name: 'eicCode', type: 'text', title: 'EIC Code', style: { ...defaultStyles }, sortable: true, visible: true },
    { name: 'ecmProcessRole', type: 'text', title: 'eCM Role', style: { ...defaultStyles }, sortable: true, visible: true },
    { name: 'errProcessRole', type: 'text', title: 'eRR Role', style: { ...defaultStyles }, sortable: true, visible: true },
    { name: 'businessContact', type: 'text', title: 'Business Contact', style: { ...defaultStyles }, sortable: true, visible: true },
    { name: 'technicalContact', type: 'text', title: 'Technical Contact', style: { ...defaultStyles }, sortable: true, visible: true }
  ];
  subscription$: Subscription;
  prefix: Prefix = '[ORG GROUPS CONTACTS]';

  constructor( private store: Store<ContactsState>,
               private titleService: Title,
               private organisationService: OrganisationService ) {
    this.data$ = this.store.pipe(select(fromOrgGroupsDtSelectors.getData));
    this.loading$ = this.store.pipe(select(fromOrgGroupsDtSelectors.getLoading));
    this.sorting$ = this.store.pipe(select(fromOrgGroupsDtSelectors.getSorting));
    this.paging$ = this.store.pipe(select(fromOrgGroupsDtSelectors.getPaging));
    this.filters$ = this.store.pipe(select(fromOrgGroupsDtSelectors.getFilters));
    this.messages$ = this.store.pipe(select(fromOrgGroupsDtSelectors.getMessages));
    this.selected$ = this.store.pipe(select(fromOrgGroupsDtSelectors.getSelected));

    this.orgGroups$ = this.store.pipe(select(fromOrgGroupsSelectors.getOrgGroups));
    this.organisationsId$ = this.store.pipe(select(getOrganisationId));
  }

  onLazyLoad(state: DatatableState): void {
    this.store.dispatch(new fromDtActions.SortAndPageDataAction(this.prefix, {
      paging: {
        page: state.page,
        entriesPerPage: state.perPage
      },
      sorting: {
        columnName: state.sortField,
        sortOrder: state.sortOrder
      }
    }));
  }

  onSelectedChange(contact: Contacts): void {
    this.store.dispatch(new fromDtActions.SelectAction(this.prefix, this.selectedContact == contact ? null : contact));

  }

  onFilter(filters: ContactsFilters): void {
    this.store.dispatch(new fromDtActions.FilterDataAction(this.prefix, filters));
  }

  ngOnInit(): void {
    this.titleService.setTitle('Organization Contact Directory');

    this.store.dispatch(new LoadOrganisationGroupsAction());
    this.store.dispatch(new SetOrganisationIdAction(this.organisationService.getCachedOrganisationId()));

    this.subscription$ = this.organisationService
      .getChangeOrganisationObservable()
      .pipe(
        withLatestFrom(this.store.pipe(select(getOrganisationId))))
      .subscribe(((data: [OrganisationModel, number]) => {
        const [organisation, organisationId] = data;
        if ((organisation && organisation.organisationId !== organisationId) || (!organisation && organisationId)) {
          this.store.dispatch(new SetToDefaultAction(this.prefix));
          this.store.dispatch(new SetOrganisationIdAction(organisation ? organisation.organisationId : null));
          this.store.dispatch(new LoadOrganisationGroupsAction());
          this.store.dispatch(new fromDtActions.LoadDataAction(this.prefix));
        }
      }));

    this.selected$.subscribe((contact) => {
      this.selectedContact = contact;
    })
  }

  ngOnDestroy(): void {
    this.store.dispatch(new SetToDefaultAction(this.prefix));
    this.subscription$.unsubscribe();
  }
}
