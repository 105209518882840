import { Message } from 'primeng/api';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { Column } from '@common/shared/datatable.model';

import { ErrMifidFile, ErrMifidFileFilters, ErrMifidViewByTypes } from '../../mifid/mifid.model';
import * as fromAction from './mifid.action';

export interface ErrMifidFileState {
  uploadPanelVisible: boolean;
  maxUploadFileSizeInBytes: number;
  organisationId?: number;
  data: DatatableData<ErrMifidFile[]>;
  confirmColumnVisible: boolean;
  messages: Message[];
  filters: ErrMifidFileFilters;
  paging?: PagingFilter;
  sorting?: SortingFilter;
  loading: boolean;
  columns: Column[];
  viewBy: ErrMifidViewByTypes;
  availableDocumentTypes: string[];
}

export const initialState: ErrMifidFileState = {
  columns: [],
  uploadPanelVisible: false,
  confirmColumnVisible: false,
  maxUploadFileSizeInBytes: null,
  organisationId: null,
  filters: {
    filename: '',
    documentTypes: [],
    timeRange: null,
  },
  data: null,
  messages: [],
  loading: false,
  paging: {
    page: 0,
    entriesPerPage: 25
  },
  sorting: {
    columnName: 'receiptDate',
    sortOrder: 'DESCENDING'
  },
  viewBy: null,
  availableDocumentTypes: []
};

export function errMifidFileReducer(state: ErrMifidFileState = { ...initialState },
                                    action: fromAction.ErrMifidFileActions): ErrMifidFileState {
  switch (action.type) {
    case fromAction.LOAD_PRESET_VALUES_SUCCESS: {
      const value = action.payload.value;
      return {
        ...state,
        uploadPanelVisible: value.uploadPanelVisible,
        maxUploadFileSizeInBytes: value.maxUploadFileSizeInBytes,
        filters: {
          ...state.filters,
          documentTypes: value.availableDocumentTypes,
          timeRange: value.timeRange,
        },
        availableDocumentTypes: value.availableDocumentTypes
      };
    }

    case fromAction.FILTER_DATA_SUCCESS: {
      const payload = (action as fromAction.FilterDataSuccessAction).payload;
      return {
        ...state,
        loading: false,
        confirmColumnVisible: payload.confirmColumnVisible,
        data: {
          count: payload.count,
          values: payload.values
        },
        paging: {
          ...state.paging,
          page: payload.currentPage
        }
      };
    }
    case fromAction.FILTER_BY_DATE:
      return {
        ...state,
        loading: true,
        messages: [],
        viewBy: 'filter',
        filters: {
          ...state.filters,
          ...(action as fromAction.FilterByDateAction).payload
        }
      };

    case fromAction.FILTER_BY_NAME:
      return {
        ...state,
        loading: true,
        messages: [],
        viewBy: 'filename',
        filters: {
          ...state.filters,
          ...(action as fromAction.FilterByNameAction).payload
        }
      };

    case fromAction.SET_MESSAGES:
      return {
        ...state,
        loading: false,
        messages: [...action.payload],
      };

    case fromAction.SET_TO_DEFAULT:
      return { ...initialState };
    case fromAction.SET_ORGANISATION_ID:
      return {
        ...state,
        organisationId: action.payload
      };

    case fromAction.SORT_AND_PAGE_DATA:
      return {
        ...state,
        loading: true,
        messages: [],
        paging: action.payload.paging,
        sorting: action.payload.sorting,
      };
    case fromAction.SET_COLUMNS:
      return {
        ...state,
        columns: (action as fromAction.SetColumnsAction).payload
      };
    case fromAction.CLEAR_FILTERS:
      return {
        ...state,
        filters: {
          filename: '',
          documentTypes: [],
          timeRange: null,
        },
      };
    default:
      return state;
  }
}

export const getData = (datatableState: ErrMifidFileState): DatatableData<ErrMifidFile[]> => datatableState.data;
export const getSorting = (state: ErrMifidFileState) => state.sorting;
export const getPaging = (state: ErrMifidFileState) => state.paging;
export const getLoading = (state: ErrMifidFileState) => state.loading;
export const getMessages = (state: ErrMifidFileState) => state.messages;
export const getFilters = (state: ErrMifidFileState) => state.filters;
export const uploadPaneVisible = (state: ErrMifidFileState) => state.uploadPanelVisible;
export const confirmColumnVisible = (state: ErrMifidFileState) => state.confirmColumnVisible;
export const maxUploadFileSizeInBytes = (state: ErrMifidFileState) => state.maxUploadFileSizeInBytes;
export const getDocumentTypes = (state: ErrMifidFileState) => state.filters.documentTypes;
export const getColumns = (state: ErrMifidFileState) => state.columns;
export const viewBy = (state: ErrMifidFileState) => state.viewBy;
export const getAvailableDocumentTypes = (state: ErrMifidFileState) => state.availableDocumentTypes;
