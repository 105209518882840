import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { Message } from 'primeng/api';

@Component({
  selector: 'cms-ecm-lite-cancel',
  templateUrl: './ecm-lite-cancel.component.html',
  styleUrls: ['./ecm-lite-cancel.component.scss']
})
export class EcmLiteCancelComponent implements OnInit, OnDestroy {
  @Input() display: boolean;
  @Input() messages: Message[];

  @Input() tradeId: string;
  @Input() closeButton: boolean;
  @Input() execution: boolean;
  @Input() confirmationId: string;
  @Output() exit = new EventEmitter<Message[]>();
  @Output() cancel = new EventEmitter<{confirmationId: string}>();

  ngOnInit(): void {
    this.closeButton = false;
  }

  onVisibleChange(visible: boolean): void {
    if (!visible) {
      this.hide();
    }
  }

  hide(): void {
    this.messages = [];
    this.display = false;
    this.exit.emit();
    this.closeButton = false;
  }

  onAbort(): void {
    this.hide();
  }

  onClose(): void {
    this.hide();
  }

  onCancel(): void {
    this.cancel.emit({confirmationId: this.confirmationId});
  }

  ngOnDestroy(): void {
    this.closeButton = false;
  }
}
