import { BehaviorSubject, Observable, throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { AuthenticationService } from '../../../../auth/authentication.service';
import { OrganisationService } from '../../../../common/organisation.service';
import { EndPointConstants } from '../../../../common/end-point-constants';
import { PagingFilter, SortingFilter } from '../../../../common/shared/results.model';
import { BaseResponseModel, DateTimeRangeFilter } from '../../../../common/shared/shared.model';
import { catchError, map } from 'rxjs/operators';
import { Message } from 'primeng/api';

@Injectable()
export class RemitFilesInService {

  private api_route: string = '/api/err/remit/filesin';

  private acerUploadGrant = new BehaviorSubject<string>(null);
  private message = new BehaviorSubject<Message>(null);
  private resetInput = new BehaviorSubject<boolean>(false);

  private uploading = new BehaviorSubject<boolean>(false);

  private searchQuery = new BehaviorSubject<RemitFilesInSearchQuery>(null);
  private busy: boolean = false;
  private filesInData = new BehaviorSubject<any>([]);
  private loading = new BehaviorSubject<boolean>(false);

  constructor(private authService: AuthenticationService, private orgService: OrganisationService, private httpClient: HttpClient) {
  }

  public onResetInput() {
    return this.resetInput.asObservable();
  }

  public getUploadingObservable(): Observable<boolean> {
    return this.uploading.asObservable();
  }

  public getLoading(): Observable<boolean> {
    return this.loading.asObservable();
  }

  public setLoading(value: boolean): void {
    this.loading.next(value);
  }

  public search(searchModel: RemitFilesInSearchQuery) {
    this.setLoading(true);
    this.setMessage(null);
    if (searchModel && !this.busy) {
      this.busy = true;
      const organisationId = this.orgService.getCurrentOrganisationId();

      let httpParams = new HttpParams();
      if (organisationId) {
        httpParams = httpParams.append(EndPointConstants.PARAM_ORGANISATION_ID, organisationId.toString());
      }

      this.httpClient.post<any>(this.api_route + '/search', searchModel, {
        params: httpParams
      }).subscribe(response => {
          if (response.validationFailureMessages && response.validationFailureMessages.length) {
            for (let i = 0; i < response.validationFailureMessages.length; i++) {
              this.setMessage({ severity: 'error', detail: response.validationFailureMessages[i] });
            }
          }
          this.busy = false;
          this.filesInData.next(response);
          this.setLoading(false);
        },
        err => this.setLoading(false));
    }
  }

  public onFilesInDataChange(): Observable<any> {
    return this.filesInData.asObservable();
  }

  public sendFile(xmlFile: File, isElcomOnly: boolean) {
    this.setMessage(null);
    if (this.isValidFile(xmlFile)) {

      const organisationId = this.orgService.getCurrentOrganisationId();
      let httpParams = new HttpParams();

      if (organisationId && !this.uploading.value) {
        this.uploading.next(true);
        httpParams = httpParams.append(EndPointConstants.PARAM_ORGANISATION_ID, organisationId.toString());
        httpParams = httpParams.append(EndPointConstants.PARAM_IS_ELCOM_ONLY, isElcomOnly);

        const formData = new FormData();
        formData.append('file', xmlFile);

        this.httpClient.post<BaseResponseModel>(this.api_route + '/uploadAcerReport', formData, { params: httpParams })
          .subscribe(response => {

            if (response.validationFailureMessages) {
              this.uploading.next(false);
              this.resetInput.next(false);

              for (let i = 0; i < response.validationFailureMessages.length; i++) {
                this.setMessage({
                  severity: 'error',
                  detail: response.validationFailureMessages[i]
                });
              }
            } else {
              this.uploading.next(false);
              this.setMessage({ severity: 'success', summary: 'Success', detail: 'File uploaded successfully.' });
              this.resetInput.next(true);
            }

          }, error => {
            this.uploading.next(false);
            this.setMessage({ severity: 'error', summary: 'Error', detail: error.statusText });
          });
      }
    }
  }

  public downloadExcel(): Observable<{ filename: string, blob: Blob }> {
    const searchQuery = this.searchQuery.getValue();

    const organisationId = this.orgService.getCurrentOrganisationId();
    let httpParams = new HttpParams();
    if (organisationId && searchQuery) {
      httpParams = httpParams.append(EndPointConstants.PARAM_ORGANISATION_ID, this.orgService.getCurrentOrganisationId().toString());
    }
    const options = { observe: 'response' as 'body', params: httpParams, responseType: 'blob' as 'json' };

    return this.httpClient.post(this.api_route + '/search/report', searchQuery, options)
      .pipe(map((response: HttpResponse<Blob>) => {
        const filename = this.getFilenameFromHeader(response.headers);
        const blob: Blob = response.body;
        return { filename, blob };
      }), catchError(this.handleError));
  }

  private getFilenameFromHeader(headers): string {
    const contentDisposition = headers.get('content-disposition');
    return contentDisposition.split(';')[1].trim().split('=')[1];
  }

  private handleError(error: HttpErrorResponse) {
    console.error(error);
    const errMsg = (error.message) ? error.message : 'Server error';
    return observableThrowError(errMsg);
  }

  refreshAcerUploadGrants() {

    const organisationId = this.orgService.getCurrentOrganisationId();
    let httpParams = new HttpParams();
    if (organisationId > 0) {
      this.acerUploadGrant.next('No organisation in your group is authorised to upload ACER report files.');
      httpParams = httpParams.append(EndPointConstants.PARAM_ORGANISATION_ID, organisationId.toString());
      const grants = this.httpClient.get<any>(this.api_route + '/acerUploadRights', {
        params: httpParams
      }).subscribe(
        data => {
          this.acerUploadGrant.next(data.response);
        }
      );

    } else {
      this.acerUploadGrant.next('');
    }
  }

  reset() {
    this.searchQuery.next(null);
    this.message.next(null);
    this.filesInData.next([]);
    this.busy = false;
  }

  public setSearchQuery(searchQuery: RemitFilesInSearchQuery) {
    this.searchQuery.next(searchQuery);
  }

  getSearchQuery(): RemitFilesInSearchQuery {
    return this.searchQuery.getValue();
  }

  public onSearchQueryChange(): Observable<RemitFilesInSearchQuery> {
    return this.searchQuery.asObservable();
  }

  public setMessage(message: Message) {
    this.message.next(message);
  }

  public onMessageChange(): Observable<Message> {
    return this.message.asObservable();
  }

  loadDocumentTypes(): Observable<string[]> {
    const organisationId = this.orgService.getCachedOrganisationId();
    let httpParams = new HttpParams();
    if (organisationId) {
      httpParams = httpParams.append(EndPointConstants.PARAM_ORGANISATION_ID, organisationId.toString());
    }
    return this.httpClient.get<any>(this.api_route + '/documentTypes', {
      params: httpParams
    }).pipe(map(
      data => {
        return data.values;
      }
    ));
  }

  loadReceiptDate(): Observable<any> {

    const organisationId = this.orgService.getCachedOrganisationId();
    let httpParams = new HttpParams();
    if (organisationId) {
      httpParams = httpParams.append(EndPointConstants.PARAM_ORGANISATION_ID, organisationId.toString());
    }
    return this.httpClient.get<any>(this.api_route + '/receivedDateTimeRange', {
      params: httpParams
    }).pipe(map(
      data => {
        return data;
      }
    ));
  }

  isValidFile(file: File): boolean {
    let valid: boolean = true;
    if (file) {
      if (file.name.length > 100) {
        valid = false;
        this.setMessage({ severity: 'error', summary: 'Error', detail: 'File name length over limit.' });
      }

      const sizeInMB = parseFloat(((file.size / 1000) / 1024).toFixed(2));

      if (sizeInMB > 50) {
        valid = false;
        this.setMessage({ severity: 'error', summary: 'Error', detail: 'File exceeds limit of 50 MB.' });
      }

      if (file.type !== 'text/xml') {
        valid = false;
        this.setMessage({ severity: 'error', summary: 'Error', detail: 'Please choose a .xml file.' });
      }
    }

    return valid;
  }

  onAcerUploadGrant(): Observable<string> {
    return this.acerUploadGrant.asObservable();
  }

  hasUploadPermission(): Observable<any> {

    const organisationId = this.orgService.getCurrentOrganisationId();
    let httpParams = new HttpParams();
    if (organisationId) {
      httpParams = httpParams.append(EndPointConstants.PARAM_ORGANISATION_ID, organisationId.toString());
    }
    return this.httpClient.get<any>(this.api_route + '/uploadPermission', {
      params: httpParams
    }).pipe(map(
      data => {
        return data;
      }
    ));
  }

  hasElcomOnlyPermission(): Observable<any> {

    const organisationId = this.orgService.getCurrentOrganisationId();
    let httpParams = new HttpParams();
    if (organisationId) {
      httpParams = httpParams.append(EndPointConstants.PARAM_ORGANISATION_ID, organisationId.toString());
    }
    return this.httpClient.get<any>(this.api_route + '/elcomOnlyPermission', {
      params: httpParams
    }).pipe(map(
      data => {
        return data;
      }
    ));
  }
}

export class RemitFilesInSearchQuery {

  filenameSearchModel?: ByFilenameSearchModel;
  documentSearchModel?: ByDocumentSearchModel;

  sorting: SortingFilter;
  paging: PagingFilter;

  public static isNotEmptySearchQuery(query: RemitFilesInSearchQuery): boolean {
    return query != null && JSON.stringify(query) !== JSON.stringify(RemitFilesInSearchQuery.createEmptySearchQuery());
  }

  public static createEmptySearchQuery(): RemitFilesInSearchQuery {
    return {
      paging: {
        entriesPerPage: 25,
        page: 0
      },
      sorting: {
        columnName: 'RECEIPT_TIMESTAMP',
        sortOrder: 'DESCENDING',
      }
    };
  }

  public static createDocumentSearchQuery(): RemitFilesInSearchQuery {
    const documentSearchQuery = this.createEmptySearchQuery();
    documentSearchQuery.documentSearchModel = new ByDocumentSearchModel();

    return documentSearchQuery;
  }

  public static createFilenameSearchQuery(): RemitFilesInSearchQuery {
    const filenameSearchQuery = this.createEmptySearchQuery();
    filenameSearchQuery.filenameSearchModel = new ByFilenameSearchModel();
    return filenameSearchQuery;
  }
}

export class ByDocumentSearchModel {

  documentTypes: string[];
  receivedDateTimeRange: DateTimeRangeFilter;
}

export class ByFilenameSearchModel {

  filename: string;
}

