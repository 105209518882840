import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table/table.component';
import { RouterModule, Routes } from '@angular/router';
import { CmsCommonModule } from '@common/cms-common.module';
import { ButtonModule } from 'primeng/button';
import { SharedModule } from 'primeng/api';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { getReducers, reducerToken } from './state/table.reducers';
import { AcerBillingRunsMailEffects } from './state/table.effects';


const routes: Routes = [{ path: 'table', component: TableComponent}];


@NgModule({
  declarations: [
    TableComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    StoreModule.forFeature('acer_billing_mail', reducerToken),
    EffectsModule.forFeature([
      AcerBillingRunsMailEffects
    ]),
    CmsCommonModule,
    ButtonModule,
    SharedModule,
  ], providers: [
    {
      provide: reducerToken,
      useFactory: getReducers
    }
  ]
})
export class MailsReportModule { }
