import { TableEntry, } from '../../../administration-tab-panel/billing-runs/acer/acer-billing-run-tab.model';
import {
  AcerBillingRunsActions,
  cleanState, getAcerBillingRunErrorsSuccess,
  loading, loadPresetsSuccess,
  loadTableSuccess, resetAcerBillingRunErrors,
  resetMessages, setDeleteMessages,
  setMessages,
  setPagingAndSorting,
} from './acer-runs.actions';
import { createReducer, on } from '@ngrx/store';
import { Message } from 'primeng/api';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';

export interface AcerBillingRunsState {
  messages: { table: Message[], dialog: Message[] };
  loading: { table?: boolean, errors?: boolean, delete?: boolean, run?: boolean, send?: boolean };
  paging: PagingFilter;
  sorting: SortingFilter;
  data: DatatableData<TableEntry[]>;
  presets: { billingRunAllowed: boolean };
  errors: { name: string, code: string }[];
}


export const initialAcerBillingRunsState: AcerBillingRunsState = {
  messages: { table: [], dialog: [] },
  errors:[],
  loading: { table: false, errors: false, delete: false, run: false, send: false },
  paging: { page: 0, entriesPerPage: 20 },
  sorting: { columnName: 'quarter', sortOrder: 'DESCENDING' },
  data: { values: [], currentPage: 0, count: 0 },
  presets: { billingRunAllowed: false }
};


export const acerBillingRunsReducer = createReducer<AcerBillingRunsState, AcerBillingRunsActions>(
  initialAcerBillingRunsState,

  on(cleanState, ( ) => ({
    ...initialAcerBillingRunsState
  })),

  on(loading, (state, { load }) => ({
    ...state,
    loading: {...state.loading, ...load }
  })),

  on(setMessages, (state, { msg }) => ({
    ...state,
    messages: { dialog: [...state.messages.dialog], table: [...msg]  }
  })),

  on(setDeleteMessages, (state, { msg }) => ({
    ...state,
    messages: { table: [...state.messages.table], dialog: [...msg] }
  })),

  on(resetMessages, (state) => ({
    ...state,
    messages: { table: [], dialog: [] },
  })),

  on(resetAcerBillingRunErrors, (state) => ({
    ...state,
    errors: []
  })),

  on(loadPresetsSuccess, (state, { billingRunAllowed }) => ({
    ...state,
    presets: { billingRunAllowed }
  })),

  on(setPagingAndSorting, (state, { paging, sorting }) => ({
    ...state,
    paging: paging,
    sorting: sorting,
  })),

  on(loadTableSuccess, (state, { data }) => ({
    ...state,
    data: { ...data },
    paging: { ...state.paging, page: data.currentPage }
  })),

  on(getAcerBillingRunErrorsSuccess, (state, { err }) => ({
    ...state,
    errors: [...err]
  })),
);
