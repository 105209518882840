<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>

  <p-tabView (onChange)="setTab($event)" [activeIndex]="activeIndex">
    <p-tabPanel header="Invoice Settings">
      <div class="tab-view-body">
        <div class="tab-view-body">
          <cms-invoice-settings *ngIf="activeIndex === 0"></cms-invoice-settings>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Netting Settings">
      <div class="tab-view-body">
        <div class="tab-view-body">
          <cms-netting-settings *ngIf="activeIndex === 1"></cms-netting-settings>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Tolerance Matching Settings">
      <div class="tab-view-body">
        <div class="tab-view-body">
          <cms-tolerance *ngIf="activeIndex === 2"></cms-tolerance>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Email Subscriptions">
      <div class="tab-view-body">
        <div class="tab-view-body">
          <cms-email-subscription *ngIf="activeIndex === 3"></cms-email-subscription>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="eSM Email Whitelist">
      <div class="tab-view-body">
        <div class="tab-view-body">
          <cms-whitelist *ngIf="activeIndex === 4"></cms-whitelist>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>

</ng-template>
