import { Observable, throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ApiError, BaseResponseModel } from './shared/shared.model';
import { catchError, tap } from 'rxjs/operators';
import { AuthenticationService } from '../auth/authentication.service';

@Injectable()
export class UrlReferenceService {

  private urlReferenceModel: UrlReferenceModel;

  constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
  }

  public resolveUrlReference(referenceCode: string): Observable<UrlReferenceModel> {

    return this.httpClient.get<UrlReferenceModel>('/api/url_reference/' + referenceCode)
      .pipe(
        tap((urlReferenceModel: UrlReferenceModel) => this.urlReferenceModel = urlReferenceModel),
        catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    const apiError: ApiError = error.error;
    const errMsg = (apiError && apiError.errorMessage) ? apiError.errorMessage : 'Server error';
    return observableThrowError(errMsg);
  }

  public getAndResetUrlReferenceModel(): UrlReferenceModel {
    const result: UrlReferenceModel = this.urlReferenceModel;
    this.urlReferenceModel = null;
    return result;
  }
}

export interface UrlReferenceModel extends BaseResponseModel{
  organisationId: number;
  referenceCode: string;
  targetUrl: string;
  targetRequestParams: string; // request JSON object
}
