<div class="panel-box">
  <div class="margin-bottom-3" *ngIf="isUtiSearchFieldVisible()">
    <cms-search-textarea [disabled]="loading | async" [formGroup]="utiSearchForm" id="uti" label="UTI / Order ID" (submit)="searchByUti($event)"></cms-search-textarea>
    <p class="error-note text-small mb-0" *ngIf="isUtiRuleConformError()">Field 'UTI / Order ID' {{this.utiSearchForm.controls['uti'].errors['idsNotRuleConform'].reason}}.</p>
  </div>
  <div class="margin-bottom-3" *ngIf="isTransactionIdSearchFieldVisible()">
    <cms-search-textarea [disabled]="loading | async" [formGroup]="transactionIdForm" id="transactionId"
    [label]="transactionIdLabel" (submit)="searchByTransactionId($event)"></cms-search-textarea>
    <p class="error-note text-small mb-0" *ngIf="isTransactionIdsRuleConformError()">Field '{{transactionIdLabel}}' {{this.transactionIdForm.controls['transactionId'].errors['idsNotRuleConform'].reason}}.</p>
  </div>
  <div class="margin-bottom-3" *ngIf="isContractIdSearchFieldVisible()">
    <cms-search-textarea [disabled]="loading | async" [formGroup]="contractIdForm" id="contractId" label="UTI (Contract ID)" (submit)="searchByContractId($event)"></cms-search-textarea>
    <p class="error-note text-small mb-0" *ngIf="isContractIdsRuleConformError()">Field 'UTI (Contract ID)' {{this.contractIdForm.controls['contractId'].errors['idsNotRuleConform'].reason}}.</p>
  </div>
  <div class="margin-buttom-3" *ngIf="isUniqueIdVisible()">
    <cms-search-textarea [disabled]="loading | async" [formGroup]="uniqueIdForm" id="uniqueId" label="Unique ID" (submit)="searchByUniqueId($event)"></cms-search-textarea>
    <p class="error-note text-small mb-0" *ngIf="isUniqueIdsRuleConformError()">Field 'Unique ID' {{this.uniqueIdForm.controls['uniqueId'].errors['idsNotRuleConform'].reason}}.</p>
  </div>
  <div class="margin-buttom-3" *ngIf="isReferenceIdVisible()">
    <cms-search-textarea [disabled]="loading | async" [formGroup]="referenceIdForm" id="referenceId" label="Reference ID" (submit)="searchByReferenceId($event)"></cms-search-textarea>
    <p class="error-note text-small mb-0" *ngIf="isReferenceIdsRuleConformError()">Field 'Reference ID' {{this.referenceIdForm.controls['referenceId'].errors['idsNotRuleConform'].reason}}.</p>
  </div>
  <div class="margin-bottom-6">
    <cms-search-input [disabled]="loading | async" [formGroup]="filenameForm" id="filename" label="Filename" (submit)="searchByFilename($event)" maxlength="255"></cms-search-input>
  </div>
  <div class="margin-bottom-3 row">
    <div class="col">
      <button class="btn form-control margin-bottom-3 margin-top-3 padding-6" (click)="setReset()">Reset</button>
    </div>
  </div>
</div>
