import { Injectable } from '@angular/core';
import { ApiRequestService } from '@common/api-request.service';
import {
  EsmNettingStatementAggregationKey,
  EsmNettingStatementAggregationKeyResponseModel
} from './esm-netting-finder-aggregation-key.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EsmNettingFinderTableAggregationKeysService {

  private baseUrl= '/api/esm/nettingstatement/finder';
  constructor(private apiRequestService: ApiRequestService) { }

  private loading: boolean = false;

  aggregationKeyUrlMap = {
    'commodity': {path:'/aggKeysTypeCommodityByParentId'},
    'deliveryPointArea': {path:'/aggKeysTypeDeliveryPointByParentId'},
    'physicalFinancial': {path:'/aggKeysTypeInvoiceTypeByParentId'},
    'currency': {path:'/aggKeysTypeCurrencyByParentId'},
    'marketInformation': {path:'/aggKeysTypeMarketInformationByParentId'},
    'totalVolumeUnit': {path:'/aggKeysTypeTotalVolumeUnitByParentId'},
    'fixedFloating': {path:'/aggKeysTypeFixedOrFloatingByParentId'},
    'natureOfPrice': {path:'/aggKeysTypeNatureOfPriceByParentId'},
    'agreementDetails': {path:'/aggKeysTypeAgreementDetailsByParentId'}
  };
  aggregationKeyNameMap = {
    'commodity': 'COMMODITY',
    'deliveryPointArea': 'DELIVERY_POINT',
    'physicalFinancial':  'INVOICE_TYPE',
    'currency': 'CURRENCY',
    'marketInformation': 'MARKET_INFORMATION',
    'totalVolumeUnit': 'TOTAL_VOLUME_UNIT',
    'fixedFloating':'FIXED_OR_FLOATING',
    'natureOfPrice': 'NATURE_OF_PRICE',
    'agreementDetails': 'AGREEMENT_DETAILS'
  };

  aggregationKeyMap = [];


  aggregationKeys$ = new BehaviorSubject<string>(null);

  loadAggregationKeys(name: string, id: string): void {

      this.loading = true;
      const params = new HttpParams().set('parentId', id);

       this.apiRequestService.get(`${this.baseUrl}${this.aggregationKeyUrlMap[name].path}`, params).subscribe((response: EsmNettingStatementAggregationKeyResponseModel) => {

         if(response.values && response.values.length > 0){
           const parentId: string = response.values[0].parentId;
           const keyMap = this.aggregationKeyMap[parentId] || [];
           keyMap[response.values[0].aggKeyType] = response.values;
           this.aggregationKeyMap[parentId] = keyMap;
           this.aggregationKeys$.next(parentId);

         }

         this.loading = false;
      });
  }

  getAggregationKeys(id: string, name: string ): EsmNettingStatementAggregationKey[]{
    const keys = this.aggregationKeyMap[id];
    return keys[this.aggregationKeyNameMap[name]];
  }

  onAggregationKeysLoaded(): Observable<string>{
    return this.aggregationKeys$.asObservable();
  }
}
