import { createSelector } from '@ngrx/store';
import { getDashboard } from '../dashboard.selectors';
import * as fromReducer from './filters.reducers';

export const getFilters = createSelector(getDashboard, fromReducer.getFilters);
export const getViewBy = createSelector(getFilters, fromReducer.getViewBy);
export const getFilterProperties = createSelector(getFilters, fromReducer.getFilterProperties);

export const getBroker = createSelector(getFilters, fromReducer.getBroker);
export const getTransactionTypes = createSelector(getFilters, fromReducer.getTransactionTypes);
export const getMarkets = createSelector(getFilters, fromReducer.getMarkets);
export const getCounterParties = createSelector(getFilters, fromReducer.getCounterParties);
export const getCommodities = createSelector(getFilters, fromReducer.getCommodities);
export const getLoaded = createSelector(getFilters, fromReducer.getLoaded);
