<div class="fixing-index">
  <div class="form-title">Trade | Fixing Index</div>
  <div class="form-body" [formGroup]="formGroup">
    <div formArrayName="fixingIndices">
      <div *ngFor="let fixingIndex of fixingIndices; let i = index" [formGroupName]="i">

        <div class="row gutter-0 !me-[40px]">
          <div class="col-5 label">Index Name {{i + 1}} <span class="text-required">*</span></div>
          <div class="col-7 ps-2 pe-1">
            <p-dropdown formControlName="indexName" [options]="indexNames" [group]="true" [style]="{'width': '100%'}" class="nowrap-dropdown"
              [styleClass]="'margin-3 ' + getFixingIndexErrorClass(i, 'indexName')" >
            </p-dropdown>
          </div>
          <span *ngIf="getFixingIndexErrorClass(i, 'indexName')" class="fa fa-info-circle absolute error-marker"></span>
        </div>
        <div class="row gutter-0 !me-[40px]">
          <div class="col-5 label">Index Type {{i + 1}}</div>
          <div class="col-7 ps-2 pe-1">
            <p-dropdown formControlName="indexType" [options]="indexTypes" [group]="true" [style]="{'width': '100%'}"
              [styleClass]="'margin-3 '" ></p-dropdown>
          </div>
        </div>
        <div class="row gutter-0 !me-[40px]">
          <div class="col-5 label">Index Source {{i + 1}}</div>
          <div class="col-7 ps-2 pe-1">
            <input type="text" class="form-control p-component padding-6 text-medium margin-3"
              formControlName="indexSource" [maxlength]="150">
          </div>
        </div>
        <div class="row gutter-0 !me-[40px]">
          <div class="col-5 label">First Index Date {{i + 1}}</div>
          <div class="col-7 ps-2 pe-1">
            <span class="inline-block padding-right-3 margin-3">
              <p-calendar
                formControlName="firstFixingDate"
                [showIcon]="true"
                [monthNavigator]="true"
                [yearNavigator]="true"
                placeholder="dd/mm/yyyy"
                dateFormat="dd/mm/yy"
                [style]="{ 'width': '125px'}"
                cmsDateRestriction>
              </p-calendar>
            </span>
          </div>
        </div>
        <div class="row gutter-0 !me-[40px]">
          <div class="col-5 label">Last Index Date {{i + 1}}</div>
          <div class="col-7 ps-2 pe-1">
            <span class="inline-block padding-right-3 margin-3">
              <p-calendar
                formControlName="lastFixingDate"
                [showIcon]="true"
                [monthNavigator]="true"
                [yearNavigator]="true"
                placeholder="dd/mm/yyyy"
                dateFormat="dd/mm/yy"
                [styleClass]="getFixingIndexErrorClass(i, 'lastFixingDate')"
                [style]="{ 'width': '125px'}"
                cmsDateRestriction>
              </p-calendar>
            </span>
          </div>
          <span *ngIf="getFixingIndexErrorClass(i, 'lastFixingDate')"
            class="fa fa-info-circle absolute error-marker"></span>
        </div>
        <div class="row gutter-0 !me-[40px]">
          <div class="col-5 label bottom-row-border">Fixing Frequency {{i + 1}}</div>
          <div class="col-7 bottom-row-border ps-2 pe-1">
            <p-dropdown formControlName="fixingFrequency" [options]="fixingFrequencies" [style]="{'width': '100%'}"
              [styleClass]="'margin-3 '" ></p-dropdown>
          </div>
        </div>

      </div>
      <div class="row gutter-0 !me-[40px]">
        <div class="col-5 label"></div>
        <div class="col-7 ps-2 pe-1">
          <button type="button" class="btn add-remove-line" (click)="add()" [disabled]="disableAddButton">Add</button>
          <button type="button" class="btn add-remove-line" (click)="remove()"
            [disabled]="disableRemoveButton">Remove</button>
        </div>
      </div>
    </div>
  </div>
</div>
