import { createSelector } from '@ngrx/store';
import { getEcmState, State } from '../../reducers';
import * as fromReducer from './process.reducers';

export const getSettingsProcessState = createSelector(getEcmState, (state: State) => state.settingsProcess);

export const getData = createSelector(getSettingsProcessState, fromReducer.getData);

export const getSorting = createSelector(getSettingsProcessState, fromReducer.getSorting);
export const getLoading = createSelector(getSettingsProcessState, fromReducer.getLoading);
export const getMessages = createSelector(getSettingsProcessState, fromReducer.getMessages);

export const getColumns = createSelector(getSettingsProcessState, fromReducer.getColumns);
export const getSelected = createSelector(getSettingsProcessState, fromReducer.getSelected);
