import { Component, OnDestroy, OnInit } from '@angular/core';
import { OrganisationService } from '@common/organisation.service';
import { EsmSettingsState } from '../../state/settings/settings.reducers';
import { select, Store } from '@ngrx/store';
import * as fromNettingActions from '../../state/settings/netting/netting-settings.actions';
import * as fromNettingSelectors from '../../state/settings/netting/netting-settings.selectors';
import { Observable, Subscription } from 'rxjs';
import { Message } from 'primeng/api';
import * as fromDownloadActions from '../../state/settings/netting/netting-settings.actions';
import {
  esmNettingSettingsColumns,
  FinancialBusinessOffsetRange, NettingSettingsActivateBlockRequestModel,
  NettingSettingsModel,
  PhysicalBusinessOffsetRange,
  VatIds
} from './netting-settings.model';
import { DatatableData, PagingFilter, SortingFilter, SortOrder } from '@common/shared/results.model';
import { pagingAndSorting, setSelectedVatNumber } from '../../state/settings/netting/netting-settings.actions';
import { DialogState } from '@common/state/reducers';
import { SETTINGS_DIALOGS } from '../invoice/invoice-settings.model';
import { PagerInfo } from '@common/shared/datatable.model';

@Component({
  selector: 'cms-netting-settings',
  templateUrl: './netting-settings.component.html',
  styleUrls: ['./netting-settings.component.scss']
})
export class NettingSettingsComponent implements OnInit, OnDestroy {
  messages$: Observable<Message[]> = this.store.pipe(select(fromNettingSelectors.getMessages));
  loading$: Observable<boolean> = this.store.pipe(select(fromNettingSelectors.getLoading));
  paging$: Observable<PagingFilter> = this.store.pipe(select(fromNettingSelectors.getPaging));
  sorting$: Observable<SortingFilter> = this.store.pipe(select(fromNettingSelectors.getSorting));
  data$: Observable<any[]> = this.store.pipe(select(fromNettingSelectors.getTableData));
  dataWithPaging$: Observable<DatatableData<NettingSettingsModel[]>> = this.store.pipe(select(fromNettingSelectors.getTableDateWithPaging));
  vatIds$: Observable<VatIds[]> = this.store.pipe(select(fromNettingSelectors.getVatIds));
  dialog$: Observable<DialogState<SETTINGS_DIALOGS>> = this.store.pipe(select(fromNettingSelectors.getDialog));
  selectedVatId$: Observable<string> = this.store.pipe(select(fromNettingSelectors.getSelectedVatId));
  columns = esmNettingSettingsColumns;
  pagerInfo: PagerInfo;

  vatIds: VatIds[] = null;

  toggleNettingAllowed$: Observable<boolean> = this.store.pipe(select(fromNettingSelectors.getToggleNettingAllowed));
  nettingSupported$: Observable<boolean> = this.store.pipe(select(fromNettingSelectors.getNettingSupported));
  centralNettingSupported$: Observable<boolean> = this.store.pipe(select(fromNettingSelectors.getCentralNettingSupported));
  crossCommodityNettingSupported$: Observable<boolean> = this.store.pipe(select(fromNettingSelectors.getCCNettingSupported));
  downloadAllowed$: Observable<boolean> = this.store.pipe(select(fromNettingSelectors.getDownloadAllowed));
  subscription$: Subscription;

  businessOffsetUntilPhysical = PhysicalBusinessOffsetRange;
  businessOffsetUntilFinancial = FinancialBusinessOffsetRange;
  selectedCallDayPhysicalNettingGeneration$: Observable<number> = this.store.pipe(select(fromNettingSelectors.getSelectedCallDayPhysicalNettingGeneration));
  selectedCallDayFinancialNettingGeneration$: Observable<number> = this.store.pipe(select(fromNettingSelectors.getSelectedCallDayFinancialNettingGeneration));
  callOfDayPhysicalNettingInitiallySet = false;
  callOfDayFinancialNettingInitiallySet = false;

  constructor(private store: Store<EsmSettingsState>,
              public organisationService: OrganisationService) {
  }

  ngOnInit() {

    this.pagerInfo = PagerInfo.calcPagerInfo(0, 0, 25);
    this.subscription$ = this.organisationService.onRefresh().subscribe((authenticated) => {
      if (authenticated) {
        this.store.dispatch(fromNettingActions.loadPresetValuesAction());
        this.onReset();
        this.store.dispatch(fromNettingActions.loading(false));
      }
    });

    this.subscription$.add(this.vatIds$.subscribe(value => {
      this.vatIds = value as VatIds[];
      this.vatIds.splice(0, 0, { masterDataId: null, vatId: null });
      if (this.vatIds !== null && this.vatIds !== undefined) {
        this.store.dispatch(setSelectedVatNumber(value[0].vatId));
        this.store.dispatch(fromNettingActions.filterTableData());
      }

    }));
    this.subscription$.add(this.dataWithPaging$.subscribe(data => {
      this.pagerInfo = PagerInfo.calcPagerInfo(data.count, data.currentPage, 25);
    }));
  }

  onDownload() {
    this.store.dispatch(fromDownloadActions.downloadGatekeeperAction());
  }

  onNettingSupportedChanged(checked: boolean): void {
    this.store.dispatch(fromNettingActions.confirmActivateAction(checked));
  }

  onCentralNettingSupportedChanged(checked: boolean): void {
    this.store.dispatch(fromNettingActions.setCentralNettingSupported(checked));
  }

  onCCNettingSupportChanged(checked: boolean): void {
    this.store.dispatch(fromNettingActions.setCrossCommodityNettingSupported(checked));
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

  onPhysicalDropDownDayRangeChange(selected: number): void {
    // CMSDEV-4331: Since updating angular to 17.3.4 it is necessary to prevent the first trigger of the dropdown ngModelChanged event.
    // This would reset the value in the backend to the initial default state of 12 days.
    if (!this.callOfDayPhysicalNettingInitiallySet) {
      this.callOfDayPhysicalNettingInitiallySet = true;
      return;
    }
    this.store.dispatch(fromNettingActions.setCallDayPhysicalNettingGeneration(selected));
  }

  onFinancialDropDownDayRangeChange(selected: number): void {
    // CMSDEV-4331: Since updating angular to 17.3.4 it is necessary to prevent the first trigger of the dropdown ngModelChanged event.
    // This would reset the value in the backend to the initial default state of 12 days.
    if (!this.callOfDayFinancialNettingInitiallySet) {
      this.callOfDayFinancialNettingInitiallySet = true;
      return;
    }
    this.store.dispatch(fromNettingActions.setCallDayFinancialNettingGeneration(selected));
  }

  onLazyLoad({ first, rows, sortField, sortOrder }: any) {
    const currentPage = first / rows;
    const sOrder = (sortOrder === -1) ? 'DESCENDING' : 'ASCENDING';

    this.store.dispatch(pagingAndSorting({
        paging: { page: currentPage, entriesPerPage: rows },
        sorting: { sortOrder: sOrder, columnName: sortField }
      })
    );
    this.store.dispatch(fromNettingActions.filterTableData());
  }

  onReset() {
    this.store.dispatch(fromNettingActions.setSearchString(null));
  }

  onTextFieldFilter(text: string) {
    this.store.dispatch(fromNettingActions.setSearchString(text));
    this.store.dispatch(fromNettingActions.filterTableData());
  }

  openDialog(row: any) {
    this.store.dispatch(fromNettingActions.openDialogAction({
      name: 'TOGGLE',
      messages: [],
      data: row
    }));
  }

  onActivateButton(row: NettingSettingsModel) {
    const requestModel: NettingSettingsActivateBlockRequestModel = {
      // CMSDEV-2104: as discussed with alex the BE expects the FE to return the agreement
      // value which should be the new value, therefore we need to invert the agreement boolean
      agreement: !row.agreement,
      cpMasterDataId: row.cpMasterDataId,
      cpOrganisationId: row.cpOrganisationId,
      organisationId: row.organisationId,
      masterDataId: row.masterDataId
    };
    this.store.dispatch(fromNettingActions.closeDialogAction());
    this.store.dispatch(fromNettingActions.activateNetting(requestModel));
  }

  onBlockItem(row: NettingSettingsModel) {
    const requestModel: NettingSettingsActivateBlockRequestModel = {
      // CMSDEV-2104: as discussed with alex the BE expects the FE to return the agreement
      // value which should be the new value, therefore we need to invert the agreement boolean
      agreement: !row.agreement,
      cpMasterDataId: row.cpMasterDataId,
      cpOrganisationId: row.cpOrganisationId,
      organisationId: row.organisationId,
      masterDataId: row.masterDataId
    };
    this.store.dispatch(fromNettingActions.closeDialogAction());
    this.store.dispatch(fromNettingActions.blockNetting(requestModel));
  }

  onCancelButton() {
    this.store.dispatch(fromNettingActions.closeDialogAction());
    this.store.dispatch(fromNettingActions.filterTableData());
    this.store.dispatch(fromNettingActions.loading(false));
  }

  dropdownValueChanged(event: any) {
    this.store.dispatch(fromNettingActions.setSelectedVatNumber(event.value.vatId));
    this.store.dispatch(fromNettingActions.filterTableData());
  }
}
