import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'cms-near-match-button',
  templateUrl: './near-match-button.component.html',
  styleUrls: ['./near-match-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NearMatchButtonComponent {
  @Input() title = '';
}
