import { Column } from '../shared/datatable.model';

export namespace AudittrailDatatableConfig {
  // TODO CMSGUI-264 mr3 2018-01: check how to assure with typescript that a typo in a field name of TABLE_COLUMNS or a entry of DEFAULT_TABLE_COLUMNS is noticed by compiler

  const TABLE_COLUMNS: { [name: string]: Column } = {
    timestamp: { title: 'Date / Time', style: { 'width': '75px' } },
    text: { title: 'Description', style: { 'width': '800px' }, sortable: false },

    severity: { title: 'severity', style: { 'width': '100px' }, visible: false },
    source: { title: 'Source', style: { 'width': '100px' }, visible: false },
    id: { title: 'Id', style: { 'width': '100px' }, visible: false }
  };

  /**
   * Only the following entries are visible columns in the given order.
   */
  const DEFAULT_TABLE_COLUMNS = [
    'timestamp',
    'text',
    'severity',
    'source',
    'id'
  ];

  export function getDefaultSortingColumn(): string {
    return 'timestamp';
  }

  export function getDefaultColumns(): string[] {
    return DEFAULT_TABLE_COLUMNS;
  }

  export function getColumns(columnNames: string[]): Column[] {
    return columnNames.map(name => {
      const column = TABLE_COLUMNS[name];
      return Object.assign({
        name: name,
        width: '200px',
        sortable: true,
        visible: true,
        editable: false
      }, column);
    });
  }
}
