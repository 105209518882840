import { Observable, throwError as observableThrowError } from 'rxjs';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SystemInformationModel } from './system-informations.model';
import { catchError, publishReplay, refCount } from 'rxjs/operators';

@Injectable()
export class SystemService {

  private apiUrl = '/api/system/information';
  private systemInformation: Observable<SystemInformationModel>;

  constructor(private httpClient: HttpClient) {
  }

  getInformations(): Observable<SystemInformationModel> {
    if (!this.systemInformation) {
      this.systemInformation = this.fetchSystemInformations().pipe(
        publishReplay(1)
        , refCount());
    }
    return this.systemInformation;
  }

  clearInformationsCache() {
    this.systemInformation = null;
  }

  private fetchSystemInformations(): Observable<SystemInformationModel> {
    return this.httpClient.get<SystemInformationModel>(this.apiUrl).pipe(
      catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    const errMsg = (error.message) ? error.message : 'Server error';
    return observableThrowError(errMsg);
  }

}
