import { EsmSettingsState } from '../settings.reducers';
import { createSelector } from '@ngrx/store';
import { EsmNettingSettingsState } from './netting-settings.reducers';
import { getEsmSettingsState } from '../settings.selectors';

export const getEsmNettingSettingsState = createSelector(getEsmSettingsState, (state: EsmSettingsState) => state.netting);
export const getToggleNettingAllowed = createSelector(getEsmNettingSettingsState, (state: EsmNettingSettingsState) => state.toggleNettingAllowed);
export const getNettingSupported = createSelector(getEsmNettingSettingsState, (state: EsmNettingSettingsState) => state.nettingSupported);
export const getCentralNettingSupported = createSelector(getEsmNettingSettingsState, (state: EsmNettingSettingsState) => state.centralNettingSupported);
export const getCCNettingSupported = createSelector(getEsmNettingSettingsState, (state: EsmNettingSettingsState) => state.crossCommodityNettingSupported);
export const getDownloadAllowed = createSelector(getEsmNettingSettingsState, (state: EsmNettingSettingsState) => state.downloadAllowed);
export const getMessages = createSelector(getEsmNettingSettingsState, (state: EsmNettingSettingsState) => state.messages);
export const getSelectedCallDayPhysicalNettingGeneration = createSelector(getEsmNettingSettingsState, (state: EsmNettingSettingsState) => state.generationDatePhysical);
export const getSelectedCallDayFinancialNettingGeneration = createSelector(getEsmNettingSettingsState, (state: EsmNettingSettingsState) => state.generationDateFinancial);
export const getLoading = createSelector(getEsmNettingSettingsState, (state: EsmNettingSettingsState) => state.loading);
export const getPaging = createSelector(getEsmNettingSettingsState, (state: EsmNettingSettingsState) => state.paging);
export const getSorting = createSelector(getEsmNettingSettingsState, (state: EsmNettingSettingsState) => state.sorting);
export const getTableData = createSelector(getEsmNettingSettingsState, (state: EsmNettingSettingsState) =>
  state.data.values == null ? null : state.data.values.map((item) => Object.assign({}, item)));
export const getTableDateWithPaging = createSelector(getEsmNettingSettingsState, (state: EsmNettingSettingsState) => state.data);
export const getVatIds = createSelector(getEsmNettingSettingsState, (state: EsmNettingSettingsState) => state.vatIds);
export const getDialog = createSelector(getEsmNettingSettingsState, (state: EsmNettingSettingsState) => state.dialog);
export const getSearchString = createSelector(getEsmNettingSettingsState, (state: EsmNettingSettingsState) => state.searchString);
export const getSelectedVatId = createSelector(getEsmNettingSettingsState, (state: EsmNettingSettingsState) => state.selectedVatId);
