<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>

<h1>ROBO Delegation Settings for {{getOrganisationName()}} </h1>
<div class="panel-box">
  <p-messages [(value)]="messages" [closable]="false"></p-messages>
  <cms-info ngPreserveWhitespaces>
    <!--@formatter:off (markdown rendering breaks, when the following tags are formatted)-->
    __ROBO Delegation Settings__

    **Purpose**

    As market participant you can control which other trading organisation groups may report on your behalf through the CMS eRR service.

    **List of Delegatees**

    The list shows all organisation groups on the CMS which have set up your company for ROBO (reporting on behalf of).
    * **Permission** = whether eRR will accepts trades from the group on sent on your behalf (Allow) or not (Block)
    * **Delegatee Group** = legal name of delegatee group (as registered on the CMS)
    * **Update** = last time you changed the permission
    * **Display Name (at Delegatee)** = which display name your delegatee configured for your company (only applies to their view)
    * **Time Zone Setting** = which locality the delegatee configured for you (influences how execution timestamps reported in local time are interpreted)

    The permissioning is shown as:
    * **Allow/Block** if it was explicitly configured by you
    * **Block (DEFAULT)** if you have not set an explicit permission yet
    * **Allow/Block (INACTIVE)** if the delegatee has removed you from their list of delegators (in which case they cannot report for you)

    **Updating Your Permissions - Master Operator (MO) privileges required**
    * Tick the checkboxes of the delegatees whose permission you wish to change
    * From the dropdown box choose the intended permission
    * Click "Apply"

    With option "Forget (if inactive)" you can remove obsolete (INACTIVE) permission settings.
    <!--@formatter:on-->
  </cms-info>

  <div id="permission-panel" class="col-12 panel-box margin-bottom-15 decent-max-width" *ngIf="permissionChangePanelVisible$ | async">
    <form [formGroup]="permissionsForm" class="row gutter-0 col-12" (ngSubmit)="onSubmit()">
      <label class="col-auto margin-3 padding-6">Change permission for checked groups:</label>
      <div class="col-auto" id="roboPermissionsSelectedPermission">
        <p-dropdown
          class="margin-3"
          [options]="permissions"
          formControlName="selectedPermission"
          [style]="{width: '135px'}">
        </p-dropdown>
      </div>
      <button type="submit" tabindex="5" class="btn margin-top-3 margin-bottom-3 margin-left-12 col-auto"
              [disabled]="!(permissionChangePanelVisible$ | async)">Apply
      </button>
    </form>
  </div>

  <div class="col-12 col-md-12 padding-0">
    <div style="max-width: 1300px; overflow-x: auto;">

      <p-table [value]="getReportingGroups()" dataKey="rowNum" [sortOrder]="1" selectionMode=""
               sortField="delegateeGroup"
               [(selection)]="selectedReportingGroups"
               scrollHeight="520px"
               [autoLayout]="true"
               [scrollable]="false">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 30px" *ngIf="permissionChangePanelVisible$ | async">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th pSortableColumn="permission">
              Permission
              <p-sortIcon field="permission"></p-sortIcon>
            </th>
            <th pSortableColumn="delegateeGroup">
              Delegatee Group
              <p-sortIcon field="delegateeGroup"></p-sortIcon>
            </th>
            <th pSortableColumn="lastUpdate">
              Last Update
              <p-sortIcon field="lastUpdate"></p-sortIcon>
            </th>
            <th pSortableColumn="displayNameAtDelegatee">
              Display Name (at Delegatee)
              <p-sortIcon field="displayNameAtDelegatee"></p-sortIcon>
            </th>
            <th pSortableColumn="timeZoneSetting">
              Time Zone Setting
              <p-sortIcon field="timeZoneSetting"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td style="width: 30px" *ngIf="permissionChangePanelVisible$ | async">
              <p-tableCheckbox [value]="rowData" [id]="'table-checkbox-row-' + rowData.rowNum"></p-tableCheckbox>
            </td>
            <td>
              <span *ngIf="rowData.permission == null" style="color: #D31245;">Block (Default)</span>
              <span *ngIf="rowData.permission != null">{{rowData.permission}}</span>
            </td>
            <td>{{rowData.delegateeGroup}}</td>
            <td><span>{{rowData.lastUpdate | dateFormatter:'timestamp'}}</span></td>
            <td>{{rowData.displayNameAtDelegatee}}</td>
            <td>{{rowData.timeZoneSetting}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

</ng-template>
