import { createAction, props } from '@ngrx/store';
import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import { Message } from 'primeng/api';
import { EmailWhitelistModel, PresetValues, TableValues } from '../../../settings/whitelist/whitelist.model';
import { EditableCell } from '@common/datatable/cell-editor/cell-editor.model';
import { Data } from '@angular/router';

export const loadTableData = createAction('[ESM-EMAIL-WHITELIST] load email whitelist table.');
export const loadPresets = createAction('[ESM-EMAIL-WHITELIST] load presets.');
export const loading = createAction('[ESM-EMAIL-WHITELIST] loading email whitelist table', (payload: boolean) => ({ payload }));
export const setMessages = createAction('[ESM-EMAIL-WHITELIST] set messages', (payload: Message[]) => ({ payload }));
export const pagingAndSorting = createAction('[ESM-EMAIL-WHITELIST] Paging and Sorting', (payload: { sorting: SortingFilter, paging: PagingFilter }) => ({ payload }));
export const uploadCsv = createAction('[ESM-EMAIL-WHITELIST] Uploading Csv-File', (payload: File) => ({ payload }));
export const cleanState = createAction('[ESM-EMAIL-WHITELIST] Resetting State');
export const downloadCsv = createAction('[ESM-EMAIL-WHITELIST] Download Csv-File', (payload: number) => ({ payload }));
export const setSearchToken = createAction('[ESM-EMAIL-WHITELIST] Setting Search Token', (payload: string) => ({ payload }));

export const triggerCheckbox = createAction('[ESM-EMAIL-WHITELIST] toggle offset', props<{ checked: boolean }>());
export const triggerCheckboxSuccess = createAction('[ESM-EMAIL-WHITELIST] toggle offset success', props<{ checked: boolean }>());

export const toggleAttachXML = createAction('[ESM-EMAIL-WHITELIST] toggle attach XML', props<{data:EmailWhitelistModel }>());


export const loadPresetsSuccess = createAction('[ESM-EMAIL-WHITELIST] loading presets succeeded.', (payload: PresetValues) => ({ payload }));
export const loadTableDataSuccess = createAction('[ESM-EMAIL-WHITELIST] loading email whitelist table succeeded.', (payload: TableValues) => ({ payload }));
export const uploadCsvSuccess = createAction('[ESM-EMAIL-WHITELIST] Uploading Csv-File succeeded');


export type EsmEmailWhitelistAction =
  | typeof loadTableData
  | typeof loadPresets
  | typeof loading
  | typeof setMessages
  | typeof pagingAndSorting
  | typeof uploadCsv
  | typeof downloadCsv
  | typeof cleanState
  | typeof loadPresetsSuccess
  | typeof loadTableDataSuccess
  | typeof uploadCsvSuccess
  | typeof setSearchToken
  | typeof triggerCheckbox
  | typeof triggerCheckboxSuccess
  | typeof toggleAttachXML;

