import * as moment from 'moment';
import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { BillingRuns, ErrorModalData } from '../billing-runs.model';

@Component({
  selector: 'cms-error-details-dialog',
  templateUrl: './error-details-dialog.component.html',
  styleUrls: ['./error-details-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorDetailsDialogComponent implements OnInit {
  @Input() display: boolean = false;
  @Input() data: ErrorModalData;
  @Output() cancel = new EventEmitter();
  header: string;

  constructor() { }

  ngOnInit(): void {
    this.generateModalheader(this.data.item);
  }

  onCancel(): void {
    this.cancel.emit();
  }

  private generateModalheader(data: BillingRuns): void {
    const date = moment(data.runDate).format('DD/MM/YYYY HH:mm:ss');
    this.header = `Errors in Billing Run ${data.billingMonth} ${data.draft ? '(draft)' : '(final)'} from ${date}`;
  }
}
