<form class="panel-box margin-bottom-15" [formGroup]="form">
  <fieldset>
    <label class="text-bold">Invoice Roles</label>
    <div class="form-check">
      <input type="radio" 
            class="form-check-input" 
            (change)="handleChange()" 
            id="esm-roleBy-all-radio" 
            value=""
            formControlName="roleBy" 
            name="roleBy" />
      <label class="form-check-label" for="esm-roleBy-all-radio">All</label>
    </div>
    <div class="form-check">
      <input type="radio" 
            class="form-check-input" 
            (change)="handleChange()" 
            id="esm-roleBy-official-radio"
            value="{{EsmRoleByType.OFFICIAL}}" 
            formControlName="roleBy" 
            name="roleBy" />
      <label class="form-check-label" for="esm-roleBy-official-radio">Invoices</label>
    </div>
    <div class="form-check">
      <input type="radio" 
            class="form-check-input" 
            (change)="handleChange()" 
            id="esm-roleBy-shadow-radio"
            value="{{EsmRoleByType.SHADOW}}" 
            formControlName="roleBy" 
            name="roleBy" />
      <label class="form-check-label" for="esm-roleBy-shadow-radio">Shadow Invoices</label>
    </div>
  </fieldset>
</form>
