import { dialogReducer, DialogState } from '@common/state/reducers';
import { SETTINGS_DIALOGS } from '../../../settings/invoice/invoice-settings.model';
import * as fromInvoiceActions from './invoice-settings.actions';
import * as fromInvoicePanelActions from './add-panel/add-panel.actions';
import * as fromInvoiceTableActions from './table/table.actions';
import * as fromEsmSettingsAddPanelReducers from './add-panel/add-panel.reducers';
import { esmAddPanelReducer } from './add-panel/add-panel.reducers';
import * as fromEsmSettingsTableReducers from './table/table.reducers';
import { esmTableReducer } from './table/table.reducers';
import { Message } from 'primeng/api';

export interface EsmInvoiceSettingsState {
  adding: fromEsmSettingsAddPanelReducers.EsmSettingsAddPanelState;
  table: fromEsmSettingsTableReducers.EsmSettingsTableState;
  dialog: DialogState<SETTINGS_DIALOGS>;
  messages: Message[];
}

export const initialState: EsmInvoiceSettingsState = {
  adding: fromEsmSettingsAddPanelReducers.addPanelInitialState,
  table: fromEsmSettingsTableReducers.tableInitialState,
  dialog: null,
  messages: null
};

const settingsDialogReducer = dialogReducer<SETTINGS_DIALOGS>({
  OPEN_DIALOG: fromInvoiceActions.openDialogAction.type,
  UPDATE_DIALOG: fromInvoiceActions.updateDialogAction.type,
  CLOSE_DIALOG: fromInvoiceActions.closeDialogAction.type,
  SET_TO_DEFAULT: fromInvoiceActions.defaultDialogAction.type
});

export function esmInvoiceSettingsReducer(
  state: EsmInvoiceSettingsState = { ...initialState },
  action: fromInvoicePanelActions.EsmSettingsAddPanelActions
    & fromInvoiceActions.EsmInvoiceSettingsAction
    & fromInvoiceTableActions.EsmSettingsTableActions): EsmInvoiceSettingsState {
  return {
    ...state,
    adding: esmAddPanelReducer(state.adding, action),
    table: esmTableReducer(state.table, action),
    dialog: settingsDialogReducer(state.dialog, action)
  };
}
