import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange
} from '@angular/core';
import { Message } from 'primeng/api';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'cms-retry-queue-dialog',
  templateUrl: './retry-queue-dialog.component.html',
  styleUrls: ['./retry-queue-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RetryQueueDialogComponent implements OnInit, OnChanges {
  @Input() display: boolean;
  @Input() messages: Message[];
  @Input() reasons: { key: string, value: string }[];
  @Output() cancelDialog = new EventEmitter();
  @Output() rejectDialog = new EventEmitter();
  reasonsOptions: { key: string, value: string }[] = [{ key: '', value: ''}];
  form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      reason: this.reasonsOptions ? this.reasonsOptions[0] : '',
      value: ''
    });
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (changes.reasons) {
      this.reasonsOptions = [
        { key: '', value: ''},
        ...(this.reasons || [])
      ];
    }

    if (this.form && changes.reasons) {
      this.form.patchValue({
        reason: this.reasonsOptions[0]
      });
      this.form.updateValueAndValidity();
    }
  }

  onChange(value: string): void {
    this.form.patchValue({
      value
    });
  }

  onCancelDialog(): void {
    this.cancelDialog.emit();
    this.form.patchValue({
      reason: this.reasonsOptions[0],
      value: ''
    });
  }

  onRejectDialog(): void {
    const { value } = this.form.value;
    this.rejectDialog.emit(value);
  }

  onVisibleChange(visible: boolean): void {
    if (!visible) {
      this.onCancelDialog();
    }
  }
}
