import { ChangeDetectionStrategy, Component, EventEmitter, Input,
  OnChanges, OnInit, Output, SimpleChange
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import { FilterRequest } from '../retry-queue.model';

@Component({
  selector: 'cms-retry-queue-filter',
  templateUrl: './retry-queue-filter.component.html',
  styleUrls: ['./retry-queue-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RetryQueueFilterComponent implements OnInit, OnChanges {
  @Input() paging: PagingFilter;
  @Input() sorting: SortingFilter;
  @Input() value: string;
  @Output() filter = new EventEmitter<FilterRequest>();
  @Output() reset = new EventEmitter<string>();
  form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      filterRequest: ''
    });
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (this.form && changes.value) {
      this.form.patchValue({
        filterRequest: this.value
      });
      this.form.updateValueAndValidity();
    }
  }

  onFilter(): void {
    const [ paging, sorting ] = [ this.paging, this.sorting ];
    this.filter.emit({
      filter: this.form.value.filterRequest,
      paging,
      sorting
    });
  }

  onReset(): void {
    this.form.patchValue({
      filterRequest: ''
    });
    this.reset.emit();
  }
}
