import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Message } from 'primeng/api';
import { Observable } from 'rxjs';
import { DialogState } from '@common/state/reducers';
import { SETTINGS_DIALOGS } from '../../invoice/invoice-settings.model';
import { select, Store } from '@ngrx/store';
import * as fromSelectors from '../../../state/settings/invoice/invoice-settings.selectors';
import { EsmSettingsState } from '../../../state/settings/settings.reducers';
import { OrganisationService } from '@common/organisation.service';
import { NettingSettingsModel } from '../netting-settings.model';

@Component({
  selector: 'cms-netting-settings-dialog',
  templateUrl: './netting-settings-dialog.component.html',
  styleUrls: ['./netting-settings-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NettingSettingsDialogComponent implements OnInit {

  @Input() data: NettingSettingsModel;
  @Input() displayDialog: boolean;
  @Input() messages: Message[];
  @Output() cancel = new EventEmitter();
  @Output() activateItem = new EventEmitter();
  @Output() blockItem = new EventEmitter();
  action: string;
  isActivated: boolean;

  constructor(private store: Store<EsmSettingsState>,
              public organisationService: OrganisationService) { }

  ngOnInit(): void {
    this.isActivated = this.data.agreement;
    this.action = this.isActivated ? 'Block' : 'Activate';
  }

  onCancel(): void {
    this.cancel.emit();
  }

  onConfirm(): void {
    if (this.action === 'Block') {
      // todo select id from data object
      this.blockItem.emit(this.data);
    } else {
      this.activateItem.emit(this.data);
    }
  }

  onVisibleChange(visible: boolean): void {
    if (!visible) {
      this.onCancel();
    }
  }
}
