<h1>eSM Tolerance Matching Settings by {{(organisationService.getChangeOrganisationObservable() | async)?.displayName}}
  ({{(organisationService.getChangeOrganisationObservable() | async)?.eicCode}})</h1>


<div class="panel-box">

<cms-info ngPreserveWhitespaces>


  <u>__Tolerance Match Settings__</u>

  The below settings control if your eSM organisation accepts tolerance matching of invoices and allows your master operator user to define accepted matching tolerances by providing absolute amounts and percentage amounts per currency. Tolerance settings for physical and financial invoices are distinguished by the EFET eSM standard as well and must be provided independently.

  __Application of tolerances in eSM invoice matching:__ Fields applying to a tolerance match are

  * ESMDocument/InvoiceData/GrossAmount/TotalAmount
  * ESMDocument/InvoiceData/NetAmount/TotalAmount
  * ESMDocument/InvoiceData/VATAmount

  If the invoice shadow document issuer party supports tolerance matching and a mismatch in one (or more) of these fields occurs, the tolerance match settings of the invoice shadow document issuer will be used to determine if the fields can be considered tolerance matched. The tolerance defined by the shadow document issuer is applicable because the shadow document issuer is the payer and has to accept the terms and conditions of the official invoice.

  The fields listed above are considered tolerance matched, if

  1) absolute value (field value(shadow invoice) - field value(official invoice)) <= absolute tolerance amount of shadow document issuer recorded for the respective currency and invoice type (physical/financial)
  2) absolute value (field value(shadow invoice) - field value(official invoice))/(field value(official invoice) <= percentage tolerance amount of shadow document issuer for the respective currency and invoice type (physical/financial)

  If no tolerances are recorded by the shadow document issuer for the respective currency or invoice type (physical/financial) even though he opted to accept tolerances, then a tolerance match cannot be applied to the invoice.


  <u>_Instructions_</u>

  * __Support Tolerance Matching:__ Check this checkbox, if your organisation supports tolerance matching of invoices. If this  checkbox is unchecked, tolerances matches of invoice are blocked by your organisation.
  * __Table with recorded Tolerance Settings:__ The table lists all the tolerance settings recorded in eSM for your organisation. The tolerances displayed in the table are inline editable, a master operator may change tolerance settings by clicking into a cell to change the value of the field, the change is validated and saved by pressing 'Enter'
  * __Allowed values:__
    * __Absolute Amount:__ A positive value with up to 16 digits and 2 decimal places.
    * __Percentage Amount:__ A positive value not bigger than 100 and with up to 8 decimal places.
</cms-info>

  <p-messages [value]="messages$ | async" [closable]="true"></p-messages>

  <p-fieldset id="tolerance_check" legend="Tolerance Matching Support" class="mb-4 block">
    <p-checkbox inputId="esm_tolerance_check_box" [disabled]="!(editable$|async)" [ngClass]="{'l-disabled':!(editable$|async)}" #check [(ngModel)]="checked" binary="true" (onChange)="toggle($event)" [label]="check.checked? 'Tolerance Matching Is Enabled':'Tolerance Matching Is Disabled'" class="mb-2 ms-2"></p-checkbox>
  </p-fieldset>


<p-fieldset id="tolerance">

  <p-header>Accepted tolerance amounts and percentages per currency and invoice type (physical/financial)</p-header>


  <p-table id="tolerance_table"
           [paginator]="true"
           [rows]="(paging$ | async)?.entriesPerPage"
           [totalRecords]="(data$ | async)?.count"
           [columns]="columns"
           [value]="(data$ | async)"
           [sortMode]="'single'"
           [sortField]="(sorting$|async).columnName"
           [sortOrder]="(sorting$|async).sortOrder==='ASCENDING' ? 1 : -1"
           [loading]="loading$ | async"
           [autoLayout]="true"
           [lazy]="true"
           [lazyLoadOnInit]="false"
           (onLazyLoad)="onLazyLoad($event)"
           (onEditInit)="onRowEditInit($event)"
           (onEditCancel)="onRowEditCancel()"
           (onEditComplete)="onEditComplete($event)">

    <ng-template pTemplate="header">
      <tr>
        <ng-container *ngFor="let col of columns">
          <th [pSortableColumn]="col.name" *ngIf="col.visible">{{col.title}}
            <p-sortIcon [field]="col.name" *ngIf="col.sortable"></p-sortIcon>
          </th>
        </ng-container>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-index="rowIndex">
      <tr >
        <td>{{rowData.currency | uppercase}}</td>
        <td>{{rowData.invoiceType | uppercase}}</td>

        <td [pEditableColumn]="rowData"
            [pEditableColumnRowIndex]="index"
            [pEditableColumnField]="'absTolerance'"
            [pEditableColumnDisabled]="!(editable$|async)" class="editable-text">
          <p-cellEditor>
            <ng-template pTemplate="input"><input cmsSimpleDigitOnly pInputText pattern="[0-9]{1,16}(\.[0-9]{0,2})?" [(ngModel)]="rowData.absTolerance"></ng-template>
            <ng-template pTemplate="output">{{ rowData.absTolerance | absolute }}</ng-template>
          </p-cellEditor>
        </td>


        <td [pEditableColumn]="rowData"
            [pEditableColumnRowIndex]="index"
            [pEditableColumnField]="'prectTolerance'"
            [pEditableColumnDisabled]="!(editable$|async)" class="editable-text">
        <p-cellEditor>
            <ng-template pTemplate="input"><input cmsSimpleDigitOnly pInputText pattern="(100|[0-9]{1,2}(\.[0-9]{0,8})?)" [(ngModel)]="rowData.prectTolerance"></ng-template>
            <ng-template pTemplate="output">{{ rowData.prectTolerance | percentage }}</ng-template>
          </p-cellEditor>
        </td>

      </tr>
    </ng-template>
  </p-table>
<!--
  <p-progressBar  *ngIf="fLoading$ | async" mode="indeterminate"></p-progressBar>
-->

</p-fieldset>
</div>


