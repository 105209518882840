<section class="panel-box cms-esm-netting-finder-filter-container" style="height: calc(100% - 15px);">
  <form class="align-items-center form" [formGroup]="form">
    <div class="row">
      <div class="col-12 col-md-4">
        <div>
          <label class="text-bold  ">Counterparty</label>
          <p-multiSelect selectedItemsLabel="All ({0})"
                         id="esm-netting-finder-multiselect-cp"
                         [style]="{'width':'100%'}"
                         inputId="esm-nt-finder-multiselect-cp"
                         [maxSelectedLabels]="counterPartyOptions.length - 1"
                         [filter]="true"
                         [options]="counterPartyOptions"
                         tabindex="5"
                         placeholder="None"
                         formControlName="counterParties">
          </p-multiSelect>
        </div>
        <div>
          <label class="text-bold  ">Delivery Point</label>
          <p-multiSelect selectedItemsLabel="All ({0})"
                         inputId="esm-nt-finder-multiselect-dp"
                         [options]="deliveryPointOptions"
                         [maxSelectedLabels]="deliveryPointOptions.length - 1"
                         [filter]="false"
                         tabindex="6"
                         [style]="{'width':'100%'}"
                         placeholder="None"
                         formControlName="deliveryPoints">
          </p-multiSelect>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div>
          <label class="text-bold">Commodity</label>
          <p-multiSelect selectedItemsLabel="All ({0})"
                         [maxSelectedLabels]="commodityOptions.length - 1"
                         [filter]="false"
                         [options]="commodityOptions"
                         [style]="{'width':'100%'}"
                         tabindex="8"
                         placeholder="None"
                         formControlName="commodities">
          </p-multiSelect>
        </div>
        <div>
          <label class="text-bold">Netting Statement Type</label>
          <p-multiSelect selectedItemsLabel="All ({0})"
                         [maxSelectedLabels]="nettingStatementTypeOptions.length - 1"
                         [filter]="false"
                         [options]="nettingStatementTypeOptions"
                         [style]="{'width':'100%'}"
                         tabindex="9"
                         placeholder="None"
                         formControlName="nettingStatementTypes">
          </p-multiSelect>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="row position-relative">
          <label class="text-bold ps-0">Submission Date Range</label>
          <div class="col-12 col-xs-12 col-md-5 ps-0">
            <p-calendar placeholder="From Date"
                        id="submissionDateStart"
                        formControlName="submissionDateStart"
                        showIcon="true"
                        monthNavigator="true"
                        yearNavigator="true"
                        dateFormat="dd/mm/yy"
                        [maxDate]="defaultMaxDate"
                        cmsDateRestriction>
            </p-calendar>
          </div>
          <div class="col-12 col-xs-12 col-md-5 ps-0 date">
            <p-calendar placeholder="To Date"
                        id="submissionDateEnd"
                        formControlName="submissionDateEnd"
                        showIcon="true"
                        monthNavigator="true"
                        yearNavigator="true"
                        dateFormat="dd/mm/yy"
                        [maxDate]="defaultMaxDate"
                        cmsDateRestriction>
            </p-calendar>
          </div>
          <div class="col-2 px-0">
            <cms-additional-date-range
              [form]="form"
              [startField]="'submissionDateStart'"
              [endField]="'submissionDateEnd'">
            </cms-additional-date-range>
          </div>
        </div>
        <div class="row calendar position-relative">
          <div class="col-12 col-xs-12 col-md-5 ps-0">
            <label class="text-bold  ">Invoice Period Start</label>
            <p-calendar id="invoiceStartDate"
                        formControlName="invoicePeriodStartDate"
                        showIcon="true"
                        monthNavigator="true"
                        yearNavigator="true"
                        dateFormat="dd/mm/yy"
                        placeholder="From Date"
                        cmsDateRestriction>
            </p-calendar>
          </div>
          <div class="col-12 col-xs-12 col-md-5 ps-0">
            <label class="text-bold  ">Invoice Period End</label>
            <p-calendar id="invoicePeriodEndDate"
                        formControlName="invoicePeriodEndDate"
                        showIcon="true"
                        monthNavigator="true"
                        placeholder="To Date"
                        yearNavigator="true"
                        dateFormat="dd/mm/yy"
                        cmsDateRestriction>
            </p-calendar>
          </div>
          <div class="col-2 col-xs-2 col-md-2 px-0" style="position: initial">
            <label class="text-bold">&nbsp;</label>
            <cms-additional-date-range
              [form]="form"
              [startField]="'invoicePeriodStartDate'"
              [endField]="'invoicePeriodEndDate'">
            </cms-additional-date-range>
          </div>
        </div>
        <div class="row calendar">
          <label class="text-bold ps-0">Payment Date Range</label>
          <div class="col-12 col-xs-12 col-md-5 ps-0">
            <p-calendar id="paymentDateStart"
                        formControlName="paymentDateStart"
                        placeholder="From Date"
                        showIcon="true"
                        monthNavigator="true"
                        yearNavigator="true"
                        dateFormat="dd/mm/yy"
                        cmsDateRestriction>
            </p-calendar>
          </div>
          <div class="col-12 col-xs-12 col-md-5 ps-0">
            <p-calendar id="paymentDateEnd"
                        formControlName="paymentDateEnd"
                        placeholder="To Date"
                        showIcon="true"
                        monthNavigator="true"
                        yearNavigator="true"
                        dateFormat="dd/mm/yy"
                        cmsDateRestriction>
            </p-calendar>
          </div>
        </div>
      </div>
    </div>
    <div class="row align-items-baseline margin-top-12 btn-container">
      <div class="col-12 col-xs-6 col-md-6">
        <label class="radio-label text-bold margin-right-9">Sender</label>
        <label class="radio-label margin-right-9">
          <input type="radio"
                 formControlName="senderUs"
                 id="US"
                 [value]="true"
                 class="margin-right-3"
                 name="senderUs" />Us
        </label>
        <label class="radio-label margin-right-9">
          <input type="radio"
                 formControlName="senderUs"
                 id="THEM"
                 [value]="false"
                 class="margin-right-3"
                 name="senderUs" />Them
        </label>
      </div>
      <div class="col-12 col-xs-6 col-md-6  text-end">


        <cms-esm-netting-finder-myfilter [myForm]="form">
        </cms-esm-netting-finder-myfilter>

        <button class="btn margin-top-3 margin-bottom-3 margin-left-12" type="submit" (click)="setFilter()" [disabled]="!(form && form.valid) || loading">
          Search
        </button>
        <button class="btn margin-top-3 margin-bottom-3 margin-left-12  margin-right-12" type="button" (click)="reset()">
          Reset
        </button>
      </div>
    </div>
  </form>
</section>
