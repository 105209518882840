import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import { EsmAddressDetails, EsmCompanyRegistry, EsmContactDetails, EsmVatDetails } from '../master-data.model';

export interface EsmExternalMasterDataPresetValues {
  uploadAllowed: true;
  maxUploadSize: number;
}

export interface EsmExternalMasterDataModel {
  eicCode: string;
  vatId: string;
  legalName: string;
  iban: string;
  existingMasterData: boolean;
  masterDataId: number;
  version: number;
}

const defaultStyles = {
  'text-align': 'left',
  'text-overflow': 'ellipsis',
  'overflow': 'hidden',
  'white-space': 'nowrap'
};

export const esmExternalMasterDataTableColumns = [
  {name: 'eicCode', type: 'text', title: 'EIC Code', style: {...defaultStyles, 'max-width': '70px'}, sortable: true, visible: true},
  {name: 'vatId', type: 'text', title: 'VAT ID', style: {...defaultStyles, 'max-width': '50px'}, sortable: true, visible:true },
  {name: 'legalName', type: 'text', title: 'Legal Name', style: {...defaultStyles, 'max-width': '200px'}, sortable: true, visible:true },
  {name: 'existingMasterData', type: 'warning', title: 'Provided own master data', style: { ...defaultStyles, 'text-align': 'center', 'max-width': '125px'}, sortable: true, visible:true },
  {name: '', type: 'buttons', title: '', style: {...defaultStyles, 'text-align': 'center', 'max-width':'40px'}, sortable: false, visible:true },
  {name: 'masterDataId', type: null, title: null, sortable: false, visible: false },
  {name: 'version', type: null, title: null, sortable: false, visible: false }
];

export interface TableValues {
  data: { values, currentPage, count };
  paging: PagingFilter;
  sorting: SortingFilter;
}
