import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import {
  RemitDashboardBucketReportingRoleType,
  RemitDashboardBucketViewByType,
  RemitDocumentType
} from '../dashboard/filter/remit-dashboard-filter-model';
import {
  RemitTransactionsFilterCommodity,
  RemitTransactionsFilterRecordType,
  RemitTransactionsFilterState
} from '../transactions/filter/remit-transactions-filter.model';
import { DateRangeFilter } from '@common/shared/shared.model';

export interface RemitTransactionsFilters {
  sorting?: SortingFilter;
  paging?: PagingFilter;
}

export type RemitTransactionSearchMode =
  'SEARCH_BUCKET_DETAIL'
  | 'SEARCH_FILTER'
  | 'SEARCH_UTI'
  | 'SEARCH_TRANSACTION_ID'
  | 'SEARCH_CONTRACT_ID'
  | 'SEARCH_FILENAME'
  | 'SEARCH_REFERENCE_ID'
  | 'SEARCH_UNIQUE_ID';

export const defaultPaging: PagingFilter = {
  entriesPerPage: 25,
  page: 0
}

export const defaultSorting: SortingFilter = {
  columnName: 'creationTimestamp',
  sortOrder: 'DESCENDING',
}

export interface RemitTransactionsSearchFilter {
  ids?: string[];
  filename?: string;
  state?: string;
  viewBy?: RemitDashboardBucketViewByType;
  remitReportingRole?: RemitDashboardBucketReportingRoleType;
  remitDocumentType?: RemitDocumentType;
  dateRangeType?: string;
  omps?: string[];
  mps?: string[];
  states?: RemitTransactionsFilterState[];
  actionTypes?: string[];
  commodity?: RemitTransactionsFilterCommodity;
  recordType?: RemitTransactionsFilterRecordType;
  quantity?: number;
  price?: number;
  transactionTime?: DateRangeFilter;
  creationDate?: DateRangeFilter;
  receivedByErr?: DateRangeFilter;
  contractDate?: DateRangeFilter;
  lastUpdate?: DateRangeFilter;
  gasDayUtc?: DateRangeFilter;
  searchMode?: RemitTransactionSearchMode;
  senderNames?: string[];
}

export class RemitTransactionsSearchQuery implements RemitTransactionsSearchFilter {
  ids?: string[];
  filename?: string;
  state?: string;
  viewBy?: RemitDashboardBucketViewByType;
  remitReportingRole?: RemitDashboardBucketReportingRoleType;
  remitDocumentType?: RemitDocumentType;
  dateRangeType?: string;
  omps?: string[];
  mp?: string[];
  states?: RemitTransactionsFilterState[];
  actionTypes?: string[];
  commodity?: RemitTransactionsFilterCommodity;
  recordType?: RemitTransactionsFilterRecordType;
  quantity?: number;
  price?: number;
  transactionTime?: DateRangeFilter;
  creationDate?: DateRangeFilter;
  receivedByErr?: DateRangeFilter;
  contractDate?: DateRangeFilter;
  lastUpdate?: DateRangeFilter;
  gasDayUtc?: DateRangeFilter;
  searchMode?: RemitTransactionSearchMode;
  senderNames?: string[];

  sorting: SortingFilter;
  paging: PagingFilter;

  public static isNotEmptySearchQuery(query: RemitTransactionsSearchQuery): boolean {
    return query != null && JSON.stringify(query) !== JSON.stringify(RemitTransactionsSearchQuery.createEmptySearchQuery());
  }

  public static createEmptySearchQuery(): RemitTransactionsSearchQuery {
    return {
      paging: defaultPaging,
      sorting: defaultSorting
    };
  }
}
