<h1>ElCom Whitelist</h1>
<div class="panel-box">
  <cms-info ngPreserveWhitespaces>
    __ElCom Whitelist__

    Activate and deactivate reporting to ElCom for Swiss counterparties.

    **Activating ElCom Reporting**

    1. Ensure the authorisation procedure has been completed and the ACER code has been verified
    2. Enter the market participant's ACER code and press **Add**
    3. Double-check in the list that the ACER code has been accepted and maps to the expected LEI registry entry and CMS organisation
    ElCom reporting will be activated for that MP immediately.

    **Deactivating ElCom Reporting**

    1. Ensure that immediate termination of ElCom reporting has been authorised for the MP
    2. Find the MP in the list and press the **Delete** icon
    3. Verify the ACER code to be removed in the confirm dialog and press **Yes - Delete**
    ElCom reporting will stop immediately for that MP.

    **Finding Activated market Participants**

    The filter function works for all party codes (ACER, LEI, EIC) and name fields (group, organisation, registered name).

    **About the List**

    | **Field** | **Meaning** |
    | :------------------ |:-- |
    | ACER Code | The MP's ACER code |
    | Organisation Group | Details of CMS organisation to which ACER code belongs. |
    | Organisation | In rare cases the code does not belong to a CMS organisation. |
    | EIC Code | In this case these fields are empty and only OMP data can be reported. |
    | LEI Code | LEI which is associated with this ACER code |
    | LEI Registered Name     | LEI registry information found for the LEI |
    | Country |  |
    | External | Number of organisation groups who have set up this MP as external party for ROBO reporting |

    **About ElCom Reporting**

    StromVV is the Swiss equivalent of REMIT. It pertains to electricity trades executed by Swiss market participants and is enforced by the Swiss regulation authority ElCom.
    ElCom receives trades immediately after they have been reported to ACER if:

    - the MP's ACER code is on this whitelist
    - the commodity is "EL"
    - the format is REMIT Table 1, 2 or 3
  </cms-info>
  <p-messages [value]="messages$ | async" [closable]="true"></p-messages>
  <cms-elcom-whitelist-add-panel (add)="onAdd($event)"
                                 (clear)="onClear()"
                                 [value]="getAcerCodeValue$ | async">
  </cms-elcom-whitelist-add-panel>
  <cms-datatable *ngIf="(data$ | async) as data"
                 [tableColumns]="dataTableColumns"
                 [currentPage]="(paging$ | async).page"
                 [sortField]="(sorting$ | async).columnName"
                 [sortOrder]="(sorting$ | async).sortOrder"
                 [loading]="loading$ | async"
                 [data]="data.values"
                 [totalRecords]="data.count"
                 [autoLayout]="true"
                 [scrollable]="false"
                 [lazyLoadOnInit]="false"
                 (lazyLoad)="onLazyLoad($event)"
                 selectionMode="single">
    <ng-template pTemplate="caption">
      <div class="row margin-0">
        <cms-input-text-filter (filter)="onTextFieldFilter($event)"
                               [text]="(filters$ | async)?.filter"
                               [placeholder]="'Filter'"
                               class="col-6 margin-0 padding-0">
        </cms-input-text-filter>
      </div>
    </ng-template>
    <ng-template pTemplate="body" let-row="row" let-column="column">
      <td #tdContainer *ngIf="column.type === 'textarea'" [ngStyle]="column.style" class="position-relative non-editable-textarea-cell">
       <cms-non-editable-textarea [value]="row[column.name]"
                                  [container]="tdContainer">
        </cms-non-editable-textarea>
      </td>
      <td *ngIf="column.type === 'button'" [ngStyle]="column.style">
        <button id="elcom-whitelist-delete-btn" class="btn" pButton type="button" icon="fa fa-trash-o" (click)="onDelete(row.acerCode)"></button>
      </td>
    </ng-template>
  </cms-datatable>
</div>
<cms-elcom-whitelist-dialog [display]="display"
                            [acerCode]="acerCode"
                            (cancel)="onCancel()"
                            (confirm)="onConfirm()">
</cms-elcom-whitelist-dialog>
