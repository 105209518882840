<p-dialog
  *ngIf="data && display"
  [header]="header"
  [visible]="display"
  [modal]="true"
  (visibleChange)="onCancel()"
  [style]="{width: '700px'}">
  <section class="margin-15 errors-container">
    <h4 class="!font-bold">The following errors occured during the run:</h4>
    <p *ngFor="let error of data.values">{{ error }}</p>
  </section>
  <p-footer>
    <button class="btn margin-right-3" type="button" (click)="onCancel()">Close</button>
  </p-footer>
</p-dialog>
