import { datatableReducer, DatatableState } from '../datatable/datatable.reducer';
import { ElcomWhitelistFilters, ElcomWhitelistValues } from '../../administration-tab-panel/elcom-whitelist/elcom-whitelist.model';
import { organisationIdReducer } from '../organisation-id/organisation-id.reducer';
import * as fromActions from './elcom-whitelist.actions';

export type  ElcomWhitelistDatatable = DatatableState<ElcomWhitelistValues, ElcomWhitelistFilters>;

export interface ElcomWhitelistState {
  organisationId?: number;
  datatable: ElcomWhitelistDatatable;
  acerCodeValue: string;
}

export const datatableInitialState: ElcomWhitelistDatatable = {
  data: {
    values: [],
    count: 0
  },
  messages: [],
  filters: {
    filter: null
  },
  loading: false,
  paging: {
    page: 0,
    entriesPerPage: 25
  },
  sorting: {
    columnName: 'orgGroupName',
    sortOrder: 'DESCENDING'
  }
};

export const initialElcomWhitelistState: ElcomWhitelistState = {
  organisationId: null,
  datatable: datatableInitialState,
  acerCodeValue: null
};

export const elcomWhitelistDatatableReducer = datatableReducer('[ELCOM WHITELIST]', datatableInitialState);
export const elcomWhitelistOrganisationIdReducer = organisationIdReducer('[ELCOM WHITELIST]');

export function elcomWhitelistReducer(state: ElcomWhitelistState = {...initialElcomWhitelistState},
                                      action: fromActions.ElcomWhitelistActions): ElcomWhitelistState {
  switch (action.type) {
    case fromActions.clearElcomWhitelistAction.type:
      return {
        ...state,
        datatable: {
          ...state.datatable,
          messages: []
        }
      };
    case fromActions.addElcomWhitelistAction.type:
      return {
        ...state,
        acerCodeValue: action.payload
      };
    case fromActions.addElcomWhitelistSuccessAction.type:
      return {
        ...state,
        acerCodeValue: null
      };
    case fromActions.setToDefaultAction.type:
      return {
        ...initialElcomWhitelistState
      };
    default:
      return {
        ...state,
        datatable: elcomWhitelistDatatableReducer(state.datatable, action as any),
        organisationId: elcomWhitelistOrganisationIdReducer(state.organisationId, action as any)
      };
  }
}
