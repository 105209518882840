<p-dialog *ngIf="display"
          header="{{'Move Organisation ' + data.displayName}}"
          [visible]="display"
          [modal]="true"
          (visibleChange)="onVisibleChange($event)"
          [style]="{width: '650px', minWidth: '500px'}">
  <p-messages [value]="messages" [closable]="true"></p-messages>
  <div class="margin-15">
    <form [formGroup]="form" class="row" autocomplete="off">
      <div class="col-12 margin-bottom-12 flex">
        <label for="organisationGroupDisplayName" class="col-4 float-left">Current organisation group:</label>
        <div class="col-8">
          <input autocomplete="off" tabindex="1" id="organisationGroupDisplayName" type="text" formControlName="organisationGroupDisplayName" class="form-control col-8 padding-6" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12">
        <label for="organisationGroupDisplayName" class="col-4 float-left">Target organisation group:<span class="required">*</span></label>
        <p-dropdown class="col-8 padding-0"
                    appendTo="body"
                    [options]="orgGroupsOptions"
                    id="newOrganisationGroup"
                    [style]="{width: '385px'}"
                    formControlName="newOrganisationGroup">
        </p-dropdown>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label for="reason" class="col-4 float-left">Reason:<span class="required">*</span></label>
        <div class="col-8">
          <textarea autocomplete="off" tabindex="5" col="3" id="reason" formControlName="reason" class="form-control col-8 padding-6" style="height: 4em;" [ngClass]="{'error': errors['reason']}"></textarea>
        </div>
      </div>
    </form>
  </div>
  <p-footer>
    <button class="btn margin-right-3" type="button" (click)="onCancel()">Cancel</button>
    <button class="btn" type="button" (click)="onConfirm()">Move</button>
  </p-footer>
</p-dialog>
