<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>

<p-tabView [activeIndex]="activeIndex" (onChange)="onChangeTab($event)">
  <p-tabPanel header="eCM 4.0 Process" *ngIf="processVisible">
    <div class="tab-view-body">
      <div class="tab-view-body">
        <cms-process *ngIf="activeIndex === 0"></cms-process>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Products" *ngIf="productsVisible">
    <div class="tab-view-body">
      <div class="tab-view-body">
        <cms-ecm-products *ngIf="activeIndex === 1"></cms-ecm-products>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Broker Restrictions" *ngIf="brokerRestrictionsVisible">
    <div class="tab-view-body">
      <div class="tab-view-body">
        <cms-broker-restrictions *ngIf="activeIndex === 2"></cms-broker-restrictions>
      </div>
    </div>
  </p-tabPanel>
</p-tabView>

</ng-template>
