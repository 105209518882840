import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Message } from 'primeng/api';
import { MyFilter } from '@common/my-filters/my-filters.model';

@Component({
  selector: 'cms-save-my-current-dialog',
  templateUrl: './save-my-current-dialog.component.html',
  styleUrls: ['./save-my-current-dialog.component.scss']
})
export class SaveMyCurrentDialogComponent implements OnInit, OnChanges {
  @Input() display = false;
  @Input() myFilters: MyFilter[] = [];
  @Input() messages: Message[];
  @Output() displayChange = new EventEmitter<boolean>();
  @Output() exit = new EventEmitter<Message[]>();
  @Output() saveAs = new EventEmitter<MyFilter>();
  @Output() replace = new EventEmitter<MyFilter>();
  errors: {[key: string]: ValidationErrors} = {};
  form: UntypedFormGroup;
  defaultOption = {label: 'Choose saved filter...', value: null};
  filtersOptions: {label: string, value: any }[] = [this.defaultOption];

  constructor(private formBuilder: UntypedFormBuilder) { }

  onSaveAs(): void {
    const {
      filterName,
      defaultFilter
    } = this.form.value;
    this.saveAs.emit({
      filterName,
      defaultFilter
    });
  }

  onReplace(): void {
    this.replace.emit(this.form.value.filterNames);
  }

  onCancel(): void {
    this.hide();
  }

  onVisibleChange(visible: boolean): void {
    if (!visible) {
      this.onCancel();
    }
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      filterName: null,
      defaultFilter: false,
      filterNames: [null, Validators.required]
    });
    this.initMyFiltersOptions();
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (this.form) {
      if (changes.display && !this.display) {
        this.hide();
      }
    }

    if (changes.myFilters) {
      this.initMyFiltersOptions();
    }

    if (changes.messages) {
      this.errors = {};
      if (this.messages) {
        this.messages.forEach((obj: Message) => {
          const { key: controlName } = obj;
          this.errors[controlName] = { 'server': true };
        });
      }
    }
  }

  private hide(): void {
    this.messages = [];
    this.display = false;
    this.displayChange.emit(this.display);
    this.form.reset();
    this.form.updateValueAndValidity();
    this.exit.emit();
  }

  private initMyFiltersOptions(): void {
    this.filtersOptions = [
      this.defaultOption,
      ...(this.myFilters ? this.myFilters.map(item => ({label: item.filterName, value: item})) : [])
    ];
  }
}
