import { createAction, union } from '@ngrx/store';
import {
  InvoiceFinderPropertiesFilters,
  EsmInvoiceFilterValues,
  InvoiceFinderIdFiltersResponse,
  InvoiceFinderFilters,
  EsmInvoiceFinderFilterData
} from '../../../invoice/finder/invoice-finder.model';

export const getFiltersValuesAction = createAction('[INVOICE FINDER] Get Filters Values');
export const getFiltersValuesSuccess = createAction('[INVOICE FINDER] Get Filters Values Success', (payload: EsmInvoiceFilterValues) => ({ payload }));
export const filterAction = createAction('[INVOICE FINDER] Filter', (payload: InvoiceFinderFilters) => ({ payload }));
export const filterSuccessAction = createAction('[INVOICE FINDER] Filter Success',(payload: EsmInvoiceFinderFilterData) => ({payload}));
export const clearPropertiesFiltersAction = createAction('[INVOICE FINDER] Clean By Properties Filters');
export const updateFiltersAction = createAction('[INVOICE FINDER] Update Filters', (payload: InvoiceFinderPropertiesFilters) => ({ payload }));
export const clearIdPropertiesFiltersAction = createAction('[INVOICE FINDER] Clean By ID Properties Filters');

const actions = union({
  getFiltersValuesSuccess,
  filterAction,
  getFiltersValuesAction,
  clearPropertiesFiltersAction,
  updateFiltersAction,
  clearIdPropertiesFiltersAction,
  filterSuccessAction,
});

export type FiltersActions = typeof actions;
