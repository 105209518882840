import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Message } from 'primeng/api';
import { select, Store } from '@ngrx/store';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { OrganisationService } from '@common/organisation.service';
import { ErrorMessageService } from '@common/errors/error-message.service';
import { initialMailBillingRunsState, State } from '../state/table.reducers';
import { getLoading, getMessages, getPaging, getSorting, getTableData } from '../state/table.selectors';
import { loadTable, setPagingAndSorting } from '../state/table.actions';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'cms-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnDestroy ,OnInit{

  columns = mailBillingTableColumns;
  data = initialMailBillingRunsState;
  acerBillingRunId:string;
  quarter:string;

  subscriptions$: Subscription = new Subscription();
  messages$: Observable<Message[]> = this.store.select(getMessages);
  loading$: Observable<boolean> = this.store.pipe(select(getLoading));
  paging$: Observable<PagingFilter> = this.store.select(getPaging);
  sorting$: Observable<SortingFilter> = this.store.select(getSorting);
  data$: Observable<DatatableData<TableEntry[]>> = this.store.select(getTableData);

  refreshTable = () => this.store.dispatch(loadTable({ acerBillingRunId: this.acerBillingRunId, quarter: this.quarter }));


  constructor(private store: Store<State>,
              public organisationService: OrganisationService,
              public readonly errorMessageService: ErrorMessageService,
              private route: ActivatedRoute,
              private titleService:Title)  {


  }




  onLazyLoad({ page, perPage, sortField, sortOrder }): void {

    this.store.dispatch(setPagingAndSorting({
      paging: {
        page,
        entriesPerPage: perPage
      },
      sorting: {
        sortOrder,
        columnName: sortField
      }
    }));
    this.refreshTable();
  }


  ngOnDestroy(): void {

    this.subscriptions$.unsubscribe();
  }

  ngOnInit(): void {

    this.subscriptions$.add(this.route.queryParams.subscribe(({ acerBillingRunId, quarter }) => {
      this.acerBillingRunId = acerBillingRunId;
      this.quarter = quarter;
      this.refreshTable();
      this.titleService.setTitle('Invoice Mails for '+ quarter);
    }));
  }
}




export interface TableEntry {
  orgGroupName: string;
  filename: string;
  sendStatus: string;
  recipients: string;
  error: string;
}

const defaultStyles = {
  'text-align': 'left',
  'text-overflow': 'ellipsis',
  'overflow': 'hidden',
  'white-space': 'nowrap',
  'width':'9%'
};


export const mailBillingTableColumns = [
  { name: 'orgGroupName', type: 'text', title: 'Organisation Group', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'filename', type: 'text', title: 'Filename', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'sendStatus', type: 'text', title: 'Send Status', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'recipients', type: 'text', title: 'Recipients', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'error', type: 'text', title: 'Error', style: { ...defaultStyles,}, sortable: false, visible: true },
];
