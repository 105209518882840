import { Column } from '@common/shared/datatable.model';
import { ErrorResponse } from '@common/error-response.model';
import { PagingFilter } from '@common/shared/results.model';

export const defaultStyles = {
  'text-align': 'left',
  'text-overflow': 'ellipsis',
  'overflow': 'hidden',
  'white-space': 'nowrap'
};

export const dataTableColumns: Column[] = [
  { name: 'billingMonth', type: 'text', title: 'Billing Month', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'runDate', type: 'date', title: 'Executed', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'runBy', type: 'text', title: 'Executed By', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'executionTimeInSeconds', type: 'text', title: 'Exec. time (s)', style: { ...defaultStyles, 'max-width': '80px', 'text-align': 'right' }, sortable: false, visible: true },
  { name: 'invoiced', type: 'text', title: 'Invoiced', style: { ...defaultStyles, 'max-width': '80px', 'text-align': 'right' }, sortable: false, visible: true },
  { name: 'withheld', type: 'text', title: 'Withheld', style: { ...defaultStyles, 'max-width': '80px', 'text-align': 'right' }, sortable: false, visible: true },
  { name: 'errors', type: 'error', title: 'Errors', style: { ...defaultStyles, 'max-width': '80px', 'text-align': 'right' }, sortable: false, visible: true },
  { name: 'draft', type: 'yes/no', title: 'Draft', style: { ...defaultStyles, 'max-width': '80px' }, sortable: false, visible: true },
  { name: '', type: 'downloadButton', title: 'Download', style: { ...defaultStyles, 'text-align': 'center', 'max-width': '80px' }, sortable: false, visible: true },
  { name: '', type: 'mailButton', title: 'Send', style: { ...defaultStyles, 'text-align': 'center', 'max-width': '80px' }, sortable: false, visible: true },
  { name: '', type: 'reportButton', title: 'Report', style: { ...defaultStyles, 'text-align': 'center', 'max-width': '80px' }, sortable: false, visible: true },
  { name: '', type: 'deleteButton', title: 'Delete', style: { ...defaultStyles, 'text-align': 'center', 'max-width': '80px' }, sortable: false, visible: true }
];

export const mailingDetailsDTColumns: Column[] = [
  { name: 'orgGroupName', type: 'text', title: 'Organisation Group', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'filename', type: 'text', title: 'Filename', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'sendStatus', type: 'text', title: 'Send Status', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'recipients', type: 'text', title: 'Recipients', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'error', type: 'text', title: 'Error', style: { ...defaultStyles }, sortable: false, visible: true }
];

export interface BillingRuns {
  billingMonth: string;
  billingRunId: number;
  deletable: boolean;
  draft: boolean;
  errors: number;
  executionTimeInSeconds: number;
  invoiced: number;
  mailingButtonEnabled: boolean;
  mailingButtonVisible: boolean;
  reportButtonVisible: boolean;
  runBy: string;
  runDate: string;
  withheld: number;
}

export const initialBillingRunsModel = {
  billingMonth: '',
  billingRunId: 0,
  deletable: false,
  draft: false,
  errors: 0,
  executionTimeInSeconds: 0,
  invoiced: 8,
  mailingButtonEnabled: false,
  mailingButtonVisible: true,
  reportButtonVisible: true,
  runBy: '',
  runDate: '',
  withheld: 0
};

export const InitialBillingRunParams = {
  brokerMatchCap: 0,
  brokerMatchFee: 0,
  ecmLiteFreeCap: 0,
  traderMatchCap: 0,
  traderMatchFee: 0,
  errAnnualFee: 0,
  errTxCap: 0,
  errTxFee: 0,
  ecmFeeDiscount: 0,
  invoiceTrigger: 0,
};

export interface BillingRunStatus {
  billingRunAllowed: boolean;
  progressOfCurrentRun: number;
  templatesExists: boolean;
}

export interface StartBillingRun {
  draft: boolean;
  invoiceStartNumber: number | string;
}

export interface BillingRunParams {
  brokerMatchCap: number;
  brokerMatchFee: number;
  ecmLiteFreeCap: number;
  traderMatchCap: number;
  traderMatchFee: number;
  errAnnualFee: number;
  errTxCap: number;
  errTxFee: number;
  ecmFeeDiscount: number;
  invoiceTrigger: number;
}

export interface BillinRunMailDetails {
  error: string;
  filename: string;
  orgGroupName: string;
  recipients: string;
  sendStatus: string;
}

export interface GetBillingRunsResponse extends ErrorResponse {
  values: BillingRuns[];
  count: number;
  currentPage: number;
}

export interface GetBillingRunsRequest {
  paging: PagingFilter;
}

export interface GetBillingRunErrorsResponse extends ErrorResponse {
  values: string[];
}

export interface ErrorModalData {
  values: string[];
  item: BillingRuns;
}

export interface MailingDetailsResponse extends ErrorResponse {
  values: MailingDetailsValues[];
}

export interface MailingDetailsValues {
  error: string;
  filename: string;
  orgGroupName: string;
  recipients: string;
  sendStatus: string;
}
export interface GetBillingRunDefaultResponse<T> extends ErrorResponse {
  value: T;
}
