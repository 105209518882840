<div class="other-counterparty">
  <div class="form-title">Trade | Other Counterparty</div>
  <div class="form-body" [formGroup]="formGroup">
    <div class="row gutter-0 !me-[40px]" *ngIf="!modifyTrade">
      <div class="col-5 label">Report both sides</div>
      <div class="col-7 ps-2 flex align-middle">
        <input type="checkbox" class="margin-3" id="isReportBothSides-table2"
               formControlName="reportBothSides" (change)="onReportBoth($event);" style="vertical-align: bottom;" />
      </div>
    </div>

    <div formArrayName="otherMarketParticipants" *ngIf="!reportBoth">
      <div class="row gutter-0 !me-[40px] bottom-row-border" *ngFor="let marketParticipants of otherMarketParticipants; let i = index"
           [formGroupName]="i">
        <div class="col-5 label">Other Market Participant {{ i + 1 }}
          <span class="text-required" *ngIf="i == 0">*</span>
        </div>
        <div id="remit-trade-entry-table2-otherMarketParicipant-BeneficiaryType" class="col-3 ps-2">
          <p-dropdown [options]="otherBeneficiaryType" [style]="{'width': '100%'}" formControlName="codeType"
                      [styleClass]="'margin-3' + getOtherMarketParticipantErrorStyleClass(i, 'codeType' )"></p-dropdown>
        </div>
        <div id="remit-trade-entry-table2-otherMarketParicipant" class="col-4 ps-2 pe-1">
          <input id="remit-trade-entry-table2-otherMarketParticipantInput" type="text"
                 [class]="'form-control padding-6 text-medium margin-3' + getOtherMarketParticipantErrorStyleClass(i, 'code')"
                 formControlName="code" [maxlength]="20">
        </div>
        <span
          *ngIf="getOtherMarketParticipantErrorStyleClass(i, 'code') || getOtherMarketParticipantErrorStyleClass(i, 'codeType' )"
          class="fa fa-info-circle absolute error-marker"></span>
      </div>
    </div>

    <div class="row gutter-0 !me-[40px]" *ngIf="reportBoth">
      <div class="col-5 label">Other Market Participant <span class="text-required">*</span></div>
      <div class="col-7 ps-2 pe-1">
        <p-dropdown formControlName="otherMarketParticipantCode" [options]="otherMarketParticipantsData" [filter]="true" [filterFields]="['label']"
                    [style]="{'width': '100%'}"
                    [styleClass]="'margin-3 '  + getValidationErrorStyleClass('otherMarketParticipantCode')"
        ></p-dropdown>
      </div>
      <span *ngIf="formGroup.controls['otherMarketParticipantCode'].hasError('error')"
            class="fa fa-info-circle absolute error-marker"></span>
    </div>

    <div formArrayName="otherBeneficiaries" *ngIf="reportBoth">
      <div class="row gutter-0 !me-[40px] bottom-row-border" *ngFor="let beneficiary of beneficiaries; let i = index" [formGroupName]="i">
        <div class="col-5 label">Beneficiary (for other MP) {{ i + 1 }}</div>
        <div class="col-4 pe-0">
          <p-dropdown [options]="otherBeneficiaryType" formControlName="codeType"
                      [styleClass]="'margin-3 ' + getOtherBeneficiariesErrorStyleClass(i, 'codeType')">
          </p-dropdown>
        </div>
        <div class="col-3 pe-0">
          <input type="text"
                 [class]="'form-control padding-6 text-medium margin-3' + getOtherBeneficiariesErrorStyleClass(i, 'code')"
                 formControlName="code" [maxlength]="20">
        </div>
        <span
          *ngIf="getOtherBeneficiariesErrorStyleClass(i, 'code') || getOtherBeneficiariesErrorStyleClass(i, 'codeType')"
          class="fa fa-info-circle absolute error-marker"></span>
      </div>
    </div>

    <div class="form-body">
      <div class="row gutter-0 !me-[40px]">
        <div class="col-5 label"></div>
        <div class="col-7 ps-2 pe-1">
          <button type="button" class="btn add-remove-line" (click)="add()" [disabled]="addDisabled">Add</button>
          <button type="button" class="btn add-remove-line" (click)="remove()"
                  [disabled]="removeDisabled">Remove
          </button>
        </div>
      </div>
    </div>

  </div>
</div>
