
<div class="panel-box margin-bottom-15">

  <h1>eSM Master Data</h1>
  <div class="row g-0 margin-0">
    <div class="margin-right-50 col-auto">
      <label class="text-bold margin-right-9">Legal Name:</label>
      <span>{{data?.organisationLegalName}}</span>
    </div>
    <div class="col-auto">
      <label class="text-bold margin-right-9">Code (Type='{{data?.organisationCodeType}}'):</label>
      <span>{{data?.organisationCode}}</span>
    </div>
  </div>
</div>
