import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {OrganisationService} from '@common/organisation.service';
import {CpmlDealsService} from '../cpml-deals.service';
import { ErrorMessageService } from '@common/errors/error-message.service';
import { Message } from 'primeng/api';

@Component({
  selector: 'cms-cpml-dealfinder',
  templateUrl: './cpml-dealfinder.component.html'
})
export class CpmlDealFinderComponent implements OnInit, OnDestroy{
  isDatatableVisible: boolean;
  routeSubscription$: Subscription = new Subscription();
  notFoundIdsSubscription$: Subscription = new Subscription();
  notFoundIDs$: BehaviorSubject<string[]>;
  idsCount$: BehaviorSubject<number>;
  tableMessage: Message[] = [];

  constructor(private organisationService: OrganisationService,
              private changeDetectorRef: ChangeDetectorRef,
              public cpmlDealService: CpmlDealsService,
              public errorMessageService:ErrorMessageService) {
  }

  ngOnInit(): void {
    this.isDatatableVisible = false;
    this.routeSubscription$.add(this.organisationService.getChangeNotNullOrganisationObservable()
      .subscribe(() => {
        this.isDatatableVisible = false;
      }));
    this.idsCount$ = new BehaviorSubject<number>(42);
    this.notFoundIDs$ = new BehaviorSubject<string[]>([]);
    this.notFoundIdsSubscription$.add(this.cpmlDealService.getNotFoundIdsObservable().subscribe(this.onNotFoundIdsUpdate.bind(this)));
  }

  ngOnDestroy(): void {
    this.routeSubscription$.unsubscribe();
    this.notFoundIdsSubscription$.unsubscribe();
  }

  changeDatatableVisibility(): void {
    this.isDatatableVisible = true;
  }

  onNotFoundIdsUpdate(notFoundIds): void {
    const query = this.cpmlDealService.getCurrentSearchQuery();
    if (query != null && query['ids'] != null) {
      this.idsCount$.next(query.ids.length);
      this.notFoundIDs$.next(notFoundIds);
      this.changeDetectorRef.markForCheck();
    } else {
      this.idsCount$.next(0);
      this.notFoundIDs$.next([]);
      this.changeDetectorRef.markForCheck();
    }
  }
}
