import { Injectable, Injector } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';

import { AuthenticationService } from '../../auth/authentication.service';
import { OrganisationService } from '@common/organisation.service';
import { ApiRequestService } from '@common/api-request.service';
import * as moment from 'moment';

@Injectable()
export class ClientErrorsService {
  constructor(
    private injector: Injector) {
  }

  sendErrToServer(error: Error) {
    const httpClient = this.injector.get(ApiRequestService);

    // get error object
    const errorToSend = this.addContextInfo(error);

    // send error to server
    return httpClient.post('api/error/ui', errorToSend);
  }

  addContextInfo(error: Error) {

    // get services
    const authenticationService = this.injector.get(AuthenticationService);
    const location = this.injector.get(LocationStrategy);
    const orgService = this.injector.get(OrganisationService);

    // get user and selected organisation
    const userName = !!authenticationService.getUser() ? authenticationService.getUser().userName : 'current no user is authenticated';
    const selectedOrg = orgService.getSelectedOrganisationName();

    // get time and location error occurred
    const time = moment(moment.now()).format('YYYY-MM-DDThh:mm:ss');
    const url = location.path();

    // get errorMessage and full stacktrace
    const errorName = error.name;
    const errorMessage = error.message;
    const errorStack = error.stack;

    return { time, url, userName, selectedOrg, errorName, errorMessage, errorStack };
  }
}
