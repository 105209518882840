<form class="row col-12 padding-0" [formGroup]="form" *ngIf="processRolesVisible || groupsVisible || userVisible">
  <div class="col-12 margin-3 padding-0 text-start">
    <p-dropdown *ngIf="groupsVisible"

                [style]="{width: '200px'}"
                class="col-lg-2 col-12 padding-0 margin-3"
                [options]="orgGroupsOptions"
                [filter]="true"
                [filterFields]="['label']"
                filterMatchMode="startsWith"
                formControlName="selectedOrganisationGroupId"
                (onChange)="onOrgGroupChange()">
    </p-dropdown>
    <p-dropdown *ngIf="userVisible"
                [style]="{width: '200px'}"
                class="col-lg-2 col-12 padding-0 margin-3"
                [options]="usersOptions"
                [filter]="true"
                [filterFields]="['label']"
                filterMatchMode="startsWith"
                formControlName="selectedUsername"
                (onChange)="onUsersChange()">
    </p-dropdown>
    <ng-container *ngIf="processRolesVisible && processRoles">
      <div class="process-role-container">
        <label *ngFor="let processRole of processRoles" class="margin-left-12 radio-button">
          <input type="radio"
                 formControlName="selectedProcessRole"
                 [value]="processRole.name"
                 name="selectedProcessRole"
                 (change)="onProcessRoleChange()"/>{{processRole.displayName}}</label>
      </div>
    </ng-container>
    <div class="row margin-0 export-user-roles">
      <button class="btn export-btn" pButton type="button" icon="fa fa-download" label="Export" (click)="onExport()"></button>
    </div>
  </div>
</form>
