import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Message } from 'primeng/api';

import { MessagesMapperService } from '@common/messages-mapper.service';

@Component({
  selector: 'cms-upload-panel',
  templateUrl: './upload-panel.component.html',
  styleUrls: ['./upload-panel.component.scss']
})
export class UploadPanelComponent {
  @ViewChild('upload', { read: ElementRef, static: true }) uploadEl$: ElementRef;
  @Input() maxFileSize: number = 1; // 1Mb
  @Input() accept = '.xml';
  @Input() inBytesSize = false;
  @Input() title: string = '';
  @Input() disabled: boolean = false;
  @Input() busy: boolean = false;
  @Input() fullWidth: boolean;
  @Output() error = new EventEmitter<Message[]>();
  @Output() upload = new EventEmitter<File>();
  file: File = null;

  constructor(private mapper: MessagesMapperService) {
  }

  resetInput() {
    this.uploadEl$.nativeElement.value = null;
    this.file = null;
  }

  onBrowseFiles(): boolean {
    this.resetInput();
    this.error.emit([]);
    this.uploadEl$.nativeElement.click();
    return false;
  }

  get full() {
    if (!this.fullWidth) {
      return ' decent-max-width';
    }
  }

  onSubmit(): void {
    this.upload.emit(this.file);
    this.resetInput();
  }

  onSelect(event): void {
    const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    const file = files[0];
    if (this.validateSelectedFile(file)) {
      this.file = file;
    }
  }

  private validateSelectedFile(file: File): boolean {
    if (!this.validateExt(file)) {
      this.error.emit([this.mapper.createErrorMessage(`File is not of type [${this.accept}].`)]);
      this.resetInput();
      return false;
    }
    if (!this.validateFileSize(file)) {
      this.error.emit([this.mapper.createErrorMessage(`File exceeds limit of ${this.maxFileSize} ${this.inBytesSize ? 'B' : 'MB'}.`)]);
      this.resetInput();
      return false;
    }
    return true;
  }

  private validateFileSize(file: File): boolean {
    const maxSizeInBytes = (this.inBytesSize) ? this.maxFileSize : this.maxFileSize * 1024 * 1024;
    return (file.size < maxSizeInBytes);
  }

  private validateExt(file: File): boolean {
    return file.name.endsWith(this.accept);
  }
}
