import { Component } from '@angular/core';
import { ErrorMessageService } from '@common/errors/error-message.service';

@Component({
  selector: 'cms-remit-files-out',
  templateUrl: './remit-files-out.component.html',
  styleUrls: ['./remit-files-out.component.scss']
})
export class RemitFilesOutComponent {

  constructor( public readonly errorMessageService:ErrorMessageService) {
  }
}
