import { Component, Input, OnInit } from '@angular/core';
import { AdditionalDateRangeEntry, getBasicDateRanges } from '@common/additional-date-range/additional-date-range.model';

@Component({
  selector: 'cms-additional-date-range',
  templateUrl: './additional-date-range.component.html',
  styleUrls: ['./additional-date-range.component.scss']
})
export class AdditionalDateRangeComponent implements OnInit {
  @Input() field: string;
  @Input() form: any;
  @Input() dateRanges: AdditionalDateRangeEntry[] = getBasicDateRanges();
  @Input() startField: string;
  @Input() endField: string;
  allItems = [];

  ngOnInit() {

    this.allItems = this.dateRanges.map((range: AdditionalDateRangeEntry) => {
      return {
        label: range.label, command: () => this.selectDateItem(range.start, range.end)
      };
    });
  }

  selectDateItem(start: Date, end: Date): void {
    this.form.patchValue({
      [this.startField]: start,
      [this.endField]: end
    });
    this.form.updateValueAndValidity();
  }
}
