import { Action } from '@ngrx/store';
import { Message } from 'primeng/api';
import {
  EcmSettingsBrokerRestrictionsDataResponse,
  PresetValueResponse
} from '../../../settings/ecm-settings-tab-panel/broker-restrictions/broker-restrictions.model';

export const SET_TO_DEFAULT = '[ECM SETTINGS BROKER] Set to default';
export const LOAD_DATA = '[ECM SETTINGS BROKER] Load Data';
export const SET_MESSAGES = '[ECM SETTINGS BROKER] Set Messages';
export const LOAD_DATA_SUCCESS = '[ECM SETTINGS BROKER] Load Data Success';
export const UPLOAD_FILE_SUCCESS = '[ECM SETTINGS BROKER] Upload File Success';
export const DOWNLOAD_XML = '[ECM SETTINGS BROKER] Download XML';
export const UPLOAD_FILE = '[ECM SETTINGS BROKER] Upload File';
export const LOAD_PRESET_VALUES = '[ECM SETTINGS BROKER] Load Preset Values';
export const LOAD_PRESET_VALUES_SUCCESS = '[ECM SETTINGS BROKER] Load Preset Values Success';
export const CONFIRM_DIALOG_SUCCESS = '[ECM SETTINGS BROKER] Confirm dialog success';
export const CONFIRM_DIALOG_CANCEL = '[ECM SETTINGS BROKER] Confirm dialog cancel';
export const OPEN_DIALOG = '[ECM SETTINGS BROKER] Open dialog';
export const CLOSE_DIALOG = '[ECM SETTINGS BROKER] Close dialog';

export class SetMessagesAction implements Action {
  readonly type = SET_MESSAGES;

  constructor(public payload: Message[]) { }
}

export class SetToDefaultAction implements Action {
  readonly type = SET_TO_DEFAULT;
}

export class LoadDataSuccessAction implements Action {
  readonly type = LOAD_DATA_SUCCESS;

  constructor(public payload: EcmSettingsBrokerRestrictionsDataResponse) { }
}

export class UploadFileSuccess implements Action {
  readonly type = UPLOAD_FILE_SUCCESS;
}

export class LoadDataAction implements Action {
  readonly type = LOAD_DATA;
}

export class DownloadXmlAction implements Action {
  readonly type = DOWNLOAD_XML;
}

export class UploadAction implements Action {
  readonly type = UPLOAD_FILE;

  constructor(public payload: File) { }
}

export class LoadPresetValuesAction implements Action {
  readonly type = LOAD_PRESET_VALUES;
}

export class LoadPresetValuesSuccessAction implements Action {
  readonly type = LOAD_PRESET_VALUES_SUCCESS;

  constructor(public payload: PresetValueResponse) { }
}

export class ConfirmDialogSuccess implements Action {
  readonly type = CONFIRM_DIALOG_SUCCESS;
}

export class ConfirmDialogCancel implements Action {
  readonly type = CONFIRM_DIALOG_CANCEL;
}

export class OpenDialog implements Action {
  readonly type = OPEN_DIALOG;
}

export class CloseDialog implements Action {
  readonly type = CLOSE_DIALOG;
}

export type EcmSettingsBrokerRestrictionsActions = LoadDataAction | LoadDataSuccessAction
  | SetMessagesAction | DownloadXmlAction | UploadAction | LoadPresetValuesAction
  | LoadPresetValuesSuccessAction | SetToDefaultAction | ConfirmDialogSuccess
  | ConfirmDialogCancel | OpenDialog | CloseDialog | UploadFileSuccess;
