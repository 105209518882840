import { organisationIdReducer } from '../organisation-id/organisation-id.reducer';
import * as fromAction from '../user-account/user-account.actions';
import { Organisation, OrganisationsFilters } from '../../administration-tab-panel/organisations/organisations.model';
import { dialogReducer, DialogState } from '../dialog/dialog.reducer';
import { datatableReducer, DatatableState } from '../datatable/datatable.reducer';
import { OrganisationGroup } from '../../administration-tab-panel/administration.model';
import { Prefix } from '../reducers';
import { SET_TO_DEFAULT } from '../shared/shared.actions';
import { LOAD_ORGANISATION_FORM_SUCCESS, LoadOrganisationFormSuccessAction } from './organisation.actions';

export type OrganisationDatatable = DatatableState<Organisation, OrganisationsFilters>;

export interface OrganisationsState {
  dialog: DialogState;
  organisationId?: number;
  datatable: OrganisationDatatable;
  form: AdminOrganisationFormState;
}

export interface AdminOrganisationFormState {
  orgGroupSelectorVisible: boolean;
  orgGroupIdColumnVisible: boolean;
  orgGroups: OrganisationGroup[];
  toggleStatusAllowed: boolean;
  addAllowed: boolean;
  editAllowed: boolean;
  onlyContactsEditable: boolean;
  deleteAllowed: boolean;
  moveAllowed: boolean;
  editEcmAllowed: boolean;
  editErrAllowed: boolean;
  editEsmAllowed: boolean;
}

export const datatableInitialState: OrganisationDatatable = {
  selected: null,
  data: {
    values: [],
    count: 0
  },
  messages: [],
  filters: {
    organisationGroupId: null
  },
  loading: false,
  paging: {
    page: 0,
    entriesPerPage: 25
  },
  sorting: {
    columnName: 'organisationGroupDisplayName',
    sortOrder: 'ASCENDING'
  },
  editableData: null,
};

export const initialOrgFormState: AdminOrganisationFormState = {
  orgGroups: [],
  orgGroupSelectorVisible: false,
  orgGroupIdColumnVisible: false,
  toggleStatusAllowed: false,
  addAllowed: false,
  editAllowed: false,
  onlyContactsEditable: false,
  deleteAllowed: false,
  moveAllowed: false,
  editEcmAllowed: false,
  editErrAllowed: false,
  editEsmAllowed: false
};

export const initialOrganisationsState: OrganisationsState = {
  dialog: null,
  organisationId: null,
  datatable: datatableInitialState,
  form: initialOrgFormState
};

export const orgDialogReducer = dialogReducer('[ORGANISATION]');
export const orgDatatableReducer = datatableReducer('[ORGANISATION]', datatableInitialState);
export const orgOrganisationIdReducer = organisationIdReducer('[ORGANISATION]');
export const orgAdminOrganisationFormReducer = adminOrganisationFormReducer('[ORGANISATION]', initialOrgFormState);

export function adminOrganisationFormReducer(prefix: Prefix, initialState) {
  return function (state: AdminOrganisationFormState = initialState,
                   action: LoadOrganisationFormSuccessAction): AdminOrganisationFormState {
    switch (action.type) {
      case `${prefix} ${LOAD_ORGANISATION_FORM_SUCCESS}`:
        const {
          organisationGroups,
          editEsmpAllowed,
          ...rest
        } = action.payload;

        return {
          orgGroups: organisationGroups,
          editEsmAllowed: editEsmpAllowed,
          ...rest
        };

      case `${prefix} ${SET_TO_DEFAULT}`:
        return {
          ...initialState
        };
      default:
        return state;
    }
  };
}

export function organisationsReducer(state: OrganisationsState = { ...initialOrganisationsState },
                                     action: fromAction.UserAccountActions): OrganisationsState {
  return {
    dialog: orgDialogReducer(state.dialog, action as any),
    datatable: orgDatatableReducer(state.datatable, action),
    organisationId: orgOrganisationIdReducer(state.organisationId, action as any),
    form: orgAdminOrganisationFormReducer(state.form, action as any)
  };
}

