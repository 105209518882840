import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RemitReportService } from '../../remit-report.service';
import { catchError, debounceTime, delay, takeUntil, tap } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'cms-table-button',
  templateUrl: './table-button.component.html',
  styleUrls: ['./table-button.component.scss']
})
export class TableButtonComponent implements OnInit, OnDestroy {

  constructor(private remitReportService: RemitReportService,
              private cd: ChangeDetectorRef) {}

  @Input() activityReportId;

  private subscription: Subscription = new Subscription();
  loading: boolean = false;
  click: Subject<boolean> = new Subject();


  ngOnInit(): void {

    this.subscription.add(this.click.asObservable().pipe(debounceTime(1000)).subscribe(() =>
      this.remitReportService.downloadExcelReport(this.activityReportId)
        .pipe(
          tap(data => FileSaver.saveAs(data.blob, data.filename)))
        .subscribe(() => this.load(false), () => this.load(false)))
    );

  }


  public loadExcelExport(): void {
   this.load(true);
   this.click.next(true);
  }

  private load(b: boolean) {
      this.loading = b;
      this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}


