<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="messages | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>

  <p-tabView [activeIndex]="activeIndex" (onChange)="onChangeTab($event)" *ngIf="reportapp">
    <p-tabPanel header="CMS Billing Runs" >
      <div class="tab-view-body">
        <div class="tab-view-body">
         <cms-billing-run *ngIf="activeIndex === 0 "></cms-billing-run>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="ACER Billing Runs">
      <div class="tab-view-body">
        <div class="tab-view-body">
          <cms-acer-billing-run  *ngIf="activeIndex === 1"> </cms-acer-billing-run>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>
</ng-template>

