<p-dropdown [options]="organisations"
            [(ngModel)]="selectedOrganisation"
            (onHide)="onHide()"
            [filter]="true"
            [filterFields]="['label']"
            placeholder="Select an Organisation"
            [styleClass]="'org-selection'"
            filterMatchMode="startsWith"
            scrollHeight="300px">
</p-dropdown>
