import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { Message } from 'primeng/api';
import {
  EditErr,
  ErrPresetValuesResponse,
  ErrProcessRoles,
  Organisation,
} from '../../organisations.model';
import { MessagesMapperService } from '../../../../../common/messages-mapper.service';

@Component({
  selector: 'cms-edit-err-dialog',
  templateUrl: './edit-err-dialog.component.html',
  styleUrls: ['./edit-err-dialog.component.scss']
})
export class EditErrDialogComponent implements OnInit, OnChanges {
  @Input() data: Organisation;
  @Input() presetValues: ErrPresetValuesResponse;
  @Input() display = false;
  @Input() messages: Message[];
  @Output() displayChange = new EventEmitter<boolean>();
  @Output() confirm = new EventEmitter<EditErr>();
  @Output() cancel = new EventEmitter<Message[]>();
  defaultEmirRepositoryOptions: {label: string, value: any}[] = [];
  errors: {[key: string]: ValidationErrors} = {};
  form: UntypedFormGroup;
  readonly ErrProcessRoles = ErrProcessRoles;

  get errPartyExist() {
    const { processRoles: { selectedOption: selectedProcessRole = null }, leiCode } = this.presetValues;
    return leiCode && selectedProcessRole;
  }

  constructor(private formBuilder: UntypedFormBuilder,
              private mapper: MessagesMapperService) {
  }

  onCancel(): void {
    this.hide();
    this.cancel.emit(this.mapper.toWarningMessages(`Editing of organisation '${this.data.displayName}' was cancelled.`));
  }

  onVisibleChange(visible: boolean): void {
    if (!visible) {
      this.onCancel();
    }
  }

  onConfirm(): void {
    const {
      processRole,
      defaultEmirRepository,
      ompConnectionMethod,
      partnerId,
      acerCode,
      acerCopyPartnerId,
      elcomReceipt,
      externalRrmScreenEnabled,
      leiCode,
      mifidMPPosRepXPSubscription,
      mifidPositionReporting,
      mifidXmlEbftpPartnerId,
      ompPartnerId,
      smallMediumTrader,
      timeZone
    } = this.form.value;

    const out: EditErr = {
      processRole,
      defaultEmirRepository,
      partnerId: partnerId || null,
      acerCode: acerCode || null,
      acerCopyPartnerId: acerCopyPartnerId || null,
      leiCode,
      ompPartnerId: ompPartnerId || null,
      timeZone,
      selectedOrganisationId: this.data.orgId,
      externalRrmScreenEnabled
    };
    if (processRole === ErrProcessRoles.Trader) {
      out.mifidPositionReporting = mifidPositionReporting;
      out.mifidMPPosRepXPSubscription = mifidMPPosRepXPSubscription;
      out.smallMediumTrader = smallMediumTrader;
      out.mifidXmlEbftpPartnerId = mifidXmlEbftpPartnerId || null;
      out.elcomReceipt = elcomReceipt;
    } else {
      out.ompConnectionMethod = ompConnectionMethod;
    }

    this.confirm.emit(out);
  }

  ngOnInit(): void {
    const {
      partnerId,
      processRoles: { selectedOption: selectedProcessRole = null },
      emirRepositoryTypes: { selectedOption: selectedRepositoryType },
      ompConnectionMethods: { selectedOption: selectedMethod },
      acerCode,
      acerCopyPartnerId,
      elcomReceipt,
      externalRrmScreenEnabled,
      leiCode,
      mifidMPPosRepXPSubscription,
      mifidPositionReporting,
      mifidXmlEbftpPartnerId,
      ompPartnerId,
      smallMediumTrader,
      timeZone
    } = this.presetValues;
    this.form = this.formBuilder.group({
      processRole: selectedProcessRole,
      defaultEmirRepository: selectedRepositoryType,
      ompConnectionMethod: selectedMethod,
      partnerId,
      acerCode: acerCode,
      acerCopyPartnerId,
      elcomReceipt,
      externalRrmScreenEnabled,
      leiCode,
      mifidMPPosRepXPSubscription,
      mifidPositionReporting,
      mifidXmlEbftpPartnerId,
      ompPartnerId,
      smallMediumTrader,
      timeZone
    });
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (this.form) {
      if (changes.display && !this.display) {
        this.hide();
      }
      if (changes.data) {
        const {
          partnerId,
          processRoles: { selectedOption: selectedProcessRole = null },
          emirRepositoryTypes: { selectedOption: selectedRepositoryType },
          ompConnectionMethods: { selectedOption: selectedMethod },
          acerCode,
          acerCopyPartnerId,
          elcomReceipt,
          externalRrmScreenEnabled,
          leiCode,
          mifidMPPosRepXPSubscription,
          mifidPositionReporting,
          mifidXmlEbftpPartnerId,
          ompPartnerId,
          smallMediumTrader,
          timeZone
        } = this.presetValues;
        this.form.patchValue({
          processRole: selectedProcessRole,
          defaultEmirRepository: selectedRepositoryType,
          ompConnectionMethod: selectedMethod,
          partnerId,
          acerCode: acerCode,
          acerCopyPartnerId,
          elcomReceipt,
          externalRrmScreenEnabled,
          leiCode,
          mifidMPPosRepXPSubscription,
          mifidPositionReporting,
          mifidXmlEbftpPartnerId,
          ompPartnerId,
          smallMediumTrader,
          timeZone
        });
        this.form.updateValueAndValidity();
      }
    }

    if (changes.messages) {
      this.errors = {};
      if (this.messages) {
        this.messages.forEach((obj: Message) => {
          const { key: controlName } = obj;
          this.errors[controlName] = { 'server': true };
        });
      }
    }

    if (changes.presetValues) {
      this.defaultEmirRepositoryOptions = this.presetValues && this.presetValues.emirRepositoryTypes ?
        this.presetValues.emirRepositoryTypes.options :
        [];
    }
  }

  private hide(): void {
    this.messages = [];
    this.display = false;
    this.displayChange.emit(this.display);
    this.form.reset();
    this.form.updateValueAndValidity();
  }
}
