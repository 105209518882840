import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {DatatableState} from '@common/datatable/datatable.model';
import {MessagesMapperService} from '@common/messages-mapper.service';
import {OrganisationService} from '@common/organisation.service';
import {Column} from '@common/shared/datatable.model';
import {DatatableData, PagingFilter, SortingFilter} from '@common/shared/results.model';
import {Observable, Subscription} from 'rxjs';
import {Message} from 'primeng/api';
import {OrganisationGroupsState} from '../../state/organisation-groups/organisation-groups.reducer';
import {DialogState} from '../../state/dialog/dialog.reducer';
import {AddEditOrganisationGroup, EditBillingFormData, OrganisationGroup} from './organisation-groups.model';
import {Prefix} from '../../state/reducers';
import {SetOrganisationIdAction} from '../../state/organisation-id/organisation-id.actions';
import {ActivatedRoute, Router} from '@angular/router';
import * as fromSelectors from '../../state/organisation-groups/organisation-groups.selectors';
import * as fromDtActions from '../../state/datatable/datatable.actions';
import * as fromOrgGroupsActions from '../../state/organisation-groups/organisation-groups.actions';
import * as fromDialogActions from '../../state/dialog/dialog.actions';
import {SetToDefaultAction} from '../../state/shared/shared.actions';

const defaultStyles = {
  'text-align': 'left',
  'text-overflow': 'ellipsis',
  'white-space': 'nowrap'
};

@Component({
  selector: 'cms-organisation-groups',
  templateUrl: './organisation-groups.component.html',
  styleUrls: ['./organisation-groups.component.scss']
})
export class OrganisationGroupsComponent implements OnInit, OnDestroy {
  filters$: Observable<string> = this.store.pipe(select(fromSelectors.getFilters));
  data$: Observable<DatatableData<OrganisationGroup[]>> = this.store.pipe(select(fromSelectors.getData),);
  loading$: Observable<boolean> = this.store.pipe(select(fromSelectors.getLoading));
  sorting$: Observable<SortingFilter> = this.store.pipe(select(fromSelectors.getSorting));
  messages$: Observable<Message[]> = this.store.pipe(select(fromSelectors.getMessages));
  paging$: Observable<PagingFilter> = this.store.pipe(select(fromSelectors.getPaging));
  displayName: string;
  selected$: Observable<OrganisationGroup> = this.store.pipe(select(fromSelectors.getSelected));
  dialog$: Observable<DialogState> = this.store.pipe(select(fromSelectors.getDialog));
  subscr$: Subscription = new Subscription();
  selected: OrganisationGroup;
  readonly prefix: Prefix = '[ORGANISATION GROUPS]';
  readonly dataTableColumns: Column[] = [
    { name: 'displayName', type: 'text', title: 'Display Name', style: { ...defaultStyles }, sortable: true, visible: true },
    { name: 'legalName', type: 'text', title: 'Legal Name', style: { ...defaultStyles }, sortable: true, visible: true },
    { name: 'numberOfActiveOrganisations', type: 'text', title: 'Organisations', style: { ...defaultStyles, 'text-align': 'right' }, sortable: true, visible: true },
    { name: 'numberOfActiveUserAdmins', type: 'text', title: 'User Admins', style: { ...defaultStyles, 'text-align': 'right' }, sortable: true, visible: true },
    { name: 'numberOfActiveOperators', type: 'text', title: 'Operators', style: { ...defaultStyles, 'text-align': 'right' }, sortable: true, visible: true },
    { name: 'fourthPartyPermission', type: 'active', title: '4th Party Rep.', style: { ...defaultStyles }, sortable: true, visible: true },
    { name: 'withBillingData', type: 'yes', title: 'Billing Data', style: { ...defaultStyles }, sortable: true, visible: true },
    { name: 'activityReport', type: 'yes', title: 'XML Activity Reporting', style: { ...defaultStyles }, sortable: true, visible: true },
    { name: 'reconciliationReport', type: 'yes', title: 'XML Reconciliation Reporting', style: { ...defaultStyles }, sortable: true, visible: true },
    { name: 'excludeFromBilling', type: 'status', title: 'Exclude From CMS Billing', style: { ...defaultStyles, 'text-align': 'center' }, sortable: true, visible: true },
  ];

  constructor(private store: Store<OrganisationGroupsState>,
              private mapper: MessagesMapperService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private organisationService: OrganisationService) {
  }

  onLazyLoad(state: DatatableState): void {
    this.store.dispatch(new fromDtActions.SortAndPageDataAction(this.prefix, {
      paging: {
        page: state.page,
        entriesPerPage: state.perPage
      },
      sorting: {
        columnName: state.sortField,
        sortOrder: state.sortOrder
      }
    }));
  }

  onTextFieldFilter(filters: string): void {
    this.store.dispatch(new fromDtActions.FilterDataAction(this.prefix, filters));
  }

  onExport(): void {
    this.store.dispatch(fromOrgGroupsActions.exportAction());
  }

  onSelected(org: OrganisationGroup): void {
    this.store.dispatch(new fromDtActions.SelectAction(this.prefix, org));
  }

  onManageUsers(): void {
    const {organisationGroupId} = this.selected;
    this.router.navigate(['/admin/userAccounts'], {queryParams: {organisationGroupId}});
  }

  onManageOrganisations(): void {
    const {organisationGroupId} = this.selected;
    this.router.navigate(['/admin/organisations'], {queryParams: {organisationGroupId}});
  }

  onAddGroup(): void {
    this.store.dispatch(new fromDialogActions.OpenDialogAction(this.prefix, {
      name: 'ADD',
    }));
  }

  onEditGroup(): void {
    this.store.dispatch(new fromDialogActions.OpenDialogAction(this.prefix, {
      name: 'EDIT',
    }));
  }

  onDeleteGroup(): void {
    this.store.dispatch(fromOrgGroupsActions.deleteOrgGroupAction({ organisationGroupId: this.selected.organisationGroupId }));
  }

  onEditBillingData(): void {
    this.store.dispatch(fromOrgGroupsActions.loadEditBillingData({ organisationGroupId: this.selected.organisationGroupId }));
    this.store.dispatch(new fromDialogActions.OpenDialogAction(this.prefix, {
      name: 'EDIT_BILLING_DATA',
    }));
  }

  onDialogCancel(messages: Message[]): void {
    this.store.dispatch(new fromDtActions.SetMessagesAction(this.prefix, messages));
    this.store.dispatch(new fromDialogActions.CloseDialogAction(this.prefix));
  }

  onGenerate(obj: { organisationGroupId: number }): void {
    this.store.dispatch(fromOrgGroupsActions.generateApiKeyAction(obj));
  }

  onAddGroupConfirm(obj: AddEditOrganisationGroup): void {
    this.store.dispatch(fromOrgGroupsActions.addOrgGroupAction(obj));
  }

  onEditGroupConfirm(obj: AddEditOrganisationGroup): void {
    this.store.dispatch(fromOrgGroupsActions.editOrgGroupAction(obj));
  }

  onEditBillingDataConfirm(obj: { displayName: string, data: EditBillingFormData }): void {
    this.store.dispatch(fromOrgGroupsActions.editBillingData(obj));
  }

  ngOnInit(): void {
    this.store.dispatch(new SetOrganisationIdAction(this.prefix, this.organisationService.getCachedOrganisationId()));
    this.subscr$.add(this.store.pipe(select(fromSelectors.getSelected))
      .subscribe((selected: OrganisationGroup) => {
        this.selected = selected;
        if (selected == null) {
          this.router.navigate(['/admin/organisationGroups']);
        }
      }));
  }

  ngOnDestroy(): void {
    this.subscr$.unsubscribe();
    this.store.dispatch(new SetToDefaultAction(this.prefix));
  }

}

