import {createSelector} from '@ngrx/store';
import {getNettingDashboardState} from '../dashboard.selectors';
import {EsmNettingDashboardState} from '../dashboard.reducers';
import {EsmNettingDashboardTableState} from './table.reducers';

export const getTable = createSelector(getNettingDashboardState, (state: EsmNettingDashboardState) => state.table);
export const getMessages = createSelector(getTable, (state: EsmNettingDashboardTableState) => state.messages);
export const getLoading = createSelector(getTable, (state: EsmNettingDashboardTableState) => state.loading);
export const getSelectedIds = createSelector(getTable, (state: EsmNettingDashboardTableState) => state.selectedIds);
export const getSelectedNettingStatements = createSelector(getTable, (state: EsmNettingDashboardTableState) => state.nettingStatements);

export const getData = createSelector(getTable, (state: EsmNettingDashboardTableState) => state.data);
export const getPaging = createSelector(getTable, (state: EsmNettingDashboardTableState) => state.paging);
export const getSorting = createSelector(getTable, (state: EsmNettingDashboardTableState) => state.sorting);
export const getColumns = createSelector(getTable, (state: EsmNettingDashboardTableState) => state.columns);
