import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PasswordService } from './password.service';

@Component({
  selector: 'cms-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: [
    './password.component.scss'
  ],
})

export class ResetPasswordComponent implements OnInit {

  resetPasswordForm: UntypedFormGroup;
  private errorMsg: string = '';

  constructor(private titleService: Title, formBuilder: UntypedFormBuilder, private passwordService: PasswordService) {
    this.resetPasswordForm = formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(16), Validators.pattern('[a-zA-Z0-9$!\\+\\-_.,]+')]],
      repeatPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(16), Validators.pattern('[a-zA-Z0-9$!\\+\\-_.,]+')]],
    });

    titleService.setTitle('Reset My Password');

    this.passwordService.getStatus().subscribe(
      status => {
        if (status.response) {
          this.handleStatusResponse(status.response);
        }
      }
    );
  }

  ngOnInit() {
  }

  onSubmit() {
    if (this.isValidPassword()) {
      this.setErrorMsg('');
      const passwordDto = {
        newPassword: this.resetPasswordForm.value.password,
        hash: this.passwordService.getHash()
      };

      this.passwordService.reset(passwordDto);
    } else {
      this.setErrorMsg('Repeated password does not match.');
    }
  }

  getErrorMsg(): string {
    return this.errorMsg;
  }

  setErrorMsg(msg: string) {
    this.errorMsg = msg;
  }

  private handleStatusResponse(msg: string) {
    switch (msg) {
      case 'REQUEST_EXPIRED':
        this.setErrorMsg('Your password link has expired.');
        break;
      case 'USED_PASSWORD':
        this.setErrorMsg('New password has been used before.');
        break;
      case 'WEAK_PASSWORD':
        this.setErrorMsg('New password does not meet the password policy requirements.');
        break;
      case 'SUCCESS':
        this.setErrorMsg('');
        break;
    }
  }

  public hasInputErrors(): boolean {
    return this.errorMsg.length > 0;
  }

  private isValidPassword(): boolean {
    const password = this.resetPasswordForm.value.password;
    const repeatPassword = this.resetPasswordForm.value.repeatPassword;

    return password && password.match('[a-zA-Z0-9$!\\+\\-_.,]+') && repeatPassword === password;
  }
}

