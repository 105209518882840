import { Component, Input, ViewChild } from '@angular/core';
import { CpmlDashboardBucketCategoryType, CpmlDashboardDocumentStateType } from './filter/cpml-dashboard-filter.types';
import { ActivatedRoute, Event, NavigationEnd, Params, Router } from '@angular/router';
import { CpmlDealsService } from '../cpml-deals.service';
import { TabView } from 'primeng/tabview';
import { Message } from 'primeng/api';

@Component({
  selector: 'cms-cpml-dealview-panel',
  templateUrl: './cpml-dealview-panel.component.html',
  styleUrls: ['./cpml-dealview-panel.component.scss']
})
export class CpmlDealviewPanelComponent {

  @ViewChild(TabView, { static: true }) tabView: TabView;
  tabCategories: CpmlDashboardBucketCategoryType[] = ['IN_PROGRESS', 'ISSUE', 'COMPLETE'];

  activeIndex: number = 0;
  category: CpmlDashboardBucketCategoryType;
  @Input() state: CpmlDashboardDocumentStateType;

  tableMessage: Message[] = [];

  constructor(private dealService: CpmlDealsService, private router: Router, private route: ActivatedRoute) {

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.route.queryParams
          .subscribe((params: Params) => {
            this.category = <CpmlDashboardBucketCategoryType>params['category'];
            if (this.category) {
              this.activeIndex = this.tabCategories.indexOf(this.category);
              if (this.activeIndex) {
                this.changeCategoryTab(this.activeIndex);
              }
            }
          });
      }
    });
  }

  navigateToBucket(event) {
    this.changeCategoryTab(event.index);
  }

  private changeCategoryTab(index: number) {
    const selectedCategory = this.tabCategories[index];
    if (selectedCategory !== this.category) {
      this.router.navigate(['/err/cpml_dashboard/' + selectedCategory.toLowerCase()]);
    }
  }

  changeState(state: CpmlDashboardDocumentStateType) {
    this.state = state;
    this.dealService.emitChangeDocumentState(state);
  }

  backToDashboard() {
    this.router.navigate(['/err/cpml_dashboard/' + this.tabCategories[this.activeIndex].toLowerCase()]);
  }

}
