import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';

export class TypedFormGroup<T> extends UntypedFormGroup {
  readonly value: T;
  errors: {[key: string]: ValidationErrors};

  getRawValue(): T {
    return super.getRawValue();
  }

  setValue(value: T): void {
    super.setValue(value);
  }

  validate(force = false): boolean {
    this.errors = {};
    let isValid = true;
    Object.keys(this.controls).forEach((name: string) => {
      const control: AbstractControl = this.controls[name];
      isValid = isValid && (control.disabled || control.valid);
      if (control && !control.disable && !control.valid && (control.touched || force)) {
        this.errors[name] = control.errors;
      }
    });
    return isValid;
  }
}
