import * as moment from 'moment';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DateRange, EsmFileUploadFilters } from '../file-upload.model';

interface FormValues {
  filename: string;
  startDate: string | Date;
  startTime: string;
  endDate: string | Date;
  endTime: string;
}

@Component({
  selector: 'cms-file-upload-filters',
  templateUrl: './file-upload-filters.component.html',
  styleUrls: ['./file-upload-filters.component.scss']
})
export class FileUploadFiltersComponent implements OnInit, OnChanges {
  @Input() disabled: boolean = false;
  @Input() filters: EsmFileUploadFilters;
  @Output() searchByTime = new EventEmitter<DateRange>();
  @Output() searchByFilename = new EventEmitter<string>();
  @Output() reset = new EventEmitter();
  form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    const data = this.generateFormValues();
    this.form = this.formBuilder.group({
      filename: [data.filename, Validators.required],
      startDate: [data.startDate, Validators.required],
      startTime: [data.startTime, Validators.required],
      endDate: [data.endDate, Validators.required],
      endTime: [data.endTime, Validators.required],
    });
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (this.form && changes.filters) {
      const data = this.generateFormValues();
      this.form.patchValue(data);
      this.form.updateValueAndValidity();
    }
  }

  onSearchByTimeFilter(): void {
    const data = this.generateFilterData();
    this.searchByTime.emit(data);
  }

  onSearchByFilename(): void {
    const { filename } = this.form.getRawValue();
    this.searchByFilename.emit(filename);
  }

  onReset(): void {
    this.reset.emit();
  }

  private generateFormValues(): FormValues {
    const { filename = '', dateTimeRange } = this.filters;
    return {
      filename,
      startDate: dateTimeRange && moment(dateTimeRange.start).toDate() || null,
      startTime: dateTimeRange && moment(dateTimeRange.start).format('HH:mm:ss') || null,
      endDate: dateTimeRange && moment(dateTimeRange.end).toDate() || null,
      endTime: dateTimeRange && moment(dateTimeRange.end).format('HH:mm:ss') || null
    };
  }

  private generateFilterData(): DateRange {
    const { startDate, endDate } = this.form.getRawValue();
    let { endTime, startTime } = this.form.getRawValue();

    startTime = moment(startTime, 'HH:mm:ss');
    endTime = moment(endTime, 'HH:mm:ss');
    const start = startDate && moment(startDate).set({
      'hour': startTime.get('hour'),
      'minute': startTime.get('minute'),
      'second': startTime.get('second')
    }).format('YYYY-MM-DDTHH:mm:ss') || null;

    const end = endDate && moment(endDate).set({
      'hour': endTime.get('hour'),
      'minute': endTime.get('minute'),
      'second': endTime.get('second')
    }).format('YYYY-MM-DDTHH:mm:ss') || null;
    
    return { start, end };
  }
}
