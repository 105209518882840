<div class="panel-box">
  <cms-info ngPreserveWhitespaces>
    <!--@formatter:off (markdown rendering breaks, when the following tags are formatted)-->
    **Purpose**

    Market Participants can use this form to report **bilateral standard trades** to ACER in **REMIT Table 1 format**.

    This function requires Master Operator (MO) privileges.

    **How to Report**

       * From the title bar select the organisational unit you wish to submit the report for
       * Enter the trade details and press "Report Trade"
       * On successful submission you can enter the next trade
       * Hint: Green-coloured product-related fields retain their previously entered value for swift entry of similar trades
       * Switch to screen "REMIT Transactions / Table 1" in order to check reporting progress for your entered trades

    **Guidance on Selected Fields**

    Market Participant (trade side for which to report)

       * Self-reporting (default case): "Market Participant" is populated with the selected internal organisation
       * Agent reporting: change "Market Participant" to any external party set up for your organisation group - your internal organisation will be captured as reporting agent (sender) for the external party
       * Double-sided reporting: check option "Report both sides" to submit reports for both trade sides at once - in this mode additional fields "Trader ID (for other MP)" and "Beneficiary (for other MP)" appear
       * Note that you can only report for internal/external parties for which an ACER code is registered in the CMS

    Standard Fields

       * Transaction time: enter the execution date and time in UTC
       * Delivery Profiles: for complex delivery profiles (such as off-peak) you can add up to 2 additional intervals
       * Days of the Week: enter
          * blank field for "all days"
          * WD for weekday
          * WN for weekend
          * MO, TU, etc for single weekdays
          * or ranges such as FRtoSU

    Option Trades

       * Choosing an option contract type will add 5 additional fields
       * The fields "Price" / "Notional Amount" refer to the option premium in this case and use the "Option Premium Currency"
       * The field "Strike Price" uses the specified "Price Currency"

    Executions of Framework Contracts

       * enabling the checkbox "Execution" means you wish to report an execution of a framework contract
       * framework contracts are reported in the "REMIT Table 2" format
       * enter the "Contract ID" of the underlying framework contract in field "Linked Transaction ID"
    <!--@formatter:on-->
  </cms-info>
  <div>
    <p-messages [enableService]="false" [value]="acerV3DisabledMessage" [closable]="false" class="col-12"></p-messages>
    <p-messages></p-messages>
  </div>
  <form [formGroup]="tradeForm" (submit)="submit()" *ngIf="showTradeEntryForm">
  <div class="component-box">
    <cms-remit-trade-entry-table1-references [formGroup]="tradeForm"></cms-remit-trade-entry-table1-references>
    <cms-remit-trade-entry-table1-product-details [formGroup]="tradeForm"></cms-remit-trade-entry-table1-product-details>
    <cms-remit-trade-entry-table1-settlement [formGroup]="tradeForm"></cms-remit-trade-entry-table1-settlement>
    <cms-remit-trade-entry-table1-delivery-profile [formGroup]="tradeForm"></cms-remit-trade-entry-table1-delivery-profile>
  </div>
  <div class="component-box">
    <cms-remit-trade-entry-table1-reporting-counterparty [formGroup]="tradeForm"></cms-remit-trade-entry-table1-reporting-counterparty>
    <cms-remit-trade-entry-table1-other-counterparty [formGroup]="tradeForm"></cms-remit-trade-entry-table1-other-counterparty>
    <cms-remit-trade-entry-table1-commercials [formGroup]="tradeForm"></cms-remit-trade-entry-table1-commercials>
    <cms-remit-trade-entry-table1-option-detail [formGroup]="tradeForm"></cms-remit-trade-entry-table1-option-detail>
  </div>
  <div *ngIf="showTradeEntryForm" class="margin-15">
    <button id="remit-trade-entry-reset" type="button" class="btn margin-right-6" (click)="resetAndInit(true)">Reset</button>
    <button id="remit-trade-entry-reportTrade" type="submit" class="btn " (click)="submit()" [disabled]="!submitEnabled">Report Trade</button>
  </div>
  </form>
</div>

