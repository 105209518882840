import { Action } from '@ngrx/store';
import {
  EcmSettingsProcessDataResponse,
  ProcessUpdate
} from '../../../settings/ecm-settings-tab-panel/process/ecm-process.model';
import { Column } from '@common/shared/datatable.model';
import { Message } from 'primeng/api';

export const SET_TO_DEFAULT = '[ECM SETTINGS] Set to default';
export const SET_MESSAGES = '[ECM SETTINGS] Set Messages';
export const SET_COLUMNS = '[ECM SETTINGS] Set Columns';
export const SORT_AND_PAGE_DATA = '[ECM SETTINGS] Sort and Page Data';
export const LOAD_DATA = '[ECM SETTINGS] Load Data';
export const LOAD_DATA_SUCCESS = '[ECM SETTINGS] Load Data Success';
export const UPDATE_ECM_FEATURES = '[ECM SETTINGS] Update Ecm Features';

export class SetToDefaultAction implements Action {
  readonly type = SET_TO_DEFAULT;
}

export class SetMessagesAction implements Action {
  readonly type = SET_MESSAGES;
  constructor(public payload: Message[]) { }
}

export class SetColumnsAction implements Action {
  readonly type = SET_COLUMNS;
  constructor(public payload: Column[]) { }
}

export class LoadDataAction implements Action {
  readonly type = LOAD_DATA;
}

export class LoadDataSuccessAction implements Action {
  readonly type = LOAD_DATA_SUCCESS;
  constructor(public payload: EcmSettingsProcessDataResponse) { }
}

export class UpdateEcmFeatures implements Action {
  readonly type = UPDATE_ECM_FEATURES;
  constructor(public payload: ProcessUpdate) { }
}

export type EcmSettingsProcessActions = SetToDefaultAction | SetMessagesAction | LoadDataAction |
  LoadDataSuccessAction | SetColumnsAction | UpdateEcmFeatures;
