import { Message } from 'primeng/api';
import { Component, OnInit, OnChanges, SimpleChange, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { BillingRunParams } from '../billing-runs.model';
import { keyPressByRegex } from '../../../administration.model';

@Component({
  selector: 'cms-billing-params-form',
  templateUrl: './billing-params-form.component.html',
  styleUrls: ['./billing-params-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillingParamsFormComponent implements OnInit, OnChanges {
  @Input() formData: BillingRunParams;
  @Input() isDisabled: boolean = false;
  @Input() messages: Message[] = [];
  form: UntypedFormGroup;
  errors: { [key: string]: ValidationErrors } = {};
  onKeyPress = keyPressByRegex;
  readonly floatRegex: RegExp = /^[0-9]{0,10}([.][0-9]{0,2})?$/;
  readonly intRegex: RegExp = /^[0-9]{0,10}$/;

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({ ...this.formData });
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes.messages && this.messages) {
      this.errors = {};
      this.messages.forEach(({ key: controlName }: Message) => {
        this.errors[controlName] = { 'server': true };
      });
    }

    if (this.form && changes.formData) {
      this.form.patchValue({ ...this.formData });
      this.form.updateValueAndValidity();
    }
  }

  onPaste(event, regex: RegExp): boolean  {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    if (!(clipboardData && regex.test(clipboardData.getData('Text')))) {
      event.preventDefault();
      return false;
    }
  }

  getFormValues(): BillingRunParams {
    const obj = this.form.getRawValue();
    Object.keys(obj).forEach((key) => obj[key] = parseFloat(obj[key]));
    return obj;
  }
}
