import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Ecm_View_By_Values } from '../ecm-dashboard.model';

@Component({
  selector: 'cms-ecm-view-by-filter',
  templateUrl: './ecm-view-by-filter.component.html',
  styleUrls: ['./ecm-view-by-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EcmViewByFilterComponent implements OnInit, OnChanges, OnDestroy {
  @Input() value: Ecm_View_By_Values = 'TRADE_DATE';
  @Output() apply = new EventEmitter<Ecm_View_By_Values>();
  form: UntypedFormGroup;
  subscr$: Subscription;

  constructor (private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      viewBy: this.value,
    });

    this.subscr$ = this.form.valueChanges.subscribe((value: {viewBy: Ecm_View_By_Values}) => {
      this.apply.emit(value.viewBy);
    });
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (this.form && changes.value && this.value !== this.form.value.viewBy) {
      this.form.patchValue({
        viewBy: this.value
      });
      this.form.updateValueAndValidity();
    }
  }

  ngOnDestroy(): void {
    this.subscr$.unsubscribe();
  }
}
