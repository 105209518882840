import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { Message } from 'primeng/api';
import { EditBillingFormData, OrganisationGroup, VatCode } from '../organisation-groups.model';
import { keyPressByRegex } from '../../administration.model';

@Component({
  selector: 'cms-edit-billing-data-dialog',
  templateUrl: './edit-billing-data-dialog.component.html',
  styleUrls: ['./edit-billing-data-dialog.component.scss']
})
export class EditBillingDataDialogComponent implements OnInit, OnChanges {
  @Input() orgGroup: OrganisationGroup;
  @Input() data: EditBillingFormData;
  @Input() vatCodes: VatCode[];
  @Input() display = false;
  @Input() messages: Message[];
  @Output() displayChange = new EventEmitter<boolean>();
  @Output() confirm = new EventEmitter<{displayName: string, data: EditBillingFormData}>();
  @Output() cancel = new EventEmitter<Message[]>();
  vatCodeOptions: {label: string, value: any}[] = [{label: '', value: null}];
  errors: {[key: string]: ValidationErrors} = {};
  form: UntypedFormGroup;
  onKeyPress;
  readonly amountRegex = /^[0-9]{0,5}([.][0-9]{0,2})?$/;
  readonly vatRateRegex = /^[0-9]{0,2}([.][0-9]{0,2})?$/;

  selectedVatId;

  constructor(private formBuilder: UntypedFormBuilder,
              private mapper: MessagesMapperService) {
    this.onKeyPress = keyPressByRegex;
  }

  ngOnInit(): void {
    const {
      additionalNotes = null,
      address00 = null,
      address01 = null,
      address02 = null,
      address03 = null,
      address04 = null,
      debtorId = null,
      vatCode = null,
      vatNumber = null,
      vatRate = null,
      excludeFromBilling = null,
      invoiceRecipientOverride = null,
      organisationGroupID = null,
      additionalServices = [],
      contactEmails = [],
      errFreeTransactions = null,
      errOutstandingBalance = null,
      separateAcerBilling = null,
      acerInvoiceRecipientOverride = null,
      acerAddress00 = null,
      acerAddress01 = null,
      acerAddress02 = null,
      acerAddress03 = null,
      acerAddress04 = null,
      acerContactEmails = [],
      acerAdditionalNotes = null
    } = this.data || {};
    const { legalName } = this.orgGroup;

    this.form = this.formBuilder.group({
      legalName: { value: legalName, disabled: true,},
      additionalServices: this.formBuilder.array(additionalServices.map(item => this.formBuilder.group(item))),
      contactEmails: this.formBuilder.array(contactEmails.map(email => ({email}))),
      additionalNotes,
      address00,
      address01,
      address02,
      address03,
      address04,
      debtorId,
      vatCode,
      vatNumber,
      vatRate,
      excludeFromBilling,
      invoiceRecipientOverride,
      organisationGroupID,
      errFreeTransactions: { value: errFreeTransactions, disabled: true},
      errOutstandingBalance:  { value: errOutstandingBalance, disabled: true},
      separateAcerBilling,
      acerInvoiceRecipientOverride,
      acerAddress00,
      acerAddress01,
      acerAddress02,
      acerAddress03,
      acerAddress04,
      acerContactEmails: this.formBuilder.array(acerContactEmails.map(acerEmail => ({acerEmail}))),
      acerAdditionalNotes
    });
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    let vCode;
    if (this.form) {
      if (changes.display && !this.display) {
        this.hide();
      }
      if (changes.data) {
        const { legalName } = this.orgGroup;
        const {
          additionalNotes = null,
          address00 = null,
          address01 = null,
          address02 = null,
          address03 = null,
          address04 = null,
          debtorId = null,
          vatCode = null,
          vatNumber = null,
          vatRate = null,
          excludeFromBilling = null,
          invoiceRecipientOverride = null,
          organisationGroupID = null,
          additionalServices = [],
          contactEmails = [],
          errFreeTransactions = null,
          errOutstandingBalance = null,
          separateAcerBilling = null,
          acerInvoiceRecipientOverride = null,
          acerAddress00 = null,
          acerAddress01 = null,
          acerAddress02 = null,
          acerAddress03 = null,
          acerAddress04 = null,
          acerContactEmails = [],
          acerAdditionalNotes = null
        } = this.data || {};
        this.form.patchValue({
          legalName,
          additionalNotes,
          address00,
          address01,
          address02,
          address03,
          address04,
          debtorId,
          vatCode,
          vatNumber,
          vatRate,
          excludeFromBilling,
          invoiceRecipientOverride,
          organisationGroupID,
          additionalServices: [],
          contactEmails: [],
          errFreeTransactions,
          errOutstandingBalance,
          separateAcerBilling,
          acerInvoiceRecipientOverride,
          acerAddress00,
          acerAddress01,
          acerAddress02,
          acerAddress03,
          acerAddress04,
          acerContactEmails: [],
          acerAdditionalNotes
        });

        vCode = vatCode;
        this.initLists(contactEmails, additionalServices, acerContactEmails);
        this.form.updateValueAndValidity();
      }
    }

    if (changes.vatCodes) {
      this.vatCodeOptions = [
        { label: '', value: null },
        ...(this.vatCodes
          ? this.vatCodes.map(item => ({ label: `${item.key}: ${item.value}`, value: item.key }))
          : [])
      ];

        this.selectedVatId = this.vatCodeOptions.find(x => x.value === vCode);

    }

    if (changes.messages) {
      this.errors = {};
      if (this.messages) {
        this.messages.forEach((obj: Message) => {
          const { key: controlName } = obj;
          this.errors[controlName] = { 'server': true };
        });
      }
    }
  }

  onCancel(): void {
    this.hide();
    this.cancel.emit(
      this.mapper.toWarningMessages(
        `Editing billing data for organisation group '${this.orgGroup.displayName}' was cancelled.`
      ));
  }

  onAddEmail(): void {
    const emails = this.form.controls['contactEmails'] as UntypedFormArray;
    if (emails && emails.controls.length < 5) {
      emails.push(this.formBuilder.group({email: null}));
      this.form.updateValueAndValidity();
    }
  }

  onRemoveEmail(): void {
    const emails = this.form.controls['contactEmails'] as UntypedFormArray;
    if (emails && emails.controls.length > 1) {
      emails.removeAt(emails.controls.length - 1);
      this.form.updateValueAndValidity();
    }
  }

  onAddAcerEmail(): void {
    const acerEmails = this.form.controls['acerContactEmails'] as UntypedFormArray;
    if (acerEmails && acerEmails.controls.length < 5) {
      acerEmails.push(this.formBuilder.group({acerEmail: null}));
      this.form.updateValueAndValidity();
    }
  }

  onRemoveAcerEmail(): void {
    const acerEmails = this.form.controls['acerContactEmails'] as UntypedFormArray;
    if (acerEmails && acerEmails.controls.length > 1) {
      acerEmails.removeAt(acerEmails.controls.length - 1);
      this.form.updateValueAndValidity();
    }
  }

  onAddService(): void {
    const additionalServices = this.form.controls['additionalServices'] as UntypedFormArray;
    if (additionalServices && additionalServices.controls.length < 10) {
      additionalServices.push(this.formBuilder.group({
        amount: null,
        description: null
      }));
      this.form.updateValueAndValidity();
    }
  }

  onRemoveService(): void {
    const additionalServices = this.form.controls['additionalServices'] as UntypedFormArray;
    if (additionalServices && additionalServices.controls.length > 0) {
      additionalServices.removeAt(additionalServices.controls.length - 1);
      this.form.updateValueAndValidity();
    }
  }

  onVisibleChange(visible: boolean): void {
    if (!visible) {
      this.onCancel();
    }
  }

  onConfirm(): void {
    const contactEmails = this.form.value['contactEmails'].map(item => item.email);
    const { displayName } = this.orgGroup;
    const { organisationGroupID } = this.form.value;
    const { organisationGroupId } = this.orgGroup;
    const data = Object.entries(this.form.value).reduce((obj, [key, value]) => {
      obj[key] = value != null? value: null;
      return obj;
    }, {}) as EditBillingFormData;
    const vatCode = (data.vatCode as any).value;
    const acerContactEmails = this.form.value['acerContactEmails'].map(item => item.acerEmail);
    this.confirm.emit({
      data: {
        ...data,
        vatCode,
        contactEmails,
        organisationGroupID: organisationGroupID || organisationGroupId || null,
        acerContactEmails
      },
      displayName,
    });
  }

  onPaste(event, regex: RegExp): boolean {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    if (!(clipboardData && regex.test(clipboardData.getData('Text')))) {
      event.preventDefault();
      return false;
    }
  }

  private hide(): void {
    this.messages = [];
    this.display = false;
    this.displayChange.emit(this.display);
    this.form.reset();
    this.form.updateValueAndValidity();
  }

  private initLists(contactEmails: string[], additionalServices, acerContactEmails: string[]): void {
    const contactEmailsForm = this.form.controls['contactEmails'] as UntypedFormArray;
    if (contactEmails && contactEmails.length) {
      contactEmails.forEach(email => {
        contactEmailsForm.push(this.formBuilder.group({email}));
      });
    } else {
      contactEmailsForm.push(this.formBuilder.group({email: ''}));
    }

    const additionalServicesForm = this.form.controls['additionalServices'] as UntypedFormArray;
    additionalServices.forEach(item => {
      additionalServicesForm.push(this.formBuilder.group(item));
    });

    const acerContactEmailsForm = this.form.controls['acerContactEmails'] as UntypedFormArray;
    if (acerContactEmails && acerContactEmails.length) {
      acerContactEmails.forEach(acerEmail => {
        acerContactEmailsForm.push(this.formBuilder.group({acerEmail}));
      });
    } else {
      acerContactEmailsForm.push(this.formBuilder.group({acerEmail: ''}));
    }
  }
}
