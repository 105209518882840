import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { Message } from 'primeng/api';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { EditableState } from '../../editable-status-cell/editable-status-cell.component';

export interface ReactivateUser {
  comment: string;
  currentState?: string;
  newPassword: string;
  newPasswordRepeat?: string;
  userIdForStateChange: number | string;
}

@Component({
  selector: 'cms-activate-dialog',
  templateUrl: './activate-dialog.component.html',
  styleUrls: ['./activate-dialog.component.scss']
})
export class ActivateDialogComponent implements OnInit, OnChanges {
  @Input() data: EditableState;
  @Input() display = false;
  @Input() messages: Message[];
  @Output() displayChange = new EventEmitter<boolean>();
  @Output() cancel = new EventEmitter<Message[]>();
  @Output() reactivate = new EventEmitter<ReactivateUser>();
  errors: {[key: string]: ValidationErrors} = {};
  form: UntypedFormGroup;
  header: string;

  constructor(private formBuilder: UntypedFormBuilder,
              private mapper: MessagesMapperService) {
  }

  onCancel(): void {
    this.hide();
    this.cancel.emit(this.mapper.toWarningMessages(this.data.data.state === 'LOCKED' ?
      `Unlocking of account '${this.data.data.userName}' was cancelled.` :
      `Reactivation of account '${this.data.data.userName}' was cancelled.`));
  }

  onVisibleChange(visible: boolean): void {
    if (!visible) {
      this.onCancel();
    }
  }

  onConfirm(): void {
    this.messages = [];
    const { comment, newPassword, newPasswordRepeat } = this.form.value;
    const out: ReactivateUser =  {
      comment,
      currentState: this.data.data.state,
      userIdForStateChange: this.data.data.id,
      newPassword,
      newPasswordRepeat
    };

    this.reactivate.emit(out);
  }

  ngOnInit(): void {
    const currentUserState = this.data.data.state;
    switch (currentUserState) {
      case 'LOCKED':
        this.header = 'Unlock User Account';
        break;
      case 'INACTIVE':
        this.header = 'Reactivate User Account';
        break;
    }
    this.form = this.formBuilder.group({
      comment: '',
      newPassword: '',
      newPasswordRepeat: '',
    });
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (this.form && changes.data) {
      this.form.updateValueAndValidity();
    }

    if (changes.messages) {
      this.errors = {};
      if (this.messages) {
        this.messages.forEach((obj: Message) => {
          const { key: controlName } = obj;
          this.errors[controlName] = { 'server': true };
        });
      }
    }
  }

  private hide(): void {
    this.messages = [];
    this.display = false;
    this.displayChange.emit(this.display);
    this.form.reset();
    this.form.updateValueAndValidity();
  }
}
