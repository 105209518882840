import { createSelector } from '@ngrx/store';
import { getErrState, State } from '../reducers';
import * as fromReducer from './external-rrm.reducers';

export const getExternalRrmState = createSelector(getErrState, (state: State) => state.externalRrm);
export const getOrganisationId = createSelector(getExternalRrmState, fromReducer.getOrganisationId);
export const getSorting = createSelector(getExternalRrmState, fromReducer.getSorting);
export const getPaging = createSelector(getExternalRrmState, fromReducer.getPaging);
export const getLoading = createSelector(getExternalRrmState, fromReducer.getLoading);
export const getMessages = createSelector(getExternalRrmState, fromReducer.getMessages);
export const getData = createSelector(getExternalRrmState, fromReducer.getData);
export const getFilter = createSelector(getExternalRrmState, fromReducer.getFilter);
export const getFormData = createSelector(getExternalRrmState, fromReducer.getFormData);
export const getStatus = createSelector(getExternalRrmState, fromReducer.getStatus);

