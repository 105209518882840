import { ErrorResponse } from '@common/error-response.model';
import { Column } from '../../../common/shared/datatable.model';
import { DatatableData } from '../../../common/shared/results.model';
import { EditableCell } from '../../../common/datatable/cell-editor/cell-editor.model';

export interface ProcessRoleFilterEntry {
  displayName: string;
  name: string;
}

export interface OrgGroupFilterEntry {
  displayName: string;
  organisationGroupId: number;
}

export interface UserFilterEntry {
  displayName: string;
  userName: string;
}

export interface UserRoleFilter<T> {
  filterEntries: T[];
  visible: boolean;
}

export interface UserRolesFilterResponse extends ErrorResponse {
  organisationGroupFilter?: UserRoleFilter<OrgGroupFilterEntry>;
  processRoleFilter?: UserRoleFilter<ProcessRoleFilterEntry>;
  userFilter?: UserRoleFilter<UserFilterEntry>;
}

export interface UserRolesFilters {
  selectedOrganisationGroupId?: number;
  selectedProcessRole?: ProcessRoles;
  selectedUsername?: string;
}

export interface MatrixResponse extends ErrorResponse {
  userRolesMatrix: UserRolesMatrix;
}

export interface OrganisationLabel {
  displayName: string;
  organisationId: number;
}

export interface UserRoleDatatableItem {
  userAdmin: boolean;
  userName: string;
  [key: string]: MatrixRole | string | boolean;
}

export interface UserRolesMatrixRow {
  userAdmin: boolean;
  userName: string;
  userOrganisationRoles: UserRolesMatrixRowItem;
}

export interface UserRolesMatrixRowItem {
  [key: string]: MatrixRole | string | boolean;
}

export interface MatrixRole {
  masterOperator: boolean;
  operator: boolean;
}

export interface UserRolesMatrix {
  organisationGroupName: string;
  organisationLabelsRow: OrganisationLabel[];
  userRolesMatrixRows: UserRolesMatrixRow[];
}

export interface FilterDataSuccess {
  columns: Column[];
  data: DatatableData<any[]>;
}

export interface UpdateMatrixRequest {
  selectedOrganisationGroupId: number;
  selectedProcessRole: string;
  userRolesMatrixRows: UserRolesMatrixRow[];
}

export interface UserRolesEditableData {
  [key: number]: {
    [key: string] : EditableCell;
  };
}

export enum ProcessRoles {
  ERR = 'ERR',
  ECM = 'ECM',
}
