import { Column } from '@common/shared/datatable.model';
import { ErrorResponse } from '@common/error-response.model';
import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import { Message } from 'primeng/api';
export interface EsmMessagesModel {
  masterData?: Message[];
  bankDetails?: Message[];
}
export interface EsmLoadVatIdsResponse extends ErrorResponse {
  timestamp: string;
  values: EsmVatIds[];
}

export interface EsmVatIds {
  masterDataId: number;
  vatId: string;
}

export interface EsmLoadBankDetailsRequest {
  masterDataId: number;
  paging: PagingFilter;
  sorting: SortingFilter;
}

export interface EsmLoadFormValuesResponse extends ErrorResponse {
  timestamp: string;
  value: EsmFormValues;
  version: number;
}

export interface EsmAddressDetails {
  city: string;
  country: string;
  postalCode: string;
  street: string;
  streetNumber: string;
}

export interface EsmCompanyRegistry {
  branch: string;
  city: string;
  country: string;
  registryName: string;
  registryNumber: string;
}

export interface EsmContactDetails {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
}

export interface EsmVatDetails {
  legalName: string;
  vatId: string;
  vatJurisdictionCurrency: string;
  vatRepresentative: string;
}

export interface EsmFormValues {
  addressDetails: EsmAddressDetails;
  otherAddressDetails: EsmAddressDetails;
  companyRegistry: EsmCompanyRegistry;
  contactDetails: EsmContactDetails;
  vatDetails: EsmVatDetails;
  version: number;
  masterDataId: number;
  headOfficeVatId: string;
  enableForceOfAttraction: boolean;
  headOfficeCompany: string;
  headOfficeAddress1: string;
  headOfficeAddress2: string;
}
export interface EsmExternalFormValues {
  addressDetails: EsmAddressDetails;
  otherAddressDetails: EsmAddressDetails;
  companyRegistry: EsmCompanyRegistry;
  contactDetails: EsmContactDetails;
  vatDetails: EsmVatDetails;
  version: number;
  masterDataId: number;
  eicCode: string;
  headOfficeVatId: string;
  enableForceOfAttraction: boolean;
  headOfficeCompany: string;
  headOfficeAddress1: string;
  headOfficeAddress2: string;
}
export interface AddBankDetailValue {
  bankAcctHolder: string;
  bic: string;
  currency: string;
  iban: string;
}

export interface LoadBankDetailsResponse extends ErrorResponse {
  count: number;
  currentPage: number;
  timestamp: string;
  values: EsmBankDetails[];
}

export interface UpdateBankdetailRequest {
  newValue: string;
  bankDetailsId: string;
  name: string;
  version: number;
}

export interface EsmBankDetails {
  accountHolder: string;
  bankDetailsId: number;
  bic: string;
  currency: string;
  iban: string;
  masterDataId: number;
  version: number;
}

const defaultStyles = {
  'text-align': 'left',
  'text-overflow': 'ellipsis',
  'overflow': 'hidden',
  'white-space': 'nowrap',
  'height': '27px'
};

export const dataTableColumns: Column[] = [
  {name: 'currency', type: 'editable', title: 'Curr.', style: {...defaultStyles}, sortable: true, visible: true, maxLength: 30},
  {name: 'iban', type: 'editable', title: 'IBAN', style: {...defaultStyles}, sortable: true, visible: true, maxLength: 30},
  {name: 'bic', type: 'editable', title: 'BIC', style: {...defaultStyles}, sortable: true, visible: true, maxLength: 30},
  {
    name: 'accountHolder',
    type: 'editable',
    title: 'Account Holder',
    style: {...defaultStyles},
    sortable: true,
    visible: true,
    maxLength: 255
  },
  {
    name: 'delete',
    type: 'deleteButton',
    title: 'Delete',
    style: {...defaultStyles, 'text-align': 'center'},
    sortable: false,
    visible: true
  }
];

export const initialFormValues = {
  vatDetails: {
    vatId: '',
    legalName: '',
    vatJurisdictionCurrency: '',
    vatRepresentative: ''
  },
  addressDetails: {
    city: '',
    country: '',
    postalCode: '',
    street: '',
    streetNumber: '',
  },
  otherAddressDetails: {
    city: '',
    country: '',
    postalCode: '',
    street: '',
    streetNumber: '',
  },
  contactDetails: {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
  },
  companyRegistry: {
    branch: '',
    city: '',
    country: '',
    registryName: '',
    registryNumber: '',
  },
  masterDataId: null,
  version: null,
  eicCode: null,
  headOfficeVatId: null,
  enableForceOfAttraction: null,
  headOfficeCompany: null,
  headOfficeAddress1: null,
  headOfficeAddress2: null
};

export const initialBankValue = {bankAcctHolder: '', bic: '', currency: '', iban: ''};

export const TitleMap = {
  'internal': 'eSM Internal Master Data',
  'external': 'eSM External Master Data'
};
