import { Component, Input } from '@angular/core';

@Component({
  selector: 'cms-cpml-dashboard-panel',
  templateUrl: './cpml-dashboard-panel.component.html',
  styleUrls: ['./cpml-dashboard-panel.component.scss'],
})
export class CpmlDashboardPanelComponent {
  @Input() category: string;
}
