import { Injectable } from '@angular/core';
import { ApiRequestService } from '@common/api-request.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { catchError, combineLatest, concatMap,  shareReplay, switchMap } from 'rxjs/operators';
import { Message } from 'primeng/api';
import * as RemitSelectors from './remit.selectors';
import { saveBlob } from '@common/cms-common.model';
import { HttpParams } from '@angular/common/http';
import { ErrTerminationState } from './remit.reducer';
import { PresetValues } from '../../remit/termination/err.termination.model';
import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import { Observable, of } from 'rxjs';
import * as RemitActions from './remit.actions';
import * as moment from 'moment';

@Injectable()
export class ErrTerminationEffects {
  private baseUrl = '/api/err/remit/early_termination';

  constructor(private apiGateway: ApiRequestService,
              private actions$: Actions,
              private store$: Store<ErrTerminationState>,
              private mapper: MessagesMapperService) {
  }


  // ====== table effects ======

  load_presets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RemitActions.loadPresets),
      switchMap(() =>
        this.apiGateway.get(`${this.baseUrl}/presets`)
          .pipe(
            switchMap(response => {
              const messages: Message[] = this.mapper.toErrorMessages(response);

              const presets: PresetValues = {
                allowedToUpload: response.value?.uploadEnabled,
                maxUploadFileSizeInBytes: response.value?.maxUploadFileSizeInBytes,
                organisationNames: response.value?.organisationNames
              };

              return messages?.length ?
                [RemitActions.setMessages({ messages })] :
                [RemitActions.resetMessages(), RemitActions.loadPresetsSuccess({ presets })];
            }),

            catchError(error => of(RemitActions.setMessages(this.mapper.toErrorMessagesObj(error.errorMessage || 'Server Error')))
            ))
      ))
  );


  load_table$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RemitActions.loadTable),
      shareReplay(),
      combineLatest(this.store$.pipe(select(RemitSelectors.getPaging)), this.store$.pipe(select(RemitSelectors.getSorting))),
      switchMap(([, paging, sorting]) => this.loadData(paging, sorting)))
  );

  paging_sorting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RemitActions.pagingAndSorting),
      concatMap(({ paging, sorting }) => this.loadData(paging, sorting)))
  );

  // =======  upload panel effects ======

  upload_CSV$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RemitActions.uploadCsv),
      switchMap(({ file }) => {
        this.store$.dispatch(RemitActions.loading({ load: { upload: true } }));
        const formData = new FormData();
        formData.append('file', file);

        return this.apiGateway.post(`${this.baseUrl}/upload`, formData, null)
          .pipe(
            concatMap(response => {
              let mappedData;
              const messages: Message[] = this.mapper.toErrorMessages(response);

              if (messages == null || messages?.length == 0) {
                mappedData = metaData(response);
              }

              return messages?.length ?
                [RemitActions.setMessages({ messages }),
                  RemitActions.loading({ load: { upload: false } })] :
                [RemitActions.loading({ load: { upload: false } }),
                  RemitActions.resetFilInput(),
                  RemitActions.uploadCsvSuccess({ metaData: mappedData }),
                  RemitActions.resetMessages()];
            }),

            catchError(error => [RemitActions.loading({ load: { upload: false } }),
              RemitActions.setMessages(this.mapper.toErrorMessagesObj(error.errorMessage || 'Server Error'))]));
      }))
  );


  // =======  table cell effects ======

  load_list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RemitActions.loadModalList),
      switchMap(({ id, column }) =>
        this.apiGateway.get(`${this.baseUrl}/list/${endPoint(column)}`, new HttpParams().set('id', `${id}`))
          .pipe(
            switchMap((response: any) => {
              const messages = this.mapper.toErrorMessages(response);
              const utis = response?.value?.utis;
              const list = { id, column, values: utis };

              return messages?.length ?
                [RemitActions.setMessages({ messages })] :
                [ RemitActions.resetMessages(),
                  RemitActions.loading({ load: { dropDown: false } }),
                  RemitActions.loadModalListSuccess({ list })];
            }))),
      catchError(error => of(RemitActions.setMessages(this.mapper.toErrorMessagesObj(error.errorMessage || 'Server Error'))))
    ));


  download_ZIP$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(RemitActions.downloadZip),
        switchMap(({ id }) => {
          let params = new HttpParams();
          params = params.set('uploadId', `${id}`);

          return this.apiGateway.getBlob(`${this.baseUrl}/download_zip`, null, params)
            .pipe(
              switchMap((response: any) => {
                saveBlob(response);
                return of(RemitActions.resetMessages());
              }),

              catchError(error => of(RemitActions.setMessages(this.mapper.toErrorMessagesObj(error.errorMessage || 'Server error'))))
            );
        })
      )
  );


  // ====== modal dialog effects ======

  execute$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(RemitActions.executeCsvUpload),
        concatMap(({ id, terminationDate, transactionDate, transactionTime }) => {

          const payload = {
            'technicalId': `${id}`,
            'terminationDate': moment(terminationDate).format('YYYY-MM-DD'),
            'transactionDate': moment(transactionDate).format('YYYY-MM-DD'),
            'transactionTime': moment(transactionTime).format('YYYY-MM-DDTHH:mm:ss')
          };

          return this.apiGateway.post(`${this.baseUrl}/create_records`, payload)
            .pipe(
              switchMap(() => [RemitActions.setMessages({ messages: this.mapper.toSuccessMessages('successfully canceled termination file upload') }), RemitActions.cleanCsvMeta()]),
              catchError(error => [RemitActions.cleanCsvMeta(), RemitActions.setMessages(this.mapper.toErrorMessagesObj(error.errorMessage || 'Server error'))])
            );
        })
      )
  );

  abort$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RemitActions.abortCsvUpload),
      concatMap(({id}) => {
        let params = new HttpParams();
        params = params.set('id', `${id}`);
        return this.apiGateway.post(`${this.baseUrl}/cancel`, params)
          .pipe(
            concatMap(response => {
              const errors = this.mapper.toErrorMessages(response);
              const messages: Message[] = errors?.length != 0 ? errors : this.mapper.toSuccessMessages('successfully canceled termination file upload');

              return [RemitActions.setMessages({ messages }), RemitActions.cleanCsvMeta()];
            }),

            catchError(error => of(RemitActions.setMessages(this.mapper.toErrorMessagesObj(error.errorMessage || 'Server Error')))
            ));
      }))
  );


  // ======  helper ======

  private loadData(paging: PagingFilter, sorting: SortingFilter): Observable<Action> {
    this.store$.dispatch(RemitActions.loading({ load: { table: true } }));
    return this.apiGateway.post(`${this.baseUrl}/data`, { sorting, paging })
      .pipe(
        switchMap(response => {
          const messages: Message[] = this.mapper.toErrorMessages(response);
          const { values, currentPage, count } = response;

          return messages?.length ?
            [RemitActions.setMessages({ messages })]:
            [RemitActions.querySuccess({
              data: { values, count, currentPage },
              paging: paging,
              sorting: sorting }),
              RemitActions.loading({ load: { table: false }})];
        }),

        catchError(error => [RemitActions.loading({ load: { table: false } }),
          RemitActions.setMessages(this.mapper.toErrorMessagesObj(error.errorMessage || 'Server Error'))]));
  }
}

const endPoint = name => {
  switch (name) {
    case 'duplicateCount':
      return END_POINTS.duplicates;
    case 'notFoundCount':
      return END_POINTS.not_found;
    case 'withoutPermissionCount':
      return END_POINTS.no_permission;
    case 'issueCount':
      return END_POINTS.issues;
    default:
      throw new Error('remit early termination path not found');
  }
};

const metaData = ({ value }) => ({
  identifier: value.id,
  countFound: value.foundCount,
  countDuplicate: value.duplicateCount,
  countNotFound: value.notFoundCount,
  countNoPermission: value.withoutPermissionCount,
  countIssue: value.issueCount,
  notFound: value.notFoundUtis,
  noPermission: value.withoutPermissionUtis,
  duplicates: value.duplicateUtis,
  issues: value.issueUtis
});

enum END_POINTS {
  duplicates ='duplicates',
  no_permission='no_permission',
  not_found='not_found',
  issues = 'issues',
}

