import { Injectable } from '@angular/core';
import { ApiRequestService } from '../../../../common/api-request.service';
import { HttpParams } from '@angular/common/http';
import { TransactionSheetResponseModel } from '../../../../common/sheet/transaction-sheet-model';
import { Observable } from 'rxjs';
import { EndPointConstants } from '../../../../common/end-point-constants';
import { map } from 'rxjs/operators';

@Injectable()
export class RemitTable2TradeService {

  constructor(private apiRequestService: ApiRequestService) {
  }

  public getTransactionSheet(regime: string, recordType: string, documentType: string, historyId: number): Observable<TransactionSheetResponseModel> {
    let params = new HttpParams();
    params = params.append(EndPointConstants.PARAM_REGIME, regime);
    if (recordType) {
      params = params.append(EndPointConstants.PARAM_RECORD_TYPE, recordType);
    }
    params = params.append(EndPointConstants.PARAM_DOCUMENT_TYPE, documentType);
    params = params.append(EndPointConstants.PARAM_HISTORY_ID, String(historyId));

    return this.apiRequestService.get('/api/err/remit/transactions/sheet', params);
  }

  public loadPresetsCancellation(historyId: number): Observable<any> {
    let params = new HttpParams();
    if (historyId) {
      params = params.append(EndPointConstants.PARAM_HISTORY_ID, historyId.toString());
    }

    return this.apiRequestService.get('/api/err/remit/lite/remitTable2/loadPresetsCancellation', params)
      .pipe(map(data => {
        return data;
      }));
  }

  public loadPresetsDeletion(historyId: number): Observable<any> {
    let params = new HttpParams();
    if (historyId) {
      params = params.append('historyId', historyId.toString());
    }

    return this.apiRequestService.get('/api/err/remit/lite/remitTable2/loadPresetsDeletion', params)
      .pipe(map(data => {
        return data;
      }));
  }

  public submitCancellation(historyId: number, terminationDate: string): Observable<any> {
    const params = new HttpParams() //
      .append(EndPointConstants.PARAM_HISTORY_ID, historyId.toString())
      .append('terminationDate', terminationDate);

    return this.apiRequestService.post('/api/err/remit/lite/remitTable2/cancellation', null, params)
      .pipe(map(data => {
        return data;
      }));
  }

  public submitError(historyId: number): Observable<any> {
    const params = new HttpParams() //
      .append('historyId', historyId.toString());

    return this.apiRequestService.post('/api/err/remit/lite/remitTable2/deletion', null, params)
      .pipe(map(data => {
        return data;
      }));
  }

}
