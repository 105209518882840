<div class="panel-box margin-left-50 margin-right-50 margin-top-50">
  <h1>Modify Trade</h1>
  <div class="panel-box">
    <div id="divRemitTradeModifyFeedback">
      <p-messages *ngIf="showModificationValues" [enableService]="false" [value]="acerV3DisabledMessage" [closable]="false" class="col-12"></p-messages>
      <p-messages [(value)]="messages" [closable]="false"></p-messages>
    </div>
    <ng-container *ngIf="!internalError && showModificationValues">
      <div class="relative margin-center" style="max-width: 1200px;">
        <div class="component-box">
          <cms-remit-trade-entry-table1-modify-references [formGroup]="modifyForm"></cms-remit-trade-entry-table1-modify-references>
          <cms-remit-trade-entry-table1-modify-commercials [formGroup]="modifyForm"></cms-remit-trade-entry-table1-modify-commercials>
          <cms-remit-trade-entry-table1-modify-fixing-index [formGroup]="modifyForm"></cms-remit-trade-entry-table1-modify-fixing-index>
          <cms-remit-trade-entry-table1-modify-settlement [formGroup]="modifyForm"></cms-remit-trade-entry-table1-modify-settlement>
        </div>
        <div class="component-box">
          <cms-remit-trade-entry-table1-modify-contractdelivery [formGroup]="modifyForm"></cms-remit-trade-entry-table1-modify-contractdelivery>
          <cms-remit-trade-entry-table1-modify-contractdeliveryprofile [formGroup]="modifyForm"></cms-remit-trade-entry-table1-modify-contractdeliveryprofile>
        </div>
      </div>

      <div class="col-12 text-center">
        <button id="btnRemitTradeCancelSubmit" type="button" (click)="submit()" class="btn margin-3 padding-6" [disabled]="isDisabled()">
          Modify Trade
          <p-progressSpinner class="margin-top-9" *ngIf="isInProgress()" [style]="{width: '15px', height: '15px'}" strokeWidth="8"></p-progressSpinner>
        </button>
      </div>

    </ng-container>
  </div>
</div>
