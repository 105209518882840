import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, switchMap, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { Message } from 'primeng/api';
import { ApiRequestService } from '@common/api-request.service';
import { MessagesMapperService } from '@common/messages-mapper.service';
import * as fromActions from './broker-restrictions.actions';
import { UploadAction } from './broker-restrictions.actions';
import { ErrorResponse } from '@common/error-response.model';
import { PresetValueResponse } from '../../../settings/ecm-settings-tab-panel/broker-restrictions/broker-restrictions.model';
import { saveBlob } from '@common/cms-common.model';

@Injectable()
export class EcmSettingsBrokerRestrictionsEffects {
  private baseUrl = 'api/ecm/settings/broker/restrictions';

  constructor(private actions$: Actions,
              private apiGateway: ApiRequestService,
              private mapper: MessagesMapperService) { }

   uploadFileSuccess$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.UPLOAD_FILE_SUCCESS),
      switchMap(() =>
        this.actions$.pipe(
          ofType(fromActions.LOAD_DATA_SUCCESS),
          switchMap(() => of(new fromActions.SetMessagesAction(this.mapper.toSuccessMessages('File uploaded.')))),
          take(1))
      )));

   loadData$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.LOAD_DATA),
      concatMap(() =>
        this.apiGateway.get(`${this.baseUrl}/data`, null)
          .pipe(switchMap((response: any) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [new fromActions.SetMessagesAction(messages)];
              }
              return [
                new fromActions.SetMessagesAction([]),
                new fromActions.LoadDataSuccessAction(response)
              ];
            }),
            catchError(() => of(new fromActions.SetMessagesAction([
              this.mapper.createErrorMessage('Server error')
            ])))
          )
      )
    ));

   downloadXml$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.DOWNLOAD_XML),
      switchMap(() =>
        this.apiGateway.getBlob(`${this.baseUrl}/download`, null)
          .pipe(switchMap((response: any) => {
              saveBlob(response);
              return of(new fromActions.SetMessagesAction([]));
            }),
            catchError(() => of(new fromActions.SetMessagesAction([
              this.mapper.createErrorMessage('Server error')
            ]))))
      )));

   upload$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.UPLOAD_FILE),
      concatMap((action: UploadAction) => {
        const formData = new FormData();
        formData.append('file', action.payload);

        return this.apiGateway.post(`${this.baseUrl}/upload`, formData, null)
          .pipe(
            concatMap((response: ErrorResponse | null) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return of(new fromActions.SetMessagesAction(messages));
              }
              return [
                new fromActions.SetMessagesAction([]),
                new fromActions.UploadFileSuccess(),
                new fromActions.LoadDataAction(),
              ];
            }),
            catchError(error => of(new fromActions.SetMessagesAction([
              this.mapper.createErrorMessage('Server Error')
            ])))
          );
      })));

   loadPresetValues$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.LOAD_PRESET_VALUES),
      switchMap(() =>
        this.apiGateway
          .get(`${this.baseUrl}/loadPresetValues`)
          .pipe(map((response: PresetValueResponse) => {
            const messages: Message[] = this.mapper.toErrorMessages(response);
            if (messages && messages.length) {
              return new fromActions.SetMessagesAction(messages);
            }
            return new fromActions.LoadPresetValuesSuccessAction(response);
          }))
      )));
}
