import {Component, OnDestroy, OnInit} from '@angular/core';
import {LocationStrategy} from '@angular/common';
import {NavigationEnd, Router} from '@angular/router';
import {filter, takeUntil} from 'rxjs/operators';
import {RemitTransactionsFilterService} from './filter/remit-transactions-filter.service';
import {RemitTransactionsService} from '../datatable/remit-transactions.service';
import {Observable, Subject} from 'rxjs';
import {Message} from 'primeng/api';
import {ErrorMessageService} from '@common/errors/error-message.service';

@Component({
  selector: 'cms-remit-transactions',
  templateUrl: './remit-transactions.component.html',
  styleUrls: ['./remit-transactions.component.scss']
})
export class RemitTransactionsComponent implements OnInit, OnDestroy {
  selectedTabDocType: string;

  errorMessages$: Observable<Message[]>;
  private onDestroy$ = new Subject();
  constructor(private remitTransactionsFilterService: RemitTransactionsFilterService,
              private remitTransactionsService: RemitTransactionsService,
              private locationStrategy: LocationStrategy,
              private router: Router,
              public readonly errorMessageService: ErrorMessageService) { }

  ngOnInit(): void {
    this.router.events
      .pipe( takeUntil(this.onDestroy$),filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        const remitTransactionsSearchQuery = this.remitTransactionsService.getCachedSearchQuery(this.selectedTabDocType);
        if (remitTransactionsSearchQuery != null) {
          this.remitTransactionsService.emitNewSearchQuery(remitTransactionsSearchQuery);
        }
      });

    this.errorMessages$ = this.remitTransactionsService.onSearchValidationMessages();
  }

  setInitialTabDocType(docType: string): void{
    this.selectedTabDocType = docType;
  }

  resetTableData(docType: string): void {
    this.remitTransactionsService.resetData();
    this.remitTransactionsService.setCachedSearchQuery(this.selectedTabDocType, null);
    this.selectedTabDocType = docType;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();

    this.remitTransactionsService.setValidationMessages([]);
  }
}
