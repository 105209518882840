import { createSelector } from '@ngrx/store';
import { CounterPartyState } from './counterparty.reducers';
import { NettingPreviewState } from '../index';
import { getEsmState, State } from '../../reducers';


export const getNettingPreviewState = createSelector(getEsmState, (state: State) => state.nettingPreview);

export const getCounterpartiesState = createSelector(getNettingPreviewState, (state: NettingPreviewState) => state.counterparties);
export const getCounterparties = createSelector(getCounterpartiesState, (preview: CounterPartyState) => preview.counterparties);
export const getSelected = createSelector(getCounterpartiesState, (preview: CounterPartyState) => preview.selectedCounterPartyOrgIds);
export const getLoading = createSelector(getCounterpartiesState, (preview: CounterPartyState) => preview.loading);

