import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cms-esm-near-match-button',
  templateUrl: './esm-near-match-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EsmNearMatchButtonComponent implements OnInit{
  @Input() row: { nearMatchButtonVisible: boolean, id?: number};
  @Input() route: string;
  title: string;
  queryParams;

  ngOnInit(): void {
    if (typeof this.route !== 'undefined') {
      if (this.route.includes('netting')) {
        this.title = 'eSM Netting Near Match';
        this.queryParams = {referenceNettingStatementId: this.row.id};
      } else {
        this.title = 'eSM Invoice Near Match';
        this.queryParams = {referenceInvoiceId: this.row.id};
      }
    }
  }

}
