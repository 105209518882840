<p-dialog *ngIf="display"
          header="{{'Unhide Confirmation' + (data.tradeId ?  ' (' + data.tradeId + ')' : '')}}"
          [(visible)]="display"
          [modal]="true"
          (visibleChange)="onVisibleChange($event)"
          [style]="{'min-width': '600px'}">
  <p-messages [value]="messages" [closable]="true"></p-messages>
  <div class="info-panel">
    <span class="fa fa-info-circle padding-right-6"></span>
    Confirmation will not be suppressed by the "hidden" filter in the eCM Dashboard/Deal Finder anymore.
  </div>
  <p-footer>
    <button class="btn margin-right-3" type="button" (click)="onCancel()">Cancel</button>
    <button class="btn" type="button" (click)="onSave()">Unhide</button>
  </p-footer>
</p-dialog>
