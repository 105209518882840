import { createAction, union } from '@ngrx/store';
import {
  EsmNettingDashboardPresetFilters,
  EsmNettingDashboardPresetFiltersValues,
  EsmNettingRoleByType
} from '../../../../netting/dashboard/esm-netting-dashboard.model';

export const loadPresetValuesAction = createAction('[ESM NETTING DASHBOARD] Load Preset Values');
export const loadPresetValuesSuccessAction = createAction('[ESM NETTING DASHBOARD] Load Preset Values Success', (payload: EsmNettingDashboardPresetFiltersValues) => ({ payload }));
export const setRoleByFilterValueAction = createAction('[ESM NETTING DASHBOARD] Set Role By Filter Action', (payload: EsmNettingRoleByType) => ({ payload }));
export const setFilterValuesAction = createAction('[ESM NETTING DASHBOARD] Set Filter Values Action', (payload: EsmNettingDashboardPresetFilters) => ({ payload }));
export const setDateRangeAction = createAction('[ESM NETTING DASHBOARD] Set Date Range Action', (payload: string) => ({ payload }));
export const setCategoryAction = createAction('[ESM NETTING DASHBOARD] Set Category Action', (payload: string) => ({ payload }));
export const filterAction = createAction('[ESM NETTING DASHBOARD] Filter');
export const filterSuccessAction = createAction('[ESM NETTING DASHBOARD] Filter Success', (payload: any) => ({ payload }));
export const refreshFiltersAction = createAction('[ESM NETTING DASHBOARD] Refresh Filters');
export const resetFiltersAction = createAction('[ESM NETTING DASHBOARD] Reset Filters');

const actions = union({
  loadPresetValuesAction,
  loadPresetValuesSuccessAction,
  setRoleByFilterValueAction,
  setFilterValuesAction,
  setCategoryAction,
  filterAction,
  filterSuccessAction,
  refreshFiltersAction,
  resetFiltersAction
});

export type EsmNettingDashboardFiltersAction = typeof actions;
