import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { Message } from 'primeng/api';
import { ActivatedRoute, Params } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { RemitTable2TradeService } from '../remit-table2-trade.service';
import { EndPointConstants } from '../../../../../common/end-point-constants';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cms-remit-table2-trade-error',
  templateUrl: './remit-table2-trade-error.component.html',
  styleUrls: ['./remit-table2-trade-error.component.scss']
})
export class RemitTable2TradeErrorComponent implements OnInit, OnDestroy {

  private onDestroy$ = new Subject();

  msgs: Message[] = [];
  disabled: boolean = false;
  inProgress: boolean = false;
  historyId: number;
  submitEnabled$: Observable<boolean> = of(false);

  internalError: boolean = false;

  constructor(private service: RemitTable2TradeService, private route: ActivatedRoute, private titleService: Title) {
  }

  ngOnInit() {
    this.msgs = [];

    this.inProgress = false;

    this.titleService.setTitle('Delete Table 2 Trade');

    this.route.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe((params: Params) => {
      this.historyId = params[EndPointConstants.PARAM_HISTORY_ID];
    });

    this.service.loadPresetsDeletion(this.historyId).pipe(takeUntil(this.onDestroy$))
      .subscribe(
        response => {
          if(response){

            this.submitEnabled$ = of(response.submitEnabled);

            if (response.validationFailureMessages) {
              this.disabled = true;
              this.internalError = true;
              for (let i = 0; i < response.validationFailureMessages.length; i++) {
                this.msgs = [...this.msgs, { severity: 'error', summary: response.validationFailureMessages[i] }];
              }
            }
          }
        }, error => {
          this.disabled = true;
          this.internalError = true;

          let errorDetail = '';
          if (error.error && error.error.errorMessage) {
            errorDetail = error.error.errorMessage;
          }

          this.msgs = [...this.msgs, { severity: 'error', summary: error.statusText, detail: errorDetail }];
        });
  }

  submitDelete() {
    this.msgs = [];
    this.disabled = true;
    this.inProgress = true;

    this.service.submitError(this.historyId).pipe(takeUntil(this.onDestroy$)).subscribe(
      response => {
        if (response.validationFailureMessages) {
          for (let i = 0; i < response.validationFailureMessages.length; i++) {
            this.msgs = [...this.msgs, { severity: 'error', summary: response.validationFailureMessages[i] }];
          }
          this.disabled = false;
        } else {
          this.msgs = [...this.msgs, { severity: 'success', summary: 'Deletion submitted successfully.' }];
        }
        this.inProgress = false;
      }, error => {
        this.msgs = [...this.msgs, { severity: 'error', summary: error.statusText, detail: error.error.errorMessage }];
        this.inProgress = false;
      }
    );
  }

  isInProgress(): boolean {
    return this.inProgress;
  }

  ngOnDestroy() {
    this.submitEnabled$ = of(false);
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

}
