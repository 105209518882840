<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>

<h1>CpML Dashboard</h1>
<p-tabView (onChange)="setCategoryTab($event)"
           [activeIndex]="activeIndex$ | async"
           [styleClass]="'minimized'">
  <p-tabPanel header="In Progress">
  </p-tabPanel>
  <p-tabPanel header="Issue">
  </p-tabPanel>
  <p-tabPanel header="Complete">
  </p-tabPanel>
</p-tabView>
<div class="padding-12 cms-tab-view-body">
  <cms-cpml-dashboard-panel [category]="selectedCategory" ></cms-cpml-dashboard-panel>
</div>

</ng-template>
