import { createAction, props, union } from '@ngrx/store';
import { Message } from 'primeng/api';
import {
  NettingSettingsActivateBlockRequestModel, NettingSettingsModel,
  PresetValues
} from '../../../settings/netting/netting-settings.model';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { DialogState } from '@common/state/reducers';
import { SETTINGS_DIALOGS } from '../../../settings/invoice/invoice-settings.model';

export const loadTableData = createAction('[ESM NETTING SETTINGS] Load netting setting Table');
export const loadTableDataSuccess = createAction('[ESM NETTING SETTINGS] Load netting setting Table Success', (payload: DatatableData<NettingSettingsModel[]>) => ({payload}));
export const filterTableData = createAction('[ESM NETTING SETTINGS] Filter netting setting Table');
export const loadPresetValuesAction = createAction('[ESM NETTING SETTINGS] Load Preset Values');
export const loadPresetValuesSuccessAction = createAction('[ESM NETTING SETTINGS] Load Preset Values Success', (payload: PresetValues) => ({ payload }));
export const setMessagesAction = createAction('[ESM NETTING SETTINGS] Set Messages', (payload: Message[]) => ({ payload }));
export const setToggleNettingAllowed = createAction('[ESM NETTING SETTINGS] Toggle Netting Allowed', (payload: boolean) => ({ payload }));
export const setNettingSupported = createAction('[ESM NETTING SETTINGS] Netting Supported', (payload: boolean) => ({ payload }));
export const confirmActivateAction = createAction('[ESM NETTING SETTINGS] Confirm Activate', (payload: boolean) => ({ payload }));
export const setCentralNettingSupported = createAction('[ESM NETTING SETTINGS] Central Netting Supported', (payload: boolean) => ({ payload }));
export const setCrossCommodityNettingSupported = createAction('[ESM NETTING SETTINGS] Cross Commodity Netting Supported', (payload: boolean) => ({ payload }));
export const confirmBlockAction = createAction('[ESM NETTING SETTINGS] Confirm Block');
export const pagingAndSorting = createAction('[ESM NETTING SETTINGS] paging', (payload: {sorting: SortingFilter, paging: PagingFilter }) => ({payload}));
export const loading = createAction('[ESM NETTING SETTINGS] loading', (payload: boolean) => ({payload}));
export const setCallDayPhysicalNettingGeneration = createAction('[ESM NETTING SETTINGS] Set Business Days Until Physical Netting Statement Is Generated', (payload: number) => ({ payload }));
export const setCallDayFinancialNettingGeneration = createAction('[ESM NETTING SETTINGS] Set Business Days Until Financial Netting Statement Is Generated', (payload: number) => ({ payload }));
export const openDialogAction = createAction('[ESM NETTING SETTINGS] Open Dialog', (payload: DialogState<SETTINGS_DIALOGS>) => ({ payload }));
export const closeDialogAction = createAction('[ESM NETTING SETTINGS] Close Dialog');
export const activateNetting = createAction('[ESM NETTING SETTINGS] activate netting for party', (payload: NettingSettingsActivateBlockRequestModel) => ({payload}));
export const blockNetting = createAction('[ESM NETTING SETTINGS] block netting for party', (payload: NettingSettingsActivateBlockRequestModel) => ({payload}));
export const downloadGatekeeperAction = createAction('[ESM NETTING SETTINGS] Download Gatekeeper');
export const setSearchString = createAction('[ESM NETTING SETTINGS] Setting Search Token', (payload: string) => ({payload}));
export const setSelectedVatNumber = createAction('[ESM NETTING SETTINGS] Setting Vat Number', (payload: string) => ({payload}));

const actions = union({
  loadPresetValuesAction,
  loadPresetValuesSuccessAction,
  setMessagesAction,
  setToggleNettingAllowed,
  setNettingSupported,
  setCentralNettingSupported,
  setCrossCommodityNettingSupported,
  confirmActivateAction,
  confirmBlockAction,
  setCallDayPhysicalNettingGeneration,
  setCallDayFinancialNettingGeneration,
  downloadGatekeeperAction,
  loadTableData,
  loading,
  pagingAndSorting,
  openDialogAction,
  closeDialogAction,
  activateNetting,
  blockNetting,
  filterTableData,
  setSearchString,
  setSelectedVatNumber,
  loadTableDataSuccess
});

export type EsmNettingSettingsActions = typeof actions;
