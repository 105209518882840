import { Component, OnDestroy, OnInit } from '@angular/core';
import {  UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RemitTradeEntryTable2Service } from './remit-trade-entry-table2.service';
import { Title } from '@angular/platform-browser';
import {MessageService} from 'primeng/api';
import { DateUtils } from '@common/shared/date.utils';
import { OrganisationService } from '@common/organisation.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'cms-remit-trade-entry-table2',
  templateUrl: './remit-trade-entry-table2.component.html',
  styleUrls: ['./remit-trade-entry-table2.component.scss'],
  providers: [MessageService]

})
export class RemitTradeEntryTable2Component implements OnInit, OnDestroy {

  tradeForm: UntypedFormGroup;
  private onDestroy$ = new Subject();

  showTradeEntryForm: boolean = false;

  onReset$ = new Subject();


  submitDisabled: boolean = true;
  constructor(private formBuilder: UntypedFormBuilder,
              private tradeEntryService: RemitTradeEntryTable2Service,
              private title: Title,
              private organisationService: OrganisationService,
              private messageService:MessageService) {
    this.tradeForm = this.formBuilder.group({});
  }

  ngOnInit() {
    this.messageService.clear();
    this.onReset$.next(false);
    this.submitDisabled = true;
    this.title.setTitle('REMIT Table 2 Trade Entry');
    this.checkAcerCodeAndLoadValues();
    this.organisationService.getOrganisationChangeIfAccessible().pipe(
      takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.reset();
        this.checkAcerCodeAndLoadValues();
      });

    this.tradeEntryService.onMessageChange()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(errorMessages => (errorMessages) ? this.messageService.add(errorMessages) : this.messageService.clear());

    this.tradeEntryService.validationErrorsFieldNames
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        fieldName => {
          // TODO PB deliveryProfiles field : daysOfTheWeek0, daysOfTheWeek1 ...
          if (this.tradeForm.controls[fieldName]) {
            this.tradeForm.controls[fieldName].setErrors({ error: true });
          }
        }
      );

    this.tradeEntryService.onTradeEntryDataChange().pipe(takeUntil(this.onDestroy$)).subscribe(data =>{
      if(data){
        this.submitDisabled = !data.submitAllowed;
      }else {
        this.submitDisabled = true;
      }
    });

    this.tradeEntryService.submitted.pipe(takeUntil(this.onDestroy$))
      .subscribe( submitted => {
        if(submitted){
          this.onReset$.next(true);
        }
      });
  }

  checkAcerCodeAndLoadValues() {
    this.tradeEntryService.hasAcerCode().then(
      hasAcerCode => {
        this.showTradeEntryForm = hasAcerCode;
        if (this.showTradeEntryForm) {
          this.messageService.clear();
          this.tradeEntryService.loadTradeEntryData({});
        } else {
          this.messageService.clear();
          const errorCode = 'No ACER code was found for your organisation which is mandatory for REMIT reporting. Please contact the CMS Service Team and have your ACER code registered in the CMS.';
          this.messageService.add({ severity: 'error', detail: errorCode });
        }
      }
    );
  }

  reset() {
    this.tradeForm.reset();
    this.messageService.clear();
    this.onReset$.next(true);
    this.tradeEntryService.reset();
  }

  submit() {
    const trade = this.tradeForm.value;
    this.messageService.clear();

    Object.keys(this.tradeForm.controls).forEach(key => {
      this.tradeForm.controls[key].setErrors(null);
    });

    // format dates - contract
    const contractDate = DateUtils.toISOStringWithIgnoreTimezoneOffset(this.tradeForm.controls['contractDate'].value);

    // format dates -startDate
    const startDate: Date = this.tradeForm.controls['deliveryStart'].value;
    const isoStartDate = DateUtils.toISOStringWithIgnoreTimezoneOffset(startDate);

    // format dates - end date
    const endDate: Date = this.tradeForm.controls['deliveryEnd'].value;
    const isoEndDate = DateUtils.toISOStringWithIgnoreTimezoneOffset(endDate);

    // format date in option Details
    let optionDetails = this.tradeForm.controls['optionDetails'].value;
    optionDetails = optionDetails.map( o => {
      return {
          ...o,
          firstExerciseDate: DateUtils.toISOStringWithIgnoreTimezoneOffset(o.firstExerciseDate),
          lastExerciseDate: DateUtils.toISOStringWithIgnoreTimezoneOffset(o.lastExerciseDate),
      };
    });

    let volumeOptionalityIntervals = this.tradeForm.controls['volumeOptionalityIntervals'].value;
    volumeOptionalityIntervals = volumeOptionalityIntervals.map( o => {
      return {
        ...o,
        startDate: DateUtils.toISOStringWithIgnoreTimezoneOffset(o.startDate),
        endDate: DateUtils.toISOStringWithIgnoreTimezoneOffset(o.endDate),
      };
    });

    // format dates - in fixing index
    let fixingIndices = this.tradeForm.controls['fixingIndices'].value;
    fixingIndices = fixingIndices.map( o => {
      return {
        ...o,
        firstFixingDate: DateUtils.toISOStringWithIgnoreTimezoneOffset(o.firstFixingDate),
        lastFixingDate: DateUtils.toISOStringWithIgnoreTimezoneOffset(o.lastFixingDate),
      };
    });

    const deliveryPointsOrZones = this.tradeForm.controls['deliveryPointOrZones'].value;
    const dpzs = deliveryPointsOrZones.map(v => v.deliveryPointOrZone );

    const tradeEntry = {
      ...this.tradeForm.value,
      contractDate: contractDate,
      deliveryStart: isoStartDate,
      deliveryEnd: isoEndDate,
      optionDetails: optionDetails,
      volumeOptionalityIntervals: volumeOptionalityIntervals,
      fixingIndices: fixingIndices,
      deliveryPointOrZones: dpzs
    };

    this.tradeEntryService.submit(tradeEntry);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
    this.tradeEntryService.clearTradeEntryFormData();
  }
}
