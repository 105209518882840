import { UserAccount, UserAccountsFilters } from '../../administration-tab-panel/user-accounts/user-accounts.model';
import * as fromAction from './user-account.actions';
import { dialogReducer, DialogState } from '../dialog/dialog.reducer';
import { datatableReducer, DatatableState } from '../datatable/datatable.reducer';
import { organisationGroupReducer, OrganisationGroupState } from '../organisation-group/organisation-group.reducer';
import { organisationIdReducer } from '../organisation-id/organisation-id.reducer';

export interface UserAccountsState {
  organisationId?: number;
  orgGroups : OrganisationGroupState;
  datatable: DatatableState<UserAccount, UserAccountsFilters>;
  dialog: DialogState;
}

export const datatableInitialState: DatatableState<UserAccount, UserAccountsFilters> = {
  selected: null,
  data: {
    values: [],
    count: 0
  },
  messages: [],
  filters: {
    filterInactive: true,
    organisationGroupId: null
  },
  loading: false,
  paging: {
    page: 0,
    entriesPerPage: 25
  },
  sorting: {
    columnName: 'organisationGroupName',
    sortOrder: 'ASCENDING'
  },
  editableData: null,
};

export const initialOrgGroupsState: OrganisationGroupState = {
  orgGroupSelectorVisible: false,
  orgGroups: []
};

export const initialUserAccountsState: UserAccountsState = {
  organisationId: null,
  orgGroups: initialOrgGroupsState,
  datatable: datatableInitialState,
  dialog: null,
};

export function userAccountReducer(state: UserAccountsState = { ...initialUserAccountsState },
                                   action: fromAction.UserAccountActions): UserAccountsState {
  return {
    dialog: dialogReducer('[USER ACCOUNT]')(state.dialog, action as any),
    datatable: datatableReducer('[USER ACCOUNT]', datatableInitialState)(state.datatable, action as any),
    orgGroups: organisationGroupReducer('[USER ACCOUNT]', initialOrgGroupsState)(state.orgGroups, action as any),
    organisationId: organisationIdReducer('[USER ACCOUNT]')(state.organisationId, action as any),
  };
}

