import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SelectItem, SelectItemGroup } from 'primeng/api';
import { RemitTradeEntryTable2Service } from '../remit-trade-entry-table2.service';
import { takeUntil } from 'rxjs/operators';
import { RemitTradeEntryTable2ProductDetailsDataModel } from '../remit-trade-entry-table2-data.model';
import {
  RemitTable2TradeProductDetailsModel,
} from '../../../trade/table2/modify/remit-table2-trade-entry.model';
import { Subject } from 'rxjs';

@Component({
    selector: 'cms-remit-trade-entry-table2-product-details',
    templateUrl: './remit-trade-entry-table2-product-details.component.html',
    styleUrls: ['./remit-trade-entry-table2-product-details.component.scss']
})
export class RemitTradeEntryTable2ProductDetailsComponent implements OnInit, OnChanges, OnDestroy {

    @Input()
    formGroup: UntypedFormGroup;

    @Input()
    onReset: Subject<boolean>;

  @Input()
  data: RemitTable2TradeProductDetailsModel;

    contractTypes: SelectItemGroup[];
    energyCommodities: SelectItem[];
    deliveryPointsOrZones: SelectItemGroup[];
    loadTypes: SelectItemGroup[];
    settlementMethods: SelectItem[];

    validationErrors: string[] = [];

    productDetailData: RemitTradeEntryTable2ProductDetailsDataModel;

    private onDestroy$ = new Subject();

    constructor(private formBuilder: UntypedFormBuilder, private tradeEntryService: RemitTradeEntryTable2Service) {
        this.onReset = new Subject<boolean>();
    }

    ngOnInit() {

        this.initForm();

        this.tradeEntryService.onTradeEntryDataChange().pipe(takeUntil(this.onDestroy$)).subscribe(formData => {
            if (formData && formData.productDetails) {
                this.productDetailData = formData.productDetails;
            }

            this.initFormValues();
            this.fillFormWithData();
        });

        this.onReset.subscribe(reset => {
            if (reset) {
                this.resetForm();
            }
        });

      this.tradeEntryService.onValidationFieldErrors().pipe(takeUntil(this.onDestroy$))
        .subscribe(
          fieldName => {
            if (fieldName && fieldName.indexOf('deliveryPointOrZone') >= 0) {
              const items = this.formGroup.controls['deliveryPointOrZones'] as UntypedFormArray;
              const index = fieldName.charAt(fieldName.length - 1);
              const formGroup = items.controls[parseInt(index,10)] as UntypedFormGroup;
              formGroup.controls['deliveryPointOrZone'].setErrors({ error: true });
            }
          }
        );
    }

    private initForm() {
        this.formGroup.addControl('contractType', new UntypedFormControl(''));
        this.formGroup.addControl('energyCommodity', new UntypedFormControl(''));
        this.formGroup.addControl('deliveryPointOrZones', this.formBuilder.array(
            []
        ));
        this.formGroup.addControl('loadType', new UntypedFormControl(''));
        this.formGroup.addControl('settlementMethod', new UntypedFormControl(''));

        this.add('');
    }

    private resetForm() {

      this.formGroup.controls['contractType'].reset();
      this.formGroup.controls['energyCommodity'].reset();
      this.formGroup.controls['loadType'].reset();
      this.formGroup.controls['settlementMethod'].reset();

      this.formGroup.removeControl('deliveryPointOrZones');
      this.formGroup.addControl('deliveryPointOrZones', this.formBuilder.array(
        []
      ));
      this.add('');
    }

    private initFormValues() {
        if (this.productDetailData) {
            this.contractTypes = this.tradeEntryService.getGroupedValues(this.productDetailData.contractTypes);
            this.energyCommodities = this.convertKeyValueToSelectItem(this.productDetailData.energyCommodities);
            this.deliveryPointsOrZones = this.tradeEntryService.getGroupedValues(this.productDetailData.deliveryPointsOrZones);
            this.loadTypes = this.tradeEntryService.getGroupedValues(this.productDetailData.loadTypes);
            this.settlementMethods = this.convertKeyValueToSelectItem(this.productDetailData.settlementMethods);
        }
    }


  ngOnChanges(changes: SimpleChanges): void {
    this.fillFormWithData();
  }


  add(deliveryZone: string) {

        const deliveryZones = this.formGroup.controls['deliveryPointOrZones'] as UntypedFormArray;

        if (deliveryZones.length < 4) {

            const formControl = new UntypedFormControl();
            formControl.setValue(deliveryZone);

            deliveryZones.push(
                this.formBuilder.group({ deliveryPointOrZone: formControl })
            );
        }
    }

    appendValidationErrorClassOfArray(i: number, fieldName: string): string {

        const form = this.deliveryZones[i] as UntypedFormGroup;

        if (form) {

            if (this.formGroup.touched && form.controls[fieldName].dirty && form.controls[fieldName].invalid) {
                return 'error';
            }
        }
    }

    remove() {
        const deliveryZones = this.formGroup.controls['deliveryPointOrZones'] as UntypedFormArray;

        if (deliveryZones.length > 1) {
            deliveryZones.removeAt(deliveryZones.length - 1);
        }
    }

    get deliveryZones(): AbstractControl[] {
        return (this.formGroup.controls['deliveryPointOrZones'] as UntypedFormArray).controls;
    }

    get disableAddButton(): boolean {
        return this.deliveryZones.length > 3 || this.formGroup.disabled;
    }

    get disableRemoveButton(): boolean {
        return this.deliveryZones.length < 2 || this.formGroup.disabled;
    }

    appendValidationErrorClass(fieldName: string): string {
        if (this.validationErrors[fieldName]) {
            return 'error';
        }
    }

    getValidationErrorStyleClass(fieldName: string): string {
      if (this.formGroup.controls[fieldName] && this.formGroup.controls[fieldName].hasError('error')) {
        return 'error';
      }
    }

    getDeliveryPointOrZonesValidationErrorStyleClass(index: number, fieldName: string): string {

      const items = this.formGroup.get('deliveryPointOrZones') as UntypedFormArray;
      if (items && items.controls[index]) {

        const formGroup = items.controls[index] as UntypedFormGroup;

        if (formGroup.controls['deliveryPointOrZone'].hasError('error')) {
          return ' error';
        }
      }

      return '';
    }

  convertKeyValueToSelectItem(keyValueData: any): SelectItem[] {
        if (keyValueData) {
            return keyValueData.map(method => {
                return {
                    label: method.value,
                    value: method.key
                };
            });
        }
        return [];
    }



    ngOnDestroy(): void {
        this.onDestroy$.next(true);
        this.onDestroy$.unsubscribe();
        this.onReset.unsubscribe();
    }


    private fillFormWithData(){
      if (this.formGroup && this.data) {
        this.formGroup.patchValue({
          contractType: this.data.contractType,
          energyCommodity: this.data.energyCommodity,
          loadType: this.data.loadType,
          settlementMethod: this.data.settlementMethod,
        });

        if(this.data.deliveryPointOrZones){
          this.formGroup.removeControl('deliveryPointOrZones');
          this.formGroup.addControl('deliveryPointOrZones', this.formBuilder.array(
            []
          ));
          this.data.deliveryPointOrZones.forEach((value) => {this.add(value);});
        }
      }
    }
}
