import { Action } from '@ngrx/store';
import { Message } from 'primeng/api';
import { DatatableConfig, DatatableData } from '@common/shared/results.model';
import { Contacts, ContactsFilters, UserContacts, UserContactsFilters } from '../../contacts/contacts.model';
import { Prefix } from '../reducers';

export const FILTER_DATA = 'Filter Data';
export const FILTER_DATA_SUCCESS = 'Filter Data Success';
export const SET_MESSAGES = 'Set Messages';
export const SORT_AND_PAGE_DATA = 'Sort and Page Data';
export const LOAD_DATA = 'Load Data';
export const SELECT = 'SELECT';
export const SET_FILTERS = 'Set Filters';
export const SET_TO_DEFAULT = 'Set to Default';

export class SetToDefaultAction implements Action {
  get type() {
    return `${this.prefix} ${SET_TO_DEFAULT}`;
  }
  constructor(public prefix: Prefix) {
    return {
      type: this.type,
      prefix: this.prefix
    };
  }
}

export class SelectAction implements Action {
  get type() {
    return `${this.prefix} ${SELECT}`;
  }

  constructor(public prefix: Prefix,
              public payload: Contacts) {
  }
}

export class LoadDataAction implements Action {
  get type() {
    return `${this.prefix} ${LOAD_DATA}`;
  }

  constructor(public prefix: Prefix) {
  }
}

export class FilterDataAction implements Action {
  get type() {
    return `${this.prefix} ${FILTER_DATA}`;
  }

  constructor(public prefix: Prefix,
              public payload: ContactsFilters | UserContactsFilters) {
  }
}

export class SetFiltersAction implements Action {
  get type() {
    return `${this.prefix} ${SET_FILTERS}`;
  }

  constructor(public prefix: Prefix,
              public payload: ContactsFilters | UserContactsFilters) {
  }
}

export class FilterDataSuccessAction implements Action {
  get type() {
    return `${this.prefix} ${FILTER_DATA_SUCCESS}`;
  }

  constructor(public prefix: Prefix,
              public payload: DatatableData<(Contacts | UserContacts)[]>) {
  }
}

export class SetMessagesAction implements Action {
  get type() {
    return `${this.prefix} ${SET_MESSAGES}`;
  }

  constructor(public prefix: Prefix,
              public payload: Message[]) {
  }
}

export class SortAndPageDataAction implements Action {
  get type() {
    return `${this.prefix} ${SORT_AND_PAGE_DATA}`;
  }

  constructor(public prefix: Prefix, public payload: DatatableConfig) {
  }
}

export type DatatableActions = FilterDataAction | FilterDataSuccessAction | SetMessagesAction | SortAndPageDataAction | SetToDefaultAction;
