import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { EsmNettingDashboardPresetFilters } from '../esm-netting-dashboard.model';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { updateDealsMultiSelect, updateDealsMultiSelectLabelValue } from '@common/deals/deals.model';
import { AdditionalDateRangeEntry, getEsmDashboardBucketRanges } from '@common/additional-date-range/additional-date-range.model';

@Component({
  selector: 'cms-netting-statement-filter',
  templateUrl: './netting-statement-filter.component.html',
  styleUrls: ['./netting-statement-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EsmNettingStatementFilterComponent implements OnInit, OnChanges {
  @Input() filters: EsmNettingDashboardPresetFilters;
  @Input() commodities: string[] = [];
  @Input() deliveryPoints: { keyObject: string, valueObject: string }[]= [];
  @Input() counterParties: { key: number, value: string }[] = [];
  @Input() nettingStatementTypes: string[] = [];
  @Output() reset = new EventEmitter();
  @Output() filter = new EventEmitter<EsmNettingDashboardPresetFilters>();
  form: UntypedFormGroup;
  commoditiesOptions: { label: string, value: string}[] = [];
  deliveryPointsOptions: { label: string, value: string}[] = [];
  counterPartiesOptions: { label: string, value: number}[] = [];
  nettingTypesOptions: { label: string, value: string}[] = [];
  updateDealsMultiSelect;
  updateMultiSelectKeyValue;
  dateRanges: AdditionalDateRangeEntry[] = getEsmDashboardBucketRanges();

  errors: { [key: string]: ValidationErrors } = {};

  constructor(private formBuilder: UntypedFormBuilder) {
    this.updateDealsMultiSelect = updateDealsMultiSelect.bind(this);
    this.updateMultiSelectKeyValue = updateDealsMultiSelectLabelValue.bind(this);
  }

  ngOnInit() {
    const { counterParties, deliveryPoints, commodities, nettingStatementTypes, paymentDateRange, invoiceStartDate, invoiceEndDate } = this.filters;
    this.form = this.formBuilder.group({
      counterParties: {value: counterParties, disabled: !this.counterParties || !this.counterParties.length},
      deliveryPoints: {value: deliveryPoints, disabled: !this.deliveryPoints || !this.deliveryPoints.length},
      commodities: {value: commodities, disabled: !this.commodities || !this.commodities.length},
      nettingStatementTypes: {value: nettingStatementTypes, disabled: !this.nettingStatementTypes || !this.nettingStatementTypes.length},
      paymentDateStart: paymentDateRange && paymentDateRange.start,
      paymentDateEnd: paymentDateRange && paymentDateRange.end,
      invoiceStartDate: invoiceStartDate,
      invoiceEndDate: invoiceEndDate
    });

    this.updateCounterPartiesMultiselect();

    this.updateMultiSelectKeyValue(
      'deliveryPoints',
      'deliveryPointsOptions',
      this.deliveryPoints,
      Validators.required);

    this.updateDealsMultiSelect(
      'commodities',
      'commoditiesOptions',
      this.commodities,
      Validators.required);

    this.updateDealsMultiSelect(
      'nettingStatementTypes',
      'nettingTypesOptions',
      this.nettingStatementTypes,
      Validators.required);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.form && changes.filters) {
      const { counterParties, deliveryPoints, commodities, nettingStatementTypes, paymentDateRange, invoiceStartDate, invoiceEndDate } = this.filters;
      this.form.patchValue({
        counterParties,
        deliveryPoints,
        commodities,
        nettingStatementTypes,
        paymentDateStart: paymentDateRange && paymentDateRange.start,
        paymentDateEnd: paymentDateRange && paymentDateRange.end,
        invoiceStartDate,
        invoiceEndDate
      });
      this.form.updateValueAndValidity();
    }

    if (changes.counterParties) {
      this.updateCounterPartiesMultiselect();
    }

    if (changes.deliveryPoints) {
      this.updateMultiSelectKeyValue(
        'deliveryPoints',
        'deliveryPointsOptions',
        this.deliveryPoints,
        Validators.required);
    }

    if (changes.commodities) {
      this.updateDealsMultiSelect(
        'commodities',
        'commoditiesOptions',
        this.commodities,
        Validators.required);
    }

    if (changes.nettingStatementTypes) {
      this.updateDealsMultiSelect(
        'nettingStatementTypes',
        'nettingTypesOptions',
        this.nettingStatementTypes,
        Validators.required);
    }
  }

  onReset(): void {
    this.reset.emit();
  }

  onFilter(): void {
    if (this.form.invalid) {
      return;
    }
    const {
      counterParties,
      deliveryPoints,
      commodities,
      nettingStatementTypes,
      paymentDateStart,
      paymentDateEnd,
      invoiceStartDate,
      invoiceEndDate
    } = this.form.getRawValue();
    this.filter.emit({
      counterParties,
      deliveryPoints,
      commodities,
      nettingStatementTypes,
      paymentDateRange: {
        start: paymentDateStart,
        end: paymentDateEnd
      },
      invoiceStartDate,
      invoiceEndDate
    });
  }

  hasErrors() {
    return !this.form.valid;
  }

  private updateCounterPartiesMultiselect(): void {
    const isValues = this.counterParties && this.counterParties.length;
    this.counterPartiesOptions = isValues ?
      this.counterParties.map(item => ({ label: item.value, value: item.key })) : [];
    if (!this.form) {
      return;
    }
    const counterPartyOrgIdsControl = this.form.controls['counterParties'];
    if (isValues) {
      counterPartyOrgIdsControl.enable();
      counterPartyOrgIdsControl.setValidators(Validators.required);
    } else {
      counterPartyOrgIdsControl.disable();
      counterPartyOrgIdsControl.clearValidators();
    }
    this.form.updateValueAndValidity();
  }
}
