import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Utils } from '../shared/utils';
import { TransactionSheetResponseModel, TransactionSheetTableConfig, TransactionSheetValuesGroupModel } from './transaction-sheet-model';
import { filter, map } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'cms-transaction-sheet',
  styleUrls: ['./transaction-sheet.component.scss'],
  templateUrl: './transaction-sheet.component.html',
})
export class TransactionSheetComponent implements OnInit {
  @Input()
  set data(value: TransactionSheetResponseModel) {
    this.transactionSheetResponseModelSubject.next(value);
  }
  leftRightGroups$: Observable<TransactionSheetValuesGroupModel[][]>;
  private transactionSheetResponseModelSubject = new BehaviorSubject<TransactionSheetResponseModel>(null);

  constructor(private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle('View Trade');
    this.leftRightGroups$ = this.transactionSheetResponseModelSubject
      .pipe(filter(model => model != null),
        map(model => this.partitionInLeftAndRightTableGroups(model.valuesGroups, model.documentType, model.recordType)));
  }

  hasMultiItemValue(groupItem): boolean {
    return groupItem.valueType === 'MULTI';
  }

  private partitionInLeftAndRightTableGroups(groups: TransactionSheetValuesGroupModel[], documentType: string, recordType: string) {
    const leftGroupNames = TransactionSheetTableConfig.getLeftTableGroupNamesByDocumentType(documentType, recordType);
    const isLeftTableCondition = group => leftGroupNames.indexOf(group.name) >= 0;
    return Utils.partitionInLeftAndRightBy(isLeftTableCondition, groups);
  }
}
