import { Component, OnDestroy, OnInit } from '@angular/core';
import * as fromFinderActions from '../../state/netting/finder/esm-netting-finder.actions';
import { select, Store } from '@ngrx/store';
import { EsmNettingStatementFinderState } from '../../state/netting/finder/esm-netting-finder.reducers';
import {
  EsmNettingStatementFinderFormValues,
  EsmNettingStatementModel,
  NettingStatementFinderPropertyFilter,
  NettingStatementFinderReferencesFilter
} from './esm-netting-finder.model';
import { Observable, Subject, Subscription } from 'rxjs';
import * as fromFilterSelectors from '../../state/netting/finder/esm-netting-finder.selectors';
import { OrganisationService } from '@common/organisation.service';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import * as fromTableSelectors from '../../state/netting/finder/table/table.selectors';
import { Column } from '@common/shared/datatable.model';
import { DatatableState } from '@common/datatable/datatable.model';
import * as fromTableActions from '../../state/netting/finder/table/table.actions';
import { EsmInvoiceFinder } from '../../invoice/finder/invoice-finder.model';
import * as fromCommon from '@common/state/reducers';
import * as fromDialogSelectors from '../../state/netting/finder/dialog/dialog.selectors';
import { NETTING_STATEMENT_DIALOGS } from '../../state/netting/finder/dialog/dialog.reducers';
import { AddNote, NotesCellViewEvent } from '@common/deals/deals.model';
import * as fromDialogActions from '../../state/netting/finder/dialog/dialog.actions';
import { Router } from '@angular/router';
import { ErrorMessageService } from '@common/errors/error-message.service';

@Component({
  selector: 'cms-esm-netting-finder',
  templateUrl: './esm-netting-finder.component.html',
  styleUrls: ['./esm-netting-finder.component.scss']
})
export class EsmNettingFinderComponent implements OnInit, OnDestroy {

  filter$: Observable<NettingStatementFinderPropertyFilter> = this.store.pipe(select(fromFilterSelectors.getFilter));
  formValues$: Observable<EsmNettingStatementFinderFormValues> = this.store.pipe(select(fromFilterSelectors.getFormValues));
  selectedIds$: Observable<string[]> = this.store.pipe(select(fromTableSelectors.getSelectedIds));
  notFoundIds$: Observable<string[]> = this.store.pipe(select(fromFilterSelectors.getReferencesNotFoundIds));
  idsCount$: Observable<number> = this.store.pipe(select(fromFilterSelectors.getReferencesIdsCount));
  dialog$: Observable<fromCommon.DialogState<NETTING_STATEMENT_DIALOGS>> = this.store.pipe(select(fromDialogSelectors.getDialog));
  tableVisible$: Observable<boolean> = this.store.pipe(select(fromTableSelectors.isVisible));
  messages$ = this.store.pipe(select(fromTableSelectors.getMessages));
  data$: Observable<DatatableData<EsmNettingStatementModel[]>> = this.store.pipe(select(fromTableSelectors.getData));
  loading$ = this.store.pipe(select(fromTableSelectors.getLoading));
  paging$: Observable<PagingFilter> = this.store.pipe(select(fromTableSelectors.getPaging));
  sorting$: Observable<SortingFilter> = this.store.pipe(select(fromTableSelectors.getSorting));
  columns$: Observable<Column[]> = this.store.pipe(select(fromTableSelectors.getColumns));
  selected$: Observable<EsmNettingStatementModel[]> = this.store.pipe(select(fromTableSelectors.getSelectedNettingStatements));
  subscription$: Subscription = new Subscription();
  private selectedNettingStatements: EsmNettingStatementModel[] = [];
  private onDestroy$ = new Subject();

  singleNttType: string;
  singleCommodity: string;

  referenceSearchConfig = [
    {label: 'Our Netting Statement ID', name: 'ourNettingStatementId'},
    {label: 'Their Netting Statement ID', name: 'theirNettingStatementId'},
    {label: 'Document ID', name: 'documentId'},
    {label: 'Invoice Number', name: 'invoiceNumber'}
  ];


  constructor(private store: Store<EsmNettingStatementFinderState>,
              private organisationService: OrganisationService,
              private router: Router,
              public errorMessageService: ErrorMessageService) { }

  ngOnInit() {
    this.subscription$.add(this.organisationService.onRefresh()
      .subscribe((authenticated) => {
        if (authenticated && this.organisationService.getCurrentOrganisationId()!=null) {
        this.store.dispatch(fromTableActions.setVisibleAction(false));
        this.store.dispatch(fromFinderActions.getFormValuesAction());
        }
      }));

    this.subscription$.add(this.data$.subscribe(data => {
      if (data.values && this.selectedNettingStatements) {
        const ids = this.selectedNettingStatements.map(s => s.id);
        const selected: EsmNettingStatementModel[] = data.values.filter(d => ids.indexOf(d.id) !== -1);
        this.onSelected(selected);
      }
    }));
  }

  onLazyLoad({page, perPage, sortField, sortOrder}: DatatableState): void {
    this.store.dispatch(fromTableActions.sortAndPageDataAction({
      paging: {
        page,
        entriesPerPage: perPage
      },
      sorting: {
        sortOrder,
        columnName: sortField
      }
    }));
  }

  onFilter(filter: NettingStatementFinderPropertyFilter): void {
    this.store.dispatch(fromTableActions.setSearchByAction('filter'));
    this.store.dispatch(fromTableActions.setVisibleAction(true));
    this.store.dispatch(fromFinderActions.searchByFilterAction(filter));
    this.singleValueSearchPreview(filter);
  }

  onResetByIds(): void {
     this.store.dispatch(fromFinderActions.resetReferenceFilterAction());
  }

  onReferenceSearch(referencesFilter: NettingStatementFinderReferencesFilter): void {
    this.store.dispatch(fromTableActions.setSearchByAction('reference'));
    this.store.dispatch(fromTableActions.setVisibleAction(true));
    this.store.dispatch(fromFinderActions.searchByReferencesAction(referencesFilter));
  }

  onDownloadXml({ id }: EsmInvoiceFinder): void {
    this.store.dispatch(fromTableActions.downloadXmlAction(id));
  }


  onDownloadPdf({ documentId }: EsmInvoiceFinder): void {
    this.store.dispatch(fromTableActions.downloadPdfAction(documentId));

  }

  onReset(): void {
    this.store.dispatch(fromFinderActions.resetPropertyFilterAction());
    /// to be implemented with user's default filter
  }

  onClickCustomize(): void {
    this.router.navigate(['/settings/datatable/customize'], {queryParams: {documentType: 'ESM_NETTING'}});
  }


  onViewNotes(data: NotesCellViewEvent): void {
    this.store.dispatch(fromDialogActions.openDialogAction({
      name: 'VIEW_NOTES',
      messages: [],
      data
    }));
  }

  onOpenAddNoteDialog(): void {
    this.store.dispatch(fromDialogActions.openDialogAction({
      name: 'ADD_NOTES',
      messages: [],
    }));
  }

  onCancelNotes(): void {
    this.store.dispatch(fromDialogActions.closeDialogAction());
  }

  onConfirmAddNotes(obj: AddNote): void {
    this.store.dispatch(fromTableActions.addNoteAction(obj));
  }

  onSelected(nettingStatements: EsmNettingStatementModel[]) {
    this.selectedNettingStatements = nettingStatements;
    let confirmationIds: string[] = [];
    if (nettingStatements) {
      confirmationIds = nettingStatements.map(s => s.id + '');
    }
    this.store.dispatch(fromTableActions.setSelectedIdsAction({confirmationIds}));
    this.store.dispatch(fromTableActions.setSelectedNettingStatementsAction({nettingStatements}));
  }

  onExport(): void {
    this.store.dispatch(fromTableActions.exportAction());
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
    this.store.dispatch(fromFinderActions.setDefaultStateAction());
    this.selectedNettingStatements = [];
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }


  getDisplayParams({id, documentId}: EsmNettingStatementModel): object {
    return {
      displayValue: documentId,
      urlPart: 'esm/netting',
      nettingId: id
    };
  }

  private singleValueSearchPreview({ nettingStatementTypes, commodities }) {
    this.singleNttType = nettingStatementTypes.length === 1 ? nettingStatementTypes[0] : null;
    this.singleCommodity = commodities.length === 1 ? commodities[0] : null;
  }
}
