import { Prefix } from '../reducers';

export const SET_ORGANISATION_ID = 'Set Organisation Id';

export class SetOrganisationIdAction {
  get type() {
    return `${this.prefix} ${SET_ORGANISATION_ID}`;
  }

  constructor(public prefix: Prefix,
              public payload: number) {
  }
}
