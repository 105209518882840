import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from './pdf-settings.actions';
import { catchError, concatMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { checkErrors, toPayload } from '@common/cms-common.model';
import { of } from 'rxjs';
import { EsmPdfSettings } from '../../../../master-data/internal-master-data/internal-master-data.model';
import * as fromSelectors from '../internal-master-data.selectors';
import { select, Store } from '@ngrx/store';
import { ErrorResponse } from '@common/error-response.model';
import { Message } from 'primeng/api';
import { Injectable } from '@angular/core';
import { ApiRequestService } from '@common/api-request.service';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { EsmPdfSettingsState } from './pdf-settings.reducer';
import { storePdfTemplateSettingsAction } from './pdf-settings.actions';

@Injectable()
export class EsmMasterDataPdfSettingsEffects {
  private baseUrl = '/api/esm/masterdata/pdfsettings';

  constructor(private actions$: Actions,
              private apiGateway: ApiRequestService,
              private mapper: MessagesMapperService,
              private store$: Store<EsmPdfSettingsState>) {
  }


  load_pdf_preset_values$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadPdfPresetValueAction),
      switchMap(() =>
        this.apiGateway.get(`${this.baseUrl}/loadPresetValues`)
          .pipe(
            switchMap(checkErrors),
            switchMap(({value}) => [
              fromActions.loadPdfPresetValuesSuccessAction(value),
              fromActions.setPdfMessagesAction([])
            ]),
            catchError(error => of(fromActions.setPdfMessagesAction(this.mapper.toErrorMessages(error, 'Server Error'))))
          )
      )
    )
  );

  create_pdf_settings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createPdfSettingsAction),
      map(toPayload),
      concatMap((payload) =>
        this.apiGateway.post(`${this.baseUrl}/create`, payload)
          .pipe(
            switchMap(checkErrors),
            concatMap(({ value }) => [
              fromActions.createPdfSettingsSuccessAction(value),
              fromActions.setPdfMessagesAction(this.mapper.toSuccessMessages('Successfully Created Pdf Settings'))]),
            catchError(error => of(fromActions.setPdfMessagesAction(this.mapper.toErrorMessages(error, 'Server Error'))))
          )
      )
    )
  );


  save_pdf_settings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.storePdfSettingsAction),
      map(toPayload),
      concatMap((payload: EsmPdfSettings) =>
        this.apiGateway.put(`${this.baseUrl}/update`, payload)
          .pipe(
            switchMap(checkErrors),
            concatMap(({ value }) => [
              fromActions.storePdfSettingsSuccessAction(value),
              fromActions.setPdfMessagesAction(this.mapper.toSuccessMessages('Successfully Edited Pdf Settings'))]),
            catchError(error => of(fromActions.setPdfMessagesAction(this.mapper.toErrorMessages(error, 'Server Error'))))
          )
      )
    )
  );

  save_pdf_template_settings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.storePdfTemplateSettingsAction),
      map(toPayload),
      concatMap((payload: EsmPdfSettings) =>
        this.apiGateway.put(`${this.baseUrl}/updateTemplates`,
          {
            invoiceTemplate: payload.invoiceTemplate,
            nettingTemplate: payload.nettingTemplate,
            version: payload.version,
            masterDataId: payload.masterDataId
          })
          .pipe(
            switchMap(checkErrors),
            concatMap(({ value }) => [
              fromActions.storePdfSettingsSuccessAction(value),
              fromActions.setPdfMessagesAction(this.mapper.toSuccessMessages('Successfully Edited Pdf Template Settings'))]),
            catchError(error => of(fromActions.setPdfMessagesAction(this.mapper.toErrorMessages(error, 'Server Error'))))
          )
      )
    )
  );


  load_pdf_settings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadPdfSettingsAction),
      withLatestFrom(this.store$.select(fromSelectors.getMasterDataId)),
      filter(([, masterDataId]) => Boolean(masterDataId)),
      concatMap(([, masterDataId]) =>
        this.apiGateway.get(`${this.baseUrl}/loadFormValues`, {'masterDataId': masterDataId})
          .pipe(
            switchMap(({value}) => [fromActions.loadPdfSettingsSuccessAction(value)]),
            catchError(error => of(fromActions.setPdfMessagesAction(this.mapper.toErrorMessages(error, 'Server Error'))))
          )
      )
    )
  );


  upload_pdf_settings_logo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.uploadPdfSettingsLogoAction),
      map(toPayload),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getMasterDataId))),
      concatMap(([payload, masterDataId]: [File, number]) => {
        const formData = new FormData();
        formData.append('file', payload);

        return this.apiGateway.post(`${this.baseUrl}/updateLogo`, formData, {'masterDataId': masterDataId})
          .pipe(
            concatMap((response: ErrorResponse | null) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [fromActions.setPdfMessagesAction(messages)];
              }
              return [
                fromActions.loadPdfSettingsAction(),
                fromActions.setPdfMessagesAction(this.mapper.toSuccessMessages('Image uploaded.')),
              ];
            }),
            catchError(error => of(fromActions.setPdfMessagesAction(this.mapper.toErrorMessages(error, 'Server Error')))));
      })));
}
