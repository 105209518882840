<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>
<h1>eCM Reports</h1>
<div class="panel-box margin-0">
  <cms-info ngPreserveWhitespaces>
    __eCM Reports__

    Select a time period (a full month or shorter) and click on one of the report buttons.

    **eCM Billing Details**

    Contains all (billable and non-billable) eCM matches that the CMS registered for your organisations group within the selected time period.

    The report will only include organisations to which you have OP (operator) or MO (master operator) access.

    Find more detailed field-by-field information within the report.

    **eCM Ranking Report**

    Indicates how your organisation group compares to others with respect to eCM matching.

    The report is anonymized but highlights the respective rank of your group in the following areas:

    * Confirmation Volumes
    * Match Version (%) = Percentage of your confirmations matched in version 1 (without amendment)
    * Time to Submit (%) = Percentage of your confirmations submitted on the day of execution (T+0)
    * Time to Match (%) = Percentage of your confirmations matched within T+2
  </cms-info>
  <p-messages [value]="messages$ | async" class="col-12 padding-0"></p-messages>
  <cms-reports-date-filters [filters]="startAndEndDate$ | async"></cms-reports-date-filters>
  <div class="ecm-reports__btn-container">
    <button class="btn" pButton label="eCM Billing Details" (click)="onDetails('billing')" [disabled]="disableBilling$ | async"></button>
    <button class="btn" pButton label="eCM Ranking Report" (click)="onDetails('ranking')" [disabled]="disableRanking$ | async"></button>
  </div>
</div>
</ng-template>
