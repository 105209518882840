<p-dialog *ngIf="data && display"
          [header]="header"
          [(visible)]="display"
          [modal]="true"
          (visibleChange)="onVisibleChange($event)"
          [style]="{'min-width': '600px'}">
  <p-messages [value]="messages" [closable]="true"></p-messages>
  <form [formGroup]="form">
    <label for="comment">Enter comment for audit log and confirm: <span class="required">*</span></label>
    <textarea [ngClass]="{'error': this.errors['comment']}" id="comment" name="comment" formControlName="comment" class="form-control" rows="10"></textarea>
  </form>
  <p-footer>
    <button class="btn margin-right-3" type="button" (click)="onCancel()">Cancel</button>
    <button class="btn" type="button" (click)="onConfirm()">Confirm</button>
  </p-footer>
</p-dialog>
