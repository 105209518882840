import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Message } from 'primeng/api';
import { filter, first, map, skip, switchMap, withLatestFrom } from 'rxjs/operators';

import { OrganisationService } from '@common/organisation.service';
import { OrganisationModel } from '@common/organisation.model';

import { EcmEnterTradeState } from '../state/enter-trade/enter-trade.reducers';
import * as fromAction from '../state/enter-trade/enter-trade.actions';
import * as fromSelectors from '../state/enter-trade/enter-trade.selectors';
import { EcmEnterTradeFormData, EcmEnterTradeProduct } from './enter-trade.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { NavigationService } from '@common/menu/navigation.service';
import { MenuItem } from '@common/menu/menu.model';
import { ErrorMessageService } from '@common/errors/error-message.service';

@Component({
  selector: 'cms-enter-trade',
  templateUrl: './enter-trade.component.html',
  styleUrls: ['./enter-trade.component.scss']
})
export class EnterTradeComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  messages$: Observable<Message[]> = this.store.pipe(select(fromSelectors.getMessages));
  formValues$ = this.store.pipe(select(fromSelectors.getFormValues));
  deliveryPeriod$ = this.store.pipe(select(fromSelectors.getDeliveryPeriod));
  totalVolumeData$ = this.store.pipe(select(fromSelectors.getTotalVolumeData));
  totalContractValue$ = this.store.pipe(select(fromSelectors.getTotalContractValue));
  productOptions$ = this.store.pipe(select(fromSelectors.getProductOptions));
  subscriptions$: Subscription = new Subscription();

  isEditable: boolean = false;
  id: number = null;
  editableFormData = null;
  selectedProduct$= new BehaviorSubject<{capacity:string,unit:string}>({ capacity: 'MWh', unit: 'day' });

  constructor(private formBuilder: UntypedFormBuilder,
              private store: Store<EcmEnterTradeState>,
              private organisationService: OrganisationService,
              private navigationService: NavigationService,
              private route: ActivatedRoute,
              private router: Router,
              private titleService: Title,
              public readonly errorMessageService: ErrorMessageService) {
  }

  ngOnInit(): void {
    this.subscriptions$.add(this.navigationService.onNavigationChanged()
      .pipe(
        filter(item => !!item),
        first()).subscribe(() => {
        this.store.dispatch(new fromAction.SetOrganisationIdAction(this.organisationService.getCachedOrganisationId()));
        this.store.dispatch(new fromAction.GetProductsListAction());
    }));

    this.subscriptions$.add(this.organisationService
      .getChangeOrganisationObservable()
      .pipe(
        withLatestFrom(this.store.pipe(select(fromSelectors.getOrganisationId))),
        switchMap((data: [OrganisationModel, number]) => {
          return this.navigationService.onNavigationChanged().pipe(
            skip(1),
            map((menu: MenuItem[]) => {
              return [...data, menu];
            }));
        })
      )
      .subscribe((data: [OrganisationModel, number, MenuItem[]]) => {
        const [organisation, organisationId, menu] = data;
        if (this.navigationService.containsPath('ecm/trade', menu)) {
          if ((organisation && organisation.organisationId !== organisationId) || (!organisation && organisationId)) {
            this.store.dispatch(new fromAction.SetToDefaultAction());
            this.store.dispatch(new fromAction.SetOrganisationIdAction(organisation ? organisation.organisationId : null));
            this.store.dispatch(new fromAction.GetProductsListAction());
            if (this.id) {
              this.store.dispatch(new fromAction.GetEditableItemAction(this.id));
              this.form.get('product').disable();
            }
          }
        } else {
          this.router.navigate(['/help/welcome'], { queryParamsHandling: 'preserve' });
        }
      }));

    this.form = this.formBuilder.group({
      product: { value: '', disabled: !!this.id }
    });

    this.subscriptions$.add(this.store.pipe(select(fromSelectors.getProduct))
      .subscribe((product: EcmEnterTradeProduct) => {
        this.form.patchValue({ product });
      }));

    this.subscriptions$.add(this.route.params.subscribe(params => {
      this.id = params['id'] ? +params['id'] : null;
      this.isEditable = !!this.id;
      if (this.isEditable) {
        this.store.dispatch(new fromAction.GetEditableItemAction(this.id));
        this.form.get('product').disable();
        this.subscriptions$.add(this.store.pipe(select(fromSelectors.getEditableData))
          .subscribe(editableFormData => {
            this.editableFormData = editableFormData;
            if (editableFormData) {
              this.onChangeProduct({ value: { productId: editableFormData.productSelectionId } });
            }
          }));
        this.subscriptions$.add(this.store.pipe(select(fromSelectors.getEditableId))
          .subscribe(id => {
            if (!id) {
              this.isEditable = false;
              this.editableFormData = null;
              this.form.get('product').enable();
            } else  {
              this.isEditable = true;
              this.form.get('product').disable();
            }
          }));
      }
    }));

    this.titleService.setTitle('eCM lite Trade Entry');
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
    this.store.dispatch(new fromAction.SetToDefaultAction());
  }

  onChangeProduct(product: { value: EcmEnterTradeProduct }) {
    if (!this.isEditable) {
      this.store.dispatch(new fromAction.SetToClearFormAction());
    }
    this.store.dispatch(new fromAction.SetProductAction(product.value));

    if (product.value) {
      this.store.dispatch(new fromAction.GetFormValuesAction());
    }
  }

  onFormChange(formData: EcmEnterTradeFormData) {
    this.store.dispatch(new fromAction.SetFormDataAction(formData));
  }

  onPeriodChange() {
    this.store.dispatch(new fromAction.GetDeliveryPeriodAction());
  }

  onCalculateTotalValue() {
    this.store.dispatch(new fromAction.GetTotalVolumeAction());
  }

  onCalculateTotalContractValue() {
    this.store.dispatch(new fromAction.GetTotalContractAction());
  }

  submitForm() {
    this.store.dispatch(new fromAction.SubmitFormDataAction());
  }

  amendForm() {
    this.store.dispatch(new fromAction.UpdateItemAction(this.id));
  }
}
