import {Message} from 'primeng/api';
import {Observable, Subscription} from 'rxjs';
import {withLatestFrom} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {OrganisationService} from '@common/organisation.service';
import {MessagesMapperService} from '@common/messages-mapper.service';
import {DatatableState} from '@common/datatable/datatable.model';
import {Column} from '@common/shared/datatable.model';
import {DatatableData, PagingFilter} from '@common/shared/results.model';
import {OrganisationModel} from '@common/organisation.model';
import {BillingRunParams, BillingRuns, dataTableColumns, StartBillingRun} from './billing-runs.model';
import {Prefix} from '../../../state/reducers';
import {CmsBillingRunsState} from '../../../state/billing-runs/cms/cms-runs.reducers';
import {DialogState} from '../../../state/dialog/dialog.reducer';
import {SetOrganisationIdAction} from '../../../state/organisation-id/organisation-id.actions';
import {SetToDefaultAction} from '../../../state/shared/shared.actions';
import * as fromDialogActions from '../../../state/dialog/dialog.actions';
import * as billingRunsActions from '../../../state/billing-runs/cms/cms-runs.actions';
import * as fromDtActions from '../../../state/datatable/datatable.actions';
import * as fromSelectors from '../../../state/billing-runs/cms/cms-runs.selectors';

@Component({
  selector: 'cms-billing-run',
  templateUrl: './cms-billing-run-tab.component.html',
  styleUrls: ['./cms-billing-run-tab.component.scss']
})
export class CmsBillingRunTabComponent implements OnInit, OnDestroy {
  data$: Observable<DatatableData<BillingRuns[]>> = this.store.pipe(select(fromSelectors.getData));
  paging$: Observable<PagingFilter> = this.store.pipe(select(fromSelectors.getPaging));
  loading$: Observable<boolean> = this.store.pipe(select(fromSelectors.getLoading));
  dataTableColumns: Column[] = dataTableColumns;
  messages$: Observable<Message[]> = this.store.pipe(select(fromSelectors.getMessages));
  subscription$: Subscription;
  dialog$: Observable<DialogState> = this.store.pipe(select(fromSelectors.getDialog));
  billingRunParams$: Observable<BillingRunParams> = this.store.pipe(select(fromSelectors.getBillingRunParams));
  billingRunPermission$: Observable<boolean> = this.store.pipe(select(fromSelectors.billingRunPermission));
  startBillingRunModel$: Observable<StartBillingRun> = this.store.pipe(select(fromSelectors.startBillingRun));
  isBillingRunLoading$: Observable<boolean> = this.store.pipe(select(fromSelectors.isBillinRunLoading));

  private readonly prefix: Prefix = '[BILLING RUNS]';

  constructor(private readonly store: Store<CmsBillingRunsState>,
              private readonly organisationService: OrganisationService,
              private mapper: MessagesMapperService) { }

  ngOnInit(): void {
    this.store.dispatch(new SetOrganisationIdAction(this.prefix, this.organisationService.getCachedOrganisationId()));
    this.store.dispatch(new fromDtActions.LoadDataAction(this.prefix));
    this.store.dispatch(billingRunsActions.getBillingRunParams());
    this.store.dispatch(billingRunsActions.getBillingRunStatus());
    this.onOrganisationChangeSubscription();
  }

  ngOnDestroy(): void {
    this.store.dispatch(new SetToDefaultAction(this.prefix));
    this.subscription$.unsubscribe();
  }

  onLazyLoad(state: DatatableState): void {
    this.store.dispatch(new fromDtActions.SortAndPageDataAction(this.prefix, {
      paging: {
        page: state.page,
        entriesPerPage: state.perPage
      },
      sorting: {
        columnName: state.sortField,
        sortOrder: state.sortOrder
      }
    }));
  }

  onDeleteItem(data: BillingRuns): void {
    this.store.dispatch(new fromDialogActions.OpenDialogAction(this.prefix, {
      name: 'DELETE',
      messages: [],
      data
    }));
  }

  onConfirmDelete(billingRunId: number): void {
    this.store.dispatch(billingRunsActions.deleteBillingRunAction(billingRunId));
  }

  onEditParams(data: BillingRuns): void {
    this.store.dispatch(new fromDialogActions.OpenDialogAction(this.prefix, {
      name: 'EDIT',
      messages: [],
      data
    }));
  }

  onConfirmEdit(data: BillingRunParams): void {
    this.store.dispatch(billingRunsActions.editBillingRunParams(data));
  }

  onDialogCancel(messages: Message[] = []): void {
    this.store.dispatch(new fromDtActions.SetMessagesAction(this.prefix, messages));
    this.store.dispatch(new fromDialogActions.CloseDialogAction(this.prefix));
  }

  onDownloadZip(billingRunId: number): void {
    this.store.dispatch(billingRunsActions.downloadZipAction(billingRunId));
  }

  onOpenReport(data: BillingRuns): void { }

  onSendMail(billingRunId: number): void {
    const messages = this.mapper.toWarningMessages('Sending invoice mails triggered.');
    this.store.dispatch(new fromDtActions.SetMessagesAction(this.prefix, messages));
    this.store.dispatch(billingRunsActions.sendInvoiceMailsAction(billingRunId));
  }

  onShowErrorDetails(data: BillingRuns): void {
    if (!data.errors) { return; }
    this.store.dispatch(billingRunsActions.getBillingRunErrors(data));
  }

  onStartBillingRun(data: StartBillingRun): void {
    this.store.dispatch(billingRunsActions.setStartBillingRunModel(data));
    this.store.dispatch(billingRunsActions.startBillingRun(data));
  }

  getQueryParams(billingRunId: number):any {
    return {'billingRunId': billingRunId, 'organisationId': this.organisationService.getCachedOrganisationId()};
  }

  private onOrganisationChangeSubscription(): void {
    this.subscription$ = this.organisationService
      .getChangeOrganisationObservable()
      .pipe(withLatestFrom(this.store.pipe(select(fromSelectors.getOrganisationId))))
      .subscribe(([organisation, organisationId]: [OrganisationModel, number]) => {
        if ((organisation && organisation.organisationId !== organisationId) || (!organisation && organisationId)) {

          this.store.dispatch(new SetToDefaultAction(this.prefix));
          this.store.dispatch(new SetOrganisationIdAction(this.prefix, organisation ? organisation.organisationId : null));
          this.store.dispatch(new fromDtActions.LoadDataAction(this.prefix));
        }
      });
  }
}
