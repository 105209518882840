<div class="container-fluid row justify-content-center">
  <div class="col-12">
    <div class="margin-top-60">
      <div class="box">
        <img class="margin-bottom-30" src="assets/img/equias_logo.png" />
        <h1>404</h1>
        <h2>Your request could not be processed</h2>
        <a routerLink="/help/welcome">Return</a>
      </div>
    </div>
  </div>
</div>
