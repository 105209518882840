import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { merge, Subscription } from 'rxjs';
import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import { Column, DatatableConfig, PagerInfo } from '@common/shared/datatable.model';
import { LazyLoadEvent } from 'primeng/api';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RoboDelegatorsSearchQuery, RoboDelegatorsService } from '../robo-delegators.service';
import { OrganisationService } from '@common/organisation.service';
import { StandingInstructionsService } from '../../../standing-instructions/standing-instructions.service';
import { filter, skip } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'cms-robo-delegators-datatable',
  templateUrl: './robo-delegators-datatable.component.html',
  styleUrls: ['./robo-delegators-datatable.component.scss']
})
export class RoboDelegatorsDatatableComponent implements OnInit, OnDestroy {
  subscription$: Subscription = new Subscription();
  private tableColumns: Column[] = RoboDelegatorsDatatableConfig.TABLE_COLUMNS;
  pagerInfo: PagerInfo;
  loading: boolean = false;
  records = [];
  recordCount: number;
  hidden: boolean = true;
  filterForm: UntypedFormGroup;
  defaults = DatatableConfig;
  orgId: number;
  errPartyId: number;
  showStandingInstructionDetail: boolean = false;
  presetValues$ = this.service.loadPresetValues();

  selection = {};

  constructor(private service: RoboDelegatorsService,
              private organisationService: OrganisationService,
              private formBuilder: UntypedFormBuilder,
              private standingInstructionService: StandingInstructionsService,
              private route: ActivatedRoute,
              private ref: ChangeDetectorRef) {


    this.filterForm = formBuilder.group({
      filter: ['', Validators.maxLength(255)],
    });
  }

  ngOnInit() {
    const initialData: { query, result } = this.route.snapshot.data['whiteList'];

    this.records = initialData.result.rows;
    this.recordCount = initialData.result.recordCount;

    this.pagerInfo = PagerInfo.calcPagerInfo(
      initialData.query.recordCount,
      initialData.query.paging.page,
      initialData.query.paging.entriesPerPage);

    this.subscription$
      .add(merge(this.service.onRefresh(), this.organisationService.onRefresh(), this.service.onSearchQueryChange().pipe(skip(2)))
        .pipe(filter(Boolean))
        .subscribe(() => this.loadWhitelist(this.service.getSearchQuery())));

  }

  get cols() {
    return this.tableColumns;
  }

  getRecordCount() {
    return this.recordCount;
  }

  // event fired by primeng datatable
  onLazyLoad(event: LazyLoadEvent) {

    const currentPage = event.first / event.rows;
    const pagingFilter: PagingFilter = {
      entriesPerPage: event.rows,
      page: currentPage
    };

    const columnName = event.sortField || 'leiCode';

    const sortOrder = (event.sortOrder === -1) ? 'DESCENDING' : 'ASCENDING';
    const sortingFilter: SortingFilter = { columnName, sortOrder };

    const searchQuery = this.service.getSearchQuery();

    // update search query to table status
    if (searchQuery) {
      searchQuery.paging = pagingFilter;
      searchQuery.sorting = sortingFilter;
      this.service.setSearchQuery(searchQuery);
    }
  }

  loadWhitelist(searchQuery: RoboDelegatorsSearchQuery) {
    if (searchQuery) {
      const currentPage = searchQuery.paging.page;
      const resultsPerPage = searchQuery.paging.entriesPerPage;
      this.loading = true;

      this.subscription$.add(this.service.search(searchQuery).subscribe(result => {
          this.records = result.rows;
          this.pagerInfo = PagerInfo.calcPagerInfo(result.recordCount, currentPage, resultsPerPage);
          this.recordCount = result.recordCount;

          this.loading = false;
          this.ref.detectChanges();
        },
        err => {
          this.loading = false;
          this.ref.detectChanges();
        }));
    }
  }

  filter() {
    const searchQuery = this.service.getSearchQuery();
    searchQuery.filter = this.filterForm.controls['filter'].value;
    this.service.setSearchQuery(searchQuery);
  }

  reset() {
    this.filterForm.reset();
    this.filter();
  }

  isFieldAlert(value): boolean {
    const alerting: string[] = ['Block', 'Block (DEFAULT)', 'not registered', 'unknown'];
    return alerting.indexOf(value) !== -1;
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
  }

  viewStandingInstruction(errPartyId: number) {
    this.showStandingInstructionDetail = true;
    this.errPartyId = errPartyId;

    this.standingInstructionService.loadRoboDelegatorsStandingInstruction(errPartyId);
  }

  closeModal(): void {
    this.showStandingInstructionDetail = false;
    this.standingInstructionService.standingInstruction.next(null);
    this.errPartyId = null;
  }
}

export namespace RoboDelegatorsDatatableConfig {

  export const TABLE_COLUMNS: Column[] = [
    { name: 'leiCode', title: 'LEI Code', style: { 'width': '160px', 'text-align': 'left' }, sortable: true },
    { name: 'acerCode', title: 'ACER Code', style: { 'width': '100px', 'text-align': 'left' }, sortable: true },
    { name: 'permission', title: 'Permission', style: { 'width': '100px', 'text-align': 'left' }, sortable: true },
    { name: 'errGroup', title: 'eRR Group', style: { 'width': '120px', 'text-align': 'left' }, sortable: true },
    { name: 'displayName', title: 'Display Name', style: { 'width': '120px', 'text-align': 'left' }, sortable: true },
    { name: 'timezone', title: 'Time Zone', style: { 'width': '100px', 'text-align': 'left' }, sortable: true },
    { name: 'defaultEmirRepository', title: 'Default EMIR TR', style: { 'width': '120px', 'text-align': 'left' }, sortable: true },
    { name: 'hasStandingInstruction', title: 'Std.Inst.', style: { 'width': '85px', 'text-align': 'center' }, sortable: true },
    { name: 'registeredName', title: 'Registered Name (LEI Registry)', style: { 'width': '260px', 'text-align': 'left' }, sortable: true }
  ];

}