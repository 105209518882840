<ng-container *ngIf="dialog && editableData">
  <cms-lock-inactivate-dialog *ngIf="editableData"
                              [data]="editableData"
                              [messages]="dialog?.messages"
                              [display]="dialog?.name === 'STATE'"
                              (cancel)="onCancel($event)"
                              (inactivate)="onConfirmInactivate($event)"
                              (lock)="onConfirmLock($event)">
  </cms-lock-inactivate-dialog>
  <cms-activate-dialog *ngIf="editableData"
                       [data]="editableData"
                       [messages]="dialog?.messages"
                       [display]="dialog?.name === 'STATE'"
                       (cancel)="onCancel($event)"
                       (reactivate)="onConfirmReactivate($event)">
  </cms-activate-dialog>
</ng-container>
<ng-container *ngIf="dialog && selected">
  <cms-edit-user-account-dialog [data]="selected"
                                [display]="dialog?.name === 'EDIT'"
                                [tokenIdVisible]="tokenIdVisible"
                                [messages]="dialog?.messages"
                                (cancel)="onCancel($event)"
                                (confirm)="onConfirmEditUserAccount($event)">
  </cms-edit-user-account-dialog>
  <cms-reset-password-dialog [data]="selected"
                             [display]="dialog?.name === 'RESET'"
                             [messages]="dialog?.messages"
                             (cancel)="onCancel($event)"
                             (confirm)="onConfirmResetPassword($event)">
  </cms-reset-password-dialog>
</ng-container>
<cms-add-user-account-dialog [organisationGroupId]="organisationGroupId"
                             [tokenIdVisible]="tokenIdVisible"
                             [display]="dialog?.name === 'ADD'"
                             [messages]="dialog?.messages"
                             (cancel)="onCancel($event)"
                             (confirm)="onConfirmAddUser($event)">
</cms-add-user-account-dialog>

