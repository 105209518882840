import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {EndPointConstants} from '@common/end-point-constants';
import {CommonListResponseModel, DateRangeFilter} from '@common/shared/shared.model';
import {AuthenticationService} from '../../../../auth/authentication.service';
import {OrganisationService} from '@common/organisation.service';
import {
  MultiSelect,
  RemitPresetDatesResponseModel,
  RemitTransactionFilterModel,
  RemitTransactionsFilterState
} from './remit-transactions-filter.model';
import {RemitDocumentType} from '../../dashboard/filter/remit-dashboard-filter-model';
import {RemitTransactionsSearchFilter} from '../../datatable/remit-transactions.model';

@Injectable()
export class RemitTransactionsFilterService {
  private transactionFilterStore: RemitTransactionFilterModel [] = [];
  private isAuthenticated = false;

  constructor(private authenticationService: AuthenticationService,
              private organisationService: OrganisationService,
              private httpClient: HttpClient,) {

    this.authenticationService.isAuthenticated()
      .subscribe((value) => {
        this.isAuthenticated = value;
        if (!value) {
          this.transactionFilterStore = [];
        }
      });
  }

  loadOmpNames(): Promise<any[]> {
    const organisationId = this.organisationService.getCurrentOrganisationId();
    let httpParams = new HttpParams();

    if (organisationId) {
      httpParams = httpParams.append(EndPointConstants.PARAM_ORGANISATION_ID, organisationId.toString());
    }

    return this.httpClient.get<any>('/api/err/remit/search/table1/ompNames', {
      params: httpParams
    }).pipe(map(data => {
      return data.values? data.values : [];
    })).toPromise();
  }

  loadCommodities(): Promise<any> {

    const organisationId = this.organisationService.getCurrentOrganisationId();
    let httpParams = new HttpParams();

    if (organisationId) {
      httpParams = httpParams.append(EndPointConstants.PARAM_ORGANISATION_ID, organisationId.toString());
    }

    return this.httpClient.get<any>('/api/err/remit/search/table1/commodities', {
      params: httpParams
    }).toPromise();
  }

  loadRecordTypes(): Promise<any> {

    const organisationId = this.organisationService.getCurrentOrganisationId();
    let httpParams = new HttpParams();

    if (organisationId) {
      httpParams = httpParams.append(EndPointConstants.PARAM_ORGANISATION_ID, organisationId.toString());
    }

    return this.httpClient.get<any>('/api/err/remit/search/table1/recordTypes', {
      params: httpParams
    }).toPromise();
  }

  loadActionTypes(remitDocumentType: RemitDocumentType): Promise<string[]> {

    const organisationId = this.organisationService.getCurrentOrganisationId(),
      actionTypeUrl = RemitTransactionsFilterService.getBaseUrlForRemitDocumentType(remitDocumentType) + 'actionTypes',
      httpParams = !organisationId ? new HttpParams() : new HttpParams()
        .append(EndPointConstants.PARAM_ORGANISATION_ID, organisationId.toString());


    return this.httpClient.get<CommonListResponseModel<string>>(actionTypeUrl, { params: httpParams })
      .pipe(map(response => response.values))
      .toPromise();
  }

  loadStates(remitDocumentType: RemitDocumentType): Promise<RemitTransactionsFilterState[]> {

    const organisationId = this.organisationService.getCurrentOrganisationId();
    let httpParams = new HttpParams();

    if (organisationId) {
      httpParams = httpParams.append(EndPointConstants.PARAM_ORGANISATION_ID, organisationId.toString());
    }

    const getStatesUrl = RemitTransactionsFilterService.getBaseUrlForRemitDocumentType(remitDocumentType) + 'states';
    return this.httpClient.get<CommonListResponseModel<RemitTransactionsFilterState>>(getStatesUrl, {
      params: httpParams
    }).pipe(map((response: CommonListResponseModel<RemitTransactionsFilterState>) => response.values)).toPromise();
  }

  loadDocumentTypes(remitDocumentType: RemitDocumentType): Promise<RemitDocumentType[]> {

    const organisationId = this.organisationService.getCurrentOrganisationId();
    let httpParams = new HttpParams();

    if (organisationId) {
      httpParams = httpParams.append(EndPointConstants.PARAM_ORGANISATION_ID, organisationId.toString());
    }

    const getDocTypesUrl = RemitTransactionsFilterService.getBaseUrlForRemitDocumentType(remitDocumentType) + 'documentTypes';
    return this.httpClient.get<CommonListResponseModel<RemitDocumentType>>(getDocTypesUrl, {
      params: httpParams
    }).pipe(map((response: CommonListResponseModel<RemitDocumentType>) => response.values)).toPromise();
  }

  loadMpNames(): Promise<string[]> {
    const organisationId = this.organisationService.getCurrentOrganisationId();
    let httpParams = new HttpParams();

    if (organisationId) {
      httpParams = httpParams.append(EndPointConstants.PARAM_ORGANISATION_ID, organisationId.toString());
    }

    return this.httpClient.get<any>('/api/err/remit/search/table1/mpNames', {
      params: httpParams
    }).pipe(map(data => {
      return data.values? data.values : [];
    })).toPromise();
  }

  storeTransactionFilter(remitDocumentType: RemitDocumentType, filter: RemitTransactionFilterModel) {
    if (this.isAuthenticated) {
      this.transactionFilterStore[remitDocumentType] = filter;
    }
  }

  loadTransactionFilter(remitDocumentType: RemitDocumentType): RemitTransactionFilterModel {
    return this.transactionFilterStore[remitDocumentType];
  }

  loadSenderNames(): Promise<string[]> {
    const organisationId = this.organisationService.getCurrentOrganisationId();
    let httpParams = new HttpParams();

    if (organisationId) {
      httpParams = httpParams.append(EndPointConstants.PARAM_ORGANISATION_ID, organisationId.toString());
    }

    return this.httpClient.get<any>('/api/err/remit/search/table1/senderNames', {
      params: httpParams
    }).pipe(map(data => {
      return data.values? data.values : [];
    })).toPromise();
  }

  createRemitTransactionsSearchQuery(filter: RemitTransactionFilterModel, multiSelect?: MultiSelect): RemitTransactionsSearchFilter {

    const noActionFilter = (!!filter.actionTypes && filter.actionTypes.length === multiSelect.actionTypes.length) || (!!!filter.actionTypes),
      noStateFilter = (filter.states && filter.states.length === multiSelect.states.length) || (!!!filter.states);

    return {
      searchMode: 'SEARCH_FILTER',
      remitDocumentType: filter.documentType,
      transactionTime: RemitTransactionsFilterService.getAsDateRange(filter.transactionTimeStart, filter.transactionTimeEnd),
      receivedByErr: RemitTransactionsFilterService.getAsDateRange(filter.receivedByErrStart, filter.receivedByErrEnd),
      lastUpdate: RemitTransactionsFilterService.getAsDateRange(filter.lastUpdateStart, filter.lastUpdateEnd),
      contractDate: RemitTransactionsFilterService.getAsDateRange(filter.contractDateStart, filter.contractDateEnd),
      creationDate: RemitTransactionsFilterService.getAsDateRange(filter.creationDateStart, filter.creationDateEnd),
      gasDayUtc: RemitTransactionsFilterService.getAsDateRange(filter.gasDayUtcStart, filter.gasDayUtcEnd),
      ...filter, // ugly: fields not belonging to RemitTransactionsSearchQuery will be added
      actionTypes: noActionFilter ? null : filter.actionTypes,
      states: noStateFilter ? null : filter.states,
    };
  }

  private static getAsDateRange(start: Date, end: Date): DateRangeFilter {
    if (start || end) {
      return new DateRangeFilter(start, end);
    }

    return null;
  }

  loadInitialStartReceivedByErrDate(): Promise<any> {
    const organisationId = this.organisationService.getCurrentOrganisationId();
    let httpParams = new HttpParams();

    if (organisationId) {
      httpParams = httpParams.append(EndPointConstants.PARAM_ORGANISATION_ID, organisationId.toString());
    }

    return this.httpClient.get<RemitPresetDatesResponseModel>('/api/err/remit/transactions/common/presetDates', {
      params: httpParams
    }).toPromise();
  }

  private static getBaseUrlForRemitDocumentType(remitDocumentType: RemitDocumentType): string {
    switch (remitDocumentType) {
      case 'REMIT_TABLE_1':
        return '/api/err/remit/search/table1/';
      case 'REMIT_TABLE_2':
        return '/api/err/remit/search/table2/';
      case 'ELECTRICITY_RIGHTS':
        return '/api/err/remit/search/table3/';
      case 'GAS_CAPACITY':
        return '/api/err/remit/search/table4/';
      case 'FUNDAMENTALS':
        return '/api/err/remit/search/fundamentals/';
    }
  }
}
