import { FormGroup } from '@angular/forms';

export class RemitSearchModel{
  public utiSearchForm: string;
  public transactionIdForm: string;
  public contractIdForm: string;
  public uniqueIdForm: string;
  public referenceIdForm: string;
  public filenameForm: string;
}
