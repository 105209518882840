import {createSelector} from '@ngrx/store';
import {getEsmInvoiceFinderState} from '../invoice-finder.selectors';
import {EsmInvoiceFinderState} from '../invoice-finder.reducers';
import {EsmInvoiceTableState} from './table.reducer';

export const getTable = createSelector(getEsmInvoiceFinderState, (state: EsmInvoiceFinderState) => state.table);
export const getMessages = createSelector(getTable, (state: EsmInvoiceTableState) => state.messages);
export const getLoading = createSelector(getTable, (state: EsmInvoiceTableState) => state.loading);

export const getData = createSelector(getTable, (state: EsmInvoiceTableState) => state.data);
export const getPaging = createSelector(getTable, (state: EsmInvoiceTableState) => state.paging);
export const getSorting = createSelector(getTable, (state: EsmInvoiceTableState) => state.sorting);
export const getInvoiceFinderHtml = createSelector(getTable, (state: EsmInvoiceTableState) => state.invoiceFinderHtml);
export const getSelectedIds = createSelector(getTable, (state: EsmInvoiceTableState) => state.selectedIds);
export const getSelectedInvoices = createSelector(getTable, (state: EsmInvoiceTableState) => state.invoices);
export const getColumns = createSelector(getTable, (state: EsmInvoiceTableState) => state.columns);
