import { createAction, union } from '@ngrx/store';
import { PartyDataEditableModel } from '../../administration-tab-panel/party-data/party-data.model';
import { Message } from 'primeng/api';

export const addPartydataAction = createAction('[PARTY DATA] Add Party Data', (payload: PartyDataEditableModel) => ({ payload }));
export const deletePartyDataAction = createAction('[PARTY DATA] Delete Party Data', (payload: string) => ({ payload }));
export const updateAddPartyDataAction = createAction('[PARTY DATA] Update Add Party Data', (payload: PartyDataEditableModel) => ({ payload }));
export const editPartyDataAction = createAction('[PARTY DATA] Edit Party Data', (payload: PartyDataEditableModel) => ({ payload }));
export const setMessagesAction = createAction('[PARTY DATA] Set Party Data Messages', (payload: Message[]) => ({ payload }));

const actions = union({
  updateAddPartyDataAction,
  editPartyDataAction,
  deletePartyDataAction,
  addPartydataAction,
  setMessagesAction
});

export type PartyDataActions = typeof actions;
