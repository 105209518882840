<div class="panel-box margin-left-50 margin-right-50 margin-top-50">
  <h1>Delete Record(s)</h1>
  <div id="divRemitTradeErrorFeedback">
    <p-messages [(value)]="msgs" [closable]="false"></p-messages>
  </div>
  <ng-container *ngIf="!internalError">
    <div class="p-warn margin-bottom-15">
      <div class="absolute top-3">
        <i class="fa fa-exclamation-triangle fa-2x"></i>
      </div>
      <div class="padding-left-30 margin-left-15">
        <b>Confirm you wish to delete this REMIT trade record from ARIS by sending an error ("E") record.</b>
        <p></p>
        Please note:
        <ul class="list-style-inherit">
          <li>this action will void the below trade record in ARIS as if not reported (logical delete)</li>
          <li>ARIS will also delete other records for this trade having the same contract date</li>
          <li>you cannot undo this action other than by resending the record(s)</li>
        </ul>
        <p>Press "Delete Record(s)" to proceed or close this tab to abort.</p>
      </div>
    </div>
    <div class="col-12 text-center">
      <button id="btnRemitTradeErrorSubmit" class="btn margin-3 padding-6" type="button" (click)="submitDelete()" [disabled]="(disabled || !(submitEnabled$ | async))">
        Delete Record(s)
        <p-progressSpinner class="margin-top-9" *ngIf="isInProgress()" [style]="{width: '15px', height: '15px'}" strokeWidth="8"></p-progressSpinner>
      </button>
    </div>
  </ng-container>
</div>
