import { Column } from '@common/shared/datatable.model';
import { ErrorResponse } from '@common/error-response.model';

export interface SendingFailuresSearchRequest {
  fromDate: Date | string;
  fromTime: string;
  toDate: Date | string;
  toTime: string;
}

export interface SendingFailuresPresetValuesModel extends ErrorResponse {
  from: Date | string;
  to: Date | string;
}

export interface SendingFailuresValues {
  count: number;
  errorCode: string;
  errorDetails: string[];
  fromDate: string;
  fromTime: string;
  receiverId: string;
  receiverOrganisation: string;
  receiverOrganisationGroup: string;
  senderId: string;
  toDate: string;
  toTime: string;
  xpServiceKeyHash: string;
}

export interface SendingFailuresResendResponse extends ErrorResponse {
  successfullyResentCount: number;
  errors: { [key: string]: string };
}

export interface SendingFailuresResponse extends ErrorResponse {
  values: SendingFailuresValues[];
}

export interface SendingFailuresSelected {
  [rowIndex: number]: SendingFailuresValues;
}

export const defaultStyles = {
  'text-align': 'left',
  'text-overflow': 'ellipsis',
  'overflow': 'hidden',
  'white-space': 'nowrap'
};

export const allColumnsConfig: Column[] = [
  { name: 'receiverOrganisationGroup', type: 'text', title: 'Receiver Org. Group', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'receiverOrganisation', type: 'text', title: 'Receiver Organisation', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'senderId', type: 'text', title: 'Sender', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'receiverId', type: 'text', title: 'Receiver', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'errorCode', type: 'text', title: 'Error Code', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'count', type: 'text', title: 'Count', style: { ...defaultStyles, 'text-align': 'right' }, sortable: true, visible: true },
  { name: 'errorDetails', type: 'button', title: 'Details', style: { ...defaultStyles, 'text-align': 'center' }, sortable: false, visible: true },
  { name: 'select', type: 'check', title: 'Select', style: { ...defaultStyles, 'text-align': 'center' }, sortable: false, visible: true },
  { name: 'fromDate', type: '', title: '', style: { ...defaultStyles }, sortable: false, visible: false },
  { name: 'fromTime', type: '', title: '', style: { ...defaultStyles }, sortable: false, visible: false },
  { name: 'toDate', type: '', title: '', style: { ...defaultStyles }, sortable: false, visible: false },
  { name: 'toTime', type: '', title: '', style: { ...defaultStyles }, sortable: false, visible: false },
  { name: 'xpServiceKeyHash', type: '', title: '', style: { ...defaultStyles }, sortable: false, visible: false }
];
