<cms-info ngPreserveWhitespaces>
  <!--@formatter:off (markdown rendering breaks, when the following tags are formatted)-->
  __eRR Dashboard (CpML only)__

  This screen is for monitoring reporting under EMIR to an EMIR trade repository. It offers a limited view on REMIT trades (see below).
  It shows the reporting state of trade records in a state / age matrix. Click on the record counts to drill down to the affected records.

  **EMIR Reporting**
  eRR EMIR reporting is a completely automated process in which your organisation submits electronic documents in CpML format (Commodity Product Markup Language). Your documents are subjected to a validation and enrichment stage after which they are forwarded to your EMIR trade repository. CpML documents bear a version number - the Dashboard only reflects the latest submitted version.
  The Dashboard supports all EMIR document types: OTC commodity (CNF), OTC currency (FXT), OTC interest rates (IRT) and exchange traded products (ETD) of various asset classes.

  **REMIT Reporting**
  A separate REMIT Dashboard is available to monitor REMIT-specific report types in a single place. This dashboard only covers REMIT standard trades if your company submits them in CpML format (CNF or ETD documents). After successful validation and enrichment they are transformed to the ACER report format "REMITTable1" and from them on available in the REMIT Dashboard.
  This dashboard does not cover any submissions in the ACER document formats or sent through alternative submission channels (manual entry, manual upload). It can still be useful to analyse enrichment and validation issues with your CpML-based REMIT submissions.

  **Using the Filters**
  * Your **Legal Entity** is chosen in the title bar - results will show all records sent by it or on its behalf
  * **Trade Date / Submission Date**: the age of trades is shown in terms of their execution date or submission to eRR
  * **Trade Repository**: useful to separate EMIR trades (choose your EMIR TR), REMIT Trades (choose "ACER") and trades reported to ElCom (choose "ElCom")
  * **Asset Class**: asset class in terms of EMIR, all REMIT trades are commodity trades
  * **OTC** vs **ETD**: documents in OTC formats (CNF/FXT/IRT) vs documents in exchange format (ETD)

  **Interpreting the States**

  Dashboard states are subdivided into three categories shown on separate tabs: "In progress", "Issue" (actionable) and "Complete" (successful).

  | Category    | State                   | Meaning                   |
  | :---------- | :---------------------- |:------------------------- |
  | In Progress | Pending                 | Sent to trade repository awaiting response. |
  | In Progress | Alleged                 | Counterparty submitted an EMIR report through eRR for which I have not reported my trade side (under the same UTI) yet. |
  | In Progress | Retry                   | Enrichment failure. CMS service team checks for missing data and will manually retry or reject the report. |
  | Issue	      | Rejected - eRR          | Validation failure within eRR, trade will not be sent to the repository. Correction required. |
  | Issue	      | Rejected - TR           | Trade repository rejected the submission. Correction required. |
  | Issue	      | Communication prob - TR | Submission held due to a connection failure to the repository. CMS service team will inspect the issue. |
  | Issue	      | Processing - eRR        | Stuck in eRR processing due to a technical problem. CMS service team will inspect the issue. |
  | Complete    | Reported to TR          | Successfully reported and acknowledged by the repository. |
  | Complete    | Not reportable          | Deal type not eligible for reporting. Only applies if eligibility processing delegated to eRR (processing instruction "CmsReport"). |
  <!--@formatter:on-->
</cms-info>
<cms-cpml-dashboard-filter [category]="category"></cms-cpml-dashboard-filter>
<cms-cpml-bucket [category]="category"></cms-cpml-bucket>
