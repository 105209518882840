<p-dialog 
  *ngIf="data && display" 
  [header]="header" 
  [visible]="display" 
  [modal]="true" 
  (visibleChange)="onCancel()"
  [style]="{width: '700px'}">
  <p-messages [value]="errorMessages" [closable]="true"></p-messages>
  <cms-billing-params-form 
    #form 
    [formData]="data" 
    [messages]="errorMessages">
  </cms-billing-params-form>
  <p-footer>
    <button 
      class="btn margin-right-3" 
      id="billing-params-edit-cancel-button" 
      type="button"
      (click)="onCancel()">
      Cancel
    </button>
    <button class="btn" type="button" id="billing-params-edit-submit-button" (click)="onConfirm()">Update</button>
  </p-footer>
</p-dialog>
