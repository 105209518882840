import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { AuthenticationService } from '../../../auth/authentication.service';
import { OrganisationService } from '../../../common/organisation.service';
import { SmtTransactionsBucketsResponseModel } from './smt-dashboard.model';
import { EndPointConstants } from '../../../common/end-point-constants';
import { catchError, map } from 'rxjs/operators';
import { TreeNode } from 'primeng/api';

@Injectable()
export class SmtDashboardService {

  private apiUrl = '/api/err/smt/transactions/buckets';

  constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService, private organisationService: OrganisationService) {

  }

  getDashboard(): Observable<TreeNode[]> {
    return this.fetchDashboard().pipe(
      map(this.mapBucketsToTreeTableModel));
  }

  private fetchDashboard(): Observable<SmtTransactionsBucketsResponseModel> {
    let params = new HttpParams();
    if (this.organisationService.getCachedOrganisationId()) {
      params = params.append(EndPointConstants.PARAM_ORGANISATION_ID, this.organisationService.getCachedOrganisationId().toString());
    }
    return this.httpClient.get<SmtTransactionsBucketsResponseModel>(this.apiUrl, { params: params }).pipe(
      map((response: SmtTransactionsBucketsResponseModel) => response),
      catchError(this.handleError));
  }

  private mapBucketsToTreeTableModel(bucketsData: SmtTransactionsBucketsResponseModel): TreeNode[] {
    const data = bucketsData.data;
    return (data || []).map(regimes => {
      const regimeEntries = regimes.regimeEntries;
      return {
        'expanded': true,
        'data': {
          'disabled': true,
          'regime': regimes.regime
        },
        'children': (regimeEntries || []).map(regimeEntry => {
          const status = regimeEntry.status;
          const statusEntries = regimeEntry.statusEntries;
          return {
            'data': {
              'data-line': true,
              'status': status,
              '_regime': regimes.regime,
              ...regimeEntry.sumStatusEntries,
            },
            'children': (statusEntries || []).map(statusEntry => {
              return {
                'data': {
                  'leaf': true,
                  '_status': status,
                  '_regime': regimes.regime,
                  ...statusEntry
                }
              };
            })
          };
        })
      };
    });
  }

  private handleError(error: HttpErrorResponse) {
    const errMsg = (error.message) ? error.message : 'Server error';
    const empty: SmtTransactionsBucketsResponseModel = { data: [], organisationId: null };
    return of(empty);
  }

}
