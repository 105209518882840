import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserInformationModel } from '@common/user-informations.model';
import { EndPointConstants } from '@common/end-point-constants';
import { Credentials } from './credentials.model';
import { StateService } from '@common/state.service';
import { LoginResultModel } from './login-result.model';

@Injectable()
export class AuthenticationService {
  loginRoute: string = '/login';
  private authUrl = '/api/user/info';
  private loginUrl = '/api/auth/login';
  private logoutUrl = '/api/auth/logout';
  private authoriseUrl = '/cms/gui/authorise';
  private user: UserInformationModel;
  private authenticated = new BehaviorSubject<boolean>(false);
  private authError = new BehaviorSubject<any>(null);
  private authorisation = new BehaviorSubject<any>({});
  private access = new BehaviorSubject<boolean>(false);

  get hasAccess(): boolean {
    return this.access && this.access.getValue();
  }

  get hasAccessChange(): Observable<boolean> {
    return this.access && this.access.asObservable();
  }


  constructor(private router: Router,
              private httpClient: HttpClient,
              private stateService: StateService) {

    this.authenticated.next(false);

    if(this.httpClient){
      this.authenticate();
    }
  }

  setAuthenticated(value: boolean) {
    this.authenticated.next(value);
  }


  login(credentials: Credentials): Observable<LoginResultModel> {
    const urlSearchParams = new HttpParams();
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    this.stateService.reset();
    headers = headers.append(EndPointConstants.HEADER_PARAM_AUTHENTICATION, btoa(unescape(encodeURIComponent(JSON.stringify(credentials)))));
    // this.setLoginRoute();

    return this.httpClient
      .post(this.loginUrl, null,
        // observe only works inline :/
        {
          headers: headers,
          observe: 'response',
          params: urlSearchParams
        }).pipe(map((response: HttpResponse<LoginResultModel>) => {

        const changePasswordImmediately = response.body.changePasswordImmediately;

        this.setLoginRoute();

        if(this.httpClient) {
          this.authenticate();
        }
          return <LoginResultModel>{ success: true, changePasswordImmediately: changePasswordImmediately };

      }));
  }

  logout() {
      this.httpClient.get(this.logoutUrl, {}).subscribe(response => {
        this.logoutSuccess();
      });
  }

  /**
   * @param {string} path: when falsy, currentPath is used
   * @param {number} organisationId
   * @returns {Promise<boolean>}
   */
  authorise(path: string, organisationId: number): Promise<boolean> {
      let params = new HttpParams();

      // auth guard
      if (path) {
          params = params.append('path', path);
      }else {
          params = params.append('path',this.getCurrentPath());
        }

      if (organisationId) {
        params = params.append(EndPointConstants.PARAM_ORGANISATION_ID, organisationId.toString());
      }

      const options = { params: params };

      return this.httpClient.get(this.authoriseUrl, options).pipe(map(
        (response: boolean) => {

          this.access.next(response);
          return this.access.getValue() != null && !this.forcePassword();
        }
      ), catchError((err) => this.handleAuthoriseError(err))).toPromise();
  }

  public authorisUrl(path: string, organisationId: number): Promise<boolean> {
    let params = new HttpParams();

    // auth guard
    if (path) {
      params = params.append('path', path);
    }else {
      params = params.append('path',this.getCurrentPath());
    }

    if (organisationId) {
      params = params.append(EndPointConstants.PARAM_ORGANISATION_ID, organisationId.toString());
    }

    const options = { params: params };

    return this.httpClient.get(this.authoriseUrl, options).pipe(map(
      (response: boolean) => {
        return response;
      }
    ), catchError((err) => this.handleAuthoriseError(err))).toPromise();
  }

  getUser() {
    return this.user;
  }

  authenticate() {
    if (this.hasXSRFToken() && !window.location.pathname.includes('reset_password')) {
      const options = {};
      this.httpClient.get(this.authUrl, options).subscribe(
        data => this.handleAuthentication(data)
      );
   }
  }

  handleAuthentication(response) {
    this.user = response;
    this.setAuthenticated(true);
    this.authError.next(null);
  }

  // called by interceptor http error interceptor
  handleAuthError(error: any) {
    this.user = null;
    if(error && error.url){
      const path = this.getUrlPath(error.url);
      switch (path){
        case this.logoutUrl:
        case this.loginUrl: this.onLoginFailed(error); break;
        default: this.onRequestUnauthorised(error); break;
      }
    }
  }

  isAuthenticated(): Observable<boolean> {
    return this.authenticated.asObservable();
  }

  forcePassword(): boolean {
    return this.user && this.user.passwordExpirationImmediately;
  }


  onRequestForbidden(){
    this.navigateLogin();
  }

  onRequestUnauthorised(error){
    this.setAuthenticated(false);
    this.authError.next(error);
    this.navigateLogin();
  }

  onAuthenticationError(): Observable<any>{
    return this.authError.asObservable();
  }


  private navigateLogin() {
    if (this.router.url && this.router.url.indexOf('/login') < 0) {
      const loginUrl = this.stateService.loginRoute || this.loginRoute;
      this.router.navigate([loginUrl], { queryParamsHandling: 'preserve' });
    }
  }

  private handleLoginError(error: HttpErrorResponse): Observable<LoginResultModel> {
    return of(<LoginResultModel>{ success: false });
  }

  private handleAuthoriseError(error: any): Observable<boolean> {
    this.authorisation.next({});
    this.access.next(false);
    return observableThrowError(error);
  }

  private getCurrentPath(): string {
    return this.removeLeadingSlashAndQueryFrom(this.router.url);
  }

  private removeLeadingSlashAndQueryFrom(url: string): string {
    return url.replace(/\/([^?]+).*/, '$1');
  }


  private onLoginFailed(error) {
    this.setAuthenticated(false);
    const authError = {
      statusText: 'Access denied.',
      ...error
    };
    this.authError.next(authError);
    this.navigateLogin();
  }

  private setLoginRoute(): void{
    if (this.router.url && this.router.url.indexOf('/token') > 0){
      this.stateService.setLoginRoute('/login/token');
    } else {
      this.stateService.setLoginRoute('/login');
    }
  }

  hasXSRFToken():boolean {
    return this.stateService.get('XSRF-TOKEN') != null;
  }

  private logoutSuccess() {
    this.loginRoute = this.stateService.loginRoute;
    this.user = null;
    this.setAuthenticated(false);
    this.router.navigate([this.loginRoute]);
    this.stateService.reset();
  }

  private getUrlPath(url: string): string {
    let path: string = null;

    if(url){
      const urlObject: URL = new URL(url);

      path = urlObject.pathname;
    }

    return path;
  }
}
