import { createAction, props, union } from '@ngrx/store';
import { SettingsTableModel } from '../../../settings/tolerance/tolerance.model';
import { Message } from 'primeng/api';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';

export const loadTableData = createAction('[ESM-TOLERANCE-SETTINGS] load tolerance table');
export const loadTableDatSuccess = createAction('[ESM-TOLERANCE-SETTINGS] load tolerance table success', props<{ data: DatatableData<SettingsTableModel[]>}>());
export const pagingAndSorting = createAction('[ESM-TOLERANCE-SETTINGS] paging', props<{ sorting: SortingFilter, paging: PagingFilter }>());
//
export const loadPresets = createAction('[ESM-TOLERANCE-SETTINGS] load presets');
export const loadPresetsSuccess = createAction('[ESM-TOLERANCE-SETTINGS] load presets success', props<{ supportToleranceMatching:boolean, editable:boolean }>());
//
export const triggerCheckbox = createAction('[ESM-TOLERANCE-SETTINGS] set to default', props<{ checked: boolean }>());
export const triggerCheckboxSuccess = createAction('[ESM-TOLERANCE-SETTINGS] set to default success', props<{ checked: boolean }>());

export const commitRow = createAction('[ESM-TOLERANCE-SETTINGS] commit tolerance row', props<{ field:'absTolerance' | 'prectTolerance', data: SettingsTableModel}>());
export const updateRow = createAction('[ESM-TOLERANCE-SETTINGS] update tolerance row', props<{ data: SettingsTableModel}>());
export const resetRow = createAction('[ESM-TOLERANCE-SETTINGS] reset tolerance row');

//
export const loading = createAction('[ESM-TOLERANCE-SETTINGS] loading tolerance table', props<{ load: boolean }>());
export const setMessages = createAction('[ESM-TOLERANCE-SETTINGS] set messages', props<{ messages: Message[] }>());
export const cleanState = createAction('[ESM-TOLERANCE-SETTINGS] clear tolerance state');


export type EsmToleranceSettingsAction =
  | typeof triggerCheckbox
  | typeof triggerCheckboxSuccess
  | typeof loadTableData
  | typeof loadTableDatSuccess
  | typeof loadPresets
  | typeof loadPresetsSuccess
  | typeof pagingAndSorting
  | typeof setMessages
  | typeof updateRow
  | typeof commitRow
  | typeof cleanState
  | typeof loading;


