import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { catchError, finalize, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { ApiRequestService } from '@common/api-request.service';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { PermissionsState } from './permissions.reducer';
import {
  loading,
  loadPresets,
  loadPresetsSuccess,
  loadTable,
  loadTableSuccess, resetEntries,
  resetMessages,
  setMessages,
  updateEntries, updateStoreEntries
} from './permissions.actions';
import { getPaging, getSorting } from './permissions.selectors';
import { Message } from 'primeng/api';

@Injectable()
export class PermissionsEffects {
  private baseUrl = '/api/esm/permissions';

  constructor(private actions$: Actions,
              private apiGateway: ApiRequestService,
              private store$: Store<PermissionsState>,
              private mapper: MessagesMapperService) {
  }

  load_table$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTable),
      withLatestFrom(this.store$.pipe(select(getPaging)), this.store$.pipe(select(getSorting))),
      tap(() => this.store$.dispatch(loading({ isLoading: true }))),
      switchMap(([, paging, sorting]) => this.apiGateway.post(`${this.baseUrl}/loadData`, { sorting, paging })
        .pipe(
          switchMap(response => {
            const { values, currentPage, count, messages } = this.mapResponse(response);
            return messages?.length ?
              of(setMessages({ messages })) :
              of(loadTableSuccess({ data: { values, count, currentPage } }));
          }),
          catchError(error => of(setMessages(this.mapper.toErrorMessagesObj(error.errorMessage || 'Server Error')))),
          finalize(() => this.store$.dispatch(loading({ isLoading: false }))))
      )
    )
  );

  load_presets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPresets),
      tap(() => this.store$.dispatch(loading({ isLoading: true }))),
      switchMap(() =>
        this.apiGateway.get(`${this.baseUrl}/loadPresetValues`)
          .pipe(
            switchMap(response => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              const { editAllowed } = response.value;
              return messages?.length ?
                of(setMessages({ messages })) :
                of(resetMessages(), loadPresetsSuccess({ editAllowed }));
            }),
            catchError(error => of(setMessages(this.mapper.toErrorMessagesObj(error.errorMessage || 'Server Error')))),
            finalize(() => this.store$.dispatch(loading({ isLoading: false }))))
      )
    )
  );

  save_entries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateEntries),
      tap(() => this.store$.dispatch(loading({ isLoading: true }))),
      switchMap(({ selectedPermissions, newPermission }) =>
        this.apiGateway.post(`${this.baseUrl}/update`, { selectedPermissions, newPermission })
          .pipe(
            switchMap(response => {
              const messages: Message[] = this.mapper.toErrorMessages(response); // TODO there may be a positive message to preview
              return messages?.length ?
                of(setMessages({ messages }), resetEntries()) :
                of(setMessages({messages:this.mapper.toSuccessMessages('Successfully update')}), loadTable());
            }),
            catchError(error => of(setMessages(this.mapper.toErrorMessagesObj(error.errorMessage || 'Server Error')))),
            finalize(() => this.store$.dispatch(loading({ isLoading: false }))))
      )
    )
  );

  // ======  helper ====== //

  mapResponse = (response) => {
    const { values, currentPage, count } = response;
    const messages = this.mapper.toErrorMessages(response);

    return { values, currentPage, count, messages };
  }

}
