import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '../../common/user.service';
import { UserInformationModel } from '../../common/user-informations.model';
import { OrganisationService } from '../../common/organisation.service';
import { OrganisationModel } from '../../common/organisation.model';
import { Message, SelectItem } from 'primeng/api';

@Component({
  selector: 'cms-account',
  templateUrl: './account.component.html'
})
export class AccountComponent implements OnChanges, OnDestroy, OnInit {
  accountForm: UntypedFormGroup;
  userInformations: UserInformationModel;
  organisations: SelectItem[];
  accountChanged: boolean;
  messages: Message[] = [];
  private onDestroy$ = new Subject();

  constructor(private formBuilder: UntypedFormBuilder,
              private userService: UserService,
              private organisationService: OrganisationService)  {
  }

  initFormValues(userInfos): void {
    if (userInfos) {
      this.accountForm.patchValue(
        {
          userName: userInfos.userName,
          firstName: userInfos.firstName,
          lastName: userInfos.lastName,
          email: userInfos.email,
          organisation: userInfos.organisation ? + userInfos.organisation : userInfos.organisation,
          job: userInfos.job,
          phone: userInfos.phone,
          mobile: userInfos.mobile,
          contactOther: userInfos.contactOther,
        });
      this.accountForm.updateValueAndValidity();
    }
  }

  onSubmit(): void {
    this.userInformations.organisation = this.accountForm.value.organisation;
    this.userInformations.job = this.accountForm.value.job;
    this.userInformations.phone = this.accountForm.value.phone;
    this.userInformations.mobile = this.accountForm.value.mobile;
    this.userInformations.contactOther = this.accountForm.value.contactOther;
    this.userService.updateAccount(this.userInformations).subscribe(
      user => {
        this.accountChanged = false;
        this.messages = [];
        this.messages.push(
          { severity: 'success', summary: 'Success!', detail: 'Saved account changes!' }
        );
      },
      err => {
        this.messages = [];
        this.messages.push(
          { severity: 'error', summary: 'Error!', detail: 'Failed to save account changes!' }
        );
      }
    );
  }

  getUpdateDisabled(): boolean {
    return !this.accountChanged || !this.accountForm.valid;
  }

  ngOnChanges(): void {
    this.userInformations.organisation = this.userInformations.organisation ?
      +this.userInformations.organisation : this.userInformations.organisation;
    this.userInformations.job = this.accountForm.value.job;
    this.userInformations.phone = this.accountForm.value.phone;
    this.userInformations.mobile = this.accountForm.value.mobile;
    this.userInformations.contactOther = this.accountForm.value.contactOther;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  ngOnInit(): void {
    this.userInformations = {};

    this.accountForm = this.formBuilder.group({
      userName: [{ value: this.userInformations.userName, disabled: true }, Validators.required],
      lastName: [{ value: this.userInformations.lastName, disabled: true }, Validators.required],
      firstName: [{ value: this.userInformations.firstName, disabled: true }, Validators.required],
      email: [{ value: this.userInformations.email, disabled: true }, Validators.required],
      organisation: [this.userInformations.organisation ? + this.userInformations.organisation : this.userInformations.organisation],
      job: [this.userInformations.job],
      phone: [this.userInformations.phone],
      mobile: [this.userInformations.mobile],
      contactOther: ['']
    });

    this.userService.getInformations().pipe(
      takeUntil(this.onDestroy$))
      .subscribe(informations => {
          this.userInformations = informations;
          this.initFormValues(this.userInformations);
        },
        err => {
        });

    this.organisationService.subscribeOrganisations().subscribe((organisations: OrganisationModel[]) => {
      this.organisations = [{ label: '', value: null }];
      const orgs = organisations.map(organisation => ({
        label: `${organisation.displayName} (${organisation.eicCode})`,
        value: organisation.organisationId
      }));
      this.organisations = this.organisations.concat(orgs);

      if (this.organisations != null && this.userInformations != null) {

        const containsDefaultOrg = this.organisations.some(i => i.value?.toString() === this.userInformations.organisation);

        if (!containsDefaultOrg && this.userInformations.organisation) {

          this.accountForm.patchValue({
            organisation: null
          });
          this.accountForm.updateValueAndValidity();

          this.accountChanged = true;
          this.messages = [];
          this.messages.push(
            { severity: 'error', summary: 'Error!', detail: 'Current default organisation is not assigned to user \''
             + this.userInformations.userName + '\' through his roles. Please select a different default organisation.'});
        }
      }
    });

    this.accountForm.valueChanges.subscribe(data => {
      this.accountChanged = true;
    });
  }
}
