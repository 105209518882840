<div>
  <form [formGroup]="remitDashboardFilterFormGroup">
    <fieldset>
      <div class="container row">
        <div class="col-sm-3 flex flex-col">
          <legend class="margin-bottom-3 font-bold !text-sm">View by</legend>
          <label class="margin-right-9 ps-[15px]">
            <input type="radio" class="margin-right-3" name="viewBy" value="TRANSACTION_DATE" formControlName="viewBy">
            Trade Date
          </label>
          <label class="margin-right-9 ps-[15px]">
            <input type="radio" class="margin-right-3" name="viewBy" value="SUBMISSION_DATE" formControlName="viewBy">
            Submission Date
          </label>
        </div>

        <div class="col-sm-3">
          <legend class="margin-bottom-3 !font-bold  !text-sm">Document Type</legend>
          <div class="col-12 ps-[15px]">
            <p-dropdown
              [options]="documentTypeOptions"
              formControlName="documentType"
              [style]="{width: '100%'}">
            </p-dropdown>
          </div>
        </div>

        <div class="col-sm-4">
          <legend class="margin-bottom-3 !font-bold !text-sm">Reporting Role</legend>
          <div class="col-12">
            <label class="margin-right-9 ps-[15px]">
              <input type="radio" class="margin-right-3" name="reportingRoleType" value="MARKET_PARTICIPANT" formControlName="reportingRoleType">
              Market Participant
            </label>
          </div>
          <div class="col-12">
            <label class="margin-right-9 ps-[15px]">
              <input type="radio" class="margin-right-3" name="reportingRoleType" value="SENDER" formControlName="reportingRoleType">
              Sender
            </label>
          </div>
        </div>

        <div class="justify-content-end">
          <button type="submit" class="btn right" (click)="submit()">Search</button>
        </div>
      </div>
    </fieldset>
  </form>


</div>
