import { Action } from '@ngrx/store';
import { OrganisationGroupsResponse } from '../../contacts/contacts.model';

export const LOAD_ORGANISATION_GROUPS_SUCCESS = '[CONTACTS] Load Organisation Groups Success';
export const LOAD_ORGANISATION_GROUPS = '[CONTACTS] Load Organisation Groups';

export class LoadOrganisationGroupsAction implements Action {
  readonly type = LOAD_ORGANISATION_GROUPS;
}

export class LoadOrganisationGroupsSuccessAction {
  readonly type = LOAD_ORGANISATION_GROUPS_SUCCESS;
  constructor(public payload: OrganisationGroupsResponse) { }
}
