import { Pipe, PipeTransform } from '@angular/core';
import { DateUtils, TransformDateFormat } from '../../common/shared/date.utils';

@Pipe({
  name: 'absolute'
})

export class AbsolutePipe implements PipeTransform {

  transform(value: number): number {
    return Math.abs(value);
  }

}
