<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>

  <h1>eCM Deal Finder</h1>
  <div class="panel-box margin-0">
    <cms-info ngPreserveWhitespaces>
      The Deal Finder allows a user to search for specific confirmations within an organisation.

      __Filters__

      The left section of the Deal Finder is dedicated to searching confirmations by various identifiers:
      * **Our Trade ID** – trade reference assigned by your organisation
      * **Their Trade ID** – trade reference assigned by your counterparty
      * **Document ID / USI / UTI** – Document ID, USI (Unique Swap Identifier) or UTI (Unique Trade Identifier)

      In each field you can search for up to 50 identifiers at once, e.g. by copying and pasting them from a spreadsheet.

      The right section of the Deal Finder offers a combination of various other search. They include the filters featured in the Dashboard plus:
      * **Price**
      * **Sender** – Select "Us" for confirmations sent by your organisation and "Them" for confirmations alleged against you.
      * **Hidden** – Choose whether to view hidden confirmations (default is exclude).
      * **Trade Date Range**
      * **Submission Date Range**
      * **Delivery Start**
      * **Delivery End**

      __Multiple-Choice Filters__

      Filter options will only contain information relevant to the user’s organisation. For example, if an organisation does not confirm Power trades, then Power will not be available in the Commodity filter drop down list. The list is generated at the time of log in for each user, based on all submissions made in the previous 60 days. It is also possible to select multiple fields within each filter by using the check boxes in
      the drop down box.

      Available filters are:
      * **Counterparty** – identified by EIC code
      * **Broker** – identified by broker code
      * **Transaction Type** – confirmed transaction type, e.g. FOR, OPT etc.
      * **Commodity** – confirmed commodity, e.g. Power, Gas etc.
      * **Market** – country code of the market area, e.g. FR for France

      __My Filters__

      You can save searches using the “My Filters” button. This is available on all Dashboard and Deal Finder screens. For further details please refer to the CMS User Guide.

    </cms-info>
    <div class="row">
      <cms-not-found-ids-warning class="col-12"
                                 [ids]="notFoundIDs$ | async"
                                 [allCount]="idsCount$ | async">
      </cms-not-found-ids-warning>
      <p-messages [value]="messages$ | async" [closable]="true" class="col-12"></p-messages>
      <div class="col-12 col-xl-4 col-lg-6 col-md-12 cms-id-filters">
        <cms-id-filters [idFiltersLabels]="idFiltersLabels"
                        [disabled]="loading$ | async"
                        (filter)="onFilter($event)"
                        [filters]="idFilters$ | async"
                        (reset)="onResetByIds()">
        </cms-id-filters>
      </div>
      <div class="col-12 col-xl-8 col-lg-12 col-md-12 properties-filters">
        <cms-deal-finder-properties-filters
          [disabled]="loading$ | async"
          [filters]="propFilters$ | async"
          [allDealsFilter]="allDealsFilter$ | async"
          [defaultFilter]="defaultFilter$ | async"
          [dialog]="dialog$ | async"
          [myFilters]="myFilters$ | async"
          [counterParties]="counterParties$ | async"
          [markets]="markets$ | async"
          [transactionTypes]="transactionTypes$ | async"
          [brokers]="broker$ | async"
          [commodities]="commodities$ | async"
          [messages]="messages$ | async"
          (closeDialog)="onCloseDialog()"
          (deleteFilter)="onDeleteFilter($event)"
          (setDefaultFilter)="onSetDefault($event)"
          (selectMyFilter)="onSelectMyFilter($event)"
          (manageFilters)="onManageMyFilters()"
          (confirmSaveCurrent)="onConfirmSaveFilter($event)"
          (openSaveCurrent)="onOpenSaveCurrent()"
          (replaceFilter)="onConfirmReplaceFilter($event)"
          (filter)="onFilterByProperties($event)"
          (reset)="onFilterByPropertiesReset()">
        </cms-deal-finder-properties-filters>
      </div>
    </div>
    <cms-datatable *ngIf="filterBy$ | async"
                   [currentPage]="(paging$ | async).page"
                   [tableColumns]="columns$ | async"
                   [sortField]="(sorting$ | async).columnName"
                   [sortOrder]="(sorting$ | async).sortOrder"
                   [rows]="(paging$ | async)?.entriesPerPage"
                   [loading]="loading$ | async"
                   [data]="(data$ | async)?.values"
                   [totalRecords]="(data$ | async)?.count"
                   [selected]="this.selected$ | async"
                   [autoLayout]="true"
                   [scrollable]="false"
                   [checkboxRow]="true"
                   [lazy]="!!(filterBy$ && (data$ | async)?.values.length)"
                   (selectedChange)="onSelected($event)"
                   (lazyLoad)="onLazyLoad($event)">
      <ng-template pTemplate="caption">
        <div class="float-left p-1 font-weight-bold">Found Deals</div>
        <cms-deal-table-caption [showTerminationButton]="true"
                                [terminateDisable]="!(editRights$|async) || terminateDisable"
                                (openAddNoteDialog)="onOpenAddNoteDialog()"
                                (openTerminateDialog)="openTerminateDialog()"
                                (exportEvent)="onExport()"
                                (clickCustomize)="onClickCustomize()"
                                [exportDisable]="!(data$ | async)"
                                [addNoteDisable]="!(noteRights$| async) || !(confirmationIds | async)?.length"></cms-deal-table-caption>
      </ng-template>
      <ng-template pTemplate="body" let-row="row" let-column="column">
        <td *ngIf="column.type === 'document-hidden'" [ngStyle]="column.style">
          <cms-document-hidden-cell *ngIf="row.hideCheckboxVisible"
                                    [data]="row"
                                    (unhide)="onUnhide($event)"
                                    (hide)="onHide($event)">
          </cms-document-hidden-cell>
        </td>

        <!--amend/cancel buttons-->
        <td *ngIf="column.type === 'button' && column.name === 'cancelDeal'" [ngStyle]="column.style">
          <div class="btn-wrapper inline-block btn--amend-cancel">
            <a routerLink="/ecm/trade/entry/{{row.id}}">
              <button *ngIf="row.ecmLightAmendmentButtonVisible" class="btn" type="button" title="Amend">Amend</button>
            </a>
            <button *ngIf="row.ecmLightCancelButtonVisible" class="btn margin-left-3" type="button" title="Cancel" (click)="onEcmLiteCancel(row)">Cancel</button>
          </div>
        </td>
        <!--document button-->
        <td *ngIf="column.type === 'button' && column.name === 'openDocument'" [ngStyle]="column.style">
          <div class="btn-wrapper inline-block">
            <a target="_blank"
               id="dealfinder-html"
               routerLink="/ecm/dealfinder/confirmation_doc"
               [queryParams]="{ id: row.id }">
              <button pButton type="button" icon="fa fa-file" title="Document"></button>
            </a>
          </div>
        </td>
        <!--document (xml) button-->
        <td *ngIf="column.type === 'button' && column.name === 'openDocumentXml'" [ngStyle]="column.style">
          <div class="btn-wrapper inline-block">
            <button pButton type="button" icon="fa fa-file-code-o" title="Document (XML)" (click)="onDownloadXml(row)"></button>
          </div>
        </td>
        <!--audit trail button-->
        <td *ngIf="column.type === 'button' && column.name === 'auditTrail'" [ngStyle]="column.style">
          <div class="btn-wrapper inline-block">
            <a routerLink="/ecm/audittrail" title="Audit Trail" target="_blank" [queryParams]="getDisplayParams(row)">
              <button pButton type="button" icon="fa fa-search" title="Audit Trail"></button>
            </a>
          </div>
        </td>
        <td *ngIf="column.type === 'button' && column.name === 'notes'" [ngStyle]="column.style">
          <cms-notes-cell [confirmationId]="row.id"
                          [data]="row"
                          [notes]="row.notes"
                          (view)="onViewNotes($event)">
          </cms-notes-cell>
        </td>
        <td *ngIf="column.type === 'button' && column.name === 'counterpartyNearMatch'" [ngStyle]="column.style">
          <cms-ecm-near-match-cell class="comp-match-cell"
                                   [data]="row"
                                   [isBroker]="false">
          </cms-ecm-near-match-cell>
        </td>
        <td *ngIf="column.type === 'button' && column.name === 'brokerNearMatch'" [ngStyle]="column.style">
          <cms-ecm-near-match-cell class="comp-match-cell"
                                   [data]="row"
                                   [isBroker]="true">
          </cms-ecm-near-match-cell>
        </td>
      </ng-template>
      <ng-template pTemplate="customEmptyMessage">
        <tr>
          <td colspan="100" *ngIf="!(loading$ | async)">
            No records found
          </td>
        </tr>
      </ng-template>
    </cms-datatable>
    <cms-terminate-deal-dialog *ngIf="(dialog$ | async)?.name === 'TERMINATE_DEAL'"
                               [display]="(dialog$ | async)?.name === 'TERMINATE_DEAL'"
                               [deals]="selected$ | async"
                               [messages]="(dialog$ | async)?.messages"
                               (exit)="onCancelTerminateDealDialog()"
                               (terminate)="onConfirmTerminateDeal($event)"
                               [closeButton]="(dialog$ | async)?.data.closeButton"
                               [dialog]="dialog$ | async">
    </cms-terminate-deal-dialog>
    <cms-ecm-lite-cancel *ngIf="(dialog$ | async)?.name === 'ECM_LITE_CANCEL'"
                         [messages]="(dialog$ | async)?.messages"
                         [display]="(dialog$ | async)?.name === 'ECM_LITE_CANCEL'"
                         (exit)="onAbortEcmLiteCancelDialog()"
                         (cancel)="onConfirmEcmLiteCancel($event)"
                         [tradeId]="tradeIdOfRow"
                         [closeButton]="(dialog$ | async)?.data.closeButton"
                         [execution]="(dialog$ | async)?.data.loadingDialog"
                         [confirmationId]="(dialog$ | async)?.data?.confirmationId">
    </cms-ecm-lite-cancel>
    <cms-add-note-dialog *ngIf="(dialog$ | async)?.name === 'ADD_NOTES'"
                         [display]="(dialog$ | async)?.name === 'ADD_NOTES'"
                         [messages]="(dialog$ | async)?.messages"
                         [confirmationIds]="confirmationIds | async"
                         (cancel)="onCancelAddNotes()"
                         (save)="onConfirmAddNotes($event)">
    </cms-add-note-dialog>
    <cms-view-confirmation-notes-dialog *ngIf="(dialog$ | async)?.name === 'VIEW_NOTES'"
                                        [display]="(dialog$ | async)?.name === 'VIEW_NOTES'"
                                        [messages]="(dialog$ | async)?.messages"
                                        [data]="(dialog$ | async)?.data"
                                        (cancel)="onCancelViewNotes()"
                                        (save)="onConfirmAddNotes($event)">
    </cms-view-confirmation-notes-dialog>
    <cms-hide-confirmation-dialog *ngIf="(dialog$ | async)?.name === 'HIDE_CONFIRMATION'"
                                  [display]="(dialog$ | async)?.name === 'HIDE_CONFIRMATION'"
                                  [messages]="(dialog$ | async)?.messages"
                                  [data]="(dialog$ | async)?.data"
                                  (cancel)="onCancelHideUnhideConfirmation()"
                                  (confirm)="onConfirmHideConfirmation($event)">
    </cms-hide-confirmation-dialog>
    <cms-unhide-confirmation-dialog *ngIf="(dialog$ | async)?.name === 'UNHIDE_CONFIRMATION'"
                                    [display]="(dialog$ | async)?.name === 'UNHIDE_CONFIRMATION'"
                                    [messages]="(dialog$ | async)?.messages"
                                    [data]="(dialog$ | async)?.data"
                                    (cancel)="onCancelHideUnhideConfirmation()"
                                    (confirm)="onConfirmUnHideConfirmation($event)">
    </cms-unhide-confirmation-dialog>
  </div>
  <p-dialog [(visible)]="displayEmailWarning" [modal]="true" header="Are you sure that you want to perform this action?" [closable]=false [style]="{width: '35%','max-width': '50rem','filter': 'drop-shadow(5px 5px 5px #777)'}">
    <p-messages [value]="errorMessages" [closable]="false" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" class="mt3 mb3 block"></p-messages>
    <div class="flex reverse full-width justify-content-end">
      <button pButton class="btn block  mt-3" (click)="closeEmailWarning()" label="Cancel"></button>
      <button pButton class="btn block mt-3 ms-3" (click)="triggerExportAndCloseWarning()" label="Yes - Proceed"></button>
    </div>
  </p-dialog>

</ng-template>
