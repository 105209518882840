import { Component, OnDestroy, OnInit } from '@angular/core';
import { OrganisationService } from '@common/organisation.service';
import { select, Store } from '@ngrx/store';
import { Message } from 'primeng/api';
import { withLatestFrom } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { OrganisationModel } from '@common/organisation.model';
import { Column } from '@common/shared/datatable.model';
import { DialogState } from '@common/state/reducers';
import {
  allColumnsConfig,
  ErrSendingFailuresResponse,
  ErrSendingFailuresSearchRequest,
  ErrSendingFailuresSelected,
  ErrSendingFailuresValues
} from './err-sending-failures.model';
import * as fromReducers from '../state/err-sending-failures/err-sending-failures.reducers';
import * as fromActions from '../state/err-sending-failures/err-sending-failures.actions';
import * as fromSelectors from '../state/err-sending-failures/err-sending-failures.selectors';
import * as moment from 'moment';

@Component({
  selector: 'cms-err-sending-failures',
  templateUrl: './err-sending-failures.component.html',
  styleUrls: ['./err-sending-failures.component.scss']
})
export class ErrSendingFailuresComponent implements OnInit, OnDestroy {
  processesOptions$: Observable<{ label: string, value: string }[]> = this.store.pipe(select(fromSelectors.getProcessesOptions));
  filters$: Observable<ErrSendingFailuresSearchRequest> = this.store.pipe(select(fromSelectors.getFilters));
  messages$: Observable<Message[]> = this.store.pipe(select(fromSelectors.getMessages));
  data$: Observable<ErrSendingFailuresResponse> = this.store.pipe(select(fromSelectors.getData));
  initializing$: Observable<boolean> = this.store.pipe(select(fromSelectors.getInitializing));
  isSelected$: Observable<boolean> = this.store.pipe(select(fromSelectors.getIsSelected));
  selectedItems$: Observable<ErrSendingFailuresSelected> = this.store.pipe(select(fromSelectors.getSelectedItems));
  loadingTable$: Observable<boolean> = this.store.pipe(select(fromSelectors.getLoadingTable));
  dialog$: Observable<DialogState<fromReducers.ERR_SF_DIALOGS>> = this.store.pipe(select(fromSelectors.getDialog));
  subscription$: Subscription;
  readonly tableColumns: Column[] = allColumnsConfig;

  constructor(private readonly organisationService: OrganisationService,
              private store: Store<fromReducers.ErrSendingFailuresState>) { }

  ngOnInit(): void {
    this.store.dispatch(fromActions.setOrganisationId(this.organisationService.getCachedOrganisationId()));
    this.store.dispatch(fromActions.getPreset());

    this.subscription$ = this.organisationService
      .getChangeOrganisationObservable()
      .pipe(withLatestFrom(this.store.pipe(select(fromSelectors.getOrganisationId))))
      .subscribe(([organisation, organisationId]: [OrganisationModel, number]) => {
        if ((organisation && organisation.organisationId !== organisationId) || (!organisation && organisationId)) {
          this.store.dispatch(fromActions.setToDefault());
          this.store.dispatch(fromActions.setOrganisationId(organisation ? organisation.organisationId : null));
        }
      });
  }

  setFilter(value: { processName, fromDateFilter, fromTimeFilter, toDateFilter, toTimeFilter }): void {

      const { processName, fromDateFilter, fromTimeFilter, toDateFilter, toTimeFilter } = value;
      const formattedFromDate = moment(fromDateFilter, 'DD/MM/YYYY');
      const formattedToDate = moment(toDateFilter, 'DD/MM/YYYY');
      const formattedFromTime = moment(fromTimeFilter, 'HH:mm:ss');
      const formattedToTime = moment(toTimeFilter, 'HH:mm:ss');

      const start = moment.utc([
        formattedFromDate.year(),
        formattedFromDate.month(),
        formattedFromDate.date(),
        formattedFromTime.hours(),
        formattedFromTime.minutes(),
        formattedFromTime.seconds(),
        formattedFromTime.milliseconds()
      ]).toDate();

      const end = moment.utc([
        formattedToDate.year(),
        formattedToDate.month(),
        formattedToDate.date(),
        formattedToTime.hours(),
        formattedToTime.minutes(),
        formattedToTime.seconds(),
        formattedToTime.milliseconds()
      ]).toDate();


    this.store.dispatch(fromActions.setFilter({ processName , start, end }));
  }

  onSearch(request: ErrSendingFailuresSearchRequest): void {
    this.store.dispatch(fromActions.setFilter({processName: request.processName, start: request.start, end: request.end}));
    this.store.dispatch(fromActions.search(request));
  }

  onRefresh(processName: string): void {
    this.store.dispatch(fromActions.refresh(processName));
  }

  onDetailing(data: string): void {
    this.store.dispatch(fromActions.openDialog({ name: 'INFO' , data }));
  }

  onCloseDialog(): void {
    this.store.dispatch(fromActions.closeDialogAction());
  }

  onConfirmResendDialog(): void {
    this.store.dispatch(fromActions.resend());
  }

  onResend(): void {
    this.store.dispatch(fromActions.checkResend());
  }

  onCheck(checked: boolean, row: ErrSendingFailuresValues, rowIndex: number): void {
    this.store.dispatch(checked ? fromActions.select({ [rowIndex]: row }) : fromActions.deselect({ [rowIndex]: row }));
  }

  onToggleSelectAll(toggled: boolean): void {
    this.store.dispatch(fromActions.toggleSelectAll(toggled));
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
    this.store.dispatch(fromActions.setToDefault());
  }
}
