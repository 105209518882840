import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { UntypedFormControl, UntypedFormGroup, Validators, } from '@angular/forms';
import { CounterPartyState } from '../../state/netting-preview/counterparty/counterparty.reducers';
import * as CounterPartyActions from '../../state/netting-preview/counterparty/counterparty.actions';
import * as CounterPartySelector from '../../state/netting-preview/counterparty/counterparty.selectors';
import * as TableFiltersActions from '../../state/netting-preview/netting-table/netting-table.actions';
import * as AmountTableFiltersActions from '../../state/netting-preview/amount-table/amount-table.actions';
import { Observable, Subscription } from 'rxjs';
import { CounterpartyOption } from '../netting-preview.model';
import { filter, tap } from 'rxjs/operators';
import { OrganisationService } from '@common/organisation.service';
import * as CpActions from '../../state/netting-preview/counterparty/counterparty.actions';

@Component({
  selector: 'cms-counterparty-dropdown',
  templateUrl: './counterparty-dropdown.component.html',
  styleUrls: ['./counterparty-dropdown.component.scss']
})
export class CounterpartyDropdownComponent implements OnDestroy {

  selectedScope: any = [];
  counterparties: CounterpartyOption[];

  counterPartis$: Observable<CounterpartyOption[]> = this.store$.pipe(select(CounterPartySelector.getCounterparties),
    tap(options => {
      this.group.controls.dropDown.markAsPristine();
      this.counterparties = options;
      this.selectedScope = options.map(({ value }) => value);
    }));

  group = new UntypedFormGroup({ dropDown: new UntypedFormControl('dropDown', [Validators.required]) });
  subscription$: Subscription  = new Subscription();

  constructor(private store$: Store<CounterPartyState>, private organisationService: OrganisationService) {}

  init(){

    this.subscription$.add(this.group.controls.dropDown.valueChanges
      .pipe(filter(({ length }) => !!length))
      .subscribe((counterPartyOrgIds: number[]) => {
        this.store$.dispatch(TableFiltersActions.cleanState());
        this.store$.dispatch(AmountTableFiltersActions.cleanState());
        this.store$.dispatch(CounterPartyActions.setSelectedItems({ counterPartyOrgIds }));
        this.store$.dispatch(TableFiltersActions.loadTable());
      }));

    this.subscription$.add(this.counterPartis$.subscribe());

    this.subscription$.add(this.organisationService.onRefresh().pipe(filter(auth => auth && this.organisationService.getCurrentOrganisationId()!=null))
      .subscribe(() => {
        this.store$.dispatch(CpActions.cleanState());
        this.store$.dispatch(TableFiltersActions.cleanState());
        this.store$.dispatch(TableFiltersActions.loading({loading:false}));
        this.store$.dispatch(CpActions.loadPresets());
      }));
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

}
