import { datatableReducer, DatatableState } from '../../datatable/datatable.reducer';
import { dialogReducer, DialogState } from '../../dialog/dialog.reducer';
import { BillingRuns, BillingRunParams, StartBillingRun } from '../../../administration-tab-panel/billing-runs/cms/billing-runs.model';
import * as fromAction from '../../billing-runs/cms/cms-runs.actions';
import { organisationIdReducer } from '../../organisation-id/organisation-id.reducer';

export interface CmsBillingRunsState {
  organisationId?: number;
  datatable: DatatableState<BillingRuns>;
  dialog: DialogState;
  billingRunPermission: boolean;
  billingRunParams: BillingRunParams;
  startBillingRun: StartBillingRun;
  isBillinRunLoading: boolean;
}

const datatableInitialState: DatatableState<BillingRuns> = {
  selected: null,
  data: {
    values: [],
    count: 0
  },
  paging: {
    entriesPerPage: 25,
    page: 0
  },
  messages: [],
  loading: false
};

export const initialCmsBillingRunsState: CmsBillingRunsState = {
  organisationId: null,
  datatable: datatableInitialState,
  dialog: null,
  billingRunPermission: false,
  billingRunParams: null,
  startBillingRun: null,
  isBillinRunLoading: false
};

export function cmsBillingRunsReducer(
  state: CmsBillingRunsState = { ...initialCmsBillingRunsState },
  action: fromAction.CmsBillingRunsActions): CmsBillingRunsState {
  switch (action.type) {
    case fromAction.setBillingRunPermission.type:
      return {
        ...state,
        billingRunPermission: action.payload
      };
    case fromAction.setBillingRunParams.type:
      return {
        ...state,
        billingRunParams: action.payload
      };
    case fromAction.setStartBillingRunModel.type:
      return {
        ...state,
        startBillingRun: action.payload
      };
    case fromAction.startBillingRun.type:
      return {
        ...state,
        isBillinRunLoading: true
      };
    case fromAction.getBillingRunStatus.type:
      return {
        ...state,
        isBillinRunLoading: false
      };
    default:
      return {
        ...state,
        dialog: dialogReducer('[BILLING RUNS]')(state.dialog, action as any),
        datatable: datatableReducer('[BILLING RUNS]', datatableInitialState)(state.datatable, action as any),
        organisationId: organisationIdReducer('[BILLING RUNS]')(state.organisationId, action as any)
      };
  }
}
