import { Action } from '@ngrx/store';
import { EcmMyFilter, EcmMyFiltersResponse } from '../../../deal-finder/deal-finder.model';
import { MyFilter } from '@common/my-filters/my-filters.model';

export const SET_DEFAULT_FILTER = '[ECM DASHBOARD] Set Default Filter';
export const LOAD_MY_FILTERS = '[ECM DASHBOARD] Load My Filters';
export const LOAD_MY_FILTERS_SUCCESS = '[ECM DASHBOARD] Load My Filters Success';
export const SET_ALL_DEALS_FILTER = '[ECM DASHBOARD] Set All Deals Filter';
export const SELECT_MY_FILTER = '[ECM DASHBOARD] Select My Filter';
export const DELETE_MY_FILTER = '[ECM DASHBOARD] Delete My Filter';
export const DELETE_MY_FILTER_SUCCESS = '[ECM DASHBOARD] Delete My Filter Success';
export const REPLACE_MY_FILTERS = '[ECM DASHBOARD] Replace My Filters';
export const SAVE_MY_FILTERS = '[ECM DASHBOARD] Save My Filters';
export const RELOAD_MY_FILTER = '[ECM DASHBOARD] Reload My Filters';
export const RELOAD_MY_FILTER_SUCCESS = '[ECM DASHBOARD] Reload My Filters Success';

export class LoadMyFiltersAction implements Action {
  readonly type = LOAD_MY_FILTERS;
}

export class LoadMyFiltersSuccessAction implements Action {
  readonly type = LOAD_MY_FILTERS_SUCCESS;
  constructor(public payload: EcmMyFiltersResponse) { }
}

export class ReloadMyFiltersSuccessAction implements Action {
  readonly type = RELOAD_MY_FILTER_SUCCESS;
  constructor(public payload: EcmMyFiltersResponse) { }
}

export class SetAllDealsFilterAction implements Action {
  readonly type = SET_ALL_DEALS_FILTER;
  constructor(public payload: EcmMyFilter) { }
}

export class SelectMyFilterAction implements Action {
  readonly type = SELECT_MY_FILTER;
  constructor(public payload: EcmMyFilter) { }
}

export class SetDefaultFilterAction implements Action {
  readonly type = SET_DEFAULT_FILTER;
  constructor(public payload: MyFilter) { }
}

export class DeleteMyFilterAction implements Action {
  readonly type = DELETE_MY_FILTER;
  constructor(public payload: {filterName : string}) { }
}

export class DeleteMyFilterSuccessAction implements Action {
  readonly type = DELETE_MY_FILTER_SUCCESS;
  constructor(public payload: {filterName : string}) { }
}

export class ReplaceMyFiltersAction implements Action {
  readonly type = REPLACE_MY_FILTERS;
  constructor(public payload: EcmMyFilter) { }
}

export class SaveMyFiltersAction implements Action {
  readonly type = SAVE_MY_FILTERS;
  constructor(public payload: EcmMyFilter) { }
}

export class ReloadMyFiltersAction implements Action {
  readonly type = RELOAD_MY_FILTER;
}

export type MyFiltersActions = LoadMyFiltersAction | LoadMyFiltersSuccessAction | SetAllDealsFilterAction |
  SetDefaultFilterAction | DeleteMyFilterAction | DeleteMyFilterSuccessAction | ReplaceMyFiltersAction |
  SaveMyFiltersAction | ReloadMyFiltersAction | ReloadMyFiltersSuccessAction;
