import {Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ApiRequestService } from '@common/api-request.service';
import { RoboDelegatorsSearchQuery } from '../../err/robo/delegators/robo-delegators.service';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class RemitTransResolver   {

  constructor(private apiRequestService: ApiRequestService) {}

  searchQuery = RoboDelegatorsSearchQuery.createEmptySearchQuery();

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>|Promise<any> {

     return this.apiRequestService.post('/api/robo/delegators/loadRoboWhitelist', this.searchQuery)
       .pipe(
         map((result)=>({'query': this.searchQuery, 'result':result})),
         catchError(() => of(undefined)));
  }

}
