<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>

<cms-info ngPreserveWhitespaces>
  __eCM Dashboard__

  This screen is for monitoring eCM trade confirmation in progress, with issues and completed.

  __Using the Filters__

  Trades can be viewed by

  * **Trade Date** – date the trade was agreed
  * **Submission Date** – date the confirmation was submitted to CMS.\
    Users can limit the displayed trade confirmations further by using the following filters:
      * **Counterparty** (EIC code of trader organisation)
      * **Broker** (Broker code of broker organisation)
      * **Transaction Type** (FOR, FXD_SWP etc.)
      * **Commodity** ( Gas, Power etc.)
      * **Market** (country code)
      * **Hidden** (exclude / include / show only hidden confirmations)

  __Trade confirmations are categorised by age__

  * **Today** – confirmations agreed today or submitted today
  * **Before Today** – confirmations with a trade date older than 1 day or submitted more than 1 day ago
  * **1** – confirmations agreed or submitted 1 day ago only
  * **2** – confirmations agreed or submitted 2 days ago only
  * **3** – confirmations agreed or submitted 3 days ago only
  * **4-7** – confirmations agreed or submitted 4-7 days ago only
  * **8-30** – confirmations agreed or submitted 8-30 days ago only
  * **31-90** – confirmations agreed or submitted 31-90 days ago

  __For Traders: Interpreting the States__

  Dashboard states are subdivided into three categories shown on separate tabs: "In progress", "Issue" (actionable) and "Complete" (successful).

  1. **In Progress** – Deals that have been well processed but have not matched yet. All confirmations within this section are in state Pending with the counterparty or broker and can match, be amended, or be cancelled. In Progress deals are split into four subcategories for Traders:

      a) **Pending (CP)** – Your confirmations waiting to be counterparty-matched. No matching counterparty document (CNF) was found.

      b) **Alleged (CP)** – Confirmations received from your counterparties awaiting a matching confirmation (CNF) from your organisation.

      c) **Pending (BR)** – Your confirmations waiting to be broker-matched. No matching broker confirmation (BCN) was found.

      d) **Alleged (BR)** – Broker confirmations awaiting matching details (CNF and/or BFI) from your side.

  2. **Issue** – Confirmations in the Issue tab cannot match in the CMS without corrective action. These issues are split into the subcategories:

      a) **Failed** – Confirmations that were sent to the CMS but do not comply with the EFET eCM Standards (e.g. missing “DeliveryPointArea”).

      b) **Receiving** – A transitory state while receiving your CNF documents and acknowledging the receipt to your trading system.

      c) **Matching**  – A transitory state while establishing a match and communicating it to the countersides.

  3. **Complete** – Deals that have matched or have been cancelled. These are split into five subcategories for Traders

     a) **Matched** – Confirmations that have matched completely with the counterparty (bilateral).

     b) **Matched (BR)** – Confirmations that have matched completely with the broker

     c) **Preliminary Matched (BR)** – Confirmations that are pending with the counterparty and have matched with the broker. The confirmations will remain in a Preliminary Matched state until the counterparty match completes.

     d) **Cancelled** – Confirmations that have been cancelled.

     e) **Terminated** – Confirmations that have been terminated and are not eligible for matching. By submitting an amendment with a higher version number, an organisation can return the trade confirmation to a Pending state from which it is eligible to match.

  __For Brokers: Interpreting the States__

  Broker Dashboard states are subdivided into three categories shown on separate tabs: "In progress", "Issue" (actionable) and "Complete" (successful).

  1. **In Progress** – Shows all Pending confirmations that have not been matched in CMS yet. These are split into two subcategories:

      a) **Pending** – Your confirmations waiting to match against a trader's confirmation (CNF) and optionally broker fee information (BFI).

      b) **Alleged** – Trade confirmations submitted by a trade counterparty for broker-matching. No matching broker confirmation (BCN) was found.

  2. **Issue** – Shows all confirmations that are unable to match in CMS and require corrective action. These are split into three subcategories:

      a) **Failed** – Confirmations that were sent to the CMS but do not comply with the EFET eCM Standards (e.g. missing “DeliveryPointArea”).

      b) **Receiving** – A transitory state while receiving your CNF documents and acknowledging the receipt to your broker system.

      c) **Matching** – A transitory state while establishing a match and communicating it to you and the trading party.

  3. **Complete** – Deals that have matched or been cancelled. These are split into four subcategories:

      a) **Matched** – Your confirmation was matched with the trader party. The match is final, i.e. the trader party has also completed the counterparty match.

      b) **Preliminary Matched** – Your confirmation was matched with the trader party. The match is preliminary until the trader party has completed or cancelled the counterparty match.

      c) **Cancelled** – Confirmations that you have cancelled.

      d) **Terminated** – Confirmations that were terminated manually or by time-out. By submitting an amendment with a higher version number you can return it to a ‘Pending’ state.

</cms-info>
<p-tabView [activeIndex]="activeIndex" (onChange)="onChangeTab($event)">
  <p-tabPanel header="In Progress">
    <div class="tab-view-body">
      <cms-ecm-dashboard-tab *ngIf="activeIndex === 0"
                             [category]="tabs[activeIndex]">
      </cms-ecm-dashboard-tab>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Issue">
    <div class="tab-view-body">
      <cms-ecm-dashboard-tab *ngIf="activeIndex === 1"
                             [category]="tabs[activeIndex]">
      </cms-ecm-dashboard-tab>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Complete">
    <div class="tab-view-body">
      <cms-ecm-dashboard-tab *ngIf="activeIndex === 2"
                             [category]="tabs[activeIndex]">
      </cms-ecm-dashboard-tab>
    </div>
  </p-tabPanel>
</p-tabView>

</ng-template>
