import { Message } from 'primeng/api';
import { createReducer, on } from '@ngrx/store';
import * as fromNettingActions from './netting-settings.actions';
import { emptyData, NettingSettingsModel, VatIds } from '../../../settings/netting/netting-settings.model';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { DialogState } from '@common/state/reducers';
import { SETTINGS_DIALOGS } from '../../../settings/invoice/invoice-settings.model';

export interface EsmNettingSettingsState {
  toggleNettingAllowed: boolean;
  nettingSupported: boolean;
  centralNettingSupported: boolean;
  crossCommodityNettingSupported: boolean;
  downloadAllowed: boolean;
  messages: Message[];
  generationDatePhysical: number;
  generationDateFinancial: number;
  data: DatatableData<NettingSettingsModel[]>;
  paging: PagingFilter;
  sorting: SortingFilter;
  loading: boolean;
  vatIds: VatIds[];
  dialog: DialogState<SETTINGS_DIALOGS>;
  searchString: string;
  selectedVatId: string;
}

export const initialState: EsmNettingSettingsState = {
  toggleNettingAllowed: false,
  nettingSupported: false,
  centralNettingSupported: false,
  crossCommodityNettingSupported: false,
  downloadAllowed: false,
  messages: [],
  generationDatePhysical: 12,
  generationDateFinancial: 4,
  data: emptyData,
  sorting: { columnName: 'vatNumber', sortOrder: 'ASCENDING' },
  paging: {
    entriesPerPage: 25,
    page: 0
  },
  loading: false,
  vatIds: [],
  dialog: null,
  searchString: null,
  selectedVatId: null
};

export const esmNettingSettingsReducer = createReducer<EsmNettingSettingsState>(
  initialState,
  on(fromNettingActions.loadPresetValuesSuccessAction, (state: EsmNettingSettingsState, { payload: data }): EsmNettingSettingsState => ({
      ...state,
      toggleNettingAllowed: data.toggleNettingAllowed,
      nettingSupported: data.nettingSupported,
      centralNettingSupported: data.centralNettingSupported,
      crossCommodityNettingSupported: data.crossCommodityNettingSupported,
      downloadAllowed: data.downloadAllowed,
      messages: null,
      generationDatePhysical: data.generationDatePhysical,
      generationDateFinancial: data.generationDateFinancial,
      vatIds: data.vatIds
    }
  )),
  on(fromNettingActions.setMessagesAction, (state: EsmNettingSettingsState, { payload: messages}): EsmNettingSettingsState => ({
    ...state,
    messages
  })),
  on(fromNettingActions.setNettingSupported, (state: EsmNettingSettingsState, {payload: flag}) => ({ ...state, nettingSupported: flag })),
  on(fromNettingActions.setCentralNettingSupported, (state: EsmNettingSettingsState, {payload: flag}) => ({ ...state, centralNettingSupported: flag })),
  on(fromNettingActions.setCrossCommodityNettingSupported, (state: EsmNettingSettingsState, {payload: flag}) => ({ ...state, crossCommodityNettingSupported: flag })),
  on(fromNettingActions.setCallDayPhysicalNettingGeneration, (state: EsmNettingSettingsState, {payload: selected}) => ({...state, selectedCallDayPhysicalNettingGeneration$: selected})),
  on(fromNettingActions.setCallDayFinancialNettingGeneration, (state: EsmNettingSettingsState, {payload: selected}) => ({...state, selectedCallDayFinancialNettingGeneration$: selected})),
  on(fromNettingActions.loading, (state: EsmNettingSettingsState, {payload: flag}) => ({...state, loading: flag})),
  on(fromNettingActions.openDialogAction, (state: EsmNettingSettingsState, {payload: dialog}) => ({...state, dialog: dialog})),
  on(fromNettingActions.closeDialogAction, (state: EsmNettingSettingsState) => ({...state, dialog: null})),
  on(fromNettingActions.setSearchString, (state: EsmNettingSettingsState, {payload: searchString}) => ({...state, searchString: searchString})),
  on(fromNettingActions.setSelectedVatNumber, (state: EsmNettingSettingsState, {payload: vatNumber}) => ({...state, selectedVatId: vatNumber})),
  on(fromNettingActions.loadTableDataSuccess, (state: EsmNettingSettingsState, {payload: data}) => ({...state, data: data})),
  on(fromNettingActions.pagingAndSorting, (state: EsmNettingSettingsState, {payload: pageingAndSorting}) => ({...state, paging: pageingAndSorting.paging, sorting: pageingAndSorting.sorting}))
);
