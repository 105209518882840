import { Message } from 'primeng/api';
import { createReducer, on } from '@ngrx/store';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { Column } from '@common/shared/datatable.model';
import { EsmInvoiceFinder } from '../../../invoice/finder/invoice-finder.model';
import * as fromActions from './table.actions';
import { InvoiceFinderTableActions } from './table.actions';
import * as fromFiltersActions from '../filters/filters.actions';
import * as fromIFActions from '../invoice-finder.actions';

export interface EsmInvoiceTableState {
  messages: Message[];
  data: DatatableData<EsmInvoiceFinder[]>;
  loading: boolean;
  paging?: PagingFilter;
  sorting?: SortingFilter;
  invoiceFinderHtml: Blob;
  selectedIds: string[];
  invoices: EsmInvoiceFinder[];
  columns: Column[];
}

export const tableInitialState: EsmInvoiceTableState = {
  messages: [],
  data: {
    values: null,
    count: 0,
    currentPage: 0
  },
  paging: {
    page: 0,
    entriesPerPage: 25
  },
  sorting: {
    columnName: 'submissionTimestamp',
    sortOrder: 'DESCENDING'
  },
  loading: false,
  invoiceFinderHtml: null,
  selectedIds: [],
  invoices: [],
  columns: [],
};

export const tableReducer = createReducer<EsmInvoiceTableState, InvoiceFinderTableActions>(
  tableInitialState,
  on(fromActions.setMessagesAction, (state: EsmInvoiceTableState, {payload: messages}): EsmInvoiceTableState =>
    ({...state, loading: false, messages})),
  on(fromActions.setHtmlAction, (state: EsmInvoiceTableState, {payload: invoiceFinderHtml}): EsmInvoiceTableState =>
    ({...state, invoiceFinderHtml})),
  on(fromFiltersActions.filterAction, (state: EsmInvoiceTableState) => ({...state, loading: true})),
  on(fromIFActions.setToDefaultAction, () => ({...tableInitialState})),
  on(fromActions.sortAndPageDataAction, (state: EsmInvoiceTableState, {payload: {paging, sorting}}) => ({
    ...state,
    loading: true,
    messages: [],
    paging,
    sorting,
  })),
  on(fromFiltersActions.filterSuccessAction, (state: EsmInvoiceTableState, {payload: data}) => ({
    ...state,
    data: {
      count: data.count,
      values: data.values
    },
    paging: {
      ...state.paging,
      page: data.currentPage
    },
    loading: false,
    selectedIds: []})),
  on(fromFiltersActions.clearIdPropertiesFiltersAction, (state: EsmInvoiceTableState) => ({
    ...state,
    data: {
      values: null,
      count: 0,
      currentPage: 0
    },
    messages: []
  })),
  on(fromActions.setSelectedIdsAction, (state: EsmInvoiceTableState, {payload: {confirmationIds: confirmationIds}}) => ({
    ...state,
    selectedIds: confirmationIds,
  })),
  on(fromActions.setSelectedInvoicesAction, (state: EsmInvoiceTableState, {payload: {invoices: invoices}}) => ({
    ...state,
    invoices: invoices,
  })),
  on(fromActions.setColumnsAction, (state: EsmInvoiceTableState, {payload: columns}) => ({
    ...state,
    columns,
  })),
);
