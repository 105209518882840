import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RemitTradeEntryTable1ModifyService } from '../remit-trade-entry-table1-modify.service';
import { Subject } from 'rxjs';
import { SelectItemGroup } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cms-remit-trade-entry-table1-modify-contractdelivery',
  templateUrl: './remit-trade-entry-table1-modify-contractdelivery.component.html',
  styleUrls: ['./remit-trade-entry-table1-modify-contractdelivery.component.scss']
})

export class RemitTradeEntryTable1ModifyContractdeliveryComponent implements OnInit, OnDestroy {

  @Input()
  formGroup: UntypedFormGroup;

  private onDestroy$ = new Subject();

  loadTypes: SelectItemGroup[];
  deliveryPointOrZones: SelectItemGroup[];

  disableButtons: boolean = false;

  validationErrors: string[] = [];

  private deliveryModel: DeliveryTradeEntryModifyModel;

  constructor(private table1ModifyService: RemitTradeEntryTable1ModifyService, private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.formGroup.addControl('loadType', new UntypedFormControl(''));
    this.formGroup.addControl('deliveryStartDate', new UntypedFormControl(''));
    this.formGroup.addControl('deliveryEndDate', new UntypedFormControl(''));

    this.formGroup.addControl('deliveryZones', this.formBuilder.array(
      []
    ));

    this.table1ModifyService.onTradeEntryModelChange().pipe(takeUntil(this.onDestroy$)).subscribe(tradeEntryModel => {
      if (tradeEntryModel) {
        this.deliveryModel = tradeEntryModel.deliveryResponseModel;
        this.initFormValues();
      }
    });

    this.table1ModifyService.onTradeEntrySetupDataChange().pipe(takeUntil(this.onDestroy$)).subscribe(setupData => {
      if (setupData) {
        this.initFormSetup(setupData.deliveryValueSetsResponseModel);
      }
    });

    this.table1ModifyService.onModifyTradeDisabled().subscribe(modified => {
      this.disableButtons = modified;
    });

    this.table1ModifyService.onValidationErrors().pipe(takeUntil(this.onDestroy$)).subscribe(validationErrors => {
      if (validationErrors) {
        this.handleServerValidationErrors(validationErrors);
      }
    });

  }

  initFormValues() {
    if (this.deliveryModel) {

      this.reset();
      this.formGroup.controls['loadType'].setValue(this.deliveryModel.loadType);
      this.formGroup.controls['deliveryStartDate'].setValue(new Date(this.deliveryModel.deliveryStart));

      this.formGroup.controls['deliveryEndDate'].setValue(new Date(this.deliveryModel.deliveryEnd));

      for (const zone of this.deliveryModel.deliveryPointOrZones) {
        this.add(zone);
      }
    }
  }

  private initFormSetup(deliverySetupData: any) {
    if (deliverySetupData) {
      this.loadTypes = [];
      this.loadTypes = [{
        label: ' ',
        items: [{ label: ' ', value: null }]
      }];
      this.loadTypes = this.loadTypes.concat(this.table1ModifyService.getGroupedValues(deliverySetupData.loadTypes));
      this.deliveryPointOrZones = this.table1ModifyService.getGroupedValues(deliverySetupData.deliveryPointOrZones);
    }
  }

  private reset() {
    this.formGroup.controls['loadType'].reset();
    this.formGroup.controls['deliveryStartDate'].reset();
    this.formGroup.controls['deliveryEndDate'].reset();
    this.formGroup.controls['deliveryZones'].reset();
  }

  get deliveryZones(): AbstractControl[] {
    return (this.formGroup.controls['deliveryZones'] as UntypedFormArray).controls;
  }

  get disableAddButton(): boolean {
    return this.deliveryZones.length > 3 || this.disableButtons;
  }

  get disableRemoveButton(): boolean {
    return this.deliveryZones.length < 2 || this.disableButtons;
  }

  get bilateral(): boolean {
    return this.deliveryModel !== null;
  }

  add(deliveryZone: string) {

    const deliveryZones = this.formGroup.controls['deliveryZones'] as UntypedFormArray;

    if (deliveryZones.length < 4) {

      const formControl = new UntypedFormControl();
      formControl.setValue(deliveryZone);

      deliveryZones.push(
        this.formBuilder.group({ deliveryPointOrZone: formControl })
      );
    }
  }

  remove() {
    const deliveryZones = this.formGroup.controls['deliveryZones'] as UntypedFormArray;

    if (deliveryZones.length > 1) {
      deliveryZones.removeAt(deliveryZones.length - 1);
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  private handleServerValidationErrors(errors: any) {

    this.validationErrors = [];

    if (errors) {
      this.validationErrors['deliveryStartDate'] = errors.deliveryStartDate;
      this.validationErrors['deliveryEndDate'] = errors.deliveryEndDate;
      this.validationErrors['deliveryZones'] = errors.deliveryZones;
    }
  }

  appendValidationErrorClass(fieldName: string): string {
    if (this.validationErrors[fieldName]) {

      return 'error';
    }
  }

  appendValidationErrorClassOfArray(i: number, fieldName: string): string {

    const form = this.deliveryZones[i] as UntypedFormGroup;

    if (form) {

      if (this.formGroup.touched && form.controls[fieldName].dirty && form.controls[fieldName].invalid) {
        return 'error';
      }
    }
  }

}

export interface DeliveryTradeEntryModifyModel {
  loadType: string;
  deliveryPointOrZones: string[];
  deliveryStart: string;
  deliveryEnd: string;
}
