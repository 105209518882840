export interface ColumnSettingsResponseModel {
  activeColumns: string[];
  inactiveColumns: string[];
}

export class PersistColumSettingsRequestModel {
  activeColumns: string[];
}

export interface PersistColumnSettingsResponseModel {
  userId: number;
  activeColumns: string[];
  inactiveColumns: string[];
}

export interface SimpleValuePairResponseModel {
  ident: number;
  value: string;
}

export class LoadUsersOwningPersistedColumnSettingResponseModel {
  users: SimpleValuePairResponseModel[];
}

export type DatatableCustomizeSettingsDocumentType = 'remitt1' | 'remitt2' | 'remitt3' | 'remitt4' | 'remitfund' | 'ecm' | 'esmInvoice' | 'esmNettingstatement';
