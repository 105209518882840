import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ApiRequestService } from '@common/api-request.service';
import { MessagesMapperService } from '@common/messages-mapper.service';

import { Action, select, Store } from '@ngrx/store';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import * as AcerActions from './table.actions';
import * as AcerSelectors from './table.selectors';
import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import { Observable } from 'rxjs';
import { Message } from 'primeng/api';
import { TableEntry } from '../table/table.component';
import { State } from './table.reducers';
import {HttpParams} from '@angular/common/http';


@Injectable()
export class AcerBillingRunsMailEffects {
  private baseUrl = '/api/administration/acer/billingruns';

  constructor(private apiGateway: ApiRequestService,
    private actions$: Actions,
    private store$: Store<State>,
    private mapper: MessagesMapperService) { }




  load_table$ = createEffect(() =>
    this.actions$.pipe(ofType(AcerActions.loadTable))
      .pipe(switchMap(({acerBillingRunId}) => this.loadData(acerBillingRunId))));


  // ======  helper ====== //

  private loadData(acerBillingRunId: number): Observable<Action> {

    this.store$.dispatch(AcerActions.loading({ load: true  }));

    let params = new HttpParams();
    params = params.set('acerBillingRunId', acerBillingRunId);

    return this.apiGateway.get(`${this.baseUrl}/maildata`, params)
      .pipe(
        switchMap(response => {
          const messages: Message[] = this.mapper.toErrorMessages(response);
          const { values, currentPage, count } = response;

          return messages?.length ?
            [AcerActions.setMessages({ messages }), AcerActions.loading({ load:  false})]:
            [
              AcerActions.setMessages({ messages: [] }),
              AcerActions.loadTableSuccess({ data: {values, count, currentPage } }),
              AcerActions.loading({ load:  false})
            ];
        }),

        catchError(error => [AcerActions.loading({ load: false}),
          AcerActions.setMessages(this.mapper.toErrorMessagesObj(error.errorMessage || 'Server Error'))]));
  }



}
