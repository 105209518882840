import { Component, DoCheck, ElementRef, IterableDiffers, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TradeEntryService } from '../trade-entry.service';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Message } from 'primeng/api';
import { PickList } from 'primeng/picklist';

@Component({
  selector: 'cms-trade-entry-customise',
  templateUrl: './trade-entry-customise.component.html',
  styleUrls: ['./trade-entry-customise.component.scss']
})
export class TradeEntryCustomiseComponent implements OnInit, OnDestroy, DoCheck {

  static queryParamConfigurationName = 'configurationName';
  static queryParamFieldDisplayName = 'fieldDisplayName';

  @ViewChild(PickList, { static: true }) pickList: PickList;

  messages: Message[] = [];
  disabled: boolean;

  private onDestroy$ = new Subject();

  configurationName: string;
  fieldDisplayName: string;
  dropItem: Element[];

  users: { ident: number, value: string }[];

  preferred: KeyValueModel[];
  nonPreferred: KeyValueModel[];

  differ: any;

  constructor(private service: TradeEntryService, private title: Title, private activatedRoute: ActivatedRoute, private elementRef: ElementRef, private differs: IterableDiffers) {
    this.differ = differs.find([]).create(null);
  }

  ngOnInit() {
    this.messages = [];
    this.disabled = true;
    this.title.setTitle('Preferred Values');

    this.configurationName = this.activatedRoute.snapshot.queryParams[TradeEntryCustomiseComponent.queryParamConfigurationName];
    this.fieldDisplayName = this.activatedRoute.snapshot.queryParams[TradeEntryCustomiseComponent.queryParamFieldDisplayName];

    this.service.loadTradeEntryConfiguration(null, this.configurationName).pipe(takeUntil(this.onDestroy$)).subscribe(config => {
      if (config) {
        this.nonPreferred = config.notPreferredValues ? config.notPreferredValues : [];
        this.preferred = config.preferredValues ? config.preferredValues : [];
      }
    });

    this.service.loadUsers(this.configurationName).pipe(takeUntil(this.onDestroy$)).subscribe(users => {
      this.users = users;
      this.users.unshift({ ident: null, value: 'Choose user' });
    });
  }

  ngDoCheck() {
    const change = this.differ.diff(this.preferred);

    if (change) {
      change.forEachMovedItem((record) => {
        this.disabled = false;
      });

    }
  }

  loadUserSettings(event) {
    if (event.value) {

      this.service.loadTradeEntryConfiguration(event.value.ident, this.configurationName).pipe(takeUntil(this.onDestroy$)).subscribe(config => {
        if (config) {
          this.nonPreferred = config.notPreferredValues ? config.notPreferredValues : [];
          this.preferred = config.preferredValues ? config.preferredValues : [];

          if (event.value.ident) {
            this.messages = [];
            // messages
            this.messages.push({
              severity: 'warn',
              summary: '',
              detail: '"Save Changes" now if you wish to adopt the settings of user "' + event.value.value + '"'
            });
          }

          this.disabled = false;
        }
      });
    }
  }

  loadDefault() {
    this.service.loadDefaultTradeEntryConfiguration(this.configurationName).pipe(takeUntil(this.onDestroy$)).subscribe(config => {
      if (config) {
        this.nonPreferred = config.notPreferredValues ? config.notPreferredValues : [];
        this.preferred = config.preferredValues ? config.preferredValues : [];

        this.messages = [];
        this.disabled = false;

        // messages
        this.messages.push({
          severity: 'warn',
          summary: '',
          detail: '"Save Changes" now if you wish to replace your current settings with the system defaults.'
        });
      }
    });
  }

  saveSettings() {

    this.disabled = true;
    const keys = this.preferred.map(s => s.key);
    const requestModel = {
      tradeEntrySettingsKeys: keys
    };

    this.service.saveChanges(this.configurationName, requestModel).pipe(takeUntil(this.onDestroy$)).subscribe(result => {
        this.messages = [];
        // messages
        this.messages.push({ severity: 'success', summary: '', detail: 'Settings saved.' });
      },
      err => {
        this.messages = [];
        this.disabled = false;
      }
    );
  }

  enableSaveButton() {
    this.disabled = false;
  }

  ngOnDestroy(): void {
    this.messages = [];
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  getListWidthConfigurationClass(): string {
    return this.configurationName;
  }

  moveList($event) {

    this.dropItem = this.elementRef.nativeElement.querySelectorAll('.p-picklist-item');

    if (this.dropItem) {
      for (const element of this.dropItem) {
        if (this.isDragHitBoxTop(element, $event)) {
          element.previousElementSibling.setAttribute('style', 'height: 29px');
          element.nextElementSibling.setAttribute('style', 'height: 2px');
        } else if (this.isDragHitBoxBottom(element, $event)) {
          element.previousElementSibling.setAttribute('style', 'height: 2px');
          element.nextElementSibling.setAttribute('style', 'height: 29px');
        } else {
          element.previousElementSibling.setAttribute('style', 'height: 2px');
        }
      }
    }
  }

  private isDragHitBoxTop(element: Element, $event): boolean {
    const rect = element.getBoundingClientRect();
    return rect.left < $event.x && rect.right > $event.x && rect.top < $event.y && rect.bottom - 14 > $event.y;
  }

  private isDragHitBoxBottom(element: Element, $event): boolean {
    const rect = element.getBoundingClientRect();
    return rect.left < $event.x && rect.right > $event.x && rect.top + 15 < $event.y && rect.bottom > $event.y;
  }

  resetDropPoints() {
    const dropPoints = this.elementRef.nativeElement.querySelectorAll('.p-picklist-droppoint');

    if (dropPoints) {
      for (const dp of dropPoints) {
        dp.setAttribute('style', 'height: 2px');
      }
    }
  }

}

export interface KeyValueModel {
  key: string;
  value?: string;
}
