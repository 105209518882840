import { EcmMyFilter } from '../../../deal-finder/deal-finder.model';
import { SET_TO_DEFAULT, SetToDefaultAction } from '../dashboard.actions';
import * as fromMFActions from './my-filters.actions';
import { EcmDashboardState } from '../dashboard.reducers';
import { AllDealsMyFilterName } from '@common/my-filters/my-filters.model';
import { HiddenDeals } from '@common/deals/deals.model';

export interface EcmDashboardMyFiltersState {
  defaultFilter: EcmMyFilter;
  filterSelections: { [key: string]: EcmMyFilter };
  allDealsFilter: EcmMyFilter;
}

export const myFilterInitialState = {
  defaultFilter: null,
  filterSelections: null,
  allDealsFilter: {
    defaultFilter: true,
    hiddenType: HiddenDeals.EXCLUDE,
    filterName: AllDealsMyFilterName,
    brokerFilter: [],
    counterpartyFilter: [],
    commodityFilter: [],
    marketFilter: [],
    transactionTypeFilter: []
  }
};

export function ecmMyFiltersReducer(state: EcmDashboardMyFiltersState,
                                      action: fromMFActions.MyFiltersActions | SetToDefaultAction): EcmDashboardMyFiltersState {
  switch (action.type) {
    case fromMFActions.DELETE_MY_FILTER_SUCCESS: {
      return deleteFilter(state, action.payload.filterName);
    }
    case fromMFActions.SET_ALL_DEALS_FILTER: {
      return {
        ...state,
        allDealsFilter: action.payload
      };
    }
    case fromMFActions.LOAD_MY_FILTERS_SUCCESS:
    case fromMFActions.RELOAD_MY_FILTER_SUCCESS: {
      const { defaultFilter, filterSelections } = action.payload;
      return {
        ...state,
        defaultFilter,
        filterSelections
      };
    }
    case SET_TO_DEFAULT:
      return { ...myFilterInitialState };
    default:
      return state;
  }
}

export const deleteFilter = (state: EcmDashboardMyFiltersState, filterName: string): EcmDashboardMyFiltersState => {
  const defaultFilter = state.defaultFilter && state.defaultFilter.filterName === filterName ? null : state.defaultFilter;
  let filterSelections = null;
  if (state.filterSelections) {
    filterSelections = {};
    Object.entries(state.filterSelections).forEach(entry => {
      const [key, value] = entry;
      if (key !== filterName) {
        filterSelections[key] = value;
      }
    });
  }
  return {
    ...state,
    defaultFilter,
    filterSelections
  };
};

export const getMyFilters = (state: EcmDashboardState): EcmDashboardMyFiltersState => state.myFilters;
export const getAllMyFilters = (state: EcmDashboardMyFiltersState): EcmMyFilter[] => state.filterSelections ? Object.values(state.filterSelections) : null;
export const getDefaultFilter = (state: EcmDashboardMyFiltersState): EcmMyFilter => state.defaultFilter;
export const getAllDealsFilter = (state: EcmDashboardMyFiltersState): EcmMyFilter => state.allDealsFilter;
