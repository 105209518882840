<div class="panel-box margin-bottom-12" *ngIf="onUploadPermission | async">
  <label>Upload ACER XML report file (.xml)</label>
  <form [formGroup]="uploadForm" class="flex flex-wrap md:flex-nowrap align-items-center col-12 col-lg-8" (ngSubmit)="submit()">
    <div class="w-full md:w-1/2">
      <input id="input-filename-remit-files-in" type="text" class="form-control p-component padding-6 text-medium" [value]="fileName" readonly>
    </div>
    <div class="w-full md:w-auto px-0 md:!px-3">
      <input class="checkbox" type="checkbox" (change)="onCheckbox($event)" [checked]="this.elcomOnly" formControlName="elcomOnly">ElCom only
    </div>
    <div class="w-full md:w-auto">
      <button class="btn form-control margin-3 padding-6" style="width: 75px;" (click)="browseFiles();" [disabled]="this.filesInService.getUploadingObservable()| async">Browse...</button>
      <button id="btn-submit-remit-files-in" type="submit" style="width: 75px;" class="btn form-control margin-3 padding-6" [disabled]="!fileSelected || (this.filesInService.getUploadingObservable()| async)">Upload</button>
    </div>

    <input formControlName="xmlFile" id="remit-files-in-upload-button" type="file" class="hidden" (change)="onSelect($event)" accept=".xml" #fileUpload >
  </form>
</div>
