import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RemitTradeEntryTable1Service } from '../remit-trade-entry-table1.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SelectItemGroup } from 'primeng/api';
import { RemitTradeEntryTable1SettlementModel } from '../remit-trade-entry-table1-data.model';

@Component({
  selector: 'cms-remit-trade-entry-table1-commercials',
  templateUrl: './remit-trade-entry-table1-commercials.component.html',
  styleUrls: ['./remit-trade-entry-table1-commercials.component.scss']
})
export class RemitTradeEntryTable1CommercialsComponent implements OnInit, OnDestroy {

  @Input()
  formGroup: UntypedFormGroup;
  tradeEntrySettlementData: RemitTradeEntryTable1SettlementModel = null;

  private onDestroy$ = new Subject();
  validationErrors: string[] = [];

  execution: boolean;
  priceCurrency: string;

  quantityUnit: string;
  quantityUnitValue: string;
  totalQuantityUnit: string;
  totalQuantityUnitValue: string;
  indexName: SelectItemGroup[];
  indexValue: string;
  indexCurrencies: SelectItemGroup[];

  constructor(private tradeEntryService: RemitTradeEntryTable1Service) {
  }

  ngOnInit() {
    this.formGroup.addControl('deliveryStartDate', new UntypedFormControl(''));
    this.formGroup.addControl('deliveryEndDate', new UntypedFormControl(''));
    this.formGroup.addControl('price', new UntypedFormControl(''));
    this.formGroup.addControl('notionalAmount', new UntypedFormControl(''));
    this.formGroup.addControl('quantity', new UntypedFormControl(''));
    this.formGroup.addControl('totalNotionalContractQuantity', new UntypedFormControl(''));
    this.formGroup.addControl('indexName', new UntypedFormControl(''));
    this.formGroup.addControl('indexValue', new UntypedFormControl(''));
    this.formGroup.addControl('indexCurrency', new UntypedFormControl({value: '', disabled: false}));

    this.tradeEntryService.onTradeEntryDataChange().pipe(takeUntil(this.onDestroy$)).subscribe(tradeEntryData => {
      if (tradeEntryData) {
        this.tradeEntrySettlementData = tradeEntryData.settlement;
        this.indexCurrencies = this.getIndexCurrencies();
        this.indexName = this.getIndexNames();
        this.updateAcerV3Form();
      }
    });

    this.formGroup.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(
      formValues => {

        if (formValues.contractType && formValues.contractType.startsWith('OP')) {
          this.priceCurrency = '';
          if (formValues.optionPremiumCurrency) {
            this.priceCurrency = formValues.optionPremiumCurrency;
          }
        } else if (this.priceCurrency !== formValues.priceCurrency) {
          this.priceCurrency = formValues.priceCurrency;
        }

        if (this.quantityUnit !== formValues.quantityUnit) {
          this.quantityUnit = formValues.quantityUnit;
          this.quantityUnitValue = this.tradeEntryService.getSelectedQuantityUnitValue();
        }

        if (this.totalQuantityUnit !== formValues.totalQuantityUnit) {
          this.totalQuantityUnit = formValues.totalQuantityUnit;
          this.totalQuantityUnitValue = this.tradeEntryService.getSelectedTotalQuantityUnitValue();
        }

        this.execution = formValues.execution;

      }
    );
  }

  private updateAcerV3Form() {
    this.formGroup.get('indexCurrency').enable();

  }

  private getIndexNames(): SelectItemGroup[] {
    if (this.tradeEntrySettlementData) {
      return this.tradeEntryService.getGroupedValues(this.tradeEntrySettlementData.indexNames);
    }

    return [{ label: '', items: [] }];
  }

  private getIndexCurrencies(): SelectItemGroup[] {
    if (this.tradeEntrySettlementData) {
      return this.tradeEntryService.getGroupedValues(this.tradeEntrySettlementData.priceCurrencies);
    }

    return [{ label: '', items: [] }];
  }

  selectCommodityFirstPlaceholder(fieldName: SelectItemGroup[]): string {
    return fieldName && fieldName[0].items.length === 0 ? 'Please select a Commodity first' : '';
  }

  getValidationErrorStyleClass(fieldName: string): string {
    if (this.formGroup.controls[fieldName].hasError('error')) {
      return 'error';
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

}
