<h1 *ngIf="!(selectedOrgGroup$ | async)">List All Internal Organisations</h1>
<h1 *ngIf="(selectedOrgGroup$ | async)">List Internal Organisations Of Organisation Group <span>{{(selectedOrgGroup$ | async).value}}</span></h1>
<div class="panel-box">
  <p-messages [value]="messages$ | async" [closable]="true"></p-messages>
  <div class="margin-bottom-12 decent-max-width">
    <cms-organisation-group-filter *ngIf="orgGroupSelectorVisible"
                                   [filters]="(filters$ | async).organisationGroupId"
                                   [orgGroups]="orgGroups$ | async"
                                   (filter)="onOrgGroupFilter($event)">
    </cms-organisation-group-filter>
  </div>
  <cms-datatable *ngIf="(data$ | async) as data" class="tb-header-right"
                 selectionMode="single"
                 [currentPage]="(paging$ | async).page"
                 [tableColumns]="tableColumns$ | async"
                 [sortField]="(sorting$ | async).columnName"
                 [sortOrder]="(sorting$ | async).sortOrder"
                 [loading]="loading$ | async"
                 [data]="data.values"
                 [totalRecords]="data.count"
                 [selected]="selected"
                 (selectedChange)="onOrganisationSelected($event)"
                 [lazy]="true"
                 [lazyLoadOnInit]="false"
                 (lazyLoad)="onLazyLoad($event)">
    <ng-template pTemplate="caption">
      <div class="row margin-0">
        <cms-input-text-filter placeholder="{{getPlaceholderText()}}" (filter)="onTextFieldFilter($event)" class="col-6 margin-0 padding-0"></cms-input-text-filter>
        <div class="col text-end">
          <button class="btn export-btn" pButton type="button" icon="fa fa-download" label="Export" (click)="onExport()"></button>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="body" let-row="row" let-column="column">
      <td *ngIf="column.type === 'orgGrpId' && fhaUser" [ngStyle]="column.style" >{{row[column.name]}}</td>
      <td *ngIf="column.type === 'status'" [ngStyle]="column.style">
        <cms-cell-status [column]="column"
                         [showBlock]="false"
                         [row]="row"
                         [editable]="(form$ | async)?.toggleStatusAllowed"
                         (confirm)="onChangeStatus($event)">
        </cms-cell-status>
      </td>
    </ng-template>
  </cms-datatable>
  <div class="padding-0 col-lg-12 col-12 btn__container margin-3 flex organisations---footer-btn">
    <div class="col padding-0 margin-0 flex justify-content-start align-items-center">
      <button *ngIf="(form$ | async)?.editAllowed" [disabled]="!selected" class="btn margin-top-3 margin-bottom-3 margin-left-12" type="submit" (click)="onEditOrganisation()">Edit Organisation</button>
      <button *ngIf="(form$ | async)?.editEcmAllowed" [disabled]="!selected" class="btn margin-top-3 margin-bottom-3 margin-left-12" type="submit" (click)="onEditEcm()">Edit eCM</button>
      <button *ngIf="(form$ | async)?.editErrAllowed" [disabled]="!selected" class="btn margin-top-3 margin-bottom-3 margin-left-12" type="submit" (click)="onEditErr()">Edit eRR</button>
      <button *ngIf="(form$ | async)?.editEsmAllowed" [disabled]="!selected" class="btn margin-top-3 margin-bottom-3 margin-left-12" type="submit" (click)="onEditEsm()">Edit eSM</button>
    </div>

    <div class="col padding-0 margin-0 flex justify-content-end align-items-center" *ngIf="orgGroupSelectorVisible">
      <button [disabled]="!((filters$ | async).organisationGroupId)" class="btn margin-top-3 margin-bottom-3 margin-left-12" type="button" (click)="onAddOrganisation()">Add Organisation</button>
      <button [disabled]="!selected" class="btn margin-top-3 margin-bottom-3 margin-left-12" type="submit" (click)="onMoveOrganisation()">Move Organisation</button>
      <button [disabled]="!selected" class="btn margin-top-3 margin-bottom-3 margin-left-12" type="submit" (click)="onDeleteOrganisation()">Delete Organisation</button>
    </div>
  </div>
</div>
<cms-organisation-dialogs-container *ngIf="(form$ | async)?.editAllowed"
                                    [orgGroups]="orgGroups$ | async"
                                    [editableData]="editableData$ | async"
                                    [organisationGroupId]="(filters$ | async)?.organisationGroupId"
                                    [selected]="selected"
                                    [dialog]="dialog$ | async"
                                    (cancel)="onCancelDialog($event)"
                                    (delete)="onConfirmDeleteOrganisation($event)"
                                    (add)="onConfirmAddOrganisation($event)"
                                    (edit)="onConfirmEditOrganisation($event)"
                                    (changeActivation)="onConfirmActivationChange($event)"
                                    (editEcm)="onConfirmEditEcm($event)"
                                    (editErr)="onConfirmEditErr($event)"
                                    (editEsm)="onConfirmEditEsm($event)"
                                    (move)="onConfirmMoveOrganisation($event)">
</cms-organisation-dialogs-container>
