import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiRequestService } from '@common/api-request.service';
import {
  RemitTradeEntryTable1DataModel,
  RemitTradeEntryTable1OtherCounterpartyModel,
  RemitTradeEntryTable1ReportingCounterpartyModel,
  RemitTradeEntryTable1SettlementModel,
  RemitTradeEntryTable1ValueSetResponseModel
} from './remit-trade-entry-table1-data.model';
import { Message, SelectItemGroup } from 'primeng/api';
import { map, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class RemitTradeEntryTable1Service {

  apiPath: string = '/api/err/remit/tradeEntry/table1';
  tradeEntryData = new BehaviorSubject<RemitTradeEntryTable1DataModel>(null);

  selectedQuantityUnitValue: string;
  selectedTotalQuantityUnitValue: string;

  private message = new BehaviorSubject<Message>(null);
  submitted = new BehaviorSubject<boolean>(false);
  reseted = new BehaviorSubject<boolean>(false);
  validationErrorsFieldNames = new BehaviorSubject<string>(null);

  transactionTimeValid = new BehaviorSubject<boolean>(false);
  startTimeValid = new BehaviorSubject<boolean>(false);
  endTimeValid = new BehaviorSubject<boolean>(false);
  allFieldsValid = new BehaviorSubject<boolean>(false);

  constructor(private apiRequestService: ApiRequestService) {

    // Note: zip() did not work. This on the other hand seems to work well.
    this.transactionTimeValid.asObservable().pipe(withLatestFrom(this.startTimeValid, this.endTimeValid)).subscribe(([transactionTimeValid, startTimeValid, endTimeValid]) => {
      this.allFieldsValid.next(transactionTimeValid && startTimeValid && endTimeValid);
    });
    this.startTimeValid.asObservable().pipe(withLatestFrom(this.transactionTimeValid, this.endTimeValid)).subscribe(([startTimeValid, transactionTimeValid, endTimeValid]) => {
      this.allFieldsValid.next(transactionTimeValid && startTimeValid && endTimeValid);
    });
    this.endTimeValid.asObservable().pipe(withLatestFrom(this.transactionTimeValid, this.startTimeValid)).subscribe(([endTimeValid, transactionTimeValid, startTimeValid]) => {
      this.allFieldsValid.next(transactionTimeValid && startTimeValid && endTimeValid);
    });
  }

  submit(searchQuery: any) {
    this.apiRequestService.post('/api/err/remit/tradeEntry/remitTable1/newTrade', searchQuery).subscribe(
      response => {
        if (response.fieldValidationMessages && Object.keys(response.fieldValidationMessages).length > 0) {
          this.listFieldValidationErrorsOrdered(response.fieldValidationMessages);
          for (const errorMessage of Object.keys(response.fieldValidationMessages)) {
            this.validationErrorsFieldNames.next(errorMessage);
          }
          this.submitted.next(false);
        } else if (response.validationFailureMessages) {
          for (const element of response.validationFailureMessages) {
            this.setMessage({ severity: 'error', detail: element });
          }
          this.submitted.next(false);
        } else {
          this.setMessage({ severity: 'success', detail: 'Trade report created successfully.' });
          this.submitted.next(true);
        }
      }, error => {
        let errorDetails = '';
        if (error.error && error.error.errorMessage) {
          errorDetails = error.error.errorMessage;
        }
        this.setMessage({ severity: 'error', summary: error.statusText, detail: errorDetails });
        this.submitted.next(false);
      }
    );
  }

  loadTradeEntryData(searchQuery: any) {
    this.apiRequestService.post(this.apiPath + '/loadValues', searchQuery).subscribe(
      response => {
        this.tradeEntryData.next(response);
      }
    );
  }

  hasAcerCode(): Promise<boolean> {
    return this.apiRequestService.get('/api/err/remit/tradeEntry/hasAcerCode', null).pipe(map(response => {
      return response.response;
    })).toPromise();
  }

  onSubmitted(): Observable<boolean> {
    return this.submitted.asObservable();
  }

  onReset(): Observable<boolean>{
    return this.reseted.asObservable();
  }

  nextReset(value:boolean){
    this.reseted.next(value);
  }

  onTradeEntryDataChange(): Observable<RemitTradeEntryTable1DataModel> {
    return this.tradeEntryData.asObservable();
  }

  onAllFieldsValidUpdated(): Observable<boolean> {
    return this.allFieldsValid.asObservable();
  }

  getGroupedValues(array: any): SelectItemGroup[] {
    if (array) {
      if (array.preferredValues.length > 0) {
        return [
          {
            label: 'Preferred',
            items: array.preferredValues.map(item => (
              {
                label: item.value,
                value: item.key
              }
            ))
          }
          ,
          {
            label: 'Others',
            items:
              array.notPreferredValues.map(item => (
                {
                  label: item.value,
                  value: item.key
                }
              ))
          }
        ];
      } else {
        return [{
          label: ' ',
          items: array.notPreferredValues.map(item => (
            {
              label: item.value, value: item.key
            }
          ))
        }];
      }
    }

    return [{
      label: 'Please select a Commodity first!',
      items: []

    }];
  }

  updateSelectedQuantityUnitValue(quantityKey: string) {
    if (quantityKey) {
      const allValues = this.tradeEntryData.getValue().settlement.quantityUnits.preferredValues.concat(this.tradeEntryData.getValue().settlement.quantityUnits.notPreferredValues);
      this.selectedQuantityUnitValue = allValues.find(item => item.key === quantityKey).value;
    }
  }

  getSelectedQuantityUnitValue() {
    return this.selectedQuantityUnitValue;
  }

  updateSelectedTotalQuantityUnitValue(totalQuantityKey: string) {
    if (totalQuantityKey) {
      const allValues = this.tradeEntryData.getValue().settlement.totalQuantityUnits.preferredValues.concat(this.tradeEntryData.getValue().settlement.totalQuantityUnits.notPreferredValues);
      this.selectedTotalQuantityUnitValue = allValues.find(item => item.key === totalQuantityKey).value;
    }
  }

  getSelectedTotalQuantityUnitValue() {
    return this.selectedTotalQuantityUnitValue;
  }

  clearTradeEntryFormData(){
    this.tradeEntryData.next(null);
    this.message.next(null);
    this.validationErrorsFieldNames.next(null);
  }

  public setMessage(message: Message) {
    this.message.next(message);
  }

  public onMessageChange(): Observable<Message> {
    return this.message.asObservable();
  }

  onValidationErrorsFieldNamesChanged(): Observable<string>{
    return this.validationErrorsFieldNames.asObservable();
  }

  getMockData(): RemitTradeEntryTable1DataModel {

    const tmpContractType = {
      preferredValues: [{ 'key': 'AU', 'value': 'AU - Auction' }, { 'key': 'OP', 'value': 'OP - Option' }],
      notPreferredValues: [{ 'key': 'CO', 'value': 'CO - Continous' }, { 'key': 'FU', 'value': 'FU - Future' }, {
        'key': 'FW',
        'value': 'FW - Forward'
      }, { 'key': 'OP_FU', 'value': 'OP_FU - Option On Future' }, {
        'key': 'OP_FW',
        'value': 'OP_FW - Option On Forward'
      }, { 'key': 'OP_SW', 'value': 'OP_SW - Option On Swap' }, { 'key': 'OT', 'value': 'OT - Other' }, {
        'key': 'SP',
        'value': 'SP - Spread'
      }, { 'key': 'SW', 'value': 'SW - Swap (financial)' }]

    };

    const lt = {
      preferredValues: [],
      notPreferredValues: [{ 'key': 'abc', 'value': 'ABC - Continous' }, { 'key': 'FU', 'value': 'FU - Future' }]
    };

    const productDetails: RemitTradeEntryTable1ValueSetResponseModel = {
      contractTypes: tmpContractType,
      loadTypes: lt,
      energyCommodities: [{ key: 'NG', value: 'NG' }, { key: 'EL', value: 'EL' }]
    };

    const settlementData: RemitTradeEntryTable1SettlementModel = {
      settlementMethods: [{ key: 'OP', value: 'OP' }],
      indexNames: tmpContractType,
      priceCurrencies: tmpContractType,
      optionPremiumCurrencies: tmpContractType,
      quantityUnits: tmpContractType,
      totalQuantityUnits: tmpContractType
    };

    const reportingCounterpartyData: RemitTradeEntryTable1ReportingCounterpartyModel = {
      marketParticipants: [],
      traderId: '',
      buySell: [{ key: 'BUY', value: 'Buy' }],
      beneficiaryType: [{ key: 'ACE', value: 'ACE' }],
      beneficiaryCode: ''
    };

    const otherCounterpartyData: RemitTradeEntryTable1OtherCounterpartyModel = {
      otherMarketParticipants: [],
      otherBeneficiaryType: [{ key: 'ACE', value: 'ACE' }],
    };

    const entryData: RemitTradeEntryTable1DataModel = {
      marketParticipant: 'ALPHA0002.AA',
      productDetails: productDetails,
      settlement: settlementData,
      reportingCounterparty: reportingCounterpartyData,
      otherCounterparty: otherCounterpartyData,
      submitEnabled: true,
    };

    return entryData;
  }

  private listFieldValidationErrorsOrdered(fieldErrors: any){

    const handledErrors: string [] = [];

    let fieldList = ['transactionTime', 'uti', 'contractType', 'energyCommodity', 'deliveryPointOrZone', 'settlementMethod', 'priceCurrency', 'quantityUnit','totalQuantityUnit'];

    for (const field of fieldList) {
      if(fieldErrors[field]){
        handledErrors.push(field);
        this.setMessage({ severity: 'error', detail: fieldErrors[field]});
      }
    }

    for (const field of Object.keys(fieldErrors)) {
      if(field.indexOf('startTime') === 0 || field.indexOf('endTime') === 0  ){
        handledErrors.push(field);
        this.setMessage({ severity: 'error', detail: fieldErrors[field] });
      }
    }

    fieldList = ['traderId', 'buyOrSell', 'beneficiaryCode', 'otherMarketParticipantCode', 'beneficiaryCodeForOtherMarketParticipant', 'deliveryStartDate', 'deliveryEndDate', 'price', 'notionalAmount', 'quantity', 'totalNotionalContractQuantity'];

    for (const field of fieldList) {
      if(fieldErrors[field]){
        handledErrors.push(field);
        this.setMessage({ severity: 'error', detail: fieldErrors[field]});
      }
    }

    // other errors
    for (const field of Object.keys(fieldErrors)) {
      if(handledErrors.indexOf(field) < 0 ){
        this.setMessage({ severity: 'error', detail: fieldErrors[field] });
      }
    }

  }
}



