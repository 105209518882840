import { createSelector } from '@ngrx/store';
import { getEsmInvoiceFinderState } from '../invoice-finder.selectors';
import { EsmInvoiceFinderFiltersState } from './filters.reducers';
import * as fromReducer from './filters.reducers';

export const getFilters = createSelector(getEsmInvoiceFinderState, fromReducer.getFilters);
export const getFilterProperties = createSelector(getFilters, fromReducer.getFilterProperties);
export const getCommodities = createSelector(getFilters, fromReducer.getCommodities);
export const getCounterParties = createSelector(getFilters, fromReducer.getCounterParties);
export const getDeliveryPoints = createSelector(getFilters, fromReducer.getDeliveryPoints);
export const getInvoiceTypes = createSelector(getFilters, fromReducer.getInvoiceTypes);
export const getLoaded = createSelector(getFilters, fromReducer.getLoaded);
export const getNoFoundIds = createSelector(getFilters, fromReducer.getNoFoundIds);
export const getIdsCount = createSelector(getFilters, fromReducer.getIdsCount);
export const getInputIds = createSelector(getFilters, fromReducer.getInputIds);
export const getFilterBy = createSelector(getFilters, (state: EsmInvoiceFinderFiltersState) => state.filterBy);
