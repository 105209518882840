import { Pipe, PipeTransform } from '@angular/core';
import { DateUtils, TransformDateFormat } from '../../common/shared/date.utils';

@Pipe({
  name: 'percentage'
})

export class PercentagePipe implements PipeTransform {

  transform(value: number): string {
    return value ? value + '%' : '0%';
  }

}
