import {createReducer, on} from '@ngrx/store';
import {DatatableData, PagingFilter, SortingFilter} from '@common/shared/results.model';
import * as fromMDActions from './internal-master-data.actions';
import * as fromCommon from '@common/state/reducers';
import {
  AddBankDetailValue,
  EsmBankDetails,
  EsmFormValues,
  EsmMessagesModel,
  EsmVatIds,
  initialFormValues
} from '../../../master-data/master-data.model';
import {esmMDPdfSettingsReducer, EsmPdfSettingsState, initialMDPdfSettingsState} from './pdf-settings/pdf-settings.reducer';
import * as fromMdPdfSettingsActions from './pdf-settings/pdf-settings.actions';
import { EsmInternalMasterDataPresetValues } from 'app/esm/master-data/internal-master-data/internal-master-data.model';
import { ESM_MD_DIALOGS } from '../master-data.reducer';


export interface EsmInternalMasterDataState {
  presetValues: EsmInternalMasterDataPresetValues;
  messages: EsmMessagesModel;
  vatIds: EsmVatIds[];
  masterDataId: number;
  version: number;
  formValues: EsmFormValues;
  bankDetailValue: AddBankDetailValue;
  isFormDisable: boolean;
  isNewMDRecord: boolean;
  dialog: fromCommon.DialogState<ESM_MD_DIALOGS>;
  data: DatatableData<EsmBankDetails[]>;
  paging: PagingFilter;
  sorting: SortingFilter;
  loading: boolean;
  stale: boolean;
  isVisibleDetails: boolean;
  pdfSettings: EsmPdfSettingsState;
}

export const initialState: EsmInternalMasterDataState = {
  presetValues: null,
  messages: {
    masterData: [],
    bankDetails: []
  },
  vatIds: [],
  masterDataId: null,
  version: null,
  formValues: null,
  bankDetailValue: null,
  isFormDisable: true,
  isNewMDRecord: false,
  dialog: null,
  data: {
    values: [],
    count: 0,
    currentPage: 0
  },
  paging: {
    page: 0,
    entriesPerPage: 25
  },
  sorting: {
    columnName: 'currency',
    sortOrder: 'ASCENDING'
  },
  loading: false,
  isVisibleDetails: false,
  stale: false,
  pdfSettings: {...initialMDPdfSettingsState},
};

export const baseEsmMasterDataReducer = createReducer<EsmInternalMasterDataState, fromMDActions.MasterDataActions>(
  initialState,
  on(fromMDActions.loadPresetValuesSuccessAction, (state, {payload: {presetValues}}) => ({
    ...state,
    isFormDisable: !presetValues.editButtonsVisible,
    presetValues
  })),
  on(fromMDActions.setMessagesAction, (state, {payload}) => ({
      ...state,
      messages: {...state.messages, ...payload},
      dialog: null
    }
  )),
  on(fromMDActions.loadVatIdsSuccessAction, (state, {payload: {vatIds}}) => ({...state, vatIds})),
  on(fromMDActions.selectMasterDataIdAction, (state, {payload: masterDataId}) => ({...state, isVisibleDetails: true, masterDataId})),
  on(fromMDActions.setMasterDataIdAction, (state, {payload: masterDataId}) => ({...state, isVisibleDetails: true, masterDataId})),
  on(fromMDActions.setVersionAction, (state, {payload: version}) => ({...state, version})),
  on(fromMDActions.setFormEditableAction, (state, {payload: editable}) => ({...state, isFormDisable: !editable})),
  on(fromMDActions.loadVatIdsAction, state => ({...state, masterDataId: null})),
  on(fromMDActions.loadFormValuesSuccessAction, (state, {payload: {formValues}}) => ({
    ...state,
    formValues,
    isNewMDRecord: false,
  })),
  on(fromMDActions.persistSuccessAction, (state, {payload: formValues}) => ({...state, isNewMDRecord: false, formValues})),
  on(fromMDActions.updateSuccessAction, (state, {payload: formValues}) => ({...state, formValues})),
  on(fromMDActions.setStaleDataAction, (state, {payload: stale}) => ({...state, stale})),
  on(fromMDActions.setBankDetailValueAction, (state, {payload: bankDetailValue}) => ({...state, bankDetailValue})),
  on(fromMDActions.cancelAction, (state, {payload: formValues}) => ({...state, formValues})),
  on(fromMDActions.createNewMDRecordAction, state => ({
    ...state,
    isVisibleDetails: true,
    isNewMDRecord: true,
    isFormDisable: false,
    masterDataId: null,
    version: null,
    data: {
      values: [],
      count: 0,
      currentPage: 0
    },
    formValues: {...initialFormValues},
    messages: {...initialState.messages}
  })),
  on(fromMDActions.failMDRecordingAction, state => ({
    ...state,
    isVisibleDetails: false,
    formValues: {...initialFormValues}
  })),
  on(fromMDActions.failBDRecordingAction, state => ({
    ...state,
    bankDetailValue: null,
    loading: false,
    data: {...initialState.data},
    paging: {...initialState.paging},
    sorting: {...initialState.sorting}
  })),
  on(fromMDActions.loadBankDetailsAction, state => ({...state, loading: true})),
  on(fromMDActions.loadBankDetailsSuccessAction, (state, {payload}) => ({
    ...state,
    loading: false,
    data: {
      count: payload.count,
      values: payload.values
    },
    paging: {
      ...state.paging,
      page: payload.currentPage
    }
  })),
  on(fromMDActions.sortAndPageDataAction, (state, {payload: {paging, sorting}}) => ({
    ...state,
    loading: true,
    messages: {...state.messages, bankDetails: []},
    paging,
    sorting,
  })),
  on(fromMDActions.deleteMDRecordSuccessAction, state => ({
    ...state,
    isVisibleDetails: false
  })),
  on(fromMDActions.setToDefaultAction, () => ({...initialState}))
);

export const esmMasterDataReducer =
  fromCommon.withDialog<ESM_MD_DIALOGS, EsmInternalMasterDataState>(
      {
        OPEN_DIALOG: fromMDActions.openDialogAction.type,
        UPDATE_DIALOG: fromMDActions.updateDialogAction.type,
        CLOSE_DIALOG: fromMDActions.closeDialogAction.type,
        SET_TO_DEFAULT: fromMDActions.setToDefaultAction.type
      }
  )(baseEsmMasterDataReducer);


export function initEsmMasterDataReducer(
  state: EsmInternalMasterDataState = { ...initialState},
  action: fromMDActions.MasterDataActions & fromMdPdfSettingsActions.masterDataPdfSettingsActions) : EsmInternalMasterDataState{
  return {
    ...esmMasterDataReducer(state,action),
    pdfSettings: esmMDPdfSettingsReducer(state.pdfSettings, action)
  };
}
