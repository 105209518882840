import { Action } from '@ngrx/store';
import { OrganisationActivation } from '../../administration-tab-panel/organisations/dialogs-container/activation-dialog/activation.model';
import {
  DeleteOrganisation,
  EcmPresetValuesRequest,
  EcmPresetValuesResponse,
  EditEcm,
  EditErr,
  ErrPresetValuesResponse,
  MoveOrganisation,
  EditOrganisation,
  AddOrganisation,
  ChangeToExternalPartyAllowedResponse,
  Organisation,
  AdminOrganisationFormResponse,
  EsmPresetValuesRequest, EsmPresetValuesResponse, EditEsm
} from '../../administration-tab-panel/organisations/organisations.model';
import { Prefix } from '../reducers';

export const EXPORT = '[ORGANISATION] Export';
export const UPDATE_ACTIVATION = '[ORGANISATION] Update Activation';
export const ADD_ORGANISATION = '[ORGANISATION] Add Organisation';
export const EDIT_ORGANISATION = '[ORGANISATION] Edit Organisation';
export const CONFIRM_DELETE_ORGANISATION = '[ORGANISATION] Confirm Delete Organisation';
export const DELETE_ORGANISATION = '[ORGANISATION] Delete Organisation';
export const MOVE_ORGANISATION = '[ORGANISATION] Move Organisation';
export const EDIT_ECM = '[ORGANISATION] Edit eCM';
export const EDIT_ERR = '[ORGANISATION] Edit eRR';
export const EDIT_ESM = '[ORGANISATION] Edit eSM';
export const EDIT_ESM_FLAG = '[ORGANISATION] Edit eSM Flag';
export const LOAD_ECM_PRESET_VALUES = '[ORGANISATION] Load eCM Preset Values';
export const LOAD_ECM_PRESET_VALUES_SUCCESS = '[ORGANISATION] Load eCM Preset Values Success';
export const LOAD_ERR_PRESET_VALUES = '[ORGANISATION] Load eRR Preset Values';
export const LOAD_ERR_PRESET_VALUES_SUCCESS = '[ORGANISATION] Load eRR Preset Values Success';
export const CHANGE_TO_EXTERNAL_PARTY_ALLOWED = '[ORGANISATION] Is Change To External Party Allowed';
export const CHANGE_TO_EXTERNAL_PARTY_ALLOWED_SUCCESS = '[ORGANISATION] Is Change To External Party Allowed Success';
export const LOAD_ORGANISATION_FORM = '[ORGANISATION] Load Organisation Form';
export const LOAD_ORGANISATION_FORM_SUCCESS = '[ORGANISATION] Load Organisation Form Success';
export const LOAD_ESM_PRESET_VALUES = '[ORGANISATION] Load eSM Preset Values';
export const LOAD_ESM_PRESET_VALUES_SUCCESS = '[ORGANISATION] Load eSM Preset Values Success';

export class ChangeToExternalPartyAllowedAction implements Action {
  readonly type = CHANGE_TO_EXTERNAL_PARTY_ALLOWED;
}

export class ChangeToExternalPartyAllowedSuccessAction implements Action {
  readonly type = CHANGE_TO_EXTERNAL_PARTY_ALLOWED_SUCCESS;
  constructor(public payload: ChangeToExternalPartyAllowedResponse) { }
}

export class ExportAction implements Action {
  readonly type = EXPORT;
}

export class UpdateActivationAction implements Action {
  readonly type = UPDATE_ACTIVATION;
  constructor(public payload: OrganisationActivation) { }
}

export class AddOrganisationAction implements Action {
  readonly type = ADD_ORGANISATION;
  constructor(public payload: AddOrganisation) { }
}

export class EditOrganisationAction implements Action {
  readonly type = EDIT_ORGANISATION;
  constructor(public payload: EditOrganisation) { }
}

export class DeleteAction implements Action {
  readonly type = DELETE_ORGANISATION;
}

export class ConfirmDeleteOrganisationAction implements Action {
  readonly type = CONFIRM_DELETE_ORGANISATION;
  constructor(public payload: DeleteOrganisation) { }
}

export class MoveOrganisationAction implements Action {
  readonly type = MOVE_ORGANISATION;
  constructor(public payload: MoveOrganisation) { }
}

export class EditEcmAction implements Action {
  readonly type = EDIT_ECM;
  constructor(public payload: EditEcm) { }
}

export class EditErrAction implements Action {
  readonly type = EDIT_ERR;
  constructor(public payload: EditErr) { }
}


export class EditEsmAction implements Action {
  readonly type = EDIT_ESM;
  constructor(public payload: EditEsm) { }
}

export class LoadEcmPresetValuesAction {
  readonly type = LOAD_ECM_PRESET_VALUES;
  constructor(public payload: EcmPresetValuesRequest) { }
}

export class LoadEcmPresetValuesSuccessAction {
  readonly type = LOAD_ECM_PRESET_VALUES_SUCCESS;
  constructor(public payload: EcmPresetValuesResponse) { }
}

export class LoadErrPresetValuesAction {
  readonly type = LOAD_ERR_PRESET_VALUES;
  constructor(public payload: EcmPresetValuesRequest) { }
}

export class LoadEsmPresetValuesAction {
  readonly type = LOAD_ESM_PRESET_VALUES;
  constructor(public payload: EsmPresetValuesRequest) { }
}

export class LoadEsmPresetValuesSuccessAction {
  readonly type = LOAD_ESM_PRESET_VALUES_SUCCESS;
  constructor(public payload: EsmPresetValuesResponse) { }
}

export class LoadErrPresetValuesSuccessAction {
  readonly type = LOAD_ERR_PRESET_VALUES_SUCCESS;
  constructor(public payload: ErrPresetValuesResponse) { }
}

export class LoadOrganisationFormAction implements Action {
  get type() {
    return `${this.prefix} ${LOAD_ORGANISATION_FORM}`;
  }

  constructor(public prefix: Prefix) {
  }
}

export class LoadOrganisationFormSuccessAction {
  get type() {
    return `${this.prefix} ${LOAD_ORGANISATION_FORM_SUCCESS}`;
  }

  constructor(public prefix: Prefix, public payload: AdminOrganisationFormResponse) {
  }
}

export type OrganisationActions = ExportAction | UpdateActivationAction | AddOrganisationAction | EditOrganisationAction |
  MoveOrganisationAction | EditEcmAction | EditErrAction | LoadEcmPresetValuesAction |
  LoadEcmPresetValuesSuccessAction | LoadErrPresetValuesAction | LoadErrPresetValuesSuccessAction |
  LoadOrganisationFormAction | LoadOrganisationFormSuccessAction |
  EditEsmAction;
