import { createAction, union } from '@ngrx/store';
import { DatatableConfig, DatatableData } from '@common/shared/results.model';
import { Message } from 'primeng/api';
import { Column } from '@common/shared/datatable.model';
import { AddNote, ECM_DEALS_DIALOGS } from '@common/deals/deals.model';
import { DialogState } from '@common/state/reducers';

export const loadDealsAction = createAction('[ECM MONITORING DEALS] Load Deals');
export const loadDealsSuccessAction = createAction('[ECM MONITORING DEALS] Load Deals Success', (payload: DatatableData<any>) => ({payload}));
export const setToDefaultAction = createAction('[ECM MONITORING DEALS] Set to Default');
export const setSortAndPageDataAction = createAction('[ECM MONITORING DEALS] Sort and Page Data', (payload: DatatableConfig) => ({payload}));
export const setMessagesAction = createAction('[ECM MONITORING DEALS] Set Messages', (payload: Message[]) => ({payload}));
export const setColumnAction = createAction('[ECM MONITORING DEALS] Set Column', (payload: Column[]) => ({payload}));
export const exportAction = createAction('[ECM MONITORING DEALS] Export');
export const downloadXmlAction = createAction('[ECM MONITORING DEALS] Download Xml', (payload: {confirmationId}) => ({payload}));
export const downloadHtmlAction = createAction('[ECM MONITORING DEALS] Download Html', (payload: {confirmationId}) => ({payload}));
export const addNotesAction = createAction('[ECM MONITORING DEALS] Add Notes', (payload: AddNote) => ({payload}));
export const setQueryParamsAction = createAction('[ECM MONITORING DEALS] Set Query Params', (payload: {
  organisationId: number,
  categoryName: string,
  senderOrgEicCode: string,
  submissionDate: string
}) => ({payload}));
export const setSelectedConfirmationIdsAction = createAction('[ECM MONITORING DEALS] Set Selected Confirmation Ids', (payload: {confirmationIds: string[]}) => ({payload}));
export const addSelectedConfirmIdAction = createAction('[ECM MONITORING DEALS] Add Selected Confirmation Id', (payload: {confirmationId: string}) => ({payload}));
export const removeSelectedConfirmIdAction = createAction('[ECM MONITORING DEALS] Remove Selected Confirmation Id', (payload: {confirmationId: string}) => ({payload}));
export const openDialogAction = createAction('[ECM MONITORING DEALS] Open Dialog', (payload: DialogState<ECM_DEALS_DIALOGS>) => ({payload}));
export const closeDialogAction = createAction('[ECM MONITORING DEALS] Close Dialog');
export const updateDialogAction = createAction('[ECM MONITORING DEALS] Update Dialog', (payload: DialogState<ECM_DEALS_DIALOGS>) => ({payload}));

const action = union({
  loadDealsAction,
  loadDealsSuccessAction,
  setQueryParamsAction,
  setSortAndPageDataAction,
  setMessagesAction,
  setColumnAction,
  setSelectedConfirmationIdsAction,
  addSelectedConfirmIdAction,
  removeSelectedConfirmIdAction,
  openDialogAction,
  closeDialogAction,
  updateDialogAction,
});

export type MonitoringDealsActions = typeof action;
