<form [formGroup]="form" class="panel-box" autocomplete="off">
  <div class="form-row align-items-center">
    <label class="text-field-label margin-0 !font-bold col-md-12 col-lg-4 pe-0">Invoice Start Number:
      <span class="asterisk"></span></label>
    <div class="col-md-12 col-lg-4">
      <input type="text" cmsDigitOnly
             formControlName="invoiceStartNumber"
             class="form-control"
             [ngClass]="{'error': errors['invoiceStartNumber']}"
             (keypress)="onKeypress($event)"
             id="invoiceStartNumber">
    </div>
    <label class="justify-content-center margin-0 col-md-12 col-lg-1 checkbox-container">
      <input class="checkbox" type="checkbox" id="draft" formControlName="draft">Draft
    </label>
    <div class="margin-right-12 col">
      <button class="btn" (click)="onConfirm()" id="billin-runs-start-run-button" [disabled]="isDisabled || isLoading">
        Start Billing Run
        <span *ngIf="isLoading"><i class="pi-spin pi pi-spinner"></i></span>
      </button>
    </div>
  </div>
</form>
