import { DomHandler } from 'primeng/dom';
import { Table, TableService } from 'primeng/table';
import { ObjectUtils } from 'primeng/utils';
import { Component, ContentChild, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { Message } from 'primeng/api';
import { DatatableState } from '@common/datatable/datatable.model';
import { OrganisationModel } from '@common/organisation.model';
import { Column } from '@common/shared/datatable.model';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { DatatableComponent } from '@common/datatable/datatable.component';
import { OrganisationService } from '@common/organisation.service';
import {
  allColumnsConfig,
  DialogState,
  FilterRequest,
  TableDataValuesResponse,
} from './retry-queue.model';
import * as fromSelectors from '../state/retry-queue/retry-queue.selectors';
import * as fromActions from '../state/retry-queue/retry-queue.actions';
import { ErrRetryQueueState } from '../state/retry-queue/retry-queue.reducers';
import { ErrorMessageService } from '@common/errors/error-message.service';

@Component({
  selector: 'cms-retry-queue',
  templateUrl: './retry-queue.component.html',
  styleUrls: ['./retry-queue.component.scss'],
  providers: [
    DomHandler,
    ObjectUtils,
    TableService,
    Table
  ],
})
export class RetryQueueComponent implements OnInit, OnDestroy {
  @ContentChild(DatatableComponent, { static: false }) datatable: DatatableComponent;
  data$: Observable<DatatableData<TableDataValuesResponse[]>> = this.store.pipe(select(fromSelectors.getData));
  loading$: Observable<boolean> = this.store.pipe(select(fromSelectors.getLoading));
  messages$: Observable<Message[]> = this.store.pipe(select(fromSelectors.getMessages));
  paging$: Observable<PagingFilter> = this.store.pipe(select(fromSelectors.getPaging));
  sorting$: Observable<SortingFilter> = this.store.pipe(select(fromSelectors.getSorting));
  filter$: Observable<string> = this.store.pipe(select(fromSelectors.getFilter));
  reasons$: Observable<{ key: string, value: string }[]> = this.store.pipe(select(fromSelectors.getReasons));
  dialog$: Observable<DialogState> = this.store.pipe(select(fromSelectors.getDialog));
  disabled$: Observable<boolean> = this.store.pipe(select(fromSelectors.getDisabled));
  selected$:  Observable<TableDataValuesResponse[]> = this.store.pipe(select(fromSelectors.getSelected));
  subscription$: Subscription;
  dataTableColumns: Column[] = allColumnsConfig;
  initialFilter: FilterRequest = {
    filter: '',
    paging: {
      entriesPerPage: 25,
      page: 0
    },
    sorting: {
      columnName: 'receiptTimestamp',
      sortOrder: 'ASCENDING'
    }
  };
  constructor(private store: Store<ErrRetryQueueState>,
              private organisationService: OrganisationService,
              public readonly errorMessageService:ErrorMessageService) { }

  ngOnInit(): void {
    this.store.dispatch(fromActions.setOrganisationIdAction(this.organisationService.getCachedOrganisationId()));
    this.store.dispatch(fromActions.loadDataAction(this.initialFilter));
    this.store.dispatch(fromActions.getRejectReasonsAction([]));

    this.subscription$ = this.organisationService
      .getChangeOrganisationObservable()
      .pipe(withLatestFrom(this.store.pipe(select(fromSelectors.getOrganisationId))))
      .subscribe((([organisation, organisationId]: [OrganisationModel, number]) => {
        if ((organisation && organisation.organisationId !== organisationId) || (!organisation && organisationId)) {
          this.store.dispatch(fromActions.setOrganisationIdAction(organisation ? organisation.organisationId : null));
          this.store.dispatch(fromActions.setToDefaultAction());
          this.store.dispatch(fromActions.loadDataAction(this.initialFilter));
          this.store.dispatch(fromActions.getRejectReasonsAction([]));
        }
      }));
  }

  onFilter(filter): void {
    this.store.dispatch(fromActions.loadDataAction(filter));
  }

  onReset(): void {
    this.store.dispatch(fromActions.setToDefaultAction());
    this.store.dispatch(fromActions.loadDataAction(this.initialFilter));
    this.store.dispatch(fromActions.getRejectReasonsAction([]));
  }

  onSelected(selected: TableDataValuesResponse[]) : void {
    this.store.dispatch(fromActions.setSelectedItems(selected));
  }

  onLazyLoad(state: DatatableState): void {
    this.store.dispatch(fromActions.sortAndPageDataAction({
      paging: {
        page: state.page,
        entriesPerPage: state.perPage
      },
      sorting: {
        columnName: state.sortField,
        sortOrder: state.sortOrder
      }
    }));
  }

  onDownload(dataStoreId: number): void {
    this.store.dispatch(fromActions.downloadDocumentAction(dataStoreId));
  }

  onRetry(): void {
    this.store.dispatch(fromActions.retryAction());
  }

  onReject(): void {
    this.store.dispatch(fromActions.openDialogAction({
      messages: []
    }));
  }

  onCancelDialog(): void {
    this.store.dispatch(fromActions.closeDialogAction());
  }

  onRejectDialog(reason : string): void {
    this.store.dispatch(fromActions.rejectAction({ reason }));
  }

  ngOnDestroy(): void {
    this.store.dispatch(fromActions.setToDefaultAction());
    this.subscription$.unsubscribe();
  }
}
