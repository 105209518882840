import { State } from '../reducers';
import { ecmDashboardFiltersReducer, EcmDashboardFiltersState, filtersInitialState } from './filters/filters.reducers';
import { Ecm_Dashboard_Categories } from '../../ecm-dashboard/ecm-dashboard.model';
import { DialogState } from './dialog/dialog.reducers';
import * as fromMFReducer from './my-filters/my-filters.reducers';
import * as fromCategoryReducer from './category/category.reducers';
import * as fromDialogReducer from './dialog/dialog.reducers';
import * as fromTabReducer from './tab/tab.reducers';
import { ecmDealsInitialState, ecmDealsReducer, EcmDealsState } from './deals/deals.reducers';

export interface EcmDashboardState {
  filters: EcmDashboardFiltersState;
  myFilters: fromMFReducer.EcmDashboardMyFiltersState;
  category: Ecm_Dashboard_Categories;
  dialog: DialogState;
  tab: fromTabReducer.EcmDashboardTabState;
  deals: EcmDealsState;
}

export const initialState: EcmDashboardState = {
  category: null,
  filters: filtersInitialState,
  myFilters: fromMFReducer.myFilterInitialState,
  dialog: null,
  tab: fromTabReducer.tabInitialState,
  deals: ecmDealsInitialState,
};

export function ecmDashboardReducer(state: EcmDashboardState = {...initialState},
                                    action: any): EcmDashboardState {
  return {
    filters: ecmDashboardFiltersReducer(state.filters, action as any),
    myFilters: fromMFReducer.ecmMyFiltersReducer(state.myFilters, action as any),
    category: fromCategoryReducer.ecmDashboardCategoryReducer(state.category, action as any),
    dialog: fromDialogReducer.dialogReducer(state.dialog, action as any),
    tab: fromTabReducer.tabReducer(state.tab, action as any),
    deals: ecmDealsReducer(state.deals, action as any),
  };
}

export const getDashboard = (state: State): EcmDashboardState=> state.dashboard;
