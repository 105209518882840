<h1>External Master Data</h1>
  <cms-info ngPreserveWhitespaces>
    <u>__External eSM Master Data__</u>

    __Purpose of the External Master Data__

    Master operators of eSM organisations can upload eSM Master Data for their counterparties in order to enrich the invoices with the necessary data. Uploading external eSM Master data is only available for counterparties which did not upload eSM Master data themselves. That can either be:
     * __Counterparties which are not registered to the CMS:__ These counterparties are called off-site counterparties and after an upload of an invoice, these counterparties will instantly receive an email notification with the attached invoice PDF and XML. After a successful sending of the invoice, the invoices can be found in the invoice dashboard under the tab 'Complete' with the state 'PDF sent'. If its not possible to send them, those invoices can be found in the invoice dashboard on the tab 'Issue' with the state 'PDF not sent'.
     * __Counterparties which are registered to the CMS but do not participate in the regular eSM process:__ Those counterparties are called eSM lite users. They have the possibility to look at invoice via the eSM Invoice Dashboard or Finder which have been uploaded by a full eSM counterparty. After checking the invoices, the eSM lite user can use the click-and-agree functionality and agree or dispute invoices. After the agreement both counterparties receive an email notification with the attached invoice PDF and/or XML. After the agreement of invoices by an eSM lite user, the invoice can be found in the eSM Dashboard on the Tab 'Complete' and the state 'Matched (Web User)'. If an invoice gets disputed, the full eSM user receives an email with the notification of the change of state. The reason for dispute can be found in the notes in the GUI as disputed invoices have an obligatory note attached. If the eSM lite user has disputed an invoice, the invoice can be found on the tab 'Issue' with the state 'Mismatched'.

    __Information in the List__
     * __EIC Code__ The EIC code of the counterparty uploaded to the external eSM Master data by you
     * __VAT Code__ The vat code of the counterparty uploaded to the external eSM Master data by you
     * __Legal name__: The display name of the counterparty uploaded to the external eSM Master data by you
     * __Provided own Master data:__ Warning if the counterparty has uploaded eSM Master data for themselves. When the warning is shown the enrichment uses the eSM Master data the organisation uploaded themselves. The data uploaded by you will not be used
     * __Edit__: Adapt the external eSM Master data
     * __Delete:__ Delete the external eSM Master data

    __Searching the List__
     * Use the "Filter" field below to check for the existence of specific EIC codes, VAT Codes or Legal names
     * Filtering is case-insensitive and also finds parts of codes

    __Maintaining the List__

    The external eSM Master data can either be manually added by clicking on the new button or uploaded via a CSV file which contains all necessary information for the enrichment of an eSM invoice. To adapt the existing data either use the edit button or download the existing data and adapt the CSV file before uploading.
     * Only eSM users with Master Operator (MO) privileges can modify the external eSM Master Data
     * __Upload__ - if successful - replaces the existing external eSM Master Data
     * __New__ can manually add new external eSM Master data entries
     * __Download__ - will download the current list as CSV file

    __Upload Format__

    The upload format of the external eSM Master data is a CSV file which contains the necessary eSM Master data. The organisation is identified by the EIC code and VAT number.
     * **EIC_CODE** - The EIC code (16 characters long) of your external eSM counterparty which is mandatory
     * **VAT_ID** - The VAT ID of your external eSM counterparty which is mandatory
     * **VAT_REPRESENTATIVE** - The VAT representative (255 characters long) of your external eSM counterparty
     * **VAT_CURRENCY** - The VAT currency (currency code) of your external eSM counterparty which is mandatory
     * **LEGAL_NAME** - The legal name (255 characters long) of your external eSM counterparty which is mandatory
     * **COMPANY_REG_NUM** - The company's registration number (255 characters long) of your external eSM counterparty which is mandatory
     * **COMPANY_REG_NAME** - The company registration name (255 characters long) of your external eSM counterparty which is mandatory
     * **COMPANY_REG_CITY** - The company registration city (255 characters long) of your external eSM counterparty which is mandatory
     * **COMPANY_REG_COUNTRY** - The company registration country (country code) of your external eSM counterparty which is mandatory
     * **BRANCH_INFORMATION** - The company branch information (255 characters long) of your external eSM counterparty
     * **COMPANY_STREET** - The company street information (255 characters long) of your external eSM counterparty which is mandatory
     * **COMPANY_STREET_NUMBER** - The company street number (20 characters long) of your external eSM counterparty which is mandatory
     * **COMPANY_CITY** - The company city (255 characters long) of your external eSM counterparty which is mandatory
     * **COMPANY_POSTALCODE** - The company postal code (10 characters long) of your external eSM counterparty which is mandatory
     * **COMPANY_COUNTRY** - The company city (255 characters long) of your external eSM counterparty which is mandatory
     * **CONTACT_FIRST_NAME** - The company contact information first name (255 characters long) of your external eSM counterparty
     * **CONTACT_LAST_NAME** - The company contact information last name (255 characters long) of your external eSM counterparty
     * **CONTACT_PHONE_NUMBER** - The phone number of the companies contact (length 6-30, start with a '+' following by at least 6 digits) of your external eSM counterparty
     * **CONTACT_EMAIL** - The email adress of the companies contact (255 characters long) of your external eSM counterparty  which is mandatory
     * **OTHER_ADDRESS_STREET** - The additional street (255 characters long) of your external eSM counterparty
     * **OTHER_ADDRESS_STREET_NUMBER** - The additional street number (20 characters long) of your external eSM counterparty
     * **OTHER_ADDRESS_CITY** - The additional city (255 characters long) of your external eSM counterparty
     * **OTHER_ADDRESS_POSTALCODE** - The additional postal code (10 characters long) of your external eSM counterparty
     * **OTHER_ADDRESS_COUNTRY** - The additional country (255 characters long) of your external eSM counterparty
     * **CURRENCY1** - The currency of the banking information (currency code) of your external eSM counterparty
     * **IBAN1** - The IBAN of the banking information (validate against BICType) of your external eSM counterparty
     * **BIC1** - The BIC of the banking information (validate against IBANType) of your external eSM counterparty
     * **BANK_ACCT_HOLDER1** - The bank holder of the banking information (255 characters long) of your external eSM counterparty
     * File size limit is 5 MB
     * Required header line is: EIC_CODE,VAT_ID,VAT_REPRESENTATIVE,VAT_CURRENCY,LEGAL_NAME,COMPANY_REG_NUM,COMPANY_REG_NAME,
     COMPANY_REG_CITY,COMPANY_REG_COUNTRY,BRANCH_INFORMATION,COMPANY_STREET,COMPANY_STREET_NUMBER,
     COMPANY_CITY,COMPANY_POSTALCODE,COMPANY_COUNTRY,CONTACT_FIRST_NAME,CONTACT_LAST_NAME,
     CONTACT_PHONE_NUMBER,CONTACT_EMAIL,OTHER_ADDRESS_STREET,OTHER_ADDRESS_STREET_NUMBER,
     OTHER_ADDRESS_CITY,OTHER_ADDRESS_POSTALCODE,OTHER_ADDRESS_COUNTRY,CURRENCY1,IBAN1,BIC1,
     BANK_ACCT_HOLDER1,CURRENCY2,IBAN2,BIC2,BANK_ACCT_HOLDER2,CURRENCY3,IBAN3,BIC3,BANK_ACCT_HOLDER3
     * The combination of EIC_CODE, VAT_ID, COMMODITY must be unique
  </cms-info>
  <p-messages [closable]="true" [value]="(messages$ | async)"></p-messages>
  <ng-container id="detail" *ngIf="(detailsVisible$ | async); then details; else datatable"></ng-container>
  <ng-template #details>
    <cms-external-master-data-details></cms-external-master-data-details>
  </ng-template>
  <ng-template #datatable>
    <cms-external-master-data-datatable></cms-external-master-data-datatable>
  </ng-template>
