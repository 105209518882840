import * as moment from 'moment';
import * as FileSaver from 'file-saver';
import { Message } from 'primeng/api';
import { Injectable } from '@angular/core';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { getFilenameFromHeader } from '@common/deals/deals.model';
import { toPayload } from '@common/cms-common.model';
import { ErrorResponse } from '@common/error-response.model';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { ApiRequestService } from '@common/api-request.service';
import { DatatableConfig } from '@common/shared/results.model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  ExternalRrmFilter,
  ExternalRrmRequest,
  ExternalRrmResponse,
  ExternalRrmFormResponse,
  ResendRequest,
  RRMPresetValuesResponse, RRMPresetValuesResponseModel, ExternalRrmStatusResponse
} from '../../external-rrm/external-rrm.model';
import { ExternalRrmState } from './external-rrm.reducers';
import * as fromActions from './external-rrm.actions';
import * as fromSelectors from './external-rrm.selectors';

@Injectable()
export class ExternalErrEffects {
  private baseUrl = 'api/err/rrm/external';

  constructor(private actions$: Actions,
              private apiGateway: ApiRequestService,
              private mapper: MessagesMapperService,
              private store$: Store<ExternalRrmState>) { }

   getStatusAction$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.getStatusAction.type),
      switchMap(() =>
        this.apiGateway.get(`${this.baseUrl}/statusInfo`)
          .pipe(
            switchMap((response: ExternalRrmStatusResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [fromActions.setMessagesAction(messages)];
              }
              return [
                fromActions.setMessagesAction([]),
                fromActions.setStatusAction(response.value),
              ];
            }),
            catchError(error => of(fromActions.setMessagesAction([
              this.mapper.createErrorMessage(error.errorMessage)
            ])))
          ))));


   getPresetValuesAction$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.getPresetValuesAction.type),
      switchMap(() =>
        this.apiGateway.get(`${this.baseUrl}/loadPresetValues`)
          .pipe(
            switchMap((response: RRMPresetValuesResponseModel) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [fromActions.setMessagesAction(messages)];
              }
              return [
                fromActions.setMessagesAction([]),
                fromActions.onPresetValuesAction(response.value),
              ];
            }),
            catchError(error => of(fromActions.setMessagesAction([
              this.mapper.createErrorMessage(error.errorMessage)
            ])))
          ))));

   filter$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.filterAction.type),
      map(toPayload),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getExternalRrmState))),
      switchMap(([filter, { sorting, paging }]: [ExternalRrmFilter | any, ExternalRrmState]) => this.loadData({ paging, sorting, ...filter }))));

   sortAndPage$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.sortAndPageDataAction.type),
      map(toPayload),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getFilter))),
      switchMap(([{ sorting, paging }, filter]: [DatatableConfig, ExternalRrmFilter | any]) => this.loadData({ sorting, paging, ...filter }))));

   loadDefaultDataAction$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.loadDefaultDataAction.type),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getFilter))),
      switchMap(([, filter]: [any, ExternalRrmFilter]) => {
        const filterData = {
          ...filter,
          start: moment().subtract(1, 'days').startOf('day').toDate(),
          end: moment().add(1, 'days').startOf('day').toDate()
        };
        return [
          fromActions.setFiltersValueAction(filterData),
          fromActions.filterAction(filterData)
        ];
      })
    ));

   resendAction$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.resendAction.type),
      map(toPayload),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getFilter))),
      switchMap(([request, filter]: [ResendRequest, ExternalRrmFilter]) =>
        this.apiGateway.post(`${this.baseUrl}/resend`, request)
          .pipe(
            switchMap((response: ErrorResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [fromActions.setMessagesAction(messages)];
              }
              return [
                fromActions.filterAction(filter),
                fromActions.setMessagesAction([]),
              ];
            }),
            catchError(error => of(fromActions.setMessagesAction([
              this.mapper.createErrorMessage(error.errorMessage)
            ])))
          ))));

   downloadDocumentAction = createEffect(() => this.actions$
    .pipe(ofType(fromActions.downloadDocumentAction.type),
      map(toPayload),
      switchMap((value: number) => {
        let params = new HttpParams();
        params = params.set('id', value.toString());
        return this.apiGateway.getBlob(`${this.baseUrl}/document`, null, params)
          .pipe(
            switchMap((response: HttpResponse<Blob>) => {
              const filename = getFilenameFromHeader(response.headers);
              const blob: Blob = response.body;
              FileSaver.saveAs(blob, filename);
              return [];
            }),
            catchError(error => of(fromActions.setMessagesAction([
              this.mapper.createErrorMessage(error.errorMessage)
            ])))
          );
      })));

  private loadData(request: ExternalRrmRequest) {
    const format = 'YYYY-MM-DDTHH:mm:ss';
    const { start, end, messageStatus, messageType, receiver, ...obj } = request;
    const optionsObj = {
      ...(messageStatus && { messageStatus }),
      ...(messageType && { messageType }),
      ...(receiver && { receiver }),
      start: start && moment(request.start).format(format) || null,
      end: end && moment(request.end).format(format) || null
    };
    return this.apiGateway
      .post(`${this.baseUrl}/data`, { ...obj, ...optionsObj })
      .pipe(
        switchMap((response: ExternalRrmResponse) => {
          const messages: Message[] = this.mapper.toErrorMessages(response);
          if (messages && messages.length) {
            return [fromActions.setMessagesAction(messages)];
          }
          return [
            fromActions.filterSuccessAction(response),
            fromActions.setMessagesAction([])
          ];
        }),
        catchError(error => of(fromActions.setMessagesAction([this.mapper.createErrorMessage(error.errorMessage)]))));
  }
}
