import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cms-filter-multiselect',
  templateUrl: './filter-multiselect.component.html',
  styleUrls: ['./filter-multiselect.component.scss']
})
export class FilterMultiselectComponent<T> implements OnInit, OnDestroy {
  @Input() label: string;
  @Input() formControlName: string;
  @Input() options: T[];
  @Input() parentFormGroup: UntypedFormGroup;
  @Input() resetEventEmitter: EventEmitter<void>;
  @Output() onChangeOptions = new EventEmitter<T[]>();
  _selectItems: SelectItem[];
  private onDestroy$ = new Subject();

  ngOnInit(): void {
    this.resetEventEmitter
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(_ => this.onResetSelect());

    this._selectItems = this.options.map(option => {
      return { label: option.toString(), value: option.toString() };
    });
  }

  ngOnDestroy(): void {
    // Clean up all subscriptions at once:
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  onChanged(): void {
    this.onChangeOptions.emit(this.getFormControl().value);
  }

  private onResetSelect(): void {
    this.getFormControl().setValue(this.options);
  }

  private getFormControl(): UntypedFormControl {
    return this.parentFormGroup.get(this.formControlName) as UntypedFormControl;
  }
}
