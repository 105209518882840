import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';
import { RemitTradeEntryTable2Service } from '../remit-trade-entry-table2.service';
import { RemitTradeEntryTable2ReportingCounterpartyDataModel } from '../remit-trade-entry-table2-data.model';
import { RemitTable2TradeReportingCounterpartyModel } from '../../../trade/table2/modify/remit-table2-trade-entry.model';

@Component({
  selector: 'cms-remit-trade-entry-table2-reporting-counterparty',
  templateUrl: './remit-trade-entry-table2-reporting-counterparty.component.html',
  styleUrls: ['./remit-trade-entry-table2-reporting-counterparty.component.scss']
})
export class RemitTradeEntryTable2ReportingCounterpartyComponent implements OnInit, OnChanges, OnDestroy {
  @Input() formGroup: UntypedFormGroup;
  @Input() onReset: Subject<boolean>;
  @Input() readonly: boolean = false;
  @Input() modifyTrade: boolean = false;
  @Input() data: RemitTable2TradeReportingCounterpartyModel;
  marketParticipants: SelectItem[];
  buySell: SelectItem[];
  beneficiaryType: SelectItem[];
  beneficiaryCount: number = 0;
  reportingCounterpartyData: RemitTradeEntryTable2ReportingCounterpartyDataModel;
  marketParticipant: string;
  private onDestroy$ = new Subject();

  get beneficiaries(): AbstractControl[] {
    return (this.formGroup.controls['beneficiaries'] as UntypedFormArray).controls;
  }

  constructor(private formBuilder: UntypedFormBuilder,
              private tradeEntryService: RemitTradeEntryTable2Service) {
    this.onReset = new Subject<boolean>();
  }

  ngOnInit(): void {
    this.initForm();

    this.tradeEntryService.onTradeEntryDataChange().pipe(takeUntil(this.onDestroy$)).subscribe(formData => {
      if (formData && formData.reportingCounterparty) {
        this.reportingCounterpartyData = formData.reportingCounterparty;
        this.marketParticipant = formData.marketParticipant;
      }

      this.initFormValues();
      this.fillFormWithData();

    });

    this.tradeEntryService.onValidationFieldErrors().pipe(takeUntil(this.onDestroy$))
      .subscribe(
        fieldName => {
          if (fieldName && fieldName.indexOf('beneficiaries') >= 0) {
            const items = this.formGroup.controls['beneficiaries'] as UntypedFormArray;

            const parse = fieldName.split('.');
            const index = parse[0].charAt(parse[0].length - 1);
            const formGroup = items.controls[parseInt(index, 10)] as UntypedFormGroup;

            if (parse[1]) {
              switch (parse[1]) {
                case 'code':
                  formGroup.controls['code'].setErrors({ error: true });
                  break;
                case 'type':
                  formGroup.controls['codeType'].setErrors({ error: true });
                  break;
              }
            }
          }
        }
      );

    this.onReset.subscribe(reset => {
      if (reset) {
        this.resetForm();
      }
    });
  }

  ngOnChanges(_changes: SimpleChanges): void {
      this.fillFormWithData();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
    this.onReset.unsubscribe();
  }

  getValidationErrorStyleClass(fieldName: string): string {
    if (this.formGroup.controls[fieldName].hasError('error')) {
      return 'error';
    }
  }

  isAddDisabled(): boolean {
    return this.beneficiaryCount === 4 || this.formGroup.disabled;
  }

  isRemoveDisabled(): boolean {
    return this.beneficiaryCount === 0 || this.formGroup.disabled;
  }

  add(): void {
    this.beneficiaryCount++;
    const beneficiaries = this.formGroup.controls['beneficiaries'] as UntypedFormArray;

    const formControl = new UntypedFormControl();
    formControl.setValue(null);

    beneficiaries.push(
      this.formBuilder.group({codeType: formControl, code: [''] })
    );

  }

  remove(): void {
    const beneficiaries = this.formGroup.controls['beneficiaries'] as UntypedFormArray;

    if (beneficiaries.length >= 1) {
      beneficiaries.removeAt(beneficiaries.length - 1);
    }

    this.beneficiaryCount--;
  }

  convertKeyValueToSelectItem(keyValueData: any): SelectItem[] {
    if (keyValueData) {
      return keyValueData.map(method => ({
          label: method.value,
          value: method.key
        }));
    }
    return [];
  }

  // otherMarketParticipants0.code
  getBeneficiaryErrorStyleClass(index: number, fieldName: string): string {

    const items = this.formGroup.get('beneficiaries') as UntypedFormArray;
    if (items && items.controls[index]) {

      const formGroup = items.controls[index] as UntypedFormGroup;

      if (formGroup.controls[fieldName].hasError('error')) {
        return ' error';
      }
    }

    return '';
  }

  private initForm(): void {
    this.formGroup.addControl('marketParticipantCode', new UntypedFormControl('', Validators.required));
    this.formGroup.addControl('buyOrSell', new UntypedFormControl('', Validators.required));
    this.formGroup.addControl('beneficiaries', this.formBuilder.array(
      []
    ));

    this.add();
  }

  private resetForm(): void {

    this.formGroup.controls['marketParticipantCode'].reset();
    this.formGroup.controls['buyOrSell'].reset();

    this.formGroup.removeControl('beneficiaries');
    this.formGroup.addControl('beneficiaries', this.formBuilder.array(
      []
    ));

    this.add();

    this. initFormValues();
  }

  private initFormValues(): void {
    if (this.reportingCounterpartyData) {
      this.marketParticipants = this.convertKeyValueToSelectItem(this.reportingCounterpartyData.marketParticipants);
      this.buySell = this.convertKeyValueToSelectItem(this.reportingCounterpartyData.buySell);
      this.beneficiaryType = [{ label: ' ', value: null }];
      this.beneficiaryType = this.beneficiaryType.concat( this.convertKeyValueToSelectItem(this.reportingCounterpartyData.beneficiaryType));
    }

    this.formGroup.patchValue({
      marketParticipantCode: this.marketParticipant
    });
  }

  private fillFormWithData(){
    if (this.formGroup && this.data) {

      if(this.modifyTrade){
        this.marketParticipants = [
          {
            label: this.data.marketParticipantCode,
            value: this.data.marketParticipantCode
          }
        ];
      }

      this.formGroup.patchValue({marketParticipantCode: this.data.marketParticipantCode, buyOrSell: this.data.buyOrSell});

      if(this.data.beneficiaries){
        const beneficiaries = this.formGroup.controls['beneficiaries'] as UntypedFormArray;

        if(beneficiaries){
          for( let i = 0; i <  this.data.beneficiaries.length; i++){
            beneficiaries.at(i).setValue(this.data.beneficiaries[i]);
          }
        }
      }
    }
  }
}
