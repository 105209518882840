import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { EmailWhitelistModel } from '../../../settings/whitelist/whitelist.model';
import { Message } from 'primeng/api';
import { createReducer, on } from '@ngrx/store';
import {
  cleanState,
  EsmEmailWhitelistAction,
  loadPresets,
  loadPresetsSuccess,
  loadTableData, loadTableDataSuccess, pagingAndSorting,
  setMessages, setSearchToken,
  triggerCheckboxSuccess,
  uploadCsv
} from './whitelist.actions';

export interface EsmEmailWhitelistState {
  data: DatatableData<EmailWhitelistModel[]>;
  pagingAndSorting: {
    paging: PagingFilter,
    sorting: SortingFilter
  };
  searchToken: string;

  messages: Message[];
  loading: { table: boolean, upload: boolean };

  isUploadAllowed: boolean;
  maxUploadSize: number;
  pdfOffsiteEnabled:boolean;
}

export const initialState: EsmEmailWhitelistState = {
  messages: [],
  loading: { table: false, upload: false },
  pagingAndSorting: {
    paging: {
      entriesPerPage: 25, page: 0
    },
    sorting: { columnName: 'eic', sortOrder: 'ASCENDING' }
  },
  searchToken: '',
  data: null,

  isUploadAllowed: true,
  maxUploadSize: 1,
  pdfOffsiteEnabled:false
};

export const esmEmailWhitelistReducer = createReducer<EsmEmailWhitelistState, EsmEmailWhitelistAction>(
  initialState,

  // TODO {CMSDEV-1639} on(something, return state)
  on(setMessages, (state, { payload }) => ({
      ...state,
      messages: payload
    })
  ),
  on(cleanState, () => ({
      ...initialState
    })
  ),
  on(loadPresets, (state) => ({
      ...state,
      loading: { table: true, upload: false }
    })
  ),
  on(loadPresetsSuccess, (state, { payload }) => ({
      ...state,
      loading: { table: false, upload: false },
      maxUploadSize: payload.maxUploadSizeInBytes,
      isUploadAllowed: payload.allowedToUpload,
      pdfOffsiteEnabled:payload.pdfOffsiteEnabled
    })
  ),
  on(loadTableDataSuccess, (state, { payload }) => ({
      ...state,
      loading: { table: false, upload: false },
      paging: payload.paging,
      sorting: payload.sorting,
      data: payload.data
    })
  ),
  on(loadTableData, (state) => ({
    ...state,
    loading: { table: true, upload: false }
  })),
  on(uploadCsv, (state) => ({
    ...state,
    loading: { table: false, upload: true }
  })),
  on(uploadCsv, (state) => ({
    ...state,
    loading: { table: false, upload: false }
  })),
  on(setSearchToken, (state, { payload }) => ({
    ...state,
    searchToken: payload
  })),
  on(pagingAndSorting, (state, { payload }) => ({
    ...state,
    pagingAndSorting: payload
  })),
  on(triggerCheckboxSuccess, (state, { checked }) => ({ ...state, pdfOffsiteEnabled: checked })),

);
