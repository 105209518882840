import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Message } from 'primeng/api';
import { OrganisationModel } from '@common/organisation.model';
import { ApiRequestService } from '@common/api-request.service';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { Column } from '@common/shared/datatable.model';
import { OrganisationService } from '@common/organisation.service';
import { saveBlob } from '@common/cms-common.model';
import { UserAccountsState } from '../user-account/user-account.reducer';
import { AuthenticationService } from '../../../auth/authentication.service';
import * as fromSelectors from './user-roles.selector';
import * as fromActions from './user-role.actions';
import {
  MatrixResponse, OrganisationLabel,
  ProcessRoles, UpdateMatrixRequest,
  UserRolesFilterResponse,
  UserRolesFilters, UserRolesMatrixRow, UserRolesMatrixRowItem
} from '../../administration-tab-panel/user-roles/user-roles.model';
import { UserRolesState } from './user-role.reducer';

const defaultStyles = {
  'min-width': '185px',
  'text-align': 'center',
  'text-overflow': 'ellipsis',
  'height': '30px'
};

@Injectable()
export class UserRoleEffects {
  private baseUrl = 'api/administration/userRoles';

  constructor(private apiGateway: ApiRequestService,
              private activatedRoute: ActivatedRoute,
              private actions$: Actions,
              private store$: Store<UserAccountsState>,
              private mapper: MessagesMapperService,
              private authService: AuthenticationService,
              private router: Router,
              public organisationService: OrganisationService) {
  }

   updateMatrix = createEffect(() => this.actions$.pipe(
    ofType(fromActions.UPDATE_MATRIX),
    withLatestFrom(this.store$.pipe(select(fromSelectors.getUserRolesState))),
    switchMap(([, state]: [fromActions.UpdateMatrixAction, UserRolesState]) =>
      this.apiGateway
        .post(`${this.baseUrl}/updateRolesMatrix`, this.getUpdateMatrixRequest(state), null)
        .pipe(
          switchMap((response: any) => {
            const messages: Message[] = this.mapper.toErrorMessages(response);
            if (messages && messages.length) {
              return of(new fromActions.SetMessagesAction(messages));
            }
            return [
              new fromActions.UpdateMatrixSuccess(),
              new fromActions.SetMessagesAction(this.mapper.toSuccessMessages('User roles updated.')),
              new fromActions.FilterDataAction(state.filters),
            ];
          }),
          catchError(() => of(new fromActions.SetMessagesAction([this.mapper.createErrorMessage('Server error')])))
        )
    )));

   export = createEffect(() => this.actions$.pipe(
    ofType(fromActions.EXPORT),
    switchMap((action: fromActions.ExportAction) =>
      this.apiGateway
        .getBlob(`${this.baseUrl}/loadRolesMatrix/report`, action.payload, null)
        .pipe(
          switchMap((response: any) => {
            saveBlob(response);
            return of(new fromActions.SetMessagesAction([]));
          }),
          catchError(() => of(new fromActions.SetMessagesAction([this.mapper.createErrorMessage('Server error')])))
        )
    )));

   filterData = createEffect(() => this.actions$.pipe(
    ofType(fromActions.FILTER_DATA),
    withLatestFrom(this.store$.pipe(select(fromSelectors.getFilters))),
    switchMap(([, filters]: [fromActions.LoadFiltersDataAction, UserRolesFilters]) =>
      this.apiGateway.post(`${this.baseUrl}/loadRolesMatrix`, filters)
        .pipe(
          map((response: MatrixResponse) => {
            const messages: Message[] = this.mapper.toErrorMessages(response);
            if (messages && messages.length) {
              return new fromActions.SetMessagesAction(messages);
            }

            const columns: Column[] = [];
            const defaultColumn = { type: 'text', style: { ...defaultStyles }, sortable: false, visible: true };
            if (response.userRolesMatrix && response.userRolesMatrix.organisationLabelsRow) {
              const { organisationGroupName, organisationLabelsRow } = response.userRolesMatrix;
              columns.push({
                ...defaultColumn,
                name: 'userName',
                title: 'User Name',
                style: { ...defaultStyles, 'min-width': '85px' } });
              columns.push({
                ...defaultColumn,
                name: 'userAdmin',
                type: 'adminStatus',
                title: organisationGroupName,
                style: { ...defaultStyles, 'min-width': '67px' }
              });
              columns.push(...organisationLabelsRow.map((label: OrganisationLabel) =>
                ({
                  ...defaultColumn,
                  name: label.organisationId.toString(),
                  title: label.displayName,
                  type: 'mo/operatorStatus',
                  style: { ...defaultStyles, 'min-width': '127px', 'word-break': 'break-word' }
                })));
            }

            const data = [];
            if (response.userRolesMatrix && response.userRolesMatrix.userRolesMatrixRows) {
              const rows: UserRolesMatrixRow[] = response.userRolesMatrix.userRolesMatrixRows;
              data.push(...rows.map((row: UserRolesMatrixRow) => {
                const { userName, userAdmin } = row;
                const item: UserRolesMatrixRowItem = {
                  userName,
                  userAdmin,
                  ...row.userOrganisationRoles
                };
                return item;
              }));
            }

            return new fromActions.FilterDataSuccessAction({
              columns,
              data: {
                values: data,
                count: data.length
              }
            });
          }),
        )
    )));

   loadFiltersData = createEffect(() => this.actions$.pipe(
    ofType(fromActions.LOAD_FILTERS_DATA),
    withLatestFrom(this.store$.pipe(select(fromSelectors.getFilters))),
    switchMap(([, filters]: [fromActions.LoadFiltersDataAction, UserRolesFilters]) =>
      this.apiGateway.post(`${this.baseUrl}/loadFilters`, filters)
        .pipe(
          switchMap((response: UserRolesFilterResponse) => {
            const messages: Message[] = this.mapper.toErrorMessages(response);
            if (messages && messages.length) {
              return of(new fromActions.SetMessagesAction(messages));
            }
            const orgGroups = response.organisationGroupFilter.filterEntries;
            const userRolesFilters: UserRolesFilters = {
              selectedUsername: null,
              selectedOrganisationGroupId: orgGroups && orgGroups.length ?
                orgGroups[0].organisationGroupId : null,
              selectedProcessRole: ProcessRoles.ECM
            };
            return [
              new fromActions.LoadFiltersDataSuccessAction(response),
              new fromActions.SetFiltersAction(userRolesFilters),
              new fromActions.FilterDataAction(userRolesFilters),
              new fromActions.SetMessagesAction([])
            ];
          }),
          catchError(error => of(new fromActions.SetMessagesAction([this.mapper.createErrorMessage(error.errorMessage)])))
        )
    )));

   updateFiltersData = createEffect(() => this.actions$.pipe(
    ofType(fromActions.UPDATE_FILTERS_DATA),
    withLatestFrom(this.store$.pipe(select(fromSelectors.getFilters))),
    switchMap(([, filters]: [fromActions.LoadFiltersDataAction, UserRolesFilters]) =>
      this.apiGateway.post(`${this.baseUrl}/loadFilters`, filters)
        .pipe(
          switchMap((response: UserRolesFilterResponse) => {
            const messages: Message[] = this.mapper.toErrorMessages(response);
            if (messages && messages.length) {
              return of(new fromActions.SetMessagesAction(messages));
            }

            const actions = [];

            const orgGroups = response.organisationGroupFilter.filterEntries;
            let userRolesFilters: UserRolesFilters = {
              ...filters,
              selectedOrganisationGroupId: filters.selectedOrganisationGroupId ? filters.selectedOrganisationGroupId : orgGroups && orgGroups.length ?
                orgGroups[0].organisationGroupId : null
            };


            // when all users is not included, or there is no selected userName in user filters request
            const { userFilter: { filterEntries, visible } } = response;
            if (visible && filterEntries && !filterEntries.some(item => item.userName === filters.selectedUsername) ) {
              userRolesFilters = {...userRolesFilters, selectedUsername: filterEntries[0] ? filterEntries[0].userName : null };
             // actions.push(new fromActions.SetFiltersAction(userRolesFilters));
            }

            actions.push(
              new fromActions.LoadFiltersDataSuccessAction(response),
              new fromActions.SetMessagesAction([]),
              new fromActions.FilterDataAction(userRolesFilters),
              new fromActions.SetFiltersAction(userRolesFilters),
            );
            return actions;
          }),
          catchError(error => of(new fromActions.SetMessagesAction([this.mapper.createErrorMessage(error.errorMessage)])))
        )
    )));

   organisationChange$ = createEffect(() => this.organisationService
    .getChangeOrganisationObservable()
    .pipe(
      filter(() => this.router.routerState.snapshot.url.includes('admin/userRoles')),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getOrganisationId))),
      switchMap(([organisation, organisationId]: [OrganisationModel, number]) => {
        if ((this.authService.hasAccess) &&
          ((organisation && organisation.organisationId !== organisationId) || (!organisation && organisationId))) {
          const selectedOrganisationGroupId = this.activatedRoute.snapshot.queryParams.organisationGroupId || null;
          return [
            new fromActions.SetToDefaultAction(),
            new fromActions.SetFiltersAction({ selectedOrganisationGroupId }),
            new fromActions.SetOrganisationIdAction(organisation ? organisation.organisationId : null),
            selectedOrganisationGroupId ? new fromActions.UpdateFilterDataAction({ selectedOrganisationGroupId }) :
            new fromActions.LoadFiltersDataAction()
          ];
        }
        return of(new fromActions.SetOrganisationIdAction(organisation ? organisation.organisationId : null));
      })));

  private getUpdateMatrixRequest({ filters: { selectedOrganisationGroupId, selectedProcessRole }, editableData, data }: UserRolesState): UpdateMatrixRequest {
    return {
      selectedOrganisationGroupId,
      selectedProcessRole,
      userRolesMatrixRows: data.values.map((item, index) => {
        const editableDataItem = editableData[index] || {};
        const row = {
          userAdmin: !(editableDataItem && editableDataItem.hasOwnProperty('userAdmin')) ?
            item.userAdmin : editableDataItem.userAdmin.newValue,
          userName: item.userName,
          userOrganisationRoles: { ...item }
        };
        Object.entries(editableDataItem).forEach(([key, value]: [string, any]) => {
          row.userOrganisationRoles[key] = value.newValue;
        });
        delete row.userOrganisationRoles['userAdmin'];
        delete row.userOrganisationRoles['userName'];
        return row;
      })
    };
  }
}
