import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DashboardDealsNavModel } from '@common/dashboard-deals-nav/dashboard-deals-nav.model';

@Component({
  selector: 'cms-dashboard-deal-nav',
  templateUrl: './dashboard-deals-nav.component.html',
  styleUrls: ['./dashboard-deals-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardDealsNavComponent {
  @Input() data: DashboardDealsNavModel[];
  @Input() state: any;
  @Output() back = new EventEmitter();
  @Output() changeState = new EventEmitter();

  onBack(): void {
    this.back.emit();
  }

  onChangeState(item: DashboardDealsNavModel): void {
    this.changeState.emit(item);
  }
}
