import { createReducer, on } from '@ngrx/store';
import {
  FilterBy,
  InvoiceFinderFiltersIdInputs,
  InvoiceFinderPropertiesFilters,
  KeyValueProp,
  generateInitialDate
} from '../../../invoice/finder/invoice-finder.model';
import { EsmInvoiceFinderState } from '../invoice-finder.reducers';
import * as fromFiltersActions from './filters.actions';
import * as fromIFActions from '../invoice-finder.actions';

export interface EsmInvoiceFinderFiltersState {
  propFilters: InvoiceFinderPropertiesFilters;
  commodities: string[];
  counterParties: KeyValueProp[];
  invoiceTypes: string[];
  deliveryPoints: { keyObject: string, valueObject: string }[];
  loaded: boolean;
  filterBy: FilterBy;
  inputIds: InvoiceFinderFiltersIdInputs;
  notFoundIds: string[];
}

export const filtersInitialState: EsmInvoiceFinderFiltersState = {
  propFilters: {
    commodities: [],
    counterPartyOrgIds: [],
    deliveryPoints: [],
    invoiceTypes: [],
    invoiceStartDate: null,
    invoiceEndDate: null,
    paymentDateRange: {
      start: null,
      end: null
    },
    submissionDateRange: {
      start: generateInitialDate(19),
      end: null
    },
    senderUs: true,
    webUser: false,
    multiAgreeButtonVisible: false
  },
  commodities: [],
  counterParties: null,
  invoiceTypes: [],
  deliveryPoints: [],
  filterBy: null,
  loaded: false,
  inputIds: {
    ourInvoiceNumber: '',
    theirInvoiceNumber: '',
    documentId: '',
    purchaseOrderNumber: '',
    tradeId: '',
  },
  notFoundIds: []
};

export const esmInvoiceFinderFiltersReducer = createReducer<EsmInvoiceFinderFiltersState,
  fromFiltersActions.FiltersActions | fromIFActions.InvoiceFinderActions>(
  filtersInitialState,
  on(fromFiltersActions.filterAction, (state: EsmInvoiceFinderFiltersState, { payload }): EsmInvoiceFinderFiltersState => ({
    ...state,
    ...payload
  })),
  on(fromFiltersActions.updateFiltersAction, (state: EsmInvoiceFinderFiltersState, { payload }): EsmInvoiceFinderFiltersState => ({
    ...state,
    loaded: true,
    propFilters: {
      ...state.propFilters,
      ...payload
    }
  })),
  on(fromFiltersActions.getFiltersValuesSuccess, (state: EsmInvoiceFinderFiltersState, { payload }): EsmInvoiceFinderFiltersState => {
      const {
        commodities,
        counterParties,
        invoiceTypes,
        deliveryPoints
      } = payload;
      return {
        ...state,
        commodities,
        counterParties,
        invoiceTypes,
        deliveryPoints: deliveryPoints.map(({ key, value }) => ({ keyObject: key, valueObject: value })),
        loaded: true,
        propFilters: {
          ...state.propFilters,
          commodities,
          counterPartyOrgIds: counterParties.map(item => item.key),
          deliveryPoints:deliveryPoints.map(({ key, value }) => ({ keyObject: key, valueObject: value })),
          invoiceTypes,
          senderUs: true,
          webUser: payload.webUser,
          multiAgreeButtonVisible: payload.multiAgreeButtonVisible
        },
      };
    }),
  on(fromFiltersActions.filterSuccessAction, (state: EsmInvoiceFinderFiltersState, { payload: { notFoundIds } }): EsmInvoiceFinderFiltersState => ({
    ...state,
    notFoundIds
  })),
  on(fromFiltersActions.clearIdPropertiesFiltersAction, (state: EsmInvoiceFinderFiltersState): EsmInvoiceFinderFiltersState => ({
    ...state,
    inputIds: {
      ourInvoiceNumber: '',
      theirInvoiceNumber: '',
      documentId: '',
      purchaseOrderNumber: '',
      tradeId: '',
    },
    notFoundIds: [],
    filterBy: null
  })),
  on(fromIFActions.setToDefaultAction, (state: EsmInvoiceFinderFiltersState): EsmInvoiceFinderFiltersState => ({
    ...filtersInitialState
  })),
);

export const getFilters = (state: EsmInvoiceFinderState) => state.filters;
export const getFilterProperties = (state: EsmInvoiceFinderFiltersState) => state.propFilters;
export const getCommodities = (state: EsmInvoiceFinderFiltersState) => state.commodities;
export const getCounterParties = (state: EsmInvoiceFinderFiltersState) => state.counterParties;
export const getInvoiceTypes = (state: EsmInvoiceFinderFiltersState) => state.invoiceTypes;
export const getDeliveryPoints = (state: EsmInvoiceFinderFiltersState) => state.deliveryPoints;
export const getLoaded = (state: EsmInvoiceFinderFiltersState) => state.loaded;
export const getNoFoundIds = (state: EsmInvoiceFinderFiltersState) => state.notFoundIds;
export const getInputIds = (state: EsmInvoiceFinderFiltersState) => state.inputIds;
export const getIdsCount = (state: EsmInvoiceFinderFiltersState) => {
  const { filterBy, inputIds } = state;
  const { ourInvoiceNumber, theirInvoiceNumber, documentId, purchaseOrderNumber, tradeId } = inputIds;
  switch (filterBy) {
    case 'ourInvoiceNumber':
      return ourInvoiceNumber ? ourInvoiceNumber.replace('↵', ' ')
        .replace(/\s+/g, ' ').split(' ').length : 0;
    case 'theirInvoiceNumber':
      return theirInvoiceNumber ? theirInvoiceNumber.replace('↵', ' ')
        .replace(/\s+/g, ' ').split(' ').length : 0;
    case 'documentId':
      return documentId ? documentId.replace('↵', ' ')
        .replace(/\s+/g, ' ').split(' ').length : 0;
    case 'purchaseOrderNumber':
      return purchaseOrderNumber ? purchaseOrderNumber.replace('↵', ' ')
        .replace(/\s+/g, ' ').split(' ').length : 0;
    case 'tradeId':
      return tradeId ? tradeId.replace('↵', ' ')
        .replace(/\s+/g, ' ').split(' ').length : 0;
  }
};
