import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { from, Observable, Subscription } from 'rxjs';
import { filter, mergeMap, startWith } from 'rxjs/operators';
import { CpmlDashboardService } from './cpml-dashboard.service';
import { CpmlDashboardBucketCategoryType } from './filter/cpml-dashboard-filter.types';
import { ErrorMessageService } from '@common/errors/error-message.service';

@Component({
  selector: 'cms-cpml-dashboard',
  templateUrl: './cpml-dashboard.component.html',
  styleUrls: ['./cpml-dashboard.component.scss']
})
export class CpmlDashboardComponent implements OnInit, OnDestroy {
  tabCategories: CpmlDashboardBucketCategoryType[] = ['IN_PROGRESS', 'ISSUE', 'COMPLETE'];
  selectedCategory: CpmlDashboardBucketCategoryType;
  activeIndex$: Observable<number>;
  private activeIndexSubscription$: Subscription;

  constructor(private cpmlDashboardService: CpmlDashboardService,
              private router: Router,
              public errorMessageService:ErrorMessageService) { }

  ngOnInit(): void {
    this.activeIndex$ =
      this.router.events
        .pipe(filter(event => event instanceof NavigationEnd),
          mergeMap((event: NavigationEnd) => this.calcActiveTabIndexObservable(event.url)),
          startWith(this.calcActiveTabIndex(this.router.url))); // this is for F5 reload to add the init router url to the stream

    this.activeIndexSubscription$ = this.activeIndex$
      .subscribe(this.changeCategoryTab.bind(this));
  }

  ngOnDestroy(): void {
    if (this.activeIndexSubscription$) {
      this.activeIndexSubscription$.unsubscribe();
    }
  }

  setCategoryTab(event: any): void {
    this.changeCategoryTab(event.index);
  }

  private changeCategoryTab(index: number): void {
    this.selectedCategory = this.tabCategories[index];
    this.router.navigate(['/err/cpml_dashboard/' + this.selectedCategory.toLowerCase()]);
    this.cpmlDashboardService.emitChangeCategoryFilter(this.selectedCategory);
  }

  private calcActiveTabIndexObservable(url: string): Observable<number> {
    return from([this.calcActiveTabIndex(url)]);
  }

  private calcActiveTabIndex(url: string): number {
    return this.tabCategories.reduce((activeIndex, category, arrIndex) => {
      return new RegExp('\/' + category + '$', 'i').test(url) ? arrIndex : activeIndex;
    }, 0);
  }
}
