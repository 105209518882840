import { createSelector } from '@ngrx/store';
import { getErrState, State } from '../reducers';
import * as fromReducer from '../mifid/mifid.reducer';

export const getMifidFileInState = createSelector(getErrState, (state: State) => state.mifidFile);
export const getOrganisationId = createSelector(getMifidFileInState, (state: fromReducer.ErrMifidFileState) => state.organisationId);

export const getData = createSelector(getMifidFileInState, fromReducer.getData);
export const getSorting = createSelector(getMifidFileInState, fromReducer.getSorting);
export const getPaging = createSelector(getMifidFileInState, fromReducer.getPaging);
export const getLoading = createSelector(getMifidFileInState, fromReducer.getLoading);
export const getMessages = createSelector(getMifidFileInState, fromReducer.getMessages);

export const getFilters = createSelector(getMifidFileInState, fromReducer.getFilters);
export const uploadPaneVisible = createSelector(getMifidFileInState, fromReducer.uploadPaneVisible);
export const maxUploadFileSizeInBytes = createSelector(getMifidFileInState, fromReducer.maxUploadFileSizeInBytes);
export const getDocumentTypes = createSelector(getMifidFileInState, fromReducer.getDocumentTypes);
export const getColumns = createSelector(getMifidFileInState, fromReducer.getColumns);
export const viewBy = createSelector(getMifidFileInState, fromReducer.viewBy);
export const getAvailableDocumentTypes = createSelector(getMifidFileInState, fromReducer.getAvailableDocumentTypes);
