import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NettedInvoicesComponent } from './netted-invoices/netted-invoices.component';
import { CmsCommonModule } from '@common/cms-common.module';
import { RouterModule, Routes } from '@angular/router';
import { AuthorisationGuard } from '../../../../auth/authorisation.guard';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { EsmModule } from '../../../esm.module';
import { getReducers, reducerToken } from './state';
import { InvoicesTableEffects } from './state/netted-invoices/invoices-table.effects';
import { ButtonModule } from 'primeng/button';
import { MessagesModule } from 'primeng/messages';
import { TableModule } from 'primeng/table';

const routes: Routes = [{ path: 'netted_invoices', component: NettedInvoicesComponent, canActivate: [AuthorisationGuard] }];

@NgModule({
  declarations: [NettedInvoicesComponent],
  imports: [
    CommonModule,
    CmsCommonModule,
    MessagesModule,
    RouterModule,
    ButtonModule,
    RouterModule.forChild(routes),
    StoreModule.forFeature('ntt_invoice_table', reducerToken),
    EffectsModule.forFeature([
      InvoicesTableEffects
    ]),
    EsmModule,
    TableModule,
  ],
  providers: [
    {
      provide: reducerToken,
      useFactory: getReducers
    }
  ]
})
export class EsmNttPreviewTableModule { }
