import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemitTable1TradeComponent } from './remit-table1-trade.component';
import { RemitTable1TradeCancelComponent } from './cancel/remit-table1-trade-cancel.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RemitTable1TradeService } from './remit-table1-trade.service';
import { RouterModule } from '@angular/router';
import { RemitTable1TradeErrorComponent } from './error/remit-table1-trade-error.component';
import { CmsCommonModule } from '../../../../common/cms-common.module';
import { RemitTable1TradeModifyComponent } from './modify/remit-table1-trade-modify.component';
import { RemitTradeEntryTable1ModifyCommercialsComponent } from './modify/form/remit-trade-entry-table1-modify-commercials.component';
import { RemitTradeEntryTable1ModifyService } from './modify/remit-trade-entry-table1-modify.service';
import { RemitTradeEntryTable1ModifyReferencesComponent } from './modify/form/remit-trade-entry-table1-modify-references.component';
import { RemitTradeEntryTable1ModifySettlementComponent } from './modify/form/remit-trade-entry-table1-modify-settlement.component';
import { RemitTradeEntryTable1ModifyContractdeliveryComponent } from './modify/form/remit-trade-entry-table1-modify-contractdelivery.component';
import { RemitTradeEntryTable1ModifyContractdeliveryprofileComponent } from './modify/form/remit-trade-entry-table1-modify-contractdeliveryprofile.component';
import { RemitTradeEntryTable1ModifyFixingIndexComponent } from './modify/form/remit-trade-entry-table1-modify-fixing-index.component';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { CalendarModule } from 'primeng/calendar';
import { InputMaskModule } from 'primeng/inputmask';

@NgModule({
  imports: [
    CommonModule,
    TableModule,
    BrowserModule,
    CmsCommonModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarModule,
    InputMaskModule,
    DropdownModule,
    MessageModule,
    MessagesModule,
    RouterModule,
    ProgressSpinnerModule,
  ],
  providers: [RemitTable1TradeService, RemitTradeEntryTable1ModifyService],
  declarations: [
    RemitTable1TradeComponent,
    RemitTable1TradeCancelComponent,
    RemitTable1TradeErrorComponent,
    RemitTable1TradeModifyComponent,
    RemitTradeEntryTable1ModifyCommercialsComponent,
    RemitTradeEntryTable1ModifyReferencesComponent,
    RemitTradeEntryTable1ModifySettlementComponent,
    RemitTradeEntryTable1ModifyContractdeliveryComponent,
    RemitTradeEntryTable1ModifyContractdeliveryprofileComponent,
    RemitTradeEntryTable1ModifyFixingIndexComponent
  ]
})
export class RemitTable1TradeModule {
}
