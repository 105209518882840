<div class="row">
  <div class="col-8 col-xl-2 col-lg-8 col-md-8">
    <cms-ecm-view-by-filter [value]="viewBy$ | async"
                            (apply)="onApplyViewByFilter($event)">
    </cms-ecm-view-by-filter>
  </div>
  <div class="col-12 col-xl-8 col-lg-12 col-md-12">
    <cms-ecm-dashboard-properties-filters [filters]="filtersProps$ | async"
                                          [defaultFilter]="defaultFilter$ | async"
                                          [dialog]="dialog$ | async"
                                          [allDealsFilter]="allDealsFilter$ | async"
                                          [myFilters]="myFilters$ | async"
                                          [brokers]="broker$ | async"
                                          [counterParties]="counterParties$ | async"
                                          [commodities]="commodities$ | async"
                                          [transactionTypes]="transactionTypes$ | async"
                                          [markets]="markets$ | async"
                                          (manageFilters)="onManageMyFilters()"
                                          (openSaveCurrent)="onOpenSaveCurrent()"
                                          (selectMyFilter)="onSelectMyFilter($event)"
                                          (confirmSaveCurrent)="onConfirmSaveFilter($event)"
                                          (replaceFilter)="onConfirmReplaceFilter($event)"
                                          (reset)="onResetFilters()"
                                          (filter)="onFilter($event)"
                                          (closeDialog)="onCloseDialog()"
                                          (deleteFilter)="onDeleteFilter($event)"
                                          (setDefaultFilter)="onSetDefault($event)">
    </cms-ecm-dashboard-properties-filters>
  </div>
</div>
