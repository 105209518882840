import { Component, OnDestroy, OnInit } from '@angular/core';
import { RemitFilesInService } from './remit-files-in.service';
import { Message } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OrganisationService } from '../../../../common/organisation.service';
import { ErrorMessageService } from '@common/errors/error-message.service';

@Component({
  selector: 'cms-remit-files-in',
  templateUrl: './remit-files-in.component.html',
  styleUrls: ['./remit-files-in.component.scss']
})
export class RemitFilesInComponent implements OnInit, OnDestroy {
  acerUploadGrantText: string = '';
  messages: Message[] = [];
  private onDestroy$ = new Subject();

  constructor(private filesInService: RemitFilesInService,
              private orgService: OrganisationService,
              public readonly errorMessageService: ErrorMessageService) {

  }

  ngOnInit(): void {
    this.messages = [];
    this.filesInService.onAcerUploadGrant()
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(grant => {
      this.acerUploadGrantText = grant;
    });

    this.filesInService.refreshAcerUploadGrants();

    this.filesInService.onMessageChange().pipe(takeUntil(this.onDestroy$)).subscribe(
      message => {
        if (message != null) {
          this.messages = [...this.messages, message];
         // this.filesInService.resetInput();
        } else {
          this.messages = [];
        }
      });

    this.orgService.getOrganisationChangeIfAccessible().pipe(takeUntil(this.onDestroy$)).subscribe(org => {
      this.filesInService.reset();
      this.messages = [];
      this.filesInService.refreshAcerUploadGrants();
    });
  }

  ngOnDestroy(): void {
    this.filesInService.reset();
    this.messages = [];
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  public hasGrants(): boolean {
    return this.acerUploadGrantText && this.acerUploadGrantText.length > 0;
  }

}
