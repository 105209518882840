import { datatableReducer, DatatableState } from '../datatable/datatable.reducer';
import { organisationIdReducer } from '../organisation-id/organisation-id.reducer';
import { PartyData, PartyDataEditableModel } from '../../administration-tab-panel/party-data/party-data.model';
import { Message } from 'primeng/api';
import * as fromAction from './party-data.actions';

export interface PartyDataState {
  organisationId?: number;
  datatable: DatatableState<PartyData, string>;
  addPartyData: PartyDataEditableModel;
  messages: Message[];
}

const datatableInitialState: DatatableState<PartyData> = {
  selected: null,
  filters: null,
  data: {
    values: [],
    count: 0
  },
  sorting: {
    columnName: 'partyCode',
    sortOrder: 'ASCENDING'
  },
  paging: {
    entriesPerPage: 25,
    page: 0
  },
  messages: [],
  loading: false,
  editableData: null
};

export const initialPartyDataState: PartyDataState = {
  organisationId: null,
  datatable: datatableInitialState,
  addPartyData: { displayName: '', partyCode: '' },
  messages: []
};

export function partyDataReducer(
  state: PartyDataState = { ...initialPartyDataState },
  action: fromAction.PartyDataActions): PartyDataState {

  switch (action.type) {
    case fromAction.setMessagesAction.type:
      return {
        ...state,
        messages: action.payload
      };
    case fromAction.updateAddPartyDataAction.type:
      return {
        ...state,
        addPartyData: action.payload
      };
    default:
      return {
        ...state,
        datatable: datatableReducer('[PARTY DATA]', datatableInitialState)(state.datatable, action as any),
        organisationId: organisationIdReducer('[PARTY DATA]')(state.organisationId, action as any),
      };
  }
}
