import { createAction, union } from '@ngrx/store';
import { Message } from 'primeng/api';
import { DatatableConfig } from '@common/shared/results.model';
import { AddNote, Dispute } from '@common/deals/deals.model';
import { Column } from '@common/shared/datatable.model';
import { EsmInvoiceFinder } from '../../../invoice/finder/invoice-finder.model';

export const setMessagesAction = createAction('[INVOICE FINDER] Set Messages', (payload: Message[]) => ({ payload }));
export const sortAndPageDataAction = createAction('[INVOICE FINDER] Sort And Page Data', (payload: DatatableConfig) => ({ payload }));
export const exportAction = createAction('[INVOICE FINDER] Export');
export const downloadHtmlAction = createAction('[INVOICE FINDER] Download Html', (payload: number) => ({ payload }));
export const downloadXmlAction = createAction('[INVOICE FINDER] Download Xml', (payload: number) => ({ payload }));
export const downloadPdfAction = createAction('[INVOICE FINDER] Download Pdf', (payload: number) => ({ payload }));
export const resendAction = createAction('[INVOICE FINDER] Resend Pdf', (payload: { id: number, screen: string }) => ({ payload }));
export const addNoteAction = createAction('[INVOICE FINDER] Add Note', (payload: AddNote) => ({ payload }));
export const addNoteSuccessAction = createAction('[INVOICE FINDER] Add Note Success');
export const setHtmlAction = createAction('[INVOICE FINDER] Set Html', (payload: Blob) => ({ payload }));
export const setSelectedIdsAction = createAction('[INVOICE FINDER] Set Selected Ids Action', (payload: { confirmationIds: string[] }) => ({ payload }));
export const setSelectedInvoicesAction = createAction('[INVOICE FINDER] Set Selected Invoices Action', (payload: { invoices: EsmInvoiceFinder[] }) => ({ payload }));
export const setColumnsAction = createAction('[INVOICE FINDER] Set Columns', (payload: Column[]) => ({ payload }));
export const confirmAgreeInvoices = createAction('[INVOICE FINDER] Confirm the Selected Invoices Action', (payload: { invoiceIds: string[] }) => ({ payload }));
export const confirmDisputeInvoice = createAction('[INVOICE FINDER] Confirm Dispute Invoice', (payload: Dispute) => ({ payload }));

const actions = union({
  setMessagesAction,
  sortAndPageDataAction,
  exportAction,
  downloadHtmlAction,
  downloadXmlAction,
  downloadPdfAction,
  addNoteAction,
  addNoteSuccessAction,
  setHtmlAction,
  setSelectedIdsAction,
  setSelectedInvoicesAction,
  setColumnsAction,
  resendAction,
  confirmAgreeInvoices,
  confirmDisputeInvoice,
});

export type InvoiceFinderTableActions = typeof actions;
