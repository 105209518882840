import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorisationGuard } from '../auth/authorisation.guard';
import { InvoiceFinderComponent } from './invoice/finder/invoice-finder.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { TabToTitleMap } from '../ecm/ecm-dashboard/ecm-dashboard.model';
import { EsmDashboardComponent } from './invoice/dashboard/esm-dashboard.component';
import { EsmDashboardTabComponent } from './invoice/dashboard/esm-dashboard-tab/esm-dashboard-tab.component';
import { EsmNettingDashboardComponent } from './netting/dashboard/esm-netting-dashboard.component';
import { EsmNettingDashboardTabComponent } from './netting/dashboard/esm-netting-dashboard-tab/esm-netting-dashboard-tab.component';
import { SettingsComponent } from './settings/settings.component';
import { MasterDataComponent } from './master-data/master-data.component';
import { EsmNettingFinderComponent } from './netting/finder/esm-netting-finder.component';
import { NettingPreviewComponent } from './netting-preview/netting-preview.component';
import { WebUserComponent } from './permissions/web-user/web-user.component';

const routes: Routes = [
  { path: 'invoicefinder', component: InvoiceFinderComponent, canActivate: [AuthorisationGuard] },
  { path: 'fileupload', component: FileUploadComponent, canActivate: [AuthorisationGuard] },
  { path: 'masterdata', redirectTo: 'masterdata/internal', pathMatch: 'full', data: { panel: 'internal'} },
  { path: 'masterdata/internal', component: MasterDataComponent, data: { panel: 'internal'}, canActivate: [AuthorisationGuard] },
  { path: 'masterdata/external', component: MasterDataComponent, data: { panel: 'external'}, canActivate: [AuthorisationGuard] },
  {
    path: 'dashboard', component: EsmDashboardComponent, canActivate: [AuthorisationGuard],
    children: [
      { path: '', data: { panel: 'inprogress' }, redirectTo: 'inprogress', pathMatch: 'full' },
      { path: 'inprogress', component: EsmDashboardTabComponent, data: { panel: 'inprogress', title: TabToTitleMap['inprogress'] } },
      { path: 'issue', component: EsmDashboardTabComponent, data: { panel: 'issue', title: TabToTitleMap['issue'] } },
      { path: 'complete', component: EsmDashboardTabComponent, data: { panel: 'complete', title: TabToTitleMap['complete'] } },
      { path: 'inprogress/deals', component: EsmDashboardTabComponent, data: { panel: 'inprogress' } },
      { path: 'issue/deals', component: EsmDashboardTabComponent, data: { panel: 'issue' } },
      { path: 'complete/deals', component: EsmDashboardTabComponent, data: { panel: 'complete' } },
    ]
  },
  {
    path: 'nettingdashboard', component: EsmNettingDashboardComponent, canActivate: [AuthorisationGuard],
    children: [
      { path: '', data: { panel: 'inprogress' }, redirectTo: 'inprogress', pathMatch: 'full' },
      { path: 'inprogress', component: EsmNettingDashboardTabComponent, data: { panel: 'inprogress', title: TabToTitleMap['inprogress'] } },
      { path: 'issue', component: EsmNettingDashboardTabComponent, data: { panel: 'issue', title: TabToTitleMap['issue'] } },
      { path: 'complete', component: EsmNettingDashboardTabComponent, data: { panel: 'complete', title: TabToTitleMap['complete'] } },
      { path: 'inprogress/deals', component: EsmNettingDashboardTabComponent, data: { panel: 'inprogress' } },
      { path: 'issue/deals', component: EsmNettingDashboardTabComponent, data: { panel: 'issue' } },
      { path: 'complete/deals', component: EsmNettingDashboardTabComponent, data: { panel: 'complete' } },
    ]
  },
  {
    path: 'settings', component: SettingsComponent, canActivate: [AuthorisationGuard],
    children: [
      { path: 'invoice', component: SettingsComponent },
      { path: 'netting', component: SettingsComponent },
      { path: 'tolerance', component: SettingsComponent },
      { path: 'subscription', component: SettingsComponent },
      { path: 'whitelist', component: SettingsComponent },

    ]
  },
  { path: 'nettingfinder', component: EsmNettingFinderComponent, canActivate: [AuthorisationGuard] },
  { path: 'netting_preview', component: NettingPreviewComponent, canActivate: [AuthorisationGuard] },
  { path: 'permissions', component: WebUserComponent, canActivate: [AuthorisationGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EsmRoutingModule {
}
