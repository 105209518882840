<div id="datatable">
  <cms-upload-panel *ngIf="(presetValues$ | async)?.uploadAllowed"
                [accept]="'.csv'"
                [maxFileSize]="(presetValues$ | async)?.maxUploadSize"
                [inBytesSize]="true"
                [busy]="uploadStatus$ | async"
                title="Upload eSM External Master Data: (.csv file)"
                (error)="onError($event)"
                (upload)=onUpload($event)>
  </cms-upload-panel>
  <cms-datatable
               [scrollable]="false"
               [tableColumns]="columns"
               [currentPage]="(data$ | async).currentPage"
               [rows]="(paging$ | async).entriesPerPage"
               [sortField]="(sorting$ | async).columnName"
               [sortOrder]="(sorting$ | async).sortOrder"
               [loading]="(tableStatus$ | async)"
               [data]="(data$ | async).values"
               [totalRecords]="(data$ | async).count"
               [autoLayout]="true"
               [lazy]="true"
               [lazyLoadOnInit]="false"
               (lazyLoad)="onLazyLoad($event)">
                <ng-template pTemplate="caption">
                  <div class="row margin-0 lign-items-center">
                    <cms-input-text-filter placeholder="EIC / VAT ID / Legal Name" (filter)="onFilter($event)" class="col-6 margin-0 padding-0" maxLength="50"></cms-input-text-filter>
                    <div class="col text-end margin-top-3 margin-bottom-3">
                      <button *ngIf="(presetValues$ | async)?.uploadAllowed" id="newBtn" class="btn" pButton type="button" icon="fa fa-plus" label="New" (click)="onNew()"></button>
                      <button id="downloadBtn" class="btn" pButton type="button" icon="fa fa-download" label="Download" (click)="onDownload()"></button>
                    </div>
                  </div>
                </ng-template>
                <ng-template pTemplate="body" let-column="column" let-row="row">
                  <td *ngIf="column.type === 'warning'" [ngStyle]="column.style">
                    <span [ngClass]="{
                      'yellow-icon': (row[column.name] !== null && row[column.name] !== undefined) && row[column.name].toString() === 'true' }">
                      <i class="warn-icons fa fa-exclamation-triangle"></i>
                    </span>
                  </td>
                  <td *ngIf="column.type === 'buttons'" [ngStyle]="column.style">
                    <div class="btn-wrapper">
                      <button *ngIf="(presetValues$ | async)?.uploadAllowed" class="btn btn-icon fa fa-pencil margin-left-3 margin-right-3"
                              title="Details" type="button" (click)="onEdit(row)"></button>
                      <button *ngIf="!(presetValues$ | async)?.uploadAllowed" class="btn btn-icon fa fa-search margin-left-3 margin-right-3"
                              title="Details" type="button" (click)="onEdit(row)"></button>
                      <button *ngIf="(presetValues$ | async)?.uploadAllowed" class="btn btn-icon fa fa-trash-o margin-left-3 margin-right-3"
                              title="Delete" type="button" (click)="onDelete(row)"></button>
                    </div>
                  </td>
                </ng-template>
  </cms-datatable>
</div>

<ng-container *ngIf="(dialog$ | async) as dialog">
  <cms-master-data-dialog
                        [selectedVat]="selectedRecord?.vatId"
                        [dialog]="dialog"
                        (cancel)="onCancelDelete()"
                        (confirm)="onConfirmDelete()">
  </cms-master-data-dialog>
</ng-container>
