import { createFeatureSelector, createSelector } from '@ngrx/store';
import { InvoicesTableState } from './invoices-table.reducer';
import { DatatableData } from '@common/shared/results.model';
import { EsmNettedInvoices } from '../../netted-invoices/netted-invoices.model';
import { getNttInvoiceTable, State } from '../index';



export const getTable = createSelector(getNttInvoiceTable, (state: State) => state.nttTable);
export const getMessages = createSelector(getTable, (state: InvoicesTableState) => state.messages);
export const getLoading = createSelector(getTable, (state: InvoicesTableState) => state.loading);
export const getSelectedIds = createSelector(getTable, (state: InvoicesTableState) => state.selectedIds);
export const getSelectedInvoices = createSelector(getTable, (state: InvoicesTableState) => state.invoices);
export const getRequestModel = createSelector(getTable, (state: InvoicesTableState) => state.requestModel);
export const getData = createSelector(getTable, (state: InvoicesTableState):DatatableData<EsmNettedInvoices[]> => state.data);
export const getPaging = createSelector(getTable, (state: InvoicesTableState) => state.paging);
export const getSorting = createSelector(getTable, (state: InvoicesTableState) => state.sorting);
export const getColumns = createSelector(getTable, (state: InvoicesTableState) => state.columns);
export const getHeader = createSelector(getTable, (state: InvoicesTableState) =>state.header);

export const getDialog = createSelector(getNttInvoiceTable, (state: State) => state.dialog);
