import * as fromStandingInstructions from './standing-instructions-upload.reducer';
import { ActionReducerMap } from '@ngrx/store';


export interface State {
  standingInstructionsUpload: fromStandingInstructions.StandingInstructionsUploadState;
}

export const reducers: ActionReducerMap<State> = {
  standingInstructionsUpload: fromStandingInstructions.standingInstructionsUploadReducer
};
