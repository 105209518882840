<p-menu #menu [popup]="true" [model]="allItems"></p-menu>
<button type="button"
        pButton
        icon="fa fa-cog"
        class="btn margin-top-3 margin-bottom-3 margin-left-12 position-relative"
        label="My Filters" (click)="menu.toggle($event)">
  <i class="fa fa-sort-down"></i>
</button>
<cms-save-my-current-dialog *ngIf="dialog?.name === 'SAVE_CURRENT'"
                            [myFilters]="filters"
                            [messages]="dialog?.messages"
                            (replace)="replaceFilter.emit($event);"
                            (saveAs)="saveAs.emit($event)"
                            [display]="dialog?.name === 'SAVE_CURRENT'"
                            (exit)="closeDialog.emit()">
</cms-save-my-current-dialog>
<cms-filters-management-dialog *ngIf="dialog?.name === 'MANAGE_FILTERS'"
                               [messages]="dialog?.messages"
                               [myFilters]="filters"
                               [defaultFilter]="defaultFilter"
                               [display]="dialog?.name === 'MANAGE_FILTERS'"
                               (setDefault)="setDefault.emit($event)"
                               (delete)="delete.emit($event)"
                               (exit)="closeDialog.emit()">
</cms-filters-management-dialog>
