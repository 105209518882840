import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProcessDatatableSettings } from '../ecm-process.model';

@Component({
  selector: 'cms-edit-button',
  templateUrl: './ecm-process-edit-button.component.html',
  styleUrls: ['./ecm-process-edit-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EcmProcessEditButtonComponent {
  @Input() disabled: boolean;
  @Input() rowIndexEditButton: number;
  @Input() openRowIndex: number;
  @Output() editMode = new EventEmitter<boolean>();
  @Output() cancel = new EventEmitter();
  @Output() save = new EventEmitter<ProcessDatatableSettings>();
  additionalButtons = false;

  onEditButton(rowIndex): void {
    // to prevent PrimeNG datatable error: "Uncaught TypeError: Cannot read property 'nodeName' of null" is used setTimeout
    setTimeout(() => {
      this.editMode.emit(rowIndex);
      this.additionalButtons = true;
      }, 0);
  }

  onCancel(): void {
    // to prevent PrimeNG datatable error: "Uncaught TypeError: Cannot read property 'nodeName' of null" is used setTimeout
    setTimeout(() => {
      this.additionalButtons = false;
      this.cancel.emit();
    }, 0);
  }

  onSave(): void {
    // to prevent PrimeNG datatable error: "Uncaught TypeError: Cannot read property 'nodeName' of null" is used setTimeout
    setTimeout(() => {
      this.additionalButtons = false;
      this.save.emit();
    }, 0);
  }
}
