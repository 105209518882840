import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MyFilter } from '@common/my-filters/my-filters.model';
import { HiddenDeals, updateDealsMultiSelect } from '@common/deals/deals.model';
import { Message } from 'primeng/api';
import { EcmMyFilter } from '../../deal-finder/deal-finder.model';
import { EcmDashboardPropertiesFilters } from '../ecm-dashboard.model';
import { DialogState } from '../../state/dashboard/dialog/dialog.reducers';
import { getSaveReplaceMyFilter, shouldDisableSaveCurrent } from '../../ecm.model';

@Component({
  selector: 'cms-ecm-dashboard-properties-filters',
  templateUrl: './ecm-dashboard-properties-filters.component.html',
  styleUrls: ['./ecm-dashboard-properties-filters.component.scss']
})
export class EcmDashboardPropertiesFiltersComponent implements OnInit, OnChanges {
  @Input() myFilters: EcmMyFilter[];
  @Input() allDealsFilter: EcmMyFilter;
  @Input() filters: EcmDashboardPropertiesFilters;
  @Input() brokers = [];
  @Input() commodities = [];
  @Input() counterParties = [];
  @Input() markets = [];
  @Input() transactionTypes = [];
  @Input() dialog: DialogState;
  @Input() messages: Message[] = [];
  @Input() defaultFilter: MyFilter;
  @Output() filter = new EventEmitter<EcmDashboardPropertiesFilters>();
  @Output() reset = new EventEmitter();
  @Output() confirmSaveCurrent = new EventEmitter();
  @Output() openSaveCurrent = new EventEmitter();
  @Output() manageFilters = new EventEmitter();
  @Output() selectMyFilter = new EventEmitter<EcmMyFilter | null>();
  @Output() replaceFilter = new EventEmitter<MyFilter>();
  @Output() closeDialog = new EventEmitter();
  @Output() deleteFilter = new EventEmitter<string>();
  @Output() setDefaultFilter = new EventEmitter<MyFilter>();

  disableSaveCurrent = true;
  form: UntypedFormGroup;
  brokerOptions: { label: string, value: string}[] = [];
  commodityOptions: { label: string, value: string}[] = [];
  counterPartyOptions: { label: string, value: string}[] = [];
  marketOptions: { label: string, value: string}[] = [];
  transactionTypeOptions: { label: string, value: string}[] = [];
  errors: {[key: string]: ValidationErrors} = {};
  updateDealsMultiSelect;
  readonly HiddenDeals = HiddenDeals;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.updateDealsMultiSelect = updateDealsMultiSelect.bind(this);
  }

  onChanges(): void {
    if (this.form.invalid) {
      return;
    }
    const {
      brokers,
      commodities,
      counterParties,
      hiddenDeals,
      markets,
      transactionTypes,
    } = this.form.getRawValue();
    this.filter.emit({
      brokers,
      commodities,
      counterParties,
      hiddenDeals,
      markets,
      transactionTypes,
    });
  }

  onReset(): void {
    this.reset.emit();
    this.form.reset(this.filters);
  }

  ngOnInit() {
    const {
      brokers,
      commodities,
      counterParties,
      hiddenDeals,
      markets,
      transactionTypes
    } = this.filters;

    this.form = this.formBuilder.group({
      brokers: { value: brokers, disabled: !this.brokers.length },
      commodities: { value: commodities, disabled: !this.commodities.length },
      counterParties: { value: counterParties, disabled: !this.counterPartyOptions.length },
      markets: { value: markets, disabled: !this.markets.length },
      transactionTypes: { value: transactionTypes, disabled: !this.transactionTypes.length },
      hiddenDeals: hiddenDeals,
    });

    this.updateDealsMultiSelect(
      'brokers',
      'brokerOptions',
      this.brokers,
      Validators.required);

    this.updateDealsMultiSelect(
      'commodities',
      'commodityOptions',
      this.commodities,
      Validators.required);

    this.updateDealsMultiSelect(
      'counterParties',
      'counterPartyOptions',
      this.counterParties,
      Validators.required);

    this.updateDealsMultiSelect(
      'markets',
      'marketOptions',
      this.markets,
      Validators.required);

    this.updateDealsMultiSelect(
      'transactionTypes',
      'transactionTypeOptions',
      this.transactionTypes,
      Validators.required);

    this.disableSaveCurrent = shouldDisableSaveCurrent(this.form.getRawValue(), this.allDealsFilter);

    this.form.valueChanges.subscribe(() => {
      this.disableSaveCurrent = shouldDisableSaveCurrent(this.form.getRawValue(), this.allDealsFilter);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.brokers) {
      this.updateDealsMultiSelect(
        'brokers',
        'brokerOptions',
        this.brokers,
        Validators.required);
    }

    if (changes.commodities) {
      this.updateDealsMultiSelect(
        'commodities',
        'commodityOptions',
        this.commodities,
        Validators.required);
    }

    if (changes.counterParties) {
      this.updateDealsMultiSelect(
        'counterParties',
        'counterPartyOptions',
        this.counterParties,
        Validators.required);
    }

    if (changes.markets) {
      this.updateDealsMultiSelect(
        'markets',
        'marketOptions',
        this.markets,
        Validators.required);
    }

    if (changes.transactionTypes) {
      this.updateDealsMultiSelect(
        'transactionTypes',
        'transactionTypeOptions',
        this.transactionTypes,
        Validators.required);
    }

    if (changes.messages) {
      this.errors = {};
      if (this.messages) {
        this.messages.forEach((obj: Message) => {
          const { key: controlName } = obj;
          this.errors[controlName] = { 'server': true };
        });
      }
    }

    if (this.form) {
      this.disableSaveCurrent = shouldDisableSaveCurrent(this.form.getRawValue(), this.allDealsFilter);
    }
  }

  onSelectMyFilter(filter: MyFilter): void {
    this.selectMyFilter.emit(filter as EcmMyFilter);
  }

  onReplaceFilter(filter: MyFilter): void {
    this.replaceFilter.emit(getSaveReplaceMyFilter(this.form.getRawValue(), filter));
  }

  onSaveFilter(filter: MyFilter): void {
    this.confirmSaveCurrent.emit(getSaveReplaceMyFilter(this.form.getRawValue(), filter));
  }

  hasErrors() {
    return !this.form.valid;
  }
}

