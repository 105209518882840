import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { CmsCommonModule } from '@common/cms-common.module';
import { RouterModule } from '@angular/router';
import { RemitTable2TradeService } from './remit-table2-trade.service';
import { RemitTable2TradeComponent } from './remit-table2-trade.component';
import { RemitTable2TradeCancelComponent } from './cancel/remit-table2-trade-cancel.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RemitTable2TradeErrorComponent } from './error/remit-table2-trade-error.component';
import { RemitTradeTable2ModifyComponent } from './modify/remit-trade-table2-modify.component';
import { RemitTradeEntryTable2FormModule } from '../../trade-entry/table2/form/remit-trade-entry-table2-form.module';
import { TableModule } from 'primeng/table';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { CalendarModule } from 'primeng/calendar';

@NgModule({
  imports: [
    CommonModule,
    TableModule,
    BrowserModule,
    CmsCommonModule,
    RouterModule,
    ReactiveFormsModule,
    CalendarModule,
    MessageModule,
    MessagesModule,
    ProgressSpinnerModule,
    RemitTradeEntryTable2FormModule
  ],
  providers: [RemitTable2TradeService],
  declarations: [
    RemitTable2TradeComponent,
    RemitTable2TradeCancelComponent,
    RemitTable2TradeErrorComponent,
    RemitTradeTable2ModifyComponent
  ]
})
export class RemitTable2TradeModule {
}
