import { datatableReducer, DatatableState } from '../datatable/datatable.reducer';
import { dialogReducer, DialogState } from '../dialog/dialog.reducer';
import { FhaAccount } from '../../administration-tab-panel/fha-accounts/fha-accounts.model';
import * as fromAction from '../fha-accounts/fha-accounts.actions';
import { organisationIdReducer } from '../organisation-id/organisation-id.reducer';

export interface FhaAccountsState {
  organisationId?: number;
  datatable: DatatableState<FhaAccount>;
  dialog: DialogState;
}

export const datatableInitialState: DatatableState<FhaAccount> = {
  selected: null,
  data: {
    values: [],
    count: 0
  },
  messages: [],
  loading: false,
  editableData: null
};

export const initialFhaAccountsState: FhaAccountsState = {
  organisationId: null,
  datatable: datatableInitialState,
  dialog: null
};

export function fhaAccountsReducer(state: FhaAccountsState = { ...initialFhaAccountsState },
                                   action: fromAction.FhaAccountsActions): FhaAccountsState {
  return {
    dialog: dialogReducer('[FHA ACCOUNTS]')(state.dialog, action as any),
    datatable: datatableReducer('[FHA ACCOUNTS]', datatableInitialState)(state.datatable, action as any),
    organisationId: organisationIdReducer('[FHA ACCOUNTS]')(state.organisationId, action as any)
  };
}
