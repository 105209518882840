<h1>List All User Accounts</h1>
<div class="panel-box">
  <p-messages [value]="messages$ | async" [closable]="true"></p-messages>
  <div class="margin-bottom-12 decent-max-width" id='org-groups-dropdown'>
    <cms-user-accounts-filter
      [filters]="filters$ | async"
      [orgGroups]="orgGroups$ | async"
      [groupsVisible]="orgGroupSelectorVisible"
      [filterInactiveVisible]="filterInactiveVisible"
      (filter)="onFilter($event)">

    </cms-user-accounts-filter>
  </div>
  <div class="user-accounts__table-btn-container">
    <cms-datatable *ngIf="(data$ | async) as data"
                   selectionMode="single"
                   [currentPage]="(paging$ | async).page"
                   [tableColumns]="dataTableColumns"
                   [sortField]="(sorting$ | async).columnName"
                   [sortOrder]="(sorting$ | async).sortOrder"
                   [loading]="loading$ | async"
                   [data]="data.values"
                   [totalRecords]="data.count"
                   [selected]="selected$ | async"
                   (selectedChange)="onUserAccountSelected($event)"
                   [scrollable]="false"
                   [autoLayout]="true"
                   (lazyLoad)="onLazyLoad($event)">
    <ng-template pTemplate="body" let-row="row" let-column="column">
      <td *ngIf="column.type === 'userState'" [ngStyle]="column.style" [ngClass]="'editable-text'" cmsCellEditor>
        <cms-editable-status-cell [state]="row[column.name]"
                                  [row]="row"
                                  [selected]="editableData$ | async"
                                  (cancelEdit)="onCancelEdit()"
                                  (initEdit)="onInitEdit($event)"
                                  (editComplete)="onEditComplete($event)">
        </cms-editable-status-cell>
      </td>
    </ng-template>
  </cms-datatable>
    <div class="padding-0 col-lg-12 col-12 btn__container margin-top-3 flex justify-content-end align-items-center">
      <button [disabled]="!((selected$ | async)?.state === 'ACTIVE')" class="btn margin-top-3 margin-bottom-3 margin-left-12" type="submit" (click)="onResetPassword()">Reset Password</button>
      <button [disabled]="!(selected$ | async)" class="btn margin-top-3 margin-bottom-3 margin-left-12" type="button" (click)="onEditUserAccount()">Edit User Account</button>
      <button *ngIf="orgGroupSelectorVisible" [disabled]="!((filters$ | async).organisationGroupId)" class="btn margin-top-3 margin-bottom-3 margin-left-12" type="button" (click)="onAddUserAccount()">Add User Account</button>
      <button *ngIf="!orgGroupSelectorVisible" class="btn margin-top-3 margin-bottom-3 margin-left-12" type="button" (click)="onAddUserAccount()">Add User Account</button>
    </div>
  </div>
</div>
<cms-dialog-container [organisationGroupId]="(filters$ | async).organisationGroupId"
                      [editableData]="editableData$ | async"
                      [selected]="selected$ | async"
                      [dialog]="dialog$ | async"
                      (reset)="onConfirmResetPassword($event)"
                      (cancel)="onCancelDialog($event)"
                      (reactivate)="onConfirmReactivate($event)"
                      (inactivate)="onConfirmInactivate($event)"
                      (lock)="onConfirmLock($event)"
                      (edit)="onConfirmEditUserAccount($event)"
                      (add)="onConfirmAddUser($event)">
</cms-dialog-container>
