import { Message } from 'primeng/api';
import { Column } from '@common/shared/datatable.model';
import { EcmDashboardState } from '../dashboard.reducers';
import { ecmStatesToLabelMaps, EcmBucketsTableRows } from '../../../ecm-dashboard/ecm-dashboard.model';
import * as fromTabActions from './tab.actions';
import * as fromDbActions from '../dashboard.actions';
import * as fromFilterActions from '../filters/filters.actions';
import * as fromCategoryActions from '../category/category.actions';
import { DashboardDealsNavModel } from '@common/dashboard-deals-nav/dashboard-deals-nav.model';

export interface EcmDashboardTabState {
  messages: Message[];
  data: { rows: EcmBucketsTableRows[] };
  loading: boolean;
  stateNames: string[];
  columns: Column[];
}

export const tabInitialState: EcmDashboardTabState = {
  messages: [],
  stateNames: [],
  columns: [],
  data: {
    rows: null
  },
  loading: true
};

export function tabReducer(state: EcmDashboardTabState = tabInitialState,
                           action: fromTabActions.TabActions | fromDbActions.SetToDefaultAction
                             | fromFilterActions.FilterAction | fromCategoryActions.SetCategoryAction): EcmDashboardTabState {
  switch (action.type) {
    case fromFilterActions.FILTER:
    case fromTabActions.GET_BUCKETS:
    case fromCategoryActions.SET_CATEGORY:
      return {
        ...state,
        loading: true
      };
    case fromTabActions.GET_BUCKETS_SUCCESS:
      return {
        ...state,
        loading: false,
        stateNames: action.payload.stateNames,
        data: {
          rows: action.payload.rows
        }
      };
    case fromTabActions.SET_MESSAGES: {
      return {
        ...state,
        messages: action.payload
      };
    }
    case fromDbActions.SET_TO_DEFAULT: {
      return { ...tabInitialState };
    }
    case fromTabActions.SET_COLUMNS: {
      return {
        ...state,
        columns: action.payload
      };
    }
    default:
      return state;
  }
}

export const getTab = (state: EcmDashboardState): EcmDashboardTabState => state.tab;
export const getMessages = (state: EcmDashboardTabState): Message[] => state.messages;
export const getBucketsValues = (state: EcmDashboardTabState): EcmBucketsTableRows[] => state.data.rows;
export const getLoading = (state: EcmDashboardTabState): boolean => state.loading;
export const getColumns = (state: EcmDashboardTabState): Column[] => state.columns;
export const getNavValues = (state: EcmDashboardTabState): DashboardDealsNavModel[] => {
  return (state.stateNames) ? state.stateNames.map(item => {
    return {
      state: item,
      title: ecmStatesToLabelMaps[item],
    };
  }) : [];
};
