import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { Message } from 'primeng/api';
import { Column } from '@common/shared/datatable.model';
import { EcmDashboardState } from '../dashboard.reducers';
import { EcmDateRange, EcmDealItemModel, EcmStatesEnum } from '../../../ecm-dashboard/ecm-dashboard.model';
import * as fromActions from './deals.actions';
import * as fromFilterActions from '../filters/filters.actions';
import { DialogState } from '@common/deals/deals.model';
import { EcmDealFinder } from '../../../deal-finder/deal-finder.model';

export interface EcmDealsState {
  data: DatatableData<EcmDealItemModel[]>;
  columns: Column[];
  messages: Message[];
  paging?: PagingFilter;
  sorting?: SortingFilter;
  loading: boolean;
  state: EcmStatesEnum;
  dateRange: EcmDateRange;
  confirmationIds: string[];
  selected: EcmDealFinder[];
  dialog: DialogState;
  dateRangeText: string;
  terminationRights: boolean;
  editNotesRights: boolean;
}

export const ecmDealsInitialState: EcmDealsState = {
  terminationRights: false,
  editNotesRights: false,
  columns: [],
  data: {
    values: [],
    count: 0,
    currentPage: 0
  },
  loading: true,
  sorting: {
    columnName: 'submissionDate',
    sortOrder: 'DESCENDING'
  },
  messages: [],
  paging: {
    page: 0,
    entriesPerPage: 25
  },
  state: EcmStatesEnum.TRADER_IN_PROGRESS_PENDING,
  dateRange: EcmDateRange.TOTAL,
  confirmationIds: [],
  selected: [],
  dialog: null,
  dateRangeText: '',
};

export function ecmDealsReducer(state: EcmDealsState,
                                action: fromActions.DealsActions
                                  | fromFilterActions.FilterAction): EcmDealsState {
  switch (action.type) {
    case fromActions.SET_COLUMNS: {
      return {
        ...state,
        columns: action.payload
      };
    }
    case fromActions.SORT_AND_PAGE_DATA: {
      const { paging, sorting } = action.payload;
      return {
        ...state,
        loading: true,
        messages: [],
        confirmationIds: [],
        paging,
        sorting
      };
    }
    case fromActions.LOAD_PRESETS_SUCCESS: {
      return {
        ...state,
        terminationRights: action.rights.termination,
        editNotesRights: action.rights.notes,
      };
    }
    case fromFilterActions.FILTER:
    case fromActions.LOAD_DATA: {
      return {
        ...state,
        loading: true,
        confirmationIds: [],
        messages: [],
      };
    }
    case fromActions.LOAD_DATA_SUCCESS: {
      const { count, values, currentPage: page, dateRangeText } = action.payload;
      return {
        ...state,
        loading: false,
        dateRangeText,
        data: {
          count,
          values
        },
        paging: {
          ...state.paging,
          page
        }
      };
    }
    case fromActions.SET_MESSAGES:
      return {
        ...state,
        loading: false,
        messages: [...action.payload],
      };
    case fromActions.SET_TO_DEFAULT:
      return { ...ecmDealsInitialState };
    case fromActions.TERMINATE_CONFIRM_SUCCESS:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          data: {
            closeButton: true
          }
        }
      };
    case fromActions.CONFIRM_ECM_LITE_CANCEL:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          data: {
            ...state.dialog.data,
            loadingDialog: true,
            closeButton: false
          }
        }
      };
    case fromActions.CONFIRM_ECM_LITE_CANCEL_SUCCESS:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          data: {
            loadingDialog: false,
            closeButton: true
          }
        }
      };
    case fromActions.OPEN_DIALOG:
      return {
        ...state,
        dialog: { ...action.payload }
      };
    case fromActions.UPDATE_DIALOG:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          messages: action.payload
        }
      };
    case fromActions.CLOSE_DIALOG:
      return {
        ...state,
        dialog: null
      };

    case fromActions.SET_SELECTED_DEALS: {
      return {
        ...state,
        selected: action.payload.deals

      };
    }

    case fromActions.SET_SELECTED_CONFIRMATION_IDS: {
      return {
        ...state,
        confirmationIds: action.payload.confirmationIds

      };
    }
    case fromActions.ADD_SELECTED_CONFIRM_ID: {
      return {
        ...state,
        confirmationIds: [
          ...state.confirmationIds,
          action.payload.confirmationId
        ]
      };
    }
    case fromActions.REMOVE_SELECTED_CONFIRM_ID: {
      const index = state.confirmationIds.indexOf(action.payload.confirmationId);
      const confirmationIds = [
        ...state.confirmationIds.slice(0, index),
        ...state.confirmationIds.slice(index + 1, state.confirmationIds.length)
      ];
      return {
        ...state,
        confirmationIds
      };
    }
    case fromActions.SET_PARAMS: {
      return {
        ...state,
        state: action.payload.state,
        dateRange: action.payload.dateRange
      };
    }
    default:
      return state;
  }
}

export const getDeals = (state: EcmDashboardState): EcmDealsState => state.deals;
export const getData = (state: EcmDealsState): DatatableData<EcmDealItemModel[]> => state.data;
export const getSorting = (state: EcmDealsState) => state.sorting;
export const getPaging = (state: EcmDealsState) => state.paging;
export const getLoading = (state: EcmDealsState) => state.loading;
export const getMessages = (state: EcmDealsState) => state.messages;
export const getColumns = (state: EcmDealsState) => state.columns;
export const getConfirmationIds = (state: EcmDealsState) => state.confirmationIds;
export const getSelected = (state: EcmDealsState) => state.selected;
export const getDialog = (state: EcmDealsState) => state.dialog;
export const getDateRangeText = (state: EcmDealsState) => state.dateRangeText;
export const getDealEditRights = (state: EcmDealsState) => state.terminationRights;
export const getDealNoteRights = (state: EcmDealsState) => state.editNotesRights;
