import { ErrTerminationState } from './remit.reducer';
import {  createSelector } from '@ngrx/store';
import { getErrState, State } from '../reducers';


export const getTerminationState = createSelector(getErrState, (state: State) => state.remitTermination);
export const getData = createSelector(getTerminationState, (termination: ErrTerminationState) => termination.data);
export const getLoading = createSelector(getTerminationState, (termination: ErrTerminationState) => termination.loading);
export const getSorting = createSelector(getTerminationState, (termination: ErrTerminationState) => termination.sorting);
export const getPaging = createSelector(getTerminationState, (termination: ErrTerminationState) => termination.paging);
export const getMessages = createSelector(getTerminationState, (termination: ErrTerminationState) => termination.messages);
export const getMaxUploadSize = createSelector(getTerminationState, (termination: ErrTerminationState) => termination.maxUploadFileSizeInBytes);
export const getAllowedToUpload = createSelector(getTerminationState, (termination: ErrTerminationState) => termination.allowedToUpload);
export const getOrgInfo = createSelector(getTerminationState, (termination: ErrTerminationState) => termination.organisationNames);
export const getCsvMetaData = createSelector(getTerminationState, (termination: ErrTerminationState) => termination.uploadMetaData);
export const getList = createSelector(getTerminationState, (termination: ErrTerminationState, props) =>
  termination.previewLists.find((element) => (element?.id === props.id && element?.column === props.column))?.values);
