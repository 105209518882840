import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../auth/authentication.service';
import { UserService } from '../user.service';
import { UserInformationModel } from '../user-informations.model';
import { SystemService } from '../../system/system.service';
import { SystemInformationModel } from '../../system/system-informations.model';

@Component({
  selector: 'cms-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  authenticated: boolean;
  userInformation: UserInformationModel;
  systemInformations: SystemInformationModel;

  constructor(private authService: AuthenticationService,
              private systemService: SystemService,
              private userService: UserService) {
  }

  ngOnInit() {
    this.authService.isAuthenticated().subscribe(
      status => {
        this.authenticated = status;
        if (this.authenticated) {
          this.systemService.getInformations().subscribe((systemInformation: SystemInformationModel) => this.systemInformations = systemInformation);
          this.userService.getInformations().subscribe((userInformation: UserInformationModel) => this.userInformation = userInformation);
        }
      },
      error => {
        this.authenticated = false;
      });
  }

  onLogout() {
    this.authService.logout();
  }

  isAuthenticated(): boolean {
    return this.authenticated;
  }

}
