import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChange } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { SendingFailuresSearchRequest } from '../sending-failures.model';

@Component({
  selector: 'cms-sending-failures-filter',
  templateUrl: './sending-failures-filter.component.html',
  styleUrls: ['./sending-failures-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendingFailuresFilterComponent implements OnChanges {
  @Input() filters: SendingFailuresSearchRequest;
  @Output() search = new EventEmitter<SendingFailuresSearchRequest>();
  @Output() refresh = new EventEmitter();
  form: UntypedFormGroup;

  constructor (private formBuilder: UntypedFormBuilder) {
    this.form = this.formBuilder.group({
      fromDate: [null, Validators.required],
      fromTime: [null, Validators.required],
      toDate: [null, Validators.required],
      toTime: [null, Validators.required]
    });
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (this.form && changes.filters) {
      const { fromDate, fromTime, toDate, toTime } = this.filters;

      this.form.patchValue({
        fromDate: fromDate ? moment(fromDate).toDate() : null,
        toDate: toDate ? moment(toDate).toDate() : null,
        fromTime: fromTime || null,
        toTime: toTime || null
      });
      this.form.updateValueAndValidity();
    }
  }

  onSearch(): void {
    this.search.emit(this.getDates());
  }

  onRefresh(): void {
    this.refresh.emit();
  }

  private getDates(): SendingFailuresSearchRequest {
    const { fromDate, toDate, fromTime, toTime } = this.form.value;
    const formattedFromDate = moment(new Date(fromDate)).format('YYYY-MM-DD');
    const formattedToDate = moment(new Date(toDate)).format('YYYY-MM-DD');
    return { fromDate: formattedFromDate, toDate: formattedToDate, fromTime, toTime };
  }
}
