import { Component } from '@angular/core';
import { ErrorMessageService } from '@common/errors/error-message.service';

@Component({
  selector: 'cms-page',
  templateUrl: './page.component.html',
})
export class PageComponent {

}
