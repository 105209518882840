import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Injectable,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange
} from '@angular/core';
import { MatrixRole, UserRoleDatatableItem } from '../user-roles.model';
import { EditableCell } from '../../../../common/datatable/cell-editor/cell-editor.model';
import { Column } from '../../../../common/shared/datatable.model';

@Component({
  selector: 'cms-user-role-status',
  templateUrl: './user-role-status.component.html',
  styleUrls: ['./user-role-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

@Injectable()
export class UserRoleStatusComponent implements OnInit, OnChanges {
  @Input() column: Column;
  @Input() row: UserRoleDatatableItem;
  @Input() newRow: EditableCell;
  @Input() rowIndex: number;
  @Input() mode: 'adminStatus' | 'mo/operatorStatus';
  @Output() change = new EventEmitter<EditableCell>();
  @Output() unChange = new EventEmitter<EditableCell>();

  getCurrentValue(prop?: 'masterOperator' | 'operator'): boolean {
    return prop ? this.row[this.column.name][prop] : this.row[this.column.name];
  }

  getNewValue(prop?: 'masterOperator' | 'operator'): boolean {
    return this.newRow ? prop ?  this.newRow.newValue[prop] : this.newRow.newValue : null;
  }

  selected(prop?: 'masterOperator' | 'operator'): boolean {
    const currentValue = this.getCurrentValue(prop);
    const newValue = this.getNewValue(prop);

    return this.newRow ? newValue : currentValue;
  }

  status(checked: boolean, prop?: 'masterOperator' | 'operator'): boolean {
    const currentValue = this.getCurrentValue(prop);
    const newValue = this.getNewValue(prop);
    return this.newRow && currentValue === !checked &&
      newValue === checked && currentValue !== newValue;
  }

/*  unchecked(prop: string): boolean {
    const currentValue = this.row[this.column.name];
    return this.newRow && currentValue === true &&
      this.newRow.newValue === false && currentValue !== this.newRow.newValue;
  }

  checked(prop: string): boolean {
    const currentValue = prop ? this.row[this.column.name][prop] : this.row[this.column.name];
    return this.newRow && currentValue === false &&
      this.newRow.newValue === true && currentValue !== this.newRow.newValue;
  }*/

  constructor() { }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {

  }

  ngOnInit() {
  }

  onAdminClick() {
    if (this.newRow) {
      this.unChange.emit({
        column: this.column,
        data: null,
        newValue: null,
        index: this.rowIndex
      });
    } else {
      this.change.emit({
        column: this.column,
        data: this.row,
        newValue: !this.getCurrentValue(),
        index: this.rowIndex
      });
    }
  }

  onOperatorClick() {
    const currentMatrixRole = this.row[this.column.name] as MatrixRole;
    if (this.newRow) {
      const newCurrentMatrixRole = this.newRow.newValue as MatrixRole;
      if (!newCurrentMatrixRole.operator === currentMatrixRole.operator &&
        newCurrentMatrixRole.masterOperator === currentMatrixRole.masterOperator) {
        this.unOperatorChange();
      } else {
        this.operatorToggleChange('operator');
      }
    } else {
      this.firstOperatorToggleChange('operator');
    }
  }

  onMasterOperatorClick() {
    const currentMatrixRole = this.row[this.column.name] as MatrixRole;
    if (this.newRow) {
      const newCurrentMatrixRole = this.newRow.newValue as MatrixRole;
      if (!newCurrentMatrixRole.masterOperator === currentMatrixRole.masterOperator &&
        newCurrentMatrixRole.operator === currentMatrixRole.operator) {
        this.unOperatorChange();
      } else {
        this.operatorToggleChange('masterOperator');
      }
    } else {
      this.firstOperatorToggleChange('masterOperator');
    }
  }

  unOperatorChange(): void {
    this.unChange.emit({
      column: this.column,
      data: null,
      newValue: null,
      index: this.rowIndex
    });
  }

  operatorToggleChange(prop: 'masterOperator' | 'operator'): void {
    const newCurrentMatrixRole = this.newRow.newValue as MatrixRole;
    this.change.emit({
      column: this.column,
      data: this.row,
      newValue: {
        ...newCurrentMatrixRole,
        [prop]: !newCurrentMatrixRole[prop]
      } as MatrixRole,
      index: this.rowIndex
    });
  }

  firstOperatorToggleChange(prop: 'masterOperator' | 'operator'): void {
    const currentMatrixRole = this.row[this.column.name] as MatrixRole;

    this.change.emit({
      column: this.column,
      data: this.row,
      newValue: {
        ...currentMatrixRole,
        [prop]: !currentMatrixRole[prop]
      } as MatrixRole,
      index: this.rowIndex
    });
  }
}
