import * as moment from 'moment';
import { Message } from 'primeng/api';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { toPayload } from '@common/cms-common.model';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { ApiRequestService } from '@common/api-request.service';
import { AvailableQueuesResponse, ExceptionResponse, ExceptionFilter, RetryDeleteResponse } from '../../exceptions/exceptions.model';
import { ExceptionsState } from './exceptions.reducers';
import * as fromActions from './exceptions.actions';
import * as fromSelectors from './exceptions.selectors';

@Injectable()
export class ExceptionsEffects {
  private baseUrl = 'api/problemsolving/exception';

  constructor(private actions$: Actions,
              private apiGateway: ApiRequestService,
              private mapper: MessagesMapperService,
              private store$: Store<ExceptionsState>) { }

   getAvailableQueues$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.getAvailableQueues.type),
      switchMap(() => {
        return this.apiGateway.get(`${this.baseUrl}/getAvailableQueues`)
          .pipe(
            map((response: AvailableQueuesResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return fromActions.setMessages(messages);
              }
              return fromActions.setAvailableQueues(response.values);
            }),
            catchError(error => of(fromActions.setMessages([
              this.mapper.createErrorMessage(error.errorMessage)
            ])))
          );
      }
      )));

   filter$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.filter.type),
      map(toPayload),
      switchMap((filter: ExceptionFilter) => this.loadData({ ...filter }))));

   deleteExceptions$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.deleteExceptions.type),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getExceptionsState))),
      switchMap(([, { data, selectedItems }]: [ReturnType<typeof fromActions.deleteExceptions>, ExceptionsState]) => {
        const requestArray = data.values
          .filter(item => !!selectedItems[item.messageId])
          .map(item => ({ messageId: item.messageId, queueName: item.queueName, queued: item.queued }));
        return this.apiGateway.post(`${this.baseUrl}/delete`, requestArray)
          .pipe(
            switchMap((response: RetryDeleteResponse) => {
              return [
                fromActions.closeDialogAction(),
                fromActions.setMessages([]),
                fromActions.setEditedItems(response.values)
              ];
            }),
            catchError(error => {
              const message = error.errorMessage || error.statusText;
              return of(fromActions.updateDialogAction([this.mapper.createErrorMessage(message)]));
            }));
      })));

   retryExceptions$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.retryExceptions.type),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getExceptionsState))),
      switchMap(([, { data, selectedItems }]: [ReturnType<typeof fromActions.deleteExceptions>, ExceptionsState]) => {
        const requestArray = data.values
          .filter(item => !!selectedItems[item.messageId])
          .map(item => ({ messageId: item.messageId, queueName: item.queueName, queued: item.queued }));
        return this.apiGateway.post(`${this.baseUrl}/retry`, requestArray)
          .pipe(
            switchMap((response: RetryDeleteResponse) => {
              return [
                fromActions.setMessages([]),
                fromActions.setEditedItems(response.values)
              ];
            }),
            catchError(error => of(fromActions.setMessages([
              this.mapper.createErrorMessage(error.errorMessage)
            ])))
          );
      }
      )));
  private loadData(request: ExceptionFilter) {
    const format = 'YYYY-MM-DD';
    request.to = request.to ? moment(request.to).format(format) : '';
    request.from = request.from ? moment(request.from).format(format) : '';
    return this.apiGateway
      .post(`${this.baseUrl}/findExceptions`, request)
      .pipe(
        switchMap((response: ExceptionResponse) => {
          const messages: Message[] = this.mapper.toErrorMessages(response);
          if (messages && messages.length) {
            return [fromActions.setMessages(messages)];
          }

          const responseMessages = [];
          if (response.connectionErrors) {
            const connectionError = this.mapper.createErrorMessage(response.connectionErrors);
            responseMessages.push(connectionError);
          }
          return [
            fromActions.setMessages(responseMessages),
            fromActions.loadDataSuccessAction(response)
          ];
        }),
        catchError(error => of(fromActions.setMessages([this.mapper.createErrorMessage(error.errorMessage)]))));
  }
}
