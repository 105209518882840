import { createAction, props, union } from '@ngrx/store';
import { Message } from 'primeng/api';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { TableEntry } from '../table/table.component';

export const loading = createAction('[ACER BILLING RUNS MAIL REPORTS] un/loading', props<{load:boolean }>());
export const loadTable = createAction('[ACER BILLING RUNS MAIL REPORTS] Load table data', props<{ acerBillingRunId, quarter }>());
export const loadTableSuccess = createAction('[ACER BILLING RUNS MAIL REPORTS] Load table data successfully', props<{ data: DatatableData<TableEntry[]>}>());

export const setMessages = createAction('[ACER BILLING RUNS MAIL REPORTS] set messages', props<{ messages: Message[] }>());
export const resetMessages = createAction('[ACER BILLING RUNS MAIL REPORTS] Reset Messages');

export const pagingAndSorting = createAction('[ACER BILLING RUNS MAIL REPORTS] Get sorting and paging', props<{ sorting: SortingFilter, paging: PagingFilter}>());
export const setPagingAndSorting = createAction('[ACER BILLING RUNS MAIL REPORTS] Set sorting and paging', props<{ sorting: SortingFilter, paging: PagingFilter}>());


export const cleanState = createAction('[ACER BILLING RUNS MAIL REPORTS] Clear table data state');



const actions = union({
  loading,
  loadTable,
  loadTableSuccess,
  setMessages,
  resetMessages,
  pagingAndSorting,
  setPagingAndSorting,

});

export type AcerMailBillingRunsActions = typeof actions;

