import * as fromAction from './standing-instructions-upload.actions';

export interface StandingInstructionsUploadState {
  uploadPanelVisible: boolean;
  uploadAllowed: boolean;
}

export const initialStandingInstructionsUploadState: StandingInstructionsUploadState = {
  uploadPanelVisible: false,
  uploadAllowed: false
};

export function standingInstructionsUploadReducer(
  state = initialStandingInstructionsUploadState,
  action: fromAction.StandingInstructionsUploadActions
): StandingInstructionsUploadState {
  switch (action.type) {
    case fromAction.GET_PRESET_VALUES_SUCCESS: {
      return {
        ...state,
        ...action.payload
      }
    }
    case fromAction.GET_PRESET_VALUES:
    default: {
      return {
        ...state
      };
    }
  }
}
