<cms-info ngPreserveWhitespaces>
  <!--@formatter:off (markdown rendering breaks, when the following tags are formatted)-->
  <!-- list with (a,b,c) need two whitespace at the end of line, so ngx-markdown interpret it as a list -->
  __EMIR Reconciliation Report__

  This is an automated report which compares trade portfolios between counterparties based on their EMIR trade reporting.

  The report is executed **each weekday** (MON-FRI).

  **Reconciled Trades**

  * The report includes **EMIR submissions** to eRR which were accepted by your EMIR TR (trade repository).
  * In addition you can **upload** trade data of your counterparties in **CSV format** (in case they are not eRR participants).
  * The report includes all **legal entities** of your organisation group registered for eRR EMIR reporting.
  * The report includes all **OTC trades** (commodity, FX, interest rates) and excludes ETD trades (exchange traded derivatives) which are exempt from EMIR portfolio reconciliation requirements.
  * The report includes a comparison of **Mark-to-Market valuations** (available for trades for which MTM was reported by both sides).

  __Included Legal Entities__

  The "Report Level" setting chosen by your organisation group determines which organisations in your group are included in the report

  * Option "Group" (default): all legal entities are included in one report
  * Option "Organisation": separate reports are generated for each legal entity

  **Reconciliation Process**

  Matching takes place in two steps:

  1. find pairs: for trade details reported by one counterparty find the corresponding data sent by the other counterparty; a pair is established when both sides have used the same UTI (unique trade identifier) and counterparty identifiers (LEIs)
  2. match pairs: for each established pair commercial details are compared field by field; the relevant fields are defined in the EFET ePR standard (electronic portfolio reconciliation)

  **Functions**

  * **Download**: get reconciliation results in Excel format
  * **Upload CSV** (available to master operators only): upload counterparty data (find additional instructions on the upload panel)
  * **Rerun** (available to master operators only): instruct the CMS to redo a given reconciliation. The new report version will include corrections and additional uploads performed after the last run.

  **Meaning of the Data Columns**

  **Dates**

  * **As of**: valuation and reconciliation cut-off date, i.e. reconciliation reflects trades executed on/before this date and outstanding on this date
  * **Executed**: date and time at which the reconciliation was performed

  **Trade counts**

  * **MTM**: number of trades for which mark-to-market values are available from both sides
  * **Matched**: your trades whose reconciliation fields matched perfectly with counterparty data
  * **Mismatched**: your trades in which discrepencies were detected
  * **Pairing Failed**: submissions by your group members and counterparties which failed to pair on UTI and LEIs
  * **Pending**: your trade sides for which the counterparty did not report their sides through eRR
  * **Alleged**: the reverse - counterparty reports against your group members where no trade reports are available from you
  <!--@formatter:on (markdown rendering breaks, when the following tags are formatted)-->
</cms-info>
<p-messages [(value)]="messages" [closable]="false"></p-messages>
<div class="decent-max-width">

  <cms-emir-reconciliation-report-upload-panel *ngIf="uploadPanelVisible"></cms-emir-reconciliation-report-upload-panel>

  <p-dialog header="Repeat Reconciliation Run" id="rerun-dialog" [visible]="(rerunState | async) != 'START'" [modal]="true"[style]="{width:'300px'}" (onHide)="closeRerunDialog()" (onShow)="showRerunDialog()">
    <ng-container #pnl *ngIf="(rerunState | async) == 'RERUN' || (rerunState | async) == 'EXECUTING'">
      <div class="row col-12 decent-max-width">
        <div class="col-12  padding-0">
          Press Execute to re-calculate reconciliation results per as of date {{asOfDate}}
        </div>
      </div>
    </ng-container>
    <ng-container #pnl *ngIf="(rerunState | async) == 'END'">
      <div class="row col-12 decent-max-width" *ngIf="hasErrorMessage()">
        <div class="col-12  padding-0">
          <i class="fa fa-close" style="color: #D31245; font-size: 21px;"></i> {{serviceErrorMessage}}
        </div>
      </div>
      <div class="row col-12 decent-max-width" *ngIf="!hasErrorMessage()">
        <div class="col-12  padding-0">
          <i class="fa fa-check" style="color: #23c180; font-size: 21px;"></i> Reconciliation completed.
        </div>
      </div>
    </ng-container>
    <p-footer>
      <button type="button" id="btn-emir-report-rerun-cancel" class="margin-3" pButton label="Cancel" (click)="closeRerunDialog()" *ngIf="(rerunState | async) == 'RERUN'"></button>
      <button type="button" id="btn-emir-report-rerun-execute" class="margin-3" pButton label="Execute" (click)="executeRerun()" *ngIf="(rerunState | async) == 'RERUN'"></button>
      <button type="button" id="btn-emir-report-rerun-close" class="margin-3" pButton label="Close" (click)="closeRerunDialog()" *ngIf="(rerunState | async) == 'END'"></button>
      <p-progressSpinner class="margin-top-9" *ngIf="(rerunState | async) == 'EXECUTING'" [style]="{width: '15px', height: '15px'}" strokeWidth="8"></p-progressSpinner>
    </p-footer>
  </p-dialog>

  <p-dialog header="Additional Information" [(visible)]="isOverlayPanelVisible" [modal]="isBackgroundBlocked" [style]="{width: '300px'}">
    <table>
      <tr>
        <td>Version:</td>
        <td style="padding-left: 20px">{{version}}</td>
      </tr>
      <tr>
        <td>Executed by:</td>
        <td style="padding-left: 20px">{{executedBy}}</td>
      </tr>
      <tr>
        <td>Execution time [s]:</td>
        <td style="padding-left: 20px">{{executionDurationInSec}}</td>
      </tr>
    </table>
    <p-footer>
      <button type="button" id="cancelAddInfoBtn" pButton label="Cancel" (click)="closeInfo()"></button>
    </p-footer>
  </p-dialog>
</div>
<div style="max-width: 925px;">
  <p-table [value]="rows | async" selectionMode="single" [autoLayout]="true"  [(selection)]="selection" [loading]="loading">
    <ng-template pTemplate="header">
      <tr>
        <ng-container *ngFor="let col of columns">
          <th [style]="col.style" *ngIf="col.name == 'rerun' && rerunAllowed">
            {{col.title}}
          </th>
          <th [style]="col.style" *ngIf="col.name != 'rerun'">
            {{col.title}}
          </th>
        </ng-container>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-row let-index="rowIndex">
      <tr [pSelectableRow]="row">
        <ng-container *ngFor="let col of columns">
          <td *ngIf="col.name == 'rerun' && rerunAllowed" [style]="col.style">
            <div class="btn-wrapper">
              <button class="w-init btn btn-icon fa fa-repeat" type="button" [id]="'btn-emir-report-rerun-' + row.id" (click)="showRepeatReconciliation(row)" title="Rerun"></button>
            </div>
          </td>
          <td *ngIf="col.name == 'result'" [ngStyle]="col.style">
            <div class="btn-wrapper">
              <button type="button" [id]="'btn-emir-report-excel-download-' + index" class="w-init btn btn-icon fa fa-file margin-right-6" title="Excel download" (click)="loadExcelExport(row.id)"></button>
              <button  class="btn btn-icon fa fa-info-circle" type="button" [id]="'btn-emir-report-additional-info-' + index" title="Additional Information" (click)="showAdditionalInformations(row)"></button>
            </div>
          </td>
          <td *ngIf="col.name != 'result' && col.name != 'rerun'" [ngStyle]="col.style">
            <span class="truncated-text">{{row[col.name]}}</span>
          </td>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>


</div>

