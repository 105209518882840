import { createAction, union } from '@ngrx/store';
import {
  AddingInvoiceTypeRequest,
  AddingInvoiceTypeResponseValue,
  PresetValues
} from '../../../../settings/invoice/invoice-settings.model';

export const loadPresetValuesAction = createAction('[ESM INVOICE SETTINGS] Load Preset Values');
export const loadPresetValuesSuccessAction = createAction('[ESM INVOICE SETTINGS] Load Preset Values Success', (payload: PresetValues) => ({ payload }));
export const addInvoiceTypeAction = createAction('[ESM INVOICE SETTINGS] Add Invoice Type', (payload: AddingInvoiceTypeRequest) => ({ payload }));
export const addInvoiceTypeSuccessAction = createAction('[ESM INVOICE SETTINGS] Add Invoice Type Success', (payload: AddingInvoiceTypeResponseValue) => ({ payload }));
export const cleanAddProductAction = createAction('[ESM INVOICE SETTINGS] Clean Add Products');


export type EsmSettingsAddPanelActions = | typeof loadPresetValuesAction
  | typeof loadPresetValuesSuccessAction
  | typeof addInvoiceTypeAction
  | typeof addInvoiceTypeSuccessAction
  | typeof cleanAddProductAction;

