import { createAction, props } from '@ngrx/store';
import {
  DocumentSide,
  DocumentType,
  SubscriptionData,
} from '../../../settings/subscription/email-subscription.model';
import { Message } from 'primeng/api';

export const loadData =  createAction('[ESM SUBSCRIPTION SETTINGS] load subscription data');
export const loadDataSuccess = createAction('[ESM SUBSCRIPTION SETTINGS] load subscription data success', (payload: SubscriptionData) => ({ payload }));
export const setMatchedInvoicePdf = createAction('[ESM SUBSCRIPTION SETTINGS] set matched invoice PDF flag', (payload: boolean) => ({ payload }));
export const setMatchedNettingPdf = createAction('[ESM SUBSCRIPTION SETTINGS] set matched netting statement PDF flag', (payload: boolean) => ({ payload }));
export const setMatchedInvoiceXml = createAction('[ESM SUBSCRIPTION SETTINGS] set matched invoice XML flag', (payload: boolean) => ({ payload }));
export const setMatchedNettingXml = createAction('[ESM SUBSCRIPTION SETTINGS] set matched netting statement XML flag', (payload: boolean) => ({ payload }));
export const setDocumentType = createAction('[ESM SUBSCRIPTION SETTINGS] set document type', (payload: DocumentType) => ({ payload }));
export const setDocumentSide = createAction('[ESM SUBSCRIPTION SETTINGS] set document side', (payload: DocumentSide) => ({ payload }));
export const setToDefaultAction = createAction('[ESM SUBSCRIPTION SETTINGS] Set to default');

export const setMessages = createAction('[ESM SUBSCRIPTION SETTINGS] set messages', props<{ messages: Message[] }>());

export type SubscriptionSettingsAction =
  | typeof  loadData
  | typeof  loadDataSuccess
  | typeof  setMatchedInvoicePdf
  | typeof  setMatchedNettingPdf
  | typeof  setMatchedInvoiceXml
  | typeof  setMatchedNettingXml
  | typeof  setDocumentType
  | typeof  setDocumentSide
  | typeof  setToDefaultAction;

