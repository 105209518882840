import { Injectable } from '@angular/core';
import { ApiRequestService } from '../../../../common/api-request.service';
import { PagingFilter, SortingFilter } from '../../../../common/shared/results.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { RemitOmpWhitelistImportResponseModel } from './remit-omp-whitelist.model';
import { map } from 'rxjs/operators';
import { Message } from 'primeng/api';

@Injectable()
export class RemitOmpWhitelistService {

  private api_route: string = '/api/err/remit/omp/whitelist';

  private message = new BehaviorSubject<Message>(null);
  private searchQuery = new BehaviorSubject<RemitOmpWhitelistSearchQuery>(null);
  private refresh = new BehaviorSubject<boolean>(false);
  private presetValues$ = new BehaviorSubject<{uploadPanelVisible:boolean}>({uploadPanelVisible:false});

  constructor(private apiRequestService: ApiRequestService) {
  }

  public uploadFile(file: File) {
    if (this.isFileValid(file)) {
      const formData = new FormData();
      formData.append('file', file);

      this.apiRequestService.post('/api/err/remit/omp/whitelist/importCsv', formData, null)
        .subscribe(response => {
          const responseModel = response as RemitOmpWhitelistImportResponseModel;
          if (responseModel.validationFailureMessages) {
            const tempMessage: Message = {severity: 'error',
              summary: '',
              detail: '',
              id: 1,
              key: 'key',
              life: 1,
              sticky: true,
              closable: false,
              data: 0};
            for (let i = 0; i < responseModel.validationFailureMessages.length; i++) {
              if (i === 0) {
                tempMessage.summary = response.validationFailureMessages[i];
              } else {
                tempMessage.detail += response.validationFailureMessages[i] + ' ';
              }
            }
            this.setMessage(tempMessage);
          } else {
            const successMessage = responseModel.numbersProcessed + ' records uploaded successfully. ' + responseModel.numbersReplaced + ' records replaced.';
            this.setMessage({ severity: 'success', summary: 'Success', detail: successMessage, key: 'key' });
            this.refresh.next(true);
          }
        }, error => {
          this.setMessage({ severity: 'error', summary: 'Error', detail: error.message, key: 'key' });
        });
    }
  }

  public onRefresh(): Observable<boolean> {
    return this.refresh.asObservable();
  }

  loadPresetValues(): void {
    this.apiRequestService.get(this.api_route + '/loadPresetValues').pipe(
      map((response: any) => response.value)).subscribe(value => {
      this.presetValues$.next(value);
    });
  }

  onPresetValuesChanged(): Observable<{uploadPanelVisible:boolean}>{
    return this.presetValues$.asObservable();
  }

  setPresetValues(presetValues: {uploadPanelVisible:boolean}) {
    return this.presetValues$.next(presetValues);
  }

  isFileValid(file: File): boolean {
    this.setMessage(null);
    let valid: boolean = true;
    if (file) {
      const sizeInMB = parseFloat(((file.size / 1000) / 1024).toFixed(2));

      if (sizeInMB > 1) {
        valid = false;
        this.setMessage({ severity: 'error', summary: 'Error', detail: 'File must not exceed 1.0 MB.', key: 'key' });
      }

      if (!file.name.endsWith('.csv')) {
        valid = false;
        this.setMessage({ severity: 'error', summary: 'Error', detail: 'Please choose a .csv file.', key: 'key'});
      }
    }
    return valid;
  }

  public setSearchQuery(searchQuery: RemitOmpWhitelistSearchQuery) {
    this.searchQuery.next(searchQuery);
  }

  getSearchQuery(): RemitOmpWhitelistSearchQuery {
    return this.searchQuery.getValue();
  }

  public onSearchQueryChange(): Observable<RemitOmpWhitelistSearchQuery> {
    return this.searchQuery.asObservable();
  }

  search(searchQuery: RemitOmpWhitelistSearchQuery): Observable<any> {
    this.refresh.next(false);
    return this.apiRequestService.post(this.api_route + '/search', searchQuery, null);
  }

  public setMessage(message: Message) {
    this.message.next(message);
  }

  public onMessageChange(): Observable<Message> {
    return this.message.asObservable();
  }

  public refreshSite() {
    this.refresh.next(true);
  }

}

export class RemitOmpWhitelistSearchQuery {

  filterValue?: string;
  sorting: SortingFilter;
  paging: PagingFilter;

  public static isNotEmptySearchQuery(query: RemitOmpWhitelistSearchQuery): boolean {
    return query != null && JSON.stringify(query) !== JSON.stringify(RemitOmpWhitelistSearchQuery.createEmptySearchQuery());
  }

  public static createEmptySearchQuery(): RemitOmpWhitelistSearchQuery {
    return {
      paging: {
        entriesPerPage: 25,
        page: 0
      },
      sorting: {
        columnName: 'mpCode',
        sortOrder: 'ASCENDING',
      }
    };
  }

}
