<div class="row margin-0 table-caption">
  <div class="col text-end pe-0">
    <button class="btn" pButton type="button" label="Multi Agree" *ngIf="showMultiAgreeButton"
            [disabled]="multiAgreeDisable" (click)="onOpenAgreeDialog()"></button>
    <button class="btn" pButton type="button" label="Terminate Deal(s)" *ngIf="showTerminationButton"
            [disabled]="terminateDisable" (click)="onOpenTerminateDialog()"></button>
    <button class="btn" pButton type="button" icon="fa fa-commenting-o" label="Add Note"
            (click)="onOpenAddNoteDialog()"
            [disabled]="addNoteDisable"></button>
    <button *ngIf="showCustomise" class="btn" pButton type="button" icon="fa fa-cog" label="Customize"
            (click)="onClickCustomize()"></button>
    <button class="btn export-btn" pButton type="button" icon="fa fa-download" label="Export"
            (click)="onExport()"
            [disabled]="exportDisable"></button>
  </div>
</div>
