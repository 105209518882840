import { createSelector } from '@ngrx/store';
import { getEcmState, State } from '../../reducers';
import * as fromReducer from './broker-restrictions.reducers';

export const getSettingsBrokerState = createSelector(getEcmState, (state: State) => state.settingsBrokerRestrictions);

export const getData = createSelector(getSettingsBrokerState, fromReducer.getData);
export const getLoading = createSelector(getSettingsBrokerState, fromReducer.getLoading);
export const getMessages = createSelector(getSettingsBrokerState, fromReducer.getMessages);

export const getMaxUploadSize = createSelector(getSettingsBrokerState, fromReducer.getMaxUploadSize);
export const getAllowedToUpload = createSelector(getSettingsBrokerState, fromReducer.getAllowedToUpload);
export const getDialog = createSelector(getSettingsBrokerState, fromReducer.getDialog);
