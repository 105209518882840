<p-dialog *ngIf="data && data?.state === 'ACTIVE' && display"
          header="Reset Password"
          [(visible)]="display"
          [modal]="true"
          (visibleChange)="onVisibleChange($event)"
          [style]="{width: '600px'}">
  <p-messages [value]="messages" [closable]="true"></p-messages>
  <form [formGroup]="form" class="row">
    <div class="col-12 margin-bottom-21">
      <p-panel header="Password Policy Requirements" [toggleable]="true">
        New password must have <strong>between 8 to 16</strong> characters.<br />
        It must contain at least<br />
        <strong>one lower-case letter</strong>: a-z<br />
        <strong>one upper-case letter</strong>: A-Z<br />
        <strong>one number or special character</strong>: 0-9 $ ! + - _ . ,<br />
        Only the above characters are allowed.<br />
        New password must be different from your last 3 passwords.
      </p-panel>
    </div>
    <div class="col-12 margin-bottom-12">
      <label class="col-5 float-left">User Name:</label>
      <span class="col-7 padding-6">{{data?.userName}}</span>
    </div>
    <div class="col-12 margin-bottom-12">
      <label class="col-5 float-left">Lock State:</label>
      <span class="col-7 padding-6">{{data?.state !== 'LOCKED' ? 'not locked' : ''}}</span>
    </div>
    <div class="col-12 margin-bottom-12">
      <label class="col-5 float-left">New Password:
        <span class="text-required">*</span>
      </label>
      <input [ngClass]="{'newPassword': this.errors['comment']}"  type="password" formControlName="newPassword" class="form-control col-7 padding-6"/>
    </div>
    <div class="col-12 margin-bottom-12">
      <label class="col-5 float-left">New Password (repeat):
        <span class="text-required">*</span>
      </label>
      <input [ngClass]="{'newPasswordRepeat': this.errors['comment']}" type="password" formControlName="newPasswordRepeat" class="form-control col-7 padding-6"/>
    </div>
  </form>
  <p-footer>
    <button class="btn margin-right-3" type="button" (click)="onCancel()">Cancel</button>
    <button class="btn" type="button" (click)="onConfirm()">Reset Password</button>
  </p-footer>
</p-dialog>
