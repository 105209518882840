import { ErrorResponse } from '@common/error-response.model';
import { PagingFilter, SortingFilter } from '@common/shared/results.model';

export interface ElcomWhitelistValues {
  acerCode: string;
  extParty: number;
  lei: string;
  leiCountry: string;
  leiRegName: string;
  orgEic: string;
  orgGroupName: string;
  orgName: string;
}

export interface ElcomWhitelistResponse extends ErrorResponse {
  values: ElcomWhitelistValues[];
  count: number;
  currentPage: number;
}

export interface ElcomWhitelistFilters {
  filter?: string;
}

export interface ElcomWhitelistRequest extends ElcomWhitelistFilters {
  paging?: PagingFilter;
  sorting?: SortingFilter;
}

export const defaultStyles = {
  'text-align': 'left',
  'text-overflow': 'ellipsis',
  'overflow': 'hidden',
  'white-space': 'nowrap'
};
