<cms-info ngPreserveWhitespaces>

  Users can opt in to receive mail alerts sent from CMS.

  Global Mail Alert Settings allows UA accounts to opt in for mail alerts when the state of another user’s account changes. In the Global Mail Alert Settings screen the UA can view:
  * User Name – Name of the user as it appears on CMS (also their log in name).
  * Real Name – Real name of the user.
  * User State – Displaying one of three states; Active, Inactive and Locked. These can be changed by the UA within the Administration section (See section User Accounts).
  * User State Changes – This shows whether the selected user will receive email alerts relating to state changes for other users within their Organisation e.g. when a user account has been locked.

  eCM Mail Alert Settings allow eCM MO accounts to opt in for mail alerts when eCM documents are invalid. In the eCM Mail Alert Settings screen the MO can view:
  * User Name – Name of the user as it appears on CMS (also their log in name).
  * Real Name– Real name of the user.
  * User State – Displaying one of three states; Active, Inactive and Locked. These can be changed by the UA within the administration section.
  * Invalid Documents (MO only) – Validation failure on received eCM confirmation.

</cms-info>

<p-tabView (onChange)="onChangeTab($event)" *ngIf="tabsVisibility" [activeIndex]="activeIndex">
  <p-tabPanel header="Global" *ngIf="activeIndex!== null && tabsVisibility.global" [selected]="activeIndex === 0">
    <div class="tab-view-body">
      <div class="tab-view-body">
        <cms-global></cms-global>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="eCM" *ngIf="activeIndex!== null && tabsVisibility.eCM" [selected]="activeIndex === 1">
    <div class="tab-view-body">
      <div class="tab-view-body">
        <cms-ecm></cms-ecm>
      </div>
    </div>
  </p-tabPanel>
</p-tabView>
