import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Message } from 'primeng/api';
import { DialogState } from '../../../state/dialog/dialog.reducer';
import { LockInactivateUser } from './lock-inactivate-dialog/lock-inactivate-dialog.component';
import { FhaAccount } from '../../fha-accounts/fha-accounts.model';
import { UserAccount } from '../../user-accounts/user-accounts.model';
import { EditableState } from '../editable-status-cell/editable-status-cell.component';
import { ReactivateUser } from './activate-dialog/activate-dialog.component';
import { EditUserAccount } from './edit-user-account-dialog/edit-user-account-dialog.component';
import { AddUserAccount } from './add-user-account-dialog/add-user-account-dialog.component';
import { ResetPassword } from './reset-password-dialog/reset-password-dialog.component';

@Component({
  selector: 'cms-dialog-container',
  templateUrl: './dialogs-container.component.html',
  styleUrls: ['./dialogs-container.component.scss']
})
export class DialogsContainerComponent {
  @Input() dialog: DialogState;
  @Input() editableData: EditableState;
  @Input() organisationGroupId: string;
  @Input() selected: UserAccount | FhaAccount;
  @Input() tokenIdVisible: boolean;
  @Output() cancel = new EventEmitter<Message[]>();
  @Output() reactivate = new EventEmitter<ReactivateUser>();
  @Output() inactivate = new EventEmitter<LockInactivateUser>();
  @Output() lock = new EventEmitter<LockInactivateUser>();
  @Output() edit = new EventEmitter<EditUserAccount>();
  @Output() reset = new EventEmitter<ResetPassword>();
  @Output() add = new EventEmitter<AddUserAccount>();

  onCancel(messages: Message[]): void {
    this.cancel.emit(messages);
    this.dialog = null;
  }

  onConfirmInactivate(obj: LockInactivateUser): void {
    this.inactivate.emit(obj);
  }

  onConfirmLock(obj: LockInactivateUser): void {
    this.lock.emit(obj);
  }

  onConfirmReactivate(obj: ReactivateUser): void {
    this.reactivate.emit(obj);
  }

  onConfirmEditUserAccount(obj: EditUserAccount): void {
    this.edit.emit(obj);
  }

  onConfirmResetPassword(obj: ResetPassword): void {
    this.reset.emit(obj);
  }

  onConfirmAddUser(obj: AddUserAccount): void {
    this.add.emit(obj);
  }
}
