<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>

<h1>Remit Transactions</h1>
<div class="margin-bottom-15">
  <p-messages [value]="errorMessages$ | async" [closable]="true" class="block"></p-messages>

  <cms-remit-transactions-panel (onInitialDocType)="setInitialTabDocType($event)" (onTabChange)="resetTableData($event)"></cms-remit-transactions-panel>
</div>
<cms-remit-datatable></cms-remit-datatable>

</ng-template>
