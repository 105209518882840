import { InjectionToken } from '@angular/core';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromDealFinder from '../deal-finder/deal-finder.reducers';
import * as fromReports from '../reports/reports.reducers';
import * as fromSettingsProcess from '../settings/process/process.reducers';
import * as fromSettingsBroker from '../settings/broker-restrictions/broker-restrictions.reducers';
import * as fromSettingsProducts from '../settings/products/products.reducers';
import * as fromDashboard from '../dashboard/dashboard.reducers';
import * as fromEnterTrade from '../enter-trade/enter-trade.reducers';
import * as fromProducts from '../products/ecm-products.reducers';
import * as fromMonitoring from '../monitoring/monitoring.reducers';

export const getEcmState = createFeatureSelector<State>('ecm');

export interface State {
  dealFinder: fromDealFinder.EcmDealFinderState;
  reports: fromReports.ReportsState;
  settingsProcess: fromSettingsProcess.EcmSettingsProcessState;
  settingsProducts: fromSettingsProducts.ProductsState;
  settingsBrokerRestrictions: fromSettingsBroker.EcmSettingsBrokerRestrictionsState;
  dashboard: fromDashboard.EcmDashboardState;
  enterTrade: fromEnterTrade.EcmEnterTradeState;
  products: fromProducts.EcmProductsState;
  monitoring: fromMonitoring.MonitoringState;
}

export const reducers: ActionReducerMap<State> = {
  dealFinder: fromDealFinder.ecmDealFinderReducer,
  reports: fromReports.ecmReportsReducer,
  settingsProcess: fromSettingsProcess.ecmSettingsProcessReducer,
  settingsProducts: fromSettingsProducts.ecmSettingsProductsReducer,
  settingsBrokerRestrictions: fromSettingsBroker.ecmSettingsBrokerRestrictionsReducer,
  dashboard: fromDashboard.ecmDashboardReducer,
  enterTrade: fromEnterTrade.ecmEnterTradeReducer,
  products: fromProducts.ecmProductsReducer,
  monitoring: fromMonitoring.ecmMonitoringReducer
};

export const reducerToken = new InjectionToken<ActionReducerMap<State>>(
  'ECM Registered Reducers'
);

export const getReducers = () => reducers;
