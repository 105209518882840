import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';
import { RemitTradeEntryTable1Service } from '../remit-trade-entry-table1.service';
import { RemitTradeEntryTable1DataModel, RemitTradeEntryTable1ReportingCounterpartyModel } from '../remit-trade-entry-table1-data.model';

@Component({
  selector: 'cms-remit-trade-entry-table1-reporting-counterparty',
  templateUrl: './remit-trade-entry-table1-reporting-counterparty.component.html',
  styleUrls: ['./remit-trade-entry-table1-reporting-counterparty.component.scss']
})
export class RemitTradeEntryTable1ReportingCounterpartyComponent implements OnInit, OnDestroy {
  @Input() formGroup: UntypedFormGroup;

  reportingCounterpartyData: RemitTradeEntryTable1ReportingCounterpartyModel;
  marketParticipants: SelectItem[];
  buySell: SelectItem[];
  beneficiaryType: SelectItem[];
  execution: boolean = false;

  private onDestroy$ = new Subject();

  constructor(private tradeEntryService: RemitTradeEntryTable1Service) { }

  ngOnInit() {
    this.formGroup.addControl('marketParticipantCode', new UntypedFormControl(''));
    this.formGroup.addControl('traderId', new UntypedFormControl(''));
    this.formGroup.addControl('buyOrSell', new UntypedFormControl(''));
    this.formGroup.addControl('beneficiaryCodeType', new UntypedFormControl('ACE'));
    this.formGroup.addControl('beneficiaryCode', new UntypedFormControl(''));

    this.tradeEntryService.onTradeEntryDataChange().pipe(takeUntil(this.onDestroy$))
      .subscribe((tradeEntryData: RemitTradeEntryTable1DataModel) => {
        if (tradeEntryData && tradeEntryData.reportingCounterparty) {
          this.formGroup.patchValue({
            marketParticipantCode: tradeEntryData.marketParticipant
          });
          this.reportingCounterpartyData = tradeEntryData.reportingCounterparty;
          this.initMarketParticipants();
          this.initBuySell();
          this.initBeneficiaryType();
        }
      });

    // toggle traderID required
    this.formGroup.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(formValues => {
      if (this.execution !== formValues.execution) {
        this.execution = formValues.execution;
      }
    });
  }

  getValidationErrorStyleClass(fieldName: string): string {
    if (this.formGroup.controls[fieldName].hasError('error')) {
      return 'error';
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  private initMarketParticipants() {
    if (this.reportingCounterpartyData) { // TODO CMSGUI-443 mr2 2018-06: is it allowed to be null? if not delete the check?
      this.marketParticipants = this.reportingCounterpartyData.marketParticipants.map(item => (
        { label: item.value, value: item.key }// TODO CMSGUI-443 mr2 2018-06: align KeyValuePairModel (java) with SelectItem (ng) to that no mapping is needed
      ));
    }
  }

  private initBuySell() {
    if (this.reportingCounterpartyData) {
      this.buySell = this.reportingCounterpartyData.buySell.map(item => (
        { label: item.value, value: item.key }
      ));
    }
  }

  private initBeneficiaryType() {
    if (this.reportingCounterpartyData) {
      this.beneficiaryType = this.reportingCounterpartyData.beneficiaryType.map(item => (
        { label: item.key, value: item.key }
      ));
    }
  }
}
