import { Message } from 'primeng/api';
import { Prefix } from '../reducers';
import { UserAccountActions } from '../user-account/user-account.actions';
import * as actions from './dialog.actions';
import { SET_TO_DEFAULT } from '../shared/shared.actions';

export type DIALOGS = 'ADD' | 'EDIT' | 'RESET' | 'STATE' | 'DELETE' | 'MOVE' |
  'EDIT_ECM' | 'EDIT_ERR' | 'EDIT_BILLING_DATA' | 'EDIT_ESM' | 'ESM_FLAG' | string;

export interface DialogState {
  name: DIALOGS;
  messages?: Message[];
  data?: any;
}

export function dialogReducer(prefix: Prefix, initialState = null) {
  return function (state: DialogState = initialState,
                   action: UserAccountActions): DialogState {
    switch (action.type) {
      case `${prefix} ${actions.OPEN_DIALOG}`:
        return {
          ...(action as actions.OpenDialogAction).payload
        };
      case `${prefix} ${actions.CLOSE_DIALOG}`:
        return null;
      case `${prefix} ${actions.UPDATE_DIALOG}`:
        return {
          ...state,
          messages: (action as actions.UpdateDialogAction).payload
        };
      case `${prefix} ${SET_TO_DEFAULT}`:
        return null;
      default:
        return state;
    }
  };
}
