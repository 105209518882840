<form [formGroup]="viewByDateTypeFormGroup">
  <fieldset>
    <legend class="!font-bold" style="font-size: 14px">View by</legend>
    <div class="row ">
      <div class="col-12">
        <label>
          <input type="radio" name="viewByDateType" value="TRADE_DATE" formControlName="viewByDateType" class="align-middle">
          Trade Date
        </label>
      </div>
      <div class="col-12">
        <label>
          <input type="radio" name="viewByDateType" value="SUBMISSION_DATE" formControlName="viewByDateType" class="align-middle">
          Submission Date
        </label>
      </div>
    </div>
  </fieldset>
</form>
