import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as NttActions from './netting-table.actions';
import {catchError, switchMap, withLatestFrom} from 'rxjs/operators';
import { Action, select, Store } from '@ngrx/store';
import * as NttSelectors from './netting-table.selectors';
import * as CpSelectors from '../counterparty/counterparty.selectors';

import { Injectable } from '@angular/core';
import { ApiRequestService } from '@common/api-request.service';
import { NettingTableState } from './netting-table.reducers';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { Message } from 'primeng/api';
import {  Observable } from 'rxjs';

import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import { CounterPartyState } from '../counterparty/counterparty.reducers';


@Injectable()
export class NettingPreviewEffects {
  private baseUrl = '/api/esm/netting/preview';

  constructor(private apiGateway: ApiRequestService,
              private actions$: Actions,
              private store$: Store<NettingTableState>,
              private store2: Store<CounterPartyState>,
              private mapper: MessagesMapperService) {
  }


  load_table$ = createEffect(() =>
    this.actions$.pipe(ofType(NttActions.loadTable))
      .pipe(
      withLatestFrom(
        this.store2.pipe(select(CpSelectors.getSelected)),
        this.store$.pipe(select(NttSelectors.getDiscrepancy)),
        this.store$.pipe(select(NttSelectors.getPaging)),
        this.store$.pipe(select(NttSelectors.getSorting))),
      switchMap(([, selectedCounterPartyOrgIds, discrepancy, paging, sorting]) => this.loadData(selectedCounterPartyOrgIds, paging, sorting, discrepancy))));


  // ======  helper ====== //

  private loadData(counterPartyOrgIds: number[], paging: PagingFilter, sorting: SortingFilter, discrepancy: boolean): Observable<Action> {
    this.store$.dispatch(NttActions.loading({ loading: true }));
    return this.apiGateway.post(`${this.baseUrl}/data`, { counterPartyOrgIds, sorting, paging ,'discrepanciesOnly' : discrepancy})
      .pipe(
        switchMap(response => {
          const messages: Message[] = this.mapper.toErrorMessages(response);
          const { values, currentPage, count } = response;

          values.forEach((value, index) => value.id = index);

          return messages?.length ?
            [NttActions.setMessages({ messages })]:
            [NttActions.setMessages({ messages: [] }), NttActions.querySuccess({ data: { values, count, currentPage } })];
        }),

        catchError(error => [NttActions.loading({ loading: false }),
          NttActions.setMessages(this.mapper.toErrorMessagesObj(error.errorMessage || 'Server Error'))]));
  }
}
