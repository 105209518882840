import { Injectable } from '@angular/core';
import { ApiRequestService } from '../../../../../common/api-request.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { RemitTradeEntryTable2DataModel } from '../../../trade-entry/table2/remit-trade-entry-table2-data.model';
import { RemitTable2TradeEntryModel } from './remit-table2-trade-entry.model';
import { HttpParams } from '@angular/common/http';
import { Message } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class RemitTradeEntryTable2ModifyService {

  apiPath: string = '/api/err/remit/lite/remitTable2/';
  tradeEntryData = new BehaviorSubject<RemitTradeEntryTable2DataModel>(null);

  tradeData = new BehaviorSubject<RemitTable2TradeEntryModel>(null);

  private message = new BehaviorSubject<Message>(null);

  constructor(private apiRequestService: ApiRequestService) { }

  loadTrade(historyId: string): void {

    const params: HttpParams = new HttpParams().append('historyId', historyId);

    this.apiRequestService.get(this.apiPath + 'loadPresetsModify', params).subscribe(response => {
      if(response.validationFailureMessages){
        this.handleFailureMessages(response.validationFailureMessages);
      }

      this.tradeData.next(response);
    });
  }

  handleFailureMessages(messages: string[]){
    for (const message of messages) {
      this.message.next({ severity: 'error', summary: message });
    }
  }

  reset(){
    this.message.next( null);
  }

  public onMessageChange(): Observable<Message> {
    return this.message.asObservable();
  }

  onTradeLoaded(): Observable<RemitTable2TradeEntryModel> {
    return this.tradeData.asObservable();
  }

}
