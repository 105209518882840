import { createSelector } from '@ngrx/store';
import { getProblemSolvingState, State } from '../reducers';
import * as fromReducer from './exceptions.reducers';

export const getExceptionsState = createSelector(getProblemSolvingState, (state: State) => state.exceptions);
export const getOrganisationId = createSelector(getExceptionsState, (state: fromReducer.ExceptionsState) => state.organisationId);

export const getData = createSelector(getExceptionsState, fromReducer.getData);
export const getMessages = createSelector(getExceptionsState, fromReducer.getMessages);
export const getLoading = createSelector(getExceptionsState, fromReducer.getLoading);
export const getDisabled = createSelector(getExceptionsState, fromReducer.getDisabled);
export const getAvalableQueues = createSelector(getExceptionsState, fromReducer.getAvalableQueues);
export const getFilters = createSelector(getExceptionsState, fromReducer.getFilters);
export const getDialog = createSelector(getExceptionsState, fromReducer.getDialog);
export const getSelected = createSelector(getExceptionsState, fromReducer.getSelected);
export const getEditedItems = createSelector(getExceptionsState, fromReducer.getEditedItems);
export const getPaging = createSelector(getExceptionsState, fromReducer.getPaging);
