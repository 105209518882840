<p-fieldset id="ntt_overview_main">

  <p-header>{{selectedItem?.title || 'Netting Statement Preview with due date'}}</p-header>

  <div class="flex mb-0 pb-0">

    <address id="payer" class="w-25">
      <span class="text-bold mb-2 mt-3 text-large">Payer</span>
      <div class="ms-3 mt-3">
        {{selectedItem?.payer.name}}
        <br class="mb-1">
        {{selectedItem?.payer.streetAndNumber}}
        <br class="mb-1">
        {{selectedItem?.payer.postalAndCity}}
        <br class="mb-1">
        {{ selectedItem?.payer.country}}
        <br class="mb-1">
        <span *ngIf="selectedItem?.payer.vatCode">VAT-Code:</span> {{selectedItem?.payer.vatCode}}
        <div class="mb-4"></div> <!--chrome fix -->
        <span *ngIf="selectedItem?.payer.contact || selectedItem?.payer.email">Contact:</span> {{selectedItem?.payer.contact}}
        <br class="mb-1" *ngIf="selectedItem?.payer.email && selectedItem?.payer.contact">
        <span [ngClass]="{'mar-left':selectedItem?.payer.email && selectedItem?.payer.contact}">{{selectedItem?.payer.email}}</span>
        <br class="mb-2">
        <span *ngIf="selectedItem?.payer.phone">Phone:</span> {{ selectedItem?.payer.phone}}
      </div>
    </address>
    <address id="receiver" class="mb-0 w-25">
      <span class="text-bold mb-2 mt-3 text-large">Receiver</span>
      <div class="ms-3 mt-3">
        {{selectedItem?.receiver.name}}
        <br class="mb-1">
        {{selectedItem?.receiver.streetAndNumber}}
        <br class="mb-1">
        {{selectedItem?.receiver.postalAndCity}}
        <br class="mb-1">
        {{selectedItem?.receiver.country}}
        <br class="mb-1">
        <span *ngIf="selectedItem?.receiver.vatCode">VAT-Code:</span> {{selectedItem?.receiver.vatCode}}
        <div class="mb-4"></div> <!--chrome fix -->
        <span *ngIf="selectedItem?.receiver.contact || selectedItem?.receiver.email">Contact:</span> {{selectedItem?.receiver.contact}}
        <br class="mb-1" *ngIf="selectedItem?.receiver.email && selectedItem?.receiver.contact">
        <span [ngClass]="{'mar-left':selectedItem?.receiver.email && selectedItem?.receiver.contact}">{{selectedItem?.receiver.email}}</span>
        <br class="mb-2">
        <span *ngIf="selectedItem?.receiver.phone">Phone:</span> {{ selectedItem?.receiver.phone}}
      </div>
    </address>
    <address class="mb-0 w-25"></address>
    <div class="mt-0 w-25">
      <div [pTooltip]="selectedItem?.genButtonToolTipText" tooltipPosition="left" [tooltipDisabled]="selectedItem?.generationButtonVisible">
        <button class="btn" [disabled]="!selectedItem?.generationButtonVisible" (click)="onOpenDialog()" id="open-generate-netting-dialog-button">
          Generate Netting Statement
        </button>
      </div>
    </div>
  </div>

  <hr class="full-width mb-0 mt-0">

  <div id="details" class="flex mb-0 mt-0">

    <address id="cc" class="flex flex-row align-items-center mb-1 w-25">
      <p class="ms-3 mt-3">
        <span *ngIf="!!selectedItem">Commodities:</span> {{ selectedItem?.details.commodities | splitspace}}
        <br>
        <span *ngIf="!!selectedItem">Currency:</span> {{ selectedItem?.details.currency }}
      </p>
    </address>

    <address id="ad" class="flex flex-row align-items-center mb-1 w-25">
      <p class="ms-3 mt-3">
        <span *ngIf="!!selectedItem" class="me-1">Agreement:</span>{{selectedItem?.details.agreement}}
        <br>
        <span *ngIf="!!selectedItem" class="me-1">Due Date:</span>{{selectedItem?.details.dueDate}}
      </p>
    </address>
  </div>

  <cms-netted-amount class="block mt-4"></cms-netted-amount>

</p-fieldset>


