import { EcmSettingsBrokerRestrictionsData } from '../../../settings/ecm-settings-tab-panel/broker-restrictions/broker-restrictions.model';
import { Message } from 'primeng/api';
import * as fromAction from './broker-restrictions.actions';

export interface EcmSettingsBrokerRestrictionsState {
  data: EcmSettingsBrokerRestrictionsData;
  messages: Message[];
  loading: boolean;
  maxUploadSizeInMb: number;
  allowedToUpload: boolean;
  showDialog: boolean;
}

export const initialState: EcmSettingsBrokerRestrictionsState = {
  data: {
    values: null,
  },
  messages: [],
  loading: false,
  maxUploadSizeInMb: 1,
  allowedToUpload: false,
  showDialog: false,
};


export function ecmSettingsBrokerRestrictionsReducer(
  state: EcmSettingsBrokerRestrictionsState = { ...initialState },
  action: fromAction.EcmSettingsBrokerRestrictionsActions) {
  switch (action.type) {
    case fromAction.LOAD_DATA_SUCCESS:
      return {
        ...state,
        data: (action as fromAction.LoadDataSuccessAction).payload
      };
    case fromAction.SET_MESSAGES:
      return {
        ...state,
        loading: false,
        messages: [...(action as fromAction.SetMessagesAction).payload],
      };
    case fromAction.LOAD_PRESET_VALUES_SUCCESS:
      const { allowedToUpload, maxUploadSizeInMb } =
        (action as fromAction.LoadPresetValuesSuccessAction).payload;
      return {
        ...state,
        allowedToUpload,
        maxUploadSizeInMb,
        loading: false,
      };
    case fromAction.SET_TO_DEFAULT:
      return { ...initialState };
    case fromAction.OPEN_DIALOG:
      return {
        ...state,
        messages: [],
        showDialog: true,
      };
    case fromAction.CLOSE_DIALOG:
      return {
        ...state,
        showDialog: false,
      };
    case fromAction.UPLOAD_FILE:
      return {
        ...state,
        file: action.payload
      };
    case fromAction.CONFIRM_DIALOG_CANCEL:
      return {
        ...state,
        file: null,
        showDialog: false,
      };
    default:
      return state;
  }
}

export const getData = (datatableState: EcmSettingsBrokerRestrictionsState) => datatableState.data;
export const getLoading = (state: EcmSettingsBrokerRestrictionsState) => state.loading;
export const getMessages = (state: EcmSettingsBrokerRestrictionsState) => state.messages;
export const getMaxUploadSize = (state: EcmSettingsBrokerRestrictionsState) => state.maxUploadSizeInMb;
export const getAllowedToUpload = (state: EcmSettingsBrokerRestrictionsState) => state.allowedToUpload;
export const getDialog = (state: EcmSettingsBrokerRestrictionsState) => state.showDialog;
