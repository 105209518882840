<form class="flex flex-row items-center" [formGroup]="form" *ngIf="groupsVisible || filterInactiveVisible">
  <div class="margin-3 padding-0 text-start">
    <p-dropdown class="padding-0 margin-3" *ngIf="groupsVisible"
                [style]="{width: '190px'}"
                [options]="orgGroupsOptions"
                [filter]="true"
                [filterFields]="['label']"
                filterMatchMode="startsWith"
                formControlName="organisationGroupId"
                (onChange)="onChange()">
    </p-dropdown>
  </div>
  <div class="margin-3 padding-0 text-start" style="padding-top: 2px">
    <!-- primeng docu refers to using formControl instead of formControlName for p-checkbox -->
    <p-checkbox class="padding-0 margin-3" *ngIf="filterInactiveVisible"
                inputId="uua_inactive_box_inp_id"
                id="uua_inactive_box"
                [formControl]="form.controls['filterInactive']"
                (onChange)="onChange()"
                [(ngModel)]="filters.filterInactive"
                [binary]="true">
    </p-checkbox>
    <!--todo: rework styling    -->
    <label class="text-start" for="uua_inactive_box" style="padding-top: 2px; margin: 3px 3px 3px 1px">
      Hide inactive users
    </label>
  </div>
</form>
