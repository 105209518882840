import { Message } from 'primeng/api';
import { EsmFile, EsmFileUploadFilters } from '../../file-upload/file-upload.model';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import * as fromActions from './file-upload.actions';
import { FileUploadActions } from './file-upload.actions';
import { createReducer, on } from '@ngrx/store';
import * as moment from 'moment';

export interface EsmFileUploadState {
  data: DatatableData<EsmFile[]>;
  messages: Message[];
  loading: boolean;
  maxUploadSizeInBytes: number;
  allowedToUpload: boolean;
  paging?: PagingFilter;
  sorting?: SortingFilter;
  filters: EsmFileUploadFilters;
  busy: boolean;
}

export const initialState: EsmFileUploadState = {
  data: {
    values: null,
  },
  messages: [],
  loading: false,
  maxUploadSizeInBytes: 0,
  allowedToUpload: false,
  paging: {
    page: 0,
    entriesPerPage: 25
  },
  sorting: {
    columnName: 'submissionDate',
    sortOrder: 'DESCENDING'
  },
  filters: null,
  busy: false,
};

export const esmFileUploadReducer = createReducer<EsmFileUploadState, FileUploadActions>(
  initialState,
  on(fromActions.sortAndPageDataAction, (state: EsmFileUploadState, {payload: { paging, sorting }}): EsmFileUploadState => ({
    ...state,
    loading: true,
    messages: [],
    paging,
    sorting
  })),
  on(fromActions.setMessagesAction, (state: EsmFileUploadState, {payload: messages}): EsmFileUploadState => ({ ...state, messages, loading: false })),
  on(fromActions.setToDefaultAction, (state: EsmFileUploadState): EsmFileUploadState => ({ ...state, ...initialState })),
  on(fromActions.setAllowedToUploadAction, (state: EsmFileUploadState, {payload: allowedToUpload}): EsmFileUploadState => ({ ...state, allowedToUpload })),
  on(fromActions.setMaxUploadSize, (state: EsmFileUploadState, {payload: maxUploadSizeInBytes}): EsmFileUploadState => ({ ...state, maxUploadSizeInBytes })),
  on(fromActions.setBusy, (state: EsmFileUploadState, {payload: busy}): EsmFileUploadState => ({ ...state, busy })),
  on(fromActions.setFilterValuesAction, (state: EsmFileUploadState, {payload: filters}): EsmFileUploadState => ({ ...state, filters })),

  on(fromActions.loadDataAction, (state: EsmFileUploadState): EsmFileUploadState => ({ ...state, loading: true })),
  on(fromActions.loadDataSuccessAction, (state: EsmFileUploadState, { payload: { currentPage, count, values } }): EsmFileUploadState => ({
    ...state,
    loading: false,
    paging: {
      ...state.paging,
      page: currentPage
    },
    data: {
      count,
      values: values.map(value => ({
        ...value,
        submissionDate: value.submissionDate ? moment(value.submissionDate).format('DD/MM/YYYY HH:mm:ss') : null
      }))
    }
  }))
);
