import { Component, ViewEncapsulation } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from './auth/authentication.service';

@Component({
  selector: 'cms-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css',
    './app.component.scss',
    '../styles/themes/equias/theme.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  title = 'app';

  constructor(private authService: AuthenticationService, private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (location.pathname === '/') {
          this.router.navigate([this.authService.loginRoute], { queryParamsHandling: 'preserve' });
        }
      }
    });

  }

}
