<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>

<h1>SMT Deal Finder</h1>
<div class="panel-box">
<cms-info ngPreserveWhitespaces>
  <!--@formatter:off (markdown rendering breaks, when the following tags are formatted)-->
  __SMT Deal Finder__

  Find trade records reported to regulators on your behalf here.

  **Search Modes**

  1. **UTI**: finds trade reports by their UTI (Unique Transaction Identifier) - you can copy and paste up to 50 IDs into the search field
  2. **Trade Date**: enter a range of transaction dates within which to search

  Note that "Trade Date" refers to the execution date of a trade. For reported lifecycle events (modifications, cancellations) the effective date of the modification.

  **Transaction List**

  Use the **document icons** in each row to display all reported fields or download the electronic document as it was reported.
  Use the **Export** button to download the displayed results as an Excel sheet.

  **Further Information**

  Please refer to the help text on screen "SMT Dashboard" for additional information about reported transactions.
  <!--@formatter:on-->
</cms-info>

<cms-smt-dealfinder-filter></cms-smt-dealfinder-filter>
<cms-smt-datatable></cms-smt-datatable>
</div>

</ng-template>
