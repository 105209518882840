import { Action } from '@ngrx/store';
import { Message } from 'primeng/api';
import { Column } from '@common/shared/datatable.model';
import { EcmBucketsTableRows } from '../../../ecm-dashboard/ecm-dashboard.model';

export const SET_MESSAGES = '[ECM DASHBOARD] Set Messages';
export const LOAD_MY_FILTERS = '[ECM DASHBOARD] Load My Filters';
export const GET_BUCKETS = '[ECM DASHBOARD] Get Buckets';
export const GET_BUCKETS_SUCCESS = '[ECM DASHBOARD] Get Buckets Success';
export const SET_COLUMNS = '[ECM DASHBOARD] Set Columns';

export class SetMessagesAction implements Action {
  readonly type = SET_MESSAGES;

  constructor(public payload: Message[]) { }
}

export class LoadMyFiltersAction implements Action {
  readonly type = LOAD_MY_FILTERS;
}

export class GetBucketsAction implements Action {
  readonly type = GET_BUCKETS;
}

export class GetBucketsSuccessAction implements Action {
  readonly type = GET_BUCKETS_SUCCESS;

  constructor(public payload: { rows: EcmBucketsTableRows[], stateNames: string[] }) { }
}

export class SetColumnsAction implements Action {
  readonly type = SET_COLUMNS;

  constructor(public payload: Column[]) { }
}

export type TabActions = SetMessagesAction | GetBucketsSuccessAction | LoadMyFiltersAction | GetBucketsAction
  | SetColumnsAction;
