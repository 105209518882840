import { createAction, union } from '@ngrx/store';
import { Message } from 'primeng/api';
import { DialogState } from '@common/state/reducers';
import { ExceptionFilter, ExceptionResponse, EditedItem } from '../../exceptions/exceptions.model';
import { DatatableConfig, PagingFilter } from '../../../common/shared/results.model';
import { EXCEPTIONS_DIALOGS } from './exceptions.reducers';

export const setOrganisationId = createAction('[EXCEPTIONS] Set Organisation Id', (payload: number) => ({ payload }));
export const setToDefault = createAction('[EXCEPTIONS] Set to default');
export const setMessages = createAction('[EXCEPTIONS] Set Messages', (payload: Message[]) => ({ payload }));
export const setFiltersValue = createAction('[EXCEPTIONS] Set Filters Value', (payload: ExceptionFilter) => ({ payload }));
export const getAvailableQueues = createAction('[EXCEPTIONS] Get Available Queues');
export const setAvailableQueues = createAction('[EXCEPTIONS] Set Available Queues', (payload: string[]) => ({ payload }));
export const filter = createAction('[EXCEPTIONS] Filter', (payload: ExceptionFilter) => ({ payload }));
export const loadDataSuccessAction = createAction('[EXCEPTIONS] Load Data Success Action', (payload: ExceptionResponse) => ({ payload }));
export const sortAndPageDataAction = createAction('[EXCEPTIONS] Sort And Page Data', (payload: DatatableConfig) => ({ payload }));
export const deleteExceptions = createAction('[EXCEPTIONS] Delete Exceptions');
export const retryExceptions = createAction('[EXCEPTIONS] Retry Exceptions');
export const checkAction = createAction('[EXCEPTIONS] Check', (payload: string) => ({ payload }));
export const uncheckAction = createAction('[EXCEPTIONS] Uncheck', (payload: string) => ({ payload }));
export const toggleCheckAllAction = createAction('[EXCEPTIONS] Toggle Check All', (payload: boolean) => ({ payload }));
export const setSelectedItems = createAction('[EXCEPTIONS] Set Selected Items');
export const setEditedItems = createAction('[EXCEPTIONS] Set Edited Items', (payload: EditedItem[]) => ({ payload }));
export const openDialogAction = createAction('[EXCEPTIONS] Open Dialog', (payload: DialogState<EXCEPTIONS_DIALOGS>) => ({ payload }));
export const updateDialogAction = createAction('[EXCEPTIONS] Update Dialog', (payload: Message[]) => ({ payload }));
export const closeDialogAction = createAction('[EXCEPTIONS] Close Dialog');
export const defaultDialogAction = createAction('[EXCEPTIONS] Default Dialog');
export const setPaging = createAction('[EXCEPTIONS] Set Paging', (payload: PagingFilter) => ({ payload }));

const actions = union({
  setOrganisationId,
  setToDefault,
  setMessages,
  setFiltersValue,
  filter,
  loadDataSuccessAction,
  getAvailableQueues,
  setAvailableQueues,
  sortAndPageDataAction,
  deleteExceptions,
  checkAction,
  uncheckAction,
  toggleCheckAllAction,
  setSelectedItems,
  setEditedItems,
  setPaging
});

export type ExceptionsActions = typeof actions;

