import * as fromActions from '../layout/layout.actions';

export interface LayoutState {
  tabs: fromActions.TabsVisibility;
}

export const initialState: LayoutState = {
  tabs: null
};

export function layoutReducer(state = initialState, action: fromActions.LayoutActions): LayoutState {
  switch (action.type) {
    case fromActions.SET_TABS_VISIBILITY:
      return {
        tabs: action.payload ? { ...action.payload } : null
      };
    default:
      return state;
  }
}
