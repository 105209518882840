import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import {  filter, first, map, switchMap, tap } from 'rxjs/operators';

import { AuthenticationService } from '../auth/authentication.service';
import { OrganisationModel, SelectableOrganisationResponse } from './organisation.model';
import { OrganisationGroupModel } from './organisation-group.model';
import { StateService } from './state.service';

@Injectable()
export class OrganisationService {
  private readonly apiUrl = '/api/selectableOrganisations';
  private organisations = new BehaviorSubject<OrganisationModel[]>([]);
  private currentOrgSubject = new ReplaySubject<OrganisationModel>(1);
  private selectedOrganisation: OrganisationModel = null;
  private selectedOrganisationId: number;
  private organisationGroup = new BehaviorSubject<OrganisationGroupModel>({ legalName: '', displayName: '' });
  private loaded = new BehaviorSubject<boolean>(false);
  private refresh$ = new BehaviorSubject<boolean>(false);

  constructor(private httpClient: HttpClient,
              private authenticationService: AuthenticationService,
              private router: Router, private stateService: StateService) {

  }


  emitChangeOrganisation(organisationId: number) {
    // this check is necessary to prevent looping between selecting and authorisation
    if (this.selectedOrganisationId !== organisationId) {
      this.selectedOrganisation = this.getOrganisations().filter(o => o.organisationId === organisationId)[0];

      this.currentOrgSubject.next(this.selectedOrganisation);

      if (this.selectedOrganisation) {
        this.loadOrganisationGroup();
      }

      this.selectedOrganisationId = organisationId;

      this.authoriseSelectedOrganisation(organisationId);
    }

  }

  getChangeOrganisationObservable(): Observable<OrganisationModel> {
    return this.currentOrgSubject.asObservable();
  }

  onRefresh(): Observable<boolean> {
    return this.refresh$.asObservable();
  }

  getOrganisationChangeIfAccessible(): Observable<OrganisationModel> {
    let organisationId = this.getCachedOrganisationId();
    return this.authenticationService
      .hasAccessChange
      .pipe(
        filter((hasAccess: boolean) => hasAccess),
        switchMap((value) =>
          this.getChangeOrganisationObservable()
            .pipe(
              first(),
              filter((next) =>
                !!((next && next.organisationId !== organisationId) || (!next && organisationId))
              ),
              tap((org: OrganisationModel) => organisationId = org ? org.organisationId : null)
            )
        )
      );
  }

  getSelectedOrganisationName(): string | null {
    return this.selectedOrganisation ? this.selectedOrganisation.displayName : null;
  }

  /**
   * ATTENTION: use this method only on pages which are left automatically, when the user selects no-org in orgSelector!
   * (Otherwise the caller would continue to use the organisation selected before selecting no-org.)
   */
  getChangeNotNullOrganisationObservable(): Observable<OrganisationModel> {
    return this.getChangeOrganisationObservable()
      .pipe(filter(org => org != null));
  }

  clearOrganisationsCache() {
    this.setOrganisations([]);
  }

  protected authoriseSelectedOrganisation(organisationId: number) {
    const noPathSoThatCurrentPathIsUsed = null;
    this.authenticationService.authorise(noPathSoThatCurrentPathIsUsed, organisationId).then(authorised => {
        if (!authorised) {
          this.router.navigate(['/'], { queryParamsHandling: 'preserve' });
        }
        this.refresh$.next(true);
      }
    );
  }

  loadSelectableOrganisations(): Promise<SelectableOrganisationResponse> {

    if(this.authenticationService.isAuthenticated()){
      const options = {  };
      return this.httpClient.get<SelectableOrganisationResponse>(this.apiUrl, options).pipe(
        map((response) => {
          this.setOrganisations(response.organisations);
          return response;
        })
      ).toPromise();
    } else {
      return of({organisations: [], selectionOptional: false}).toPromise();
    }
  }

  private isLoaded(): boolean {
    return this.loaded.getValue();
  }

  public loading(): Observable<boolean> {
    return this.loaded.asObservable();
  }

  public getOrganisations(): OrganisationModel[] {
    const orgs = this.organisations.getValue();
    return orgs;
  }

  setOrganisations(organisations: OrganisationModel[]) {
    this.organisations.next(organisations);
  }

  public subscribeOrganisations(): Observable<OrganisationModel[]> {
    return this.organisations.asObservable();
  }

  protected loadOrganisationGroup(): void {
    if (this.selectedOrganisation) {

      const options = {};
      this.httpClient.get<OrganisationGroupModel>('api/organisation/' + this.selectedOrganisation.organisationId + '/group', options).subscribe(response => {
          if (response) {
            this.organisationGroup.next(response);
          }
        }
      );
    }
  }

  getOrganisationGroup(): Observable<OrganisationGroupModel> {
    return this.organisationGroup.asObservable();
  }

  public getCurrentOrganisationId(): number {
    return this.selectedOrganisation ? this.selectedOrganisation.organisationId : null;
  }

  public getCurrentOrCachedOrganisationId(): number {
    return this.selectedOrganisation ? this.selectedOrganisation.organisationId : this.getCachedOrganisationId();
  }

  public setCurrentOrganisationId(organisationId: number): void {
    this.emitChangeOrganisation(organisationId);
  }

  public reset(): void {
    this.clearOrganisationsCache();
    this.currentOrgSubject.next(null);
    delete this.selectedOrganisation;
    delete this.selectedOrganisationId;
    this.loaded.next(false);
  }

  getCachedOrganisationId(): number {
    return this.stateService.organisationId;
  }

  public deselectCachedOrganisation() {
    if (this.getCachedOrganisationId()) {
      this.cacheCurrentOrganisationId(null);
    }
  }

  public cacheCurrentOrganisationId(organisationId: number) {
    this.stateService.setOrganisationId(organisationId);
  }

  deleteCachedOrganisationId() {
    this.stateService.setOrganisationId(null);
  }
}
