<div class="panel-box margin-0">
  <p-messages [closable]="true" class="col-12"></p-messages>

  <!--user org group datatable-->
  <cms-datatable selectionMode="single"
                 class="block disable-overflow"
                 *ngIf="(data$ | async)?.userOrgGroupDataVisible"
                 [data]="(data$ | async)?.userOrgGroupData"
                 [tableColumns]="columns$ | async"
                 [sortField]="(sorting$ | async).columnName"
                 [sortOrder]="(sorting$ | async).sortOrder"
                 [selected]="selected$ | async"
                 [paginator]="false"
                 [autoLayout]="true"
                 [scrollable]="false"
                 [tooltipDisabled]="true"
                 [lazy]="false">
    <ng-template pTemplate="caption">
      <div class="row margin-0 table-caption">
        My Org Group: {{ (data$ | async)?.userOrgGroupData[0].orgGroupDisplayName }}
      </div>
    </ng-template>
    <ng-template pTemplate="body" let-row="row" let-column="column" let-rowIndex="rowIndex">
      <td *ngIf="column.type === 'on/off'" [ngStyle]="column.style">
        <cms-of-on-cell [data]="row[column.name]"
                        [column]="column.name"
                        [selectedRowIndex]="rowIndexProcess"
                        [rowIndex]="rowIndex"
                        (save)="onOfOnSave($event)"
                        [editable]="editDisabled">
        </cms-of-on-cell>
      </td>
      <td [ngStyle]="column.style" *ngIf="(column.type === 'button') && (column.visible === true)">
        <cms-edit-button *ngIf="column.type === 'button'"
                         [disabled]="!row.editActionEnabled || editDisabled"
                         (editMode)="onEditMode(rowIndex, row)"
                         [rowIndexEditButton]="rowIndex"
                         [openRowIndex]="rowIndexProcess"
                         (cancel)="onCancelButton()"
                         (save)="onSaveButton(newValues)">
        </cms-edit-button>
      </td>
    </ng-template>
  </cms-datatable>
  <!--broker datatable-->
  <cms-datatable selectionMode="single"
                 class="block disable-overflow"
                 *ngIf="(data$ | async)?.brokerData"
                 [data]="(data$ | async)?.brokerData"
                 [tableColumns]="brokersAndTradersDataTableColumns"
                 [sortOrder]="(sorting$ | async).sortOrder"
                 [selected]="selected$ | async"
                 [paginator]="false"
                 [autoLayout]="true"
                 [scrollable]="false"
                 [tooltipDisabled]="true"
                 [lazy]="false">
    <ng-template pTemplate="caption">
      <div class="row margin-0 table-caption">
        Brokers
      </div>
    </ng-template>
    <ng-template pTemplate="body" let-row="row" let-column="column">
      <td *ngIf="column.type === 'on/off'" [ngStyle]="column.style">
        <span *ngIf="row[column.name] === null">N/A</span>
        <i class="fa fa-check" *ngIf="row[column.name] === true"></i>
        <i class="fa fa-times" *ngIf="row[column.name] === false"></i>
      </td>
    </ng-template>
  </cms-datatable>
  <!--trader datatable-->
  <cms-datatable selectionMode="single"
                 class="block disable-overflow"
                 *ngIf="(data$ | async)?.traderData"
                 [data]="(data$ | async)?.traderData"
                 [tableColumns]="brokersAndTradersDataTableColumns"
                 [sortOrder]="(sorting$ | async).sortOrder"
                 [selected]="selected$ | async"
                 [paginator]="false"
                 [autoLayout]="true"
                 [scrollable]="false"
                 [tooltipDisabled]="true"
                 [lazy]="false">
    <ng-template pTemplate="caption">
      <div class="row margin-0 table-caption">
        Traders
      </div>
    </ng-template>
    <ng-template pTemplate="body" let-row="row" let-column="column">
      <td *ngIf="column.type === 'on/off'" [ngStyle]="column.style">
        <span *ngIf="row[column.name] === null">N/A</span>
        <i class="fa fa-check" *ngIf="row[column.name] === true"></i>
        <i class="fa fa-times" *ngIf="row[column.name] === false"></i>
      </td>
    </ng-template>
  </cms-datatable>
</div>
