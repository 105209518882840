import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, throwError } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Message } from 'primeng/api';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { OrganisationService } from '@common/organisation.service';
import { DatatableConfig, DatatableData } from '@common/shared/results.model';
import { ApiRequestService } from '@common/api-request.service';
import { Prefix } from '../reducers';
import { OrganisationDatatable, OrganisationsState } from './organisation.reducer';
import {
  ChangeOrganisationResponse,
  EcmPresetValuesResponse,
  ErrPresetValuesResponse,
  Organisation,
  OrganisationsFilters,
  OrganisationsRequest,
  OrganisationsResponse,
  AddOrganisation,
  ChangeToExternalPartyAllowedResponse,
  AdminOrganisationFormResponse,
  EsmPresetValuesRequest,
  EsmPresetValuesResponse, EcmPresetValuesRequest, DeleteOrganisation, EditEsm
} from '../../administration-tab-panel/organisations/organisations.model';
import { canDeleteOrg, getDatatable, getFilters, getSelected } from './organisation.selectors';
import { DatatableState } from '../datatable/datatable.reducer';
import * as fromActions from './organisation.actions';
import * as fromDtActions from '../datatable/datatable.actions';
import * as fromDialogActions from '../dialog/dialog.actions';
import { saveBlob, toPayload } from '@common/cms-common.model';
import { ErrorResponse } from '@common/error-response.model';
import { EditEsmAction } from './organisation.actions';

@Injectable()
export class OrganisationEffects {
  private baseUrl = 'api/administration/organisations';
  private prefix: Prefix = '[ORGANISATION]';

  constructor(private apiGateway: ApiRequestService,
              private actions$: Actions,
              private store$: Store<OrganisationsState>,
              private mapper: MessagesMapperService,
              public organisationService: OrganisationService) {
    this.handleChangeOrganisation = this.handleChangeOrganisation.bind(this);
    this.reloadAfterChangeOrganisation = this.reloadAfterChangeOrganisation.bind(this);
  }

   changeToExternalPartyAllowedAction$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.CHANGE_TO_EXTERNAL_PARTY_ALLOWED),
      withLatestFrom(this.store$.pipe(select(getSelected))),
      concatMap(([, { orgId }]: [fromActions.ChangeToExternalPartyAllowedAction, Organisation]) =>
        this.apiGateway
          .get(`${this.baseUrl}/isChangeToExternalPartyAllowed`, {'selectedOrganisationId':orgId})
          .pipe(map((response: ChangeToExternalPartyAllowedResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return new fromDtActions.SetMessagesAction(this.prefix, messages);
              }
              return new fromDialogActions.OpenDialogAction(this.prefix, {
                name: 'DELETE',
                data: response.value
              });
            }),
            catchError(error => of(new fromDtActions.SetMessagesAction(this.prefix, [
              this.mapper.createErrorMessage(error.errorMessage)
            ])))
          )
      )));

   editEsm$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.EDIT_ESM),
      map(toPayload),
      concatMap((response: EditEsm) =>
        this.apiGateway
          .post(`${this.baseUrl}/editEsmProcessDetails`, response)
          .pipe(
            withLatestFrom(this.store$.pipe(select(getDatatable))),
            concatMap((data: [ChangeOrganisationResponse, OrganisationDatatable]) =>
              this.reloadAfterChangeOrganisation(...data)),
            catchError(error => of(new fromDtActions.SetMessagesAction(this.prefix, [
              this.mapper.createErrorMessage(error.errorMessage)
            ]))))
      )));

   editEcm$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.EDIT_ECM),
      concatMap((action: fromActions.EditEcmAction) =>
        this.apiGateway
          .post(`${this.baseUrl}/editEcmProcessDetails`, action.payload)
          .pipe(
            withLatestFrom(this.store$.pipe(select(getDatatable))),
            concatMap((data: [ChangeOrganisationResponse, OrganisationDatatable]) =>
              this.reloadAfterChangeOrganisation(...data)),
            catchError(error => of(new fromDtActions.SetMessagesAction(this.prefix, [
              this.mapper.createErrorMessage(error.errorMessage)
            ]))))
      )));

   editErr$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.EDIT_ERR),
      concatMap((action: fromActions.EditErrAction) =>
        this.apiGateway
          .post(`${this.baseUrl}/editErrProcessDetails`, action.payload)
          .pipe(
            withLatestFrom(this.store$.pipe(select(getDatatable))),
            concatMap((data: [ChangeOrganisationResponse, OrganisationDatatable]) =>
              this.reloadAfterChangeOrganisation(...data)),
            catchError(error => of(new fromDtActions.SetMessagesAction(this.prefix, [
              this.mapper.createErrorMessage(error.errorMessage)
            ]))))
      )));

   editEsmFlag$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.EDIT_ESM_FLAG),
      map(toPayload),
      concatMap((response: EditEsmAction) =>
        this.apiGateway.post(`${this.baseUrl}/editEsmEnabledFlag`, response)
          .pipe(
            withLatestFrom(this.store$.pipe(select(getDatatable))),
            concatMap((data: [ChangeOrganisationResponse, OrganisationDatatable]) =>
              this.reloadAfterChangeOrganisation(...data)),
            catchError(error => of(new fromDtActions.SetMessagesAction(this.prefix, [
              this.mapper.createErrorMessage(error.errorMessage)
            ])))
          )
      )
    ));

   loadEcmPresetValues = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.LOAD_ECM_PRESET_VALUES),
      map(toPayload),
      switchMap((request: EcmPresetValuesRequest) =>
        this.apiGateway
          .get(`${this.baseUrl}/loadEcmPresetValues`, request)
          .pipe(
            map((response: EcmPresetValuesResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return new fromDtActions.SetMessagesAction(this.prefix, messages);
              }
              return new fromDialogActions.OpenDialogAction(this.prefix, {
                name: 'EDIT_ECM',
                data: response
              });
            }),
            catchError(error => of(new fromDtActions.SetMessagesAction(this.prefix, [
              this.mapper.createErrorMessage(error.errorMessage)
            ])))
          )
      )));

   loadErrPresetValues = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.LOAD_ERR_PRESET_VALUES),
      map(toPayload),
      switchMap((request: EcmPresetValuesRequest) =>
        this.apiGateway
          .get(`${this.baseUrl}/loadErrPresetValues`, request)
          .pipe(
            map((response: ErrPresetValuesResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return new fromDtActions.SetMessagesAction(this.prefix, messages);
              }
              return new fromDialogActions.OpenDialogAction(this.prefix, {
                name: 'EDIT_ERR',
                data: response
              });
            }),
            catchError(error => of(new fromDtActions.SetMessagesAction(this.prefix, [
              this.mapper.createErrorMessage(error.errorMessage)
            ])))
          )
      )));

   loadEsmPresetValues = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.LOAD_ESM_PRESET_VALUES),
      map(toPayload),
      switchMap((request: EsmPresetValuesRequest) =>
        this.apiGateway.get(`${this.baseUrl}/loadEsmProcessDetails`, request)
          .pipe(
            tap((response: ErrorResponse) => response && MessagesMapperService.hasAnyError(response) && throwError(response)),
            map((response: EsmPresetValuesResponse) =>
              new fromDialogActions.OpenDialogAction(this.prefix, {
                name: 'EDIT_ESM',
                data: response
              })
            ),
            catchError(error => of(new fromDtActions.SetMessagesAction(this.prefix, this.mapper.toErrorMessages(error))))
          )
      )
    ));

   moveOrganisation$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.MOVE_ORGANISATION),
      concatMap((action: fromActions.MoveOrganisationAction) =>
        this.apiGateway
          .post(`${this.baseUrl}/move`, action.payload)
          .pipe(
            withLatestFrom(this.store$.pipe(select(getDatatable))),
            concatMap((data: [ChangeOrganisationResponse, OrganisationDatatable]) => this.reloadAfterChangeOrganisation(...data)),
            catchError(error => of(new fromDtActions.SetMessagesAction(this.prefix, [
              this.mapper.createErrorMessage(error.errorMessage)
            ])))
          )
      )));

   deleteOrganisation$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.DELETE_ORGANISATION),
      withLatestFrom(this.store$.pipe(select(canDeleteOrg))),
      map(([, canDelete]: [fromActions.DeleteAction, boolean]) => {
        if (canDelete) {
          return new fromActions.ChangeToExternalPartyAllowedAction();
        } else {
          return new fromDtActions.SetMessagesAction(this.prefix,
            [
              this.mapper.createErrorMessage(
                'You cannot delete the organisation while it is selected as your current working set (see selector in title bar).')
            ]);
        }
      })));

   confirmDeleteOrganisation$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.CONFIRM_DELETE_ORGANISATION),
      map(toPayload),
      concatMap((request: DeleteOrganisation) =>
        this.apiGateway
          .delete(`${this.baseUrl}/deleteOrganisation`, request)
          .pipe(
            withLatestFrom(this.store$.pipe(select(getDatatable))),
            concatMap((data: [ChangeOrganisationResponse, OrganisationDatatable]) =>
              this.reloadAfterChangeOrganisation(...data)),
            catchError(error => of(new fromDtActions.SetMessagesAction(this.prefix, [
              this.mapper.createErrorMessage(error.errorMessage)
            ])))
          )
      )));

   editOrganisation$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.EDIT_ORGANISATION),
      concatMap((action: fromActions.EditOrganisationAction) =>
        this.apiGateway
          .post(`${this.baseUrl}/editOrganisation`, action.payload)
          .pipe(
            concatMap(this.handleChangeOrganisation),
            catchError(error => of(new fromDtActions.SetMessagesAction(this.prefix, [
              this.mapper.createErrorMessage(error.errorMessage)
            ])))
          )
      )));

   addOrganisation$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.ADD_ORGANISATION),
      concatMap((action: fromActions.AddOrganisationAction) => {
          const {
            businessContact,
            displayName,
            eicCode,
            legalName,
            organisationGroupId,
            technicalContact
          } = action.payload;
          const request: AddOrganisation = {
            displayName,
            eicCode,
            legalName,
            organisationGroupId,
          };
          if (businessContact) {
            request.businessContact = businessContact;
          }

          if (technicalContact) {
            request.technicalContact = technicalContact;
          }
          return this.apiGateway
            .post(`${this.baseUrl}/addOrganisation`, request)
            .pipe(
              withLatestFrom(this.store$.pipe(select(getDatatable))),
              concatMap((data: [ChangeOrganisationResponse, OrganisationDatatable]) =>
                this.reloadAfterChangeOrganisation(...data)),
              catchError(error => of(new fromDtActions.SetMessagesAction(this.prefix, [
                this.mapper.createErrorMessage(error.errorMessage)
              ])))
            );
        }
      )
    ));

   updateActivation$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.UPDATE_ACTIVATION),
      concatMap((action: fromActions.UpdateActivationAction) =>
        this.apiGateway.post(`${this.baseUrl}/changeState`, action.payload)
          .pipe(
            switchMap(this.handleChangeOrganisation),
            catchError(error => of(new fromDtActions.SetMessagesAction(this.prefix, [
              this.mapper.createErrorMessage(error.errorMessage)
            ])))
          )
      )
    ));

   updateFilter$ = createEffect(() => this.actions$
    .pipe(
      ofType(`${this.prefix} ${fromDtActions.UPDATE_FILTER_DATA}`),
      withLatestFrom(this.store$.pipe(select(getFilters))),
      map((data: [any, OrganisationsFilters]) => new fromDtActions.FilterDataAction(this.prefix, data[1]))
    ));

   filter$ = createEffect(() => this.actions$
    .pipe(
      ofType(`${this.prefix} ${fromDtActions.FILTER_DATA}`),
      withLatestFrom(this.store$.pipe(select(getDatatable))),
      switchMap(([action, state]: [fromDtActions.FilterDataAction, any]) =>
        this.loadData({
          paging: state.paging,
          sorting: state.sorting,
          ...action.payload as OrganisationsFilters
        })
      )));

   export = createEffect(() => this.actions$.pipe(
    ofType(fromActions.EXPORT),
    withLatestFrom(this.store$.pipe(select(getDatatable))),
    switchMap((data: [fromActions.ExportAction, DatatableState<Organisation, OrganisationsFilters>]) => {
      const { paging, sorting, filters } = data[1];
      const request: OrganisationsRequest = {
        paging,
        sorting,
        ...filters,
      };
      return this.apiGateway
        .getBlob(`${this.baseUrl}/report`, request, null)
        .pipe(
          switchMap((response: any) => {
            saveBlob(response);
            return of(new fromDtActions.SetMessagesAction(this.prefix, []));
          }),
          catchError(() => of(new fromDtActions.SetMessagesAction(this.prefix, [
            this.mapper.createErrorMessage('Server error')
          ])))
        );
    })));

   organisationGroups$ = createEffect(() => this.actions$
    .pipe(
      ofType(`${this.prefix} ${fromActions.LOAD_ORGANISATION_FORM}`),
      switchMap(() =>
        this.apiGateway
          .get(`${this.baseUrl}/organisationGroups`, null)
          .pipe(
            map((response: AdminOrganisationFormResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return new fromDtActions.SetMessagesAction(this.prefix, messages);
              }
              return new fromActions.LoadOrganisationFormSuccessAction(this.prefix, response);
            }),
            catchError(error => of(new fromDtActions.SetMessagesAction(this.prefix, [
              this.mapper.createErrorMessage(error.errorMessage)
            ]))))
      )));

   loadData$ = createEffect(() => this.actions$
    .pipe(
      ofType(`${this.prefix} ${fromDtActions.LOAD_DATA}`),
      withLatestFrom(this.store$.pipe(select(getFilters))),
      map((data: [fromDtActions.LoadDataAction, OrganisationsFilters]) =>
        new fromDtActions.FilterDataAction(this.prefix, data[1])
      )
    ));

   sortAndPage$ = createEffect(() => this.actions$
    .pipe(
      ofType(`${this.prefix} ${fromDtActions.SORT_AND_PAGE_DATA}`),
      map(toPayload),
      withLatestFrom(this.store$.pipe(select(getFilters))),
      switchMap(([{ sorting, paging }, { organisationGroupId, filterValue }]: [DatatableConfig, OrganisationsFilters]) =>
        this.loadData({
          organisationGroupId,
          filterValue,
          sorting,
          paging,
        })
      )));

  private loadData(request: OrganisationsRequest) {
    return this.apiGateway
      .post(`${this.baseUrl}/loadOrganisations`, request)
      .pipe(
        map((response: OrganisationsResponse) => {
          const messages: Message[] = this.mapper.toErrorMessages(response);
          if (messages && messages.length) {
            return new fromDtActions.SetMessagesAction(this.prefix, messages);
          }

          response.organisations.forEach(item => {
            item.id = item.orgId;
          });

          const { currentPage, organisations, recordCount } = response;
          return new fromDtActions.FilterDataSuccessAction(this.prefix, {
            currentPage,
            count: recordCount,
            values: organisations
          } as DatatableData<Organisation[]>);
        }),
        catchError(error => of(new fromDtActions.SetMessagesAction(this.prefix, [
          this.mapper.createErrorMessage(error.errorMessage)
        ])))
      );
  }

  private handleChangeOrganisation(response: ChangeOrganisationResponse) {
    const messages: Message[] = this.mapper.toErrorMessages(response);
    if (messages && messages.length) {
      return of(new fromDialogActions.UpdateDialogAction(this.prefix, messages));
    }
    const updatedOrg = response.organisation;
    updatedOrg.id = updatedOrg.orgId;
    return [
      new fromDialogActions.CloseDialogAction(this.prefix),
      new fromDtActions.InsertItemAction(this.prefix, response.organisation),
      new fromDtActions.SetMessagesAction(this.prefix, this.mapper.toSuccessMessages(response.feedbackMessage))
    ];
  }

  private reloadAfterChangeOrganisation(response: ChangeOrganisationResponse, state: OrganisationDatatable) {
    const messages: Message[] = this.mapper.toErrorMessages(response);
    if (messages && messages.length) {
      return of(new fromDialogActions.UpdateDialogAction(this.prefix, messages));
    } else {
      return this.loadData({
        organisationGroupId: (state.filters as OrganisationsFilters).organisationGroupId,
        filterValue: (state.filters as OrganisationsFilters).filterValue,
        paging: state.paging,
        sorting: state.sorting
      }).pipe(
        switchMap((filteringAction) => [
          filteringAction,
          new fromDialogActions.CloseDialogAction(this.prefix),
          new fromDtActions.SetMessagesAction(this.prefix, this.mapper.toSuccessMessages(response.feedbackMessage))
          ]
        )
      );
    }
  }
}

