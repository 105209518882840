import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { select, Store } from '@ngrx/store';
import { Message } from 'primeng/api';
import { withLatestFrom } from 'rxjs/operators';
import { OrganisationModel } from '@common/organisation.model';
import { OrganisationService } from '@common/organisation.service';
import { DatatableState } from '@common/datatable/datatable.model';
import { Column } from '@common/shared/datatable.model';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import * as fromAction from '../../state/mifid-details/mifid-details.action';
import * as fromSelectors from '../../state/mifid-details/mifid-details.selectors';
import { ErrMifidDetailsInformation, ErrMifidDetailsItem, ErrMifidDetailsItemResponse } from '../mifid.model';
import * as fromDialogActions from '../../state/dialog/dialog.actions';
import * as fromDialogSelectors from '../../state/dialog/dialog.selectors';
import { ERR_MIFID_DIALOGS } from '../../state/dialog/dialog.reducers';

@Component({
  selector: 'cms-err-mifid-details',
  templateUrl: './mifid-details.component.html',
  styleUrls: ['./mifid-details.component.scss']
})
export class MifidDetailsComponent implements OnInit, OnDestroy {
  loading$: Observable<boolean> = this.store.pipe(select(fromSelectors.getLoading));
  messages$: Observable<Message[]> = this.store.pipe(select(fromSelectors.getMessages));
  data$: Observable<DatatableData<ErrMifidDetailsItemResponse[]>> = this.store.pipe(select(fromSelectors.getData));
  paging$: Observable<PagingFilter> = this.store.pipe(select(fromSelectors.getPaging));
  sorting$: Observable<SortingFilter> = this.store.pipe(select(fromSelectors.getSorting));
  columns$: Observable<Column[]> = this.store.pipe(select(fromSelectors.getColumns));
  formValues$: Observable<any> = this.store.pipe(select(fromSelectors.getFormValues));
  dialog$: Observable<{ name: ERR_MIFID_DIALOGS, messages?: Message[] }> = this.store.pipe(select(fromDialogSelectors.getDialog));

  subscriptions$: Subscription = new Subscription();
  information: ErrMifidDetailsInformation;
  editable: boolean = false;
  selectedRow: ErrMifidDetailsItem = null;
  title: string = 'Positions for file';
  filename: string = '';
  isFCAPlus: boolean = false;


  constructor(private organisationService: OrganisationService,
              private route: ActivatedRoute,
              private titleService: Title,
              private store: Store<any>) {
    this.titleService.setTitle(this.title);
  }

  ngOnInit(): void {
    this.store.dispatch(new fromAction.SetFileIdAction(this.route.snapshot.queryParams.fileId));
    this.store.dispatch(new fromAction.LoadInformationAction());
    this.store.dispatch(new fromAction.LoadDataAction());

    this.subscriptions$.add(this.organisationService
      .getChangeOrganisationObservable()
      .pipe(
        withLatestFrom(this.store.pipe(select(fromSelectors.getOrganisationId))))
      .subscribe(((data: [OrganisationModel, number]) => {
        const [organisation, organisationId] = data;
        if ((organisation && organisation.organisationId !== organisationId) || (!organisation && organisationId)) {
          this.store.dispatch(new fromAction.SetToDefaultAction());
          this.store.dispatch(new fromAction.SetOrganisationIdAction(organisation ? organisation.organisationId : null));

          this.store.dispatch(new fromAction.SetFileIdAction(this.route.snapshot.queryParams.fileId));
          this.store.dispatch(new fromAction.LoadInformationAction());
          this.store.dispatch(new fromAction.LoadDataAction());
        }
      })));

    this.subscriptions$.add(this.store.pipe(select(fromSelectors.getInformation))
      .subscribe(information => {
        this.information = information;
        this.filename = (information) ? information.filename : '';
        this.title = `Positions for file ${this.filename}`;
        this.titleService.setTitle(this.title);
        this.isFCAPlus = information && information.docType === 'FCAExtendedPositionReport';
      }));
    this.subscriptions$.add(this.store.pipe(select(fromSelectors.getEditable))
      .subscribe(editable => this.editable = editable));
  }

  ngOnDestroy(): void {
    this.store.dispatch(new fromAction.SetToDefaultAction());
    this.subscriptions$.unsubscribe();
  }

  onLazyLoad(state: DatatableState): void {
    this.store.dispatch(new fromAction.SortAndPageDataAction({
      paging: {
        page: state.page,
        entriesPerPage: state.perPage
      },
      sorting: {
        columnName: state.sortField,
        sortOrder: state.sortOrder
      }
    }));
  }

  onOpenAddNew() {
    this.store.dispatch(new fromAction.LoadFormValuesAction());
    this.store.dispatch(fromDialogActions.openDialogAction({ name: 'ADD_NEW' }));
  }

  onOpenEdit(row: ErrMifidDetailsItem) {
    this.store.dispatch(new fromAction.LoadFormValuesAction());
    this.selectedRow = this.getFormattedRow({ ...row });
    this.store.dispatch(fromDialogActions.openDialogAction({ name: 'EDIT' }));
  }

  closeEditDialog() {
    this.selectedRow = null;
    this.closeDialog();
  }

  closeDialog() {
    this.store.dispatch(fromDialogActions.closeDialogAction());
  }

  onSaveItem(data: { isEditView: boolean, item: ErrMifidDetailsItem }) {
    this.selectedRow = null;
    if (data.isEditView) {
      this.store.dispatch(new fromAction.EditItemAction(data.item));
    } else {
      this.store.dispatch(new fromAction.AddNewItemAction(data.item));
    }
  }

  onConfirm() {
    this.store.dispatch(new fromAction.ConfirmItemAction());
    this.closeDialog();
  }

  onOpenConfirm() {
    this.store.dispatch(fromDialogActions.openDialogAction({ name: 'CONFIRM_FILE' }));
  }

  onOpenDiscard() {
    this.store.dispatch(fromDialogActions.openDialogAction({ name: 'DISCARD_FILE' }));
  }

  onDeleteItem(row: ErrMifidDetailsItem): void {
    this.store.dispatch(new fromAction.DeleteItemPositionAction(row.id));
  }

  onDiscard() {
    this.store.dispatch(new fromAction.DiscardChangesAction());
    this.closeDialog();
  }

  private getFormattedRow(row: any): ErrMifidDetailsItem {
    Object.keys(row).map(key =>
      row[key] = (row[key] && row[key].hasOwnProperty('value')) ? row[key].value : row[key]);
    return row;
  }
}
