import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { filter } from 'rxjs/operators';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ErrorMessageService } from '@common/errors/error-message.service';

@Component({
  selector: 'cms-remit-trade-entry',
  templateUrl: './remit-trade-entry.component.html',
  styleUrls: ['./remit-trade-entry.component.scss']
})
export class RemitTradeEntryComponent implements OnInit, OnDestroy {
  activeIndex: number = 0;
  private activeTabSubscription$: Subscription;
  tabs: string[] = ['table1', 'table2'];
  selectedTab: string;

  @Output() onTabChange = new EventEmitter<string>();

  constructor(private router: Router, public readonly errorMessageService:ErrorMessageService) {}

  ngOnInit(): void {
    this.setIndexByUrl(this.router.url);
    this.activeTabSubscription$ =
    this.router.events.pipe(filter(event => event instanceof NavigationStart && event.navigationTrigger === 'popstate'))
    .subscribe((event: NavigationStart) => this.setIndexByUrl(event.url));
  }

  setTab(event: any): void {
    this.setActiveTabData(event.index);
    this.onTabChange.emit(this.selectedTab);
    this.router.navigate([`/err/remit_trade_entry/${this.selectedTab.toLowerCase()}`]);
  }

  private setIndexByUrl(url: string): void {
    const index = this.calcActiveTabIndex(url);
    this.setActiveTabData(index);
  }

  private setActiveTabData(index: number): void {
    this.activeIndex = index;
    this.selectedTab = this.tabs[index];
  }

  private calcActiveTabIndex(url: string): number {
    const index = this.tabs.findIndex(el => url.includes(el));
    return (index > -1) ? index : 0;
  }

  ngOnDestroy(): void {
    this.activeTabSubscription$.unsubscribe();
  }
}
