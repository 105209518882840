<form [formGroup]="group" class="panel-box pt-1 form_s">
  <h6 class="m-0 mb-2 mt-1">Counterparties</h6>
  <p-multiSelect #drop selectedItemsLabel="Selected ({0})"
                 [formControlName]="'dropDown'"
                 [style]="{'width':'100%'}"
                 [filter]="true"
                 id="esm-netting-preview-multiselect-cp"
                 [options]="counterparties"
                 [(ngModel)]="selectedScope"
                 maxSelectedLabels="1"
                 emptyFilterMessage="no available counterparty"
                 placeholder="Choose counterparty">
<!--    <p-header class="float-right" (click)="drop.toggleAll($event)">
      select all
    </p-header>
    -->
  </p-multiSelect>
</form>
