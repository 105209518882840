import { createSelector } from '@ngrx/store';
import { getEsmState, State } from '../../reducers';
import { NettingPreviewState } from '../index';
import { InvoiceOverviewState } from './invoice-overview.reducers';

export const getNettingPreviewState = createSelector(getEsmState, (state: State) => state.nettingPreview);

export const getInvoiceOverviewState = createSelector(getNettingPreviewState, (state: NettingPreviewState) => state.invoiceOverview);
export const getLoading = createSelector(getInvoiceOverviewState, (preview: InvoiceOverviewState) => preview.loading);

export const getSelected = createSelector(getInvoiceOverviewState, (preview: InvoiceOverviewState) => preview.selected);
export const checkPresence = createSelector(getInvoiceOverviewState, (preview: InvoiceOverviewState, props) =>
  preview.previewItems.find(element => element?.id === props.id));

export const getAllowDialog = createSelector(getInvoiceOverviewState, (state: InvoiceOverviewState) => state.allowDialog);



