import { Message } from 'primeng/api';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiRequestService } from '@common/api-request.service';
import { saveBlob } from '@common/cms-common.model';
import { MessagesMapperService } from '@common/messages-mapper.service';
import * as fromDownloadGatekeeperAction from './gatekeeper-downloading.actions';
import * as fromTableActions from '../table/table.actions';
import { URL_GATEKEEPER_INVOICE } from '../../../../settings/invoice/invoice-settings.model';

@Injectable()
export class EsmSettingsInvoiceDownloadingGatekeeperEffects {

  constructor(private actions$: Actions,
              private apiGateway: ApiRequestService,
              private mapper: MessagesMapperService) { }

  downloadGatekeeper$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromDownloadGatekeeperAction.downloadGatekeeperAction.type),
      switchMap(() =>
        this.apiGateway.getBlob(`${URL_GATEKEEPER_INVOICE}/download/xls`)
          .pipe(
            switchMap((response: any) => {
              saveBlob(response);
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return of(fromTableActions.setMessagesAction(messages));
              }
              return [fromTableActions.setMessagesAction([])];
            }),
            catchError(error => of(fromTableActions.setMessagesAction([
              this.mapper.createErrorMessage(error.errorMessage || 'Server Error')
            ]))))
      )
    )
  );
}
