import { ApiRequestService } from '@common/api-request.service';
import { EsmEmailWhitelistState } from './whitelist.reducers';
import { MessagesMapperService } from '@common/messages-mapper.service';
import * as emailWhitelistActions from './whitelist.actions';
import { PresetValues, TableValues } from '../../../settings/whitelist/whitelist.model';
import { getPaging, getSearchToken, getSorting } from './whitelist.selectors';
import {
  downloadCsv,
  loading, loadPresets,
  loadPresetsSuccess, loadTableData,
  loadTableDataSuccess,
  setMessages, toggleAttachXML, triggerCheckbox, triggerCheckboxSuccess,
  uploadCsv,
  uploadCsvSuccess
} from './whitelist.actions';
import { saveBlob } from '@common/cms-common.model';
import {catchError, concatMap, switchMap, withLatestFrom} from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Message } from 'primeng/api';
import { Injectable } from '@angular/core';

@Injectable()
export class EsmEmailWhitelistEffects {
  private baseUrl = '/api/esm/settings/email';

  constructor(private apiGateway: ApiRequestService,
              private actions: Actions,
              private store: Store<EsmEmailWhitelistState>,
              private mapper: MessagesMapperService) {
  }

  loadPresets$ = createEffect(() =>
    this.actions.pipe(
      ofType(emailWhitelistActions.loadPresets),
      switchMap(() =>
        this.apiGateway.get(`${this.baseUrl}/loadPresetValues`)
          .pipe(
            switchMap(response => {
              const messages: Message[] = this.mapper.toErrorMessages(response);

              const presets: PresetValues = {
                allowedToUpload: response.value?.uploadPanelVisible,
                maxUploadSizeInBytes: response.value?.maxUploadSize,
                pdfOffsiteEnabled: response.value?.pdfOffsiteEnabled
              };

              return messages?.length ?
                [setMessages(messages)] :
                [setMessages([]), loadPresetsSuccess(presets)];
            }),
            catchError(error => [loading(false),
              setMessages(this.mapper.toErrorMessagesObj(error.errorMessage || 'Server Error').messages)])
          )
      )
    ));

  loadTableData$ = createEffect(() =>
    this.actions.pipe(
      ofType(emailWhitelistActions.loadTableData, emailWhitelistActions.pagingAndSorting, emailWhitelistActions.setSearchToken),
      withLatestFrom(this.store.pipe(select(getPaging)),
        this.store.pipe(select(getSearchToken)),
        this.store.pipe(select(getSorting))
      ),
      switchMap(([, paging, searchToken, sorting]) => {
        return this.apiGateway.post(`${this.baseUrl}/table/filter`, { paging, searchToken, sorting })
          .pipe(
            switchMap(response => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              const { values, currentPage, count } = response;
              const tableValues: TableValues = {
                data: { values, currentPage, count },
                paging: paging,
                sorting: sorting
              };


              return messages?.length ?
                [setMessages(messages)] :
                [loadTableDataSuccess(tableValues)];
            }),
            catchError(error => [loading(false),
              setMessages(this.mapper.toErrorMessagesObj(error.errorMessage || 'Server Error').messages)])
          ); }
      )));

  downloadCsv$ = createEffect(() =>
    this.actions.pipe(
      ofType(downloadCsv),
      switchMap(({ payload }) => {
          let params = new HttpParams();
          params = params.set('uploadId', `${payload}`);

          return this.apiGateway.getBlob(`${this.baseUrl}/download/csv`, null, params)
            .pipe(
              switchMap((response: any) => {
                saveBlob(response);
                return of(setMessages([]));
              }),
              catchError(error => [setMessages(this.mapper.toErrorMessagesObj(error.errorMessage || 'Server error').messages)]
              ));
        }
      )));

  uploadCsv$ = createEffect(() =>
    this.actions.pipe(
      ofType(uploadCsv),
      switchMap(({ payload }) => {
        const formData = new FormData();
        formData.set('file', payload);

        return this.apiGateway.post(`${this.baseUrl}/upload/csv`, formData, null)
          .pipe(
            concatMap(response => {
              const messages: Message[] = this.mapper.toErrorMessages(response);

              return messages?.length ?
                [setMessages(messages)] :
                [uploadCsvSuccess(),
                  loadTableData(),
                  setMessages([{ severity: 'success', detail: response.response }])];
            }),
            catchError(error => [setMessages(this.mapper.toErrorMessagesObj(error.errorMessage || 'Server Error').messages)]));
      })
    ));

  toggle$ = createEffect(() =>
    this.actions.pipe(
      ofType(triggerCheckbox),
      switchMap(({ checked }) =>
        this.apiGateway.post(`${this.baseUrl}/offsite`, checked, null,  new HttpHeaders({ 'Content-Type': 'application/json' }))
          .pipe(
            switchMap(response => {
              const messages: Message[] = this.mapper.toErrorMessages(response);

              return messages?.length ?
                [setMessages( messages), loadPresets()] :
                [setMessages(this.mapper.toSuccessMessages('Successfully changed \'eSM Offsite Parties Support\' settings')), triggerCheckboxSuccess({ checked })];
            }),

            catchError(error => [setMessages(this.mapper.toErrorMessages(error.errorMessage || 'Server Error'))])
          ))
    )
  );

  toggleAttachXml$ = createEffect(() =>
    this.actions.pipe(
      ofType(toggleAttachXML),
      switchMap(({ data }) =>
        this.apiGateway.post(`${this.baseUrl}/toggleattachxml`, data , data,  new HttpHeaders({ 'Content-Type': 'application/json' }))
          .pipe(
            switchMap(response => {
              const messages: Message[] = this.mapper.toErrorMessages(response);

              return messages?.length ? [setMessages(messages)] : [setMessages([]),loadTableData()];
            }),

            catchError(error => [setMessages(this.mapper.toErrorMessages(error.errorMessage || 'Server Error'))])
          ))
    )
  );


}


