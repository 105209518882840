<p-dialog [resizable]="false"
          *ngIf="displayDialog"
          [(visible)]="displayDialog"
          [modal]="true"
          [style]="{width: '500px', minHeight: '225px'}"
          (onHide)="onCancel()">
  <p-header>
    <span *ngIf="value === false">
      Activate Product: {{transactionType}} {{commodity}} {{market}}
    </span>
    <span *ngIf="value === true">
      Block Product: {{transactionType}} {{commodity}} {{market}}
    </span>
  </p-header>
  <p-messages [closable]="false" class="col-12" [value]="messages" *ngIf="messages"></p-messages>
  <div class="warning" *ngIf="value === true">
    <div class="warning__id-container">
      <i class="fa fa-exclamation-triangle"></i>
      <span>
        <b>Confirm you wish to stop confirming this product in eCM.</b><br>
        The eCM Gatekeeper will reject any submission for this product from you and your counterparties.
      </span>
    </div>
  </div>
  <div class="warning" *ngIf="value === false">
    <div class="warning__id-container warning__id-container--info">
      <i class="fa fa-info-circle"></i>
      <span>
        <b>Confirm you wish to activate this product for matching.</b><br>
        The eCM Gatekeeper will henceforth accept confirmations for this product from you and your matching partners.
      </span>
    </div>
  </div>
  <p-footer>
    <button pButton type="button" class="btn" (click)="onCancel()" label="Cancel"></button>
    <button pButton type="button" class="btn" *ngIf="value === false" (click)="onActivateItem()" label="Yes - Activate"></button>
    <button pButton type="button" class="btn" *ngIf="value === true" (click)="onBlockItem()" label="Yes - Block"></button>
  </p-footer>
</p-dialog>
