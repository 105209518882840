import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { InitialStartAndEndDate } from '../reports.model';

@Component({
  selector: 'cms-reports-date-filters',
  templateUrl: './reports-date-filters.component.html',
  styleUrls: ['./reports-date-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportsDateFiltersComponent implements OnInit, OnChanges {
  @Input() filters: InitialStartAndEndDate;
  form: UntypedFormGroup;
  defaultFilter: InitialStartAndEndDate = { start: null, end: null };

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      ...(this.filters || this.defaultFilter)
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.form && changes.filters) {
      this.form.patchValue({
        ...(this.filters || this.defaultFilter)
      });
      this.form.updateValueAndValidity();
    }
  }
}
