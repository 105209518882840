import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RemitDashboardComponent } from './remit-dashboard.component';
import { RemitTableModule } from '../datatable/remit-datatable.module';
import { CmsCommonModule } from '../../../common/cms-common.module';
import { RemitDashboardFilterComponent } from './filter/remit-dashboard-filter.component';
import { RemitDashboardService } from './remit-dashboard.service';
import { RemitDashboardBucketComponent } from './bucket/remit-dashboard-bucket.component';
import { TableModule } from 'primeng/table';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MessagesModule } from 'primeng/messages';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RadioButtonModule,
    TableModule,
    CmsCommonModule,
    RemitTableModule,
    MessagesModule,
    DropdownModule,
  ],
  declarations: [
    RemitDashboardComponent,
    RemitDashboardFilterComponent,
    RemitDashboardBucketComponent,
  ],
  providers: [
    RemitDashboardService
  ]
})
export class RemitDashboardModule {
}
