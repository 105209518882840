<div *ngIf="!hidden">
  <cms-datatable
    dataKey="id"
    [data]="rows"
    [totalRecords]="numberOfTotalRows"
    [tableColumns]="cols"
    [sortOrder]="sorting.sortOrder"
    [sortField]="sorting.columnName"
    [paginator]="true"
    [loading]="(remitTransactionsService.getLoading() | async)"
    [lazy]="!!(rows && rows.length)"
    [lazyLoadOnInit]="false"
    [hidden]="hidden"
    [autoLayout]="true"
    [scrollable]="false"
    selectionMode="single"
    (lazyLoad)="onLazyLoad($event)">

    <ng-template pTemplate="caption">
      <div class="row">
        <div class="col text-end flex justify-content-end ps-1">
          <div>
            <button pButton type="button" icon="fa fa-download" (click)="loadExcelExport()" label="Export" [disabled]="!hasData()"></button>
          </div>
          <div>
            <a routerLink='/settings/datatable/customize' [queryParams]="{documentType: docType}"> <!--  [queryParams]="{documentType: 'remitTable1'} -->
              <button pButton type="button" icon="fa fa-cog" label="Customize"></button>
            </a>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="customBody" let-row="row" let-rowIndex="rowIndex">
      <tr [pSelectableRow]="row">
        <ng-container *ngFor="let col of cols">
          <td *ngIf="col.visible && col.name === 'buttons'" [ngStyle]="getStyles(col)">
            <ng-container *ngIf="isT1Selected() || isT2Selected()">
              <div class="btn-wrapper">
                <a routerLink="/err/remit_transaction_sheet" [queryParams]="{ regime: 'REMIT', recordType: row.recordType, documentType: docType, historyId: row.id }" target="_blank">
                  <button pButton class="btn-icon" type="button" icon="fa fa-file-text-o" title="View {{row.recordType}}"></button>
                </a>
              </div>
            </ng-container>
            <ng-container *ngIf="isT2Selected()">
              <div class="btn-wrapper">
                <button pButton type="button" label="Exec" (click)="openExecutionsSearch(row.contractId)" title="Find Executions {{row.recordType}}"></button>
              </div>
            </ng-container>
            <ng-container *ngIf="isT3Selected() || isT4Selected()">
              <div class="btn-wrapper">
                <button pButton class="btn-icon" type="button" icon="fa fa-file-code-o" (click)="loadRemitXml(row.id, docType)" title="XML Export"></button>
              </div>
            </ng-container >
            <ng-container *ngIf="row.auditTrailButtonVisible">
              <div class="btn-wrapper">
                <a routerLink="/err/remit_audittrail" title="Audit Trail" [queryParams]="createQueryParams(docType, row)" target="_blank">
                  <button pButton class="btn-icon" type="button" icon="fa fa-search"></button>
                </a>
              </div>
            </ng-container>
            <ng-container *ngIf="row.cancelModifyButtonVisible">
              <div class="btn-wrapper">
                <a [routerLink]="getRouterLinkModify()" [queryParams]="{ regime: 'REMIT', recordType: row.recordType, documentType: docType, historyId: row.id }" target="_blank">
                  <button id="btnRemitDataTableModify" pButton class="btn-icon" type="button" label="M" title="Modify {{row.recordType}}"></button>
                </a>
              </div>
            </ng-container>
            <ng-container *ngIf="row.cancelModifyButtonVisible">
              <div class="btn-wrapper">
                <a [routerLink]="getRouterLinkCancel()" [queryParams]="{ regime: 'REMIT', recordType: row.recordType, documentType: docType, historyId: row.id }" target="_blank">
                  <button id="btnRemitDataTableCancel" pButton class="btn-icon" type="button" label="C" title="Cancel {{row.recordType}}"></button>
                </a>
              </div>
            </ng-container>
            <ng-container *ngIf="row.errorButtonVisible">
              <div class="btn-wrapper">
                <a [routerLink]="getRouterLinkError()" [queryParams]="{ regime: 'REMIT', recordType: row.recordType, documentType: docType, historyId: row.id }" target="_blank">
                  <button id="btnRemitDataTableError" pButton class="btn-icon" type="button" label="E" title="Delete {{row.recordType}}"></button>
                </a>
              </div>
            </ng-container>
          </td>
          <td *ngIf="col.visible && !col.editable && col.name !== 'buttons'" [ngStyle]="getStyles(col)" [pTooltip]="row[col.name]"  #cbdy tooltipPosition="top" [tooltipDisabled]="widthFit(row[col.name], cbdy)">
            <span>{{row[col.name]}}</span>
          </td>
          <td pEditableColumn *ngIf="col.visible && col.editable" [ngStyle]="getStyles(col)">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input type="text" [(ngModel)]="row[col.name]" class="form-control">
              </ng-template>
              <ng-template pTemplate="output">
                <span>{{row[col.name]}}</span>
              </ng-template>
            </p-cellEditor>
          </td>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="customEmptyMessage">
      <tr>
        <td *ngIf="!(remitTransactionsService.getLoading() | async)">
          No records found
        </td>
      </tr>
    </ng-template>
  </cms-datatable>
</div>
