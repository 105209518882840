import { createSelector } from '@ngrx/store';
import { getEsmState, State } from '../../reducers';
import { NettingPreviewState } from '../index';
import { GenerationDialogState } from './generation-dialog.reducers';

export const getNettingPreviewState = createSelector(getEsmState, (state: State) => state.nettingPreview);

export const getGenerationDialogState = createSelector(getNettingPreviewState, (state: NettingPreviewState) => state.generationDialog);

export const getNettingStatement = createSelector(getGenerationDialogState, (dialog: GenerationDialogState) => dialog.nettingStatement);




