import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromUserAccount from '../user-account/user-account.reducer';
import * as fromOrganisation from '../organisation/organisation.reducer';
import * as fromUserRole from '../user-role/user-role.reducer';
import * as fromFhaAccounts from '../fha-accounts/fha-accounts.reducers';
import * as fromOrgGroups from '../organisation-groups/organisation-groups.reducer';
import * as fromBillingReports from '../billing-reports/billing-reports.reducers';
import * as fromPartyData from '../party-data/party-data.reducers';
import * as fromBillingRunsData from '../billing-runs/index';
import * as fromElcomWhitelist from '../elcom-whitelist/elcom-whitelist.reducers';
import * as fromBillingTxReport from '../billing-tx-report/billing-tx-report.reducers';

export type Prefix = '[USER ACCOUNT]' | '[ORGANISATION]' | '[FHA ACCOUNTS]' | '[ORGANISATION GROUPS]' | '[BILLING HISTORY]' | '[PARTY DATA]' | '[BILLING RUNS]' | '[MAILING DETAILS]' | '[ELCOM WHITELIST]';

export const getAdminState = createFeatureSelector<State>('admin');

export interface State {
  userAccount: fromUserAccount.UserAccountsState;
  userRole: fromUserRole.UserRolesState;
  organisation: fromOrganisation.OrganisationsState;
  fhaAccounts: fromFhaAccounts.FhaAccountsState;
  organisationGroups: fromOrgGroups.OrganisationGroupsState;
  billingReports: fromBillingReports.BillingReportsState;
  partyData: fromPartyData.PartyDataState;
  billingRuns: fromBillingRunsData.BillingRunsState;
  elcomWhitelist: fromElcomWhitelist.ElcomWhitelistState;
  billingTxReport: fromBillingTxReport.BillingTxReportState;
}

export const reducers: ActionReducerMap<State> = {
  userAccount: fromUserAccount.userAccountReducer,
  userRole: fromUserRole.reducer,
  organisation: fromOrganisation.organisationsReducer,
  fhaAccounts: fromFhaAccounts.fhaAccountsReducer,
  organisationGroups: fromOrgGroups.organisationGroupsReducer,
  billingReports: fromBillingReports.billingReportsReducer,
  partyData: fromPartyData.partyDataReducer,
  billingRuns: fromBillingRunsData.adminBillingRunsReducer,
  elcomWhitelist: fromElcomWhitelist.elcomWhitelistReducer,
  billingTxReport: fromBillingTxReport.billingTxReportReducer
};
