import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../../../auth/authentication.service';
import { Observable, of, Subject } from 'rxjs';
import { RemitOmpWhitelistService } from './remit-omp-whitelist.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cms-remit-omp-whitelist-upload-panel',
  templateUrl: './remit-omp-whitelist-upload-panel.component.html',
  styleUrls: ['./remit-omp-whitelist-upload-panel.component.scss']
})
export class RemitOmpWhitelistUploadPanelComponent implements OnInit, OnDestroy {

  @ViewChild('fileUpload', { static: false })
  fileUpload: ElementRef;

  private onDestroy$ = new Subject();
  public uploadForm: UntypedFormGroup;

  fileSelected: boolean = false;
  uploadPanelVisible = false;
  csvFile: File;
  fileName: string = '';

  constructor(private formBuilder: UntypedFormBuilder, private remitOmpWhitelistService: RemitOmpWhitelistService) {
    this.uploadForm = this.formBuilder.group({
      csvFile: ['', Validators.required]
    });
  }

  ngOnInit() {

    this.remitOmpWhitelistService.onPresetValuesChanged().subscribe(presetValues => {
      if(presetValues){
        this.uploadPanelVisible = presetValues.uploadPanelVisible;
      }
    });

    this.remitOmpWhitelistService.loadPresetValues();
  }

  onSelect(event): void {
    const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    if (files) {
      this.csvFile = files[0];
      this.fileName = this.csvFile.name;
      this.fileSelected = true;
    } else {
      this.fileName = '';
    }
  }

  browseFiles() {
    this.reset();
    document.getElementById('input-file-filename-remit-omp-whitelist').click();

    // prevents page reload
    return false;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
    this.remitOmpWhitelistService.setPresetValues({uploadPanelVisible: false});
    this.reset();
  }

  private reset(){
    if(this.fileUpload){
      this.fileUpload.nativeElement.value = null;
    }
    this.csvFile = null;
    this.fileName = '';
    this.fileSelected = false;
    this.remitOmpWhitelistService.setMessage(null);
  }

  submit() {
    if(this.uploadForm.controls['csvFile']){
      this.remitOmpWhitelistService.uploadFile(this.csvFile);
    }
  }

}
