import { Component, OnDestroy, OnInit } from '@angular/core';
import { OrganisationService } from '@common/organisation.service';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { SubscriptionSettingsState } from '../../state/settings/subscription/subscription-settings.reducers';
import { Message } from 'primeng/api';
import * as fromSelectors from '../../state/settings/subscription/subscription-settings.selectors';
import * as fromActions from '../../state/settings/subscription/subscription-settings.actions';
import { DocumentSide, DocumentType, WhitelistRecipientsData, whitelistRecipientsTableColumns } from './email-subscription.model';

@Component({
  selector: 'cms-email-subscription',
  templateUrl: './email-subscription.component.html',
  styleUrls: ['./email-subscription.component.scss']
})
export class EmailSubscriptionComponent implements OnInit, OnDestroy {

  subscriptions$: Subscription = new Subscription();
  messages$: Observable<Message[]> = this.store$.select(fromSelectors.getMessages);
  organisation$: Observable<boolean> = this.organisationService.onRefresh().pipe(filter(auth => auth));
  editAllowed$: Observable<boolean> = this.store$.pipe(select(fromSelectors.getEditAllowed));
  matchedInvoicePdf$: Observable<boolean> = this.store$.pipe(select(fromSelectors.getMatchedInvoicePdf));
  matchedNettingPdf$: Observable<boolean> = this.store$.pipe(select(fromSelectors.getMatchedNettingPdf));
  matchedInvoiceXml$: Observable<boolean> = this.store$.pipe(select(fromSelectors.getMatchedInvoiceXml));
  matchedNettingXml$: Observable<boolean> = this.store$.pipe(select(fromSelectors.getMatchedNettingXml));
  documentType$: Observable<DocumentType> = this.store$.pipe(select(fromSelectors.getDocumentType));
  documentSide$: Observable<DocumentSide> = this.store$.pipe(select(fromSelectors.getDocumentSide));
  data$: Observable<WhitelistRecipientsData[]> = this.store$.select(fromSelectors.getTableData);

  readonly DocumentType = DocumentType;
  readonly DocumentSide = DocumentSide;
  columns = whitelistRecipientsTableColumns;

  constructor(private store$: Store<SubscriptionSettingsState>,
              public organisationService: OrganisationService) { }

  ngOnInit(): void {
    this.subscriptions$
      .add(this.organisation$.subscribe(() => {
        this.store$.dispatch(fromActions.loadData());
      }));
  }

  onMatchedInvoicePdfChanged(checked: boolean): void {
    this.store$.dispatch(fromActions.setMatchedInvoicePdf(checked));
  }

  onMatchedNettingPdfChanged(checked: boolean): void {
    this.store$.dispatch(fromActions.setMatchedNettingPdf(checked));
  }

  onMatchedInvoiceXmlChanged(checked: boolean): void {
    this.store$.dispatch(fromActions.setMatchedInvoiceXml(checked));
  }

  onMatchedNettingXmlChanged(checked: boolean): void {
    this.store$.dispatch(fromActions.setMatchedNettingXml(checked));
  }

  setDocumentType(type: DocumentType) {
    this.store$.dispatch(fromActions.setDocumentType(type));
  }

  setDocumentSide(side: DocumentSide) {
    this.store$.dispatch(fromActions.setDocumentSide(side));
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
    this.store$.dispatch(fromActions.setToDefaultAction());
  }

}

