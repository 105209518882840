<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>

  <h1>eSM Invoice Finder</h1>

  <cms-table-toast [selected]="selected"></cms-table-toast>

<div class="panel-box margin-0">
  <div class="row">
    <p-messages [value]="messages$ | async" [closable]="true" class="ps-3 pe-3 pb-2 w-100" ></p-messages>
    <cms-not-found-ids-warning class="col-12"
                               [ids]="notFoundIds$ | async"
                               [allCount]="idsCount$ | async">
    </cms-not-found-ids-warning>
    <div class="col-12 col-xl-4 col-lg-6 col-md-12 cms-id-filters">
      <cms-id-filters class="h-100"
                      [idFiltersLabels]="idFiltersLabels"
                      [disabled]="loading$ | async"
                      [filters]="inputIds$ | async"
                      (filter)="onFilterByIds($event)"
                      (reset)="onResetByIds()">
      </cms-id-filters>
    </div>
    <div class="col-12 col-xl-8 col-lg-12 col-md-12 properties-filters">
      <cms-invoice-finder-properties-filter *ngIf="filtersProps$ | async"
                                            [disabled]="(loading$ | async) || !(loaded$ | async)"
                                            [myFilters]="myFilters$ | async"
                                            [defaultFilter]="defaultFilter$ | async"
                                            [dialog]="dialog$ | async"
                                            [messages]="messages$ | async"
                                            [allDealsFilter]="allDealsFilter$ | async"
                                            [filters]="filtersProps$ | async"
                                            [commodities]="commodities$ | async"
                                            [counterParties]="counterParties$ | async"
                                            [invoiceTypes]="invoiceTypes$ | async"
                                            [deliveryPoints]="deliveryPoints$ | async"
                                            (setDefaultFilter)="onSetToDefault($event)"
                                            (selectMyFilter)="onSelectMyFilter($event)"
                                            (openSaveCurrent)="onOpenSaveCurrent()"
                                            (confirmSaveCurrent)="onConfirmSaveCurrent($event)"
                                            (replaceFilter)="onConfirmReplaceCurrent($event)"
                                            (manageFilters)="onManageFilters()"
                                            (deleteFilter)="onDeleteFilter($event)"
                                            (closeDialog)="onCloseMyFiltersDialog()"
                                            (filter)="onFilterByProp($event)"
                                            (reset)="onResetFilters()">
      </cms-invoice-finder-properties-filter>
    </div>
  </div>
  <cms-datatable [styleClass]="'mt-3'"
                 [tableId]="'invoice-finder-table'"
                 *ngIf="filtersBy$ | async "
                 [currentPage]="(paging$ | async).page"
                 [tableColumns]="columns$ | async"
                 [sortField]="(sorting$ | async).columnName"
                 [sortOrder]="(sorting$ | async).sortOrder"
                 [rows]="(paging$ | async)?.entriesPerPage"
                 [loading]="loading$ | async"
                 [data]="(data$ | async)?.values"
                 [totalRecords]="(data$ | async)?.count"
                 [autoLayout]="true"
                 [scrollable]="false"
                 [lazyLoadOnInit]="false"
                 (lazyLoad)="onLazyLoad($event)"
                 [checkboxRow]="true"
                 [(selected)]="selectedRows">

    <ng-template pTemplate="caption">
      <div class="float-left p-1 !font-bold">Found Invoices</div>
      <div class="float-left date-label"></div>
      <cms-deal-table-caption (openAddNoteDialog)="onOpenAddNoteDialog()"
                              (exportEvent)="onExport()"
                              (clickCustomize)="onClickCustomize()"
                              [exportDisable]="!(data$ | async)?.values"
                              [addNoteDisable]="!(selectedIds$ | async).length"
                              [multiAgreeDisable]="!(selectedIds$ | async).length"
                              [showMultiAgreeButton]="isMultiAgreeVisible"
                              (openAgreeDialog)="onOpenMultiAgreeDialog()">
      </cms-deal-table-caption>
    </ng-template>

    <ng-template pTemplate="body" let-row="row" let-column="column">
      <!--document button-->
      <td *ngIf="column.type === 'button' && column.name === 'openDocument'" [ngStyle]="column.style">
        <div class="btn-wrapper inline-block">
          <a target="_blank"
            id="invoicefinder-html"
            routerLink="/esm/invoicefinder/esm_document"
            [queryParams]="{ id: row.id }">
            <button pButton type="button" icon="fa fa-file" title="Document"></button>
          </a>
        </div>
      </td>
      <!--document (xml) button-->
      <td *ngIf="column.type === 'button' && column.name === 'openDocumentXml'" [ngStyle]="column.style">
        <div class="btn-wrapper inline-block">
          <button pButton type="button" icon="fa fa-file-code-o" title="Document (XML)" (click)="onDownloadXml(row)"></button>
        </div>
      </td>
      <!--audit trail button-->
      <td *ngIf="column.type === 'button' && column.name === 'auditTrail'" [ngStyle]="column.style">
        <div class="btn-wrapper inline-block">
          <a routerLink="/esm/audittrail/invoice" title="Audit Trail" [queryParams]="getDisplayParams(row)" target="_blank">
            <button pButton type="button" icon="fa fa-search" title="Audit Trail" id="button-esm-invoice-finder-audit-trail"></button>
          </a>
        </div>
      </td>
      <!--near match button-->
      <td *ngIf="column.type === 'button' && column.name === 'nearMatch'">
        <cms-esm-near-match-button class="comp-match-cell" [row]="row" route="/esm/match/invoice"></cms-esm-near-match-button>
      </td>
      <!--mismatch button-->
      <td *ngIf="column.type === 'button' && column.name === 'misMatch'">
        <cms-esm-mis-match-button class="comp-match-cell" [row]="row" route="/esm/compare/invoice"></cms-esm-mis-match-button>
      </td>
      <!--note button-->
      <td *ngIf="column.type === 'button' && column.name === 'notes'" [ngStyle]="column.style">
        <cms-notes-cell [confirmationId]="row.id"
          [data]="row"
          [notes]="row.notes"
          (view)="onViewNotes($event)">
        </cms-notes-cell>
      </td>
      <!--pdf button-->
      <td *ngIf="column.type === 'button' && column.name === 'pdf'">
        <div class="btn-wrapper inline-block" *ngIf="row.pdfButtonVisible">
          <button pButton type="button" icon="fa fa-file-pdf-o" title="Document (PDF)" (click)="onDownloadPdf(row)"></button>
        </div>
      </td>
    <!--      resend button-->
      <td *ngIf="column.type === 'button' && column.name === 'resend'">
        <div class="btn-wrapper inline-block" *ngIf="row.resendButtonVisible">
          <button pButton class="ui-button-resend-pdf" type="button" iconPos="right" icon="fa fa-solid fa-envelope" title="Resend Document (PDF)" (click)="resendDocument(row)"></button>
        </div>
      </td>
      <!--      agree button-->
      <td *ngIf="column.type === 'button' && column.name === 'agree'">
        <div class="btn-wrapper inline-block" *ngIf="row.agreeButtonVisible">
          <button pButton class="btn" type="button" title="Agree Invoice" (click)="agreeItem(row)" label="Agree"></button>
        </div>
      </td>
      <!--      dispute button-->
      <td *ngIf="column.type === 'button' && column.name === 'dispute'">
        <div class="btn-wrapper inline-block" *ngIf="row.disputeButtonVisible">
          <button pButton class="btn" type="button" title="Dispute Invoice" (click)="disputeItem(row)" label="Dispute"></button>
        </div>
      </td>
    </ng-template>
    <ng-template pTemplate="customEmptyMessage">
      <tr>
        <td colspan="100" *ngIf="!(loading$ | async)">
          No records found
        </td>
      </tr>
    </ng-template>
  </cms-datatable>
</div>
<section>
  <cms-add-note-dialog *ngIf="(dialog$ | async)?.name === 'ADD_NOTES'"
                      [display]="(dialog$ | async)?.name === 'ADD_NOTES'"
                      [messages]="(dialog$ | async)?.messages"
                       [headerText]="'Add note to invoices'"
                      [confirmationIds]="selectedIds$ | async"
                      (cancel)="onCancelAddNotes()"
                      (save)="onConfirmAddNotes($event)">
  </cms-add-note-dialog>
  <cms-view-confirmation-notes-dialog *ngIf="(dialog$ | async)?.name === 'VIEW_NOTES'"
                                      [display]="(dialog$ | async)?.name === 'VIEW_NOTES'"
                                      [messages]="(dialog$ | async)?.messages"
                                      [data]="(dialog$ | async)?.data"
                                      (cancel)="onCancelViewNotes()"
                                      [headerText]="'View invoice notes'"
                                      (save)="onConfirmAddNotes($event)">
  </cms-view-confirmation-notes-dialog>

  <cms-esm-invoice-agree-dialog *ngIf="(dialog$ | async)?.name === 'AGREE'"
                                [display]="(dialog$ | async)?.name === 'AGREE'"
                                [messages]="(dialog$ | async)?.messages"
                                [headerText]="'Invoice Agreement'"
                                [invoices]="(dialog$ | async)?.data"
                                (cancel)="onCancelAgree()"
                                (agree)="onConfirmAgree($event)">
  </cms-esm-invoice-agree-dialog>

  <cms-esm-invoice-dispute-dialog *ngIf="(dialog$ | async)?.name === 'DISPUTE'"
                                  [display]="(dialog$ | async)?.name === 'DISPUTE'"
                                  [messages]="(dialog$ | async)?.messages"
                                  [headerText]="'Invoice Dispute'"
                                  [invoice]="(dialog$ | async)?.data"
                                  (cancel)="onCancelDispute()"
                                  (dispute)="onConfirmDispute($event)">
  </cms-esm-invoice-dispute-dialog>

</section>

</ng-template>
