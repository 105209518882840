import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { RemitTradeEntryTable2Service } from '../remit-trade-entry-table2.service';
import { takeUntil } from 'rxjs/operators';
import { RemitTradeEntryTable2VolumeDataModel } from '../remit-trade-entry-table2-data.model';
import { SelectItem } from 'primeng/api';
import { RemitTable2TradeReferencesModel, RemitTable2TradeVolumeModel } from '../../../trade/table2/modify/remit-table2-trade-entry.model';

@Component({
  selector: 'cms-remit-trade-entry-table2-volume',
  templateUrl: './remit-trade-entry-table2-volume.component.html',
  styleUrls: ['./remit-trade-entry-table2-volume.component.scss']
})
export class RemitTradeEntryTable2VolumeComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  formGroup: UntypedFormGroup;


  @Input()
  onReset: Subject<boolean>;

  @Input()
  data: RemitTable2TradeVolumeModel;

  private onDestroy$ = new Subject();

  volumeData: RemitTradeEntryTable2VolumeDataModel;

  contractQuantity: SelectItem[];
  volumeOptionality: SelectItem[];
  optionalFrequency: SelectItem[];

  constructor(private tradeEntryService: RemitTradeEntryTable2Service) {
    this.onReset = new Subject<boolean>();
  }

  ngOnInit() {
    this.formGroup.addControl('totalNotionalContractQuantityValue', new UntypedFormControl(''));
    this.formGroup.addControl('totalNotionalContractQuantityUnit', new UntypedFormControl(''));
    this.formGroup.addControl('volumeOptionality', new UntypedFormControl(''));
    this.formGroup.addControl('volumeOptionalityFrequency', new UntypedFormControl(''));

    this.tradeEntryService.onTradeEntryDataChange().pipe(takeUntil(this.onDestroy$)).subscribe(formData => {
      if (formData && formData.volume) {
        this.volumeData = formData.volume;
      }

      this.initFormValues();
      this.fillFormWithData();
    });

    this.onReset.pipe(takeUntil(this.onDestroy$))
      .subscribe(   response => {
        if (response) {
          this.reset();
        }
      });
  }


  ngOnChanges(changes: SimpleChanges): void {
      this.fillFormWithData();
  }

  appendValidationErrorClass(fieldName: string): string {
    if (this.formGroup.controls[fieldName].hasError('error')) {
      return 'error';
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  private initFormValues() {
    if (this.volumeData) {
      this.contractQuantity = [{ label: ' ', value: null }];
      this.contractQuantity = this.contractQuantity.concat(this.tradeEntryService.convertKeyValueToSelectItem(this.volumeData.contractQuantity));
      this.volumeOptionality = [{ label: ' ', value: null }];
      this.volumeOptionality = this.volumeOptionality.concat(this.tradeEntryService.convertKeyValueToSelectItem(this.volumeData.volumeOptionality));
      this.optionalFrequency = [{ label: ' ', value: null }];
      this.optionalFrequency = this.optionalFrequency.concat(this.tradeEntryService.convertKeyValueToSelectItem(this.volumeData.optionalFrequency));
    }
  }

  private reset() {
    this.formGroup.controls['totalNotionalContractQuantityValue'].reset();
    this.formGroup.controls['totalNotionalContractQuantityUnit'].reset();
    this.formGroup.controls['volumeOptionality'].reset();
    this.formGroup.controls['volumeOptionalityFrequency'].reset();
  }

  private fillFormWithData(){
    if (this.formGroup && this.data) {
      this.formGroup.patchValue({
        totalNotionalContractQuantityValue: this.data.totalNotionalContractQuantityValue,
        totalNotionalContractQuantityUnit: this.data.totalNotionalContractQuantityUnit,
        volumeOptionality: this.data.volumeOptionality,
        volumeOptionalityFrequency: this.data.volumeOptionalityFrequency
      });
    }
  }
}
