<p-dialog *ngIf="display"
          header="Save Current Filter Settings"
          [visible]="display"
          [modal]="true"
          (visibleChange)="onVisibleChange($event)"
          styleClass="save-my-current-dialog"
          appendTo="body"
          [style]="{width: '600px'}">
  <p-messages [value]="messages" [closable]="true"></p-messages>
  <div class="margin-15">
    <form [formGroup]="form" class="row" autocomplete="off">
      <div class="col-12 margin-bottom-12 row">
        <button class="btn" type="button" (click)="onSaveAs()">Save As</button>
        <input type="text" class="form-control col-6 padding-6 filterName" formControlName="filterName" id="filterName" [ngClass]="{'error': errors['filterName']}" [maxlength]="40">
        <label class="justify-content-center"><input class="checkbox" type="checkbox" formControlName="defaultFilter">use as default</label>
      </div>
      <div class="col-12 margin-bottom-12 row">
        <button class="btn" type="button" (click)="onReplace()" [disabled]="form.get('filterNames').invalid">Replace</button>
        <p-dropdown appendTo="body"
                    [style]="{width: '397px'}"
                    [options]="filtersOptions"
                    id="filterNames"
                    styleClass="filterNames col-8 padding-6"
                    [ngClass]="{'error': errors['filterNames']}"
                    formControlName="filterNames">
        </p-dropdown>
      </div>
    </form>
  </div>
  <p-footer>
    <button class="btn" type="button" (click)="onCancel()">Cancel</button>
  </p-footer>
</p-dialog>
