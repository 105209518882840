<p-dialog header="Confirm Generation of Netting Statement"
          [visible]="true"
          [modal]="true"
          [contentStyle]="{overflow: 'auto', height: '550px', width: '650px'}"
          [positionTop]="110"
          [resizable]="false"
          [closable]="false"
          [style]="{width: '650px', minWidth: '650px', height: '550px', minHeight: '550px'}">
  <div class="">
    <div class="flex">
      <div style="margin-top: 1rem">
        <i class="fa fa-exclamation-circle fa-3x"></i>
      </div>
      <div>
        <div class="ms-3 mt-3">
          <span>Confirm that you want to generate the following netting statement:</span>
          <div class="mb-2"></div>
          <br class="mb-1">
          <span>Sender = </span> {{nettingStatement?.senderId}}
          <br>
          <span>Receiver = </span> {{nettingStatement?.receiverId}}
          <div class="mb-2"></div>
          <br class="mb-1">
          <span>Commodities:</span>  {{nettingStatement?.commodities}}
          <br>
          <span>Agreement:</span>  {{nettingStatement?.agreement}}
          <br>
          <span>Currency:</span>  {{nettingStatement?.currency}}
          <br>
          <span>Payment date:</span>  {{nettingStatement?.paymentDate}}
          <div class="mb-2"></div>
          <br class="mb-1">
        </div>
        <div>
          <section>
            <div class="mt-3">
              <span class="text-bold" *ngIf="openInvoices.length>0">The following invoices which belong to the above netting category are not matched and will not appear on the netting statement: </span>
            </div>
            <div class="inline-block  w-100" style="text-align: left;">
              <p-listbox class="border-0"
                       [listStyle]="getStyle()"
                       [readonly]="true"
                       [options]="openInvoices"
                        *ngIf="openInvoices.length>0">
              </p-listbox>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
  <hr class="full-width mb-0 mt-0">
  <p-footer>
    <button class="btn margin-right-3" type="button" (click)="onCancel()">Cancel</button>
    <button class="btn" type="button" (click)="onConfirm()">Yes - Generate</button>
  </p-footer>
</p-dialog>
