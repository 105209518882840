import { Component, OnDestroy, OnInit } from '@angular/core';
import { Column, DatatableConfig, PagerInfo } from '@common/shared/datatable.model';
import { Subject } from 'rxjs';
import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import { RemitOmpWhitelistSearchQuery, RemitOmpWhitelistService } from '../remit-omp-whitelist.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { DatatableState } from '@common/datatable/datatable.model';

@Component({
  selector: 'cms-remit-omp-whitelist-datatable',
  templateUrl: './remit-omp-whitelist-datatable.component.html',
  styleUrls: ['./remit-omp-whitelist-datatable.component.scss']
})
export class RemitOmpWhitelistDatatableComponent implements OnInit, OnDestroy {
  private tableColumns: Column[] = RemitOmpWhitelistDatatableConfig.TABLE_COLUMNS;
  pagerInfo: PagerInfo;
  loading: boolean = false;
  records = [];
  recordCount: number;
  sortBy: string = 'mpCode';
  hidden: boolean = true;
  private onDestroy$ = new Subject();
  filterForm: UntypedFormGroup;
  defaults = DatatableConfig;

  constructor(private service: RemitOmpWhitelistService, private formBuilder: UntypedFormBuilder) {
    this.filterForm = formBuilder.group({
      mpCode: [''],
    });
  }

  ngOnInit() {
    this.pagerInfo = PagerInfo.calcPagerInfo(0, 0, DatatableConfig.DEFAULT_ROWS);

    const searchQuery = RemitOmpWhitelistSearchQuery.createEmptySearchQuery();
    this.service.setSearchQuery(searchQuery);

    this.service.onSearchQueryChange().pipe(takeUntil(this.onDestroy$)).subscribe(query => {
      this.loadWhitelist(query);
    });

    this.service.onRefresh().pipe(takeUntil(this.onDestroy$)).subscribe(refresh => {
      if (refresh) {
        this.loadWhitelist(this.service.getSearchQuery());
      }
    });
  }

  get cols() {
    return this.tableColumns;
  }

  getRecordCount() {
    return this.recordCount;
  }

  // event fired by primeng datatable
  onLazyLoad({page, perPage: entriesPerPage, sortField = 'mpCode', sortOrder}: DatatableState) {
    const pagingFilter: PagingFilter = {
      entriesPerPage,
      page
    };

    this.sortBy = sortField|| 'mpCode';
    const columnName = this.sortBy;
    const sortingFilter: SortingFilter = { columnName, sortOrder };
    const searchQuery = this.service.getSearchQuery();

    // update search query to table status
    if (searchQuery) {
      searchQuery.paging = pagingFilter;
      searchQuery.sorting = sortingFilter;
      this.service.setSearchQuery(searchQuery);
    }
  }

  loadWhitelist(searchQuery: RemitOmpWhitelistSearchQuery) {
    if (searchQuery) {
      const currentPage = searchQuery.paging.page;
      const resultsPerPage = searchQuery.paging.entriesPerPage;
      this.loading = true;

      this.service.search(searchQuery).pipe(takeUntil(this.onDestroy$)).subscribe(result => {

        this.loading = false;
        this.records = result.rows;
        this.pagerInfo = PagerInfo.calcPagerInfo(result.recordCount, currentPage, resultsPerPage);
        this.recordCount = result.recordCount;
      });
    }
  }

  filter() {
    const searchQuery = RemitOmpWhitelistSearchQuery.createEmptySearchQuery();
    searchQuery.sorting = this.service.getSearchQuery().sorting;
    searchQuery.filterValue = this.filterForm.controls['mpCode'].value;
    this.service.setSearchQuery(searchQuery);
  }

  reset() {
    this.filterForm.reset();
    this.filter();
  }

  isFieldAlert(value): boolean {
    const alerting: string[] = ['Block', 'not registered', 'unknown'];
    return alerting.indexOf(value) !== -1;
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }
}

export const defaultStyles = {
  'text-overflow': 'ellipsis',
  'overflow': 'hidden',
  'white-space': 'nowrap'
};

export namespace RemitOmpWhitelistDatatableConfig {
  export const TABLE_COLUMNS: Column[] = [
    { name: 'mpCodeType', title: 'Code Type', style: { ...defaultStyles, 'width': '60px', 'text-align': 'left' }, sortable: true, visible: true },
    { name: 'mpCode', title: 'MP Code', style: { ...defaultStyles, 'width': '100px', 'text-align': 'left' }, sortable: true, visible: true },
    { name: 'remitProcessingModeEnum', title: 'REMIT Mode', style: { ...defaultStyles, 'width': '80px', 'text-align': 'left' }, sortable: true, visible: true },
    { name: 'errParty', title: 'eRR Party', style: { ...defaultStyles, 'width': '100px', 'text-align': 'left' }, sortable: true, visible: true },
    { name: 'mpAcerCode', title: 'Reported ACER Code', style: { ...defaultStyles, 'width': '100px', 'text-align': 'left' }, sortable: true, visible: true },
    {
      name: 'leiRegisteredName',
      title: 'Registered Name (LEI Registry)',
      style: { ...defaultStyles, 'width': '280px', 'text-align': 'left' },
      sortable: true,
      visible: true
    }
  ];

}
