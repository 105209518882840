import { createSelector } from '@ngrx/store';
import { getEcmState, State } from '../reducers';
import { MonitoringCategories, MonitoringCategory, tabsList } from '../../monitoring/monitoring.model';
import * as fromReducer from './monitoring.reducers';

export const getEcmMonitoringState = createSelector(getEcmState, (state: State) => state.monitoring);
export const getMessages = createSelector(getEcmMonitoringState, (state: fromReducer.MonitoringState) => state.messages);
export const getLastSnapshotMessage = createSelector(getEcmMonitoringState, (state: fromReducer.MonitoringState) => state.lastSnapshotMessage);
export const getNewSnapshotLoading = createSelector(getEcmMonitoringState, (state: fromReducer.MonitoringState) => state.newSnapshotLoading);
export const getChangeDate = createSelector(getEcmMonitoringState, (state: fromReducer.MonitoringState) => state.changeDate);
export const getLogs = createSelector(getEcmMonitoringState, (state: fromReducer.MonitoringState) => state.logs);
export const getCategories = createSelector(getEcmMonitoringState, (state: fromReducer.MonitoringState) => state.categories);
export const getColumns = createSelector(getEcmMonitoringState, (state: fromReducer.MonitoringState) => state.columns);
export const getData = createSelector(getEcmMonitoringState, (state: fromReducer.MonitoringState) => state.data);
export const getDoneCheckinLoading = createSelector(getEcmMonitoringState, (state: fromReducer.MonitoringState) => state.doneCheckingLoading);
export const getLoading = createSelector(getEcmMonitoringState, (state: fromReducer.MonitoringState) => state.loading);
export const getSelectedCategoryIndex = createSelector(getCategories,
  (categories: MonitoringCategory[]) => {
    const index = categories && categories.length ?
      categories.findIndex(item => !item.checked) :
      0;
    return index !== -1 ? index : (categories.length - 1);
  });

export const getCurrentCategory = (data: MonitoringCategories[] = tabsList) => createSelector(getSelectedCategoryIndex,
  (index: number): MonitoringCategories => {
    return data[index];
  });
export const getLoaded = createSelector(getEcmMonitoringState, (state: fromReducer.MonitoringState) => state.loaded);
