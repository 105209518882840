export class Utils {

  /**
   * Partition by size
   *
   * example: partition([1,2,3,4,5,6], 2);
   *          becomes:  [[1,2], [3,4], [5,6]]
   * @param inputColl
   * @param size
   */
  public static partition<T>(inputColl: T[], size: number): T[][] {
    if (size < 1) {
      return [inputColl];
    }

    function iter(acc, coll) {
      if (!coll.length) {
        return acc;
      }
      if (coll.length < size) {
        return iter(acc, []);
      }
      const part = coll.slice(0, size);
      return iter(acc.concat([part]), coll.slice(size));
    }

    return iter([], inputColl);
  }

  /**
   * Partition by condition in left and right part
   * example: partitionInLeftAndRightBy((x) => x % 2 === 0, [1, 2, 3, 4, 5, 6, 7, 8]);
   *          becomes:  [[2, 4, 6, 8], [1, 3, 5, 7]]
   * @param isLeftCondition
   * @param arr
   */
  public static partitionInLeftAndRightBy<T>(isLeftCondition: (value: T) => boolean, arr: T[]) {
    return (arr || []).reduce((acc, value) =>
        isLeftCondition(value) ?
          [[...acc[0], value], acc[1]] :
          [acc[0], [...acc[1], value]]
      , [[], []]);
  }

  /**
   * map an uri string to an uri segment object
   */
  public static uriParser(href: string) {
    const parser = document.createElement('a');
    parser.href = '' + href;
    return {
      protocol: parser.protocol,
      hostname: parser.hostname,
      port: parser.port,
      pathname: parser.pathname,
      search: parser.search,
      hash: parser.hash,
      host: parser.host
    };
  }

  /**
   * Arrays Equals Ignoring Order
   *
   * example: equalsWithIgnoreOrder(['A','B'], ['B', 'A']):
   *          becomes: true
   *
   * attention: works currently only for flat arrays with simple types
   */
  public static equalsWithIgnoreOrder<T>(a: T[], b: T[]) {
    if (a == null && b == null) {
      return true;
    }
    const aTmp = [...(a || [])], bTmp = [...(b || [])];
    aTmp.sort();
    bTmp.sort();
    return JSON.stringify(aTmp) === JSON.stringify(bTmp);
  }

}
