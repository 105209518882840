
<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>

<h1>Product Definitions for eCM Gatekeeper</h1>
<div class="panel-box margin-0">
  <cms-info ngPreserveWhitespaces>
    <!--@formatter:off (markdown rendering breaks, when the following tags are formatted)-->

    __eCM Gatekeeper Products__

    Register/delete product definitions for the eCM Gatekeeper here.
    Newly added products are implicitly blocked for all CMS organisation - they have to opt in before they can use the product.

    __Functions__

    * **Download:** download the eCM Gatekeeper Report
    * **Add (a product):** adds a product definition to the list
    * **Clear (input form):** empties the input fields
    * **Delete (a product):** press the "X" button next to a product, only available if no organisation has activated it

    __Usage Hints__

    * In each input field press * (asterisk) to open a list of auto-complete options
    * The sort order is fixed: (1) predefined transaction type order (2) alphabetical by commodity (3) alphabetical by market
    * When entering commodities and markets take great care that they (1) comply with the eCM standard and (2) are free of leading/trailing white-space

    __Physical Products__

    * Transaction types: FOR, PHYS_INX, OPT, OPT_PHYS_INX
    * Commodity is mandatory: see definition "EnergyProductType" in the eCM standard
    * Markets are mandatory for some commodities (e.g. Power, Gas), forbidden for others (CER, Coal) - check the business rules for field "Market"

    __Financial Products__

    * Transaction types: FXD_SWP, FLT_SWP, OPT_FXD_SWP, OPT_FLT_SWP, OPT_FIN_INX
    * Commodity is mandatory: see definition of "IndexCommodityType" in the eCM standard
    * Two additional synthetic values are defined for commodity:
    * [Formula] applies to any deal which contains at least 1 formula ID instead of naming an index commodity
    * [X-Commodity] (short for "cross commodity") applies to any deal which mixes different index commodities
    * Market is always left blank
    <!--@formatter:on-->
  </cms-info>
  <div class="row margin-0">
    <button class="btn export-btn col-auto"
            pButton
            type="button"
            icon="fa fa-download"
            label="Download Gatekeeper Settings"
            (click)="onDownload()">
    </button>
  </div>
  <cms-ecm-products-add-panel [data]="add$ | async"
                              [messages]="messages$ | async"
                              [commodities]="commodities$ | async"
                              [markets]="markets$ | async"
                              [transactionTypes]="transactionTypes$ | async"
                              (clear)="onClear()"
                              (confirm)="onAdd($event)">
  </cms-ecm-products-add-panel>
  <cms-datatable *ngIf="data$ | async as data"
                 [tableColumns]="dataTableColumns"
                 [loading]="loading$ | async"
                 [data]="data"
                 [autoLayout]="true"
                 [scrollable]="false"
                 [paginator]="false"
                 styleClass="decent-max-width"
                 [lazy]="false">
    <ng-template pTemplate="body" let-row="row" let-column="column">
      <td *ngIf="column.type === 'deleteButton'" [ngStyle]="column.style">
        <div class="btn-wrapper" *ngIf="row.deleteButtonVisible">
          <button class="btn btn-icon fa fa-times" type="button" label="Delete" (click)="onDeleteItem(row.productId)" title="Delete Product"></button>
        </div>
      </td>
    </ng-template>
  </cms-datatable>
</div>

</ng-template>
