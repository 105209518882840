import { Column } from '../../../common/shared/datatable.model';

export namespace CpmlDealFinderDataTableConfig {

  export const defaultStyles = {
    'text-overflow': 'ellipsis',
    'overflow': 'hidden',
    'white-space': 'nowrap'
  };

  const TABLE_COLUMNS: { [name: string]: Column } = {
    receiptTimestamp: { title: 'Receipt', style: { ...defaultStyles, 'width': '130px' } },
    updateTimestamp: { title: 'Update', style: { ...defaultStyles, 'width': '130px' } },
    buttons: { title: '', style: { ...defaultStyles, 'width': '70px' }, sortable: false },
    documentType: { title: 'Document Type', style: { ...defaultStyles, 'min-width': '100px' } },
    documentId: { title: 'Document ID', style: { ...defaultStyles, 'width': '450px' } },
    documentVersion: { title: 'Doc. Version', style: { ...defaultStyles, 'width': '160px' } },
    tradeId: { title: 'Trade ID', style: { ...defaultStyles, 'min-width': '100px' } },
    agent: { title: 'Agent', style: { ...defaultStyles, 'width': '170px' } },
    onBehalfOf: { title: 'On Behalf Of', style: { ...defaultStyles, 'width': '150px' } },
    senderId: { title: 'Sender', style: { ...defaultStyles, 'width': '180px' } },
    buyerParty: { title: 'Buyer', style: { ...defaultStyles, 'width': '180px' } },
    sellerParty: { title: 'Seller', style: { ...defaultStyles, 'width': '180px' } },
    uti: { title: 'UTI', style: { ...defaultStyles, 'width': '340px' } },
    usi: { title: 'USI', style: { ...defaultStyles, 'width': '340px' } },
    state: { title: 'State', style: { ...defaultStyles, 'width': '170px' } },
    dataStoreId: { title: 'DataStore Id', style: { ...defaultStyles, 'width': '120px' }, visible: false },
    auditGroupId: { title: 'AuditGroup Id', style: { ...defaultStyles, 'width': '120px' }, visible: false },
    repositories: { title: 'Repositories', sortable: false, style: { ...defaultStyles, 'width': '120px' } },
  };

  /**
   * Only the following entries are visible columns in the given order.
   */
  const DEFAULT_TABLE_COLUMNS = [
    'receiptTimestamp',
    'updateTimestamp',
    'dataStoreId',
    'buttons',
    'documentType',
    'documentId',
    'documentVersion',
    'tradeId',
    'agent',
    'onBehalfOf',
    'senderId',
    'buyerParty',
    'sellerParty',
    'uti',
    'usi',
    'state',
    'repositories'
  ];

  export function getDefaultSortingColumn(): string {
    return 'receiptTimestamp';
  }

  export function getDefaultColumns(): string[] {
    return DEFAULT_TABLE_COLUMNS;
  }

  export function getColumn(columnName: string): Column {
    return getColumns([columnName])[0];
  }

  export function getColumns(columnNames: string[]): Column[] {
    return columnNames.map(name => {
      const column = TABLE_COLUMNS[name];
      return Object.assign({
        name: name,
        width: '200px',
        sortable: true,
        visible: true,
        editable: false
      }, column);
    });
  }
}
