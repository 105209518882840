import { Ecm_Dashboard_Categories } from '../../../ecm-dashboard/ecm-dashboard.model';
import { EcmDashboardCategoryActions, SET_CATEGORY } from './category.actions';
import { EcmDashboardState } from '../dashboard.reducers';

export function ecmDashboardCategoryReducer(state: Ecm_Dashboard_Categories,
                                            action: EcmDashboardCategoryActions): Ecm_Dashboard_Categories {
  switch (action.type) {
    case SET_CATEGORY: {
      return action.payload;
    }
    default:
      return state;
  }
}

export const getCategory = (state: EcmDashboardState) => state.category;

