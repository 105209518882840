<p-dialog *ngIf="display"
          [header]="headerText"
          [(visible)]="display"
          [modal]="true"
          (visibleChange)="onVisibleChange($event)"
          [style]="{'max-height': '530px', width: '600px'}">
  <p-messages [value]="messages" [closable]="true"></p-messages>
  <form [formGroup]="form">
    <label for="text">Text:</label>
    <textarea [ngClass]="{'error': this.errors['text']}" id="text" name="text" formControlName="text" class="form-control" rows="10"></textarea>
  </form>
  <div class="view-confirm-footer">
    <button class="btn margin-right-3" type="button" (click)="onCancel()">Cancel</button>
    <button class="btn" type="button" (click)="onSave()">Save</button>
  </div>
  <ng-container *ngFor="let note of data?.notes">
    <cms-note [note]="note" [showNoteInfo]="true"></cms-note>
  </ng-container>
</p-dialog>
