import { createSelector } from '@ngrx/store';
import { getAdminState, State } from '../reducers';
import { DatatableState } from '../datatable/datatable.reducer';
import * as fromDatatable from '../datatable/datatable.reducer';
import { FhaAccountsState } from './fha-accounts.reducers';

export const getFhaAccountsState = createSelector(getAdminState, (state: State) => state.fhaAccounts);
export const getDatatable = createSelector(getFhaAccountsState, (fhaAccounts: FhaAccountsState): DatatableState => fhaAccounts.datatable);
export const getOrganisationId = createSelector(getFhaAccountsState, (state: FhaAccountsState) => state.organisationId);
export const getDialog = createSelector(getFhaAccountsState, (state: FhaAccountsState) => state.dialog);

export const getData = createSelector(getDatatable, fromDatatable.getData);
export const getEditableData = createSelector(getDatatable, fromDatatable.getEditableData);
export const getMessages = createSelector(getDatatable, fromDatatable.getMessages);
export const getSelected = createSelector(getDatatable, fromDatatable.getSelected);
