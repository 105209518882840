import { Component, Input, OnInit } from '@angular/core';
import { EsmInternalMasterDataPresetValues } from '../../internal-master-data/internal-master-data.model';

@Component({
  selector: 'cms-master-data-header',
  templateUrl: './master-data-header.component.html',
  styleUrls: ['./master-data-header.component.scss'],
})
export class MasterDataHeaderComponent implements OnInit {
  @Input() data: EsmInternalMasterDataPresetValues;
  @Input() loading = false;

  constructor() { }

  ngOnInit() {
  }

}
