import { Injectable } from '@angular/core';
import { Message } from 'primeng/api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { toPayload } from '@common/cms-common.model';
import { ApiRequestService } from '@common/api-request.service';
import { MessagesMapperService } from '@common/messages-mapper.service';
import {
  EditInvoiceTypeResponseValue,
  InvoiceTypeResponseValue,
  URL_GATEKEEPER_INVOICE
} from '../../../../settings/invoice/invoice-settings.model';
import * as fromActions from '../invoice-settings.actions';
import * as fromTableActions from './table.actions';
import { OrganisationService } from '@common/organisation.service';

@Injectable()
export class EsmSettingsTableEffects {

  private actionsFiltered$ = this.actions$.pipe(filter(() => this.organisationService.getCurrentOrganisationId() !== null));

  constructor(private actions$: Actions,
              private apiGateway: ApiRequestService,
              private mapper: MessagesMapperService,
              private organisationService: OrganisationService) {
  }

  loadTableData$ = createEffect(() =>
    this.actionsFiltered$.pipe(
      ofType(fromTableActions.loadTableDataAction),
      switchMap(() =>
        this.apiGateway.get(`${URL_GATEKEEPER_INVOICE}/loadTableData`)
          .pipe(
            switchMap((response: InvoiceTypeResponseValue) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return of(fromTableActions.setMessagesAction(messages));
              }
              return [
                fromTableActions.loadTableDataSuccessAction(response.values),
              ];
            }),
            catchError(error => of(fromTableActions.setMessagesAction([
              this.mapper.createErrorMessage(error.errorMessage || 'Server Error')
            ]))))
      )
    )
  );

  deleteAction$ = createEffect(() =>
    this.actionsFiltered$.pipe(
      ofType(fromTableActions.deleteAction),
      map(toPayload),
      switchMap((invoiceTypeId: number) =>
        this.apiGateway.delete(`${URL_GATEKEEPER_INVOICE}/${invoiceTypeId}/delete`)
          .pipe(
            switchMap((response: EditInvoiceTypeResponseValue) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return of(fromTableActions.setMessagesAction(messages));
              }
              return [
                fromTableActions.loadTableDataAction(),
                fromTableActions.setMessagesAction(this.mapper.toSuccessMessages('Invoice type has been deleted successfully'))
              ];
            }),
            catchError(error => of(fromTableActions.setMessagesAction([
              this.mapper.createErrorMessage(error.errorMessage || 'Server Error')
            ]))))
      )
    )
  );

  blockItem$ = createEffect(() =>
    this.actionsFiltered$.pipe(
      ofType(fromTableActions.confirmBlockAction),
      map(toPayload),
      switchMap((invoiceTypeId: number) =>
        this.apiGateway.post(`${URL_GATEKEEPER_INVOICE}/${invoiceTypeId}/block`, null)
          .pipe(
            switchMap((response: EditInvoiceTypeResponseValue) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return of(fromActions.updateDialogAction(messages));
              }
              return [
                fromActions.closeDialogAction(),
                fromTableActions.loadTableDataAction()
              ];
            }),
            catchError(error => of(fromActions.updateDialogAction([
              this.mapper.createErrorMessage(error.errorMessage || 'Server Error')
            ]))))
      )
    )
  );

  activateItem$ = createEffect(() =>
    this.actionsFiltered$.pipe(
      ofType(fromTableActions.confirmActivateAction),
      map(toPayload),
      switchMap((invoiceTypeId: number) =>
        this.apiGateway.post(`${URL_GATEKEEPER_INVOICE}/${invoiceTypeId}/activate`, null)
          .pipe(
            switchMap((response: EditInvoiceTypeResponseValue) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return of(fromActions.updateDialogAction(messages));
              }
              return [
                fromActions.closeDialogAction(),
                fromTableActions.loadTableDataAction()
              ];
            }),
            catchError(error => of(fromActions.updateDialogAction([
              this.mapper.createErrorMessage(error.errorMessage || 'Server Error')
            ]))))
      )
    )
  );
}
