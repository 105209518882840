import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiRequestService } from '@common/api-request.service';
import { MessagesMapperService } from '@common/messages-mapper.service';
import * as fromNettingActions from './netting-settings.actions';
import { loading, loadTableData, loadTableDataSuccess } from './netting-settings.actions';
import { catchError, shareReplay, switchMap, withLatestFrom } from 'rxjs/operators';
import { NettingSettingsModel, PresetValuesObj, URL_GATEKEEPER_NETTING } from '../../../settings/netting/netting-settings.model';
import { Message } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { saveBlob } from '@common/cms-common.model';
import { select, Store } from '@ngrx/store';
import { getPaging, getSearchString, getSelectedVatId, getSorting } from './netting-settings.selectors';
import { EsmNettingSettingsState } from './netting-settings.reducers';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { Action } from '@ngrx/store/src/models';

@Injectable()
export class EsmNettingSettingsEffects {

  loadPresetValues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNettingActions.loadPresetValuesAction.type),
      switchMap(() =>
        this.apiGateway.get(`${URL_GATEKEEPER_NETTING}/loadPresetValues`)
          .pipe(
            switchMap((response: PresetValuesObj) => this.loadData(response)),
            catchError(error => this.generateErrorMessage(error)))
      )
    )
  );

  setNetting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNettingActions.confirmActivateAction), switchMap(({ payload }) =>
        payload ? this.activateNetting(payload) : this.blockNetting(payload))
    ));

  setPhysicalDay = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNettingActions.setCallDayPhysicalNettingGeneration), switchMap(({ payload }) =>
        this.apiGateway.post(`${URL_GATEKEEPER_NETTING}/netting-generation-date/physical`, payload)
          .pipe(
            switchMap((response: any) => this.defaultResponse(response)),
            catchError(error => this.generateErrorMessage(error))
          ))
    ));

  setFinancialDay = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNettingActions.setCallDayFinancialNettingGeneration), switchMap(({ payload }) =>
        this.apiGateway.post(`${URL_GATEKEEPER_NETTING}/netting-generation-date/financial`, payload)
          .pipe(
            switchMap((response: any) => this.defaultResponse(response)),
            catchError(error => this.generateErrorMessage(error))
          ))
    ));

  setCentralNetting = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNettingActions.setCentralNettingSupported), switchMap(({ payload }) =>
        this.apiGateway.post(`${URL_GATEKEEPER_NETTING}/toggle/central-netting`, payload, null, new HttpHeaders({ 'Content-Type': 'application/json' }))
          .pipe(
            switchMap((response: any) => this.defaultResponse(response)),
            catchError(error => this.generateErrorMessage(error))
          ))
    ));

  setCrossCommodityNetting = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNettingActions.setCrossCommodityNettingSupported), switchMap(({ payload }) =>
        this.apiGateway.post(`${URL_GATEKEEPER_NETTING}/toggle/cross-commodity-netting`, payload, null, new HttpHeaders({ 'Content-Type': 'application/json' }))
          .pipe(
            switchMap((response: any) => this.defaultResponse(response)),
            catchError(error => this.generateErrorMessage(error))
          ))
    ));

  toggleNettingForParty = createEffect(() =>
  this.actions$.pipe(
    ofType(fromNettingActions.activateNetting.type, fromNettingActions.blockNetting.type),
    switchMap( ({payload}) => {
      return this.apiGateway.post(`${URL_GATEKEEPER_NETTING}/toggle/cross-commodity-mapping-for-counterparty`, payload
      ).pipe(
        switchMap((response: any) => this.activateBlockResponse(response)),
        catchError(err => this.generateErrorMessage(err))
      );
    })
  ));

  load_table = createEffect(() =>
  this.actions$.pipe(
    ofType(loadTableData),
    shareReplay(),
    withLatestFrom(this.store$.pipe(select(getPaging)), this.store$.pipe(select(getSorting))),
    switchMap(([,paging, sorting]) => this.loadTableData(paging, sorting)))
  );

  filter_table = createEffect(() =>
  this.actions$.pipe(
    ofType(fromNettingActions.filterTableData.type),
    shareReplay(),
    withLatestFrom(this.store$.pipe(select(getSelectedVatId)), this.store$.pipe(select(getPaging)), this.store$.pipe(select(getSorting)), this.store$.pipe(select(getSearchString))),
    switchMap(([, vatNumber, paging, sorting, searchString]) => {
      return this.apiGateway.post(`${URL_GATEKEEPER_NETTING}/searchByFilter`, { vatNumber, paging, searchString, sorting })
        .pipe(
          switchMap((response: any) => this.handleTableDataResponse(response)),
          catchError(err => this.generateErrorMessage(err))
        );
    })
  ));

  downloadGatekeeper$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNettingActions.downloadGatekeeperAction.type),
      switchMap(() =>
        this.apiGateway.getBlob(`${URL_GATEKEEPER_NETTING}/download/xls`)
          .pipe(
            switchMap((response: any) => {
              saveBlob(response);
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return of(fromNettingActions.setMessagesAction(messages));
              }
              return [fromNettingActions.setMessagesAction([])];
            }),
            catchError(error => of(fromNettingActions.setMessagesAction([
              this.mapper.createErrorMessage(error.errorMessage || 'Server Error')
            ]))))
      )
    )
  );

  private loadTableData(paging: PagingFilter, sorting: SortingFilter): Observable<Action> {
    this.store$.dispatch(loading(true));
    return this.apiGateway.post(`${URL_GATEKEEPER_NETTING}/searchByFilter`, {sorting, paging})
      .pipe(
        switchMap((response: any) => this.handleTableDataResponse(response)),
        catchError(error => this.generateErrorMessage(error))
      );
  }

  private updateCheckboxes = (payload: boolean) => [
    fromNettingActions.setMessagesAction([]),
    fromNettingActions.setNettingSupported(payload),
    fromNettingActions.setCentralNettingSupported(false),
    fromNettingActions.setCrossCommodityNettingSupported(false)
  ]

  private generateErrorMessage = error => of(fromNettingActions.setMessagesAction([this.mapper.createErrorMessage(error.errorMessage || 'Server Error')]));

  private handleResponse = (response: any, payload: boolean) => {
    const messages: Message[] = this.mapper.toErrorMessages(response);
    if (messages && messages.length) {
      return of(fromNettingActions.setMessagesAction(messages));
    }
    return this.updateCheckboxes(payload);
  }

  private defaultResponse = (response: any) => {
    const messages: Message[] = this.mapper.toErrorMessages(response);
    if (messages && messages.length) {
      return of(fromNettingActions.setMessagesAction(messages));
    }
    return [fromNettingActions.setMessagesAction([])];
  }

  private activateBlockResponse = (response: any) => {
    const messages: Message[] = this.mapper.toErrorMessages(response);
    if (messages && messages.length) {
      return of(fromNettingActions.setMessagesAction(messages));
    }
    return [fromNettingActions.setMessagesAction([]), fromNettingActions.filterTableData()];
  }

  private handleTableDataResponse = (response: DatatableData<NettingSettingsModel[]>) => {
    return [loadTableDataSuccess(response), fromNettingActions.setMessagesAction( [])];
  }

  private loadData = (response: PresetValuesObj) => {
    const messages: Message[] = this.mapper.toErrorMessages(response);
    if (messages && messages.length) {
      return of(fromNettingActions.setMessagesAction(messages));
    }
    return [fromNettingActions.loadPresetValuesSuccessAction(response.value),
      fromNettingActions.setToggleNettingAllowed(response.value.toggleNettingAllowed),
      fromNettingActions.setNettingSupported(response.value.nettingSupported),
      fromNettingActions.setMessagesAction([])
    ];
  }

  private blockNetting(payload) {
    return this.apiGateway.post(`${URL_GATEKEEPER_NETTING}/block`, null).pipe(
      switchMap((response: any) => this.handleResponse(response, payload)),
      catchError(error => this.generateErrorMessage(error))
    );
  }

  private activateNetting(payload) {
    return this.apiGateway.post(`${URL_GATEKEEPER_NETTING}/activate`, null).pipe(
      switchMap((response: any) => this.handleResponse(response, payload)),
      catchError(error => this.generateErrorMessage(error))
    );
  }

  constructor(private actions$: Actions,
              private apiGateway: ApiRequestService,
              private store$: Store<EsmNettingSettingsState>,
              private mapper: MessagesMapperService) {
  }
}
