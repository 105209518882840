<div>
  <h1><span class="inline-block">Personal Column Settings</span></h1>
</div>
<p-messages [(value)]="messages"></p-messages>
<div class="panel-box">
  <button type="button" class="btn padding-btn margin-right-6" (click)="back()"><i class="fa fa-arrow-left"></i> Go Back</button>
  <div class="margin-top-15 margin-bottom-30 decent-max-width">

    <cms-info ngPreserveWhitespaces>
      * Drag columns to the left list to display them.
      * Drag them up/down within the list to reorder them.
      * Drag them to the right list to remove them.
      * Always press **"Save Changes"** when done.
    </cms-info>
    <div class="row full-width">

      <div class="col-sm-6 col-md-3 col-lg-2 margin-bottom-15">
        <button class="btn padding-btn" id="button-saveChanges" [disabled]="disabled" (click)="saveChanges()">Save Changes</button>
      </div>
      <div class="col-sm-6 col-md-3 col-lg-2 margin-bottom-15">
        <button class="btn padding-btn" (click)="loadDefault()">Load Defaults</button>
      </div>
      <div class="margin-left col-sm-12 col-md-6 col-lg-6 offset-lg-1 p-lg-offset-1 margin-bottom-15">
        Load settings from user:
        <p-dropdown [options]="users.users" optionLabel="value" [style]="{'width':'210px'}" (onChange)="change($event.value.ident)" [disabled]="isUserSelectionDisabled"></p-dropdown>
      </div>
    </div>

    <p-pickList [source]="selectedColumns" sourceHeader="Selected Columns" targetHeader="Hidden Columns" [target]="hiddenColumns" [responsive]="true" (onSourceReorder)="enableSaveButton();" (onTargetReorder)="enableSaveButton();" dragdrop="true" dragdropScope="customise table columns" [showSourceControls]="false" [showTargetControls]="false" (onMoveToSource)="enableSaveButton(); resetDropPoints();"
                (dragend)="resetDropPoints();" (onMoveToTarget)="enableSaveButton(); resetDropPoints();">
      <ng-template let-item pTemplate="item">
        <div class="p-helper-clearfix" (dragstart)="$event.dataTransfer.setData('text/plain', '')" (dragover)="moveList($event);" draggable="true">
          {{item.title}}
        </div>
      </ng-template>
    </p-pickList>

  </div>
</div>
