import { ErrorResponse } from '@common/error-response.model';
import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import { DateRange } from '../../invoice/finder/invoice-finder.model';

export enum EsmStatesEnum {
  Pending = 'PENDING',
  Alleged = 'ALLEGED',
  Failed = 'FAILED',
  Mismatched = 'MISMATCHED',
  MismatchedTimeout = 'MISMATCHED_TIMEOUT',
  UnmatchedTimeout = 'UNMATCHED_TIMEOUT',
  Matched = 'MATCHED',
  Cancelled = 'CANCELLED'
}

export enum EsmNettingRoleByType {
  OFFICIAL = 'DOCUMENT',
  REVERSE = 'REVERSE_DOCUMENT'
}

export enum EsmNettingDashboardTableType {
  BUCKETS = 'loadBuckets',
  TABLE_DATA = 'loadTableData'
}

export enum CategoriesMap {
  inprogress = 'IN_PROGRESS',
  issue = 'ISSUE',
  complete = 'COMPLETE'
}

export enum EsmDateRange {
  TOTAL = 'TOTAL',
  EXCEEDED = 'EXCEEDED',
  TOMORROW = 'TOMORROW',
  RANGE2 = 'RANGE2',
  RANGE1 = 'RANGE1',
  RANGE3 = 'RANGE3',
  TODAY = 'TODAY',
  RANGE4_7 = 'RANGE4_7',
  RANGE8_31 = 'RANGE8_31',
  LATER = 'LATER',
}

export enum EsmStatesEnum {
  PENDING = 'PENDING',
  ALLEGED = 'ALLEGED',
  MISMATCHED = 'MISMATCHED',
  FAILED = 'FAILED',
  MISMATCHED_TIMEOUT = 'MISMATCHED_TIMEOUT',
  UNMATCHED_TIMEOUT = 'UNMATCHED_TIMEOUT',
  MATCHED_COMPLETE = 'MATCHED_COMPLETE',
  MATCHED_TOTALS = 'MATCHED_TOTALS',
  MATCHED = 'MATCHED',
  CANCELLED = 'CANCELLED',
}

export interface FilterRequestModel {
  category: string;
  commodities: string[];
  counterParties: number[];
  deliveryPoints: { keyObject: string, valueObject: string }[];
  nettingStatementRole: EsmNettingRoleByType;
  nettingStatementTypes: string[];
  invoiceStartDate: string;
  invoiceEndDate: string;
  paymentDateRange: DateRange;
}

export interface EsmNettingDashboardBuckets extends ErrorResponse {
  bucketNames: string[];
  stateNames: EsmStatesEnum[];
  rows: EsmBucketsRows[];
}

export interface EsmNettingDashboardPresetFiltersResponse extends ErrorResponse {
  value: EsmNettingDashboardPresetFiltersValues;
}

export interface EsmDashboardTableDataRequest {
  dateRange: string;
  filterRequestModel: FilterRequestModel;
  paging: PagingFilter;
  sorting: SortingFilter;
  state: EsmStatesEnum;
}

export interface EsmDashboardTableDataResponse extends ErrorResponse {
  count: number;
  currentPage: number;
  values: EsmInvoiceFinderDeal[];
  notFoundIds?: string[];
  dateRangeText: string;
}

export interface EsmBucketValues {
  TOTAL: number;
  EXCEEDED: number;
  TOMORROW: number;
  RANGE2: number;
  RANGE1: number;
  RANGE3: number;
  TODAY: number;
  RANGE4_7: number;
  RANGE8_31: number;
  LATER: number;
}

export interface EsmBucketsRows extends EsmBucketValues {
  state: EsmStatesEnum;
}

export interface EsmNettingDashboardPresetFiltersValues {
  commodities: string[];
  counterParties: { key: number, value: string }[];
  deliveryPoints:any[];
  nettingStatementTypes: string[];
  paymentDateRange: DateRange;
  invoiceStartDate: string;
  invoiceEndDate: string;
}

export interface EsmNettingDashboardPresetFilters {
  commodities: string[];
  counterParties: number[];
  deliveryPoints: { keyObject: string, valueObject: string }[];
  nettingStatementTypes: string[];
  paymentDateRange: DateRange;
  invoiceStartDate: string;
  invoiceEndDate: string;
}

export interface EsmNettingDashboardTab {
  route: EsmNettingDashboardTabsType;
  title: string;
  header: string;
}

export interface EsmInvoiceFinderDeal {
  counterParty: string;
  agreement: string;
  commodity: string;
  currency: string;
  customerId: string;
  deliveryPointArea: string;
  documentId: string;
  documentType: string;
  documentVersion: number;
  fixedFloating: string;
  id: number;
  invoiceDate: string;
  invoiceNumber: string;
  invoicePeriodEnd: string;
  invoicePeriodStart: string;
  lastUpdateUtc: string;
  marketInformation: string;
  masterAgreementVersion: string;
  matchType: string;
  natureOfPrice: string;
  paymentDate: string;
  physicalFinancial: string;
  purchaseOrderNumber: string;
  receiverId: string;
  selfBilling: boolean;
  senderId: string;
  state: string;
  submissionTimestamp: string;
  supplierId: string;
  taxPoint: string;
  totalAmount: number;
  totalAmountCurrency: string;
  totalGrossAmount: number;
  totalGrossAmountCurrency: string;
  totalVolume: number;
  totalVolumeUnit: string;
  vatAmount: number;
  vatAmountCurrency: string;
  vatAmountDomestic: number;
  vatAmountDomesticCurrency: string;
  vatRate: number;
  nearMatchButtonVisible: boolean;
  misMatchButtonVisible: boolean;
  nttdPdfButtonVisible:boolean;
  customerDisplayName: string;
  supplierDisplayName: string;
}

export type EsmNettingDashboardTabsType = 'inprogress' | 'issue' | 'complete';

export const EsmNettingDashboardTabs: EsmNettingDashboardTab[] = [
  { route: 'inprogress', title: 'Dashboard In Progress Page', header: 'In Progress' },
  { route: 'issue', title: 'Dashboard Issue Page', header: 'Issue' },
  { route: 'complete', title: 'Dashboard Complete Page', header: 'Complete' },
];


export const esmDateRangeToLabelMaps = {
  [EsmDateRange.TODAY]: '0 (Today)',
  [EsmDateRange.TOMORROW]: '1 (Tomorrow)',
  [EsmDateRange.EXCEEDED]: 'Exceeded',
  [EsmDateRange.TOTAL]: 'Total',
  [EsmDateRange.RANGE1]: '1',
  [EsmDateRange.RANGE2]: '2',
  [EsmDateRange.RANGE3]: '3',
  [EsmDateRange.RANGE4_7]: '4-7',
  [EsmDateRange.RANGE8_31]: '8-31',
  [EsmDateRange.LATER]: 'Later',
};

export const esmStatesToLabelMaps = {
  [EsmStatesEnum.PENDING]: 'Pending',
  [EsmStatesEnum.ALLEGED]: 'Alleged',
  [EsmStatesEnum.MISMATCHED]: 'Mismatched',
  [EsmStatesEnum.FAILED]: 'Failed',
  [EsmStatesEnum.MISMATCHED_TIMEOUT]: 'Mismatched (Timeout)',
  [EsmStatesEnum.UNMATCHED_TIMEOUT]: 'Unmatched (Timeout)',
  [EsmStatesEnum.MATCHED_TOTALS]: 'Matched (Totals)',
  [EsmStatesEnum.MATCHED_COMPLETE]: 'Matched (Complete)',
  [EsmStatesEnum.MATCHED]: 'Matched',
  [EsmStatesEnum.CANCELLED]: 'Cancelled',
};

export const defaultStyles = {
  'text-align': 'left',
  'text-overflow': 'ellipsis',
  'overflow': 'hidden',
  'white-space': 'nowrap'
};

const defaultBucketColumn = {
  name: '',
  type: 'esm',
  title: '',
  style: { ...defaultStyles, 'color': '#006591', 'text-align': 'right', 'width': '150px' },
  sortable: false,
  visible: true
};

export function buildColumns(response: EsmNettingDashboardBuckets) {
  const dateRanges = response.bucketNames;
  const columns = [
    {
      ...defaultBucketColumn,
      name: 'state',
      type: 'state',
      title: 'State',
    }
  ];
  dateRanges.forEach((dateRange: EsmDateRange) => {
    columns.push({
      ...defaultBucketColumn,
      name: dateRange,
      title: esmDateRangeToLabelMaps[dateRange],
    });
  });
  return columns;
}
