import { Injectable } from '@angular/core';
import { ApiRequestService } from '@common/api-request.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { ErrMifidFileDetailsState } from './mifid-details.reducer';
import * as fromActions from './mifid-details.action';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Message } from 'primeng/api';
import { of } from 'rxjs';
import { getFileId, getMifidFileDetailsInState } from './mifid-details.selectors';
import { HttpParams } from '@angular/common/http';
import {
  allDetailsColumnsConfig,
  ErrMifidDetailsDataResponse,
  ErrMifidDetailsInformationResponse,
} from '../../mifid/mifid.model';
import * as fromDialogActions from '../dialog/dialog.actions';

@Injectable()
export class ErrMifidDetailsEffects {
  private baseUrl = '/api/err/mifid/details';

  constructor(private apiGateway: ApiRequestService,
              private actions$: Actions,
              private store$: Store<ErrMifidFileDetailsState>,
              private mapper: MessagesMapperService,) {
  }

   loadInformation$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.LOAD_INFORMATION),
      withLatestFrom(this.store$.pipe(select(getFileId))),
      switchMap((data: [fromActions.LoadInformationAction, any]) => {
        const [, fileId] = data;
        let params = new HttpParams();
        params = params.set('fileId', fileId);

        return this.apiGateway.get(`${this.baseUrl}/informations`, params)
          .pipe(switchMap((response: ErrMifidDetailsInformationResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [new fromActions.SetMessagesAction(messages)];
              }
              return [
                new fromActions.LoadInformationSuccessAction(response),
                new fromActions.SetColumnsAction(this.getColumns(response.value.confirmFileEnabled)),
                new fromActions.SetMessagesAction([]),
              ];
            }),
            catchError(error => of(new fromActions.SetMessagesAction([
              this.mapper.createErrorMessage('Server Error')
            ]))));
      })));

   loadData$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.LOAD_DATA),
      withLatestFrom(this.store$.pipe(select(getMifidFileDetailsInState))),
      switchMap((data: [fromActions.LoadDataAction, ErrMifidFileDetailsState]) => {
        const [, state] = data;
        const { fileId, sorting, paging, editable } = state;
        return this.loadData(fileId, sorting, paging, editable);
      })));

   sortAndPage$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.SORT_AND_PAGE_DATA),
      withLatestFrom(this.store$.pipe(select(getMifidFileDetailsInState))),
      switchMap((data: [fromActions.SortAndPageDataAction, ErrMifidFileDetailsState]) => {
          const [action, state] = data;
          const { sorting, paging } = action.payload;
          return this.loadData(state.fileId, sorting, paging, state.editable);
        }
      )));

   loadFormValues$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.LOAD_FORM_VALUES),
      withLatestFrom(this.store$.pipe(select(getFileId))),
      switchMap((data: [fromActions.LoadFormValuesAction, any]) => {
        const [, fileId] = data;
        let params = new HttpParams();
        params = params.set('fileId', fileId);

        return this.apiGateway.get(`${this.baseUrl}/formValues`, params)
          .pipe(switchMap((response: any) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [new fromActions.SetMessagesAction(messages)];
              }
              return [
                new fromActions.LoadFormValuesSuccessAction(response),
                new fromActions.SetMessagesAction([]),
              ];
            }),
            catchError(error => of(new fromActions.SetMessagesAction([
              this.mapper.createErrorMessage('Server Error')
            ]))));
      })));

   addItem$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.ADD_NEW_ITEM),
      withLatestFrom(this.store$.pipe(select(getFileId))),
      switchMap((data: [fromActions.AddNewItemAction, any]) => {
        const [action, fileId] = data;
        let params = new HttpParams();
        params = params.set('fileId', fileId);

        return this.apiGateway.post(`${this.baseUrl}/add`, action.payload, params)
          .pipe(switchMap((response: any) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [fromDialogActions.updateDialogAction(messages)];
              }
              return [
                new fromActions.AddNewItemSuccessAction(),
                new fromActions.LoadDataAction(),
                new fromActions.LoadInformationAction(),
                fromDialogActions.updateDialogAction([]),
                fromDialogActions.closeDialogAction(),
              ];
            }),
            catchError(error => of(new fromActions.SetMessagesAction([
              this.mapper.createErrorMessage('Server Error')
            ]))));
      })));

   editItem$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.EDIT_ITEM),
      withLatestFrom(this.store$.pipe(select(getFileId))),
      switchMap((data: [fromActions.EditItemAction, any]) => {
        const [action, fileId] = data;
        let params = new HttpParams();
        params = params.set('fileId', fileId);

        return this.apiGateway.put(`${this.baseUrl}/edit`, action.payload, params)
          .pipe(switchMap((response: any) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [fromDialogActions.updateDialogAction(messages)];
              }
              return [
                new fromActions.EditItemSuccessAction(),
                new fromActions.LoadDataAction(),
                new fromActions.LoadInformationAction(),
                fromDialogActions.updateDialogAction([]),
                fromDialogActions.closeDialogAction(),
              ];

            }),
            catchError(error => of(new fromActions.SetMessagesAction([
              this.mapper.createErrorMessage('Server Error')
            ]))));
      })));

   confirmItem$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.CONFIRM_ITEM),
      withLatestFrom(this.store$.pipe(select(getFileId))),
      switchMap((data: [fromActions.ConfirmItemAction, any]) => {
        const [, fileId] = data;
        let params = new HttpParams();
        params = params.set('fileId', fileId);

        return this.apiGateway.post(`${this.baseUrl}/confirm`, params)
          .pipe(switchMap((response: any) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [new fromActions.SetMessagesAction(messages)];
              }
              return [
                new fromActions.ConfirmItemSuccessAction(),
                new fromActions.LoadDataAction(),
                new fromActions.LoadInformationAction(),
                new fromActions.SetMessagesAction([]),
              ];

            }),
            catchError(error => of(new fromActions.SetMessagesAction([
              this.mapper.createErrorMessage('Server Error')
            ]))));
      })));

   deleteItem$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.DELETE_ITEM_POSITION),
      withLatestFrom(this.store$.pipe(select(getFileId))),
      switchMap((data: [fromActions.DeleteItemPositionAction, any]) => {
        const [action, fileId] = data;
        let params = new HttpParams();
        params = params.set('recordId', action.payload);

        return this.apiGateway.delete(`${this.baseUrl}/data/${action.payload}/delete`, params)
          .pipe(switchMap((response: any) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [new fromActions.SetMessagesAction(messages)];
              }
              return [
                new fromActions.DeleteItemPositionSuccessAction(),
                new fromActions.LoadDataAction(),
                new fromActions.LoadInformationAction(),
                new fromActions.SetMessagesAction([]),
              ];

            }),
            catchError(error => of(new fromActions.SetMessagesAction([
              this.mapper.createErrorMessage('Server Error')
            ]))));
      })));

   discardChanges$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.DISCARD_CHANGES),
      withLatestFrom(this.store$.pipe(select(getFileId))),
      switchMap((data: [fromActions.DiscardChangesAction, any]) => {
        const [, fileId] = data;
        let params = new HttpParams();
        params = params.set('fileId', fileId);

        return this.apiGateway.post(`${this.baseUrl}/discard`, params)
          .pipe(switchMap((response: any) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [new fromActions.SetMessagesAction(messages)];
              }
              return [
                new fromActions.DiscardChangesSuccessAction(),
                new fromActions.LoadDataAction(),
                new fromActions.LoadInformationAction(),
                new fromActions.SetMessagesAction([]),
              ];

            }),
            catchError(error => of(new fromActions.SetMessagesAction([
              this.mapper.createErrorMessage('Server Error')
            ]))));
      })));


  private loadData(fileId, sorting, paging, editable) {
    const request = { fileId, sorting, paging };
    return this.apiGateway.post(`${this.baseUrl}/data`, request)
      .pipe(switchMap((response: ErrMifidDetailsDataResponse) => {
          const messages: Message[] = this.mapper.toErrorMessages(response);
          if (messages && messages.length) {
            return [new fromActions.SetMessagesAction(messages)];
          }
          const res = {
            values: response.values,
            count: response.count,
            currentPage: response.currentPage,
          };
          return [
            new fromActions.LoadDataSuccessAction(res),
            new fromActions.SetMessagesAction([]),
          ];
        }),
        catchError(error => of(new fromActions.SetMessagesAction([
          this.mapper.createErrorMessage('Server Error')
        ]))));
  }

  private getColumns(editable: boolean = false) {
    return allDetailsColumnsConfig.map(col => {
      col.visible = col.type === 'button' ? editable : col.visible;
      return col;
    });
  }
}
