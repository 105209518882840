<div class="volume-optionality-intervals">
  <div class="form-title">Trade | Volume Optionality Intervals</div>
  <div class="form-body" [formGroup]="formGroup">

    <div formArrayName="volumeOptionalityIntervals">
      <div *ngFor="let interval of intervals; let i = index" [formGroupName]="i">
        <div class="row gutter-0 !me-[40px]">
          <div class="col-5 label">Start / End Date {{i + 1 }} <span class="text-required">*</span></div>
          <div class="col-7 row gutter-0 ps-2 pe-1">
            <div class="col-6">
              <p-calendar
                formControlName="startDate"
                placeholder="dd/mm/yyyy"
                [showIcon]="true"
                [monthNavigator]="true"
                [yearNavigator]="true"
                dateFormat="dd/mm/yy"
                [style]="{ 'width': '100%'}"
                [styleClass]="'margin-3 ' + getVolumeOptionalityIntervalsErrorClass(i, 'startDate')"
                cmsDateRestriction>
              </p-calendar>
            </div>
            <div class="col-6 ps-2">
              <p-calendar
                formControlName="endDate"
                placeholder="dd/mm/yyyy"
                [showIcon]="true"
                [monthNavigator]="true"
                [yearNavigator]="true"
                dateFormat="dd/mm/yy"
                [style]="{ 'width': '100%'}"
                [styleClass]="'margin-3 ' + getVolumeOptionalityIntervalsErrorClass(i, 'endDate')"
                cmsDateRestriction>
              </p-calendar>
            </div>
          </div>
          <span
            *ngIf="getVolumeOptionalityIntervalsErrorClass(i, 'startDate') || getVolumeOptionalityIntervalsErrorClass(i, 'endDate')"
            class="fa fa-info-circle absolute error-marker"></span>
        </div>
        <div class="row gutter-0 !me-[40px] bottom-row-border">
          <div class="col-5 label">Capacity {{i + 1 }} <span class="text-required">*</span></div>
          <div class="col-7 row gutter-0 ps-2 pe-1">
            <div class="col-6">
              <input type="text" cmsDigitOnly
                [class]="'form-control padding-6 text-medium margin-3 ' + getVolumeOptionalityIntervalsErrorClass(i, 'capacityValue')"
                formControlName="capacityValue">
            </div>
            <div class="col-6 ps-2">
              <p-dropdown formControlName="capacityUnit" [options]="capacityType"
                [style]="{'width': '100%'}"
                [styleClass]="'margin-3 ' + getVolumeOptionalityIntervalsErrorClass(i, 'capacityUnit')"
                ></p-dropdown>
            </div>
          </div>
          <span
            *ngIf="getVolumeOptionalityIntervalsErrorClass(i, 'capacityValue') || getVolumeOptionalityIntervalsErrorClass(i, 'capacityUnit')"
            class="fa fa-info-circle absolute error-marker"></span>
        </div>
      </div>
    </div>
  </div>

  <div class="form-body">
    <div class="row gutter-0 !me-[40px] ">
      <div class="col-5 label"></div>
      <div class="col-7 ps-2 pe-1">
        <button type="button" class="btn add-remove-line" (click)="addInterval()"
          [disabled]="isAddIntervalDisabled()">Add</button>
        <button type="button" class="btn add-remove-line" (click)="removeInterval()"
          [disabled]="isRemoveIntervalDisabled()">Remove</button>
      </div>
    </div>
  </div>
</div>
