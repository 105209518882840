import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ClientErrorsService } from '@common/errors/client-error-service';

@Injectable()
export class CmsErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {
  }

  handleError(error: Error): void {

    if (error instanceof HttpErrorResponse) {
      // currently handled in HttpErrorInterceptor.intercept
    }else {
      this.injector.get(ClientErrorsService)
        .sendErrToServer(error)
        .subscribe();

      console.error(error);
    }
  }
}
