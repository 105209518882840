import { createAction, props, union } from '@ngrx/store';
import { CounterpartyOption } from '../../../netting-preview/netting-preview.model';


export const loadPresets = createAction('[ESM-NTT-PREVIEW] Load counterparties preset values');
export const loadPresetsSuccess = createAction('[ESM-NTT-PREVIEW] Load counterparties preset values successfully', props<{ presets: CounterpartyOption[] }>());
export const cleanState = createAction('[ESM-NTT-PREVIEW] Clean counterparties state');
export const setSelectedItems = createAction('[ESM-NTT-PREVIEW] Select counterparty items',props<{ counterPartyOrgIds: number[]}>());


const actions = union({
  loadPresets,
  loadPresetsSuccess,
  cleanState,
  setSelectedItems,
});

export type CounterPartyActions = typeof actions;

