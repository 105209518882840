import { DialogState } from '@common/state/reducers';
import { createAction, union } from '@ngrx/store';
import { Message } from 'primeng/api';
import { ERR_MIFID_DIALOGS } from './dialog.reducers';

export const openDialogAction = createAction('[ERR] Open Dialog', (payload: DialogState<ERR_MIFID_DIALOGS>) => ({ payload }));
export const updateDialogAction = createAction('[ERR] Update Dialog', (payload: Message[]) => ({ payload }));
export const closeDialogAction = createAction('[ERR] Close Dialog');

const action = union({ openDialogAction, updateDialogAction, closeDialogAction });

export type DialogActions = typeof action;
