import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { StandingInstructionsService } from '../../standing-instructions.service';

import * as fromActions from './standing-instructions-upload.actions';

@Injectable()
export class StandingInstructionsUploadEffects {

  constructor(
    private actions$: Actions,
    private standingInstructionsService: StandingInstructionsService
  ) {
  }

  getPresetValues$ = createEffect(
    () => this.actions$.pipe(ofType(fromActions.GET_PRESET_VALUES),
      switchMap(() => this.standingInstructionsService.loadPresetValues()),
      map(presetValue => new fromActions.GetPresetValuesSuccess(presetValue))
    ));

}
