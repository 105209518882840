import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemitOmpWhitelistComponent } from './remit-omp-whitelist.component';
import { CmsCommonModule } from '@common/cms-common.module';
import { RemitOmpWhitelistUploadPanelComponent } from './remit-omp-whitelist-upload-panel.component';
import { RemitOmpWhitelistDatatableComponent } from './datatable/remit-omp-whitelist-datatable.component';
import { BrowserModule } from '@angular/platform-browser';
import { RemitOmpWhitelistService } from './remit-omp-whitelist.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { InputMaskModule } from 'primeng/inputmask';

@NgModule({
  imports: [
    CommonModule,
    CmsCommonModule,
    TableModule,
    BrowserModule,
    ButtonModule,
    InputMaskModule,
    FormsModule,
    ReactiveFormsModule,
    MessagesModule,
    MessageModule,
  ],
  providers: [RemitOmpWhitelistService],
  declarations: [RemitOmpWhitelistComponent, RemitOmpWhitelistUploadPanelComponent, RemitOmpWhitelistDatatableComponent]
})

export class RemitOmpWhitelistModule {
}
