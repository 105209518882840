import { Action } from '@ngrx/store';
import { Message } from 'primeng/api';
import { DatatableConfig } from '@common/shared/results.model';
import { ErrMifidFile, ErrMifidFileFilters, MifidDatatableModel, PresetValuesResponse } from '../../mifid/mifid.model';
import { Column } from '@common/shared/datatable.model';

export const SET_TO_DEFAULT = '[ERR MIFID FILE] Set to default';
export const SET_ORGANISATION_ID = '[ERR MIFID FILE] Set Organisation Id';
export const SET_MESSAGES = '[ERR MIFID FILE] Set Messages';
export const LOAD_DATA = '[ERR MIFID FILE] Load Data';
export const LOAD_DATA_SUCCESS = '[ERR MIFID FILE] Load Data success';
export const FILTER_BY_NAME = '[ERR MIFID FILE] Filter Data by name';
export const FILTER_BY_DATE = '[ERR MIFID FILE] Filter Data by date';
export const FILTER_DATA_SUCCESS = '[ERR MIFID FILE] Filter Data Success';
export const SORT_AND_PAGE_DATA = '[ERR MIFID FILE] Sort and Page Data';
export const LOAD_PRESET_VALUES = '[ERR MIFID FILE] Load Preset Values';
export const LOAD_PRESET_VALUES_SUCCESS = '[ERR MIFID FILE] Load Preset Values Success';
export const CLEAR_FILTERS = '[ERR MIFID FILE] Clear Filters';
export const DOWNLOAD_DOCUMENT = '[ERR MIFID FILE] Download Document';
export const UPLOAD_FILE = '[ERR MIFID FILE] Upload File';
export const DOWNLOAD_TEMPLATE = '[ERR MIFID FILE] Download Template';
export const SET_COLUMNS = '[ERR MIFID FILE] Set Columns';
export const CONFIRM_ITEM = '[ERR MIFID FILE] Confirm item';
export const CONFIRM_ITEM_SUCCESS = '[ERR MIFID FILE] Confirm item success';
export const EXPORT = '[ERR MIFID FILE] Export';

export class FilterByNameAction implements Action {
  readonly type = FILTER_BY_NAME;

  constructor(public payload: ErrMifidFileFilters) { }
}

export class FilterByDateAction implements Action {
  readonly type = FILTER_BY_DATE;

  constructor(public payload: ErrMifidFileFilters) { }
}

export class SortAndPageDataAction implements Action {
  readonly type = SORT_AND_PAGE_DATA;

  constructor(public payload: DatatableConfig) { }
}

export class FilterDataSuccessAction implements Action {
  readonly type = FILTER_DATA_SUCCESS;

  constructor(public payload: MifidDatatableModel<ErrMifidFile[]>) { }
}

export class LoadDataAction implements Action {
  readonly type = LOAD_DATA;
}

export class LoadDataSuccessAction implements Action {
  readonly type = LOAD_DATA_SUCCESS;

  constructor(public payload: any) { }
}

export class SetToDefaultAction implements Action {
  readonly type = SET_TO_DEFAULT;
}

export class SetOrganisationIdAction implements Action {
  readonly type = SET_ORGANISATION_ID;

  constructor(public payload: number) { }
}

export class SetMessagesAction implements Action {
  readonly type = SET_MESSAGES;

  constructor(public payload: Message[]) { }
}

export class LoadPresetValuesAction implements Action {
  readonly type = LOAD_PRESET_VALUES;
}

export class ClearFilters implements Action {
  readonly type = CLEAR_FILTERS;
}

export class LoadPresetValuesSuccessAction implements Action {
  readonly type = LOAD_PRESET_VALUES_SUCCESS;

  constructor(public payload: { value: PresetValuesResponse }) { }
}

export class DownloadDocumentAction implements Action {
  readonly type = DOWNLOAD_DOCUMENT;

  constructor(public payload: { filename: string, dataStoreId: any }) { }
}

export class UploadAction implements Action {
  readonly type = UPLOAD_FILE;

  constructor(public payload: File) { }
}

export class DownloadTemplateAction implements Action {
  readonly type = DOWNLOAD_TEMPLATE;

  constructor(public payload: string) { }
}

export class SetColumnsAction implements Action {
  readonly type = SET_COLUMNS;

  constructor(public payload: Column[]) { }
}

export class ConfirmItemAction implements Action {
  readonly type = CONFIRM_ITEM;

  constructor(public payload: any) {}
}

export class ConfirmItemSuccessAction implements Action {
  readonly type = CONFIRM_ITEM_SUCCESS;
}

export class ExportAction implements Action {
  readonly type = EXPORT;
}

export type ErrMifidFileActions = SetToDefaultAction | SetOrganisationIdAction
  | SetMessagesAction | FilterByNameAction | SortAndPageDataAction
  | FilterDataSuccessAction | LoadDataAction | LoadPresetValuesAction
  | LoadPresetValuesSuccessAction | UploadAction
  | FilterByDateAction | SetColumnsAction | ConfirmItemAction | ConfirmItemSuccessAction
  | ExportAction | ClearFilters;

