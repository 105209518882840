import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Message } from 'primeng/api';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import * as moment from 'moment';
import { MarkdownService } from 'ngx-markdown';
import { OrganisationService } from '@common/organisation.service';
import { Column } from '@common/shared/datatable.model';
import { MonitoringState } from '../state/monitoring/monitoring.reducers';
import { categoryToTitleMap, MonitoringCategory, MonitoringDataTableItem, MonitoringLogs, tabsList } from './monitoring.model';
import * as fromActions from '../state/monitoring/monitoring.actions';
import * as fromSelectors from '../state/monitoring/monitoring.selectors';
import { ErrorMessageService } from '@common/errors/error-message.service';
import { SortOrder } from '@common/shared/results.model';

@Component({
  selector: 'cms-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.scss']
})
export class MonitoringComponent implements OnInit, OnDestroy {
  messages$: Observable<Message[]> = this.store.pipe(select(fromSelectors.getMessages));
  lastSnapshotMessage$: Observable<string> = this.store.pipe(select(fromSelectors.getLastSnapshotMessage));
  newSnapshotLoading$: Observable<boolean> = this.store.pipe(select(fromSelectors.getNewSnapshotLoading));
  getChangeDate$: Observable<string> = this.store.pipe(select(fromSelectors.getChangeDate));
  queryParams: string;
  logs$: Observable<MonitoringLogs[]> = this.store.pipe(select(fromSelectors.getLogs));
  subscription$: Subscription = new Subscription();
  categories$: Observable<MonitoringCategory[]> = this.store.pipe(select(fromSelectors.getCategories));
  loaded$: Observable<boolean> = this.store.pipe(select(fromSelectors.getLoaded));
  activeIndex$: Observable<number> = this.store.pipe(select(fromSelectors.getSelectedCategoryIndex));
  columns$: Observable<Column[]> = this.store.pipe(select(fromSelectors.getColumns));
  data$: Observable<MonitoringDataTableItem[]> = this.store.pipe(select(fromSelectors.getData), filter(Boolean), map((copy: []) => [...copy]));
  loading$: Observable<boolean> = this.store.pipe(select(fromSelectors.getLoading));
  doneCheckingLoading$: Observable<boolean> = this.store.pipe(select(fromSelectors.getDoneCheckinLoading));
  logsText: string | Promise<string>;
  categories: MonitoringCategory[];
  readonly categoryToTitleMap: { [key: string]: string } = categoryToTitleMap;

  constructor(private store: Store<MonitoringState>,
              private markdownService: MarkdownService,
              private organisationService: OrganisationService,
              private cd: ChangeDetectorRef,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              public readonly errorMessageService: ErrorMessageService) {

    this.store.dispatch(fromActions.getStatesAction(this.queryParams));
  }

  ngOnInit(): void {
    this.subscription$
      .add(this.activatedRoute.queryParams
        .subscribe(queryParams => {
          this.queryParams = queryParams.changeDate ? queryParams.changeDate : null;
        }));

    this.subscription$.add(this.organisationService.getOrganisationChangeIfAccessible()
      .pipe(withLatestFrom(this.store.pipe(select(fromSelectors.getChangeDate))))
      .subscribe(([, changeDate]) => {
        this.store.dispatch(fromActions.setToDefaultAction());
        this.store.dispatch(fromActions.getStatesAction(changeDate));
      }));

    this.subscription$.add(this.categories$.subscribe((categories: MonitoringCategory[]) => {
      this.categories = categories;
      this.cd.detectChanges();
    }));

    this.subscription$.add(this.logs$.subscribe((logs: MonitoringLogs[]) => {
      if (logs) {
        this.logsText = this.markdownService.parse(`
          <!--@formatter:off (markdown rendering breaks, when the following tags are formatted)-->
          | Initiator           |                Started|             Completed|
          | :------------------ |---:|---:|
          ${logs.map(item => `|${item.initiator || ''}|${item.started && moment(item.started).format('HH:mm')}|${item.completed ? moment(item.completed).format('HH:mm') : ''}|`).join('\n')}\n
          <!--@formatter:on-->
        `);
      } else {
        this.logsText = null;
      }
    }));
  }

  onDoneChecking(category: MonitoringCategory): void {
    const { categoryName, snapshotId } = category;
    this.store.dispatch(fromActions.doneCheckingAction({ category: categoryName, snapshotId }));
  }

  onNewSnapshot(): void {
    this.store.dispatch(fromActions.createSnapshotAction());
  }

  onChangeDate(changeDate: string): void {
    this.router.navigate([], { queryParams: { changeDate: changeDate !== 'Invalid date' ? changeDate : null } });
    this.store.dispatch(fromActions.getStatesAction(changeDate));
  }

  onChangeTab({ index }: { index: number }): void {
    this.store.dispatch(fromActions.loadDataAction({ category: tabsList[index] }));
  }

  ngOnDestroy(): void {
    this.store.dispatch(fromActions.setToDefaultAction());
    this.subscription$.unsubscribe();
  }
}
