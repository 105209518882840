import { createSelector } from '@ngrx/store';
import { getAdminState, State } from '../reducers';
import { AdminOrganisationFormState, OrganisationsState } from './organisation.reducer';
import * as fromDatatable from '../datatable/datatable.reducer';
import { DatatableState } from '../datatable/datatable.reducer';
import { OrganisationGroup } from '../../administration-tab-panel/administration.model';

export const getOrganisationsState = createSelector(getAdminState, (state: State) => state.organisation);
export const getFormValues = createSelector(getOrganisationsState, (state: OrganisationsState) => state.form);
export const getDatatable = createSelector(getOrganisationsState, (userAccount: OrganisationsState): DatatableState => userAccount.datatable);
export const getOrganisationId = createSelector(getOrganisationsState, (state: OrganisationsState) => state.organisationId);
export const getDialog = createSelector(getOrganisationsState, (state: OrganisationsState) => state.dialog);
export const getFilters = createSelector(getDatatable, (state: DatatableState) => state.filters);

export const getOrgGroupsList = createSelector(getFormValues, (state: AdminOrganisationFormState) => state.orgGroups);
export const getOrgGroupSelectorVisible = createSelector(getFormValues, (state: AdminOrganisationFormState) => state.orgGroupSelectorVisible);
export const getOrgGroupIdColumnVisible = createSelector(getFormValues, (state: AdminOrganisationFormState) => state.orgGroupIdColumnVisible);

export const getData = createSelector(getDatatable, fromDatatable.getData);
export const getSorting = createSelector(getDatatable, fromDatatable.getSorting);
export const getPaging = createSelector(getDatatable, fromDatatable.getPaging);
export const getLoading = createSelector(getDatatable, fromDatatable.getLoading);
export const getEditableData = createSelector(getDatatable, fromDatatable.getEditableData);
export const getMessages = createSelector(getDatatable, fromDatatable.getMessages);
export const getSelected = createSelector(getDatatable, fromDatatable.getSelected);
export const getSelectedOrgFilterName = createSelector(getOrganisationsState, (state: OrganisationsState) =>
  state.form.orgGroups.find((group: OrganisationGroup) => group.key === state.datatable.filters.organisationGroupId));

export const canDeleteOrg = createSelector(getOrganisationsState, (state: OrganisationsState): boolean => !(state.datatable.selected && state.datatable.selected.orgId === state.organisationId));
