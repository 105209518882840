import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../common/user.service';
import { OrganisationService } from '../../../common/organisation.service';
import { OrganisationModel } from '../../../common/organisation.model';
import { ReportingModel } from './reporting.model';
import { Observable, Subject } from 'rxjs';
import { DateUtils } from '../../../common/shared/date.utils';
import { takeUntil } from 'rxjs/operators';
import { RoboPermissionsService } from './robo-permissions.service';
import { Message } from 'primeng/api';
import { ErrorMessageService } from '@common/errors/error-message.service';

@Component({
  selector: 'cms-robo-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss']
})
export class PermissionsComponent implements OnInit, OnChanges, OnDestroy {
  private onDestroy$ = new Subject();
  private reportingPartiesApiUrl = '/api/err/reporting/permissions/';
  public permissionsForm: UntypedFormGroup;
  public messages: Message[] = [];
  permissions: { label: string, value: string }[];
  selectedReportingGroups: ReportingModel[] = [];
  selectedPermission: number = -1;
  reportingGroups: ReportingModel[];
  selectedOrganisation: OrganisationModel;
  writePermission = false;
  permissionChangePanelVisible$: Observable<boolean>;

  constructor(private formBuilder: UntypedFormBuilder,
              private userService: UserService,
              private organisationService: OrganisationService,
              private permissionService: RoboPermissionsService,
              public readonly errorMessageService:ErrorMessageService) {
    this.permissionsForm = this.formBuilder.group({});
  }

  ngOnInit() {

    this.permissionService.onPermissionEntriesChanged().pipe(takeUntil(this.onDestroy$))
      .subscribe(data => {
        this.initPermissionList(data);
      });

    this.permissionChangePanelVisible$ = this.permissionService.onPresetValuesChanged();

    this.permissionService.loadPresetValues();

    this.permissionChangePanelVisible$.pipe(takeUntil(this.onDestroy$))
      .subscribe(visible => {
        if (visible) {
          this.permissionsForm = this.createPermissionsForm();
        }
      });

    this.organisationService.getChangeNotNullOrganisationObservable()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(organisation => {

        this.selectedOrganisation = organisation;
        if (organisation) {
          this.permissionService.loadPermissionEntries();
        }
      });

    this.permissions = [ // see RoboBlocklistChangePermissionModeEnum
      { value: '', label: '' },
      { value: 'allow', label: 'Allow' },
      { value: 'block', label: 'Block' },
      { value: 'forget', label: 'Forget (if inactive)' }
    ];

    if (this.hasWritePermission()) {
      this.permissionsForm = this.createPermissionsForm();
    }
  }

  private createPermissionsForm() {
    return this.formBuilder.group({
      selectedPermission: [ null, Validators.required],
    });
  }

  ngOnChanges() {
    this.selectedPermission = this.permissionsForm.value.selectedPermission;
  }

  onSubmit() {
    if (this.permissionsForm.invalid || !this.isSelected()) {
      this.messages = [{
        severity: 'error',
        summary: 'Error',
        detail: 'Select a permission.'
      }];
      return;
    }
    if (this.selectedReportingGroups.length <= 0) {
      this.messages = [{
        severity: 'error',
        summary: 'Error',
        detail: 'Select one or more Delegatee Groups.'
      }];
      return;
    }

    this.permissionService.setPermissions(this.getRoboSelectedPermissionRequestModel()).then(
      (data) => {
        // success
        this.permissionService.loadPermissionEntries();
        this.messages = [{
          severity: 'success',
          detail: 'ROBO permissions updated.'
        }];
      },
      (err: HttpErrorResponse) => {
        this.messages = [{
          severity: 'error',
          summary: 'Error',
          detail: `Internal error: data update failed. Please contact Equias support with error reference number ${err.error.errorReferenceCode}`
        }];
      });
  }

  private initPermissionList(entries) {
    this.reportingGroups = [];
    this.selectedReportingGroups = [];

    let rowNum = 0;
    if (entries) {
      entries.forEach(entry => {
        entry.lastUpdate = entry.lastUpdate ? new Date(entry.lastUpdate) : null;
        entry.rowNum = rowNum;
        rowNum++;
      });
      this.reportingGroups = entries;
    }
  }

  private getUpdateApiCall(): string {
    return this.reportingPartiesApiUrl
      + this.selectedOrganisation.organisationId;
  }

  getSelectedGroupIds(): string[] {
    const selectedIds = [];
    this.selectedReportingGroups.forEach(group => selectedIds.push(group.id));
    return selectedIds;
  }

  getReportingGroups() {
    return this.reportingGroups;
  }

  getOrganisationName(): string {
    return this.selectedOrganisation ? this.selectedOrganisation.displayName : '';
  }

  getSelectedPermission(): string {
    return this.permissionsForm.value.selectedPermission;
  }

  getRoboSelectedPermissionRequestModel(): RoboUpdatePermissionModel {
    const groups = this.selectedReportingGroups;
    groups.forEach(g =>
      g.lastUpdate = g.lastUpdate ? DateUtils.formatToISOIgnoreZone(new Date(g.lastUpdate)) : null
    );

    return {
      selectedPermission: this.getSelectedPermission(),
      delegatees: groups
    };
  }

  hasWritePermission(): boolean {
    return this.writePermission;
  }

  isSelected(): boolean {
    return this.permissionsForm.value.selectedPermission && this.permissionsForm.value.selectedPermission !== -1;
  }

  public ngOnDestroy(): void {
    // Clean up all subscriptions at once:
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }
}

export class RoboUpdatePermissionModel {

  selectedPermission: string;
  delegatees: ReportingModel[];
}
