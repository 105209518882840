import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Message } from 'primeng/api';

@Component({
  selector: 'cms-mifid-confirm-dialog',
  templateUrl: './mifid-confirm-dialog.component.html',
  styleUrls: ['./mifid-confirm-dialog.component.scss']
})
export class MifidConfirmDialogComponent {
  @Input() display = false;
  @Input() messages: Message[] = [];
  @Input() text: string = '';
  @Input() header: string = '';
  @Input() buttonText: string = '';

  @Output() confirm = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<Message[]>();

  constructor() { }

  onCancel(): void {
    this.display = false;
    this.cancel.emit();
  }

  onConfirm(): void {
    this.confirm.emit();
  }

  onVisibleChange(visible: boolean): void {
    if (!visible) {
      this.onCancel();
    }
  }
}
