import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Message } from 'primeng/api';
import { ActivatedRoute, Params } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { EndPointConstants } from '../../../../../common/end-point-constants';
import { RemitTable2TradeService } from '../remit-table2-trade.service';
import { DateUtils } from '../../../../../common/shared/date.utils';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cms-remit-table2-trade-cancel',
  templateUrl: './remit-table2-trade-cancel.component.html',
  styleUrls: ['./remit-table2-trade-cancel.component.scss']
})
export class RemitTable2TradeCancelComponent implements OnInit, OnDestroy {

  private onDestroy$ = new Subject();

  cancelForm: UntypedFormGroup;

  historyId: number;

  msgs: Message[] = [];
  disabled: boolean = false;
  inProgress: boolean = false;
  internalError: boolean = false;

  submitEnabled$: Observable<boolean> = of(false);

  constructor(formBuilder: UntypedFormBuilder, private service: RemitTable2TradeService, private route: ActivatedRoute, private titleService: Title) {
    this.cancelForm = formBuilder.group({
      terminationDate: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    this.msgs = [];

    this.inProgress = false;

    this.titleService.setTitle('Cancel Trade');

    this.route.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe((params: Params) => {
      this.historyId = params[EndPointConstants.PARAM_HISTORY_ID];
    });

    this.service.loadPresetsCancellation(this.historyId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(response => {

        if(response){

          this.cancelForm.disable();

          if (response.validationFailureMessages) {
            this.disabled = true;
            this.internalError = true;
            for (let i = 0; i < response.validationFailureMessages.length; i++) {
              this.msgs = [...this.msgs, { severity: 'error', summary: response.validationFailureMessages[i] }];
            }
          } else {
            this.submitEnabled$ = of(response.submitEnabled);
            this.disabled = false;
            const terminationDate = moment(response.terminationDate).toDate();

            if (terminationDate) {
              this.cancelForm.controls['terminationDate'].setValue(terminationDate);
            }
            this.internalError = false;

            if (!this.disabled && response.submitEnabled) {
              this.cancelForm.enable();
            }
          }
        }

      }, error => {
        this.disabled = true;
        this.internalError = true;

        let errorDetail = '';
        if (error.error && error.error.errorMessage) {
          errorDetail = error.error.errorMessage;
        }

        this.msgs = [...this.msgs, { severity: 'error', summary: error.statusText, detail: errorDetail }];
      });

  }

  public submit() {
    this.msgs = [];
    if (this.isFormValid()) {
      this.disabled = true;
      this.inProgress = true;
      const terminationDate: Date = this.cancelForm.controls['terminationDate'].value;
      const terminationDateParam = DateUtils.formatToISOIgnoreZone(terminationDate).split('T')[0];

      this.service.submitCancellation(this.historyId, terminationDateParam)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(response => {
          if (response.validationFailureMessages) {
            for (let i = 0; i < response.validationFailureMessages.length; i++) {
              this.msgs = [...this.msgs, { severity: 'error', summary: response.validationFailureMessages[i] }];
            }
            this.disabled = false;
          } else {
            this.cancelForm.disable();
            this.msgs = [...this.msgs, { severity: 'success', summary: 'Cancellation submitted successfully.' }];
          }
          this.inProgress = false;
        }, error => {
          let errorDetail = '';
          if (error.errorMessage) {
            errorDetail = error.errorMessage;
          }
          this.inProgress = false;
          this.msgs = [...this.msgs, { severity: 'error', summary: error.statusText, detail: errorDetail }];
        });
    }
  }

  isFormValid(): boolean {
    if (!this.cancelForm.valid) {
      if (this.cancelForm.controls['terminationDate'].invalid) {
        this.msgs = [...this.msgs, { severity: 'error', summary: 'Field \'Termination Date\' is required.' }];
      }
      return false;
    }
    return true;
  }

  isInProgress(): boolean {
    return this.inProgress;
  }

  ngOnDestroy(): void {
    this.submitEnabled$ = of(false);
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }
}
