import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Message } from 'primeng/api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Action, select, Store } from '@ngrx/store';
import { AddNote, getDealsColumns, mapRequestValues } from '@common/deals/deals.model';
import { getBlobContent, saveBlob, toPayload } from '@common/cms-common.model';
import { EsmNettingDashboardState } from '../dashboard.reducers';
import { EsmNettingDashboardTableState } from './table.reducers';
import { EsmNettingDashboardFiltersState } from '../filters/filters.reducers';
import { ApiRequestService } from '@common/api-request.service';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import {
  buildColumns,
  EsmDashboardTableDataRequest,
  EsmNettingDashboardBuckets,
  EsmNettingDashboardPresetFilters,
  EsmNettingDashboardTableType,
  FilterRequestModel
} from '../../../../netting/dashboard/esm-netting-dashboard.model';
import { EsmNettingStatementTableColumns } from '../../../../netting/finder/esm-netting-finder.model';
import * as fromTableSelectors from './table.selectors';
import * as fromFiltersSelectors from '../filters/filters.selectors';
import * as fromDashboardSelectors from '../dashboard.selectors';
import * as fromDBActions from '../dashboard.actions';
import * as fromDBTableActions from './table.actions';
import * as fromDBFiltersActions from '../filters/filters.actions';
import { ErrorResponse } from '@common/error-response.model';
import * as fromDialogActions from '../../finder/dialog/dialog.actions';
import { HttpParams } from '@angular/common/http';
import * as moment from 'moment';

@Injectable()
export class EsmNettingDashboardTableEffects {
  private baseUrl = '/api/esm/nettingstatement/dashboard';

  constructor(private apiGateway: ApiRequestService,
              private actions$: Actions,
              private router: Router,
              private store$: Store<EsmNettingDashboardState>,
              private mapper: MessagesMapperService) { }


  downloadPdf$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromDBTableActions.downloadPdfAction),
      map(toPayload),
      concatMap(id => this.apiGateway.getBlob(`${this.baseUrl}/download/pdf`, null, this.params(id))
        .pipe(switchMap(this.handleBlob), catchError(response => this.handleBlobErrorContent(response))))));


  filter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromDBFiltersActions.filterAction,
        fromDBFiltersActions.refreshFiltersAction,
        fromDBTableActions.sortAndPageDataAction
      ),
      withLatestFrom(
        this.store$.pipe(select(fromTableSelectors.getTable)),
        this.store$.pipe(select(fromFiltersSelectors.getFilters)),
        this.store$.pipe(select(fromDashboardSelectors.getNettingDashboardState)),
      ),
      switchMap(([, { paging, sorting }, filters, { viewType, state }]: [Action, EsmNettingDashboardTableState, EsmNettingDashboardFiltersState, EsmNettingDashboardState]) => (
        this.loadData(filters, paging, sorting, viewType, state)
      ))
    ));

  addNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromDBTableActions.addNoteAction),
      map(toPayload),
      concatMap(({confirmationIds: ids, text}: AddNote) => {
        return this.apiGateway.post(`/api/esm/nettingstatement/finder/createNote`, { ids, text })
          .pipe(
            switchMap((response: ErrorResponse & {}) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return of(fromDialogActions.updateDialogAction(messages));
              }
              return [
                fromDialogActions.closeDialogAction(),
                fromDialogActions.updateDialogAction([]),
                fromDBTableActions.addNoteSuccessAction()
              ];
            })
          );
      })
    ));

  addNotesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromDBTableActions.addNoteSuccessAction),
      filter(() => (this.router.routerState.snapshot.url.includes('esm/nettingdashboard'))),
      map(() => fromDBFiltersActions.filterAction()))
    );

  export$ = createEffect(() =>
      this.actions$.pipe(
        ofType(fromDBTableActions.exportAction),
        withLatestFrom(
          this.store$.pipe(select(fromTableSelectors.getTable)),
          this.store$.pipe(select(fromFiltersSelectors.getFilters)),
          this.store$.pipe(select(fromDashboardSelectors.getNettingDashboardState)),
        ),
        concatMap(([, { paging, sorting }, filters, { viewType, state }]: [Action, EsmNettingDashboardTableState, EsmNettingDashboardFiltersState, EsmNettingDashboardState]) =>
          this.apiGateway.getBlob(`${this.baseUrl}/loadTableData/export`,
            this.getFilterRequest(filters, paging, sorting, viewType, state))
            .pipe(
              switchMap(this.handleBlob),
              catchError(error => of(fromDBTableActions.setMessagesAction([this.mapper.createErrorMessage(error.errorMessage || 'Server Error')])))
          )
        ),
      ),
  );

  private loadData(filters: EsmNettingDashboardFiltersState, paging: PagingFilter, sorting: SortingFilter, viewType: EsmNettingDashboardTableType, state: string) {
    const request = this.getFilterRequest(filters, paging, sorting, viewType, state);
    return this.apiGateway
      .post(`${this.baseUrl}/${viewType}`, request)
      .pipe(
        switchMap((response: EsmDashboardTableDataRequest | EsmNettingDashboardBuckets | any) => {
          const messages: Message[] = this.mapper.toErrorMessages(response);
          if (messages && messages.length) {
            return [fromDBTableActions.setMessagesAction(messages)];
          }
          const isBucketView = viewType === EsmNettingDashboardTableType.BUCKETS;
          const columns = isBucketView ? buildColumns(response) : getDealsColumns(response.columnOrder, EsmNettingStatementTableColumns);
          return [
            isBucketView ? fromDBActions.setStateNamesAction(response.stateNames) : null,
            fromDBTableActions.setColumnsAction(columns),
            fromDBFiltersActions.filterSuccessAction(response),
            fromDBTableActions.setMessagesAction([]),
          ].filter(Boolean);
        }),
        catchError(error => of(fromDBTableActions.setMessagesAction([this.mapper.createErrorMessage(error.errorMessage || 'Server Error')])))
      );
  }

  private getFilterRequest(filters: EsmNettingDashboardFiltersState,
                           paging: PagingFilter,
                           sorting: SortingFilter,
                           viewType: EsmNettingDashboardTableType,
                           state: string): EsmNettingDashboardPresetFilters | any {
    const { counterParties, commodities, deliveryPoints, nettingStatementTypes, paymentDateRange, invoiceStartDate, invoiceEndDate } = filters.value;
    const { nettingStatementRole, category, dateRange } = filters;
    const filterRequestModel: FilterRequestModel = {
      commodities: mapRequestValues(commodities, filters.commodities),
      deliveryPoints: mapRequestValues(deliveryPoints, filters.deliveryPoints),
      nettingStatementTypes: mapRequestValues(nettingStatementTypes, filters.nettingStatementTypes),
      counterParties: mapRequestValues(counterParties, filters.counterParties? filters.counterParties.map(item => item.key) : []),
      nettingStatementRole: nettingStatementRole,
      category,
      paymentDateRange: {
        start: paymentDateRange && paymentDateRange.start ? moment(paymentDateRange.start).format('YYYY-MM-DD') : null,
        end: paymentDateRange && paymentDateRange.end ? moment(paymentDateRange.end).format('YYYY-MM-DD') : null
      },
      invoiceStartDate: invoiceStartDate ? moment(invoiceStartDate).format('YYYY-MM-DD') : null,
      invoiceEndDate: invoiceEndDate ? moment(invoiceEndDate).format('YYYY-MM-DD') : null
    };

    if (viewType === EsmNettingDashboardTableType.BUCKETS) {
      return filterRequestModel;
    } else if (viewType === EsmNettingDashboardTableType.TABLE_DATA) {
      return {
        filterRequestModel,
        sorting,
        paging,
        dateRange,
        state
      };
    }
    return filters.value;
  }

  private handleBlob(response): Observable<ReturnType<typeof fromDBTableActions.setMessagesAction>> {
    saveBlob(response);
    return of(fromDBTableActions.setMessagesAction([]));
  }


  /**
   * a perfect transformation of promise to observable response
   *
   * TODO move it to commons
   */
  private handleBlobErrorContent(errResponse):Observable<Action> {
    window.scrollTo(0, 0); // scroll top if error occurred

    return new Observable(observer => {
      const controller = new AbortController();

      getBlobContent(errResponse)
        .then(message => {
          observer.next(fromDBTableActions.setMessagesAction([this.mapper.createErrorMessage(message)]));
          observer.complete();})
        .catch(err => {observer.error(err);});

      return () => controller.abort();
    });
  }

  params = (id) => new HttpParams().set('documentId', id);


}
