import { createSelector } from '@ngrx/store';
import { getNettingDashboardState } from '../dashboard.selectors';
import { EsmNettingDashboardFiltersState } from './filters.reducers';
import { EsmNettingDashboardState } from '../dashboard.reducers';

export const getFilters = createSelector(getNettingDashboardState, (state: EsmNettingDashboardState) => state.filters);
export const getFiltersValues = createSelector(getFilters, (state: EsmNettingDashboardFiltersState) => state.value);
export const getCommoditiesValues = createSelector(getFilters, (state: EsmNettingDashboardFiltersState) => state.commodities);
export const getCounterPartiesValues = createSelector(getFilters, (state: EsmNettingDashboardFiltersState) => state.counterParties);
export const getDeliveryPointsValues = createSelector(getFilters, (state: EsmNettingDashboardFiltersState) => state.deliveryPoints);
export const getNettingTypesValues = createSelector(getFilters, (state: EsmNettingDashboardFiltersState) => state.nettingStatementTypes);
export const getNettingStatementRoleValue = createSelector(getFilters, (state: EsmNettingDashboardFiltersState) => state.nettingStatementRole);
export const getDateRange = createSelector(getFilters, (state: EsmNettingDashboardFiltersState) => state.dateRange);
export const getDateRangeText = createSelector(getNettingDashboardState, (state: EsmNettingDashboardState) => state.table.dateRangeText);
export const getType = createSelector(getNettingDashboardState, (state: EsmNettingDashboardState) => state.viewType);

