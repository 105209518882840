import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { OrganisationService } from '@common/organisation.service';
import { DateRangeFilter } from '@common/shared/shared.model';
import { DateUtils } from '@common/shared/date.utils';
import { ValidationUtils } from '@common/shared/validation.utils';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Message } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { RemitReportService } from '../remit-report.service';
import { AbstractFormComponent } from '../../../smt/dealfinder/filter/abstract-filter.component';

@Component({
  selector: 'cms-remit-billing-report',
  templateUrl: './remit-billing-report.component.html',
  styleUrls: ['./remit-billing-report.component.scss']
})
export class RemitBillingReportComponent extends AbstractFormComponent implements OnInit, OnDestroy {
  private static readonly startDateControlName = 'startDate';
  private static readonly endDateControlName = 'endDate';
  private static readonly startDateAfterEndDateErrorCode = 'startDateAfterEndDate';
  private static readonly invalidDaysRangeErrorCode = 'invalidDaysRange';

  filterForm: UntypedFormGroup;
  messages: Message[] = [];
  downloading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private onDestroy$ = new Subject();

  constructor(private organisationService: OrganisationService,
              private remitReportService: RemitReportService,
              private formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.createForm();
    this.initStartEndDate();
    this.downloading.next(false);
  }

  private createForm(): void {
    this.filterForm = this.formBuilder.group({
        [RemitBillingReportComponent.startDateControlName]: ['', [Validators.required]],
        [RemitBillingReportComponent.endDateControlName]: ['', [Validators.required]]
      },
      { validator: this.extraValidator }
    );
  }

  protected getFormGroup(): UntypedFormGroup {
    return this.filterForm;
  }

  private extraValidator(group: UntypedFormGroup): void {
    function validateNotStartDateAfterEndDate(startDateControlName: string, endDateControlName: string, errorCode: string): void {
      const startDateField: AbstractControl = group.controls[startDateControlName];
      const endDateField: AbstractControl = group.controls[endDateControlName];

      ValidationUtils.removeError(startDateField, errorCode);
      ValidationUtils.removeError(endDateField, errorCode);

      if (startDateField.value && endDateField.value) {
        const startDateValue: Date = new Date(startDateField.value);
        const endDateValue: Date = new Date(endDateField.value);

        startDateValue.setHours(0);
        startDateValue.setMinutes(0);
        endDateValue.setHours(0);
        endDateValue.setMinutes(0);

        if (startDateValue.getTime() > endDateValue.getTime()) {
          ValidationUtils.addError(startDateField, errorCode, true);
          ValidationUtils.addError(endDateField, errorCode, true);
        }
      }
    }

    function validateNotInvalidDaysRange(startDateControlName: string, endDateControlName: string, errorCode: string): any {
      const startDateField = group.controls[startDateControlName];
      const endDateField = group.controls[endDateControlName];

      ValidationUtils.removeError(startDateField, errorCode);
      ValidationUtils.removeError(endDateField, errorCode);

      if (startDateField.value && endDateField.value) {
        const startDateValue: Date = new Date(startDateField.value);
        const endDateValue: Date = new Date(endDateField.value);

        const days = DateUtils.dateDiffInDays(startDateValue, endDateValue);
        // dateDiffInDays(2018-05-01, 2018-05-31) = 30 but report would be for 31 days
        const daysUsedInReport = days + 1;
        if (daysUsedInReport > 31) {
          ValidationUtils.addError(startDateField, errorCode, true);
          ValidationUtils.addError(endDateField, errorCode, true);
        }
      }
    }

    validateNotStartDateAfterEndDate(RemitBillingReportComponent.startDateControlName, RemitBillingReportComponent.endDateControlName, RemitBillingReportComponent.startDateAfterEndDateErrorCode);
    validateNotInvalidDaysRange(RemitBillingReportComponent.startDateControlName, RemitBillingReportComponent.endDateControlName, RemitBillingReportComponent.invalidDaysRangeErrorCode);
  }

  isStartDateAfterEndDate(): boolean {
    return this.isError(RemitBillingReportComponent.endDateControlName, RemitBillingReportComponent.startDateAfterEndDateErrorCode);
  }

  isInvalidDaysRange(): boolean {
    return this.isError(RemitBillingReportComponent.endDateControlName, RemitBillingReportComponent.invalidDaysRangeErrorCode);
  }

  initStartEndDate(): void {

    // IE10 compatible initialisation
    const current = new Date();
    const start = new Date(current.getFullYear(), current.getMonth() - 1, 1);
    this.filterForm.controls[RemitBillingReportComponent.startDateControlName].setValue(start);
    const end = new Date(current.getFullYear(), current.getMonth(), 0);
    this.filterForm.controls[RemitBillingReportComponent.endDateControlName].setValue(end);
  }

  onSubmit(): void {
    this.setFormSubmitAttempt(true);
    this.messages = [];
    if (this.isFormValid()) {
      this.searchByFilter();
    }
  }

  protected isFormValid(): boolean {
    if (!super.isFormValid()) {
      if (this.isRequiredError(RemitBillingReportComponent.startDateControlName)) {
        this.messages.push({ severity: 'error', detail: 'Field \'Start Date\' is required.' });
      }
      if (this.isRequiredError(RemitBillingReportComponent.endDateControlName)) {
        this.messages.push({ severity: 'error', detail: 'Field \'End Date\' is required.' });
      }
      if (this.isStartDateAfterEndDate()) {
        this.messages.push({ severity: 'error', detail: '\'End Date\' must not precede \'Start Date\'.' });
      }
      if (this.isInvalidDaysRange()) {
        this.messages.push({ severity: 'error', detail: 'Reporting period for \'eRR Billing\' is limited to 31 days.' });
      }
      return false;
    }
    return true;
  }

  private searchByFilter(): void {
    const startDate: Date = this.filterForm.controls[RemitBillingReportComponent.startDateControlName].value;
    const endDate: Date = this.filterForm.controls[RemitBillingReportComponent.endDateControlName].value;
    const dateRange = new DateRangeFilter(startDate, endDate);

    this.downloading.next(true);
    this.messages.push({ severity: 'info', detail: 'Generating billing report...' });
    this.remitReportService.downloadBillingReport(dateRange)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(data => {
        FileSaver.saveAs(data.blob, data.filename);
        this.downloading.next(false);
        this.messages = [];
        this.messages.push({ severity: 'success', detail: 'Download of billing report successful.' });
      }, error => {
        this.downloading.next(false);
      });
  }

  isDownloading(): Observable<boolean> {
    return this.downloading.asObservable();
  }

  ngOnDestroy(): void {
    // Clean up all subscriptions at once:
    this.messages = [];
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }
}
