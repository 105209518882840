import { generateInitialDate, KeyValueProp } from '../../../invoice/finder/invoice-finder.model';
import { createReducer, on } from '@ngrx/store';
import * as fromNettingStatementFinderActions from '../../netting/finder/esm-netting-finder.actions';
import * as fromNettingStatementTableActions from '../../netting/finder/table/table.actions';
import {
  NettingStatementFinderPropertyFilter,
  NettingStatementFinderReferencesFilter,
} from '../../../netting/finder/esm-netting-finder.model';
import { EsmNettingStatementTableState, tableInitialState, tableReducer } from './table/table.reducers';
import {
  esmNettingStatementFinderMyFiltersReducer,
  EsmNettingStatementFinderMyFiltersState,
  myFilterInitialState
} from './my-filter/my-filter.reducers';
import * as fromCommon from '@common/state/reducers';
import { esmNettingStatementFinderDialogReducer, NETTING_STATEMENT_DIALOGS } from './dialog/dialog.reducers';

export type SearchBy =  'filter' | 'reference';

// filter search state
export interface EsmNettingStatementFinderFilterState {
  filter: NettingStatementFinderPropertyFilter; // for selected filter

  // possible filter values
  commodities: string[];
  counterParties: KeyValueProp[];
  deliveryPoints: { keyObject: string, valueObject: string }[];
  nettingStatementTypes: string[];
  senderUs: boolean;
  loaded: boolean;
}

// reference search state
export interface EsmNettingStatementFinderReferencesState {
  references: NettingStatementFinderReferencesFilter; // for selected filter
  idCount: number;
  notFoundIds: string[];
}

// netting finder state
export interface EsmNettingStatementFinderState {
  filterState: EsmNettingStatementFinderFilterState;
  myFilterState: EsmNettingStatementFinderMyFiltersState;
  referencesState: EsmNettingStatementFinderReferencesState;
  dialog: fromCommon.DialogState<NETTING_STATEMENT_DIALOGS>;
    table: EsmNettingStatementTableState;
}

// initial filter search state
export const initialFilterState: EsmNettingStatementFinderFilterState = {
  filter: {
    commodities: [],
    counterParties: [],
    deliveryPoints: [],
    nettingStatementTypes: [],
    invoicePeriod: {
      start: null,
      end: null
    },
    paymentDateRange: {
      start: null,
      end: null
    },
    submissionDateRange: {
      start: generateInitialDate(19),
      end: null
    },
    senderUs: true
  },
  commodities: [],
  counterParties: [],
  nettingStatementTypes: [],
  deliveryPoints: [],
  senderUs: true,
  loaded: false,
};

// initial reference search state
export const initialReferencesState: EsmNettingStatementFinderReferencesState = {
 references: null,
  idCount: 0,
  notFoundIds: []
};


// initial netting statement finder state
export const initialNettingStatementFinderState: EsmNettingStatementFinderState = {
  filterState: initialFilterState,
  myFilterState: myFilterInitialState,
  referencesState: initialReferencesState,
  dialog: null,
  table: tableInitialState,
};

// filter state reducer
export const esmNettingStatementFinderFilterReducer = createReducer<EsmNettingStatementFinderFilterState,
  fromNettingStatementFinderActions.NettingFinderActions>(
  initialFilterState,
  on(fromNettingStatementFinderActions.searchByFilterAction, (state: EsmNettingStatementFinderFilterState, { payload }): EsmNettingStatementFinderFilterState => ({
    ...state,
    filter: payload,
  })),
  on(fromNettingStatementFinderActions.onFormValuesResponseAction, (state: EsmNettingStatementFinderFilterState, { payload }): EsmNettingStatementFinderFilterState => {
    const {
      commodities,
      counterParties,
      nettingStatementTypes,
      deliveryPoints
    } = payload;
    return {
      ...state,
      commodities,
      counterParties,
      nettingStatementTypes,
      deliveryPoints: deliveryPoints.map(({ key, value }) => ({ keyObject: key, valueObject: value })),
      loaded: true,
      filter: {
        ...state.filter,
        commodities,
        counterParties: counterParties ? counterParties.map(item => item.key) : [],
        deliveryPoints: deliveryPoints.map(({ key, value }) => ({ keyObject: key, valueObject: value })),
        nettingStatementTypes,
        senderUs: true
      }
    };
  }),
  on(fromNettingStatementFinderActions.updateFiltersAction, (state: EsmNettingStatementFinderFilterState, { payload }): EsmNettingStatementFinderFilterState => ({
    ...state,
    loaded: true,
    filter: {
      ...state.filter,
      ...payload
    }
  })),
  on(fromNettingStatementFinderActions.setDefaultStateAction, (state: EsmNettingStatementFinderFilterState): EsmNettingStatementFinderFilterState => ({
    ...initialFilterState
  })),
);

// reference state reducer
export const esmNettingStatementFinderReferencesReducer = createReducer<EsmNettingStatementFinderReferencesState,
  fromNettingStatementFinderActions.NettingFinderActions>(
  initialReferencesState,
  on(fromNettingStatementFinderActions.searchByReferencesAction, (state: EsmNettingStatementFinderReferencesState, { payload }): EsmNettingStatementFinderReferencesState => ({
    ...state,
    references: payload,
  })),
  on(fromNettingStatementFinderActions.setReferencesFilterIdsAction, (state: EsmNettingStatementFinderReferencesState, { payload }): EsmNettingStatementFinderReferencesState => ({
    ...state,
    idCount: payload.idCount,
    notFoundIds: payload.notFoundIds
  })),

  on(fromNettingStatementFinderActions.resetReferenceFilterAction, (state: EsmNettingStatementFinderReferencesState): EsmNettingStatementFinderReferencesState => ({
    ...initialReferencesState
  })),
);


// create netting statement finder reducers (activated via index.ts)
export function initEsmNettingStatementFinderReducer(
  state: EsmNettingStatementFinderState = { ...initialNettingStatementFinderState },
  action: fromNettingStatementFinderActions.NettingFinderActions & fromNettingStatementTableActions.NettingStatementFinderTableActions): EsmNettingStatementFinderState {
  return {
    ...state,
    filterState: esmNettingStatementFinderFilterReducer(state.filterState, action),
    referencesState: esmNettingStatementFinderReferencesReducer(state.referencesState, action),
    myFilterState: esmNettingStatementFinderMyFiltersReducer(state.myFilterState, action),
    dialog: esmNettingStatementFinderDialogReducer(state.dialog, action),
    table: tableReducer(state.table, action),
  };
}

export const getFilter = (state: EsmNettingStatementFinderFilterState) => state.filter;
export const getReferences = (state: EsmNettingStatementFinderReferencesState) => state.references;
export const getFormValues = (state: EsmNettingStatementFinderFilterState) => {
    return {
      commodities: state.commodities,
      nettingStatementTypes: state.nettingStatementTypes,
      counterParties: state.counterParties,
      deliveryPoints: state.deliveryPoints
  };
};

