import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { EsmNettingRoleByType } from '../esm-netting-dashboard.model';

@Component({
  selector: 'cms-esm-netting-role-by-filter',
  templateUrl: './esm-netting-role-by-filter.component.html',
  styleUrls: ['./esm-netting-role-by-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EsmNettingRoleByFilterComponent implements OnInit, OnChanges {
  @Input() value: string;
  @Input() disabled: boolean;
  @Output() onChange = new EventEmitter<EsmNettingRoleByType>();
  form: UntypedFormGroup;
  readonly EsmNettingRoleByType = EsmNettingRoleByType;

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      roleBy: this.value || '',
    });
  }

  handleChange(): void {
    const value = this.form.getRawValue().roleBy || null;
    this.onChange.emit(value);
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (this.form && changes.value && this.value !== this.form.value.roleBy) {
      this.form.patchValue({ roleBy: this.value || '' });
      this.form.updateValueAndValidity();
    }
  }
}
