import { Component, Input } from '@angular/core';

@Component({
  selector: 'cms-transaction-sheet-group-item',
  template: `
        <div class="row flex-nowrap group-item group-item-{{name}}">
            <div class="item-label-col"><span class="item-label">{{label}}</span></div>
            <ng-container *ngIf="values?.length > 1; else elseDefaultValueTemplate">
                <div class="col-7">
                    <div class="row" *ngFor="let value of values">
                        <div class="col item-value-col">
                            <span class="item-value">
                                {{value}}
                            </span>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #elseDefaultValueTemplate>
                <div class="col-auto item-value-col"><span class="item-value">{{values}}</span></div>
            </ng-template>
        </div>
    `
})
export class TransactionSheetGroupItemComponent {

  @Input() name: string;

  @Input() label: string;

  @Input() values: string[];

}
