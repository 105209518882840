
export interface TableEntry {
  id: number;
  permission: string;
  eicCode: number;
  displayName: string;
  lastUpdate: string;
}


const defaultStyles = {
  'white-space': 'nowrap',
  'height':'22px',
};


export const esmPresetsTableColumns = [
  { name: 'id', type: 'hidden', title: '', style: '', sortable: false, visible: false },
  { name: 'permission', type: 'text', title: 'Permission', style: { ...defaultStyles}, sortable: true, visible: true },
  { name: 'eicCode', type: 'text', title: 'EIC Code', style: { ...defaultStyles}, sortable: true, visible: true },
  { name: 'displayName', type: 'text', title: 'Display Name', style: { ...defaultStyles}, sortable: true, visible: true },
  { name: 'lastUpdate', type: 'date', title: 'Last Update', style: { ...defaultStyles}, sortable: true, visible: true }
];
