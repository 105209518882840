import { createReducer, on } from '@ngrx/store';
import { Message } from 'primeng/api';
import { DocumentSide, DocumentType, WhitelistRecipientsData } from '../../../settings/subscription/email-subscription.model';
import * as fromSubscriptionActions from './subscription-settings.actions';

export interface SubscriptionSettingsState {
  messages: Message[];
  editAllowed: boolean;
  matchedInvoicePdf: boolean;
  matchedNettingStatementPdf: boolean;
  matchedInvoiceXml: boolean;
  matchedNettingStatementXml: boolean;
  documentType: DocumentType;
  documentSide: DocumentSide;
  esmEmailWhitelistRecipients: WhitelistRecipientsData[];
}

export const initialState: SubscriptionSettingsState = {
  messages: [],
  editAllowed: false,
  matchedInvoicePdf: false,
  matchedNettingStatementPdf: false,
  matchedInvoiceXml: false,
  matchedNettingStatementXml: false,
  documentType: DocumentType.OFFICIAL,
  documentSide: DocumentSide.OWN_DOCUMENT,
  esmEmailWhitelistRecipients: null,
};

export const esmSubscriptionSettingsReducer = createReducer<SubscriptionSettingsState, fromSubscriptionActions.SubscriptionSettingsAction>(
  initialState,
  on(fromSubscriptionActions.loadDataSuccess, (state: SubscriptionSettingsState, { payload: data }): SubscriptionSettingsState => ({
    ... state,
      editAllowed: data.editAllowed,
      matchedInvoicePdf: data.matchedInvoicePdf,
      matchedNettingStatementPdf: data.matchedNettingStatementPdf,
      matchedInvoiceXml: data.matchedInvoiceXml,
      matchedNettingStatementXml: data.matchedNettingStatementXml,
      documentType: data.documentType,
      documentSide: data.documentSide,
      esmEmailWhitelistRecipients: data.esmEmailWhitelistRecipients
    }
  )),

  on(fromSubscriptionActions.setMatchedInvoicePdf, (state: SubscriptionSettingsState, {payload: flag}) => ({ ...state, matchedInvoicePdf: flag })),
  on(fromSubscriptionActions.setMatchedNettingPdf, (state: SubscriptionSettingsState, {payload: flag}) => ({ ...state, matchedNettingStatementPdf: flag })),
  on(fromSubscriptionActions.setMatchedInvoiceXml, (state: SubscriptionSettingsState, {payload: flag}) => ({ ...state, matchedInvoiceXml: flag })),
  on(fromSubscriptionActions.setMatchedNettingXml, (state: SubscriptionSettingsState, {payload: flag}) => ({ ...state, matchedNettingStatementXml: flag })),
  on(fromSubscriptionActions.setDocumentType, (state: SubscriptionSettingsState,  {payload: value}) => ({ ...state, documentType: value })),
  on(fromSubscriptionActions.setDocumentSide, (state: SubscriptionSettingsState,  {payload: value}) => ({ ...state, documentSide: value })),
  on(fromSubscriptionActions.setMessages, (state, { messages }) => ({ ...state, messages: messages })),
  on(fromSubscriptionActions.setToDefaultAction, () =>    ({ ...initialState })),
);
