import { createSelector } from '@ngrx/store';
import { ContactsState } from '../contacts/contacts.reducers';
import { getContactsState } from '../contacts/contacts.selectors';
import { DatatableState } from '../datatable/datatable.reducer';
import { UserContacts, UserContactsFilters } from '../../contacts/contacts.model';
import * as fromDatatable from '../datatable/datatable.reducer';

export const getUserDatatableState = createSelector(getContactsState, (state: ContactsState) => state.usersDt);

export const getFilters = createSelector(getUserDatatableState,
  (state: DatatableState<UserContacts, UserContactsFilters>) => state.filters);
export const getData = createSelector(getUserDatatableState, fromDatatable.getData);
export const getSorting = createSelector(getUserDatatableState, fromDatatable.getSorting);
export const getPaging = createSelector(getUserDatatableState, fromDatatable.getPaging);
export const getLoading = createSelector(getUserDatatableState, fromDatatable.getLoading);
export const getMessages = createSelector(getUserDatatableState, fromDatatable.getMessages);
export const getSelected = createSelector(getUserDatatableState, fromDatatable.getSelected);
