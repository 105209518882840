import { createSelector } from '@ngrx/store';
import { getEsmState, State } from '../reducers';
import { EsmDashboardState } from './dashboard.reducers';

export const getDashboardState = createSelector(getEsmState, (state: State) => state.dashboard);
export const getViewType = createSelector(getDashboardState, (state: EsmDashboardState) => state.viewType);
export const getState = createSelector(getDashboardState, (state: EsmDashboardState) => state.state);
export const getStateNames = createSelector(getDashboardState, (state: EsmDashboardState) => state.stateNames);
export const getDialog = createSelector(getDashboardState, (state: EsmDashboardState) => state.dialog);
export const getMessage = createSelector(getDashboardState, (state: EsmDashboardState) => state.messges);
