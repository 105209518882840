<div class="trade-reference">
  <div class="form-title">Trade | Delivery</div>
  <div class="form-body" [formGroup]="formGroup">
    <div class="row gutter-0 !me-[40px]">
      <div class="col-5 label">Delivery Start <span class="text-required">*</span></div>
      <div class="col-7 ps-2">
        <span id="remit-trade-entry-table2-delivery-start" class="inline-block padding-right-3 margin-3">
          <p-calendar
            formControlName="deliveryStart"
            placeholder="dd/mm/yyyy"
            [showIcon]="true"
            [monthNavigator]="true"
            [yearNavigator]="true"
            dateFormat="dd/mm/yy"
            [style]="{ 'width': '125px'}"
            [styleClass]="getValidationErrorStyleClass('deliveryStart')"
            cmsDateRestriction>
          </p-calendar>
        </span>
      </div>
      <span *ngIf="formGroup.controls['deliveryStart'].hasError('error')"
        class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0 !me-[40px]">
      <div class="col-5 label">Delivery End <span class="text-required">*</span></div>
      <div class="col-7 ps-2 ">
        <span id="remit-trade-entry-table2-delivery-end" class="inline-block padding-right-3 margin-3">
          <p-calendar
            formControlName="deliveryEnd"
            placeholder="dd/mm/yyyy"
            [showIcon]="true"
            [monthNavigator]="true"
            [yearNavigator]="true"
            dateFormat="dd/mm/yy"
            [style]="{ 'width': '125px'}"
            [styleClass]="getValidationErrorStyleClass('deliveryEnd')"
            cmsDateRestriction>
          </p-calendar>
        </span>
      </div>
      <span *ngIf="formGroup.controls['deliveryEnd'].hasError('error')"
        class="fa fa-info-circle absolute error-marker"></span>
    </div>
  </div>
</div>
