import * as moment from 'moment';

export interface AdditionalDateRangeEntry {
  end: Date;
  start: Date;
  label: string;
}

export function getBasicDateRanges(): AdditionalDateRangeEntry[] {
  const now = moment().startOf('day');
  return [
    { start: now.clone().toDate(), end: now.clone().toDate(), label: 'Today' },
    { start: now.clone().subtract(6, 'day').toDate(), end: now.clone().toDate(), label: 'Last 7 Days' },
    { start: now.clone().startOf('month').toDate(), end: now.clone().toDate(), label: 'Month to date' },
    { start: now.clone().startOf('year').toDate(), end: now.clone().toDate(), label: 'Year to date' },
    {
      start: now.clone().subtract(1, 'months').startOf('month').toDate(),
      end: now.clone().subtract(1, 'months').endOf('month').toDate(),
      label: 'Previous month'
    }
  ];
}

export function getEsmDashboardBucketRanges(): AdditionalDateRangeEntry[] {
  const now = moment().startOf('day');
  return [
    {
      start: now.clone().add(1, 'months').startOf('month').toDate(),
      end: now.clone().add(1, 'months').endOf('month').toDate(),
      label: 'Next month'
    },
    {
      start: now.clone().startOf('month').toDate(),
      end: now.clone().endOf('month').toDate(),
      label: 'Current month'
    },
    {
      start: now.clone().subtract(1, 'months').startOf('month').toDate(),
      end: now.clone().subtract(1, 'months').endOf('month').toDate(),
      label: 'Last month'
    },
    {
      start: now.clone().subtract(2, 'months').startOf('month').toDate(),
      end: now.clone().subtract(2, 'months').endOf('month').toDate(),
      label: '2 months before'
    },
    {
      start: now.clone().subtract(3, 'months').startOf('month').toDate(),
      end: now.clone().subtract(3, 'months').endOf('month').toDate(),
      label: '3 months before'
    },
    {
      start: now.clone().subtract(4, 'months').startOf('month').toDate(),
      end: now.clone().subtract(4, 'months').endOf('month').toDate(),
      label: '4 months before'
    }
  ];
}
