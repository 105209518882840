<div>
  <div class="p-panel p-component p-component-content p-corner-all" role="panel">
    <div class="p-panel-titlebar p-component-header p-corner-all">
      <span class="p-panel-title">{{title}}</span>
    </div>
  </div>
  <div class="row information" *ngIf="information">
    <div class="col-md-4 col-lg-5 col-12">
      <div class="row">
        <div class="col-6 label"> Position Report File:</div>
        <div class="col-6">{{ information.filename }}</div>
      </div>
      <div class="row">
        <div class="col-6 label">Submitting OMP:</div>
        <div class="col-6">{{ information.ompName }}</div>
      </div>
      <div class="row">
        <div class="col-6 label">Document Type:</div>
        <div class="col-6">{{ information.docType }}</div>
      </div>
    </div>
    <div class="col-md-4 col-lg-3 col-12">
      <div class="row">
        <div class="col-6 label">Document State:</div>
        <div class="col-6">{{ information.state }}</div>
      </div>
    </div>
    <div class="col-md-4 col-lg-4 col-12">
      <div class="row">
        <div class="col-6 label">Number of Records:</div>
        <div class="col-6">{{ information.recordCount }}</div>
      </div>
      <div class="row">
        <div class="col-6 label">Receipt Timestamp:</div>
        <div class="col-6">{{ information.receiptTimestamp }}</div>
      </div>
    </div>
  </div>

  <div class="table-container">
    <cms-datatable [hidden]="!(data$ | async)"
                   selectionMode="single"
                   [currentPage]="(paging$ | async).page"
                   [tableColumns]="(columns$ | async)"
                   [sortField]="(sorting$ | async).columnName"
                   [sortOrder]="(sorting$ | async).sortOrder"
                   [loading]="loading$ | async"
                   [data]="(data$ | async)?.values"
                   [totalRecords]="(data$ | async)?.count"
                   [autoLayout]="true"
                   [scrollable]="false"
                   [lazy]="!!((data$ | async)?.values?.length)"
                   (lazyLoad)="onLazyLoad($event)">
      <ng-template pTemplate="body" let-row="row" let-column="column">
        <!--edit button-->
        <td *ngIf="editable && column.type === 'button' && column.name === 'edit'" [ngStyle]="column.style">
          <div class="btn-wrapper inline-block" *ngIf="row.editable">
            <button pButton type="button" icon="fa fa-pencil" title="Edit position" (click)="onOpenEdit(row)"></button>
          </div>
        </td>

        <!--delete button-->
        <td *ngIf="editable && column.type === 'button' && column.name === 'delete'" [ngStyle]="column.style">
          <div class="btn-wrapper inline-block" *ngIf="row.editable">
            <button pButton type="button" icon="fa fa-trash" (click)="onDeleteItem(row)"
                    title="Delete position"></button>
          </div>
        </td>

        <!--custom cells-->
        <td *ngIf="column.type === 'edited'" [ngStyle]="column.style">
          <span [class.edited]="row[column.name].edited || row.positionState === 'Added'"
                [class.error-note]="row.positionState === 'Deleted'">{{row[column.name]?.value}}</span>
        </td>
        <td *ngIf="column.type === 'currency-edited'" [ngStyle]="column.style">
          <span [class.edited]="row[column.name].edited || row.positionState === 'Added'"
                [class.error-note]="row.positionState === 'Deleted'">{{row[column.name]?.value | number:'1.0-15':'en-US'}}</span>
        </td>
      </ng-template>
    </cms-datatable>

    <div class="text-end mt-3">
      <button *ngIf="information?.addPositionEnabled" type="button" class="me-2 btn" (click)="onOpenAddNew()">
        Add Position Record
      </button>
      <button *ngIf="information?.confirmFileEnabled" (click)="onOpenConfirm()" type="button" class="me-2 btn">
        Confirm File
      </button>
      <button *ngIf="information?.discardChangesEnabled" (click)="onOpenDiscard()" type="button" class="btn">
        Discard changes
      </button>
    </div>
  </div>
</div>

<cms-err-mifid-details-view *ngIf="dialog$ | async as dialog"
                            [display]="dialog?.name === 'ADD_NEW' || dialog.name === 'EDIT'"
                            [messages]="dialog?.messages"
                            [formValues]="formValues$ | async"
                            [isFCAPlus]="isFCAPlus"
                            [data]="selectedRow"
                            (exit)="closeEditDialog()"
                            (save)="onSaveItem($event)"></cms-err-mifid-details-view>

<cms-mifid-confirm-dialog *ngIf="dialog$ | async as dialog"
                          [text]="'Do you really want to confirm all changes in File \'' + filename +
                          '\' and send it back to the OMP?'"
                          [header]="'Confirm changes to PositionRecords'"
                          [buttonText]="'Confirm'"
                          [messages]="dialog?.messages"
                          [display]="dialog?.name === 'CONFIRM_FILE'"
                          (cancel)="closeDialog()"
                          (confirm)="onConfirm()"></cms-mifid-confirm-dialog>

<cms-mifid-confirm-dialog *ngIf="dialog$ | async as dialog"
                          [text]="'Do you really want to discard all changes made in File \'' + filename + '\'?'"
                          [header]="'Discard changes to PositionRecords'"
                          [buttonText]="'Discard'"
                          [messages]="dialog?.messages"
                          [display]="dialog?.name === 'DISCARD_FILE'"
                          (cancel)="closeDialog()"
                          (confirm)="onDiscard()"></cms-mifid-confirm-dialog>
