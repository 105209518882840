import { Column } from '../../../common/shared/datatable.model';

export namespace ErrRemitDataTableRemitTable1Config {

   const defaultStyles = {
    'text-align': 'left',
      'text-overflow': 'ellipsis',
      'overflow': 'hidden',
      'white-space': 'nowrap',
      'height': '27px'
  };


  export const TABLE_COLUMNS: { [name: string]: Column } = {
    // Keep this and the following files aligned.
    // app.bsl/de.ponton.cmh.bsl.bslimpl/src/de/ponton/cmh/bsl/bslimpl/internal/service/userinterfacecustomisation/ErrRemitT1PersonalColumnEnum.java
    // app.gui/de.ponton.cmh.err.ui.data/src/de/ponton/cmh/err/ui/data/uio/remit/transactions/table1/ErrRemitTable1TransactionsUIO.properties
    // app.gui/de.ponton.cmh.err.ui.components/src/de/ponton/cmh/err/ui/components/remit/transactions/table1/columnorder/ErrRemitTable1ColumnOrderDataPanel.properties

    creationTimestamp: { title: 'Received by eRR', style: { 'width': '165px',  ...defaultStyles } },
    lastUpdate: { title: 'Last Update', style: { 'width': '165px',  ...defaultStyles } },
    source: { title: 'Source', style: { 'width': '95px',  ...defaultStyles } },
    originatorName: { title: 'Sender Name', style: { 'width': '150px',  ...defaultStyles } },
    buySellIndicator: { title: 'B/S', longTitle: 'Buy/Sell Indicator', style: { 'width': '70px',  ...defaultStyles } },
    recordType: { title: 'Type', longTitle: 'Type of Record', style: { 'width': '80px',  ...defaultStyles } },
    action: { title: 'Action', longTitle: 'Action Type', style: { 'width': '90px',  ...defaultStyles } },
    transactionTimestampUtc: { title: 'Transaction Time (UTC)', style: { 'width': '215px',  ...defaultStyles } },
    buttons: {
      title: 'Buttons',
      style: { 'width': '152px',  ...defaultStyles },
      sortable: false,
      hiddenTitle: true
    },
    state: { title: 'State', style: { 'width': '130px',  ...defaultStyles } },
    transactionId: { title: 'UTI / Order ID', style: { 'width': '400px',  ...defaultStyles } },
    ompName: { title: 'OMP Name', style: { 'width': '150px',  ...defaultStyles } },
    mpName: { title: 'MP Name', longTitle: 'MP Name (LEI Registry)', style: { 'width': '150px',  ...defaultStyles } },
    otherMpPartyCode: { title: 'Other MP Code', style: { 'width': '190px',  ...defaultStyles } },
    deleted: { title: 'Deleted', style: { 'width': '100px',  ...defaultStyles } },

    beneficiaryPartyCode: { title: 'Beneficiary Code', style: { 'width': '170px',  ...defaultStyles } },
    commodity: { title: 'Comm', longTitle: 'Commodity', style: { 'width': '100px',  ...defaultStyles } },
    contractId: { title: 'Contract ID', longTitle: 'Contract ID (Table 1)', style: { 'width': '130px',  ...defaultStyles } },
    contractType: { title: 'Contract Type', style: { 'width': '150px',  ...defaultStyles } },
    contractName: { title: 'Contract Name', style: { 'width': '160px',  ...defaultStyles } },
    deliveryPoint: { title: 'Delivery Point', style: { 'width': '150px',  ...defaultStyles } },
    deliveryStartDate: { title: 'Delivery Start', style: { 'width': '140px',  ...defaultStyles } },
    deliveryEndDate: { title: 'Delivery End', style: { 'width': '135px',  ...defaultStyles } },
    elcomLastUpdate: { title: 'ElCom Last Update', style: { 'width': '180px',  ...defaultStyles } },
    elcomState: { title: 'ElCom State', style: { 'width': '135px',  ...defaultStyles } },
    errorDetails: { title: 'Error Details', style: { 'width': '350px',  ...defaultStyles}, editable: true },
    inputFilename: {
      title: 'Filename (Input File)',
      style: { 'width': '210px',  ...defaultStyles, 'cursor': 'help' },
      editable: true
    },
    reportedFilename: {
      title: 'Filename (Reported)',
      style: { 'width': '210px',  ...defaultStyles, 'cursor': 'help' },
      editable: true
    },
    ompPartyCode: { title: 'OMP Code', style: { 'width': '190px',  ...defaultStyles } },
    mpPartyCode: { title: 'MP Code', style: { 'width': '190px' } },
    price: { title: 'Price', style: { 'width': '100px', 'text-align': 'right' } },
    currency: { title: 'Cur.', longTitle: 'Price Currency', style: { 'width': '90px',  ...defaultStyles } },
    quantity: { title: 'Quantity', style: { 'width': '120px', 'text-align': 'right' } },
    quantityUnit: { title: 'Unit', longTitle: 'Quantity Unit', style: { 'width': '100px',  ...defaultStyles } },
    notionalAmountValue: { title: 'Notional Amount', style: { 'width': '170px', 'text-align': 'right' } },
    notionalAmountCurrency: {
      title: 'Not. Cur.',
      longTitle: 'Notional Amount Currency',
      style: { 'width': '110px',  ...defaultStyles }
    },
    totalQuantityValue: {
      title: 'Total Quantity',
      longTitle: 'Total Notional Contract Quantity',
      style: { 'width': '150px', 'text-align': 'right' }
    },
    totalQuantityUnit: {
      title: 'Total Unit',
      longTitle: 'Total Notional Contract Quantity Unit',
      style: { 'width': '120px',  ...defaultStyles }
    },
    ompTradeRelease: { title: 'OMP Trade Release', style: { 'width': '190px',  ...defaultStyles } },
    orderStatus: { title: 'Ord. Status', longTitle: 'Order Status', style: { 'width': '130px',  ...defaultStyles } },

    // always hidden
    // dataStoreId: { title: 'DataStore Id', style: { 'width': '120px' }, visible: false },
  };
}
