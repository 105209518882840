import { Component, OnDestroy, OnInit } from '@angular/core';
import { RemitDashboardService } from '../remit-dashboard.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { RemitDashboardSearchFilter } from './remit-dashboard-filter-model';
import { Subject } from 'rxjs';

@Component({
  selector: 'cms-remit-dashboard-filter',
  templateUrl: './remit-dashboard-filter.component.html',
  styleUrls: ['./remit-dashboard-filter.component.scss']
})
export class RemitDashboardFilterComponent implements OnInit, OnDestroy {

  private onDestroy$ = new Subject();
  remitDashboardFilterFormGroup: UntypedFormGroup;
  private dashBoardFilter: RemitDashboardSearchFilter;
  documentTypeOptions: { label: string, value: string }[];

  constructor(private dashboardService: RemitDashboardService, private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {

    this.dashBoardFilter = this.dashboardService.getCurrentSearchFilter();

    this.remitDashboardFilterFormGroup = this.formBuilder.group({
      viewBy: new UntypedFormControl(this.dashBoardFilter.viewBy, Validators.required),
      documentType: new UntypedFormControl(this.dashBoardFilter.documentType, Validators.required),
      reportingRoleType: new UntypedFormControl(this.dashBoardFilter.reportingRoleType, Validators.required)
    });

    this.documentTypeOptions = [
      { label: 'REMIT Table 1', value: 'REMIT_TABLE_1' },
      { label: 'REMIT Table 2', value: 'REMIT_TABLE_2' },
      { label: 'Electricity Rights', value: 'ELECTRICITY_RIGHTS' },
      { label: 'Gas Capacity', value: 'GAS_CAPACITY' },
      { label: 'Fundamentals', value: 'FUNDAMENTALS' },
    ];
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  submit() {
    this.dashBoardFilter=this.remitDashboardFilterFormGroup.value;
    this.dashboardService.updateFilter(this.dashBoardFilter);
  }

}
