import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OrganisationService } from './organisation.service';
import { AuthenticationService } from '../auth/authentication.service';
import { EndPointConstants } from './end-point-constants';
import { Router } from '@angular/router';

@Injectable()
export class RequestLoggingService {

  openRequests: {[key: string]: string};

  constructor(private router: Router) {
    this.openRequests = {};
  }

  logRequest(apiRequestPath: string): void {
    this.openRequests[apiRequestPath] = this.router.url;
  }

  removeRequest(apiRequestPath: string): void {
    if (this.openRequests[apiRequestPath]) {
      delete this.openRequests[apiRequestPath];
    }
  }

  isRequestOnCurrentPage(apiRequestPath: string): boolean {
    if (apiRequestPath in this.openRequests) {
      const entry = this.openRequests[apiRequestPath];
      return (entry === this.router.url);
    }
    return false;
  }

}
