<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content class="panel-box tab-view-body">

  <p-messages [value]="messages$ | async" [closable]="true"></p-messages>

  <section class="panel-box">
    <cms-info ngPreserveWhitespaces [fullWidth]="true">
      <u>**eSM Permission**</u>

      **Purpose of the Permission**

      Master operators of eSM lite organisations can set the Permissions for the click-and-agree functionality. If set on 'ALLOW' you can use the click-and-agree functionality for invoices sent by an full eSM user. If set on 'BLOCK' you can not use the click-and-agree functionality and only receive invoices with an email notification.

      * **eSM lite user with click-and-agree functionality**: You can become an eSM lite user and use the click and agree functionality if (1) a full eSM user deposited you as a counterparty in their email whitelist and (2) you set your Permissions in this screen on 'ALLOW'. When a new invoice is available for you, you will receive an email notification with the link to the invoice. In the invoice finder and dashboard you have
      the possibility to 'AGREE' and 'DISPUTE' invoices with the corresponding buttons. 'AGREE' means the invoice gets the state 'Matched'. 'DISPUTE' means the invoice is 'MISMATCHED'. Until the expiration of the payment date you can change your answer from 'AGREE' to 'DISPUTE' or 'DISPUTE' to 'AGREE'. After the expiration of the payment date, a change is no longer possible. If you do not respond five business days before the
      expiration of the payment date, you will receive the invoice in an email notification with the attached invoice PDF and XML.
      * **eSM offsite process**: The eSM offsite process is set if (1) a full eSM user deposited you as a counterparty in their email whitelist and (2) you set your Permission in this screen on 'BLOCK'. When a new invoice is available for you, you will receive an email notification with an attached invoice PDF and XML right away. You will not have the possibility to agree or dispute the invoice.

      **Support eSM click-and-agree functionality**

      * **ALLOW eSM click-and-agree**: When checked 'ALLOW', this means that your party supports the eSM click-and-agree functionality. Counterparties which are enabled for eSM on the CMS can upload invoices and then you can 'AGREE' or 'DISPUTE' these invoices in the invoice dashboard or finder.
      * **BLOCK eSM click-and-agree**: When checked 'BLOCK', this means that your party does not support the eSM click-and-agree functionality. Counterparties which are enabled for eSM on the CMS can upload invoices and your party will receive an email notification with the attached invoice PDF and XML without the possibility to click-and-agree.

      **Information in the List**

      This list shows all full eSM users who would like to use the eSM click-and-agree function with you.

      * **Permission** - It shows which Permission the Master operator selected for the eSM counterparty. For new counterparties the default settings are set on 'BLOCK_DEFAULT'.
      * **EIC Code** - The EIC code of the eSM counterparty who whitelisted you in eSM
      * **Display name** - The display name of the eSM counterparty who whitelisted you in eSM
      * **Last updated** - It shows the last time you changed the permission

      **The Permission is shown as**

      * **Allow/Block** - If it was explicitly configured by you
      * **Block_Default** - If you have not set an explicit permission yet

      **Updating Your Permissions - Master Operator (MO) privileges required**

      * Tick the checkboxes of the organisation whose permission you wish to change
      * From the dropdown box choose the intended permission
      * Click "Apply"
    </cms-info>

    <cms-datatable
      [lazy]="true"
      [autoLayout]="true"
      [scrollable]="false"
      [lazyLoadOnInit]="false"
      [tooltipDisabled]="true"
      [checkboxRow]="hasEditRights$|async"
      [selected]="selectedEntries"
      [tableColumns]="columns"
      [data]="(data$ | async).values"
      [loading]="(loading$ | async)"
      [totalRecords]="(data$ | async).count"
      [currentPage]="(paging$ | async).page"
      [rows]="(paging$ | async).entriesPerPage"
      [sortField]="(sorting$ | async).columnName"
      [sortOrder]="(sorting$ | async).sortOrder"
      (lazyLoad)="onLazyLoad($event)"
      (selectedChange)="select($event)">

      <ng-template pTemplate="caption">
        <div class="flex justify-content-left">

          <div class="p-text-start me-4 ms-1 align-self-center" style="font-size: 15px;">
            {{(hasEditRights$| async) ? 'Change permissions for checked groups: ' : 'You can not operate on this screen'}}</div>

          <div class="flex align-content-center" [ngStyle]="{'visibility': (hasEditRights$| async)? 'visible':'hidden'}">

            <p-dropdown [options]="[  {label:'Block',value:'BLOCK'},{label:'Allow',value:'ALLOW'}]"
                        [(ngModel)]="newPermission"
                        [disabled]="this.selectedEntries.length < 1 ||  !(hasEditRights$ | async)">
            </p-dropdown>

            <button id="permissions_submit_btn" pButton class="btn ms-4 mt-1" type="submit" label="Apply"
                    (click)="execute()"
                    [disabled]="this.selectedEntries.length<1 || !(hasEditRights$| async)">
            </button>

          </div>
        </div>
      </ng-template>

    </cms-datatable>

  </section>
</ng-template>
