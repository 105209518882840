import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Message } from 'primeng/api';
import { HttpParams } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';

import { ApiRequestService } from '@common/api-request.service';
import { MessagesMapperService } from '@common/messages-mapper.service';
import * as fromActions from './enter-trade.actions';
import { EcmEnterTradeFormData, EcmEnterTradeFormResponse, EcmEnterTradeProductsResponse } from '../../enter-trade/enter-trade.model';
import { EcmEnterTradeState } from './enter-trade.reducers';
import * as fromSelectors from './enter-trade.selectors';
import { ErrorResponse } from '@common/error-response.model';
import * as moment from 'moment';

@Injectable()
export class EcmEnterTradeEffects {
  private baseUrl = 'api/ecm/trade/entry';
  private shortUrl = 'api/ecm/trade';

  constructor(private actions$: Actions,
              private apiGateway: ApiRequestService,
              private mapper: MessagesMapperService,
              private store$: Store<EcmEnterTradeState>) {
  }

   getProductsList$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.GET_PRODUCTS_LIST),
      concatMap((action: fromActions.GetProductsListAction) => {
        return this.apiGateway.get(`${this.baseUrl}/products`, null)
          .pipe(switchMap((response: EcmEnterTradeProductsResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [new fromActions.SetMessagesAction(messages)];
              }
              return [
                new fromActions.SetMessagesAction([]),
                new fromActions.GetProductsListSuccessAction(response)
              ];
            }),
            catchError(() => of(new fromActions.SetMessagesAction([
              this.mapper.createErrorMessage('Server error')
            ])))
          );
      })
    ));

   getFormValues$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.GET_FORM_VALUES),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getProductId))),
      switchMap((obj: [fromActions.GetFormValuesAction, number]) => {
        const [, productId] = obj;
        let params = new HttpParams();
        params = params.set('productId', productId.toString());

        return this.apiGateway.get(`${this.baseUrl}/form`, params)
          .pipe(switchMap((response: EcmEnterTradeFormResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [new fromActions.SetMessagesAction(messages)];
              }
              let message = [];
              if (response.deliveryPoints && response.deliveryPoints.length < 1) {
                message = this.mapper.toWarningMessages('No delivery points defined for the chosen product. Contact Equias support.');
              }
              return [
                new fromActions.GetFormValuesSuccessAction(response),
                new fromActions.SetMessagesAction(message),
              ];
            }),
            catchError(error => of(new fromActions.SetMessagesAction([
              this.mapper.createErrorMessage('Server Error')
            ])))
          );
      })
    ));

   getDeliveryPeriod$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.GET_DELIVERY_PERIOD),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getPeriodsValues)),
        this.store$.pipe(select(fromSelectors.getProductId))),
      switchMap((obj: [fromActions.GetDeliveryPeriodAction, any, number]) => {
        const [, periodValues, productId] = obj;
        const requestObj = {
          ...periodValues,
          productSelectionId: productId,
          deliveryEndDate: periodValues.deliveryEndDate ? moment(periodValues.deliveryEndDate).format('YYYY-MM-DD').toString() : null,
          deliveryStartDate: periodValues.deliveryStartDate ? moment(periodValues.deliveryStartDate).format('YYYY-MM-DD').toString() : null
        };
        return this.apiGateway.post(`${this.baseUrl}/delivery/period`, requestObj)
          .pipe(map((response: any) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return new fromActions.SetMessagesAction(messages);
              }
              return new fromActions.GetDeliveryPeriodSuccessAction(response);
            }), catchError(error => of(new fromActions.SetMessagesAction([
              this.mapper.createErrorMessage('Server Error')
            ])))
          );
      })
    ));

   calculateTotalVolume$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.GET_TOTAL_VOLUME),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.getProductId)),
        this.store$.pipe(select(fromSelectors.getCapacityValue)),
        this.store$.pipe(select(fromSelectors.getPeriodsValues))),
      switchMap((data: [fromActions.GetTotalVolumeAction, number, any, any]) => {
        const [, productSelectionId, capacity, deliveryPeriod] = data;
        return this.apiGateway.post(`${this.baseUrl}/calculateTotalVolume`, {
          productSelectionId,
          capacity,
          deliveryPeriod: {
            ...deliveryPeriod,
            deliveryEndDate: deliveryPeriod.deliveryEndDate ? moment(deliveryPeriod.deliveryEndDate).format('YYYY-MM-DD').toString() : null,
            deliveryStartDate: deliveryPeriod.deliveryStartDate ? moment(deliveryPeriod.deliveryStartDate).format('YYYY-MM-DD').toString() : null
          }
        })
          .pipe(switchMap((response: any) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [new fromActions.SetMessagesAction(messages)];
              }
              return [
                new fromActions.GetTotalVolumeSuccessAction(response),
                new fromActions.SetMessagesAction([])
              ];
            }), catchError(error => of(new fromActions.SetMessagesAction([
              this.mapper.createErrorMessage('Server Error')
            ])))
          );

      })
    ));

   calculateTotalContractValue$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.GET_TOTAL_CONTRACT),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.getProductId)),
        this.store$.pipe(select(fromSelectors.getCapacityValue)),
        this.store$.pipe(select(fromSelectors.getPeriodsValues)),
        this.store$.pipe(select(fromSelectors.getPriceValue))),
      switchMap((data: [fromActions.GetTotalContractAction, number, any, any, any]) => {
        const [, productSelectionId, capacity, deliveryPeriod, price] = data;
        return this.apiGateway.post(`${this.baseUrl}/calculateTotalContractValue`, {
          productSelectionId,
          capacity,
          deliveryPeriod: {
            ...deliveryPeriod,
            deliveryEndDate: deliveryPeriod.deliveryEndDate ? moment(deliveryPeriod.deliveryEndDate).format('YYYY-MM-DD').toString() : null,
            deliveryStartDate: deliveryPeriod.deliveryStartDate ? moment(deliveryPeriod.deliveryStartDate).format('YYYY-MM-DD').toString() : null
          },
          price
        })
          .pipe(switchMap((response: any) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [new fromActions.SetMessagesAction(messages)];
              }
              return [
                new fromActions.GetTotalContractSuccessAction(response),
                new fromActions.SetMessagesAction([])
              ];
            }), catchError(error => of(new fromActions.SetMessagesAction([
              this.mapper.createErrorMessage('Server Error')
            ])))
          );

      })
    ));

   submitForm$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.SUBMIT_FORM_DATA),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.getFormData)),
        this.store$.pipe(select(fromSelectors.getProductId))),
      switchMap((obj: [fromActions.SubmitFormDataAction, EcmEnterTradeFormData, number]) => {
        const [, data, productId] = obj;
        const requestModel = this.formDataPrepared({ ...data }, productId);
        return this.apiGateway.post(`${this.baseUrl}/submit`, requestModel)
          .pipe(switchMap((response: ErrorResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [new fromActions.SetMessagesAction(messages)];
              }
              return [
                new fromActions.SubmitFormDataSuccessAction(),
                new fromActions.SetToClearFormAction(),
                new fromActions.SetMessagesAction(this.mapper.toSuccessMessages('Confirmation submitted successfully.')),
              ];
            }),
            catchError(error => of(new fromActions.SetMessagesAction([
              this.mapper.createErrorMessage('Server Error')
            ])))
          );
      })
    ));

   getAmendItem$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.GET_EDITABLE_ITEM),
      switchMap((action: fromActions.GetEditableItemAction) => {
        return this.apiGateway.get(`${this.shortUrl}/${action.payload}`)
          .pipe(switchMap((response: ErrorResponse & EcmEnterTradeFormData) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [new fromActions.SetMessagesAction(messages)];
              }
              return [
                new fromActions.GetEditableItemSuccessAction(response),
                new fromActions.SetMessagesAction([]),
              ];
            }),
            catchError(error => of(new fromActions.SetMessagesAction([
              this.mapper.createErrorMessage('Server Error')
            ])))
          );
      })));

   updateAmendItem$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.UPDATE_ITEM),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.getFormData)),
        this.store$.pipe(select(fromSelectors.getProductId))),
      switchMap((obj: [fromActions.UpdateItemAction, EcmEnterTradeFormData, number]) => {
        const [action, data, productId] = obj;
        const requestModel = this.formDataPrepared({ ...data }, productId);
        return this.apiGateway.put(`${this.baseUrl}/submit/${action.payload}`, requestModel)
          .pipe(switchMap((response: ErrorResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [new fromActions.SetMessagesAction(messages)];
              }
              return [
                new fromActions.UpdateItemSuccessAction(),
                new fromActions.SetToClearFormAction(),
                new fromActions.SetMessagesAction(this.mapper.toSuccessMessages('Confirmation submitted successfully.')),
              ];
            }),
            catchError(error => of(new fromActions.SetMessagesAction([
              this.mapper.createErrorMessage('Server Error')
            ])))
          );
      })));

  private formDataPrepared(data: EcmEnterTradeFormData, productId: number): EcmEnterTradeFormData {
    const date = (data as any).tradeDate as Date;
    const tradeTime = data.tradeTimeInUtc ? new Date(data.tradeTimeInUtc) : null;
    if (date && tradeTime) {
      tradeTime.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
    }
    data.tradeTimeInUtc = (date) ? data.tradeTimeInUtc : null;
    (data as any).tradeDate = null;
    const tradeTimeInUTC = tradeTime ? moment(tradeTime).format('YYYY-MM-DDThh:mm:ss') : null;
    const deliveryStartDate = (data as any).deliveryStartDate as Date;
    const deliveryEndDate = (data as any).deliveryEndDate as Date;
    const requestModel = { ...data, productSelectionId: productId };
    requestModel.tradeTimeInUtc = tradeTimeInUTC ? tradeTimeInUTC : null;
    requestModel.deliveryStartDate = deliveryStartDate ? moment(deliveryStartDate).format('YYYY-MM-DD').toString() : null;
    requestModel.deliveryEndDate = deliveryEndDate ? moment(deliveryEndDate).format('YYYY-MM-DD').toString() : null;
    Object.keys(requestModel).forEach((key) => (!requestModel[key] && (typeof requestModel[key] !== 'boolean')) && delete requestModel[key]);
    return requestModel;
  }
}
