import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Message } from 'primeng/api';
import { DialogState } from '@common/state/reducers';
import { Column } from '@common/shared/datatable.model';
import { Observable, Subscription } from 'rxjs';
import { OrganisationService } from '@common/organisation.service';
import { AddingInvoiceTypeRequest, dataTableColumns, InvoiceTypeModel, SETTINGS_DIALOGS } from './invoice-settings.model';
import { EsmSettingsState } from '../../state/settings/settings.reducers';
import * as fromActions from '../../state/settings/invoice/invoice-settings.actions';
import * as fromAddPanelActions from '../../state/settings/invoice/add-panel/add-panel.actions';
import * as fromTableActions from '../../state/settings/invoice/table/table.actions';
import * as fromDownloadActions from '../../state/settings/invoice/gatekeeper-downloading/gatekeeper-downloading.actions';
import * as fromSelectors from '../../state/settings/invoice/invoice-settings.selectors';
import * as fromAddPanelSelectors from '../../state/settings/invoice/add-panel/add-panel.selectors';
import * as fromTableSelectors from '../../state/settings/invoice/table/table.selectors';

@Component({
  selector: 'cms-invoice-settings',
  templateUrl: './invoice-settings.component.html',
  styleUrls: ['./invoice-settings.component.scss']
})
export class InvoiceSettingsComponent implements OnInit, OnDestroy {
  dialog$: Observable<DialogState<SETTINGS_DIALOGS>> = this.store.pipe(select(fromSelectors.getDialog));
  types$: Observable<string[]> = this.store.pipe(select(fromAddPanelSelectors.getTypes));
  commodities$: Observable<string[]> = this.store.pipe(select(fromAddPanelSelectors.getCommodities));
  priceTypes$: Observable<string[]> = this.store.pipe(select(fromAddPanelSelectors.getPriceTypes));
  natureOfPrices$: Observable<string[]> = this.store.pipe(select(fromAddPanelSelectors.getNatureOfPrices));
  messages$: Observable<Message[]> = this.store.pipe(select(fromTableSelectors.getMessages));
  loading$: Observable<boolean> = this.store.pipe(select(fromTableSelectors.getLoading));
  toggleMatchAllowed$: Observable<boolean> = this.store.pipe(select(fromTableSelectors.getToggleMatchAllowed));
  editTypesAllowed$: Observable<boolean> = this.store.pipe(select(fromTableSelectors.getEditTypesAllowed));
  data$: Observable<InvoiceTypeModel[]> = this.store.pipe(select(fromTableSelectors.getData));
  presetValues$: Observable<AddingInvoiceTypeRequest> = this.store.pipe(select(fromAddPanelSelectors.getPresetValues));
  subscription$: Subscription;
  dataTableColumns: Column[] = dataTableColumns;

  constructor(private store: Store<EsmSettingsState>,
              public organisationService: OrganisationService) {
  }

  ngOnInit(): void {

    const editSubscription = this.editTypesAllowed$.subscribe(value => {
      this.dataTableColumns = value ? dataTableColumns : dataTableColumns.filter(item => item.type !== 'deleteButton');
    });

    this.subscription$ = this.organisationService.onRefresh()
      .subscribe((refresh) => {
        if(refresh){
          this.store.dispatch(fromActions.setToDefaultAction());
          this.store.dispatch(fromAddPanelActions.loadPresetValuesAction());
          this.store.dispatch(fromTableActions.loadTableDataAction());
        }
      });

    this.subscription$.add(editSubscription);
  }

  onDownload(): void {
    this.store.dispatch(fromDownloadActions.downloadGatekeeperAction());
  }

  onClear(): void {
    this.store.dispatch(fromAddPanelActions.cleanAddProductAction());
  }

  onAdd({ type, commodity, priceType, natureOfPrice }: AddingInvoiceTypeRequest): void {
    this.store.dispatch(fromAddPanelActions.addInvoiceTypeAction({ type, commodity, priceType, natureOfPrice }));
  }

  onDeleteItem(invoiceTypeId: number): void {
    this.store.dispatch(fromTableActions.deleteAction(invoiceTypeId));
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
    this.store.dispatch(fromActions.setToDefaultAction());
  }

  onChangeStatus(data: InvoiceTypeModel): void {
    this.store.dispatch(fromActions.openDialogAction({
      name: 'TOGGLE',
      messages: [],
      data
    }));
  }

  onCancelButton(): void {
    this.store.dispatch(fromActions.closeDialogAction());
    this.store.dispatch(fromTableActions.loadTableDataAction());
  }

  onActivateButton(invoiceTypeId: number): void {
    this.store.dispatch(fromTableActions.confirmActivateAction(invoiceTypeId));
  }

  onBlockItem(invoiceTypeId: number): void {
    this.store.dispatch(fromTableActions.confirmBlockAction(invoiceTypeId));
  }
}
