import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { EndPointConstants } from '../../../../common/end-point-constants';
import { Observable, of } from 'rxjs';
import { TransactionSheetResponseModel } from '../../../../common/sheet/transaction-sheet-model';
import { RemitTable1TradeService } from './remit-table1-trade.service';
import { catchError, mergeMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Message } from 'primeng/api';
import { ErrorMessageService } from '@common/errors/error-message.service';

@Component({
  selector: 'cms-remit-table1-trade',
  templateUrl: './remit-table1-trade.component.html',
  styleUrls: ['./remit-table1-trade.component.scss']
})
export class RemitTable1TradeComponent implements OnInit {

  msgs: Message[] = [];

  transactionSheetResponse$: Observable<TransactionSheetResponseModel>;

  constructor(private service: RemitTable1TradeService,
              private route: ActivatedRoute,
              public readonly errorMessageService:ErrorMessageService) {
  }

  ngOnInit(): void {
    this.transactionSheetResponse$ = this.route.queryParams
      .pipe(mergeMap((params: Params) => {
        const regime = params[EndPointConstants.PARAM_REGIME];
        const recordType = params[EndPointConstants.PARAM_RECORD_TYPE];
        const documentType = params[EndPointConstants.PARAM_DOCUMENT_TYPE];
        const historyId = params[EndPointConstants.PARAM_HISTORY_ID];
        return this.service.getTransactionSheet(regime, recordType, documentType, historyId).pipe(catchError((err: HttpErrorResponse) => this.handleError(err)));
      }));
  }

  handleError($error: any): Observable<any> {
    let errorDetail = '';
    if ($error.error && $error.error.errorMessage) {
      errorDetail = $error.error.errorMessage;
    }
    if ($error.statusText === 'Unauthorized') {
      this.msgs.push({ severity: 'error', summary: $error.statusText });
    } else {
      this.msgs.push({ severity: 'error', summary: $error.statusText, detail: errorDetail });
    }

    return of({});
  }
}
