import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from '../../auth/authentication.service';
import { OrganisationService } from '../organisation.service';
import { MenuItem } from './menu.model';
import { NavigationService } from './navigation.service';
import { OrganisationModel } from '../organisation.model';

@Component({
  selector: 'cms-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenuComponent implements OnInit, OnDestroy {
  menu: MenuItem[];
  organisationId: any;
  private onDestroy$ = new Subject<boolean>();
  private ALL_BLANKS: RegExp = new RegExp(' ', 'g');
  private ALL_UNDERLINE: RegExp = new RegExp('_', 'g');
  private ALL_SLASHES: RegExp = new RegExp('/', 'g');

  constructor(private authService: AuthenticationService,
              private organisationService: OrganisationService,
              private httpClient: HttpClient,
              private navigationService: NavigationService,
              private router: Router,
              private titleService: Title) {
    this.router.events.pipe(
      filter((event: Event) => event instanceof NavigationEnd),
      switchMap(() =>
        this.authService.isAuthenticated()),
      takeUntil(this.onDestroy$))
      .subscribe((authenticated: boolean) => {
        if (authenticated && this.menu) {
          this.setTitle();
        }
        if (!authenticated) {
          this.menu = null;
          this.navigationService.cleanNavigation();
        }
      });
  }

  ngOnInit(): void {
    this.organisationId = this.organisationService.getCachedOrganisationId();

    this.navigationService.navigation$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((navigation: MenuItem[]) => {
        if (navigation) {
          this.menu = navigation;
          this.setTitle();
        }
      });

    this.organisationService.getChangeOrganisationObservable()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((org: OrganisationModel) => {
        if (!org || (org && this.organisationId !== org.organisationId)) {
          this.navigationService.fetchNavigation();
        }
        this.organisationId = org ? org.organisationId : null;
      });


    if(this.authService.hasXSRFToken()){
      this.navigationService.fetchNavigation();
    }


  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  createId(itemPath: string, childPath: string): string {
    let id = '';
    if (itemPath && childPath) {
      id = 'nav-' + itemPath + '-' + childPath;
      id = id.replace(this.ALL_SLASHES, '')
        .replace(this.ALL_BLANKS, '-')
        .replace(this.ALL_UNDERLINE, '-')
        .trim();
    }
    return id.toLowerCase();
  }

  setTitle(): void {
    if (this.menu) {
      this.menu.some(menuItem => {
        return menuItem.children.some(child => {
          if (this.isChildPath(child.path)) {
            this.titleService.setTitle(child.name);
            return true;
          }
          return false;
        });
      });
    }
  }

  isChildPath(path: string): boolean {
    const url = this.router.url.split('/');
    return url.length > 3 ? '/' + url[url.length - 2] === path : url.length > 1 ? '/' + url[url.length - 1] === path : false;
  }

  getPath(): string {
    const url = this.router.url.split('/');
    return url.length > 0 ? '/' + url[1] : '/';
  }

  onNavigate(path: string): boolean {
    this.router.navigate([path]);
    return false;
  }
}

