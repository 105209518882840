<h1>Dashboard Dataview</h1>
<cms-cpml-dealview-panel [state]="state"></cms-cpml-dealview-panel>
<p-messages [value]="tableMessage" [closable]="true" class="col-12" (close)="tableMessage=[]"></p-messages>

<div class="panel-box margin-bottom-15">
  <table>
    <tr>
      <td class="padding-right-15">
        Trade Repositories:
      </td>
      <td>
        <span>{{ getRepositories() }}</span>
      </td>
    </tr>
    <tr>
      <td class="padding-right-15">
        Asset Classes:
      </td>
      <td>
        <span>{{ getAssetClasses() }}</span>
      </td>
    </tr>
    <tr>
      <td class="padding-right-15">
        OTC vs ETD:
      </td>
      <td>
        <span>{{ getDocumentFormats() }}</span>
      </td>
    </tr>
  </table>
</div>
<cms-cpml-datatable (message)="tableMessage=$event"></cms-cpml-datatable>
