import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CalendarModule } from 'primeng/calendar';
import { MessagesModule } from 'primeng/messages';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { PipeModule } from '../pipe/pipe.module';
import { DialogModule } from 'primeng/dialog';
import { CmsCommonModule } from '../common/cms-common.module';
import { ProblemSolvingRoutingModule } from './problem-solving-routing.module';
import { ExceptionsComponent } from './exceptions/exceptions.component';
import { ExceptionsEffects } from './state/exceptions/exceptions.effects';
import { reducers } from './state/reducers';
import { ExceptionsFilterComponent } from './exceptions/exceptions-filter/exceptions-filter.component';
import { DeleteExceptionsDialogComponent } from './exceptions/delete-exceptions-dialog/delete-exceptions-dialog.component';
import { ExceptionsInfoDialogComponent } from './exceptions/exceptions-info-dialog/exceptions-info-dialog.component';
import { ErrSendingFailuresComponent } from './err-sending-failures/err-sending-failures.component';
import { ErrSendingFailuresFilterComponent } from './err-sending-failures/err-sending-failures-filter/err-sending-failures-filter.component';
import { ErrSendingFailuresEffects } from './state/err-sending-failures/err-sending-failures.effects';
import { ErrSendingFailuresResendDialogComponent } from './err-sending-failures/err-sending-failures-resend-dialog/err-sending-failures-resend-dialog.component';
import { SendingFailuresComponent } from './sending-failures/sending-failures.component';
import { SendingFailuresFilterComponent } from './sending-failures/sending-failures-filter/sending-failures-filter.component';
import { SendingFailuresEffects } from './state/sending-failures/sending-failures.effects';
import { InfoDialogComponent } from './shared/info-dialog/info-dialog.component';

@NgModule({
  declarations: [
    ExceptionsComponent,
    ExceptionsFilterComponent,
    DeleteExceptionsDialogComponent,
    ExceptionsInfoDialogComponent,
    ErrSendingFailuresComponent,
    ErrSendingFailuresFilterComponent,
    ErrSendingFailuresResendDialogComponent,
    SendingFailuresComponent,
    SendingFailuresFilterComponent,
    InfoDialogComponent
  ],
  imports: [
    ProblemSolvingRoutingModule,
    CmsCommonModule,
    CommonModule,
    ReactiveFormsModule,
    MessagesModule,
    DropdownModule,
    CalendarModule,
    DialogModule,
    InputMaskModule,
    PipeModule,
    StoreModule.forFeature('problemsolving', reducers),
    EffectsModule.forFeature([
      ExceptionsEffects,
      ErrSendingFailuresEffects,
      SendingFailuresEffects
    ]),
  ]
})
export class ProblemSolvingModule { }
