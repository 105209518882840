import { Component, ContentChild, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { OrganisationService } from '@common/organisation.service';
import { Message } from 'primeng/api';
import { ReportsState } from '../state/reports/reports.reducers';
import { InitialStartAndEndDateResponse } from './reports.model';
import { ReportType } from '../ecm.model';
import { ReportsDateFiltersComponent } from './reports-date-filters/reports-date-filters.component';
import * as fromAction from '../state/reports/reports.actions';
import * as fromSelectors from '../state/reports/reports.selectors';
import { ErrorMessageService } from '@common/errors/error-message.service';

@Component({
  selector: 'cms-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit, OnDestroy {
  @ViewChild(ReportsDateFiltersComponent, {static: false}) dates: ReportsDateFiltersComponent;
  startAndEndDate$: Observable<InitialStartAndEndDateResponse> = this.store.pipe(select(fromSelectors.getInitialStartAndEndDate));
  messages$: Observable<Message[]> = this.store.pipe(select(fromSelectors.getMessages));
  disableBilling$: Observable<boolean> = this.store.pipe(select(fromSelectors.getDisableBilling));
  disableRanking$: Observable<boolean> = this.store.pipe(select(fromSelectors.getDisableRanking));
  private onDestroy$ = new Subject();

  constructor(private organisationService: OrganisationService,
              private store: Store<ReportsState>,
              private title: Title,
              public readonly errorMessageService: ErrorMessageService) {
  }

  ngOnInit(): void {
    this.title.setTitle('eCM Reports');
    this.store.dispatch(new fromAction.LoadInitialStartAndEndDateAction());

    this.organisationService.getOrganisationChangeIfAccessible()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.store.dispatch(new fromAction.LoadInitialStartAndEndDateAction());
      });
  }

  onDetails(reportType: ReportType): void {
    const { start, end } = this.dates.form.value;
    this.store.dispatch(new fromAction.ValidateAction({
      filter: {
        start,
        end
      },
      reportType
    }));
  }

  ngOnDestroy(): void {
    this.store.dispatch(new fromAction.SetToDefault());
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }
}
