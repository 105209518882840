import { Action } from '@ngrx/store';
import { Message } from 'primeng/api';
import {
  EcmEnterTradeFormData,
  EcmEnterTradeFormResponse,
  EcmEnterTradeProduct,
  EcmEnterTradeProductsResponse, EcmEnterTradeTotalVolumeResponse
} from '../../enter-trade/enter-trade.model';
import { ErrorResponse } from '@common/error-response.model';

export const SET_TO_DEFAULT = '[ECM ENTER TRADE] Set to default';
export const SET_TO_CLEAR_FORM = '[ECM ENTER TRADE] Set to clear form';
export const SET_ORGANISATION_ID = '[ECM ENTER TRADE] Set Organisation Id';
export const SET_MESSAGES = '[ECM ENTER TRADE] Set Messages';
export const GET_PRODUCTS_LIST = '[ECM ENTER TRADE] Get products list';
export const GET_PRODUCTS_LIST_SUCCESS = '[ECM ENTER TRADE] Get products list success';
export const GET_FORM_VALUES = '[ECM ENTER TRADE] Get form values';
export const GET_FORM_VALUES_SUCCESS = '[ECM ENTER TRADE] Get form values success';
export const SET_FORM_DATA = '[ECM ENTER TRADE] Set form data ';
export const SUBMIT_FORM_DATA = '[ECM ENTER TRADE] Submit form data';
export const SUBMIT_FORM_DATA_SUCCESS = '[ECM ENTER TRADE] Submit form data success';
export const SET_PRODUCT_ID = '[ECM ENTER TRADE] Set product value';
export const GET_DELIVERY_PERIOD = '[ECM ENTER TRADE] Get delivery period';
export const GET_DELIVERY_PERIOD_SUCCESS = '[ECM ENTER TRADE] Get delivery period success';
export const GET_TOTAL_VOLUME = '[ECM ENTER TRADE] Calculate total volume';
export const GET_TOTAL_VOLUME_SUCCESS = '[ECM ENTER TRADE] Calculate total volume success';
export const GET_TOTAL_CONTRACT = '[ECM ENTER TRADE] Calculate total contract value';
export const GET_TOTAL_CONTRACT_SUCCESS = '[ECM ENTER TRADE] Calculate total contract value success';
export const GET_EDITABLE_ITEM = '[ECM ENTER TRADE] Get editable item data';
export const GET_EDITABLE_ITEM_SUCCESS = '[ECM ENTER TRADE] Get editable item data success';
export const UPDATE_ITEM = '[ECM ENTER TRADE] Update item';
export const UPDATE_ITEM_SUCCESS = '[ECM ENTER TRADE] Update item success';

export class SetToDefaultAction implements Action {
  readonly type = SET_TO_DEFAULT;
}

export class SetToClearFormAction implements Action {
  readonly type = SET_TO_CLEAR_FORM;
}

export class SetMessagesAction implements Action {
  readonly type = SET_MESSAGES;

  constructor(public payload: Message[]) { }
}

export class SetOrganisationIdAction implements Action {
  readonly type = SET_ORGANISATION_ID;

  constructor(public payload: number) { }
}

export class GetProductsListAction implements Action {
  readonly type = GET_PRODUCTS_LIST;
}

export class GetProductsListSuccessAction implements Action {
  readonly type = GET_PRODUCTS_LIST_SUCCESS;

  constructor(public  payload: EcmEnterTradeProductsResponse) {}
}

export class GetFormValuesAction implements Action {
  readonly type = GET_FORM_VALUES;
}

export class GetFormValuesSuccessAction implements Action {
  readonly type = GET_FORM_VALUES_SUCCESS;

  constructor(public  payload: EcmEnterTradeFormResponse) {}
}

export class SetFormDataAction implements Action {
  readonly type = SET_FORM_DATA;

  constructor(public payload: EcmEnterTradeFormData) {}
}

export class SubmitFormDataSuccessAction implements Action {
  readonly type = SUBMIT_FORM_DATA_SUCCESS;
}

export class SubmitFormDataAction implements Action {
  readonly type = SUBMIT_FORM_DATA;
}

export class SetProductAction implements Action {
  readonly type = SET_PRODUCT_ID;

  constructor(public payload: EcmEnterTradeProduct) {}
}

export class GetDeliveryPeriodAction implements Action {
  readonly type = GET_DELIVERY_PERIOD;
}

export class GetDeliveryPeriodSuccessAction implements Action {
  readonly type = GET_DELIVERY_PERIOD_SUCCESS;

  constructor(public  payload: ErrorResponse & { response: string }) {}
}

export class GetTotalVolumeAction implements Action {
  readonly type = GET_TOTAL_VOLUME;
}

export class GetTotalVolumeSuccessAction implements Action {
  readonly type = GET_TOTAL_VOLUME_SUCCESS;

  constructor(public payload: EcmEnterTradeTotalVolumeResponse) {}
}

export class GetTotalContractAction implements Action {
  readonly type = GET_TOTAL_CONTRACT;
}

export class GetTotalContractSuccessAction implements Action {
  readonly type = GET_TOTAL_CONTRACT_SUCCESS;

  constructor(public payload: ErrorResponse & { value: any }) {}
}

export class GetEditableItemAction implements Action {
  readonly type = GET_EDITABLE_ITEM;

  constructor(public payload: number) {}
}

export class GetEditableItemSuccessAction implements Action {
  readonly type = GET_EDITABLE_ITEM_SUCCESS;

  constructor(public payload: EcmEnterTradeFormData) {}
}

export class UpdateItemAction implements Action {
  readonly type = UPDATE_ITEM;

  constructor(public payload: number) {}
}

export class UpdateItemSuccessAction implements Action {
  readonly type = UPDATE_ITEM_SUCCESS;
}

export type EcmEnterTradeActions = SetToDefaultAction | SetMessagesAction | SetOrganisationIdAction
  | GetProductsListAction | GetProductsListSuccessAction | GetFormValuesAction
  | GetFormValuesSuccessAction | SetFormDataAction | SubmitFormDataSuccessAction | SetProductAction
  | GetDeliveryPeriodAction | GetDeliveryPeriodSuccessAction | GetTotalVolumeAction
  | GetTotalVolumeSuccessAction | GetTotalContractAction | GetTotalContractSuccessAction
  | SubmitFormDataAction | SetToClearFormAction | GetEditableItemAction
  | GetEditableItemSuccessAction | UpdateItemAction | UpdateItemSuccessAction;
