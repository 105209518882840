import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RoboDelegatorsService } from '../../robo-delegators.service';
import { GetPresetValuesSuccess, RoboDelegatorsUploadActionsTypes } from './robo-delegators-upload.actions';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class RoboDelegatorsUploadEffects {

  
  getPresetValues$ = createEffect(() => this.actions$.pipe(ofType(RoboDelegatorsUploadActionsTypes.GetPresetValues),
    switchMap(()=> this.roboDelegatorService.loadPresetValues()),
    map(presetValue => new GetPresetValuesSuccess(presetValue))
  ));

  constructor(private actions$: Actions, private roboDelegatorService: RoboDelegatorsService){}

}
