import { createAction, union } from '@ngrx/store';
import { LockInactivateUser } from '../../administration-tab-panel/common/dialogs-container/lock-inactivate-dialog/lock-inactivate-dialog.component';
import { ReactivateUser } from '../../administration-tab-panel/common/dialogs-container/activate-dialog/activate-dialog.component';
import { EditUserAccount } from '../../administration-tab-panel/common/dialogs-container/edit-user-account-dialog/edit-user-account-dialog.component';
import { AddUserAccount } from '../../administration-tab-panel/common/dialogs-container/add-user-account-dialog/add-user-account-dialog.component';
import { ResetPassword } from '../../administration-tab-panel/common/dialogs-container/reset-password-dialog/reset-password-dialog.component';

export const lockUserAction = createAction('[FHA ACCOUNTS] Lock User', (payload: LockInactivateUser) => ({payload}));
export const inactivateUserAction = createAction('[FHA ACCOUNTS] Inactivate User', (payload: LockInactivateUser) => ({payload}));
export const reactivateUserAction = createAction('[FHA ACCOUNTS] Reactivate User', (payload: ReactivateUser) => ({payload}));
export const editUserAccountAction = createAction('[FHA ACCOUNTS] Edit User Account', (payload: EditUserAccount) => ({payload}));
export const addUserAction = createAction('[FHA ACCOUNTS] Add User', (payload: AddUserAccount) => ({payload}));
export const resetPasswordAction = createAction('[FHA ACCOUNTS] Reset Password', (payload: ResetPassword) => ({payload}));

const actions = union({
  lockUserAction,
  inactivateUserAction,
  reactivateUserAction,
  editUserAccountAction,
  addUserAction,
  resetPasswordAction
});

export type FhaAccountsActions = typeof actions;
