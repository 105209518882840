import { Message } from 'primeng/api';
import * as moment from 'moment';
import { LoadingReport } from '../../administration-tab-panel/billing-reports/billing-reports.model';
import * as fromActions from './billing-reports.actions';

export interface BillingReportsState {
  messages: Message[];
  organisationId: number;
  dates: {
    startDate: Date,
    endDate: Date
  };
  loading: LoadingReport;
}

export const initialState: BillingReportsState = {
  messages: [],
  organisationId: null,
  dates: null,
  loading: {
    ecm: false,
    err: false,
    rankingInternal: false,
    rankingPublic: false
  }
};

export function billingReportsReducer(state: BillingReportsState = {...initialState},
                                      action: fromActions.BillingReportsActions): BillingReportsState {
  switch (action.type) {
    case fromActions.validateBillingReportsAction.type: {
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload.reportType]: true
        }
      };
    }
    case fromActions.downloadBillingReportSuccessAction.type:
    case fromActions.validationFailedAction.type: {
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload]: false
        }
      };
    }
    case fromActions.setMessagesAction.type: {
      return {
        ...state,
        messages: [...action.payload]
      };
    }
    case fromActions.setDateRangeAction.type: {
      return {
        ...state,
        dates: {
          startDate: action.payload.start,
          endDate: action.payload.end
        }
      };
    }
    case fromActions.setOrganisationIdAction.type: {
      return {
        ...state,
        organisationId: action.payload
      };
    }
    case fromActions.setToDefaultAction.type: {
      return {
        ...initialState
      };
    }
    default:
      return state;
  }
}
