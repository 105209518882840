import { Action } from '@ngrx/store';
import { Message } from 'primeng/api';
import {
  FilterDataSuccess,
  UserRoleDatatableItem,
  UserRolesFilterResponse,
  UserRolesFilters
} from '../../administration-tab-panel/user-roles/user-roles.model';
import { EditableCell } from '../../../common/datatable/cell-editor/cell-editor.model';

export const LOAD_FILTERS_DATA = '[USER ROLE] Load Filters Data';
export const LOAD_FILTERS_DATA_SUCCESS = '[USER ROLE] Load Filters Data Success';
export const UPDATE_FILTERS_DATA = '[USER ROLE] Update Filters Data';
export const FILTER_DATA = '[USER ROLE] Filter Data';
export const SET_MESSAGES = '[USER ROLE] Set Messages';
export const SET_TO_DEFAULT = '[USER ROLE] Set To Default';
export const SET_ORGANISATION_ID = '[USER ROLE] Set Organisation Id';
export const FILTER_DATA_SUCCESS = '[USER ROLE] Filter Data Success';
export const SET_FILTERS = '[USER ROLE] Set Filters';
export const EXPORT = '[USER ROLE] Export';
export const UPDATE_MATRIX = '[USER ROLE] Update Matrix';
export const SET_EDITABLE_DATA = '[USER ROLE] Set Editable Data';
export const REMOVE_EDITABLE_DATA = '[USER ROLE] Remove Editable Data';
export const SAVE_MATRIX_SUCCESS = '[USER ROLE] Save Matrix Success';

export class SetMessagesAction implements Action {
  type = SET_MESSAGES;
  constructor(public payload: Message[]) { }
}

export class FilterDataAction implements Action {
  type = FILTER_DATA;
  constructor(public payload: UserRolesFilters) { }
}

export class FilterDataSuccessAction implements Action {
  type = FILTER_DATA_SUCCESS;
  constructor(public payload: FilterDataSuccess) { }
}

export class SetFiltersAction implements Action {
  type = SET_FILTERS;
  constructor(public payload: UserRolesFilters) { }
}

export class LoadFiltersDataAction implements Action {
  type = LOAD_FILTERS_DATA;
}

export class UpdateFilterDataAction implements Action {
  type = UPDATE_FILTERS_DATA;
  constructor(public payload: UserRolesFilters) { }
}

export class LoadFiltersDataSuccessAction implements Action {
  type = LOAD_FILTERS_DATA_SUCCESS;
  constructor(public payload: UserRolesFilterResponse) { }
}

export class SetToDefaultAction implements Action {
  type = SET_TO_DEFAULT;
}

export class SetOrganisationIdAction implements Action {
  type = SET_ORGANISATION_ID;
  constructor(public payload: number) { }
}

export class ExportAction implements Action {
  type = EXPORT;
  constructor(public payload: UserRolesFilters) { }
}

export class UpdateMatrixAction implements Action {
  type = UPDATE_MATRIX;
}

export class UpdateMatrixSuccess implements Action {
  type = SAVE_MATRIX_SUCCESS;
}

export class SetEditableDataAction implements Action {
  type = SET_EDITABLE_DATA;
  constructor(public payload: EditableCell) { }
}

export class RemoveEditableDataAction implements Action {
  type = REMOVE_EDITABLE_DATA;
  constructor(public payload: EditableCell) { }
}

export type UserRoleActionsActions = UpdateMatrixAction | ExportAction
  | SetOrganisationIdAction | SetToDefaultAction | LoadFiltersDataAction
  | FilterDataAction | SetMessagesAction | FilterDataAction
  | LoadFiltersDataSuccessAction | LoadFiltersDataAction | SetEditableDataAction
  | RemoveEditableDataAction | UpdateMatrixSuccess;
