import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsCommonModule } from '@common/cms-common.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { InputMaskModule } from 'primeng/inputmask';
import { EsmRoutingModule } from './esm-routing.module';
import { InvoiceFinderComponent } from './invoice/finder/invoice-finder.component';
import { EsmInvoiceFinderFiltersEffects } from './state/invoice-finder/filters/filters.effects';
import {
  InvoiceFinderPropertiesFilterComponent
} from './invoice/finder/invoice-finder-properties-filter/invoice-finder-properties-filter.component';
import { EsmInvoiceFinderMyFiltersEffects } from './state/invoice-finder/my-filters/my-filters.effects';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FileUploadFiltersComponent } from './file-upload/file-upload-filters/file-upload-filters.component';
import { EsmFileUploadEffects } from './state/file-upload/file-upload.effects';
import { getReducers, reducerToken } from './state/reducers';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { TabViewModule } from 'primeng/tabview';
import { EsmInvoiceFinderTableEffects } from './state/invoice-finder/table/table.effects';
import { EsmDashboardComponent } from './invoice/dashboard/esm-dashboard.component';
import { EsmDashboardTabComponent } from './invoice/dashboard/esm-dashboard-tab/esm-dashboard-tab.component';
import { EsmRoleByFilterComponent } from './invoice/dashboard/esm-role-by-filter/esm-role-by-filter.component';
import { InvoiceFilterComponent } from './invoice/dashboard/invoice-filter/invoice-filter.component';
import { EsmDashboardFiltersEffects } from './state/dashboard/filters/filters.effects';
import { EsmNettingDashboardFiltersEffects } from './state/netting/dashboard/filters/filters.effects';
import { EsmDashboardTableEffects } from './state/dashboard/table/table.effects';
import { EsmNettingDashboardTableEffects } from './state/netting/dashboard/table/table.effects';
import { EsmDashboardEffects } from './state/dashboard/dashboard.effects';
import { EsmNettingDashboardEffects } from './state/netting/dashboard/dashboard.effects';
import { EsmNearMatchButtonComponent } from './common/esm-near-match-button/esm-near-match-button.component';
import { EsmMisMatchButtonComponent } from './common/esm-mis-match-button/esm-mis-match-button.component';
import { InvoiceSettingsComponent } from './settings/invoice/invoice-settings.component';
import { InvoiceSettingsAddPanelComponent } from './settings/invoice/invoice-settings-add-panel/invoice-settings-add-panel.component';
import { InvoiceAutoCompleteComponent } from './settings/invoice/invoice-settings-add-panel/auto-complete/invoice-auto-complete.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { EsmSettingsAddPanelEffects } from './state/settings/invoice/add-panel/add-panel.effects';
import { EsmSettingsTableEffects } from './state/settings/invoice/table/table.effects';
import {
  EsmSettingsInvoiceDownloadingGatekeeperEffects
} from './state/settings/invoice/gatekeeper-downloading/gatekeeper-downloading.effects';
import { InvoiceFinderHtmlComponent } from './invoice/finder/invoice-finder-html/invoice-finder-html.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MasterDataComponent } from './master-data/master-data.component';
import { InternalMasterDataComponent } from './master-data/internal-master-data/internal-master-data.component';
import { ExternalMasterDataComponent } from './master-data/external-master-data/external-master-data.component';
import { EsmMasterDataEffects } from './state/master-data/internal-master-data/internal-master-data.effects';
import { EsmExternalMasterDataEffects } from './state/master-data/external-master-data/external-master-data.effects';
import { MasterDataHeaderComponent } from './master-data/internal-master-data/master-data-header/master-data-header.component';
import { MasterDataSelectVatIdComponent } from './master-data/internal-master-data/master-data-select-vat-id/master-data-select-vat-id.component';
import { MasterDataVatDetailsComponent } from './master-data/common/master-data-vat-details/master-data-vat-details.component';
import { MasterDataAddBankDetailComponent } from './master-data/common/master-data-add-bank-detail/master-data-add-bank-detail.component';
import { MasterDataTabComponent } from './master-data/internal-master-data/master-data-tab/master-data-tab.component';
import { MasterDataPdfSettingsComponent } from './master-data/internal-master-data/master-data-pdf-settings/master-data-pdf-settings.component';
import { EsmNettingDashboardComponent } from './netting/dashboard/esm-netting-dashboard.component';
import { EsmNettingDashboardTabComponent } from './netting/dashboard/esm-netting-dashboard-tab/esm-netting-dashboard-tab.component';
import { EsmNettingRoleByFilterComponent } from './netting/dashboard/esm-netting-role-by-filter/esm-netting-role-by-filter.component';
import { EsmNettingStatementFilterComponent } from './netting/dashboard/netting-statement-filter/netting-statement-filter.component';
import { EsmNettingFinderComponent } from './netting/finder/esm-netting-finder.component';
import { EsmNettingFinderFilterComponent } from './netting/finder/filter/esm-netting-finder-filter.component';
import { EsmNettingStatementFinderEffects } from './state/netting/finder/esm-netting-finder.effects';
import { EsmNettingStatementTableEffects } from './state/netting/finder/table/table.effects';
import { EsmNettingStatementFinderUserFilterEffects } from './state/netting/finder/my-filter/my-filter.effects';
import { EsmNettingFinderMyfilterComponent } from './netting/finder/filter/my-filter/esm-netting-finder-myfilter.component';
import { NettingFinderHtmlComponent } from './netting/finder/netting-finder-html/netting-finder-html.component';
import {
  EsmNettingFinderTableAggregationKeysDropdownComponent
} from './netting/finder/table/aggregation-keys/esm-netting-finder-table-aggregation-keys-dropdown.component';
import { NettingSettingsComponent } from './settings/netting/netting-settings.component';
import { SettingsComponent } from './settings/settings.component';
import { EsmNettingSettingsEffects } from './state/settings/netting/netting-settings.effects';
import { InvoiceSettingsDialogComponent } from './settings/invoice/invoice-settings-dialog/invoice-settings-dialog.component';
import { ImageUploadComponent } from './master-data/internal-master-data/master-data-pdf-settings/image-upload/image-upload.component';
import { EsmMasterDataPdfSettingsEffects } from './state/master-data/internal-master-data/pdf-settings/pdf-settings.effects';
import { CounterpartyDropdownComponent } from './netting-preview/counterparty-dropdown/counterparty-dropdown.component';
import { NettingTableOverviewComponent } from './netting-preview/netting-table-overview/netting-table-overview.component';
import { NettingOverviewComponent } from './netting-preview/netting-overview/netting-overview.component';
import { NettingPreviewEffects } from './state/netting-preview/netting-table/netting-table.effects';
import { CounterPartyEffects } from './state/netting-preview/counterparty/counterparty.effects';
import { InvoiceOverviewEffects } from './state/netting-preview/invoice-overview/invoice-overview.effects';
import { ToleranceComponent } from './settings/tolerance/tolerance.component';
import { PipeModule } from '../pipe/pipe.module';
import { ToleranceSettingsEffects } from './state/settings/tolerance/tolerance-settings.effects';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { ProgressBarModule } from 'primeng/progressbar';
import { FieldsetModule } from 'primeng/fieldset';
import { TableModule } from 'primeng/table';
import { NettingPreviewComponent } from './netting-preview/netting-preview.component';
import { NettedAmountComponent } from './netting-preview/netted-amount/netted-amount.component';
import { GenerationDialogComponent } from './netting-preview/generation-dialog/generation-dialog.component';
import { GenerationDialogEffects } from './state/netting-preview/generation-dialog/generation-dialog.effects';
import { EmailSubscriptionComponent } from './settings/subscription/email-subscription.component';
import { SubscriptionSettingsEffects } from './state/settings/subscription/subscription-settings.effects';
import { EsmEmailWhitelistEffects } from './state/settings/whitelist/whitelist.effects';
import { AdministrationModule } from 'app/administration/administration.module';
import { WhitelistComponent } from './settings/whitelist/whitelist.component';
import { WebUserComponent } from './permissions/web-user/web-user.component';
import { ToolbarModule } from 'primeng/toolbar';
import { DropdownModule } from 'primeng/dropdown';
import { PermissionsEffects } from './state/permissions/permissions.effects';
import { InvoiceAgreeDialogComponent } from './invoice/finder/invoice-agree-dialog/invoice-agree-dialog.component';
import { ListboxModule } from 'primeng/listbox';
import { InvoiceDisputeDialogComponent } from './invoice/finder/invoice-dispute-dialog/invoice-dispute-dialog.component';
import { ToastModule } from 'primeng/toast';
import { PanelModule } from 'primeng/panel';
import { TableToastComponent } from './common/table-toast/table-toast.component';
import { NettingSettingsDialogComponent } from './settings/netting/netting-settings-dialog/netting-settings-dialog.component';
import { ExternalMasterDataDatatableComponent } from './master-data/external-master-data/external-master-data-datatable/external-master-data-datatable.component';
import { ExternalMasterDataDetailsComponent } from './master-data/external-master-data/external-master-data-details/external-master-data-details.component';
import { MasterDataDialogComponent } from './master-data/common/master-data-dialog/master-data-dialog.component';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@NgModule({
  declarations: [
    InvoiceFinderComponent,
    InvoiceFinderPropertiesFilterComponent,
    FileUploadComponent,
    FileUploadFiltersComponent,
    EsmDashboardComponent,
    EsmDashboardTabComponent,
    EsmRoleByFilterComponent,
    InvoiceFilterComponent,
    EsmNearMatchButtonComponent,
    EsmMisMatchButtonComponent,
    InvoiceSettingsComponent,
    InvoiceSettingsAddPanelComponent,
    InvoiceAutoCompleteComponent,
    InvoiceFinderHtmlComponent,
    InvoiceSettingsDialogComponent,
    MasterDataComponent,
    InternalMasterDataComponent,
    ExternalMasterDataComponent,
    ExternalMasterDataDatatableComponent,
    ExternalMasterDataDetailsComponent,
    MasterDataHeaderComponent,
    MasterDataSelectVatIdComponent,
    MasterDataVatDetailsComponent,
    MasterDataAddBankDetailComponent,
    MasterDataDialogComponent,
    EsmNettingDashboardComponent,
    EsmNettingDashboardTabComponent,
    EsmNettingRoleByFilterComponent,
    EsmNettingStatementFilterComponent,
    EsmNettingFinderComponent,
    EsmNettingFinderFilterComponent,
    EsmNettingFinderMyfilterComponent,
    NettingFinderHtmlComponent,
    EsmNettingFinderTableAggregationKeysDropdownComponent,
    SettingsComponent,
    NettingSettingsComponent,
    MasterDataTabComponent,
    MasterDataPdfSettingsComponent,
    ImageUploadComponent,
    NettingPreviewComponent,
    CounterpartyDropdownComponent,
    NettingTableOverviewComponent,
    NettingOverviewComponent,
    NettedAmountComponent,
    ToleranceComponent,
    GenerationDialogComponent,
    EmailSubscriptionComponent,
    WhitelistComponent,
    WebUserComponent,
    InvoiceAgreeDialogComponent,
    InvoiceDisputeDialogComponent,
    TableToastComponent,
    NettingSettingsDialogComponent,
  ],
    imports: [
        AdministrationModule,
        CommonModule,
        CmsCommonModule,
        EsmRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        MultiSelectModule,
        ProgressSpinnerModule,
        CalendarModule,
        DialogModule,
        SharedModule,
        ButtonModule,
        InputMaskModule,
        MessagesModule,
        TabViewModule,
        DropdownModule,
        ToolbarModule,
        StoreModule.forFeature('esm', reducerToken),
        EffectsModule.forFeature([
            EsmInvoiceFinderFiltersEffects,
            EsmInvoiceFinderMyFiltersEffects,
            EsmFileUploadEffects,
            EsmInvoiceFinderTableEffects,
            EsmDashboardFiltersEffects,
            EsmNettingDashboardFiltersEffects,
            EsmDashboardTableEffects,
            EsmNettingDashboardTableEffects,
            EsmDashboardEffects,
            EsmNettingDashboardEffects,
            EsmSettingsAddPanelEffects,
            EsmSettingsTableEffects,
            EsmMasterDataEffects,
            EsmExternalMasterDataEffects,
            EsmMasterDataPdfSettingsEffects,
            EsmNettingStatementFinderEffects,
            EsmNettingStatementTableEffects,
            EsmNettingStatementFinderUserFilterEffects,
            EsmSettingsInvoiceDownloadingGatekeeperEffects,
            EsmNettingSettingsEffects,
            NettingPreviewEffects,
            CounterPartyEffects,
            InvoiceOverviewEffects,
            ToleranceSettingsEffects,
            GenerationDialogEffects,
            SubscriptionSettingsEffects,
            EsmEmailWhitelistEffects,
            PermissionsEffects
        ]),
        AutoCompleteModule,
        FieldsetModule,
        TableModule,
        CheckboxModule,
        InputTextModule,
        PipeModule,
        InputNumberModule,
        ProgressBarModule,
        ListboxModule,
        ToastModule,
        PanelModule,
        TooltipModule,
        ConfirmDialogModule
    ],
  exports: [
    EsmMisMatchButtonComponent,
    EsmNearMatchButtonComponent
  ],
  providers: [
    {
      provide: reducerToken,
      useFactory: getReducers
    }
  ]
})
export class EsmModule {
}
