import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { Message } from 'primeng/api';
import { EcmAddProduct } from '../ecm-products';

@Component({
  selector: 'cms-ecm-products-add-panel',
  templateUrl: './ecm-products-add-panel.component.html',
  styleUrls: ['./ecm-products-add-panel.component.scss']
})
export class EcmProductsAddPanelComponent implements OnInit, OnChanges {
  @Input() data: EcmAddProduct = {
    commodity: null,
    market: null,
    transactionType: null
  };
  @Input() messages: Message[] = [];
  @Input() commodities: string[] = [];
  @Input() markets: string[] = [];
  @Input() transactionTypes: string[] = [];
  @Output() clear = new EventEmitter();
  @Output() confirm = new EventEmitter<EcmAddProduct>();

  form: UntypedFormGroup;
  errors: {[key: string]: ValidationErrors} = {};

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      commodity: this.data ? this.data.commodity : '',
      market: this.data ? this.data.market : '',
      transactionType: this.data ? this.data.transactionType : '',
    });
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (changes.messages) {
      this.errors = {};
      if (this.messages) {
        this.messages.forEach((obj: Message) => {
          const { key: controlName } = obj;
          this.errors[controlName] = { 'server': true };
        });
      }
    }

    if (this.form && changes.data) {
      const {
        commodity = '', market = '', transactionType = ''
      } = this.data;
      this.form.patchValue({
        commodity,
        market,
        transactionType
      });
      this.form.updateValueAndValidity();
    }
  }

  onAdd(){
    this.confirm.emit({...this.form.value});
  }

  onClear(): void {
    this.form.reset();
    this.errors = {};
    this.clear.emit();
  }
}
