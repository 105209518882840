import { createSelector } from '@ngrx/store';
import { getContactsState } from '../contacts/contacts.selectors';
import { ContactsState } from '../contacts/contacts.reducers';
import { DatatableState } from '../datatable/datatable.reducer';
import { Contacts, ContactsFilters } from '../../contacts/contacts.model';
import * as fromDatatable from '../datatable/datatable.reducer';

export const getDatatable = createSelector(getContactsState, (state: ContactsState) => state.orgGroupsDt);

export const getFilters = createSelector(getDatatable, (state: DatatableState<Contacts, ContactsFilters>) => state.filters);

export const getData = createSelector(getDatatable, fromDatatable.getData);
export const getSorting = createSelector(getDatatable, fromDatatable.getSorting);
export const getPaging = createSelector(getDatatable, fromDatatable.getPaging);
export const getLoading = createSelector(getDatatable, fromDatatable.getLoading);
export const getMessages = createSelector(getDatatable, fromDatatable.getMessages);
export const getSelected = createSelector(getDatatable, fromDatatable.getSelected);
