import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cms-err-sending-failures-resend-dialog',
  templateUrl: './err-sending-failures-resend-dialog.component.html',
  styleUrls: ['./err-sending-failures-resend-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrSendingFailuresResendDialogComponent {
  @Input() display: boolean;
  @Output() closeResendDialog = new EventEmitter();
  @Output() confirmResendDialog = new EventEmitter();

  onConfirm(): void {
    this.confirmResendDialog.emit();
  }

  onCancel(): void {
    this.closeResendDialog.emit();
  }

  onVisibleChange(): void {
    this.onCancel();
  }
}
