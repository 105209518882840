import { Action, ActionReducer, MetaReducer } from '@ngrx/store';
import { MyFilter } from '@common/my-filters/my-filters.model';
import { Message } from 'primeng/api';
import { environment } from '../../../../environments/environment';

export const pipeHigherOrderReducers = (...higherOrderReducers) =>
  // returns a higher order reducer that takes a base reducer
  baseReducer =>
    // returns the new reducer that is the result of the baseReducer
    // being passed through all the given Higher Order Reducers
    higherOrderReducers.reduce(
      (reducer, nextHigherOrderReducer) => nextHigherOrderReducer(reducer),
      baseReducer
    );


export const noopReducer = <T>(state: T): T => state;

export function messagesReducer(actionTypes: { SET_MESSAGES: string, RESET: string}) {
  const actionReducerMap = {
    [actionTypes.SET_MESSAGES]: (state, action) => action.payload,
    [actionTypes.RESET]: () => null
  };
  return function (state: Message[], action): Message[] {
    const reducer = actionReducerMap[action.type] || noopReducer;
    return reducer(state, action);
  };
}

export function organisationIdReducer(actionTypes: { SET: string, RESET: string}) {
  const actionReducerMap = {
    [actionTypes.SET]: (state, action) => action.payload,
    [actionTypes.RESET]: () => null
  };
  return function (state: number, action): number {
    const reducer = actionReducerMap[action.type] || noopReducer;
    return reducer(state, action);
  };
}

export const getOrgId = <T extends {organisationId: number}>(state: T): number => state.organisationId;
export const getMessages = <T extends {messages: Message[]}>(state: T): Message[] => state.messages;


export const logger = (reducer: ActionReducer<any>): ActionReducer<any> => {
  return (state: any, action: any): any => {
    const result = reducer(state, action);

    console.groupCollapsed(action.type);
    console.log('prev state', state);
    console.log('action', action);
    console.log('next state', result);
    console.groupEnd();

    return result;
  };
};

export const metaReducers: MetaReducer<any>[] = !environment.production ? [logger] : [];

export interface MyFiltersState<T extends MyFilter> {
  defaultFilter: T;
  filterSelections: T[];
  allDealsFilter: T;
}

export const deleteFilter = <T extends MyFilter>(state: MyFiltersState<T>,
                                {payload: {filterName}}: {payload}): MyFiltersState<T> => {
  const defaultFilter = state.defaultFilter && state.defaultFilter.filterName === filterName ? null : state.defaultFilter;
  let filterSelections = null;
  if (state.filterSelections) {
    filterSelections = [...state.filterSelections.filter(item => item.filterName !== filterName)];
  }
  return {
    ...state,
    defaultFilter,
    filterSelections
  };
};

export const loadMyFilters = <T extends MyFilter>(state: MyFiltersState<T>, {payload}: {payload}) => ({
  ...state,
  ...payload
});

export const setAllDeals = <T extends MyFilter>(state: MyFiltersState<T>, {payload: allDealsFilter}: {payload: T}): MyFiltersState<T> =>
  ({...state, allDealsFilter});

export function myFiltersReducer<T extends MyFilter>(actionTypes: {
  DELETE_MY_FILTER_SUCCESS: string,
  LOAD_MY_FILTERS_SUCCESS: string,
  RELOAD_MY_FILTER_SUCCESS: string,
  SET_ALL_DEALS: string,
  SET_TO_DEFAULT: string,
}, initialState: MyFiltersState<T>) {
  const actionReducerMap = {
    [actionTypes.DELETE_MY_FILTER_SUCCESS]: deleteFilter,
    [actionTypes.LOAD_MY_FILTERS_SUCCESS]: loadMyFilters,
    [actionTypes.RELOAD_MY_FILTER_SUCCESS]: loadMyFilters,
    [actionTypes.SET_ALL_DEALS]: setAllDeals,
    [actionTypes.SET_TO_DEFAULT]: () => ({ ...initialState as object })
  };
  return function (state: MyFiltersState<T>, action): MyFiltersState<T> {
    const reducer = actionReducerMap[action.type] || noopReducer;
    return reducer(state, action);
  };
}

export const getAllMyFilters = <T extends MyFilter>(state: MyFiltersState<T>): MyFilter[] => state.filterSelections;
export const getDefaultFilter = <T extends MyFilter>(state: MyFiltersState<T>): MyFilter => state.defaultFilter;
export const getAllDealsFilter = <T extends MyFilter>(state: MyFiltersState<T>): T => state.allDealsFilter;

export interface DialogState<T> {
  name?: T;
  messages?: Message[];
  data?: any;
}

export interface DialogActionTypes {
  OPEN_DIALOG: string;
  UPDATE_DIALOG: string;
  CLOSE_DIALOG: string;
  SET_TO_DEFAULT: string;
}

export function dialogReducer<TDialogs>(actionTypes: DialogActionTypes) {
  const actionReducerMap = {
    [actionTypes.OPEN_DIALOG]: (state: DialogState<TDialogs>, action) => ({
      ...state,
      ...action.payload
    }),
    [actionTypes.UPDATE_DIALOG]: (state: DialogState<TDialogs>, action) => ({
      ...state,
      messages: action.payload
    }),
    [actionTypes.CLOSE_DIALOG]: () => null,
    [actionTypes.SET_TO_DEFAULT]: () => null,
  };
  return function (state: DialogState<TDialogs>, action): DialogState<TDialogs> {
    const reducer = actionReducerMap[action.type] || noopReducer;
    return reducer(state, action);
  };
}

export const withDialog = <TDialogs extends string, TBaseState extends {dialog: DialogState<TDialogs>} = any>(actionTypes: DialogActionTypes) => {
  const actionReducerMap = {
    [actionTypes.OPEN_DIALOG]: (state: TBaseState, action) => ({
      ...state,
      dialog: {...action.payload}
    }),
    [actionTypes.UPDATE_DIALOG]: (state: TBaseState, action) => ({
      ...state,
      dialog: {
        ...state.dialog,
        messages: action.payload
      }
    }),
    [actionTypes.CLOSE_DIALOG]: (state: TBaseState) => ({
      ...state,
      dialog: null
    }),
    [actionTypes.SET_TO_DEFAULT]: (state: TBaseState) => ({
      ...state,
      dialog: null
    }),
  };
  return (baseReducer: (TBaseState, Action) => TBaseState) =>
    (state: TBaseState, action): DialogState<TDialogs> & TBaseState => {
      const reducer = actionReducerMap[action.type] || noopReducer;
      const newState = reducer(state, action);
      return baseReducer(newState, action);
    };
};

export const getDialog = (state) => state.dialog;
