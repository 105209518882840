import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store/src/models';
import { ErrorResponse } from '@common/error-response.model';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { ApiRequestService } from '@common/api-request.service';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { checkErrors, toPayload } from '@common/cms-common.model';
import { EsmInternalMasterDataState } from './internal-master-data.reducer';
import {
  AddBankDetailValue,
  EsmBankDetails,
  EsmFormValues,
  EsmLoadFormValuesResponse,
  EsmLoadVatIdsResponse,
  LoadBankDetailsResponse,
  UpdateBankdetailRequest
} from '../../../master-data/master-data.model';
import * as fromActions from './internal-master-data.actions';
import * as fromSelectors from './internal-master-data.selectors';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Message } from 'primeng/api';
import { EsmInternalLoadPresetValuesResponse } from 'app/esm/master-data/internal-master-data/internal-master-data.model';
import { propNameToUpdateUrlMap } from '../master-data.effects';

@Injectable()
export class EsmMasterDataEffects {
  private baseUrl = '/api/esm/masterdata';

  constructor(private actions$: Actions,
              private apiGateway: ApiRequestService,
              private mapper: MessagesMapperService,
              private store$: Store<EsmInternalMasterDataState>) {
  }

  updatePropertyAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateBankDetail),
      map(toPayload),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getMasterDataId))),
      switchMap(([{newValue, bankDetailsId, name, version}, masterDataId]: [UpdateBankdetailRequest, number]) =>
        this.apiGateway.post(`${this.baseUrl}/${propNameToUpdateUrlMap[name]}`,
          {newValue: newValue},
          {'masterDataId': masterDataId, 'newValue': newValue, 'bankDetailsId': bankDetailsId, 'name': name, 'version': version}).pipe(
          switchMap(checkErrors),
          switchMap(() => [
            fromActions.setMessagesAction({bankDetails: []}),
            fromActions.setMessagesAction({masterData: []}),
            fromActions.setMessagesAction({bankDetails: this.mapper.toSuccessMessages('Bank details successfully updated')}),
            fromActions.loadBankDetailsAction(masterDataId)
          ]),
          catchError(error => {
            const isStaleData: boolean = checkStaleErrorExists(error);

            return [
              fromActions.setStaleDataAction(isStaleData),
              fromActions.selectMasterDataIdAction(masterDataId),
              fromActions.loadVatIdsAction(),
            ].filter(Boolean);
          })
        )
      )
    )
  );

  deleteBankDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteBankDetail),
      map(toPayload),
      switchMap((bankDetails: EsmBankDetails) =>
        this.apiGateway.delete(`${this.baseUrl}/deleteBankDetails`,
          {
            bankDetailsId: bankDetails.bankDetailsId,
            masterDataId: bankDetails.masterDataId,
            version: bankDetails.version
          }
        )
          .pipe(
            switchMap(checkErrors),
            switchMap(() => [
              fromActions.setMessagesAction({masterData: []}),
              fromActions.setMessagesAction({bankDetails: this.mapper.toSuccessMessages('Successfully deleted')}),
              fromActions.loadBankDetailsAction(bankDetails.masterDataId)
            ]),
            catchError(error => {
              const isStaleData: boolean = checkStaleErrorExists(error);

              return [
                fromActions.setStaleDataAction(isStaleData),
                fromActions.selectMasterDataIdAction(bankDetails.masterDataId),
              ].filter(Boolean);
            })
          )
      )
    )
  );

  deleteMDRecord$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteMDRecordAction),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getMasterDataId)), this.store$.pipe(select(fromSelectors.getVersion))),
      switchMap(([, masterDataId, version]: [Action, number, number]) =>
        this.apiGateway.delete(`${this.baseUrl}/delete`, {masterDataId, version})
          .pipe(
            switchMap(checkErrors),
            switchMap(() => [
              fromActions.loadVatIdsAction(),
              fromActions.closeDialogAction(),
              fromActions.setVersionAction(null),
              fromActions.selectMasterDataIdAction(null),
              fromActions.setMessagesAction({masterData: this.mapper.toSuccessMessages('Successfully deleted'), bankDetails: []}),
              fromActions.deleteMDRecordSuccessAction(),
            ]),
            catchError(
              error => {
                const staleError: boolean = checkStaleErrorExists(error);
                let existError;
                if (!staleError) {
                  existError = checkVatRecordExistError(error);
                }

                const messages = existError ?
                  [this.mapper.createErrorMessage(existError)] : [];

                return [
                  existError ? fromActions.setMessagesAction({masterData: messages}) : null,
                  staleError ? fromActions.setStaleDataAction(staleError) : null,
                  staleError ? fromActions.loadFormValuesAction({masterDataId: masterDataId}) : null,
                ].filter(Boolean);
              }
            )
          )
      )
    )
  );

  loadFormValues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadFormValuesAction),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getMasterDataId)), this.store$.pipe(select(fromSelectors.isStale))),
      switchMap(([, masterDataId, stale]: [Action, number, boolean]) =>
        this.apiGateway.get(`${this.baseUrl}/loadFormValues`, {masterDataId: masterDataId}).pipe(
          switchMap(checkErrors),
          switchMap(({value}: EsmLoadFormValuesResponse) => [
            fromActions.setMessagesAction(stale ? {
              masterData: this.mapper.toWarningMessages('This master data record is no longer available. Someone else might have updated it.')
            } : {masterData: []}),

            fromActions.loadFormValuesSuccessAction({formValues: value}),
            fromActions.setStaleDataAction(false),
            fromActions.setVersionAction(value.version),
            fromActions.loadBankDetailsAction(masterDataId)
          ]),
          catchError(error => {
            const existError = checkVatRecordExistError(error);
            return [
              fromActions.setMessagesAction({masterData: this.mapper.toErrorMessages(error, 'Server Error')}),
              existError ? fromActions.setVersionAction(null) : null,
              existError ? fromActions.selectMasterDataIdAction(null) : null,
            ].filter(Boolean);
          })
        )
      )
    )
  );

  loadVatIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadVatIdsAction),
      switchMap(() =>
        this.apiGateway.get(`${this.baseUrl}/loadVatIds`)
          .pipe(
            switchMap(checkErrors),
            switchMap(({values, timestamp}: EsmLoadVatIdsResponse) => [
              fromActions.loadVatIdsSuccessAction({vatIds: values})
            ]),
            catchError(error => of(fromActions.setMessagesAction({masterData: this.mapper.toErrorMessages(error, 'Server Error')})))
          )
      )
    )
  );

  loadPresetValues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadPresetValuesAction),
      switchMap(() =>
        this.apiGateway.get(`${this.baseUrl}/loadPresetValues`)
          .pipe(
            switchMap(checkErrors),
            switchMap(({value}: EsmInternalLoadPresetValuesResponse) => [
              fromActions.setMessagesAction({masterData: []}),
              fromActions.loadPresetValuesSuccessAction({presetValues: value})
            ]),
            catchError(error => of(fromActions.setMessagesAction({masterData: this.mapper.toErrorMessages(error, 'Server Error')})))
          )
      )
    )
  );

  selectMasterDataId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectMasterDataIdAction.type),
      map(toPayload),
      switchMap((masterDataId: number) => {
        return [
          masterDataId ? fromActions.loadFormValuesAction({masterDataId}) : fromActions.failMDRecordingAction(),
          masterDataId ? fromActions.loadBankDetailsAction(masterDataId) : fromActions.failBDRecordingAction(),
        ];
      })
    )
  );

  save$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.persistAction),
      map(toPayload),
      switchMap((request: EsmFormValues) =>
        this.apiGateway.post(`${this.baseUrl}/save`, request)
          .pipe(
            switchMap(checkErrors),
            switchMap((response: { value: EsmFormValues }) => [
              fromActions.loadVatIdsAction(),
              fromActions.setMasterDataIdAction(response.value.masterDataId),
              fromActions.setVersionAction(response.value.version),
              fromActions.persistSuccessAction(response.value),
              fromActions.setMessagesAction({masterData: this.mapper.toSuccessMessages('Successfully done'), bankDetails: []}),
            ]),
            catchError(error => {
              const existError = checkVatRecordExistError(error);
              const messages = existError ?
                [this.mapper.createErrorMessage(existError)] :
                this.mapper.toErrorMessages(error, 'Server Error');

              return [
                fromActions.setMessagesAction({masterData: messages}),
                //  existError ? fromActions.failMDRecordingAction() : null,
              ].filter(Boolean);
            })
          )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateAction),
      map(toPayload),
      switchMap((request: EsmFormValues) =>
        this.apiGateway.put(`${this.baseUrl}/update`, request)
          .pipe(
            switchMap(checkErrors),
            switchMap((response: { value: EsmFormValues }) => [
              fromActions.loadVatIdsAction(),
              fromActions.selectMasterDataIdAction(response.value.masterDataId),
              fromActions.setVersionAction(request.version),
              fromActions.updateSuccessAction(request),
              fromActions.setMessagesAction({masterData: this.mapper.toSuccessMessages('Successfully done'), bankDetails: []}),
            ]),
            catchError(error => {
              const staleError: boolean = checkStaleErrorExists(error);
              let existError;
              if (!staleError) {
                existError = checkVatRecordExistError(error);
              }

              const messages = existError ?
                [this.mapper.createErrorMessage(existError)] :
                this.mapper.toErrorMessages(error, 'Server Error');

              messages.push(
                ...this.mapper.objectToErrorMessages(error.fieldValidationFailures),
                ...this.mapper.arrayToErrorMessage(error.validationFailures),
              );

              return [
                fromActions.setMessagesAction({masterData: []}),
                fromActions.setMessagesAction({masterData: messages}),
                staleError ? fromActions.setStaleDataAction(staleError) : null,
                staleError ? fromActions.loadFormValuesAction({masterDataId: request.masterDataId}) : null,
                // existError ? fromActions.failMDRecordingAction() : null,
              ].filter(Boolean);
            })
          )
      )
    )
  );

  sortAndPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.sortAndPageDataAction),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getMasterDataId))),
      map(([, masterDataId]: [Action, number]) => fromActions.loadBankDetailsAction(masterDataId))
    )
  );

  loadBankDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadBankDetailsAction),
      map(toPayload),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getInternalState))),
      switchMap(([masterDataId, {paging, sorting}]: [number, EsmInternalMasterDataState]) =>
        this.apiGateway.post(`${this.baseUrl}/loadBankDetails`, {paging, sorting, masterDataId})
          .pipe(
            switchMap(checkErrors),
            switchMap((data: LoadBankDetailsResponse) => [
              fromActions.loadBankDetailsSuccessAction(data)
            ]),
            catchError(error => of(fromActions.setMessagesAction({bankDetails: this.mapper.toErrorMessages(error, 'Server Error')})))
          )
      )
    )
  );

  addBankDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addBankDetailAction),
      map(toPayload),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getInternalState))),
      switchMap(([data, {version, masterDataId}]: [AddBankDetailValue, EsmInternalMasterDataState]) =>
        this.apiGateway.post(`${this.baseUrl}/addBankDetails`, {...data, version, masterDataId})
          .pipe(
            switchMap(checkErrors),
            switchMap(() => [
              fromActions.setMessagesAction({bankDetails: this.mapper.toSuccessMessages('Successfully done'), masterData: []}),
              fromActions.setBankDetailValueAction(null),
              fromActions.loadBankDetailsAction(masterDataId)
            ]),
            catchError(error => of(fromActions.setMessagesAction({bankDetails: this.mapper.toErrorMessages(error, 'Server Error')})))
          )
      )
    )
  );

  updateAllowBankDetailsDownload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateAllowBankingDetailsDownloadAction),
      map(toPayload),
      concatMap((value: boolean) => {
        const params = new HttpParams().set('newValue', value);
        return this.apiGateway.post(`${this.baseUrl}/allowBankingDetailsDownload`, null, params)
          .pipe(
            switchMap((response: any) => this.defaultResponse(response)),
            catchError(error => of(fromActions.setMessagesAction({masterData: this.mapper.toErrorMessages(error, 'Server Error')})))
          );
      })
    )
  );

  private defaultResponse = (response: any) => {
    const messages: Message[] = this.mapper.toErrorMessages(response);
    if (messages && messages.length) {
      return [fromActions.setMessagesAction({masterData: messages})];
    }
    return [fromActions.setMessagesAction({masterData: []})];
  }
}

const checkVatRecordExistError = (error: ErrorResponse): string => {
  const vatIdrecordField = error.fieldValidationMessages && error.fieldValidationMessages.vatIdRecord;
  const isExistError = vatIdrecordField && (vatIdrecordField.includes('master data record already exists') || vatIdrecordField.includes('master data record for the selected VAT ID does not exist'));
  return isExistError ? vatIdrecordField : error.validationFailureMessages ? error.validationFailureMessages[0] : '';
};
const checkStaleErrorExists = (error: ErrorResponse): boolean => {
  return error instanceof HttpErrorResponse ? (error as HttpErrorResponse).status === 409 : false;
};
