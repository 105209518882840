<p-dialog *ngIf="data"
          header="Edit Selected User Account"
          [visible]="display"
          [modal]="true"
          (visibleChange)="onVisibleChange($event)"
          [style]="{width: '600px'}"
          >
  <p-messages [value]="messages" [closable]="true"></p-messages>
  <div class="margin-15">
    <form [formGroup]="form" class="row">
      <div class="col-12 margin-bottom-12 flex">
        <label class="col-4 float-left">User Name:<span class="text-required">*</span></label>
        <div class="col-8">
          <input [ngClass]="{'error': errors['userName']}" id="account-username" type="text" formControlName="userName" class="form-control col-8 padding-6" maxlength="25"/>
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label class="col-4 float-left">First Name:<span class="text-required">*</span></label>
        <div class="col-8">
          <input [ngClass]="{'error': errors['firstName']}" id="account-firstname" type="text" formControlName="firstName" class="form-control col-8 padding-6" maxlength="25"/>
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label class="col-4 float-left">Second Name:<span class="text-required">*</span></label>
        <div class="col-8">
          <input [ngClass]="{'error': errors['secondName']}" id="account-secondName" type="text" formControlName="secondName" class="form-control col-8 padding-6" maxlength="25"/>
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label class="col-4 float-left">Email:<span class="text-required">*</span></label>
        <div class="col-8">
          <input [ngClass]="{'error': errors['email']}" id="account-email" type="text" formControlName="email" class="form-control col-8 padding-6" maxlength="60"/>
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex" *ngIf="tokenIdVisible">
        <label class="col-4 float-left">Token ID:<span class="text-required">*</span></label>
        <div class="col-8">
          <input [ngClass]="{'error': errors['email']}" id="account-tokenId" type="text" formControlName="tokenId" class="form-control col-8 padding-6" maxlength="60"/>
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label class="col-4 float-left">Job Title:</label>
        <div class="col-8">
          <input id="account-jobtitle" type="text" formControlName="jobTitle" tabindex="2" class="form-control col-8 padding-6" [maxlength]="70" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label class="col-4 float-left">Phone:</label>
        <div class="col-8">
          <input id="account-phone" type="text" formControlName="phone" tabindex="3" class="form-control col-8 padding-6" [maxlength]="25" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label class="col-4 float-left">Mobile:</label>
        <div class="col-8">
          <input id="account-mobile" type="text" formControlName="mobile" tabindex="4" class="form-control col-8 padding-6" [maxlength]="25" />
        </div>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label class="col-4 float-left">Contact Other:</label>
        <div class="col-8">
          <textarea col="3" id="account-contactother" formControlName="contactOther" tabindex="5" class="form-control col-8 padding-6" style="height: 4em;"></textarea>
        </div>
      </div>
    </form>
  </div>
  <p-footer>
    <button class="btn margin-right-3" type="button" (click)="onCancel()">Cancel</button>
    <button class="btn" type="button" (click)="onConfirm()">Update</button>
  </p-footer>
</p-dialog>
