import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MyFilter } from '@common/my-filters/my-filters.model';

@Component({
  selector: 'cms-delete-filter',
  templateUrl: './delete-filter.component.html',
  styleUrls: ['./delete-filter.component.scss'],
})
export class DeleteFilterComponent {
  @Input() row: MyFilter;
  @Input() hide = false;
  @Output() delete = new EventEmitter<MyFilter>();
  showConfirmationButtons = false;

  onConfirmDelete(): void {
    this.delete.emit(this.row);
    this.onToggle(false);
  }

  onToggle(value: boolean): void {
    setTimeout(() => {
      this.showConfirmationButtons = value;
    });
  }
}
