<div class="">
<cms-info [header]="'Open Syntax Help...'" ngPreserveWhitespaces>
  **Paragraphs**

  A paragraph is one or more consecutive lines of text separated by one or more blank lines.


  **Emphasized text**

  \**italic**, \*\***bold**\** and \*\*\****bold italic***\***


  **Code**

  Mark text as \``Code`\`


  **Lists**

  \* An item in a bulleted (unordered) list

  \* A subitem, indented with 4 spaces

  \* Another item in a bulleted list

  1\. An item in an enumerated (ordered) list

  2\. Another item in an enumerated list


  **Headings**

  HTML headings are produced by placing a number of hashes before the header text corresponding to the level of heading desired.
  # \# First-level heading

  #### \#### Fourth-level heading



  **Blockquotes**

  \> This text will be enclosed in an HTML blockquote element.
  > This text will be enclosed in an HTML blockquote element.


  **Links**

  `` [link text here](https://www2.cms.equias.org "link title here") ``

  [link text here](https://www2.cms.equias.org "link title here")


  **Horizontal rules**

  Horizontal rules are created by placing three underscores on a line by themselves. You may use spaces between the hyphens.

  \- - -

</cms-info>

<div class="margin-bottom-12">
  <label>Title</label>
  <div class="row margin-0">
    <input type="text" placeholder="Add title..." maxlength="100" class="col-12 margin-0 form-control p-component padding-6 text-medium" [(ngModel)]="model.title" />
  </div>
</div>
<div>
  <label>Content</label>
  <div>
  <textarea rows="10" placeholder="Add content..." maxlength="20000"  class="col-12 margin-0 form-control p-component padding-6 text-medium" [(ngModel)]="model.content"></textarea>
  </div>
</div>
  <div class="col-12 text-end">
    <button type="button" class="btn margin-6" (click)="cancel()">Cancel</button>
    <button type="button" class="btn margin-6" (click)="submit()">Update</button>
  </div>
</div>
