import { NgModule } from '@angular/core';
import { DatatableCustomizeSettingsComponent } from './datatable-customize-settings.component';
import { CmsCommonModule } from '../../../common/cms-common.module';
import { DatatableCustomizeSettingsService } from './datatable-customize-settings.service';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { MessagesModule } from 'primeng/messages';
import { PickListModule } from 'primeng/picklist';

@NgModule({
  imports: [
    FormsModule,
    BrowserModule,
    CmsCommonModule,
    PickListModule,
    MessagesModule,
    MessagesModule,
    DropdownModule
  ],
  declarations: [DatatableCustomizeSettingsComponent],
  providers: [
    DatatableCustomizeSettingsService
  ]
})
export class DatatableCustomizeSettingsModule {
}
