import { EsmPdfAccessRights } from '../../../../master-data/internal-master-data/internal-master-data.model';
import { createReducer, on } from '@ngrx/store';
import * as fromMDPdfActions from './pdf-settings.actions';
import { masterDataPdfSettingsActions } from './pdf-settings.actions';
import { Message } from 'primeng/api';

export interface EsmPdfSettingsState {
  version: number;
  masterDataId: number;
  invoiceBoilerplate1?: string;
  invoiceBoilerplate2?: string;
  nettingBoilerplate1?: string;
  nettingBoilerplate2?: string;
  invoiceTemplate?:string;
  nettingTemplate?:string;
  accessRights?: EsmPdfAccessRights;
  logo?: File;
  logoAvailable: boolean;
  id?: number;
  messages?: Message[];
}

export const initialMDPdfSettingsState: EsmPdfSettingsState = {
  id: null,
  logo: null,
  version: 0,
  invoiceBoilerplate1: '',
  invoiceBoilerplate2: '',
  nettingBoilerplate1: '',
  nettingBoilerplate2: '',
  invoiceTemplate:'',
  nettingTemplate:'',
  masterDataId: null,
  messages:null,
  logoAvailable: false,
  accessRights: { editAllowed: false, uploadAllowed: false, createAllowed: false }
};

export const esmMDPdfSettingsReducer = createReducer<EsmPdfSettingsState, masterDataPdfSettingsActions>(
  initialMDPdfSettingsState,
  on(fromMDPdfActions.loadPdfPresetValuesSuccessAction, (state, {payload}) => ({
      ...state,
      accessRights: payload
  })),
  on(fromMDPdfActions.createPdfSettingsSuccessAction, (state, {payload}) => ({
    ...state,
    ...payload
  })),
  on(fromMDPdfActions.loadPdfSettingsSuccessAction, (state, {payload}) => ({
    ...payload,
    accessRights: state.accessRights
  })),
  on(fromMDPdfActions.storePdfSettingsSuccessAction, (state, {payload}) => ({
    ...state,
    ...payload
  })),
  on(fromMDPdfActions.setPdfMessagesAction,(state,{payload}) => ({
    ...state,
    messages: payload
  })),
  on(fromMDPdfActions.resetPdfFormAction, (state, {payload}) => ({
    ...payload,
    accessRights: state.accessRights
  }))
);




