<p-messages [value]="messages" [closable]="true"></p-messages>
<form class="panel-box row gutter-0 align-items-center margin-bottom-12 decent-max-width" [formGroup]="form">
  <div class="col-xl-3 col-lg-3 col-md-6 col-12 ">
    <input class="form-control p-component margin-3 ng-trim-ignore"
           type="text" placeholder="Party Code"
           formControlName="partyCode"
           [ngClass]="{'error': errors['partyCode']}"
           cmsUpperCase
           maxlength="20">
  </div>
  <div class="col-xl-3 col-lg-3 col-md-6 col-12 ps-2">
    <input class="col-xl-3 col-lg-3 col-md-6 col-12 form-control p-component margin-3 ng-trim-ignore"
           type="text"
           placeholder="Display Name"
           formControlName="displayName"
           [ngClass]="{'error': errors['displayName']}"
           cmsUpperCase
           maxlength="20">
  </div>
  <div class="col-auto btn__container justify-content-xl-start justify-content-end">
    <button type="submit" class="btn margin-top-3 margin-bottom-3 margin-left-12" (click)="onAdd(form)">Add</button>
    <button class="btn margin-top-3 margin-bottom-3 margin-left-12" (click)="onClear()">Clear</button>
  </div>
</form>
