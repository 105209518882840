import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RemitTradeEntryTable2DataModel } from './remit-trade-entry-table2-data.model';
import { ApiRequestService } from '../../../../common/api-request.service';
import { Message, SelectItem, SelectItemGroup } from 'primeng/api';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RemitTradeEntryTable2Service {

  tradeEntryData = new BehaviorSubject<RemitTradeEntryTable2DataModel>(null);
  apiPath: string = '/api/err/remit/tradeEntry/table2';

  submitted = new BehaviorSubject<boolean>(false);
  modified = new BehaviorSubject<boolean>(false);
  reseted = new BehaviorSubject<boolean>(false);

  private submitting: boolean = false;

  private message = new BehaviorSubject<Message>(null);
  validationErrorsFieldNames = new BehaviorSubject<string>(null);

  constructor(private apiRequestService: ApiRequestService) {}

  submit(tradeEntry: any) {
    if (!this.submitting) {
      this.submitting = true;
      this.apiRequestService.post(this.apiPath + '/newTrade', tradeEntry).subscribe(
        response => {
          if (response.fieldValidationMessages && Object.keys(response.fieldValidationMessages).length > 0) {
              this.listFieldValidationErrorsOrdered(response.fieldValidationMessages);
            for (const errorMessage of Object.keys(response.fieldValidationMessages)) {
              this.validationErrorsFieldNames.next(errorMessage);
            }
            this.submitted.next(false);
          } else if (response.validationFailureMessages) {
            for (let i = 0; i < response.validationFailureMessages.length; i++) {
              this.setMessage({ severity: 'error', detail: response.validationFailureMessages[i] });
            }
            this.submitted.next(false);
          } else {
            this.setMessage({ severity: 'success', detail: 'Trade report created successfully.' });
            this.submitted.next(true);
          }
          this.submitting = false;
        }, error => {
          let errorDetails = '';
          if (error.error && error.error.errorMessage) {
            errorDetails = error.error.errorMessage;
          }
          this.setMessage({ severity: 'error', summary: error.statusText, detail: errorDetails });
          this.submitted.next(false);
          this.submitting = false;
        }
      );
    }
  }

  modify(tradeEntry: any, historyId: string) {
    if (!this.submitting && tradeEntry && historyId) {
      this.submitting = true;

      const params = new HttpParams().append('historyId', historyId);

      this.apiRequestService.post('/api/err/remit/lite/remitTable2/modify', tradeEntry, params).subscribe(
        response => {
          if (response.fieldValidationMessages && Object.keys(response.fieldValidationMessages).length > 0) {
            this.listFieldValidationErrorsOrdered(response.fieldValidationMessages);
            for (const errorMessage of Object.keys(response.fieldValidationMessages)) {
              this.validationErrorsFieldNames.next(errorMessage);
            }
            this.modified.next(false);
          } else if (response.validationFailureMessages) {
            for (let i = 0; i < response.validationFailureMessages.length; i++) {
              this.setMessage({ severity: 'error', detail: response.validationFailureMessages[i] });
            }
            this.modified.next(false);
          } else {
            this.setMessage({ severity: 'success', detail: 'Trade report created successfully.' });
            this.modified.next(true);
          }
          this.submitting = false;
        }, error => {
          let errorDetails = '';
          if (error.error && error.error.errorMessage) {
            errorDetails = error.error.errorMessage;
          }
          this.setMessage({ severity: 'error', summary: error.statusText, detail: errorDetails });
          this.modified.next(false);
          this.submitting = false;
        }
      );
    }
  }

  loadTradeEntryData(searchQuery: any) {
    this.apiRequestService.post(this.apiPath + '/loadValues', searchQuery).subscribe(
      response => {
        this.tradeEntryData.next(response);
      }
    );
  }

  loadTradeModifyData() {
    this.apiRequestService.get('/api/err/remit/lite/remitTable2/loadValueSetsModify', null).subscribe(
      response => {
        this.tradeEntryData.next(response);
      }
    );
  }

  hasAcerCode(): Promise<boolean> {
    return this.apiRequestService.get('/api/err/remit/tradeEntry/hasAcerCode', null).pipe(map(response => {
      return response.response;
    })).toPromise();
  }

  onTradeEntryDataChange(): Observable<RemitTradeEntryTable2DataModel> {
    return this.tradeEntryData.asObservable();
  }

  onTradeModified(): Observable<boolean> {
    return this.modified.asObservable();
  }

  public setMessage(message: Message) {
    this.message.next(message);
  }

  public onMessageChange(): Observable<Message> {
    return this.message.asObservable();
  }

  onValidationFieldErrors(): Observable<any>{
      return this.validationErrorsFieldNames.asObservable();
  }

  reset() {
    this.message.next(null);
    this.validationErrorsFieldNames.next(null);
  }

  clearTradeEntryFormData(){
    this.tradeEntryData.next(null);
    this.reset();
  }

  convertKeyValueToSelectItem(keyValueData: any): SelectItem[] {
    if (keyValueData) {
      return keyValueData.map(method => {
        return {
          label: method.value,
          value: method.key
        };
      });
    }
    return [];
  }

  getGroupedValues(array: any): SelectItemGroup[] {
    if (array) {
      if (array.preferredValues.length > 0) {
        return [
          {
            label: 'Preferred',
            items: array.preferredValues.map(item => (
              {
                label: item.value,
                value: item.key
              }
            ))
          }
          ,
          {
            label: 'Others',
            items:
              array.notPreferredValues.map(item => (
                {
                  label: item.value,
                  value: item.key
                }
              ))
          }
        ];
      } else {
        return [{
          label: ' ',
          items: array.notPreferredValues.map(item => (
            {
              label: item.value, value: item.key
            }
          ))
        }];
      }
    }

    return [{
      label: 'Please select a Commodity first!',
      items: []

    }];
  }


  private listFieldValidationErrorsOrdered(fieldErrors: any){

    const handledErrors: string [] = [];

    const fieldList = ['uti', 'contractDate', 'contractType', 'energyCommodity', 'deliveryPointOrZone0', 'deliveryPointOrZone1', 'deliveryPointOrZone2', 'deliveryPointOrZone3',
      'settlementMethod', 'buyOrSell', 'otherMarketParticipantCode'];

    for (const field of fieldList) {
      if(fieldErrors[field]){
        handledErrors.push(field);
        this.setMessage({ severity: 'error', detail: fieldErrors[field]});
      }
    }

    // errors of Other Counterparty
    for (const field of Object.keys(fieldErrors)) {
      if(field.indexOf('otherMarketParticipants') === 0 ){
        handledErrors.push(field);
        this.setMessage({ severity: 'error', detail: fieldErrors[field] });
      }
    }

    for (const field of ['deliveryStart', 'deliveryEnd']) {
      if(fieldErrors[field]){
        handledErrors.push(field);
        this.setMessage({ severity: 'error', detail: fieldErrors[field]});
      }
    }

    // errors of volume optionals
    for (const field of Object.keys(fieldErrors)) {
      if(field.indexOf('volumeOptionalityIntervals') === 0 ){
        handledErrors.push(field);
        this.setMessage({ severity: 'error', detail: fieldErrors[field] });
      }
    }

    for (const field of ['price']) {
      if(fieldErrors[field]){
        handledErrors.push(field);
        this.setMessage({ severity: 'error', detail: fieldErrors[field]});
      }
    }

    // errors of volume optionals
    for (const field of Object.keys(fieldErrors)) {
      if(field.indexOf('fixingIndices') === 0 ){
        handledErrors.push(field);
        this.setMessage({ severity: 'error', detail: fieldErrors[field] });
      }
    }

    // errors of volume optionals
    for (const field of Object.keys(fieldErrors)) {
      if(handledErrors.indexOf(field) < 0 ){
        this.setMessage({ severity: 'error', detail: fieldErrors[field] });
      }
    }

  }
}
