import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, switchMap, withLatestFrom, map } from 'rxjs/operators';
import { Message } from 'primeng/api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import * as FileSaver from 'file-saver';
import { ApiRequestService } from '@common/api-request.service';
import { MessagesMapperService } from '@common/messages-mapper.service';
import * as fromActions from './retry-queue.actions';
import * as fromSelectors from './retry-queue.selectors';
import { RejectReasons, FilterRequest, TableDataResponse } from '../../retry-queue/retry-queue.model';
import { ErrRetryQueueState } from './retry-queue.reducers';
import { ErrorResponse } from '@common/error-response.model';
import { toPayload } from '@common/cms-common.model';

@Injectable()
export class ErrRetryQueueEffects {
  private baseUrl = '/api/err/queue';

  constructor(private apiGateway: ApiRequestService,
              private actions$: Actions,
              private store$: Store<ErrRetryQueueState>,
              private mapper: MessagesMapperService) { }

   loadTableData$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.loadDataAction.type),
      map(toPayload),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getRetryQueueState))),
      switchMap(([{ filter, sorting, paging }, state]: [FilterRequest, ErrRetryQueueState]) => {
        return this.loadData({ ...state, filter, paging, sorting });
      })
    ));

   sortAndPage$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.sortAndPageDataAction.type),
      map(toPayload),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getRetryQueueState))),
      switchMap(([{ sorting, paging }, state]: [FilterRequest, ErrRetryQueueState]) => {
        return this.loadData({
          ...state,
          sorting,
          paging
        });
        })
    ));

   downloadDocument$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.downloadDocumentAction.type),
      map(toPayload),
      switchMap((dataStoreId: number) => {
        let params = new HttpParams();
        params = params.set('dataStoreId', dataStoreId.toString());
        return this.apiGateway.getBlob(`${this.baseUrl}/downloadDocument`, null, params)
          .pipe(
            switchMap((response: any) => {
              const filename = this.getFilenameFromHeader(response.headers);
              const blob: Blob = response.body;
              FileSaver.saveAs(blob, filename);
              return of(fromActions.setMessagesAction([]));
            }),
            catchError(() => of(fromActions.setMessagesAction([
              this.mapper.createErrorMessage('Server error')
            ])))
          );
      })
    ));

   retry$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.retryAction.type),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getRetryQueueState))),
      switchMap(([,{ data, filter, paging, sorting, selected }]: [ReturnType<typeof fromActions.retryAction>, ErrRetryQueueState]) => {
        // const retryArray = data.values
        //   .filter(item => !!selectedItems[item.id])
        //   .map(item => ({ id: item.id, datastoreId: item.datastoreId, processId: item.processId }));
        return this.apiGateway.post(`${this.baseUrl}/retry`, selected)
          .pipe(
            switchMap((response: ErrorResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [fromActions.setMessagesAction(messages)];
              }

              return [
                fromActions.loadDataAction({ filter, paging, sorting }),
                fromActions.setMessagesAction([]),
                fromActions.setSelectedItems([])
              ];
            }),
            catchError(error => of(fromActions.setMessagesAction([
              this.mapper.createErrorMessage(error.errorMessage)
            ])))
          );
      })
    ));

   rejectReasons$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.getRejectReasonsAction.type),
      switchMap((data: [ReturnType<typeof fromActions.getRejectReasonsAction>, ErrRetryQueueState]) => {
        return this.apiGateway.get(`${this.baseUrl}/reject/reasons`)
          .pipe(
            switchMap((response: RejectReasons) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [fromActions.setMessagesAction(messages)];
              }

              return [
                fromActions.getRejectReasonsSuccessAction(response.values),
                fromActions.setMessagesAction([]),
                fromActions.setSelectedItems([])
              ];
            }),
            catchError(error => of(fromActions.setMessagesAction([
              this.mapper.createErrorMessage(error.errorMessage)
            ])))
          );
      })
    ));

   reject$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.rejectAction.type),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getRetryQueueState))),
      switchMap(([action, { data, filter, paging, sorting, selected }]: [ReturnType<typeof fromActions.rejectAction>, ErrRetryQueueState]) => {
        // const retryArray = data.values
        //   .filter(item => !!selectedItems[item.id])
        //   .map(item => ({ id: item.id, datastoreId: item.datastoreId, processId: item.processId }));
        let reason = new HttpParams();
        reason = reason.set('reason', action.payload.reason);
        return this.apiGateway.post(`${this.baseUrl}/reject`, selected, reason)
          .pipe(
            switchMap((response: ErrorResponse) => {
              const actions = [];
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                actions.push(fromActions.openDialogAction({ messages }));
              } else {
                actions.push(
                  fromActions.closeDialogAction(),
                  fromActions.rejectSuccessAction(),
                  fromActions.setMessagesAction([]),
                  fromActions.loadDataAction({ filter, paging, sorting })
                );
              }
              return actions;
            }),
            catchError(error => of(fromActions.setMessagesAction([
              this.mapper.createErrorMessage(error.errorMessage)
            ])))
          );
      })
    ));

  private getFilenameFromHeader(headers): string {
    const contentDisposition = headers.get('content-disposition');
    return contentDisposition.split(';')[1].trim().split('=')[1];
  }

  private loadData(state: ErrRetryQueueState) {
    const { sorting, paging, filter } = state;
    const request = { sorting, paging, filter };
    return this.apiGateway.post(`${this.baseUrl}/loadTableData`, request)
      .pipe(switchMap((response: TableDataResponse) => {
          const messages: Message[] = this.mapper.toErrorMessages(response);
          if (messages && messages.length) {
            return [fromActions.setMessagesAction(messages)];
          }
          return [
            fromActions.loadDataSuccessAction(response),
            fromActions.setMessagesAction([])
          ];
        }),
        catchError(error => of(fromActions.setMessagesAction([
          this.mapper.createErrorMessage(error.errorMessage)
        ]))));
  }
}
