import { createSelector } from '@ngrx/store';
import * as fromFilterReducer from './esm-netting-finder.reducers';
import { getEsmState, State } from '../../reducers';
import { EsmNettingStatementFinderState } from './esm-netting-finder.reducers';
import { EsmNettingStatementFinderReferencesState } from './esm-netting-finder.reducers';

export const getEsmNettingFinderState = createSelector(getEsmState, (state: State) => state.nettingStatementFinder);

// select filter
export const getEsmNettingFinderFilterState = createSelector(getEsmNettingFinderState, (state: EsmNettingStatementFinderState) => state.filterState);
export const getFormValues = createSelector(getEsmNettingFinderFilterState, fromFilterReducer.getFormValues);
export const getFilter = createSelector(getEsmNettingFinderFilterState, fromFilterReducer.getFilter);

// select references
export const getEsmNettingFinderReferencesState = createSelector(getEsmNettingFinderState, (state: EsmNettingStatementFinderState) => state.referencesState);
export const getReferences = createSelector(getEsmNettingFinderReferencesState, fromFilterReducer.getReferences);
export const getReferencesIdsCount = createSelector(getEsmNettingFinderReferencesState, (state: EsmNettingStatementFinderReferencesState) => state.idCount);
export const getReferencesNotFoundIds = createSelector(getEsmNettingFinderReferencesState, (state: EsmNettingStatementFinderReferencesState) => state.notFoundIds);
