
    <div id="details">
      <cms-back-button (back)="onBack()" label="Back to Overview"></cms-back-button>
      <p-messages [closable]="true" [value]="(messages$ | async)?.masterData"></p-messages>

      <cms-master-data-vat-details (cancel)="onBack()"
                             (delete)="onMasterDataDelete()"
                             (save)="onSave($event)"
                             [editVisible]="(presetValues$ | async)?.uploadAllowed"
                             [formValues]="formValues$ | async"
                             [isFormDisable]="!((presetValues$ | async)?.uploadAllowed)"
                             [isNewMDRecord]="isNewRecord$ | async"
                             [messages]="(messages$ | async).masterData"
                             [includeEic]="true">
      </cms-master-data-vat-details>
      <p-messages [closable]="true" [value]="(messages$ | async)?.bankDetails"></p-messages>
      <section *ngIf="(presetValues$ | async) && (bankData$ | async)" class="panel-box margin-top-12">
        <div class="margin-top-3 flex justify-content-start">
          <label class="text-bold form__label">Banking Details</label>
        </div>

        <cms-datatable (lazyLoad)="onLazyLoad($event)" styleClass="w-1000"
                       [currentPage]="(bankData$ | async).paging.page"
                       [data]="(bankData$ | async).bankDetails"
                       [scrollable]="false"
                       [lazyLoadOnInit]="false"
                       [lazy]="!!(bankData && bankData.bankDetails && bankData.bankDetails.length)"
                       [loading]="(bankData$ | async)?.loading"
                       [rows]="(bankData$ | async).paging.entriesPerPage"
                       [sortField]="(bankData$ | async).sorting.columnName"
                       [sortOrder]="(bankData$ | async).sorting.sortOrder"
                       [tableColumns]="bankDetailColumns"
                       [totalRecords]="(bankData$ | async).count">
          <ng-template *ngIf="(presetValues$ | async)?.uploadAllowed" pTemplate="caption">
            <div class="row margin-0">
              <cms-master-data-add-bank-detail
                (confirm)="onAddBankDetail($event)"
                [data]="bankDetailValue"
                [loading]="(bankData$ | async)?.loading"
                [messages]="(messages$ | async)?.bankDetails"
                [version]="(formValues$ | async)?.version">
              </cms-master-data-add-bank-detail>
              version: {{(formValues$ | async)?.version}}
            </div>
          </ng-template>
          <ng-template let-column="column" let-row="row" pTemplate="body">
            <td *ngIf="column.type === 'deleteButton'" [ngStyle]="column.style">
              <div class="btn-wrapper">
                <button (click)="onDeleteBankDetail(row)" class="btn btn-icon fa fa-times" label="Delete" title="Delete Bank Detail" type="button"></button>
              </div>
            </td>
            <td *ngIf="column.type === 'editable'"
                [ngClass]="{'editable-text': (presetValues$ | async)?.uploadAllowed}"
                [ngStyle]="column.style"
                cmsCellEditor>
              <span *ngIf="!(presetValues$ | async)?.uploadAllowed">{{row[column.name]}}</span>
              <cms-cell-editor (cancelEdit)="onCancelBDEdit()"
                               (editComplete)="onCompleteBDEdit($event)"
                               *ngIf="(presetValues$ | async)?.uploadAllowed"
                               [column]="column"
                               [editable]="true"
                               [maxValueLength]="column.maxLength"
                               [messages]="(messages$ | async)?.bankDetails"
                               [row]="row"
                               [value]="row[column.name]">
              </cms-cell-editor>
            </td>
          </ng-template>
        </cms-datatable>
      </section>
    </div>

<ng-container *ngIf="(dialog$ | async) as dialog">
  <cms-master-data-dialog
                        [selectedVat]="(formValues$ | async)?.vatDetails.vatId"
                        [dialog]="dialog"
                        (cancel)="onCancelDelete()"
                        (confirm)="onConfirmDelete()">
  </cms-master-data-dialog>
</ng-container>
