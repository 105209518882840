<p-panel header="{{releaseNotes.title}}">
  <div class="padding-6" [ngClass]="{'editable': (releaseNotes$ | async).editable}" (click)="switchEditMode()" *ngIf="isDisplayMode | async">
    <span class="edit-content-icon fa fa-pencil" *ngIf="(releaseNotes$ | async).editable"></span>
    <markdown [data]="(releaseNotes$ | async).content"></markdown>
  </div>
  <cms-help-text-editor [state]="'RELEASE_NOTES'" (canceled)="switchEditMode()"
                        [content]="(releaseNotes$ | async).content"
                        [title]="(releaseNotes$ | async).title"
                        *ngIf="isEditMode | async"></cms-help-text-editor>
</p-panel>
