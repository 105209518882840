import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RemitTradeEntryTable1ModifyService } from '../remit-trade-entry-table1-modify.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'cms-remit-trade-entry-table1-modify-contractdeliveryprofile',
  templateUrl: './remit-trade-entry-table1-modify-contractdeliveryprofile.component.html',
  styleUrls: ['./remit-trade-entry-table1-modify-contractdeliveryprofile.component.scss']
})
export class RemitTradeEntryTable1ModifyContractdeliveryprofileComponent implements OnInit, OnDestroy {

  @Input()
  formGroup: UntypedFormGroup;

  private onDestroy$ = new Subject();

  disableButtons: boolean = false;

  validationErrors: string[] = [];

  complex: boolean = false;
  responseModel: any;
  deliveryProfiles: DeliveryProfileTradeEntryModifyModel[];

  constructor(private table1ModifyService: RemitTradeEntryTable1ModifyService, private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.formGroup.addControl('deliveryProfiles', this.formBuilder.array(
      []
    ));

    this.table1ModifyService.onTradeEntryModelChange().pipe(takeUntil(this.onDestroy$)).subscribe(tradeEntryModel => {
      if (tradeEntryModel) {
        this.responseModel = tradeEntryModel.deliveryProfileResponseModel;
        this.initFormValues();
      }
    });

    this.table1ModifyService.onModifyTradeDisabled().subscribe(modified => {
      this.disableButtons = modified;
    });

    this.table1ModifyService.onValidationErrors().pipe(takeUntil(this.onDestroy$)).subscribe(validationErrors => {
      this.handleServerValidationErrors(validationErrors);
    });

  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
    this.complex = false;
    this.responseModel = null;
    this.deliveryProfiles = [];
  }

  get profiles(): AbstractControl[] {
    return (this.formGroup.controls['deliveryProfiles'] as UntypedFormArray).controls;
  }

  get disableAddButton(): boolean {
    return this.profiles.length > 2 || this.disableButtons;
  }

  get disableRemoveButton(): boolean {
    return this.profiles.length < 2 || this.disableButtons;
  }

  get bilateral(): boolean {
    return this.responseModel !== null;
  }

  initFormValues() {
    if (this.responseModel) {
      this.deliveryProfiles = this.responseModel.deliveryProfiles;
      this.complex = this.responseModel.tooComplex;
      if (this.deliveryProfiles) {

        for (const profile of this.deliveryProfiles) {
          this.add(profile);
        }
      }
    }
  }

  add(deliveryProfile: DeliveryProfileTradeEntryModifyModel) {

    const profiles = this.formGroup.controls['deliveryProfiles'] as UntypedFormArray;

    if (profiles.length < 3) {
      profiles.push(
        this.formBuilder.group({
          daysOfTheWeek: [deliveryProfile ? deliveryProfile.daysOfTheWeek : ''],
          startTime: [deliveryProfile ? deliveryProfile.startTime : ''],
          endTime: [deliveryProfile ? deliveryProfile.endTime : ''],
        })
      );
    }
  }

  remove() {
    const profiles = this.formGroup.controls['deliveryProfiles'] as UntypedFormArray;

    if (profiles.length > 1) {
      profiles.removeAt(profiles.length - 1);
    }
  }

  appendValidationErrorClassOfArray(i: number, fieldName: string): string {

    const form = this.profiles[i] as UntypedFormGroup;

    if (form) {

      if (this.validationErrors[fieldName + i]) {
        return 'error';
      }
    }
  }

  private handleServerValidationErrors(errors: any) {
    this.validationErrors = [];
    if (errors) {
      this.validationErrors['startTime0'] = errors.startTime1;
      this.validationErrors['startTime1'] = errors.startTime2;
      this.validationErrors['startTime2'] = errors.startTime3;
      this.validationErrors['endTime0'] = errors.endTime1;
      this.validationErrors['endTime1'] = errors.endTime2;
      this.validationErrors['endTime2'] = errors.endTime3;
    }
  }

}

export interface DeliveryProfileTradeEntryModifyModel {
  daysOfTheWeek: string;
  startTime: string;
  endTime: string;
}
