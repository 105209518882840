import { Subject } from 'rxjs';
import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SystemService } from '../../../system/system.service';
import { SystemInformationModel } from '../../../system/system-informations.model';
import { UserService } from '../../../common/user.service';
import { UserInformationModel } from '../../../common/user-informations.model';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cms-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
  preserveWhitespaces: true
})

@Injectable()
export class StatusComponent implements OnInit, OnDestroy {

  private static MIN_DAYS_LEFT_BEFORE_NOTIFY_THAT_PASSWORD_EXPIRE_SOON = 10;

  private onDestroy$ = new Subject();

  systemInformations: SystemInformationModel;
  userInformations: UserInformationModel;

  constructor(private systemService: SystemService,
              private userService: UserService,
              private router: Router) {
  }

  ngOnInit() {

    this.systemService.getInformations().pipe(
      takeUntil(this.onDestroy$))
      .subscribe(informations => {
          this.systemInformations = informations;
        },
        err => {
        });

    this.userService.getInformations().pipe(
      takeUntil(this.onDestroy$))
      .subscribe(informations => {
          this.userInformations = informations;
        },
        err => {
        });

  }

  getUserInformationsDate() {
    if (this.userInformations != null) {
      return Date.parse(this.userInformations.lastLogin);
    }
    return null;
  }

  isShowPasswordExpiresWarning(): boolean {
    return this.userInformations
      && this.userInformations.passwordExpiration <= this.getMinimumDaysLeftBeforeNotifyThatPasswordWillExpireSoon();
  }

  getMinimumDaysLeftBeforeNotifyThatPasswordWillExpireSoon() {
    return StatusComponent.MIN_DAYS_LEFT_BEFORE_NOTIFY_THAT_PASSWORD_EXPIRE_SOON;
  }

  getSystemInformations(): SystemInformationModel {
    return this.systemInformations;
  }

  getUserInformations(): string {
    if (this.userInformations) {
      return this.userInformations.firstName + ' ' + this.userInformations.lastName + ' (' + this.userInformations.userName + ')';
    }

    return '';
  }

  hasSystemInformations(): boolean {
    return this.systemInformations !== null;
  }

  getInstanceName(): string {
    if (this.systemInformations != null) {
      return this.systemInformations.instanceName;
    }
    return '';
  }

  getVersion(): string {
    if (this.systemInformations != null) {
      return this.systemInformations.version;
    }
    return '';
  }

  getRevisionNumber(): string {
    if (this.systemInformations != null) {
      return this.systemInformations.revisionNumber;
    }
    return '';
  }

  public ngOnDestroy(): void {
    // Clean up all subscriptions at once:
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  onChangePassword(): void {
    this.router.navigate(['/', 'preferences', 'change_password']);
  }
}
