<div class="table-container m-3">
  <p-messages [value]="messages$ | async" [closable]="false"></p-messages>

  <h1>{{header$ | async}}</h1>

  <cms-datatable id="ntt_preview_invoice_table"
                 [currentPage]="(paging$ | async).page"
                 [tableColumns]="columns$ | async"
                 [sortField]="(sorting$ | async).columnName"
                 [sortOrder]="(sorting$ | async).sortOrder"
                 [rows]="(paging$ | async)?.entriesPerPage"
                 [loading]="loading$ | async"
                 [data]="(data$ | async)?.values"
                 [totalRecords]="(data$ | async)?.count"
                 [autoLayout]="true"
                 [scrollable]="false"
                 [lazy]="true"
                 [lazyLoadOnInit]="false"
                 (lazyLoad)="onLazyLoad($event)"
                 [checkboxRow]="true"
                 [selected]="selected$ | async"
                 (selectedChange)="onSelected($event)">
    <ng-template pTemplate="caption">
      <div class="float-left p-1 !font-bold">Found Invoices</div>
      <div class="float-left date-label"></div>
      <cms-deal-table-caption (openAddNoteDialog)="onOpenAddNoteDialog()"
                              (exportEvent)="onExport()"
                              (clickCustomize)="onClickCustomize()"
                              [exportDisable]="!(data$ | async)?.values"
                              [addNoteDisable]="!(selectedIds$ | async).length">
      </cms-deal-table-caption>
    </ng-template>
    <ng-template pTemplate="body" let-row="row" let-column="column">
      <!--document button-->
      <td *ngIf="column.type === 'button' && column.name === 'openDocument'" [ngStyle]="column.style">
        <div class="btn-wrapper inline-block">
          <a target="_blank"
             id="invoicefinder-html"
             routerLink="/esm/invoicefinder/esm_document"
             [queryParams]="{ id: row.id }">
            <button pButton type="button" icon="fa fa-file" title="Document"></button>
          </a>
        </div>
      </td>
      <!--document (xml) button-->
      <td *ngIf="column.type === 'button' && column.name === 'openDocumentXml'" [ngStyle]="column.style">
        <div class="btn-wrapper inline-block">
          <button pButton type="button" icon="fa fa-file-code-o" title="Document (XML)" (click)="onDownloadXml(row)"></button>
        </div>
      </td>
      <!--audit trail button-->
      <td *ngIf="column.type === 'button' && column.name === 'auditTrail'" [ngStyle]="column.style">
        <div class="btn-wrapper inline-block">
          <a routerLink="/esm/audittrail/invoice" title="Audit Trail" [queryParams]="getDisplayParams(row)" target="_blank">
            <button pButton type="button" icon="fa fa-search" title="Audit Trail"></button>
          </a>
        </div>
      </td>
      <!--near match button-->
      <td *ngIf="column.type === 'button' && column.name === 'nearMatch'">
        <cms-esm-near-match-button class="comp-match-cell" [row]="row" route="/esm/match/invoice"></cms-esm-near-match-button>
      </td>
      <!--mismatch button-->
      <td *ngIf="column.type === 'button' && column.name === 'misMatch'">
        <cms-esm-mis-match-button class="comp-match-cell" [row]="row" route="/esm/compare/invoice"></cms-esm-mis-match-button>
      </td>
      <!--note button-->
      <td *ngIf="column.type === 'button' && column.name === 'notes'" [ngStyle]="column.style">
        <cms-notes-cell [confirmationId]="row.id"
                        [data]="row"
                        [notes]="row.notes"
                        (view)="onViewNotes($event)">
        </cms-notes-cell>
      </td>
      <!--pdf button-->
      <td *ngIf="column.type === 'button' && column.name === 'pdf'">
        <div class="btn-wrapper inline-block" *ngIf="row.pdfButtonVisible">
          <button pButton type="button" icon="fa fa-file-pdf-o" title="Document (PDF)" (click)="onDownloadPdf(row)"></button>
        </div>
      </td>
    </ng-template>
    <ng-template pTemplate="customEmptyMessage">
      <tr>
        <td colspan="100" *ngIf="!(loading$ | async)">
          No records found
        </td>
      </tr>
    </ng-template>
  </cms-datatable>

  <section>
    <cms-add-note-dialog *ngIf="(dialog$ | async)?.name === 'ADD_NOTES'"
                         [display]="(dialog$ | async)?.name === 'ADD_NOTES'"
                         [messages]="(dialog$ | async)?.messages"
                         [headerText]="'Add note to invoices'"
                         [confirmationIds]="selectedIds$ | async"
                         (cancel)="onCancelAddNotes()"
                         (save)="onConfirmAddNotes($event)">
    </cms-add-note-dialog>
    <cms-view-confirmation-notes-dialog *ngIf="(dialog$ | async)?.name === 'VIEW_NOTES'"
                                        [display]="(dialog$ | async)?.name === 'VIEW_NOTES'"
                                        [messages]="(dialog$ | async)?.messages"
                                        [data]="(dialog$ | async)?.data"
                                        (cancel)="onCancelViewNotes()"
                                        [headerText]="'View invoice notes'"
                                        (save)="onConfirmAddNotes($event)">
    </cms-view-confirmation-notes-dialog>
  </section>

</div>
