import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { AuthenticationService } from '../../../auth/authentication.service';
import { OrganisationService } from '../../../common/organisation.service';
import { Utils } from '../../../common/shared/utils';
import {
  AnyOfCpmlDashboardFilterStateTypes,
  CpmlDashboardAssetClassType,
  CpmlDashboardBucketCategoryType,
  CpmlDashboardBucketViewByType,
  CpmlDashboardDocumentFormatType,
  CpmlDashboardDocumentStateType,
  CpmlDashboardFilterStateType,
  CpmlDashboardTradeRepositoryType,
  getAllCpmlDashboardAssetClasses,
  getAllCpmlDashboardDocumentFormats,
  getAllCpmlDashboardTradeRepositories,
  getDefaultBucketCategory,
  getDefaultBucketViewBy,
} from './filter/cpml-dashboard-filter.types';
import { EndPointConstants } from '../../../common/end-point-constants';

@Injectable()
export class CpmlDashboardService {

  private filterState = new BehaviorSubject<CpmlDashboardFilterStateType>(this.getDefaultState());

  private bucketApiUrl: string = '/api/err/cpml/buckets';

  constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService, private organisationService: OrganisationService) {
  }

  emitChangeCategoryFilter(category: CpmlDashboardBucketCategoryType) {
    this.updateState('category', category);
  }

  emitChangeViewByFilter(viewBy: CpmlDashboardBucketViewByType) {
    this.updateState('viewBy', viewBy);
  }

  emitChangeTradeRepositoriesFilter(tradeRepositories: CpmlDashboardTradeRepositoryType[]) {
    this.updateState('tradeRepositories', tradeRepositories);
  }

  emitChangeAssetClassesFilter(assetClasses: CpmlDashboardAssetClassType[]) {
    this.updateState('assetClasses', assetClasses);
  }

  emitChangeDocumentFormatFilter(documentFormats: CpmlDashboardDocumentFormatType[]) {
    this.updateState('documentFormats', documentFormats);
  }

  emitChangeDocumentStateFilter(documentState: CpmlDashboardDocumentStateType) {
    this.updateState('documentState', documentState);
  }

  getCurrentFilterStateChangeObservable(): Observable<CpmlDashboardFilterStateType> {
    return this.filterState.asObservable();
  }

  getCurrentFilterState(): CpmlDashboardFilterStateType {
    return this.filterState.getValue();
  }

  resetFilterState() {
    const category = this.getCurrentFilterState().category;
    // but do not reset category state
    this.filterState.next({ ...this.getDefaultState(), category });
  }


  emitFullFilter(filter : CpmlDashboardFilterStateType) {
    this.filterState.next(filter);
  }
  loadBuckets(): Observable<any> {

    const organisationId = this.organisationService.getCachedOrganisationId();
    let httpParams = new HttpParams();
    if (organisationId) {
      httpParams = httpParams.append(EndPointConstants.PARAM_ORGANISATION_ID, organisationId.toString());
    }

    const searchQuery = this.buildFinalSearchQuery(this.getCurrentFilterState());

    return this.httpClient.post<any>(this.bucketApiUrl,
      searchQuery, {
        params: httpParams
      });
  }

  private buildFinalSearchQuery(filterstate: CpmlDashboardFilterStateType): CpmlDashboardFilterStateType {
    const ifEqualsReturnNull = <T>(a: T[]) => (b: T[]) => Utils.equalsWithIgnoreOrder(a, b) ? null : b;

    const whenTradeRepositoriesAreEqualToDefaultValuesReturnNull = ifEqualsReturnNull(getAllCpmlDashboardTradeRepositories());
    const whenAssetClassesAreEqualToDefaultValuesReturnNull = ifEqualsReturnNull(getAllCpmlDashboardAssetClasses());
    const whenDocumentFormatsAreEqualToDefaultValuesReturnNull = ifEqualsReturnNull(getAllCpmlDashboardDocumentFormats());

    return {
      ...filterstate,
      tradeRepositories: whenTradeRepositoriesAreEqualToDefaultValuesReturnNull(filterstate.tradeRepositories),
      assetClasses: whenAssetClassesAreEqualToDefaultValuesReturnNull(filterstate.assetClasses),
      documentFormats: whenDocumentFormatsAreEqualToDefaultValuesReturnNull(filterstate.documentFormats),
    };
  }

  private getDefaultState() {
    return {
      category: getDefaultBucketCategory(),
      viewBy: getDefaultBucketViewBy(),
      tradeRepositories: getAllCpmlDashboardTradeRepositories(),
      assetClasses: getAllCpmlDashboardAssetClasses(),
      documentFormats: getAllCpmlDashboardDocumentFormats(),
      documentState: null
    };
  }

  private updateState(key: keyof CpmlDashboardFilterStateType, value: AnyOfCpmlDashboardFilterStateTypes) {
    this.filterState.next({ ...this.getCurrentFilterState(), [key]: value });
  }
}
