import * as moment from 'moment';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { OrganisationService } from '@common/organisation.service';
import { RemitTransactionsService } from '../../datatable/remit-transactions.service';
import { MultiSelect, RemitPresetDatesResponseModel, RemitTransactionFilterModel } from './remit-transactions-filter.model';
import { RemitTransactionsFilterService } from './remit-transactions-filter.service';
import { actionTypesMap, RemitDocumentType } from '../../dashboard/filter/remit-dashboard-filter-model';
import { StateService } from '@common/state.service';
import { count } from 'rxjs/operators';

@Component({
  selector: 'cms-remit-transactions-filter',
  templateUrl: './remit-transactions-filter.component.html',
  styleUrls: ['./remit-transactions-filter.component.scss']
})
export class RemitTransactionsFilterComponent implements OnInit, OnDestroy, OnChanges {

  constructor(public remitTransactionsService: RemitTransactionsService,
              private remitTransactionsFilterService: RemitTransactionsFilterService,
              private organisationService: OrganisationService,
              private formBuilder: UntypedFormBuilder,
              private stateService: StateService) {
  }

  @Input() documentType: RemitDocumentType;
  form: UntypedFormGroup;
  transactionFilter: RemitTransactionFilterModel = {
    omps: null,
    mps: null,
    states: null,
    commodity: null,
    recordType: null,
    actionTypes: null,
    documentType: null,
    documentTypes: null,
    quantity: null,
    price: null,
    transactionTimeStart: null,
    transactionTimeEnd: null,
    receivedByErrStart: null,
    receivedByErrEnd: null,
    lastUpdateStart: null,
    lastUpdateEnd: null,
    contractDateStart: null,
    contractDateEnd: null,
    creationDateStart: null,
    creationDateEnd: null,
    gasDayUtcStart: null,
    gasDayUtcEnd: null,
    senderNames: null
  };

  multiSelect = { actionTypes: [], states: [] } as MultiSelect;
  omps: SelectItem[] = [];
  mps: SelectItem[] = [];
  commodities: SelectItem[] = [];
  recordTypes: SelectItem[] = [];
  actionTypes: SelectItem[] = [];
  senderNames: SelectItem[] = [];
  states: SelectItem[] = [];
  documentTypes: SelectItem[] = [];
  todayMaxDate: Date;
  private orgChange$: Subscription;
  actionTypeLabel: string;
  isDropdownDisabled = (obj) => (obj[0] === undefined);

  ngOnInit(): void {
    const now = moment();
    this.todayMaxDate = now.toDate();
    this.form = this.createForm();
    this.orgChange$ = this.organisationService.getChangeOrganisationObservable().subscribe(() => this.init());
  }

  private init(): void {
    const dropDowns: Promise<any>[] = [];

    const allTabs = Promise.all([
      this.remitTransactionsFilterService.loadCommodities(),
      this.remitTransactionsFilterService.loadStates(this.documentType)
    ]).then((values) => {
      this.multiSelect.states = values[1];

      this.commodities = values[0].commodities.map(com => ({ label: com.value, value: com.ident }));
      this.states = values[1].map(state => ({ label: state, value: state }));

      this.isDropdownDisabled(this.commodities) ? this.form.get('commodity').disable() : this.form.get('commodity').enable();
      if (this.transactionFilter && !this.transactionFilter.states) {
        this.resetSelectedStates();
      }
    });

    dropDowns.push(allTabs);

    if (this.isT1Selected()) {
      const t1 = Promise.all([
        this.remitTransactionsFilterService.loadOmpNames(),
        this.remitTransactionsFilterService.loadMpNames(),
        this.remitTransactionsFilterService.loadRecordTypes(),
        this.remitTransactionsFilterService.loadSenderNames()
      ]).then((values) => {

        this.omps = values[0].map(omp => ({ label: omp, value: omp }));
        this.mps = values[1].map(mp => ({ label: mp, value: mp }));
        this.recordTypes = values[2].recordTypes.map(rt => ({ label: rt.value, value: rt.ident }));
        this.senderNames = values[3].map(sn => ({ label: sn, value: sn }));

        this.isDropdownDisabled(this.omps) ? this.form.get('omps').disable() : this.form.get('omps').enable();
        this.isDropdownDisabled(this.mps) ? this.form.get('mps').disable() : this.form.get('mps').enable();
        this.isDropdownDisabled(this.recordTypes) ? this.form.get('recordType').disable() : this.form.get('recordType').enable();
        this.isDropdownDisabled(this.senderNames) ? this.form.get('senderNames').disable() : this.form.get('senderNames').enable();
      });

      dropDowns.push(t1);
    }

    if (this.isDocumentTypeFundamentals()) {
      this.form.controls.actionTypes.disable();
      const fundamentals = this.remitTransactionsFilterService.loadDocumentTypes(this.documentType)
        .then(docTypes => {
          this.documentTypes = docTypes.map(doctype => ({ label: doctype, value: doctype }));
          this.isDropdownDisabled(this.documentTypes) ? this.form.get('documentTypes').disable() : this.form.get('documentTypes').enable();
          if (this.transactionFilter && !this.transactionFilter.documentTypes) {
            this.resetSelectedDocumentTypes();
          }
        });
      dropDowns.push(fundamentals);

    } else {
      const t1_t2_t3_t4 = this.remitTransactionsFilterService.loadActionTypes(this.documentType).then(aTypes => {
        this.actionTypes = aTypes.map(value => ({ label: value, value: value }));
        this.multiSelect.actionTypes = aTypes;

        this.isDropdownDisabled(this.actionTypes) ? this.form.get('actionTypes').disable() : this.form.get('actionTypes').enable();
        if (this.transactionFilter && !this.transactionFilter.actionTypes) {
          this.resetSelectedActionTypes();
        }
      });
      dropDowns.push(t1_t2_t3_t4);
    }

    Promise.all(dropDowns).then(() => {

      this.transactionFilter = this.stateService.getCache(this.documentType + '_FILTER') as RemitTransactionFilterModel;
      this.populateForm(this.transactionFilter);

      this.form.valueChanges.subscribe(val => {
        this.transactionFilter = val;
        this.stateService.cacheState(val, this.documentType + '_FILTER');
      });
      this.loadInitialDates();
      this.reset();
    });

  }

  private createForm(): UntypedFormGroup {
    const formGroup: UntypedFormGroup = this.formBuilder.group({
        omps: [this.transactionFilter.omps],
        mps: [this.transactionFilter.mps],
        states: [this.transactionFilter.states, Validators.required],
        actionTypes: [this.transactionFilter.actionTypes, Validators.required],
        commodity: [this.transactionFilter.commodity],
        recordType: [this.transactionFilter.recordType],
        quantity: [this.transactionFilter.quantity],
        price: [this.transactionFilter.price],
        transactionTimeStart: [this.transactionFilter.transactionTimeStart, RemitTransactionsFilterComponent.transactionTimeRangeValidator],
        transactionTimeEnd: [this.transactionFilter.transactionTimeEnd, RemitTransactionsFilterComponent.transactionTimeRangeValidator],
        receivedByErrStart: [this.transactionFilter.receivedByErrStart, RemitTransactionsFilterComponent.receivedByRangeValidator],
        receivedByErrEnd: [this.transactionFilter.receivedByErrEnd, RemitTransactionsFilterComponent.receivedByRangeValidator],
        lastUpdateStart: [this.transactionFilter.lastUpdateStart, RemitTransactionsFilterComponent.lastUpdateRangeValidator],
        lastUpdateEnd: [this.transactionFilter.lastUpdateEnd, RemitTransactionsFilterComponent.lastUpdateRangeValidator],
        contractDateStart: [this.transactionFilter.contractDateStart, RemitTransactionsFilterComponent.contractDateRangeValidator],
        contractDateEnd: [this.transactionFilter.contractDateEnd, RemitTransactionsFilterComponent.contractDateRangeValidator],
        creationDateStart: [this.transactionFilter.creationDateStart, RemitTransactionsFilterComponent.creationDateRangeValidator],
        creationDateEnd: [this.transactionFilter.creationDateEnd, RemitTransactionsFilterComponent.creationDateRangeValidator],
        gasDayUtcStart: [this.transactionFilter.gasDayUtcStart, this.gasDayUtcDateRangeValidator],
        gasDayUtcEnd: [this.transactionFilter.gasDayUtcEnd, this.gasDayUtcDateRangeValidator],
        senderNames: [this.transactionFilter.senderNames]
      }
    );

    if (this.isDocumentTypeVisible()) {
      const documentTypesControl = new UntypedFormControl('', Validators.required);
      formGroup.addControl('documentTypes', documentTypesControl);
    }

    return formGroup;
  }

  ngOnDestroy(): void {
    this.orgChange$.unsubscribe();
  }

  onSubmit(): void {
    // check if all values are selected
    this.transactionFilter.documentType = this.documentType;
    const remitTransactionsSearchFilter = this.remitTransactionsFilterService
      .createRemitTransactionsSearchQuery(this.transactionFilter, this.multiSelect);

    remitTransactionsSearchFilter.omps = this.omps?.length === remitTransactionsSearchFilter?.omps?.length ? null: remitTransactionsSearchFilter.omps;
    remitTransactionsSearchFilter.mps = this.mps?.length === remitTransactionsSearchFilter?.mps?.length  ? null : remitTransactionsSearchFilter.mps;
    remitTransactionsSearchFilter.senderNames = this.senderNames?.length === remitTransactionsSearchFilter?.senderNames?.length  ? null : remitTransactionsSearchFilter.senderNames;

    this.remitTransactionsService.updateSearchQuery(this.documentType, remitTransactionsSearchFilter);
  }

  reset(): void {
    this.form.reset();
    this.loadInitialDates();
    this.resetSelectedOmps();
    this.resetSelectedMps();
    this.resetSenderNames();
    this.resetSelectedStates();
    this.resetSelectedActionTypes();
    this.resetSelectedDocumentTypes();
    this.transactionFilter = null;
  }

  private resetSelectedOmps(): void {
    if (this.omps) {
      this.form.patchValue({ omps: this.omps.map(({ value }) => value) });
    }
  }

  private resetSelectedMps(): void {
    if (this.mps) {
      this.form.patchValue({ mps: this.mps.map(({ value }) => value) });
    }
  }

  private resetSelectedStates(): void {
    if (this.states) {
      this.form.patchValue({ states: this.states.map(({ value }) => value) });
    }
  }

  private resetSelectedActionTypes(): void {
    if (this.actionTypes) {
      this.form.patchValue({ actionTypes: this.actionTypes.map(({ value }) => value) });
      this.form.updateValueAndValidity();
    }
  }

  private resetSelectedDocumentTypes(): void {
    if (this.documentTypes) {
      this.form.patchValue({ documentTypes: this.documentTypes.map(({ value }) => value) });
      this.form.updateValueAndValidity();
    }
  }
  private resetSenderNames(): void {
    if (this.documentTypes) {
      this.form.patchValue({ senderNames: this.senderNames.map(({ value }) => value) });
    }
  }

  private populateForm(model: RemitTransactionFilterModel) {
    const notEmpty = model && model.omps != null;

    if (notEmpty) {

      if (this.actionTypes.length > 0 && !!model.actionTypes) {
        const dropLabels: string[] = this.actionTypes.map(({ label }) => label);
        model.actionTypes = model.actionTypes.filter(item => dropLabels.includes(item));
      } else {
        model.actionTypes = null;
      }

      for (const [key, value] of Object.entries(model)) {
        if ((key.includes('Start') || key.includes('End')) && value) {
          model[`${key}`] = new Date(value);
        }
      }
      this.form.patchValue(model);
      this.form.markAsPristine();
    }
  }

  private loadInitialDates(): void {
    this.remitTransactionsFilterService.loadInitialStartReceivedByErrDate().then((presetDates: RemitPresetDatesResponseModel) => {
      if (presetDates.receivedByErr) {
        if (!this.form.value.receivedByErrStart && presetDates.receivedByErr.start) {
          const receivedByErrStart = new Date(presetDates.receivedByErr.start);
          this.form.patchValue({
            receivedByErrStart
          });
        }
        if (!this.form.value.receivedByErrEnd && presetDates.receivedByErr.end) {
          const receivedByErrEnd = new Date(presetDates.receivedByErr.end);
          this.form.patchValue({
            receivedByErrEnd
          });
        }
      }
    });
  }

  ngOnChanges({ documentType }: any) {
    this.actionTypeLabel = actionTypesMap.get(documentType.currentValue);
  }

  isValidFilter(): boolean {
    return this.form.valid;
  }

  isCommodityVisible(): boolean {
    return this.documentType === 'REMIT_TABLE_1' || this.documentType === 'REMIT_TABLE_2';
  }

  isPriceVisible(): boolean {
    return this.documentType === 'REMIT_TABLE_1' || this.documentType === 'REMIT_TABLE_2';
  }

  isT1Selected(): boolean {
    return this.documentType === 'REMIT_TABLE_1';
  }

  isT2Selected(): boolean {
    return this.documentType === 'REMIT_TABLE_2';
  }

  isCreationTimestampVisible(): boolean {
    return this.documentType === 'ELECTRICITY_RIGHTS' || this.documentType === 'GAS_CAPACITY';
  }

  isDocumentTypeVisible(): boolean {
    return this.documentType === 'FUNDAMENTALS';
  }

  isGasDayStartUtcVisible(): boolean {
    return this.documentType === 'FUNDAMENTALS';
  }

  isDocumentTypeFundamentals(): boolean {
    return this.documentType === 'FUNDAMENTALS';
  }

  getMaxSelectedStates(): number {
    let val: number;
    switch (this.documentType) {
      case 'REMIT_TABLE_1':
        val = 7;
        break;
      case 'REMIT_TABLE_2':
        val = 5;
        break;
      // case 'ELECTRICITY_RIGHTS':
      // case 'GAS_CAPACITY':
      // case 'FUNDAMENTALS':
      default:
        val = 4;
        break;
    }
    return val;
  }

  private static receivedByRangeValidator(control: AbstractControl) {
    return dateRangeValidator(control, 'receivedByErrStart', 'receivedByErrEnd');
  }

  private static transactionTimeRangeValidator(control: AbstractControl) {
    return dateRangeValidator(control, 'transactionTimeStart', 'transactionTimeEnd');
  }

  private static lastUpdateRangeValidator(control: AbstractControl) {
    return dateRangeValidator(control, 'lastUpdateStart', 'lastUpdateEnd');
  }

  private static contractDateRangeValidator(control: AbstractControl) {
    return dateRangeValidator(control, 'contractDateStart', 'contractDateEnd');
  }

  private static creationDateRangeValidator(control: AbstractControl) {
    return dateRangeValidator(control, 'creationDateStart', 'creationDateEnd');
  }

  public gasDayUtcDateRangeValidator(control: AbstractControl) {
    return dateRangeValidator(control, 'gasDayUtcStart', 'gasDayUtcEnd');
  }

  public isReceivedByRuleConformError() {
    const endErrors = this.form.controls['receivedByErrEnd'].errors;
    const startErrors = this.form.controls['receivedByErrStart'].errors;
    return (endErrors && endErrors[0]) || (startErrors && startErrors[0]);
  }

  public isTransactionTimeRuleConformError() {
    const endErrors = this.form.controls['transactionTimeEnd'].errors;
    const startErrors = this.form.controls['transactionTimeStart'].errors;
    return (endErrors && endErrors[0]) || (startErrors && startErrors[0]);
  }

  public isLastUpdateRuleConformError() {
    const endErrors = this.form.controls['lastUpdateEnd'].errors;
    const startErrors = this.form.controls['lastUpdateStart'].errors;
    return (endErrors && endErrors[0]) || (startErrors && startErrors[0]);
  }

  public isContractDateRuleConformError() {
    const endErrors = this.form.controls['contractDateEnd'].errors;
    const startErrors = this.form.controls['contractDateStart'].errors;
    return (endErrors && endErrors[0]) || (startErrors && startErrors[0]);
  }

  public isCreationDateRuleConformError() {
    const endErrors = this.form.controls['creationDateEnd'].errors;
    const startErrors = this.form.controls['creationDateStart'].errors;
    return (endErrors && endErrors[0]) || (startErrors && startErrors[0]);
  }

  public isGasDayUtcRuleConformError() {
    const endErrors = this.form.controls['gasDayUtcEnd'].errors;
    const startErrors = this.form.controls['gasDayUtcStart'].errors;
    return (endErrors && endErrors[0]) || (startErrors && startErrors[0]);
  }
}

function dateRangeValidator(control: AbstractControl, startDateControlName: string, endDateControlName: string) {
  let errors = null;
  const form = control.parent;
  if (form) {
    const endDate = form.controls[endDateControlName];
    form.controls[endDateControlName].errors = [];
    form.controls[startDateControlName].errors = [];// TODO CMSGUI-440 mr2 2018-05: check with knut: compiler would not approve 1. errors, status readonly. 2. errors not an array but an object: ValidationErrors
    form.controls[endDateControlName].status = 'VALID';
    form.controls[startDateControlName].status = 'VALID';
    if (endDate && endDate.value) {

      const date = new Date(endDate.value);
      let compareDate = null;

      if (form && form.controls[startDateControlName] && form.controls[startDateControlName].value) {
        compareDate = new Date(form.controls[startDateControlName].value);
        date.setHours(0);
        date.setMinutes(0);
        compareDate.setHours(0);
        compareDate.setMinutes(0);
        if (date < compareDate) {
          errors = [];
          errors.push(endDateControlName + 'NotRuleConform');
        }
      }
    }
  }

  return errors;
}


