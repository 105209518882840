import { createSelector } from '@ngrx/store';
import * as fromCommon from '@common/state/reducers';
import { getEsmInvoiceFinderState } from '../invoice-finder.selectors';
import { EsmInvoiceFinderState } from '../invoice-finder.reducers';
import { EsmInvoiceFinderMyFiltersState } from './my-filters.reducers';

export const getMyFilters = createSelector(getEsmInvoiceFinderState,
  (state: EsmInvoiceFinderState): EsmInvoiceFinderMyFiltersState =>
  state.myFilters);
export const getAllMyFilters = createSelector(getMyFilters, fromCommon.getAllMyFilters);
export const getDefaultFilter = createSelector(getMyFilters, fromCommon.getDefaultFilter);
export const getAllDealsFilter = createSelector(getMyFilters, fromCommon.getAllDealsFilter);
