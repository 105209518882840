<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>

<h1>eRR Deal Finder (CpML)</h1>

  <p-messages [value]="tableMessage" [closable]="true" class="col-12" (close)="tableMessage=[]"></p-messages>

  <div class="panel-box margin-bottom-12">
  <cms-info ngPreserveWhitespaces>
    <!--@formatter:off (markdown rendering breaks, when the following tags are formatted)-->
    __eRR Deal Finder (CpML only)__

    Investigate the reporting state of particular trades to your **EMIR** trade repository here.
    You can also find **REMIT** and **ElCom** trade report submissions here if they have been submitted in the **CpML** format (Commodity Product Markup Language).
    Unless you encounter enrichment and validation errors we however suggest you use the REMIT-specific monitoring features for your ACER reporting.

    **About Search Results**

    The resulting deal list is restricted to the legal entity you selected in the CMS title bar. This entity must be the document sender or counterparty on whose behalf the data is reported.
    CpML documents are versioned. Only the state of the latest submission is reflected in the deal list.
    You can, however, use the audit trail (magnifier) icon to check the entire reporting history for a particular trade.
    Refer to the documentation of screen "Dashboard (CpML)" for more information on states.

    **Searching by ID**

    Various types of trade identifers are available for searching. The respective search fields accept up to 50 identifiers separated by comma or line break - try cutting and pasting them from a spreadsheet:
    * **Document ID** = the value of the "Document ID" field of your CpML document
    * **UTI** = the unique trade identifier by which the trade is identified in the trade repository
    * **Trade ID** = the local trade reference from your trading system

    **Recent Reporting History**

    Use the rightmost search button to display all submissions done within the last five calendar days.
    Optionally filter them by **Document Type**:

    | Type  | Detail |
    |-------|-------------|
    | CNF   | OTC commodity trade |
    | FXT	| OTC currency trade |
    | IRT	| OTC interest rate trade |
    | ETD	| Exchange trade (various asset classes) |
    | VAL	| Valuation report |
    | COL	| Collateral report |

    You may also filter by **Trade Repository**:

    | Trade Repository Value | Detail |
    |-------|-------------|
    | REGIS_TR | EMIR trades |
    | UNA_VISTA | EMIR trades |
    | UNA_VISTA_MIFIR | MIFIR trades |
    | DTCC | EMIR trades |
    | ACER | REMIT trades |
    | ELCOM | trades reported to ElCom|
    | NONE | trades that were not sent to a repository (e.g. because they could not be validated by the CMS) |

    Guidance on the **State** column:

    | State  | Meaning |
    |-------|-------------|
    | Pending | The document was sent to the repository and is now waiting for a response or it is waiting to be sent to the repository because a former version is still waiting for a response. |
    | Alleged | A counterparty submitted a trade that has not been submitted by your organisation yet. |
    | Retry | Deals that have failed validation during the mapping or enrichment stage and have been moved to the manual intervention queue for inspection bythe service team. |
    | Rejected eRR | Any submission that has an error within the submitted XML file and is rejected by the eRR system. |
    | Rejected TR | Any submission that has been rejected directly by the TR. |
    | Communication prob -TR | Any submission that cannot be sent to the TR due a communications issue. |
    | Processing - eRR | Any submission that is currently being processed by the TR. |
    | Reported to TR | Successfully reported submissions to the TR. |
    | Not Reportable | Any submission that has been processed by eRR but does not require reporting under EMIR. |

    The 'State' column shows a cumulated status across all repositories that the document has been or will be submitted to. The state that requires a user's attention is shown with preference. For example, if a document is reported to two repositories and the state for one repository is 'Reported' and for the second repository 'Rejected TR', the cumulated status is shown as 'Rejected TR'.
    The order of preference is: \
    No Report < Reported < Pending < Communication problem < Processing < Manual intervention < Rejected TR < Rejected eRR.

    <!--@formatter:on-->
  </cms-info>
  <cms-not-found-ids-warning class="col-12"
                             [ids]="notFoundIDs$ | async"
                             [allCount]="idsCount$ | async">
  </cms-not-found-ids-warning>
  <cms-cpml-dealfinder-filter (changeDatatableVisibility)="changeDatatableVisibility()"></cms-cpml-dealfinder-filter>
</div>
<cms-cpml-datatable
  *ngIf="isDatatableVisible"
  (message)="tableMessage = $event"
></cms-cpml-datatable>

</ng-template>
