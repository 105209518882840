import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PasswordService } from './password.service';

@Component({
  selector: 'cms-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: [
    './password.component.scss'
  ],
})

export class ForgotPasswordComponent {

  forgotPasswordForm: UntypedFormGroup;
  private FORGOT_PASSWORD_API_URL: string = '/api/user/password/reset';
  errorMsg: string = '';

  constructor(private titleService: Title, formBuilder: UntypedFormBuilder, private passwordService: PasswordService, private router: Router) {
    this.forgotPasswordForm = formBuilder.group({
      username: ['', Validators.required],
    });

    titleService.setTitle('Reset My Password');

    this.passwordService.getErrorMessage().subscribe(
      msg => {
        this.errorMsg = msg;
      }
    );
  }

  onSubmit() {
    const username = this.forgotPasswordForm.value.username;

    if (username && username.length > 0) {
      this.passwordService.request(username);
    }
  }
}
