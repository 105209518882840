import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, combineLatest, switchMap, withLatestFrom } from 'rxjs/operators';
import { Message } from 'primeng/api';
import { ApiRequestService } from '@common/api-request.service';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { AllDealsMyFilterName } from '@common/my-filters/my-filters.model';
import { HiddenDeals } from '@common/deals/deals.model';
import { emptyDealsValue } from '@common/deals/deals.model';
import { EcmDashboardState } from '../dashboard.reducers';
import { EcmPropertyFiltersValuesResponse } from '../../../deal-finder/deal-finder.model';
import { getDashboard } from '../dashboard.selectors';
import { updateFilters } from '../../../ecm.model';
import * as fromTabActions from '../tab/tab.actions';
import * as fromMyFiltersActions from '../my-filters/my-filters.actions';
import * as fromFiltersActions from './filters.actions';
import * as fromMFActions from '../my-filters/my-filters.actions';

@Injectable()
export class EcmFiltersEffects {
  private baseUrl = '/api/ecm/dashboard';

  constructor(private apiGateway: ApiRequestService,
              private actions$: Actions,
              private store$: Store<EcmDashboardState>,
              private router: Router,
              private mapper: MessagesMapperService,) {
  }

   clearPropertiesFilters$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromFiltersActions.CLEAN_BY_PROPERTIES_FILTERS),
      withLatestFrom(this.store$.pipe(select(getDashboard))),
      switchMap(([, {filters, myFilters}]: [any, EcmDashboardState]) =>
        [
          new fromFiltersActions.UpdateFiltersAction(updateFilters(filters, filters.propFilters, myFilters.defaultFilter)),
          new fromTabActions.GetBucketsAction()
        ]
      )
    ));

   getFiltersSuccess$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromFiltersActions.GET_FILTERS_VALUES_SUCCESS),
      combineLatest(this.actions$.pipe(ofType(fromMFActions.LOAD_MY_FILTERS_SUCCESS))),
      withLatestFrom(this.store$.pipe(select(getDashboard))),
      switchMap(([, {filters, myFilters}]: any) =>
        [
          new fromFiltersActions.UpdateFiltersAction(updateFilters(filters, filters.propFilters, myFilters.defaultFilter)),
          new fromTabActions.GetBucketsAction()
        ]
      )
    ));

   filtersValues$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromFiltersActions.GET_FILTERS_VALUES),
      switchMap(() =>
        this.apiGateway.get(`${this.baseUrl}/formValues`)
          .pipe(
            switchMap((response: EcmPropertyFiltersValuesResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [new fromTabActions.SetMessagesAction(messages)];
              }
              let { broker } = response;
              const { commodities, counterParties, markets, transactionTypes } = response;
              if (broker) {
                broker = broker.map(item => item || emptyDealsValue);
              }          
              return [
                new fromTabActions.SetMessagesAction([]),
                new fromFiltersActions.GetFiltersValuesSuccessAction({ ...response, broker }),
                new fromMyFiltersActions.SetAllDealsFilterAction({
                  defaultFilter: true,
                  filterName: AllDealsMyFilterName,
                  hiddenType: HiddenDeals.EXCLUDE,
                  brokerFilter: broker,
                  commodityFilter: commodities,
                  counterpartyFilter: counterParties,
                  marketFilter: markets,
                  transactionTypeFilter: transactionTypes
                }),
              ];
            }),
            catchError(error => of(new fromTabActions.SetMessagesAction([this.mapper.createErrorMessage(error.errorMessage)])))
          )
      )
    ));
}


