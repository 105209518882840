import { createSelector } from '@ngrx/store';
import { getAdminState, State } from '../reducers';
import { PartyDataState } from './party-data.reducers';
import { DatatableState } from '../datatable/datatable.reducer';
import * as fromDatatable from '../datatable/datatable.reducer';
import { PartyDataEditableModel } from '../../administration-tab-panel/party-data/party-data.model';
import { Message } from 'primeng/api';

export const getPartyDataState = createSelector(getAdminState, (state: State) => state.partyData);
export const getDatatable = createSelector(getPartyDataState, (partyData: PartyDataState): DatatableState => partyData.datatable);
export const getFilters = createSelector(getDatatable, (state: DatatableState): string => state.filters);
export const getOrganisationId = createSelector(getPartyDataState, (state: PartyDataState) => state.organisationId);

export const getData = createSelector(getDatatable, fromDatatable.getData);
export const getSorting = createSelector(getDatatable, fromDatatable.getSorting);
export const getPaging = createSelector(getDatatable, fromDatatable.getPaging);
export const getLoading = createSelector(getDatatable, fromDatatable.getLoading);
export const getDtMessages = createSelector(getDatatable, fromDatatable.getMessages);
export const getMessages = createSelector(getPartyDataState, (partyData: PartyDataState): Message[] => partyData.messages);
export const getAddPartyData = createSelector(getPartyDataState, (partyData: PartyDataState): PartyDataEditableModel => partyData.addPartyData);

