export const TAB = 9,
  LEFT_ARROW = 37,
  RIGHT_ARROW = 39,
  BACKSPACE = 8,
  DELETE = 46,
  ESCAPE = 27,
  ENTER = 13;

export const SPECIAL_CHARACTERS = [' ', '/', '(', ')', '+', '\/', '-'];

export function overrideCharAtPosition(input: HTMLInputElement, position: number, key: string) {
  const currentValue = input.value;
  input.value = currentValue.slice(0, position) + key + currentValue.slice(position + 1);
}

export function emptyInput(input: string): boolean {
  return !!!input.match(/.*\d+.*/g);
}

