<!--// TODO CMSGUI-284 mr2 2018-02: check handling of long values like 100er uti.-->
<div class="sheet">
  <div class="row d-inline-flex flex-nowrap">
    <ng-container *ngFor="let groups of (leftRightGroups$ | async);">

      <div class="col-auto" *ngIf="groups?.length > 0">

        <ng-container *ngFor="let group of groups ">
          <cms-transaction-sheet-group name="{{group.name}} " label="{{group.label}} ">

            <ng-container *ngFor="let groupItem of group.values ">

              <ng-container *ngIf="hasMultiItemValue(groupItem); else elseTemplate ">
                <div class="group-multi-item ">
                  <ng-container *ngFor="let multiItem of groupItem.values ">
                    <cms-transaction-sheet-group-item [name]="multiItem.name" [label]="multiItem.label" [values]="multiItem.values">
                    </cms-transaction-sheet-group-item>
                  </ng-container>
                </div>
              </ng-container>
              <ng-template #elseTemplate>
                <cms-transaction-sheet-group-item [name]="groupItem.name" [label]="groupItem.label" [values]="groupItem.values">
                </cms-transaction-sheet-group-item>
              </ng-template>
            </ng-container>

          </cms-transaction-sheet-group>
        </ng-container>
      </div>

    </ng-container>
  </div>
</div>
