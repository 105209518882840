import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Message } from 'primeng/api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Action, select, Store } from '@ngrx/store';
import { AddNote, getDealsColumns } from '@common/deals/deals.model';
import { getBlobContent, saveBlob, toPayload } from '@common/cms-common.model';
import { InvoicesTableState } from './invoices-table.reducer';
import { ApiRequestService } from '@common/api-request.service';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import { ErrorResponse } from '@common/error-response.model';
import {
  allColumnsDealConfig,
  EsmNettedInvoicesTableDataResponse,
  NettedInvoicesRequestModel
} from '../../netted-invoices/netted-invoices.model';
import * as fromTableSelectors from './invoices-table.selectors';
import * as fromActions from './invoices-table.actions';
import * as fromDialogActions from './../dialog/dialog.actions';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class InvoicesTableEffects {

  constructor(private apiGateway: ApiRequestService,
              private actions$: Actions,
              private router: Router,
              private store$: Store<InvoicesTableState>,
              private mapper: MessagesMapperService) { }

  private baseUrl = '/api/esm/netting/preview';
  private baseUrlInv = '/api/esm/invoice/finder';

  loadDataAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadDataAction, fromActions.sortAndPageDataAction),
      withLatestFrom(this.store$.pipe(select(fromTableSelectors.getTable))),
      concatMap(([, state]: [Action, InvoicesTableState]) =>
        this.loadData(state)
      )
    )
  );

  export$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.exportAction),
      withLatestFrom(
        this.store$.pipe(select(fromTableSelectors.getTable)),
      ),
      concatMap(([, { paging, sorting, requestModel}]: [Action, InvoicesTableState]) =>

        this.apiGateway.getBlob(
          `${this.baseUrl}/invoices/export`, this.getLoadingRequest(paging, sorting, requestModel))
          .pipe(
            switchMap(this.handleBlob),
            catchError(error => of(fromActions.setMessagesAction([this.mapper.createErrorMessage(error.errorMessage || 'Server Error')]))))
      )
    )
  );

  downloadDoc$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.downloadXmlAction),
      map(toPayload),
      concatMap((id: number) =>
        this.apiGateway.getBlob(`${this.baseUrlInv}/invoices/xml`, null, new HttpParams().set('invoiceId', id.toString()))
          .pipe(
            switchMap(this.handleBlob),
            catchError(error => of(fromActions.setMessagesAction([this.mapper.createErrorMessage(error.errorMessage)]))))
      ),
    )
  );

  addNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addNoteAction),
      map(toPayload),
      concatMap(({confirmationIds: ids, text}: AddNote) => {
        return this.apiGateway.post(`${this.baseUrlInv}/createNote`, { ids, text })
          .pipe(
            switchMap((response: ErrorResponse & {}) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return of(fromDialogActions.updateDialogAction(messages));
              }
              return [
                fromDialogActions.closeDialogAction(),
                fromDialogActions.updateDialogAction([]),
                fromActions.addNoteSuccessAction()
              ];
            })
          );
      })
    ));

  addNotesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addNoteSuccessAction),
      filter(() => (this.router.routerState.snapshot.url.includes('netted_invoices'))),
      map(() => fromActions.loadDataAction()))
  );

  downloadPDf$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.downloadPdfAction),
      map(toPayload),
      concatMap(id => {

        return this.apiGateway.getBlob(`${this.baseUrlInv}/invoices/pdf`, null,
          new HttpParams().set('invoiceId', id.toString()))
          .pipe(
            switchMap(this.handleBlob),
            catchError(response => this.handleBlobErrorContent(response)));
      })
    )
  );


  // === helper ===

  private loadData(state: InvoicesTableState) {
    const request = this.getLoadingRequest(state.paging, state.sorting, state.requestModel);
    return this.apiGateway
      .post(`${this.baseUrl}/invoices`, request)
      .pipe(
        switchMap((response: EsmNettedInvoicesTableDataResponse) => {
          const messages: Message[] = this.mapper.toErrorMessages(response);


          if (messages && messages.length) {
            return [fromActions.setMessagesAction(messages), fromActions.setLoadingAction(false)];
          }

          const columns = getDealsColumns(response.columnOrder, allColumnsDealConfig);

          return [
            fromActions.setColumnsAction(columns),
            fromActions.setMessagesAction([]),
            fromActions.loadDataSuccessAction(response),
          ].filter(Boolean);
        }),
        catchError(error => of(
          fromActions.setMessagesAction([this.mapper.createErrorMessage(error.errorMessage || 'Server Error')]),
          fromActions.setLoadingAction(false)
        ))
      );
  }

  private getLoadingRequest(paging: PagingFilter, sorting: SortingFilter, requestModel: NettedInvoicesRequestModel) {

    return {
      counterPartyId: requestModel.counterPartyId,
      caseNumber: requestModel.caseNumber,
      myVAT: requestModel.myVAT,
      cpVAT: requestModel.cpVAT,
      dueDate: requestModel.dueDate,
      physicalFinancial: requestModel.physicalFinancial,
      commodities: requestModel.commodities,
      currency: requestModel.currency,
      agreement: requestModel.agreement,
      masterAgreementVersion: requestModel.masterAgreementVersion,
      paging,
      sorting
    };
  }

  private handleBlob(response): Observable<ReturnType<typeof fromActions.setMessagesAction>> {
    saveBlob(response);
    return of(fromActions.setMessagesAction([]));
  }


  /**
   * a perfect transformation of promise to observable response
   */
  private handleBlobErrorContent(errResponse):Observable<Action> {

    return new Observable(observer => {
      const controller = new AbortController();

      getBlobContent(errResponse)
        .then(message => {
          observer.next(fromActions.setMessagesAction([this.mapper.createErrorMessage(message)]));
          observer.complete();})
        .catch(err => {observer.error(err);});

      return () => controller.abort();
    });
  }

}

