import { createSelector } from '@ngrx/store';
import { EsmMasterDataState } from '../master-data.reducer';
import { getEsmMasterDataState } from '../master-data.selectors';
import { EsmExternalBankDetails, EsmExternalMasterDataDetailsState, EsmExternalMasterDataState, EsmExternalMasterDataTableState } from './external-master-data.reducer';

export const getExternalState = createSelector(getEsmMasterDataState, (state: EsmMasterDataState) => state.external);
export const getPresetValues = createSelector(getExternalState, (state: EsmExternalMasterDataState) => state.presetValues);
export const getMessages = createSelector(getExternalState, (state: EsmExternalMasterDataState) => state.messages);

export const getIsDetailsVisible = createSelector(getExternalState, (state: EsmExternalMasterDataState) => state.isDetailsVisible);
export const getIsNewRecord = createSelector(getExternalState, (state: EsmExternalMasterDataState) => state.isNewRecord);
export const getUploadStatus = createSelector(getExternalState, (state: EsmExternalMasterDataState) => state.upload.loading);
export const getDialog = createSelector(getExternalState, (state: EsmExternalMasterDataState) => state.dialog);

export const getTableDataState = createSelector(getExternalState, (state: EsmExternalMasterDataState) => state.tableData);
export const getTablePaging = createSelector(getTableDataState, (state: EsmExternalMasterDataTableState) => state.paging);
export const getTableSorting = createSelector(getTableDataState, (state: EsmExternalMasterDataTableState) => state.sorting);
export const getTableSearchToken = createSelector(getTableDataState, (state: EsmExternalMasterDataTableState) => state.searchToken);
export const getTableData = createSelector(getTableDataState, (state: EsmExternalMasterDataTableState) => state.data);
export const getTableStatus = createSelector(getTableDataState, (state: EsmExternalMasterDataTableState) => state.loading);

export const getDetailsState = createSelector(getExternalState, (state: EsmExternalMasterDataState) => state.details);
export const getDetailsMessages = createSelector(getDetailsState, (state: EsmExternalMasterDataDetailsState) => state.messages);
export const getDetailsFormValues = createSelector(getDetailsState, (state: EsmExternalMasterDataDetailsState) => state.formValues);
export const getDetailsBankData = createSelector(getDetailsState, (state: EsmExternalMasterDataDetailsState) => state.bankDetails);

export const getBankDetailsSorting = createSelector(getDetailsBankData, (state: EsmExternalBankDetails) => state.sorting);
export const getBankDetailsPaging = createSelector(getDetailsBankData, (state: EsmExternalBankDetails) => state.paging);
