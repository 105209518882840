import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { Message } from 'primeng/api';
import { ValidationErrors } from '@angular/forms';
import { EsmInvoiceFinder } from '../invoice-finder.model';
import { EsmStatesEnum, esmStatesToLabelMaps } from '../../dashboard/esm-dashboard.model';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'cms-esm-invoice-agree-dialog',
  templateUrl: './invoice-agree-dialog.component.html',
  styleUrls: ['./invoice-agree-dialog.component.scss']
})
export class InvoiceAgreeDialogComponent implements OnInit, OnChanges {
  @Input() invoices: EsmInvoiceFinder[];
  @Input() display = false;
  @Input() messages: Message[] = [];
  @Input() headerText = 'Invoice Agreement';
  @Output() displayChange = new EventEmitter<boolean>();
  @Output() cancel = new EventEmitter<Message[]>();
  @Output() agree = new EventEmitter<string[]>();
  errors: {[key: string]: ValidationErrors} = {};
  validInvoices: EsmInvoiceFinder[] = [];
  invalidInvoices: EsmInvoiceFinder[] = [];
  invoiceList: any[] = [];
  invalidList: any[] = [];
  hasValidInvoices: boolean;
  showWarning: boolean = false;

  noScroll= {
    'max-height':'150px',
    'font-size':'0.8rem'};

  scrollStyle = {
    'max-height':'150px',
    'font-size':'0.8rem',
    'border-color': '#d1d1d1',
    'border-style': 'solid',
    'border-radius': '5px'
  };
  constructor(private decimalPipe: DecimalPipe) { }


  ngOnInit(): void {
    if (this.invoices && this.invoices.length) {
      const today = new Date();
      this.validInvoices = this.invoices.filter(invoice => (invoice.state === esmStatesToLabelMaps[EsmStatesEnum.PENDING] || invoice.state === esmStatesToLabelMaps[EsmStatesEnum.MISMATCHED]) && ((new Date(invoice.paymentDate)).setHours(0,0,0,0) >= today.setHours(0,0,0,0)));
      this.hasValidInvoices = this.validInvoices.length > 0;
      if (this.hasValidInvoices) {
        this.invoiceList = this.validInvoices.map(invoice => (invoice.documentId + ', Due date: ' + invoice.paymentDate + ', Gross amount: ' + this.decimalPipe.transform(invoice.totalGrossAmount, '1.2-2') + ' ' + invoice.totalGrossAmountCurrency)).map(label => ({ label }));
        this.invalidInvoices = this.invoices.filter(invoice => (invoice.state !== esmStatesToLabelMaps[EsmStatesEnum.PENDING] && invoice.state !== esmStatesToLabelMaps[EsmStatesEnum.MISMATCHED]) || ((new Date(invoice.paymentDate)).setHours(0,0,0,0) < today.setHours(0,0,0,0)));
        if (this.invalidInvoices.length > 0) {
          this.invalidList = this.invalidInvoices.map(invoice => (invoice.documentId + ', State: ' + invoice.state  + ', Due date: ' + invoice.paymentDate)).map(label => ({ label }));
          this.showWarning = true;
        }
      } else {
        this.showWarning = true;
      }
      if (this.showWarning) {
        this.messages = [{
          severity: 'warn',
          summary: '',
          detail: 'The selected invoices should be in "PENDING" or "MISMATCHED" status and the payment date should not have expired yet.'
        }];
      }
    }
  }

  onConfirmAgree(): void {
    let confirmationIds: string[] = [];
    if (this.validInvoices) {
      confirmationIds = this.validInvoices.map(d => d.id + '');
      this.agree.emit( confirmationIds );
    }
  }

  onCancel(): void {
    this.hide();
  }

  onVisibleChange(visible: boolean): void {
    if (!visible) {
      this.onCancel();
    }
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (changes.messages) {
      this.errors = {};
      if (this.messages) {
        this.messages.forEach((obj: Message) => {
          const { key: controlName } = obj;
          this.errors[controlName] = { 'server': true };
        });
      }
    }
  }

  private hide(): void {
    this.messages = [];
    this.display = false;
    this.displayChange.emit(this.display);
    this.cancel.emit();
  }

  getStyle(list: string) {
    return list === 'invoiceList' ? this.invoiceList.length > 4 ? this.scrollStyle : this.noScroll :
      this.invalidInvoices.length > 4 ? this.scrollStyle : this.noScroll;
  }
}
