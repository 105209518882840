import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { Message } from 'primeng/api';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { ApiRequestService } from '@common/api-request.service';
import { DatatableConfig, DatatableData } from '@common/shared/results.model';
import { ErrorResponse } from '@common/error-response.model';
import { toPayload } from '@common/cms-common.model';
import {
  ElcomWhitelistResponse,
  ElcomWhitelistFilters,
  ElcomWhitelistRequest,
  ElcomWhitelistValues
} from '../../administration-tab-panel/elcom-whitelist/elcom-whitelist.model';
import { ElcomWhitelistState } from './elcom-whitelist.reducers';
import { Prefix } from '../reducers';
import * as fromDtActions from '../datatable/datatable.actions';
import * as fromSelectors from './elcom-whitelist.selectors';
import * as fromActions from './elcom-whitelist.actions';

@Injectable()
export class ElcomWhitelistEffects {
  private baseUrl = 'api/administration/elcomwhitelist';
  private prefix: Prefix = '[ELCOM WHITELIST]';

  constructor(private apiGateway: ApiRequestService,
              private actions$: Actions,
              private store$: Store<ElcomWhitelistState>,
              private mapper: MessagesMapperService) { }

   filter$ = createEffect(() => this.actions$
    .pipe(ofType(`${this.prefix} ${fromDtActions.FILTER_DATA}`),
      map(toPayload),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getDatatable))),
      switchMap(([value, { sorting, paging }]: [string, any]) => {
          const request: ElcomWhitelistRequest = {
            paging,
            sorting,
            ...value as ElcomWhitelistFilters
          };
          return this.loadData(request);
        }
      )));

   sortAndPage$ = createEffect(() => this.actions$
    .pipe(ofType(`${this.prefix} ${fromDtActions.SORT_AND_PAGE_DATA}`),
      map(toPayload),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getFilters))),
      switchMap(([{ sorting, paging }, { filter }]: [DatatableConfig, ElcomWhitelistFilters]) => {
          return this.loadData({
            filter,
            sorting,
            paging,
          });
        }
      )));

   delete$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.deleteElcomWhitelistAction.type),
      map(toPayload),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getFilters))),
      switchMap(([value, { filter }]: [string, ElcomWhitelistFilters]) => {
        let params = new HttpParams();
        params = params.set('acerCode', value);
        return this.apiGateway.delete(`${this.baseUrl}/delete`, params)
          .pipe(
            switchMap((response: ErrorResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [new fromDtActions.SetMessagesAction(this.prefix, messages)];
              }

              return [
                new fromDtActions.FilterDataAction(this.prefix, { filter }),
                new fromDtActions.SetMessagesAction(this.prefix, this.mapper.toSuccessMessages(`${value} successfully deleted.`))
              ];
            }));
      }),
      catchError(error => of(new fromDtActions.SetMessagesAction(this.prefix, [
        this.mapper.createErrorMessage(error.errorMessage)
      ])))
    ));

   add$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.addElcomWhitelistAction.type),
      map(toPayload),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getFilters))),
      switchMap(([acerCode, { filter }]: [string, ElcomWhitelistFilters]) => {
        return this.apiGateway.post(`${this.baseUrl}/add`, { acerCode })
          .pipe(
            switchMap((response: ErrorResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [new fromDtActions.SetMessagesAction(this.prefix, messages)];
              }

              return [
                fromActions.addElcomWhitelistSuccessAction(),
                new fromDtActions.FilterDataAction(this.prefix, { filter }),
                new fromDtActions.SetMessagesAction(this.prefix, this.mapper.toSuccessMessages(`${acerCode} successfully whitelisted.`))
              ];
            }));
      }),
      catchError(error => of(new fromDtActions.SetMessagesAction(this.prefix, [
        this.mapper.createErrorMessage(error.errorMessage)
      ])))
    ));

  private loadData(request: ElcomWhitelistRequest) {
    return this.apiGateway
      .post(`${this.baseUrl}/data`, request)
      .pipe(
        map((response: ElcomWhitelistResponse) => {
          const messages: Message[] = this.mapper.toErrorMessages(response);
          if (messages && messages.length) {
            return new fromDtActions.SetMessagesAction(this.prefix, messages);
          }

          const { currentPage, count, values } = response;
          return new fromDtActions.FilterDataSuccessAction(this.prefix, {
            currentPage,
            count,
            values
          } as DatatableData<ElcomWhitelistValues[]>);
        }),
        catchError(error => of(new fromDtActions.SetMessagesAction(this.prefix, [
          this.mapper.createErrorMessage(error.errorMessage)
        ]))));
  }
}
