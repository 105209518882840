import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RemitTradeEntryTable1ModifyService } from '../remit-trade-entry-table1-modify.service';
import { SelectItemGroup } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cms-remit-trade-entry-table1-modify-fixing-index',
  templateUrl: './remit-trade-entry-table1-modify-fixing-index.component.html',
  styleUrls: ['./remit-trade-entry-table1-modify-fixing-index.component.scss']
})
export class RemitTradeEntryTable1ModifyFixingIndexComponent implements OnInit, OnDestroy {

  @Input()
  formGroup: UntypedFormGroup;

  onDestroy$ = new Subject();

  disableButtons: boolean = false;
  currencies: SelectItemGroup[];

  indexNames: SelectItemGroup[];

  fixingIndices: FixingIndexTradeEntryModel[] = null;

  validationErrors: string[] = [];


  constructor(private table1ModifyService: RemitTradeEntryTable1ModifyService, private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {

    this.formGroup.addControl('fixingIndices', this.formBuilder.array([]));

    this.table1ModifyService.onTradeEntryModelChange().pipe(takeUntil(this.onDestroy$)).subscribe(tradeEntryModel => {
      if (tradeEntryModel) {
        this.fixingIndices = tradeEntryModel?.commercialResponseModel?.fixingIndexModels ?? [];
        this.initFormValues();
        this.updateAcerV3Form();
      }
    });

    this.table1ModifyService.onTradeEntrySetupDataChange().pipe(takeUntil(this.onDestroy$)).subscribe(setupData => {
      if (setupData?.commercialValueSetsResponseModel) {
        this.initFormSetup(setupData.commercialValueSetsResponseModel);
      }
    });

    this.table1ModifyService.onModifyTradeDisabled().subscribe(modified => {
      this.disableButtons = modified;
    });

    this.table1ModifyService.onValidationErrors().pipe(takeUntil(this.onDestroy$)).subscribe(validationErrors => {
      this.handleServerValidationErrors(validationErrors);
    });
  }

  private updateAcerV3Form() {

      (this.formGroup.get('fixingIndices') as UntypedFormArray)?.controls.forEach( fixIndex => {
        (fixIndex.get('indexValues') as UntypedFormArray)?.controls.forEach( indValue => {
          indValue.get('indexCurrency').enable();
        })
      });
  }

  private initFormSetup(commercialValues: any) {
    if (commercialValues?.indexNames) {
      this.indexNames = this.table1ModifyService.getGroupedValues(commercialValues.indexNames);
    }
    if (commercialValues?.currencies) {
      this.currencies = this.table1ModifyService.getGroupedValues(commercialValues.currencies);
    }
  }

  get bilateral(): boolean {
    return this.fixingIndices !== null;
  }

  get indices(): AbstractControl[] {
    return (this.formGroup.controls['fixingIndices'] as UntypedFormArray).controls;
  }

  get disableAddButton(): boolean {
    return this.indices.length > 3 || this.disableButtons;
  }

  get disableRemoveButton(): boolean {
    return this.indices.length === 0 || this.disableButtons;
  }

  add(fixingIndex: FixingIndexTradeEntryModel) {

    const indices = this.formGroup.controls['fixingIndices'] as UntypedFormArray;

    if (indices.length < 4) {

      indices.push(
        this.formBuilder.group({
          indexName: [fixingIndex?.indexName ?? ''],
          indexValues: this.formBuilder.array(this.extractIndexValueFormGroups(fixingIndex))
        })
      );
    }
  }

  private extractIndexValueFormGroups(fixingIndex: FixingIndexTradeEntryModel): UntypedFormGroup[] {
    if (fixingIndex?.indexValues?.length > 0) {
      return fixingIndex.indexValues.map(iv => this.toFormGroup(iv));
    } else {
      return [this.toFormGroup(null)];
    }
  }

  private toFormGroup(indexValue: IndexValueTradeEntryModel): UntypedFormGroup {
    return this.formBuilder.group({
      indexValue: [indexValue?.indexValue ?? ''],
      indexCurrency: [{ value: indexValue?.indexCurrency ?? '', disabled: false }]
    });
  }

  remove() {
    const indices = this.formGroup.controls['fixingIndices'] as UntypedFormArray;

    if (indices.length > 0) {
      indices.removeAt(indices.length - 1);
    }
  }

  initFormValues() {
    (this.formGroup.controls['fixingIndices'] as UntypedFormArray).controls = [];
    if (this.fixingIndices) {
      for (const index of this.fixingIndices) {
        this.add(index);
      }
    }
  }

  reset() {
    this.formGroup.controls['fixingIndices'].reset();
    this.formGroup.addControl('fixingIndices', this.formBuilder.array(
      []
    ));
  }

  appendValidationErrorClassOfArray(i: number, fieldName: string): string {

    const form = this.indices[i] as UntypedFormGroup;

    if (form) {

      if (this.validationErrors[fieldName + i]) {
        return 'error';
      }
    }
  }

  private handleServerValidationErrors(errors: any) {
    this.validationErrors = [];
    if (errors) {
      this.validationErrors['indexName0'] = errors.indexName1;
      this.validationErrors['indexName1'] = errors.indexName2;
      this.validationErrors['indexName2'] = errors.indexName3;
      this.validationErrors['indexName3'] = errors.indexName4;
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }
}

export interface FixingIndexTradeEntryModel {
  indexName: string;
  indexValues: IndexValueTradeEntryModel[];
}

export interface IndexValueTradeEntryModel {
  indexValue: number;
  indexCurrency: string;
}


