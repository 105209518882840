import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Message } from 'primeng/api';
import { SortingFilter } from '@common/shared/results.model';
import { Column } from '@common/shared/datatable.model';
import { OrganisationService } from '@common/organisation.service';
import {
  allColumnsConfigBrokersAndTraders,
  allColumnsConfigOrgGroup,
  EcmSettingsProcessData,
  ProcessDataInDatatable
} from './ecm-process.model';
import * as fromSelectors from '../../../state/settings/process/process.selectors';
import { EcmSettingsProcessState } from '../../../state/settings/process/process.reducers';
import * as fromAction from '../../../state/settings/process/process.actions';

@Component({
  selector: 'cms-process',
  templateUrl: './ecm-process.component.html',
  styleUrls: ['./ecm-process.component.scss']
})

export class EcmProcessComponent implements OnInit, OnDestroy {
  data$: Observable<EcmSettingsProcessData> = this.store.pipe(select(fromSelectors.getData));
  messages$: Observable<Message[]> = this.store.pipe(select(fromSelectors.getMessages));
  sorting$: Observable<SortingFilter> = this.store.pipe(select(fromSelectors.getSorting));
  selected$: Observable<ProcessDataInDatatable> = this.store.pipe(select(fromSelectors.getSelected));
  columns$: Observable<Column[]> = this.store.pipe(select(fromSelectors.getColumns));
  subscription$: Subscription;
  rowIndexProcess: number;
  editDisabled: boolean;
  newValues: ProcessDataInDatatable;
  readonly orgGroupDataTableColumns: Column[] = allColumnsConfigOrgGroup;
  readonly brokersAndTradersDataTableColumns: Column[] = allColumnsConfigBrokersAndTraders;

  constructor(private store: Store<EcmSettingsProcessState>,
              private organisationService: OrganisationService) { }

  ngOnInit() {
    this.store.dispatch(new fromAction.LoadDataAction());

    this.subscription$ = this.organisationService.getOrganisationChangeIfAccessible()
      .subscribe(() => {
        this.store.dispatch(new fromAction.SetToDefaultAction());
        this.store.dispatch(new fromAction.LoadDataAction());
      });
    this.newValues = null;
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
    this.store.dispatch(new fromAction.SetToDefaultAction());
  }

  onEditMode(rowIndex, row): void {
    this.rowIndexProcess = rowIndex;
    this.editDisabled = true;
    this.newValues = {...row };
  }

  onCancelButton(): void {
    this.newValues = null;
    this.rowIndexProcess = null;
    this.editDisabled = false;
  }

  onOfOnSave(changedItem: {column: string, newValue: boolean}): void {
    const { column, newValue } = changedItem;

    this.newValues = {
      ...this.newValues,
      [column]: newValue
    };
  }

  onSaveButton(obj: ProcessDataInDatatable): void {
    this.store.dispatch(new fromAction.UpdateEcmFeatures({
      organisationId: obj.orgId,
      settings: {
        amendmentOfCancelledDeals: obj.amendmentOfCancelledDeals,
        amendmentOfMatchedDeals: obj.amendmentOfMatchedDeals,
        autoTerminate: obj.autoTerminate,
        boxResultContainsUSI: obj.boxResultContainsUSI,
        boxResultContainsUTI: obj.boxResultContainsUTI,
        boxResultForTerminate: obj.boxResultForTerminate,
        matchBrokerFee: obj.matchBrokerFee,
        tearUpMatches: obj.tearUpMatches,
        amendmentOfBrokerLeg: obj.amendmentOfBrokerLeg,
        boxResultRmPending: obj.boxResultRmPending,
        boxResultTearUpRequested: obj.boxResultTearUpRequested
      }
    }));
    setTimeout(() => {
      this.editDisabled = false;
      this.newValues = null;
      this.rowIndexProcess = null;
    }, 2500);
  }
}
