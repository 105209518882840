import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { Message } from 'primeng/api';
import { AddEditOrganisationGroup, OrganisationGroup, Reporting, ReportLevel } from '../organisation-groups.model';

@Component({
  selector: 'cms-add-edit-group-dialog',
  templateUrl: './add-edit-group-dialog.component.html',
  styleUrls: ['./add-edit-group-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddEditGroupDialogComponent implements OnInit, OnChanges {
  @Input() apiKey: string;
  @Input() data: OrganisationGroup;
  @Input() display = false;
  @Input() messages: Message[];
  @Input() mode: 'ADD' | 'EDIT';
  @Output() displayChange = new EventEmitter<boolean>();
  @Output() confirm = new EventEmitter<AddEditOrganisationGroup>();
  @Output() cancel = new EventEmitter<Message[]>();
  @Output() generate = new EventEmitter<{ organisationGroupId: number }>();
  errors: { [key: string]: ValidationErrors } = {};
  form: UntypedFormGroup;
  ReportLevel = ReportLevel;
  Reporting = Reporting;

  constructor(private formBuilder: UntypedFormBuilder,
              private mapper: MessagesMapperService) {
  }

  onCancel(): void {
    this.hide();
    this.cancel.emit(this.mapper.toWarningMessages(
      this.mode === 'ADD' ? 'Adding a new organisation group was cancelled.' : `Editing of organisation group '${this.data.displayName}' was cancelled.`
    ));
  }

  onVisibleChange(visible: boolean): void {
    if (!visible) {
      this.onCancel();
    }
  }

  onConfirm(): void {
    const out: AddEditOrganisationGroup = this.form.getRawValue();
    if (this.mode === 'EDIT') {
      out.organisationGroupId = this.data.organisationGroupId;
    }
    this.confirm.emit(out);
  }

  onGenerate(): void {
    const { organisationGroupId } = this.data;
    this.generate.emit({ organisationGroupId });
  }

  onClickOrganisation(): void {
    this.form.controls['reportFeedPartnerId'].setValue(null);
    this.form.controls['activityReport'].setValue(false);
    this.form.controls['reconciliationReport'].setValue(false);
  }

  ngOnInit(): void {
    const {
      displayName = null,
      fourthPartyPermission: fourthPartyPermissions = false,
      allowedIpRange: ipRange = null,
      legalName = null,
      reportFeedPartner: reportFeedPartnerId = null,
      apiKey = null,
      reportLevel = ReportLevel.GROUP,
      activityReport = false,
      reconciliationReport = false,
    } = this.data || {};
    this.form = this.formBuilder.group({
      displayName,
      fourthPartyPermissions,
      ipRange,
      legalName,
      reportFeedPartnerId,
      reportLevel,
      activityReport,
      reconciliationReport
    });

    if (this.mode === 'EDIT') {
      this.form.addControl('apiKey', new UntypedFormControl({ value: apiKey || null, disabled: true }));
    }
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (this.form) {
      if (changes.display && !this.display) {
        this.hide();
      }
      if (changes.data) {
        const {
          displayName = null,
          fourthPartyPermission: fourthPartyPermissions = false,
          allowedIpRange: ipRange = null,
          legalName = null,
          reportFeedPartner: reportFeedPartnerId = null,
          apiKey = null,
          reportLevel = ReportLevel.GROUP,
          activityReport = false,
          reconciliationReport = false,
        } = this.data || {};
        this.form.patchValue({
          displayName,
          fourthPartyPermissions,
          ipRange,
          legalName,
          reportFeedPartnerId,
          apiKey,
          reportLevel,
          activityReport,
          reconciliationReport
        });
        this.form.updateValueAndValidity();
      }

      if (changes.apiKey) {
        this.form.controls['apiKey'].patchValue(this.apiKey || null);
      }
    }

    if (changes.messages) {
      this.errors = {};
      if (this.messages) {
        this.messages.forEach((obj: Message) => {
          const { key: controlName } = obj;
          this.errors[controlName] = { 'server': true };
        });
      }
    }
  }

  private hide(): void {
    this.messages = [];
    this.display = false;
    this.displayChange.emit(this.display);
    this.form.reset();
    this.form.updateValueAndValidity();
  }
}
