import { Component, OnInit } from '@angular/core';
// services
import { UserService } from '@common/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlReferenceModel, UrlReferenceService } from '@common/url-reference.service';
import { OrganisationService } from '@common/organisation.service';
import { Message } from 'primeng/api';
import { catchError, filter, map, take, tap } from 'rxjs/operators';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { Observable, of } from 'rxjs';
import { NavigationService } from '@common/menu/navigation.service';

@Component({
  selector: 'cms-welcome',
  templateUrl: './welcome.component.html'
})

export class WelcomeComponent implements OnInit {

  messages: Message[] = [];

  constructor(private userService: UserService,
              private orgService: OrganisationService,
              private urlReferenceService: UrlReferenceService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private mapper: MessagesMapperService,
              private navigationService: NavigationService) {
  }

  ngOnInit() {
    const referenceCode = this.activatedRoute.snapshot.queryParams['ref'];

    if (referenceCode != null) {
      this.resolveLink(referenceCode).subscribe(({ targetUrl, targetRequestParams }) =>
          this.router.navigate([targetUrl], { state: { targetRequestParams } }),
        err => this.handleUrlReferenceError('' + err));
    }
  }

  private resolveLink(param: string): Observable<{ targetUrl: string; targetRequestParams: string }> {

    return this.urlReferenceService.resolveUrlReference(param)
      .pipe(
        tap(m => this.hasMessage(m)),
        filter(v => this.isValidUrlReference(v)),
        tap(({ organisationId }) => this.setOrganisation(organisationId)),
        tap(() => this.navigationService.fetchNavigation()),
        map(({ targetUrl, targetRequestParams }) => ({ targetUrl, targetRequestParams })),
        take(1));
  }


  private setOrganisation(organisationId): void {
    this.orgService.subscribeOrganisations()
      .pipe(
        filter(organisations => organisations?.length > 0),
        tap(() => this.emitAndCashOrg(organisationId)),
        take(1),
        catchError((err) => of('set organisation did not work: ' + err)))
      .subscribe();
  }

  private emitAndCashOrg(organisationId){
    this.orgService.emitChangeOrganisation(organisationId);
    this.orgService.cacheCurrentOrganisationId(organisationId);
  }

  private handleUrlReferenceError(message: string) {
    this.messages = [];
    const detail = message.startsWith('Unauthorized access') ?
      'You do not have the necessary permission to view the requested page.' :
      message;
    this.messages.push(this.mapper.createErrorMessage(detail));
  }

  private hasMessage(urlReferenceModel: UrlReferenceModel) {
    if (urlReferenceModel?.validationFailureMessages?.length > 0) {
      this.messages = MessagesMapperService.arrayToErrorMessage(urlReferenceModel.validationFailureMessages);
    }
  }

  private isValidUrlReference(urlReferenceModel: UrlReferenceModel): boolean {
    return urlReferenceModel?.organisationId != null && urlReferenceModel?.targetUrl != null;
  }
}
