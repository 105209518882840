<div>
    <form [formGroup]="editForm" >
        <div class="row col-12">
            <div class="col-5 label padding-6">LEI Code: <span class="text-required">*</span></div>
            <div class="col-7">
                <input id="leiCode" formControlName="leiCode" class="form-control margin-3 text-medium" type="text" readonly />
            </div>
        </div>
        <div class="row col-12">
            <div class="col-5 label padding-6">Counterparty Financial Nature: <span class="text-required">*</span></div>
            <div id="financialNature" class="col-7">
                <p-dropdown formControlName="cpFinancialNature" [options]="financialNatures" [style]="{'width': '100%'}" [styleClass]="hasCpFinancialNatureValidationErrors ? 'margin-3 error' : 'margin-3'" ></p-dropdown>
                <span *ngIf="hasCpFinancialNatureValidationErrors" class="fa fa-info-circle absolute validation-error"></span>
            </div>
        </div>
        <div class="row col-12">
            <div class="col-5 label padding-6">Counterparty Corporate Sectors: <span class="text-required" *ngIf="isCpCorpSectorsRequired">*</span></div>
            <div id="corporateSectors" class="col-7">
                <ul class="padding-6">
                  <li *ngFor="let cpCorpSector of cpCorpSectors">
                    {{cpCorpSector.label}}
                  </li>
                </ul>
                <span *ngIf="hasCpCorpSectorsValidationErrors" class="fa fa-info-circle absolute validation-error"></span>
            </div>
        </div>
        <div class="row col-12">
            <div class="col-5 label padding-6">Trading capacity: <span class="text-required">*</span></div>
            <div id="tradingCapacity" class="col-7">
                <p-dropdown formControlName="tradingCapacity" [options]="tradingCapacities" [style]="{'width': '100%'}" [styleClass]="hasTradingCapacityValidationErrors ? 'margin-3 error' : 'margin-3'" ></p-dropdown>
                <span *ngIf="hasTradingCapacityValidationErrors" class="fa fa-info-circle absolute validation-error"></span>
            </div>
        </div>
        <div class="row col-12 margin-bottom-3">
            <div class="col-5 label padding-6">Beneficiary ID: <span class="text-required" *ngIf="isBeneficiaryIdRequired">*</span></div>
            <div class="col-7">
                <input id="beneficiaryId"
                    formControlName="beneficiaryId"
                    class="form-control margin-3 text-medium"
                    type="text"
                    [ngClass]="{'error': hasBeneficiaryIdValidationErrors }"
                    [readonly]="true"
                    maxlength="20"/>
                <span *ngIf="hasBeneficiaryIdValidationErrors" class="fa fa-info-circle absolute validation-error"></span>
            </div>
        </div>
        <div class="row col-12 margin-bottom-3">
            <div class="col-5 label padding-6">Deal directly linked to Commercial Acitvity or Treasury Financing: <span class="text-required" *ngIf="isCommercialActivityRequired">*</span></div>
            <div id="commercialActivity" class="col-7">
                <span class="margin-12 inline-block">
                  <input type="radio" id="commercialActivityYes" name="commercialActivity" value="YES" formControlName="commercialActivity" class="me-1">
                  <label for="commercialActivityYes">Yes</label>
                </span>
                <span class="margin-12 inline-block">
                    <input type="radio" id="commercialActivityNo" name="commercialActivity" value="NO" formControlName="commercialActivity" class="me-1">
                    <label for="commercialActivityNo" >No</label>
                </span>
                <span *ngIf="hasCommercialActivityValidationErrors" class="fa fa-info-circle absolute validation-error"></span>
            </div>
        </div>
        <div class="row col-12 margin-bottom-3">
            <div class="col-5 label padding-6">Clearing threshold: <span class="text-required" *ngIf="isClearingThresholdRequired">*</span></div>
            <div class="col-7">
                <span class="margin-12 inline-block">
                    <input type="radio" id="clearingThresholdAbove" name="clearingThreshold" value="ABOVE" formControlName="clearingThreshold" class="me-1">
                    <label for="clearingThresholdAbove">Above</label>
                </span>
                <span class="margin-12 inline-block">
                    <input type="radio" id="clearingThresholdBelow" name="clearingThreshold" value="BELOW" formControlName="clearingThreshold" class="me-1">
                    <label for="clearingThresholdBelow">Below</label>
                </span>
                <span *ngIf="hasClearingThresholdValidationErrors" class="fa fa-info-circle absolute validation-error"></span>
            </div>
        </div>
        <div class="row col-12 margin-bottom-3">
            <div class="col-5 label padding-6">Collateralisation: <span class="text-required" *ngIf="isCollateralisationRequired">*</span></div>
            <div id="collateralisation" class="col-7">
                <p-dropdown formControlName="collateralisation" [options]="collateralisations" [style]="{'width': '100%'}" [styleClass]="hasCollateralisationValidationErrors ? 'margin-3 error' : 'margin-3'" ></p-dropdown>
                <span *ngIf="hasCollateralisationValidationErrors" class="fa fa-info-circle absolute validation-error"></span>
            </div>
        </div>
        <div class="row col-12 margin-bottom-3">
            <div class="col-5 label padding-6">Collateral portfolio: <span class="text-required" *ngIf="isCollateralPortfolioRequired">*</span></div>
            <div class="col-7">
                <span class="margin-12 inline-block">
                    <input type="radio" id="collateralPortfolioYes" name="collateralPortfolio"  value="YES" formControlName="collateralPortfolio" class="me-1">
                    <label for="collateralPortfolioYes" >Yes</label>
                </span>
                <span class="margin-12 inline-block">
                    <input type="radio" id="collateralPortfolioNo" name="collateralPortfolio"  value="NO" formControlName="collateralPortfolio" class="me-1">
                    <label for="collateralPortfolioNo">No</label>
                </span>
                <span *ngIf="hasCollateralPortfolioValidationErrors" class="fa fa-info-circle absolute validation-error"></span>
            </div>
        </div>
        <div class="row col-12 margin-bottom-3">
            <div class="col-5 label padding-6">Collateral portfolio code: <span class="text-required" *ngIf="isCollateralPortfolioCodeRequired">*</span></div>
            <div class="col-7">
                <input id="collateralPortfolioCode" formControlName="collPortfolioCode" class="form-control margin-3 text-medium" [ngClass]="{'error': hasCollPortfolioCodeValidationErrors }" type="text" [readonly]="true" />
                <span *ngIf="hasCollPortfolioCodeValidationErrors" class="fa fa-info-circle absolute validation-error"></span>
            </div>
        </div>
        <div class="row col-12">
            <div class="col-5 label padding-6">Master Agreement version: <span class="text-required">*</span></div>
            <div class="col-7">
                <input id="masterAgreeVersion" formControlName="masterAgreeVersion" class="form-control margin-3 text-medium" [ngClass]="{'error': hasMasterAgreeVersionValidationErrors}" type="text" placeholder="Year" maxlength="4" [readonly]="true" />
                <span *ngIf="hasMasterAgreeVersionValidationErrors" class="fa fa-info-circle absolute validation-error"></span>
            </div>
        </div>
    </form>
</div>
