import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'splitspace' })
export class SplitSpace implements PipeTransform {
    transform(label: string): string {

      return label?.includes(',') ?
        label.replace(',', ', ') :
        label;
    }
}
