<cms-info ngPreserveWhitespaces>
  <!--@formatter:off (markdown rendering breaks, when the following tags are formatted)-->
  <!-- lsit with (a,b,c) need two whitespace at the end of line, so ngx-markdown interpret it as a list -->
  __REMIT Reconciliation Report__

  This automatic report analyses whether the buy and sell sides reported their REMIT trades consistently via eRR.

  It is executed __each weekday__ (MON-FRI) and retained for {{retentionRunsNumber}} days.

  __Reconciled Trades__

  * __Trade Types__: REMIT Standard Trades (REMIT Table 1) excluding orders and backloads.
  * __Counterparties__: The buy and/or sell side is member of your organisation group and has an ACER code. Anonymous reports (with blank "other MP" field) are excluded.
  * __In execution__: The trade is in execution on the report's __as of date__, i.e. trade date &le; as of date &le; delivery end date (or early termination date if applicable).
  * __Latest reported version__: The trade fields for comparison are drawn from the ACER-accepted record (New/Modify/Cancel) with the latest transaction timestamp, excluding deleted records.

  __Included Legal Entities__

  The "Report Level" setting chosen by your organisation group determines which organisations in your group are included in the report

  * Option "Group" (default): all legal entities are included in one report
  * Option "Organisation": separate reports are generated for each legal entity

  __Reconciliation Process__

  1. __Pairing__: For all trades in scope link the latest reported buy and sell sides based on MP ACER code (Party 1), Other MP ACER code (Party 2), OMP and UTI.
     Unpaired trade sides appear in category:
        (a) PAIRING FAILED if submissions from both sides exist. Check those for omissions and UTI inconsistencies.
        (b) PENDING if the counterparty has not reported any trades against your group member
        (c) ALLEGED if your group member has not reported any trades but the counterparty has

  2. __Matching__: For established pairs compare the reconciliation fields. They appear in category:
     (a) MATCHED if all reconciliation fields match
     (b) MISMATCHED if a discrepancy was found (discrepancies are highlighted)


  __Reconciliation Fields__

  | Field | Match Criterion | Details |
  | ---   | ---             | ---     |
  | Buy or Sell | opposite values | - |
  | Execution Date | same date| <ul><li>value: date in execution timestamp, if present&semi; date in transaction timestamp of earliest accepted "N" record, otherwise</li><li>note: trades with incomplete report histories (no "New" event) are excluded from this reconciliation </li></ul> |
  | Termination Date | max. one day apart | <ul><li>value: equals the delivery end date unless subject to early termination</li></ul> |
  | Contract Type | same value (with tolerance) | <ul><li>matching: OP, OP_FU, OP_FW, OP_SW are considered equal</li></ul>
  | Settlement Method | same value | - |
  | Option Type (Put or Call) | same value | - |
  | Commodity | same value | <ul><li>value: spreads with both commodities appear as "EL_NG"</li></ul> |
  | Delivery Point | same value | <ul><li>value: first occurrence if multiple delivery points are reported</li></ul>
  | Delivery Start Date | same date | - |
  | Delivery End Date | max. one day apart | - |
  | Quantity (value + unit) | same value + unit | <ul><li>value: quantity of first time interval if not defined at top level</li><li>matching: accounts for unit prefixes, e.g. 2000000 Therm/d matches 2 MTherm/d</li><li>matching: KW are considered equal to KWh/h (same for MW, GW)</li></ul> |
  | Price (value + unit) | same amount + currency | <ul><li>value: price of first time interval if not defined at top level</li><li>matching: accounts for fraction units, e.g. 27.32 GBP matches 2732 GBX</li></ul> |
  | Total Quantity (value + unit) | same value + unit | <ul><li>matching: accounts for unit prefixes, e.g. 1.5 GWh matches 1500 MWh</li></ul> |
  | Notional Amount (value + unit) | same amount | <ul><li>matching: accounts for fraction units</li></ul> |
  <!--@formatter:on (markdown rendering breaks, when the following tags are formatted)-->
</cms-info>

<div class="decent-max-width">
  <p-dialog header="Additional Information" [(visible)]="isOverlayPanelVisible" [modal]="isBackgroundBlocked" [style]="{width: '300px'}">
    <table>
      <tr>
        <td>Version:</td>
        <td style="padding-left: 20px">{{version}}</td>
      </tr>
      <tr>
        <td>Executed by:</td>
        <td style="padding-left: 20px">{{executedBy}}</td>
      </tr>
      <tr>
        <td>Execution time [s]:</td>
        <td style="padding-left: 20px">{{executionDurationInSec}}</td>
      </tr>
    </table>
    <p-footer>
      <button type="button" id="cancelAddInfoBtn" pButton label="Cancel" (click)="closeInfo()"></button>
    </p-footer>
  </p-dialog>

  <p-table [value]="rows | async" selectionMode="single" [autoLayout]="true" [(selection)]="selection">
    <ng-template pTemplate="header">
      <tr>
        <ng-container *ngFor="let col of columns">
          <th [style]="col.style">
            {{col.title}}
          </th>
        </ng-container>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-row let-index="rowIndex">
      <tr [pSelectableRow]="row">
        <ng-container *ngFor="let col of columns">
          <td *ngIf="col.name == 'result'" [ngStyle]="col.style">
            <div class="btn-wrapper">
              <button type="button" [id]="'remitRecRepDlBtn-'+index" class="btn btn-icon fa fa-file margin-right-6" title="Excel download" (click)="loadExcelExport(row.id)"></button>
              <button type="button" id="{{'addInfoBtn-'+index}}" class="btn btn-icon fa fa-info-circle" title="Additional Information" (click)="getInfo(row)"></button>
            </div>
          </td>
          <td *ngIf="col.name != 'result'" [ngStyle]="col.style">
            <span class="truncated-text">{{row[col.name]}}</span>
          </td>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>


</div>


