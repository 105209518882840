import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { Message } from 'primeng/api';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { HideConfirmation } from '@common/deals/deals.model';

@Component({
  selector: 'cms-hide-confirmation-dialog',
  templateUrl: './hide-confirmation-dialog.component.html',
  styleUrls: ['./hide-confirmation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HideConfirmationDialogComponent implements OnInit, OnChanges {
  @Input() display = false;
  @Input() data: { id: number, tradeId : string };
  @Input() messages: Message[] = [];
  @Output() displayChange = new EventEmitter<boolean>();
  @Output() cancel = new EventEmitter<Message[]>();
  @Output() confirm = new EventEmitter<HideConfirmation>();
  form: UntypedFormGroup;
  errors: {[key: string]: ValidationErrors} = {};

  constructor(private formBuilder: UntypedFormBuilder) { }

  onCancel(): void {
    this.hide();
  }

  onVisibleChange(visible: boolean): void {
    if (!visible) {
      this.onCancel();
    }
  }

  onSave(): void {
    const { reason } = this.form.value;
    const { id } = this.data;
    this.confirm.emit({ confirmationId: id, reason });
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      reason: ''
    });
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (changes.messages) {
      this.errors = {};
      if (this.messages) {
        this.messages.forEach((obj: Message) => {
          const { key: controlName } = obj;
          this.errors[controlName] = { 'server': true };
        });
      }
    }
  }

  private hide(): void {
    this.messages = [];
    this.display = false;
    this.displayChange.emit(this.display);
    this.form.reset();
    this.form.updateValueAndValidity();
    this.cancel.emit();
  }
}
