import { Message } from 'primeng/api';
import { Column } from '../../../../common/shared/datatable.model';
import { ErrorResponse } from '@common/error-response.model';

export interface ProductsModel {
  products: ProductsDatatableModel[];
  editable: boolean;
}

export interface ProductsDatatableModel {
  productId: number;
  transactionType: string;
  commodity: string;
  market: string;
  matchedBy: number;
  doWeMatch: boolean;
}

export interface ProductDataResponse extends ProductsModel, ErrorResponse { }

export interface DialogState {
  messages?: Message[];
  data?: any;
}

export interface ProductsBlockActivateResponse extends ErrorResponse {
  value: ProductsDatatableModel;
}

export const defaultStyles = {
  'text-align': 'left',
  'text-overflow': 'ellipsis',
  'overflow': 'hidden',
  'white-space': 'nowrap'
};

export let allColumnsConfig: Column[] = [
  { name: 'transactionType', type: 'text', title: 'Transaction Type', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'commodity', type: 'text', title: 'Commodity', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'market', type: 'text', title: 'Market', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'matchedBy', type: 'text', title: 'Matched by', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'doWeMatch', type: 'status', title: 'We match', style: { ...defaultStyles, 'text-align': 'center' }, sortable: false, visible: true },
  { name: 'productId', type: 'hidden', title: '', style: { ...defaultStyles }, sortable: false, visible: false }
];
