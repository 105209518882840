import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RemitTradeEntryTable1Service } from './remit-trade-entry-table1.service';
import { Message, MessageService } from 'primeng/api';
import { DateUtils } from '@common/shared/date.utils';
import { OrganisationService } from '@common/organisation.service';
import { Title } from '@angular/platform-browser';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'cms-remit-trade-entry-table1',
  templateUrl: './remit-trade-entry-table1.component.html',
  styleUrls: ['./remit-trade-entry-table1.component.scss'],
  providers: [MessageService]
})
export class RemitTradeEntryTable1Component implements OnInit, OnDestroy {

  tradeForm: UntypedFormGroup;

  showTradeEntryForm: boolean = false;
  submitEnabled: boolean = false;
  submitting: boolean = false;
  tradeEntryData: any = {};
  allFieldsValid: boolean;
  subscriptions: Subscription = new Subscription();
  acerV3DisabledMessage: Message[] = [];

  constructor(private formBuilder: UntypedFormBuilder,
              private tradeEntryService: RemitTradeEntryTable1Service,
              private organisationService: OrganisationService,
              private title: Title,
              private messageService: MessageService) {
    this.tradeForm = this.formBuilder.group({});
  }

  ngOnInit() {
    this.submitEnabled = false;
    this.allFieldsValid = false;
    this.title.setTitle('REMIT Table 1 Trade Entry');

    this.subscriptions.add(this.tradeEntryService.onMessageChange()
      .subscribe(errorMessages => {
        errorMessages ? this.messageService.add(errorMessages) : this.messageService.clear();
      }));

    this.checkAcerCodeAndLoadValues();

    this.subscriptions.add(this.organisationService.getOrganisationChangeIfAccessible()
      .subscribe(() => {
        this.resetAndInit(false);
        this.checkAcerCodeAndLoadValues();
      }));

    this.subscriptions.add(this.tradeEntryService.onTradeEntryDataChange()
      .subscribe(tradeEntryData => {
        this.tradeEntryData = tradeEntryData ?? {};
        this.submitEnabled = tradeEntryData?.submitEnabled ?? false;
        this.updateAcerV3Message();
      }));

    this.subscriptions.add(this.tradeEntryService.onValidationErrorsFieldNamesChanged()
      .subscribe(
        fieldName => {
          this.submitting = false;
          // TODO PB deliveryProfiles field : daysOfTheWeek0, daysOfTheWeek1 ...
          if (this.tradeForm.controls[fieldName]) {
            this.tradeForm.controls[fieldName].setErrors({ error: true });
          }
        }
      ));

    this.subscriptions.add(this.tradeEntryService.onSubmitted()
      .subscribe(
        response => {
          this.submitting = false;
          if (response) {
            this.tradeForm.controls['transactionDate'].reset();
            this.tradeForm.controls['transactionTime'].reset();
            this.tradeForm.controls['uti'].reset();
            this.tradeForm.controls['linkedTransactionId'].reset();

            this.tradeForm.controls['traderId'].reset();
            this.tradeForm.controls['marketParticipantCode'].reset();
            this.tradeForm.controls['marketParticipantCode'].setValue(this.tradeEntryData.marketParticipant);
            this.tradeForm.controls['buyOrSell'].reset();
            this.tradeForm.controls['beneficiaryCode'].reset();
            this.tradeForm.controls['beneficiaryCodeType'].setValue('ACE');

            this.tradeForm.controls['reportBothSides'].setValue(false);
            this.tradeForm.controls['otherMarketParticipantCodeType'].setValue('ACE');
            this.tradeForm.controls['otherMarketParticipantCode'].reset();
            this.tradeForm.controls['beneficiaryCodeTypeForOtherMarketParticipant'].setValue('ACE');

            this.tradeForm.controls['deliveryStartDate'].reset();
            this.tradeForm.controls['deliveryEndDate'].reset();
            this.tradeForm.controls['price'].reset();
            this.tradeForm.controls['notionalAmount'].reset();
            this.tradeForm.controls['quantity'].reset();
            this.tradeForm.controls['totalNotionalContractQuantity'].reset();
          }
        }
      ));

    this.subscriptions.add(this.tradeEntryService.onAllFieldsValidUpdated()
      .subscribe(allValid => this.allFieldsValid = allValid));
  }

  checkAcerCodeAndLoadValues() {
    this.tradeEntryService.hasAcerCode().then(
      hasAcerCode => {
        this.showTradeEntryForm = hasAcerCode;
        if (this.showTradeEntryForm) {
          this.messageService.clear();
          this.tradeEntryService.loadTradeEntryData({});
        } else {
          this.messageService.clear();
          const errorCode = 'No ACER code was found for your organisation which is mandatory for REMIT reporting. Please contact the CMS Service Team and have your ACER code registered in the CMS.';
          this.messageService.add({ severity: 'error', detail: errorCode });
        }
      }
    );
  }

  private updateAcerV3Message() {
    this.acerV3DisabledMessage = [{ severity: 'info', detail: 'Reporting ACER Remit Table 1 Version 3.' }];
  }

  public resetAndInit(byButton: boolean) {
    this.messageService.clear();
    this.tradeForm.reset();
    this.tradeEntryService.nextReset(true);
    this.setFormFieldValue('execution', false);
    this.setFormFieldValue('beneficiaryCodeType', 'ACE');
    this.setFormFieldValue('reportBothSides', false);
    this.setFormFieldValue('otherMarketParticipantCodeType', 'ACE');
    this.setFormFieldValue('beneficiaryCodeTypeForOtherMarketParticipant', 'ACE');

    if (this.tradeEntryData) {
      this.setFormFieldValue('marketParticipantCode', this.tradeEntryData.marketParticipant);
    }

    if (byButton) {
      this.checkAcerCodeAndLoadValues();
    }
    this.submitting = false;
  }

  // TODO PB refactor when there is time
  public submit() {
    this.messageService.clear();
    const transactionDate: Date = this.tradeForm.controls['transactionDate'].value;

    const time = this.tradeForm.controls['transactionTime'].value ? this.tradeForm.controls['transactionTime'].value.split(':') : null;
    if (transactionDate && time && time.length > 0) {
      transactionDate.setHours(parseInt(time[0], 10), parseInt(time[1], 10), parseInt(time[2], 10));
    }
    const transactionTime: string = (transactionDate) ? moment(transactionDate).format('YYYY-MM-DDTHH:mm:ss') : null;

    const startDate: Date = this.tradeForm.controls['deliveryStartDate'].value;
    const isoStartDate = DateUtils.toISOStringWithIgnoreTimezoneOffset(startDate);
    const endDate: Date = this.tradeForm.controls['deliveryEndDate'].value;
    const isoEndDate = DateUtils.toISOStringWithIgnoreTimezoneOffset(endDate);

    const exerciseDate: Date = this.tradeForm.controls['exerciseDate'].value;
    let isoExerciseDate = '';
    if (exerciseDate) {
      isoExerciseDate = DateUtils.toISOStringWithIgnoreTimezoneOffset(exerciseDate);
    }

    const searchQuery = {
      ...this.tradeForm.value,
      transactionTime: transactionTime,
      deliveryStartDate: isoStartDate,
      deliveryEndDate: isoEndDate,
      exerciseDate: isoExerciseDate
    };

    if (!this.submitting && this.submitEnabled) {
      this.submitting = true;
      this.tradeEntryService.submit(searchQuery);
    }
  }

  private setFormFieldValue(fieldName: string, value: any) {
    if (this.tradeForm.controls[fieldName]) {
      this.tradeForm.controls[fieldName].setValue(value);
    }
  }

  mapFieldNames(key: string): string {
    let fieldName: string;
    switch (key) {
      case 'linkedTransactionId':
        fieldName = 'Linked Transaction ID';
        break;
      case 'transactionDate':
        fieldName = 'Transaction time (UTC) - date part';
        break;
      case 'transactionTime':
        fieldName = 'Transaction time (UTC) - time part';
        break;
      case 'uti':
        fieldName = 'UTI';
        break;
      case 'contractType':
        fieldName = 'Contract Type';
        break;
      case 'energyCommodity':
        fieldName = 'Energy Commodity';
        break;
      case 'deliveryPointOrZone':
        fieldName = 'Delivery Point or Zone';
        break;
      case 'loadType':
        fieldName = 'Load Type';
        break;
      case 'settlementMethod':
        fieldName = 'Settlement Method';
        break;
      case 'priceCurrency':
        fieldName = 'Price Currency';
        break;
      case 'quantityUnit':
        fieldName = 'Quantity Unit';
        break;
      case 'optionPremiumCurrency':
        fieldName = 'Option Premium Currency';
        break;
      case 'optionQuantityUnit':
        fieldName = 'Option Premium Quantity Unit';
        break;
      case 'totalQuantityUnit':
        fieldName = 'Total Quantity Unit';
        break;
      case 'daysOfTheWeek':
        // TODO PB if one of the array controls is invalid entire array is invalid how to get fields ?
        //  -> start time
        //  -> end time
        fieldName = 'Days of the Week';
        break;
      case 'startTime':
        // TODO PB if one of the array controls is invalid entire array is invalid how to get fields ?
        //  -> start time
        //  -> end time
        fieldName = 'Start Time';
        break;
      case 'endTime':
        // TODO PB if one of the array controls is invalid entire array is invalid how to get fields ?
        //  -> start time
        //  -> end time
        fieldName = 'End Time';
        break;
      case 'marketParticipantCode':
        fieldName = 'Market Participant';
        break;
      case 'traderId':
        fieldName = 'Trader ID';
        break;
      case 'buyOrSell':
        fieldName = 'Buy / Sell';
        break;
      case 'otherMarketParticipantCode':
        fieldName = 'Other Market Participant';
        break;
      case 'traderIdForOtherMarketParticipant':
        fieldName = 'Trader ID (for other MP)';
        break;
      case 'deliveryStartDate':
        fieldName = 'Delivery Start';
        break;
      case 'deliveryEndDate':
        fieldName = 'Delivery End';
        break;
      case 'price':
        fieldName = 'Price';
        break;
      case 'notionalAmount':
        fieldName = 'Notional Amount';
        break;
      case 'quantity':
        fieldName = 'Quantity';
        break;
      case 'totalNotionalContractQuantity':
        fieldName = 'Total Notional Contract Quantity';
        break;
      case 'optionStyle':
        fieldName = 'Option Style';
        break;
      case 'optionType':
        fieldName = 'Option Type';
        break;
      case 'strikePrice':
        fieldName = 'Strike Price';
        break;
      case 'exerciseDate':
        fieldName = 'Exercise Date';
        break;
      default:
        fieldName = 'ERROR(\'' + key + '\')';
        break;
    }
    return fieldName;
  }

  ngOnDestroy(): void {
    this.tradeEntryService.clearTradeEntryFormData();
    this.subscriptions.unsubscribe();
  }
}
