import { createAction, union } from '@ngrx/store';
import { BillingRunParams, BillingRuns, StartBillingRun } from '../../../administration-tab-panel/billing-runs/cms/billing-runs.model';

export const deleteBillingRunAction = createAction('[BILLING RUNS] Delete Billing Run Data', (payload: number) => ({ payload }));
export const downloadZipAction = createAction('[BILLING RUNS] Download Zip Billing Run', (payload: number) => ({ payload }));
export const sendInvoiceMailsAction = createAction('[BILLING RUNS] Send Invoice Mails', (payload: number) => ({ payload }));
export const getBillingRunErrors = createAction('[BILLING RUNS] Get Billing Run Errors', (payload: BillingRuns) => ({ payload }));
export const getBillingRunParams = createAction('[BILLING RUNS] Get Billing Run Params');
export const setBillingRunParams = createAction('[BILLING RUNS] Set Billing Run Params', (payload: BillingRunParams) => ({ payload }));
export const setBillingRunPermission = createAction('[BILLING RUNS] Set Billing Run Permission', (payload: boolean) => ({ payload }));
export const setStartBillingRunModel = createAction('[BILLING RUNS] Set Start Billing Run Model', (payload: StartBillingRun) => ({ payload }));
export const startBillingRun = createAction('[BILLING RUNS] Start Billing Run', (payload: StartBillingRun) => ({ payload }));
export const getBillingRunStatus = createAction('[BILLING RUNS] Get Billing Run Status');
export const editBillingRunParams = createAction('[BILLING RUNS] Edit Billing Run Params', (payload: BillingRunParams) => ({ payload }));

const actions = union({
  deleteBillingRunAction,
  downloadZipAction,
  sendInvoiceMailsAction,
  getBillingRunErrors,
  getBillingRunParams,
  setBillingRunParams,
  getBillingRunStatus,
  setBillingRunPermission,
  editBillingRunParams,
  setStartBillingRunModel,
  startBillingRun
});

export type CmsBillingRunsActions = typeof actions;

