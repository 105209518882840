<p-messages id="change-password-growl" [(value)]="messages" [closable]="true"></p-messages>

<div *ngIf="!immediatelyScreen || (!changedSuccessfully && immediatelyScreen)"
     [ngClass]="{ 'center': immediatelyScreen}" class="row">
  <p-panel id="change-password-box" header="Change Your Password" [ngClass]="isIntegrated ? 'col-12 !max-w-[850px]' : '' " [styleClass]="'no-gutters account-box'">
    <div class="margin-15">
      <div id="info-password" *ngIf="!isImmediateChange()" class="col-12 info-panel margin-bottom-12"><span class="fa fa-info-circle padding-right-6"></span>Last password change: {{ getLastPasswordChangeDate() | date:'dd/MM/y' }} ({{ getPasswordExpirationDate() }} days left)</div>
      <form [formGroup]="form" class="container-fluid !px-0" (ngSubmit)="onSubmit()">
        <div class="row margin-bottom-21">
          <label class="col-5 !ps-[28px]">Current Password:
            <span class="text-required">*</span>
          </label>
          <div class="col-7">
            <input type="password" formControlName="currentPassword" class="form-control padding-6" [ngClass]="displayFieldCss('currentPassword')" maxlength="16" />
          </div>
        </div>
        <div class="margin-bottom-21">
          <p-panel header="Password Policy Requirements">
            New password must have <strong>between 8 to 16</strong> characters.<br />
            It must contain at least<br />
            <strong>one lower-case letter</strong>: a-z<br />
            <strong>one upper-case letter</strong>: A-Z<br />
            <strong>one number or special character</strong>: 0-9 $ ! + - _ . ,<br />
            Only the above characters are allowed.<br />
            New password must be different from your last 3 passwords.
          </p-panel>
        </div>
        <div class="row margin-bottom-12">
          <label class="col-5 !ps-[28px]">New Password:
            <span class="text-required">*</span>
          </label>
          <div class="col-7">
            <input type="password" formControlName="newPassword" class="form-control padding-6" [ngClass]="displayFieldCss('newPassword')" maxlength="16" />
          </div>
        </div>
        <div class="row margin-bottom-12">
          <label class="col-5 !ps-[28px]">New Password (repeat):
            <span class="text-required">*</span>
          </label>
          <div class="col-7">
            <input type="password" formControlName="newPasswordRepeat" class="form-control padding-6" [ngClass]="displayFieldCss('newPasswordRepeat')" maxlength="16" />
          </div>
        </div>
        <div class="margin-bottom-12 text-center">
          <p class="error-note" *ngIf="isPasswordRequiredError('currentPassword')">Field 'Current Password' is required.</p>
          <p class="error-note" *ngIf="isCurrentPasswordNotMeetThePasswordPolicy()">You entered the wrong password.</p>
          <p class="error-note" *ngIf="isPasswordRequiredError('newPassword')">Field 'New Password' is required.</p>
          <p class="error-note" *ngIf="isNewPasswordNotMeetThePasswordPolicy()">New password does not meet the password policy requirements.</p>
          <p class="error-note" *ngIf="isPasswordRequiredError('newPasswordRepeat')">Field 'New Password (repeat)' is required.</p>
          <p class="error-note" *ngIf="isNewPasswordsAreNotEqual()">Repeated password does not match.</p>
        </div>
        <div class="margin-bottom-12 flex justify-end">
          <ng-template [ngIf]="isImmediateChange()">
            <button type="button" id="btn-change-password-logout-immediate" *ngIf="showLogoutButton()" (click)="logout()" tabindex="6" class="btn margin-left-6 margin-right-6">Reject</button>
            <button type="submit" id="btn-change-password-submit-immediate" tabindex="5" class="btn">Confirm</button>
          </ng-template>
          <ng-template [ngIf]="!isImmediateChange()">
            <button type="submit" id="btn-change-password-submit-not-immediate" tabindex="5" class="btn">Update</button>
            <button type="button" id="btn-change-password-logout-not-immediate" *ngIf="showLogoutButton()" (click)="logout()" tabindex="6" class="btn margin-left-6 margin-right-6">Logout</button>
          </ng-template>
        </div>
      </form>
    </div>
  </p-panel>
</div>
<div class="center center-button" *ngIf="changedSuccessfully && immediatelyScreen">
  <h1>Password Changed Successfully</h1>
  <button class="btn" routerLink="/help/welcome">continue</button>
</div>
