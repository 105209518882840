import {Column} from '@common/shared/datatable.model';

export namespace ErrRemitDataTableRemitTable3Config {

  const defaultStyles = {
    'text-align': 'left',
    'text-overflow': 'ellipsis',
    'overflow': 'hidden',
    'white-space': 'nowrap',
    'height': '27px'
  };

  export const TABLE_COLUMNS: { [name: string]: Column } = {
    // Keep this and the following files aligned.
    // app.bsl/de.ponton.cmh.bsl.bslimpl/src/de/ponton/cmh/bsl/bslimpl/internal/service/userinterfacecustomisation/ErrRemitT3PersonalColumnEnum.java
    // app.gui/de.ponton.cmh.err.ui.data/src/de/ponton/cmh/err/ui/data/uio/remit/transactions/table3/ErrRemitTable3TransactionsUIO.properties
    // app.gui/de.ponton.cmh.err.ui.components/src/de/ponton/cmh/err/ui/components/remit/transactions/table3/columnorder/ErrRemitTable3ColumnOrderDataPanel.properties

    creationTimestamp: { title: 'Received by eRR', style: { 'width': '165px',  ...defaultStyles } },
    lastUpdate: { title: 'Last Update', style: { 'width': '165px',  ...defaultStyles } },
    senderName: { title: 'Sender Name', style: { 'width': '150px',  ...defaultStyles } },
    transactionTimestamp: { title: 'Creation Timestamp (UTC)', style: { 'width': '240px',  ...defaultStyles } },
    buttons: { title: 'Buttons', style: { 'width': '70px', 'text-align': 'center' }, sortable: false, hiddenTitle: true },
    state: { title: 'State', style: { 'width': '130px',  ...defaultStyles } },
    transactionIdentifier: { title: 'Unique ID', style: { 'width': '150px',  ...defaultStyles } },
    senderMpId: { title: 'Sender MP ID', style: { 'width': '150px',  ...defaultStyles } },
    receiverMpId: { title: 'Receiver MP ID', style: { 'width': '150px',  ...defaultStyles } },
    type: { title: 'Type', style: { 'width': '90px',  ...defaultStyles } },
    revisionNumber: { title: 'Revision', style: {  'text-align': 'right','white-space': 'nowrap' } },
    docStatusCode: { title: 'Doc Status Code', style: { 'width': '150px',  ...defaultStyles } },
    timeIntervalStart: { title: 'Time Interval Start (UTC)', style: { 'width': '200px',  ...defaultStyles } },
    timeIntervalEnd: { title: 'Time Interval End (UTC)', style: { 'width': '200px',  ...defaultStyles } },
    elcomLastUpdate: { title: 'ElCom Last Update', style: { 'width': '180px',  ...defaultStyles } },
    elcomState: { title: 'ElCom State', style: { 'width': '135px',  ...defaultStyles } },
    inputFilename: {
      title: 'Filename (Input File)',
      style: { 'width': '210px',  ...defaultStyles, 'cursor': 'help' },
      editable: true
    },
    reportedFilename: {
      title: 'Filename (Reported)',
      style: { 'width': '210px',  ...defaultStyles, 'cursor': 'help' },
      editable: true
    },
    price: { title: 'Price', style: { 'width': '100px', 'text-align': 'right' } },
    currency: { title: 'Cur.', longTitle: 'Price Currency', style: { 'width': '90px',  ...defaultStyles } },
    quantity: { title: 'Quantity', style: {  'text-align': 'right', 'white-space': 'nowrap' } },
    quantityUnit: { title: 'Unit', longTitle: 'Quantity Unit', style: { 'width': '100px',  ...defaultStyles } },

    // always hidden
    // dataStoreId: { title: 'DataStore Id', style: { 'width': '120px' }, visible: false },
  };
}
