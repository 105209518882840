import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Message } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { toPayload } from '@common/cms-common.model';
import { ApiRequestService } from '@common/api-request.service';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { ErrSendingFailuresState } from './err-sending-failures.reducers';
import {
  ErrSendingFailuresPresetModel,
  ErrSendingFailuresResendResponse,
  ErrSendingFailuresResponse,
  ErrSendingFailuresSearchRequest,
  ErrSendingFailuresSelected,
  getDatesWithoutTimezone
} from '../../err-sending-failures/err-sending-failures.model';
import * as fromActions from './err-sending-failures.actions';
import * as fromSelectors from './err-sending-failures.selectors';
import * as moment from 'moment';

@Injectable()
export class ErrSendingFailuresEffects {
  private baseUrl = '/api/problemsolving/err/sending/failures';

  constructor(private actions$: Actions,
              private apiGateway: ApiRequestService,
              private mapper: MessagesMapperService,
              private store$: Store<ErrSendingFailuresState>) { }

   getPresetValues$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.getPreset.type),
      switchMap(() =>
         this.apiGateway.get(`${this.baseUrl}/loadPresetValues`)
          .pipe(
            switchMap((response: ErrSendingFailuresPresetModel) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [fromActions.setMessages(messages)];
              }

              return [
                fromActions.onPresetSuccess(response),
                fromActions.setMessages([]),
              ];
            }),
            catchError(error => of(fromActions.setMessages([
              this.mapper.createErrorMessage(error.errorMessage)
            ]))))
      )));

   search$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.search.type),
      map(toPayload),
      switchMap((request: ErrSendingFailuresSearchRequest) => this.loadData(request))
    ));

   refresh$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.refresh.type),
        switchMap((action: ReturnType<typeof fromActions.refresh>) =>
          this.apiGateway.get(`${this.baseUrl}/loadPresetValues`)
          .pipe(
            switchMap((response: ErrSendingFailuresPresetModel) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [fromActions.setMessages(messages)];
              }

              const UTCDates = getDatesWithoutTimezone(response.from, response.to);

              return [
                fromActions.onPresetSuccess(response),
                fromActions.setMessages([]),
                fromActions.search({ processName: action.payload, start: response.from, end: response.to })
              ];
            }),
            catchError(error => of(fromActions.setMessages([
              this.mapper.createErrorMessage(error.errorMessage)
            ]))))
      )));

   reload$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.reload.type),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getFilters))),
        switchMap(([, { processName, start, end }]: [ReturnType<typeof fromActions.refresh>, ErrSendingFailuresSearchRequest]) => {
          const UTCDates = getDatesWithoutTimezone(start, end);
          return this.loadData( { processName, start: UTCDates.start, end: UTCDates.end });
        }
      )));

   checkResend$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.checkResend.type),
      withLatestFrom(this.store$.pipe(select(fromSelectors.isStartedState))),
      map(([, isStartedState]: [any, boolean]) =>
        isStartedState ? fromActions.openDialog({ name: 'RESEND' }) : fromActions.resend()
      )
    ));

   resend$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.resend.type),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.getSelectedItems)),
        this.store$.pipe(select(fromSelectors.getFilters)),
      ),
      switchMap(([, selectedItems, { start, end, processName }]: [any, ErrSendingFailuresSelected, ErrSendingFailuresSearchRequest]) => {
        const datesWithoutTimezone = getDatesWithoutTimezone(start, end);
        const selectedValues = Object.values(selectedItems);
        const request = { selectedValues, start: datesWithoutTimezone.start, end: datesWithoutTimezone.end };
        return this.apiGateway.post(`${this.baseUrl}/resend`, request)
          .pipe(
            switchMap((response: ErrSendingFailuresResendResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [fromActions.setMessages(messages)];
              }

              const errorMessages = this.mapper.arrayToErrorMessage(response.value.failureMessages);
              const successMessages = this.mapper.toSuccessMessages(response.value.successMessage);
              return [
                fromActions.setMessages(errorMessages),
                fromActions.setMessages(successMessages),
                fromActions.resendSuccess(),
                fromActions.search({ start: datesWithoutTimezone.start, end: datesWithoutTimezone.end, processName })
              ];
            }),
            catchError(error => of(fromActions.setMessages([
              this.mapper.createErrorMessage(error.errorMessage)
            ]))));
      })));

  private loadData(request: ErrSendingFailuresSearchRequest): Observable<Action> {
    const datesWithoutTimezone = getDatesWithoutTimezone(request['start'], request['end']);
    const pRequest = {...request,
                       'start': datesWithoutTimezone.start,
                       'end': datesWithoutTimezone.end};
    return this.apiGateway
      .post(`${this.baseUrl}/data`, pRequest)
      .pipe(
        switchMap((response: ErrSendingFailuresResponse) => {
          const messages: Message[] = this.mapper.toErrorMessages(response);
          const result: Action[] = [];
          if (messages && messages.length) {
            result.push(fromActions.setMessages(messages));
          } else {
            result.push(...[fromActions.searchSuccess(response.values)]);
          }
          return result;
        }),
        catchError(error => of(fromActions.setMessages([this.mapper.createErrorMessage(error.errorMessage)]))));
  }
}
