import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { Message } from 'primeng/api';
import { PartyDataEditableModel } from '../party-data.model';

@Component({
  selector: 'cms-party-data-add-panel',
  templateUrl: './party-data-add-panel.component.html',
  styleUrls: ['./party-data-add-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartyDataAddPanelComponent implements OnChanges, OnInit {
  @Input() messages: Message[] = [];
  @Input() data: PartyDataEditableModel;
  @Output() clear = new EventEmitter();
  @Output() confirm = new EventEmitter<PartyDataEditableModel>();

  form: UntypedFormGroup;
  errors: {[key: string]: ValidationErrors} = {};

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({ ...this.data });
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (changes.messages) {
      this.errors = {};
      this.messages.forEach((obj: Message) => {
        const { key: controlName } = obj;
        this.errors[controlName] = { 'server': true };
      });
    }
    if (this.form && changes.data) {
      this.form.patchValue({ ...this.data });
      this.form.updateValueAndValidity();
    }
  }

  onAdd({value}: {value: PartyDataEditableModel}) {
    this.confirm.emit({...value});
  }

  onClear(): void {
    this.clear.emit();
  }
}
