import { Column } from '@common/shared/datatable.model';
import { ErrorResponse } from '@common/error-response.model';
import { PagingFilter, SortingFilter } from '@common/shared/results.model';

const defaultStyles = {
  'text-align': 'left',
  'text-overflow': 'ellipsis',
  'overflow': 'hidden',
  'white-space': 'nowrap'
};

export const dataTableColumns: Column[] = [
  { name: 'partyCode', type: 'text', title: 'Party Code', style: { ...defaultStyles }, sortable: true, visible: true, maxLength: 20 },
  { name: 'displayName', type: 'editable', title: 'Display Name', style: { ...defaultStyles }, sortable: true, visible: true, maxLength: 20 },
  { name: 'isInternalParty', type: 'yes', title: 'Internal Party', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'delete', type: 'deleteButton', title: 'Delete', style: { ...defaultStyles, 'text-align': 'center' }, sortable: false, visible: true }
];

export interface PartyData {
  displayName: string;
  partyCode: string;
  isInternalParty: boolean;
}

export interface PartyDataEditableModel {
  displayName: string;
  partyCode: string;
}

export interface GetPartyDataRequest {
  filter: string;
  paging: PagingFilter;
  sorting: SortingFilter;
}

export interface GetPartyDataResponse extends ErrorResponse {
  values: PartyData[];
  count: number;
  currentPage: number;
}

export interface PartyDataEditableResponse extends ErrorResponse {
  value: PartyData;
}
