import { Pipe, PipeTransform } from '@angular/core';
import { DateUtils, TransformDateFormat } from '../../common/shared/date.utils';

@Pipe({
  name: 'dateFormatter'
})

export class DatePipe implements PipeTransform {

  transform(value: string, format: TransformDateFormat): string {
    return DateUtils.transformDateWithTimestamp(value, { format });
  }

}
