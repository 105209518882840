import { Injectable } from '@angular/core';
import { ApiRequestService } from '@common/api-request.service';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { Message } from 'primeng/api';
import {
  GetOrganisationGroupResponse,
  GetOrganisationGroupsRequest
} from '../../administration-tab-panel/organisation-groups/organisation-groups.model';
import {
  BillingRunQuartersResponse,
  BillingTxCountResponse,
  BillingTxGenerationResponse,
  BillingTxListResponse, OrganisationGroupListResponse,
} from '../../administration-tab-panel/billing-tx-report/billing-tx-report.models';

import * as fromActions from './billing-tx-report.actions';
import { BillingTxReportState } from './billing-tx-report.reducers';
import { Store } from '@ngrx/store';
import { saveBlob } from '@common/cms-common.model';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class BillingTxReportEffects {
  private TX_WARNING_AMOUNT = 1_000_000;

  private baseUrl = '/api/administration/acer/billingtxreport';
  private baseUrlOrgGroups = '/api/administration/organisations';
  private baseUrlBillingRuns = '/api/administration/acer/billingruns';

  constructor(
    private apiGateway: ApiRequestService,
    private actions$: Actions,
    private store: Store<BillingTxReportState>,
    private mapper: MessagesMapperService
  ) {
  }

  loadBillingRunQuarters = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.loadBillingRunQuarters.type),
      switchMap((action: ReturnType<typeof fromActions.loadBillingRunQuarters>) => this.apiGateway
        .post(`${this.baseUrlBillingRuns}/data`, action.payload)
        .pipe(
          map((response: BillingRunQuartersResponse) => {
            const messages: Message[] = this.mapper.toErrorMessages(response);
            if (messages && messages.length) {
              this.store.dispatch(fromActions.setMessagesAction(messages));
            }
            return fromActions.loadBillingRunQuartersSuccess(response);
          }),
          catchError(this.handleError)
        )
      ))
  );

  loadOrgGroupData$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.loadOrgGroupData.type),
      switchMap(() => this.apiGateway
        .get(`${this.baseUrlOrgGroups}/organisationGroups`, null)
        .pipe(
          map((response: OrganisationGroupListResponse) => {
            const messages: Message[] = this.mapper.toErrorMessages(response);
            if (messages && messages.length) {
              this.store.dispatch(fromActions.setMessagesAction(messages));
            }

            const { organisationGroups } = response;
            return fromActions.loadOrgGroupDataSuccess(organisationGroups);
          }),
          catchError(this.handleError)
        ))
    )
  );

  loadTxCount$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.loadTxCount.type),
      switchMap((action: ReturnType<typeof fromActions.loadTxCount>) => {
          return this.apiGateway
            .get(`${this.baseUrl}/count`, action.payload)
            .pipe(
              map(
                (response: BillingTxCountResponse) => {
                  const messages: Message[] = this.mapper.toErrorMessages(response);
                  if (messages && messages.length) {
                    this.store.dispatch(fromActions.setMessagesAction(messages));
                  }

                  return fromActions.loadTxCountSuccess({
                    overLimit: response.response >= this.TX_WARNING_AMOUNT,
                    noResults: response.response == 0,
                    submitPayload: action.payload
                  });
                }),
              catchError(this.handleError)
            )
        }
      )
    )
  );

  routeTxCountResultToSubmit = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(fromActions.loadTxCountSuccess.type),
        switchMap((action: ReturnType<typeof fromActions.loadTxCountSuccess>) => {
          const { overLimit, noResults, submitPayload } = action.payload;
          if (overLimit == false && noResults == false) {
            return of(fromActions.submitReportGeneration(submitPayload))
          } else {
            return EMPTY;
          }
        }),
      );
  });

  submitReportGeneration = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.submitReportGeneration.type),
      tap(() => this.store.dispatch(fromActions.setMessagesAction(this.mapper.toSuccessMessages('ACER Billing Transactions Report is being generated. Please check back later for the result.')))),
      switchMap((action: ReturnType<typeof fromActions.submitReportGeneration>) => this.apiGateway
        .post(`${this.baseUrl}/submitGenerateReport`, undefined, action.payload)
        .pipe(
          map((response: BillingTxGenerationResponse) => {
            const messages: Message[] = this.mapper.toErrorMessages(response);
            if (messages && messages.length) {
              this.store.dispatch(fromActions.setMessagesAction(messages));
            }
            return fromActions.submitReportGenerationSuccess(response);
          }),
          catchError(this.handleError)
        )),
    )
  );

  listBillingReports = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.listBillingReports.type),
      switchMap(() => this.apiGateway
        .get(`${this.baseUrl}/list`)
        .pipe(
          map((response: BillingTxListResponse) => {
            const messages: Message[] = this.mapper.toErrorMessages(response);
            if (messages && messages.length) {
              this.store.dispatch(fromActions.setMessagesAction(messages));
            }
            return fromActions.listBillingReportsSuccess(response);
          })
        )
      )
    ));

  downloadBillingReport = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.downloadBillingReport.type),
      switchMap((action: ReturnType<typeof fromActions.submitReportGeneration>) => this.apiGateway
        .getBlob(`${this.baseUrl}/download/csv`, undefined, action.payload)
        .pipe(
          switchMap((response: any) => {
            const messages: Message[] = this.mapper.toErrorMessages(response);
            if (messages && messages.length) {
              return of(fromActions.setMessagesAction(messages));
            } else {
              saveBlob(response);
            }
            return [fromActions.setMessagesAction([])];
          }),
          catchError(this.handleError)
        )
      )
    )
  );

  private handleError(error: HttpErrorResponse) {
    console.error(error);
    const errMsg = (error.message) ? error.message : 'Server error';
    return of(fromActions.setMessagesAction([this.mapper.createErrorMessage(errMsg)]));
  }
}
