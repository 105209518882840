import { Component, OnDestroy, OnInit } from '@angular/core';
import { LocationChangeEvent, LocationStrategy } from '@angular/common';
import { OrganisationService } from '@common/organisation.service';
import { OrganisationModel } from '@common/organisation.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RemitTransactionsSearchQuery } from '../datatable/remit-transactions.model';
import { RemitTransactionsService } from '../datatable/remit-transactions.service';
import { ErrorMessageService } from '@common/errors/error-message.service';

@Component({
  selector: 'cms-remit-dashboard',
  templateUrl: './remit-dashboard.component.html',
  styleUrls: ['./remit-dashboard.component.scss']
})
export class RemitDashboardComponent implements OnInit, OnDestroy {
  selectedOrganisation: OrganisationModel;
  private onDestroy$ = new Subject();

  constructor(private organisationService: OrganisationService,
              private remitTransactionsService: RemitTransactionsService,
              private locationStrategy: LocationStrategy,
              public readonly errorMessageService:ErrorMessageService) { }

  ngOnInit(): void {
    this.organisationService.getChangeOrganisationObservable()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        organisation => {
          this.selectedOrganisation = organisation;
        }
      );

    this.locationStrategy.onPopState((locationChangeEvent: LocationChangeEvent) => {
      const remitTransactionsSearchQuery: RemitTransactionsSearchQuery = this.remitTransactionsService.getCachedSearchQuery('REMIT_TABLE_1');
      if (remitTransactionsSearchQuery) {
        this.remitTransactionsService.emitNewSearchQuery(remitTransactionsSearchQuery);
      }
    });
  }

  getOrganisationName(): string {
    return this.selectedOrganisation ? ' - ' + this.selectedOrganisation.displayName : '';
  }

  public ngOnDestroy(): void {
    // Clean up all subscriptions at once:
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

}
