import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { ErrTerminationState } from '../../../state/remit/remit.reducer';
import * as RemitActions from '../../../state/remit/remit.actions';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'cms-termination-dialog',
  templateUrl: './termination-dialog.component.html',
  styleUrls: ['./termination-dialog.component.scss']
})
export class TerminationDialogComponent implements OnDestroy {

  notFound: any[];
  noPermission: any[];
  duplicates: any[];
  issues: any[];
  countFound: number;
  countDuplicate: number;
  countNotFound: number;
  countNoPermission: number;
  countIssue: number;
  identifier: number;
  completed: boolean;
  form: UntypedFormGroup;


  constructor(public config: DynamicDialogConfig,
              private store: Store<ErrTerminationState>,
              public ref: DynamicDialogRef,
              private formBuilder: UntypedFormBuilder,
              private mapper: MessagesMapperService) {

    const meta = config.data.csvMetaData;
    this.noPermission = meta.noPermission.map(label => ({ label }));
    this.notFound = meta.notFound.map(label => ({ label }));
    this.duplicates = meta.duplicates.map(label => ({ label }));
    this.issues = meta.issues.map(label => ({ label }));

    this.countFound = meta.countFound;
    this.countDuplicate = meta.countDuplicate;
    this.countNotFound = meta.countNotFound;
    this.countNoPermission = meta.countNoPermission;
    this.countIssue = meta.countIssue;
    this.identifier = meta.identifier;

    this.form = this.formBuilder.group({
      terminationDate: [null, Validators.required],
      date: [null, Validators.required],
      time: [null, Validators.required],
    });

  }


  onClose(){
    this.store.dispatch(RemitActions.abortCsvUpload({ id: this.identifier }));
    this.completed = true;
    this.ref.close();
  }

  onSubmit(){
    const { terminationDate, date, time } = this.form.value;


    this.store.dispatch(RemitActions.executeCsvUpload({
      terminationDate: terminationDate,
      transactionDate: date,
      transactionTime: this.buildDateTime(date, time),
      id: this.identifier
    }));


    this.store.dispatch(RemitActions.resetFilInput());
    this.store.dispatch(RemitActions.setMessages(this.mapper.toSuccessMessagesObj('Early Termination successfully triggered')));
    this.completed = true;
    this.ref.close();
  }

  ngOnDestroy(){
    if (!this.completed) {
      this.store.dispatch(RemitActions.abortCsvUpload({ id: this.identifier }));
    }
  }

  buildDateTime(date: Date, dateTime: string) {
    const time = dateTime.split(':');
    date.setHours(parseInt(time[0], 10), parseInt(time[1], 10), parseInt(time[2], 10));
    return date;
  }

  get toolTip() {
    return this.countFound > 0 ?
      'generate cancel records' :
      'You can not generate cancel records if there is no found entries';
  }
}

