<div class="panel-box container-fluid">
  <form [formGroup]="filenameForm" class="row mb-3">
    <div class="col-12 max-width">
      <div class="row g-0">
        <label class="col-auto label-wrap">Filename contains</label>
        <div class="col ps-0">
          <input id="input-filename-search" type="text" class="form-control p-component"
                 [maxLength]="255"
                 formControlName="filename">
        </div>
      </div>
    </div>
    <div class="col-12 col-md-2 ">
      <button class="btn form-control" style="width: 75px" [disabled]="filenameForm.invalid || disabled"
              (click)="onSearchByFilename()">Search
      </button>
    </div>
  </form>

  <form [formGroup]="filterForm" class="row">
    <div class="col-12 max-width">
      <div class="row">
        <div class="col-auto block-wrap">
          <label class="label-wrap">Document types</label>
          <p-multiSelect [options]="documentTypesOptions"
                         [filter]="false"
                         formControlName="documentTypes"
                         selectedItemsLabel="All ({0})"
                         placeholder="None"
                         [maxSelectedLabels]="1"
                         [style]="{ 'width': '150px'}">
          </p-multiSelect>
        </div>

        <div class="col-auto block-wrap">
          <label>Received from</label>
          <span class="me-1">
          <p-calendar placeholder="From Date"
                      formControlName="receivedFromDate"
                      [showIcon]="true"
                      [monthNavigator]="true"
                      [yearNavigator]="true"
                      dateFormat="dd/mm/yy"
                      [style]="{ 'width': '125px'}"
                      [title]="'Select a Date'"
                      cmsDateRestriction>
          </p-calendar>
        </span>
          <span class="me-1">
          <p-inputMask formControlName="receivedFromTime" mask="99:99:99"
                       pattern="(([0-1]?[0-9])|(2[0-3])):[0-5][0-9]:[0-5][0-9]" placeholder="hh:mm:ss"
                       slotChar="hh:mm:ss" styleClass="text-small padding-6 text-center"
                       [style]="{ 'width': '75px'}"></p-inputMask>
        </span>
        </div>

        <div class="col-auto block-wrap">
          <label>to</label>
          <span class="me-1">
          <p-calendar placeholder="to Date"
                      formControlName="receivedToDate"
                      [showIcon]="true"
                      [monthNavigator]="true"
                      [yearNavigator]="true"
                      dateFormat="dd/mm/yy"
                      [style]="{ 'width': '125px'}"
                      [title]="'Select a Date'"
                      cmsDateRestriction>
          </p-calendar>
        </span>
          <span class="me-1">
          <p-inputMask formControlName="receivedToTime" mask="99:99:99"
                       pattern="(([0-1]?[0-9])|(2[0-3])):[0-5][0-9]:[0-5][0-9]" placeholder="hh:mm:ss"
                       slotChar="hh:mm:ss" styleClass="text-small padding-6 text-center"
                       [style]="{ 'width': '75px'}"></p-inputMask>
        </span>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-2">
      <button class="btn form-control fa fa-refresh me-1" style="height: 32px; width: 32px; padding: 3px;"
              [title]="'Refresh Time and reset Document Types'"
              (click)="onRefresh()"></button>
      <button class="btn form-control"
              style="width: 75px"
              [disabled]="filterForm.invalid || disabled"
              (click)="onSearchByTimeFilter()">Search
      </button>
    </div>
  </form>
</div>
