<p-dialog *ngIf="display"
          header="Edit eCM Process Details"
          [resizable]="false"
          [visible]="display"
          [modal]="true"
          (visibleChange)="onVisibleChange($event)"
          [style]="{width: '650px', minWidth: '500px'}">
  <p-messages [value]="messages" [closable]="true"></p-messages>
  <div class="margin-15">
    <form [formGroup]="form" class="row" autocomplete="off">
      <div class="col-12 margin-bottom-12">
        <label for="processRole" class="col-4 float-left">eCM Process Role:<span class="required">*</span></label>
        <ng-container *ngIf="presetValues && presetValues.processRoles">
          <div class="process-role-container">
            <label *ngFor="let processRole of presetValues.processRoles.options" class="margin-left-12 radio-button">
              <input type="radio"
                     formControlName="processRole"
                     id="processRole"
                     class="margin-right-3"
                     [value]="processRole.value"
                     [ngClass]="{'error': errors['processRole']}"
                     name="processRole" />{{ processRole.label }}</label>
          </div>
        </ng-container>
      </div>
      <div class="col-12 margin-bottom-12 flex">
        <label for="partnerId" class="col-4 float-left">Partner ID:<span class="required">*</span></label>
        <div class="col-8">
          <input cmsUpperCase autocomplete="off" tabindex="1" id="partnerId" type="text" formControlName="partnerId" [ngClass]="{'error': errors['partnerId']}" class="ng-trim-ignore form-control col-8 padding-6" />
        </div>
      </div>
    </form>
  </div>
  <p-footer>
    <button class="btn margin-right-3" type="button" (click)="onCancel()">Cancel</button>
    <button class="btn" type="button" (click)="onConfirm()">Update</button>
  </p-footer>
</p-dialog>
