<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <label><span class="label text-bold header-font">Change Date: <span class="asterisk"></span></span>
    <p-calendar formControlName="changeDate"
                showIcon="true"
                monthNavigator="true"
                yearNavigator="true"
                dateFormat="dd/mm/yy"
                class="margin-right-30"
                keepInvalid="true"
                cmsDateRestriction>
    </p-calendar>
  </label>
  <button type="submit" class="btn" [disabled]="this.form.invalid">Go</button>
</form>
