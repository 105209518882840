import { EsmNettingDashboardTableType, EsmStatesEnum, esmStatesToLabelMaps } from '../../../netting/dashboard/esm-netting-dashboard.model';
import { EsmNettingDashboardTableState, tableInitialState, tableReducers } from './table/table.reducers';
import { EsmNettingDashboardFiltersState, filtersInitialState, filtersReducer } from './filters/filters.reducers';
import { DashboardDealsNavModel } from '@common/dashboard-deals-nav/dashboard-deals-nav.model';
import * as fromActions from './dashboard.actions';
import * as fromCommon from '@common/state/reducers';
import { NETTING_STATEMENT_DIALOGS } from '../../../state/netting/finder/dialog/dialog.reducers';
import { esmNettingStatementFinderDialogReducer } from '../../netting/finder/dialog/dialog.reducers';

export interface EsmNettingDashboardState {
  filters: EsmNettingDashboardFiltersState;
  table: EsmNettingDashboardTableState;
  viewType: EsmNettingDashboardTableType;
  state: EsmStatesEnum | string;
  stateNames: DashboardDealsNavModel[];
  dialog: fromCommon.DialogState<NETTING_STATEMENT_DIALOGS>;
}

export const initialState: EsmNettingDashboardState = {
  filters: filtersInitialState,
  table: tableInitialState,
  viewType: EsmNettingDashboardTableType.BUCKETS,
  state: null,
  stateNames: [],
  dialog: null
};

export function esmNettingDashboardReducer(
  state: EsmNettingDashboardState = { ...initialState },
  action: fromActions.NettingDashboardActions
): EsmNettingDashboardState {
  switch (action.type) {
    case (fromActions.setViewTypeAction.type):
      return {
        ...state,
        viewType: action.payload
      };
    case (fromActions.setStateAction.type):
      return {
        ...state,
        state: action.payload
      };
    case (fromActions.setStateNamesAction.type): {
      const data = action.payload || [];
      return {
      ...state,
          stateNames: data.map(item => (
          {
            state: item,
            title: esmStatesToLabelMaps[item],
          }
        ))
      };
    }
    case (fromActions.getStateNamesAction.type):
      return { ...state };
    case (fromActions.setToDefaultAction.type):
      return { ...initialState };
    default:
      return {
        ...state,
        table: tableReducers(state.table, action),
        filters: filtersReducer(state.filters, action),
        dialog: esmNettingStatementFinderDialogReducer(state.dialog, action),
      };
  }
}
