import * as moment from 'moment';
import { Message } from 'primeng/api';
import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { BillingRuns } from '../billing-runs.model';

@Component({
  selector: 'cms-delete-billing-runs-dialog',
  templateUrl: './delete-billing-runs-dialog.component.html',
  styleUrls: ['./delete-billing-runs-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteBillingRunsDialogComponent implements OnInit {
  @Input() display = false;
  @Input() data: BillingRuns;
  @Input() errorMessages: Message[];
  @Output() confirm = new EventEmitter<number>();
  @Output() cancel = new EventEmitter<Message[]>();
  header: string = '';
  contentMessages: Message[] = [{
    severity: 'warn',
    summary: '',
    detail: 'Confirm you wish to delete this entry.'
  }];


  constructor(private mapper: MessagesMapperService) { }

  ngOnInit(): void {
    this.generateModalheader();
  }

  private generateModalheader(): void {
    const date = moment(this.data.runDate).format('DD/MM/YYYY HH:mm:ss');
    this.header = `Deleting Billing Run ${this.data.billingMonth} ${this.data.draft ? '(draft)' : '(final)'} from ${date}`;
  }

  onCancel(): void {
    this.cancel.emit(this.mapper.toWarningMessages(`${this.header} was canceled.`));
  }

  onConfirm(): void {
    this.confirm.emit(this.data.billingRunId);
  }
}
