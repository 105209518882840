export interface BillingReportsParameters {
  reportType: ReportType;
  endDate: string;
  startDate: string;
}

export interface LoadingReport {
  ecm: boolean;
  err: boolean;
  rankingInternal: boolean;
  rankingPublic: boolean;
}

export type ReportType = 'ecm' | 'err' | 'rankingInternal' | 'rankingPublic';

export enum BillingReportsEnum {
  ecm = 'ecm',
  err = 'err',
  rankingInternal = 'ecm/ranking/internal',
  rankingPublic = 'ecm/ranking/public'
}
