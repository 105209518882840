<form [formGroup]="form">
  <div class="inline-block margin-right-15">
    <label>Start Date: <span class="ecm-reports__asterisk margin-right-6">*</span>
      <p-calendar formControlName="start"
                  class="inline-block"
                  showIcon="true"
                  dateFormat="dd/mm/yy"
                  monthNavigator="true"
                  yearNavigator="true"
                  cmsDateRestriction>
      </p-calendar>
    </label>
  </div>
  <div class="inline-block">
    <label>End Date: <span class="ecm-reports__asterisk margin-right-6">*</span>
      <p-calendar formControlName="end"
                  class="inline-block"
                  showIcon="true"
                  dateFormat="dd/mm/yy"
                  monthNavigator="true"
                  yearNavigator="true"
                  cmsDateRestriction>
      </p-calendar>
    </label>
  </div>
</form>
