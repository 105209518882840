import { createSelector } from '@ngrx/store';
import * as fromCommon from '@common/state/reducers';
import { getEsmNettingFinderState } from '../esm-netting-finder.selectors';
import { EsmNettingStatementFinderState } from '../esm-netting-finder.reducers';
import { EsmNettingStatementFinderMyFiltersState } from './my-filter.reducers';

export const getMyFilterState = createSelector(getEsmNettingFinderState,
  (state: EsmNettingStatementFinderState): EsmNettingStatementFinderMyFiltersState =>
  state.myFilterState);
export const getAllMyFilters = createSelector(getMyFilterState, fromCommon.getAllMyFilters);
export const getDefaultFilter = createSelector(getMyFilterState, fromCommon.getDefaultFilter);
export const getAllDealsFilter = createSelector(getMyFilterState, fromCommon.getAllDealsFilter);
