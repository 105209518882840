<div style="max-width: 585px;" class="commercials">
  <div class="form-title">Trade | Commercials</div>
  <div class="form-body" [formGroup]="formGroup">
    <div class="row gutter-0">
      <div class="col-5 label">Delivery Start <span class="text-required">*</span></div>
      <div class="col-7 ps-2 !pe-[42px]">
                <span id="remit-trade-entry-deliveryStartDate" class="inline-block padding-right-3 margin-3">
                    <p-calendar
                      formControlName="deliveryStartDate"
                      [showIcon]="true"
                      [monthNavigator]="true"
                      [yearNavigator]="true"
                      dateFormat="dd/mm/yy"
                      [style]="{ 'width': '125px'}"
                      [styleClass]="getValidationErrorStyleClass('deliveryStartDate')"
                      cmsDateRestriction>
                    </p-calendar>
                </span>
      </div>
      <span *ngIf="formGroup.controls['deliveryStartDate'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0">
      <div class="col-5 label">Delivery End <span class="text-required">*</span></div>
      <div class="col-7 ps-2 !pe-[42px]">
                <span id="remit-trade-entry-deliveryEndDate" class="inline-block padding-right-3 margin-3">
                    <p-calendar
                      formControlName="deliveryEndDate"
                      [showIcon]="true"
                      [monthNavigator]="true"
                      [yearNavigator]="true"
                      dateFormat="dd/mm/yy"
                      [style]="{ 'width': '125px'}"
                      [styleClass]="getValidationErrorStyleClass('deliveryEndDate')"
                      cmsDateRestriction>
                    </p-calendar>
                </span>
      </div>
      <span *ngIf="formGroup.controls['deliveryEndDate'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0">
      <div class="col-5 label">Price</div>
      <div class="col-5 ps-2">
        <input id="remit-trade-entry-price" type="text" maxlength="22" cmsDigitOnly class="form-control p-component padding-6 text-medium margin-3" formControlName="price" [ngClass]="{'error':formGroup.controls['price'].hasError('error')}">
      </div>
      <div class="col-2 ps-3 padding-top-9" *ngIf="priceCurrency">
        {{ priceCurrency }}
      </div>
      <span *ngIf="formGroup.controls['price'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0">
      <div class="col-5 label">Index Name</div>
      <div id="remit-trade-entry-indexName" class="col-7 ps-2 !pe-[44px]">
        <p-dropdown [options]="indexName" [filter]="true" [filterFields]="['label']" formControlName="indexName" [placeholder]="selectCommodityFirstPlaceholder(indexName)" [style]="{'width': '100%'}" [group]="true" [styleClass]="'margin-3 ' + getValidationErrorStyleClass('indexName')"></p-dropdown>
      </div>
      <span *ngIf="formGroup.controls['indexName'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0">
      <div class="col-5 label">Index Value</div>
      <div class="row col-7 ps-2 !pe-[20px]">
        <div class="col-8">
          <input id="remit-trade-entry-indexValue" type="text" cmsDigitOnly class="form-control p-component padding-6 text-medium margin-3"
                 [ngClass]="{'error':formGroup.controls['indexValue'].hasError('error')}" formControlName="indexValue" maxlength="22">
        </div>
        <div class="col-4">
          <p-dropdown [options]="indexCurrencies" [group]="true" formControlName="indexCurrency"
                      [styleClass]="'margin-3 full-width'"></p-dropdown>
        </div>
      </div>
      <span *ngIf="formGroup.controls['indexValue'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0">
      <div class="col-5 label">Notional Amount</div>
      <div class="col-5 ps-2">
        <input id="remit-trade-entry-notionalAmount" type="text" maxlength="22" cmsDigitOnly class="form-control p-component padding-6 text-medium margin-3" formControlName="notionalAmount" [ngClass]="{'error':formGroup.controls['notionalAmount'].hasError('error')}">
      </div>
      <div class="col-2 ps-3 padding-top-9" *ngIf="priceCurrency">
        {{ priceCurrency }}
      </div>
      <span *ngIf="formGroup.controls['notionalAmount'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0">
      <div class="col-5 label">Quantity <span class="text-required" *ngIf="!execution">*</span></div>
      <div class="col-5 ps-2">
        <input id="remit-trade-entry-quantity" type="text" cmsDigitOnly maxlength="22" class="form-control p-component padding-6 text-medium margin-3" formControlName="quantity" [ngClass]="{'error':formGroup.controls['quantity'].hasError('error')}">
      </div>
      <div class="col-2 ps-3 padding-top-9" *ngIf="quantityUnit">
        {{ quantityUnitValue }}
      </div>
      <span *ngIf="formGroup.controls['quantity'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0">
      <div class="col-5 label">Total Notional Contract Quantity <span class="text-required">*</span></div>
      <div class="col-5 ps-2">
        <input id="remit-trade-entry-totalNotionalContractQuantity" type="text" maxlength="22" cmsDigitOnly class="form-control p-component padding-6 text-medium margin-3" formControlName="totalNotionalContractQuantity" [ngClass]="{'error':formGroup.controls['totalNotionalContractQuantity'].hasError('error')}">
      </div>
      <div class="col-2 ps-3 padding-top-9" *ngIf="totalQuantityUnit">
        {{ totalQuantityUnitValue }}
      </div>
      <span *ngIf="formGroup.controls['totalNotionalContractQuantity'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
  </div>
</div>
