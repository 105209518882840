import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RoboDelegatorsService } from '../robo-delegators.service';
import * as FileSaver from 'file-saver';
import { takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { selectUploadAllowed, selectUploadPanelVisible } from './store/robo-delegators-upload.selectors';
import { GetPresetValues } from './store/robo-delegators-upload.actions';

@Component({
  selector: 'cms-robo-delegators-upload',
  templateUrl: './robo-delegators-upload.component.html',
  styleUrls: ['./robo-delegators-upload.component.scss']
})
export class RoboDelegatorsUploadComponent implements OnInit, OnDestroy {

  @ViewChild('fileUpload', { static: true })
  fileUpload: ElementRef;

  visible$: Observable<boolean>;
  uploadEnabled$: Observable<boolean>;
  private onDestroy$ = new Subject();
  public uploadForm: UntypedFormGroup;
  csvFile: File;
  fileName: string = '';
  fileSelected: boolean = false;

  constructor(private formBuilder: UntypedFormBuilder, private roboDelegatorsService: RoboDelegatorsService, private store: Store<any>) {
    this.uploadForm = formBuilder.group({
      csvFile: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.visible$ = this.store.pipe(
      select(selectUploadPanelVisible)
    );

    this.uploadEnabled$ = this.store.pipe(
      select(selectUploadAllowed)
    );

    this.store.dispatch(new GetPresetValues());

    this.roboDelegatorsService.onRefresh().pipe(takeUntil(this.onDestroy$)).subscribe(refresh => {
      if (refresh) {
        this.uploadForm.reset();
        this.fileSelected = false;
        this.csvFile = null;
        this.fileName = null;
      }
    });
  }

  onSelect(event): void {
    const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    this.roboDelegatorsService.setMessage(null);
    this.fileSelected = this.roboDelegatorsService.isValidFile(files[0]);
    if (this.fileSelected) {
      this.csvFile = files[0];
      this.fileName = this.csvFile.name;
    }
  }

  ngOnDestroy(): void {
    this.uploadForm.reset();
    this.fileSelected = false;
    this.csvFile = null;
    this.fileName = null;
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  submit() {
    this.roboDelegatorsService.sendFile(this.csvFile);
  }

  public download(): void {
    this.roboDelegatorsService.download()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(data => {
        FileSaver.saveAs(data.blob, data.filename);
      });
  }

  browseFiles() {
    this.reset();
    document.getElementById('robo-delegators-upload-button').click();

    // prevents page reload
    return false;
  }


  private reset(){
    if(this.fileUpload){
      this.fileUpload.nativeElement.value = null;
    }
    this.csvFile = null;
    this.fileName = '';
    this.fileSelected = false;
    this.roboDelegatorsService.setMessage(null);
  }

}
