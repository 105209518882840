import { createAction, props, union } from '@ngrx/store';
import { PresetValues, TerminateEntry, UploadMetaData } from '../../remit/termination/err.termination.model';
import { Message } from 'primeng/api';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { TerminationPreViewList } from './remit.reducer';

export const loading = createAction('[REMIT-TERMINATION] un/loading', props<{ load: { table?, upload?, dropDown? } }>());
export const loadPresets = createAction('[REMIT-TERMINATION] Load preset values');
export const loadPresetsSuccess = createAction('[REMIT-TERMINATION] Load preset values successfully', props<{ presets: PresetValues }>());
export const loadTable = createAction('[REMIT-TERMINATION] load table');
export const pagingAndSorting = createAction('[REMIT-TERMINATION] paging', props<{ sorting: SortingFilter, paging: PagingFilter }>());
export const querySuccess = createAction('[REMIT-TERMINATION] search successfully', props<{ data:DatatableData<TerminateEntry[]>, paging: PagingFilter, sorting: SortingFilter }>());
export const loadModalList = createAction('[REMIT-TERMINATION] Load Modal List', props<{ id: number, column: string }>());
export const loadModalListSuccess = createAction('[REMIT-TERMINATION] Load Modal List Success', props<{ list:TerminationPreViewList }>());
export const resetFilInput = createAction('[REMIT-TERMINATION] reset file input');
export const setMessages = createAction('[REMIT-TERMINATION] set messages', props<{ messages: Message[] }>());
export const resetMessages = createAction('[REMIT-TERMINATION] Reset Messages');
export const uploadCsv = createAction('[REMIT-TERMINATION] uploading csv', props<{ file: File }>());
export const uploadCsvSuccess = createAction('[REMIT-TERMINATION] upload csv successfully', props<{ metaData: UploadMetaData }>());
export const uploadCsvComplete = createAction('[REMIT-TERMINATION] upload csv successfully', props<{ metaData: UploadMetaData }>());
export const cleanCsvMeta = createAction('[REMIT-TERMINATION] clean CMS meta data');
export const abortCsvUpload = createAction('[REMIT-TERMINATION] cancel execution', props<{ id: number }>());
export const executeCsvUpload = createAction('[REMIT-TERMINATION] execute execution', props<{ id: number; terminationDate: Date; transactionDate: Date; transactionTime: Date; }>());
export const downloadCsv = createAction('[REMIT-TERMINATION] download csv', props<{ identifier: number }>());
export const downloadCsvSuccess = createAction('[REMIT-TERMINATION] download csv successfully');
export const downloadZip = createAction('[REMIT-TERMINATION] download zip', props<{ id: number }>());
export const downloadZipSuccess = createAction('[REMIT-TERMINATION] download zip successfully');
export const cleanState = createAction('[REMIT-TERMINATION] whole state clean');

const actions = union({
  loadPresets,
  loadPresetsSuccess,
  loadTable,
  pagingAndSorting,
  querySuccess,
  loadModalList,
  loadModalListSuccess,
  resetFilInput,
  setMessages,
  resetMessages,
  uploadCsv,
  uploadCsvSuccess,
  uploadCsvComplete,
  cleanCsvMeta,
  abortCsvUpload,
  executeCsvUpload,
  downloadCsv,
  downloadCsvSuccess,
  downloadZip,
  downloadZipSuccess,
  cleanState
});

export type RemitTerminationActions = typeof actions;

