import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Message } from 'primeng/api';
import { OrganisationModel } from '@common/organisation.model';
import { DialogState } from '@common/state/reducers';
import { Column } from '@common/shared/datatable.model';
import { OrganisationService } from '@common/organisation.service';
import {
  SendingFailuresResponse, SendingFailuresSearchRequest,
  SendingFailuresSelected, SendingFailuresValues
} from './sending-failures.model';
import * as fromReducers from '../state/sending-failures/sending-failures.reducers';
import * as fromActions from '../state/sending-failures/sending-failures.actions';
import * as fromSelectors from '../state/sending-failures/sending-failures.selectors';
import { allColumnsConfig } from './sending-failures.model';

@Component({
  selector: 'cms-sending-failures',
  templateUrl: './sending-failures.component.html',
  styleUrls: ['./sending-failures.component.scss']
})
export class SendingFailuresComponent implements OnInit, OnDestroy {
  filters$: Observable<SendingFailuresSearchRequest> = this.store.pipe(select(fromSelectors.getFilters));
  messages$: Observable<Message[]> = this.store.pipe(select(fromSelectors.getMessages));
  data$: Observable<SendingFailuresResponse> = this.store.pipe(select(fromSelectors.getData));
  initializing$: Observable<boolean> = this.store.pipe(select(fromSelectors.getInitializing));
  isSelected$: Observable<boolean> = this.store.pipe(select(fromSelectors.getIsSelected));
  selectedItems$: Observable<SendingFailuresSelected> = this.store.pipe(select(fromSelectors.getSelectedItems));
  loadingTable$: Observable<boolean> = this.store.pipe(select(fromSelectors.getLoadingTable));
  dialog$: Observable<DialogState<fromReducers.SF_DIALOGS>> = this.store.pipe(select(fromSelectors.getDialog));
  subscription$: Subscription;
  readonly tableColumns: Column[] = allColumnsConfig;

  constructor(public readonly organisationService: OrganisationService,
              private store: Store<fromReducers.SendingFailuresState>) { }

  ngOnInit(): void {
    this.store.dispatch(fromActions.setOrganisationId(this.organisationService.getCachedOrganisationId()));

    this.store.dispatch(fromActions.getPreset());

    this.subscription$ = this.organisationService.getChangeOrganisationObservable()
      .pipe(withLatestFrom(this.store.pipe(select(fromSelectors.getOrganisationId))))
      .subscribe(([organisation, organisationId]: [OrganisationModel, number]) => {
        if ((organisation && organisation.organisationId !== organisationId) || (!organisation && organisationId)) {
          this.store.dispatch(fromActions.setToDefault());
          this.store.dispatch(fromActions.setOrganisationId(organisation ? organisation.organisationId : null));
        }
      });
  }

  onSearch(request: SendingFailuresSearchRequest): void {
    this.store.dispatch(fromActions.search(request));
  }

  onRefresh(): void {
    this.store.dispatch(fromActions.refresh());
  }

  onDetailing(data: string): void {
    this.store.dispatch(fromActions.openDialog({ name: 'INFO' , data }));
  }

  onCloseDialog(): void {
    this.store.dispatch(fromActions.closeDialogAction());
  }

  onResend(): void {
    this.store.dispatch(fromActions.resend());
  }

  onCheck(checked: boolean, row: SendingFailuresValues, rowIndex: number): void {
    this.store.dispatch(checked ? fromActions.select({ [rowIndex]: row }) : fromActions.deselect({ [rowIndex]: row }));
  }

  onToggleSelectAll(toggled: boolean): void {
    this.store.dispatch(fromActions.toggleSelectAll(toggled));
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
    this.store.dispatch(fromActions.setToDefault());
  }
}
