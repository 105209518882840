import { BehaviorSubject, Observable, throwError as observableThrowError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../auth/authentication.service';
import { OrganisationService } from '../organisation.service';
import { PagingFilter, SortOrder } from '../shared/results.model';
import { AudittrailDatatableModel } from './audittrail-datatable.model';
import { EndPointConstants } from '../end-point-constants';
import { catchError, map } from 'rxjs/operators';

export class AudittrailEndPointConstants {
  static CONFIRMATION_ID = 'confirmationId';
  static DATA_STORE_ID = 'dataStoreId';
  static DATA_STORE_ID_TO_DOWNLOAD = 'dataStoreIdToDownload';
  static HISTORY_ID = 'historyId';
  static AUDIT_GROUP_ID = 'auditGroupId';
  static EXT_ID = 'extId';
  static DOCUMENT_TYPE = 'documentType';
  static AUDIT_ENTRY_ID = 'auditEntryId';
  static FILE_ID = 'fileId';
  static ID = 'id';
  static INVOICE_ID = 'invoiceId';
  static NETTING_ID ='nettingId';
  static SENDER_ORG_EIC_CODE = 'senderOrgEicCode';
}

export type AudittrailUrlPart = 'cpml' | 'remit' | 'remit_filesin' | 'mifid';

// DocumentType currently has the same values as RemitDocumentType, however it is not limited to those.
// i.e. it could be extended in case other DocumentType parameters are needed
export type AuditTrailDocumentType =
  'REMIT_TABLE_1'
  | 'REMIT_TABLE_2'
  | 'ELECTRICITY_RIGHTS'
  | 'GAS_CAPACITY'
  | 'FUNDAMENTALS'
  | 'REMITLNG'
  | 'REMITStorage';

@Injectable()
export class AudittrailService {

  private searchQuerySubject = new BehaviorSubject<AudittrailSearchQuery>(null);

  constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService, private organisationService: OrganisationService) {
  }

  private static buildNextSearchQuery(pagingFilter: PagingFilter, sortOrder: SortOrder, confirmationId: number, dataStoreId: number, historyId: number, auditGroupId: number, documentType: AuditTrailDocumentType, messagingDetails: boolean, processingDetails: boolean, fileId: number, id: number): AudittrailSearchQuery {
    const query: AudittrailSearchQuery = AudittrailSearchQuery.createEmptySearchQuery();
    if (sortOrder !== null) {
      query.sortOrder = sortOrder;
    }

    if (pagingFilter !== null) {
      query.paging = pagingFilter;
    }
    query.confirmationId = confirmationId;
    query.dataStoreId = dataStoreId;
    query.historyId = historyId;
    query.auditGroupId = auditGroupId;
    query.documentType = documentType;
    query.messagingDetails = messagingDetails;
    query.processingDetails = processingDetails;
    query.fileId = fileId;
    query.id = id;
    return query;
  }

  getCurrentSearchQuery(): AudittrailSearchQuery {
    return this.searchQuerySubject.getValue();
  }

  private getFilenameFromHeader(headers): string {
    const contentDisposition = headers.get('content-disposition');
    return contentDisposition.split(';')[1].trim().split('=')[1];
  }

  private handleError(error: HttpErrorResponse) {
    const errMsg = (error.message) ? error.message : 'Server error';
    return observableThrowError(errMsg);
  }

  public fetchAuditTrail(searchQuery: AudittrailSearchQuery, urlPart: AudittrailUrlPart): Observable<AudittrailDatatableModel> {


    let params = new HttpParams();
    if (this.organisationService.getCachedOrganisationId()) {
      params = params.append(EndPointConstants.PARAM_ORGANISATION_ID, this.organisationService.getCachedOrganisationId().toString());
    }

    const options = { params };

    if (urlPart.match('.*fileUpload')){ // /api/esm/fileupload/
      return this.httpClient.post<AudittrailDatatableModel>(`/api/audittrail/${urlPart}`, searchQuery, options);
    }else {
      return this.httpClient.post<AudittrailDatatableModel>(`/api/audittrail/${urlPart}/trail`, searchQuery, options);
    }
  }

  public getSearchQueryChangeObservable(): Observable<AudittrailSearchQuery> {
    return this.searchQuerySubject.asObservable();
  }

  public updateSearchQueryByPagingAndSortingFilter(
    pagingFilter: PagingFilter,
    sortOrder: SortOrder,
    confirmationId: number,
    dataStoreId: number,
    historyId: number,
    auditGroupId: number,
    documentType: AuditTrailDocumentType,
    messagingDetails: boolean,
    processingDetails: boolean,
    fileId: number,
    id: number) {
    const nextSearchQuery = AudittrailService.buildNextSearchQuery(pagingFilter, sortOrder, confirmationId, dataStoreId, historyId, auditGroupId, documentType, messagingDetails, processingDetails, fileId, id);
    this.searchQuerySubject.next(nextSearchQuery);
  }

  updateSearchQuery(searchQuery: AudittrailSearchQuery): void {
    this.searchQuerySubject.next(searchQuery);
  }

  public downloadAuditTrail(): Observable<{ filename: string, blob: Blob }> {
    const nextSearchQuery = this.searchQuerySubject.getValue();

    let params = new HttpParams();
    if (this.organisationService.getCachedOrganisationId()) {
      params = params.append(EndPointConstants.PARAM_ORGANISATION_ID, this.organisationService.getCachedOrganisationId().toString());
    }
    const options = { observe: 'response' as 'body', params, responseType: 'blob' as 'json' };
    return this.httpClient.post(`/api/audittrail/${nextSearchQuery.urlPart}/txt`, nextSearchQuery, options)
      .pipe(map((response: HttpResponse<Blob>) => {
        const filename = this.getFilenameFromHeader(response.headers);
        const blob: Blob = response.body;
        return { filename, blob };
      }), catchError(this.handleError));
  }

  updateMessagingDetails(showMessagingDetails: boolean): void {
    const searchQuery = this.searchQuerySubject.getValue();
    searchQuery.messagingDetails = showMessagingDetails;
    searchQuery.paging.page = 0;
    this.searchQuerySubject.next(searchQuery);
  }

  updateProcessingDetails(showProcessingDetails: boolean): void {
    const searchQuery = this.searchQuerySubject.getValue();
    searchQuery.processingDetails = showProcessingDetails;
    searchQuery.paging.page = 0;
    this.searchQuerySubject.next(searchQuery);
  }

}

export class AudittrailSearchQuery {
  confirmationId?: number; // id for ecm/dealfinder
  dataStoreId?: number;
  historyId?: number; // id for REMIT T1/T2/T3/T4/Fundamentals
  auditGroupId?: number; // remit files in, id for CpML
  extId?: number;
  fileId?: number;
  id?: number;
  documentType?: AuditTrailDocumentType; // category (for REMIT: T1-T5)
  messagingDetails: boolean;
  processingDetails?: boolean;
  urlPart?: AudittrailUrlPart;
  sortOrder: SortOrder;
  paging: PagingFilter;
  senderOrgEicCode?: string;
  invoiceId?: number; // id for esm file upload
  nettingId?: number;

  public static createEmptySearchQuery(): AudittrailSearchQuery {
    return {
      confirmationId: undefined,
      dataStoreId: undefined,
      historyId: undefined,
      auditGroupId: undefined,
      documentType: undefined,
      extId: undefined,
      fileId: undefined,
      id: undefined,
      messagingDetails: false,
      processingDetails: false,
      paging: {
        entriesPerPage: 25,
        page: 0
      },
      sortOrder: 'DESCENDING',
    };
  }
}
