import {SmtDealFinderTransactionsService} from '../dealfinder/smt-dealfinder-transactions.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {Subject} from 'rxjs';

import {
  SmtRegimeFilter,
  SmtReportingStatusFilter,
  smtReportingStatusFilterAsString,
  SmtTransactionDay,
  smtTransactionDayAsString,
  StmTransactionDashboardFilterParameters
} from '../smt-filter-model';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'cms-smt-dashboard-dataview',
  templateUrl: './smt-dashboard-dataview-component.html',
  styleUrls: ['./smt-dashboard-dataview-component.scss'],
})
export class SmtDashboardDataViewComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject();
  parameters: StmTransactionDashboardFilterParameters;

  constructor(private transactionsService: SmtDealFinderTransactionsService, private route: ActivatedRoute) {
  }

  public ngOnInit(): void {
    this.route.queryParams.pipe(
      takeUntil(this.onDestroy$))
      .subscribe((params: Params) => {
        this.parameters = {
          regime: SmtRegimeFilter[<string>params['regime']],
          status: SmtReportingStatusFilter[<string>params['status']],
          reporter: params['reporter'],
          reporterId: params['reporterId'],
          day: SmtTransactionDay[<string>params['day']]
        };
        this.transactionsService.updateSearchQueryByDashboardFilters(this.parameters);
      });
  }

  getStatusOutput(status: SmtReportingStatusFilter) {
    return smtReportingStatusFilterAsString(status);
  }

  getTransactionDayOutput(transactionDay: SmtTransactionDay) {
    return smtTransactionDayAsString(transactionDay);
  }

  getReporterOutput(reporter: string) {
    return reporter || 'All';
  }

  public ngOnDestroy(): void {
    // Clean up all subscriptions at once:
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

}
