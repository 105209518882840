import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChange } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { Message } from 'primeng/api';
import { UnhideConfirmation } from '@common/deals/deals.model';

@Component({
  selector: 'cms-unhide-confirmation-dialog',
  templateUrl: './unhide-confirmation-dialog.component.html',
  styleUrls: ['./unhide-confirmation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnhideConfirmationDialogComponent implements OnChanges {
  @Input() display = false;
  @Input() data: { id: number, tradeId : string };
  @Input() messages: Message[] = [];
  @Output() displayChange = new EventEmitter<boolean>();
  @Output() cancel = new EventEmitter<Message[]>();
  @Output() confirm = new EventEmitter<UnhideConfirmation>();
  errors: {[key: string]: ValidationErrors} = {};

  onCancel(): void {
    this.hide();
  }

  onVisibleChange(visible: boolean): void {
    if (!visible) {
      this.onCancel();
    }
  }

  onSave(): void {
    this.confirm.emit({confirmationId: this.data.id});
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (changes.messages) {
      this.errors = {};
      if (this.messages) {
        this.messages.forEach((obj: Message) => {
          const { key: controlName } = obj;
          this.errors[controlName] = { 'server': true };
        });
      }
    }
  }

  private hide(): void {
    this.messages = [];
    this.display = false;
    this.displayChange.emit(this.display);
    this.cancel.emit();
  }
}
