import { createSelector } from '@ngrx/store';
import { getDashboardState } from '../dashboard.selectors';
import { EsmDashboardFiltersState } from './filters.reducers';
import { EsmDashboardState } from '../dashboard.reducers';

export const getFilters = createSelector(getDashboardState, (state: EsmDashboardState) => state.filters);
export const getFiltersValues = createSelector(getFilters, (state: EsmDashboardFiltersState) => state.value);
export const getCommoditiesValues = createSelector(getFilters, (state: EsmDashboardFiltersState) => state.commodities);
export const getCounterPartiesValues = createSelector(getFilters, (state: EsmDashboardFiltersState) => state.counterParties);
export const getDeliveryPointsValues = createSelector(getFilters, (state: EsmDashboardFiltersState) => state.deliveryPoints);
export const getInvoiceTypesValues = createSelector(getFilters, (state: EsmDashboardFiltersState) => state.invoiceTypes);
export const getInvoiceRoleValue = createSelector(getFilters, (state: EsmDashboardFiltersState) => state.invoiceRole);
export const getDateRange = createSelector(getFilters, (state: EsmDashboardFiltersState) => state.dateRange);
export const getDateRangeText = createSelector(getDashboardState, (state: EsmDashboardState) => state.table.dateRangeText);

