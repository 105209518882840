import { Column } from '@common/shared/datatable.model';
import { ErrorResponse } from '@common/error-response.model';
import { userStates } from '../common/editable-status-cell/editable-status-cell.component';

export const defaultStyles = {
  'text-align': 'left',
  'text-overflow': 'ellipsis',
  'overflow': 'hidden',
  'white-space': 'nowrap',
  'height': '27px'
};

const stateStyle = {'padding-top' : '0px', 'padding-bottom' : '0px', ...defaultStyles};




export const allColumnsConfig: Column[] = [
  { name: 'userName', type: 'text', title: 'User Name', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'realName', type: 'text', title: 'Real Name', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'state', type: 'userState', title: 'User State', style: { ...stateStyle }, sortable: true, visible: true }
];

export interface FhaAccount {
  id: string;
  userName: string;
  firstName: string;
  secondName: string;
  email: string;
  phone: string;
  mobile: string;
  jobTitle: string;
  contactOther: string;
  state: userStates;
  tokenId: string;
  realName: string;
}

export interface FhaAccountsResponse extends ErrorResponse {
  values: FhaAccount[];
}

export interface ChangeUserResponse extends ErrorResponse {
  value: { message: string, changedUser: FhaAccount };
}

export interface AddEditUserResponse extends ErrorResponse {
  value: FhaAccount;
}

export interface ResetPasswordResponse extends ErrorResponse {
  response: string;
}
