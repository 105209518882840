import { Message } from 'primeng/api';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import {
  EcmDealFinder,
  DealFinderPropertiesFilters,
  DealFinderIdFilters, FilterBy, EcmMyFilter
} from '../../deal-finder/deal-finder.model';
import { Column } from '@common/shared/datatable.model';
import { EditableCell } from '@common/datatable/cell-editor/cell-editor.model';
import * as fromAction from './deal-finder.actions';
import * as moment from 'moment';
import { DialogState, HiddenDeals, Sender } from '@common/deals/deals.model';
import { AllDealsMyFilterName } from '@common/my-filters/my-filters.model';

export interface EcmDealFinderState {
  columns: Column[];
  selected: EcmDealFinder[];
  editableData: EditableCell;
  noFoundIds: string[];
  data: DatatableData<EcmDealFinder[]>;
  messages: Message[];
  idFilters: DealFinderIdFilters;
  propFilters: DealFinderPropertiesFilters;
  filterBy: FilterBy;
  paging?: PagingFilter;
  sorting?: SortingFilter;
  loading: boolean;
  broker: string[];
  commodities: string[];
  counterParties: string[];
  markets: string[];
  transactionTypes: string[];
  defaultFilter: EcmMyFilter;
  dialog: DialogState;
  confirmationIds: string[];
  filterSelections: {[key: string]: EcmMyFilter };
  allDealsFilter: EcmMyFilter;
  dealFinderHtml: Blob;
  terminationRights: boolean;
  editNotesRights: boolean;
}

export const defaultByPropertiesFilters: DealFinderPropertiesFilters = {
  brokers: [],
  commodities: [],
  counterParties: [],
  markets: [],
  transactionTypes: [],
  tradeDateRange: {
    start: null,
    end: null
  },
  submissionDateRange: {
    start: moment().subtract(19, 'days').toDate(),
    end: null
  },
  senderType: Sender.US,
  price: null,
  hiddenDeals: HiddenDeals.EXCLUDE,
  deliveryEndDate: null,
  deliveryStartDate: null,
};

export const initialState: EcmDealFinderState = {
  terminationRights: false,
  editNotesRights:false,
  selected: null,
  editableData: null,
  columns: [],
  noFoundIds: [],
  idFilters: {
    ourTradeId: '',
    theirTradeId: '',
    documentId: '',
  },
  propFilters: {
    ...defaultByPropertiesFilters
  },
  filterBy: null,
  data: {
    values: [],
    count: 0
  },
  messages: [],
  loading: false,
  paging: {
    page: 0,
    entriesPerPage: 25
  },
  sorting: {
    columnName: 'submissionDate',
    sortOrder: 'DESCENDING'
  },
  broker: [],
  commodities: [],
  counterParties: [],
  markets: [],
  transactionTypes: [],
  dialog: null,
  confirmationIds: [],
  defaultFilter: null,
  filterSelections: null,
  allDealsFilter: {
    defaultFilter: true,
    hiddenType: HiddenDeals.EXCLUDE,
    filterName: AllDealsMyFilterName,
    brokerFilter: [],
    counterpartyFilter: [],
    commodityFilter: [],
    marketFilter: [],
    transactionTypeFilter: []
  },
  dealFinderHtml: null
};

export function ecmDealFinderReducer(state: EcmDealFinderState = { ...initialState },
                                 action: fromAction.EcmDealFinderActions): EcmDealFinderState {
  switch (action.type) {
    case fromAction.SET_HTML:
      return {
        ...state,
        dealFinderHtml: action.payload
      };
    case fromAction.SET_SELECTED_CONFIRMATION_IDS: {
      return {
        ...state,
        confirmationIds: action.payload.confirmationIds

      };
    }
    case fromAction.LOAD_PRESETS_SUCCESS: {
      return {
        ...state,
        terminationRights: action.rights.termination,
        editNotesRights: action.rights.notes,
      };
    }
    case fromAction.SET_SELECTED_DEALS: {
      return {
        ...state,
        selected: action.payload.deals

      };
    }
    case fromAction.ADD_SELECTED_CONFIRM_ID: {
      return {
        ...state,
        confirmationIds: [
          ...state.confirmationIds,
          action.payload.confirmationId
        ]
      };
    }
    case fromAction.REMOVE_SELECTED_CONFIRM_ID: {
      const index = state.confirmationIds.indexOf(action.payload.confirmationId);
      const confirmationIds = [
        ...state.confirmationIds.slice(0, index),
        ...state.confirmationIds.slice(index + 1, state.confirmationIds.length)
      ];
      return {
        ...state,
        confirmationIds
      };
    }
    case fromAction.SELECT:
      const selected: any = (action as fromAction.SelectAction).payload;
      let editableData = state.editableData;
      if (editableData && selected && selected !== state.editableData.data) {
        editableData = null;
      }
      return {
        ...state,
        selected,
        editableData
      };
    case fromAction.CLEAN_BY_IDS: {
      return {
        ...state,
        messages: [],
        noFoundIds: [],
        idFilters: {
          ourTradeId: '',
          theirTradeId: '',
          documentId: '',
        },
      };
    }
    case fromAction.SET_COLUMNS:
      return {
        ...state,
        columns: (action as fromAction.SetColumnsAction).payload
      };
    case fromAction.DELETE_MY_FILTER_SUCCESS: {
      const { filterName } = action.payload;
      const defaultFilter = state.defaultFilter && state.defaultFilter.filterName === filterName ? null : state.defaultFilter;
      let filterSelections = null;
      if (state.filterSelections) {
        filterSelections = {};
        Object.entries(state.filterSelections).forEach(entry => {
          const [key, value] = entry;
          if (key !== filterName) {
            filterSelections[key] = value;
          }
        });
      }
      return {
        ...state,
        defaultFilter,
        filterSelections
      };
    }
    case fromAction.GET_FILTERS_VALUES_SUCCESS:
      const {
        broker = [],
        commodities = [],
        counterParties = [],
        markets = [],
        transactionTypes = [],
        submissionDate
      } = (action as fromAction.GetFiltersValuesSuccessAction).payload;
      return {
        ...state,
        broker,
        commodities,
        counterParties,
        markets,
        transactionTypes,
        propFilters: {
          ...state.propFilters,
          brokers: broker,
          commodities,
          counterParties,
          markets,
          submissionDateRange : {start: submissionDate.start ? new Date(submissionDate.start) : null, end: submissionDate.end ? new Date(submissionDate.end) : null},
          transactionTypes
        }
      };
    case fromAction.LOAD_DATA: {
      return {
        ...state,
        loading: true,
        confirmationIds: [],
        messages: [],
        noFoundIds: [],
      };
    }
    case fromAction.FILTER: {
      const {
        ourTradeId,
        theirTradeId,
        documentId,
        properties,
        filterBy
      } = action.payload;
      return {
        ...state,
        loading: true,
        confirmationIds: [],
        messages: [],
        noFoundIds: [],
        idFilters: filterBy && filterBy !== 'dealProperties' ? {
          ourTradeId,
          theirTradeId,
          documentId,
        } : state.idFilters,
        propFilters: filterBy && filterBy === 'dealProperties' ? {
          ...properties
        } : state.propFilters,
        filterBy
      };
    }
    case fromAction.UPDATE_FILTERS: {
      const { filterBy, properties } = action.payload;
      return {
        ...state,
        propFilters: filterBy && filterBy === 'dealProperties' ? {
          ...properties
        } : state.propFilters,
      };
    }
    case fromAction.SORT_AND_PAGE_DATA:
      return {
        ...state,
        noFoundIds: [],
        loading: true,
        messages: [],
        confirmationIds: [],
        paging: (action as fromAction.SortAndPageDataAction).payload.paging,
        sorting: (action as fromAction.SortAndPageDataAction).payload.sorting
      };
    case fromAction.FILTER_DATA_SUCCESS:
      const payload = (action as fromAction.FilterDataSuccessAction).payload;
      return {
        ...state,
        loading: false,
        data: {
          count: payload.count,
          values: payload.values
        },
        paging: {
          ...state.paging,
          page: payload.currentPage
        },
        noFoundIds: payload.notFoundIds
      };
    case fromAction.SET_MESSAGES:
      return {
        ...state,
        loading: false,
        messages: [...(action as fromAction.SetMessagesAction).payload],
      };
    case fromAction.LOAD_MY_FILTERS_SUCCESS: {
      return {
        ...state,
        ...action.payload
      };
    }
    case fromAction.TERMINATE_CONFIRM_SUCCESS:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          data: {
            closeButton: true
          }
        }
      };
    case fromAction.CONFIRM_ECM_LITE_CANCEL:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          data: {
            ...state.dialog.data,
            loadingDialog: true,
            closeButton: false
          }
        }
      };
    case fromAction.CONFIRM_ECM_LITE_CANCEL_SUCCESS:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          data: {
            loadingDialog: false,
            closeButton: true
          }
        }
      };
    case fromAction.OPEN_DIALOG:
      return {
        ...state,
        dialog: { ...action.payload }
      };
    case fromAction.UPDATE_DIALOG:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          messages: action.payload
        }
      };
    case fromAction.CLOSE_DIALOG:
      return {
        ...state,
        dialog: null
      };
    case fromAction.SET_TO_DEFAULT:
      return {...initialState};
    case fromAction.SET_TABLE_DATA_TO_DEFAULT:
      return {
        ...state,
        filterBy: null,
        data: {
          values: [],
          count: 0,
        },
        messages: [],
        dialog: null,
        selected: null,
        loading: false,
      };
    default:
      return state;
  }
}

export const getData = (datatableState: EcmDealFinderState): DatatableData<EcmDealFinder[]> => datatableState.data;
export const getSorting = (state: EcmDealFinderState) => state.sorting;
export const getPaging = (state: EcmDealFinderState) => state.paging;
export const getLoading = (state: EcmDealFinderState) => state.loading;
export const getMessages = (state: EcmDealFinderState) => state.messages;
export const getIdFilters = (state: EcmDealFinderState) => state.idFilters;
export const getPropFilters = (state: EcmDealFinderState) => state.propFilters;
export const getFilterBy = (state: EcmDealFinderState) => state.filterBy;

export const getBroker = (state: EcmDealFinderState) => state.broker;
export const getTransactionTypes = (state: EcmDealFinderState) => state.transactionTypes;
export const getMarkets = (state: EcmDealFinderState) => state.markets;
export const getCounterParties = (state: EcmDealFinderState) => state.counterParties;
export const getCommodities = (state: EcmDealFinderState) => state.commodities;
export const getNoFoundIds = (state: EcmDealFinderState) => state.noFoundIds;
export const getColumns = (state: EcmDealFinderState) => state.columns;
export const getSelected = (state: EcmDealFinderState) => state.selected;
export const getDialog = (state: EcmDealFinderState) => state.dialog;
export const getMyFilters = (state: EcmDealFinderState): EcmMyFilter[] =>
  state.filterSelections ? Object.values(state.filterSelections) : null;
export const getDefaultFilter = (state: EcmDealFinderState): EcmMyFilter => state.defaultFilter;
export const getConfirmationIds = (state: EcmDealFinderState) => state.confirmationIds;
export const getAllDealsFilter = (state: EcmDealFinderState) => state.allDealsFilter;
export const getDealFinderHtml = (state: EcmDealFinderState) => state.dealFinderHtml;
export const getDealEditRights = (state: EcmDealFinderState) => state.terminationRights;
export const getDealNoteRights = (state: EcmDealFinderState) => state.editNotesRights;


export const getIdsCount = (state: EcmDealFinderState) => {
  const { filterBy, idFilters: { theirTradeId, ourTradeId, documentId }} = state;
  switch (filterBy) {
    case 'theirTradeId':
      return theirTradeId ? theirTradeId.replace('↵', ' ')
        .replace(/\s+/g, ' ').split(' ').length : 0;
    case 'ourTradeId':
      return ourTradeId ? ourTradeId.replace('↵', ' ')
        .replace(/\s+/g, ' ').split(' ').length : 0;
    case 'documentId':
      return documentId ? documentId.replace('↵', ' ')
        .replace(/\s+/g, ' ').split(' ').length : 0;
  }
};

