import { createSelector } from '@ngrx/store';
import { getAdminState, State } from '../../reducers';
import { AcerBillingRunsState } from './acer-runs.reducers';

const getAcerBillingRunsState = createSelector(getAdminState, (state: State) => state.billingRuns.acerBillingRuns);

export const getTableData = createSelector(getAcerBillingRunsState, (acerBillingRuns: AcerBillingRunsState) => acerBillingRuns.data);
export const getLoading = createSelector(getAcerBillingRunsState, (acerBillingRuns: AcerBillingRunsState) => acerBillingRuns.loading);
export const getPaging = createSelector(getAcerBillingRunsState, (acerBillingRuns: AcerBillingRunsState) => acerBillingRuns.paging);
export const getSorting = createSelector(getAcerBillingRunsState, (acerBillingRuns: AcerBillingRunsState) => acerBillingRuns.sorting);
export const getMessages = createSelector(getAcerBillingRunsState, (acerBillingRuns: AcerBillingRunsState) => acerBillingRuns.messages);
export const getErrors = createSelector(getAcerBillingRunsState, (acerBillingRuns: AcerBillingRunsState) => acerBillingRuns.errors);
export const getPresets = createSelector(getAcerBillingRunsState, (acerBillingRuns: AcerBillingRunsState) => acerBillingRuns.presets);
