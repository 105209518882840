import { createAction, props, union } from '@ngrx/store';
import { NettingStatementData } from '../../../netting-preview/generation-dialog/generation-dialog.model';
import { InvoiceDivEntry } from '../../../netting-preview/netting-table-overview/netting-table-overview.model';

export const openDialog = createAction('[ESM-NTT-PREVIEW] open confirmation dialog', props<{ selected: InvoiceDivEntry }>());
export const checkCondition = createAction('[ESM-NTT-PREVIEW] checking condition', (payload: InvoiceDivEntry) => ({payload}));
export const openDialogSuccess = createAction('[ESM-NTT-PREVIEW] validate and load netting statement success', props<{ data: NettingStatementData }>());
export const generateNetting = createAction('[ESM-NTT-PREVIEW] confirm to generate netting statement', props<{ selected: InvoiceDivEntry }>());
export const closeDialog = createAction('[ESM-NTT-PREVIEW] close confirmation dialog');
export const setConfirmationGeneration = createAction('[ESM-NTT-PREVIEW] Setting confirmation response', (payload: any) => ({payload}));

const actions = union({
  openDialog,
  generateNetting,
  closeDialog,
  checkCondition,
  setConfirmationGeneration
});

export type GenerationDialogActions = typeof actions;

