<h1>List FHA Accounts</h1>
<div class="panel-box">
  <p-messages [value]="messages$ | async" [closable]="true"></p-messages>
  <div>
    <cms-datatable *ngIf="(data$ | async) as data"
                   [totalRecords]="data.values.length"
                   [scrollable]="false"
                   [autoLayout]="true"
                   [tableColumns]="dataTableColumns"
                   [data]="data.values"
                   [selected]="selected$ | async"
                   [lazy]="false"
                   (selectedChange)="onUserAccountSelected($event)"
                   sortOrder="ASCENDING"
                   sortField="userName"
                   selectionMode="single">
      <ng-template pTemplate="body" let-row="row" let-column="column">
        <td *ngIf="column.type === 'userState'" [ngStyle]="column.style" [ngClass]="'editable-text'" cmsCellEditor>
          <cms-editable-status-cell [state]="row[column.name]"
                                    [row]="row"
                                    [selected]="editableData$ | async"
                                    (cancelEdit)="onCancelEdit()"
                                    (initEdit)="onInitEdit($event)"
                                    (editComplete)="onEditComplete($event)">
          </cms-editable-status-cell>
        </td>
      </ng-template>
    </cms-datatable>
    <div class="padding-0 col-lg-12 col-12 btn__container margin-top-3 flex justify-content-end align-items-center">
      <button class="btn margin-top-3 margin-bottom-3 margin-left-12"
              type="button"
              [disabled]="!((selected$ | async)?.state === 'ACTIVE')"
              (click)="onResetPassword()">
        Reset Password
      </button>
      <button class="btn margin-top-3 margin-bottom-3 margin-left-12"
              type="button"
              [disabled]="!(selected$ | async)"
              (click)="onEditUserAccount()">
        Edit User Account
      </button>
      <button class="btn margin-top-3 margin-bottom-3 margin-left-12"
              type="button"
              (click)="onAddUserAccount()">
        Add User Account
      </button>
    </div>
  </div>
</div>
<cms-dialog-container [editableData]="editableData$ | async"
                      [selected]="selected$ | async"
                      [dialog]="dialog$ | async"
                      [tokenIdVisible]="tokenIdVisible"
                      (reset)="onConfirmResetPassword($event)"
                      (cancel)="onCancelDialog($event)"
                      (reactivate)="onConfirmReactivate($event)"
                      (inactivate)="onConfirmInactivate($event)"
                      (lock)="onConfirmLock($event)"
                      (edit)="onConfirmEditUserAccount($event)"
                      (add)="onConfirmAddUser($event)">
</cms-dialog-container>
