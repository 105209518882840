import {
  AcerMailBillingRunsActions,
  cleanState,
  loading,
  loadTableSuccess,
  resetMessages,
  setMessages,
  setPagingAndSorting,
} from './table.actions';
import { ActionReducerMap, createFeatureSelector, createReducer, on } from '@ngrx/store';
import { Message } from 'primeng/api';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { TableEntry } from '../table/table.component';

import { InjectionToken } from '@angular/core';


export const getAcerMailBillingTable = createFeatureSelector<State>('acer_billing_mail');

export interface State {
  messages: Message[];
  loading: boolean ;
  paging: PagingFilter;
  sorting: SortingFilter;
  data: DatatableData<TableEntry[]>;
  errors: { name: string, code: string }[];
}


export const reducerToken = new InjectionToken<ActionReducerMap<State>>(
  'Acer Billing Mail Report Reducers'
);


export const initialMailBillingRunsState: State = {
  messages:[],
  errors:[],
  loading:false ,
  paging: { page: 0, entriesPerPage: 5 },
  sorting: { columnName: 'orgGroupName', sortOrder: 'DESCENDING' },
  data: { values: [], currentPage: 0, count: 10 }
};



export const getReducers = () => acerBillingRunsReducer;

const acerBillingRunsReducer = createReducer<State, AcerMailBillingRunsActions>(
  initialMailBillingRunsState,

  on(cleanState, ( ) => ({
    ...initialMailBillingRunsState
  })),

  on(loading, (state, { load }) => ({
    ...state,
    loading: load
  })),

  on(setMessages, (state, { messages }) => ({
    ...state,
    messages: messages
  })),

  on(resetMessages, (state) => ({
    ...state,
    messages: []
  })),

  on(setPagingAndSorting, (state, { paging, sorting }) => ({
    ...state,
    paging: paging,
    sorting: sorting,
  })),

  on(loadTableSuccess, (state, { data }) => ({
    ...state,
    data: data,
    paging: { ...state.paging, page: data.currentPage }
  })),

);
