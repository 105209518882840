import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { Message } from 'primeng/api';
import { HttpParams } from '@angular/common/http';
import { ApiRequestService } from '@common/api-request.service';
import { MessagesMapperService } from '@common/messages-mapper.service';
import {
  CreateSnapshotResponse,
  GetLogsResponse,
  GetMonitoringDataResponse,
  GetStatesResponse,
  MonitoringCategories,
  MonitoringDataTableItem
} from '../../monitoring/monitoring.model';
import * as fromActions from './monitoring.actions';
import * as fromSelectors from './monitoring.selectors';
import { select, Store } from '@ngrx/store';
import * as fromMonitoring from './monitoring.reducers';
import { MonitoringState } from './monitoring.reducers';
import { of } from 'rxjs';
import { ErrorResponse } from '@common/error-response.model';
import { Column } from '@common/shared/datatable.model';

export const defaultStyles = {
  'width': '140px', 'text-align': 'left',
  'text-overflow': 'ellipsis',
  'overflow': 'hidden',
  'white-space': 'nowrap'
};

@Injectable()
export class MonitoringEffects {
  private baseUrl = '/api/ecm/monitoring';

  constructor(private actions$: Actions,
              private store$: Store<MonitoringState>,
              private apiGateway: ApiRequestService,
              private mapper: MessagesMapperService) { }

   doneChecking$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.doneCheckingAction.type),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.getChangeDate))
      ),
      concatMap(([action, date]: [ReturnType<typeof fromActions.doneCheckingAction>, string]) => {
        const { snapshotId, category } = action.payload;
        let param = new HttpParams();
        param = param.set('snapshotId', snapshotId.toString());
        param = param.set('category', category);
        return this.apiGateway.post(`${this.baseUrl}/check`, null, param)
          .pipe(
            concatMap((response: any | ErrorResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [fromActions.setMessagesAction(messages)];
              }

              return [
                fromActions.setMessagesAction([]),
                fromActions.setToDefaultAction(),
                fromActions.getStatesAction(date),
              ];
            })
          );
      })
    ));

  stateSuccess$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.getStatesSuccessAction.type),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getCurrentCategory()))),
      switchMap(([action, category]: [ReturnType<typeof fromActions.getStatesSuccessAction>, MonitoringCategories]) => {
        const { changeDate, categories } = action.payload;
        if (changeDate && categories && categories.length) {
          return [
            fromActions.getLogsAction(),
            fromActions.loadDataAction({ category })
          ];
        }
        return [];
      })
    ));

   loadData$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.loadDataAction.type),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getChangeDate))),
      switchMap(([action, date]: [ReturnType<typeof fromActions.loadDataAction>, string]) => {
        const { category } = action.payload;
        let param = new HttpParams();
        param = param.set('category', category);
        param = param.set('date', date);
        return this.apiGateway.get(`${this.baseUrl}/data`, param)
          .pipe(
            switchMap((response: GetMonitoringDataResponse) => {
              const messages: Message[] = [];
              const { value } = response;
              if (value) {
                const { accessValidationFailures, technicalErrors, validationFailures, fieldValidationFailures} = response.value;
                messages.push(
                  ...this.mapper.objectToErrorMessages(fieldValidationFailures),
                  ...this.mapper.arrayToErrorMessage(accessValidationFailures),
                  ...this.mapper.arrayToErrorMessage(technicalErrors),
                  ...this.mapper.arrayToErrorMessage(validationFailures),
                );
              }
              if (messages && messages.length) {
                return [fromActions.setMessagesAction(messages)];
              }

              const { timePoints = [], data = [] } = value;
              const columns: Column[] = [
                { name: 'organisationName', type: 'text', title: 'Organisation Name', style: { ...defaultStyles }, sortable: true, visible: true },
                { name: 'eicCode', type: 'text', title: 'EIC Code', style: { ...defaultStyles }, sortable: true, visible: true },
                ...timePoints.map(item => (
                  {
                    name: item,
                    type: 'text',
                    title: item,
                    style: { ...defaultStyles, 'text-align' : 'right', 'width': '70px' },
                    sortable: false,
                    visible: true
                  }
                )),
                { name: 'button', type: 'button', title: '', style: { ...defaultStyles, 'width': '50px', 'text-align': 'center' }, sortable: false, visible: true },
              ];

              const datatableItems: MonitoringDataTableItem[] = data.map(item => {
                const { eicCode, organisationName, trades = []} = item;
                return {
                  organisationName,
                  eicCode,
                  ...trades
                } as MonitoringDataTableItem;
              });

              return [
                fromActions.setMessagesAction([]),
                fromActions.setColumnsAction(columns),
                fromActions.loadDataSuccessAction(datatableItems)
              ];
            })
          );
      })
    ));

   getLogs$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.getLogsAction.type),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getEcmMonitoringState))),
      switchMap(([, state]: [any, fromMonitoring.MonitoringState]) => {
        const { changeDate, categories } = state;
        if (changeDate && categories && categories.length) {
          let param = new HttpParams();
          param = param.set('requestDate', changeDate);
          return this.apiGateway.get(`${this.baseUrl}/getAuditLogs`, param)
            .pipe(switchMap((response: GetLogsResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [fromActions.setMessagesAction(messages)];
              }

              return [
                fromActions.setMessagesAction([]),
                fromActions.getLogsSuccessAction(response.values)
              ];
            }));
        }
        return [];
      }),
      catchError(error =>
        of(fromActions.setMessagesAction([
          this.mapper.createErrorMessage(error.errorMessage)
        ])))
    ));

   createSnapshot$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.createSnapshotAction.type),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getChangeDate))),
      concatMap(([_, date]: [any, string]) => {
        return this.apiGateway.post(`${this.baseUrl}/createSnapshot`)
          .pipe(
            concatMap((response: CreateSnapshotResponse) => {
              let messages: Message[] = this.mapper.toErrorMessages(response);
              const valueMessages = response.value && response.value.errorMessage ? this.mapper.createErrorMessage(response.value.errorMessage) : null;

              if (messages && messages.length) {
                return [
                  fromActions.setMessagesAction(messages),
                  fromActions.createSnapshotLoadingOffAction()
                ];
              }

              // BE will be fixed for error messages later (info by Knut)
              if (valueMessages) {
                messages = [];
                messages.push(valueMessages);
                return [
                  fromActions.setMessagesAction(messages),
                  fromActions.createSnapshotLoadingOffAction()
                ];
              }

              return [
                fromActions.setMessagesAction([]),
                fromActions.createSnapshotLoadingOffAction(),
                fromActions.setToDefaultAction(),
                fromActions.getStatesAction(date),
              ];
            }),
            catchError(error => of(fromActions.setMessagesAction([
              this.mapper.createErrorMessage(error.errorMessage)
            ])))
          );
      })));

   getStates$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.getStatesAction.type),
      switchMap((action: ReturnType<typeof fromActions.getStatesAction>) => {
        const changeDate = action.payload;
        let params = new HttpParams();
        params = changeDate ? params.set('changeDate', changeDate) : null;
        return this.apiGateway.get(`${this.baseUrl}/states`, params)
          .pipe(
            switchMap((response: GetStatesResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [fromActions.setMessagesAction(messages)];
              }

              return [
                fromActions.setMessagesAction([]),
                fromActions.getStatesSuccessAction(response.value)
              ];
            }),
            catchError(() => of(fromActions.setMessagesAction([
              this.mapper.createErrorMessage(changeDate === 'Invalid date' ? 'Enter a valid date in format dd/mm/yyyy' : 'Server error')
            ])))
          );
      })));
}
