<div style="max-width: 585px;" class="delivery-profile">
  <div class="form-title">Contract | Delivery Profile</div>
  <div class="form-body" [formGroup]="formGroup">
    <div formArrayName="deliveryProfiles">
      <div class="div-delivery-profile" formGroupName="0">
        <div class="row gutter-0">
          <div class="col-5 label">Days of the Week 1</div>
          <div class="col-7 ps-2 !pe-[42px]">
            <input type="text" class="form-control p-component padding-6 text-medium margin-3" formControlName="daysOfTheWeek" maxlength="6">
          </div>
        </div>
        <div class="row gutter-0">
          <div class="col-5 label">Start Time 1<span class="text-required"> *</span></div>
          <div class="col-7 ps-2 !pe-[42px]">
                        <span id="remit-trade-entry-startTime1" class="inline-block padding-left-3">
                            <p-inputMask formControlName="startTime" mask="99:99:99" pattern="(([0-1]?[0-9])|(2[0-4])):[0-5][0-9]:[0-5][0-9]" placeholder="hh:mm:ss" slotChar="hh:mm:ss" [styleClass]="'text-small padding-6 text-center' + getValidationErrorStyleClass(0, 'startTime')" [style]="{ 'width': '75px'}" (change)="onStartTimeChange();"></p-inputMask>
                             <span *ngIf="getValidationErrorStyleClass(0, 'startTime')" class="fa fa-info-circle error-marker padding-3"></span>
                        </span>
          </div>
        </div>
        <div class="row gutter-0">
          <div class="col-5 label">End Time 1<span class="text-required"> *</span></div>
          <div class="col-7 ps-2 !pe-[42px]">
                        <span id="remit-trade-entry-endTime1" class="inline-block padding-left-3">
                            <p-inputMask formControlName="endTime" mask="99:99:99" pattern="(([0-1]?[0-9])|(2[0-4])):[0-5][0-9]:[0-5][0-9]" placeholder="hh:mm:ss" slotChar="hh:mm:ss" [styleClass]="'text-small padding-6 text-center' + getValidationErrorStyleClass(0, 'endTime')" [style]="{ 'width': '75px'}" (change)="onEndTimeChange();"></p-inputMask>
                             <span *ngIf="getValidationErrorStyleClass(0, 'endTime')" class="fa fa-info-circle error-marker padding-3"></span>
                        </span>
          </div>
        </div>
      </div>
      <div *ngIf="componentCount >= 1" class="div-delivery-profile" formGroupName="1">
        <div class="row gutter-0">
          <div class="col-5 label">Days of the Week 2</div>
          <div class="col-7 ps-2 !pe-[42px]">
            <input type="text" class="form-control p-component padding-6 text-medium margin-3" formControlName="daysOfTheWeek" maxlength="6">
          </div>
        </div>
        <div class="row gutter-0">
          <div class="col-5 label">Start Time 2<span class="text-required"> *</span></div>
          <div class="col-7 ps-2 !pe-[42px]">
                        <span id="remit-trade-entry-input-startTime2" class="inline-block padding-left-3">
                            <p-inputMask formControlName="startTime" mask="99:99:99" pattern="(([0-1]?[0-9])|(2[0-3])):[0-5][0-9]:[0-5][0-9]" placeholder="hh:mm:ss" slotChar="hh:mm:ss" [styleClass]="'text-small padding-6 text-center' + getValidationErrorStyleClass(1, 'startTime')" [style]="{ 'width': '75px'}" (change)="onStartTimeChange();"></p-inputMask>
                            <span *ngIf="getValidationErrorStyleClass(1, 'startTime')" class="fa fa-info-circle error-marker padding-3"></span>
                        </span>
          </div>
        </div>
        <div class="row gutter-0">
          <div class="col-5 label">End Time 2<span class="text-required"> *</span></div>
          <div class="col-7 ps-2 !pe-[42px]">
                        <span id="remit-trade-entry-input-endTime2" class="inline-block padding-left-3">
                            <p-inputMask formControlName="endTime" mask="99:99:99" pattern="(([0-1]?[0-9])|(2[0-4])):[0-5][0-9]:[0-5][0-9]" placeholder="hh:mm:ss" slotChar="hh:mm:ss" [styleClass]="'text-small padding-6 text-center' + getValidationErrorStyleClass(1, 'endTime')" [style]="{ 'width': '75px'}" (change)="onEndTimeChange();"></p-inputMask>
                            <span *ngIf="getValidationErrorStyleClass(1, 'endTime')" class="fa fa-info-circle error-marker padding-3"></span>
                        </span>
          </div>
        </div>
      </div>
      <div *ngIf="componentCount === 2" class="div-delivery-profile" formGroupName="2">
        <div class="row gutter-0">
          <div class="col-5 label">Days of the Week 3</div>
          <div class="col-7 ps-2 !pe-[42px]">
            <input type="text" class="form-control p-component padding-6 text-medium margin-3" formControlName="daysOfTheWeek" maxlength="6">
          </div>
        </div>
        <div class="row gutter-0">
          <div class="col-5 label">Start Time 3<span class="text-required"> *</span></div>
          <div class="col-7 ps-2 !pe-[42px]">
                        <span id="remit-trade-entry-startTime3" class="inline-block padding-left-3">
                            <p-inputMask formControlName="startTime" mask="99:99:99" pattern="(([0-1]?[0-9])|(2[0-3])):[0-5][0-9]:[0-5][0-9]" placeholder="hh:mm:ss" slotChar="hh:mm:ss" [styleClass]="'text-small padding-6 text-center' + getValidationErrorStyleClass(2, 'startTime')" [style]="{ 'width': '75px'}" (change)="onStartTimeChange();"></p-inputMask>
                            <span *ngIf="getValidationErrorStyleClass(2, 'startTime')" class="fa fa-info-circle error-marker padding-3"></span>
                        </span>
          </div>
        </div>
        <div class="row gutter-0">
          <div class="col-5 label">End Time 3<span class="text-required"> *</span></div>
          <div class="col-7 ps-2 !pe-[42px]">
                        <span id="remit-trade-entry-endTime3" class="inline-block padding-left-3">
                            <p-inputMask formControlName="endTime" mask="99:99:99" pattern="(([0-1]?[0-9])|(2[0-4])):[0-5][0-9]:[0-5][0-9]" placeholder="hh:mm:ss" slotChar="hh:mm:ss" [styleClass]="'text-small padding-6 text-center' + getValidationErrorStyleClass(2, 'endTime')" [style]="{ 'width': '75px'}" (change)="onEndTimeChange();"></p-inputMask>
                            <span *ngIf="getValidationErrorStyleClass(2, 'endTime')" class="fa fa-info-circle absolute error-marker"></span>
                        </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-body">
    <div class="row gutter-0">
      <div class="col-5 label"></div>
      <div class="col-7 ps-2 !pe-[42px]">
        <button type="button" class="btn add-remove-line" (click)="add()" [disabled]="isAddDisabled()">Add</button>
        <button type="button" class="btn add-remove-line" (click)="remove()" [disabled]="isRemoveDisabled()">Remove</button>
      </div>
    </div>
  </div>
</div>
