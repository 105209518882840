import { createReducer, on } from '@ngrx/store';
import * as CpAction from './counterparty.actions';
import { CounterPartyActions } from './counterparty.actions';
import { CounterpartyOption } from '../../../netting-preview/netting-preview.model';

export interface CounterPartyState {
  counterparties: CounterpartyOption[];
  selectedCounterPartyOrgIds: number[];
  loading: boolean;
}

export const counterpartiesInitialState: CounterPartyState = {
  counterparties: [],
  selectedCounterPartyOrgIds: [],
  loading: false
};


export const counterpartiesReducer = createReducer<CounterPartyState, CounterPartyActions>(
  counterpartiesInitialState,

  on(CpAction.loadPresetsSuccess, (state, { presets }) => ({
    ...state,
    loading: false,
    counterparties: [...presets]
  })),
  on(CpAction.loadPresets, (state) => ({
    ...state,
    loading: true
  })),
  on(CpAction.cleanState, () => ({ ...counterpartiesInitialState })),
  on(CpAction.setSelectedItems, (state, { counterPartyOrgIds }) => ({
    ...state,
    selectedCounterPartyOrgIds: [...counterPartyOrgIds]
  })));

