<div class="trade-reference">
  <div class="form-title">Trade | References</div>
  <div class="form-body" [formGroup]="formGroup">
    <div class="row gutter-0 !me-[40px]">
      <div class="col-5 label">UTI (Contract ID) <span class="text-required">*</span></div>
      <div class="col-7 ps-2 pe-1">
        <input id="remit-table2-trade-entry-uti" type="text" class="form-control p-component padding-6 text-medium margin-3" formControlName="uti" maxlength="100" [ngClass]="{'error':formGroup.controls['uti'].hasError('error')}" [readonly]="readonly">
      </div>
      <span *ngIf="formGroup.controls['uti'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0 !me-[40px]">
      <div class="col-5 label">Contract Date (UTC) <span class="text-required">*</span></div>
      <div class="col-7 ps-2 pe-1">
                 <span id="remit-trade-entry-table2-contractDate" class="inline-block padding-right-3 margin-3">
                        <p-calendar
                          formControlName="contractDate"
                          [showIcon]="true"
                          [monthNavigator]="true"
                          [yearNavigator]="true"
                          placeholder="dd/mm/yyyy"
                          dateFormat="dd/mm/yy"
                          [style]="{ 'width': '125px'}"
                          [styleClass]="getValidationErrorStyleClass('contractDate')"
                          cmsDateRestriction>
                        </p-calendar>
                </span>
      </div>
      <span *ngIf="formGroup.controls['contractDate'].hasError('error') || formGroup.controls['contractDate'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
  </div>
</div>
