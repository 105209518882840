<div class="datatable-container" style="max-width: 1100px">
  <p-table
    [value]="records"
    [rows]="defaults.DEFAULT_ROWS"
    [sortOrder]="-1"
    [scrollable]="true"
    [loading]="loading"
    [customSort]="true"
    (sortFunction)="sort($event)"
    sortField="executionTime"
  >
    <ng-template pTemplate="header">
      <tr>
        <th scope="col" *ngFor="let col of cols" [pSortableColumn]="col.sortable ? col.name : ''" [ngStyle]="col.style">
          {{col.title}}
          <p-sortIcon [field]="col.name" *ngIf="col.sortable"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
      <tr>
        <td *ngFor="let col of cols" [ngStyle]="col.style">
          <span *ngIf="col.name != 'download'">{{row[col.name]}}</span>
          <span *ngIf="col.name == 'download'">
            <button *ngIf="row['reportGenerationFinished']; else notificationMessage" class="btn btn-icon fa fa-file" type="button" title="Download Billing Transaction Report" (click)="downloadBillingTxReport(row.id)"></button>
            <ng-template #notificationMessage>
              <span>Generating Report</span>
            </ng-template>
          </span>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
