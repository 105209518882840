import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { InjectionToken } from '@angular/core';
import { layoutReducer } from '../mail-alerts/layout/layout.reducer';
import { mailAlertsReducer, State } from '../mail-alerts/mail-alerts.reducer';

export const getPreferencesState = createFeatureSelector<State>('preferences');

export const reducers: ActionReducerMap<State> = {
  global: mailAlertsReducer('[MAIL ALERTS GLOBAL]'),
  ecm: mailAlertsReducer('[MAIL ALERTS ECM]'),
  layout: layoutReducer
};


export const reducerToken = new InjectionToken<ActionReducerMap<State>>(
  'Mail Alerts Registered Reducers'
);

export const getReducers = () => reducers;



