import { DatatableData } from '@common/shared/results.model';
import { ErrorResponse } from '@common/error-response.model';

export interface ErrMifidFile {
  auditGroupId: number;
  confirmButtonVisible: boolean;
  dataStoreId: number;
  documentType: string;
  feedbackDate: Date;
  filename: string;
  id: number;
  messageIdentifier: string;
  recAccepted: number;
  recPending: number;
  recRejected: number;
  receiptDate: Date;
  recordCount: number;
  senderOmp: string;
  state: string;
}

export type MifidDatatableModel<T> = DatatableData<T> & {
  confirmColumnVisible: boolean;
};

export type ErrMifidViewByTypes = 'filename' | 'filter';

export const defaultStyles = {
  'text-align': 'left',
  'text-overflow': 'ellipsis',
  'overflow': 'hidden',
  'white-space': 'nowrap'
};

export const allColumnsConfig = [
  { name: 'senderOmp', type: 'text', title: 'Submitting OMP', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'filename', type: 'text', title: 'Filename', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'documentType', type: 'text', title: 'Document Type', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'receiptDate', type: 'date', title: 'Receipt Date', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'feedbackDate', type: 'date', title: 'Feedback Date', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'openDocument', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'auditTrail', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'details', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'state', type: 'text', title: 'State', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'recordCount', type: 'text', title: '# Records', style: { ...defaultStyles, 'text-align': 'right' }, sortable: true, visible: true },
  { name: 'recPending', type: 'text', title: 'Pending', style: { ...defaultStyles, 'text-align': 'right' }, sortable: true, visible: true },
  { name: 'recAccepted', type: 'text', title: 'Accepted', style: { ...defaultStyles, 'text-align': 'right' }, sortable: true, visible: true },
  { name: 'recRejected', type: 'text', title: 'Rejected', style: { ...defaultStyles, 'text-align': 'right' }, sortable: true, visible: true }
];


export interface ErrMifidFileFilters {
  filename?: string;
  documentTypes: string[];
  timeRange: ErrMifidTimeRanges;
}

export interface PresetValues {
  uploadPanelVisible: boolean;
  maxUploadFileSizeInBytes: number;
  availableDocumentTypes: string[];
  timeRange: ErrMifidTimeRanges;

}

export type PresetValuesResponse = PresetValues & ErrorResponse;


export interface ErrMifidFileResponse extends ErrorResponse {
  count: number;
  currentPage: number;
  values: ErrMifidFile[];
  confirmColumnVisible: boolean;
}

export interface ErrMifidTimeRanges {
  receivedFromDate: string;
  receivedFromTime: string;
  receivedToDate: string;
  receivedToTime: string;
}

export interface ErrMifidDetailsInformation {
  accessValidationFailures: string[];
  addPositionEnabled: boolean;
  confirmFileEnabled: boolean;
  discardChangesEnabled: boolean;
  docType: string;
  fieldValidationFailures: {};
  filename: string;
  ompName: string;
  receiptTimestamp: string;
  recordCount: number;
  state: string;
  success: boolean;
  technicalErrors: string[];
  validationFailures: string[];
}

export interface ErrMifidDetailsInformationResponse extends ErrorResponse {
  value: ErrMifidDetailsInformation;
}

export interface ErrMifidItemObj {
  edited: boolean;
  value: any;
}

export interface ErrMifidDetailsItemResponse {
  clientHedgingExemption: ErrMifidItemObj;
  clientNonInvestFirm: ErrMifidItemObj;
  editable: boolean;
  id: number;
  isin: ErrMifidItemObj;
  parentEntity: ErrMifidItemObj;
  parentPosHolderEmail: ErrMifidItemObj;
  posAccount: ErrMifidItemObj;
  posDelta: ErrMifidItemObj;
  posHolder: ErrMifidItemObj;
  posHolderCategory: ErrMifidItemObj;
  posHolderEmail: ErrMifidItemObj;
  posHolderIndependent: ErrMifidItemObj;
  posMaturity: ErrMifidItemObj;
  posQuantity: ErrMifidItemObj;
  posRepFileId: number;
  posType: ErrMifidItemObj;
  posUOM: ErrMifidItemObj;
  posUomDetails: ErrMifidItemObj;
  positionAction: string;
  positionState: string;
  refNumber: string;
  reportingDate: ErrMifidItemObj;
  reportingEntity: ErrMifidItemObj;
  reportingEntityMemberId: ErrMifidItemObj;
  riskReducingIndicator: ErrMifidItemObj;
  tradeDate: ErrMifidItemObj;
  venueId: ErrMifidItemObj;
  venueProductCode: ErrMifidItemObj;
}

export interface ErrMifidDetailsItem {
  clientHedgingExemption: boolean;
  clientNonInvestFirm: boolean;
  editable: boolean;
  id: number;
  isin: string;
  parentEntity: string;
  parentPosHolderEmail: string;
  posAccount: string;
  posDelta: number;
  posHolder: string;
  posHolderCategory: string;
  posHolderEmail: string;
  posHolderIndependent: boolean;
  posMaturity: string;
  posQuantity: number;
  posRepFileId: number;
  posType: string;
  posUOM: string;
  posUomDetails: string;
  positionAction: string;
  positionState: string;
  refNumber: string;
  reportingDate: string;
  reportingEntity: string;
  reportingEntityMemberId: string;
  riskReducingIndicator: boolean;
  tradeDate: string;
  venueId: string;
  venueProductCode: string;
}

export interface ErrMifidDetailsDataResponse extends ErrorResponse {
  count: number;
  currentPage: number;
  values: ErrMifidDetailsItemResponse[];
}

export interface ErrMifidDetailsData {
  count: number;
  currentPage: number;
  values: ErrMifidDetailsItem[];
}

export const allDetailsColumnsConfig = [
  { name: 'refNumber', type: 'text', title: 'Ref number', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'edit', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: false },
  { name: 'delete', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: false },
  { name: 'positionAction', type: 'text', title: 'Type', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'positionState', type: 'text', title: 'Position State', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'riskReducingIndicator', type: 'edited', title: 'Risk red. ind', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'posQuantity', type: 'currency-edited', title: 'Pos. quantity', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'posDelta', type: 'currency-edited', title: 'Delta eq pos.', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'posUOM', type: 'edited', title: 'Pos. UOM', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'posUomDetails', type: 'edited', title: 'UOM details', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'isin', type: 'edited', title: 'ISIN', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'reportingDate', type: 'edited', title: 'Rep. Date', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'tradeDate', type: 'edited', title: 'Trade Date', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'reportingEntity', type: 'edited', title: 'Reporting Entity', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'reportingEntityMemberId', type: 'edited', title: 'Reporting Entity Member ID', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'posAccount', type: 'edited', title: 'Pos. Account', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'posHolder', type: 'edited', title: 'Pos. Holder', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'posHolderCategory', type: 'edited', title: 'Pos. Holder Category', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'posHolderEmail', type: 'edited', title: 'Pos. Holder Email', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'parentPosHolderEmail', type: 'edited', title: 'Parent Entity Email', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'posHolderIndependent', type: 'edited', title: 'Pos. holder is independent', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'parentEntity', type: 'edited', title: 'Parent Entity', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'venueProductCode', type: 'edited', title: 'Venue product code', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'venueId', type: 'edited', title: 'Trading Venue ID', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'posType', type: 'edited', title: 'Pos. Type', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'posMaturity', type: 'edited', title: 'Pos. maturity', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'clientNonInvestFirm', type: 'edited', title: 'Client non invest firm', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'clientHedgingExemption', type: 'edited', title: 'Client hedging exempt', style: { ...defaultStyles }, sortable: true, visible: true },
];

export interface ErrMifidDetailsFormValues extends ErrorResponse {
  positionMaturity: string[];
  positionTypes: string[];
  positionUOMCodes: string[];
  success?: boolean;
  positionHolderCategories: {key: string, value: string}[];
}
