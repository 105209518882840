import { Message, SelectItem } from 'primeng/api';
import * as fromActions from './billing-tx-report.actions';
import { OrganisationGroup } from '../../administration-tab-panel/organisation-groups/organisation-groups.model';
import {
  BillingTxGenerationRequest,
  BillingTxItem,
  OrganisationGroupItem
} from '../../administration-tab-panel/billing-tx-report/billing-tx-report.models';
import * as moment from 'moment/moment';
import { Moment } from 'moment';

export interface BillingTxReportState {
  messages: Message[],
  orgGroups: SelectItem[],
  generationWarningVisible: boolean,
  noRecordsWarning: boolean,
  submitPayload: BillingTxGenerationRequest,
  billingReportList: BillingTxItem[],
  generateReportId: number,
  latestQuarter: Moment,
}

export const initialState: BillingTxReportState = {
  messages: [],
  orgGroups: [],
  generationWarningVisible: false,
  noRecordsWarning: false,
  submitPayload: undefined,
  billingReportList: [],
  generateReportId: undefined,
  latestQuarter: undefined,
}

export function billingTxReportReducer(
  state: BillingTxReportState = { ...initialState },
  action: fromActions.BillingTxReportActions
): BillingTxReportState {
  switch (action.type) {
    case fromActions.loadOrgGroupData.type: {
      return {
        ...state
      }
    }
    case fromActions.loadOrgGroupDataSuccess.type: {
      return {
        ...state,
        orgGroups: [...action.payload.map((el: OrganisationGroupItem) => ({
          label: el.value,
          value: el.key
        }))]
      }
    }
    case fromActions.loadBillingRunQuartersSuccess.type: {
      return {
        ...state,
        latestQuarter: action.payload.values.length != 0 ? moment(action.payload.values[0].quarter, "YYYY-[Q]Q"):moment().add(3, 'Q'),
      }
    }
    case fromActions.loadTxCountSuccess.type: {
      return {
        ...state,
        generationWarningVisible: action.payload.overLimit,
        noRecordsWarning: action.payload.noResults,
        submitPayload: action.payload.submitPayload,
      }
    }
    case fromActions.listBillingReportsSuccess.type: {
      return {
        ...state,
        billingReportList: [...action.payload.values],
        generateReportId: undefined,
      }
    }
    case fromActions.setMessagesAction.type: {
      return {
        ...state,
        messages: [...action.payload]
      }
    }
    case fromActions.hideCountWarning.type:
    case fromActions.submitReportGeneration.type: {
      return {
        ...state,
        generationWarningVisible: false,
        noRecordsWarning: false,
        submitPayload: undefined,
      }
    }
    case fromActions.submitReportGenerationSuccess.type: {
      return {
        ...state,
        generateReportId: action.payload.response
      }
    }
    default: {
      return { ...state }
    }
  }
}
