import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Column } from '../../../../common/shared/datatable.model';
import { DateUtils } from '../../../../common/shared/date.utils';
import { RemitReportService } from '../remit-report.service';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import * as FileSaver from 'file-saver';
import { Message } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import { OrganisationService } from '../../../../common/organisation.service';

@Component({
  selector: 'cms-emir-reconciliation-report',
  templateUrl: './emir-reconciliation-report.component.html',
  styleUrls: ['./emir-reconciliation-report.component.scss']
})
export class EmirReconciliationReportComponent implements OnInit, OnDestroy {

  columns: Column[] = [
    { title: '', name: 'rerun', style: { 'width': '80px', 'text-align': 'center' } },
    { title: 'As of', name: 'asOfDate', style: { 'width': '85px', 'text-align': 'left' } },
    { title: 'Result', name: 'result', style: { 'width': '85px', 'text-align': 'center' } },
    { title: 'Executed', name: 'executedTimestamp', style: { 'width': '140px', 'text-align': 'left' } },
    { title: 'MTM', name: 'mtmCount', style: { 'width': '60px', 'text-align': 'right' } },
    { title: 'Matched', name: 'matchedCount', style: { 'width': '85px', 'text-align': 'right' } },
    { title: 'Mismatched', name: 'mismatchCount', style: { 'width': '110px', 'text-align': 'right' } },
    { title: 'Pairing Failed', name: 'pairingFailedCount', style: { 'width': '120px', 'text-align': 'right' } },
    { title: 'Pending', name: 'pendingCount', style: { 'width': '85px', 'text-align': 'right' } },
    { title: 'Alleged', name: 'allegedCount', style: { 'text-align': 'right' } }
  ];

  // for info modal
  isOverlayPanelVisible: boolean = false;
  isBackgroundBlocked: boolean = true;
  version: number;
  executedBy: string;
  executionDurationInSec: number;
  asOfDate: string;
  rerunAllowed: boolean;
  uploadPanelVisible = false;
  serviceErrorMessage: string = '';
  rerunDialogVisible: boolean = false;


  loading: boolean = false;

  rerunState = new BehaviorSubject<EmirRerunState>('START');

  messages: Message[] = [];

  rows = new BehaviorSubject<EmirReconciliationReportModel[]>([]);
  selection: EmirReconciliationReportModel;
  private onDestroy$ = new Subject();

  constructor(private remitReportService: RemitReportService, private orgService: OrganisationService, private changeDetector: ChangeDetectorRef) {

  }

  ngOnInit() {

    // for popup of rerun
    this.rerunDialogVisible = false;

    this.remitReportService.onEmirPresetValuesChanged().subscribe(presetValues => {
      this.rerunAllowed = presetValues.rerunAllowed;
      this.uploadPanelVisible = presetValues.uploadPanelVisible;
    });

    this.remitReportService.loadEmirPresetValues();

    this.remitReportService.getEmirReconciliationReport().pipe(takeUntil(this.onDestroy$)).subscribe(
      report => {
        if (report) {
          this.rows.next(report.map(
            rep => ({
              ...rep,
              asOfDate: DateUtils.transformDate(rep.asOfDate),
              executedTimestamp: DateUtils.transformDateWithTimestamp(rep.executedTimestamp)
            })
          ));
          this.loading = false;

        }
      });

    this.remitReportService.onRefreshEmirReconciliation().pipe(takeUntil(this.onDestroy$)).subscribe(refresh => {
      if (refresh) {
        this.loading = true;
        this.remitReportService.refreshEmirReconciliationReport();
      }
    });

    this.remitReportService.onMessageChange().pipe(takeUntil(this.onDestroy$)).subscribe(msg => {
        if (msg) {
          this.messages.push(msg);
        } else {
          this.messages = [];
        }
        this.changeDetector.markForCheck();
      }
    );

    this.orgService.getOrganisationGroup().pipe(takeUntil(this.onDestroy$)).subscribe(orgGroup => {
      this.loading = true;
      this.remitReportService.refreshEmirReconciliationReport();
    });

  }

  public showAdditionalInformations(model: EmirReconciliationReportModel) {
    this.isOverlayPanelVisible = true;
    this.version = model.version;
    this.executedBy = model.executedBy;
    this.executionDurationInSec = model.executionDurationInSec;
  }

  public showRepeatReconciliation(model: EmirReconciliationReportModel) {
    this.asOfDate = model.asOfDate;

    this.rerunState.next('START');

    this.remitReportService.loadEmirPresetValues();

    if (this.rerunAllowed) {
      this.rerunState.next('RERUN');
      this.serviceErrorMessage = '';
    } else {
      this.rerunState.next('END');
      this.serviceErrorMessage = 'Another run for your organisation group is still in execution. Please retry shortly.';
    }

  }

  getRerunState(): Observable<EmirRerunState> {
    return this.rerunState.asObservable();
  }

  public closeInfo() {
    this.isOverlayPanelVisible = false;
  }

  public closeRerunDialog() {
    this.rerunState.next('START');

    if(this.rerunDialogVisible){
      this.remitReportService.refreshEmirReconciliationReport();
    }

    this.rerunDialogVisible = false;
  }

  public showRerunDialog(){
    this.rerunDialogVisible = true;
  }

  public executeRerun() {
    this.rerunState.next('EXECUTING');
    this.serviceErrorMessage = '';
    const dateParts = this.asOfDate.split('/');
    const executionDateFormat = dateParts[2] + '-' + dateParts[1] + '-' + dateParts [0];
    const isoDateFormat = DateUtils.toISOStringWithIgnoreTimezoneOffset(new Date(executionDateFormat));
    // todo call repeat service and update table on ready
    this.remitReportService.rerunEmirReport(isoDateFormat).pipe(takeUntil(this.onDestroy$)).subscribe(
      result => {
        this.rerunState.next('END');
      },
      error => {
        this.rerunState.next('END');
        const errorCode = error.error.errorMessage;
        if (errorCode) {
          this.serviceErrorMessage = 'A technical error occured. Please contact the support team with error reference number ' + errorCode + '.';
        } else {
          this.serviceErrorMessage = 'A technical error occured. Please contact the support team.';
        }
      }
    );
  }

  public loadExcelExport(reconciliationReportId): void {
    this.remitReportService.downloadEmirReconciliationExcelReport(reconciliationReportId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(data => {
        FileSaver.saveAs(data.blob, data.filename);
      });
  }

  ngOnDestroy(): void {
    this.serviceErrorMessage = '';
    this.rerunState.next('START');
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  hasErrorMessage(): boolean {
    return this.serviceErrorMessage && this.serviceErrorMessage.length > 0;
  }
}

export interface EmirReconciliationReportModel {
  id: number;
  asOfDate: string;
  executedTimestamp: string;
  mtmCount: number;
  matchedCount: number;
  mismatchCount: number;
  pairingFailedCount: number;
  pendingCount: number;
  allegedCount: number;
  version: number;
  executedBy: string;
  executionDurationInSec: number;
}

export type EmirRerunState =
  | 'START'
  | 'RERUN'
  | 'EXECUTING'
  | 'END';
