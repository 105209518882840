import { Message } from 'primeng/api';
import { Component, OnInit, OnChanges, ChangeDetectionStrategy, Input, Output, EventEmitter, SimpleChange } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { StartBillingRun } from '../billing-runs.model';
import { keyPressByRegex } from '../../../administration.model';

@Component({
  selector: 'cms-start-billing-run',
  templateUrl: './start-billing-run.component.html',
  styleUrls: ['./start-billing-run.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StartBillingRunComponent implements OnInit, OnChanges {
  @Input() formData: StartBillingRun;
  @Input() isDisabled: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() messages: Message[] = [];
  @Output() handleSubmit = new EventEmitter<StartBillingRun>();
  form: UntypedFormGroup;
  errors: { [key: string]: ValidationErrors } = {};
  private readonly intRegex: RegExp = /^[0-9]{0,10}$/;
  private defaultFormData: StartBillingRun = { invoiceStartNumber: '', draft: false };

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    const { invoiceStartNumber, draft } = this.formData || this.defaultFormData;
    this.form = this.formBuilder.group({
      invoiceStartNumber: { value: invoiceStartNumber, disabled: this.isDisabled || this.isLoading  },
      draft: { value: draft, disabled: this.isDisabled || this.isLoading }
    });
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes.messages && this.messages) {
      this.errors = {};
      this.messages.forEach(({ key: controlName }: Message) => {
        this.errors[controlName] = { 'server': true };
      });
    }

    if (this.form) {
      if (changes.formData) {
        this.form.patchValue({ ...(this.formData || this.defaultFormData) });
      }

      if (changes.isDisabled || changes.isLoading) {
        if (this.isDisabled || this.isLoading) {
          this.form.get('invoiceStartNumber').disable();
          this.form.get('draft').disable();
        } else {
          this.form.get('invoiceStartNumber').enable();
          this.form.get('draft').enable();
        }
      }
      this.form.updateValueAndValidity();
    }
  }

  onKeypress(event: any): boolean {
   return keyPressByRegex(event, this.intRegex);
  }

  onConfirm(): void {
    const obj = this.form.getRawValue();
    this.handleSubmit.emit(obj);
  }
}
