import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cms-remit-trade-entry-table1-option-detail',
  templateUrl: './remit-trade-entry-table1-option-detail.component.html',
  styleUrls: ['./remit-trade-entry-table1-option-detail.component.scss']
})
export class RemitTradeEntryTable1OptionDetailComponent implements OnInit, OnDestroy {
  @Input()
  formGroup: UntypedFormGroup;

  contractType: string;
  currency: string = '';
  visible: boolean = false;

  private onDestroy$ = new Subject();

  optionStyles: SelectItem[] = [
    { label: 'A - American', value: 'A' },
    { label: 'B - Bermudan', value: 'B' },
    { label: 'E - European', value: 'E' },
    { label: 'S - Asian', value: 'S' },
    { label: 'O - Other', value: 'O' }
  ];

  optionTypes: SelectItem[] = [
    { label: 'C - Call', value: 'C' },
    { label: 'P - Put', value: 'P' },
    { label: 'O - Other', value: 'O' }
  ];

  constructor() {
  }

  ngOnInit() {
    this.formGroup.addControl('optionStyle', new UntypedFormControl(''));
    this.formGroup.addControl('optionType', new UntypedFormControl(''));
    this.formGroup.addControl('strikePrice', new UntypedFormControl(''));
    this.formGroup.addControl('exerciseDate', new UntypedFormControl(''));

    this.formGroup.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(formValues => {
      this.onContractTypeChange(formValues.contractType);

      if (formValues.priceCurrency !== this.currency) {
        this.currency = formValues.priceCurrency;
      }
    });

  }

  getValidationErrorStyleClass(fieldName: string): string {
    if (this.formGroup.controls[fieldName].hasError('error')) {
      return 'error';
    }
  }

  private onContractTypeChange(contractType: string) {
    if (this.contractType !== contractType) {
      this.contractType = contractType;
      this.visible = contractType && this.contractType.startsWith('OP');
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }
}
