<div class="panel-box margin-top-12 margin-bottom-12 decent-max-width" *ngIf="visible$ | async">
  <label>Add/Edit/Delete Standing Instructions: (.csv file)</label>
  <form [formGroup]="uploadForm" class="flex" (ngSubmit)="submit()">

    <input id="input-filename-remit-files-in" type="text" class="form-control p-component padding-6 text-medium w-50 me-2" [value]="fileName" readonly>
    <button class="btn form-control margin-3 padding-6" style="width: 96px;" (click)="browseFiles();" [disabled]="!(uploadEnabled$ | async) ">Browse File</button>
    <button id="btn-submit-remit-files-in" type="submit" style="width: 78px;" class="btn form-control margin-3 padding-6" [disabled]="!fileSelected">Upload</button>
    <button id="btn-download-remit-files-in" type="button" style="width: 87px;" (click)="download()" class="btn form-control margin-3 margin-left-12 padding-6">Download</button>

    <input formControlName="csvFile" id="standing-instructions-upload-button" type="file" class="hidden" (change)="onSelect($event)" accept=".csv" #fileUpload>
  </form>
</div>
