import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { Message } from 'primeng/api';
import { Prefix } from '../reducers';
import { ApiRequestService } from '../../../common/api-request.service';
import { MessagesMapperService } from '../../../common/messages-mapper.service';
import { DatatableData } from '../../../common/shared/results.model';
import {
  Contacts,
  ContactsFilters,
  ContactsRequest,
  ContactsResponse,
} from '../../contacts/contacts.model';
import { ContactsState } from '../contacts/contacts.reducers';
import { DatatableState } from '../datatable/datatable.reducer';
import * as fromSelectors from './org-groups-dt.selectors';
import * as fromDt from '../datatable/datatable.actions';

@Injectable()
export class OrgGroupsDtEffects {
  private baseUrl = 'api/help/contacts';
  private prefix: Prefix = '[ORG GROUPS CONTACTS]';

  constructor(private apiGateway: ApiRequestService,
              private actions$: Actions,
              private store$: Store<ContactsState>,
              private mapper: MessagesMapperService) {
  }

   filter$ = createEffect(() => this.actions$
    .pipe(ofType(`${this.prefix} ${fromDt.FILTER_DATA}`),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getDatatable))),
      switchMap((data: [fromDt.FilterDataAction, DatatableState<Contacts, ContactsFilters>]) => {
          const [action, state] = data;
          const request: ContactsRequest = {
            paging: state.paging,
            sorting: state.sorting,
            idOfSelectedOrganisationGroup: state.filters.organisationGroupId
          };
          return this.loadData(request);
        }
      )));

   sortAndPage$ = createEffect(() => this.actions$
    .pipe(ofType(`${this.prefix} ${fromDt.SORT_AND_PAGE_DATA}`),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getFilters))),
      switchMap((data: [fromDt.SortAndPageDataAction, ContactsFilters]) => {
          const [action, filters] = data;
          const {sorting, paging} = action.payload;
          return this.loadData({
            idOfSelectedOrganisationGroup: filters ? filters.organisationGroupId : null,
            sorting,
            paging,
          });
        }
      )));

   loadData$ = createEffect(() => this.actions$
    .pipe(ofType(`${this.prefix} ${fromDt.LOAD_DATA}`),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getFilters))),
      switchMap((data: [fromDt.LoadDataAction, ContactsFilters]) => {
          return of(new fromDt.FilterDataAction(this.prefix, data[1]));
        }
      )));

  private loadData(request: ContactsRequest) {
    return this.apiGateway
      .post(`${this.baseUrl}/organisationContacts`, request)
      .pipe(
        map((response: ContactsResponse) => {
          const messages: Message[] = this.mapper.toErrorMessages(response);
          if (messages && messages.length) {
            return new fromDt.SetMessagesAction(this.prefix, messages);
          }

          return new fromDt.FilterDataSuccessAction(this.prefix, response as DatatableData<Contacts[]>);
        }),
        catchError(error => of(new fromDt.SetMessagesAction(this.prefix, [
          this.mapper.createErrorMessage(error.errorMessage)
        ]))));
  }
}
