import { createReducer, on } from '@ngrx/store';
import { TableEntry } from '../../permissions/web-user/web-user.model';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { Message } from 'primeng/api';
import {
  cleanState,
  loading,
  loadPresetsSuccess,
  loadTableSuccess,
  resetEntries, resetMessages, setMessages,
  setPagingAndSorting,
} from './permissions.actions';

export interface PermissionsState {
  messages: Message[];
  loading: boolean;
  hasEditRights: boolean;
  paging: PagingFilter;
  sorting: SortingFilter;
  data: DatatableData<TableEntry[]>;
}



export const initialState: PermissionsState = {
  messages:[],
  loading: false,
  hasEditRights: false,
  paging: { page: 0, entriesPerPage: 5 },
  sorting: { columnName: 'lastUpdate', sortOrder: 'DESCENDING' },
  data: { values: [], currentPage: 0, count: 10 }
};






const reducer = createReducer<PermissionsState>(
  initialState,

  on(cleanState, ( ) => ({
    ...initialState
  })),

  on(loading, (state, { isLoading }) => ({
    ...state,
    loading: isLoading
  })),

  on(setMessages, (state, { messages }) => ({
    ...state,
    messages: messages
  })),


  on(resetMessages, (state) => ({
    ...state,
    messages: []
  })),

  on(loadPresetsSuccess, (state, { editAllowed }) => ({
    ...state,
    hasEditRights: editAllowed
  })),

  on(resetEntries, (state) => ({
    ...state,
    data: { values: [...state.data.values] }
  })),

  on(setPagingAndSorting, (state, { paging, sorting }) => ({
    ...state,
    paging: paging,
    sorting: sorting,
  })),

  on(loadTableSuccess, (state, { data }) => ({
    ...state,
    data: data,
    paging:{...state.paging,page: data.currentPage}
  })),

);


export function permissionsReducer(state, action) {
  return reducer(state, action);
}

