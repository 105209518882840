import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Data } from '@angular/router';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Message } from 'primeng/api';
import { UserService } from '../../common/user.service';
import { UserInformationModel } from '../../common/user-informations.model';
import { passwordValidator } from './password.validator';
import { ChangePasswordModel, ChangePasswordResponseModel } from './change-password.model';
import { AuthenticationService } from '../../auth/authentication.service';

@Component({
  selector: 'cms-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  @Input()
  isIntegrated: boolean = false;

  static CHANGE_PASSWORD_ERROR_CODE_MAPPING = {
    'WRONG_OLD_PASSWORD': 'You entered the wrong password',
    'NEW_PASSWORD_INVALID': 'New password does not meet the password policy requirements',
    'USED_BEFORE': 'New password has been used before',
    'VALID': ' successfully!'
  };
  userInformations: UserInformationModel;
  changePassword: ChangePasswordModel;
  form: UntypedFormGroup;
  messages: Message[] = [];
  changedSuccessfully: boolean = false;

  private informationsSubscription: Subscription;
  private formChangesSubscription: Subscription;
  private updatePasswordSubscription: Subscription;
  private updateImmediatelySubscription: Subscription;

  private formSubmitAttempt: boolean;
  private changeImmediately: boolean = true;
  immediatelyScreen:boolean;
  constructor(private formBuilder: UntypedFormBuilder,
              private userService: UserService,
              private authService: AuthenticationService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.updateImmediatelySubscription = this.route.data.subscribe((data: Data) => {
      this.changeImmediately = data['changeImmediately'];
    }, err => {
      this.changeImmediately = false;
    });

    this.informationsSubscription = this.userService.getInformations().subscribe(informations => {
      this.userInformations = informations;
    }, err => {
    });

    this.createForm();

    this.immediatelyScreen =!!this.route.snapshot.url.find((x=>x.path.includes('change_password_immediately')));
  }

  ngOnDestroy(): void {
    this.informationsSubscription.unsubscribe();
    this.formChangesSubscription.unsubscribe();
    this.updateImmediatelySubscription.unsubscribe();
  }

  displayFieldCss(field: string): {[key: string]: boolean} {
    if (!this.formSubmitAttempt) {
      return {};
    }
    return { 'error': this.isFieldInvalid(field) };
  }

  resetForm(): void {
    this.form.reset();
    this.formSubmitAttempt = false;
  }

  getPasswordExpirationDate(): number | string {
    // I do this null check, because this.userInformations becomes sometimes null.
    if (this.userInformations) {
      return this.userInformations.passwordExpiration ? this.userInformations.passwordExpiration : '';
    }
    return null;
  }

  onSubmit(): void {
    this.formSubmitAttempt = true;
    if (this.form.valid) {
      this.changePassword = {};
      this.changePassword.newPassword = this.form.value.newPassword;
      this.changePassword.currentPassword = this.form.value.currentPassword;
      this.updatePasswordSubscription = this.userService.updatePassword(this.changePassword).subscribe(
        (data: ChangePasswordResponseModel) => {

          this.messages = [];

          if(data.fieldValidationMessages){

            const msgs: Message[] = [];
            Object.keys(data.fieldValidationMessages).forEach(function(key) {
              msgs.push({
                severity: 'error',
                detail: ChangePasswordComponent.CHANGE_PASSWORD_ERROR_CODE_MAPPING[key]
              });
            });

            this.messages = msgs;
            this.changedSuccessfully = false;
          }else {
            this.resetForm();

            this.messages.push({
              severity: 'success',
              summary: 'Password changed: ',
              detail: ChangePasswordComponent.CHANGE_PASSWORD_ERROR_CODE_MAPPING['VALID']
            });

            this.changedSuccessfully=true;
            this.userService.refresh();

            if (this.changeImmediately) {
              this.changeImmediately = false;
              this.authService.authenticate();
            }

          }

        },
        (err: HttpErrorResponse) => {
          const changePasswordResponseModel: ChangePasswordResponseModel = err.error;
          this.resetForm();
          this.messages = [];
          this.messages.push({
            severity: 'error',
            summary: 'Error',
            detail: this.getChangePasswordErrorMessage(changePasswordResponseModel.changePasswordStatus)
          });
        }
      );
    }
  }

  getLastPasswordChangeDate(): string {
    if (this.userInformations) {
      return this.userInformations.lastPasswordChange != null ? this.userInformations.lastPasswordChange : '';
    }
    return null;
  }

  isPasswordRequiredError(field: string): boolean {
    return this.isPasswordError(field, 'required');
  }

  isCurrentPasswordNotMeetThePasswordPolicy(): boolean {
    return !this.isPasswordRequiredError('currentPassword')
      && this.isPasswordError('currentPassword', 'passwordNotRuleConform');
  }

  isNewPasswordNotMeetThePasswordPolicy(): boolean {
    return !this.isPasswordRequiredError('newPassword')
      && (this.isPasswordError('newPassword', 'passwordNotRuleConform')
      || this.isPasswordError('newPassword', 'minlength')
      || this.isPasswordError('newPassword', 'maxlength')
      || this.isPasswordError('newPassword', 'pattern'));
  }

  isNewPasswordsAreNotEqual(): boolean {
    return this.isPasswordError('newPasswordRepeat', 'newPasswordsAreNotEqual');
  }

  isImmediateChange(): boolean {
    return this.changeImmediately;
  }

  showLogoutButton(): boolean {
    return this.authService.forcePassword();
  }

  logout():void {
    this.authService.logout();
  }

  private validateNewPasswordsAreEqual(group: UntypedFormGroup): void {
    const newPassword = group.controls.newPassword;
    const newPasswordRepeat = group.controls.newPasswordRepeat;
    if (newPassword.value !== newPasswordRepeat.value) {
      newPasswordRepeat.setErrors({ 'newPasswordsAreNotEqual': { value: newPasswordRepeat.value } });
    }
    return null;
  }

  private isPasswordError(field: string, error: string): boolean {
    if (this.form.get(field).errors) {
      return this.form.get(field).errors[error] && this.formSubmitAttempt;
    }
    return null;
  }

  private getChangePasswordErrorMessage(changePasswordStatus): string {
    const hasErrorMapping = ChangePasswordComponent.CHANGE_PASSWORD_ERROR_CODE_MAPPING.hasOwnProperty(changePasswordStatus);
    if (changePasswordStatus && hasErrorMapping) {
      return ChangePasswordComponent.CHANGE_PASSWORD_ERROR_CODE_MAPPING[changePasswordStatus];
    } else {
      return 'Password changed failed, see password policy!';
    }
  }

  private isFieldInvalid(field: string): boolean {
    return (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt);
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      currentPassword: ['', passwordValidator()],
      newPassword: ['', passwordValidator()],
      newPasswordRepeat: ['', passwordValidator()],
    }, { validator: this.validateNewPasswordsAreEqual });

    this.formChangesSubscription = this.form.valueChanges.subscribe(_ => {
      this.messages = [];
    });
  }

}
