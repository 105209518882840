import { createAction, union } from '@ngrx/store';
import { Message } from 'primeng/api';
import { DialogState } from '@common/state/reducers';
import {
  SendingFailuresPresetValuesModel,
  SendingFailuresSearchRequest,
  SendingFailuresSelected,
  SendingFailuresValues
} from '../../sending-failures/sending-failures.model';
import { SF_DIALOGS } from './sending-failures.reducers';

export const setOrganisationId = createAction('[SENDING FAILURES] Set Organisation Id', (payload: number) => ({ payload }));
export const setToDefault = createAction('[SENDING FAILURES] Set to default');
export const setMessages = createAction('[SENDING FAILURES] Set Messages', (payload: Message[]) => ({ payload }));
export const search = createAction('[SENDING FAILURES] Search', (payload: SendingFailuresSearchRequest) => ({ payload }));
export const searchSuccess = createAction('[SENDING FAILURES] Search Success', (payload: SendingFailuresValues[]) => ({ payload }));
export const getPreset = createAction('[SENDING FAILURES] Get Preset');
export const onPresetSuccess = createAction('[SENDING FAILURES] On Preset Success', (payload: SendingFailuresPresetValuesModel) => ({ payload }));
export const refresh = createAction('[SENDING FAILURES] Refresh');
export const select = createAction('[SENDING FAILURES] Select', (payload: SendingFailuresSelected) => ({ payload }));
export const deselect = createAction('[SENDING FAILURES] Deselect', (payload: SendingFailuresSelected) => ({ payload }));
export const resend = createAction('[SENDING FAILURES] Resend');
export const resendSuccess = createAction('[SENDING FAILURES] Resend Success');
export const openDialog = createAction('[SENDING FAILURES] Open Dialog', (payload: DialogState<SF_DIALOGS>) => ({ payload }));
export const closeDialogAction = createAction('[SENDING FAILURES] Close Dialog');
export const toggleSelectAll = createAction('[SENDING FAILURES] Toggle Check All', (payload: boolean) => ({ payload }));

const actions = union({
  setOrganisationId,
  setToDefault,
  setMessages,
  search,
  searchSuccess,
  getPreset,
  onPresetSuccess,
  refresh,
  select,
  deselect,
  resend,
  resendSuccess,
  openDialog,
  closeDialogAction,
  toggleSelectAll
});

export type SendingFailuresActions = typeof actions;
