import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { RemitFilesInComponent } from './remit-files-in.component';
import { CmsCommonModule } from '../../../../common/cms-common.module';
import { RemitFilesInService } from './remit-files-in.service';
import { RemitFilesInUploadPanelComponent } from './remit-files-in-upload-panel.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RemitFilesInSearchComponent } from './remit-files-in-search.component';
import { RemitFilesInDatatableComponent } from './remit-files-in-datatable.component';
import { RouterModule } from '@angular/router';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { MessagesModule } from 'primeng/messages';
import { CalendarModule } from 'primeng/calendar';
import { InputMaskModule } from 'primeng/inputmask';

@NgModule({
  imports: [
    BrowserModule,
    RouterModule,
    CommonModule,
    FormsModule, ReactiveFormsModule,
    TableModule,
    ButtonModule,
    DropdownModule,
    MultiSelectModule,
    MessagesModule,
    MessageModule,
    CalendarModule,
    InputMaskModule,
    CmsCommonModule,
  ],
  providers: [RemitFilesInService],
  declarations: [RemitFilesInComponent, RemitFilesInUploadPanelComponent, RemitFilesInSearchComponent, RemitFilesInDatatableComponent]
})
export class RemitFilesInModule {
}
