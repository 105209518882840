import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { AllDealsMyFilterName, MyFilter } from '@common/my-filters/my-filters.model';
import { Message } from 'primeng/api';
import { Column } from '../../shared/datatable.model';

export const defaultStyles = {
  'width': '140px', 'text-align': 'left',
  'text-overflow': 'ellipsis',
  'overflow': 'hidden',
  'white-space': 'nowrap'
};

@Component({
  selector: 'cms-filters-management-dialog',
  templateUrl: './filters-management-dialog.component.html',
  styleUrls: ['./filters-management-dialog.component.scss']
})
export class FiltersManagementDialogComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() defaultFilter: MyFilter;
  @Input() myFilters: MyFilter[];
  @Input() display = false;
  @Input() messages: Message[];
  @Output() displayChange = new EventEmitter<boolean>();
  @Output() exit = new EventEmitter<Message[]>();
  @Output() setDefault = new EventEmitter<MyFilter>();
  @Output() delete = new EventEmitter<string>();
  @ViewChild('exit', { static: false }) exitButton: ElementRef;
  data: MyFilter[];
  errors: {[key: string]: ValidationErrors} = {};
  columns: Column[] = [
    { name: 'defaultFilter', type: 'radio', title: 'Default', style: { 'width': '50px ', 'text-align': 'center' }, sortable: false, visible: true },
    { name: 'filterName', type: 'text', title: 'Filter Name', style: { ...defaultStyles, 'width': '490px', 'text-align': 'left' }, sortable: false, visible: true },
    { name: 'button', type: 'button', title: 'Delete', style: { ...defaultStyles, width: '50px', 'text-align': 'center' }, sortable: false, visible: true },
  ];
  form: UntypedFormGroup;
  readonly AllDealsMyFilterName = AllDealsMyFilterName;

  constructor(private formBuilder: UntypedFormBuilder) { }

  onDefaultChange(myFilter: MyFilter): void {
    this.setDefault.emit(myFilter);
  }

  onDeleteItem(myFilter: MyFilter): void {
    this.delete.emit(myFilter.filterName);
  }

  onExit(): void {
    this.hide();
  }

  onVisibleChange(visible: boolean): void {
    if (!visible) {
      this.onExit();
    } else {
      this.exitButton.nativeElement.focus();
    }
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      defaultFilter: !this.defaultFilter ?
        AllDealsMyFilterName :
        this.defaultFilter.filterName
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.exitButton.nativeElement.focus();
    }, 100);
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (this.form) {
      if (changes.display && !this.display) {
        this.hide();
      }
      if (changes.data) {
        this.form.updateValueAndValidity();
      }

      if (changes.defaultFilter) {
        this.form.patchValue({
          defaultFilter: !this.defaultFilter ?
            AllDealsMyFilterName :
            this.defaultFilter.filterName
        });
        this.form.updateValueAndValidity();
      }
    }

    if (changes.myFilters) {
      this.initData();
    }

    if (changes.messages) {
      this.errors = {};
      if (this.messages) {
        this.messages.forEach((obj: Message) => {
          const { key: controlName } = obj;
          this.errors[controlName] = { 'server': true };
        });
      }
    }
  }

  private hide(): void {
    this.messages = [];
    this.display = false;
    this.displayChange.emit(this.display);
    this.form.reset();
    this.form.updateValueAndValidity();
    this.exit.emit();
  }

  private initData(): void {
    this.data = [
      {
        filterName: AllDealsMyFilterName,
        defaultFilter: this.defaultFilter === null,
      },
      ...(this.myFilters || []).sort((a, b) => a.filterName.localeCompare(b.filterName))
    ];
  }
}
