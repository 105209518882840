import { Action } from '@ngrx/store';
import { DatatableConfig } from '@common/shared/results.model';
import { Message } from 'primeng/api';
import { EcmDashboardDealsResponse, EcmDateRange, EcmStatesEnum, TerminateDealRequest } from '../../../ecm-dashboard/ecm-dashboard.model';
import { Column } from '@common/shared/datatable.model';
import { AddNote, DialogState, HideConfirmation, UnhideConfirmation } from '@common/deals/deals.model';
import { EcmDealFinder } from '../../../deal-finder/deal-finder.model';

export const LOAD_DATA = '[ECM DEALS] Load Data';
export const LOAD_DATA_SUCCESS = '[ECM DEALS] Load Data Success';
export const SET_MESSAGES = '[ECM DEALS] Set Messages';
export const SORT_AND_PAGE_DATA = '[ECM DEALS] Sort and Page Data';
export const SET_TO_DEFAULT = '[ECM DEALS] Set To Default';
export const SET_COLUMNS = '[ECM DEALS] Set Columns';
export const EXPORT = '[ECM DEALS] Export';
export const ADD_NOTES = '[ECM DEALS] Add Notes';
export const OPEN_DIALOG = '[ECM DEALS] Open Dialog';
export const CLOSE_DIALOG = '[ECM DEALS] Close Dialog';
export const UPDATE_DIALOG = '[ECM DEALS] Update Dialog';
export const SET_SELECTED_DEALS = '[ECM DEALS] Set Selected Deals';
export const SET_SELECTED_CONFIRMATION_IDS = '[ECM DEALS] Set Selected Confirmation Ids';
export const ADD_SELECTED_CONFIRM_ID = '[ECM DEALS] Add Selected Confirmation Id';
export const REMOVE_SELECTED_CONFIRM_ID = '[ECM DEALS] Remove Confirmation Id';
export const DOWNLOAD_XML = '[ECM DEALS] Download XML';
export const DOWNLOAD_HTML = '[ECM DEALS] Download HTML';
export const TERMINATE_CONFIRM = '[ECM DEALS ] Terminate Deal Confirm';
export const TERMINATE_CONFIRM_SUCCESS = '[ECM DEALS] Terminate Deal Confirm Success';
export const CONFIRM_ECM_LITE_CANCEL = '[ECM DEALS] Ecm Lite Cancel Confirm';
export const CONFIRM_ECM_LITE_CANCEL_SUCCESS = '[ECM DEALS] Ecm Lite Cancel Confirm Success';
export const SET_PARAMS = '[ECM DEALS] Set Params';
export const LOAD_NAV_STATES = '[ECM DEALS] Load nav states';
export const HIDE_CONFIRMATION = '[ECM DEALS] Hide Confirmation';
export const UNHIDE_CONFIRMATION = '[ECM DEALS] Unhide Confirmation';
export const LOAD_PRESETS = '[ECM DEALS] Load Presets';
export const LOAD_PRESETS_SUCCESS = '[ECM DEALS] Load Presets Success';

export class SortAndPageDataAction implements Action {
  readonly type = SORT_AND_PAGE_DATA;

  constructor(public payload: DatatableConfig) { }
}

export class SetParamsAction implements Action {
  readonly type = SET_PARAMS;

  constructor(public payload: { state: EcmStatesEnum, dateRange: EcmDateRange }) { }
}

export class LoadPresets implements Action {
  readonly type = LOAD_PRESETS;
}

export class LoadPresetsSuccess implements Action {
  readonly type = LOAD_PRESETS_SUCCESS;

  constructor(public rights: { termination: boolean, notes: boolean }) {
  }
}

export class SetMessagesAction implements Action {
  readonly type = SET_MESSAGES;

  constructor(public payload: Message[]) { }
}

export class LoadDataAction implements Action {
  readonly type = LOAD_DATA;
}

export class LoadDataSuccessAction implements Action {
  readonly type = LOAD_DATA_SUCCESS;

  constructor(public payload: EcmDashboardDealsResponse) { }
}

export class SetToDefaultAction implements Action {
  readonly type = SET_TO_DEFAULT;
}

export class SetColumnsAction implements Action {
  readonly type = SET_COLUMNS;

  constructor(public payload: Column[]) { }
}

export class ExportAction implements Action {
  readonly type = EXPORT;
}

export class AddNotesAction implements Action {
  readonly type = ADD_NOTES;

  constructor(public payload: AddNote) { }
}

export class OpenDialogAction implements Action {
  readonly type = OPEN_DIALOG;

  constructor(public payload: DialogState) { }
}

export class CloseDialogAction implements Action {
  readonly type = CLOSE_DIALOG;
}

export class UpdateDialogAction implements Action {
  readonly type = UPDATE_DIALOG;

  constructor(public payload: Message[]) { }
}

export class SetSelectedDealsAction implements Action {
  readonly type = SET_SELECTED_DEALS;
  constructor(public payload: {deals: EcmDealFinder[]}) { }
}

export class SetSelectedConfirmIdsAction implements Action {
  readonly type = SET_SELECTED_CONFIRMATION_IDS;
  constructor(public payload: {confirmationIds: string[]}) { }
}

export class AddSelectedConfirmIdAction implements Action {
  readonly type = ADD_SELECTED_CONFIRM_ID;

  constructor(public payload: { confirmationId: string }) { }
}

export class RemoveSelectedConfirmIdAction implements Action {
  readonly type = REMOVE_SELECTED_CONFIRM_ID;

  constructor(public payload: { confirmationId: string }) { }
}

export class DownloadXmlAction implements Action {
  readonly type = DOWNLOAD_XML;

  constructor(public payload: any) { }
}

export class DownloadHtmlAction implements Action {
  readonly type = DOWNLOAD_HTML;

  constructor(public payload: any) { }
}

export class TerminateConfirmAction implements Action {
  readonly type = TERMINATE_CONFIRM;

  constructor(public payload: TerminateDealRequest) { }
}

export class TerminateConfirmSuccessAction implements Action {
  readonly type = TERMINATE_CONFIRM_SUCCESS;

  constructor(public payload: TerminateDealRequest) { }
}

export class ConfirmEcmLiteCancelAction implements Action {
  readonly type = CONFIRM_ECM_LITE_CANCEL;

  constructor(public payload: { confirmationId: any }) { }
}

export class ConfirmEcmLiteCancelSuccessAction implements Action {
  readonly type = CONFIRM_ECM_LITE_CANCEL_SUCCESS;
}

export class LoadNavStates implements Action {
  readonly type = LOAD_NAV_STATES;
}

export class HideConfirmationAction implements Action {
  readonly type = HIDE_CONFIRMATION;
  constructor(public payload: HideConfirmation) { }
}

export class UnhideConfirmationAction implements Action {
  readonly type = UNHIDE_CONFIRMATION;
  constructor(public payload: UnhideConfirmation) { }
}

export type DealsActions = SetMessagesAction | LoadDataSuccessAction | SetToDefaultAction |
  SortAndPageDataAction | LoadDataAction | SetColumnsAction | AddNotesAction |
  OpenDialogAction | CloseDialogAction | UpdateDialogAction |
  AddSelectedConfirmIdAction | RemoveSelectedConfirmIdAction | SetSelectedConfirmIdsAction | DownloadXmlAction | DownloadHtmlAction
  | TerminateConfirmAction | TerminateConfirmSuccessAction | ConfirmEcmLiteCancelAction | SetSelectedDealsAction
  | ConfirmEcmLiteCancelSuccessAction | SetParamsAction | LoadNavStates | HideConfirmationAction | UnhideConfirmationAction
  | LoadPresets| LoadPresetsSuccess;

