import { ErrorResponse } from '@common/error-response.model';
import { Column } from '@common/shared/datatable.model';

export interface PresetValuesObj extends ErrorResponse {
  value: PresetValues;
}

export interface PresetValues {
  types: string[];
  commodities: string[];
  priceTypes: string[];
  natureOfPrices: string[];
  toggleMatchAllowed: boolean;
  editTypesAllowed: boolean;
}

export interface AddingInvoiceTypeRequest {
  commodity: string;
  natureOfPrice: string;
  priceType: string;
  type: string;
}

export interface AddingInvoiceTypeResponse extends ErrorResponse {
  value: AddingInvoiceTypeResponseValue;
}

export interface InvoiceTypeModel {
  commodity: string;
  doWeMatch: boolean;
  invoiceTypeId: number;
  matchedBy: number;
  natureOfPrice: string;
  priceType: string;
  type: string;
  deleteButtonVisible: boolean;
}

export interface AddingInvoiceTypeResponseValue extends ErrorResponse, InvoiceTypeModel {
}

export interface InvoiceTypeResponseValue extends ErrorResponse {
  values: InvoiceTypeModel[];
}

export interface EditInvoiceTypeResponseValue extends ErrorResponse {
  value: InvoiceTypeModel;
}

export interface DropDownItem { value: string; }

export const defaultStyles = {
  'text-align': 'left',
  'text-overflow': 'ellipsis',
  'overflow': 'hidden',
  'white-space': 'nowrap'
};

export const dataTableColumns: Column[] = [
  { name: 'invoiceTypeId', type: 'hidden', title: '', style: { ...defaultStyles }, sortable: false, visible: false },
  { name: 'type', type: 'text', title: 'Type', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'commodity', type: 'text', title: 'Commodity', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'priceType', type: 'text', title: 'Price Type', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'natureOfPrice', type: 'text', title: 'Nature Of Price', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'matchedBy', type: 'text', title: 'Matched by', style: { ...defaultStyles }, sortable: false, visible: true },
  {
    name: 'doWeMatch',
    type: 'status',
    title: 'We match',
    style: { ...defaultStyles, 'text-align': 'center' },
    sortable: false,
    visible: true
  },
  {
    name: '',
    type: 'deleteButton',
    title: 'Delete',
    style: { ...defaultStyles, 'text-align': 'center', 'max-width': '80px' },
    sortable: false,
    visible: true
  }
];

export type SETTINGS_DIALOGS = 'TOGGLE';

export const URL_GATEKEEPER_INVOICE = 'api/esm/invoice/gatekeeper';
