import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, combineLatest, concatMap,  map, switchMap,  withLatestFrom } from 'rxjs/operators';
import { Message } from 'primeng/api';
import { ApiRequestService } from '@common/api-request.service';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { DatatableConfig, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { ErrorResponse } from '@common/error-response.model';
import { emptyDealsValue, HiddenDeals, HideConfirmation, mapRequestValues, UnhideConfirmation } from '@common/deals/deals.model';
import { AllDealsMyFilterName, MyFilter } from '@common/my-filters/my-filters.model';
import { defaultByPropertiesFilters, EcmDealFinderState } from './deal-finder.reducers';
import {
  ecmDealsAllColumnsConfig,
  DealFinderFilters,
  DealFinderPropertiesFilters,
  DeleteMyFilterResponse,
  EcmDealFinderResponse,
  EcmMyFilter,
  EcmMyFiltersResponse,
  EcmPropertyFiltersValuesResponse,
  FilterByIdRequest,
  FilterByPropertiesRequest, TerminateDealResponseModel,
} from '../../deal-finder/deal-finder.model';
import * as fromActions from './deal-finder.actions';
import * as fromSelectors from './deal-finder.selectors';
import * as moment from 'moment';

import { updateFilters } from '../../ecm.model';
import { saveBlob, toPayload } from '@common/cms-common.model';
import { LOAD_PRESETS, LoadPresetsSuccess } from './deal-finder.actions';

@Injectable()
export class DealFinderEffects {
  private baseUrl = '/api/ecm/dealfinder';
  filterToUrlMap: { [key: string]: string } = {
    'ourTradeId': 'searchByOurTradeId',
    'theirTradeId': 'searchByTheirTradeId',
    'documentId': 'searchByDocIdUsiOrUti',
    'dealProperties': 'searchByFilter'
  };

  filterToExportUrlMap: { [key: string]: string } = {
    'ourTradeId': `${this.baseUrl}/downloadDealListByOurTradeId`,
    'theirTradeId': `${this.baseUrl}/downloadDealListByTheirTradeId`,
    'documentId': `${this.baseUrl}/downloadDealListByDocIdUsiOrUti`,
    'dealProperties': `${this.baseUrl}/downloadDealListByFilter`,
  };

  constructor(private apiGateway: ApiRequestService,
              private actions$: Actions,
              private store$: Store<EcmDealFinderState>,
              private router: Router,
              private mapper: MessagesMapperService) {
    this.saveReplaceFilterHandler = this.saveReplaceFilterHandler.bind(this);
  }


   unhideConfirmation$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.UNHIDE_CONFIRMATION),
      map(toPayload),
      switchMap(({ confirmationId }: UnhideConfirmation) =>
        this.apiGateway.post(`${this.baseUrl}/unhideConfirmation`, null,
          new HttpParams().set('confirmationId', confirmationId.toString()))
          .pipe(
            switchMap((response: ErrorResponse | any) => {
              const actions = [];
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                actions.push(new fromActions.UpdateDialogAction(messages));
              } else {
                actions.push(
                  new fromActions.CloseDialogAction(),
                  new fromActions.LoadDataAction()
                );
              }
              return actions;
            })
          )
      )
    ));

  load_presets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LOAD_PRESETS),
      switchMap(() =>
        this.apiGateway.get(`${this.baseUrl}/loadPresetValues`)
          .pipe(
            switchMap(response => {
              const messages: Message[] = this.mapper.toErrorMessages(response);

              const { hasTerminationRights, hasEditNoteRights } = response;

              const rights = { termination: hasTerminationRights, notes: hasEditNoteRights };

              return messages?.length ?
                of(new fromActions.SetMessagesAction(messages)) :
                of(new fromActions.LoadPresetsSuccess(rights));
            }),
            catchError(error => of(new fromActions.SetMessagesAction(this.mapper.toErrorMessages(error.errorMessage || 'Server Error')))),
          )
      )
    )
  );


   hideConfirmation$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.HIDE_CONFIRMATION),
      map(toPayload),
      switchMap(({reason, confirmationId}: HideConfirmation) => {
        let param = new HttpParams();
        param = param.set('reason', reason);
        param = param.set('confirmationId', confirmationId.toString());
        return this.apiGateway.post(`${this.baseUrl}/hideConfirmation`, null, param)
          .pipe(
            switchMap((response: ErrorResponse | any) => {
              const actions = [];
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                actions.push(new fromActions.UpdateDialogAction(messages));
              } else {
                actions.push(
                  new fromActions.CloseDialogAction(),
                  new fromActions.LoadDataAction()
                );
              }
              return actions;
            })
          );
      })
    ));

   setDefaultFilter$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.SET_DEFAULT_FILTER),
      map(toPayload),
      concatMap(({ filterName }: MyFilter) => {
        let param = null;
        if (filterName !== 'All Deals') {
          param = new HttpParams();
          param = param.set('filterName', filterName);
        }
        return this.apiGateway.post(`${this.baseUrl}/setNewDefaultFilter`, null, param)
          .pipe(
            switchMap((response: ErrorResponse | any) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [new fromActions.SetMessagesAction(messages)];
              }
              return [
                new fromActions.LoadMyFiltersAction(),
                new fromActions.SetMessagesAction([]),
                new fromActions.UpdateDialogAction(
                  this.mapper.toSuccessMessages(`"${filterName}" is your new default filter.`)),
              ];
            })
          );
      })
    ));

   selectMyFilter$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.SELECT_MY_FILTER),
      map(toPayload),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getEcmDealFinderState))),
      map(([myFilter, state]: [EcmMyFilter, EcmDealFinderState]) =>
        new fromActions.UpdateFiltersAction({
          filterBy: 'dealProperties',
          properties: updateFilters(state, state.propFilters, myFilter) as DealFinderPropertiesFilters
        })
      )));

   clearPropertiesFilters$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.CLEAN_BY_PROPERTIES_FILTERS),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getEcmDealFinderState))),
      map(([, state]: [any, EcmDealFinderState]) =>
        new fromActions.UpdateFiltersAction({
          filterBy: 'dealProperties',
          properties: updateFilters(state, defaultByPropertiesFilters, state.defaultFilter) as DealFinderPropertiesFilters
        })
      )));

   saveMyFilter$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.SAVE_MY_FILTERS),
      map(toPayload),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getEcmDealFinderState))),
      concatMap(([myFilter, state]: [EcmMyFilter, EcmDealFinderState]) =>
        this.apiGateway.post(`${this.baseUrl}/saveFilter`, this.mapMyFilterToRequest(state, myFilter))
          .pipe(
            switchMap((response: EcmMyFiltersResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [new fromActions.UpdateDialogAction(messages)];
              }
              return [
                new fromActions.ReloadMyFiltersAction(),
                new fromActions.UpdateDialogAction([]),
                new fromActions.CloseDialogAction()
              ];
            }),
            catchError(() => of(new fromActions.SetMessagesAction([this.mapper.createErrorMessage('Server error')])))
          )
      )));

   terminateDeal$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.TERMINATE_CONFIRM),
      concatMap(({ payload }: fromActions.TerminateConfirmAction) => {
        const params = new HttpParams();
        return this.apiGateway.post(`${this.baseUrl}/terminate`, payload, params)
          .pipe(
            switchMap((response: TerminateDealResponseModel) => {
              return this.createTerminationResponse(response);
            }),
            catchError(() => of(new fromActions.SetMessagesAction([this.mapper.createErrorMessage('Server error')])))
          );
      })));

   ecmLiteCancel$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.CONFIRM_ECM_LITE_CANCEL),
      concatMap((action: fromActions.ConfirmEcmLiteCancelAction) => {
        let params = new HttpParams();
        params = params.set('confirmationId', action.payload.confirmationId);

        return this.apiGateway.post(`${this.baseUrl}/cancel`, null, params)
          .pipe(
            switchMap((response: ErrorResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [
                  new fromActions.UpdateDialogAction(messages)
                ];
              }

              return [
                new fromActions.UpdateDialogAction(this.mapper.toSuccessMessages('Trade cancelled.')),
                new fromActions.ConfirmEcmLiteCancelSuccessAction()
              ];
            }),
            catchError(() => of(new fromActions.SetMessagesAction([this.mapper.createErrorMessage('Server error')])))
          );
      })));

   loadData$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.LOAD_DATA),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getEcmDealFinderState))),
      switchMap(([, state]: [fromActions.LoadDataAction, EcmDealFinderState]) => {
        const { paging, sorting, propFilters, filterBy, idFilters } = state;
        return this.loadData({
          ...idFilters,
          properties: { ...propFilters },
          filterBy
        }, paging, sorting, state);
      })
    ));

   replaceMyFilter$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.REPLACE_MY_FILTERS),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getEcmDealFinderState))),
      concatMap(([action, state]: [fromActions.ReplaceMyFiltersAction, EcmDealFinderState]) =>
        this.apiGateway.put(`${this.baseUrl}/replaceFilter`, this.mapMyFilterToRequest(state, action.payload))
          .pipe(
            switchMap((response: EcmMyFilter | ErrorResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response as ErrorResponse);
              if (messages && messages.length) {
                return [new fromActions.UpdateDialogAction(messages)];
              }

              return [
                new fromActions.LoadMyFiltersAction(),
                new fromActions.UpdateDialogAction([]),
                new fromActions.CloseDialogAction()
              ];
            }),
            catchError(() => of(new fromActions.SetMessagesAction([this.mapper.createErrorMessage('Server error')])))
          )
      )));

   deleteMyFilter$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.DELETE_MY_FILTER),
      concatMap((action: fromActions.DeleteMyFilterAction) => {
        const filterName = action.payload.filterName;
        let param = new HttpParams();
        param = param.set('filterName', filterName);
        return this.apiGateway.delete(`${this.baseUrl}/deleteFilter`, param)
          .pipe(
            switchMap((response: DeleteMyFilterResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [new fromActions.UpdateDialogAction(messages)];
              }

              return [
                new fromActions.DeleteMyFilterSuccessAction({ filterName }),
                new fromActions.UpdateDialogAction(this.mapper.toSuccessMessages(`Filter "${filterName}" deleted.`)),
              ];
            }),
            catchError(() => of(new fromActions.SetMessagesAction([this.mapper.createErrorMessage('Server error')])))
          );
      })));
   loadMyFilters$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.LOAD_MY_FILTERS),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getEcmDealFinderState))),
      switchMap(([, state]: [fromActions.LoadMyFiltersAction, EcmDealFinderState]) =>
        this.apiGateway.get(`${this.baseUrl}/filters`)
          .pipe(
            switchMap((response: EcmMyFiltersResponse) => {
              this.mapEmptyBrokersInMyFilters(response);
              const {
                broker,
                commodities,
                counterParties,
                markets,
                transactionTypes
              } = state;
              return [
                new fromActions.LoadMyFiltersSuccessAction({
                  ...response,
                  allDealsFilter: {
                    filterName: AllDealsMyFilterName,
                    defaultFilter: !!response.defaultFilter,
                    hiddenType: HiddenDeals.EXCLUDE,
                    brokerFilter: broker,
                    counterpartyFilter: counterParties,
                    commodityFilter: commodities,
                    marketFilter: markets,
                    transactionTypeFilter: transactionTypes
                  }
                })
              ];
            }),
            catchError(() => of(new fromActions.SetMessagesAction([this.mapper.createErrorMessage('Server error')])))
          )
      )));

   getFiltersSuccess$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.GET_FILTERS_VALUES_SUCCESS),
      combineLatest(this.actions$.pipe(ofType(fromActions.LOAD_MY_FILTERS_SUCCESS))),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getEcmDealFinderState))),
      map(([, state]: [any, EcmDealFinderState]) =>
        new fromActions.UpdateFiltersAction({
          properties: updateFilters(state, state.propFilters, state.defaultFilter) as DealFinderPropertiesFilters,
          filterBy: 'dealProperties'
        })
      )
    ));

   reloadMyFilters$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.RELOAD_MY_FILTER),
      switchMap(() =>
        this.apiGateway.get(`${this.baseUrl}/filters`)
          .pipe(
            map((response: EcmMyFiltersResponse) => {
              this.mapEmptyBrokersInMyFilters(response);
              return new fromActions.LoadMyFiltersSuccessAction(response);
            }),
            catchError(() => of(new fromActions.SetMessagesAction([this.mapper.createErrorMessage('Server error')])))
          )
      )));

   filtersValues$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.GET_FILTERS_VALUES),
      switchMap(() =>
        this.apiGateway.get(`${this.baseUrl}/filterValues`)
          .pipe(
            switchMap((response: EcmPropertyFiltersValuesResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [new fromActions.SetMessagesAction(messages)];
              }
              if (response.broker) {
                response.broker = response.broker.map(item => item || emptyDealsValue);
              }
              return [
                new fromActions.SetMessagesAction([]),
                new fromActions.GetFiltersValuesSuccessAction(response),
              ];
            }),
            catchError(error => {
              if (error.status === 400) {
                this.router.navigate(['/help/welcome'], { queryParamsHandling: 'preserve' });
                return [
                  new fromActions.SetMessagesAction([]),
                  new fromActions.SetToDefaultAction()];
              } else {
                return of(new fromActions.SetMessagesAction([
                  this.mapper.createErrorMessage(error.errorMessage)
                ]));
              }
            })
          )
      )));

   addNote$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.ADD_NOTES),
      concatMap((action: fromActions.AddNotesAction) =>
        this.apiGateway.post(`${this.baseUrl}/createNote`, action.payload)
          .pipe(
            switchMap((response: ErrorResponse & { value: any }) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return of(new fromActions.UpdateDialogAction(messages));
              }
              return [
                new fromActions.CloseDialogAction(),
                new fromActions.UpdateDialogAction([]),
                new fromActions.LoadDataAction(),
              ];
            })
          )
      )));

   export$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.EXPORT),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getEcmDealFinderState))),
      concatMap((data: [fromActions.ExportAction, EcmDealFinderState]) => {
        const { propFilters, idFilters, filterBy, sorting, paging } = data[1];
        const request = this.getFilterRequest({
          ...idFilters,
          properties: { ...propFilters },
          filterBy
        }, paging, sorting, data[1]);

        return this.apiGateway.getBlob(this.filterToExportUrlMap[filterBy], request, null)
          .pipe(
            switchMap((response: any) => {
              saveBlob(response);
              return of(new fromActions.SetMessagesAction([]));
            }),
            catchError(() => of(new fromActions.SetMessagesAction([this.mapper.createErrorMessage('Server error')]))));
      })));

   filter$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.FILTER),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getEcmDealFinderState))),
      switchMap(([action, state]: [fromActions.FilterAction, EcmDealFinderState]) => {
        const { sorting, paging } = state;
        return this.loadData(action.payload, paging, sorting, state);
      })
    ));

   sortAndPage$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.SORT_AND_PAGE_DATA),
      map(toPayload),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getEcmDealFinderState))),
      switchMap(([{ sorting, paging }, state]: [DatatableConfig, EcmDealFinderState]) =>
        this.loadData({
          ...state.idFilters,
          properties: { ...state.propFilters },
          filterBy: state.filterBy
        }, paging, sorting, state)
      )));

   downloadXml$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.DOWNLOAD_XML),
      map(toPayload),
      switchMap((confirmationId: string) =>
        this.apiGateway.getBlob(`${this.baseUrl}/downloadDocument`, null,
          new HttpParams().set('confirmationId', confirmationId))
          .pipe(switchMap((response: any) => {
              saveBlob(response);
              return of(new fromActions.SetMessagesAction([]));
            }),
            catchError(() => of(new fromActions.SetMessagesAction([this.mapper.createErrorMessage('Server error')])))
          )
      )));

   downloadHtml$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.DOWNLOAD_HTML),
      map(toPayload),
      switchMap((confirmationId: string) =>
        this.apiGateway.getBlob(`${this.baseUrl}/downloadHtmlDocument`, null,
          new HttpParams().set('confirmationId', confirmationId))
          .pipe(switchMap((response: any) => {
              const blob: Blob = response.body;
              return of(new fromActions.SetMessagesAction([]),new fromActions.SetHtmlAction(blob));
            }),
            catchError(() => {
              this.router.navigate(['/help/welcome'], { queryParamsHandling: 'preserve' });
               return of(new fromActions.SetMessagesAction([]));
            }))
      )));

  private loadData(filters: DealFinderFilters, paging: PagingFilter, sorting: SortingFilter, state: EcmDealFinderState) {
    const { filterBy } = filters;
    const request = this.getFilterRequest(filters, paging, sorting, state);
    return this.apiGateway
      .post(`${this.baseUrl}/${this.filterToUrlMap[filterBy]}`, request)
      .pipe(
        switchMap((response: EcmDealFinderResponse) => {
          const messages: Message[] = this.mapper.toErrorMessages(response);
          if (messages && messages.length) {
            return [new fromActions.SetMessagesAction(messages)];
          }

          return [
            new fromActions.SetColumnsAction(this.getColumns(response.columnOrder)),
            new fromActions.FilterDataSuccessAction(response),
            new fromActions.SetMessagesAction([]),
          ];
        }),
        catchError(error => of(new fromActions.SetMessagesAction([
          this.mapper.createErrorMessage(error.errorMessage)
        ]))));
  }

  private getFilterRequest(filters: DealFinderFilters, paging: PagingFilter, sorting: SortingFilter, state: EcmDealFinderState) {
    let request: FilterByIdRequest | FilterByPropertiesRequest;
    const { ourTradeId, theirTradeId, documentId, filterBy } = filters;

    if (filterBy === 'ourTradeId') {
      request = { paging, sorting, searchValue: ourTradeId };
    } else if (filterBy === 'theirTradeId') {
      request = { paging, sorting, searchValue: theirTradeId };
    } else if (filterBy === 'documentId') {
      request = { paging, sorting, searchValue: documentId };
    } else if (filterBy === 'dealProperties') {
      const {
        brokers,
        commodities,
        counterParties,
        deliveryStartDate,
        deliveryEndDate,
        hiddenDeals,
        markets,
        price,
        senderType,
        transactionTypes,
        submissionDateRange,
        tradeDateRange,
      } = filters.properties;
      request = {
        brokers: mapRequestValues(brokers, state.broker),
        commodities: mapRequestValues(commodities, state.commodities),
        counterParties: mapRequestValues(counterParties, state.counterParties),
        deliveryStartDate: deliveryStartDate ? moment(deliveryStartDate).format('YYYY-MM-DD') : null,
        deliveryEndDate: deliveryEndDate ? moment(deliveryEndDate).format('YYYY-MM-DD') : null,
        hiddenDeals,
        markets: mapRequestValues(markets, state.markets),
        price,
        senderType,
        transactionTypes: mapRequestValues(transactionTypes, state.transactionTypes),
        submissionDateRange: {
          start: submissionDateRange.start ? moment(submissionDateRange.start).format('YYYY-MM-DD') : null,
          end: submissionDateRange.end ? moment(submissionDateRange.end).format('YYYY-MM-DD') : null
        },
        tradeDateRange: {
          start: tradeDateRange.start ? moment(tradeDateRange.start).format('YYYY-MM-DD') : null,
          end: tradeDateRange.end ? moment(tradeDateRange.end).format('YYYY-MM-DD') : null
        },
        paging,
        sorting,
      } as FilterByPropertiesRequest;
    }

    return request;
  }

  private getColumns(columnsOrder: string[]) {
    return columnsOrder && columnsOrder.length ?
      columnsOrder.map(propName => ecmDealsAllColumnsConfig.find(c => c.name === propName)) :
      [];
  }

  private mapEmptyBrokersInMyFilters(response: EcmMyFiltersResponse) {
    if (response.defaultFilter && response.defaultFilter.brokerFilter) {
      response.defaultFilter.brokerFilter = response.defaultFilter
        .brokerFilter.map(item => item || emptyDealsValue);
      if (response.filterSelections) {
        Object.entries(response.filterSelections).forEach(([, value]: [string, EcmMyFilter]) => {
          if (value.brokerFilter) {
            value.brokerFilter = value.brokerFilter.map(item => item || emptyDealsValue);
          }
        });
      }
    }
  }

  private saveReplaceFilterHandler(response: EcmMyFiltersResponse) {
    const messages: Message[] = this.mapper.toErrorMessages(response);
    if (messages && messages.length) {
      return [new fromActions.UpdateDialogAction(messages)];
    }

    return [
      new fromActions.LoadMyFiltersSuccessAction(response),
      new fromActions.UpdateDialogAction([]),
      new fromActions.CloseDialogAction()
    ];
  }

  private createTerminationResponse(response: ErrorResponse){
    const messages: Message[] = [];

    if(response.fieldValidationMessages) {
      for (const key of Object.keys(response.fieldValidationMessages)) {
        const severity = response.fieldValidationMessages[key];
        messages.push({ severity: severity, data: key });
      }

      return [new fromActions.UpdateDialogAction(messages)];
    }

    return [
      new fromActions.TerminateConfirmSuccessAction({ reason: '', confirmationIds: []}),
      new fromActions.UpdateDialogAction(this.mapper.toSuccessMessages('All selected Deals terminated successfully. Note: It may take a few moments until this is reflected in the user interface.')),
      new fromActions.LoadDataAction()
    ];
  }

  private mapMyFilterToRequest(state: EcmDealFinderState, filter: EcmMyFilter): EcmMyFilter {
    const {
      filterName,
      defaultFilter,
      counterpartyFilter,
      brokerFilter,
      hiddenType,
      commodityFilter,
      marketFilter,
      transactionTypeFilter
    } = filter;
    return {
      filterName,
      defaultFilter,
      commodityFilter: commodityFilter && state.commodities ? commodityFilter.length === state.commodities.length ? null : commodityFilter : [],
      transactionTypeFilter: transactionTypeFilter && state.transactionTypes ? transactionTypeFilter.length === state.transactionTypes.length ? null : transactionTypeFilter : [],
      marketFilter: marketFilter && state.markets ? marketFilter.length === state.markets.length ? null : marketFilter : [],
      brokerFilter: brokerFilter && state.broker ? brokerFilter.length === state.broker.length ? null : brokerFilter : [],
      hiddenType,
      counterpartyFilter: counterpartyFilter && state.counterParties ? counterpartyFilter.length === state.counterParties.length ? null : counterpartyFilter : [],
    };
  }
}
