import { DatatableState } from '../datatable/datatable.reducer';
import { orgGroupsReducer } from '../organisation-groups/organisation-groups.reducers';
import { organisationIdReducer } from '../organisation-id/organisation-id.reducers';
import {
  Contacts,
  ContactsFilters,
  OrganisationGroup,
  UserContacts,
  UserContactsFilters
} from '../../contacts/contacts.model';
import { userDatatableInitialState, userDatatableReducer } from '../user/user-dt.reducers';
import { orgGroupDatatableInitialState, orgGroupsDatatableReducer } from '../org-group-dt/org-group-dt.reducers';

export interface ContactsState {
  orgGroupsDt: DatatableState<Contacts, ContactsFilters>;
  usersDt: DatatableState<UserContacts, UserContactsFilters>;
  organisationId: number;
  orgGroups: OrganisationGroup[];
}

export const initialContactsState: ContactsState = {
  organisationId: null,
  orgGroups: [],
  orgGroupsDt: orgGroupDatatableInitialState,
  usersDt: userDatatableInitialState
};

export function contactsReducer(state: ContactsState = initialContactsState,
                                   action: any): ContactsState {
  return {
    orgGroupsDt: orgGroupsDatatableReducer(state.orgGroupsDt, action),
    usersDt: userDatatableReducer(state.usersDt, action),
    organisationId: organisationIdReducer(state.organisationId, action),
    orgGroups: orgGroupsReducer(state.orgGroups, action)
  };
}
