import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { OrganisationGroup } from '../../administration.model';

@Component({
  selector: 'cms-organisation-group-filter',
  templateUrl: './organisation-group-filter.component.html',
  styleUrls: ['./organisation-group-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganisationGroupFilterComponent implements OnInit, OnChanges {
  @Input() filters: string;
  @Input() orgGroups: OrganisationGroup[];
  @Output() filter = new EventEmitter<string>();
  orgGroupsOptions: {label: string, value: any}[] = [];
  form: UntypedFormGroup;


  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      organisationGroupId: this.filters,
    });

    this.initGroups();
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (changes.orgGroups) {
      this.initGroups();
    }

    if (this.form && changes.filters) {
      this.form.setValue({
        organisationGroupId: this.filters
      });
      this.form.updateValueAndValidity();
    }
  }

  onChange(): void {
    this.filter.emit(this.form.value.organisationGroupId);
  }

  private initGroups(): void {
    this.orgGroupsOptions = this.orgGroups && this.orgGroups.length ?
      this.orgGroups.map(item => ({ value: item.key, label: item.value })) : [];
    this.orgGroupsOptions = [{ label: 'All Organisation Groups', value: null }, ...this.orgGroupsOptions];
  }

}
