import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DealFinderComponent } from './deal-finder/deal-finder.component';
import { EnterTradeComponent } from './enter-trade/enter-trade.component';
import { ReportsComponent } from './reports/reports.component';
import { EcmSettingsTabPanelComponent } from './settings/ecm-settings-tab-panel/ecm-settings-tab-panel.component';
import { EcmDashboardComponent } from './ecm-dashboard/ecm-dashboard.component';
import { EcmDashboardDealsComponent } from './ecm-dashboard/ecm-dashboard-deals/ecm-dashboard-deals.component';
import { MonitoringComponent } from './monitoring/monitoring.component';
import { TabToTitleMap } from './ecm-dashboard/ecm-dashboard.model';
import { EcmProductsComponent } from './products/ecm-products.component';
import { AuthorisationGuard } from '../auth/authorisation.guard';

const routes: Routes = [
  { path: 'dealfinder', component: DealFinderComponent, canActivate: [AuthorisationGuard] },
  { path: 'trade', component: EnterTradeComponent, canActivate: [AuthorisationGuard] },
  { path: 'trade/entry/:id', component: EnterTradeComponent, canActivate: [AuthorisationGuard] },
  { path: 'reports', component: ReportsComponent, canActivate: [AuthorisationGuard] },
  { path: 'products', component: EcmProductsComponent, data: { title: 'eCM Product Maintenance' }, canActivate: [AuthorisationGuard] },
  { path: 'settings', redirectTo: 'settings/process', pathMatch: 'full', data: { panel: 'process'} },
  { path: 'settings/process', component: EcmSettingsTabPanelComponent, data: { panel: 'process'}, canActivate: [AuthorisationGuard] },
  { path: 'settings/products', component: EcmSettingsTabPanelComponent, data: { panel: 'products'}, canActivate: [AuthorisationGuard] },
  { path: 'settings/brokerRestrictions', component: EcmSettingsTabPanelComponent, data: { panel: 'brokerRestrictions'}, canActivate: [AuthorisationGuard] },
  { path: 'dashboard', redirectTo: 'dashboard/inprogress', pathMatch: 'full', data: { panel: 'inProgress' } },
  { path: 'dashboard/inprogress', component: EcmDashboardComponent, data: { panel: 'inprogress', title: TabToTitleMap['inprogress']}, canActivate: [AuthorisationGuard] },
  { path: 'dashboard/issue', component: EcmDashboardComponent, data: { panel: 'issue', title: TabToTitleMap['issue']}, canActivate: [AuthorisationGuard] },
  { path: 'dashboard/complete', component: EcmDashboardComponent, data: { panel: 'complete', title: TabToTitleMap['complete']}, canActivate: [AuthorisationGuard] },
  { path: 'dashboard/inprogress/deals', component: EcmDashboardDealsComponent, data: { panel: 'inprogress'} , canActivate: [AuthorisationGuard] },
  { path: 'dashboard/issue/deals', component: EcmDashboardDealsComponent, data: { panel: 'issue'}, canActivate: [AuthorisationGuard] },
  { path: 'dashboard/complete/deals', component: EcmDashboardDealsComponent, data: { panel: 'complete'}, canActivate: [AuthorisationGuard] },
  { path: 'monitoring', component: MonitoringComponent, canActivate: [AuthorisationGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EcmRoutingModule { }
