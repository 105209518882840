<div class="panel-box margin-bottom-15">
  <form [formGroup]="form">
    <fieldset>
      <legend class="font-weight-bold" style="font-size: 14px" >View by</legend>
      <div class="row ">
        <div class="col-12">
          <label><input type="radio"
                        id="tradeDateRadio"
                        value="TRADE_DATE"
                        formControlName="viewBy"
                        name="viewBy"/>Trade Date</label>
          </div>
        <div class="col-12">
          <label><input type="radio"
                        id="submissionDateRadio"
                        value="SUBMISSION_DATE"
                        formControlName="viewBy"
                        name="viewBy"/>Submission Date</label>
        </div>
      </div>
    </fieldset>
  </form>
</div>
