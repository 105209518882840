import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { Message } from 'primeng/api';
import { MessagesMapperService } from '../../../../../common/messages-mapper.service';
import { DeleteOrganisation, Organisation } from '../../organisations.model';

@Component({
  selector: 'cms-delete-organisation-dialog',
  templateUrl: './delete-organisation-dialog.component.html',
  styleUrls: ['./delete-organisation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteOrganisationDialogComponent implements OnInit, OnChanges {
  @Input() data: Organisation;
  @Input() display = false;
  @Input() messages: Message[];
  @Input() changeToExternalPartyAllowed = false;
  @Output() displayChange = new EventEmitter<boolean>();
  @Output() confirm = new EventEmitter<DeleteOrganisation>();
  @Output() cancel = new EventEmitter<Message[]>();
  errors: {[key: string]: ValidationErrors} = {};
  form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder,
              private mapper: MessagesMapperService) {
  }

  onCancel(): void {
    this.hide();
    this.cancel.emit(this.mapper.toWarningMessages(`Deleting of organisation '${this.data.displayName}' was cancelled.`));
  }

  onVisibleChange(visible: boolean): void {
    if (!visible) {
      this.onCancel();
    }
  }

  onConfirm(): void {
    const out: DeleteOrganisation = {
      ...this.form.value,
      changeToExternalParty: this.changeToExternalPartyAllowed ?
        this.form.value.changeToExternalParty : false
    };

    out.organisationIdForDeleting = this.data.orgId;

    this.confirm.emit(out);
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      changeToExternalParty: {
        value: this.changeToExternalPartyAllowed ? true : null,
        disabled: !this.changeToExternalPartyAllowed
      },
      reason: ''
    });
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (this.form) {
      if (changes.display && !this.display) {
        this.hide();
      }
      if (changes.data) {
        this.form.updateValueAndValidity();
      }

      if (changes.changeToExternalPartyAllowed) {
        this.form.patchValue( {
          value: this.changeToExternalPartyAllowed ? true : null,
          disabled: !this.changeToExternalPartyAllowed
        });
        this.form.updateValueAndValidity();
      }
    }

    if (changes.messages) {
      this.errors = {};
      if (this.messages) {
        this.messages.forEach((obj: Message) => {
          const { key: controlName } = obj;
          this.errors[controlName] = { 'server': true };
        });
      }
    }
  }

  private hide(): void {
    this.messages = [];
    this.display = false;
    this.displayChange.emit(this.display);
    this.form.reset();
    this.form.updateValueAndValidity();
  }
}
