<div style="max-width: 585px;" class="trade-reference">
  <div class="form-title">Trade | References</div>
  <div class="form-body" [formGroup]="formGroup">
    <div class="row gutter-0 !me-[40px]">
      <div class="col-5 label">Transaction time (UTC) <span class="text-required">*</span></div>
      <div class="col-7 ps-2 pe-1">
         <span id="remit-trade-entry-transactionDate" class="inline-block padding-right-3 margin-3">
                <p-calendar
                  formControlName="transactionDate"
                  [showIcon]="true"
                  [monthNavigator]="true"
                  [yearNavigator]="true"
                  dateFormat="dd/mm/yy"
                  [style]="{ 'width': '125px'}"
                  [styleClass]="appendValidationErrorClass('transactionDate')"
                  cmsDateRestriction>
                </p-calendar>
        </span>
        <span id="remit-trade-entry-transactionTime" class="inline-block padding-left-3 margin-3">
          <p-inputMask formControlName="transactionTime" mask="99:99:99" pattern="(([0-1]?[0-9])|(2[0-3])):[0-5][0-9]:[0-5][0-9]" placeholder="hh:mm:ss" slotChar="hh:mm:ss" [styleClass]="'text-small padding-6 text-center ' + appendValidationErrorClass('transactionTime')" [style]="{ 'width': '75px'}"></p-inputMask>
        </span>
      </div>
      <span *ngIf="appendValidationErrorClass('transactionDate') || appendValidationErrorClass('transactionTime')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0 !me-[40px]">
      <div class="col-5 label">Trader ID <span class="text-required">*</span></div>
      <div class="col-7 ps-2 pe-1">
        <input id="remit-trade-entry-traderId" type="text" class="form-control p-component padding-6 text-medium margin-3" formControlName="traderId" maxlength="100" [ngClass]="appendValidationErrorClass('traderId')">
      </div>
      <span *ngIf="appendValidationErrorClass('traderId')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0 !me-[40px]">
      <div class="col-5 label">Beneficiary</div>
      <div id="remit-trade-entry-beneficiaryType" class="col-2 ps-2 pe-0">
        <p-dropdown [options]="beneficiaries" formControlName="beneficiaryCodeType" [styleClass]="'margin-3 full-width '"></p-dropdown>
      </div>
      <div id="remit-trade-entry-beneficiarCode" class="col-5 ps-2 pe-1">
        <input type="text" class="form-control padding-6 text-medium margin-3" formControlName="beneficiaryCode" [ngClass]="{'error':formGroup.controls['beneficiaryCode'].hasError('error')}">
      </div>
      <span *ngIf="formGroup.controls['beneficiaryCode'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0 !me-[40px]" *ngIf="hasLinkedTransactionId">
      <div class="col-5 label">Linked Transaction ID <span class="text-required">*</span></div>
      <div id="remit-trade-entry-linkedTransactionId" class="col-7 ps-2 pe-1">
        <input type="text" class="form-control padding-6 text-medium margin-3" formControlName="linkedTransactionId" [ngClass]="{'error':formGroup.controls['linkedTransactionId'].hasError('error')}">
      </div>
      <span *ngIf="formGroup.controls['linkedTransactionId'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
  </div>
</div>
