import { Message } from 'primeng/api';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { OrganisationService } from '@common/organisation.service';
import { Column } from '@common/shared/datatable.model';
import { DialogState } from '@common/state/reducers';
import { OrganisationModel } from '../../common/organisation.model';
import { Exception, ExceptionFilter, dataTableColumns, EditedItem, SelectedException } from './exceptions.model';
import { ExceptionsState, EXCEPTIONS_DIALOGS } from '../state/exceptions/exceptions.reducers';
import * as fromActions from '../state/exceptions/exceptions.actions';
import * as fromSelectors from '../state/exceptions/exceptions.selectors';
import { DatatableData, PagingFilter } from '../../common/shared/results.model';

@Component({
  selector: 'cms-exceptions',
  templateUrl: './exceptions.component.html',
  styleUrls: ['./exceptions.component.scss']
})
export class ExceptionsComponent implements OnInit, OnDestroy {
  data$: Observable<DatatableData<Exception[]>> = this.store.pipe(select(fromSelectors.getData));
  dialog$: Observable<DialogState<EXCEPTIONS_DIALOGS>> = this.store.pipe(select(fromSelectors.getDialog));
  messages$: Observable<Message[]> = this.store.pipe(select(fromSelectors.getMessages));
  loading$: Observable<boolean> = this.store.pipe(select(fromSelectors.getLoading));
  paging$: Observable<PagingFilter> = this.store.pipe(select(fromSelectors.getPaging));
  disabled$: Observable<boolean> = this.store.pipe(select(fromSelectors.getDisabled));
  avalableQueues$: Observable<string[]> = this.store.pipe(select(fromSelectors.getAvalableQueues));
  filtersValue$: Observable<ExceptionFilter> = this.store.pipe(select(fromSelectors.getFilters));
  subscription$: Subscription;
  selectedItems$: Observable<SelectedException> = this.store.pipe(select(fromSelectors.getSelected));
  editedItems$: Observable<EditedItem[]> = this.store.pipe(select(fromSelectors.getEditedItems));
  dataTableColumns: Column[] = dataTableColumns;

  constructor(private readonly organisationService: OrganisationService,
              private store: Store<ExceptionsState>) { }

  ngOnInit(): void {
    this.store.dispatch(fromActions.setOrganisationId(this.organisationService.getCachedOrganisationId()));
    this.store.dispatch(fromActions.getAvailableQueues());
    this.onOgranisationChangeSubscription();
  }

  ngOnDestroy(): void {
    this.store.dispatch(fromActions.setToDefault());
    this.subscription$.unsubscribe();
  }

  onPageChange(event: PagingFilter): void {
    this.store.dispatch(fromActions.setPaging(event));
    this.store.dispatch(fromActions.setSelectedItems());
  }

  onSort(): void {
    this.store.dispatch(fromActions.setSelectedItems());
  }

  onHandleFilter(data: ExceptionFilter): void {
    this.store.dispatch(fromActions.setFiltersValue(data));
    this.store.dispatch(fromActions.filter(data));
  }

  onShowDetails(data: Exception): void {
    this.store.dispatch(fromActions.openDialogAction({
      name: 'INFO',
      data
    }));
  }

  onSelectToggle(checked: boolean): void {
    this.store.dispatch(fromActions.toggleCheckAllAction(checked));
  }

  onCheck(checked: boolean, id: string): void {
      this.store.dispatch(checked ? fromActions.checkAction(id) : fromActions.uncheckAction(id));
  }

  onHandleDelete(): void {
    this.store.dispatch(fromActions.openDialogAction({
      name: 'DELETE',
      messages: []
    }));
  }

  onConfirmDelete(): void {
    this.store.dispatch(fromActions.deleteExceptions());
  }

  onDialogCancel(): void {
    this.store.dispatch(fromActions.closeDialogAction());
  }

  onHandleRetry(): void {
    this.store.dispatch(fromActions.retryExceptions());
  }

  private onOgranisationChangeSubscription(): void {
    this.subscription$ = this.organisationService
      .getChangeOrganisationObservable()
      .pipe(withLatestFrom(this.store.pipe(select(fromSelectors.getOrganisationId))))
      .subscribe(([organisation, organisationId]: [OrganisationModel, number]) => {
        if ((organisation && organisation.organisationId !== organisationId) || (!organisation && organisationId)) {
          this.store.dispatch(fromActions.setToDefault());
          this.store.dispatch(fromActions.setOrganisationId(organisation ? organisation.organisationId : null));
        }
      });
  }
}
