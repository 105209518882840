import { createAction, props, union } from '@ngrx/store';
import { Message } from 'primeng/api';
import { DatatableConfig } from '@common/shared/results.model';
import { Column } from '@common/shared/datatable.model';
import {
  EsmNettedInvoices,
  EsmNettedInvoicesTableDataResponse,
  NettedInvoicesRequestModel
} from '../../netted-invoices/netted-invoices.model';
import { AddNote } from '@common/deals/deals.model';

export const setRequestModelAction = createAction('[ESM-NTT-PREVIEW] Set request model', (payload: NettedInvoicesRequestModel) => ({payload}));
export const loadDataAction = createAction('[ESM-NTT-PREVIEW] Load Invoice Data');
export const loadDataSuccessAction = createAction('[ESM-NTT-PREVIEW] Load Invoice Data Success', (payload: EsmNettedInvoicesTableDataResponse) => ({ payload }));
export const setMessagesAction = createAction('[ESM-NTT-PREVIEW] Set Messages', (payload: Message[]) => ({payload}));
export const sortAndPageDataAction = createAction('[ESM-NTT-PREVIEW] Sort And Page Data', (payload: DatatableConfig) => ({payload}));
export const setColumnsAction = createAction('[ESM-NTT-PREVIEW] Set Columns', (payload: Column[]) => ({payload}));
export const exportAction = createAction('[ESM-NTT-PREVIEW] Export');
export const setSelectedIdsAction = createAction('[ESM-NTT-PREVIEW] Set Selected Ids Action', (payload: { confirmationIds: string[] }) => ({payload}));
export const setSelectedInvoicesAction = createAction('[ESM-NTT-PREVIEW] Set Selected Invoices Action', (payload: { invoices: EsmNettedInvoices[] }) => ({payload}));
export const setLoadingAction = createAction('[ESM-NTT-PREVIEW] Set Loading', (payload: boolean) => ({ payload }));
export const setHeaderAction = createAction('[ESM-NTT-PREVIEW] Set Header', (payload: string) => ({ payload }));
//
export const addNoteSuccessAction = createAction('[ESM-NTT-PREVIEW] Add Note Success');
export const addNoteAction = createAction('[ESM-NTT-PREVIEW] Add Note', (payload: AddNote) => ({payload}));
//
export const downloadHtmlAction = createAction('[ESM-NTT-PREVIEW] Download Html', (payload: number) => ({payload}));
export const downloadXmlAction = createAction('[ESM-NTT-PREVIEW] Download Xml', (payload: number) => ({payload}));
export const downloadPdfAction = createAction('[ESM-NTT-PREVIEW] Download Pdf', (payload: number) => ({payload}));

const actions = union({
  loadDataAction,
  loadDataSuccessAction,
  setMessagesAction,
  sortAndPageDataAction,
  setColumnsAction,
  exportAction,
  setSelectedIdsAction,
  setSelectedInvoicesAction,
  setLoadingAction,
  setHeaderAction,
  downloadHtmlAction,
  downloadXmlAction,
  downloadPdfAction,
  addNoteSuccessAction,
  addNoteAction
});

export type NettingPreviewInvoicesTableActions = typeof actions;
