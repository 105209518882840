<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>

  <p-tabView [activeIndex]="activeIndex" (onChange)="onChangeTab($event)">
    <p-tabPanel header="Internal Master Data" *ngIf="internalVisible">
      <div class="tab-view-body">
        <div class="tab-view-body">
          <cms-internal-master-data *ngIf="activeIndex === 0"></cms-internal-master-data>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="External Master Data" *ngIf="externalVisible">
      <div class="tab-view-body">
        <div class="tab-view-body">
          <cms-external-master-data *ngIf="activeIndex === 1"></cms-external-master-data>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>

</ng-template>