import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {withLatestFrom} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {Message} from 'primeng/api';
import {OrganisationService} from '@common/organisation.service';
import {OrganisationModel} from '../../../common/organisation.model';
import {DatatableData, PagingFilter, SortingFilter} from '@common/shared/results.model';
import {dataTableColumns, PartyData, PartyDataEditableModel} from './party-data.model';
import {Column} from '@common/shared/datatable.model';
import {DatatableState} from '@common/datatable/datatable.model';
import {EditableCell} from '../../../common/datatable/cell-editor/cell-editor.model';
import {Prefix} from '../../state/reducers';
import {PartyDataState} from '../../state/party-data/party-data.reducers';
import {SetOrganisationIdAction} from '../../state/organisation-id/organisation-id.actions';
import {SetToDefaultAction} from '../../state/shared/shared.actions';
import * as partyDataActions from '../../state/party-data/party-data.actions';
import * as fromDtActions from '../../state/datatable/datatable.actions';
import * as fromSelectors from '../../state/party-data/party-data.selectors';

@Component({
  selector: 'cms-party-data',
  templateUrl: './party-data.component.html',
  styleUrls: ['./party-data.component.scss']
})
export class PartyDataComponent implements OnInit, OnDestroy {
  data$: Observable<DatatableData<PartyData[]>> = this.store.pipe(select(fromSelectors.getData));
  paging$: Observable<PagingFilter> = this.store.pipe(select(fromSelectors.getPaging));
  sorting$: Observable<SortingFilter> = this.store.pipe(select(fromSelectors.getSorting));
  loading$: Observable<boolean> = this.store.pipe(select(fromSelectors.getLoading));
  dataTableColumns: Column[] = dataTableColumns;
  messages$: Observable<Message[]> = this.store.pipe(select(fromSelectors.getDtMessages));
  addData$: Observable<PartyDataEditableModel> = this.store.pipe(select(fromSelectors.getAddPartyData));
  addPartyDataMessages$: Observable<Message[]> = this.store.pipe(select(fromSelectors.getMessages));
  subscription$: Subscription;
  private readonly prefix: Prefix = '[PARTY DATA]';

  constructor(private readonly store: Store<PartyDataState>,
              private readonly organisationService: OrganisationService) { }

  ngOnInit(): void {
    this.store.dispatch(new SetOrganisationIdAction(this.prefix, this.organisationService.getCachedOrganisationId()));
    this.store.dispatch(new fromDtActions.LoadDataAction(this.prefix));
    this.onOgranisationChangeSubscription();
  }

  ngOnDestroy(): void {
    this.store.dispatch(partyDataActions.setMessagesAction([]));
    this.store.dispatch(new SetToDefaultAction(this.prefix));
    this.subscription$.unsubscribe();
  }

  onLazyLoad(state: DatatableState): void {
    this.store.dispatch(new fromDtActions.SortAndPageDataAction(this.prefix, {
      paging: {
        page: state.page,
        entriesPerPage: state.perPage
      },
      sorting: {
        columnName: state.sortField,
        sortOrder: state.sortOrder
      }
    }));
  }

  onTextFieldFilter(filters: string): void {
    const value = filters ? filters.toUpperCase() : filters;
    this.store.dispatch(new fromDtActions.FilterDataAction(this.prefix, value));
  }

  onDeleteItem(data: PartyData): void {
    this.store.dispatch(partyDataActions.deletePartyDataAction(data.partyCode));
  }

  onConfirmAddPartyData(value: PartyDataEditableModel): void {
    this.store.dispatch(partyDataActions.updateAddPartyDataAction(value));
    this.store.dispatch(partyDataActions.addPartydataAction(value));
  }

  onClearAddPartyData(): void {
    this.store.dispatch(partyDataActions.updateAddPartyDataAction({ displayName: '', partyCode: '' }));
    this.store.dispatch(partyDataActions.setMessagesAction([]));
  }

  onCompleteEdit(editingCell: EditableCell): void {
    if(!editingCell) { return; }
    const { column, newValue, data: { partyCode, displayName} } = editingCell;
    const newData = { partyCode, displayName };
    newData[column.name] = newValue.toUpperCase();
    this.store.dispatch(partyDataActions.editPartyDataAction(newData));
  }

  onCancelEdit(): void {
    this.store.dispatch(new fromDtActions.SetMessagesAction(this.prefix, []));
  }

  private onOgranisationChangeSubscription() {
    this.subscription$ = this.organisationService
    .getChangeOrganisationObservable()
    .pipe(withLatestFrom(this.store.pipe(select(fromSelectors.getOrganisationId))))
    .subscribe(([organisation, organisationId]: [OrganisationModel, number]) => {
      if ((organisation && organisation.organisationId !== organisationId) || (!organisation && organisationId)) {
        this.store.dispatch(new SetToDefaultAction(this.prefix));
        this.store.dispatch(new SetOrganisationIdAction(this.prefix, organisation ? organisation.organisationId : null));
        this.store.dispatch(new fromDtActions.LoadDataAction(this.prefix));
      }
    });
  }
}
