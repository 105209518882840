import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoboDelegatorsComponent } from './robo-delegators.component';
import { CmsCommonModule } from '@common/cms-common.module';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RoboDelegatorsDatatableComponent } from './datatable/robo-delegators-datatable.component';
import { RoboDelegatorsService } from './robo-delegators.service';
import { RoboDelegatorsUploadComponent } from './upload/robo-delegators-upload.component';
import { TableModule } from 'primeng/table';
import { StoreModule } from '@ngrx/store';
import { metaReducers } from '@common/state/reducers';
import { EffectsModule } from '@ngrx/effects';
import { RoboDelegatorsUploadEffects } from './upload/store/robo-delegators-upload.effects';
import { reducers } from './upload/store';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { StandingInstructionsModule } from '../../standing-instructions/standing-instructions.module';
import { DialogModule } from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import { StandingInstructionsEditorComponent } from '../../standing-instructions/form/standing-instructions-editor.component';

@NgModule({
  imports: [
    CommonModule,
    CmsCommonModule,
    BrowserModule,
    FormsModule,
    MessageModule,
    MessagesModule,
    ReactiveFormsModule,
    TableModule,
    StoreModule.forFeature('robo-delegators', reducers, {metaReducers}),
    EffectsModule.forFeature([
      RoboDelegatorsUploadEffects
    ]),
    DialogModule,
    StandingInstructionsModule,
    ButtonModule,
  ],
  providers: [
    RoboDelegatorsService
    ],
  declarations: [RoboDelegatorsComponent, RoboDelegatorsDatatableComponent, RoboDelegatorsUploadComponent]
})

export class RoboDelegatorsModule {
}
