<div style="max-width: 585px;" class="fixing-index" *ngIf="bilateral">
  <div class="form-title">Trade | Commercials | Fixing Indices</div>
  <div class="form-body" [formGroup]="formGroup">
    <div formArrayName="fixingIndices">
      <div *ngFor="let indice of indices; let i = index" [formGroupName]="i">
        <div class="form-subtitle">
          <div class="row">
          <div class="col-5">Fixing Index {{i + 1 }}</div>
          <div id="indexValueNumber" class="col-7" style="text-align: right">{{fixingIndices[i]?.indexValues?.length > 1 ? '1 of ' + fixingIndices[i]?.indexValues.length + ' index values visible' : ''}}</div></div>
        </div>
        <div class="row gutter-0 !me-[40px]">
          <div class="col-5 label">Index Name<span class="text-required"> *</span></div>
          <div class="col-7 ps-2 pe-1">
            <p-dropdown id="indexName.{{i+1}}" [options]="indexNames" [filter]="true" [filterFields]="['label']" formControlName="indexName" [group]="true" [panelStyleClass]="'full-width'" [styleClass]="'margin-3 full-width '  + appendValidationErrorClassOfArray(i, 'indexName' )"></p-dropdown>
          </div>
        </div>
        <div formArrayName="indexValues">
          <div class="row gutter-0 !me-[40px] bottom-row-border" formGroupName="0">
            <div class="col-5 label ">Index Value</div>
            <div class="row col-7 gutter-0 ps-2 pe-1">
              <div class="col-8">
                <input id="indexValue.{{i+1}}.1" type="number" class="form-control padding-6 text-medium text-start margin-3"
                       [ngClass]="appendValidationErrorClassOfArray(i, 'indexValue')" formControlName="indexValue">
              </div>
              <div class="col-4 ps-2">
                <p-dropdown id="indexCurrency.{{i+1}}.1" [options]="currencies" [group]="true" formControlName="indexCurrency"
                            [styleClass]="'margin-3 full-width'"></p-dropdown>
              </div>
            </div>
          </div>
          <span *ngIf="appendValidationErrorClassOfArray(i, 'indexName')" class="fa fa-info-circle absolute error-marker"></span>
        </div>
      </div>
      <div class="form-body">
        <div class="row gutter-0 !me-[40px]">
          <div class="col-5 label"></div>
          <div class="col-7 ps-2 pe-1">
            <button id="addIndexButton" type="button" class="btn add-remove-line" (click)="add(null)" [disabled]="disableAddButton">Add</button>
            <button id="removeIndexButton" type="button" class="btn add-remove-line" (click)="remove()" [disabled]="disableRemoveButton">Remove</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
