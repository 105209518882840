import { esmInvoiceFinderFiltersReducer, EsmInvoiceFinderFiltersState, filtersInitialState } from './filters/filters.reducers';
import { esmInvoiceFinderDialogReducer } from './dialog/dialog.reducers';
import { esmInvoiceFinderMyFiltersReducer, EsmInvoiceFinderMyFiltersState, myFilterInitialState } from './my-filters/my-filters.reducers';
import { EsmInvoiceTableState, tableInitialState, tableReducer } from './table/table.reducer';
import { INVOICE_FINDER_DIALOGS } from '../../invoice/finder/invoice-finder.model';
import * as fromCommon from '@common/state/reducers';
import * as fromActions from './invoice-finder.actions';
import * as fromIFTableActions from './table/table.actions';

export interface EsmInvoiceFinderState {
  filters: EsmInvoiceFinderFiltersState;
  myFilters: EsmInvoiceFinderMyFiltersState;
  dialog: fromCommon.DialogState<INVOICE_FINDER_DIALOGS>;
  table: EsmInvoiceTableState;
}

export const initialState: EsmInvoiceFinderState = {
  filters: filtersInitialState,
  myFilters: myFilterInitialState,
  dialog: null,
  table: tableInitialState
};

export function esmInvoiceFinderReducer(
  state: EsmInvoiceFinderState = { ...initialState },
  action: fromActions.InvoiceFinderActions & fromIFTableActions.InvoiceFinderTableActions): EsmInvoiceFinderState {
  return {
    ...state,
    filters: esmInvoiceFinderFiltersReducer(state.filters, action),
    dialog: esmInvoiceFinderDialogReducer(state.dialog, action),
    myFilters: esmInvoiceFinderMyFiltersReducer(state.myFilters, action),
    table: tableReducer(state.table, action),
  };
}

