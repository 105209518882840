<div class="panel-box margin-bottom-12">
  <form class="row align-items-center" [formGroup]="form">
    <div class="col-12 row">
      <div class="col-12 col-md-12 col-xl-4">
        <div>
          <label class="text-bold label--multiselect form__label">Counterparty</label>
          <p-multiSelect id="ecm-dashboard-property-filter-counterparty"
                         selectedItemsLabel="All ({0})"
                         [maxSelectedLabels]="counterPartyOptions.length - 1"
                         [filter]="false"
                         [options]="counterPartyOptions"
                         tabindex="5"
                         placeholder="None"
                         [ngClass]="{'error': errors['counterParties']}"
                         formControlName="counterParties">
          </p-multiSelect>
        </div>
        <div>
          <label class="text-bold label--multiselect form__label">Broker</label>
          <p-multiSelect id="ecm-dashboard-property-filter-broker"
                         [options]="brokerOptions"
                         selectedItemsLabel="All ({0})"
                         [maxSelectedLabels]="brokerOptions.length - 1"
                         [filter]="false"
                         tabindex="6"
                         placeholder="None"
                         [ngClass]="{'error': errors['brokers']}"
                         formControlName="brokers">
          </p-multiSelect>
        </div>
      </div>
      <div class="col-12 col-md-12 col-xl-4">
        <div>
          <label class="text-bold label--multiselect form__label">Transaction Type</label>
          <p-multiSelect id="ecm-dashboard-property-filter-transaction-type"
                         selectedItemsLabel="All ({0})"
                         [maxSelectedLabels]="transactionTypeOptions.length - 1"
                         [filter]="false"
                         [options]="transactionTypeOptions"
                         tabindex="7"
                         placeholder="None"
                         [ngClass]="{'error': errors['transactionTypes']}"
                         formControlName="transactionTypes">
          </p-multiSelect>
        </div>
        <div>
          <label class="text-bold label--multiselect form__label">Commodity</label>
          <p-multiSelect id="ecm-dashboard-property-filter-commodity"
                         selectedItemsLabel="All ({0})"
                         [maxSelectedLabels]="commodityOptions.length - 1"
                         [filter]="false"
                         [options]="commodityOptions"
                         tabindex="8"
                         placeholder="None"
                         [ngClass]="{'error': errors['commodities']}"
                         formControlName="commodities">
          </p-multiSelect>
        </div>
      </div>
      <div class="col-12 col-md-12 col-xl-4">
        <div>
          <label class="text-bold label--multiselect form__label">Market</label>
          <p-multiSelect id="ecm-dashboard-property-filter-market"
                         selectedItemsLabel="All ({0})"
                         [maxSelectedLabels]="marketOptions.length - 1"
                         [filter]="false"
                         [options]="marketOptions"
                         tabindex="9"
                         placeholder="None"
                         [ngClass]="{'error': errors['markets']}"
                         formControlName="markets">
          </p-multiSelect>
        </div>
      </div>
    </div>
    <div class="col-12 row align-items-baseline">
      <div class="col-12 col-xs-6 col-md-6">
        <label class="text-bold margin-right-9">Hidden</label>
        <label class="margin-right-9"><input type="radio"
                                             formControlName="hiddenDeals"
                                             id="{{HiddenDeals.EXCLUDE}}"
                                             [value]="HiddenDeals.EXCLUDE"
                                             (change)="onChanges()"
                                             class="margin-right-3" [ngClass]="{'error': errors['hiddenDeals']}"
                                             name="hiddenDeals"/>Exclude</label>
        <label class="margin-right-9"><input type="radio"
                                             formControlName="hiddenDeals"
                                             id="{{HiddenDeals.INCLUDE}}"
                                             [value]="HiddenDeals.INCLUDE"
                                             (change)="onChanges()"
                                             class="margin-right-3" [ngClass]="{'error': errors['hiddenDeals']}"
                                             name="hiddenDeals"/>Include</label>
        <label><input type="radio"
                      formControlName="hiddenDeals"
                      id="{{HiddenDeals.HIDE_ONLY}}"
                      [value]="HiddenDeals.HIDE_ONLY" [ngClass]="{'error': errors['hiddenDeals']}"
                      (change)="onChanges()"
                      class="margin-right-3"
                      name="hiddenDeals"/>Hidden Only</label>
      </div>
      <div class="col-12 col-xs-6 col-md-6 btn__container text-end">
        <cms-filters-management-menu [filters]="myFilters"
                                     [defaultFilter]="defaultFilter"
                                     [dialog]="dialog"
                                     [disableSaveCurrent]="disableSaveCurrent || (form && form.invalid)"
                                     (select)="onSelectMyFilter($event)"
                                     (replaceFilter)="onReplaceFilter($event)"
                                     (saveAs)="onSaveFilter($event)"
                                     (setDefault)="setDefaultFilter.emit($event)"
                                     (closeDialog)="closeDialog.emit();"
                                     (delete)="deleteFilter.emit($event)"
                                     (manageFilters)="manageFilters.emit()"
                                     (saveCurrent)="openSaveCurrent.emit()">
        </cms-filters-management-menu>
        <button class="btn margin-top-3 margin-bottom-3 margin-left-12" type="button" (click)="onChanges()" [disabled]="hasErrors()">Search</button>
        <button class="btn margin-top-3 margin-bottom-3 margin-left-12" type="button" (click)="onReset()">Reset</button>
      </div>
      <div id="ecm-dashboard-property-filter-error" *ngIf="hasErrors()" class="col-12 col-xs-6 col-md-6 error-note">
        Select at least one element in marked select box.
      </div>
    </div>
  </form>
</div>

