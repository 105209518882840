<p-dialog 
  *ngIf="data && display" 
  [header]="header" 
  [visible]="display" 
  [modal]="true" 
  (visibleChange)="onCancel()"
  [style]="{width: '700px'}">
  <p-messages [value]="messages" [closable]="true"></p-messages>
  <p-messages [value]="contentMessages" [closable]="false"></p-messages>
  <p-footer>
    <button 
      class="btn margin-right-3" 
      id="billing-runs-delete-cancel-button" 
      type="button"
      (click)="onCancel()">
      Cancel
    </button>
    <button 
      class="btn" 
      id="billing-runs-delete-confirm-button" 
      type="button" 
      (click)="onConfirm()">Yes - Delete
    </button>
  </p-footer>
</p-dialog>