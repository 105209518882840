import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'cms-elcom-whitelist-dialog',
  templateUrl: './elcom-whitelist-dialog.component.html',
  styleUrls: ['./elcom-whitelist-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElcomWhitelistDialogComponent {
  @Input() display: boolean;
  @Input() acerCode: string;
  @Output() cancel = new EventEmitter();
  @Output() confirm = new EventEmitter();

  onVisibleChange(display: boolean): void {
    if (!display) {
      this.onCancel();
    }
  }

  onCancel(): void {
    this.cancel.emit();
  }

  onConfirm(): void {
    this.confirm.emit();
  }
}
