<h1>Assign User Roles</h1>
<cms-info ngPreserveWhitespaces>

  __Purpose of the User Roles__

  Users are categorised according to permission levels within the system. As a result, certain screens will be accessible depending on the role(s) to which the user has been assigned:
  * OP – (standard) Operator: Operators are allowed to access trade data in CMS
  * MO – Master Operator: Master Operators have all Operator rights and, in addition, access to privileged functions such as:
      - (eCM) tools to change eCM process settings for their organisation
      - (eRR) tools to add Standing Instructions for their organisation
      - (eSM) the ability to edit master data and eSM settings and to upload CSV files
  * UA – User Administrator: User administrators maintain access rights for users within their Organisation Group, for example, they can add/disable users, reset passwords, amend user details and configure an individual’s user roles

  __How to assign User Roles__

  1. At the top of the screen, select the reporting process: eCM, eRR or eSM.
  2. Locate the user to whom you want to assign a new role:
      - Use the drop-down menu to select the user and hide all other users or look for the user name in the First Column.
  3. Locate the column of the organisation, for which you want to assign the roles.
  4. Click the UA, MO and/or OP icon to assign or remove the user's role for the selected organisation.
      - If the icon is dark blue, the role is assigned to the user. A light blue icon means that the role is not assigned to the user for the organisation. The MO role includes the privileges of the OP role.
  5. Click Save Changes

  __Please note:__
  Only active accounts are present in this screen. In the case a user account is locked you will need to unlock the account in the “User Accounts” screen then update the User Role(s) in the “Assign User Roles” screen.

  If there are more than 500 entries, the ‘All Users’ option is removed from the user selection dropdown to ensure timely loading of the screen.

</cms-info>
<div class="panel-box">
  <p-messages [value]="messages$ | async" [closable]="true"></p-messages>
  <cms-datatable *ngIf="(columns$ | async).length"
                [paginator]="false"
                [loading]="loading$ | async"
                [data]="(data$ | async)?.values"
                [autoLayout]="true"
                [scrollable]="false"
                [totalRecords]="(data$ | async)?.count"
                [lazy]="false"
                [tableColumns]="columns$ | async">
    <ng-template pTemplate="caption">
      <cms-user-roles-filters [filters]="filters$ | async"
                              [users]="users$ | async"
                              [orgGroups]="orgGroups$ | async"
                              [userVisible]="usersVisible$ | async"
                              [groupsVisible]="orgGroupVisible$ | async"
                              [processRoles]="processRole$ | async"
                              [processRolesVisible]="processRoleVisible$ | async"
                              (export)="onExport($event)"
                              (filter)="onFilter($event)">
      </cms-user-roles-filters>
    </ng-template>
    <ng-template pTemplate="body" let-row="row" let-column="column" let-rowIndex="rowIndex">
      <td *ngIf="column.type === 'adminStatus'" [ngStyle]="column.style">
        <cms-user-role-status mode="adminStatus"
                              [row]="row"
                              [rowIndex]="rowIndex"
                              [newRow]="editableData && editableData[rowIndex] && editableData[rowIndex][column.name]"
                              [column]="column"
                              (unChange)="onUnChange($event)"
                              (change)="onChange($event)">
        </cms-user-role-status>
      </td>
      <td *ngIf="column.type === 'mo/operatorStatus'" [ngStyle]="column.style">
        <cms-user-role-status mode="mo/operatorStatus"
                              [row]="row"
                              [rowIndex]="rowIndex"
                              [newRow]="editableData && editableData[rowIndex] && editableData[rowIndex][column.name]"
                              [column]="column"
                              (unChange)="onUnChange($event)"
                              (change)="onChange($event)">
        </cms-user-role-status>
      </td>
    </ng-template>
  </cms-datatable>
  <div class="padding-0 col-lg-12 col-12 btn__container margin-top-3 flex justify-content-end align-items-center">
    <button [disabled]="!editableData" class="btn margin-top-3 margin-bottom-3 margin-left-12" type="button" (click)="onSaveChangesClick()">Save Changes</button>
  </div>
</div>
