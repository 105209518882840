import { BehaviorSubject, Observable, Subscription, throwError as observableThrowError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../auth/authentication.service';
import { OrganisationService } from '@common/organisation.service';
import {
  CpmlDealsSearchQuery,
  CpmlFilters,
  CpmlIdFilter,
  CpmlSearchType,
  DocumentType
} from './dealfinder/filter/cpml-dealfinder-filter.model';
import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import { CpmlDealFinderDataTableModel } from './datatable/cpml-datatable.model';
import { CpmlDashboardDocumentStateType, CpmlDashboardTradeRepositoryType } from './dashboard/filter/cpml-dashboard-filter.types';
import { EndPointConstants } from '@common/end-point-constants';
import { catchError, map, tap } from 'rxjs/operators';
import { ApiRequestService } from '@common/api-request.service';

@Injectable()
export class CpmlDealsService implements OnDestroy {

  private searchQuerySubject = new BehaviorSubject<CpmlDealsSearchQuery>(null);
  private loading = new BehaviorSubject<boolean>(false);
  private resetSubject = new EventEmitter();
  private notFoundIds = new EventEmitter<string[]>();
  private selectedOrg;
  private subscription: Subscription;

  public getLoading(): Observable<boolean> {
    return this.loading.asObservable();
  }

  public setLoading(value: boolean): void {
    this.loading.next(value);
  }

  constructor(private httpClient: HttpClient,
              private apiRequestService: ApiRequestService,
              private authenticationService: AuthenticationService,
              private organisationService: OrganisationService) {
    this.subscription = organisationService.getChangeOrganisationObservable()
      .subscribe(({ organisationId  } = {}) => this.selectedOrg = organisationId);
  }

  public emitChangeIdFilter(idFilter: CpmlIdFilter) {
    const nextSearchQuery = this.buildNextSearchQueryByCpmlFilters({
      ids: idFilter.ids,
      searchType: CpmlIdFilter.mapIdType(idFilter.searchType),
      documentTypes: undefined
    });
    this.searchQuerySubject.next(nextSearchQuery);
  }

  public emitResetFilter() {
    this.searchQuerySubject.next(null);
    this.notFoundIds.emit([]);
    this.resetSubject.emit();
  }

  public emitChangeDocumentState(state: CpmlDashboardDocumentStateType) {
    this.emitNewSearchQuery({ ...this.getCurrentSearchQuery(), state: state });
  }

  public emitChangeDealFinderFilter(documentTypeFilter: DocumentType[], tradeRepositoryFilter: CpmlDashboardTradeRepositoryType[]) {
    const nextSearchQuery = this.buildNextSearchQueryByCpmlFilters({
      ids: undefined,
      searchType: CpmlSearchType.FILTER_SEARCH,
      documentTypes: documentTypeFilter,
      tradeRepositories: tradeRepositoryFilter
    });
    this.searchQuerySubject.next(nextSearchQuery);
  }

  public emitNewSearchQuery(searchQuery: CpmlDealsSearchQuery) {
    this.searchQuerySubject.next(searchQuery);
  }

  public getDocTypeSearchFilterChangeObservable(): Observable<DocumentType[]> {
    return this.searchQuerySubject.pipe(map(searchQuery => searchQuery.documentTypes));
  }

  public getDocumentStateChangeObservable(): Observable<CpmlDashboardDocumentStateType> {
    return this.searchQuerySubject.pipe(map(searchQuery => searchQuery.state));
  }

  public getResetStatusChangeObservable() {
    return this.resetSubject.asObservable();
  }

  public fetchCpmlDeals(searchQuery: CpmlDealsSearchQuery): Observable<CpmlDealFinderDataTableModel> {
    this.setLoading(true);

    return this.apiRequestService.post('/api/err/cpml/deals', searchQuery)
      .pipe(
        tap(() => this.setLoading(false)),
        tap(result => result.notFoundIds ? this.notFoundIds.emit(result.notFoundIds) : this.notFoundIds.emit([])),
        catchError(err => {
          this.setLoading(false);
          return err;
        }));
  }

  public fetchCpmlBucketDetails(searchQuery: CpmlDealsSearchQuery): Observable<CpmlDealFinderDataTableModel> {
    this.setLoading(true);

    return this.apiRequestService.post('/api/err/cpml/buckets/details', searchQuery)
      .pipe(
        tap(() => this.setLoading(false)),
        catchError(err => {
          this.setLoading(false);
          return err;
        }));
  }

  public getSearchQueryChangeObservable(): Observable<CpmlDealsSearchQuery> {
    return this.searchQuerySubject.asObservable();
  }

  public getCurrentSearchQuery() {
    return this.searchQuerySubject.getValue();
  }

  public updateSearchQueryByPagingAndSortingFilter(pagingFilter: PagingFilter, sortingFilter: SortingFilter) {
    const nextSearchQuery = this.buildNextSearchQueryByCpmlFilters({ paging: pagingFilter, sorting: sortingFilter });
    this.searchQuerySubject.next(nextSearchQuery);
  }

  private buildNextSearchQueryByCpmlFilters(newFilters: CpmlFilters): CpmlDealsSearchQuery {
    return {
      ...CpmlDealsSearchQuery.createEmptySearchQuery(),
      ...this.getCurrentSearchQuery(),
      ...newFilters
    };
  }

  public downloadCpmlXml(auditGroupId: number): Observable<{ filename: string, blob: Blob }> {

    let params = new HttpParams();
    if (this.organisationService.getCurrentOrganisationId()) {
      params = params.append(EndPointConstants.PARAM_ORGANISATION_ID, this.organisationService.getCurrentOrganisationId().toString());
    }
    const options = { observe: 'response' as 'body', params: params, responseType: 'blob' as 'json' };

    return this.httpClient.get('/api/err/cpml/' + auditGroupId + '/xml', options)
      .pipe(map((response: HttpResponse<Blob>) => {
        const filename = this.getFilenameFromHeader(response.headers);
        const blob: Blob = response.body;
        return { filename, blob };
      }), catchError(this.handleError));
  }

  private getFilenameFromHeader(headers): string {
    const contentDisposition = headers.get('content-disposition');
    return contentDisposition.split(';')[1].trim().split('=')[1];
  }

  private handleError(error: HttpErrorResponse) {
    console.error(error);
    const errMsg = (error.message) ? error.message : 'Server error';
    return observableThrowError(errMsg);
  }

  public downloadExcelExport(searchQuery?: CpmlDealsSearchQuery): Observable<{ filename: string, blob: Blob }> {
    const nextSearchQuery = searchQuery || this.searchQuerySubject.getValue();

    let params = new HttpParams();
    if (this.organisationService.getCurrentOrganisationId()) {
      params = params.append(EndPointConstants.PARAM_ORGANISATION_ID, this.organisationService.getCurrentOrganisationId().toString());
    }
    const options = { observe: 'response' as 'body', params: params, responseType: 'blob' as 'json' };

    // URL depends on whether this function is called via either Dashboard/Buckets or Dealfinder
    let url: string;
    if (CpmlDealsSearchQuery.isDealFinderSearchQuery(nextSearchQuery)) {
      url = '/api/err/cpml/deals/report';
    } else {
      url = '/api/err/cpml/buckets/report';
    }

    return this.httpClient.post(url, nextSearchQuery, options)
      .pipe(map((response: HttpResponse<Blob>) => {
        const filename = this.getFilenameFromHeader(response.headers);
        const blob: Blob = response.body;
        return { filename, blob };
      }), catchError(this.handleError));
  }

  public getNotFoundIdsObservable() {
    return this.notFoundIds;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
