import { ErrorResponse } from '@common/error-response.model';

export interface CreateSnapshotResponse extends ErrorResponse {
  value: {errorMessage: string, success: boolean};
}

export interface GetStatesResponse extends ErrorResponse {
  value: GetStatesResponseValue;
}

export interface GetStatesResponseValue {
  technicalErrors: string[];
  accessValidationFailures: string[];
  validationFailures: string[];
  fieldValidationFailures: {[key: string]: string};
  changeDate: string;
  lastSnapshotMessage: string;
  categories: MonitoringCategory[];
  success: boolean;
}

export interface MonitoringLogs {
  completed: any;
  initiator: string;
  started: string;
}

export interface GetLogsResponse extends ErrorResponse {
  values: MonitoringLogs[];
}

export interface MonitoringCategory {
  snapshotId: number;
  categoryName: MonitoringCategories;
  checked: boolean;
  checkedMessage: string;
}

export interface GetMonitoringDataResponse {
  value: GetMonitoringData;
  success: boolean;
}

export interface GetMonitoringData {
  technicalErrors: string[];
  accessValidationFailures: string[];
  validationFailures: string[];
  fieldValidationFailures: {[key: string]: string};
  snapshotId: number;
  checkLogMessage: string;
  timePoints: string[];
  data: MonitoringDataItem[];
}

export interface MonitoringDataItem {
  organisationName: string;
  eicCode: string;
  trades: {[key: string]: number };
}

export interface MonitoringDataTableItem {
  organisationName: string;
  eicCode: string;
  [key: string]: string;
}

export type MonitoringCategories = 'FAILED' | 'RECEIVING' | 'MATCHING' | 'MATCHED' | 'MATCHED_BR';
export const tabsList: MonitoringCategories[] = ['FAILED', 'RECEIVING', 'MATCHING', 'MATCHED', 'MATCHED_BR'] as MonitoringCategories[];
export const categoryToTitleMap: {[key: string]: string} = {
  'FAILED': 'Failed',
  'RECEIVING': 'Receiving',
  'MATCHING': 'Matching',
  'MATCHED': 'Matched',
  'MATCHED_BR': 'Matched (BR)'
};
