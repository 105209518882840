import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiRequestService } from '@common/api-request.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { OrganisationModel } from '@common/organisation.model';
import { OrganisationService } from '@common/organisation.service';
import { OrganisationGroupsState } from './organisation-groups.reducer';
import { catchError, concatMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Prefix } from '../reducers';
import {
  EditOrgGroupResponse,
  GetEditBillingDataResponse,
  GetOrganisationGroupResponse,
  GetOrganisationGroupsRequest,
  OrganisationGroup,
} from '../../administration-tab-panel/organisation-groups/organisation-groups.model';
import { Message } from 'primeng/api';
import { of } from 'rxjs';
import { SetToDefaultAction } from '../shared/shared.actions';
import { SetOrganisationIdAction } from '../organisation-id/organisation-id.actions';
import * as fromDbActions from '../datatable/datatable.actions';
import * as fromSelectors from '../organisation-groups/organisation-groups.selectors';
import * as fromActions from '../organisation-groups/organisation-groups.actions';
import * as fromDialogActions from '../dialog/dialog.actions';
import { DatatableState } from '../datatable/datatable.reducer';
import { getFilenameFromHeader } from '@common/deals/deals.model';
import * as FileSaver from 'file-saver';
import { ErrorResponse } from '@common/error-response.model';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class OrganisationGroupsEffects {
  private baseUrl = '/api/administration/organisation/groups';
  prefix: Prefix = '[ORGANISATION GROUPS]';
  constructor(private apiGateway: ApiRequestService,
              private actions$: Actions,
              private organisationService: OrganisationService,
              private store$: Store<OrganisationGroupsState>,
              private router: Router,
              private mapper: MessagesMapperService) { }

   editForm$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.editBillingData.type),
      switchMap((action: ReturnType<typeof  fromActions.editBillingData>) => {
        const { data, displayName } = action.payload;
        return this.apiGateway.put(`${this.baseUrl}/billing/edit`, data)
          .pipe(
            switchMap((response: ErrorResponse | any) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [new fromDialogActions.UpdateDialogAction(this.prefix, messages)];
              }
              return [
                new fromDbActions.LoadDataAction(this.prefix),
                new fromDialogActions.CloseDialogAction(this.prefix),
                new fromDbActions.SetMessagesAction(
                  this.prefix,
                  this.mapper.toSuccessMessages(`Billing data for organisation group '${displayName}' has been updated.`)
                )
              ];
            }),
            catchError(error =>
              of(new fromDbActions.SetMessagesAction(this.prefix, [this.mapper.createErrorMessage(error.errorMessage)]))
            )
          );
      }),
      catchError(error =>
        of(new fromDbActions.SetMessagesAction(this.prefix, [this.mapper.createErrorMessage(error.errorMessage)]))
      )
    ));


   billingData$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.loadEditBillingData.type),
      switchMap((action: ReturnType<typeof fromActions.loadEditBillingData>) => {
        const { organisationGroupId } = action.payload;
        let params = new HttpParams();
        params = params.set('organisationGroupId', organisationGroupId.toString());
        return this.apiGateway.post(`${this.baseUrl}/billing/form`, null, params)
          .pipe(
            map((response: GetEditBillingDataResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response as ErrorResponse);
              if (messages && messages.length) {
                return new fromDialogActions.UpdateDialogAction(this.prefix, messages);
              }

              return new fromDialogActions.OpenDialogAction(this.prefix, {
                name: 'EDIT_BILLING_DATA',
                messages: [],
                data: response.value
              });
            }),
            catchError(error =>
              of(new fromDbActions.SetMessagesAction(this.prefix, [this.mapper.createErrorMessage(error.errorMessage)]))
            )
          );
      }),
      catchError(error =>
        of(new fromDbActions.SetMessagesAction(this.prefix, [this.mapper.createErrorMessage(error.errorMessage)]))
      )
    ));

   generate$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.generateApiKeyAction.type),
      switchMap((action: ReturnType<typeof fromActions.generateApiKeyAction>) => {
        const { organisationGroupId } = action.payload;
        let params = new HttpParams();
        params = params.set('organisationGroupId', organisationGroupId.toString());
        return this.apiGateway.get(`${this.baseUrl}/apiKey`, params)
          .pipe(
            map((response: {response: string}) => {
              const messages: Message[] = this.mapper.toErrorMessages(response as ErrorResponse);
              if (messages && messages.length) {
                return new fromDialogActions.UpdateDialogAction(this.prefix, messages);
              }
              const { response: apiKey } = response;
              return new fromDialogActions.OpenDialogAction(this.prefix, {
                name: 'EDIT',
                messages: [],
                data: {
                  apiKey
                }
              });
            })
          );
      })
    ));

   deleteGroup$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.deleteOrgGroupAction.type),
      concatMap((action: ReturnType<typeof fromActions.deleteOrgGroupAction>) => {
        const { organisationGroupId } = action.payload;
        let params = new HttpParams();
        params = params.set('organisationGroupId', organisationGroupId.toString());
        return this.apiGateway.delete(`${this.baseUrl}/delete`, params)
          .pipe(
            switchMap((response: any | ErrorResponse) => {
              const actions = [];
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                actions.push(new fromDbActions.SetMessagesAction(this.prefix, messages));
              }

              actions.push(new fromDbActions.LoadDataAction(this.prefix));

              const val = response.value;
              if(val && val.success && val.message){

                const msgs = this.mapper.toSuccessMessages(val.message);
                actions.push(new fromDbActions.SetMessagesAction(this.prefix, msgs));
              }

              return actions;
            }),
            catchError(error =>
              of(new fromDbActions.SetMessagesAction(this.prefix, [this.mapper.createErrorMessage(error.errorMessage)]))
            )
          );
      }),
      catchError(error =>
        of(new fromDbActions.SetMessagesAction(this.prefix, [this.mapper.createErrorMessage(error.errorMessage)]))
      )
    ));

   editOrgGroup$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.editOrgGroupAction.type),
      switchMap((action: ReturnType<typeof fromActions.editOrgGroupAction>) => {
        return this.apiGateway.put(`${this.baseUrl}/edit`, action.payload)
          .pipe(
            switchMap((response: EditOrgGroupResponse) => {
              const actions = [];
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                actions.push(new fromDialogActions.UpdateDialogAction(this.prefix, messages));
              } else {
                const { value: { message } } = response;
                actions.push(
                  new fromDbActions.LoadDataAction(this.prefix),
                  new fromDialogActions.CloseDialogAction(this.prefix),
                  new fromDbActions.SetMessagesAction(this.prefix, this.mapper.toSuccessMessages(message)),
                );
              }
              return actions;
            })
          );
      }
    )));

   addOrgGroup$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.addOrgGroupAction.type),
      switchMap((action: ReturnType<typeof fromActions.addOrgGroupAction>) => {
        return this.apiGateway.post(`${this.baseUrl}/add`, action.payload)
          .pipe(
            switchMap((response: any | ErrorResponse) => {
              const actions = [];


              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                actions.push(new fromDialogActions.UpdateDialogAction(this.prefix, messages));
              } else {
                actions.push(
                  new fromDbActions.LoadDataAction(this.prefix),
                  new fromDialogActions.CloseDialogAction(this.prefix)
                );
                const val = response.value;
                if(val && val.success && val.message){

                  const msgs = this.mapper.toSuccessMessages(val.message);
                  actions.push(new fromDbActions.SetMessagesAction(this.prefix, msgs));
                }

              }
              return actions;
            })
          );
      }),
      catchError(error =>
        of(new fromDbActions.SetMessagesAction(this.prefix, [this.mapper.createErrorMessage(error.errorMessage)]))
      )
    ));

   export$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.exportAction.type),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getDatatable))),
      switchMap(([,state]: [any, DatatableState<OrganisationGroup, string>]) => {
        const { filters: filterValue, paging, sorting } = state;
        const request: GetOrganisationGroupsRequest = {
          sorting,
          paging,
          filterValue
        };
        return this.apiGateway.getBlob(`${this.baseUrl}/report`, request)
          .pipe(
            switchMap((response) => {
              const filename = getFilenameFromHeader(response.headers);
              const blob: Blob = response.body;
              FileSaver.saveAs(blob, filename);
              return [new fromDbActions.SetMessagesAction(this.prefix, [])];
            }),
          );
      }),
      catchError(error =>
        of(new fromDbActions.SetMessagesAction(this.prefix, [this.mapper.createErrorMessage(error.errorMessage)]))
      )
    ));

   loadData$ = createEffect(() => this.actions$
    .pipe(ofType(`${this.prefix} ${fromDbActions.LOAD_DATA}`),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getFilters))),
      switchMap((data: [fromDbActions.LoadDataAction, string]) => {
          return of(new fromDbActions.FilterDataAction(this.prefix, data[1]));
        }
      )));

   filter$ = createEffect(() => this.actions$
    .pipe(ofType(`${this.prefix} ${fromDbActions.FILTER_DATA}`),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getDatatable))),
      switchMap((data: [fromDbActions.FilterDataAction, any]) => {
          const [action, state] = data;
          const request: GetOrganisationGroupsRequest = {
            paging: state.paging,
            sorting: state.sorting,
            filterValue: action.payload as string
          };
          return this.loadData(request);
        }
      )));

   sortAndPage$ = createEffect(() => this.actions$
    .pipe(ofType(`${this.prefix} ${fromDbActions.SORT_AND_PAGE_DATA}`),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getFilters))),
      switchMap((data: [fromDbActions.SortAndPageDataAction, string]) => {
          const [action, filters] = data;
          const { sorting, paging } = action.payload;
          return this.loadData({
            filterValue: filters,
            sorting,
            paging,
          });
        }
      )));

   organisationChange$ = createEffect(() => this.organisationService
    .getChangeOrganisationObservable()
    .pipe(
      filter(() => this.router.routerState.snapshot.url.includes('admin/organisationGroups')),
      filter(() => this.router.routerState.snapshot.url.includes('admin/organisationGroups')),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getOrganisationId))),
      switchMap((data: [OrganisationModel, number]) => {
        const [organisation, organisationId] = data;
        if ((organisation && organisation.organisationId !== organisationId) || (!organisation && organisationId)) {
          return [
            new SetToDefaultAction(this.prefix),
            new SetOrganisationIdAction(this.prefix, organisation ? organisation.organisationId : null),
            new fromDbActions.LoadDataAction(this.prefix),
          ];
        }
        return [];
      })));

  private loadData(request: GetOrganisationGroupsRequest) {
    return this.apiGateway
      .post(`${this.baseUrl}/search`, request)
      .pipe(
        map((response: GetOrganisationGroupResponse) => {
          const messages: Message[] = this.mapper.toErrorMessages(response);
          if (messages && messages.length) {
            return new fromDbActions.SetMessagesAction(this.prefix, messages);
          }
          const {currentPage, values, count} = response;
          return new fromDbActions.FilterDataSuccessAction(this.prefix, {
            currentPage, values, count
          });
        }),
        catchError(error =>
          of(new fromDbActions.SetMessagesAction(this.prefix, [this.mapper.createErrorMessage(error.errorMessage)]))
        )
      );
  }
}

