import { AfterViewInit, Directive, ElementRef, HostListener, Input } from '@angular/core';
import { Calendar } from 'primeng/calendar';
import { dateValidator, maskDigitValidators, neverValidator } from '@common/directives/calendar/digit-validator';
import {
  BACKSPACE,
  DELETE,
  emptyInput,
  ENTER,
  ESCAPE,
  LEFT_ARROW,
  overrideCharAtPosition,
  RIGHT_ARROW,
  SPECIAL_CHARACTERS,
  TAB
} from '@common/directives/calendar/calendar.utils';
import { DateUtils } from '@common/shared/date.utils';

@Directive({
  selector: 'p-calendar[cmsDateRestriction]'
})

export class CalendarDirective implements AfterViewInit {

  validMaskValues: string = '39/19/2999';
  input: HTMLInputElement;


  @Input()
  ngModel:string;

  constructor(private el: ElementRef,
              private calendar: Calendar) {
  }

  ngAfterViewInit(): void {
    this.input = (this.el.nativeElement as HTMLElement).querySelector('input');
    if (!this.calendar.yearRange) {
      this.calendar.yearRange = DateUtils.getDefaultYearRange();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event) {
    event.preventDefault();
    let pastedInput: string;
    if (window['clipboardData']) {  // IE
      pastedInput = window['clipboardData'].getData('Text').replace(/\./g, '/');

    } else {
      pastedInput = event.clipboardData.getData('text/plain').replace(/\./g, '/');
    }

    if (dateValidator(pastedInput, this.calendar.minDate, this.calendar.maxDate)) {
      this.input.value = pastedInput;
      this.updateCalendar();
    }
  }

  @HostListener('keydown', ['$event', '$event.keyCode', '$event.code', '$event.key'])
  onKeyDownEvent($event: KeyboardEvent, keyCode, code, number) {
    if ($event.metaKey || $event.ctrlKey) {
      return;
    }
    if (keyCode !== TAB) {
      $event.preventDefault();
    }

    if (keyCode === ESCAPE || keyCode === ENTER) {
      this.calendar.hideOverlay();
      this.input.blur();
    }

    if (keyCode === DELETE || keyCode === BACKSPACE) {
      this.deleteSelection();
    }

    const key = number,
      cursorPosition = this.input.selectionStart;

    switch (keyCode) {
      case LEFT_ARROW:
        this.handelLeftArrow(cursorPosition);
        break;
      case RIGHT_ARROW:
        this.handelRightArrow(cursorPosition);
        break;
      case BACKSPACE:
        this.handelBackSpace(cursorPosition);
        break;
      case DELETE:
        this.handelDelete(cursorPosition);
        break;
    }

    const maskDigit = this.validMaskValues.charAt(cursorPosition),
      digitValidator = maskDigitValidators[maskDigit] || neverValidator;

    if (digitValidator(key)) {
      overrideCharAtPosition(this.input, cursorPosition, key);
      this.handelRightArrow(cursorPosition);
    }

    const date = dateValidator(this.input.value, this.calendar.minDate, this.calendar.maxDate);
    if (date) {
      this.input.value = date as string;
      this.updateCalendar();
    }
  }

  @HostListener('mouseover')
  onMouseIn() {
    this.setUpPlaceHolder();
  }

  @HostListener('mouseout')
  onMouseOut() {
    const empty = emptyInput(this.input.value) && !dateValidator(this.input.value, this.calendar.minDate, this.calendar.maxDate);
    const hover = !this.calendar.focus && empty;

    if (hover) {
      this.input.value = null;
      this.updateCalendar();
    }
  }

  @HostListener('onClose')
  onMouseBluer() {
    const empty = emptyInput(this.input.value) && !dateValidator(this.input.value, this.calendar.minDate, this.calendar.maxDate);

    if (empty) {
      this.input.value = null;
      this.updateCalendar();
    }
  }


  @HostListener('onFocus')
  onSelect() {
    this.setUpPlaceHolder();
  }

  @HostListener('drop', ['$event'])  onDrop(event: DragEvent) {
    event.preventDefault();
  }

  handelBackSpace(cursorPosition) {
    const nextCurPos = this.getTextLeftCurPosition(cursorPosition);

    if (nextCurPos !== undefined) {
      overrideCharAtPosition(this.input, nextCurPos, '_');
      this.input.setSelectionRange(nextCurPos, nextCurPos);
    }
  }

  handelDelete(cursorPosition) {
    const nextCurPos = this.getNextRightCurPosition(cursorPosition),
      validPosition = (cursorPosition !== 10 &&
        cursorPosition < this.input.value.length &&
        this.input.value[cursorPosition] !== '_'),
      nextValidPosition = (nextCurPos !== 10 &&
        this.input.value[cursorPosition] === '_');

    if (validPosition) {
      overrideCharAtPosition(this.input, cursorPosition, '_');
      this.input.setSelectionRange(cursorPosition, cursorPosition);
    } else if (nextValidPosition) {
      overrideCharAtPosition(this.input, nextCurPos, '_');
      this.input.setSelectionRange(nextCurPos, nextCurPos);

    }
  }

  handelLeftArrow(cursorPosition) {
    const nextCurPos = this.getTextLeftCurPosition(cursorPosition);

    if (nextCurPos !== undefined) {
      this.input.setSelectionRange(nextCurPos, nextCurPos);
    }
  }

  handelRightArrow(cursorPosition) {
    const nextCurPos = this.getNextRightCurPosition(cursorPosition);

    if (nextCurPos !== undefined) {
      this.input.setSelectionRange(nextCurPos, nextCurPos);
    }
  }

  private getNextRightCurPosition(cursorPosition): number {
    let nextCurPos: number;
    if (cursorPosition === 9) {
      return 10;
    } // last index or else process

    this.input.value
      .slice(cursorPosition + 1)
      .split('')
      .reduce((index, char) => {
        if (!nextCurPos && !SPECIAL_CHARACTERS.includes(char)) {
          nextCurPos = cursorPosition + index;
        }
        return index += +1;
      }, 1);
    return nextCurPos;
  }

  private getTextLeftCurPosition(cursorPosition): number {
    let nextCurPos: number;
    const previousCurPos: string[] =
      this.input.value
        .slice(0, cursorPosition)
        .split('');

    previousCurPos
      .reduceRight((index, char) => {
        if (!nextCurPos && !SPECIAL_CHARACTERS.includes(char)) {
          nextCurPos = index;
        }
        return index += -1;
      }, previousCurPos.length - 1);
    return nextCurPos;
  }

  buildPlaceHolder(): string {
    const chars = this.validMaskValues.split('');
    return chars.reduce((result, char) => {
      return result += SPECIAL_CHARACTERS.includes(char) ? char : '_';
    }, ''); // empty string is the starting point  string
  }

  private updateCalendar() {
    const value = this.calendar.parseValueFromString(this.input.value);
    if (value) {
      if (this.calendar.yearRange) {
        const date = value as Date;
        const yearRange = this.calendar.yearRange.split(':');
        const factor = (parseInt(yearRange[1], 10) - parseInt(yearRange[0], 10)) / 2;
        this.calendar.yearRange = (date.getFullYear() - factor) + ':' + (date.getFullYear() + factor);
      }
      this.calendar.updateUI();
    }
    this.calendar.updateModel(value);
  }

  deleteSelection() {
    if ((this.input.selectionEnd - this.input.selectionStart) > 0) {
      this.calendar.updateModel(null);
      this.input.value = this.buildPlaceHolder();
      this.input.selectionStart = 0;
      this.input.selectionEnd = 0;
    }
  }

  private setUpPlaceHolder() {
    if (!this.input.value.length) {
      this.input.value = this.buildPlaceHolder();
      this.input.selectionStart = 0;
      this.input.selectionEnd = 0;
    }
  }
}
