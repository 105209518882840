import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromMifidFile from '../mifid/mifid.reducer';
import * as fromMifidFileDetails from '../mifid-details/mifid-details.reducer';
import * as fromRetryQueue from '../retry-queue/retry-queue.reducers';
import * as fromExternalRrm from '../external-rrm/external-rrm.reducers';
import * as fromRemitTermination from '../remit/remit.reducer';

import { DialogState } from '@common/state/reducers';
import { ERR_MIFID_DIALOGS, errDialogReducer } from '../dialog/dialog.reducers';
import { InjectionToken } from '@angular/core';
import { terminationReducer } from '../remit/remit.reducer';

export const getErrState = createFeatureSelector<State>('err');

export interface State {
  mifidFile: fromMifidFile.ErrMifidFileState;
  mifidFileDetails: fromMifidFileDetails.ErrMifidFileDetailsState;
  dialog: DialogState<ERR_MIFID_DIALOGS>;
  retryQueue: fromRetryQueue.ErrRetryQueueState;
  externalRrm: fromExternalRrm.ExternalRrmState;
  remitTermination: fromRemitTermination.ErrTerminationState;
}

export const reducers: ActionReducerMap<State> = {
  mifidFile: fromMifidFile.errMifidFileReducer,
  mifidFileDetails: fromMifidFileDetails.errMifidFileDetailsReducer,
  dialog: errDialogReducer,
  retryQueue: fromRetryQueue.errRetryQueueReducer,
  externalRrm: fromExternalRrm.externalRrmReducer,
  remitTermination: terminationReducer,
};

export const reducerToken = new InjectionToken<ActionReducerMap<State>>(
  'ERR Registered Reducers'
);

export const getReducers = () => reducers;
