import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Message } from 'primeng/api';
import {
  InsertItemAction,
  LOAD_DATA,
  LoadDataAction,
  LoadDataSuccessAction,
  SetMessagesAction
} from '../datatable/datatable.actions';
import { DatatableData } from '@common/shared/results.model';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiRequestService } from '@common/api-request.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { Prefix } from '../reducers';
import {
  AddEditUserResponse,
  ChangeUserResponse,
  FhaAccount,
  FhaAccountsResponse,
  ResetPasswordResponse
} from '../../administration-tab-panel/fha-accounts/fha-accounts.model';
import * as fromActions from './fha-accounts.actions';
import { CloseDialogAction, UpdateDialogAction } from '../dialog/dialog.actions';
import { FhaAccountsState } from './fha-accounts.reducers';
import { getDatatable } from './fha-accounts.selectors';

@Injectable()
export class FhaAccountsEffects {
  private baseUrl = '/api/administration/fha/accounts';
  private prefix: Prefix = '[FHA ACCOUNTS]';

  constructor(private apiGateway: ApiRequestService,
              private actions$: Actions,
              private store$: Store<FhaAccountsState>,
              private mapper: MessagesMapperService) {
    this.handleChangeUser = this.handleChangeUser.bind(this);
  }

   loadData$ = createEffect(() => this.actions$
    .pipe(ofType(`${this.prefix} ${LOAD_DATA}`),
      switchMap((action: LoadDataAction) => {
        return this.loadData();
        }
      )));

   lockUser$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.lockUserAction.type),
      switchMap((action: ReturnType<typeof fromActions.lockUserAction>) => {
        const { currentState, userIdForStateChange: userIdForEditing, comment } = action.payload;
          return this.apiGateway
            .post(`${this.baseUrl}/lockUser`, { userIdForEditing, comment })
            .pipe(
              switchMap(this.handleChangeUser),
              catchError(error => of(new SetMessagesAction(this.prefix,
                [this.mapper.createErrorMessage(error.errorMessage)]))));
        }
      )));

   reactivateUser$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.reactivateUserAction.type),
      switchMap((action: ReturnType<typeof fromActions.reactivateUserAction>) => {
        const { comment, newPassword: password, userIdForStateChange: userIdForEditing } = action.payload;
          return this.apiGateway
            .post(`${this.baseUrl}/activateUser`, { comment, password, userIdForEditing })
            .pipe(
              switchMap(this.handleChangeUser),
              catchError(error => of(new SetMessagesAction(this.prefix,
                [this.mapper.createErrorMessage(error.errorMessage)]))));
        }
      )));

   inactivateUser$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.inactivateUserAction.type),
      switchMap((action: ReturnType<typeof fromActions.inactivateUserAction>) => {
        const { currentState, userIdForStateChange: userIdForEditing, comment } = action.payload;
          return this.apiGateway
            .post(`${this.baseUrl}/deactivateUser`, { userIdForEditing, comment })
            .pipe(
              switchMap(this.handleChangeUser),
              catchError(error => of(new SetMessagesAction(this.prefix,
                [this.mapper.createErrorMessage(error.errorMessage)]))));
        }
      )));

   editUserAccount$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.editUserAccountAction.type),
      concatMap((action: ReturnType<typeof fromActions.editUserAccountAction>) => {
        const { state, realName,  ...request } = action.payload;
          return this.apiGateway
            .put(`${this.baseUrl}/edit`, request)
            .pipe(
              concatMap((response: AddEditUserResponse) => {
                const messages: Message[] = this.mapper.toErrorMessages(response);
                if (messages && messages.length) {
                  return of(new UpdateDialogAction(this.prefix, messages));
                }
                const editedUser = response.value;
                editedUser.realName = this.getRealUser(editedUser);
                return [
                  new CloseDialogAction(this.prefix),
                  new InsertItemAction(this.prefix, response.value),
                  new SetMessagesAction(this.prefix, this.mapper.toSuccessMessages(`User account '${request.userName}' updated.`))
                ];
              }),
              catchError(error => of(new SetMessagesAction(this.prefix,
                [this.mapper.createErrorMessage(error.errorMessage)]))));
        }
      )));

   addUserAccount$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.addUserAction.type),
      concatMap((action: ReturnType<typeof fromActions.addUserAction>) => {
        const { organisationGroupId, confirmPassword, ...request } = action.payload;
          return this.apiGateway
            .post(`${this.baseUrl}/add`, request)
            .pipe(
              withLatestFrom(this.store$.pipe(select(getDatatable))),
              concatMap(([response, state]: [AddEditUserResponse, any]) => {
                const messages: Message[] = this.mapper.toErrorMessages(response);
                if (messages && messages.length) {
                  return of(new UpdateDialogAction(this.prefix, messages));
                } else {
                  return this.loadData().pipe(switchMap((filteringAction) => {
                    return [
                      filteringAction,
                      new CloseDialogAction(this.prefix),
                      new SetMessagesAction(this.prefix, this.mapper.toSuccessMessages(`User account '${request.userName}' created.`))
                    ];
                  }));
                }
              }),
              catchError(error => of(new SetMessagesAction(this.prefix, [this.mapper.createErrorMessage(error.errorMessage)]))));
        }
      )));

   resetPassword$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.resetPasswordAction.type),
      switchMap((action: ReturnType<typeof fromActions.resetPasswordAction>) => {
        const { newPassword: password, userIdForPasswordReset } = action.payload;
          return this.apiGateway
            .post(`${this.baseUrl}/resetPassword`, { password, userIdForPasswordReset })
            .pipe(
              switchMap((response: ResetPasswordResponse) => {
                const messages: Message[] = this.mapper.toErrorMessages(response);
                if (messages && messages.length) {
                  return of(new UpdateDialogAction(this.prefix, messages));
                }
                return [
                  new CloseDialogAction(this.prefix),
                  new SetMessagesAction(this.prefix,
                    this.mapper.toSuccessMessages(response.response))
                ];
              }),
              catchError(error => of(new SetMessagesAction(this.prefix, [this.mapper.createErrorMessage(error.errorMessage)]))));
        }
      )));

  private loadData() {
    return this.apiGateway.get(`${this.baseUrl}/data`)
      .pipe(
        map((response: FhaAccountsResponse) => {
          const messages: Message[] = this.mapper.toErrorMessages(response);
          if (messages && messages.length) {
            return new SetMessagesAction(this.prefix, messages);
          }

          if (response && response.values) {
            response.values.forEach((item: FhaAccount) => {
              item.realName = `${item.firstName} ${item.secondName}`;
            });
          }

          return new LoadDataSuccessAction(this.prefix, response as DatatableData<FhaAccount[]>);
        }),
        catchError(error => of(new SetMessagesAction(this.prefix, [this.mapper.createErrorMessage(error.errorMessage)]))));
  }

  private handleChangeUser(response: ChangeUserResponse) {
    const messages: Message[] = this.mapper.toErrorMessages(response);
    if (messages && messages.length) {
      return of(new UpdateDialogAction(this.prefix, messages));
    }
    const updatedUser = response.value.changedUser;
    updatedUser.realName = this.getRealUser(updatedUser);
    return [
      new CloseDialogAction(this.prefix),
      new InsertItemAction(this.prefix, response.value.changedUser),
      new SetMessagesAction(this.prefix, this.mapper.toSuccessMessages(response.value.message))
    ];
  }

  private getRealUser(user: FhaAccount): string {
    return `${user.firstName} ${user.secondName}`;
  }
}
