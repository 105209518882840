import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChange
} from '@angular/core';
import { Column } from '../../shared/datatable.model';
import { EditableCell } from '../cell-editor/cell-editor.model';
import { Message } from 'primeng/api';

@Component ({
  selector: 'cms-cell-status',
  templateUrl: './cell-status.component.html',
  styleUrls: ['./cell-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CellStatusComponent implements OnChanges {
  @Input() column: Column;
  @Input() row: any;
  @Input() editable: boolean;
  @Input() emptyTemplate = '';
  @Input() showBlock = true;
  @Input() error: Message[];
  @Output() confirm = new EventEmitter<EditableCell>();
  @Output() clickToggleAttachXML = new EventEmitter<EditableCell>();

  disabled = false;
  readonly undefined = undefined;

  get value(): string {
    return this.row[this.column.name];
  }

  onStatusChangeClick(): void {
    if (!this.showBlock || (this.value !== null && this.value !== undefined)) {
      this.disabled = true;
      this.confirm.emit({
        column: this.column,
        data: this.row,
        newValue: this.row[this.column.name].toString() !== 'true'
      });
    }
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (changes.row || changes.column || (this.error && this.error.length)) {
      this.disabled = false;
    }
  }

  onClickAttachXML(): void {
    this.clickToggleAttachXML.emit({
      column: this.column,
      data: this.row,
      newValue: null
    });
  }
}
