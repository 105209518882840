import { Observable } from 'rxjs';

export interface Column {
  title: string;
  longTitle?: string;
  name?: string;
  style?: any;
  sortable?: boolean;
  visible?: boolean | Observable<boolean>;
  editable?: boolean;
  hiddenTitle?: boolean;
  type?: 'editable' | 'text' |'list'| 'yes/no' | 'date' | 'adminStatus' | 'mo/operatorStatus' | 'button' | 'email-ref' | 'dateWithoutTime' | 'on/off' | 'currency' | 'currency-edited' | 'currency-four' | 'check' | 'textarea' | 'percent' | 'checkbox' | any;
  maxLength?: number;
}

export class PagerInfo {
  fromNumberOfResults: number;
  toNumberOfResults: number;
  numberOfAllRecords: number;
  resultsPerPage: number;
  startOffsetOfPage: number;
  currentResultsOnPage: number;

  public static calcPagerInfo(numberOfAllRecords: number, currentPage: number, resultsPerPage: number): PagerInfo {
    const numberOfRestResults = numberOfAllRecords % resultsPerPage;
    const startOffsetOfPage = currentPage * resultsPerPage;
    const endOffsetOfPage = currentPage * resultsPerPage + resultsPerPage;
    const fromNumberOfResults = (numberOfAllRecords > 0) ? startOffsetOfPage + 1 : startOffsetOfPage;
    const toNumberOfResults = (endOffsetOfPage > numberOfAllRecords) ? endOffsetOfPage - (resultsPerPage - numberOfRestResults) : endOffsetOfPage;
    const currentResultsOnPage = toNumberOfResults - fromNumberOfResults;
    return { fromNumberOfResults, toNumberOfResults, numberOfAllRecords, resultsPerPage, startOffsetOfPage, currentResultsOnPage };
  }
}

export class DefaultValues {
  public getRowsPerPage() {
    return 25;
  }

  public getRowsPerPageOptions() {
    return [10, 25, 50, 100];
  }

}

export namespace DatatableConfig {

  export const DEFAULT_ROWS: number = 25;
  export const ROW_SELECTION_NUMBERS: number[] = [10, 25, 50, 100];

}
