import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MailAlertsComponent } from './mail-alerts/mail-alerts.component';
import { AccountComponent } from './account/account.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

const routes: Routes = [
  { path: 'mailAlerts', children: [
      { path: '', component: MailAlertsComponent,  data: { panel: 'mail-alerts'} },
      { path: 'global', component: MailAlertsComponent, data: { panel: 'global', title: 'Mail Alerts'} },
      { path: 'ecm', component: MailAlertsComponent, data: { panel: 'ecm'}},
    ],
  },
  { path: 'account', component: AccountComponent},
  { path: 'change_password', component: ChangePasswordComponent, data: { changeImmediately: false, isIntegrated: true }}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PreferencesRoutingModule { }
