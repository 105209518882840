<div style="max-width: 585px;" class="product-details">
  <div class="form-title">Contract | Product Details</div>
  <div class="form-body" [formGroup]="formGroup">
    <div class="row gutter-0">
      <div class="col-5 label">Contract Type <span class="text-required">*</span></div>
      <div id="remit-trade-entry-contractType" class="col-7 ps-2 !pe-[42px]">
        <p-dropdown formControlName="contractType" [options]="contractTypes" [style]="{'width': '100%'}" [styleClass]="'margin-3 ' + getValidationErrorStyleClass('contractType')" [group]="true" ></p-dropdown>
      </div>
      <span *ngIf="formGroup.controls['contractType'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0">
      <div class="col-5 label">Energy Commodity <span class="text-required">*</span></div>
      <div id="remit-trade-entry-energyCommodity" class="col-7 ps-2 !pe-[42px]">
        <p-dropdown formControlName="energyCommodity" [options]="commodities" [style]="{'width': '100%'}" [styleClass]="'margin-3 ' + getValidationErrorStyleClass('energyCommodity')" (onChange)="onChangeCommodity()"></p-dropdown>
      </div>
      <span *ngIf="formGroup.controls['energyCommodity'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0">
      <div class="col-5 label">Delivery Point or Zone <span class="text-required">*</span></div>
      <div id="remit-trade-entry-deliveryType" class="col-7 ps-2 !pe-[42px]">
        <p-dropdown formControlName="deliveryPointOrZone" [placeholder]="selectCommodityFirstPlaceholder(deliveryPoints)" [options]="deliveryPoints" [style]="{'width': '100%'}" [styleClass]="'margin-3 ' + getValidationErrorStyleClass('deliveryPointOrZone')" [group]="true"></p-dropdown>
      </div>
      <span *ngIf="formGroup.controls['deliveryPointOrZone'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0">
      <div class="col-5 label">Load Type</div>
      <div id="remit-trade-entry-loadType" class="col-7 ps-2 !pe-[42px]">
        <p-dropdown formControlName="loadType" [options]="loadTypes" [style]="{'width': '100%'}" [styleClass]="'margin-3 ' + getValidationErrorStyleClass('loadType')" [group]="true" ></p-dropdown>
      </div>
      <span *ngIf="formGroup.controls['loadType'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
  </div>
</div>
