import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

export function passwordValidator(): ValidatorFn {

  const ALLOWED_PASSWORD_CHARACTERS_REGEX = /[a-zA-Z0-9$!\+\-_.,]+/;

  const ruleConformValidator: ValidatorFn = (control: AbstractControl): { [key: string]: any } => {
    /* at least 1 lower-case letter */
    const isPassRuleConform = /.*[A-Z]+.*/.test(control.value) &&
      /* at least 1 upper-case letter */
      /.*[A-Z]+.*/.test(control.value) &&
      /* at least 1 digit OR special character */
      (/.*[\d]+.*/.test(control.value) || /.*[$!\+\-_.,]+.*/.test(control.value));
    return isPassRuleConform ? null : { 'passwordNotRuleConform': { value: control.value } };
  };

  return Validators.compose([
    Validators.required,
    Validators.minLength(8),
    Validators.maxLength(16),
    ruleConformValidator,
    Validators.pattern(ALLOWED_PASSWORD_CHARACTERS_REGEX)
  ]);

}

