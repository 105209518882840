import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

import { CpmlDashboardBucketViewByType, getDefaultBucketViewBy } from './cpml-dashboard-filter.types';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cms-cpml-dashboard-view-by-date-filter',
  templateUrl: './cpml-dashboard-view-by-date-filter.component.html',
  styleUrls: ['./cpml-dashboard-view-by-date-filter.component.scss']
})
export class CpmlDashboardViewByDateFilterComponent implements OnInit, OnDestroy {

  @Input() viewBy: CpmlDashboardBucketViewByType;

  @Input() resetEventEmitter: EventEmitter<void>;

  @Output() onChangeViewByDateFilter = new EventEmitter<CpmlDashboardBucketViewByType>();

  viewByDateTypeFormGroup: UntypedFormGroup;

  private onDestroy$ = new Subject();

  constructor(private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    const viewByDateTypeControl = new UntypedFormControl(this.viewBy);

    this.viewByDateTypeFormGroup = this.formBuilder.group({
      viewByDateType: viewByDateTypeControl
    });

    viewByDateTypeControl
      .valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(this.onChangeViewByDateFilter);

    const resetViewByDateRadioGroup = () => {
      const defaultViewBy = getDefaultBucketViewBy();
      viewByDateTypeControl.setValue(defaultViewBy);
    };

    this.resetEventEmitter
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(_ => resetViewByDateRadioGroup());
  }

  ngOnDestroy(): void {
    // Clean up all subscriptions at once:
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }
}
