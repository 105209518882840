import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { HttpParams } from '@angular/common/http';
import { Actions, ofType } from '@ngrx/effects';
import { catchError, concatMap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Message } from 'primeng/api';
import { ApiRequestService } from '../../../common/api-request.service';
import { MessagesMapperService } from '../../../common/messages-mapper.service';
import * as fromActions from './mail-alerts.actions';
import {
  MailAlert,
  MailAlertsResponse,
  MailAlertSubscriptions,
  UpdateMailAlertsStatusRequest,
  UpdateStatusResponse
} from '../../mail-alerts/mail-alerts.model';

export type Prefix = '[MAIL ALERTS ECM]' | '[MAIL ALERTS GLOBAL]';

@Injectable({
  providedIn: 'root'
})
export class MailAlertsService {
  private readonly baseUrl = '/api/preferences/mailAlerts';

  constructor(private apiGateway: ApiRequestService,
              private actions$: Actions,
              private mapper: MessagesMapperService) { }

  updateStatus(prefix: Prefix, subscription: MailAlertSubscriptions) {
    return this.actions$.pipe(ofType(`${prefix} Update Status`),
      concatMap((action: fromActions.UpdateStatusAction) => this.updateStatusHandler(prefix, action, subscription)));
  }

  loadData(prefix: Prefix, subscription: MailAlertSubscriptions) {
    return this.actions$
      .pipe(ofType(`${prefix} Load Data`),
        switchMap(() => this.loadDataHandler(prefix, subscription)));
  }

  private updateStatusHandler(prefix: Prefix, action: fromActions.UpdateStatusAction, type: MailAlertSubscriptions) {
    const cell = action.payload;
    const global: MailAlert = cell.data;
    const body: UpdateMailAlertsStatusRequest = {
      subscriptionGroup: type,
      affectedUsername: global.username,
      targetState: cell.newValue,
      subscription: cell.column.name
    };

    return this.apiGateway.post(`${this.baseUrl}/setSubscriptionState`, body)
      .pipe(
        concatMap((response: UpdateStatusResponse) => {
          const messages: Message[] = this.mapper.toErrorMessages(response);
          if (messages && messages.length) {
            return of(new fromActions.SetMessagesAction(prefix, messages));
          }
          const item = response.affectedUserRow;
          const data = {
            ...item,
            ...item.subscriptions
          };

          return [
            new fromActions.SetMessagesAction(
              prefix,
              this.mapper.toSuccessMessages(`'${cell.column.title}' event subscription of user '${item.username}' updated.`)),
            new fromActions.UpdateStatusSuccessAction(prefix, data)
          ];
        }),
        catchError(error => [
          new fromActions.SetMessagesAction(prefix,[this.mapper.createErrorMessage(error.errorMessage)]),
          new fromActions.LoadDataFailedAction(prefix)
        ]));
  }

  private loadDataHandler(prefix: Prefix, subscriptionGroup: MailAlertSubscriptions) {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('subscriptionGroup', subscriptionGroup);
    return this.apiGateway.get(`${this.baseUrl}/loadTable`, httpParams)
      .pipe(
        switchMap((response: MailAlertsResponse) => {
          const messages: Message[] = this.mapper.toErrorMessages(response);
          if (messages && messages.length) {
            return [
              new fromActions.SetMessagesAction(prefix,messages),
              new fromActions.LoadDataFailedAction(prefix)
            ];
          }
          const data = response && response.rows ? response.rows.map(item => (
            {
              ...item,
              ...item.subscriptions
            })) : [];

          return [
            new fromActions.UpdateColumnsAction(prefix,response ? response.columns.map(item =>
              ({
                name: item.subscription,
                type: 'status',
                title: item.displayName,
                style: { 'width': '155px', 'text-align': 'center' },
                sortable: true,
                visible: true
              })
            ) : []),
            new fromActions.LoadDataSuccessAction(prefix, {
              values: data,
              count: data.length
            })];
        }),
        catchError(error => [
          new fromActions.SetMessagesAction(prefix, [this.mapper.createErrorMessage(error.errorMessage)]),
          new fromActions.LoadDataFailedAction(prefix)
        ]));
  }
}
