import { AfterViewInit, Component, Directive, ElementRef, HostListener, Input } from '@angular/core';




@Directive({
 selector: '[cmsSimpleDigitOnly]'
})
export class OnlyNumberDirective {


  @Input() pattern;


  @Input() dotAllowed = true;


  constructor(private el: ElementRef) { }


  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = <KeyboardEvent> event;
    const inputField = e.target as HTMLInputElement;

    if (e.keyCode === 190) {
      if (!this.dotAllowed || inputField.value.indexOf('.') > 0) {
        e.preventDefault();
      }
    }


    if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (
      (e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
      (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
  }


  @HostListener('paste', ['$event'])
  onPaste(event) {
    const inputFiled = event.target as HTMLInputElement;
    let pastedInput: string;
    if (window['clipboardData']) {  // IE
      pastedInput = window['clipboardData'].getData('Text');
    } else if (event.clipboardData && event.clipboardData.getData) {  // other Browsers
      pastedInput = event.clipboardData.getData('text/plain');
    }

    if(pastedInput.match(this.pattern)){
      inputFiled.value = '';
      this.clipBoardPaste(pastedInput);
    }else{
      event.preventDefault();
    }
  }

  private clipBoardPaste(input: string): void {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(input);
      document.execCommand('paste');
    }
  }
}



