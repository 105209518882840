<div [formGroup]="form">
  <div class="row">
    <div class="col-md-6 col-12 col-xl-5">
      <div class="form-block">
        <div class="row">
          <div class="col-12 col-sm-4">
            <label class="label--date form__label">
              Trade time (UTC)
              <span class="asterisk"></span>
            </label>
          </div>
          <div class="col-12 col-sm-4">
            <p-calendar placeholder="Trade Date"
                        id="tradeDate"
                        tabindex="1"
                        formControlName="tradeDate"
                        showIcon="true"
                        monthNavigator="true"
                        yearNavigator="true"
                        dateFormat="dd/mm/yy"
                        [ngClass]="{'error': errors['tradeTimeInUtc']}"
                        cmsDateRestriction>
            </p-calendar>
          </div>
          <div class="col-12 col-sm-4">
            <p-calendar placeholder="Trade time"
                        id="tradeDateTime"
                        tabindex="2"
                        formControlName="tradeTimeInUtc"
                        [showIcon]="false"
                        [timeOnly]="true"
                        dateFormat="hh:mm:ss"
                        [ngClass]="{'error': errors['tradeTimeInUtc']}"
                        [showSeconds]="true">
            </p-calendar>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-4">
            <label class="label--input form__label" for="tradeId">Trade ID
              <span class="asterisk"></span>
            </label>
          </div>
          <div class="col-12 col-sm-8">
            <input formControlName="tradeId"
                   id="tradeId"
                   type="text"
                   tabindex="3"
                   [maxLength]="30"
                   class="form-control p-component padding-6 text-medium"
                   [ngClass]="{'error': errors['tradeId']}">
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-4">
            <label class="label--input form__label" for="traderName">Trader name</label>
          </div>
          <div class="col-12 col-sm-8">
            <input formControlName="traderName"
                   id="traderName"
                   tabindex="4"
                   type="text"
                   [maxLength]="35"
                   class="form-control p-component padding-6 text-medium"
                   [ngClass]="{'error': errors['traderName']}">
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-4">
            <label class="label--input form__label" for="brokerCode">Broker code</label>
          </div>
          <div class="col-12 col-sm-8">
            <input formControlName="brokerCode"
                   id="brokerCode"
                   tabindex="5"
                   type="text"
                   [maxLength]="5"
                   class="form-control p-component padding-6 text-medium"
                   [ngClass]="{'error': errors['brokerCode']}">
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-4">
            <label class="label--input form__label">
              Counterparty
              <span class="asterisk"></span>
            </label>
          </div>
          <div class="col-12 col-sm-8">
            <p-dropdown
              [options]="counterPartiesOptions"
              tabindex="6"
              [ngClass]="{'error': errors['counterPartyId']}"
              formControlName="counterPartyId"
              [showClear]="false"
              [style]="{'width':'100%'}"
              (onChange)="changeCountryParty($event)">
            </p-dropdown>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-4"></div>
          <div class="col-12 col-sm-8">
            {{ counterParty?.legalName }}
          </div>
        </div>
      </div>

      <div class="form-block">
        <div class="row">
          <div class="col-12 col-sm-4">
            <label class="label--input form__label">
              Trade action
              <span class="asterisk"></span>
            </label>
          </div>
          <div class="col-12 col-sm-8">
            <div class="radio-box" [ngClass]="{'error': errors['isActingAsBuyer']}">
              <label class="me-3"><input type="radio"
                                         formControlName="isActingAsBuyer"
                                         id="tradeActionBuy"
                                         name="isActingAsBuyer"
                                         [value]="true"
                                         tabindex="7"
                                         class="m-1 align-bottom">Buy</label>
              <label class="me-3"><input type="radio"
                                         id="tradeActionSell"
                                         name="isActingAsBuyer"
                                         [value]="false"
                                         formControlName="isActingAsBuyer"
                                         class="m-1 align-bottom" tabindex="8">Sell</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-4">
            <label class="label--input form__label">
              Agreement
              <span class="asterisk"></span>
            </label>
          </div>
          <div class="col-12 col-sm-8">
            <p-dropdown
              [options]="agreementsOptions"
              tabindex="9"
              [ngClass]="{'error': errors['agreementId']}"
              formControlName="agreementId"
              [showClear]="false"
              [style]="{'width':'100%'}">
            </p-dropdown>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-4">
            <label class="label--input form__label">
              Currency
              <span class="asterisk"></span>
            </label>
          </div>
          <div class="col-12 col-sm-8">
            <p-dropdown
              id="enter-trade-currency"
              [options]="currenciesOptions"
              tabindex="10"
              [ngClass]="{'error': errors['currencyId']}"
              formControlName="currencyId"
              [showClear]="false"
              [style]="{'width':'100%'}"
              (onChange)="changeCurrency($event)">
            </p-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-12 col-xl-5">
      <div class="form-block">
        <div class="row">
          <div class="col-12 col-sm-4">
            <label class="label--input form__label">
              Delivery point
              <span class="asterisk"></span>
            </label>
          </div>
          <div class="col-12 col-sm-8">
            <p-dropdown
              [options]="deliveryPointsOptions"
              tabindex="11"
              [ngClass]="{'error': errors['deliveryPointId']}"
              formControlName="deliveryPointId"
              [showClear]="false"
              [style]="{'width':'100%'}">
            </p-dropdown>
          </div>
        </div>

        <div class="row" *ngIf="formValues?.hubCodeForGasVisible">
          <div class="col-12 col-sm-4">
            <label class="label--input form__label" for="ourHubCode">
              Our hub code
              <span class="asterisk"></span>
            </label>
          </div>
          <div class="col-12 col-sm-8">
            <input formControlName="ourHubCode"
                   id="ourHubCode"
                   tabindex="12"
                   type="text"
                   [maxLength]="50"
                   class="form-control p-component padding-6 text-medium"
                   [ngClass]="{'error': errors['ourHubCode']}">
          </div>
        </div>

        <div class="row" *ngIf="formValues?.hubCodeForGasVisible">
          <div class="col-12 col-sm-4">
            <label class="label--input form__label" for="cpHubCode">
              CP hub code
              <span class="asterisk"></span>
            </label>
          </div>
          <div class="col-12 col-sm-8">
            <input formControlName="cpHubCode"
                   id="cpHubCode"
                   tabindex="13"
                   type="text"
                   [maxLength]="50"
                   class="form-control p-component padding-6 text-medium"
                   [ngClass]="{'error': errors['cpHubCode']}">
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-4">
            <label class="label--date form__label">
              Delivery start
              <span class="asterisk"></span>
            </label>
          </div>
          <div class="col-12 col-sm-4">
            <p-calendar id="deliveryStartDate"
                        tabindex="14"
                        formControlName="deliveryStartDate"
                        showIcon="true"
                        monthNavigator="true"
                        yearNavigator="true"
                        dateFormat="dd/mm/yy"
                        [ngClass]="{'error': errors['deliveryStartDate']}"
                        (onSelect)="onCalculatedValuesChange()"
                        cmsDateRestriction>
            </p-calendar>
          </div>
          <div class="col-12 col-sm-4">
            <p-dropdown [options]="deliveryTimesOptions"
                        tabindex="15"
                        [ngClass]="{'error': errors['deliveryStartTime']}"
                        formControlName="deliveryStartTime"
                        [showClear]="false"
                        [style]="{'width':'100%'}"
                        (onChange)="onCalculatedValuesChange()">
            </p-dropdown>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-4">
            <label class="label--date form__label">
              Delivery end
              <span class="asterisk"></span>
            </label>
          </div>
          <div class="col-12 col-sm-4">
            <p-calendar id="deliveryEndDate"
                        tabindex="16"
                        formControlName="deliveryEndDate"
                        showIcon="true"
                        monthNavigator="true"
                        yearNavigator="true"
                        dateFormat="dd/mm/yy"
                        [ngClass]="{'error': errors['deliveryEndDate']}"
                        (onSelect)="onCalculatedValuesChange()"
                        cmsDateRestriction>
            </p-calendar>
          </div>
          <div class="col-12 col-sm-4">
            <p-dropdown
              [options]="deliveryTimesOptions"
              tabindex="17"
              [ngClass]="{'error': errors['deliveryEndTime']}"
              formControlName="deliveryEndTime"
              [showClear]="false"
              [style]="{'width':'100%'}"
              (onChange)="onCalculatedValuesChange()">
            </p-dropdown>
          </div>
        </div>
        <div class="row" *ngIf="deliveryPeriod">
          <div class="col-12 col-sm-4"></div>
          <div class="col-12 col-sm-8"> {{ deliveryPeriod }}</div>
        </div>
      </div>
      <div class="form-block">
        <div class="row">
          <div class="col-12 col-sm-4">
            <label class="label--input form__label" for="capacityValue">
              Capacity
              <span class="asterisk"></span>
            </label>
          </div>
          <div class="col-12 col-sm-4">
            <input formControlName="capacityValue"
                   id="capacityValue"
                   type="text"
                   tabindex="18"
                   cmsDigitOnly
                   class="form-control p-component padding-6 text-medium text-end"
                   [maxLength]="14"
                   [ngClass]="{'error': errors['capacityValue']}"
                   (change)="onCalculatedValuesChange()">

          </div>
          <div class="col-12 col-sm-4">
            <label>{{ formValues?.deliveryRateUnit }}</label>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-4">
            <label class="label--input form__label" for="priceValue">
              Price
              <span class="asterisk"></span>
            </label>
          </div>
          <div class="col-12 col-sm-4">
            <input formControlName="priceValue"
                   type="text"
                   tabindex="19"
                   id="priceValue"
                   class="form-control p-component padding-6 text-medium text-end"
                   cmsDigitOnly
                   [ngClass]="{'error': errors['priceValue']}"
                   [maxLength]="10"
                   (change)="onCalculatedValuesChange()">
          </div>
          <div class="col-12 col-sm-4">
            <label>{{ currency?.label }}/{{ formValues?.volumeUnit }}</label>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-4">
            <label class="label--input form__label">Total volume</label>
          </div>
          <div class="col-12 col-sm-4">
            {{ totalVolumeData?.totalVolume }}
          </div>
          <div class="col-12 col-sm-4">
            <label>{{ formValues?.volumeUnit }}</label>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-4">
            <label class="label--input form__label">Total contract value</label>
          </div>
          <div class="col-12 col-sm-4">
            {{ totalContractValue }}
          </div>
          <div class="col-12 col-sm-4">
            <label>{{ currency?.label }}</label>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="row" *ngIf="formValues?.bscAccountInformationForPowerGbSectionVisible">
    <div class="col-md-6 col-12 col-xl-5">
      <div> BSC Account Information (UK Power)</div>
      <div class="bsc-box">
        <div class="row">
          <div class="col-12 col-sm-4">
            <label class="label--input form__label" for="bscOurPartyId">
              Our party ID
              <span class="asterisk"></span>
            </label>
          </div>
          <div class="col-12 col-sm-8">
            <input formControlName="bscOurPartyId"
                   id="bscOurPartyId"
                   type="text"
                   tabindex="20"
                   [maxLength]="50"
                   class="form-control p-component padding-6 text-medium"
                   [ngClass]="{'error': errors['bscOurPartyId']}">
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-4">
            <label class="label--input form__label" for="bscCounterpartyId">
              Counterparty ID
              <span class="asterisk"></span>
            </label>
          </div>
          <div class="col-12 col-sm-8">
            <input formControlName="bscCounterpartyId"
                   id="bscCounterpartyId"
                   type="text"
                   tabindex="21"
                   [maxLength]="50"
                   class="form-control p-component padding-6 text-medium"
                   [ngClass]="{'error': errors['bscCounterpartyId']}">
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-4">
            <label class="label--input form__label">
              Notification agent
              <span class="asterisk"></span>
            </label>
          </div>
          <div class="col-12 col-sm-8">
            <div class="radio-box" [ngClass]="{'error': errors['bscNotificationAgent']}">
              <label class="me-3"><input type="radio"
                                         formControlName="bscIsSenderNotificationAgent"
                                         id="notificationAgentUs"
                                         name="bscIsSenderNotificationAgent"
                                         [value]="true"
                                         tabindex="22"
                                         class="m-1 align-bottom">Us</label>
              <label class="me-3"><input type="radio"
                                         id="notificationAgentCounterparty"
                                         name="bscIsSenderNotificationAgent"
                                         [value]="false"
                                         formControlName="bscIsSenderNotificationAgent"
                                         class="m-1 align-bottom" tabindex="23">Counterparty</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-4">
            <label class="label--input form__label">
              Our account
              <span class="asterisk"></span>
            </label>
          </div>
          <div class="col-12 col-sm-8">
            <div class="radio-box" [ngClass]="{'error': errors['bscOurAccount']}">
              <label class="me-3"><input type="radio"
                                         formControlName="bscIsSenderProductionAccount"
                                         id="senderProductionAccount"
                                         name="bscIsSenderProductionAccount"
                                         [value]="true"
                                         tabindex="24"
                                         class="m-1 align-bottom">Production</label>
              <label class="me-3"><input type="radio"
                                         id="senderProductionAccountConsumption"
                                         name="bscIsSenderProductionAccount"
                                         [value]="false"
                                         formControlName="bscIsSenderProductionAccount"
                                         class="m-1 align-bottom" tabindex="25">Consumption</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-4">
            <label class="label--input form__label">
              CP account
              <span class="asterisk"></span>
            </label>
          </div>
          <div class="col-12 col-sm-8">
            <div class="radio-box" [ngClass]="{'error': errors['bscCounterpartyAccount']}">
              <label class="me-3"><input type="radio"
                                         formControlName="bscIsCounterpartyProductionAccount"
                                         id="counterpartyProductionAccount"
                                         name="bscIsCounterpartyProductionAccount"
                                         [value]="true"
                                         tabindex="26"
                                         class="m-1 align-bottom">Production</label>
              <label class="me-3"><input type="radio"
                                         id="counterpartyProductionAccountConsumption"
                                         name="bscIsCounterpartyProductionAccount"
                                         [value]="false"
                                         formControlName="bscIsCounterpartyProductionAccount"
                                         class="m-1 align-bottom" tabindex="27">Consumption</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-4">
            <label class="label--input form__label">
              Schedule 5
              <span class="asterisk"></span>
            </label>
          </div>
          <div class="col-12 col-sm-8">
            <div class="radio-box" [ngClass]="{'error': errors['bscSchedule5On']}">
              <label class="me-3"><input type="radio"
                                         formControlName="bscIsSchedule5On"
                                         id="bscIsSchedule5OnOff"
                                         name="bscIsSchedule5On"
                                         [value]="false"
                                         tabindex="28"
                                         class="m-1 align-bottom">Off</label>
              <label class="me-3"><input type="radio"
                                         id="bscIsSchedule5OnOn"
                                         name="bscIsSchedule5On"
                                         [value]="true"
                                         formControlName="bscIsSchedule5On"
                                         class="m-1 align-bottom" tabindex="29">On</label>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</div>
