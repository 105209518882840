<p-panel header="{{(annoucement$ | async).title}}">
  <div class="padding-6" [ngClass]="{'editable': (annoucement$ | async).editable}" (click)="switchEditMode()"  *ngIf="isDisplayMode | async">
    <span class="edit-content-icon fa fa-pencil" *ngIf="(annoucement$ | async).editable"></span>
    <markdown [data]="(annoucement$ | async).content"></markdown>
  </div>
  <cms-help-text-editor [state]="'ANNOUNCEMENTS'" (canceled)="switchEditMode()"
                        [content]="(annoucement$ | async).content"
                        [title]="(annoucement$ | async).title"
                        *ngIf="isEditMode | async"></cms-help-text-editor>
</p-panel>
