<div class="panel-box margin-bottom-12 margin-top-12 decent-max-width">
  <p-messages [value]="messages" [closable]="true"></p-messages>
  <form class="align-items-center dropdown" [formGroup]="form">
    <cms-auto-complete [items]="types"
                       [inputStyleClass]="errors['Type'] ? 'error type margin-3' : 'type margin-3'"
                       formControlName="type"
                       required="true"
                       [placeholder]="'Type'"></cms-auto-complete>
    <cms-auto-complete [items]="commodities"
                       [inputStyleClass]="errors['Commodity'] ? 'error commodity margin-3' : 'commodity margin-3'"
                       formControlName="commodity"
                       required="true"
                       [placeholder]="'Commodity'"></cms-auto-complete>
    <cms-auto-complete [items]="priceTypes"
                       [inputStyleClass]="errors['Price Type'] ? 'error priceType margin-3' : 'priceType margin-3'"
                       formControlName="priceType"
                       required="true"
                       [placeholder]="'Price Type'"></cms-auto-complete>
    <cms-auto-complete [items]="natureOfPrices"
                       [inputStyleClass]="errors['Nature of Price'] ? 'error natureOfPrice margin-3' : 'natureOfPrice margin-3'"
                       formControlName="natureOfPrice"
                       required="true"
                       [placeholder]="'Nature Of Price'"></cms-auto-complete>
    <div class="btn__container inline-block">
      <button type="submit" class="btn margin-top-3 margin-bottom-3 margin-left-12" (click)="onAdd()" [disabled]="form.invalid">Add</button>
      <button class="btn margin-top-3 margin-bottom-3 margin-left-12" (click)="onClear()">Clear</button>
    </div>
  </form>
</div>
