import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TransactionSheetResponseModel } from '../../../../common/sheet/transaction-sheet-model';
import { Observable, of, Subject } from 'rxjs';
import { RemitTable2TradeService } from './remit-table2-trade.service';
import { EndPointConstants } from '../../../../common/end-point-constants';
import { Message } from 'primeng/api';
import { catchError, mergeMap, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { RemitTradeEntryTable2ModifyService } from './modify/remit-trade-entry-table2-modify.service';
import { ErrorMessageService } from '@common/errors/error-message.service';

@Component({
  selector: 'cms-remit-table2-trade',
  templateUrl: './remit-table2-trade.component.html',
  styleUrls: ['./remit-table2-trade.component.scss']
})
export class RemitTable2TradeComponent implements OnInit, OnDestroy {

  msgs: Message[] = [];

  showHtmlTrade: boolean = true;
  private onDestroy$ = new Subject();

  transactionSheetResponse$: Observable<TransactionSheetResponseModel>;

  constructor(private service: RemitTable2TradeService,
              private remitTradeModifyService: RemitTradeEntryTable2ModifyService,
              private route: ActivatedRoute,
              public readonly errorMessageService:ErrorMessageService) {
  }

  ngOnInit() {
    this.msgs = [];

    // when trade loaded
    this.remitTradeModifyService.onTradeLoaded().pipe(takeUntil(this.onDestroy$)).subscribe(tradeEntry => {
      if(tradeEntry && tradeEntry.references){
        this.showHtmlTrade = false;
      }else{
        this.showHtmlTrade = true;
      }
    });


    this.transactionSheetResponse$ = this.route.queryParams.pipe(
      mergeMap((params: Params) => {
        const regime = params[EndPointConstants.PARAM_REGIME];
        const recordType = params[EndPointConstants.PARAM_RECORD_TYPE];
        const documentType = params[EndPointConstants.PARAM_DOCUMENT_TYPE];
        const historyId = params[EndPointConstants.PARAM_HISTORY_ID];
        return this.service.getTransactionSheet(regime, recordType, documentType, historyId).pipe(catchError((err: HttpErrorResponse) => this.handleError(err)));
      }));
  }

  handleError($error: any): Observable<any> {
    let errorDetail = '';
    if ($error.error && $error.error.errorMessage) {
      errorDetail = $error.error.errorMessage;
    }

    this.msgs.push({ severity: 'error', summary: $error.statusText, detail: errorDetail });
    return of({});
  }


  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

}
