<div class="datatable-container">
  <cms-datatable selectionMode="single"
                 [data]="records"
                 [totalRecords]="recordCount"
                 [tableColumns]="cols"
                 sortOrder="ASCENDING"
                 [sortField]="sortBy"
                 [paginator]="true"
                 [loading]="loading"
                 [lazy]="!!(records && records.length)"
                 (lazyLoad)="onLazyLoad($event)">
    <ng-template pTemplate="caption">
      <div class="row">
        <div class="col-12 text-end" style="max-width: 650px;">
          <form [formGroup]="filterForm" class="row align-items-center col-12" (ngSubmit)="filter()">
            <div class="col-8 padding-0">
              <input formControlName="mpCode" id="input-filter-remit-omp-whitelist" type="text" class="form-control p-component padding-6 text-medium" placeholder="Filter">
            </div>
            <div class="col-4">
              <button id="btn-submit-remit-omp-whitelist-filter" class="btn form-control margin-3 padding-6" style="width: 75px;" type="submit">Filter</button>
              <button id="btn-submit-remit-omp-whitelist-reset" style="width: 75px;" class="btn form-control margin-3 padding-6" type="button" (click)="reset();">Reset</button>
            </div>
          </form>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="customBody" let-row="row" let-rowIndex="rowIndex">
      <tr>
        <ng-container *ngFor="let col of cols">
          <td *ngIf="col.visible" [ngStyle]="col.style">
            <span [ngClass]="{'text-alert' : isFieldAlert(row[col.name])}">{{row[col.name]}}</span>
          </td>
        </ng-container>
    </ng-template>
  </cms-datatable>
</div>

