import { Action } from '@ngrx/store';
import { Message } from 'primeng/api';
import { DatatableConfig } from '@common/shared/results.model';
import {
  ErrMifidDetailsDataResponse,
  ErrMifidDetailsFormValues,
  ErrMifidDetailsInformationResponse,
  ErrMifidDetailsItem,
} from '../../mifid/mifid.model';
import { Column } from '@common/shared/datatable.model';

export const SET_TO_DEFAULT = '[ERR MIFID FILE DETAILS] Set to default';
export const SET_ORGANISATION_ID = '[ERR MIFID FILE DETAILS] Set Organisation Id';
export const SET_MESSAGES = '[ERR MIFID FILE DETAILS] Set Messages';
export const LOAD_DATA = '[ERR MIFID FILE DETAILS] Load Data';
export const LOAD_DATA_SUCCESS = '[ERR MIFID FILE DETAILS] Load Data success';
export const SORT_AND_PAGE_DATA = '[ERR MIFID FILE DETAILS] Sort and Page Data';
export const LOAD_INFORMATION = '[ERR MIFID FILE DETAILS] Load information';
export const LOAD_INFORMATION_SUCCESS = '[ERR MIFID FILE DETAILS] Load information success';
export const SET_FILE_ID = '[ERR MIFID FILE DETAILS] Set fileId';
export const SET_COLUMNS = '[ERR MIFID FILE DETAILS] Set columns';
export const LOAD_FORM_VALUES = '[ERR MIFID FILE DETAILS] Load form values';
export const LOAD_FORM_VALUES_SUCCESS = '[ERR MIFID FILE DETAILS] Load form values success';
export const ADD_NEW_ITEM = '[ERR MIFID FILE DETAILS] Add new item';
export const ADD_NEW_ITEM_SUCCESS = '[ERR MIFID FILE DETAILS] Add new item success';
export const EDIT_ITEM = '[ERR MIFID FILE DETAILS] Edit item';
export const EDIT_ITEM_SUCCESS = '[ERR MIFID FILE DETAILS] Edit item success';
export const CONFIRM_ITEM = '[ERR MIFID FILE DETAILS] Confirm item';
export const CONFIRM_ITEM_SUCCESS = '[ERR MIFID FILE DETAILS] Confirm item success';
export const DISCARD_CHANGES = '[ERR MIFID FILE DETAILS] Discard changes';
export const DISCARD_CHANGES_SUCCESS = '[ERR MIFID FILE DETAILS] Discard changes success';
export const DELETE_ITEM_POSITION = '[ERR MIFID FILE DETAILS] Delete item';
export const DELETE_ITEM_POSITION_SUCCESS = '[ERR MIFID FILE DETAILS] Delete item success';

export class SetToDefaultAction implements Action {
  readonly type = SET_TO_DEFAULT;
}

export class SetOrganisationIdAction implements Action {
  readonly type = SET_ORGANISATION_ID;

  constructor(public payload: number) { }
}

export class SortAndPageDataAction implements Action {
  readonly type = SORT_AND_PAGE_DATA;

  constructor(public payload: DatatableConfig) { }
}

export class LoadDataAction implements Action {
  readonly type = LOAD_DATA;
}

export class LoadDataSuccessAction implements Action {
  readonly type = LOAD_DATA_SUCCESS;

  constructor(public payload: ErrMifidDetailsDataResponse) { }
}

export class LoadInformationAction implements Action {
  readonly type = LOAD_INFORMATION;
}

export class LoadInformationSuccessAction implements Action {
  readonly type = LOAD_INFORMATION_SUCCESS;

  constructor(public payload: ErrMifidDetailsInformationResponse) { }
}

export class LoadFormValuesAction implements Action {
  readonly type = LOAD_FORM_VALUES;
}

export class LoadFormValuesSuccessAction implements Action {
  readonly type = LOAD_FORM_VALUES_SUCCESS;

  constructor(public payload: { value: ErrMifidDetailsFormValues }) { }
}

export class SetMessagesAction implements Action {
  readonly type = SET_MESSAGES;

  constructor(public payload: Message[]) { }
}

export class SetFileIdAction implements Action {
  readonly type = SET_FILE_ID;

  constructor(public payload: number) { }
}

export class SetColumnsAction implements Action {
  readonly type = SET_COLUMNS;

  constructor(public payload: Column[]) { }
}

export class AddNewItemAction implements Action {
  readonly type = ADD_NEW_ITEM;

  constructor(public payload: ErrMifidDetailsItem) { }
}

export class AddNewItemSuccessAction implements Action {
  readonly type = ADD_NEW_ITEM_SUCCESS;

}

export class EditItemAction implements Action {
  readonly type = EDIT_ITEM;

  constructor(public payload: ErrMifidDetailsItem) { }
}

export class EditItemSuccessAction implements Action {
  readonly type = EDIT_ITEM_SUCCESS;
}

export class ConfirmItemAction implements Action {
  readonly type = CONFIRM_ITEM;
}

export class ConfirmItemSuccessAction implements Action {
  readonly type = CONFIRM_ITEM_SUCCESS;
}

export class DiscardChangesAction implements Action {
  readonly type = DISCARD_CHANGES;
}

export class DiscardChangesSuccessAction implements Action {
  readonly type = DISCARD_CHANGES_SUCCESS;
}

export class DeleteItemPositionAction implements Action {
  readonly type = DELETE_ITEM_POSITION;

  constructor(public payload: any) {}
}

export class DeleteItemPositionSuccessAction implements Action {
  readonly type = DELETE_ITEM_POSITION_SUCCESS;
}

export type ErrMifidFileDetailsActions = SetToDefaultAction | SetOrganisationIdAction |
  SetMessagesAction | SortAndPageDataAction | LoadDataAction | LoadDataSuccessAction |
  LoadInformationAction | LoadInformationSuccessAction | SetFileIdAction |
  SetColumnsAction | LoadFormValuesAction | LoadFormValuesSuccessAction |
  AddNewItemAction | AddNewItemSuccessAction | EditItemAction | EditItemSuccessAction |
  ConfirmItemAction | ConfirmItemSuccessAction | DiscardChangesAction |
  DiscardChangesSuccessAction | DeleteItemPositionAction | DeleteItemPositionSuccessAction;
