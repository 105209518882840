import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ContactsFilters, OrganisationGroup } from '../contacts.model';

@Component({
  selector: 'cms-contacts-filters',
  templateUrl: './contacts-filters.component.html',
  styleUrls: ['./contacts-filters.component.scss']
})
export class ContactsFiltersComponent implements OnInit, OnChanges {
  @Input() filters: ContactsFilters;
  @Input() orgGroups: OrganisationGroup[];
  @Output() filter = new EventEmitter<ContactsFilters>();

  form: UntypedFormGroup;
  orgGroupsOptions: {label: string, value: any}[] = [];

  constructor( private formBuilder: UntypedFormBuilder ) { }

  ngOnInit(): void {
    const {
      organisationGroupId = null
    } = this.filters;
    this.form = this.formBuilder.group({
      organisationGroupId: organisationGroupId,
    });

    this.initGroups();
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (changes.orgGroups) {
      this.initGroups();
    }

    if (this.form && changes.filters) {
      const {
        organisationGroupId = null
      } = this.filters;
      this.form.setValue({
        organisationGroupId
      });
      this.form.updateValueAndValidity();
    }
  }

  onChange(): void {
    this.filter.emit({
      organisationGroupId: this.form.value.organisationGroupId
    });
  }

  private initGroups(): void {
    this.orgGroupsOptions = this.orgGroups && this.orgGroups.length ?
      this.orgGroups.map(item => ({ value: item.key, label: item.value })) : [];
    this.orgGroupsOptions = [{ label: 'All Organisation Groups', value: null }, ...this.orgGroupsOptions];
  }
}
