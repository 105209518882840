type PhysFin = 'Physical' | 'Financial';




export interface SettingsTableModel {
  currency: string;
  invoiceType: PhysFin;
  absTolerance: number;
  prectTolerance: number;
  id:number;
}


export const esmToleranceTableColumns = [
  { name: 'currency', type: 'text', title: 'Currency', sortable: true, visible: true },
  { name: 'invoiceType',  type: 'text', title: 'Physical Or Financial',  sortable: true, visible: true },
  { name: 'absTolerance',   type: 'editable', title: 'Absolute Amount',  sortable: true, visible: true },
  { name: 'prectTolerance',  type: 'editable', title: 'Percentage Amount', sortable: true, visible: true },
  { name: 'id',  type: null, title: null, sortable: false, visible: false },

];
