import { Injectable } from '@angular/core';
import { Message } from 'primeng/api';
import { ErrorResponse } from './error-response.model';

@Injectable({
  providedIn: 'root'
})
export class MessagesMapperService {
  static hasAnyError(response: ErrorResponse): boolean {
    return response && !!(response.fieldValidationMessages || response.validationFailureMessages || response.errorMessage || response.stale);
  }

  static arrayToErrorMessage(errors: string[]): Message[] {
    return errors ? errors.map((detail: string) => ({ severity: 'error', detail })) : [];
  }

  toErrorMessages(response: ErrorResponse, defaultMsg?: string | string[]): Message[] {
    if (response.fieldValidationMessages) {
      return this.objectToErrorMessages(response.fieldValidationMessages);
    }
    if (response.validationFailureMessages) {
      return this.arrayToErrorMessage(response.validationFailureMessages);
    }

    if (response.errorMessage) {
      return [this.createErrorMessage(response.errorMessage)];
    }

    if (defaultMsg) {
      return this.arrayToErrorMessage(Array.isArray(defaultMsg) ? defaultMsg : [defaultMsg]);
    }

    return [];
  }

  objectToErrorMessages(errors: { [key: string]: string }): Message[] {
    return errors ? Object
      .entries(errors)
      .map(([key, detail]: [string, string]) => ({ key, severity: 'error', detail })) : [];
  }

  arrayToErrorMessage(errors: string[]): Message[] {
    return errors ? errors.map((detail: string) => ({ severity: 'error', detail })) : [];
  }

  createErrorMessage(detail: string): Message {
    return { severity: 'error', detail } as Message;
  }

  toSuccessMessages(detail: string): Message[] {
    return [{ severity: 'success', detail }];
  }

  toSuccessMessagesObj(detail: string): { messages: Message[] } {
    return { messages: [{ severity: 'success', detail }] };
  }

  toErrorMessagesObj(detail: string): { messages: Message[] } {
    return { messages: [{ severity: 'error', detail }] };
  }

  toWarningMessages(detail: string): Message[] {
    return [{ severity: 'warn', detail }];
  }

  createErrorDownloadMessage(): Message {
    return { severity: 'warn', detail: 'could not download billing run, please refresh page' } as Message;
  }
}
