<div class="panel-box margin-bottom-15">
  <label class="text-bold form__label">Select VAT ID</label>
  <form class="row align-items-center g-0 m-0" [formGroup]="form">
    <button *ngIf="newVisible" class="btn margin-right-9 col-auto" type="button" (click)="onRunNew()">New</button>
    <p-dropdown class="col-auto ms-0" id="vat-id-selector" [options]="valIDOptions" formControlName="masterDataId" (onChange)="onSelect()"></p-dropdown>
    <p-checkbox inputId="allow-banking-details-download"
                [ngClass]="{'l-disabled':!enableBankingDetailsCheckbox}"
                [(ngModel)]="allowBankingDetailsDownload"
                (onChange)="toggle()"
                binary="true"
                [formControlName]="'allowBankingDetailsDownload'"
                label="Allow Banking Details for eSM Master Data Download"
                class="ms-4 mt-2 col-auto">
    </p-checkbox>
  </form>
</div>
