import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cms-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoDialogComponent {
  @Input() display: boolean;
  @Input() errorDetails: string;
  @Output() close = new EventEmitter();

  onVisibleChange(): void {
    this.close.emit();
  }
}
