<div class="datatable-container">
  <cms-datatable selectionMode="single"
                 sortOrder="DESCENDING"
                 [data]="records"
                 [totalRecords]="recordCount"
                 [tableColumns]="cols"
                 [currentPage]="currentPage"
                 [sortField]="sortBy"
                 [paginator]="true"
                 [loading]="(filesInService.getLoading() | async)"
                 [hidden]="hidden"
                 [autoLayout]="true"
                 [scrollable]="false"
                 [lazy]="!!(records && records.length)"
                 (lazyLoad)="onLazyLoad($event)">
    <ng-template pTemplate="caption">
      <div class="row">
        <div class="col-12 text-end">
          <button pButton type="button" icon="fa fa-download" label="Export" (click)="downloadExcel()"></button>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="customBody" let-row="row" let-rowIndex="rowIndex">
      <tr>
        <ng-container *ngFor="let col of cols">
          <td *ngIf="col.visible && col.name !== 'buttons' && !col.editable" [ngStyle]="col.style">
            <span>{{row[col.name]}}</span>
          </td>
          <td *ngIf="col.visible && !col.editable && col.name === 'buttons'" [ngStyle]="col.style">
            <div class="btn-wrapper inline-block">
              <a routerLink="/api/err/remit/filesin/document/xml" style="padding: 3px;" [queryParams]="{'id': row.id, 'organisationId': organisationId}" title="Document" target="_blank" download>
                <button pButton type="button" icon="fa fa-file-code-o"></button>
              </a>
            </div>
            &nbsp;
            <div class="btn-wrapper inline-block">
              <a routerLink="/err/remit_filesin_audittrail" class="padding-3 btn btn-icon fa fa-search" style="padding: 3px;" title="Audit Trail" target="_blank" [queryParams]="createAuditTrailQueryParams(row)"></a>
            </div>
          </td>
          <td pEditableColumn *ngIf="col.visible && col.editable" [ngStyle]="col.style">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input type="text" [(ngModel)]="row[col.name]" class="form-control">
              </ng-template>
              <ng-template pTemplate="output">
                <span>{{row[col.name]}}</span>
              </ng-template>
            </p-cellEditor>
          </td>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="customEmptyMessage">
      <tr>
        <td colspan="100" *ngIf="!(isLoading | async)">
          No records found
        </td>
      </tr>
    </ng-template>
  </cms-datatable>
</div>
