<p-messages [(value)]="messages" [closable]="false"></p-messages>
<div>

</div>
<div class="panel-box">
  <cms-headline [pageTitle]="'Preferred Values for \'' + fieldDisplayName + '\''" [displayOrganisationGroupName]="false"></cms-headline>
  <a routerLink="/err/settings/trade_entry" title="Back">
    <button type="button" class="btn padding-btn margin-right-6"><i class="fa fa-arrow-left"></i> Go Back</button>
  </a>
  <div class="margin-top-12 margin-bottom-30 decent-max-width">

    <cms-info ngPreserveWhitespaces>
      * Drag items to the left to add them to your preferred values.
      * Drag them up/down within the list to reorder them.
      * Drag them to the right to remove them from your preferred values.
      * Clear your preferences by clicking **Load Defaults**.
      * Choose the name of a colleague to adopt his or her preferences.
      * Always press **Save Changes** to make your changes permanent.
    </cms-info>
    <div class="row full-width">

      <div class="col-sm-6 col-md-3 col-lg-2 margin-bottom-15">
        <button class="btn padding-btn" [disabled]="disabled" (click)="saveSettings()">Save Changes</button>
      </div>
      <div class="col-sm-6 col-md-3 col-lg-2 margin-bottom-15">
        <button class="btn padding-btn" (click)="loadDefault()">Load Defaults</button>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6 offset-lg-1 p-lg-offset-1 margin-bottom-15">
        Load settings from user:
        <p-dropdown id="user-settings-selector" [options]="users" optionLabel="value" [style]="{'width':'210px'}" (onChange)="loadUserSettings($event)"></p-dropdown>
      </div>
    </div>
  </div>
  <div [class]="getListWidthConfigurationClass()">
    <p-pickList id="trade-entry-picklist" [source]="preferred" sourceHeader="Preferred Values" targetHeader="Not Preferred" [target]="nonPreferred" [responsive]="true" dragdrop="true" dragdropScope="customise" [showSourceControls]="false" [showTargetControls]="false" (onMoveToSource)="enableSaveButton(); resetDropPoints();" (dragend)="resetDropPoints();" (onMoveToTarget)="enableSaveButton(); resetDropPoints();">
      <ng-template let-item pTemplate="item">
        <div class="p-helper-clearfix" (dragover)="moveList($event);">
          {{item.value}}
        </div>
      </ng-template>
    </p-pickList>
  </div>
</div>
