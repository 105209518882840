import { Message } from 'primeng/api';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import {
  defaultRrmFilter,
  ExternalFormData,
  ExternalRrmStatus,
  ExternalRrm,
  ExternalRrmFilter
} from '../../external-rrm/external-rrm.model';
import * as fromActions from './external-rrm.actions';
import * as moment from 'moment';

export interface ExternalRrmState {
  organisationId: number;
  loading: boolean;
  messages: Message[];
  filter: ExternalRrmFilter;
  data: DatatableData<ExternalRrm[]>;
  formData: ExternalFormData;
  status?: ExternalRrmStatus;
  paging: PagingFilter;
  sorting: SortingFilter;
}

export const initialState: ExternalRrmState = {
  organisationId: null,
  loading: false,
  messages: [],
  formData: null,
  status: null,
  filter: { ...defaultRrmFilter },
  data: {
    values: null,
    count: 0,
    currentPage: 0
  },
  paging: {
    page: 0,
    entriesPerPage: 25
  },
  sorting: {
    columnName: 'receiptTimestamp',
    sortOrder: 'DESCENDING'
  },
};

export function externalRrmReducer(state: ExternalRrmState = { ...initialState },
  action: fromActions.ExternalRrmActions): ExternalRrmState {
  switch (action.type) {
    case fromActions.setToDefaultAction.type:
      return { ...initialState };
    case fromActions.setOrganisationId.type:
      return {
        ...state,
        organisationId: action.payload
      };
    case fromActions.setMessagesAction.type:
      return {
        ...state,
        messages: action.payload,
        loading: false
      };
    case fromActions.setStatusAction.type:
      return {
        ...state,
        status: {
          lastSuccessfullReceived: moment(action.payload.lastSuccessfullReceived).format('DD/MM/YYYY HH:mm:ss'),
          lastSuccessfullSent:  moment(action.payload.lastSuccessfullSent).format('DD/MM/YYYY HH:mm:ss'),
          oldestOutstandingResponse:  moment(action.payload.oldestOutstandingResponse).format('DD/MM/YYYY HH:mm:ss'),
          outstandingResponses:  action.payload.outstandingResponses

        }

      };
    case fromActions.onPresetValuesAction.type:
      return {
        ...state,
        formData: {
          ...action.payload,
          end: new Date(action.payload.receivedFrom),
          start:  new Date(action.payload.receivedTo)
        }
      };
    case fromActions.setFiltersValueAction.type:
      return {
        ...state,
        filter: action.payload
      };
    case fromActions.filterAction.type:
      return {
        ...state,
        loading: true
      };
    case fromActions.filterSuccessAction.type:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    case fromActions.sortAndPageDataAction.type:
      return {
        ...state,
        paging: action.payload.paging,
        sorting: action.payload.sorting
      };
    default:
      return { ...state };
  }
}

export const getOrganisationId = (state: ExternalRrmState) => state.organisationId;
export const getData = (state: ExternalRrmState): DatatableData<ExternalRrm[]> => state.data;
export const getSorting = (state: ExternalRrmState) => state.sorting;
export const getPaging = (state: ExternalRrmState) => state.paging;
export const getLoading = (state: ExternalRrmState) => state.loading;
export const getMessages = (state: ExternalRrmState) => state.messages;
export const getFilter = (state: ExternalRrmState) => state.filter;
export const getFormData = (state: ExternalRrmState) => state.formData;
export const getStatus = (state: ExternalRrmState) => state.status;
