<p-overlayPanel #op [dismissable]="true"  appendTo="body" [style]="style">
  <!--<div class="notes-cell__arrow notes__arrow&#45;&#45;left"></div>-->
  <cms-note *ngIf="notes && notes.length" [showNoteInfo]="false" [note]="notes[0]"></cms-note>
</p-overlayPanel>
<div class="btn-wrapper flex align-items-center">
  <button pButton
          class="notes-cell__button"
          type="button"
          [title]="!(notes && notes.length) ? title() : 'Notes'"
          (mouseenter)="onMouseEnter(op, $event)"
          (mouseleave)="onMouseLeave(op)"
          (click)="onClick()">
    <i class="fa fa-commenting-o me-1"></i>

    <span *ngIf="notes && notes.length"
          class="notes-cell__button--bg"
          [ngClass]="{
            'fha': notes && notes[0].state === 'FHA',
            'own_cp': notes && notes[0].state === 'OWN_CP',
            'other_cp': notes && notes[0].state === 'OTHER_CP'
            }"></span>
    <span>{{notes ? notes.length : 0}}</span>
  </button>
</div>
