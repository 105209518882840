import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemitTradeEntryComponent } from './remit-trade-entry.component';
import { RemitTradeEntryTable1Component } from './table1/remit-trade-entry-table1.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CmsCommonModule } from '../../../common/cms-common.module';
import { RouterModule } from '@angular/router';
import { RemitTradeEntryTable1ReferencesComponent } from './table1/form/remit-trade-entry-table1-references.component';
import { RemitTradeEntryTable1ProductDetailsComponent } from './table1/form/remit-trade-entry-table1-product-details.component';
import { RemitTradeEntryTable1Service } from './table1/remit-trade-entry-table1.service';
import { RemitTradeEntryTable1SettlementComponent } from './table1/form/remit-trade-entry-table1-settlement.component';
import { RemitTradeEntryTable1DeliveryProfileComponent } from './table1/form/remit-trade-entry-table1-delivery-profile.component';
import { RemitTradeEntryTable1ReportingCounterpartyComponent } from './table1/form/remit-trade-entry-table1-reporting-counterparty.component';
import { RemitTradeEntryTable1CommercialsComponent } from './table1/form/remit-trade-entry-table1-commercials.component';
import { RemitTradeEntryTable1OtherCounterpartyComponent } from './table1/form/remit-trade-entry-table1-other-counterparty.component';
import { RemitTradeEntryTable1OptionDetailComponent } from './table1/form/remit-trade-entry-table1-option-detail.component';
import { RemitTradeEntryTable2Component } from './table2/remit-trade-entry-table2.component';
import { RemitTradeEntryTable2Service } from './table2/remit-trade-entry-table2.service';
import { RemitTradeEntryTable2FormModule } from './table2/form/remit-trade-entry-table2-form.module';
import { DropdownModule } from 'primeng/dropdown';
import { TabViewModule } from 'primeng/tabview';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { CalendarModule } from 'primeng/calendar';
import { InputMaskModule } from 'primeng/inputmask';
import { CheckboxModule } from 'primeng/checkbox';

@NgModule({
  imports: [
    CommonModule,
    CmsCommonModule,
    FormsModule,
    ReactiveFormsModule,
    TabViewModule,
    CalendarModule,
    DropdownModule,
    InputMaskModule,
    CheckboxModule,
    MessageModule,
    MessagesModule,
    RouterModule,
    RemitTradeEntryTable2FormModule
  ],
  providers: [RemitTradeEntryTable1Service, RemitTradeEntryTable2Service],
  declarations: [
    RemitTradeEntryTable1Component,
    RemitTradeEntryTable2Component,
    RemitTradeEntryComponent,
    RemitTradeEntryTable1ReferencesComponent,
    RemitTradeEntryTable1ProductDetailsComponent,
    RemitTradeEntryTable1SettlementComponent,
    RemitTradeEntryTable1DeliveryProfileComponent,
    RemitTradeEntryTable1ReportingCounterpartyComponent,
    RemitTradeEntryTable1OtherCounterpartyComponent,
    RemitTradeEntryTable1CommercialsComponent,
    RemitTradeEntryTable1OptionDetailComponent
  ]

})
export class RemitTradeEntryModule {
}
