import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cms-new-snapshot',
  templateUrl: './new-snapshot.component.html',
  styleUrls: ['./new-snapshot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewSnapshotComponent {
  @Input() lastSnapshotMessage: string;
  @Input() newSnapshotLoading: boolean;
  @Output() newSnapshot = new EventEmitter();

  onNewSnapshot(): void {
    this.newSnapshot.emit();
  }
}
