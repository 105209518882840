import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Message } from 'primeng/api';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { Column } from '@common/shared/datatable.model';
import { OrganisationService } from '@common/organisation.service';
import { DatatableState } from '@common/datatable/datatable.model';
import { AddNote, HideConfirmation, NotesCellViewEvent, UnhideConfirmation } from '@common/deals/deals.model';
import { NavigationService } from '@common/menu/navigation.service';
import { MyFilter } from '@common/my-filters/my-filters.model';

import {
  DealFinderFilters,
  DealFinderIdFilters,
  DealFinderPropertiesFilters,
  EcmDealFinder,
  EcmMyFilter,
  FilterBy,
} from './deal-finder.model';
import { EcmDealFinderState } from '../state/deal-finder/deal-finder.reducers';
import * as fromSelectors from '../state/deal-finder/deal-finder.selectors';
import * as fromAction from '../state/deal-finder/deal-finder.actions';
import { filter } from 'rxjs/operators';
import { ErrorMessageService } from '@common/errors/error-message.service';
import { getDealEditRights, getDealNoteRights } from '../state/deal-finder/deal-finder.selectors';
import { UserService } from '@common/user.service';

@Component({
  selector: 'cms-deal-finder',
  templateUrl: './deal-finder.component.html',
  styleUrls: ['./deal-finder.component.scss']
})
export class DealFinderComponent implements OnInit, OnDestroy {
  data$: Observable<DatatableData<EcmDealFinder[]>> = this.store.pipe(select(fromSelectors.getData));
  loading$: Observable<boolean> = this.store.pipe(select(fromSelectors.getLoading));
  messages$: Observable<Message[]> = this.store.pipe(select(fromSelectors.getMessages));
  paging$: Observable<PagingFilter> = this.store.pipe(select(fromSelectors.getPaging));
  sorting$: Observable<SortingFilter> = this.store.pipe(select(fromSelectors.getSorting));
  filterBy$: Observable<FilterBy> = this.store.pipe(select(fromSelectors.filterBy));
  idFilters$: Observable<DealFinderIdFilters> = this.store.pipe(select(fromSelectors.getIdFilters));
  propFilters$: Observable<DealFinderPropertiesFilters> = this.store.pipe(select(fromSelectors.getPropFilters));
  columns$: Observable<Column[]> = this.store.pipe(select(fromSelectors.getColumns));
  confirmationIds: Observable<string[]> = this.store.pipe(select(fromSelectors.getConfirmationIds));
  editRights$: Observable<boolean> = this.store.pipe(select(fromSelectors.getDealEditRights));
  noteRights$: Observable<boolean> = this.store.pipe(select(fromSelectors.getDealNoteRights));
  filterBy: FilterBy;
  filterBySubscription$: Subscription;
  subscription$: Subscription = new Subscription();
  broker$ = this.store.pipe(select(fromSelectors.getBroker));
  transactionTypes$ = this.store.pipe(select(fromSelectors.getTransactionTypes));
  markets$ = this.store.pipe(select(fromSelectors.getMarkets));
  counterParties$ = this.store.pipe(select(fromSelectors.getCounterParties));
  commodities$ = this.store.pipe(select(fromSelectors.getCommodities));
  notFoundIDs$ = this.store.pipe(select(fromSelectors.getNoFoundIds));
  idsCount$ = this.store.pipe(select(fromSelectors.getIdsCount));
  selected$ = this.store.pipe(select(fromSelectors.getSelected));
  dialog$ = this.store.pipe(select(fromSelectors.getDialog));
  myFilters$ = this.store.pipe(select(fromSelectors.getMyFilters));
  defaultFilter$ = this.store.pipe(select(fromSelectors.getDefaultFilter));
  allDealsFilter$ = this.store.pipe(select(fromSelectors.getAllDealsFilter));
  tradeIdOfRow: string;
  idFiltersLabels = [
    { label: 'Our Trade ID', name: 'ourTradeId' },
    { label: 'Their Trade ID', name: 'theirTradeId' },
    { label: 'Document ID / USI / UTI', name: 'documentId' }
  ];
  displayEmailWarning: boolean = false;
  errorMessages: Message[];

  private selectedDeals: EcmDealFinder[] = [];

  constructor(private organisationService: OrganisationService,
              private router: Router,
              private navigationService: NavigationService,
              private store: Store<EcmDealFinderState>,
              public readonly errorMessageService: ErrorMessageService,
              private userService: UserService) {
  }

  onLazyLoad({ page, perPage, sortOrder, sortField }: DatatableState): void {
    this.store.dispatch(new fromAction.SortAndPageDataAction({
      paging: {
        page,
        entriesPerPage: perPage
      },
      sorting: {
        columnName: sortField,
        sortOrder
      }
    }));
  }

  onConfirmReplaceFilter(replace: EcmMyFilter): void {
    this.store.dispatch(new fromAction.ReplaceMyFiltersAction(replace));
  }

  onConfirmSaveFilter(saveFilter: EcmMyFilter): void {
    this.store.dispatch(new fromAction.SaveMyFiltersAction(saveFilter));
  }

  onExport(): void {
    this.checkEmailFormatAndShowWarning();
  }

  onOpenAddNoteDialog(): void {
    this.store.dispatch(new fromAction.OpenDialogAction({
      name: 'ADD_NOTES',
      messages: [],
    }));
  }

  onClickCustomize(): void {
    this.router.navigate(['/settings/datatable/customize'], { queryParams: { documentType: 'ECM' } });
  }

  onSelected(deals: EcmDealFinder[]) {
    this.selectedDeals = deals;
    let confirmationIds: string[] = [];
    if (deals) {
      confirmationIds = deals.map(d => d.id + '');
    }
    this.store.dispatch(new fromAction.SetSelectedConfirmIdsAction({ confirmationIds }));
    this.store.dispatch(new fromAction.SetSelectedDealsAction({ deals }));
  }

  get terminateDisable(): Boolean {
    return this.selectedDeals?.length <= 0;
  }

  onViewNotes(obj: NotesCellViewEvent): void {
    this.store.dispatch(new fromAction.OpenDialogAction({
      name: 'VIEW_NOTES',
      messages: [],
      data: obj
    }));
  }

  ngOnInit(): void {

    this.subscription$.add(this.organisationService.onRefresh()
      .pipe(filter(authenticated => authenticated && this.organisationService.getCurrentOrganisationId() !== null))
      .subscribe(() => {
        this.store.dispatch(new fromAction.SetToDefaultAction());
        this.store.dispatch(new fromAction.GetFiltersValuesAction());
        this.store.dispatch(new fromAction.LoadMyFiltersAction());
        this.store.dispatch(new fromAction.LoadPresets());
      }));

    this.filterBySubscription$ = this.filterBy$.subscribe((by: FilterBy) => {
      this.filterBy = by;
    });

    this.subscription$.add(this.data$.subscribe(data => {
      if (data.values && this.selectedDeals) {
        const ids = this.selectedDeals.map(s => s.id);
        const selected: EcmDealFinder[] = data.values.filter(d => ids.indexOf(d.id) !== -1);
        this.onSelected(selected);
      }
    }));
  }

  onSelectMyFilter(selectedFilter: MyFilter): void {
    this.store.dispatch(new fromAction.SelectMyFilterAction(selectedFilter as EcmMyFilter));
  }

  onDeleteFilter(filterName: string): void {
    this.store.dispatch(new fromAction.DeleteMyFilterAction({ filterName }));
    this.subscription$.unsubscribe();
    this.filterBySubscription$.unsubscribe();
  }

  onConfirmEcmLiteCancel({ confirmationId }): void {
    this.store.dispatch(new fromAction.ConfirmEcmLiteCancelAction({ confirmationId }));
  }

  onSetDefault(selectedFilter: MyFilter): void {
    this.store.dispatch(new fromAction.SetDefaultFilterAction(selectedFilter));
  }

  onManageMyFilters(): void {
    this.store.dispatch(new fromAction.OpenDialogAction({
      name: 'MANAGE_FILTERS'
    }));
  }

  onHide(data: { hideCheckboxVisible: boolean, hidden: boolean, hideCheckboxEnabled: boolean }): void {
    this.store.dispatch(new fromAction.OpenDialogAction({
      name: 'HIDE_CONFIRMATION',
      data
    }));
  }

  onUnhide(data: { hideCheckboxVisible: boolean, hidden: boolean, hideCheckboxEnabled: boolean }): void {
    this.store.dispatch(new fromAction.OpenDialogAction({
      name: 'UNHIDE_CONFIRMATION',
      data
    }));
  }

  onCloseDialog(): void {
    this.store.dispatch(new fromAction.CloseDialogAction());
  }

  onCancelTerminateDealDialog(): void {
    this.store.dispatch(new fromAction.CloseDialogAction());
  }

  onAbortEcmLiteCancelDialog(): void {
    this.store.dispatch(new fromAction.CloseDialogAction());
  }

  onCancelAddNotes(): void {
    this.store.dispatch(new fromAction.CloseDialogAction());
  }

  onCancelViewNotes(): void {
    this.store.dispatch(new fromAction.CloseDialogAction());
  }

  onCancelHideUnhideConfirmation(): void {
    this.store.dispatch(new fromAction.CloseDialogAction());
    this.store.dispatch(new fromAction.LoadDataAction());
  }

  onConfirmHideConfirmation(obj: HideConfirmation): void {
    this.store.dispatch(new fromAction.HideConfirmationAction(obj));
  }

  onConfirmUnHideConfirmation(obj: UnhideConfirmation): void {
    this.store.dispatch(new fromAction.UnhideConfirmationAction(obj));
  }

  onConfirmAddNotes(obj: AddNote): void {
    this.store.dispatch(new fromAction.AddNotesAction(obj));
  }

  onOpenSaveCurrent(): void {
    this.store.dispatch(new fromAction.OpenDialogAction({
      name: 'SAVE_CURRENT'
    }));
  }

  openTerminateDialog(): void {
    this.store.dispatch(new fromAction.OpenDialogAction(
      {
        name: 'TERMINATE_DEAL',
        data: {
          confirmationIds: this.selectedDeals.map(d => d.id)
        }
      }));
  }

  onConfirmTerminateDeal({ reason, data }: any): void {
    this.store.dispatch(new fromAction.TerminateConfirmAction({ reason: reason, confirmationIds: data.confirmationIds }
    ));
  }

  onEcmLiteCancel({ id, tradeId }: EcmDealFinder): void {
    this.store.dispatch(new fromAction.OpenDialogAction({
        name: 'ECM_LITE_CANCEL',
        data: {
          confirmationId: id
        }
      }
    ));
    this.tradeIdOfRow = tradeId;
  }

  onFilter(filters: DealFinderFilters) {
    this.store.dispatch(new fromAction.FilterAction(filters));
  }

  ngOnDestroy(): void {
    this.store.dispatch(new fromAction.SetTableDataToDefaultAction());

    this.store.dispatch(new fromAction.SetToDefaultAction());
    this.subscription$.unsubscribe();
    this.filterBySubscription$.unsubscribe();
    this.selectedDeals = [];
  }

  onFilterByProperties(filters: DealFinderPropertiesFilters): void {
    this.store.dispatch(new fromAction.FilterAction({
      properties: filters,
      filterBy: 'dealProperties'
    }));
  }

  onResetByIds(): void {
    this.store.dispatch(new fromAction.ClearByIDsAction());
  }

  onFilterByPropertiesReset(): void {
    this.store.dispatch(new fromAction.ClearByPropertiesFiltersAction());
  }

  onDownloadXml({ id }: EcmDealFinder): void {
    this.store.dispatch(new fromAction.DownloadXmlAction(id));
  }

  getDisplayParams({ documentId, id }: EcmDealFinder) {
    return {
      displayValue: documentId,
      urlPart: 'ecm',
      confirmationId: id
    };
  }

  checkEmailFormatAndShowWarning(): void {
    if (this.userService.getCurrentInformations().email.includes('@ponton')) {
      this.openEmailWarning();
    } else {
      this.triggerExportAndCloseWarning();
    }
  }

  openEmailWarning(): void {
    this.errorMessages = [{
      severity: 'error',
      summary: 'Please consider that performing an export involves considerable effort in creating incident reports. If you proceed a report mail will be sent to Hugh, Liam and Thomas.'
    }];
    this.displayEmailWarning = true;
  }

  closeEmailWarning(): void {
    this.errorMessages = [];
    this.displayEmailWarning = false;
  }

  triggerExportAndCloseWarning(): void {
    this.store.dispatch(new fromAction.ExportAction())
    this.closeEmailWarning();
  }
}
