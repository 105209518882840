import { Action } from '@ngrx/store';
import { Message } from 'primeng/api';
import {
  DealFinderFilters,
  EcmDealFinder,
  EcmPropertyFiltersValuesResponse,
  EcmDealFinderResponse, EcmMyFilter,
  TerminateDealRequest
} from '../../deal-finder/deal-finder.model';
import { DatatableConfig } from '@common/shared/results.model';
import { Column } from '@common/shared/datatable.model';
import { MyFilter } from '@common/my-filters/my-filters.model';
import { AddNote, DialogState, HideConfirmation, UnhideConfirmation } from '@common/deals/deals.model';

export const SET_TO_DEFAULT = '[ECM DEAL FINDER] Set to default';
export const SET_MESSAGES = '[ECM DEAL FINDER] Set Messages';
export const LOAD_DATA = '[ECM DEAL FINDER] Load Data';
export const FILTER = '[ECM DEAL FINDER] Filter Data';
export const FILTER_DATA_SUCCESS = '[ECM DEAL FINDER] Filter Data Success';
export const SET_COLUMNS = '[ECM DEAL FINDER] Set Columns';
export const SORT_AND_PAGE_DATA = '[ECM DEAL FINDER] Sort and Page Data';
export const CLEAR_FILTERS = '[ECM DEAL FINDER] Clear Filters';
export const GET_FILTERS_VALUES = '[ECM DEAL FINDER] Get Filters Values';
export const GET_FILTERS_VALUES_SUCCESS = '[ECM DEAL FINDER] Get Filters Values Success';
export const CLEAN_BY_PROPERTIES_FILTERS = '[ECM DEAL FINDER] Clean By Properties Filters';
export const CLEAN_BY_IDS = '[ECM DEAL FINDER] Clean IDs';
export const EXPORT = '[ECM DEAL FINDER] Export';
export const ADD_NOTES = '[ECM DEAL FINDER] Add Notes';
export const SELECT = '[ECM DEAL FINDER] Select';
export const LOAD_MY_FILTERS = '[ECM DEAL FINDER] Load My Filters';
export const RELOAD_MY_FILTER = '[ECM DEAL FINDER] Reload My Filters';
export const LOAD_MY_FILTERS_SUCCESS = '[ECM DEAL FINDER] Load My Filters Success';
export const SAVE_MY_FILTERS = '[ECM DEAL FINDER] Save My Filters';
export const REPLACE_MY_FILTERS = '[ECM DEAL FINDER] Replace My Filters';
export const DELETE_MY_FILTER = '[ECM DEAL FINDER] Delete My Filter';
export const DELETE_MY_FILTER_SUCCESS = '[ECM DEAL FINDER] Delete My Filter Success';
export const TERMINATE_CONFIRM = '[ECM DEAL FINDER] Terminate Deal Confirm';
export const TERMINATE_CONFIRM_SUCCESS = '[ECM DEAL FINDER] Terminate Deal Confirm Success';
export const CONFIRM_ECM_LITE_CANCEL = '[ECM DEAL FINDER] Ecm Lite Cancel Confirm';
export const CONFIRM_ECM_LITE_CANCEL_SUCCESS = '[ECM DEAL FINDER] Ecm Lite Cancel Confirm Success';
export const SELECT_MY_FILTER = '[ECM DEAL FINDER] Select My Filter';
export const OPEN_DIALOG = '[ECM DEAL FINDER] Open Dialog';
export const CLOSE_DIALOG = '[ECM DEAL FINDER] Close Dialog';
export const UPDATE_DIALOG = '[ECM DEAL FINDER] Update Dialog';
export const UPDATE_FILTERS = '[ECM DEAL FINDER] Update Filters';
export const SET_DEFAULT_FILTER = '[ECM DEAL FINDER] Set Default Filter';
export const DOWNLOAD_XML = '[ECM DEAL FINDER] Download XML';
export const DOWNLOAD_HTML = '[ECM DEAL FINDER] Download HTML';
export const SET_SELECTED_DEALS = '[ECM DEAL FINDER] Set Selected Deals';
export const SET_SELECTED_CONFIRMATION_IDS = '[ECM DEAL FINDER] Set Selected Confirmation Ids';
export const ADD_SELECTED_CONFIRM_ID = '[ECM DEAL FINDER] Add Selected Confirmation Id';
export const REMOVE_SELECTED_CONFIRM_ID = '[ECM DEAL FINDER] Add Remove Confirmation Id';
export const SET_TABLE_DATA_TO_DEFAULT = '[ECM DEAL FINDER] Set table data to default';
export const HIDE_CONFIRMATION = '[ECM DEAL FINDER] Hide Confirmation';
export const UNHIDE_CONFIRMATION = '[ECM DEAL FINDER] Unhide Confirmation';
export const SET_HTML = '[ECM DEAL FINDER] Set Html';
export const LOAD_PRESETS = '[ECM DEAL FINDER] Load Presets';
export const LOAD_PRESETS_SUCCESS = '[ECM DEAL FINDER] Load Presets Success';

export class DownloadXmlAction implements Action {
  readonly type = DOWNLOAD_XML;
  constructor(public payload: number) { }
}

export class DownloadHtmlAction implements Action {
  readonly type = DOWNLOAD_HTML;
  constructor(public payload: number) { }
}

export class SetHtmlAction implements Action {
  readonly type = SET_HTML;
  constructor(public payload: Blob) { }
}

export class LoadPresets implements Action {
  readonly type = LOAD_PRESETS;
}

export class LoadPresetsSuccess implements Action {
  readonly type = LOAD_PRESETS_SUCCESS;

  constructor(public rights: { termination: boolean, notes: boolean }) {
  }
}

export class FilterAction implements Action {
  readonly type = FILTER;
  constructor(public payload: DealFinderFilters) { }
}

export class UpdateFiltersAction implements Action {
  readonly type = UPDATE_FILTERS;
  constructor(public payload: DealFinderFilters) { }
}

export class SortAndPageDataAction implements Action {
  readonly type = SORT_AND_PAGE_DATA;
  constructor(public payload: DatatableConfig) { }
}

export class FilterDataSuccessAction implements Action {
  readonly type = FILTER_DATA_SUCCESS;
  constructor(public payload: EcmDealFinderResponse) { }
}

export class SetColumnsAction implements Action {
  readonly type = SET_COLUMNS;
  constructor(public payload: Column[]) { }
}

export class LoadDataAction implements Action {
  readonly type = LOAD_DATA;
}

export class SetToDefaultAction implements Action {
  readonly type = SET_TO_DEFAULT;
}

export class SetMessagesAction implements Action {
  readonly type = SET_MESSAGES;
  constructor(public payload: Message[]) { }
}

export class GetFiltersValuesAction implements Action {
  readonly type = GET_FILTERS_VALUES;
}

export class GetFiltersValuesSuccessAction {
  readonly type = GET_FILTERS_VALUES_SUCCESS;
  constructor(public payload: EcmPropertyFiltersValuesResponse) { }
}

export class ClearFilters implements Action {
  readonly type = CLEAR_FILTERS;
}

export class ClearByPropertiesFiltersAction implements Action {
  readonly type = CLEAN_BY_PROPERTIES_FILTERS;
}

export class ClearByIDsAction implements Action {
  readonly type = CLEAN_BY_IDS;
}

export class ExportAction implements Action {
  readonly type = EXPORT;
}

export class AddNotesAction implements Action {
  readonly type = ADD_NOTES;
  constructor(public payload: AddNote) { }
}

export class SelectAction implements Action {
  readonly type = SELECT;
  constructor(public payload: EcmDealFinder) { }
}

export class LoadMyFiltersAction implements Action {
  readonly type = LOAD_MY_FILTERS;
}

export class ReloadMyFiltersAction implements Action {
  readonly type = RELOAD_MY_FILTER;
}

export class LoadMyFiltersSuccessAction implements Action {
  readonly type = LOAD_MY_FILTERS_SUCCESS;
  constructor(public payload: {
    defaultFilter?: EcmMyFilter;
    filterSelections?: {[key: string]: EcmMyFilter };
    allDealsFilter?: EcmMyFilter
  }) { }
}

export class ReplaceMyFiltersAction implements Action {
  readonly type = REPLACE_MY_FILTERS;
  constructor(public payload: EcmMyFilter) { }
}

export class SaveMyFiltersAction implements Action {
  readonly type = SAVE_MY_FILTERS;
  constructor(public payload: EcmMyFilter) { }
}

export class DeleteMyFilterAction implements Action {
  readonly type = DELETE_MY_FILTER;
  constructor(public payload: {filterName : string}) { }
}

export class DeleteMyFilterSuccessAction implements Action {
  readonly type = DELETE_MY_FILTER_SUCCESS;
  constructor(public payload: {filterName : string}) { }
}

export class TerminateConfirmAction implements Action {
  readonly type = TERMINATE_CONFIRM;
  constructor(public payload: TerminateDealRequest) { }
}

export class TerminateConfirmSuccessAction implements Action {
  readonly type = TERMINATE_CONFIRM_SUCCESS;
  constructor(public payload: TerminateDealRequest) { }
}

export class ConfirmEcmLiteCancelAction implements Action {
  readonly type = CONFIRM_ECM_LITE_CANCEL;
  constructor(public payload: {confirmationId: any}) { }
}

export class ConfirmEcmLiteCancelSuccessAction implements Action {
  readonly type = CONFIRM_ECM_LITE_CANCEL_SUCCESS;
}

export class SelectMyFilterAction implements Action {
  readonly type = SELECT_MY_FILTER;
  constructor(public payload: EcmMyFilter) { }
}

export class SetDefaultFilterAction implements Action {
  readonly type = SET_DEFAULT_FILTER;
  constructor(public payload: MyFilter) { }
}

export class OpenDialogAction implements Action {
  readonly type = OPEN_DIALOG;
  constructor(public payload: DialogState) { }
}

export class CloseDialogAction implements Action {
  readonly type = CLOSE_DIALOG;
}

export class UpdateDialogAction implements Action {
  readonly type = UPDATE_DIALOG;
  constructor(public payload: Message[]) { }
}

export class AddSelectedConfirmIdAction implements Action {
  readonly type = ADD_SELECTED_CONFIRM_ID;
  constructor(public payload: {confirmationId: string}) { }
}

export class SetSelectedConfirmIdsAction implements Action {
  readonly type = SET_SELECTED_CONFIRMATION_IDS;
  constructor(public payload: {confirmationIds: string[]}) { }
}

export class SetSelectedDealsAction implements Action {
  readonly type = SET_SELECTED_DEALS;
  constructor(public payload: {deals: EcmDealFinder[]}) { }
}

export class RemoveSelectedConfirmIdAction implements Action {
  readonly type = REMOVE_SELECTED_CONFIRM_ID;
  constructor(public payload: {confirmationId: string}) { }
}

export class SetTableDataToDefaultAction implements Action {
  readonly type = SET_TABLE_DATA_TO_DEFAULT;
}

export class HideConfirmationAction implements Action {
  readonly type = HIDE_CONFIRMATION;
  constructor(public payload: HideConfirmation) { }
}

export class UnhideConfirmationAction implements Action {
  readonly type = UNHIDE_CONFIRMATION;
  constructor(public payload: UnhideConfirmation) { }
}

export type EcmDealFinderActions = SetToDefaultAction | SetMessagesAction |
  FilterAction | SortAndPageDataAction | FilterDataSuccessAction | LoadDataAction |
  GetFiltersValuesAction | GetFiltersValuesSuccessAction | ClearByPropertiesFiltersAction | ClearByIDsAction |
  SetColumnsAction | AddNotesAction | SelectAction | LoadMyFiltersAction | SaveMyFiltersAction | LoadMyFiltersSuccessAction |
  DeleteMyFilterAction | ReplaceMyFiltersAction | OpenDialogAction | CloseDialogAction | UpdateDialogAction | UpdateFiltersAction |
  SetDefaultFilterAction | DeleteMyFilterSuccessAction | DownloadXmlAction | DownloadHtmlAction  |
  TerminateConfirmAction | TerminateConfirmSuccessAction | ConfirmEcmLiteCancelAction | ConfirmEcmLiteCancelSuccessAction |
  ReloadMyFiltersAction | SetSelectedConfirmIdsAction | AddSelectedConfirmIdAction | SetSelectedDealsAction | RemoveSelectedConfirmIdAction | SetTableDataToDefaultAction |
  HideConfirmationAction | UnhideConfirmationAction | SetHtmlAction | LoadPresets| LoadPresetsSuccess;
