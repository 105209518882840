import { SmtDashboardDataViewComponent } from './dashboard/smt-dashboard-dataview-component';
import { RouterModule } from '@angular/router';
import { SmtDashboardService } from './dashboard/smt-dashboard-service';
import { SmtDashboardComponent } from './dashboard/smt-dashboard.component';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TreeTableModule } from 'primeng/treetable';
import { PipeModule } from '../../pipe/pipe.module';
import { SmtDealFinderDateRangeFilterComponent } from './dealfinder/filter/smt-dealfinder-daterange-filter.components';
import { SmtDealFinderUtiFilterComponent } from './dealfinder/filter/smt-dealfinder-uti-filter.component';
import { SmtDataTableComponent } from './smt-datatable.component';
import { SmtDealFinderFilterComponent } from './dealfinder/smt-dealfinder-filter.component';
import { SmtDealFinderTransactionsService } from './dealfinder/smt-dealfinder-transactions.service';
import { SmtDealFinderComponent } from './dealfinder/smt-dealfinder.component';

import { CmsCommonModule } from '../../common/cms-common.module';
import { SmtTransactionSheetComponent } from './smt-transaction-sheet.component';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { PanelModule } from 'primeng/panel';
import { MessagesModule } from 'primeng/messages';

@NgModule({
    imports: [
        BrowserModule,
        RouterModule,
        BrowserAnimationsModule,
        PanelModule,
        FormsModule,
        CalendarModule,
        ButtonModule,
        ReactiveFormsModule,
        DropdownModule,
        TreeTableModule,
        TableModule,
        PipeModule,
        CmsCommonModule,
        MessagesModule
    ],
  exports: [
    SmtDealFinderFilterComponent,
  ],
  declarations: [
    SmtDealFinderComponent,
    SmtDealFinderFilterComponent,
    SmtDataTableComponent,
    SmtDealFinderDateRangeFilterComponent,
    SmtDealFinderUtiFilterComponent,
    SmtDashboardComponent,
    SmtDashboardDataViewComponent,
    SmtTransactionSheetComponent,
  ],
  providers: [
    SmtDealFinderTransactionsService,
    SmtDashboardService
  ]
})

export class SmtModule {
}
