<div style="max-width: 585px;" class="reporting-counterparty">
  <div class="form-title">Trade | Reporting Counterparty</div>
  <div class="form-body" [formGroup]="formGroup">
    <div class="row gutter-0">
      <div class="col-5 label">Market Participant <span class="text-required">*</span></div>
      <div id="remit-trade-entry-marketParticipants" class="col-7 ps-2 !pe-[42px]">
        <p-dropdown [options]="marketParticipants"
                    [filter]="true"
                    [filterFields]="['label']"
                    formControlName="marketParticipantCode"
                    [style]="{'width': '100%'}"
                    [styleClass]="'margin-3 ' + getValidationErrorStyleClass('marketParticipantCode')"
                    >
        </p-dropdown>
      </div>
      <span *ngIf="formGroup.controls['marketParticipantCode'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0">
      <div class="col-5 label">Trader ID <span class="text-required" *ngIf="!execution">*</span></div>
      <div class="col-7 ps-2 !pe-[42px]">
        <input id="remit-trade-entry-traderId" type="text" class="form-control p-component padding-6 text-medium margin-3" formControlName="traderId" maxlength="100" [ngClass]="{'error':formGroup.controls['traderId'].hasError('error')}">
      </div>
      <span *ngIf="formGroup.controls['traderId'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0">
      <div class="col-5 label">Buy / Sell <span class="text-required">*</span></div>
      <div id="remit-trade-entry-buySell" class="col-7 ps-2 !pe-[42px]">
        <p-dropdown [options]="buySell" formControlName="buyOrSell" [style]="{'width': '100%'}" [styleClass]="'margin-3 ' + getValidationErrorStyleClass('buyOrSell')" ></p-dropdown>
      </div>
      <span *ngIf="formGroup.controls['buyOrSell'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0">
      <div class="col-5 label">Beneficiary</div>
      <div id="remit-trade-entry-beneficiaryType" class="col-2 ps-2">
        <p-dropdown [options]="beneficiaryType" formControlName="beneficiaryCodeType" [styleClass]="'margin-3 ' + getValidationErrorStyleClass('beneficiaryCodeType')"></p-dropdown>
      </div>
      <div id="remit-trade-entry-beneficiarCode" class="col-5 ps-2 !pe-[42px]">
        <input type="text" class="form-control padding-6 text-medium margin-3" formControlName="beneficiaryCode" [maxLength]="20" [ngClass]="{'error':formGroup.controls['beneficiaryCode'].hasError('error')}">
      </div>
      <span *ngIf="formGroup.controls['beneficiaryCode'].hasError('error')" class="fa fa-info-circle absolute error-marker"></span>
    </div>
  </div>
</div>
