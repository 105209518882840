import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, Event, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { EsmDashboardTabs, EsmDashboardTableType, CategoriesMap } from './esm-dashboard.model';
import { EsmDashboardState } from '../../state/dashboard/dashboard.reducers';
import * as fromDBActions from '../../state/dashboard/dashboard.actions';
import * as fromDBFilterActions from '../../state/dashboard/filters/filters.actions';
import * as fromEsmDBFiltersActions from '../../state/dashboard/filters/filters.actions';
import {OrganisationService} from '@common/organisation.service';
import { ErrorMessageService } from '@common/errors/error-message.service';
import { Message } from 'primeng/api';
import { getMessage } from '../../state/dashboard/dashboard.selectors';

@Component({
  selector: 'cms-esm-dasboard',
  templateUrl: './esm-dashboard.component.html',
  styleUrls: ['./esm-dashboard.component.scss']
})
export class EsmDashboardComponent implements OnInit, OnDestroy {
  activeIndex: number;
  routeSubscription$: Subscription;
  readonly tabs = EsmDashboardTabs;
  messages: Observable<Message[]> = this.store.pipe(select(getMessage));

  constructor(private route: ActivatedRoute,
              private router: Router,
              private title: Title,
              private store: Store<EsmDashboardState>,
              private organisationService: OrganisationService,
              public errorMessageService:ErrorMessageService) { }

  ngOnInit(): void {
    this.routeSubscription$ = this.router.events.pipe(
      filter((event: Event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.setStateDataByUrl();
        this.store.dispatch(fromDBFilterActions.filterAction());
      });
    this.setStateDataByUrl();
    this.store.dispatch(fromDBFilterActions.loadPresetValuesAction());

    this.routeSubscription$.add(this.organisationService.getOrganisationChangeIfAccessible()
      .subscribe(() => {
        this.store.dispatch(fromDBFilterActions.resetFiltersAction());
      }));
  }

  ngOnDestroy(): void {
    this.store.dispatch(fromDBActions.setToDefaultAction());
    this.routeSubscription$.unsubscribe();
  }

  onChangeTab({ index }: { index: number }): void {
    const tab = this.tabs[index];
    this.router.navigate([`esm/dashboard/${tab.route}`]);
  }

  private getActiveIndexByUrl(): number {
    const childData = this.route.snapshot.firstChild.data;
    const index = this.tabs.findIndex(item => item.route === childData.panel);
    return index > -1 ? index : 0;
  }

  private initDealsData(): void {
    const stateParam = this.route.snapshot.queryParamMap.get('state');
    const dateRangeParam = this.route.snapshot.queryParamMap.get('dateRange');
    this.store.dispatch(fromDBActions.setStateAction(stateParam));
    this.store.dispatch(fromEsmDBFiltersActions.setDateRangeAction(dateRangeParam));
    this.store.dispatch(fromDBActions.getStateNamesAction());
  }

  private setStateDataByUrl(): void {
    const index = this.getActiveIndexByUrl();
    const isDealsView = this.router.url.includes('/deals');
    const viewType = isDealsView ? EsmDashboardTableType.TABLE_DATA : EsmDashboardTableType.BUCKETS;
    this.store.dispatch(fromDBActions.setViewTypeAction(viewType));

    if (index !== this.activeIndex) {
      this.activeIndex = index;
      this.title.setTitle(this.tabs[index].title);
      this.store.dispatch(fromDBActions.setStateNamesAction([]));
      this.store.dispatch(fromEsmDBFiltersActions.setCategoryAction(CategoriesMap[this.tabs[index].route]));
    }

    if (isDealsView) {
      this.initDealsData();
    }
  }
}
