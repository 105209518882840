import {createSelector} from '@ngrx/store';
import {getInternalState} from '../internal-master-data.selectors';

import {EsmInternalMasterDataState} from '../internal-master-data.reducer';
import {EsmPdfSettingsState} from './pdf-settings.reducer';


export const getEsmPdfSettingsValues = createSelector(getInternalState, (state: EsmInternalMasterDataState) => state.pdfSettings);
export const getEsmPdfSettingsPresetValues = createSelector(getEsmPdfSettingsValues, (state:EsmPdfSettingsState) => state.accessRights);
export const isEsmPdfSettingsLogoAvailable = createSelector(getEsmPdfSettingsValues, (state:EsmPdfSettingsState) => state.logoAvailable);
export const getPdfMessages = createSelector(getEsmPdfSettingsValues, (state: EsmPdfSettingsState) => state.messages);
