<div class="panel-box margin-bottom-12">
  <label>Upload counterparty trades: (.csv file)
    <a href="#" class="margin-left-12" (click)="downloadFormatInfo();" target="_blank">Format Info</a> | <a href="#" (click)="downloadTemplate();" target="_blank">Template</a>
  </label>
  <form [formGroup]="uploadForm" class="row align-items-center col-12 col-lg-8" (ngSubmit)="submit()">
    <div class="col-12 col-md-6 padding-0">
      <input id="input-filename-remit-emir-reconciliation" type="text" class="form-control p-component padding-6 text-medium" [value]="fileName" readonly>
    </div>
    <div class="col-12 col-md-6">
      <button class="btn form-control margin-3 padding-6" style="width: 75px;" (click)="browseFiles();">Browse...</button>
      <button id="btn-submit-emir-recon" type="submit" style="width: 75px;" class="btn form-control margin-3 padding-6" [disabled]="!fileSelected">Upload</button>
    </div>

    <input formControlName="cvsFile" id="input-file-filename-emir-reconciliation" type="file" class="hidden" (change)="onSelect($event)" accept=".csv" #fileUpload>
  </form>
</div>
