import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as fromMDSelectors from '../../state/master-data/external-master-data/external-master-data.selectors';
import * as fromActions from '../../state/master-data/external-master-data/external-master-data.actions';
import { Message } from 'primeng/api';
import { OrganisationService } from '@common/organisation.service';
import { filter, skip } from 'rxjs/operators';

@Component({
  selector: 'cms-external-master-data',
  templateUrl: './external-master-data.component.html',
  styleUrls: ['./external-master-data.component.scss']
})
export class ExternalMasterDataComponent implements OnInit, OnDestroy {
  messages$: Observable<Message[]> = this.store.pipe(select(fromMDSelectors.getMessages));
  detailsVisible$: Observable<boolean> = this.store.select(fromMDSelectors.getIsDetailsVisible);

  subscription$: Subscription = new Subscription();

  constructor(private store: Store,
    private organisationService: OrganisationService) {

  }

  ngOnInit() {
    this.subscription$.add(this.organisationService.onRefresh()
    .subscribe((authenticated) => {
      if(authenticated){
        this.store.dispatch(fromActions.setToDefaultAction());
        this.store.dispatch(fromActions.cleanState());
        this.store.dispatch(fromActions.loadPresetValues());
        this.store.dispatch(fromActions.loadTableData());
      }}));

    this.subscription$.add(this.detailsVisible$.pipe(filter(v => !v),skip(1)).subscribe(() => {
      this.store.dispatch(fromActions.loadPresetValues());
      this.store.dispatch(fromActions.loadTableData());
    }));
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
    this.store.dispatch(fromActions.cleanState());
  }

}
