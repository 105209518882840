import { Component, OnInit, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { EsmInvoiceFinderState } from '../../../state/invoice-finder/invoice-finder.reducers';
import * as fromSelectors from '../../../state/invoice-finder/table/table.selectors';
import * as fromAction from '../../../state/invoice-finder/table/table.actions';

@Component({
  selector: 'cms-invoice-finder-html',
  templateUrl: './invoice-finder-html.component.html',
  styleUrls: ['./invoice-finder-html.component.scss']
})
export class InvoiceFinderHtmlComponent implements OnInit, OnDestroy {

  loading: boolean = true;
  reader: FileReader;
  subscription$: Subscription;

  constructor(private store: Store<EsmInvoiceFinderState>,
              private titleService: Title,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.reader = new FileReader();
    this.reader.onloadend = this.setIframeContent.bind(this);
    this.subscription$ = this.store.pipe(select(fromSelectors.getInvoiceFinderHtml)).subscribe((blob: Blob) => {
      if (blob) {
        this.reader.readAsText(blob);
        this.loading = false;
      }
    });

    const routeSubsription = this.activatedRoute.queryParams.subscribe((params: Params) => this.onDownloadHtml(params.id));
    this.subscription$.add(routeSubsription);
  }

  public ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

  private setIframeContent(): void {
    const iframeEl = document.getElementById('iframeContent') as HTMLIFrameElement;
    const iFrameDoc = (iframeEl.contentWindow || iframeEl.contentDocument) && iframeEl.contentWindow.document;
    if (!iFrameDoc) {
      return;
    }
    iFrameDoc.write(<string>this.reader.result);
    iFrameDoc.close();
    this.titleService.setTitle(iFrameDoc.title);
    this.loading = false;
  }

  private onDownloadHtml(id: number): void {
    this.store.dispatch(fromAction.downloadHtmlAction(id));
  }
}
