<form [formGroup]="form" class="form-row align-items-center margin-bottom-12">
  <div class="col-2 margin-top-3 flex justify-content-start align-items-center field-cont">
    <label class="inline-block text-field-label !font-bold margin-0 padding-right-12">Curr.
      <span class="asterisk"></span></label>
    <input class="inline-block form-control ng-trim-ignore"
           type="text"
           formControlName="currency"
           placeholder="Currency"
           [ngClass]="{'error': (errors['currency'] && data)}"
           id="currency"
           maxlength="3"
           cmsUpperCase>
  </div>
  <div class="col-2 margin-top-3 flex justify-content-start align-items-center field-cont">
    <label class="inline-block text-field-label !font-bold margin-0 padding-right-12">IBAN
      <span class="asterisk"></span>
    </label>
    <input class="inline-block form-control ng-trim-ignore"
           type="text"
           formControlName="iban"
           placeholder="IBAN"
           maxlength="34"
           [ngClass]="{'error': (errors['iban'] && data)}"
           id="iban">
  </div>
  <div class="col-2 margin-top-3 flex justify-content-start align-items-center field-cont">
    <label class="inline-block text-field-label !font-bold margin-0 padding-right-12">BIC
      <span class="asterisk"></span>
    </label>
    <input class="inline-block form-control ng-trim-ignore"
           maxlength="11"
           type="text"
           formControlName="bic"
           placeholder="BIC"
           [ngClass]="{'error': (errors['bic'] && data)}"
           id="bic"
           cmsUpperCase>
  </div>
  <div class="col-3 margin-top-3 flex justify-content-start align-items-center field-cont">
    <label class="inline-block text-field-label !font-bold margin-0 padding-right-12">Acct. Holder:</label>
    <input class="inline-block form-control ng-trim-ignore"
           type="text"
           formControlName="bankAcctHolder"
           placeholder="Display Name"
           [ngClass]="{'error': (errors['bankAcctHolder'] && data)}"
           id="bankAcctHolder"
           maxlength="255">
  </div>
  <div class="col flex justify-content-start align-items-center field-cont button-cont">
    <button type="submit" class="btn margin-top-3 margin-bottom-3" [disabled]="loading || !(enabled| async)" (click)="onAdd()">Add</button>
    <button class="btn margin-top-3 margin-bottom-3 margin-left-12" [disabled]="loading" (click)="onClear()">Clear</button>
  </div>
</form>
