import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiRequestService } from '../../../common/api-request.service';
import { HttpParams } from '@angular/common/http';
import { PreferredValuesRowModel } from './trade_entry.model';
import { map } from 'rxjs/operators';

@Injectable()
export class TradeEntryService {

  apiPath: string = '/api/err/remit/lite/settings';

  constructor(private apiRequestService: ApiRequestService) {
  }

  loadPreferredValues(): Observable<PreferredValuesRowModel[]> {
    return this.apiRequestService.get(this.apiPath + '/loadPreferredValues', null).pipe(map(result => {
      if (result.fields) {
        return result.fields;
      }

      return of([]);
    }));
  }

  public loadTradeEntryConfiguration(userId: number, configurationName: string): Observable<any> {

    let httpParams = new HttpParams();

    if (userId > 0) {
      httpParams = httpParams.append('userId', userId.toString());
    }

    httpParams = httpParams.append('configurationName', configurationName);

    return this.apiRequestService.get(this.apiPath + '/loadValues', httpParams);
  }

  public loadDefaultTradeEntryConfiguration(configurationName: string): Observable<any> {

    const httpParams = new HttpParams().append('configurationName', configurationName);

    return this.apiRequestService.get(this.apiPath + '/loadDefaultValues', httpParams);
  }

  public saveChanges(configurationName: string, requestModel: any): Observable<any> {

    const httpParams = new HttpParams().append('configurationName', configurationName);

    return this.apiRequestService.post(this.apiPath + '/persist', requestModel, httpParams);
  }

  public loadUsers(configurationName: string): Observable<any> {

    let httpParams = new HttpParams();

    httpParams = httpParams.append('configurationName', configurationName);

    return this.apiRequestService.get(this.apiPath + '/loadUsers', httpParams).pipe(map(response => {
      return response.users ? response.users : of([]);
    }));
  }
}
