import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromFinderActions from '../../../netting/finder/esm-netting-finder.actions';
import * as fromTableActions from '../../../netting/finder/table/table.actions';
import { catchError, concatMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Action, select, Store } from '@ngrx/store';
import * as fromTableSelectors from '../../../netting/finder/table/table.selectors';
import * as fromFiltersSelectors from '../../../netting/finder/esm-netting-finder.selectors';
import { Injectable } from '@angular/core';
import { ApiRequestService } from '../../../../../common/api-request.service';
import { Router } from '@angular/router';
import { EsmNettingStatementFinderFilterState, EsmNettingStatementFinderState } from '../esm-netting-finder.reducers';
import { MessagesMapperService } from '@common/messages-mapper.service';

import { Message } from 'primeng/api';
import { AddNote, getDealsColumns } from '@common/deals/deals.model';
import * as moment from 'moment';
import {
  EsmNettingStatementTableColumns,
  NettingStatementFinderPropertyFilter,
  NettingStatementFinderReferencesFilter,
  NettingStatementFinderSearchModel,
  NettingStatementTableData,
} from '../../../../netting/finder/esm-netting-finder.model';
import { EsmNettingStatementTableState } from './table.reducers';
import { getBlobContent, saveBlob, toPayload } from '@common/cms-common.model';
import { Observable, of } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { ErrorResponse } from '@common/error-response.model';
import * as fromDialogActions from '../dialog/dialog.actions';

@Injectable()
export class EsmNettingStatementTableEffects {
  private baseUrl = '/api/esm/nettingstatement/finder';
  filterToUrlMap: { [key: string]: string } = {
    'dealProperties': 'filter',
    'ourInvoiceNumber': 'invoicenumber/our',
    'theirInvoiceNumber': 'invoicenumber/their',
    'documentId': 'documentId',
    'purchaseOrderNumber': 'purchaseOrderNumber',
    'tradeId': 'tradeIdUti'
  };

  constructor(private apiGateway: ApiRequestService,
              private actions$: Actions,
              private router: Router,
              private store$: Store<EsmNettingStatementFinderState>,
              private mapper: MessagesMapperService) {
  }


  downloadPdf$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTableActions.downloadPdfAction),
      map(toPayload),
      concatMap(id => this.apiGateway.getBlob(`${this.baseUrl}/download/pdf`, null, this.params(id))
          .pipe(switchMap(this.handleBlob), catchError(response => this.handleBlobErrorContent(response))))));


  downloadHtml$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTableActions.downloadHtmlAction),
      map(toPayload),
      concatMap((id: number) => {
        const params = new HttpParams().set('id', id.toString());
        return this.apiGateway.getBlob(`${this.baseUrl}/download/html`, null, params)
          .pipe(
            switchMap((response: any) => {
              const blob: Blob = response.body;
              return [
                fromTableActions.setMessagesAction([]),
                fromTableActions.setHtmlAction(blob)
              ];
            }),
            catchError(() => {
              this.router.navigate(['/help/welcome'], { queryParamsHandling: 'preserve' });
              return of(null);
            })
          );
      })
    ));

  downloadDoc$ = createEffect(() =>
      this.actions$.pipe(
        ofType(fromTableActions.downloadXmlAction),
        map(toPayload),
        concatMap((id: number) =>

           this.apiGateway.getBlob(`${this.baseUrl}/download/xml`, null, new HttpParams().set('id', id.toString()))
           .pipe(
             switchMap(this.handleBlob),
             catchError(error => of(fromTableActions.setMessagesAction([this.mapper.createErrorMessage(error.errorMessage)]))))
        )
      )
  );

  searchByFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromFinderActions.searchByFilterAction
      ),
      withLatestFrom(
        this.store$.pipe(select(fromTableSelectors.getTable)),
        this.store$.pipe(select(fromFiltersSelectors.getEsmNettingFinderFilterState)),
      ),
      switchMap(([, { paging, sorting }, filterState]: [Action, EsmNettingStatementTableState, EsmNettingStatementFinderFilterState]) =>
        this.loadData({
          filter: this.generatePropertyFilter(filterState),
          paging: paging,
          sorting: sorting
        })
      )
    )
  );

  searchByReferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromFinderActions.searchByReferencesAction
      ),
      withLatestFrom(
        this.store$.pipe(select(fromTableSelectors.getTable)),
        this.store$.pipe(select(fromFiltersSelectors.getReferences)),
      ),
      switchMap(([, { paging, sorting }, references]: [Action, EsmNettingStatementTableState, NettingStatementFinderReferencesFilter]) =>
        this.loadData({
          references: references,
          paging: paging,
          sorting: sorting
        })
      )
    )
  );

  reloadTableData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromTableActions.reloadTableDataAction,
        fromTableActions.sortAndPageDataAction
      ),
      withLatestFrom(
        this.store$.pipe(select(fromTableSelectors.getTable)),
        this.store$.pipe(select(fromFiltersSelectors.getEsmNettingFinderFilterState)),
        this.store$.pipe(select(fromFiltersSelectors.getReferences)),
      ),
      switchMap(([, { paging, sorting, searchBy }, filterr, references]:
                   [Action, EsmNettingStatementTableState, EsmNettingStatementFinderFilterState, NettingStatementFinderReferencesFilter]) => {

          switch(searchBy){
            case 'filter':
              const searchFilter=this.mapDeliveryPoint(filterr);
              return this.loadData( {filter: searchFilter, paging: paging, sorting: sorting});
            case 'reference':
              return this.loadData( {references: references, paging: paging, sorting: sorting });
            default: break;
         }
    }
    ))
  );

  addNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTableActions.addNoteAction),
      map(toPayload),
      concatMap(({confirmationIds: ids, text}: AddNote) => {
        return this.apiGateway.post(`${this.baseUrl}/createNote`, { ids, text })
          .pipe(
            switchMap((response: ErrorResponse & {}) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return of(fromDialogActions.updateDialogAction(messages));
              }
              return [
                fromDialogActions.closeDialogAction(),
                fromDialogActions.updateDialogAction([]),
                fromTableActions.addNoteSuccessAction()
              ];
            })
          );
      })
    ));

  addNotesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTableActions.addNoteSuccessAction),
      filter(() => (this.router.routerState.snapshot.url.includes('esm/nettingfinder'))),
      withLatestFrom(this.store$.pipe(select(fromFiltersSelectors.getFilter))),
      map(([,data]: [Action, NettingStatementFinderPropertyFilter]) => fromTableActions.reloadTableDataAction()))
  );


  export$ = createEffect(() =>
      this.actions$.pipe(
        ofType(fromTableActions.exportAction),
        withLatestFrom(
          this.store$.pipe(select(fromTableSelectors.getTable)),
          this.store$.pipe(select(fromFiltersSelectors.getEsmNettingFinderFilterState)),
          this.store$.pipe(select(fromFiltersSelectors.getReferences)),
        ),
        concatMap(([, { paging, sorting, searchBy }, filterr, references]:
                     [Action, EsmNettingStatementTableState, EsmNettingStatementFinderFilterState, NettingStatementFinderReferencesFilter]) => {

          switch(searchBy){
              case 'filter':
                const searchFilter = this.mapDeliveryPoint(filterr);
                return this.exportData({ filter: searchFilter, paging, sorting });
              case 'reference':
                return this.exportData({ references, paging, sorting });
              default: break;
            }
          }
        ),
        catchError(error => of(fromTableActions.setMessagesAction([this.mapper.createErrorMessage(error.errorMessage)])))
      ),
  );

  private loadData(searchModel: NettingStatementFinderSearchModel) {
    const searchRequest = this.getSearchRequest(searchModel);
    return this.apiGateway.post(this.getSearchRequestUrl(searchModel),searchRequest )
      .pipe(
        switchMap((response: NettingStatementTableData) => {

          const messages: Message[] = this.mapper.toErrorMessages(response);
          if (messages && messages.length) {
             return [fromTableActions.setMessagesAction(messages)];
          }

          const columns = getDealsColumns(response.columnOrder, EsmNettingStatementTableColumns);
          const idsCount: number = searchRequest.references && searchRequest.references.inputIds ? searchRequest.references.inputIds.split(' ').length : 0;
          return [
            fromFinderActions.setReferencesFilterIdsAction({notFoundIds: response.notFoundIds,  idCount: idsCount}),
            fromFinderActions.onFilterResponseAction(response),
             fromTableActions.setMessagesAction([]),
            fromTableActions.setColumnsAction(columns),
          ];
        }),
        // catchError(error => of(fromTableActions.setMessagesAction([this.mapper.createErrorMessage(error.errorMessage)])))
      );
  }

  private exportData(searchModel: NettingStatementFinderSearchModel) {

   return this.apiGateway.getBlob(this.getExportRequestUrl(searchModel), this.getSearchRequest(searchModel))
     .pipe(switchMap(this.handleBlob));

  }

  private getSearchRequestUrl(searchModel: NettingStatementFinderSearchModel): string {
    if (searchModel.filter) {
      return `${this.baseUrl}/search/filter`;
    }
    return `${this.baseUrl}/search/${searchModel.references.filterBy}`;
  }

  private getExportRequestUrl(searchModel: NettingStatementFinderSearchModel): string {
    if (searchModel.filter) {
      return `${this.baseUrl}/export/filter`;
    }
    return `${this.baseUrl}/export/${searchModel.references.filterBy}`;
  }

  private getSearchRequest(searchModel: NettingStatementFinderSearchModel) {
    if (searchModel.filter) {
      return this.getFilterSearchRequest(searchModel);
    }
    return this.getReferencesSearchRequest(searchModel);
  }

  private getReferencesSearchRequest(searchModel: NettingStatementFinderSearchModel) {

    let inputIds: string;

    switch (searchModel.references.filterBy) {
      case 'ourNettingStatementId':
        inputIds = searchModel.references.ourNettingStatementId;
        break;
      case 'theirNettingStatementId':
        inputIds = searchModel.references.theirNettingStatementId;
        break;
      case 'documentId':
        inputIds = searchModel.references.documentId;
        break;
      case 'invoiceNumber':
        inputIds = searchModel.references.invoiceNumber;
        break;
      default:
        break;
    }

    const request = {
      references: {
        inputIds: inputIds
      },
      paging: searchModel.paging,
      sorting: searchModel.sorting,
    } as NettingStatementFinderSearchModel;

    return request;
  }

  private getFilterSearchRequest(searchModel: NettingStatementFinderSearchModel) {
    const {
      submissionDateRange,
      paymentDateRange,
      invoicePeriod,
      counterParties,
      commodities,
      deliveryPoints,
      nettingStatementTypes,
      senderUs,
    } = searchModel.filter;

    const request = {

      filter: {
        commodities,
        counterParties,
        deliveryPoints,
        nettingStatementTypes,

        invoicePeriod: {
          start: invoicePeriod && invoicePeriod.start ? moment(invoicePeriod.start).format('YYYY-MM-DD') : null,
          end: invoicePeriod && invoicePeriod.end ? moment(invoicePeriod.end).format('YYYY-MM-DD') : null
        },
        submissionDateRange: {
          start: submissionDateRange && submissionDateRange.start ? this.transformSubmissionDateToUTC(submissionDateRange.start, 'startOfDay') : null,
          end: submissionDateRange && submissionDateRange.end ? this.transformSubmissionDateToUTC(submissionDateRange.end, 'endOfDay') : null
        },
        paymentDateRange: {
          start: paymentDateRange && paymentDateRange.start ? moment(paymentDateRange.start).format('YYYY-MM-DD') : null,
          end: paymentDateRange && paymentDateRange.end ? moment(paymentDateRange.end).format('YYYY-MM-DD') : null
        },
        senderUs
      },
      paging: searchModel.paging,
      sorting: searchModel.sorting,
    } as NettingStatementFinderSearchModel;

    return request;
  }

  private transformSubmissionDateToUTC(date: string | Date, time: 'startOfDay' | 'endOfDay'): Date {
    const utcDate = moment.utc(moment(date).format('YYYY-MM-DD'), 'YYYY-MM-DD');
    return time === 'startOfDay' ? utcDate.startOf('day').toDate() : utcDate.endOf('day').toDate();
  }


  private generatePropertyFilter(filterState: EsmNettingStatementFinderFilterState):NettingStatementFinderPropertyFilter{
    let propertyFilter: NettingStatementFinderPropertyFilter;

    const {
      submissionDateRange,
      paymentDateRange,
      invoicePeriod,
      counterParties,
      commodities,
      deliveryPoints,
      nettingStatementTypes,
      senderUs,
    } = filterState.filter;

   propertyFilter = {
      commodities: this.mapRequestValues(commodities, filterState.commodities),
      counterParties: this.mapRequestValues(counterParties, filterState.counterParties.map(item => item.key)),
      deliveryPoints: this.mapRequestValues(deliveryPoints, filterState.deliveryPoints),
      nettingStatementTypes: this.mapRequestValues(nettingStatementTypes, filterState.nettingStatementTypes),
      invoicePeriod,
      submissionDateRange,
      paymentDateRange,
      senderUs
    } as NettingStatementFinderPropertyFilter;

    return propertyFilter;
  }

  private mapDeliveryPoint(searchFilter:EsmNettingStatementFinderFilterState):NettingStatementFinderPropertyFilter{

    if (searchFilter.deliveryPoints.length===searchFilter.filter.deliveryPoints.length){
      return {...searchFilter.filter,deliveryPoints:null};
    }else {
      return searchFilter.filter;
    }
  }

  private handleBlob(response): Observable<ReturnType<typeof fromTableActions.setMessagesAction>> {
    saveBlob(response);
    return of(fromTableActions.setMessagesAction([]));
  }

  mapRequestValues = <T extends (string | number | { keyObject: string, valueObject: string } | { value: string; key: string; })[]>(selectedValues: T, allValues: T): null | T =>
   (!allValues || !selectedValues) || (allValues.length === selectedValues.length) ? null : selectedValues



  /**
   * a perfect transformation of promise to observable response
   *
   * TODO move it to commons
   */
  private handleBlobErrorContent(errResponse):Observable<Action> {
    window.scrollTo(0, 0); // scroll top if error occurred

    return new Observable(observer => {
      const controller = new AbortController();

      getBlobContent(errResponse)
        .then(message => {
          observer.next(fromTableActions.setMessagesAction([this.mapper.createErrorMessage(message)]));
          observer.complete();})
        .catch(err => {observer.error(err);});

      return () => controller.abort();
    });
  }

  params = (id) => new HttpParams().set('documentId', id);

}
