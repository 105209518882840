import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import {AnnouncementResponseModel} from './announcementResponseModel';
import {AuthenticationService} from '../auth/authentication.service';
import { ApiRequestService } from '@common/api-request.service';
import { HelpTextEditorModel } from './editor/help-text-editor.model';

@Injectable()
export class HelpService {
  private announcementsUrl = '/api/help/announcements';
  private supportUrl = '/api/help/support';
  private releaseNotesUrl = '/api/help/releaseNotes';

  annoucementSubject$ = new BehaviorSubject<AnnouncementResponseModel>({title:'', content: '', editable: false});
  supportSubject$ = new BehaviorSubject<AnnouncementResponseModel>({title:'', content: '', editable: false});
  releaseNotesSubject$ = new BehaviorSubject<AnnouncementResponseModel>({title:'', content: '', editable: false});

  constructor(private apiRequestService: ApiRequestService) {
  }

  fetchAnnouncement(): void {
    this.apiRequestService.get(this.announcementsUrl).subscribe(response =>{
      this.annoucementSubject$.next(response);
    });
  }

  submitAnnouncement(contentModel: HelpTextEditorModel): Promise<any> {

    return this.apiRequestService.post(
      this.announcementsUrl + '/save', contentModel
    ).toPromise();
  }

  submitReleaseNotes(contentModel: HelpTextEditorModel): Promise<any> {

    return this.apiRequestService.post(
      this.releaseNotesUrl+ '/save', contentModel
    ).toPromise();
  }

  submitSupport(contentModel: HelpTextEditorModel): Promise<any> {

    return this.apiRequestService.post(
      this.supportUrl+ '/save', contentModel
    ).toPromise();
  }

  fetchSupportMessage(): void {
    this.apiRequestService.get(this.supportUrl).subscribe(response =>{
      this.supportSubject$.next(response);
    });
  }

  fetchReleaseNotes(): void {
    this.apiRequestService.get(this.releaseNotesUrl).subscribe(response =>{
      this.releaseNotesSubject$.next(response);
    });
  }


  get onAnnoucementChange():Observable<AnnouncementResponseModel> {
    return this.annoucementSubject$.asObservable();
  }
  get onSupportChange():Observable<AnnouncementResponseModel> {
    return this.supportSubject$.asObservable();
  }
  get onReleaseNotesChange():Observable<AnnouncementResponseModel> {
    return this.releaseNotesSubject$.asObservable();
  }

}
