import {createAction, props, union} from '@ngrx/store';
import {Message} from 'primeng/api';
import {DatatableData, PagingFilter, SortingFilter} from '@common/shared/results.model';
import {TableEntry} from '../../../administration-tab-panel/billing-runs/acer/acer-billing-run-tab.model';

export const loading = createAction('[ACER BILLING RUNS] un/loading', props<{ load: { table?: boolean, errors?: boolean, run?: boolean, delete?: boolean, send?: boolean } }>());
export const loadTable = createAction('[ACER BILLING RUNS] Load table data');
export const loadTableSuccess = createAction('[ACER BILLING RUNS] Load table data successfully', props<{ data: DatatableData<TableEntry[]> }>());

export const loadPresets = createAction('[ACER BILLING RUNS] Load preset values');
export const loadPresetsSuccess = createAction('[ACER BILLING RUNS] Load preset values successfully', props<{ billingRunAllowed: boolean }>());


export const deleteBillingRun = createAction('[ACER BILLING RUNS] confirm delete billing run', props<{ billingRunID: number }>());
export const deleteBillingRunSuccess = createAction('[ACER BILLING RUNS] confirm delete billing run success');
export const getAcerBillingRunErrors = createAction('[ACER BILLING RUNS] get errors ', props<{ billingRunID: number }>());

export const getAcerBillingRunErrorsSuccess = createAction('[ACER BILLING RUNS] get errors success ', props<{ err: { name: string, code: string }[] }>());
export const resetAcerBillingRunErrors = createAction('[ACER BILLING RUNS] reset errors ');
export const startMailingRun = createAction('[ACER BILLING RUNS] start mailing run ', props<{ billingRunID: number }>());

export const startBillingRun = createAction('[ACER BILLING RUNS] start billing run ', props<{ invoiceStartNumber: number, isDraft: boolean }>());
export const downloadBillingRun = createAction('[ACER BILLING RUNS] download billing run ', props<{ billingRunID: number }>());
export const continueBillingRun = createAction('[ACER BILLING RUNS] continue billing run ', props<{ billingRunID: number }>());
export const cancelBillingRun = createAction('[ACER BILLING RUNS] confirm cancel billing run', props<{ billingRunID: number }>());

export const setMessages = createAction('[ACER BILLING RUNS] set messages', props<{ msg: Message[] }>());
export const resetMessages = createAction('[ACER BILLING RUNS] Reset Messages');

export const setDeleteMessages = createAction('[ACER BILLING RUNS] set delete messages', props<{ msg: Message[] }>());

export const pagingAndSorting = createAction('[ACER BILLING RUNS] Get sorting and paging', props<{ sorting: SortingFilter, paging: PagingFilter }>());
export const setPagingAndSorting = createAction('[ACER BILLING RUNS] Set sorting and paging', props<{ sorting: SortingFilter, paging: PagingFilter }>());


export const cleanState = createAction('[ACER BILLING RUNS] Clear table data state');


const actions = union({
  loading,
  loadTable,
  loadTableSuccess,
  setMessages,
  resetMessages,
  pagingAndSorting,
  setPagingAndSorting,
  deleteBillingRun,
  resetAcerBillingRunErrors,
  downloadBillingRun,
  loadPresets,
  loadPresetsSuccess
});

export type AcerBillingRunsActions = typeof actions;

