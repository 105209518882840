<ng-container *ngIf="errorMessageService.hasError(); else content2">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content2>

<p-tabView [activeIndex]="activeIndex" (onChange)="setTab($event)">
  <p-tabPanel header="Table 1">
    <div class="tab-view-body">
      <ng-template pTemplate="content" [ngIf]="activeIndex===0">
        <div class="tab-view-body">
          <h1>REMIT Table 1 Trade Entry</h1>
          <cms-remit-trade-entry-table1></cms-remit-trade-entry-table1>
        </div>
      </ng-template>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Table 2"  >
    <div class="tab-view-body">
      <ng-template pTemplate="content" [ngIf]="activeIndex===1">
        <div class="tab-view-body">
          <h1>REMIT Table 2 Trade Entry</h1>
          <cms-remit-trade-entry-table2></cms-remit-trade-entry-table2>
        </div>
      </ng-template>
    </div>
  </p-tabPanel>
</p-tabView>

</ng-template>
