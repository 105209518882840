import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UserAccountsFilters } from '../user-accounts.model';
import { OrganisationGroup } from '../../administration.model';

@Component({
  selector: 'cms-user-accounts-filter',
  templateUrl: './user-accounts-filter.component.html',
  styleUrls: ['./user-accounts-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAccountsFilterComponent implements OnInit, OnChanges {
  @Input() filters: UserAccountsFilters;
  @Input() orgGroups: OrganisationGroup[];
  @Input() groupsVisible = false;
  @Input() filterInactiveVisible = true;
  @Output() filter = new EventEmitter<UserAccountsFilters>();
  orgGroupsOptions: { label: string, value: any }[] = [];
  form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    const {
      organisationGroupId = null,
      filterInactive = true
    } = this.filters;
    this.form = this.formBuilder.group({
      organisationGroupId,
      filterInactive
    });

    this.initGroups();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes.orgGroups) {
      this.initGroups();
    }

    if (this.form && changes.filters) {
      const {
        organisationGroupId = null,
        filterInactive = true
      } = this.filters;
      this.form.patchValue({
        organisationGroupId,
        filterInactive
      });
      this.form.updateValueAndValidity();
    }
  }

  onChange(): void {
    this.filter.emit({
      ...this.form.value
    });
  }

  private initGroups(): void {
    this.orgGroupsOptions = this.orgGroups && this.orgGroups.length ?
      this.orgGroups.map(item => ({ value: item.key, label: item.value })) : [];
    this.orgGroupsOptions = [{ label: 'All Organisation Groups', value: null }, ...this.orgGroupsOptions];
  }

}
