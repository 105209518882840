import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, concatMap, switchMap } from 'rxjs/operators';
import { Message } from 'primeng/api';
import { ApiRequestService } from '@common/api-request.service';
import { MessagesMapperService } from '@common/messages-mapper.service';
import {
  allColumnsConfigOrgGroup, defaultStyles,
  EcmSettingsProcessDataResponse
} from '../../../settings/ecm-settings-tab-panel/process/ecm-process.model';
import * as fromActions from './process.actions';
import { of } from 'rxjs';

@Injectable()
export class EcmSettingsProcessEffects {
  private baseUrl = '/api/ecm/settings/process';

  constructor(private actions$: Actions,
              private apiGateway: ApiRequestService,
              private mapper: MessagesMapperService) { }

   loadSettingsData$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.LOAD_DATA),
      concatMap((action: fromActions.LoadDataAction) => {
        return this.apiGateway.post(`${this.baseUrl}/loadSettingsData`, null)
          .pipe(
            switchMap((response: EcmSettingsProcessDataResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [new fromActions.SetMessagesAction(messages)];
              }

              const buttons = { name: 'editActionEnabled', type: 'button', title: '', style: { ...defaultStyles, 'text-align': 'center' }, sortable: false, visible: false };
              const columns = [...allColumnsConfigOrgGroup, buttons ];
              if(response.editable) {
                buttons.visible = true;
              }

              return [
                new fromActions.SetColumnsAction(columns),
                new fromActions.SetMessagesAction([]),
                new fromActions.LoadDataSuccessAction(response)
              ];
            }),
            catchError(() => of(new fromActions.SetMessagesAction([
              this.mapper.createErrorMessage('Server error')
            ])))
          );
      })));

   updateEcmFeatures$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.UPDATE_ECM_FEATURES),
      concatMap((action: fromActions.UpdateEcmFeatures) => {
        return this.apiGateway.post(`${this.baseUrl}/updateEcmFeatures`, action.payload)
          .pipe(
            switchMap((response: EcmSettingsProcessDataResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [new fromActions.SetMessagesAction(messages)];
              }

              return [
                new fromActions.SetMessagesAction([]),
                new fromActions.LoadDataAction()
              ];
            }),
            catchError(() => of(new fromActions.SetMessagesAction([
              this.mapper.createErrorMessage('Server error')
            ])))
          );
      })
    ));
}
