import { DateRange, defaultStyles, KeyValueProp } from '../../invoice/finder/invoice-finder.model';
import { ErrorResponse } from '@common/error-response.model';
import { Column } from '@common/shared/datatable.model';
import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import { MyFilter } from '@common/my-filters/my-filters.model';
import { Note, Sender } from '@common/deals/deals.model';

export type ReferenceBy =  'ourNettingStatementId' | 'theirNettingStatementId' | 'documentId' | 'invoiceNumber';

export interface NettingStatementFinderReferences {
  ourNettingStatementId?: string;
  theirNettingStatementId?: string;
  documentId?: string;
  invoiceNumber?: string;
}


export interface NettingStatementFinderReferencesFilter extends NettingStatementFinderReferences{
  inputIds: string;
  filterBy: ReferenceBy;
}


export interface NettingStatementFinderPropertyFilter {
  commodities: string[];
  counterParties: (string | number)[];
  deliveryPoints: { keyObject: string, valueObject: string }[];
  invoicePeriod: DateRange;
  nettingStatementTypes: string[];
  paymentDateRange: DateRange;
  submissionDateRange: DateRange;
  senderUs: boolean;
}

export interface NettingStatementFinderSearchModel {
  filter?: NettingStatementFinderPropertyFilter;
  references?: NettingStatementFinderReferencesFilter;
  sorting: SortingFilter;
  paging: PagingFilter;
}


export interface EsmNettingStatementFinderFormValues {
  commodities: string[];
  counterParties: KeyValueProp[];
  nettingStatementTypes: string[];
  deliveryPoints: any[];
}

export interface EsmNettingStatementFinderFormResponse extends ErrorResponse {
  value: EsmNettingStatementFinderFormValues;
}


// table data

export interface NettingStatementTableData extends ErrorResponse{
  count: number;
  currentPage: number;
  values: EsmNettingStatementModel[];
  notFoundIds?: string[];
  columnOrder: string[];
}

export const EsmNettingStatementTableColumns: Column[] = [
  { name: 'submissionTimestamp', type: 'dateWithoutTime', title: 'Sub. Date', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'nettingStatementId', type: 'text', title: 'Net. Stmt. ID#', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'receiverId', type: 'text', title: 'Counterparty', style: { ...defaultStyles}, sortable: true, visible: true },
  { name: 'physicalFinancial', type: 'list', title: 'Net. Stmt. Type', style: { ...defaultStyles, padding: 0, 'overflow': 'visible' }, sortable: false, visible: true },
  { name: 'commodity', type: 'list', title: 'Commodity', style: { ...defaultStyles, padding: 0,  'overflow': 'visible', 'width': '300px' }, sortable: false, visible: true },
  { name: 'deliveryPointArea', type: 'list', title: 'Delivery Point', style: { ...defaultStyles, padding: 0, 'overflow': 'visible', 'min-width': '160px' }, sortable: false, visible: true },
  { name: 'agreementDetails', type: 'list', title: 'Agreement / Version', style: { ...defaultStyles, padding: 0, 'overflow': 'visible' }, sortable: false, visible: true },
  { name: 'currency', type: 'list', title: 'Currency', style: { ...defaultStyles, padding: 0, 'overflow': 'visible' }, sortable: false, visible: true },
  { name: 'paymentDate', type: 'dateWithoutTime', title: 'Payment Date', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'invoicePeriodStart', type: 'dateWithoutTime', title: 'Inv. Period Start', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'invoicePeriodEnd', type: 'dateWithoutTime', title: 'Inv. Period End', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'nettingStatementDate', type: 'dateWithoutTime', title: 'Net. Stmt. Date', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'totalVolumeUnit', type: 'list', title: 'Total Volume Unit', style: { ...defaultStyles, padding: 0, 'overflow': 'visible' }, sortable: false, visible: true },
  { name: 'documentId', type: 'text', title: 'Document ID', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'documentVersion', type: 'text', title: 'Document Version', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'openDocument', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: false },
  { name: 'openDocumentXml', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'auditTrail', type: 'button', title: '', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'nearMatch', type: 'button', title: '', style: { ...defaultStyles, width: '80px' }, sortable: false, visible: true },
  { name: 'misMatch', type: 'button', title: '', style: { ...defaultStyles, width: '80px' }, sortable: false, visible: true },
  { name: 'nettingPdf', type: 'button', title: '', style: { ...defaultStyles, width: '80px' }, sortable: false, visible: true },
  { name: 'state', type: 'text', title: 'State', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'vatAmount', type: 'currency', title: 'VAT Amt.', style: { ...defaultStyles, 'text-align': 'right' }, sortable: true, visible: true },
  { name: 'vatAmountCurrency', type: 'text', title: 'VAT Curr.', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'notes', type: 'button', title: '', style: { ...defaultStyles, width: '80px' }, sortable: false, visible: true },
  { name: 'fixedFloating', type: 'list', title: 'Fixed/Floating', style: { ...defaultStyles, padding: 0, 'overflow': 'visible' }, sortable: false, visible: true },
  { name: 'natureOfPrice', type: 'list', title: 'Nature Of Price', style: { ...defaultStyles, width: '500px', padding: 0, 'overflow': 'visible' }, sortable: false, visible: true },
  { name: 'marketInformation', type: 'list', title: 'Market Info', style: { ...defaultStyles, padding: 0, 'overflow': 'visible' }, sortable: false, visible: true },
  { name: 'supplierId', type: 'text', title: 'Supplier SSDSID', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'customerId', type: 'text', title: 'Customer SSDSID', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'senderId', type: 'text', title: 'Sender Party', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'matchType', type: 'text', title: 'Match Type', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'documentRole', type: 'text', title: 'Net. Stmt. Role', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'netAmount', type: 'currency', title: 'Net. Amt.', style: { ...defaultStyles,  'text-align': 'right' }, sortable: true, visible: true },
  { name: 'netAmountCurrency', type: 'text', title: 'Net. Amt. Curr.', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'grossAmount', type: 'currency', title: 'Tot. Amt.', style: { ...defaultStyles, 'text-align': 'right' }, sortable: true, visible: true },
  { name: 'grossAmountCurrency', type: 'text', title: 'Tot. Amt. Curr.', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'customerDisplayName', type: 'text', title: 'Customer Name', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'supplierDisplayName', type: 'text', title: 'Supplier Name', style: { ...defaultStyles }, sortable: true, visible: true }
];

export interface EsmNettingStatementModel {
  id: number;
  nettingStatementId: string;
  commodity: string;
  currency: string;
  customerId: string;
  agreementDetails: string; // Agreement/Version
  deliveryPointArea: string;
  documentId: string;
  documentVersion: number;
  fixedFloating: string;
  nettingStatementDate: string;
  invoicePeriodEnd: string;
  invoicePeriodStart: string;
  marketInformation: string;
  matchType: string;
  natureOfPrice: string;
  paymentDate: string;
  physicalFinancial: string;
  receiverId: string;
  notes: Note[];
  senderId: string;
  state: string;
  submissionTimestamp: string;
  supplierId: string;
  totalVolumeUnit: string;
  vatAmount: number;
  vatAmountCurrency: string;
  nearMatchButtonVisible: boolean;
  misMatchButtonVisible: boolean;
  nttdPdfButtonVisible:boolean;
  documentRole: string;
  netAmount: number;
  netAmountCurrency: number;
  grossAmount: number;
  grossAmountCurrency: number;
  customerDisplayName: string;
  supplierDisplayName: string;
}


// user filters
export interface EsmNettingStatementUserFilter extends MyFilter {
  commodityFilter: string[];
  counterpartyFilter: (string | number)[];
  deliveryPointFilter: { keyObject: string, valueObject: string }[];
  nettingStatementTypeFilter: string[];
  hiddenType: Sender;
}

export interface EsmNettingStatementUserFiltersResponse extends ErrorResponse {
  defaultFilter: EsmNettingStatementUserFilterResponse;
  filterSelections: { [key: string]: EsmNettingStatementUserFilterResponse };
}


export interface EsmNettingStatementUserFilterResponse {
  commodityFilter: string[];
  counterpartyFilter: string[];
  deliveryPointFilter: { keyObject: string, valueObject: string }[];
  nettingStatementTypeFilter: string[];
  filterName: string;
  hiddenType: Sender;
  isDefaultFilter: boolean;
}

export interface EsmNettingStatementUserFilterRequest {
  commodityFilter: string[];
  counterpartyFilter: (string|number)[];
  deliveryPointFilter: ({keyObject:string,valueObject:string}| { value: string; key: string; })[];
  nettingStatementTypeFilter: string[];
  filterName: string;
  hiddenType: Sender;
  isDefaultFilter: boolean;
}


export const getSaveReplaceMyFilter = (value: NettingStatementFinderPropertyFilter,
                                       filter: MyFilter): EsmNettingStatementUserFilter => {
  const {
    commodities,
    counterParties,
    deliveryPoints,
    nettingStatementTypes,
    senderUs
  } = value;
  return {
    filterName: filter.filterName,
    defaultFilter: filter.defaultFilter,
    commodityFilter: commodities,
    counterpartyFilter: counterParties,
    deliveryPointFilter: deliveryPoints,
    nettingStatementTypeFilter: nettingStatementTypes,
    hiddenType: senderUs ? Sender.US : Sender.THEM
  };
};


export const EsmNettingStatementTableCustomizeConfig: { [name: string]:  Column} = {
  submissionTimestamp: {name: 'submissionTimestamp', title: 'Sub. Date'},
  nettingStatementId: { name: 'nettingStatementId', title: 'Net. Stmt. ID#'},
  receiverId: { name: 'receiverId', title: 'Counterparty'},
  physicalFinancial: { name: 'physicalFinancial', title: 'Net. Stmt. Type'},
  commodity: { name: 'commodity', title: 'Commodity'},
  deliveryPointArea: { name: 'deliveryPointArea', title: 'Delivery Point' },
  agreementDetails:{ name: 'agreementDetails', title: 'Agreement / Version',  },
  currency:{ name: 'currency', title: 'Currency'},
  paymentDate: { name: 'paymentDate',  title: 'Payment Date' },
  invoicePeriodStart: { name: 'invoicePeriodStart',  title: 'Inv. Period Start' },
  invoicePeriodEnd: { name: 'invoicePeriodEnd',  title: 'Inv. Period End' },
  nettingStatementDate: { name: 'nettingStatementDate',  title: 'Net. Stmt. Date' },
  totalVolumeUnit: { name: 'totalVolumeUnit', type: 'list', title: 'Total Volume Unit'},
  documentId: { name: 'documentId',  title: 'Document ID' },
  documentVersion: { name: 'documentVersion',  title: 'Document Version' },
  openDocumentXml: { name: 'openDocumentXml', title: 'Download XML Button'},
  auditTrail: { name: 'auditTrail', title: 'Audit Trail Button' },
  nearMatch: { name: 'nearMatch',  title: 'Open Near Match Finder Button'},
  misMatch: { name: 'misMatch', title: 'Open Mismatch View Button'},
  nettingPdf: { name: 'nettingPdf', title: 'Download Netting PDF Button'},
  state: { name: 'state',  title: 'State' },
  vatAmount: { name: 'vatAmount', title: 'VAT Amt.' },
  vatAmountCurrency: { name: 'vatAmountCurrency',  title: 'VAT Curr.',},
  notes: { name: 'notes', type: 'button', title: 'Note Button'},
  fixedFloating: { name: 'fixedFloating', type: 'list', title: 'Fixed/Floating'},
  natureOfPrice: { name: 'natureOfPrice', type: 'list', title: 'Nature Of Price' },
  marketInformation: { name: 'marketInformation', type: 'list', title: 'Market Info'},
  supplierId: { name: 'supplierId',  title: 'Supplier SSDSID' },
  customerId: { name: 'customerId',  title: 'Customer SSDSID'},
  senderId: { name: 'senderId',  title: 'Sender Party'},
  matchType: { name: 'matchType',  title: 'Match Type'},
  documentRole: { name: 'documentRole',  title: 'Net. Stmt. Role'},
  netAmount: { name: 'netAmount', title: 'Net. Amt.' },
  netAmountCurrency: { name: 'netAmountCurrency', title: 'Net. Amt. Curr.'},
  grossAmount: { name: 'grossAmount', title: 'Tot. Amt.' },
  grossAmountCurrency: { name: 'grossAmountCurrency', title: 'Tot. Amt. Curr.'},
  customerDisplayName: { name: 'customerDisplayName', title: 'Customer Name' },
  supplierDisplayName: { name: 'customerDisplayName', title: 'Supplier Name' }
};
