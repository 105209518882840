<form class="panel-box row gutter-0 align-items-center margin-bottom-12 decent-max-width" [formGroup]="form">
  <div class="col-xl-3 col-lg-3 col-md-6 col-12">
    <input class="form-control p-component margin-3 ng-trim-ignore"
           type="text"
           id="elcom-whitelist-ap-input"
           formControlName="acerCode"
           cmsUpperCase
           placeholder="ACER Code"
           maxlength="12">
  </div>
  <div class="col-auto btn__container justify-content-xl-start justify-content-end">
    <button id="elcom-whitelist-ap-add-btn" type="submit" class="btn margin-top-3 margin-bottom-3 margin-left-12" (click)="onAdd()">Add</button>
    <button id="elcom-whitelist-ap-clear-btn" class="btn margin-top-3 margin-bottom-3 margin-left-12" (click)="onClear()">Clear</button>
  </div>
</form>
