<div class="panel-box margin-bottom-15">
  <form [formGroup]="documentIdFilterFormGroup" (ngSubmit)="onSubmitDocumentIdFilterForm()">
    <div class="row col">
      <label for="documentId">Document ID</label>
    </div>
    <div class="row align-items-center">
      <div class="col-12 col-sm-8 col-md-9 col-lg-12 col-xl-8">
        <textarea class="form-control p-component padding-6" maxlength="4000" id="documentId" formControlName="documentId"></textarea>
      </div>
      <div class="col">
        <button
          type="submit"
          id="documentIdSearchBtn"
          class="form-control btn margin-3 padding-6"
          [disabled]="(cpmlDealService.getLoading() | async) ||  (!documentIdFilterFormGroup.valid)">
          Search
        </button>
      </div>
    </div>
    <div class="col-12" *ngIf="documentIdFilterFormSubmitted">
      <p class="error-note" *ngIf="isDocumentIdsNotRuleConformError()">Field 'Document ID' is not rule conform.</p>
    </div>
  </form>
  <form [formGroup]="utiFilterFormGroup" (ngSubmit)="onSubmitUtiFilterForm()">
    <div class="row">
      <div class="col">
        <label for="uti">UTI</label>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-12 col-sm-8 col-md-9 col-lg-12 col-xl-8">
        <textarea class="form-control p-component padding-6" id="uti" formControlName="uti"></textarea>
      </div>
      <div class="col">
        <button type="submit" id="utiSearchBtn" class="form-control btn margin-3 padding-6" [disabled]="(cpmlDealService.getLoading() | async) ||  (!utiFilterFormGroup.valid)">Search</button>
      </div>
    </div>
    <div class="col-12" *ngIf="utiFilterFormSubmitted">
      <p class="error-note" *ngIf="isUtisNotRuleConformError()">Field 'UTI' is not rule conform.</p>

    </div>
  </form>
  <form [formGroup]="tradeIdFilterFormGroup" (ngSubmit)="onSubmitTradeIdFilterForm()">
    <div class="row">
      <div class="col">
        <label for="tradeId">Trade ID</label>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-12 col-sm-8 col-md-9 col-lg-12 col-xl-8">
        <textarea class="form-control p-component padding-6" maxlength="4000" id="tradeId" formControlName="tradeId"></textarea>
      </div>
      <div class="col">
        <button type="submit" id="tradeIdSearchBtn" class="form-control btn margin-3 padding-6" [disabled]="(cpmlDealService.getLoading() | async) ||  (!tradeIdFilterFormGroup.valid)">Search</button>
      </div>
    </div>
    <div class="col-12" *ngIf="tradeIdFilterFormSubmitted">
      <p class="error-note" *ngIf="isTradeIdsNotRuleConformError()">Field 'Trade ID' is not rule conform.</p>
    </div>
  </form>

  <div class="row align-items-center justify-content-between">
    <div class="col-12 col-sm-8 col-md-9 col-lg-12 col-xl-8">
      <div class="info-panel truncated-text">
        <span class="fa fa-info-circle padding-right-6"></span>Field accept lists of up to 50 IDs
      </div>
    </div>
    <div class="col">
      <button type="reset" id="btn-reset-uti" class="form-control btn margin-3 padding-6" (click)="onResetFilters()">Reset</button>
    </div>
  </div>


</div>
