import { counterpartiesInitialState, counterpartiesReducer, CounterPartyState } from './counterparty/counterparty.reducers';
import { nettingPreviewTableInitialState, nettingPreviewTableReducer, NettingTableState } from './netting-table/netting-table.reducers';
import { invoiceOverviewInitialState, invoiceOverviewReducer, InvoiceOverviewState } from './invoice-overview/invoice-overview.reducers';
import { amountTableInitialState, AmountTableState, nettingAmountTableReducer } from './amount-table/amount-table.reducers';
import { generationDialogInitialState, generationDialogReducer, GenerationDialogState } from './generation-dialog/generation-dialog.reducers';
import { CounterPartyActions } from './counterparty/counterparty.actions';
import { NettingTableActions } from './netting-table/netting-table.actions';
import { InvoicePreviewActions } from './invoice-overview/invoice-overview.actions';
import { AmountTableActions } from './amount-table/amount-table.actions';
import { GenerationDialogActions } from './generation-dialog/generation-dialog.actions';

export interface NettingPreviewState {
  counterparties: CounterPartyState;
  nettingTable: NettingTableState;
  amountTable: AmountTableState;
  invoiceOverview: InvoiceOverviewState;
  generationDialog: GenerationDialogState;
}

export const initialState: NettingPreviewState = {
  counterparties: counterpartiesInitialState,
  nettingTable: nettingPreviewTableInitialState,
  amountTable: amountTableInitialState,
  invoiceOverview: invoiceOverviewInitialState,
  generationDialog: generationDialogInitialState,
};

export function esmNettingPreviewReducer(
  state: NettingPreviewState = { ...initialState },
  action: CounterPartyActions & NettingTableActions & InvoicePreviewActions & AmountTableActions & GenerationDialogActions): NettingPreviewState {
  return {
    ...state,
    counterparties: counterpartiesReducer(state.counterparties, action),
    nettingTable: nettingPreviewTableReducer(state.nettingTable, action),
    invoiceOverview: invoiceOverviewReducer(state.invoiceOverview, action),
    amountTable: nettingAmountTableReducer(state.amountTable, action),
    generationDialog: generationDialogReducer(state.generationDialog, action),
  };

}
