<cms-dashboard-deal-nav *ngIf="navValues$ | async as navValues"
                        [data]="navValues"
                        [state]="state"
                        (back)="backToDashboard()"
                        (changeState)="changeState($event)">
</cms-dashboard-deal-nav>
<cms-ecm-filters-container [index]="categoryLabel"></cms-ecm-filters-container>
<div class="mt-3">
  <cms-datatable *ngIf="(data$ | async) as data"
                 [currentPage]="(paging$ | async).page"
                 [tableColumns]="columns$ | async"
                 [sortField]="(sorting$ | async).columnName"
                 [sortOrder]="(sorting$ | async).sortOrder"
                 [loading]="(loading$ | async) || !(loaded$ | async) || !(columns$ | async)?.length"
                 [data]="data.values"
                 [totalRecords]="data.count"
                 [selected]="this.selected$ | async"
                 [scrollable]="false"
                 [autoLayout]="true"
                 [checkboxRow]="true"
                 [lazy]="!!(data && data.values && data.values.length)"
                 [lazyLoadOnInit]="false"
                 (selectedChange)="onSelected($event)"
                 (lazyLoad)="onLazyLoad($event)">
    <ng-template pTemplate="caption">
      <div class="float-left date-label">
        {{dateRangeText$ | async}}
      </div>
      <cms-deal-table-caption [showTerminationButton]="true"
                              [terminateDisable]="!(editRights$|async) || terminateDisable"
                              [addNoteDisable]="!(noteRights$| async) || !(confirmationIds | async)?.length"
                              (openTerminateDialog)="openTerminateDialog()"
                              (openAddNoteDialog)="onOpenAddNoteDialog()"
                              (exportEvent)="onExport()"
                              (clickCustomize)="onClickCustomize()"
                              [exportDisable]="!(data$ | async)">
      </cms-deal-table-caption>
    </ng-template>
    <ng-template pTemplate="body" let-row="row" let-column="column">
      <td *ngIf="column.type === 'document-hidden'" [ngStyle]="column.style">
        <cms-document-hidden-cell *ngIf="row.hideCheckboxVisible"
                                  [data]="row"
                                  (unhide)="onUnhide($event)"
                                  (hide)="onHide($event)">
        </cms-document-hidden-cell>
      </td>
      <!--amend/cancel buttons-->
      <td *ngIf="column.type === 'button' && column.name === 'cancelDeal'" [ngStyle]="column.style">
        <div class="btn-wrapper inline-block btn--amend-cancel">
          <a routerLink="/ecm/trade/entry/{{row.id}}" target="_blank">
            <button *ngIf="row.ecmLightAmendmentButtonVisible" class="btn" type="button" title="Amend">Amend</button>
          </a>
          <button *ngIf="row.ecmLightCancelButtonVisible" class="btn margin-left-3" type="button" title="Cancel"
                  (click)="onEcmLiteCancel(row)">
            Cancel
          </button>
        </div>
      </td>
      <!--document button-->
      <td *ngIf="column.type === 'button' && column.name === 'openDocument'" [ngStyle]="column.style">
        <div class="btn-wrapper inline-block">
          <button pButton type="button" icon="fa fa-file" title="Document" (click)="onDownloadHtml(row)"></button>
        </div>
      </td>
      <!--document (xml) button-->
      <td *ngIf="column.type === 'button' && column.name === 'openDocumentXml'" [ngStyle]="column.style">
        <div class="btn-wrapper inline-block">
          <button pButton type="button" icon="fa fa-file-code-o" title="Document (XML)"
                  (click)="onDownloadXml(row)"></button>
        </div>
      </td>
      <!--audit trail button-->
      <td *ngIf="column.type === 'button' && column.name === 'auditTrail'" [ngStyle]="column.style">
        <div class="btn-wrapper inline-block">
          <a routerLink="/ecm/audittrail" title="Audit Trail" target="_blank" [queryParams]="getDisplayParams(row)">
            <button pButton type="button" icon="fa fa-search" title="Audit Trail"></button>
          </a>
        </div>
      </td>
      <td *ngIf="column.type === 'button' && column.name === 'notes'" [ngStyle]="column.style">
        <cms-notes-cell [confirmationId]="row.id"
                        [data]="row"
                        [notes]="row.notes"
                        (view)="onViewNotes($event)">
        </cms-notes-cell>
      </td>
      <td *ngIf="column.type === 'button' && column.name === 'counterpartyNearMatch'" [ngStyle]="column.style">
        <cms-ecm-near-match-cell class="comp-match-cell"
                                 [data]="row"
                                 [isBroker]="false">
        </cms-ecm-near-match-cell>
      </td>
      <td *ngIf="column.type === 'button' && column.name === 'brokerNearMatch'" [ngStyle]="column.style">
        <cms-ecm-near-match-cell class="comp-match-cell"
                                 [data]="row"
                                 [isBroker]="true">
        </cms-ecm-near-match-cell>
      </td>
    </ng-template>
    <ng-template pTemplate="customEmptyMessage">
      <tr>
        <td colspan="100" *ngIf="!(loading$ | async)">
          No records found
        </td>
      </tr>
    </ng-template>
  </cms-datatable>

  <cms-terminate-deal-dialog *ngIf="(dialog$ | async)?.name === 'TERMINATE_DEAL'"
                             [display]="(dialog$ | async)?.name === 'TERMINATE_DEAL'"
                             [messages]="(dialog$ | async)?.messages"
                             (exit)="onCancelTerminateDealDialog()"
                             [deals]="selected$ | async"
                             (terminate)="onConfirmTerminateDeal($event)"
                             [type]="typeOfRow"
                             [tradeId]="tradeIdOfRow"
                             [closeButton]="(dialog$ | async)?.data.closeButton"
                             [dialog]="dialog$ | async">
  </cms-terminate-deal-dialog>
  <cms-ecm-lite-cancel *ngIf="(dialog$ | async)?.name === 'ECM_LITE_CANCEL'"
                       [messages]="(dialog$ | async)?.messages"
                       [display]="(dialog$ | async)?.name === 'ECM_LITE_CANCEL'"
                       (exit)="onAbortEcmLiteCancelDialog()"
                       (cancel)="onConfirmEcmLiteCancel($event)"
                       [tradeId]="tradeIdOfRow"
                       [closeButton]="(dialog$ | async)?.data.closeButton"
                       [execution]="(dialog$ | async)?.data.loadingDialog"
                       [confirmationId]="(dialog$ | async)?.data?.confirmationId">
  </cms-ecm-lite-cancel>
  <cms-add-note-dialog *ngIf="(dialog$ | async)?.name === 'ADD_NOTES'"
                       [display]="(dialog$ | async)?.name === 'ADD_NOTES'"
                       [messages]="(dialog$ | async)?.messages"
                       [confirmationIds]="confirmationIds | async"
                       (cancel)="onCancelAddNotes()"
                       (save)="onConfirmAddNotes($event)">
  </cms-add-note-dialog>
  <cms-view-confirmation-notes-dialog *ngIf="(dialog$ | async)?.name === 'VIEW_NOTES'"
                                      [display]="(dialog$ | async)?.name === 'VIEW_NOTES'"
                                      [messages]="(dialog$ | async)?.messages"
                                      [data]="(dialog$ | async)?.data"
                                      (cancel)="onCancelViewNotes()"
                                      (save)="onConfirmAddNotes($event)">
  </cms-view-confirmation-notes-dialog>
  <cms-hide-confirmation-dialog *ngIf="(dialog$ | async)?.name === 'HIDE_CONFIRMATION'"
                                [display]="(dialog$ | async)?.name === 'HIDE_CONFIRMATION'"
                                [messages]="(dialog$ | async)?.messages"
                                [data]="(dialog$ | async)?.data"
                                (cancel)="onCancelHideUnhideConfirmation()"
                                (confirm)="onConfirmHideConfirmation($event)">
  </cms-hide-confirmation-dialog>
  <cms-unhide-confirmation-dialog *ngIf="(dialog$ | async)?.name === 'UNHIDE_CONFIRMATION'"
                                  [display]="(dialog$ | async)?.name === 'UNHIDE_CONFIRMATION'"
                                  [messages]="(dialog$ | async)?.messages"
                                  [data]="(dialog$ | async)?.data"
                                  (cancel)="onCancelHideUnhideConfirmation()"
                                  (confirm)="onConfirmUnHideConfirmation($event)">
  </cms-unhide-confirmation-dialog>
</div>
