<cms-datatable selectionMode="single"
               *ngIf="!!rows"
               [data]="rows"
               [tableId]="'smt-data-table'"
               [totalRecords]="numberOfTotalRows"
               [tableColumns]="cols"
               [sortField]="currentSortField"
               sortOrder="DESCENDING"
               [paginator]="true"
               [autoLayout]="true"
               [scrollable]="false"
               [loading]="loading"
               [lazy]="!!(rows && rows.length)"
               (lazyLoad)="onLazyLoad($event)">
  <ng-template pTemplate="caption">
    <div class="row">
      <div class="col-10 text-start">
        <span class="paginator-info">Showing {{pagerInfo.fromNumberOfResults}} to {{pagerInfo.toNumberOfResults}} of {{pagerInfo.numberOfAllRecords}}</span>
      </div>
      <div class="col-2 text-end">
        <button pButton type="button" icon="fa fa-download" (click)="loadTransactionsReport()" label="Export" [disabled]="!hasData()"></button>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="customBody" let-row="row" let-rowIndex="rowIndex">
    <tr>
      <ng-container *ngFor="let col of cols">
        <td *ngIf="col.visible && col.name !== 'buttons' && col.name !== 'quantity' && col.name!== 'price'" [ngStyle]="col.style">
          <span>{{row[col.name]}}</span>
        </td>
        <td *ngIf="col.visible && col.name == 'buttons'" [ngStyle]="col.style">
          <div class="btn-wrapper">
            <a routerLink="/err/smt_transaction_sheet" [queryParams]="{ regime: row.regime, recordType: row.type, documentType: row.documentType, historyId: row.id }" target="_blank">
              <button class="view_trade_button" pButton type="button" icon="fa fa-file-text-o" title="View {{row.type}}"></button>
            </a>
          </div>
          <div class="btn-wrapper">
            <button class="show_xml_button" pButton type="button" icon="fa fa-file-code-o" title="XML Export" (click)="loadTransactionXml(row.regime, row.dataStoreId)"></button>
          </div>
        </td>
        <td *ngIf="col.visible && col.name == 'quantity'" [ngStyle]="col.style">
          <span>{{row.quantity | number:'1.2-15':'en-US'}}</span>
        </td>
        <td *ngIf="col.visible && col.name == 'price'" [ngStyle]="col.style">
          <span>{{row.price | number:'1.2-15':'en-US'}}</span>
        </td>
      </ng-container>
  </ng-template>
</cms-datatable>
