import { Message } from 'primeng/api';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { Column } from '@common/shared/datatable.model';
import { EsmNettingStatementModel } from '../../../../netting/finder/esm-netting-finder.model';
import { createReducer, on } from '@ngrx/store';
import * as fromFiltersActions from '../../../netting/finder/esm-netting-finder.actions';
import * as fromTableActions from '../../../netting/finder/table/table.actions';
import { NettingStatementFinderTableActions } from './table.actions';
import { SearchBy } from '../esm-netting-finder.reducers';

export interface EsmNettingStatementTableState {
  messages: Message[];
  data: DatatableData<EsmNettingStatementModel[]>;
  loading: boolean;
  paging: PagingFilter;
  sorting: SortingFilter;
  nettingStatementFinderHtml: Blob;
  selectedIds: string[];
  nettingStatements: EsmNettingStatementModel[];
  columns: Column[];
  searchBy: SearchBy;
  visible: boolean;
}

export const tableInitialState: EsmNettingStatementTableState = {
  messages: [],
  data: {
    values: null,
    count: 0,
    currentPage: 0
  },
  paging: {
    page: 0,
    entriesPerPage: 25
  },
  sorting: {
    columnName: 'submissionTimestamp',
    sortOrder: 'DESCENDING'
  },
  loading: false,
  nettingStatementFinderHtml: null,
  selectedIds: [],
  nettingStatements: [],
  columns: [],
  searchBy: null,
  visible: false
};

export const tableReducer = createReducer<EsmNettingStatementTableState, NettingStatementFinderTableActions>(
  tableInitialState,
  on(fromTableActions.setMessagesAction, (state: EsmNettingStatementTableState, { payload: messages }): EsmNettingStatementTableState =>
    ({ ...state, loading: false, messages })),
  on(fromTableActions.setHtmlAction, (state: EsmNettingStatementTableState, { payload: nettingStatementFinderHtml }): EsmNettingStatementTableState =>
    ({ ...state, nettingStatementFinderHtml })),
  on(fromFiltersActions.searchByFilterAction, (state: EsmNettingStatementTableState) => ({ ...state, loading: true })),
  on(fromFiltersActions.searchByReferencesAction, (state: EsmNettingStatementTableState) => ({ ...state, loading: true })),
  // on(fromIFActions.setToDefaultAction, () => ({...tableInitialState})),
  on(fromTableActions.sortAndPageDataAction, (state: EsmNettingStatementTableState, {payload: {paging, sorting}}) => ({
    ...state,
    loading: true,
    messages: [],
    paging,
    sorting,
  })),
  on(fromFiltersActions.onFilterResponseAction, (state: EsmNettingStatementTableState, { payload: data }) => ({
    ...state,
    data: {
      count: data.count,
      values: data.values
    },
    paging: {
      ...state.paging,
      page: data.currentPage
    },
    loading: false,
    visible:true,
    selectedIds: []})),

  on(fromTableActions.setVisibleAction, (state: EsmNettingStatementTableState, { payload: visible }) => ({
    ...state,
    visible: visible,
    })),
  on(fromTableActions.setSelectedIdsAction, (state: EsmNettingStatementTableState, {payload: {confirmationIds: confirmationIds}}) => ({
    ...state,
    selectedIds: confirmationIds,
  })),
  on(fromTableActions.setSelectedNettingStatementsAction, (state: EsmNettingStatementTableState, {payload: {nettingStatements: nettingStatements}}) => ({
    ...state,
    nettingStatements: nettingStatements,
  })),

  on(fromTableActions.setSearchByAction, (state: EsmNettingStatementTableState, {payload}): EsmNettingStatementTableState => ({
    ...state,
    searchBy: payload
  })),

  on(fromTableActions.setColumnsAction, (state: EsmNettingStatementTableState, {payload: columns}) => ({
    ...state,
    columns,
  })),
);
