<div class="product-details">
  <div class="form-title">Trade | Product Details</div>
  <div class="form-body" [formGroup]="formGroup">
    <div class="row gutter-0 !me-[40px]">
      <div class="col-5 label">Contract Type <span class="text-required">*</span></div>
      <div id="remit-trade-entry-table2-contractType" class="col-7 ps-2 pe-1">
        <p-dropdown formControlName="contractType" [options]="contractTypes" [group]="true" [style]="{'width': '100%'}"
          [styleClass]="'margin-3 ' + getValidationErrorStyleClass('contractType')" >
        </p-dropdown>
      </div>
      <span *ngIf="formGroup.controls['contractType'].hasError('error')"
        class="fa fa-info-circle absolute error-marker"></span>

    </div>
    <div class="row gutter-0 !me-[40px]">
      <div class="col-5 label">Energy Commodity <span class="text-required">*</span></div>
      <div id="remit-trade-entry-table2-energyCommodity" class="col-7 ps-2 pe-1">
        <p-dropdown formControlName="energyCommodity" [options]="energyCommodities" [style]="{'width': '100%'}"
          [styleClass]="'margin-3 ' + getValidationErrorStyleClass('energyCommodity')" >
        </p-dropdown>
      </div>
      <span *ngIf="formGroup.controls['energyCommodity'].hasError('error')"
        class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div formArrayName="deliveryPointOrZones">
      <div class="row gutter-0 bottom-row-border !me-[40px]" *ngFor="let deliveryZone of deliveryZones; let i = index" [formGroupName]="i">
        <div class="col-5 label ">Delivery Point or Zone {{i + 1}} <span class="text-required"
            *ngIf="i === 0">*</span></div>
        <div id="remit-trade-entry-table2-deliveryType" class="col-7 ps-2 pe-1">
          <p-dropdown formControlName="deliveryPointOrZone" [options]="deliveryPointsOrZones"
            [style]="{'width': '100%'}"
            [styleClass]="'margin-3 ' + getDeliveryPointOrZonesValidationErrorStyleClass(i, 'deliveryPointOrZone' )"
            [group]="true"></p-dropdown>
        </div>
        <span *ngIf="getDeliveryPointOrZonesValidationErrorStyleClass(i, 'deliveryPointOrZone' )"
          class="fa fa-info-circle absolute error-marker"></span>
      </div>
    </div>
    <div class="row gutter-0 !me-[40px]">
      <div class="col-5 label"></div>
      <div class="col-7 ps-2 pe-1">
        <button type="button" class="btn add-remove-line" (click)="add('')" [disabled]="disableAddButton">Add</button>
        <button type="button" class="btn add-remove-line" (click)="remove()"
          [disabled]="disableRemoveButton">Remove</button>
      </div>
    </div>
    <div class="row gutter-0 !me-[40px]">
      <div class="col-5 label">Load Type</div>
      <div id="remit-trade-entry-table2-loadType" class="col-7 ps-2 pe-1">
        <p-dropdown formControlName="loadType" [options]="loadTypes" [group]="true" [style]="{'width': '100%'}"
          [styleClass]="'margin-3 ' + getValidationErrorStyleClass('loadType')" ></p-dropdown>
      </div>
      <span *ngIf="formGroup.controls['loadType'].hasError('error')"
        class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0 !me-[40px]">
      <div class="col-5 label">Settlement Method <span class="text-required">*</span></div>
      <div id="remit-trade-entry-table2-settlementMethod" class="col-7 ps-2 pe-1">
        <p-dropdown [options]="settlementMethods" formControlName="settlementMethod" [style]="{'width': '100%'}"
          [styleClass]="'margin-3 ' + getValidationErrorStyleClass('settlementMethod')" >
        </p-dropdown>
      </div>
      <span *ngIf="formGroup.controls['settlementMethod'].hasError('error')"
        class="fa fa-info-circle absolute error-marker"></span>
    </div>
  </div>
</div>
