import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Observer, of, Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { StandingInstructionsService } from '../standing-instructions.service';
import { select, Store } from '@ngrx/store';
import { map, takeUntil } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import {
  getStandingInstructionsState,
  selectUploadAllowed,
  selectUploadPanelVisible
} from './store/standing-instructions-upload.selectors';
import { GetPresetValues } from './store/standing-instructions-upload.actions';
import { StandingInstructionsUploadState } from './store/standing-instructions-upload.reducer';

@Component({
  selector: 'cms-standing-instructions-upload',
  templateUrl: './standing-instructions-upload.component.html',
  styleUrls: ['./standing-instructions-upload.component.scss']
})
export class StandingInstructionsUploadComponent implements OnInit, OnDestroy {

  @ViewChild('fileUpload', { static: true })
  fileUpload: ElementRef;

  visible$: Observable<boolean>;
  uploadEnabled$: Observable<boolean>;
  private onDestroy$ = new Subject();
  public uploadForm: UntypedFormGroup;
  csvFile: File;
  fileName: string = '';
  fileSelected: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private standingInstructionsService: StandingInstructionsService,
    private store: Store<StandingInstructionsUploadState>
  ) {
    this.uploadForm = formBuilder.group({
      csvFile: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.visible$ = this.store.pipe(select(selectUploadPanelVisible));

    this.uploadEnabled$ = this.store.pipe(select(selectUploadAllowed));

    this.store.dispatch(new GetPresetValues());

    this.standingInstructionsService.onRefresh().pipe(takeUntil(this.onDestroy$)).subscribe(refresh => {
      if (refresh) {
        this.uploadForm.reset();
        this.fileSelected = false;
        this.csvFile = null;
        this.fileName = null;
      }
    });
  }

  onSelect(event): void {
    const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    this.standingInstructionsService.setMessages(null);
    this.fileSelected = this.standingInstructionsService.isValidFile(files[0]);
    if (this.fileSelected) {
      this.csvFile = files[0];
      this.fileName = this.csvFile.name;
    }
  }

  ngOnDestroy(): void {
    this.uploadForm.reset();
    this.fileSelected = false;
    this.csvFile = null;
    this.fileName = null;
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  submit() {
    this.standingInstructionsService.sendFile(this.csvFile);
  }

  // downloadInstructions() {
  //   this.standingInstructionsService.downloadInstructions().then(data => {
  //     FileSaver.saveAs(data.blob, data.filename);
  //   });
  //
  //   return false;
  // }

  public download(): void {
    this.standingInstructionsService.download()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(data => {
        if ('blob' in data && 'filename' in data) {
          FileSaver.saveAs(data.blob, data.filename);
        }
      });
  }

  browseFiles() {
    this.reset();
    document.getElementById('standing-instructions-upload-button').click();

    // prevents page reload
    return false;
  }

  private reset() {
    if (this.fileUpload) {
      this.fileUpload.nativeElement.value = null;
    }
    this.csvFile = null;
    this.fileName = '';
    this.fileSelected = false;
    this.standingInstructionsService.setMessages(null);
  }

}
