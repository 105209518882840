import { EsmDashboardTableType, EsmStatesEnum, esmStatesToLabelMaps } from '../../invoice/dashboard/esm-dashboard.model';
import { EsmDashboardTableState, tableInitialState, tableReducer } from './table/table.reducer';
import { EsmDashboardFiltersState, filtersInitialState, filtersReducer } from './filters/filters.reducers';
import { DashboardDealsNavModel } from '@common/dashboard-deals-nav/dashboard-deals-nav.model';
import * as fromActions from './dashboard.actions';
import * as fromCommon from '@common/state/reducers';
import { INVOICE_FINDER_DIALOGS } from '../../invoice/finder/invoice-finder.model';
import { esmInvoiceFinderDialogReducer } from '../invoice-finder/dialog/dialog.reducers';
import { Message } from 'primeng/api';

export interface EsmDashboardState {
  filters: EsmDashboardFiltersState;
  table: EsmDashboardTableState;
  viewType: EsmDashboardTableType;
  state: EsmStatesEnum | string;
  stateNames: DashboardDealsNavModel[];
  dialog: fromCommon.DialogState<INVOICE_FINDER_DIALOGS>;
  messges:Message[];
}

export const initialState: EsmDashboardState = {
  filters: filtersInitialState,
  table: tableInitialState,
  viewType: EsmDashboardTableType.BUCKETS,
  state: null,
  stateNames: [],
  dialog: null,
  messges:[]
};

export function esmDashboardReducer(
  state: EsmDashboardState = { ...initialState },
  action: fromActions.DashboardActions
): EsmDashboardState {
  switch (action.type) {
    case (fromActions.setMessageAction.type):
      return {
        ...state,
        messges: action.payload
      };
      case (fromActions.setViewTypeAction.type):
      return {
        ...state,
        viewType: action.payload
      };
    case (fromActions.setStateAction.type):
      return {
        ...state,
        state: action.payload
      };
    case (fromActions.setStateNamesAction.type): {
      const data = action.payload || [];
      return {
        ...state,
        stateNames: data.map(item => (
          {
            state: item,
            title: esmStatesToLabelMaps[item],
          }
        ))
      };
    }
    case (fromActions.getStateNamesAction.type):
      return { ...state };
    case (fromActions.setToDefaultAction.type):
      return { ...initialState };
    default:
      return {
        ...state,
        table: tableReducer(state.table, action),
        filters: filtersReducer(state.filters, action),
        dialog: esmInvoiceFinderDialogReducer(state.dialog, action),
      };
  }
}

