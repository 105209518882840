import { Action } from '@ngrx/store';
import { Message } from 'primeng/api';
import { Prefix } from '../reducers';
import { DialogState } from './dialog.reducer';

export const OPEN_DIALOG = 'Open Dialog';
export const CLOSE_DIALOG = 'Close Dialog';
export const UPDATE_DIALOG = 'Update Dialog';

export class UpdateDialogAction implements Action {
  get type(): string {
    return `${this.prefix} ${UPDATE_DIALOG}`;
  }

  constructor(public prefix: Prefix,
              public payload: Message[]) {
  }
}

export class OpenDialogAction implements Action {
  get type(): string {
    return `${this.prefix} ${OPEN_DIALOG}`;
  }

  constructor(public prefix: Prefix,
              public payload: DialogState) {
  }
}

export class CloseDialogAction implements Action {
  get type(): string {
    return `${this.prefix} ${CLOSE_DIALOG}`;
  }

  constructor(public prefix: Prefix) {
  }
}
