import * as fromOrgGroups from './organisation-groups.actions';
import { SET_TO_DEFAULT } from '../datatable/datatable.actions';

export function orgGroupsReducer(state, action) {
  switch (action.type) {
    case fromOrgGroups.LOAD_ORGANISATION_GROUPS_SUCCESS:
      const { values } = (action as fromOrgGroups.LoadOrganisationGroupsSuccessAction).payload;
      return values;
    case `[ORG GROUPS CONTACTS] ${SET_TO_DEFAULT}`:
      return [];
    default:
      return state;
  }
}
