<p-dialog 
  *ngIf="display && data" 
  [header]="header" 
  [visible]="display" 
  [modal]="true" 
  (visibleChange)="onCancel()"
  [style]="{width: '800px'}">
  <section class="padding-3" [hidden]="!data">
    <p><span>Queue Name: </span>{{ data.queueName }}</p>
    <p><span>Message ID: </span>{{ data.messageId }}</p>
    <p><span>Queued: </span>{{ data.queued | dateFormatter }}</p>
    <p><span>Last Retry: </span>{{ data.lastRetry | dateFormatter }}</p>
    <p><span>Producer: </span>{{ data.producer }}</p>
    <p class="xml-container">
      <?xml version="1.0" encoding="UTF-8"?>
      {{data.payload }}
    </p>
    <p class="paddinng-top-9">{{ data.rootCause }}</p>
    <pre [innerHTML]="data.stacktrace"></pre>
  </section>
</p-dialog>
