import { createAction, union } from '@ngrx/store';
import { Message } from 'primeng/api';
import { BillingReportsParameters } from '../../administration-tab-panel/billing-reports/billing-reports.model';

export const validateBillingReportsAction = createAction('[BILLING REPORTS] Validate Billing Reports', (payload: BillingReportsParameters) => ({payload}));
export const validationFailedAction = createAction('[BILLING REPORTS] Validation Failed', (payload: string) => ({payload}));
export const downloadBillingReportAction = createAction('[BILLING REPORTS] Download Billing Report', (payload: BillingReportsParameters) => ({payload}));
export const downloadBillingReportSuccessAction = createAction('[BILLING REPORTS] Download Billing Report Success', (payload: string) => ({payload}));
export const setMessagesAction = createAction('[BILLING REPORTS] Set Messages', (payload: Message[]) => ({payload}));
export const setToDefaultAction = createAction('[BILLING REPORTS] Set To Default');
export const setOrganisationIdAction = createAction('[BILLING REPORTS] Set Organisation Id', (payload: number) => ({payload}));
export const getDateRangeAction = createAction('[BILLING REPORTS] Get Date Range');
export const setDateRangeAction = createAction('[BILLING REPORTS] Set Date Range', (payload: {start: Date, end: Date}) => ({payload}));

const actions = union({
  validateBillingReportsAction,
  validationFailedAction,
  downloadBillingReportAction,
  downloadBillingReportSuccessAction,
  setMessagesAction,
  getDateRangeAction,
  setDateRangeAction,
  setToDefaultAction,
  setOrganisationIdAction
});

export type BillingReportsActions = typeof actions;
