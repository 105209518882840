import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import {
  AddBankDetailValue,
  dataTableColumns,
  EsmBankDetails,
  EsmFormValues,
  EsmMessagesModel,
  EsmVatIds
} from '../../master-data.model';
import { EsmInternalMasterDataState } from '../../../state/master-data/internal-master-data/internal-master-data.reducer';
import { select, Store } from '@ngrx/store';
import * as fromMDSelectors from '../../../state/master-data/internal-master-data/internal-master-data.selectors';
import * as fromMDActions from '../../../state/master-data/internal-master-data/internal-master-data.actions';
import { DialogState } from '@common/state/reducers';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { DatatableState } from '@common/datatable/datatable.model';
import { EditableCell } from '@common/datatable/cell-editor/cell-editor.model';
import { Column } from '@common/shared/datatable.model';
import { EsmInternalMasterDataPresetValues } from '../internal-master-data.model';
import { ESM_MD_DIALOGS } from 'app/esm/state/master-data/master-data.reducer';

@Component({
  selector: 'cms-master-data-tab',
  templateUrl: './master-data-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MasterDataTabComponent implements OnInit, OnDestroy {
  presetValues$: Observable<EsmInternalMasterDataPresetValues> = this.store.pipe(select(fromMDSelectors.getEsmPresetValues));
  messages$: Observable<EsmMessagesModel> = this.store.pipe(select(fromMDSelectors.getMessages));
  formValues$: Observable<EsmFormValues> = this.store.pipe(select(fromMDSelectors.getEsmFormValues));
  isFormDisable$: Observable<boolean> = this.store.pipe(select(fromMDSelectors.getIsFormDisable));
  isNewMDRecord$: Observable<boolean> = this.store.pipe(select(fromMDSelectors.getIsNewMDRecord));
  dialog$: Observable<DialogState<ESM_MD_DIALOGS>> = this.store.pipe(select(fromMDSelectors.getDialog));
  selectedVatId$: Observable<EsmVatIds> = this.store.pipe(select(fromMDSelectors.getSelectedVatId));
  bankData$: Observable<DatatableData<EsmBankDetails[]>> = this.store.pipe(select(fromMDSelectors.getData));
  bankDetailValue$: Observable<AddBankDetailValue> = this.store.pipe(select(fromMDSelectors.getBankDetailValue));
  loading$: Observable<boolean> = this.store.pipe(select(fromMDSelectors.getLoading));
  sorting$: Observable<SortingFilter> = this.store.pipe(select(fromMDSelectors.getSorting));
  paging$: Observable<PagingFilter> = this.store.pipe(select(fromMDSelectors.getPaging));
  subscription$: Subscription = new Subscription();
  dataTableColumns: Column[];

  constructor(private store: Store<EsmInternalMasterDataState>) {
  }

  ngOnInit(): void {

    this.subscription$.add(this.presetValues$.subscribe(value => {
      this.dataTableColumns = value && value.editButtonsVisible ?
        dataTableColumns : dataTableColumns.filter(item => item.type !== 'deleteButton');
    }));
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

  onSave(value: EsmFormValues): void {
    if (value) {
      if (value.addressDetails.country) {
        value.addressDetails.country = value.addressDetails.country.toUpperCase();
      }
      if (value.otherAddressDetails.country) {
        value.addressDetails.country = value.addressDetails.country.toUpperCase();
      }
      if (value.companyRegistry.country) {
        value.companyRegistry.country = value.companyRegistry.country.toUpperCase();
      }

      value.version != null ? this.store.dispatch(fromMDActions.updateAction(value)) : this.store.dispatch(fromMDActions.persistAction(value));
    }
  }

  onCancel(value: EsmFormValues): void {
    this.store.dispatch(fromMDActions.cancelAction(value));
  }

  onMasterDataDelete(): void {
    this.store.dispatch(fromMDActions.openDialogAction({
      name: 'DELETE'
    }));
  }

  onLazyLoad(state: DatatableState): void {
    this.store.dispatch(fromMDActions.sortAndPageDataAction({
      paging: {
        page: state.page,
        entriesPerPage: state.perPage
      },
      sorting: {
        columnName: state.sortField,
        sortOrder: state.sortOrder
      }
    }));
  }

  onAddBankDetail(data: AddBankDetailValue): void {
    this.store.dispatch(fromMDActions.setBankDetailValueAction(data));
    this.store.dispatch(fromMDActions.addBankDetailAction(data));
  }

  onClearAddBankDetail(): void {
    this.store.dispatch(fromMDActions.setBankDetailValueAction(null));
  }

  onDeleteBankDetail(data: EsmBankDetails): void {
    this.store.dispatch(fromMDActions.deleteBankDetail(data));
  }

  onCompleteBDEdit(editingCell: EditableCell): void {
    if (!editingCell) {
      return;
    }
    const { newValue, data: { bankDetailsId, version }, column: { name } } = editingCell;
    this.store.dispatch(fromMDActions.updateBankDetail({ newValue, bankDetailsId, name, version }));
  }

  onCancelBDEdit(): void {
    this.store.dispatch(fromMDActions.setMessagesAction({ bankDetails: [] }));
  }

  onCancelDelete(): void {
    this.store.dispatch(fromMDActions.closeDialogAction());
  }

  onConfirmDelete(): void {
    this.store.dispatch(fromMDActions.deleteMDRecordAction());
  }
}
