import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { Message } from 'primeng/api';
import { EcmPresetValuesResponse, EditEcm, Organisation } from '../../organisations.model';
import { MessagesMapperService } from '../../../../../common/messages-mapper.service';

@Component({
  selector: 'cms-edit-ecm-dialog',
  templateUrl: './edit-ecm-dialog.component.html',
  styleUrls: ['./edit-ecm-dialog.component.scss']
})
export class EditEcmDialogComponent implements OnInit, OnChanges {
  @Input() data: Organisation;
  @Input() presetValues: EcmPresetValuesResponse;
  @Input() display = false;
  @Input() messages: Message[];
  @Output() displayChange = new EventEmitter<boolean>();
  @Output() confirm = new EventEmitter<EditEcm>();
  @Output() cancel = new EventEmitter<Message[]>();
  errors: {[key: string]: ValidationErrors} = {};
  form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder,
              private mapper: MessagesMapperService) {
  }

  onCancel(): void {
    this.hide();
    this.cancel.emit(this.mapper.toWarningMessages(`Editing of organisation '${this.data.displayName}' was cancelled.`));
  }

  onVisibleChange(visible: boolean): void {
    if (!visible) {
      this.onCancel();
    }
  }

  onConfirm(): void {
    const out: EditEcm = {
      ...this.form.value,
      selectedOrganisationId: this.data.orgId
    };

    this.confirm.emit(out);
  }

  ngOnInit(): void {
    const {
      partnerId = null,
      processRoles = null
    } = this.presetValues;
    this.form = this.formBuilder.group({
      partnerId,
      processRole: processRoles ? processRoles.selectedOption : null
    });
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (this.form) {
      if (changes.display && !this.display) {
        this.hide();
      }
      if (changes.data) {
        const {
          partnerId,
          processRoles
        } = this.presetValues;
        this.form.patchValue({
          partnerId,
          processRole: processRoles ? processRoles.selectedOption : null
        });
        this.form.updateValueAndValidity();
      }
    }

    if (changes.messages) {
      this.errors = {};
      if (this.messages) {
        this.messages.forEach((obj: Message) => {
          const { key: controlName } = obj;
          this.errors[controlName] = { 'server': true };
        });
      }
    }
  }

  private hide(): void {
    this.messages = [];
    this.display = false;
    this.displayChange.emit(this.display);
    this.form.reset();
    this.form.updateValueAndValidity();
  }
}
