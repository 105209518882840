<div class="panel-box margin-0">
  <cms-headline [pageTitle]="'Products matched by'"
                [displayOrganisationNameWithoutSeparator]="true"
                [displayOrganisationEicCode]="true"></cms-headline>
  <div class="row">
    <p-messages [closable]="true" class="col-12" [value]="messages$ | async"></p-messages>
    <cms-info ngPreserveWhitespaces>
      __eCM Gatekeeper__

      The below settings control which confirmation types the CMS accepts for matching with your organisation.

      **Instructions**

      * **Activate / block product** (master operators only): Click on the pass/block icon to toggle your matching
      preference for that product.
      * **View partners' preferences:** Click on "Download for all eCM Parties" to learn which CMS party has implemented
      which products for matching.
      * **Request product:** Wish to go live on a product not listed here? Please contact Support to have it added.

      **Rules**

      * All products are blocked by default. Participants have to opt in.
      * The gatekeeper is only available to parties on the CMS and not to peer to peer parties.
      * You cannot block products for specific partners. Your preferences apply to all partners.
      * If you block a product, neither you nor your partners can submit a confirmation for that product - it will get
      rejected ("invalid match attempt").
      * Reversely your confirmation is rejected if your matching partner blocks.
      * Exception: counterparty matching will still be performed if only the broker to a trilateral deal vetoes.
      * Note on financials: formula swaps will show "[Formula]" as commodity, cross-commodity swaps show
      "[X-Commodity]".
      * Note to eCM lite users: "N/A" denotes products that are not available for matching with eCM lite yet.

    </cms-info>
    <div class="btn-container margin-bottom-12">
      <button pButton label="Download for all eCM Parties" icon="fa fa-download" type="button" class="py-1 px-2" (click)="onDownloadProducts()"></button>
    </div>
    <cms-datatable selectionMode="single"
                   *ngIf="(data$ | async)?.products"
                   [autoLayout]="true"
                   [scrollable]="false"
                   [paginator]="false"
                   [tooltipDisabled]="true"
                   [tableColumns]="allColumnsConfig"
                   [data]="(data$ | async)?.products">
      <ng-template pTemplate="body" let-row="row" let-column="column" let-rowIndex="rowIndex">
        <td *ngIf="column.type === 'status'" [ngStyle]="column.style">
          <cms-cell-status [column]="column"
                           [row]="row"
                           emptyTemplate="N/A"
                           [editable]="(data$ | async)?.editable"
                           (confirm)="onChangeStatus(row)">
          </cms-cell-status>
        </td>
      </ng-template>
    </cms-datatable>
  </div>
  <cms-products-dialog [displayDialog]="!!(dialog$ | async)"
                       [transactionType]="transactionType"
                       [commodity]="commodity"
                       [market]="market"
                       [messages]="(dialog$ | async)?.messages"
                       [value]="(dialog$ | async)?.data"
                       (cancel)="onCancelButton()"
                       (activateItem)="onActivateButton(productId)"
                       (blockItem)="onBlockItem(productId)">
  </cms-products-dialog>
</div>
