import { DateRangeFilter, NumberRange } from '../../common/shared/shared.model';
import { PagingFilter, SortingFilter } from '../../common/shared/results.model';
import { SmtDealFinderDataTableConfig } from './smt-datatable.config';
import { StringUtils } from '../../common/shared/string.utils';

export enum SmtRegimeFilter {
  REMIT = 'REMIT',
  ELCOM = 'ELCOM',
  EMIR = 'EMIR'
}

export enum SmtReportingStatusFilter {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING'
}

export function smtReportingStatusFilterAsString(statusFilter: SmtReportingStatusFilter) {
  switch (statusFilter) {
    case SmtReportingStatusFilter.ACCEPTED:
      return 'Accepted';
    case SmtReportingStatusFilter.REJECTED:
      return 'Rejected';
    case SmtReportingStatusFilter.PENDING:
      return 'Pending';
    default:
      return 'All';
  }
}

export enum SmtTransactionDay {
  TODAY = 'TODAY',
  DAYS_AGO_1_7 = 'DAYS_AGO_1_7',
  DAYS_AGO_8_30 = 'DAYS_AGO_8_30',
  DAYS_AGO_31_60 = 'DAYS_AGO_31_60',
  DAYS_AGO_0_60 = 'DAYS_AGO_0_60'
}

export function smtTransactionDayAsString(transactionDay: SmtTransactionDay) {
  switch (transactionDay) {
    case SmtTransactionDay.TODAY:
      return 'Today';
    case SmtTransactionDay.DAYS_AGO_1_7:
      return '1-7 days ago';
    case SmtTransactionDay.DAYS_AGO_8_30:
      return '8-30 days ago';
    case SmtTransactionDay.DAYS_AGO_31_60:
      return '31-60 days ago';
    case SmtTransactionDay.DAYS_AGO_0_60:
    default:
      return 'All';
  }
}

export function smtTransactionDayAsRange(transactionDay: SmtTransactionDay): NumberRange {
  switch (transactionDay) {
    case SmtTransactionDay.TODAY:
      return { start: 0, end: 0 };
    case SmtTransactionDay.DAYS_AGO_1_7:
      return { start: 1, end: 7 };
    case SmtTransactionDay.DAYS_AGO_8_30:
      return { start: 8, end: 30 };
    case SmtTransactionDay.DAYS_AGO_31_60:
      return { start: 31, end: 60 };
    case SmtTransactionDay.DAYS_AGO_0_60:
      return { start: 0, end: 60 };
    default:
      return;
  }
}

export type SmtUtiFilter = string[];
export type SmtReporterIdFilter = number;
export type SmtTransactionDateFilter = DateRangeFilter;

export interface StmTransactionDashboardFilterParameters {
  regime: SmtRegimeFilter;
  status: SmtReportingStatusFilter;
  reporter: string;
  reporterId: SmtReporterIdFilter;
  day: SmtTransactionDay;
}

export interface SmtTransactionsSearchRequestModel {
  regime?: SmtRegimeFilter;
  reporterId?: SmtReporterIdFilter;
  status?: SmtReportingStatusFilter;
  transactionDates?: SmtTransactionDateFilter;
  utis?: SmtUtiFilter;
  sorting: SortingFilter;
  paging: PagingFilter;
}

export class StmTransactionSearchQueryBuilder {

  public static isNotEmptySearchQuery(query: SmtTransactionsSearchRequestModel): boolean {

    let notEmpty = false;

    if(query != null){
      const emptyQuery = {
        regime: query.regime,
        reporterId: query.reporterId,
        status: query.status,
        transactionDates: query.transactionDates,
        utis: query.utis
      };

      const referenceQuery = StmTransactionSearchQueryBuilder.createEmptySearchQuery();
      delete referenceQuery.paging;
      delete referenceQuery.sorting;

      notEmpty = JSON.stringify(emptyQuery) !== JSON.stringify(referenceQuery);
    }

    return notEmpty;
  }

  public static createEmptySearchQuery(): SmtTransactionsSearchRequestModel {
    return {
      regime: undefined,
      reporterId: undefined,
      status: undefined,
      transactionDates: undefined,
      utis: undefined,
      paging: {
        entriesPerPage: 25,
        page: 0
      },
      sorting: {
        columnName: StringUtils.camelCaseToSnakeUpperCase(SmtDealFinderDataTableConfig.getDefaultSortingColumn()),
        sortOrder: 'DESCENDING',
      }
    };
  }
}
