import * as fromCommon from '@common/state/reducers';
import { SET_ORGANISATION_ID } from './organisation-id.actions';
import { SET_TO_DEFAULT } from '../datatable/datatable.actions';

export const organisationIdReducer = fromCommon.organisationIdReducer(
  {
    SET: SET_ORGANISATION_ID,
    RESET: `[ORG GROUPS CONTACTS] ${SET_TO_DEFAULT}`
  }
);
