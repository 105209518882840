<div>
  <p-table [value]="buckets$ | async"
          [scrollable]="false"
          [loading]="loading"
          selectionMode="single"
          [(selection)]="selection"
          scrollHeight="520px">
    <ng-template pTemplate="header">
      <tr>
        <ng-container *ngFor="let col of columns">
          <th>
            {{col.title}}
          </th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
      <tr [pSelectableRow]="row">
        <ng-container *ngFor="let col of columns">
          <td [ngStyle]="col.style">
            <span class="truncated-text">
              <a routerLink="/err/deals" [queryParams]="getQueryParams(row, col.name)">{{row[col.name]}}</a>
            </span>
          </td>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>
</div>
