<p-dialog *ngIf="displayDialog"
          [resizable]="false"
          [(visible)]="displayDialog"
          [modal]="true"
          [style]="{width: '500px', minHeight: '225px'}"
          (visibleChange)="onVisibleChange($event)"
          (onHide)="onCancel()">
  <p-header>
    <span>{{action}} Invoice Type: {{data.type}}/{{data.commodity}}/{{data.priceType}}/{{data.natureOfPrice}}</span>
  </p-header>
  <p-messages [closable]="false" class="col-12" [value]="messages" *ngIf="messages"></p-messages>
  <div class="warning" *ngIf="isActivated">
    <div class="warning__id-container">
      <i class="fa fa-exclamation-triangle"></i>
      <span>
        <b>Confirm you wish to stop matching this invoice type in eSM.</b><br>
        The eSM Gatekeeper will reject any invoices matching this type from you and your counterparties.
      </span>
    </div>
  </div>
  <div class="warning" *ngIf="!isActivated">
    <div class="warning__id-container warning__id-container--info">
      <i class="fa fa-info-circle"></i>
      <span>
        <b>Confirm you wish to activate this invoice type for matching.</b><br>
        The eSM Gatekeeper will now accept confirmations for this product from you and your matching partners.
      </span>
    </div>
  </div>
  <p-footer>
    <button pButton
            type="button"
            class="btn"
            (click)="onCancel()"
            label="Cancel"
            id="esm-settings-dialog-cancel">
    </button>
    <button pButton
            type="button"
            class="btn"
            (click)="onConfirm()"
            label="Yes - {{ action }}"
            id="esm-settings-dialog-confirm">
    </button>
  </p-footer>
</p-dialog>
