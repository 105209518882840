import * as fromCommon from '@common/state/reducers';
import { Sender } from '@common/deals/deals.model';
import * as fromMyFiltersActions from './my-filter.actions';
import * as fromNettingFinderActions from '../esm-netting-finder.actions';
import { EsmNettingStatementUserFilter } from '../../../../netting/finder/esm-netting-finder.model';

export type EsmNettingStatementFinderMyFiltersState = fromCommon.MyFiltersState<EsmNettingStatementUserFilter>;

export const myFilterInitialState: EsmNettingStatementFinderMyFiltersState = {
  defaultFilter: null,
  filterSelections: null,
  allDealsFilter: {
    defaultFilter: true,
    filterName: 'All Statements',
    counterpartyFilter: [],
    commodityFilter: [],
    deliveryPointFilter: [],
    nettingStatementTypeFilter: [],
    hiddenType: Sender.US
  }
};

export const esmNettingStatementFinderMyFiltersReducer = fromCommon.myFiltersReducer<EsmNettingStatementUserFilter>({
  SET_TO_DEFAULT: fromNettingFinderActions.setDefaultStateAction.type,
  RELOAD_MY_FILTER_SUCCESS: fromMyFiltersActions.reloadMyFilterSuccessAction.type,
  LOAD_MY_FILTERS_SUCCESS: fromMyFiltersActions.onUserFilterLoadedAction.type,
  DELETE_MY_FILTER_SUCCESS: fromMyFiltersActions.deleteMyFilterSuccessAction.type,
  SET_ALL_DEALS: fromMyFiltersActions.setAllDealsFilterAction.type,
}, myFilterInitialState);
