<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>

<p-tabView [activeIndex]="activeIndex" (onChange)="onChangeTab($event)" *ngIf="tabs">
  <p-tabPanel [header]="tabs[0].header">
    <div class="tab-view-body">
      <div class="tab-view-body">
        <cms-esm-netting-dashboard-tab [category]="tabs[activeIndex].route" *ngIf="activeIndex === 0"></cms-esm-netting-dashboard-tab>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel [header]="tabs[1].header">
    <div class="tab-view-body">
      <div class="tab-view-body">
        <cms-esm-netting-dashboard-tab [category]="tabs[activeIndex].route" *ngIf="activeIndex === 1"></cms-esm-netting-dashboard-tab>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel [header]="tabs[2].header">
    <div class="tab-view-body">
      <div class="tab-view-body">
        <cms-esm-netting-dashboard-tab [category]="tabs[activeIndex].route"  *ngIf="activeIndex === 2"></cms-esm-netting-dashboard-tab>
      </div>
    </div>
  </p-tabPanel>
</p-tabView>

</ng-template>
