import { FilterMultiselectComponent } from './dashboard/filter/filter-multiselect.component';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CpmlDealFinderComponent } from './dealfinder/cpml-dealfinder.component';
import { CpmlDealFinderFilterComponent } from './dealfinder/filter/cpml-dealfinder-filter.component';
import { CmsCommonModule } from '../../common/cms-common.module';
import { CpmlDealFinderIdFilterComponent } from './dealfinder/filter/cpml-dealfinder-idfilter.component';
import { RouterModule } from '@angular/router';
import { CpmlDealsService } from './cpml-deals.service';
import { CpmlDashboardService } from './dashboard/cpml-dashboard.service';
import { CpmlDataTableComponent } from './datatable/cpml-datatable.component';
import { CpmlDealFinderDocTypeFilterComponent } from './dealfinder/filter/cpml-dealfinder-doctypefilter.component';
import { CpmlDashboardComponent } from './dashboard/cpml-dashboard.component';
import { CpmlDashboardPanelComponent } from './dashboard/cpml-dashboard-panel.component';
import { CpmlDashboardFilterComponent } from './dashboard/filter/cpml-dashboard-filter.component';
import { CpmlBucketComponent } from './dashboard/bucket/cpml-bucket.component';
import { CpmlDashboardViewByDateFilterComponent } from './dashboard/filter/cpml-dashboard-view-by-date-filter.component';
import { CpmlDealviewComponent } from './dashboard/cpml-dealview.component';
import { CpmlDealviewPanelComponent } from './dashboard/cpml-dealview-panel.component';
import { AudittrailModule } from '@common/audittrail/audittrail.module';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { TabViewModule } from 'primeng/tabview';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ButtonModule } from 'primeng/button';
import { MessagesModule } from 'primeng/messages';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CmsCommonModule,
    TableModule,
    MultiSelectModule,
    TabViewModule,
    ButtonModule,
    RadioButtonModule,
    AudittrailModule,
    TableModule,
    MessagesModule
  ],
  exports: [],
  declarations: [
    FilterMultiselectComponent,
    CpmlDealFinderComponent,
    CpmlDealFinderFilterComponent,
    CpmlDealFinderIdFilterComponent,
    CpmlDealFinderDocTypeFilterComponent,
    CpmlDataTableComponent,
    CpmlDashboardComponent,
    CpmlDashboardPanelComponent,
    CpmlDashboardFilterComponent,
    CpmlBucketComponent,
    CpmlDashboardViewByDateFilterComponent,
    CpmlDealviewComponent,
    CpmlDealviewPanelComponent
  ],
  providers: [
    CpmlDealsService,
    CpmlDashboardService,
  ]
})

export class CpmlModule {
}
