import { NumberRange, StartEndDate } from './shared.model';

export class DateUtils {

  private static readonly MS_PER_DAY = 1000 * 60 * 60 * 24;

  public static dateDiffInDays(a: Date, b: Date): number {
    // https://stackoverflow.com/questions/3224834/get-difference-between-2-dates-in-javascript/15289883#15289883
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / DateUtils.MS_PER_DAY);
  }

  public static calcDateRangeWithDaysAgo(rangeDaysAgo: NumberRange, dateOffset?: Date): StartEndDate {
    const offset = dateOffset || new Date();
    const fromDate = new Date(offset.getTime());
    fromDate.setDate(offset.getDate() - rangeDaysAgo.end);

    const toDate = new Date(offset.getTime());
    toDate.setDate(offset.getDate() - rangeDaysAgo.start);

    return { start: fromDate, end: toDate };
  }

  public static toISOStringWithIgnoreTimezoneOffset(date: Date): string {
    let result = null;

    if(date){
      const isoString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
      result = isoString.split('T')[0];
    }

    return result;
  }

  public static formatDate(date: Date, withTimestamp?: boolean): string {
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
    const year = date.getFullYear();
    const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    const seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();

    let formatted = day + '/' + month + '/' + year;
    if (withTimestamp) {
      formatted += ' ' + hour + ':' + minutes + ':' + seconds;
    }
    return formatted;
  }

  public static getDefaultYearRange(): string {
    return this.getYearRange(10, 10);
  }

  public static getYearRange(plus: number, minus: number): string {
    const current = new Date();

    const year = current.getFullYear();
    return (year - minus) + ':' + (year + plus);
  }

  public static transformDate(value, options?: TransformDateOptions): string {
    const { format, defaultValue } = options || { format: null, defaultValue: null };

    if (!value || value === '') {
      return defaultValue || '';
    }
    const date = new Date(value);
    return DateUtils.formatDate(date, format === 'timestamp');
  }

  public static transformDateWithTimestamp(value, options?: TransformDateOptions): string {
    return DateUtils.transformDate(value, { ...options, format: 'timestamp' });
  }

  public static formatToLocalDateTime(date: Date): string {

    function f(n) {
      // Format integers to have at least two digits.
      return n < 10 ? '0' + n : n;
    }

    return date.getFullYear() + '-' +
      f(date.getMonth() + 1) + '-' +
      f(date.getDate()) + 'T' +
      f(date.getHours()) + ':' +
      f(date.getMinutes()) + ':' +
      f(date.getSeconds());
  }

  public static getFormattedTime(date: Date): string {

    const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    const seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();

    return hour + ':' + minutes + ':' + seconds;
  }

  public static formatToISOIgnoreZone(date: Date): string {

    function f(n) {
      // Format integers to have at least two digits.
      return n < 10 ? '0' + n : n;
    }

    return date.getFullYear() + '-' +
      f(date.getMonth() + 1) + '-' +
      f(date.getDate()) + 'T' +
      f(date.getHours()) + ':' +
      f(date.getMinutes()) + ':' +
      f(date.getSeconds()) + '.' +
      f(date.getMilliseconds());
  }

  public static getLocalDateFormat(date: Date): string {

    function f(n) {
      // Format integers to have at least two digits.
      return n < 10 ? '0' + n : n;
    }

    const localDate = date ? date.getFullYear() + '-' + f(date.getMonth() + 1) + '-' + f(date.getDate()) : null;

    return localDate;
  }
}

export type TransformDateFormat = 'timestamp';

export interface TransformDateOptions {
  format?: TransformDateFormat;
  defaultValue?: string;
}


