import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ApiRequestService } from '@common/api-request.service';
import { MessagesMapperService } from '@common/messages-mapper.service';

import { Action, select, Store } from '@ngrx/store';
import { AcerBillingRunsState } from './acer-runs.reducers';
import { catchError, concatMap, debounceTime, switchMap, withLatestFrom } from 'rxjs/operators';
import * as AcerActions from './acer-runs.actions';
import * as AcerSelectors from './acer-runs.selectors';
import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import { Observable, of } from 'rxjs';
import { Message } from 'primeng/api';
import { HttpParams } from '@angular/common/http';
import { saveBlob } from '@common/cms-common.model';


@Injectable()
export class AcerBillingRunsEffects {
  private baseUrl = '/api/administration/acer/billingruns';

  constructor(private apiGateway: ApiRequestService,
    private actions$: Actions,
    private store$: Store<AcerBillingRunsState>,
    private mapper: MessagesMapperService) { }


  delete$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(AcerActions.deleteBillingRun),
        debounceTime(1000),
        concatMap(({ billingRunID}) => {
          this.store$.dispatch( AcerActions.loading({ load: {delete : true } }));

          let params = new HttpParams();
          params = params.set('acerBillingRunId', billingRunID);
          return this.apiGateway.delete(`${this.baseUrl}/delete`,params )
            .pipe(
              switchMap((response) => {
                const msg: Message[] = this.mapper.toErrorMessages(response);

                if (msg && msg.length) {
                    return [AcerActions.setDeleteMessages({ msg }), AcerActions.loading({ load: {delete : false } })];
                } else {
                  return [
                    AcerActions.loadTable(),
                    AcerActions.loadPresets(),
                    AcerActions.deleteBillingRunSuccess(),
                    AcerActions.setMessages({ msg: this.mapper.toSuccessMessages('Deleted.') }),
                    AcerActions.loading({ load: {delete : false } })
                  ];
                }

              }),
              catchError(error => [ AcerActions.loading({ load: {delete : false } }),
                AcerActions.setMessages({ msg: this.mapper.toErrorMessages(error || 'Server error') })])
            );
        })
      )
  );

  cancel$ = createEffect(() =>
    this.actions$
    .pipe(
      ofType(AcerActions.cancelBillingRun),
      debounceTime(1000),
      concatMap(({ billingRunID}) => {
        this.store$.dispatch( AcerActions.loading({ load: {delete : true } }));

        let params = new HttpParams();
        params = params.set('acerBillingRunId', billingRunID);
        return this.apiGateway.delete(`${this.baseUrl}/delete`,params )
        .pipe(
          switchMap((response) => {
            const msg: Message[] = this.mapper.toErrorMessages(response);

            if (msg && msg.length) {
                return [AcerActions.setMessages({msg}), AcerActions.loading({ load: {delete : false } })];
            } else {
              return [
                AcerActions.loadTable(),
                AcerActions.loadPresets(),
                AcerActions.deleteBillingRunSuccess(),
                AcerActions.setMessages({ msg: this.mapper.toSuccessMessages('Cancelled billing run.') }),
                AcerActions.loading({ load: {delete : false } })
              ];
            }

          }),
          catchError(error => [ AcerActions.loading({ load: {delete : false } }),
            AcerActions.setMessages({ msg: this.mapper.toErrorMessages(error || 'Server error') })])
        );
      })
    )
  );

  errors$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(AcerActions.getAcerBillingRunErrors),
        concatMap(({ billingRunID}) => {

          this.store$.dispatch(AcerActions.loading({ load: { errors: true } }));

          let params = new HttpParams();
          params = params.set('acerBillingRunId', billingRunID);

          return this.apiGateway.get(`${this.baseUrl}/errors`,params )
            .pipe(
              switchMap((response) => {
                const errors = response.values.map(i => ({ name: i, code: i }));

                return [AcerActions.getAcerBillingRunErrorsSuccess({ err: errors }), AcerActions.loading({ load: { errors: false } })];
              }),
              catchError(error => [AcerActions.setMessages({ msg: this.mapper.toErrorMessages(error || 'Server error') }), AcerActions.loading({ load: { errors: false } })])
            );
        })
      )
  );

  mailingRun$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(AcerActions.startMailingRun),
        debounceTime(1000),
        concatMap(({ billingRunID}) => {
          this.store$.dispatch( AcerActions.loading({ load: {send : true } }));

          let params = new HttpParams();
          params = params.set('acerBillingRunId', billingRunID);

          return this.apiGateway.post(`${this.baseUrl}/mailing`,params )
            .pipe(
              switchMap(() => [AcerActions.loading({ load: {send : false } }),AcerActions.loadTable(),AcerActions.setMessages({ msg: this.mapper.toSuccessMessages('Successfully started a mailing run') }) ]),
              catchError(error => [AcerActions.loading({ load: {send : false } }),AcerActions.setMessages({ msg: this.mapper.toErrorMessages(error || 'Server error')})])
            );
        })
      )
  );


  downloadZip$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AcerActions.downloadBillingRun),
      debounceTime(1000),
      switchMap(({ billingRunID }) => {

        let params = new HttpParams();
        params = params.set('acerBillingRunId', billingRunID);

        return this.apiGateway.getBlob(`${this.baseUrl}/zip`, null, params)
          .pipe(
            switchMap((response: any) => {
              saveBlob(response);
              return of(AcerActions.resetMessages());
            }),
            catchError(error => [AcerActions.setMessages({ msg: this.mapper.toErrorMessages(error, 'Server Error') })])
          );
      })
    ));

  billingRun$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(AcerActions.startBillingRun),
        debounceTime(1000),
        concatMap(({ invoiceStartNumber, isDraft  }) => {
          this.store$.dispatch(AcerActions.loading({ load: {run : true } }));

          return this.apiGateway.post(`${this.baseUrl}/startBillingRun`, { invoiceStartNumber, isDraft })
            .pipe(
              switchMap((response) => {

                if(response?.validationFailureMessages){
                  return [AcerActions.setMessages({ msg: this.mapper.toErrorMessages(response || 'Server error') }), AcerActions.loading({ load: {run : false } })];
                }

                return [
                  AcerActions.loading({ load: {run : false } }),
                  AcerActions.loadPresets(),
                  AcerActions.setMessages({ msg: this.mapper.toSuccessMessages(`An ACER billing run has been started. Please refresh the screen to view its current status.`) })];
              }),
              catchError(error => [AcerActions.loading({ load: {run : false } }),AcerActions.setMessages({ msg: this.mapper.toErrorMessages(error || 'Server error') })])
            );
        })
      )
  );

  continueBillingRun$ = createEffect(() =>
    this.actions$
    .pipe(
      ofType(AcerActions.continueBillingRun),
      debounceTime(1000),
      concatMap((billingRunID) => {
        this.store$.dispatch(AcerActions.loading({ load: {run : true } }));

        return this.apiGateway.post(`${this.baseUrl}/continueBillingRun`,  billingRunID.billingRunID )
        .pipe(
          switchMap((response) => {

            if(response?.validationFailureMessages){
              return [AcerActions.setMessages({ msg: this.mapper.toErrorMessages(response || 'Server error') }), AcerActions.loading({ load: {run : false } })];
            }

            return [
              AcerActions.loading({ load: {run : false } }),
              AcerActions.loadPresets(),
              AcerActions.setMessages({ msg: this.mapper.toSuccessMessages(`ACER billing run has been continued. Please refresh the screen to view its current status.`) })];
          }),
          catchError(error => [AcerActions.loading({ load: {run : false } }),AcerActions.setMessages({ msg: this.mapper.toErrorMessages(error || 'Server error') })])
        );
      })
    )
  );

  load_presets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AcerActions.loadPresets),
      switchMap(() =>
        this.apiGateway.get(`${this.baseUrl}/loadPresetValues`)
          .pipe(
            switchMap(response => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              const { billingRunAllowed } = response?.value;
              const result = [AcerActions.loadPresetsSuccess({ billingRunAllowed })];


              return messages?.length ?
                [AcerActions.setMessages({ msg: this.mapper.toErrorMessages(response || 'Server error') })] :
                [...result];
            }),
            catchError(error => [AcerActions.loading({ load: { run: false } }), AcerActions.setMessages({ msg: this.mapper.toErrorMessages(error || 'Server error') })])
          )
      )
    )
  );


  load_table$ = createEffect(() =>
    this.actions$.pipe(ofType(AcerActions.loadTable))
      .pipe(
        withLatestFrom(
          this.store$.pipe(select(AcerSelectors.getPaging)),
          this.store$.pipe(select(AcerSelectors.getSorting))),
        switchMap(([, paging, sorting]) => this.loadData(paging, sorting))));


  // ======  helper ====== //

  private loadData(paging: PagingFilter, sorting: SortingFilter): Observable<Action> {

    this.store$.dispatch(AcerActions.loading({ load: { table: true } }));

    return this.apiGateway.post(`${this.baseUrl}/data`, { sorting, paging })
      .pipe(
        switchMap(response => {
          const msg: Message[] = this.mapper.toErrorMessages(response);
          const { values, currentPage, count } = response;

          return msg?.length ?
            [AcerActions.setMessages({ msg }), AcerActions.loading({ load: { table: false}})]:
            [
              AcerActions.loadTableSuccess({ data: {values, count, currentPage } }),
              AcerActions.loading({ load: { table: false}})
            ];
        }),

        catchError(error => [AcerActions.loading({ load: { table: false}}),
          AcerActions.setMessages({ msg: this.mapper.toErrorMessagesObj(error || 'Server Error').messages })]));
  }



}
