import { Action } from '@ngrx/store';
import { Ecm_Dashboard_Categories } from '../../../ecm-dashboard/ecm-dashboard.model';

export const SET_CATEGORY = '[ECM DASHBOARD] Set Category';

export class SetCategoryAction implements Action {
  readonly type = SET_CATEGORY;
  constructor(public payload: Ecm_Dashboard_Categories) { }
}

export type EcmDashboardCategoryActions = SetCategoryAction;
