import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AllDealsMyFilterName, MY_FILTERS_DIALOGS, MyFilter } from '@common/my-filters/my-filters.model';
import { Message } from 'primeng/api';

@Component({
  selector: 'cms-filters-management-menu',
  templateUrl: './filters-management-menu.component.html',
  styleUrls: ['./filters-management-menu.component.scss'],
})
export class FiltersManagementMenuComponent implements OnInit, OnChanges {
  @Input() filters: MyFilter[] = [];
  @Input() disableSaveCurrent = true;
  @Input() dialog: {
    name: MY_FILTERS_DIALOGS;
    messages?: Message[];
  };
  @Input() defaultFilter: MyFilter;
  @Output() saveCurrent = new EventEmitter();
  @Output() manageFilters = new EventEmitter();
  @Output() select = new EventEmitter<MyFilter | null>();
  @Output() closeDialog = new EventEmitter();
  @Output() replaceFilter = new EventEmitter<MyFilter>();
  @Output() setDefault = new EventEmitter<MyFilter>();
  @Output() saveAs = new EventEmitter<MyFilter>();
  @Output() delete = new EventEmitter<string>();
  allItems = [];
  saveCurrentOption = {
    label: 'Save Current...',
    icon: 'fa fa-floppy-o',
    disabled: true,
    command: () => {
      this.saveCurrent.emit();
    }
  };
  manageOption = {
    label: 'Manage Filters...',
    icon: 'fa fa-wrench',
    disabled: true,
    command: () => {
      this.manageFilters.emit();
    }
  };

  constructor() {
    this.selectItem = this.selectItem.bind(this);
  }

  ngOnInit(): void {
    this.initItems();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filters) {
      this.initItems();
    }

    if (changes.disableSaveCurrent) {
      this.saveCurrentOption.disabled = this.disableSaveCurrent;
    }
  }

  private initItems(): void {
    this.manageOption.disabled = !(this.filters && this.filters.length);
    this.allItems = [
      {
        label: AllDealsMyFilterName,
        icon: 'pi',
        command: () => this.selectItem(null)
      },
      ...(this.filters ? this.filters.filter(item => item.defaultFilter).map(item => (
      {
        label: item.filterName,
        icon: 'fa fa-star',
        command: this.selectItem
      })) : []),
      { separator: true },
      ...(this.filters ? [
        ...this.filters.filter(item => !item.defaultFilter)
          .map(item => (
            {
              label: item.filterName,
              icon: 'pi',
              command: this.selectItem
            }))
          .sort((a, b) => a.label.localeCompare(b.label)),
        { separator: true },] : []),
      this.saveCurrentOption,
      this.manageOption
    ];
  }

  private selectItem(event: {item: {label}}): void {
    this.select.emit(event ? this.filters.find(item => item.filterName === event.item.label) : null);
  }
}
