<section class="panel-box cms-netting-dashboard-filters-container">
  <form class="form align-items-center" [formGroup]="form">
    <div class="row">
      <div class="col-12 col-md-4">
        <div>
          <label class="text-bold">Counterparty</label>
          <p-multiSelect selectedItemsLabel="All ({0})"
                         [maxSelectedLabels]="counterPartiesOptions.length - 1"
                         id="esm-invoice-filter-multiselect-cp"
                         placeholder="None"
                         [filter]="true"
                         id="esm-netting-dashboard-multiselect-cp"
                         [options]="counterPartiesOptions"
                         formControlName="counterParties">
          </p-multiSelect>
        </div>
        <div>
          <label class="text-bold">Delivery Point</label>
          <p-multiSelect selectedItemsLabel="All ({0})"
                         [maxSelectedLabels]="deliveryPointsOptions.length - 1"
                         id="esm-invoice-filter-multiselect-dp"
                         placeholder="None"
                         [filter]="false"
                         [options]="deliveryPointsOptions"
                         formControlName="deliveryPoints">
          </p-multiSelect>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div>
          <label class="text-bold">Commodity</label>
          <p-multiSelect selectedItemsLabel="All ({0})"
                         [maxSelectedLabels]="commoditiesOptions.length - 1"
                         id="esm-invoice-filter-multiselect-cmdt"
                         placeholder="None"
                         [filter]="false"
                         [options]="commoditiesOptions"
                         formControlName="commodities">
          </p-multiSelect>
        </div>
        <div>
          <label class="text-bold">Netting Statement Type</label>
          <p-multiSelect selectedItemsLabel="All ({0})"
                         [maxSelectedLabels]="nettingTypesOptions.length - 1"
                         id="esm-invoice-filter-multiselect-nt"
                         placeholder="None"
                         [filter]="false"
                         [options]="nettingTypesOptions"
                         formControlName="nettingStatementTypes">
          </p-multiSelect>
        </div>
      </div>
      <div class="col-12 col-md-12 col-xl-4">
        <div class="row position-relative">
          <label class="text-bold ps-0">Payment Date Range</label>
          <div class="col-12 col-md-5 ps-0">
            <p-calendar placeholder="From Date"
                        id="paymentDateStart"
                        formControlName="paymentDateStart"
                        showIcon="true"
                        monthNavigator="true"
                        yearNavigator="true"
                        dateFormat="dd/mm/yy"
                        cmsDateRestriction>
            </p-calendar>
          </div>
          <div class="col-12 col-md-5 date ps-0">
            <p-calendar placeholder="To Date"
                        id="paymentDateEnd"
                        formControlName="paymentDateEnd"
                        showIcon="true"
                        monthNavigator="true"
                        yearNavigator="true"
                        dateFormat="dd/mm/yy"
                        cmsDateRestriction>
            </p-calendar>
          </div>
          <span class="col-auto col-md-2 px-0">
          <cms-additional-date-range
            [form]="form"
            [startField]="'paymentDateStart'"
            [endField]="'paymentDateEnd'"
            [dateRanges]="dateRanges">
          </cms-additional-date-range>
            </span>
        </div>
        <div class="row calendar position-relative">
          <div class="col-12 col-xs-12 col-md-5 ps-0">
            <label class="text-bold">Invoice Period Start</label>
            <p-calendar id="invoiceStartDate"
                        formControlName="invoiceStartDate"
                        showIcon="true"
                        monthNavigator="true"
                        yearNavigator="true"
                        dateFormat="dd/mm/yy"
                        placeholder="From Date"
                        cmsDateRestriction>
            </p-calendar>
          </div>
          <div class="col-12 col-xs-12 col-md-5 ps-0">
            <label class="text-bold">Invoice Period End</label>
            <p-calendar id="invoicePeriodEndDate"
                        formControlName="invoiceEndDate"
                        showIcon="true"
                        monthNavigator="true"
                        placeholder="To Date"
                        yearNavigator="true"
                        dateFormat="dd/mm/yy"
                        cmsDateRestriction>
            </p-calendar>
          </div>
          <div class="col-2 col-xs-2 col-md-2 px-0" style="position: initial">
            <label class="text-bold  ">&nbsp;</label>
            <cms-additional-date-range
              [form]="form"
              [startField]="'invoiceStartDate'"
              [endField]="'invoiceEndDate'"
              [dateRanges]="dateRanges">
            </cms-additional-date-range>
          </div>
        </div>
      </div>
    </div>
    <div class="row align-items-baseline margin-top-12 btn__container justify-content-end">
      <div class="col-12 col-xs-6 col-md-6 btn__container text-end">
        <button class="btn margin-top-3 margin-bottom-3 margin-left-12" type="submit" (click)="onFilter()" [disabled]="hasErrors()">Search</button>
        <button class="btn margin-top-3 margin-bottom-3 margin-left-12 margin-right-12" type="button" (click)="onReset()">Reset</button>
      </div>
    </div>
    <div *ngIf="hasErrors()" class="col-12 col-md-12 col-xl-4 error-note">
      Select at least one element in marked select box.
    </div>
  </form>
</section>
