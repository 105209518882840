import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from '@common/menu/navigation.service';
import { Title } from '@angular/platform-browser';
import { ErrorMessageService } from '@common/errors/error-message.service';
import { distinct } from 'rxjs/operators';

@Component({
  selector: 'cms-billing-runs-main',
  templateUrl: './billing-runs.component.html',
  styleUrls: ['./billing-runs.component.scss']
})
export class BillingRunsComponent implements OnInit, OnDestroy {
  private routeSubscription$ = new Subscription();

  private tabs = ['cms', 'acer'];
  private titleMap = {
    'cms': 'CMS Billing Run',
    'acer': 'Acer Billing Run'
  };

  reportapp: boolean = true;
  activeIndex: number;
  messages = this.errorMessageService.on503Error().pipe(distinct());
  subscriptions$: Subscription = new Subscription();

  constructor(private router: Router,
              private route: ActivatedRoute,
              private navigationService: NavigationService,
              private changeDetectorRef: ChangeDetectorRef,
              private titleService: Title,
              public readonly errorMessageService: ErrorMessageService) {

    this.subscriptions$.add(this.messages.subscribe(() => this.reportapp = false));

  }

  ngOnInit(): void {
    this.routeSubscription$.add(this.route.data.subscribe(({ panel }: { panel: string }) => {
      if (panel) {
        this.activeIndex = this.tabs.indexOf(panel);
        this.titleService.setTitle(this.titleMap[panel]);
      } else {
        this.activeIndex = 0;
        this.titleService.setTitle(this.titleMap['cms']);
      }
    }));

  }

  ngOnDestroy(): void {
    this.routeSubscription$.unsubscribe();
    this.subscriptions$.unsubscribe();
  }

  onChangeTab({ index }: { index: number }): void {
    this.router.navigate([`admin/billing_runs/${this.tabs[index]}`]);
  }

}
