import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './index';

export const getSIState = createFeatureSelector<State>('standing-instructions');

export const getStandingInstructionsState = createSelector(
  getSIState,
  state => state.standingInstructionsUpload
);

export const selectUploadPanelVisible = createSelector(
  getStandingInstructionsState,
  state => state.uploadPanelVisible
);

export const selectUploadAllowed = createSelector(
  getStandingInstructionsState,
  state => state.uploadAllowed
);


