<div class="center" *ngIf="error.httpStatusCode===504; else errorPage500">
  <h1 style="font-size: 25px; line-height: 1.5;">
    Cannot establish connection to server, please try again in 10 minutes.<br/>
    We apologize for the inconvenience.</h1>
  <button pButton type="button" class="btn center-button" label="return" routerLink="/login"></button>
</div>

<ng-template #errorPage500>
<div class="margin-30">
  <p-panel id="account-box" header="Internal Error {{currentErrorNumber}} of {{errorCount}}">
    <div *ngIf="error">
      The request could not be processed successfully due to an internal error. Please contact support with the following information.
      <div class="errorDetailsPanel panel-box margin-top-9 margin-bottom-9">
        <table>
          <thead></thead>
          <tbody>
            <tr>
              <td class="errorPageLabel"><strong>Time of event:</strong></td>
              <td class="errorPageValue">{{error.timeOfEvent}}</td>
            </tr>
            <tr>
              <td class="errorPageLabel"><strong>User:</strong></td>
              <td class="errorPageValue">{{error.user}}</td>
            </tr>
            <tr>
              <td class="errorPageLabel"><strong>Selected Org:</strong></td>
              <td class="errorPageValue">{{error.selectedOrg}}</td>
            </tr>
            <tr>
              <td class="errorPageLabel"><strong>Page:</strong></td>
              <td class="errorPageValue">{{error.page}}</td>
            </tr>
            <tr>
              <td class="errorPageLabel"><strong>URL requested:</strong></td>
              <td class="errorPageValue">{{error.url}}</td>
            </tr>
            <tr>
              <td class="errorPageLabel"><strong>HTTP status code:</strong></td>
              <td class="errorPageValue">{{error.httpStatusCode}}</td>
            </tr>
            <tr>
              <td class="errorPageLabel"><strong>Reference code:</strong></td>
              <td class="errorPageValue">{{error.referenceCode}}</td>
            </tr>
            <tr>
              <td class="errorPageLabel"><strong>Error message:</strong></td>
              <td class="errorPageValue">{{error.errorMessage}}</td>
            </tr>
            <tr>
              <td class="errorPageLabel"><strong>Browser:</strong></td>
              <td class="errorPageValue">{{error.browser}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <button class="btn margin-right-6" id="nextErrorBtn" (click)="next()" *ngIf="showNextButton">Next error</button>
    <button class="btn margin-right-6" id="returnToHomePageBtn" (click)="returnToHomePage()">
      {{ isLoggedIn ? 'Return to home page' : 'Return to login page' }}
    </button>
    <button id="logoutBtn" class="btn" (click)="logout()" *ngIf="isLoggedIn">Logout</button>
  </p-panel>
</div>

</ng-template>
