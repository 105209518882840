import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { Message } from 'primeng/api';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { UserAccount } from '../../../user-accounts/user-accounts.model';
import { FhaAccount } from '../../../fha-accounts/fha-accounts.model';
import { userStates } from '../../editable-status-cell/editable-status-cell.component';

export interface EditUserAccount {
  contactOther: string;
  email: string;
  firstName: string;
  id: number;
  jobTitle: string;
  mobile: string;
  phone: string;
  secondName: string;
  tokenId?: string;
  userName: string;
  state?: userStates;
  realName?: string;
}

@Component({
  selector: 'cms-edit-user-account-dialog',
  templateUrl: './edit-user-account-dialog.component.html',
  styleUrls: ['./edit-user-account-dialog.component.scss']
})
export class EditUserAccountDialogComponent implements OnInit, OnChanges {
  @Input() data: UserAccount | FhaAccount;
  @Input() display = false;
  @Input() messages: Message[];
  @Input() tokenIdVisible: boolean;
  @Output() displayChange = new EventEmitter<boolean>();
  @Output() confirm = new EventEmitter<EditUserAccount>();
  @Output() cancel = new EventEmitter<Message[]>();
  errors: {[key: string]: ValidationErrors} = {};
  form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder,
              private mapper: MessagesMapperService) { }

  onCancel(): void {
    this.cancel.emit(this.mapper.toWarningMessages(`Editing of account '${this.data.userName}' was cancelled.`));
    this.hide();
  }

  onVisibleChange(visible: boolean): void {
    if (!visible) {
      this.onCancel();
    }
  }

  onConfirm(): void {
    const out: EditUserAccount = {
      ...this.form.value,
      id: this.data.id
    };

    this.confirm.emit(out);
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      ...(this.data || {})
    });
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (changes.data) {
      this.updateForm();
    }

    if (changes.messages) {
      this.errors = {};
      if (this.messages) {
        this.messages.forEach((obj: Message) => {
          const { key: controlName } = obj;
          this.errors[controlName] = { 'server': true };
        });
      }
    }
  }

  private updateForm(): void {
    if (this.form) {
      this.form.setValue({
        ...(this.data || {})
      });
      this.messages = [];
      this.form.updateValueAndValidity();
    }
  }

  private hide(): void {
    this.messages = [];
    this.display = false;
    this.displayChange.emit(this.display);
    this.updateForm();
    this.form.updateValueAndValidity();
  }
}
