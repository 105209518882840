import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DealFinderIdFilters } from '../../ecm/deal-finder/deal-finder.model';
import { InvoiceFinderFiltersIdInputs } from '../../esm/invoice/finder/invoice-finder.model';
import { NettingStatementFinderReferences } from '../../esm/netting/finder/esm-netting-finder.model';

@Component({
  selector: 'cms-id-filters',
  templateUrl: './id-filters.component.html',
  styleUrls: ['./id-filters.component.scss']
})
export class IdFiltersComponent implements OnInit, OnChanges {
  @Input() idFiltersLabels: { label: string, name: string }[];
  @Input() filters: DealFinderIdFilters | InvoiceFinderFiltersIdInputs | NettingStatementFinderReferences;
  @Input() disabled: boolean = false;
  @Output() reset = new EventEmitter();
  @Output() filter = new EventEmitter();
  form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({});
    this.idFiltersLabels.forEach((item: { label: string, name: string }) => {
      this.form.addControl(item.name, new UntypedFormControl(this.filters ? this.filters[item.name] : ''));
    });
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (this.form && changes.filters) {
      this.idFiltersLabels.forEach((item: { label: string, name: string }) => {
        this.form.patchValue({
          [item.name]: this.filters[item.name]
        });
      });
      this.form.updateValueAndValidity();
    }
  }

  onReset(): void {
    this.form.reset();
    this.reset.emit();
  }

  onFilter(filterBy: string): void {
    let values = {};
    this.idFiltersLabels.forEach((item: { label: string, name: string }) => {
      values = {
        ...values,
        [item.name]: this.form.value[item.name] || null
      };
    });
    this.filter.emit({
      ...values,
      filterBy
    });
  }
}
