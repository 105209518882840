import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'cms-mis-match-button',
  templateUrl: './mis-match-button.component.html',
  styleUrls: ['./mis-match-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MisMatchButtonComponent {
  @Input() title = '';
}
