import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RemitFilesInSearchQuery, RemitFilesInService } from './remit-files-in.service';
import { Column, DefaultValues, PagerInfo } from '@common/shared/datatable.model';
import { DateUtils } from '@common/shared/date.utils';
import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import { StringUtils } from '@common/shared/string.utils';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import * as FileSaver from 'file-saver';
import { takeUntil } from 'rxjs/operators';
import { DomHandler } from 'primeng/dom';
import { DatatableState } from '@common/datatable/datatable.model';
import { ObjectUtils } from 'primeng/utils';
import { Table, TableService } from 'primeng/table';
import { DatatableComponent } from '@common/datatable/datatable.component';
import { OrganisationService } from '@common/organisation.service';

export function tableFactory(datalist: RemitFilesInDatatableComponent): Table {
  return datalist.datatable.primeTable;
}

@Component({
  selector: 'cms-remit-files-in-datatable',
  templateUrl: './remit-files-in-datatable.component.html',
  styleUrls: ['./remit-files-in-datatable.component.scss'],
  providers: [
    DomHandler,
    ObjectUtils,
    TableService,
    Table,
    {
      provide: Table,
      useFactory: tableFactory,
      deps: [
        RemitFilesInDatatableComponent
      ]
    }
  ],
})
export class RemitFilesInDatatableComponent implements OnInit, OnDestroy {
  @ViewChild(DatatableComponent, { static: false }) datatable: DatatableComponent;
  pagerInfo: PagerInfo;
  hidden: boolean = true;
  records = [];
  recordCount: number;
  sortBy: string = 'receiptTimestamp';
  defaults = new DefaultValues();
  loading$ = new BehaviorSubject<boolean>(false);
  rowsPerPage = this.defaults.getRowsPerPage();
  rowsPerPageOptions = this.defaults.getRowsPerPageOptions();
  currentPage = 0;
  private tableColumns: Column[] = RemitFilesInDatatableConfig.TABLE_COLUMNS;
  private onDestroy$ = new Subject();

  constructor(public filesInService: RemitFilesInService,
              private orgService: OrganisationService) { }

  ngOnInit() {
    this.pagerInfo = PagerInfo.calcPagerInfo(0, 0, 20);
    this.filesInService.onSearchQueryChange().pipe(takeUntil(this.onDestroy$)).subscribe(
      this.loadData.bind(this)
    );

    this.filesInService.onFilesInDataChange().pipe(takeUntil(this.onDestroy$)).subscribe(result => {
        this.currentPage = result.currentPage;
        this.records = this.formatRows(result.rows);
        this.pagerInfo = PagerInfo.calcPagerInfo(result.recordCount, result.currentPage, this.rowsPerPage);
        this.recordCount = result.recordCount;
        this.loading$.next(false);
      });

    this.orgService.getChangeOrganisationObservable().pipe(takeUntil(this.onDestroy$)).subscribe(() => this.hidden = true);
  }

  get cols() {
    return this.tableColumns;
  }

  getRecordCount() {
    return this.recordCount;
  }


  get organisationId(): number {
    return this.orgService.getCachedOrganisationId();
  }
  // event fired by primeng datatable
  onLazyLoad({sortOrder, sortField, perPage: entriesPerPage, page}: DatatableState) {
    const pagingFilter: PagingFilter = {
      entriesPerPage,
      page
    };

    this.sortBy = sortField;
    const columnName = StringUtils.camelCaseToSnakeUpperCase(this.sortBy);
    const sortingFilter: SortingFilter = { columnName, sortOrder };

    const searchQuery = this.filesInService.getSearchQuery();

    // update search query to table status
    if (searchQuery) {
      searchQuery.paging = pagingFilter;
      searchQuery.sorting = sortingFilter;
      this.filesInService.setSearchQuery(searchQuery);
    }
  }

  // load data from via service
  private loadData(searchQuery: RemitFilesInSearchQuery) {
    if (searchQuery) {
      this.loading$.next(true);
      this.hidden = false;
      this.currentPage = searchQuery.paging.page;
      this.rowsPerPage = searchQuery.paging.entriesPerPage;
      this.filesInService.search(searchQuery);
    }
  }

  get isLoading(): Observable<boolean>{
    return this.loading$.asObservable();
  }

  // format data to rows
  private formatRows(rows) {
    let records = [];
    if (rows) {
      records = rows.map(row => ({
        ...row,
        receiptTimestamp: DateUtils.transformDateWithTimestamp(row.receiptTimestamp),
        updateTimestamp: DateUtils.transformDateWithTimestamp(row.updateTimestamp),

      }));
    }

    return records;
  }

  public downloadExcel(): void {
    this.filesInService.downloadExcel()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(data => {
        FileSaver.saveAs(data.blob, data.filename);
      });
  }


  public createAuditTrailQueryParams(row: any) {
    const queryParams = {};

    if (row.auditGroupId) {
      queryParams['auditGroupId'] = row.auditGroupId;
    }

    queryParams['displayValue'] = row.fileName;

    queryParams['urlPart'] = 'remit_filesin';

    return queryParams;
  }

  ngOnDestroy(): void {
    this.loading$.next(false);
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }
}

export const defaultStyles = {
  'text-overflow': 'ellipsis',
  'overflow': 'hidden',
  'white-space': 'nowrap'
};

export namespace RemitFilesInDatatableConfig {
  export const TABLE_COLUMNS: Column[] = [
    { name: 'receiptTimestamp', title: 'Receipt Timestamp', style: { ...defaultStyles, 'width': '80px', 'text-align': 'left' }, sortable: true, visible: true },
    { name: 'updateTimestamp', title: 'Update Timestamp', style: { ...defaultStyles, 'width': '80px', 'text-align': 'left' }, sortable: true, visible: true },
    { name: 'documentType', title: 'Document Type', style: { ...defaultStyles, 'width': '80px', 'text-align': 'left' }, sortable: true, visible: true },
    { name: 'fileName', title: 'Filename', style: { ...defaultStyles, 'width': '120px', 'text-align': 'left', 'cursor': 'help' }, editable: true, sortable: true, visible: true, },
    { name: 'buttons', title: '', style: { ...defaultStyles, 'width': '40px', 'text-align' : 'center'}, sortable: false, visible: true  },
    { name: 'recordCount', title: 'Records', style: { ...defaultStyles, 'width': '50px', 'text-align': 'right' }, sortable: true, visible: true },
    { name: 'pendingCount', title: 'Pending', style: { ...defaultStyles, 'width': '50px', 'text-align': 'right' }, sortable: true, visible: true },
    { name: 'acceptedCount', title: 'Accepted', style: { ...defaultStyles, 'width': '50px', 'text-align': 'right' }, sortable: true, visible: true },
    { name: 'rejectedCountEu', title: 'Rejected EU', style: { ...defaultStyles, 'width': '50px', 'text-align': 'right' }, sortable: true, visible: true },
    { name: 'rejectedCountUk', title: 'Rejected UK', style: { ...defaultStyles, 'width': '50px', 'text-align': 'right' }, sortable: true, visible: true }
  ];

}
