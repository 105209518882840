import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChange } from '@angular/core';

@Component({
  selector: 'cms-user-states',
  templateUrl: './user-states.component.html',
  styleUrls: ['./user-states.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserStatesComponent implements OnChanges {
  @Input() state: 'ACTIVE' | 'INACTIVE' | 'LOCKED';
  text: string;
  private readonly labels = {
    'ACTIVE': 'Active',
    'INACTIVE': 'Inactive',
    'LOCKED': 'Locked'
  };

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (changes.state) {
      this.text = this.state ? this.labels[this.state] : '';
    }
  }
}
