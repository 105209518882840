import { Message } from 'primeng/api';
import * as moment from 'moment';
import { DialogState } from '@common/state/reducers';
import * as fromActions from './sending-failures.actions';
import {
  SendingFailuresResponse,
  SendingFailuresSearchRequest,
  SendingFailuresSelected
} from '../../sending-failures/sending-failures.model';

export type SF_DIALOGS = 'INFO';

export interface SendingFailuresState {
  organisationId?: number;
  messages: Message[];
  filters: SendingFailuresSearchRequest;
  initializing: boolean;
  data: SendingFailuresResponse;
  selectedItems: SendingFailuresSelected;
  loadingTable: boolean;
  dialog: DialogState<SF_DIALOGS>;
}

const initiateDates = () => {
  const now = moment();
  const start = now.clone().subtract(7, 'days').toDate();
  const end = now.clone().toDate();

  return {
    fromDate: moment(start).format('YYYY-MM-DD'),
    fromTime: moment(start).format('HH:mm:ss'),
    toDate: moment(end).format('YYYY-MM-DD'),
    toTime: moment(end).format('HH:mm:ss')
  };
};

export const initialState: SendingFailuresState = {
  organisationId: null,
  messages: [],
  filters: {
    fromDate: '',
    fromTime: '',
    toDate: '',
    toTime: ''
  },
  initializing: false,
  data: {
    values: null
  },
  selectedItems: { },
  loadingTable: false,
  dialog: null
};

export function sendingFailuresReducer(state: SendingFailuresState = { ...initialState },
                                          action: fromActions.SendingFailuresActions) {
  switch (action.type) {
    case fromActions.toggleSelectAll.type: {
      return {
        ...state,
        selectedItems: action.payload ? { ...state.data.values } : { }
      };
    }
    case fromActions.select.type: {
      return {
        ...state,
        selectedItems: { ...state.selectedItems, ...action.payload }
      };
    }
    case fromActions.deselect.type: {
      const selectedItems = { ...state.selectedItems };
      delete selectedItems[Object.keys(action.payload).toString()];
      return {
        ...state,
        selectedItems
      };
    }
    case fromActions.openDialog.type: {
      const { name, data } = action.payload;
      return {
        ...state,
        dialog: {
          name,
          data
        }
      };
    }
    case fromActions.closeDialogAction.type: {
      return {
        ...state,
        dialog: null
      };
    }
    case fromActions.resend.type: {
      return {
        ...state,
        loadingTable: true
      };
    }
    case fromActions.resendSuccess.type: {
      return {
        ...state,
        selectedItems: { },
        loadingTable: false
      };
    }
    case fromActions.refresh.type: {
      return {
        ...state,
        filters: {
          fromDate: '',
          fromTime: '',
          toDate: '',
          toTime: ''
        },
        messages: [],
        initializing: true,
        data: {
          values: null
        },
        selectedItems: { },
      };
    }
    case fromActions.search.type: {
      const { fromDate, fromTime, toDate, toTime } = action.payload;
      return {
        ...state,
        data: {
          values: null
        },
        filters: {
          fromDate,
          fromTime,
          toDate,
          toTime
        },
        initializing: true,
        selectedItems: { },
        dialog: null
      };
    }
    case fromActions.searchSuccess.type: {
      return {
        ...state,
        data: {
          values: [...action.payload]
        },
        initializing: false,
        messages: []
      };
    }
    case fromActions.setMessages.type: {
      return {
        ...state,
        initializing: false,
        messages: [...action.payload],
      };
    }
    case fromActions.setOrganisationId.type: {
      return {
        ...state,
        organisationId: action.payload
      };
    }

    case fromActions.onPresetSuccess.type: {
      return {
        ...state,
        filters: {
          fromDate: action.payload.from,
          fromTime: moment(action.payload.from).format('HH:mm:ss'),
          toDate: action.payload.to,
          toTime: moment(action.payload.to).format('HH:mm:ss')
        }
      };
    }

    case fromActions.setToDefault.type: {
      return {
        ...initialState,
        filters: {
          fromDate: initiateDates().fromDate,
          fromTime: initiateDates().fromTime,
          toDate: initiateDates().toDate,
          toTime: initiateDates().toTime
        }
      };
    }
    default: {
      return state;
    }
  }
}

export const getFilters = (state: SendingFailuresState) => state.filters;
export const getMessages = (state: SendingFailuresState) => state.messages;
export const getInitializing = (state: SendingFailuresState) => state.initializing;
export const getData = (state: SendingFailuresState) => state.data;
export const getSelectedItems = (state: SendingFailuresState) => state.selectedItems;
export const getLoadingTable = (state: SendingFailuresState) => state.loadingTable;
export const getDialog = (state: SendingFailuresState) => state.dialog;
