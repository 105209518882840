<div class="reporting-counterparty">
  <div class="form-title">Trade | Reporting Counterparty</div>
  <div class="form-body" [formGroup]="formGroup">
    <div class="row gutter-0 !me-[40px]">
      <div class="col-5 label">Market Participant <span class="text-required">*</span></div>
      <div id="remit-trade-entry-table2-marketParticipants" class="col-7 ps-2 pe-1">
        <p-dropdown [options]="marketParticipants" [filter]="true" [filterFields]="['label']" formControlName="marketParticipantCode"
          [style]="{'width': '100%'}" [styleClass]="'margin-3 ' + getValidationErrorStyleClass('marketParticipantCode')"
          [disabled]="modifyTrade">
        </p-dropdown>
      </div>
      <span *ngIf="formGroup.controls['marketParticipantCode'].hasError('error')"
        class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0 !me-[40px]">
      <div class="col-5 label">Buy / Sell <span class="text-required">*</span></div>
      <div id="remit-trade-entry-table2-buySell" class="col-7 ps-2 pe-1">
        <p-dropdown [options]="buySell" formControlName="buyOrSell" [style]="{'width': '100%'}"
          [styleClass]="'margin-3 ' + getValidationErrorStyleClass('buyOrSell')" >
        </p-dropdown>
      </div>
      <span *ngIf="formGroup.controls['buyOrSell'].hasError('error')"
        class="fa fa-info-circle absolute error-marker"></span>
    </div>

    <div formArrayName="beneficiaries">
      <div class="row gutter-0 !me-[40px] bottom-row-border" *ngFor="let beneficiary of beneficiaries; let i = index" [formGroupName]="i">
        <div class="col-5 label">Beneficiary {{i + 1 }}</div>
        <div class="col-3 ps-2">
          <p-dropdown [options]="beneficiaryType" formControlName="codeType"
            [styleClass]="'margin-3 ' + getBeneficiaryErrorStyleClass(i, 'codeType')">
          </p-dropdown>
        </div>
        <div class="col-4 ps-0 ps-2 pe-1">
          <input type="text"
            [class]="'form-control padding-6 text-medium margin-3' + getBeneficiaryErrorStyleClass(i, 'code')"
            formControlName="code" [maxlength]="20">
        </div>
        <span *ngIf="getBeneficiaryErrorStyleClass(i, 'code') || getBeneficiaryErrorStyleClass(i, 'codeType')"
          class="fa fa-info-circle absolute error-marker"></span>
      </div>
    </div>
  </div>

  <div class="form-body">
    <div class="row gutter-0 !me-[40px]">
      <div class="col-5 label"></div>
      <div class="col-7 ps-2">
        <button type="button" class="btn add-remove-line" (click)="add()" [disabled]="isAddDisabled()">Add</button>
        <button type="button" class="btn add-remove-line" (click)="remove()"
          [disabled]="isRemoveDisabled()">Remove</button>
      </div>
    </div>
  </div>
</div>
