import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Message } from 'primeng/api';
import { ApiRequestService } from '@common/api-request.service';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { DatatableData } from '@common/shared/results.model';
import { OrganisationService } from '@common/organisation.service';
import { OrganisationModel } from '@common/organisation.model';
import * as fromActions from './user-account.actions';
import {
  ChangeUserResponse,
  ResetPasswordResponse,
  UserAccount,
  UserAccountsFilters,
  UserAccountsRequest,
  UserAccountsResponse
} from '../../administration-tab-panel/user-accounts/user-accounts.model';
import { UserAccountsState } from './user-account.reducer';
import { getDatatable, getFilters, getOrganisationId } from './user-account.selectors';
import { AuthenticationService } from '../../../auth/authentication.service';
import { Prefix, } from '../reducers';
import { OrganisationGroupsResponse } from '../../administration-tab-panel/common/organisation-group-filter/organisation-group.model';
import { CloseDialogAction, UpdateDialogAction } from '../dialog/dialog.actions';
import {
  FILTER_DATA,
  FilterDataAction,
  FilterDataSuccessAction,
  InsertItemAction,
  LOAD_DATA,
  LoadDataAction,
  SetMessagesAction,
  SORT_AND_PAGE_DATA,
  SortAndPageDataAction
} from '../datatable/datatable.actions';
import {
  LOAD_ORGANISATION_GROUPS,
  LoadOrganisationGroupsAction,
  LoadOrganisationGroupsSuccessAction
} from '../organisation-group/organisation-group.actions';
import { SetOrganisationIdAction } from '../organisation-id/organisation-id.actions';
import { SetToDefaultAction } from '../shared/shared.actions';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class UserAccountEffects {
  private baseUrl = 'api/administration/useraccounts';
  private prefix: Prefix = '[USER ACCOUNT]';

  constructor(private apiGateway: ApiRequestService,
              private actions$: Actions,
              private store$: Store<UserAccountsState>,
              private mapper: MessagesMapperService,
              private activatedRoute: ActivatedRoute,
              private authService: AuthenticationService,
              private router: Router,
              public organisationService: OrganisationService) {
    this.handleChangeUser = this.handleChangeUser.bind(this);
  }

   organisationGroups$ = createEffect(() => this.actions$
    .pipe(ofType(`${this.prefix} ${LOAD_ORGANISATION_GROUPS}`),
      switchMap( () => {
        return this.apiGateway
          .get(`${this.baseUrl}/organisationGroups`, null)
          .pipe(
            map((response: OrganisationGroupsResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return new SetMessagesAction(this.prefix, messages);
              }
              return new LoadOrganisationGroupsSuccessAction(this.prefix, response);
            }),
            catchError(error => of(new SetMessagesAction(this.prefix,
              [this.mapper.createErrorMessage(error.errorMessage)]))));
      })));

   filter$ = createEffect(() => this.actions$
    .pipe(ofType(`${this.prefix} ${FILTER_DATA}`),
      withLatestFrom(this.store$.pipe(select(getDatatable))),
      switchMap((data: [FilterDataAction, any]) => {
          const [action, state] = data;
        const request: UserAccountsRequest = {
          filterInactive: state.filterInactive,
          paging: state.paging,
          sorting: state.sorting
        };
        const { organisationGroupId, filterInactive } = action.payload as UserAccountsFilters;
          if (organisationGroupId) {
            request.organisationGroupId = organisationGroupId;
          }
        request.filterInactive = filterInactive;

          return this.loadData(request);
        }
      )));

   loadData$ = createEffect(() => this.actions$
    .pipe(ofType(`${this.prefix} ${LOAD_DATA}`),
      withLatestFrom(this.store$.pipe(select(getFilters))),
      switchMap((data: [LoadDataAction, UserAccountsFilters]) => {
          return of(new FilterDataAction(this.prefix, data[1]));
        }
      )));

   sortAndPage$ = createEffect(() => this.actions$
    .pipe(ofType(`${this.prefix} ${SORT_AND_PAGE_DATA}`),
      withLatestFrom(this.store$.pipe(select(getFilters))),
      switchMap((data: [SortAndPageDataAction, UserAccountsFilters]) => {
          const [action, filters] = data;
          const { sorting, paging } = action.payload;
          return this.loadData({
            organisationGroupId: filters ? filters.organisationGroupId : null,
            filterInactive: filters ? filters.filterInactive : true,
            sorting,
            paging,
          });
        }
      )));

   lockUser$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.LOCK_USER),
      switchMap((action: fromActions.LockUserAction) => {
        return this.apiGateway
          .post(`${this.baseUrl}/lockUser`, action.payload)
          .pipe(
            switchMap(this.handleChangeUser),
            catchError(error => of(new SetMessagesAction(this.prefix,
              [this.mapper.createErrorMessage(error.errorMessage)]))));
        }
      )));

   inactivateUser$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.INACTIVATE_USER),
      switchMap((action: fromActions.InactivateUserAction) => {
          return this.apiGateway
            .post(`${this.baseUrl}/deactivateUser`, action.payload)
            .pipe(
              switchMap(this.handleChangeUser),
              catchError(error => of(new SetMessagesAction(this.prefix,
                [this.mapper.createErrorMessage(error.errorMessage)]))));
        }
      )));

   reactivateUser$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.REACTIVATE_USER),
      switchMap((action: fromActions.ReactivateUserAction) => {
          return this.apiGateway
            .post(`${this.baseUrl}/reactivateUser`, action.payload)
            .pipe(
              switchMap(this.handleChangeUser),
              catchError(error => of(new SetMessagesAction(this.prefix,
                [this.mapper.createErrorMessage(error.errorMessage)]))));
        }
      )));

   editUserAccount$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.EDIT_USER_ACCOUNT),
      concatMap((action: fromActions.EditUserAccountAction) => {
          return this.apiGateway
            .put(`${this.baseUrl}/edit`, action.payload)
            .pipe(concatMap(this.handleChangeUser),
              catchError(error => of(new SetMessagesAction(this.prefix,
                [this.mapper.createErrorMessage(error.errorMessage)]))));
        }
      )));

   resetPassword$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.RESET_PASSWORD),
      switchMap((action: fromActions.ResetPasswordAction) => {
          return this.apiGateway
            .post(`${this.baseUrl}/resetPassword`, action.payload)
            .pipe(
              switchMap((response: ResetPasswordResponse) => {
                const messages: Message[] = this.mapper.toErrorMessages(response);
                if (messages && messages.length) {
                  return of(new UpdateDialogAction(this.prefix, messages));
                }
                return [
                  new CloseDialogAction(this.prefix),
                  new SetMessagesAction(this.prefix,
                    this.mapper.toSuccessMessages(response.response))
                ];
              }),
              catchError(error => of(new SetMessagesAction(this.prefix, [this.mapper.createErrorMessage(error.errorMessage)]))));
        }
      )));

   addUserAccount$ = createEffect(() => this.actions$
    .pipe(ofType(fromActions.ADD_USER_ACCOUNT),
      concatMap((action: fromActions.AddUserAccountAction) => {
          return this.apiGateway
            .post(`${this.baseUrl}/add`, action.payload)
            .pipe(
              withLatestFrom(this.store$.pipe(select(getDatatable))),
              concatMap((data: [ChangeUserResponse, any]) => {
                const [response, state] = data;
                const messages: Message[] = this.mapper.toErrorMessages(response);
                if (messages && messages.length) {
                  return of(new UpdateDialogAction(this.prefix, messages));
                } else {
                  return this.loadData({
                    organisationGroupId: state.filters.organisationGroupId,
                    filterInactive: state.filterInactive,
                    paging: state.paging,
                    sorting: state.sorting
                  }).pipe(switchMap((filteringAction) => {
                    return [
                      filteringAction,
                      new CloseDialogAction(this.prefix),
                      new SetMessagesAction(this.prefix, this.mapper.toSuccessMessages(response.feedback))
                    ];
                  }));
                }
              }),
              catchError(error => of(new SetMessagesAction(this.prefix, [this.mapper.createErrorMessage(error.errorMessage)]))));
        }
      )));

   organisationChange$ = createEffect(() => this.organisationService
    .getChangeOrganisationObservable()
    .pipe(
      filter(() => this.router.routerState.snapshot.url.includes('admin/userAccounts')),
      withLatestFrom(this.store$.pipe(select(getOrganisationId))),
      switchMap((data: [OrganisationModel, number]) => {
        const [organisation, organisationId] = data;
        // auth check prevents firing request when unauthorised.
        if ((this.authService.hasAccess) &&
          ((organisation && organisation.organisationId !== organisationId) || (!organisation && organisationId))) {
          const organisationGroupId = this.activatedRoute.snapshot.queryParams.organisationGroupId || null;
          return [
            new SetToDefaultAction(this.prefix),
            new SetOrganisationIdAction(this.prefix, organisation ? organisation.organisationId : null),
            new LoadDataAction(this.prefix),
            new FilterDataAction(this.prefix, { organisationGroupId }),
            new LoadOrganisationGroupsAction(this.prefix)
          ];
        }
        return of(new SetOrganisationIdAction('[USER ACCOUNT]', organisation ? organisation.organisationId : null));
      })));

  private loadData(request: UserAccountsRequest) {
    return this.apiGateway
      .post(`${this.baseUrl}/filter`, request)
      .pipe(
        map((response: UserAccountsResponse) => {
          const messages: Message[] = this.mapper.toErrorMessages(response);
          if (messages && messages.length) {
            return new SetMessagesAction(this.prefix, messages);
          }

          if (response && response.values) {
            response.values.forEach((item: UserAccount) => {
              item.realName = `${item.firstName} ${item.secondName}`;
            });
          }

          return new FilterDataSuccessAction(this.prefix, response as DatatableData<UserAccount[]>);
        }),
        catchError(error => of(new SetMessagesAction(this.prefix, [this.mapper.createErrorMessage(error.errorMessage)]))));
  }

  private getRealUser(user: UserAccount): string {
    return `${user.firstName} ${user.secondName}`;
  }

  private handleChangeUser(response: ChangeUserResponse) {
    const messages: Message[] = this.mapper.toErrorMessages(response);
    if (messages && messages.length) {
      return of(new UpdateDialogAction(this.prefix, messages));
    }
    const updatedUser = response.newModel;
    updatedUser.realName = this.getRealUser(updatedUser);
    return [
      new CloseDialogAction(this.prefix),
      new InsertItemAction(this.prefix, response.newModel),
      new SetMessagesAction(this.prefix, this.mapper.toSuccessMessages(response.feedback))
    ];
  }
}
