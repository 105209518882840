import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {ConfirmationService, Message} from 'primeng/api';

import {DatatableData, PagingFilter, SortingFilter} from '@common/shared/results.model';
import {filter, map} from 'rxjs/operators';
import {acerBillingTableColumns, TableEntry} from './acer-billing-run-tab.model';
import {AcerBillingRunsState, initialAcerBillingRunsState} from '../../../state/billing-runs/acer/acer-runs.reducers';
import {select, Store} from '@ngrx/store';
import {OrganisationService} from '@common/organisation.service';
import {ErrorMessageService} from '@common/errors/error-message.service';
import {
  cancelBillingRun,
  cleanState, continueBillingRun, deleteBillingRun, downloadBillingRun, loadPresets,
  loadTable, setDeleteMessages, setMessages,
  setPagingAndSorting,
  startBillingRun,
  startMailingRun
} from '../../../state/billing-runs/acer/acer-runs.actions';
import {
  getLoading,
  getMessages,
  getPaging,
  getPresets,
  getSorting,
  getTableData
} from '../../../state/billing-runs/acer/acer-runs.selectors';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ShowErrorsComponent} from './show-errors/show-errors.component';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Actions, ofType} from '@ngrx/effects';
import * as AcerActions from '../../../state/billing-runs/acer/acer-runs.actions';
import * as moment from 'moment';

@Component({
  selector: 'cms-acer-billing-run',
  templateUrl: './acer-billing-run-tab.component.html',
  styleUrls: ['./acer-billing-run-tab.component.scss'],
  providers: [DialogService, ConfirmationService]
})
export class AcerBillingRunTabComponent implements OnInit, OnDestroy {

  columns = acerBillingTableColumns;
  data = initialAcerBillingRunsState;

  displayTransactionsNotProcessedWarning: boolean = false;
  displayDeleteDialog: boolean = false;
  deleteHeader: string = '';
  acerBillingRunId: number;
  modal: DynamicDialogRef;
  form: UntypedFormGroup;
  confirmMessage: Message[];

  billingRunTriggered: boolean = false;
  deleteTriggered: boolean = false;
  sendTriggered: boolean = false;

  severity = 'warn';
  summary = 'Confirm your wish to delete this entry.';

  deleteMessages: Message[] = [{severity: this.severity, summary: this.summary}];
  hideDeleteConfirmButton: boolean = false;

  subscriptions$: Subscription = new Subscription();
  messages$: Observable<Message[]> = this.store.select(getMessages).pipe(map(m => m.table));
  loading$: Observable<{ table?: boolean, errors?: boolean, run?: boolean, delete?: boolean, send?: boolean }> = this.store.pipe(select(getLoading));
  paging$: Observable<PagingFilter> = this.store.select(getPaging);
  sorting$: Observable<SortingFilter> = this.store.select(getSorting);
  data$: Observable<DatatableData<TableEntry[]>> = this.store.select(getTableData);
  presets$: Observable<{ billingRunAllowed: boolean }> = this.store.select(getPresets);
  organisation$: Observable<boolean> = this.organisationService.onRefresh().pipe(filter(auth => auth));


  refreshTable = () => this.store.dispatch(loadTable());

  constructor(private store: Store<AcerBillingRunsState>,
              public organisationService: OrganisationService,
              public readonly errorMessageService: ErrorMessageService,
              public dialogService: DialogService,
              private formBuilder: UntypedFormBuilder,
              private actions$: Actions) {
  }

  confirmTransactionsNotProcessedWarning() {
    this.displayTransactionsNotProcessedWarning = false;
    this.billingRunTriggered = true;
    this.store.dispatch(continueBillingRun({billingRunID: this.acerBillingRunId}));
  }

  ngOnInit(): void {

    this.subscriptions$.add(this.actions$.pipe(ofType(AcerActions.deleteBillingRunSuccess)).subscribe(() => {
      this.acerBillingRunId = null;
      this.closeDelete();
      this.reset();
    }));
    this.subscriptions$.add(this.store.select(getMessages).pipe(map(m => m.dialog), filter(m => m?.length > 0)).subscribe(m => {
      this.deleteMessages = m;
      this.hideDeleteConfirmButton = m.filter(message => message.severity === 'error').length > 0;
    }));

    this.subscriptions$.add(this.loading$.subscribe(i => {
      if (i.run === false) {
        this.billingRunTriggered = false;
      }
      if (i.delete === false) {
        this.deleteTriggered = false;
      }
      if (i.send === false) {
        this.sendTriggered = false;
      }
    }));


    this.form = this.formBuilder.group({
      input: ['', Validators.required],
      draft: [false, Validators.required],
    });

    this.subscriptions$.add(this.presets$.subscribe(({billingRunAllowed}) => {
      this.form.get('input').reset('');
      this.form.get('draft').reset(false);
      billingRunAllowed ? this.form.enable() : this.form.disable();
    }));

    this.store.dispatch(loadPresets());

    this.subscriptions$.add(this.organisation$.subscribe(() => this.refreshTable()));
  }

  onLazyLoad({page, perPage, sortField, sortOrder}): void {

    this.store.dispatch(setPagingAndSorting({
      paging: {
        page,
        entriesPerPage: perPage
      },
      sorting: {
        sortOrder,
        columnName: sortField
      }
    }));
    this.refreshTable();
  }

  ngOnDestroy(): void {
    this.store.dispatch(cleanState());
    this.subscriptions$.unsubscribe();
  }

  openConfirmBillingRunDialog(row: TableEntry) {
    this.acerBillingRunId = row.acerBillingRunId;
    this.confirmMessage = [{
      severity: 'warn',
      summary: `There are ${row.notProcessedByAcer} transactions not yet processed by ACER. If you proceed, they will not be invoiced to the client. Would you like to proceed?.`
    }];
    this.displayTransactionsNotProcessedWarning = true;
  }

  openDeleteConfirmDialog(row: TableEntry) {
    this.acerBillingRunId = row.acerBillingRunId;
    this.deleteHeader = `Delete ACER Billing Run ${row.quarter} ${row.draft ? '(draft)' : ''} from ${moment(row.executed).format('DD/MM/YYYY HH:mm:ss')}`;
    this.displayDeleteDialog = true;
  }

  previewErrors(row: TableEntry) {

    if (row.errors > 0) {

      this.modal = this.dialogService.open(ShowErrorsComponent, {
        modal: true,
        data: {
          ...row
        },
        header: `Errors in ACER Billing Run ${row.quarter} ${row.draft ? '(draft)' : ''} from ${moment(row.executed).format('DD/MM/YYYY HH:mm:ss')}`,
        width: '35%',
        closeOnEscape: true,
        closable: false,
        style: {'max-width': '50rem', 'filter': 'drop-shadow(5px 5px 5px #777)'}
      });

    }
  }

  submit() {
    this.clear();
    const {input, draft} = this.form.value;

    if (input?.length > 0) {
      this.billingRunTriggered = true;
      this.store.dispatch(startBillingRun({invoiceStartNumber: input, isDraft: draft}));
    } else {
      this.store.dispatch(setMessages({msg: [{severity: 'warn', summary: 'ACER Billing Run requires an invoice start number'}]}));
    }
  }

  triggerMailing(acerBillingRunId) {
    this.store.dispatch(startMailingRun({billingRunID: acerBillingRunId}));
  }

  downloadZip(acerBillingRunId: any) {
    this.store.dispatch(downloadBillingRun({billingRunID: acerBillingRunId}));
  }

  clear() {
    this.store.dispatch(setMessages({msg: []}));
  }

  confirmDelete() {
    this.deleteTriggered = true;
    this.store.dispatch(deleteBillingRun({billingRunID: this.acerBillingRunId}));
  }

  confirmCancel() {
    this.displayTransactionsNotProcessedWarning = false;
    this.deleteTriggered = true;
    this.store.dispatch(cancelBillingRun({billingRunID: this.acerBillingRunId}));
  }

  closeDelete() {
    this.acerBillingRunId = null;
    this.confirmMessage = [];
    this.displayDeleteDialog = false;

  }

  reset() {
    this.store.dispatch(setDeleteMessages({msg: [{severity: this.severity, summary: this.summary}]}));
  }
}
