import { Ecm_View_By_Values, EcmDashboardPropertiesFilters } from '../../../ecm-dashboard/ecm-dashboard.model';
import { EcmPropertyFiltersValuesResponse } from '../../../deal-finder/deal-finder.model';
import { EcmDashboardState } from '../dashboard.reducers';
import * as fromFilterActions from './filters.actions';
import * as fromDbActions from '../dashboard.actions';
import { HiddenDeals } from '@common/deals/deals.model';

export interface EcmDashboardFiltersState {
  viewByFilter: Ecm_View_By_Values;
  propFilters: EcmDashboardPropertiesFilters;
  broker: string[];
  commodities: string[];
  counterParties: string[];
  markets: string[];
  transactionTypes: string[];
  loaded: boolean;
}

export const filtersInitialState: EcmDashboardFiltersState = {
  viewByFilter: 'TRADE_DATE',
  propFilters: {
    brokers: [],
    commodities: [],
    counterParties: [],
    markets: [],
    transactionTypes: [],
    hiddenDeals: HiddenDeals.EXCLUDE,
  },
  broker: [],
  commodities: [],
  counterParties: [],
  markets: [],
  transactionTypes: [],
  loaded: false,
};

export function ecmDashboardFiltersReducer(state: EcmDashboardFiltersState = { ...filtersInitialState },
                                           action: fromFilterActions.EcmFiltersActions | fromDbActions.SetToDefaultAction): EcmDashboardFiltersState {
  switch (action.type) {
    case fromFilterActions.UPDATE_FILTERS: {
      return {
        ...state,
        loaded: true,
        propFilters: { ...action.payload }
      };
    }
    case fromFilterActions.GET_FILTERS_VALUES_SUCCESS: {
      return updatePropertyFiltersValues(state, action.payload);
    }
    case fromFilterActions.FILTER: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case fromDbActions.SET_TO_DEFAULT: {
      return {
        ...filtersInitialState
      };
    }
    default:
      return state;
  }
}

const updatePropertyFiltersValues = (state: EcmDashboardFiltersState,
                                     newValues: EcmPropertyFiltersValuesResponse): EcmDashboardFiltersState => {
  const {
    broker = [],
    commodities = [],
    counterParties = [],
    markets = [],
    transactionTypes = []
  } = newValues;
  return {
    ...state,
    broker,
    commodities,
    counterParties,
    markets,
    transactionTypes,
    loaded: true,
    propFilters: {
      ...state.propFilters,
      brokers: broker,
      commodities,
      counterParties,
      markets,
      transactionTypes
    }
  };
};


export const getFilters = (state: EcmDashboardState) => state.filters;
export const getViewBy = (state: EcmDashboardFiltersState) => state.viewByFilter;
export const getFilterProperties = (state: EcmDashboardFiltersState) => state.propFilters;
export const getBroker = (state: EcmDashboardFiltersState) => state.broker;
export const getTransactionTypes = (state: EcmDashboardFiltersState) => state.transactionTypes;
export const getMarkets = (state: EcmDashboardFiltersState) => state.markets;
export const getCounterParties = (state: EcmDashboardFiltersState) => state.counterParties;
export const getCommodities = (state: EcmDashboardFiltersState) => state.commodities;
export const getLoaded = (state: EcmDashboardFiltersState) => state.loaded;
