<p-dialog (onHide)="onCancelDelete()"
            (visibleChange)="onCancelDelete()"
            *ngIf="dialog?.name === 'DELETE'"
            [header]="'Confirm that you want to delete the master data stored for VAT ID ' + selectedVat"
            [modal]="true"
            [style]="{width: '800px', minWidth: '420px'}"
            [visible]="dialog?.name === 'DELETE'">
  <p-footer>
    <button (click)="onCancelDelete()" class="btn margin-right-3" type="button">Cancel</button>
    <button (click)="onConfirmDelete()" class="btn" type="button">Ok</button>
  </p-footer>
</p-dialog>
