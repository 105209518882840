import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { Message } from 'primeng/api';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { UserAccount } from '../../../user-accounts/user-accounts.model';
import { FhaAccount } from '../../../fha-accounts/fha-accounts.model';

export interface ResetPassword {
  newPassword: string;
  newPasswordRepeat: string;
  userIdForPasswordReset: number;
}

@Component({
  selector: 'cms-reset-password-dialog',
  templateUrl: './reset-password-dialog.component.html',
  styleUrls: ['./reset-password-dialog.component.scss']
})
export class ResetPasswordDialogComponent implements OnInit, OnChanges {
  @Input() data: UserAccount | FhaAccount;
  @Input() display = false;
  @Input() messages: Message[];
  @Output() displayChange = new EventEmitter<boolean>();
  @Output() confirm = new EventEmitter<ResetPassword>();
  @Output() cancel = new EventEmitter<Message[]>();
  errors: {[key: string]: ValidationErrors} = {};
  form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder,
              private mapper: MessagesMapperService) {
  }

  onCancel(): void {
    this.hide();
    this.cancel.emit(this.mapper.toWarningMessages(`Password reset for user '${this.data.userName}' was cancelled.`));
  }

  onVisibleChange(visible: boolean): void {
    if (!visible) {
      this.onCancel();
    }
  }

  onConfirm(): void {
    const out: ResetPassword = {
      ...this.form.value,
      userIdForPasswordReset: this.data.id
    };

    this.confirm.emit(out);
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      newPassword: '',
      newPasswordRepeat: ''
    });
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (this.form && changes.data) {
      this.form.updateValueAndValidity();
    }

    if (changes.messages) {
      this.errors = {};
      if (this.messages) {
        this.messages.forEach((obj: Message) => {
          const { key: controlName } = obj;
          this.errors[controlName] = { 'server': true };
        });
      }
    }
  }

  private hide(): void {
    this.messages = [];
    this.display = false;
    this.displayChange.emit(this.display);
    this.form.reset();
    this.form.updateValueAndValidity();
  }
}
