import { Injectable } from '@angular/core';
import { RemitDashboardSearchFilter } from './filter/remit-dashboard-filter-model';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthenticationService } from '../../../auth/authentication.service';
import { OrganisationService } from '../../../common/organisation.service';
import { EndPointConstants } from '../../../common/end-point-constants';

@Injectable()
export class RemitDashboardService {

  private searchFilterSubject = new BehaviorSubject<RemitDashboardSearchFilter>(this.getDefaultFilter());

  constructor(private authService: AuthenticationService, private orgService: OrganisationService, private httpClient: HttpClient) {
  }

  updateFilter(dashboardSearchFilter: RemitDashboardSearchFilter) {
    this.searchFilterSubject.next(dashboardSearchFilter);
  }

  getSearchFilter(): Observable<RemitDashboardSearchFilter> {
    return this.searchFilterSubject.asObservable();
  }

  getCurrentSearchFilter(): RemitDashboardSearchFilter {
    return this.searchFilterSubject.getValue();
  }

  private getDefaultFilter(): RemitDashboardSearchFilter {
    return {
      viewBy: 'TRANSACTION_DATE',
      reportingRoleType: 'MARKET_PARTICIPANT',
      documentType: 'REMIT_TABLE_1'
    };
  }

  loadBuckets(searchFilter: RemitDashboardSearchFilter): any {// TODO CMSSUP-4695 mr2 2018-07: 'any' caused problems in context of rxjs update. search and replace all occurrences of 'any' and configure the build to fail if 'any' is used again (maybe one strict and one not so strict build)

    const organisationId = this.orgService.getCachedOrganisationId();
    let httpParams = new HttpParams();
    if (organisationId) {
      httpParams = httpParams.append(EndPointConstants.PARAM_ORGANISATION_ID, organisationId.toString());

      return this.httpClient.post('/api/err/remit/buckets',
        searchFilter, {
          params: httpParams
        });
    }
  }

}
