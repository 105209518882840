<div class="panel-box margin-bottom-15">
  <label class="text-bold form_label form-row">PDF Invoice Logo</label>
  <div class="row">
    <div class="col-sm-12 col-md-5 col-lg-4">
      <div style="height: 100px; width: 200px;">
        <div class="logo-spinner" *ngIf="loading">
          <p-progressSpinner></p-progressSpinner>
        </div>
        <div class="pdf-settings-logo" *ngIf="(logoAvailable$ | async)">
          <img [src]="logoAsBackgroundSrc$ | async" (load)="onLogoLoaded($event)" style="position: relative; max-height: 100%; height: auto; width: auto;">
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-7 col-lg-8">

      <cms-upload-panel *ngIf="uploadAllowed"
                        (error)="onUploadError($event)"
                        (upload)="onUpload($event)"
                        [accept]="'.png'"
                        [maxFileSize]="5"
                        [disabled]="!(pdfSetting$ | async)?.masterDataId"
                        [busy]="busy"
                        style="min-width: 340px; max-width: 420px;"
                        class="upload-button-bar">
      </cms-upload-panel>
    </div>
  </div>


</div>
