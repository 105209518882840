import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipeModule } from '../../../pipe/pipe.module';
import { ErrRemitSettingsComponent } from './err-remit-settings.component';
import { CmsCommonModule } from '@common/cms-common.module';
import { TableModule } from 'primeng/table';
import { ErrRemitSettingsService } from './err-remit-settings.service';
import { DropdownModule } from 'primeng/dropdown';
import { AccordionModule } from 'primeng/accordion';
import { PanelModule } from 'primeng/panel';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';

@NgModule({
  imports: [
    BrowserModule,
    PanelModule,
    AccordionModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    PipeModule,
    CmsCommonModule,
    MessageModule,
    MessagesModule,
    TableModule,
  ],
  providers: [
    ErrRemitSettingsService
  ],
  declarations: [ErrRemitSettingsComponent]
})
export class ErrRemitSettingsModule {
}
