import { createSelector } from '@ngrx/store';
import { getEcmState, State } from '../reducers';
import * as fromReducer from './enter-trade.reducers';
import { EcmEnterTradeProduct } from '../../enter-trade/enter-trade.model';

export const getEnterTradeState = createSelector(getEcmState, (state: State) => state.enterTrade);
export const getProductId = createSelector(getEnterTradeState, fromReducer.getProductId);
export const getMessages = createSelector(getEnterTradeState, fromReducer.getMessages);
export const getFormValues = createSelector(getEnterTradeState, fromReducer.getFormValues);
export const getFormData = createSelector(getEnterTradeState, fromReducer.getFormData);
export const getEditableData = createSelector(getEnterTradeState, fromReducer.getEditableData);
export const getEditableId = createSelector(getEnterTradeState, fromReducer.getEditableId);
export const getOrganisationId = createSelector(getEnterTradeState, fromReducer.getOrganisationId);
export const getProductsList = createSelector(getEnterTradeState, fromReducer.getProductsList);
export const getPeriodsValues = createSelector(getEnterTradeState, fromReducer.getPeriodsValues);
export const getCapacityValue = createSelector(getEnterTradeState, fromReducer.getCapacityValue);
export const getPriceValue = createSelector(getEnterTradeState, fromReducer.getPriceValue);
export const getDeliveryPeriod = createSelector(getEnterTradeState, fromReducer.getDeliveryPeriod);
export const getTotalVolumeData = createSelector(getEnterTradeState, fromReducer.getTotalVolumeData);
export const getTotalContractValue = createSelector(getEnterTradeState, fromReducer.getTotalContractValue);

export const getProduct = createSelector(getProductsList, getProductId,
  (list: EcmEnterTradeProduct[] = [], productId: number): EcmEnterTradeProduct =>
    list && productId ? list.find(item => item.productId === productId) : null);

export const getProductOptions = createSelector(getProductsList, (list: EcmEnterTradeProduct[] = []): { label: string, value: EcmEnterTradeProduct }[] =>
  list.map(item => ({ label: item.displayName, value: item })));

