import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { RemitTradeEntryTable1Service } from '../remit-trade-entry-table1.service';
import { Subject } from 'rxjs';
import { RemitTradeEntryTable1ValueSetResponseModel } from '../remit-trade-entry-table1-data.model';
import { SelectItem, SelectItemGroup } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cms-remit-trade-entry-table1-product-details',
  templateUrl: './remit-trade-entry-table1-product-details.component.html',
  styleUrls: ['./remit-trade-entry-table1-product-details.component.scss']
})
export class RemitTradeEntryTable1ProductDetailsComponent implements OnInit, OnDestroy {

  @Input()
  formGroup: UntypedFormGroup;

  private onDestroy$ = new Subject();

  productDetails: RemitTradeEntryTable1ValueSetResponseModel;

  autoSelectFirstDeliveryPointOrZone: boolean;

  contractTypes: SelectItemGroup[];
  commodities: SelectItem[];
  deliveryPoints: SelectItemGroup[];
  loadTypes: SelectItemGroup[];

  constructor(private tradeEntryService: RemitTradeEntryTable1Service) {
  }

  ngOnInit() {
    this.formGroup.addControl('contractType', new UntypedFormControl(''));
    this.formGroup.addControl('energyCommodity', new UntypedFormControl(''));
    this.formGroup.addControl('deliveryPointOrZone', new UntypedFormControl(''));
    this.formGroup.addControl('loadType', new UntypedFormControl(''));

    this.autoSelectFirstDeliveryPointOrZone = true;

    this.tradeEntryService.onTradeEntryDataChange()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tradeEntryData => {
          if (tradeEntryData && tradeEntryData.productDetails) {
            this.productDetails = tradeEntryData.productDetails;
            this.contractTypes = this.getContractTypeGroup();
            this.commodities = this.getEnergyCommodity();
            this.deliveryPoints = this.getDeliveryPointsOrZoneGroup();
            this.loadTypes = this.getLoadTypeGroup();
          }
        }
      );
  }

  selectCommodityFirstPlaceholder(fieldName: SelectItemGroup[]): string {
    return fieldName && fieldName[0].items.length === 0 ? 'Please select a Commodity first' : '';
  }

  private getEnergyCommodity() {
    if (this.productDetails && this.productDetails.energyCommodities) {
      return this.productDetails.energyCommodities.map(item => (
        { label: item.value, value: item.key }
      ));
    }
  }

  private getContractTypeGroup(): SelectItemGroup[] {
    if (this.productDetails) {
      return this.tradeEntryService.getGroupedValues(this.productDetails.contractTypes);
    }
    return [{ label: '', items: [] }];
  }

  private getDeliveryPointsOrZoneGroup(): SelectItemGroup[] {
    if (this.productDetails) {
      return this.tradeEntryService.getGroupedValues(this.productDetails.deliveryPointsOrZones);
    }

    return [{ label: '', items: [] }];
  }

  private getLoadTypeGroup(): SelectItemGroup[] {
    if (this.productDetails) {
      return this.tradeEntryService.getGroupedValues(this.productDetails.loadTypes);
    }

    return [{ label: '', items: [] }];
  }

  onChangeCommodity() {
    this.tradeEntryService.loadTradeEntryData(this.formGroup.value);
  }

  getValidationErrorStyleClass(fieldName: string): string {
    if (this.formGroup.controls[fieldName].hasError('error')) {
      return 'error';
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

}
