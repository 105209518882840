import {createAction, union} from '@ngrx/store';
import {Message} from 'primeng/api';
import {DatatableConfig} from '@common/shared/results.model';
import {Column} from '@common/shared/datatable.model';
import {AddNote} from '@common/deals/deals.model';
import {EsmInvoiceFinderDeal} from '../../../../netting/dashboard/esm-netting-dashboard.model';

export const setMessagesAction = createAction('[ESM NETTING DASHBOARD] Set Messages', (payload: Message[]) => ({payload}));
export const sortAndPageDataAction = createAction('[ESM NETTING DASHBOARD] Sort And Page Data', (payload: DatatableConfig) => ({payload}));
export const setColumnsAction = createAction('[ESM NETTING DASHBOARD] Set Columns', (payload: Column[]) => ({payload}));
export const exportAction = createAction('[ESM NETTING DASHBOARD] Export');
export const setSelectedIdsAction = createAction('[ESM NETTING DASHBOARD] Set Selected Ids Action', (payload: { confirmationIds: string[] }) => ({payload}));
export const setSelectedNettingStatementsAction = createAction('[ESM NETTING DASHBOARD] Set Selected Netting Statements Action', (payload: { nettingStatements: EsmInvoiceFinderDeal[] }) => ({payload}));
export const addNoteAction = createAction('[ESM NETTING DASHBOARD] Add Note', (payload: AddNote) => ({payload}));
export const addNoteSuccessAction = createAction('[ESM NETTING DASHBOARD] Add Note Success');
export const downloadPdfAction = createAction('[ESM NETTING DASHBOARD] Download Pdf', (payload: string) => ({payload}));

const actions = union({
  downloadPdfAction,
  setMessagesAction,
  sortAndPageDataAction,
  setColumnsAction,
  exportAction,
  setSelectedIdsAction,
  setSelectedNettingStatementsAction,
  addNoteAction,
  addNoteSuccessAction
});

export type DashboardTableActions = typeof actions;
