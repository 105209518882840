<form (ngSubmit)="submitReportGeneration()" [formGroup]="form">
  <div class="inline-block margin-right-15">
    <label class="margin-right-24">Organisation Group <span class="asterisk margin-right-6"></span>
      <p-dropdown
        [options]="(orgGroups$ | async)"
        formControlName="orgGroupSelector"
        [filter]="true"
        [filterFields]="['label']"
        [styleClass]="'org-selection'"
        [placeholder]="'Select an organisation group'"
        filterMatchMode="startsWith"
        scrollHeight="300px"
        [required]="true"
      ></p-dropdown>
    </label>

    <label class="margin-right-30">Billing Quarter <span class="asterisk margin-right-6"></span>
      <p-dropdown
        [options]="quarterOptions"
        formControlName="quarterSelector"
        [styleClass]="'billing-quarter-selection'"
        scrollHeight="300px"
        [required]="true"
      ></p-dropdown>
    </label>
    <button class="btn inline-block form-control margin-3 margin-left-12 padding-6 w-auto" [disabled]="!form.valid" type="submit">Generate Report</button>
    <div *ngIf="form.errors?.['alreadySubmitted'] && (form.touched || form.dirty)" class="cross-validation-error-message alert alert-danger p-8 mb-0">
      This report has already been generated and can be found in the list below.
    </div>
  </div>
</form>
