import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {distinctUntilChanged, withLatestFrom} from 'rxjs/operators';
import {Message} from 'primeng/api';
import * as moment from 'moment';
import {OrganisationService} from '@common/organisation.service';
import {OrganisationModel} from '@common/organisation.model';
import {ReportsState} from '../../../ecm/state/reports/reports.reducers';
import {LoadingReport, ReportType} from './billing-reports.model';
import {BillingReportsFiltersComponent} from './billing-reports-filters/billing-reports-filters.component';
import * as fromActions from '../../state/billing-reports/billing-reports.actions';
import * as fromSelectors from '../../state/billing-reports/billing-reports.selectors';

@Component({
  selector: 'cms-billing-reports',
  templateUrl: './billing-reports.component.html',
  styleUrls: ['./billing-reports.component.scss']
})
export class BillingReportsComponent implements OnInit, OnDestroy {
  @ViewChild('dates', { static: true }) dates: BillingReportsFiltersComponent;
  messages$: Observable<Message[]> = this.store.pipe(select(fromSelectors.getMessages));
  loading$: Observable<LoadingReport> = this.store.pipe(select(fromSelectors.getLoading));
  dates$: Observable<{ startDate: Date, endDate: Date }> = this.store.pipe(select(fromSelectors.getDates));
  subscription$: Subscription;

  constructor(private organisationService: OrganisationService,
              private store: Store<ReportsState>) { }

  ngOnInit(): void {
    this.store.dispatch(fromActions.setToDefaultAction());
    this.store.dispatch(fromActions.getDateRangeAction());
    this.store.dispatch(fromActions.setOrganisationIdAction(this.organisationService.getCachedOrganisationId()));

    this.subscription$ = this.organisationService
      .getChangeOrganisationObservable()
      .pipe(
        distinctUntilChanged(),
        withLatestFrom(this.store.pipe(select(fromSelectors.getOrganisationId))))
      .subscribe((([organisation, organisationId]: [OrganisationModel, number]) => {
        if ((organisation && organisation.organisationId !== organisationId) || (!organisation && organisationId)) {
          this.store.dispatch(fromActions.setOrganisationIdAction(organisation ? organisation.organisationId : null));
          this.store.dispatch(fromActions.setToDefaultAction());
        }
      }));
  }

  onBilling(reportType: ReportType): void {
    let { startDate, endDate } = this.dates.form.value;
    startDate = startDate && moment(startDate).format('YYYY-MM-DD');
    endDate = endDate && moment(endDate).format('YYYY-MM-DD');
    this.store.dispatch(fromActions.validateBillingReportsAction({ startDate, endDate, reportType }));
  }

  ngOnDestroy(): void {
    this.store.dispatch(fromActions.setToDefaultAction());
    this.subscription$.unsubscribe();
  }
}
