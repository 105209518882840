import { Injectable } from '@angular/core';
import { ApiRequestService } from '@common/api-request.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { catchError, switchMap } from 'rxjs/operators';
import { Message } from 'primeng/api';
import { CounterPartyState } from './counterparty.reducers';
import { CounterpartyOption } from '../../../netting-preview/netting-preview.model';
import { of } from 'rxjs';
import * as CounterPartyActions from './counterparty.actions';
import * as NettingTableActions from '../netting-table/netting-table.actions';

@Injectable()
export class CounterPartyEffects {
  private baseUrl = '/api/esm/netting/preview';

  constructor(private apiGateway: ApiRequestService,
              private actions$: Actions,
              private store$: Store<CounterPartyState>,
              private mapper: MessagesMapperService) {
  }

  load_presets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CounterPartyActions.loadPresets),
      switchMap(() =>
        this.apiGateway.get(`${this.baseUrl}/presets`)
          .pipe(
            switchMap(response => {
              const messages: Message[] = this.mapper.toErrorMessages(response);

              const presets: CounterpartyOption[] = response.values?.map(({ key, value }) => ({ label: value, value: key }));
              return messages?.length ?
                [NettingTableActions.setMessages({ messages })] :
                [NettingTableActions.resetMessages(), CounterPartyActions.loadPresetsSuccess({ presets })];
            }),

            catchError(error => of(NettingTableActions.setMessages(this.mapper.toErrorMessagesObj(error.errorMessage || 'Server Error')))
            ))
      ))
  );


}
