import { Message } from 'primeng/api';
import { Column } from '@common/shared/datatable.model';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import * as fromAction from './mifid-details.action';
import {
  ErrMifidDetailsFormValues,
  ErrMifidDetailsInformation,
  ErrMifidDetailsItemResponse,
} from '../../mifid/mifid.model';

export interface ErrMifidFileDetailsState {
  information: ErrMifidDetailsInformation;
  messages: Message[];
  paging?: PagingFilter;
  sorting?: SortingFilter;
  organisationId?: number;
  loading: boolean;
  data: DatatableData<ErrMifidDetailsItemResponse[]>;
  columns: Column[];
  fileId: number;
  editable: boolean;
  formValues: ErrMifidDetailsFormValues;
}

export const initialState: ErrMifidFileDetailsState = {
  information: null,
  data: null,
  loading: false,
  messages: [],
  paging: { page: 0, entriesPerPage: 25 },
  sorting: { columnName: 'refNumber', sortOrder: 'DESCENDING' },
  columns: [],
  fileId: null,
  editable: false,
  formValues: null,
};

export function errMifidFileDetailsReducer(state: ErrMifidFileDetailsState = { ...initialState },
                                           action: fromAction.ErrMifidFileDetailsActions): ErrMifidFileDetailsState {
  switch (action.type) {
    case fromAction.SET_TO_DEFAULT:
      return { ...initialState };
    case fromAction.SORT_AND_PAGE_DATA:
      return {
        ...state,
        loading: true,
        messages: [],
        paging: action.payload.paging,
        sorting: action.payload.sorting,
      };
    case fromAction.SET_MESSAGES:
      return {
        ...state,
        loading: false,
        messages: [...action.payload],
      };
    case fromAction.LOAD_INFORMATION_SUCCESS:
      return {
        ...state,
        information: action.payload.value,
        editable: action.payload.value.confirmFileEnabled,
      };
    case fromAction.LOAD_DATA:
      return {
        ...state,
        loading: true,
      };
    case fromAction.LOAD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          values: action.payload.values,
          count: action.payload.count,
        },
        paging: {
          ...state.paging,
          page: action.payload.currentPage
        },
      };
    case fromAction.SET_FILE_ID:
      return {
        ...state,
        fileId: action.payload
      };
    case fromAction.SET_COLUMNS:
      return {
        ...state,
        columns: action.payload,
      };
    case fromAction.LOAD_FORM_VALUES_SUCCESS:
      return {
        ...state,
        formValues: action.payload.value,
      };
    default:
      return state;
  }
}

export const getData = (datatableState: ErrMifidFileDetailsState): DatatableData<ErrMifidDetailsItemResponse[]> => datatableState.data;
export const getSorting = (state: ErrMifidFileDetailsState) => state.sorting;
export const getPaging = (state: ErrMifidFileDetailsState) => state.paging;
export const getLoading = (state: ErrMifidFileDetailsState) => state.loading;
export const getMessages = (state: ErrMifidFileDetailsState) => state.messages;
export const getColumns = (state: ErrMifidFileDetailsState) => state.columns;
export const getFileId = (state: ErrMifidFileDetailsState) => state.fileId;
export const getInformation = (state: ErrMifidFileDetailsState) => state.information;
export const getEditable = (state: ErrMifidFileDetailsState) => state.editable;
export const getFormValues = (state: ErrMifidFileDetailsState) => state.formValues;

