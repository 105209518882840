import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { updateDealsMultiSelect, updateDealsMultiSelectLabelValue } from '@common/deals/deals.model';
import { EsmDashboardPresetFilters } from '../esm-dashboard.model';
import { AdditionalDateRangeEntry, getEsmDashboardBucketRanges } from '@common/additional-date-range/additional-date-range.model';

@Component({
  selector: 'cms-invoice-filter',
  templateUrl: './invoice-filter.component.html',
  styleUrls: ['./invoice-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceFilterComponent implements OnInit, OnChanges {
  @Input() filters: EsmDashboardPresetFilters;
  @Input() commodities: string[] = [];
  @Input() deliveryPoints: { keyObject: string, valueObject: string }[] = [];
  @Input() counterParties: { key: number, value: string }[] = [];
  @Input() invoiceTypes: string[] = [];
  @Input() isMultiAgreeVisible: boolean = false;
  @Output() reset = new EventEmitter();
  @Output() filter = new EventEmitter<EsmDashboardPresetFilters>();
  form: UntypedFormGroup;
  commoditiesOptions: { label: string, value: string }[] = [];
  deliveryPointsOptions: { label: string, value: string }[] = [];
  counterPartiesOptions: { label: string, value: number }[] = [];
  invoiceTypesOptions: { label: string, value: string }[] = [];

  errors: { [key: string]: ValidationErrors } = {};
  updateDealsMultiSelect;
  updateMultiSelectKeyValue;
  errorMessage: string;
  controllerMap = {
    commodities: 'Commodity',
    invoiceTypes: 'Invoice Type',
    counterParties: 'Counterparty',
    deliveryPoints: 'Delivery Point'
  };
  dateRanges: AdditionalDateRangeEntry[] = getEsmDashboardBucketRanges();

  constructor(private formBuilder: UntypedFormBuilder) {
    this.updateDealsMultiSelect = updateDealsMultiSelect.bind(this);
    this.updateMultiSelectKeyValue = updateDealsMultiSelectLabelValue.bind(this);
  }

  ngOnInit(): void {
    const {
      counterParties,
      deliveryPoints,
      commodities,
      invoiceTypes,
      invoiceStartDate,
      invoiceEndDate,
      paymentDateRange,
      deliveryDateRange
    } = this.filters;
    this.form = this.formBuilder.group({
      counterParties: { value: counterParties, disabled: !this.counterParties || !this.counterParties.length },
      deliveryPoints: { value: deliveryPoints, disabled: !this.deliveryPoints || !this.deliveryPoints.length },
      commodities: { value: commodities, disabled: !this.commodities || !this.commodities.length },
      invoiceTypes: { value: invoiceTypes, disabled: !this.invoiceTypes || !this.invoiceTypes.length },
      deliveryDateRangeStart: deliveryDateRange && deliveryDateRange.start,
      deliveryDateRangeEnd: deliveryDateRange && deliveryDateRange.end,
      paymentDateStart: paymentDateRange && paymentDateRange.start,
      paymentDateEnd: paymentDateRange && paymentDateRange.end,
      invoiceStartDate,
      invoiceEndDate,
    });

    this.form.statusChanges.subscribe(status => {
      if (status === 'VALID') {
        this.errorMessage = null;
      } else if (status === 'INVALID') {
        this.errorMessage = this.setErrorMessage();
      }
    });

    this.updateCounterPartiesMultiselect();

    this.updateMultiSelectKeyValue(
      'deliveryPoints',
      'deliveryPointsOptions',
      this.deliveryPoints,
      this.deliveryPointsValidator(1000));

    this.updateDealsMultiSelect(
      'commodities',
      'commoditiesOptions',
      this.commodities,
      Validators.required);

    this.updateDealsMultiSelect(
      'invoiceTypes',
      'invoiceTypesOptions',
      this.invoiceTypes,
      Validators.required);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.form && changes.filters) {
      const {
        counterParties,
        deliveryPoints,
        commodities,
        invoiceTypes,
        invoiceStartDate,
        invoiceEndDate,
        paymentDateRange,
        deliveryDateRange
      } = this.filters;
      this.form.patchValue({
        counterParties,
        deliveryPoints,
        commodities,
        invoiceTypes,
        invoiceStartDate,
        invoiceEndDate,
        deliveryDateRangeStart: deliveryDateRange ? deliveryDateRange.start : null,
        deliveryDateRangeEnd: deliveryDateRange ? deliveryDateRange.end : null,
        paymentDateStart: paymentDateRange ? paymentDateRange.start : null,
        paymentDateEnd: paymentDateRange ? paymentDateRange.end : null,
      });
      this.form.updateValueAndValidity();
    }

    if (changes.counterParties) {
      this.updateCounterPartiesMultiselect();
    }

    if (changes.deliveryPoints) {
      this.updateMultiSelectKeyValue(
        'deliveryPoints',
        'deliveryPointsOptions',
        this.deliveryPoints,
        this.deliveryPointsValidator(1000));
    }

    if (changes.commodities) {
      this.updateDealsMultiSelect(
        'commodities',
        'commoditiesOptions',
        this.commodities,
        Validators.required);
    }

    if (changes.invoiceTypes) {
      this.updateDealsMultiSelect(
        'invoiceTypes',
        'invoiceTypesOptions',
        this.invoiceTypes,
        Validators.required);
    }
  }

  onReset(): void {
    this.reset.emit();
  }

  onFilter(): void {
    if (this.form.invalid) {
      return;
    }
    const multiAgreeButtonVisible = this.isMultiAgreeVisible;
    const {
      counterParties,
      deliveryPoints,
      commodities,
      invoiceTypes,
      invoiceStartDate,
      invoiceEndDate,
      deliveryDateRangeStart,
      deliveryDateRangeEnd,
      paymentDateStart,
      paymentDateEnd,
    } = this.form.getRawValue();
    this.filter.emit({
      counterParties,
      deliveryPoints,
      commodities,
      invoiceTypes,
      multiAgreeButtonVisible,
      invoiceStartDate,
      invoiceEndDate,
      paymentDateRange: {
        start: paymentDateStart,
        end: paymentDateEnd
      },
      deliveryDateRange: {
        start: deliveryDateRangeStart,
        end: deliveryDateRangeEnd
      }
    });
  }

  setErrorMessage(): string {

    return Object.keys(this.form.controls)
      .filter(key => this.form.get(key).errors)
      .map(key => ({ k: key, er: this.form.get(key).errors }))
      .map(({ k, er }) => Object.keys(er).map(keyError => this.controllerMap[k] + ' ' + keyError))
      .join('\n');

  }

  private updateCounterPartiesMultiselect(): void {
    const isValues = this.counterParties && this.counterParties.length;
    this.counterPartiesOptions = isValues ?
      this.counterParties.map(item => ({ label: item.value, value: item.key })) : [];
    if (!this.form) {
      return;
    }
    const counterPartyOrgIdsControl = this.form.controls['counterParties'];
    if (isValues) {
      counterPartyOrgIdsControl.enable();
      counterPartyOrgIdsControl.setValidators(Validators.required);
    } else {
      counterPartyOrgIdsControl.disable();
      counterPartyOrgIdsControl.clearValidators();
    }
    this.form.updateValueAndValidity();
  }

  private deliveryPointsValidator(size: number): ValidatorFn {

    const ruleConformValidator: ValidatorFn = (control: AbstractControl): { [key: string]: string } | null => {
      const selectedDeliveryPoints = control.value as Array<string>;

      const selectionOverflow = control.dirty &&
        selectedDeliveryPoints.length >= size &&
        selectedDeliveryPoints.length !== this.deliveryPoints.length;

      return selectionOverflow ? { ': max number of individual selections exceeded': 'overflow' } : null;
    };

    return Validators.compose([
      Validators.required,
      ruleConformValidator]);
  }
}
