import { createAction, union } from '@ngrx/store';
import { MyFilter } from '@common/my-filters/my-filters.model';
import { EsmInvoiceFinderMyFilter } from '../../../invoice/finder/invoice-finder.model';

export const setDefaultFilterAction = createAction('[INVOICE FINDER] Set Default Filter', (payload: MyFilter) => ({ payload }));
export const loadMyFiltersAction = createAction('[INVOICE FINDER] Load My Filters');
export const loadMyFiltersSuccessAction = createAction('[INVOICE FINDER] Load My Filters Success', (payload: {
  defaultFilter: EsmInvoiceFinderMyFilter,
  filterSelections: EsmInvoiceFinderMyFilter[]
}) => ({ payload }));
export const setAllDealsFilterAction = createAction('[INVOICE FINDER] Set All Deals Filter', (payload: EsmInvoiceFinderMyFilter) => ({ payload }));
export const selectMyFilterAction = createAction('[INVOICE FINDER] Select My Filter', (payload: EsmInvoiceFinderMyFilter) => ({ payload }));
export const deleteMyFilterAction = createAction('[INVOICE FINDER] Delete My Filter', (payload: { filterName: string }) => ({ payload }));
export const deleteMyFilterSuccessAction = createAction('[INVOICE FINDER] Delete My Filter Success', (payload: { filterName: string }) => ({ payload }));
export const replaceMyFiltersAction = createAction('[INVOICE FINDER] Replace My Filters', (payload: EsmInvoiceFinderMyFilter) => ({ payload }));
export const saveMyFiltersAction = createAction('[INVOICE FINDER] Save My Filters', (payload: EsmInvoiceFinderMyFilter) => ({ payload }));
export const reloadMyFiltersAction = createAction('[INVOICE FINDER] Reload My Filters');
export const reloadMyFilterSuccessAction = createAction('[INVOICE FINDER] Reload My Filter Success', (payload: {
  defaultFilter: EsmInvoiceFinderMyFilter,
  filterSelections: EsmInvoiceFinderMyFilter[]
}) => ({ payload }));

const actions = union({
  setDefaultFilterAction,
  loadMyFiltersAction,
  loadMyFiltersSuccessAction,
  setAllDealsFilterAction,
  selectMyFilterAction,
  deleteMyFilterAction,
  deleteMyFilterSuccessAction,
  replaceMyFiltersAction,
  saveMyFiltersAction,
  reloadMyFiltersAction,
  reloadMyFilterSuccessAction
});

export type MyFiltersActions = typeof actions;

