import { Action } from '@ngrx/store';
import { OrganisationGroupsResponse } from '../../administration-tab-panel/common/organisation-group-filter/organisation-group.model';
import { Prefix } from '../reducers';

export const LOAD_ORGANISATION_GROUPS_SUCCESS = 'Load Organisation Groups Success';
export const LOAD_ORGANISATION_GROUPS = 'Load Organisation Groups';

export class LoadOrganisationGroupsAction implements Action {
  get type() {
    return `${this.prefix} ${LOAD_ORGANISATION_GROUPS}`;
  }

  constructor(public prefix: Prefix) {
  }
}

export class LoadOrganisationGroupsSuccessAction {
  get type() {
    return `${this.prefix} ${LOAD_ORGANISATION_GROUPS_SUCCESS}`;
  }

  constructor(public prefix: Prefix, public payload: OrganisationGroupsResponse) {
  }
}
