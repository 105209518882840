import { Component, OnDestroy, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Store } from '@ngrx/store';
import { AcerBillingRunsState } from '../../../../state/billing-runs/acer/acer-runs.reducers';
import { getAcerBillingRunErrors, resetAcerBillingRunErrors } from '../../../../state/billing-runs/acer/acer-runs.actions';
import { Observable } from 'rxjs';
import { getErrors, getLoading } from '../../../../state/billing-runs/acer/acer-runs.selectors';

@Component({
  selector: 'cms-confirm-delete',
  templateUrl: './show-errors.component.html',
  styleUrls: ['./show-errors.component.scss']
})
export class ShowErrorsComponent implements OnDestroy {
  display: boolean = false;

  data$: Observable<{ name: string, code: string }[]> = this.store.select(getErrors);
  loading$: Observable<{ table?: boolean, errors?: boolean }> = this.store.select(getLoading);


  constructor(public config: DynamicDialogConfig,
              private store: Store<AcerBillingRunsState>,
              public dRef: DynamicDialogRef) {

    store.dispatch(getAcerBillingRunErrors({ billingRunID: config.data.acerBillingRunId }));

  }

  onClose() {
    this.store.dispatch(resetAcerBillingRunErrors());
    this.dRef.close();
  }

  ngOnDestroy(): void {
    this.store.dispatch(resetAcerBillingRunErrors()); // just in case
  }


}
