import { Component, OnDestroy, OnInit } from '@angular/core';
import { Message } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { ErrorMessageService } from '@common/errors/error-message.service';
import { StandingInstructionsService } from './standing-instructions.service';
import { map } from 'rxjs/operators';
import { OrganisationService } from '@common/organisation.service';
import { OrganisationGroupModel } from '@common/organisation-group.model';

@Component({
  selector: 'cms-standing-instructions',
  templateUrl: './standing-instructions.component.html',
  styleUrls: ['./standing-instructions.component.scss']
})
export class StandingInstructionsComponent implements OnInit, OnDestroy {

  messages: Message[] = [];
  messages$: Observable<Message[]>;
  private onDestroy$ = new Subject();


  constructor(
    private standingInstructionsService: StandingInstructionsService,
    public errorMessageService: ErrorMessageService
  ) {
  }

  ngOnInit() {
    this.messages$ = this.standingInstructionsService.onMessageChange()
      .pipe(map(m => this.messages = m ? [...this.messages, ...m] : []));
  }

  ngOnDestroy(): void {
    this.standingInstructionsService.setMessages(null);
    this.messages = [];
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

}
