import {
  AfterContentInit, AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange
} from '@angular/core';
import { EsmVatIds } from '../../master-data.model';
import {UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'cms-master-data-select-vat-id',
  templateUrl: './master-data-select-vat-id.component.html',
  styleUrls: ['./master-data-select-vat-id.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MasterDataSelectVatIdComponent implements OnChanges, OnInit {
  @Input() newVisible = true;
  @Input() valID: EsmVatIds[];
  @Input() masterDataId: number;
  @Input() allowBankingDetailsDownload: boolean;

  @Input() set enableBankingDetailsCheckbox(b: boolean) {
    if(b){
      this.form.controls.allowBankingDetailsDownload.enable();
    }
  };

  get enableBankingDetailsCheckbox() {
    return this.form.controls.allowBankingDetailsDownload.enabled;
  };
  @Output() runNew = new EventEmitter();
  @Output() selectVatId = new EventEmitter();
  @Output() toggleAllowBankingDetailsDownload = new EventEmitter();
  form: UntypedFormGroup;
  valIDOptions: {label: string, value: any}[] = [];

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      masterDataId: this.masterDataId,
      allowBankingDetailsDownload: { value: this.allowBankingDetailsDownload, disabled: true }
    });


  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (changes.valID) {
      const emptyOption = { label: '', value: null };
      this.valIDOptions = this.valID ? [emptyOption, ...this.valID.map(({ masterDataId, vatId }) => ({
          value: masterDataId,
          label: vatId
        }))]
        : [emptyOption];
    }

    if (changes.masterDataId && this.form) {
      this.form.patchValue({
        masterDataId: this.masterDataId
      });
    }
  }

  onRunNew(): void {
    this.runNew.emit();
  }

  onSelect() {
    const { masterDataId } = this.form.getRawValue();
    this.selectVatId.emit(masterDataId);
  }

  toggle() {
    this.toggleAllowBankingDetailsDownload.emit(this.form.value.allowBankingDetailsDownload);
  }
}
