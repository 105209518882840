import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'cms-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AutoCompleteComponent),
    }
  ]
})
export class AutoCompleteComponent implements ControlValueAccessor {
  @Input() items: string[] = [];
  @Input() disabled = false;
  @Input() placeholder = '';
  @Input() maxlength;
  @Input() inputStyleClass = '';
  @Input() upperCase = false;
  _value: string;

  get value() {
    return this._value;
  }

  set value(value) {
    this._value = value && this.upperCase ? value.trim().toUpperCase() : value && value.trim();
    this.onChange(value);
    this.onTouched();
  }

  suggestions: string[];
  onChange: any = () => {};
  onTouched: any = () => {};

  onSearch(search): void {
    let { query } = search;
    if (query) {
      query = query.toLowerCase().trim();
      if (query.startsWith('*')) {
        this.suggestions = [...this.items];
      } else {
        this.suggestions = [...this.items.filter(item => item.toLowerCase().startsWith(query))];
      }
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    this.value = value && this.upperCase ? value.trim().toUpperCase() : value && value.trim();
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
