import { Prefix } from '../reducers';
import { OrganisationGroup } from '../../administration-tab-panel/administration.model';
import { LOAD_ORGANISATION_GROUPS_SUCCESS, LoadOrganisationGroupsSuccessAction } from './organisation-group.actions';
import { SET_TO_DEFAULT } from '../shared/shared.actions';

export interface OrganisationGroupState {
  orgGroupSelectorVisible: boolean;
  orgGroups: OrganisationGroup[];
}

export function organisationGroupReducer(prefix: Prefix, initialState) {
  return function (state: OrganisationGroupState = initialState,
                   action: LoadOrganisationGroupsSuccessAction): OrganisationGroupState {
    switch (action.type) {
      case `${prefix} ${LOAD_ORGANISATION_GROUPS_SUCCESS}`:
        const {
          organisationGroups,
          orgGroupSelectorVisible
          } = action.payload;

        return {
          orgGroups: organisationGroups,
          orgGroupSelectorVisible,

        };

      case `${prefix} ${SET_TO_DEFAULT}`:
        return {
          ...initialState
        };
      default:
        return state;
    }
  };
}

export const getOrgGroupsList = (state: OrganisationGroupState) => state.orgGroups;
export const getOrgGroupsVisible = (state: OrganisationGroupState) => state.orgGroupSelectorVisible;

