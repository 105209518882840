import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NavigationService} from '@common/menu/navigation.service';
import {MenuItem} from '@common/menu/menu.model';
import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import * as fromSelectors from '../state/organisation-groups/organisation-groups.selectors';
import {OrganisationGroup} from './organisation-groups/organisation-groups.model';

@Component({
  selector: 'cms-administration-tab-panel',
  templateUrl: './administration-tab-panel.component.html',
  styleUrls: ['./administration-tab-panel.component.scss']
})
export class AdministrationTabPanelComponent implements OnInit, OnDestroy {
  activeIndex$ = new BehaviorSubject<number>(0);
  myTab: string = '';
  tabs = [];
  orgGroupIndex: number;
  organisationIndex: number;
  userAccountIndex: number;
  userRoleIndex: number;
  fhaAccountIndex: number;
  partydataIndex: number;
  selected: OrganisationGroup;

  private onDestroy$ = new Subject();
  tabs$ = new BehaviorSubject<string[]>([]);

  constructor(private route: ActivatedRoute,
              private router: Router,
              private store: Store,
              public navigationService: NavigationService,) {
  }

  ngOnInit(): void {
    this.route.data
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data: { panel: string }) => {
        this.myTab = data.panel;
      });

    this.store.pipe(select(fromSelectors.getSelected)).pipe(takeUntil(this.onDestroy$))
      .subscribe((selected: OrganisationGroup) => {
        this.selected = selected;
      });

    this.navigationService
      .onNavigationChanged()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((navigation: MenuItem[]) => {

        if (this.navigationService.containsPath('admin/organisationGroups', navigation)) {
          this.tabs.push('organisationGroups');
          this.orgGroupIndex = this.tabs.indexOf('organisationGroups');
        }
        if (this.navigationService.containsPath('admin/organisations', navigation)) {
          this.tabs.push('organisations');
          this.organisationIndex = this.tabs.indexOf('organisations');
        }
        if (this.navigationService.containsPath('admin/userAccounts', navigation)) {
          this.tabs.push('userAccounts');
          this.userAccountIndex = this.tabs.indexOf('userAccounts');
        }
        if (this.navigationService.containsPath('admin/userRoles', navigation)) {
          this.tabs.push('userRoles');
          this.userRoleIndex = this.tabs.indexOf('userRoles');
        }
        if (this.navigationService.containsPath('admin/fhaAccounts', navigation)) {
          this.tabs.push('fhaAccounts');
          this.fhaAccountIndex = this.tabs.indexOf('fhaAccounts');
        }
        if (this.navigationService.containsPath('admin/partydata', navigation)) {
          this.tabs.push('partydata');
          this.partydataIndex = this.tabs.indexOf('partydata');
        }
        this.tabs$.next(this.tabs);
      });

    this.tabs$.pipe(takeUntil(this.onDestroy$)).subscribe(data => {
      if (data && data.length > 0 && this.myTab) {
        this.activeIndex$.next(data.indexOf(this.myTab));
      } else {
        this.activeIndex$.next(0);
      }
    });
  }

  onChangeTab({ index }: { index: number }): void {
    let queryParams;
    if (this.selected) {
      queryParams = {organisationGroupId: this.selected.organisationGroupId};
    }
    this.router.navigate([`admin/${this.tabs[index]}`], {queryParamsHandling: 'merge', queryParams});
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  getOrganisationIdParams(tab: string): { [key: string]: string } {
    const organisationGroupId = this.route.snapshot.queryParams.organisationGroupId;
    const isOrgIdNeeded = tab === 'organisations' || tab === 'userAccounts' || tab === 'userRoles' || tab === 'fhaAccounts' || tab === 'partydata';
    return isOrgIdNeeded ? { organisationGroupId } : {};
  }

}
