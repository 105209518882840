import { Message } from 'primeng/api';
import { DialogState, dialogReducer } from '@common/state/reducers';
import { DatatableData, PagingFilter } from '../../../common/shared/results.model';
import { Exception, ExceptionFilter, EditedItem, SelectedException } from '../../exceptions/exceptions.model';
import * as fromActions from './exceptions.actions';

export type EXCEPTIONS_DIALOGS = 'DELETE' | 'INFO';

const exceptionsDialogReducer = dialogReducer<EXCEPTIONS_DIALOGS>({
  OPEN_DIALOG: fromActions.openDialogAction.type,
  UPDATE_DIALOG: fromActions.updateDialogAction.type,
  CLOSE_DIALOG: fromActions.closeDialogAction.type,
  SET_TO_DEFAULT: fromActions.defaultDialogAction.type
});
export interface ExceptionsState {
  organisationId: number;
  loading: boolean;
  disabled: boolean;
  messages: Message[];
  availableQueues: string[];
  selectedItems: SelectedException;
  filters: ExceptionFilter;
  data: DatatableData<Exception[]>;
  editedItems: EditedItem[];
  dialog: DialogState<EXCEPTIONS_DIALOGS>;
  paging: PagingFilter;
}

export const initialState: ExceptionsState = {
  organisationId: null,
  loading: false,
  disabled: true,
  messages: [],
  availableQueues: ['All'],
  selectedItems: {},
  filters: {
    queue: '',
    to: '',
    from: ''
  },
  data: {
    values: null,
    count: 0,
    currentPage: 0
  },
  editedItems: [],
  dialog: null,
  paging: {
    page: 0,
    entriesPerPage: 25
  }
};

export function exceptionsReducer(state: ExceptionsState = { ...initialState },
  action: fromActions.ExceptionsActions): ExceptionsState {
  switch (action.type) {
    case fromActions.filter.type:
      return {
        ...initialState,
        loading: true,
        availableQueues: state.availableQueues,
        filters: action.payload
      };
    case fromActions.loadDataSuccessAction.type:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    case fromActions.setMessages.type:
      return {
        ...state,
        loading: false,
        messages: [...action.payload]
      };
    case fromActions.setFiltersValue.type:
      return {
        ...state,
        filters: action.payload
      };
    case fromActions.setToDefault.type:
      return {
        ...initialState,
        availableQueues: state.availableQueues
      };
    case fromActions.setOrganisationId.type:
      return {
        ...state,
        organisationId: action.payload
      };
    case fromActions.setAvailableQueues.type:
      return {
        ...state,
        loading: false,
        availableQueues: [...state.availableQueues, ...action.payload],
      };
    case fromActions.checkAction.type: {
      const selectedItems = { ...state.selectedItems };
      selectedItems[action.payload] = true;
      return {
        ...state,
        selectedItems,
        disabled: false
      };
    }
    case fromActions.uncheckAction.type: {
      const selectedItems = { ...state.selectedItems };
      selectedItems[action.payload] = false;
      const disabled = !(Object.values(selectedItems).some(value => !!value));
      return {
        ...state,
        selectedItems,
        disabled
      };
    }
    case fromActions.toggleCheckAllAction.type: {
      const selectedItems = { ...state.selectedItems };
      Object.keys(selectedItems).forEach(key => selectedItems[key] = action.payload);
      const disabled = !action.payload;
      return {
        ...state,
        selectedItems,
        disabled
      };
    }
    case fromActions.setSelectedItems.type: {
      const selectedItems = {};
      const selected = getPaginated([...state.data.values], state.paging.entriesPerPage, state.paging.page);
      selected.forEach(item => selectedItems[item.messageId] = false);
      return {
        ...state,
        selectedItems,
        disabled: true
      };
    }
    case fromActions.setEditedItems.type: {
      return {
        ...state,
        data: initialState.data,
        editedItems: action.payload
      };
    }
    case fromActions.setPaging.type:
      return {
        ...state,
        paging: action.payload
      };
    default:
      return {
        ...state,
        dialog: exceptionsDialogReducer(state.dialog, action)
      };
  }
}

export const getData = (state: ExceptionsState): DatatableData<Exception[]> => state.data;
export const getLoading = (state: ExceptionsState) => state.loading;
export const getDisabled = (state: ExceptionsState) => state.disabled;
export const getMessages = (state: ExceptionsState) => state.messages;
export const getFilters = (state: ExceptionsState) => state.filters;
export const getAvalableQueues = (state: ExceptionsState) => state.availableQueues;
export const getDialog = (state: ExceptionsState) => state.dialog;
export const getSelected = (state: ExceptionsState) => state.selectedItems;
export const getEditedItems = (state: ExceptionsState) => state.editedItems;
export const getPaging = (state: ExceptionsState) => state.paging;

const getPaginated = (data: Exception[], perPage: number, currPage: number) => {
  return data.slice(currPage * perPage, (currPage + 1) * perPage);
};


