<p-messages [closable]="true" [value]="(messages$ | async)?.masterData" class="col-12"></p-messages>
<cms-master-data-vat-details (cancel)="onCancel($event)"
                             (delete)="onMasterDataDelete()"
                             (save)="onSave($event)"
                             [editVisible]="(presetValues$ | async)?.editButtonsVisible"
                             [formValues]="formValues$ | async"
                             [isFormDisable]="isFormDisable$ | async"
                             [isNewMDRecord]="isNewMDRecord$ | async"
                             [messages]="(messages$ | async)?.masterData"
                             [organisationLegalName]="(presetValues$ | async)?.organisationLegalName">
</cms-master-data-vat-details>
<p-messages [closable]="true" [ngStyle]="{'visibility':( messages$ | async).bankDetails.length>0?'visible':'hidden'}" [value]="(messages$ | async)?.bankDetails" class="col-12 m-no-border "></p-messages>
<section *ngIf="(presetValues$ | async) && bankData$ | async as data" class="panel-box margin-top-12">
  <div class="margin-top-3 flex justify-content-start">
    <label class="text-bold form__label">Banking Details</label>
  </div>

  <cms-datatable (lazyLoad)="onLazyLoad($event)" styleClass="w-1000"
                 [currentPage]="(paging$ | async).page"
                 [data]="data.values"
                 [scrollable]="false"
                 [lazyLoadOnInit]="false"
                 [lazy]="!!(data && data.values && data.values.length)"
                 [loading]="loading$ | async"
                 [rows]="(paging$ | async).entriesPerPage"
                 [sortField]="(sorting$ | async).columnName"
                 [sortOrder]="(sorting$ | async).sortOrder"
                 [tableColumns]="dataTableColumns"
                 [totalRecords]="(bankData$ | async).count">
    <ng-template *ngIf="(presetValues$ | async)?.editButtonsVisible" pTemplate="caption">
      <div class="row margin-0">
        <cms-master-data-add-bank-detail
          (clear)="onClearAddBankDetail()"
          (confirm)="onAddBankDetail($event)"
          [data]="bankDetailValue$ | async"
          [loading]="loading$ | async"
          [messages]="(messages$ | async)?.bankDetails"
          [version]="(formValues$ |async)?.version">
        </cms-master-data-add-bank-detail>
        version: {{(formValues$ | async)?.version}}
      </div>
    </ng-template>
    <ng-template let-column="column" let-row="row" pTemplate="body">
      <td *ngIf="column.type === 'deleteButton'" [ngStyle]="column.style">
        <div class="btn-wrapper">
          <button (click)="onDeleteBankDetail(row)" class="btn btn-icon fa fa-times" label="Delete" title="Delete Bank Detail" type="button"></button>
        </div>
      </td>
      <td *ngIf="column.type === 'editable'"
          [ngClass]="{'editable-text': (presetValues$ | async)?.editButtonsVisible}"
          [ngStyle]="column.style"
          cmsCellEditor>
        <span *ngIf="!(presetValues$ | async)?.editButtonsVisible">{{row[column.name]}}</span>
        <cms-cell-editor (cancelEdit)="onCancelBDEdit()"
                         (editComplete)="onCompleteBDEdit($event)"
                         *ngIf="(presetValues$ | async)?.editButtonsVisible"
                         [column]="column"
                         [editable]="true"
                         [maxValueLength]="column.maxLength"
                         [messages]="(messages$ | async)?.bankDetails"
                         [row]="row"
                         [value]="row[column.name]">
        </cms-cell-editor>
      </td>
    </ng-template>
  </cms-datatable>
</section>

<ng-container *ngIf="(dialog$ | async) as dialog">
  <p-dialog (onHide)="onCancelDelete()"
            (visibleChange)="onCancelDelete()"
            *ngIf="dialog?.name === 'DELETE'"
            [header]="'Confirm that you want to delete the master data stored for VAT ID ' + (selectedVatId$ | async)?.vatId"
            [modal]="true"
            [style]="{width: '800px', minWidth: '420px'}"
            [visible]="dialog?.name === 'DELETE'">
    <p-footer>
      <button (click)="onCancelDelete()" class="btn margin-right-3" type="button">Cancel</button>
      <button (click)="onConfirmDelete()" class="btn" type="button">Ok</button>
    </p-footer>
  </p-dialog>
</ng-container>
