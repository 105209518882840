<ng-container *ngIf="errorMessageService.hasError(); else content">
  <p-messages [value]="errorMessageService.on503Error() | async" [closable]="false" class="col-12"></p-messages>
</ng-container>

<ng-template #content>
  <cms-headline pageTitle="Standing Instructions" [displayOrganisationGroupName]="true"></cms-headline>

  <div class="panel-box">

    <cms-info ngPreserveWhitespaces>
      <!--@formatter:off (markdown rendering breaks, when the following tags are formatted)-->
      <u>**Standing Instructions**</u>

      **Purpose of Standing Instructions**

      * **Standing Instructions** are static data values which are used to enrich counterparty information in eRR reporting CpML documents.
      * The enrichment is optional and only applied if the values are not contained in the CpML document itself.

      **Information in the List**

      * **Org Group:** organisation group, for internal parties only to highlight them
      * **Display Name**: party name shown to your users in the eRR GUI
      * **EIC Code**: the EIC of the party
      * **LEI Code**: the LEI of the party
      * **Party Type**: indicator whether the party is an internal CMS party, a party you have set ROBO Delegators for or simply a party that is a counterparty in your trades
      * **Nature**: financial nature of the party
      * **Sector**: corporate sector(s) of the party
      * **Trading Capacity**: indicator whether the party's capacity is agent or principal
      * **Beneficiary**: the LEI or EIC code of the beneficiary
      * **Comm. Activity**: indicator whether trade is directly linked to commercial activity or treasury financing
      * **Clearing Threshold**: indicator whether above or below clearing threshold
      * **Coll.**: type of collateralisation
      * **Coll. Portfolio**: indicator whether there is a collateral portfolio
      * **Coll. Portfolio Code**: code of the collateral portfolio
      * **Agreement V**: version of the Master Agreement

      **Maintaining the List (Master Operators only)**

      * In the upload panel click on Download to export your current standing instructions in CSV format
      * Edit the file and upload it with the Browse and Upload buttons

      **CSV Format for Uploads**

      * Your files need to be UTF-8 encoded and comma-separated with optional quoting of values
      * The header line with column names is required
      * Limits: 5000 records with a maximum file size of 5 MB.
      * if you want to delete an existing record, you need to set the 'Delete' flag in the csv to "Y"

      | #  | Name  | Length | Usage | Contents  |
      |----|-------|--------|-------|-----------|
      | 1  | LEI_CODE             | 20     | M     | LEI of external entity - primary identifier                                                                           |
      | 2  | DISPLAY_NAME         | 1-20   | M     | entity name to show to your users (eRR GUI), all letters will be converted to uppercase; if the party is a CMS party or has already been set up as a ROBO delegator by you, the display name that is already in use will overwrite the value you provide here                                                                           |
      | 3  | CP_FINANCIAL_NATURE  | 1      | M     | F = Financial, N = Non-financial institution, C = Central, O = Other                                                                                |
      | 4  | CP_CORP_SECTORS      | 1-60   | C     | Mandatory for financial (field 3 = F) and non-financial (field 3 = N) institutions, otherwise blank. Multiple blank-separated values allowed (e.g. "A C I") <br/><br/><u>Financial</u>: A = Assurance undertaking, C = Credit institution, D = Central securities depository, F = Investment firm, I = Insure undertaking, L = Alternative investment fund, O = Occupational retirement provision, R = Reinsurance undertaking, U = UCITS<br/><br/><u>Non-Financial</u>: 1 = Agriculture, forestry and fishing, 2 = Mining and quarrying , 3 = Manufacturing, 4 = Electricity, gas, steam and air conditioning supply, 5 = Water supply, sewerage, waste management and remediation activities, 6 = Construction, 7 = Wholesale and retail trade, repair of motor vehicles and motorcycles, 8 = Transportation and storage, 9 = Accommodation and food service activities, 10 = Information and communication, 11 = Financial and insurance activities, 12 = Real estate activities, 13 = Professional, scientific and technical activities, 14 = Administrative and support service activities, 15 = Public administration and defence; compulsory social security, 16 = Education, 17 = Human health and social work activities, 18 = Arts, entertainment and recreation, 19 = Other service activities, 20 = Activities of households as employers,  21 = Activities of extraterritorial organisations and bodies |
      | 5  | TRADING_CAPACITY     | 1      | M     | A = Agent, P = Principal                                                                                              |
      | 6  | BENEFICIARY_ID       | 20     | C     | Mandatory for trading capacity "Agent" (field 5 = A), otherwise blank the LEI or EIC code of the beneficiary                                                                                     |
      | 7  | COMMERCIAL_ACTIVITY  | 1      | C     | Mandatory for non-financial institutions (field 3 = N), otherwise blank. Y = trade directly linked to commercial activity or treasury financing, otherwise N                                                                                   |
      | 8  | CLEARING_THRESHOLD   | 1      | C     | Optional for financial and non-financial institutions (field 3 = F or N), otherwise blank. Y = above threshold, N = below threshold                                                                                 |
      | 9  | COLLATERALISATION    | 1-2    | C     | Not allowed for central counterparties (field 3 = C), otherwise optional U = Uncollateralised, PC = Partially Collateralised, OC = One way collateralised, FC = Fully collateralised                                                                         |
      | 10 | COLLATERAL_PORTFOLIO | 1      | C     | Mandatory if collateralisation (field 9) is set to PC, OC or FC, otherwise blank Y = collateralisation at portfolio level, N = collateralisation per trade                                                                                   |
      | 11 | COLL_PORTFOLIO_CODE  | 1-52   | C     | Mandatory if collateralisation at portfolio level applies (field 10 = Y), otherwise blank Up to 52 alphanumerical characters and special characters ":", ".", "-" and "_". Special characters not allowed at the beginning or end.                                                                        |
      | 12 | MASTER_AGREE_VERSION | 4      | M     | 4 numerical digits, e.g. 2013                                                                                         |
      | 13 | DELETE               | 1      | O     | Y = this record is to be deleted                                                                                      |
      <!--@formatter:on-->
    </cms-info>
    <p-messages [value]="messages$ | async" closable="false"></p-messages>

    <cms-standing-instructions-upload></cms-standing-instructions-upload>
    <div>
      <cms-standing-instructions-datatable></cms-standing-instructions-datatable>
    </div>
  </div>

</ng-template>
