import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'cms-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoComponent {
  @Input() selected: boolean;
  @Input() header = 'Click for help...';
  @Input() value: string;
  @Input() fullWidth: boolean;

  get full() {
    if (!this.fullWidth){
      return ' decent-max-width';
    }
  }
}

