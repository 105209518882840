import { DateRangeFilter } from '../../../../common/shared/shared.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

import { SmtDealFinderTransactionsService } from '../smt-dealfinder-transactions.service';
import { AbstractFormComponent } from './abstract-filter.component';
import { UrlReferenceModel, UrlReferenceService } from '../../../../common/url-reference.service';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as fromFilterActions from '../../../../esm/state/invoice-finder/filters/filters.actions';

@Component({
  selector: 'cms-smt-dealfinder-daterange-filter',
  templateUrl: './smt-dealfinder-daterange-filter.component.html',
})
export class SmtDealFinderDateRangeFilterComponent extends AbstractFormComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject();
  dateRangeFilterFormGroup: UntypedFormGroup;

  today: Date;
  tradeDate: any;


  constructor(private transactionsService: SmtDealFinderTransactionsService,
              private urlReferenceService: UrlReferenceService,
              private formBuilder: UntypedFormBuilder) {
    super();

    if(hasData()){
      this.tradeDate = getTradeDate();
    }
  }

  public ngOnInit(): void {

    this.today = new Date();
    this.createForm();
  }

  private createForm(): void {
    this.dateRangeFilterFormGroup = this.formBuilder.group({
      fromTradeDate: [this.today, [Validators.required]],
      toTradeDate: [this.today, [Validators.required]]
    }, { validator: this.validateThatToTradeDateIsAfterOrEqualFromTradeDate });

    if (this.tradeDate) {
      this.dateRangeFilterFormGroup.setValue(this.tradeDate);
    }

    this.dateRangeFilterFormGroup.valueChanges.pipe(
      takeUntil(this.onDestroy$))
      .subscribe(value => {
        if (value.fromTradeDate === null && value.toTradeDate === null) {
          this.setFormControlValue('fromTradeDate', this.today);
          this.setFormControlValue('toTradeDate', this.today);
        }
      });
  }

  private handleUrlReferenceFilter(): void {
    const urlReferenceModel: UrlReferenceModel = this.urlReferenceService.getAndResetUrlReferenceModel();
    if (urlReferenceModel != null) {
      this.tradeDate = JSON.parse(urlReferenceModel.targetRequestParams);
    }
  }

  public onSubmitDateRangeFilter() {
    this.setFormSubmitAttempt(true);
    if (this.isFormValid()) {
      const fromTradeDateValue: Date = this.getFromTradeDateFormControlValue();
      const toTradeDateValue: Date = this.getToTradeDateFormControlValue();
      this.transactionsService.emitChangeTransactionDateFilter(DateRangeFilter.of({
        start: fromTradeDateValue,
        end: toTradeDateValue
      }));
    }
  }

  private getFromTradeDateFormControlValue(): Date {
    return this.getFormControlValue('fromTradeDate');
  }

  private getToTradeDateFormControlValue(): Date {
    return this.getFormControlValue('toTradeDate');
  }

  public onResetDateRangeFilter() {
    this.resetForm();
    this.onSubmitDateRangeFilter();
  }

  public isFromTradeDateRequiredError() {
    return this.isRequiredError('fromTradeDate');
  }

  public isToTradeDateRequiredError() {
    return this.isRequiredError('toTradeDate');
  }

  public isToTradeDateIsNotAfterOrEqualFromTradeDateError() {
    return this.isError('toTradeDate', 'toTradeDateIsNotAfterOrEqualFromTradeDate');
  }

  public ngOnDestroy(): void {
    // Clean up all subscriptions at once:
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  private validateThatToTradeDateIsAfterOrEqualFromTradeDate(group: UntypedFormGroup): any {

    if (group.controls['fromTradeDate'].value != null && group.controls['toTradeDate'].value != null) {
      const fromTradeDateValue: Date = new Date(group.controls['fromTradeDate'].value);
      const toTradeDateValue: Date = new Date(group.controls['toTradeDate'].value);
      if (fromTradeDateValue.getTime() > toTradeDateValue.getTime()) {
        group.controls.toTradeDate.setErrors({
          'toTradeDateIsNotAfterOrEqualFromTradeDate': {
            value: toTradeDateValue
          }
        });
      }
    }
  }

  protected getFormGroup(): UntypedFormGroup {
    return this.dateRangeFilterFormGroup;
  }

}

function getTradeDate() {
  return JSON.parse(window.history.state?.targetRequestParams);
}

function hasData(): boolean {
  return !!window.history.state?.targetRequestParams;
}
