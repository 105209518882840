import {Component, forwardRef, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {DropDownItem} from '../../invoice-settings.model';

@Component({
  selector: 'cms-auto-complete',
  templateUrl: './invoice-auto-complete.component.html',
  styleUrls: ['./invoice-auto-complete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InvoiceAutoCompleteComponent),
    }
  ]
})
export class InvoiceAutoCompleteComponent {
  @Input() disabled = false;
  @Input() placeholder = '';
  @Input() inputStyleClass = '';
  @Input() set items(values: string[]) {
    if (values) { this.dropDown = values.map(x => ({'value': x})); }
  }

  _value: DropDownItem;
  dropDown : DropDownItem[];


  get value() {
    return this._value;
  }

  set value(item:DropDownItem) {
    this._value=item;
    this.onChange(item);
    this.onTouched(item);
  }

  onChange: any = () => { };
  onTouched: any = () => { };


  registerOnChange(fn): void {
    this.onChange = fn;
  }

  writeValue(value:DropDownItem): void {
    this.value = value;
  }

  registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
