import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './index';

export const getRoboState = createFeatureSelector<State>('robo-delegators');

export const getRoboDelegatorsState = createSelector(
  getRoboState,
  state => state.roboDelegators
);

export const selectUploadPanelVisible = createSelector(
  getRoboDelegatorsState,
  state => state.uploadPanelVisible
);

export const selectUploadAllowed = createSelector(
  getRoboDelegatorsState,
  state => state.uploadAllowed
);


