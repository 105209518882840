import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Message } from 'primeng/api';
import { RemitTable1TradeService } from '../remit-table1-trade.service';
import { EndPointConstants } from '../../../../../common/end-point-constants';
import { DateUtils } from '../../../../../common/shared/date.utils';

@Component({
  selector: 'cms-remit-table1-trade-cancel',
  templateUrl: './remit-table1-trade-cancel.component.html',
  styleUrls: ['./remit-table1-trade-cancel.component.scss']
})
export class RemitTable1TradeCancelComponent implements OnInit, OnDestroy {
  cancelForm: UntypedFormGroup;
  recordType: string;
  historyId: number;

  msgs: Message[] = [];
  disabled: boolean = false;
  inProgress: boolean = false;
  internalError: boolean = false;

  private onDestroy$ = new Subject();
  submitEnabled$: Observable<boolean> = of(false);

  constructor(formBuilder: UntypedFormBuilder,
              private service: RemitTable1TradeService,
              private route: ActivatedRoute,
              private titleService: Title) {
    this.cancelForm = formBuilder.group({
      transactionDateUtc: ['', [Validators.required]],
      transactionTimeUtc: ['', [Validators.required]],
      terminationDate: ['', [Validators.required]],
      traderId: ['', [Validators.required]]
    });
  }

  ngOnInit() {

    this.msgs = [];
    this.inProgress = false;

    this.titleService.setTitle('Cancel Trade');

    this.route.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe((params: Params) => {
      this.recordType = params[EndPointConstants.PARAM_RECORD_TYPE];
      this.historyId = params[EndPointConstants.PARAM_HISTORY_ID];
    });

    this.service.loadPresetsCancellation(this.recordType, this.historyId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(response => {

        if(response){

          this.cancelForm.disable();

          if (response.validationFailureMessages) {
            this.disabled = true;
            this.internalError = true;
            for (let i = 0; i < response.validationFailureMessages.length; i++) {
              this.msgs = [...this.msgs, { severity: 'error', summary: response.validationFailureMessages[i] }];
            }
          } else {
            this.submitEnabled$ = of(response.submitEnabled);
            this.disabled = false;
            const transactionDateTimeUTC = new Date(response.transactionTimeUtc);
            const transactionTimeUtc = this.getFormattedTime(transactionDateTimeUTC);
            const terminationDate = new Date(response.terminationDate);
            const traderId = response.traderId;

            if (transactionTimeUtc) {
              this.cancelForm.controls['transactionTimeUtc'].setValue(transactionTimeUtc);
            }

            if (transactionDateTimeUTC) {
              this.cancelForm.controls['transactionDateUtc'].setValue(transactionDateTimeUTC);
            }
            if (terminationDate) {
              this.cancelForm.controls['terminationDate'].setValue(terminationDate);
            }
            if (traderId) {
              this.cancelForm.controls['traderId'].setValue(traderId);
            }
            this.internalError = false;

            if (!this.disabled && response.submitEnabled) {
              this.cancelForm.enable();
            }
          }
        }

      }, error => {
        this.disabled = true;
        this.internalError = true;

        let errorDetail = '';
        if (error.error && error.error.errorMessage) {
          errorDetail = error.error.errorMessage;
        }

        if (error.statusText === 'Unauthorized') {
          this.msgs = [...this.msgs, { severity: 'error', summary: error.statusText }];
        } else {
          this.msgs = [...this.msgs, { severity: 'error', summary: error.statusText, detail: errorDetail }];
        }
      });
  }

  isFormValid(): boolean {
    if (!this.cancelForm.valid) {
      if (this.cancelForm.controls['transactionDateUtc'].invalid) {
        this.msgs = [...this.msgs, { severity: 'error', summary: 'Field \'Trade time (UTC) - date part\' is required.' }];
      }

      if (this.cancelForm.controls['transactionTimeUtc'].invalid) {
        if (this.cancelForm.controls['transactionTimeUtc'].value) {
          this.msgs = [...this.msgs, { severity: 'error', summary: 'Invalid format for \'Trade time (UTC) - time part\'.' }];
        } else {
          this.msgs = [...this.msgs, { severity: 'error', summary: 'Field \'Trade time (UTC) - time part\' is required.' }];
        }
      }

      if (this.cancelForm.controls['terminationDate'].invalid) {
        this.msgs = [...this.msgs, { severity: 'error', summary: 'Field \'Termination Date\' is required.' }];
      }

      if (this.cancelForm.controls['traderId'].invalid) {
        this.msgs = [...this.msgs, { severity: 'error', summary: 'Field \'Trader ID\' is required.' }];
      }

      return false;
    }
    return true;
  }

  submit() {
    this.msgs = [];
    if (this.isFormValid()) {
      this.inProgress = true;
      this.disabled = true;

      const transactionDate: Date = this.cancelForm.controls['transactionDateUtc'].value;
      const transactionTime = this.cancelForm.controls['transactionTimeUtc'].value.split(':');

      if (this.cancelForm.value.transactionTimeUtc && transactionTime.length === 3) {
        transactionDate.setHours(parseInt(transactionTime[0], 10), parseInt(transactionTime[1], 10), parseInt(transactionTime[2], 10));
      }

      const terminationDate = this.cancelForm.controls['terminationDate'].value;

      const searchQuery = {
        transactionTimeUtc: DateUtils.formatToLocalDateTime(transactionDate),
        terminationDate: DateUtils.getLocalDateFormat(terminationDate),
        traderId: this.cancelForm.controls['traderId'].value
      };

      this.service.submitCancellation(this.recordType, this.historyId, searchQuery)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(response => {
          if (response.validationFailureMessages) {
            for (let i = 0; i < response.validationFailureMessages.length; i++) {
              this.msgs = [...this.msgs, { severity: 'error', summary: response.validationFailureMessages[i] }];
            }
            this.disabled = false;
          } else {
            this.cancelForm.disable();
            this.msgs = [...this.msgs, { severity: 'success', summary: 'Cancellation submitted successfully.' }];
          }
          this.inProgress = false;
        }, error => {

          let errorDetail = '';
          if (error.error && error.error.errorMessage) {
            errorDetail = error.error.errorMessage;
          }
          this.inProgress = false;
          if (error.statusText === 'Unauthorized') {
            this.msgs = [...this.msgs, { severity: 'error', summary: error.statusText }];
          } else {
            this.msgs = [...this.msgs, { severity: 'error', summary: error.statusText, detail: errorDetail }];
          }

        });
    }
  }

  isInProgress(): boolean {
    return this.inProgress;
  }

  ngOnDestroy(): void {
    this.submitEnabled$ = of(false);
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  private getFormattedTime(date: Date): string {

    const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    const seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();

    return hour + ':' + minutes + ':' + seconds;
  }

}
