import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { Message } from 'primeng/api';
import { OrganisationModel } from '@common/organisation.model';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { Column } from '@common/shared/datatable.model';
import { DatatableState } from '@common/datatable/datatable.model';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { OrganisationService } from '@common/organisation.service';
import { OrganisationGroup } from '../administration.model';
import { DialogState } from '../../state/dialog/dialog.reducer';
import { defaultStyles } from '../../../master-data/etd-products/etd-products.model';
import { SetToDefaultAction } from '../../state/shared/shared.actions';

import {
  AddOrganisation,
  DeleteOrganisation,
  EditEcm,
  EditErr,
  EditEsm,
  EditOrganisation,
  MoveOrganisation,
  Organisation,
  OrganisationsFilters
} from './organisations.model';
import { AdminOrganisationFormState, OrganisationsState } from '../../state/organisation/organisation.reducer';
import { Prefix } from '../../state/reducers';
import { EditableCell } from '@common/datatable/cell-editor/cell-editor.model';
import { OrganisationActivation } from './dialogs-container/activation-dialog/activation.model';
import * as fromDialogAction from '../../state/dialog/dialog.actions';
import * as fromSelectors from '../../state/organisation/organisation.selectors';
import * as fromOrgActions from '../../state/organisation/organisation.actions';
import { LoadOrganisationFormAction } from '../../state/organisation/organisation.actions';
import * as fromDbActions from '../../state/datatable/datatable.actions';
import { SetOrganisationIdAction } from '../../state/organisation-id/organisation-id.actions';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'cms-organisations',
  templateUrl: './organisations.component.html',
  styleUrls: ['./organisations.component.scss']
})
export class OrganisationsComponent implements OnInit, OnDestroy {
  data$: Observable<DatatableData<Organisation[]>>;
  loading$: Observable<boolean>;
  sorting$: Observable<SortingFilter>;
  filters$: Observable<OrganisationsFilters>;
  orgGroups$: Observable<OrganisationGroup[]>;
  form$: Observable<AdminOrganisationFormState>;
  editableData$: Observable<EditableCell>;
  messages$: Observable<Message[]>;
  dialog$: Observable<DialogState>;
  organisationsId$: Observable<number>;
  paging$: Observable<PagingFilter>;
  orgGroupSelectorVisible = false;
  fhaUser = false;
  selectedOrgGroup$: Observable<OrganisationGroup>;
  tableColumns$: Observable<Column[]>;

  orgGroupSelectorVisibleSubscription$: Subscription;
  selectedSubscription$: Subscription;
  querySubscription$: Subscription;
  subscription$: Subscription;

  readonly dataTableColumns: Column[] = [
    { name: 'organisationGroupDisplayName', type: 'text', title: 'Org. Group', style: { ...defaultStyles }, sortable: true, visible: true },
    { name: 'orgGroupId', type: 'orgGrpId', title: 'Org. Group ID', style: { ...defaultStyles }, sortable: true, visible: false },
    { name: 'displayName', type: 'text', title: 'Display Name', style: { ...defaultStyles }, sortable: true, visible: true },
    { name: 'eicCode', type: 'text', title: 'EIC Code', style: { ...defaultStyles }, sortable: true, visible: true },
    { name: 'ecmProcessRole', type: 'text', title: 'eCM Role', style: { ...defaultStyles, 'width': '100px' }, sortable: true, visible: true },
    { name: 'errProcessRole', type: 'text', title: 'eRR Role', style: { ...defaultStyles, 'width': '90px' }, sortable: true, visible: true },
    { name: 'esmRole', type: 'text', title: 'eSM Role', style: { ...defaultStyles, 'width': '90px', 'text-align': 'center' }, sortable: true, visible: true },
    { name: 'legalName', type: 'text', title: 'Legal Name', style: { ...defaultStyles, 'width': '190px' }, sortable: true, visible: true },
    { name: 'elcomCode', type: 'text', title: 'Elcom', style: { ...defaultStyles, 'width': '100px' }, sortable: true, visible: true },
    { name: 'active', type: 'status', title: 'Active', style: { ...defaultStyles, 'width': '65px', 'text-align': 'center' }, sortable: true, visible: true },
  ];
  selected: Organisation;
  prefix: Prefix = '[ORGANISATION]';


  constructor(private store: Store<OrganisationsState>,
              private activatedRoute: ActivatedRoute,
              private mapper: MessagesMapperService,
              private router: Router,
              private organisationService: OrganisationService) {

    this.data$ = this.store.pipe(select(fromSelectors.getData));
    this.loading$ = this.store.pipe(select(fromSelectors.getLoading));
    this.sorting$ = this.store.pipe(select(fromSelectors.getSorting));
    this.paging$ = this.store.pipe(select(fromSelectors.getPaging));
    this.filters$ = this.store.pipe(select(fromSelectors.getFilters));
    this.orgGroups$ = this.store.pipe(select(fromSelectors.getOrgGroupsList));
    this.form$ = this.store.pipe(select(fromSelectors.getFormValues));
    this.selectedOrgGroup$ = this.store.pipe(select(fromSelectors.getSelectedOrgFilterName));
    this.editableData$ = this.store.pipe(select(fromSelectors.getEditableData));
    this.messages$ = this.store.pipe(select(fromSelectors.getMessages));
    this.organisationsId$ = this.store.pipe(select(fromSelectors.getOrganisationId));
    this.orgGroupSelectorVisibleSubscription$ = this.store.pipe(select(fromSelectors.getOrgGroupSelectorVisible))
      .subscribe((orgGroupSelectorVisible: boolean) => {
        this.orgGroupSelectorVisible = orgGroupSelectorVisible;
      });
    this.filters$ = this.store.pipe(select(fromSelectors.getFilters));
    this.dialog$ = this.store.pipe(select(fromSelectors.getDialog));

    this.selectedSubscription$ = this.store.pipe(select(fromSelectors.getSelected))
      .subscribe((selected: Organisation) => {
        this.selected = selected;
      });

    this.tableColumns$ = this.store.pipe(
      select(fromSelectors.getOrgGroupIdColumnVisible),
      map(fhaUser => this.fhaUser = fhaUser),
      map(this.orgIdColumnVisible));
  }

  onOrganisationSelected(organisation: Organisation): void {
    this.store.dispatch(new fromDbActions.SelectAction(this.prefix, organisation));
  }

  onLazyLoad({page, perPage, sortField, sortOrder}: DatatableState): void {
    this.store.dispatch(new fromDbActions.SortAndPageDataAction(this.prefix,{
      paging: {
        page,
        entriesPerPage: perPage
      },
      sorting: {
        columnName: sortField,
        sortOrder
      }
    }));
  }

  onOrgGroupFilter(filters: string): void {
    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: {organisationGroupId: filters } });
  }

  onTextFieldFilter(filters: string): void {
    this.store.dispatch(new fromDbActions.UpdateFilterAction(this.prefix, {filterValue: filters}));
  }

  onExport(): void {
    this.store.dispatch(new fromOrgActions.ExportAction());
  }

  onChangeStatus({ data, newValue }: EditableCell): void {
    this.store.dispatch(new fromDbActions.SetEditableDataAction(this.prefix, { data, newValue }));
    this.store.dispatch(new fromDialogAction.OpenDialogAction(this.prefix, {
      name: 'STATE',
    }));
  }

  onCancelDialog(messages: Message[]): void {
    this.store.dispatch(new fromDbActions.SetMessagesAction(this.prefix, messages));
    this.store.dispatch(new fromDbActions.SetEditableDataAction(this.prefix, null));
    this.store.dispatch(new fromDialogAction.CloseDialogAction(this.prefix));
  }

  onConfirmActivationChange(obj: OrganisationActivation): void {
    this.store.dispatch(new fromOrgActions.UpdateActivationAction(obj));
  }

  ngOnInit(): void {
    this.store.dispatch(new LoadOrganisationFormAction(this.prefix));
    this.store.dispatch(new SetOrganisationIdAction(this.prefix, this.organisationService.getCachedOrganisationId()));

    this.initFilters();

    this.subscription$ = this.organisationService
      .getChangeOrganisationObservable()
      .pipe(
        withLatestFrom(this.store.pipe(select(fromSelectors.getOrganisationId))))
      .subscribe(([organisation, organisationId]: [OrganisationModel, number]) => {
        if ((organisation && organisation.organisationId !== organisationId) || (!organisation && organisationId)) {
          this.store.dispatch(new SetToDefaultAction(this.prefix));
          this.store.dispatch(new LoadOrganisationFormAction(this.prefix));
          this.store.dispatch(new SetOrganisationIdAction(this.prefix, organisation ? organisation.organisationId : null));
          const { organisationGroupId } = this.activatedRoute.snapshot.queryParams;
          this.store.dispatch(new fromDbActions.UpdateFilterAction(this.prefix, { organisationGroupId }));
        }
      });
  }

  onEditOrganisation(): void {
    this.store.dispatch(new fromDialogAction.OpenDialogAction(this.prefix, {
      name: 'EDIT'
    }));
  }

  onEditEcm(): void {
    this.store.dispatch(new fromOrgActions.LoadEcmPresetValuesAction({ selectedOrganisationId: this.selected.orgId}));
  }

  onEditErr(): void {
    this.store.dispatch(new fromOrgActions.LoadErrPresetValuesAction({ selectedOrganisationId: this.selected.orgId}));
  }

  onEditEsm(): void {
    this.store.dispatch(new fromOrgActions.LoadEsmPresetValuesAction({ selectedOrganisationId: this.selected.orgId}));
  }

  onAddOrganisation(): void {
    this.store.dispatch(new fromDialogAction.OpenDialogAction(this.prefix, {
      name: 'ADD',
    }));
  }

  onMoveOrganisation(): void {
    this.store.dispatch(new fromDialogAction.OpenDialogAction(this.prefix, {
      name: 'MOVE',
    }));
  }

  onDeleteOrganisation(): void {
    this.store.dispatch(new fromOrgActions.DeleteAction());
  }

  onConfirmMoveOrganisation(obj: MoveOrganisation): void {
    this.store.dispatch(new fromOrgActions.MoveOrganisationAction(obj));
  }

  onConfirmEditEsm(obj: EditEsm): void {
    this.store.dispatch(new fromOrgActions.EditEsmAction(obj));
  }

  onConfirmDeleteOrganisation(obj: DeleteOrganisation): void {
    this.store.dispatch(new fromOrgActions.ConfirmDeleteOrganisationAction(obj));
  }

  onConfirmAddOrganisation(obj: AddOrganisation): void {
    this.store.dispatch(new fromOrgActions.AddOrganisationAction(obj));
  }

  onConfirmEditOrganisation(obj: EditOrganisation): void {
    this.store.dispatch(new fromOrgActions.EditOrganisationAction(obj));
  }

  onConfirmEditEcm(obj: EditEcm): void {
    this.store.dispatch(new fromOrgActions.EditEcmAction(obj));
  }

  onConfirmEditErr(obj: EditErr): void {
    this.store.dispatch(new fromOrgActions.EditErrAction(obj));
  }

  ngOnDestroy(): void {
    this.store.dispatch(new SetToDefaultAction(this.prefix));
    this.subscription$.unsubscribe();
    this.selectedSubscription$.unsubscribe();
    this.querySubscription$.unsubscribe();
    this.orgGroupSelectorVisibleSubscription$.unsubscribe();
    this.selected = null;
  }

  getPlaceholderText(): string {
    return this.fhaUser ? 'Name / Code / Org. Group ID' : 'Name or Code';
  }

  private initFilters(): void {
    this.querySubscription$ = this.activatedRoute.queryParams.subscribe(queryParams => {
      const organisationGroupId = queryParams.organisationGroupId || null;
      this.store.dispatch(new fromDbActions.UpdateFilterAction(this.prefix, { organisationGroupId }));
    });
  }

  private orgIdColumnVisible = (visible: boolean) => {
    const columns = [...this.dataTableColumns];
    columns[1].visible = visible;
    return columns;
  }
}
