import { SmtReportingStatusFilter, smtReportingStatusFilterAsString, SmtTransactionDay } from '../smt-filter-model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { Subject } from 'rxjs';
import { SmtDashboardService } from './smt-dashboard-service';
import { OrganisationService } from '../../../common/organisation.service';
import { takeUntil } from 'rxjs/operators';
import { ErrorMessageService } from '@common/errors/error-message.service';

@Component({
  selector: 'cms-smt-dashboard',
  templateUrl: './smt-dashboard.component.html',
  styleUrls: ['./smt-dashboard.component.scss']
})
export class SmtDashboardComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject();
  data: TreeNode[];
  loading: boolean;

  constructor(private dashboardService: SmtDashboardService,
              private organisationService: OrganisationService,
              public readonly errorMessageService:ErrorMessageService) {
  }

  ngOnInit() {
    this.loading = true;
    this.organisationService.getChangeNotNullOrganisationObservable().pipe(
      takeUntil(this.onDestroy$))
      .subscribe((organisation) => {
        this.dashboardService.getDashboard().pipe(takeUntil(this.onDestroy$))
          .subscribe(data => {
            this.loading = false;
            this.data = data;
          }, () => {
            this.loading = false;
          });
      });
  }

  getStatusOutput(status: SmtReportingStatusFilter) {
    if (status) {
      return smtReportingStatusFilterAsString(status);
    }
    return '';
  }

  getQueryParams(data, day: SmtTransactionDay) {
    const regime = data['_regime'] || data['regime'];
    const status = data['_status'] || data['status'];
    const reporter = data['reporter'];
    const reporterId = data['reporterId'];
    const queryParams = {};

    if (regime) {
      queryParams['regime'] = regime;
    }
    if (status) {
      queryParams['status'] = status;
    }
    if (reporter) {
      queryParams['reporter'] = reporter;
    }
    if (reporterId) {
      queryParams['reporterId'] = reporterId;
    }
    if (day) {
      queryParams['day'] = day;
    }
    return queryParams;
  }

  public ngOnDestroy(): void {
    // Clean up all subscriptions at once:
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  public hasBuckets(): boolean {
    return this.data && this.data.length > 0;
  }

}
