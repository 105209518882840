import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { RemitTradeEntryTable1ModifyService } from '../remit-trade-entry-table1-modify.service';
import { takeUntil } from 'rxjs/operators';
import { DateUtils } from '@common/shared/date.utils';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'cms-remit-trade-entry-table1-modify-references',
  templateUrl: './remit-trade-entry-table1-modify-references.component.html',
  styleUrls: ['./remit-trade-entry-table1-modify-references.component.scss']
})
export class RemitTradeEntryTable1ModifyReferencesComponent implements OnInit, OnDestroy {

  @Input()
  formGroup: UntypedFormGroup;

  beneficiaries: SelectItem[];

  referenceModel: ReferenceTradeEntryModel;

  validationErrors: string[] = [];

  private onDestroy$ = new Subject();

  constructor(private tradeEntryModifyService: RemitTradeEntryTable1ModifyService) {
  }

  get hasLinkedTransactionId(): boolean {
    return this.formGroup.controls['linkedTransactionId'] != null;
  }

  appendValidationErrorClass(fieldName: string): string {
    if (this.validationErrors[fieldName]) {
      return 'error';
    }
  }

  ngOnInit() {
    this.formGroup.addControl('transactionDate', new UntypedFormControl(''));
    this.formGroup.addControl('transactionTime', new UntypedFormControl(''));
    this.formGroup.addControl('traderId', new UntypedFormControl(''));
    this.formGroup.addControl('beneficiaryCodeType', new UntypedFormControl('ACE'));
    this.formGroup.addControl('beneficiaryCode', new UntypedFormControl(''));

    this.tradeEntryModifyService.onTradeEntryModelChange().pipe(takeUntil(this.onDestroy$)).subscribe(tradeEntryModel => {

      if (tradeEntryModel) {
        this.referenceModel = tradeEntryModel.referenceResponseModel;

        this.initFormValues();
      }
    });

    this.tradeEntryModifyService.onTradeEntrySetupDataChange().pipe(takeUntil(this.onDestroy$)).subscribe(setupData => {
      if (setupData && setupData.beneficiaries) {
        this.beneficiaries = setupData.beneficiaries.map(beneficiary => ({ label: beneficiary.key, value: beneficiary.key }));
      }
    });

    this.tradeEntryModifyService.onValidationErrors().pipe(takeUntil(this.onDestroy$)).subscribe(validationErrors => {
      this.handleServerValidationErrors(validationErrors);
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  private initFormValues(): void {
    if (this.referenceModel) {

      this.reset();

      const transactionTime = new Date(this.referenceModel.transactionTimeUtc);

      this.formGroup.controls['transactionDate'].setValue(transactionTime);
      this.formGroup.controls['transactionTime'].setValue(DateUtils.getFormattedTime(transactionTime));
      this.formGroup.controls['traderId'].setValue(this.referenceModel.traderId);

      if (this.referenceModel.beneficiaryCodeType) {
        this.formGroup.controls['beneficiaryCodeType'].setValue(this.referenceModel.beneficiaryCodeType);
      } else { // default
        this.formGroup.controls['beneficiaryCodeType'].setValue('ACE');
      }
      this.formGroup.controls['beneficiaryCode'].setValue(this.referenceModel.beneficiaryCode);

      if (this.referenceModel.execution) {
        this.formGroup.addControl('linkedTransactionId', new UntypedFormControl(''));
        this.formGroup.controls['linkedTransactionId'].setValue(this.referenceModel.linkedTransactionId);
      } else {
        this.formGroup.removeControl('linkedTransactionId');
      }
    }
  }

  private reset() {
    this.formGroup.controls['transactionDate'].reset();
    this.formGroup.controls['transactionTime'].reset();
    this.formGroup.controls['traderId'].reset();
    this.formGroup.controls['beneficiaryCodeType'].reset();
    this.formGroup.controls['beneficiaryCode'].reset();
  }

  private handleServerValidationErrors(errors: any) {
    this.validationErrors = [];
    if (errors) {
      this.validationErrors['transactionDate'] = errors.transactionDate;
      this.validationErrors['transactionTime'] = errors.transactionTime;
      this.validationErrors['traderId'] = errors.traderId;
      this.validationErrors['beneficiaryCode'] = errors.beneficiaryCode;
      this.validationErrors['linkedTransactionId'] = errors.linkedTransactionId;
    }
  }

}

export interface ReferenceTradeEntryModel {
  transactionTimeUtc: string;
  traderId: string;
  beneficiaryCodeType: string;
  beneficiaryCode: string;
  linkedTransactionId?: string;
  execution: boolean;
}
