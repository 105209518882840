import { Injectable } from '@angular/core';
import { ApiRequestService } from '@common/api-request.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { DatatableConfig, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { Message } from 'primeng/api';
import { of } from 'rxjs';
import * as FileSaver from 'file-saver';
import { HttpParams } from '@angular/common/http';
import { MessagesMapperService } from '@common/messages-mapper.service';

import { EcmDashboardState } from '../dashboard.reducers';
import * as fromDealsAction from './deals.actions';
import * as fromFiltersAction from '../filters/filters.actions';
import { getDashboard } from '../dashboard.selectors';
import {
  allColumnsDealConfig,
  EcmBuckectsRequest,
  EcmDashboardDealsResponse,
  GetEcmDashboardDealsRequest,
  TerminateDealRequest,
} from '../../../ecm-dashboard/ecm-dashboard.model';
import { ErrorResponse } from '@common/error-response.model';
import { getLoaded } from '../filters/filters.selectors';
import { getDealsColumns, getFilenameFromHeader, HideConfirmation, mapRequestValues, UnhideConfirmation } from '@common/deals/deals.model';
import { toPayload } from '@common/cms-common.model';
import * as fromActions from '../../deal-finder/deal-finder.actions';

@Injectable()
export class EcmDealsEffects {
  private baseUrl = '/api/ecm/dashboard';
  private dealfinderUrl = '/api/ecm/dealfinder';

  constructor(private apiGateway: ApiRequestService,
              private actions$: Actions,
              private store$: Store<EcmDashboardState>,
              private router: Router,
              private mapper: MessagesMapperService,) {
  }



  load_presets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromDealsAction.LOAD_PRESETS),
      switchMap(() =>
        this.apiGateway.get(`${this.baseUrl}/loadPresetValues`)
          .pipe(
            switchMap(response => {
              const messages: Message[] = this.mapper.toErrorMessages(response);

              const { hasTerminationRights, hasEditNoteRights } = response;

              const rights = { termination: hasTerminationRights, notes: hasEditNoteRights };

              return messages?.length ?
                of(new fromDealsAction.SetMessagesAction(messages)) :
                of(new fromDealsAction.LoadPresetsSuccess(rights));
            }),
            catchError(error => of(new fromDealsAction.SetMessagesAction(this.mapper.toErrorMessages(error.errorMessage || 'Server Error')))),
          )
      )
    )
  );




   unhideConfirmation$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromDealsAction.UNHIDE_CONFIRMATION),
      map(toPayload),
      switchMap(({ confirmationId }: UnhideConfirmation) =>
        this.apiGateway.post(`${this.dealfinderUrl}/unhideConfirmation`, null,
          new HttpParams().set('confirmationId', confirmationId.toString()))
          .pipe(
            switchMap((response: ErrorResponse | any) => {
              const actions = [];
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                actions.push(new fromDealsAction.UpdateDialogAction(messages));
              } else {
                actions.push(
                  new fromDealsAction.CloseDialogAction(),
                  new fromDealsAction.LoadDataAction()
                );
              }
              return actions;
            })
          )
      )
    ));

   hideConfirmation$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromDealsAction.HIDE_CONFIRMATION),
      map(toPayload),
      switchMap(({ reason, confirmationId }: HideConfirmation) => {
        let param = new HttpParams();
        param = param.set('reason', reason);
        param = param.set('confirmationId', confirmationId.toString());
        return this.apiGateway.post(`${this.dealfinderUrl}/hideConfirmation`, null, param)
          .pipe(
            switchMap((response: ErrorResponse | any) => {
              const actions = [];
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                actions.push(new fromDealsAction.UpdateDialogAction(messages));
              } else {
                actions.push(
                  new fromDealsAction.CloseDialogAction(),
                  new fromDealsAction.LoadDataAction()
                );
              }
              return actions;
            }));
      })));

   sortAndPage$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromDealsAction.SORT_AND_PAGE_DATA),
      map(toPayload),
      filter(() => (this.router.routerState.snapshot.url.includes('/deals'))),
      withLatestFrom(
        this.store$.pipe(select(getDashboard)),
        this.store$.pipe(select(getLoaded))
      ),
      switchMap(([{sorting, paging}, state, loaded]: [DatatableConfig, EcmDashboardState, boolean]) =>
        loaded ? this.loadData(state, paging, sorting) : [new fromDealsAction.SetMessagesAction([])]
      )));

   loadData$ = createEffect(() => this.actions$
    .pipe(
      ofType(
        fromFiltersAction.FILTER,
        fromFiltersAction.UPDATE_FILTERS,
        fromDealsAction.LOAD_DATA),
      filter(() => (this.router.routerState.snapshot.url.includes('/deals'))),
      withLatestFrom(
        this.store$.pipe(select(getDashboard)),
        this.store$.pipe(select(getLoaded))),
      switchMap(([, state, loaded]: [fromDealsAction.LoadDataAction
        | fromFiltersAction.FilterAction | fromFiltersAction.UpdateFiltersAction,
          EcmDashboardState, boolean]) => {
          const { sorting, paging } = state.deals;
          return loaded ? this.loadData(state, paging, sorting) : [new fromDealsAction.SetMessagesAction([])];
        }
      )));

   export$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromDealsAction.EXPORT),
      withLatestFrom(this.store$.pipe(select(getDashboard))),
      concatMap(([, dbState]: [fromDealsAction.DealsActions, EcmDashboardState]) => {
        const { sorting, paging, dateRange, state } = dbState.deals;
        const loaded = dbState.filters.loaded;
        const filters = this.getFilterRequestModel(dbState);

        const request: GetEcmDashboardDealsRequest = {
          sorting,
          paging,
          filterRequestModel: filters,
          dateRange,
          state
        };
        return loaded ? this.apiGateway.getBlob(`${this.baseUrl}/deals/filter/export`, request, null)
            .pipe(
              switchMap((response: any) => {
                const filename = getFilenameFromHeader(response.headers);
                const blob: Blob = response.body;
                FileSaver.saveAs(blob, filename);
                return of(new fromDealsAction.SetMessagesAction([]));
              }),
              catchError(() => of(new fromDealsAction.SetMessagesAction([this.mapper.createErrorMessage('Server error')]))))
          : [new fromDealsAction.SetMessagesAction([])];
      })));

   terminateDeal$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromDealsAction.TERMINATE_CONFIRM),
      concatMap((action: fromDealsAction.TerminateConfirmAction) => {
        const request: TerminateDealRequest = action.payload;
        const params = new HttpParams();
        return this.apiGateway.post(`${this.dealfinderUrl}/terminate`, request, params)
          .pipe(
            switchMap((response: ErrorResponse) => {
            return this.createTerminationResponse(response);
            }),
            catchError(() => of(new fromDealsAction.SetMessagesAction([this.mapper.createErrorMessage('Server error')])))
          );
      })));

   ecmLiteCancel$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromDealsAction.CONFIRM_ECM_LITE_CANCEL),
      concatMap((action: fromDealsAction.ConfirmEcmLiteCancelAction) => {
        let params = new HttpParams();
        params = params.set('confirmationId', action.payload.confirmationId);

        return this.apiGateway.post(`${this.dealfinderUrl}/cancel`, null, params)
          .pipe(
            switchMap((response: ErrorResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [
                  new fromDealsAction.UpdateDialogAction(messages)
                ];
              }

              return [
                new fromDealsAction.UpdateDialogAction(this.mapper.toSuccessMessages('Trade cancelled.')),
                new fromDealsAction.ConfirmEcmLiteCancelSuccessAction()
              ];
            }),
            catchError(() => of(new fromDealsAction.SetMessagesAction([this.mapper.createErrorMessage('Server error')])))
          );
      })));

   addNote$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromDealsAction.ADD_NOTES),
      concatMap((action: fromDealsAction.AddNotesAction) =>
        this.apiGateway.post(`${this.dealfinderUrl}/createNote`, action.payload)
          .pipe(
            switchMap((response: ErrorResponse & { value: any }) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return of(new fromDealsAction.UpdateDialogAction(messages));
              }
              return [
                new fromDealsAction.CloseDialogAction(),
                new fromDealsAction.UpdateDialogAction([]),
                new fromDealsAction.LoadDataAction(),
              ];
            })
          )
      )));

   downloadXml$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromDealsAction.DOWNLOAD_XML),
      switchMap((action: fromDealsAction.DownloadXmlAction) =>
        this.apiGateway.getBlob(`${this.dealfinderUrl}/downloadDocument`, null,
          new HttpParams().set('confirmationId', action.payload))
          .pipe(switchMap((response: any) => {
              const filename = getFilenameFromHeader(response.headers);
              const blob: Blob = response.body;
              FileSaver.saveAs(blob, filename);
              return of(new fromDealsAction.SetMessagesAction([]));
            }),
            catchError(() => of(new fromDealsAction.SetMessagesAction([this.mapper.createErrorMessage('Server error')])))
          )
      )));

   downloadHtml$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromDealsAction.DOWNLOAD_HTML),
      switchMap((action: fromDealsAction.DownloadHtmlAction) =>
        this.apiGateway.getBlob(`${this.dealfinderUrl}/downloadHtmlDocument`, null,
          new HttpParams().set('confirmationId', action.payload))
          .pipe(switchMap((response: any) => {
              const filename = getFilenameFromHeader(response.headers);
              const blob: Blob = response.body;
              FileSaver.saveAs(blob, filename);
              return of(new fromDealsAction.SetMessagesAction([]));
            }),
            catchError(() => of(new fromDealsAction.SetMessagesAction([this.mapper.createErrorMessage('Server error')])))
          )
      )));

  private loadData(state: EcmDashboardState,
                   paging: PagingFilter,
                   sorting: SortingFilter) {
    const request: GetEcmDashboardDealsRequest = {
      sorting,
      paging,
      filterRequestModel: this.getFilterRequestModel(state),
      state: state.deals.state,
      dateRange: state.deals.dateRange
    };

    return this.apiGateway
      .post(`${this.baseUrl}/deals/filter`, request)
      .pipe(
        switchMap((response: EcmDashboardDealsResponse) => {
          const messages: Message[] = this.mapper.toErrorMessages(response);
          if (messages && messages.length) {
            return [new fromDealsAction.SetMessagesAction(messages)];
          }

          return [
            new fromDealsAction.SetColumnsAction(getDealsColumns(response.columnOrder, allColumnsDealConfig)),
            new fromDealsAction.LoadDataSuccessAction(response),
            new fromDealsAction.SetMessagesAction([]),
          ];
        }),
        catchError(error => of(new fromDealsAction.SetMessagesAction([
          this.mapper.createErrorMessage(error.errorMessage)
        ]))));
  }

  private createTerminationResponse(response: ErrorResponse){
    const messages: Message[] = [];

    if(response.fieldValidationMessages) {
      for (const key of Object.keys(response.fieldValidationMessages)) {
        const severity = response.fieldValidationMessages[key];
        messages.push({ severity: severity, data: key });
      }

      return [new fromDealsAction.UpdateDialogAction(messages)];
    }

    return [
      new fromDealsAction.TerminateConfirmSuccessAction({ reason: '', confirmationIds: []}),
      new fromDealsAction.UpdateDialogAction(this.mapper.toSuccessMessages('All selected Deals terminated successfully. Note: It may take a few moments until this is reflected in the user interface.')),
      new fromDealsAction.LoadDataAction()
    ];
  }

  private getFilterRequestModel(state: EcmDashboardState): EcmBuckectsRequest {
    const { filters } = state;
    const { propFilters } = filters;
    return {
      broker: mapRequestValues(propFilters.brokers, filters.broker),
      category: state.category,
      commodity: mapRequestValues(propFilters.commodities, filters.commodities),
      counterParty: mapRequestValues(propFilters.counterParties, filters.counterParties),
      hiddenDeals: state.filters.propFilters.hiddenDeals,
      market: mapRequestValues(propFilters.markets, filters.markets),
      transactionType: mapRequestValues(propFilters.transactionTypes, filters.transactionTypes),
      viewBy: state.filters.viewByFilter
    };
  }

}

