import { Prefix} from '../reducers';
import { SET_ORGANISATION_ID, SetOrganisationIdAction } from './organisation-id.actions';
import { SET_TO_DEFAULT } from '../shared/shared.actions';

export function organisationIdReducer(prefix: Prefix) {
  return function (state: number, action: any): number {
    switch (action.type) {
      case `${prefix} ${SET_ORGANISATION_ID}`:
        return (action as SetOrganisationIdAction).payload;
      case `${prefix} ${SET_TO_DEFAULT}`:
        return null;
      default:
        return state;
    }
  };
}
