<p-dialog *ngIf="data && display &&
  (data?.newValue === 'ACTIVE' && (data.data.state === 'LOCKED' || data.data.state === 'INACTIVE'))"
          [header]="header"
          [(visible)]="display"
          [modal]="true"
          (visibleChange)="onVisibleChange($event)"
          [style]="{'min-width': '600px'}">
  <p-messages [value]="messages" [closable]="true"></p-messages>
  <form [formGroup]="form">
    <div class="col-12 margin-bottom-12 padding-0">
      <span class="col-4 float-left ps-0">User Name:</span><span class="col-8 padding-6">{{data?.data?.userName}}</span>
    </div>
    <div class="col-12 margin-bottom-12 padding-0">
      <span  class="col-4 float-left ps-0">User State:</span>
      <cms-user-states class="col-8 padding-6" *ngIf="data?.data.state" [state]="data?.data.state"></cms-user-states>
    </div>
    <div>
      <label for="comment">Enter comment for audit log and confirm: <span class="text-required">*</span></label>
      <textarea id="comment" name="comment" formControlName="comment" class="form-control" rows="10" [ngClass]="{'error': errors['comment']}"></textarea>
    </div>
    <div class="col-12 margin-bottom-21 margin-top-21 padding-0">
      <p-panel header="Password Policy Requirements" [toggleable]="true">
        New password must have <strong>between 8 to 16</strong> characters.<br />
        It must contain at least<br />
        <strong>one lower-case letter</strong>: a-z<br />
        <strong>one upper-case letter</strong>: A-Z<br />
        <strong>one number or special character</strong>: 0-9 $ ! + - _ . ,<br />
        Only the above characters are allowed.<br />
        New password must be different from your last 3 passwords.
      </p-panel>
    </div>
    <div class="col-12 margin-bottom-12 padding-0">
      <label for="newPassword" class="col-4 float-left ps-0">New password:
        <span class="text-required">*</span>
      </label>
      <input id="newPassword" type="password" formControlName="newPassword" class="form-control col-8 padding-6" [ngClass]="{'error': errors['newPassword']}"/>
    </div>
    <div class="col-12 margin-bottom-12 padding-0">
      <label for="newPasswordRepeat" class="col-4 float-left ps-0">New password (repeat)
        <span class="text-required">*</span>
      </label>
      <input id="newPasswordRepeat" type="password" formControlName="newPasswordRepeat" class="form-control col-8 padding-6" [ngClass]="{'error': errors['newPasswordRepeat']}"/>
    </div>
  </form>
  <p-footer>
    <button class="btn margin-right-3" type="button" (click)="onCancel()">Cancel</button>
    <button  class="btn" type="button" (click)="onConfirm()">Confirm</button>
  </p-footer>
</p-dialog>
