import { createAction, union } from '@ngrx/store';
import { EsmNettingStatementUserFilter } from '../../../../netting/finder/esm-netting-finder.model';
import { MyFilter } from '@common/my-filters/my-filters.model';

export const setDefaultFilterAction = createAction('[NETTING STATEMENT FINDER] Set Default Filter', (payload: MyFilter) => ({ payload }));
export const loadUserFilterAction = createAction('[NETTING STATEMENT FINDER] Load User Filter');
export const onUserFilterLoadedAction = createAction('[NETTING STATEMENT FINDER] On User Filter Loaded', (payload: {
  defaultFilter: EsmNettingStatementUserFilter,
  filterSelections: EsmNettingStatementUserFilter[]
}) => ({ payload }));
export const setAllDealsFilterAction = createAction('[NETTING STATEMENT FINDER] Set All Deals Filter', (payload: EsmNettingStatementUserFilter) => ({ payload }));
export const selectMyFilterAction = createAction('[NETTING STATEMENT FINDER] Select My Filter', (payload: EsmNettingStatementUserFilter) => ({ payload }));
export const deleteMyFilterAction = createAction('[NETTING STATEMENT FINDER] Delete My Filter', (payload: { filterName: string }) => ({ payload }));
export const deleteMyFilterSuccessAction = createAction('[NETTING STATEMENT FINDER] Delete My Filter Success', (payload: { filterName: string }) => ({ payload }));
export const replaceMyFiltersAction = createAction('[NETTING STATEMENT FINDER] Replace My Filters', (payload: EsmNettingStatementUserFilter) => ({ payload }));
export const saveMyFiltersAction = createAction('[NETTING STATEMENT FINDER] Save My Filters', (payload: EsmNettingStatementUserFilter) => ({ payload }));
export const reloadMyFiltersAction = createAction('[NETTING STATEMENT FINDER] Reload My Filters');
export const reloadMyFilterSuccessAction = createAction('[NETTING STATEMENT FINDER] Reload My Filter Success', (payload: {
  defaultFilter: EsmNettingStatementUserFilter,
  filterSelections: EsmNettingStatementUserFilter[]
}) => ({ payload }));

const actions = union({
  setDefaultFilterAction,
  loadUserFilterAction,
  onUserFilterLoadedAction,
  setAllDealsFilterAction,
  selectMyFilterAction,
  deleteMyFilterAction,
  deleteMyFilterSuccessAction,
  replaceMyFiltersAction,
  saveMyFiltersAction,
  reloadMyFiltersAction,
  reloadMyFilterSuccessAction
});

export type MyFilterActions = typeof actions;

