import { Message } from 'primeng/api';
import * as moment from 'moment';
import { DialogState } from '@common/state/reducers';
import {
  ErrSendingFailuresResponse,
  ErrSendingFailuresSearchRequest,
  ErrSendingFailuresSelected
} from '../../err-sending-failures/err-sending-failures.model';
import * as fromActions from './err-sending-failures.actions';

export type ERR_SF_DIALOGS = 'RESEND' | 'INFO';

export interface ErrSendingFailuresState {
  organisationId?: number;
  messages: Message[];
  filters: ErrSendingFailuresSearchRequest;
  processesNames: string[];
  initializing: boolean;
  data: ErrSendingFailuresResponse;
  selectedItems: ErrSendingFailuresSelected;
  loadingTable: boolean;
  dialog: DialogState<ERR_SF_DIALOGS>;
}

const initiateDates = () => {
  const now = moment();
  return {
    start: now.clone().subtract(7, 'days').toDate(),
    end: now.clone().toDate()
  };
};

export const initialState: ErrSendingFailuresState = {
  organisationId: null,
  messages: [],
  filters: {
    processName: '',
    start: '',
    end: ''
  },
  processesNames: [],
  initializing: false,
  data: {
    values: null
  },
  selectedItems: { },
  loadingTable: false,
  dialog: null
};

export function errSendingFailuresReducer(state: ErrSendingFailuresState = { ...initialState },
                                          action: fromActions.ErrSendingFailuresActions) {
  switch (action.type) {
    case fromActions.toggleSelectAll.type: {
      return {
        ...state,
        selectedItems: action.payload ? { ...state.data.values } : { }
      };
    }
    case fromActions.select.type: {
      return {
        ...state,
        selectedItems: { ...state.selectedItems, ...action.payload }
      };
    }
    case fromActions.deselect.type: {
      const selectedItems = { ...state.selectedItems };
      delete selectedItems[Object.keys(action.payload).toString()];
      return {
        ...state,
        selectedItems
      };
    }
    case fromActions.openDialog.type: {
      const { name, data } = action.payload;
      return {
        ...state,
        dialog: {
          name,
          data
        }
      };
    }
    case fromActions.closeDialogAction.type: {
      return {
        ...state,
        dialog: null
      };
    }
    case fromActions.resend.type: {
      return {
        ...state,
        loadingTable: true
      };
    }
    case fromActions.resendSuccess.type: {
      return {
        ...state,
        selectedItems: { },
        loadingTable: false
      };
    }
    case fromActions.refresh.type: {
      return {
        ...state,
        filters: {
          processName: '',
          start: '',
          end: ''
        },
        messages: [],
        initializing: true,
        data: {
          values: null
        },
        selectedItems: { },
      };
    }
    case fromActions.search.type: {
      return {
        ...state,
        data: {
          values: null
        },
        initializing: true,
        selectedItems: { },
        dialog: null
      };
    }
    case fromActions.searchSuccess.type: {
      return {
        ...state,
        data: {
          values: [...action.payload]
        },
        initializing: false,
        messages: []
      };
    }

    case fromActions.onPresetSuccess.type: {
      return {
        ...state,
        processesNames: [...action.payload.processNames],
        filters: {...state.filters, start: new Date(action.payload.from), end:new Date(action.payload.to)}
      };
    }
    case fromActions.setFilter.type: {
      return {
        ...state,
        filters: action.payload
      };
    }
    case fromActions.setMessages.type: {
      return {
        ...state,
        initializing: false,
        messages: [...action.payload],
      };
    }
    case fromActions.setOrganisationId.type: {
      return {
        ...state,
        organisationId: action.payload
      };
    }
    case fromActions.setToDefault.type: {
      return {
        ...initialState,
        filters: {
          processName: '',
          start: initiateDates().start,
          end: initiateDates().end
        }
      };
    }
    default: {
      return state;
    }
  }
}

export const getFilters = (state: ErrSendingFailuresState) => state.filters;
export const getMessages = (state: ErrSendingFailuresState) => state.messages;
export const getInitializing = (state: ErrSendingFailuresState) => state.initializing;
export const getProcessesNames = (state: ErrSendingFailuresState) => state.processesNames;
export const getData = (state: ErrSendingFailuresState) => state.data;
export const getSelectedItems = (state: ErrSendingFailuresState) => state.selectedItems;
export const getLoadingTable = (state: ErrSendingFailuresState) => state.loadingTable;
export const getDialog = (state: ErrSendingFailuresState) => state.dialog;
