<div class="volume">
  <div class="form-title">Trade | Volume</div>
  <div class="form-body" [formGroup]="formGroup">
    <div class="row gutter-0 !me-[40px] ">
      <div class="col-5 label">Total Notional Contract Quantity</div>
      <div class="col-7 row gutter-0 ps-2 pe-1">
        <div class="col-6">
          <input type="text" cmsDigitOnly
            [class]="'form-control padding-6 text-medium margin-3 ' + appendValidationErrorClass('totalNotionalContractQuantityValue')"
            formControlName="totalNotionalContractQuantityValue" pattern="-?[0-9]{0,15}([.][0-9]{0,5})?">
        </div>
        <div class="col-6 ps-2">
          <p-dropdown formControlName="totalNotionalContractQuantityUnit" [options]="contractQuantity"
            [style]="{'width': '100%'}" [styleClass]="'margin-3 '" ></p-dropdown>
        </div>
      </div>
      <span *ngIf="formGroup.controls['totalNotionalContractQuantityValue'].hasError('error')"
        class="fa fa-info-circle absolute error-marker"></span>
    </div>
    <div class="row gutter-0 !me-[40px]">
      <div class="col-5 label">Volume Optionality</div>
      <div id="remit-trade-entry-table2-volumeOptionality" class="col-7 ps-2 pe-1">
        <p-dropdown formControlName="volumeOptionality" [options]="volumeOptionality" [style]="{'width': '100%'}"
          [styleClass]="'margin-3 ' + appendValidationErrorClass('volumeOptionality')" >
        </p-dropdown>
      </div>
    </div>
    <div class="row gutter-0 !me-[40px]">
      <div class="col-5 label">Volume Optional Frequency</div>
      <div id="remit-trade-entry-table2-volumeOptionalityFrequency" class="col-7 ps-2 pe-1">
        <p-dropdown formControlName="volumeOptionalityFrequency" [options]="optionalFrequency"
          [style]="{'width': '100%'}" [styleClass]="'margin-3 ' + appendValidationErrorClass('volumeOptionality')"
          ></p-dropdown>
      </div>
    </div>
  </div>
</div>
