import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Message } from 'primeng/api';
import { ApiRequestService } from '../../../common/api-request.service';
import { MessagesMapperService } from '../../../common/messages-mapper.service';
import { OrganisationGroupsResponse } from '../../contacts/contacts.model';
import { ContactsState } from '../contacts/contacts.reducers';
import * as fromDt from '../datatable/datatable.actions';
import * as fromOrgGroups from './organisation-groups.actions';

@Injectable()
export class OrganisationGroupsEffects {
  private baseUrl = 'api/help/contacts';

  constructor(private apiGateway: ApiRequestService,
              private actions$: Actions,
              private store$: Store<ContactsState>,
              private mapper: MessagesMapperService,) {
  }

   organisationGroups$ = createEffect(() => this.actions$
    .pipe(ofType(fromOrgGroups.LOAD_ORGANISATION_GROUPS),
      switchMap(() => {
        return this.apiGateway
          .get(`${this.baseUrl}/organisationGroups`, null)
          .pipe(
            map((response: OrganisationGroupsResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return new fromDt.SetMessagesAction('[ORG GROUPS CONTACTS]', messages);
              }
              return new fromOrgGroups.LoadOrganisationGroupsSuccessAction(response);
            }),
            catchError(error => of(new fromDt.SetMessagesAction('[ORG GROUPS CONTACTS]', [
              this.mapper.createErrorMessage(error.errorMessage)
            ]))));
      })));
}
