import { createSelector } from '@ngrx/store';
import { getAdminState, State } from '../reducers';
import { DatatableState } from '../datatable/datatable.reducer';
import { OrganisationGroupsState } from './organisation-groups.reducer';
import { DialogState } from '../dialog/dialog.reducer';
import * as fromDatatable from '../datatable/datatable.reducer';

export const getOrgGroupsState = createSelector(getAdminState, (state: State) => state.organisationGroups);
export const getDatatable = createSelector(getOrgGroupsState, (organisationGroups: OrganisationGroupsState): DatatableState => organisationGroups.datatable);
export const getOrganisationId = createSelector(getOrgGroupsState, (state: OrganisationGroupsState) => state.organisationId);
export const getFilters = createSelector(getDatatable, (state: DatatableState): string => state.filters);
export const getDialog = createSelector(getOrgGroupsState, (state: OrganisationGroupsState): DialogState => state.dialog);

export const getData = createSelector(getDatatable, fromDatatable.getData);
export const getSorting = createSelector(getDatatable, fromDatatable.getSorting);
export const getPaging = createSelector(getDatatable, fromDatatable.getPaging);
export const getLoading = createSelector(getDatatable, fromDatatable.getLoading);
export const getEditableData = createSelector(getDatatable, fromDatatable.getEditableData);
export const getMessages = createSelector(getDatatable, fromDatatable.getMessages);
export const getSelected = createSelector(getDatatable, fromDatatable.getSelected);

