import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Message } from 'primeng/api';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { toPayload } from '@common/cms-common.model';
import { ApiRequestService } from '@common/api-request.service';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { SendingFailuresState } from './sending-failures.reducers';
import {
  SendingFailuresPresetValuesModel,
  SendingFailuresResendResponse,
  SendingFailuresResponse, SendingFailuresSearchRequest, SendingFailuresSelected, SendingFailuresValues
} from '../../sending-failures/sending-failures.model';
import * as fromActions from './sending-failures.actions';
import * as fromSelectors from './sending-failures.selectors';
import { ErrSendingFailuresPresetModel } from '../../err-sending-failures/err-sending-failures.model';

@Injectable()
export class SendingFailuresEffects {
  private baseUrl = '/api/problemsolving/sending/failures';

  constructor(private actions$: Actions,
              private apiGateway: ApiRequestService,
              private mapper: MessagesMapperService,
              private store$: Store<SendingFailuresState>) { }

   getPresetValues$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.getPreset.type),
      switchMap(() =>
        this.apiGateway.get(`${this.baseUrl}/loadPresetValues`)
          .pipe(
            switchMap((response: SendingFailuresPresetValuesModel) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [fromActions.setMessages(messages)];
              }

              return [
                fromActions.onPresetSuccess(response),
                fromActions.setMessages([]),
              ];
            }),
            catchError(error => of(fromActions.setMessages([
              this.mapper.createErrorMessage(error.errorMessage)
            ]))))
      )));

   search$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.search.type),
      map(toPayload),
      switchMap((request: SendingFailuresSearchRequest) => this.loadData(request))
    ));

   refresh$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.refresh.type),
      switchMap(() =>
        this.apiGateway.get(`${this.baseUrl}/loadPresetValues`)
          .pipe(
            switchMap((response: SendingFailuresPresetValuesModel) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [fromActions.setMessages(messages)];
              }

              return [
                fromActions.onPresetSuccess(response),
                fromActions.search({
                  fromDate: moment(response.from).format('YYYY-MM-DD'),
                  fromTime: moment(response.from).format('HH:mm:ss'),
                  toDate: moment(response.to).format('YYYY-MM-DD'),
                  toTime: moment(response.to).format('HH:mm:ss')}),
                fromActions.setMessages([]),
              ];
            }),
            catchError(error => of(fromActions.setMessages([
              this.mapper.createErrorMessage(error.errorMessage)
            ]))))
      )));

   resend$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.resend.type),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.getSelectedItems)),
        this.store$.pipe(select(fromSelectors.getFilters)),
      ),
      switchMap(([, selectedItems, { fromDate, fromTime, toDate, toTime }]: [any, SendingFailuresSelected, SendingFailuresSearchRequest]) => {
        const request = Object.values(selectedItems).map((item: SendingFailuresValues) => (
          {
            errorCode: item.errorCode,
            fromDate: item.fromDate,
            fromTime: item.fromTime,
            receiverId: item.receiverId,
            senderId: item.senderId,
            toDate: item.toDate,
            toTime: item.toTime,
            xpServiceKeyHash: item.xpServiceKeyHash
          }
        ));
        return this.apiGateway.post(`${this.baseUrl}/resend`, request)
          .pipe(
            switchMap((response: SendingFailuresResendResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [fromActions.setMessages(messages)];
              }

              const errorMessages = this.mapper.objectToErrorMessages(response.errors);
              const successMessages = this.mapper.toSuccessMessages(`${response.successfullyResentCount} messages queued for resending.`);
              return [
                fromActions.setMessages(errorMessages),
                fromActions.setMessages(successMessages),
                fromActions.resendSuccess(),
                fromActions.search({ fromDate, fromTime, toDate, toTime })
              ];
            }),
            catchError(error => of(fromActions.setMessages([
              this.mapper.createErrorMessage(error.errorMessage)
            ]))));
      })));

  private loadData(request: SendingFailuresSearchRequest): Observable<Action> {
    return this.apiGateway
      .post(`${this.baseUrl}/data`, request)
      .pipe(
        switchMap((response: SendingFailuresResponse) => {
          const messages: Message[] = this.mapper.toErrorMessages(response);
          const result: Action[] = [];
          if (messages && messages.length) {
            result.push(fromActions.setMessages(messages));
          } else {
            result.push(...[fromActions.searchSuccess(response.values)]);
          }
          return result;
        }),
        catchError(error => of(fromActions.setMessages([this.mapper.createErrorMessage(error.errorMessage)]))));
  }
}
