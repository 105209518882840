import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RemitTradeEntryTable2Service } from '../remit-trade-entry-table2.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SelectItem } from 'primeng/api';
import { RemitTradeEntryTable2OtherCounterpartyDataModel } from '../remit-trade-entry-table2-data.model';
import { RemitTable2TradeOtherMarketparticipantModel } from '../../../trade/table2/modify/remit-table2-trade-entry.model';

@Component({
  selector: 'cms-remit-trade-entry-table2-other-counterparty',
  templateUrl: './remit-trade-entry-table2-other-counterparty.component.html',
  styleUrls: ['./remit-trade-entry-table2-other-counterparty.component.scss']
})
export class RemitTradeEntryTable2OtherCounterpartyComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  formGroup: UntypedFormGroup;

  @Input()
  onReset: Subject<boolean>;

  @Input()
  data: RemitTable2TradeOtherMarketparticipantModel[];

  @Input()
  modifyTrade: boolean = false;

  // when not report both sides
  otherMarketParticipantsData: SelectItem[];

  // when report both sides
  otherBeneficiaryType: SelectItem[];

  otherCounterpartyData: RemitTradeEntryTable2OtherCounterpartyDataModel;

  reportBoth: boolean = false;

  private onDestroy$ = new Subject();

  constructor(private formBuilder: UntypedFormBuilder, private tradeEntryService: RemitTradeEntryTable2Service) {
    this.onReset = new Subject<boolean>();
  }

  get otherMarketParticipants(): AbstractControl[] {
    return (this.formGroup.controls['otherMarketParticipants'] as UntypedFormArray).controls;
  }

  get beneficiaries(): AbstractControl[] {
    return (this.formGroup.controls['otherBeneficiaries'] as UntypedFormArray).controls;
  }

  get addDisabled(): boolean {
    let addDisabled: boolean;
    if (this.reportBoth) {
      addDisabled = this.beneficiaries.length >= 4;
    } else {
      addDisabled = this.otherMarketParticipants.length >= 4;
    }

    return addDisabled || this.formGroup.disabled;
  }

  get removeDisabled(): boolean {

    let removeDisabled: boolean;
    if (this.reportBoth) {
      removeDisabled = this.beneficiaries.length <= 1;
    } else {
      removeDisabled = this.otherMarketParticipants.length <= 1;
    }

    return removeDisabled || this.formGroup.disabled;
  }

  ngOnInit() {

    this.initForm();

    this.tradeEntryService.onTradeEntryDataChange().pipe(takeUntil(this.onDestroy$)).subscribe(tradeEntryData => {
      if (tradeEntryData && tradeEntryData.otherCounterparty) {
        this.otherCounterpartyData = tradeEntryData.otherCounterparty;
        this.initOtherMarketParticipants();
        this.initOtherBeneficiaryType();
      }
    });

    this.onReset.subscribe(reset => {
      if (reset) {
        this.reset();
      }
    });

    this.tradeEntryService.onValidationFieldErrors().pipe(takeUntil(this.onDestroy$))
      .subscribe(
        fieldName => {
          if (fieldName && fieldName.indexOf('otherMarketParticipants') >= 0) {
            const items = this.formGroup.controls['otherMarketParticipants'] as UntypedFormArray;

            const parse = fieldName.split('.');
            const index = parse[0].charAt(parse[0].length - 1);
            const formGroup = items.controls[parseInt(index, 10)] as UntypedFormGroup;

            if (parse[1]) {
              switch (parse[1]) {
                case 'code':
                  formGroup.controls['code'].setErrors({ error: true });
                  break;
                case 'type':
                  formGroup.controls['codeType'].setErrors({ error: true });
                  break;
              }
            }
          }

          if (fieldName && fieldName.indexOf('otherMarketBeneficiaries') >= 0) {
            const items = this.formGroup.controls['otherBeneficiaries'] as UntypedFormArray;

            const parse = fieldName.split('.');
            const index = parse[0].charAt(parse[0].length - 1);
            const formGroup = items.controls[parseInt(index, 10)] as UntypedFormGroup;

            if (parse[1]) {
              switch (parse[1]) {
                case 'code':
                  formGroup.controls['code'].setErrors({ error: true });
                  break;
                case 'type':
                  formGroup.controls['codeType'].setErrors({ error: true });
                  break;
              }
            }
          }
        }
      );
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.fillFormWithData();
  }

  add() {
    if (this.reportBoth) {
      this.addBeneficiary();
    } else {
      this.addOtherMp();
    }
  }

  remove() {

    if (this.reportBoth) {
      const beneficiaries = this.formGroup.controls['otherBeneficiaries'] as UntypedFormArray;

      if (beneficiaries.length > 1) {
        beneficiaries.removeAt(beneficiaries.length - 1);
      }
    } else {

      const otherMPs = this.formGroup.controls['otherMarketParticipants'] as UntypedFormArray;

      if (otherMPs.length > 1) {
        otherMPs.removeAt(otherMPs.length - 1);
      }
    }
  }

  // otherMarketParticipants0.code
  getOtherMarketParticipantErrorStyleClass(index: number, fieldName: string): string {

    const items = this.formGroup.get('otherMarketParticipants') as UntypedFormArray;
    if (items && items.controls[index]) {

      const formGroup = items.controls[index] as UntypedFormGroup;

      if (formGroup.controls[fieldName].hasError('error')) {
        return ' error';
      }
    }

    return '';
  }

  getOtherBeneficiariesErrorStyleClass(index: number, fieldName: string): string {

    const items = this.formGroup.get('otherBeneficiaries') as UntypedFormArray;
    if (items && items.controls[index]) {

      const formGroup = items.controls[index] as UntypedFormGroup;

      if (formGroup.controls[fieldName].hasError('error')) {
        return ' error';
      }
    }

    return '';
  }

  onReportBoth($event) {
    this.reportBoth = $event.target.checked;
  }

  getValidationErrorStyleClass(fieldName: string): string {
    if (this.formGroup.controls[fieldName] && this.formGroup.controls[fieldName].hasError('error')) {
      return 'error';
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
    this.onReset.unsubscribe();
  }

  private addBeneficiary() {
    const beneficiaries = this.formGroup.controls['otherBeneficiaries'] as UntypedFormArray;
    if (beneficiaries.length < 4) {
      beneficiaries.push(
        this.formBuilder.group({ codeType: [null], code: [''] })
      );
    }
  }

  private addOtherMp() {
    const otherMPs = this.formGroup.controls['otherMarketParticipants'] as UntypedFormArray;
    if (otherMPs.length < 4) {
      otherMPs.push(
        this.formBuilder.group({ codeType: [''], code: [''] })
      );
    }
  }

  private initForm() {
    this.formGroup.addControl('reportBothSides', new UntypedFormControl(''));
    this.formGroup.addControl('otherMarketParticipants', this.formBuilder.array(
      []
    ));

    this.formGroup.addControl('otherMarketParticipantCode', new UntypedFormControl(''));

    this.formGroup.addControl('otherBeneficiaries', this.formBuilder.array(
      []
    ));

    this.addBeneficiary();
    this.addOtherMp();
  }

  private initOtherMarketParticipants() {
    if (this.otherCounterpartyData) {
      this.otherMarketParticipantsData = this.otherCounterpartyData.otherMarketParticipants.map(item => (
        { label: item.value, value: item.key }
      ));

      this.fillFormWithData();
    }
  }

  private initOtherBeneficiaryType() {
    if (this.otherCounterpartyData) {
      this.otherBeneficiaryType = [{ label: ' ', value: null }].concat(this.otherCounterpartyData.otherBeneficiaryType.map(item => (
        { label: item.key, value: item.key }
      )));
    }
  }

  private reset() {

    this.reportBoth = false;
    this.formGroup.controls['reportBothSides'].reset();
    this.formGroup.controls['otherMarketParticipantCode'].reset();

    this.formGroup.removeControl('otherMarketParticipants');
    this.formGroup.removeControl('otherBeneficiaries');

    this.formGroup.addControl('otherMarketParticipants', this.formBuilder.array(
      []
    ));

    this.formGroup.addControl('otherBeneficiaries', this.formBuilder.array(
      []
    ));

    this.addBeneficiary();
    this.addOtherMp();
  }

  private fillFormWithData() {
    if (this.formGroup && this.data) {

      const otherMPs = this.formGroup.controls['otherMarketParticipants'] as UntypedFormArray;

      if (otherMPs) {
        let otherMp: RemitTable2TradeOtherMarketparticipantModel;
        for (let i = 0; i < this.data.length; i++) {

          if (!otherMPs.at(i)) {
            this.addOtherMp();
          }

          otherMp = {
            codeType: this.data[i].codeType,
            code: this.data[i].code
          };

          otherMPs.at(i).patchValue(otherMp);

          if (otherMPs.length === 4) {
            break;
          }
        }
      }
    }
  }
}
