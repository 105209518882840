import { ErrorResponse } from '@common/error-response.model';
import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import { Column } from '@common/shared/datatable.model';

export interface ExternalRrm {
  auditGroupId: number;
  dataStoreId: number;
  filename: string;
  id: number;
  messageDirection: string;
  messageId: string;
  messageStatus: string;
  messageType: string;
  receiptTimestamp: string;
  receiver: string;
  sender: string;
  updateTimestamp: string;
}

export interface ExternalFormData {
  messageStatusValues: string[];
  messageTypeValues: string[];
  receiverValues: string[];
  resendButtonColumnVisible: boolean;
  end: Date;
  start: Date;
}

export interface ExternalRrmStatus {
  lastSuccessfullReceived: string;
  lastSuccessfullSent: string;
  oldestOutstandingResponse: string;
  outstandingResponses: number;
}

export interface ExternalRrmStatusResponse extends ErrorResponse {
  value: ExternalRrmStatus;
}

export interface ExternalRrmForm {
  data: ExternalFormData;
  resendButtonColumnVisible: boolean;
  status: ExternalRrmStatus;
}

export interface ExternalRrmFilter {
  messageStatus: string;
  messageType: string;
  receiver: string;
  end: Date;
  start: Date;
}

export const defaultRrmFilter: ExternalRrmFilter = {
  messageStatus: '',
  messageType: '',
  receiver: '',
  start: null,
  end: null
};

export interface ExternalRrmRequest {
  paging: PagingFilter;
  sorting: SortingFilter;
  messageStatus: string;
  messageType: string;
  receiver: string;
  start: Date;
  end: Date;
}

export interface ExternalRrmResponse extends ErrorResponse {
  values: ExternalRrm[];
  count: number;
  currentPage: number;
}

export interface ExternalRrmFormResponse extends ErrorResponse {
  value: ExternalRrmForm;
}

export interface RRMPresetValuesResponse extends ErrorResponse {
  messageStatusValues: string[];
  messageTypeValues: string[];
  receiverValues: string[];
  receivedFrom: Date | string;
  receivedTo: Date | string;
  resendButtonColumnVisible: boolean;
}

export interface RRMPresetValuesResponseModel extends ErrorResponse {
  value: RRMPresetValuesResponse;
}

export interface ResendRequest {
  id: number;
}

export enum MessageStatusEnum {
  RECEIVED_FROM_CMH = 'RECEIVED_FROM_CMH',
  RECEIVED_FROM_FTP = 'RECEIVED_FROM_FTP',
  SENT_TO_CMH = 'SENT_TO_CMH',
  SENT_TO_XML = 'SENT_TO_XML',
  SENT_TO_FTP = 'SENT_TO_FTP',
  SENT_TO_WS = 'SENT_TO_WS',
  ERROR_TECHNICAL_RECEIPT = 'ERROR_TECHNICAL_RECEIPT',
  ERROR_LOCAL_PROCESSING = 'ERROR_LOCAL_PROCESSING',
  INVALID = 'INVALID',
  SENDING_TO_FTP = 'SENDING_TO_FTP'
}

const defaultStyles = {
  'text-align': 'left',
  'text-overflow': 'ellipsis',
  'white-space': 'nowrap',
  'min-width': '75px'
};

export const dataTableColumns: Column[] = [
  { name: 'receiptTimestamp', type: 'date', title: 'Receipt Timestamp', style: { ...defaultStyles }, sortable: true, visible: true },
  { name: 'updateTimestamp', type: 'date', title: 'Update Timestamp', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: '', type: 'resendButton', title: '', style: { ...defaultStyles, 'text-align': 'center', 'max-width': '80px' }, sortable: false, visible: true },
  { name: 'messageStatus', type: 'text', title: 'Status', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'messageId', type: 'text', title: 'Message ID', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'filename', type: 'text', title: 'Filename', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: '', type: 'downloadButton', title: '', style: { ...defaultStyles, 'text-align': 'center', 'max-width': '80px' }, sortable: false, visible: true },
  { name: '', type: 'auditButton', title: '', style: { ...defaultStyles, 'text-align': 'center', 'max-width': '80px' }, sortable: false, visible: true },
  { name: 'messageDirection', type: 'text', title: 'Direction', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'sender', type: 'text', title: 'Sender', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'receiver', type: 'text', title: 'Receiver', style: { ...defaultStyles }, sortable: false, visible: true },
  { name: 'messageType', type: 'text', title: 'Message Type', style: { ...defaultStyles }, sortable: false, visible: true }
];
