import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { ApiRequestService } from '@common/api-request.service';
import { EcmDashboardState } from '../dashboard.reducers';
import { MessagesMapperService } from '@common/messages-mapper.service';
import * as fromMyFiltersActions from './my-filters.actions';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import * as fromFiltersSelectors from '../filters/filters.selectors';
import { EcmDashboardFiltersState } from '../filters/filters.reducers';
import { DeleteMyFilterResponse, EcmMyFilter, EcmMyFiltersResponse } from '../../../deal-finder/deal-finder.model';
import { mapMyFilter, mapResponse, updateFilters } from '../../../ecm.model';
import * as fromFiltersActions from '../filters/filters.actions';
import * as fromTabActions from '../tab/tab.actions';
import * as fromDialogAction from '../dialog/dialog.actions';
import { HttpParams } from '@angular/common/http';
import { ErrorResponse } from '@common/error-response.model';
import { Message } from 'primeng/api';
import { AllDealsMyFilterName } from '@common/my-filters/my-filters.model';

@Injectable()
export class EcmMyFiltersEffects {
  private baseUrl = '/api/ecm/dashboard';

  constructor(private apiGateway: ApiRequestService,
              private actions$: Actions,
              private store$: Store<EcmDashboardState>,
              private router: Router,
              private mapper: MessagesMapperService) {
  }

   reloadMyFilters$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromMyFiltersActions.RELOAD_MY_FILTER),
      switchMap(() => {
        return this.apiGateway.get(`${this.baseUrl}/filters`)
          .pipe(
            switchMap((response: EcmMyFiltersResponse) => {
              mapResponse(response);
              return [
                new fromMyFiltersActions.ReloadMyFiltersSuccessAction(response),
                new fromTabActions.GetBucketsAction()
              ];

            }),
            catchError(() => of(new fromTabActions.SetMessagesAction([this.mapper.createErrorMessage('Server error')])))
          );
      })));

   saveMyFilter$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromMyFiltersActions.SAVE_MY_FILTERS),
      withLatestFrom(this.store$.pipe(select(fromFiltersSelectors.getFilters))),
      concatMap((data: [fromMyFiltersActions.SaveMyFiltersAction, EcmDashboardFiltersState]) => {
        const [action, state] = data;
        const request = mapMyFilter(state, action.payload);
        return this.apiGateway.post(`${this.baseUrl}/saveFilter`, request)
          .pipe(
            switchMap((response: EcmMyFiltersResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [new fromDialogAction.UpdateDialogAction(messages)];
              }

              return [
                new fromMyFiltersActions.ReloadMyFiltersAction(),
                new fromDialogAction.UpdateDialogAction([]),
                new fromDialogAction.CloseDialogAction()
              ];
            }),
            catchError(() => of(new fromTabActions.SetMessagesAction([this.mapper.createErrorMessage('Server error')])))
          );
      })));

   setDefaultFilter$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromMyFiltersActions.SET_DEFAULT_FILTER),
      concatMap((action: fromMyFiltersActions.SetDefaultFilterAction) => {
        let param = null;
        const filterName = action.payload.filterName;
        if (filterName !== AllDealsMyFilterName) {
          param = new HttpParams();
          param = param.set('filterName', filterName);
        }
        return this.apiGateway.post(`${this.baseUrl}/setNewDefaultFilter`, null, param)
          .pipe(
            switchMap((response: ErrorResponse | any) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [new fromTabActions.SetMessagesAction(messages)];
              }
              return [
                new fromTabActions.LoadMyFiltersAction(),
                new fromTabActions.SetMessagesAction([]),
                new fromDialogAction.UpdateDialogAction(
                  this.mapper.toSuccessMessages(`"${filterName}" is your new default filter.`)),
              ];
            })
          );
      })
    ));

   selectMyFilter$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromMyFiltersActions.SELECT_MY_FILTER),
      withLatestFrom(this.store$.pipe(select(fromFiltersSelectors.getFilters))),
      switchMap((data: [fromMyFiltersActions.SelectMyFilterAction, EcmDashboardFiltersState]) => {
        const [action, state] = data;
        const updatedFilters = updateFilters(state, state.propFilters, action.payload);
        return [
          new fromFiltersActions.UpdateFiltersAction(updatedFilters),
          new fromTabActions.GetBucketsAction()
        ];
      })));

   loadMyFilters$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromMyFiltersActions.LOAD_MY_FILTERS),
      switchMap(() =>
        this.apiGateway.get(`${this.baseUrl}/filters`)
          .pipe(
            map((response: EcmMyFiltersResponse) => {
              mapResponse(response);
              return new fromMyFiltersActions.LoadMyFiltersSuccessAction(response);
            }),
            catchError(() => of(new fromTabActions.SetMessagesAction([this.mapper.createErrorMessage('Server error')])))
          )
      )));

   deleteMyFilter$ = createEffect(() => this.actions$
    .pipe(ofType(fromMyFiltersActions.DELETE_MY_FILTER),
      concatMap((action: fromMyFiltersActions.DeleteMyFilterAction) => {
        const filterName = action.payload.filterName;
        let param = new HttpParams();
        param = param.set('filterName', filterName);
        return this.apiGateway.delete(`${this.baseUrl}/deleteFilter`, param)
          .pipe(
            switchMap((response: DeleteMyFilterResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [new fromDialogAction.UpdateDialogAction(messages)];
              }

              return [
                new fromMyFiltersActions.DeleteMyFilterSuccessAction({ filterName }),
                new fromDialogAction.UpdateDialogAction(this.mapper.toSuccessMessages(`Filter "${filterName}" deleted.`)),
              ];
            }),
            catchError(() => of(new fromTabActions.SetMessagesAction([this.mapper.createErrorMessage('Server error')])))
          );
      })));

   replaceMyFilter$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromMyFiltersActions.REPLACE_MY_FILTERS),
      withLatestFrom(this.store$.pipe(select(fromFiltersSelectors.getFilters))),
      concatMap(([action, state]: [fromMyFiltersActions.ReplaceMyFiltersAction, EcmDashboardFiltersState]) =>
        this.apiGateway.put(`${this.baseUrl}/replaceFilter`, mapMyFilter(state, action.payload))
          .pipe(
            switchMap((response: EcmMyFilter | ErrorResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response as ErrorResponse);
              if (messages && messages.length) {
                return [new fromDialogAction.UpdateDialogAction(messages)];
              }

              return [
                new fromMyFiltersActions.ReloadMyFiltersAction(),
                new fromDialogAction.UpdateDialogAction([]),
                new fromDialogAction.CloseDialogAction()
              ];
            }),
            catchError(() => of(new fromTabActions.SetMessagesAction([this.mapper.createErrorMessage('Server error')])))
          )
      )));
}
