import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors, ValidatorFn,
  Validators
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SelectItem } from 'primeng/api';
import * as moment from 'moment';
import * as fromActions from '../../../state/billing-tx-report/billing-tx-report.actions';
import * as fromSelectors from '../../../state/billing-tx-report/billing-tx-report.selectors';
import { BillingTxReportState } from '../../../state/billing-tx-report/billing-tx-report.reducers';
import { BillingTxCountRequest, BillingTxItem } from '../billing-tx-report.models';
import { Moment } from 'moment';

@Component({
  selector: 'cms-billing-tx-report-filters',
  templateUrl: './billing-tx-report-filters.component.html',
  styleUrls: ['./billing-tx-report-filters.component.scss']
})
export class BillingTxReportFiltersComponent implements OnInit {
  // Form
  form: UntypedFormGroup;

  // Form Options
  orgGroups$: Observable<SelectItem[]>;
  quarterOptions: SelectItem[];
  latestQuarterOption: SelectItem;

  // Formats
  format = '[Q]Q - Y';
  valueFormatYear = 'Y'
  valueFormatQuarter = 'Q'

  // Data
  private billingTxItems: BillingTxItem[];

  constructor(
    private store: Store<BillingTxReportState>,
    private formBuilder: UntypedFormBuilder
  ) {
  }

  ngOnInit() {
    this.store.dispatch(fromActions.loadOrgGroupData());
    this.store.dispatch(fromActions.loadBillingRunQuarters({
      paging: {
        page: 0,
        entriesPerPage: 1
      },
      sorting: {
        columnName: 'quarter',
        sortOrder: 'DESCENDING'
      }
    }));

    this.orgGroups$ = this.store.pipe(select(fromSelectors.getOrgGroups));

    this.store.pipe(select(fromSelectors.getBillingReportList)).subscribe((list) => {
      this.billingTxItems = [...list];
    });

    this.store.pipe(select(fromSelectors.getLatestQuarter)).subscribe((latestQuarter: Moment) => {
      if (latestQuarter != null) {
        this.quarterOptions = [0, 1, 2, 3, 4, 5, 6].map((i) => {
          if (i == 0) {
            this.latestQuarterOption = {
              label: moment(latestQuarter).subtract(i, 'Q').format(this.format),
              value: {
                year: moment(latestQuarter).subtract(i, 'Q').format(this.valueFormatYear),
                quarter: moment(latestQuarter).subtract(i, 'Q').format(this.valueFormatQuarter)
              }
            }
          }
          return {
            label: moment(latestQuarter).subtract(i, 'Q').format(this.format),
            value: {
              year: moment(latestQuarter).subtract(i, 'Q').format(this.valueFormatYear),
              quarter: moment(latestQuarter).subtract(i, 'Q').format(this.valueFormatQuarter)
            }
          }
        });
      }
    });

    this.form = this.formBuilder.group({
      orgGroupSelector: ['', Validators.required],
      quarterSelector: [this.latestQuarterOption, Validators.required]
    }, {
      validators: this.checkReportAlreadySubmitted(this)
    });
  }

  checkReportAlreadySubmitted(component: BillingTxReportFiltersComponent): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const orgGroup = control.get('orgGroupSelector');
      const quarter = control.get('quarterSelector');
      return orgGroup && quarter &&
      orgGroup.value && quarter.value &&
      component.billingTxItems.some((el: BillingTxItem) =>
        el.groupId == orgGroup.value &&
        el.year == quarter.value.year - 1 && el.quarter == quarter.value.quarter
      ) ? { alreadySubmitted: true } : null;
    }
  }

  submitReportGeneration(): void {
    if (this.form.invalid) {
      return;
    }

    this.store.dispatch(fromActions.loadTxCount({
      groupId: this.form.value.orgGroupSelector,
      year: this.form.value.quarterSelector.year - 1,
      quarter: this.form.value.quarterSelector.quarter
    } as BillingTxCountRequest));
  }
}
