import { BehaviorSubject, Observable, throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { PagingFilter, SortingFilter } from '../../../common/shared/results.model';
import { ApiRequestService } from '../../../common/api-request.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Message } from 'primeng/api';
import { catchError, map } from 'rxjs/operators';
import { PresetValues, PresetValuesResponse } from './robo-delegators.model';
import { OrganisationService } from '@common/organisation.service';

@Injectable()
export class RoboDelegatorsService {

  private api_route: string = '/api/robo/delegators';

  private searchQuery = new BehaviorSubject<RoboDelegatorsSearchQuery>(RoboDelegatorsSearchQuery.createEmptySearchQuery());

  private message = new BehaviorSubject<Message>(null);

  private uploading: boolean = false;

  private refresh = new BehaviorSubject<boolean>(false);

  constructor(private apiRequestService: ApiRequestService, private organisationService: OrganisationService) {
  }

  public setSearchQuery(searchQuery: RoboDelegatorsSearchQuery) {
    this.searchQuery.next(searchQuery);
  }

  getSearchQuery(): RoboDelegatorsSearchQuery {
    return this.searchQuery.getValue();
  }

  public onSearchQueryChange(): Observable<RoboDelegatorsSearchQuery> {
    return this.searchQuery.asObservable();
  }

 loadPresetValues(): Observable<PresetValues> {
    return this.apiRequestService.get(this.api_route + '/loadPresetValues').pipe(
      map((response: PresetValuesResponse) => {
    return {
        uploadAllowed: response.value.uploadAllowed,
        uploadPanelVisible: response.value.uploadPanelVisible,
        viewDetailsAllowed: response.value.viewDetailsAllowed
    };
    }));
  }

  search(searchQuery: RoboDelegatorsSearchQuery): Observable<any> {
    if (this.organisationService.getCurrentOrganisationId() !== null) {
      this.refresh.next(false);
      return this.apiRequestService.post(this.api_route + '/loadRoboWhitelist', searchQuery);
    }

  }

  public sendFile(csvFile: File) {
    this.setMessage(null);

    if (this.isValidFile(csvFile) && !this.uploading) {
      this.uploading = true;
      const formData = new FormData();
      formData.append('file', csvFile);

      this.apiRequestService.post(this.api_route + '/importCsv', formData).subscribe(response => {
        if (response.validationFailureMessages) {
          this.uploading = false;
          for (let i = 0; i < response.validationFailureMessages.length; i++) {
            this.setMessage({ severity: 'error', detail: response.validationFailureMessages[i] });
          }
        } else {
          this.uploading = false;
          this.setMessage({
            severity: 'success',
            summary: '',
            detail: response.numbersProcessed + ' ROBO delegators registered successfully.'
          });
          this.refresh.next(true);
        }
      }, error => {
        this.uploading = false;
        this.setMessage({ severity: 'error', summary: '', detail: error.statusText });
      });
    }
  }

  public download(): Observable<{ filename: string, blob: Blob }> {

    return this.apiRequestService.getBlob(this.api_route + '/download/csv', null, null).pipe(map((response: HttpResponse<Blob>) => {
      const filename = this.getFilenameFromHeader(response.headers);
      const blob: Blob = response.body;
      return { filename, blob };
    }), catchError(this.handleError));
  }

  private getFilenameFromHeader(headers): string {
    const contentDisposition = headers.get('content-disposition');
    return contentDisposition.split(';')[1].trim().split('=')[1];
  }

  private handleError(error: HttpErrorResponse) {
    console.error(error);
    const errMsg = (error.message) ? error.message : 'Server error';
    return observableThrowError(errMsg);
  }

  isValidFile(file: File): boolean {
    let valid: boolean = true;
    if (file) {
      if (file.name.length > 100) {
        valid = false;
        this.setMessage({ severity: 'error', summary: '', detail: 'File name length over limit.' });
      }

      const sizeInMB = parseFloat(((file.size / 1000) / 1024).toFixed(2));

      if (sizeInMB > 1) {
        valid = false;
        this.setMessage({ severity: 'error', summary: '', detail: 'File exceeds limit of 1 MB.' });
      }

      if (!file.name.endsWith('.csv')) {
        valid = false;
        this.setMessage({ severity: 'error', summary: '', detail: 'Please choose a .csv file.' });
      }
    }

    return valid;
  }

  public setMessage(message: Message) {
    this.message.next(message);
  }

  public onMessageChange(): Observable<Message> {
    return this.message.asObservable();
  }

  public onRefresh(): Observable<boolean> {
    return this.refresh.asObservable();
  }

}

export class RoboDelegatorsSearchQuery {

  filter?: string;
  sorting: SortingFilter;
  paging: PagingFilter;

  public static isNotEmptySearchQuery(query: RoboDelegatorsSearchQuery): boolean {
    return query != null && JSON.stringify(query) !== JSON.stringify(RoboDelegatorsSearchQuery.createEmptySearchQuery());
  }

  public static createEmptySearchQuery(): RoboDelegatorsSearchQuery {
    return {
      paging: {
        entriesPerPage: 25,
        page: 0
      },
      sorting: {
        columnName: 'displayName',
        sortOrder: 'ASCENDING',
      }
    };
  }

}
